import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  selectIssue,
  clearSelectedIssue,
  setPageConfig,
  fetchIssues,
  fetchIssuesStatus,
  fetchComments,
  fetchSingleIssue,
  fetchTagsForProject,
  fetchProjectUsers,
  updateIssueTags,
  createTag,
  deleteTags,
  clearTagStatus,
  createComment,
  resetProcessState,
  resolveIssue,
  closeManageTagsModal,
  closeReportPreviewModal,
} from '../../redux/actions/issuesV2';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { clearProjects, fetchProject } from '../../redux/actions/projects';
import { IssueDataManagerTypes } from './index.types';
import IssuesDataManagerV2 from './index';

const getUserRole = (state: any, projectId: any): any => {
  if (state && state.auth && state.auth.user) {
    const filtered = state.auth.user.project_roles.filter(
      (p: any) => p.project_id === projectId
    );

    if (filtered.length) {
      return filtered[0].role;
    }
  }

  return null;
};

const mapStateToProps = (
  state: any,
  { projectId }: IssueDataManagerTypes.OwnProps
): IssueDataManagerTypes.ReduxState => {
  const { issuesV2 } = state;

  return {
    issues: issuesV2.issues,
    comments: issuesV2.comments,
    commentPage: issuesV2.commentPage,
    singleIssue: issuesV2.singleIssue,
    issuesStatus: issuesV2.issuesStatus,
    loading: issuesV2.loading,
    processState: issuesV2.processState,
    tags: issuesV2.tags,
    hasMore: issuesV2.hasMore,
    pageConfig: issuesV2.pageConfig,
    project: state.projects.data.find((x: any) => x.id === projectId),
    projectUsers: issuesV2.projectUsers,
    user: state.auth.user,
    userRole: getUserRole(state, projectId),
    showModals: issuesV2.showModals,
  };
};

const mapDispatchToProps = (
  dispatch: any
): IssueDataManagerTypes.DispatchProps => {
  return bindActionCreators(
    {
      fetchIssues,
      fetchIssuesStatus,
      fetchComments,
      fetchSingleIssue,
      fetchProject,
      clearProjects,
      fetchTagsForProject,
      fetchProjectUsers,
      selectIssue,
      clearSelectedIssue,
      setPageConfig,
      createComment,
      resolveIssue,
      updateIssueTags,
      createTag,
      deleteTags,
      clearTagStatus,
      resetProcessState,
      closeManageTagsModal,
      closeReportPreviewModal,
      showSnackbar: actionShowSnackbar,
    },
    dispatch
  );
};

const IssuesDataManagerV2Container = connect<
  IssueDataManagerTypes.ReduxState,
  IssueDataManagerTypes.DispatchProps,
  IssueDataManagerTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(IssuesDataManagerV2);

export default IssuesDataManagerV2Container;
