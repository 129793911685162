import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  resetCoachMarks,
  showCoachMarks,
} from '../../redux/actions/coachmarks';
import CoachMark from './Coachmark';

const mapStateToProps = (state: any) => {
  return {
    run: state.coachmarks.isRunning,
    steps: state.coachmarks.steps,
    views: state.views.data,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      resetCoachMarks,
      showCoachMarks,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachMark);
