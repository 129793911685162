import { Modal } from 'antd';
import React from 'react';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import {
  useMapStyleApi,
  useMapStyleContext,
} from 'src/components/Mission/MapMaker/hooks';

export interface DeleteLayerModalProps {
  layer: LayerDescriptor;
  visible: boolean;
  onDeleteLayer: (layer?: LayerDescriptor) => void;
}

export const DeleteLayerModal: React.FC<DeleteLayerModalProps> = ({
  layer,
  visible,
  onDeleteLayer,
}) => {
  const mapStyle = useMapStyleContext();
  const api = useMapStyleApi();

  if (!mapStyle) return <></>;

  return (
    <Modal
      title="Confirm Layer Deletion"
      centered
      visible={visible}
      maskClosable
      onCancel={() => onDeleteLayer()}
      onOk={async () => {
        const { projectId, aoiId, id } = mapStyle.style;

        await api
          .deleteStyleLayer(projectId, aoiId, id, layer.name)
          .catch((e) => {
            console.error('There was an error while deleting the layer.', e);

            return undefined;
          });

        onDeleteLayer(layer);
      }}
      destroyOnClose
      okText="Delete"
    >
      Are you sure you want to delete this layer?
    </Modal>
  );
};
