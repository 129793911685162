import React from 'react';
import classnames from 'classnames';
import moment from 'moment-timezone';
import Dropdown from '../../DropDown';
import { View } from '../../../api/views.types';
import style from './index.module.scss';
import { undefinedOrNull, firstLetterUpperCase } from '../../../utils/functs';

interface IOption {
  value: string;
  label: string;
}

interface IProps {
  viewList?: View[];
  view?: View;
  className?: string;
  style?: React.CSSProperties;
  onChange: (id: string) => void;
}

class ViewSelector extends React.Component<IProps> {
  private handleOption = (option: IOption): void => {
    const { onChange } = this.props;

    onChange(option.value);
  };

  private getOptions = (): IOption[] => {
    const { viewList, view: viewFromProp } = this.props;

    if (!undefinedOrNull(viewList)) {
      return viewList
        .filter((view) => view.type === viewFromProp?.type)
        .filter((view) => {
          if (
            viewFromProp?.type === 'inspection' ||
            viewFromProp?.type === 'site_navigation'
          ) {
            return true;
          }

          return view.subType === viewFromProp?.subType;
        })
        .filter((view) => view.date === viewFromProp?.date)
        .map((view, index) => ({
          value: view.id,
          label:
            view.name ||
            `${index + 1}. ${firstLetterUpperCase(
              view.type
            )}-${firstLetterUpperCase(view.subType ?? '')} ${moment(
              view.date.split('T')[0]
            ).format('ll')}`,
        }));
    }

    return [];
  };

  public render(): React.ReactNode {
    const { className, view: viewFromProp, style: styleFromProp } = this.props;

    if (!viewFromProp) {
      return null;
    }

    const { id: viewId, subType } = viewFromProp;
    const options = this.getOptions();

    options.sort((a: any, b: any) => {
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
    });

    if ((subType === 'survey' && options.length > 0) || options.length > 1) {
      return (
        <div
          className={`${classnames(
            style.container,
            className
          )} no-include-screenshot`}
          style={styleFromProp}
          id="view-selector-dropdown"
        >
          <Dropdown
            options={options}
            value={viewId}
            onChange={this.handleOption}
          />
        </div>
      );
    }

    return <React.Fragment />;
  }
}

export default ViewSelector;
