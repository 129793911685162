// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlotGoogleMap_container__60f5u{-webkit-user-select:none;-ms-user-select:none;user-select:none;height:300px;width:420px}", "",{"version":3,"sources":["webpack://./src/components/PlotGoogleMap/index.module.scss","webpack://./src/styles/scss/base/mixins/_no-select.scss"],"names":[],"mappings":"AAEA,gCCGE,wBAAA,CACA,oBAAA,CACA,gBAAA,CDFA,YAAA,CACA,WAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  @include no-select();\n\n  height: #{$googleMapHeight}px;\n  width: #{$googleMapWidth}px;\n}\n","// example: @include no-select();\n\n@mixin no-select() {\n  -moz-user-select: none;\n  -khtml-user-select: none;\n  -webkit-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PlotGoogleMap_container__60f5u"
};
export default ___CSS_LOADER_EXPORT___;
