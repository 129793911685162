import * as React from 'react';
import KDBush from 'kdbush';
import styles from './index.module.scss';
import { NavigationButtonsControllerPropsTypes } from './index.types';

class NavigationButtons8WayController extends React.PureComponent<NavigationButtonsControllerPropsTypes> {
  private searchRadius = 200 / 111111;

  private getNavMap(): any {
    const { points, navigationNeighboursData, guid, rotationCorrection } =
      this.props;

    if (points) {
      const filtered = points.filter((p: any) => p.guid === guid);
      const point = filtered.length > 0 ? filtered[0] : null;

      if (point) {
        const kdtree = new KDBush(
          points,
          (p: any) => p.longitude,
          (p: any) => p.latitude,
          64
        );
        const neighbours = kdtree
          .within(point.longitude, point.latitude, this.searchRadius)
          .map((idx: any) => kdtree.points[idx])
          .filter((p: any) => p.guid !== point.guid)
          .sort((a: any, b: any) => {
            const distA =
              (a.latitude - point.latitude) ** 2 +
              (a.longitude - point.longitude) ** 2;
            const distB =
              (b.latitude - point.latitude) ** 2 +
              (b.longitude - point.longitude) ** 2;

            return distA < distB ? -1 : distA === distB ? 0 : 1;
          });

        const map = {
          n: null,
          ne: null,
          e: null,
          se: null,
          s: null,
          sw: null,
          w: null,
          nw: null,
        };

        const correction = ((rotationCorrection || 0) * Math.PI) / 180;

        for (let i = 0; i < neighbours.length; i += 1) {
          const np = neighbours[i];
          let angle =
            Math.atan2(
              np.latitude - point.latitude,
              np.longitude - point.longitude
            ) + correction;

          angle = (2 * Math.PI + angle) % (2 * Math.PI);
          // angle is always between 0 to 2PI

          if (!map.e) {
            if (angle < Math.PI / 8 || angle > (17 / 8) * Math.PI) {
              map.e = np.guid;
            }
          }

          if (!map.ne) {
            if (angle >= (1 / 8) * Math.PI && angle < (3 / 8) * Math.PI) {
              map.ne = np.guid;
            }
          }

          if (!map.n) {
            if (angle >= (3 / 8) * Math.PI && angle < (5 / 8) * Math.PI) {
              map.n = np.guid;
            }
          }

          if (!map.nw) {
            if (angle >= (5 / 8) * Math.PI && angle < (7 / 8) * Math.PI) {
              map.nw = np.guid;
            }
          }

          if (!map.w) {
            if (angle >= (7 / 8) * Math.PI && angle < (9 / 8) * Math.PI) {
              map.w = np.guid;
            }
          }

          if (!map.sw) {
            if (angle >= (9 / 8) * Math.PI && angle < (11 / 8) * Math.PI) {
              map.sw = np.guid;
            }
          }

          if (!map.s) {
            if (angle >= (11 / 8) * Math.PI && angle < (13 / 8) * Math.PI) {
              map.s = np.guid;
            }
          }

          if (!map.se) {
            if (angle >= (13 / 8) * Math.PI && angle < (15 / 8) * Math.PI) {
              map.se = np.guid;
            }
          }

          if (!map.e) {
            if (angle >= (15 / 8) * Math.PI && angle < (17 / 8) * Math.PI) {
              map.e = np.guid;
            }
          }
        }

        return map;
      }
    }

    const navMap: any = navigationNeighboursData
      ? navigationNeighboursData[guid] || {}
      : {};

    return navMap;
  }

  private handleNavigationBtnClick = (nextGuid: string) => {
    const { onNavigationBtnClick } = this.props;

    if (!onNavigationBtnClick) {
      return;
    }

    onNavigationBtnClick(nextGuid);
  };

  public render(): React.ReactNode {
    const { style } = this.props;

    const navMap: any = this.getNavMap();
    const { n, ne, e, se, s, sw, w, nw } = navMap;

    return (
      <div className={styles.container} style={style}>
        <div className={styles.controller}>
          <div className={styles.crossCenter}>
            <button
              className={styles.crossTop}
              disabled={!n}
              onClick={() => {
                this.handleNavigationBtnClick(n);
              }}
              title="Top"
            >
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </button>
            <button
              className={styles.crossTopLeft}
              disabled={!nw}
              onClick={() => {
                this.handleNavigationBtnClick(nw);
              }}
              title="Top Left"
            >
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </button>
            <button
              className={styles.crossLeft}
              disabled={!w}
              onClick={() => {
                this.handleNavigationBtnClick(w);
              }}
              title="Left"
            >
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </button>
            <button
              className={styles.crossTopRight}
              disabled={!ne}
              onClick={() => {
                this.handleNavigationBtnClick(ne);
              }}
              title="Top Right"
            >
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </button>
            <button
              className={styles.crossRight}
              disabled={!e}
              onClick={() => {
                this.handleNavigationBtnClick(e);
              }}
              title="Right"
            >
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </button>
            <button
              className={styles.crossBottomRight}
              disabled={!se}
              onClick={() => {
                this.handleNavigationBtnClick(se);
              }}
              title="Bottom Right"
            >
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </button>
            <button
              className={styles.crossBottom}
              disabled={!s}
              onClick={() => {
                this.handleNavigationBtnClick(s);
              }}
              title="Bottom"
            >
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </button>
            <button
              className={styles.crossBottomLeft}
              disabled={!sw}
              onClick={() => {
                this.handleNavigationBtnClick(sw);
              }}
              title="Bottom Left"
            >
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default NavigationButtons8WayController;
