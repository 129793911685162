import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchElevation } from '../../redux/actions/volume';
import ElevationCalculator, {
  IDispatchProps,
  IOwnProps,
  IStateProps,
} from './ElevationCalculator';

const mapStateToProps = (state: any): IStateProps => {
  return {
    elevation: state.volume.data.elevation,
    loading: state.volume.loading,
    error: state.volume.error,
  };
};

const mapDispatchToProps = (
  dispatch: any,
  { viewId, shape }: IOwnProps
): IDispatchProps => {
  const shapeArray = Array.isArray(shape) ? shape : [shape];
  const points = shapeArray.map((shapeItem) => shapeItem.geometry.coordinates);

  return bindActionCreators(
    {
      fetchElevation: fetchElevation.bind(null, viewId, points),
    },
    dispatch
  );
};

const ElevationCalculatorContainer = connect<
  IStateProps,
  IDispatchProps,
  IOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ElevationCalculator);

export default ElevationCalculatorContainer;
