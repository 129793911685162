import * as React from 'react';
import classnames from 'classnames';
import { Tabs } from 'antd';
import Icon from '../Icon';
import style from './index.module.scss';
import {
  ViewControlsV2TypeTypes,
  ViewControlsV2ItemsTypes,
  ViewControlsV2PropsTypes,
  ViewControlsV2StateTypes,
} from './index.types';
import { inArray, undefinedOrNull } from '../../utils/functs';

const { TabPane } = Tabs;

class ViewControlsV2 extends React.Component<
  ViewControlsV2PropsTypes,
  ViewControlsV2StateTypes
> {
  private theNextTabType: ViewControlsV2TypeTypes | null = null;

  public constructor(props: ViewControlsV2PropsTypes) {
    super(props);
    this.state = {
      selectedItemKey: '0',
    };
  }

  public shouldComponentUpdate({
    items: nextItems,
    viewControlsCreateTime: nextViewControlsCreateTime,
  }: Readonly<ViewControlsV2PropsTypes>): boolean {
    const { items, viewControlsCreateTime } = this.props;
    // this is done to make sure that components arent mounted at each state change in the parent

    if (viewControlsCreateTime !== nextViewControlsCreateTime) {
      return true;
    }

    return !(
      items !== nextItems && JSON.stringify(nextItems) === JSON.stringify(items)
    );
  }

  public componentDidUpdate({
    items: prevItems,
  }: Readonly<ViewControlsV2PropsTypes>): void {
    const { items } = this.props;

    if (JSON.stringify(prevItems) !== JSON.stringify(items)) {
      this.moveTab(this.theNextTabType);

      this.theNextTabType = null;
    }
  }

  private handleSelectItem = (item: ViewControlsV2ItemsTypes): void => {
    const { onChange, selectedViewControlsMenuTypeList } = this.props;

    if (inArray(selectedViewControlsMenuTypeList || [], item.id)) {
      this.moveTab(item.id);

      return;
    }

    if (!onChange) {
      return;
    }

    this.theNextTabType = item.id;

    onChange(item.id);
  };

  private handleIntentChange = (item: ViewControlsV2TypeTypes): void => {
    const { onIntentChange } = this.props;

    if (!onIntentChange) {
      return;
    }

    onIntentChange(item);
  };

  private moveTab = (item: ViewControlsV2TypeTypes | null): void => {
    let itemIndex: null | number = null;

    if (!undefinedOrNull(item)) {
      itemIndex = this.getSelectedMenuList()
        .map((a) => a.id)
        .indexOf(item);
    }

    const selectedTabIndex = this.getSelectedTabItem(itemIndex);

    this.handleTabChange(`${selectedTabIndex}`);
  };

  private handleTabChange = (key: string) => {
    this.setState(
      {
        selectedItemKey: key,
      },
      () => {
        // change the intent after tab is changed to facilitate the draw control change
        const selectedItemsList = this.getSelectedMenuList();
        const selectedTabItem = selectedItemsList[parseInt(key, 10)];

        if (!selectedTabItem) {
          return;
        }

        this.handleIntentChange(selectedTabItem.id);
      }
    );
  };

  private renderMenuItems = (): JSX.Element[] | null => {
    const { items } = this.props;

    if (!items) {
      return null;
    }

    return items.map((item) => {
      return (
        <div
          key={item.id}
          onClick={() => {
            if (item.disabled) {
              return;
            }

            this.handleSelectItem(item);
          }}
          className={classnames(style.menuItem, {
            [style.active]: item.selected,
            [style.disabled]: item.disabled,
          })}
        >
          <div>
            <Icon name={item.id} />
          </div>

          {item.label}
        </div>
      );
    });
  };

  private getSelectedTabItem = (selectedTabIndex: number | null): number => {
    const { selectedItemKey: selectedItemKeyInState } = this.state;

    const selectedMenuList = this.getSelectedMenuList();
    let _selectedTabIndex = selectedTabIndex;

    if (undefinedOrNull(_selectedTabIndex)) {
      _selectedTabIndex = parseInt(selectedItemKeyInState, 10);
    }

    if (!selectedMenuList || selectedMenuList.length < 1) {
      return 0;
    }

    if (selectedMenuList.length - 1 < _selectedTabIndex) {
      _selectedTabIndex = selectedMenuList.length - 1;
    }

    if (_selectedTabIndex < 0) {
      return 0;
    }

    return _selectedTabIndex;
  };

  private RenderMenuContentWrapper = (): JSX.Element | null => {
    const { selectedItemKey } = this.state;
    const elemList = this.getSelectedMenuList();

    if (!elemList || elemList.length < 1) {
      return null;
    }

    return (
      <div className={style.controlBoxWrapper}>
        <Tabs
          defaultActiveKey="0"
          onChange={this.handleTabChange}
          activeKey={selectedItemKey}
        >
          {elemList.map((a, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <TabPane tab={a.label} key={`${index}`} forceRender={false}>
                <a.renderControlBox key={a.id} />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    );
  };

  public getSelectedMenuList = (): ViewControlsV2ItemsTypes[] => {
    const { items, selectedViewControlsMenuTypeList } = this.props;

    const selectedItems = (items || []).filter((a) => a.selected);

    return selectedItems.slice(0).sort((a, b) => {
      return (
        (selectedViewControlsMenuTypeList || []).indexOf(a.id) -
        (selectedViewControlsMenuTypeList || []).indexOf(b.id)
      );
    });
  };

  public render(): React.ReactNode {
    const { className } = this.props;

    const { RenderMenuContentWrapper } = this;

    return (
      <div className={classnames(style.container, className)}>
        <div className={style.menu}>{this.renderMenuItems()}</div>
        <RenderMenuContentWrapper />
      </div>
    );
  }
}

export default ViewControlsV2;
