import * as React from 'react';
import { Cameras } from '../../../../vendor/navigation';
import styles from './index.module.scss';

export interface IProps {
  images: any[];
  selectedImage?: string;
  onNavigationBtnClick: (nextImage: string) => void;
  forceYawToNorth: boolean;
}

export interface IState {
  cameras?: any;
  navState: NavigationState;
}

export interface NavigationState {
  front: string | null;
  back: string | null;
  top: string | null;
  down: string | null;
  right: string | null;
  left: string | null;
}

export default class Navigation3DController extends React.Component<
  IProps,
  IState
> {
  private searchRadius = 1000;

  constructor(props: IProps) {
    super(props);

    const { images, selectedImage, forceYawToNorth } = this.props;
    const cameras = new Cameras(images || [], this.searchRadius);

    cameras.buildKdTree();

    const img = (images || []).find((i) => i.guid === selectedImage);
    const navState = img
      ? cameras.findNearest(img, forceYawToNorth)
      : ({} as any);

    this.state = { navState };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.images && nextProps.images.length > 1) {
      const { images, selectedImage, forceYawToNorth } = nextProps;
      const cameras: any = new Cameras(images || [], this.searchRadius);

      cameras.buildKdTree();

      const img = (images || []).find((i) => i.guid === selectedImage);
      const navState = img ? cameras.findNearest(img, forceYawToNorth) : {};

      this.state = { navState };
    }
  }

  private handleNavigationBtnClick(guid: string | null) {
    const { onNavigationBtnClick } = this.props;

    if (guid) {
      onNavigationBtnClick(guid);
    }
  }

  public render() {
    const { navState: nav } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.controller}>
          <div className={styles.crossCenter}>
            <button
              className={styles.crossTop}
              disabled={!nav.top}
              onClick={() => {
                this.handleNavigationBtnClick(nav.top);
              }}
              title="Top"
            >
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </button>
            <button
              className={styles.crossLeft}
              disabled={!nav.left}
              onClick={() => {
                this.handleNavigationBtnClick(nav.left);
              }}
              title="Left"
            >
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </button>
            <button
              className={styles.crossRight}
              disabled={!nav.right}
              onClick={() => {
                this.handleNavigationBtnClick(nav.right);
              }}
              title="Right"
            >
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </button>
            <button
              className={styles.crossBottom}
              disabled={!nav.down}
              onClick={() => {
                this.handleNavigationBtnClick(nav.down);
              }}
              title="Bottom"
            >
              <i className="fa fa-chevron-up" aria-hidden="true" />
            </button>
            <button
              className={styles.front}
              disabled={!nav.front}
              onClick={() => {
                this.handleNavigationBtnClick(nav.front);
              }}
              title="Front"
            >
              F
            </button>
            <button
              className={styles.back}
              disabled={!nav.back}
              onClick={() => {
                this.handleNavigationBtnClick(nav.back);
              }}
              title="Back"
            >
              B
            </button>
          </div>
        </div>
      </div>
    );
  }
}
