import { Issue, Tag, IssuesPageConfig } from '../../api/issue.types';
import IssueAPI from '../../api/issue';
import CapiAPI from '../../api/capiV2';
import { ProjectUser } from '../../api/projects.types';
import { NullOrGenericObjectType } from '../../shapes/app';
import {
  ISSUES_PAGINATION_DEFAULT_PAGE_SIZE,
  ISSUE_COMMENTS_PAGINATION_DEFAULT_PAGE_SIZE,
} from '../../constants';

const issueApi = new IssueAPI();
const capiApi = new CapiAPI();

export function issuesPageConfigToParams(pageConfig: IssuesPageConfig) {
  const { projectId, aoiId, page, tagId, showResolved } = pageConfig;

  return {
    projectId,
    aoiId,
    offset: (page || 0) * ISSUES_PAGINATION_DEFAULT_PAGE_SIZE,
    limit: ISSUES_PAGINATION_DEFAULT_PAGE_SIZE,
    showResolved: showResolved || false,
    tagId: tagId || '',
  };
}

export function commentPageToParams(page: number) {
  return {
    offset: (page || 0) * ISSUE_COMMENTS_PAGINATION_DEFAULT_PAGE_SIZE,
    limit: ISSUE_COMMENTS_PAGINATION_DEFAULT_PAGE_SIZE,
  };
}

const issueCreated = (id: string): {} => {
  return {
    type: 'V2_ISSUE_CREATE_SUCCESS',
    payload: {
      id,
    },
  };
};

const issueCreateFailed = (): {} => {
  return {
    type: 'V2_ISSUE_CREATE_FAILED',
  };
};

const issueCreateStarted = (): {} => {
  return {
    type: 'V2_ISSUE_CREATE_STARTED',
  };
};

const issueFetched = (data: any[]): {} => {
  return {
    type: 'V2_ISSUE_FETCH_SUCCESS',
    payload: data,
  };
};

const issueFetchFailed = (): {} => {
  return {
    type: 'V2_ISSUE_FETCH_FAILED',
  };
};

const issueFetchStarted = (): {} => {
  return {
    type: 'V2_ISSUE_FETCH_STARTED',
  };
};

const issueStatusFetchStarted = (): {} => {
  return {
    type: 'V2_ISSUE_STATUS_FETCH_STARTED',
  };
};

const issueStatusFetchFailed = (): {} => {
  return {
    type: 'V2_ISSUE_STATUS_FETCH_FAILED',
  };
};

const issueStatusFetchSuccess = (data: any): {} => {
  return {
    type: 'V2_ISSUE_STATUS_FETCH_SUCCESS',
    payload: data,
  };
};

const commentFetchStarted = (): {} => {
  return {
    type: 'V2_COMMENT_FETCH_STARTED',
  };
};

const commentFetched = (data: any[], commentPage: number): {} => {
  return {
    type: 'V2_COMMENT_FETCH_SUCCESS',
    payload: {
      data,
      commentPage,
    },
  };
};

const commentFetchFailed = (): {} => {
  return {
    type: 'V2_COMMENT_FETCH_FAILED',
  };
};

const commentCreateStarted = (): {} => {
  return {
    type: 'V2_COMMENT_CREATE_STARTED',
  };
};

const commentCreated = (data: any, issueId: string): {} => {
  return {
    type: 'V2_COMMENT_CREATE_SUCCESS',
    payload: {
      data,
      issueId,
    },
  };
};

const commentCreateFailed = (): {} => {
  return {
    type: 'V2_COMMENT_CREATE_FAILED',
  };
};

const issueResolveStarted = (): {} => {
  return {
    type: 'V2_ISSUE_RESOLVE_STARTED',
  };
};

const issueResolved = (data: any): {} => {
  return {
    type: 'V2_ISSUE_RESOLVE_SUCCESS',
    payload: data,
  };
};

const issueResolveFailed = (): {} => {
  return {
    type: 'V2_ISSUE_RESOLVE_FAILED',
  };
};

const issueTagsUpdateStarted = (): {} => {
  return {
    type: 'V2_ISSUE_TAGS_UPDATE_STARTED',
  };
};

const issueTagsUpdateSuccess = (issue: Issue): {} => {
  return {
    type: 'V2_ISSUE_TAGS_UPDATE_SUCCESS',
    payload: issue,
  };
};

const issueTagsUpdateFailed = (): {} => {
  return {
    type: 'V2_ISSUE_TAGS_UPDATE_FAILED',
  };
};

const issueSelected = (data: Issue): {} => {
  return {
    type: 'V2_ISSUE_SELECTED',
    payload: data,
  };
};

const issueCleared = (): {} => {
  return {
    type: 'V2_ISSUE_CLEARED',
  };
};

const pageConfigUpdated = (pageConfig: IssuesPageConfig): {} => {
  return {
    type: 'V2_ISSUE_PAGE_CONFIG_UPDATED',
    payload: pageConfig,
  };
};

const pageConfigCleared = (): {} => {
  return {
    type: 'V2_ISSUE_PAGE_CONFIG_CLEARED',
  };
};

const singleIssueFetchStarted = (): {} => {
  return {
    type: 'V2_SINGLE_ISSUE_FETCH_STARTED',
  };
};

const singleIssueFetched = (data: any): {} => {
  return {
    type: 'V2_SINGLE_ISSUE_FETCH_SUCCESS',
    payload: data,
  };
};

const singleIssueFetchFailed = (): {} => {
  return {
    type: 'V2_SINGLE_ISSUE_FETCH_FAILED',
  };
};

const tagsFetchStarted = (): {} => {
  return {
    type: 'V2_TAG_FETCH_STARTED',
  };
};

const tagsFetched = (data: any[]): {} => {
  return {
    type: 'V2_TAGS_FETCHED_SUCCESS',
    payload: data,
  };
};

const tagsFetchFailed = (): {} => {
  return {
    type: 'V2_TAGS_FETCH_FAILED',
  };
};

const tagCreationStarted = (): {} => {
  return {
    type: 'V2_TAG_CREATION_STARTED',
  };
};

const tagCreatedSuccess = (tag: Tag): {} => {
  return {
    type: 'V2_TAG_CREATED_SUCCESS',
    payload: tag,
  };
};

const tagCreatedFailed = (): {} => {
  return {
    type: 'V2_TAG_CREATED_FAILED',
  };
};

const tagDeleteStarted = (): {} => {
  return {
    type: 'V2_TAG_DELETE_STARTED',
  };
};

const tagDeleteSuccess = (tags: string[]): {} => {
  return {
    type: 'V2_TAG_DELETE_SUCCESS',
    payload: tags,
  };
};

const tagDeleteFailed = (): {} => {
  return {
    type: 'V2_TAG_DELETE_FAILED',
  };
};

const resetTagsStatus = (): {} => {
  return {
    type: 'V2_TAGS_STATUS_RESET',
  };
};

const resetIssuesState = (): {} => {
  return {
    type: 'V2_RESET_ISSUES_STATE',
  };
};

const resetIssuesProcessState = (): {} => {
  return {
    type: 'V2_RESET_ISSUES_PROCESS_STATE',
  };
};

const projectUsersFetchStarted = (): {} => {
  return {
    type: 'V2_PROJECT_USERS_FETCH_STARTED',
  };
};

const projectUsersFetchSuccess = (projectUsers: ProjectUser[]): {} => {
  return {
    type: 'V2_PROJECT_USERS_FETCH_SUCCESS',
    payload: projectUsers,
  };
};

const projectUsersFetchFailed = (): {} => {
  return {
    type: 'V2_PROJECT_USERS_FETCH_FAILED',
  };
};

const showManageTagsModal = (): {} => {
  return {
    type: 'V2_ISSUES_SHOW_MANAGE_TAG_MODAL',
  };
};

const hideManageTagsModal = (): {} => {
  return {
    type: 'V2_ISSUES_HIDE_MANAGE_TAG_MODAL',
  };
};

const showReportPreviewModal = (): {} => {
  return {
    type: 'V2_ISSUES_SHOW_REPORT_PREVIEW_MODAL',
  };
};

const hideReportPreviewModal = (): {} => {
  return {
    type: 'V2_ISSUES_HIDE_REPORT_PREVIEW_MODAL',
  };
};

export const openManageTagsModal =
  (): {} =>
  (dispatch: any): void => {
    dispatch(showManageTagsModal());
  };

export const closeManageTagsModal =
  (): {} =>
  (dispatch: any): void => {
    dispatch(hideManageTagsModal());
  };

export const openReportPreviewModal =
  (): {} =>
  (dispatch: any): void => {
    dispatch(showReportPreviewModal());
  };

export const closeReportPreviewModal =
  (): {} =>
  (dispatch: any): void => {
    dispatch(hideReportPreviewModal());
  };

export const resetIssuesReducer =
  (): {} =>
  (dispatch: any): void => {
    dispatch(resetIssuesState());
  };

export const resetProcessState =
  (): {} =>
  (dispatch: any): void => {
    dispatch(resetIssuesProcessState());
  };

export const selectIssue =
  (issue: Issue): {} =>
  (dispatch: any): void => {
    dispatch(issueSelected(issue));
  };

export const clearSelectedIssue =
  (): {} =>
  (dispatch: any): void => {
    dispatch(issueCleared());
  };

export const setPageConfig =
  (pageConfig: IssuesPageConfig): {} =>
  (dispatch: any): void => {
    dispatch(pageConfigUpdated(pageConfig));
  };

export const clearPageConfig =
  (): {} =>
  (dispatch: any): void => {
    dispatch(pageConfigCleared());
  };

export const clearTagStatus =
  (): {} =>
  (dispatch: any): void => {
    dispatch(resetTagsStatus());
  };

export const createTag =
  (projectId: string, tagName: string): {} =>
  (dispatch: any): void => {
    dispatch(tagCreationStarted());

    issueApi
      .createTagForProject(projectId, tagName)
      .then((response) => {
        dispatch(tagCreatedSuccess(response.data));
      })
      .catch(() => {
        dispatch(tagCreatedFailed());
      });
  };

export const deleteTags =
  (projectId: string, tagsToDelete: string[]): {} =>
  (dispatch: any): void => {
    dispatch(tagDeleteStarted());

    issueApi
      .deleteTags(projectId, tagsToDelete)
      .then(() => {
        dispatch(tagDeleteSuccess(tagsToDelete));
      })
      .catch(() => {
        dispatch(tagDeleteFailed());
      });
  };

export const fetchTagsForProject =
  (projectId: string): any =>
  (dispatch: any) => {
    dispatch(tagsFetchStarted());
    issueApi
      .fetchTagsByProject(projectId)
      .then((response) => dispatch(tagsFetched(response.data)))
      .catch(() => dispatch(tagsFetchFailed()));
  };

export const createIssue =
  (
    viewId: string,
    title: string,
    message: string,
    config: any,
    shapeGeoJson: string | null,
    screenshot?: string,
    tagIds?: string[]
  ): any =>
  (dispatch: any): Promise<NullOrGenericObjectType> => {
    dispatch(issueCreateStarted());

    return new Promise((resolve) => {
      issueApi
        .createIssue(
          viewId,
          title,
          message,
          config,
          shapeGeoJson,
          screenshot,
          tagIds
        )
        .then((data) => {
          dispatch(issueCreated(data.id));

          return resolve(data ? data.data : null);
        })
        .catch(() => {
          dispatch(issueCreateFailed());

          return resolve(null);
        });
    });
  };

export const fetchIssues =
  (pageConfig: IssuesPageConfig): any =>
  (dispatch: any) => {
    const { projectId, aoiId, showResolved, offset, limit, tagId } =
      issuesPageConfigToParams(pageConfig);

    dispatch(issueFetchStarted());
    if (aoiId) {
      issueApi
        .fetchIssuesByAOI(projectId, aoiId, showResolved, offset, limit, tagId)
        .then((response) => {
          dispatch(issueFetched(response.data));
          dispatch(pageConfigUpdated(pageConfig));
        })
        .catch(() => dispatch(issueFetchFailed()));
    } else {
      issueApi
        .fetchIssuesByProject(projectId, showResolved, offset, limit, tagId)
        .then((response) => {
          dispatch(issueFetched(response.data));
          dispatch(pageConfigUpdated(pageConfig));
        })
        .catch(() => dispatch(issueFetchFailed()));
    }
  };

export const fetchIssuesStatus =
  (pageConfig: IssuesPageConfig): any =>
  (dispatch: any) => {
    const { projectId, aoiId } = issuesPageConfigToParams(pageConfig);

    dispatch(issueStatusFetchStarted());
    if (aoiId) {
      issueApi
        .fetchIssuesStatusByAOI(projectId, aoiId)
        .then((response) => dispatch(issueStatusFetchSuccess(response.data)))
        .catch(() => dispatch(issueStatusFetchFailed()));
    } else {
      issueApi
        .fetchIssuesStatusByProject(projectId)
        .then((response) => dispatch(issueStatusFetchSuccess(response.data)))
        .catch(() => dispatch(issueStatusFetchFailed()));
    }
  };

export const fetchComments =
  (issueId: string, page: number): any =>
  (dispatch: any) => {
    const { offset, limit } = commentPageToParams(page);

    dispatch(commentFetchStarted());
    issueApi
      .fetchIssueComments(issueId, offset, limit)
      .then((response) => dispatch(commentFetched(response.data, page)))
      .catch(() => dispatch(commentFetchFailed()));
  };

export const createComment =
  (issueId: string, commentMessage: string): any =>
  (dispatch: any) => {
    dispatch(commentCreateStarted());
    issueApi
      .createComment(issueId, commentMessage)
      .then((response) => dispatch(commentCreated(response.data, issueId)))
      .catch(() => dispatch(commentCreateFailed()));
  };

export const resolveIssue =
  (issueId: string): any =>
  (dispatch: any) => {
    dispatch(issueResolveStarted());
    issueApi
      .resolveIssue(issueId)
      .then((response) => dispatch(issueResolved(response.data)))
      .catch(() => dispatch(issueResolveFailed()));
  };

export const updateIssueTags =
  (issueId: string, tagIds: string[]): any =>
  (dispatch: any) => {
    dispatch(issueTagsUpdateStarted());
    issueApi
      .updateIssue(issueId, { tagIds })
      .then((response) => dispatch(issueTagsUpdateSuccess(response.data)))
      .catch(() => dispatch(issueTagsUpdateFailed()));
  };

export const fetchSingleIssue =
  (issueId: string): any =>
  (dispatch: any) => {
    dispatch(singleIssueFetchStarted());
    issueApi
      .fetchIssue(issueId)
      .then((response) => dispatch(singleIssueFetched(response.data)))
      .catch(() => dispatch(singleIssueFetchFailed()));
  };

export const fetchProjectUsers = (projectId: string) => (dispatch: any) => {
  dispatch(projectUsersFetchStarted());
  capiApi
    .getProjectUsers(projectId)
    .then((res) => dispatch(projectUsersFetchSuccess(res.data.users)))
    .catch(() => dispatch(projectUsersFetchFailed()));
};
