import ApiBase from './ApiBase';
import { BASE_WORKBENCH_API_URL } from '../constants/urls';
import { processErrorHandling } from '../utils/helpers';

import {
  ListElevationDifferenceResponse,
  ElevationDifferenceResponse,
  CreateElevationDifferenceRequest,
  PublishElevationDifferenceRequest,
} from './elevationDifference.types';

export default class ElevationDifferenceAPIs extends ApiBase {
  public constructor() {
    super(`${BASE_WORKBENCH_API_URL}/v1/`);
  }

  public getElevationDifferenceById(
    projectId: string,
    aoiId: string,
    elevationDifferenceId: string
  ): Promise<ElevationDifferenceResponse> {
    return this.get(
      `projects/${projectId}/aois/${aoiId}/elevationdifference/${elevationDifferenceId}`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to fetch the elevation difference object',
          isArray: false,
        });
      });
  }

  public getElevationDifferencesForAOI(
    projectId: string,
    aoiId: string
  ): Promise<ListElevationDifferenceResponse> {
    return this.get(`projects/${projectId}/aois/${aoiId}/elevationdifference`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to list elevation differences',
          isArray: true,
        });
      });
  }

  public createElevationDifference(
    projectId: string,
    aoiId: string,
    elevationDifference: CreateElevationDifferenceRequest
  ): Promise<ElevationDifferenceResponse> {
    return this.post(
      `projects/${projectId}/aois/${aoiId}/elevationdifference`,
      elevationDifference
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to create the elevation difference.',
          isArray: false,
        });
      });
  }

  public requestElevationDifference(
    projectId: string,
    aoiId: string,
    elevationDifference: CreateElevationDifferenceRequest
  ): Promise<ElevationDifferenceResponse> {
    return this.post(
      `projects/${projectId}/aois/${aoiId}/elevationdifference/request`,
      elevationDifference
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to request an elevation difference.',
          isArray: false,
        });
      });
  }

  public updateElevationDifference(
    projectId: string,
    aoiId: string,
    elevationDifferenceId: string,
    elevationDifference: CreateElevationDifferenceRequest
  ): Promise<ElevationDifferenceResponse> {
    return this.patch(
      `projects/${projectId}/aois/${aoiId}/elevationdifference/${elevationDifferenceId}`,
      elevationDifference
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to update the elevation difference.',
          isArray: false,
        });
      });
  }

  private _controlElevationDifferencePublish(
    projectId: string,
    aoiId: string,
    elevationDifferenceId: string,
    publishRequest: PublishElevationDifferenceRequest
  ): Promise<ElevationDifferenceResponse> {
    return this.post(
      `projects/${projectId}/aois/${aoiId}/elevationdifference/${elevationDifferenceId}/publish`,
      publishRequest
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error while trying to ${
            publishRequest.finalized ? 'publish' : 'un-publish'
          } the elevation difference.`,
          isArray: false,
        });
      });
  }

  public publishElevationDifference(
    projectId: string,
    aoiId: string,
    elevationDifferenceId: string
  ): Promise<ElevationDifferenceResponse> {
    return this._controlElevationDifferencePublish(
      projectId,
      aoiId,
      elevationDifferenceId,
      { finalized: true }
    );
  }

  public unPublishElevationDifference(
    projectId: string,
    aoiId: string,
    elevationDifferenceId: string
  ): Promise<ElevationDifferenceResponse> {
    return this._controlElevationDifferencePublish(
      projectId,
      aoiId,
      elevationDifferenceId,
      { finalized: false }
    );
  }

  public processElevationDifference(
    projectId: string,
    aoiId: string,
    elevationDifferenceId: string
  ): Promise<ElevationDifferenceResponse> {
    return this.post(
      `projects/${projectId}/aois/${aoiId}/elevationdifference/${elevationDifferenceId}/process`,
      {}
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error while trying to process the elevation difference.`,
          isArray: false,
        });
      });
  }
}
