import React from 'react';

export const EULA: React.FC = () => {
  return (
    <div style={{ height: '400px', overflow: 'auto' }}>
      <p>End-User License Agreement (&quot;Agreement&quot;)</p>
      Our EULA was last updated on 11th October 2022
      <p>
        Please read this End-User License Agreement carefully before clicking
        the &quot;I Agree&quot; button, downloading or using Vimana.
        Interpretation and Definitions Interpretation
      </p>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural. Definitions
      </p>
      For the purposes of this End-User License Agreement: &quot;Agreement&quot;
      means this End-User License Agreement that forms the entire agreement
      between You and Aspec Scire Inc regarding the use of their application
      Vimana. &quot;Application&quot; means the software provided by the Aspec
      Scire downloaded by You through an Application Store&apos;s account to a
      Device, named Vimana &quot;Application Store&quot; means the digital
      distribution service operated and developed by Aspec Scire Inc. by which
      Vimana has been downloaded to your Device. &quot;Company&quot; (referred
      to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or
      &quot;Our&quot; in this Agreement) refers to Aspec Scire Inc.
      &quot;Content&quot; refers to content such as text, images, or other
      information that can be posted, uploaded, linked to or otherwise made
      available by You, regardless of the form of that content.
      &quot;Country&quot; refers to: USA &quot;Device&quot; means any device
      that can access the Vimana such as a computer, a cellphone or a digital
      tablet. &quot;Third-Party Services&quot; means any services or content
      (including data, information, applications and other products services)
      provided by a third-party that may be displayed, included or made
      available by the Vimana. &quot;You&quot; means the individual accessing or
      using Vimana or the company, or other legal entity on behalf of which such
      individual is accessing or using the Application, as applicable.
      Acknowledgment By clicking the &quot;I Agree&quot; button, downloading or
      using the Application, You are agreeing to be bound by the terms and
      conditions of this Agreement. If You do not agree to the terms of this
      Agreement, do not click on the &quot;I Agree&quot; button, do not download
      or do not use the Application. This Agreement is a legal document between
      You and the Company and it governs your use of the Application made
      available to You by the Company. This Agreement is between You and the
      Company only. Therefore, the Company is solely responsible for the
      Application and its content. Since the Application can be accessed and
      used by other users, the use of the Application by those users is
      expressly subject to this Agreement. The Application is licensed, not
      sold, to You by the Company for use strictly in accordance with the terms
      of this Agreement. License Scope of License The Company grants You a
      revocable, non-exclusive, non-transferable, limited license to download,
      install and use the Application strictly in accordance with the terms of
      this Agreement. You may only use the Application on a Device that You own
      or control. License Restrictions You agree not to, and You will not permit
      others to: License, sell, rent, lease, assign, distribute, transmit, host,
      outsource, disclose or otherwise commercially exploit the Application or
      make the Application available to any third party. Remove, alter or
      obscure any proprietary notice (including any notice of copyright or
      trademark) of the Company or its affiliates, partners, suppliers or the
      licensors of the Application. Intellectual Property The Application,
      including without limitation all copyrights, patents, trademarks, trade
      secrets and other intellectual property rights are, and shall remain, the
      sole and exclusive property of the Company. The Company shall not be
      obligated to indemnify or defend You with respect to any third party claim
      arising out of or relating to the Application. To the extent the Company
      is required to provide indemnification by applicable law, the Company, not
      the Application Store, shall be solely responsible for the investigation,
      defense, settlement and discharge of any claim that the Application or
      your use of it infringes any third party intellectual property rights.
      Modifications to the Application The Company reserves the right to modify,
      suspend or discontinue, temporarily or permanently, the Application or any
      service to which it connects, with or without notice and without liability
      to You. Updates to the Application The Company may from time to time
      provide enhancements or improvements to the features/functionality of the
      Application, which may include patches, bug fixes, updates, upgrades and
      other modifications. Updates may modify or delete certain features and/or
      functionalities of the Application. You agree that the Company has no
      obligation to (i) provide any Updates, or (ii) continue to provide or
      enable any particular features and/or functionalities of the Application
      to You. You further agree that all updates or any other modifications will
      be (i) deemed to constitute an integral part of the Application, and (ii)
      subject to the terms and conditions of this Agreement. Maintenance and
      Support The Company does not provide any maintenance or support for the
      download and use of the Application. To the extent that any maintenance or
      support is required by applicable law, the Company, shall be obligated to
      furnish any such maintenance or support. Third-Party Services The
      Application may display, include or make available third-party content
      (including data, information, applications and other products services) or
      provide links to third-party websites or services. You acknowledge and
      agree that the Company shall not be responsible for any Third-party
      Services, including their accuracy, completeness, timeliness, validity,
      copyright compliance, legality, decency, quality or any other aspect
      thereof. The Company does not assume and shall not have any liability or
      responsibility to You or any other person or entity for any Third-party
      Services. You must comply with applicable Third parties&apos; Terms of
      agreement when using the Application. Third-party Services and links
      thereto are provided solely as a convenience to You and You access and use
      them entirely at your own risk and subject to such third parties&apos;
      Terms and conditions. Term and Termination This Agreement shall remain in
      effect until terminated by You or the Company. The Company may, in its
      sole discretion, at any time and for any or no reason, suspend or
      terminate this Agreement with or without prior notice. This Agreement will
      terminate immediately, without prior notice from the Company, in the event
      that you fail to comply with any provision of this Agreement. You may also
      terminate this Agreement by deleting the Application and all copies
      thereof from your Device or from your computer. Upon termination of this
      Agreement, You shall cease all use of the Application and delete all
      copies of the Application from your Device. Termination of this Agreement
      will not limit any of the Company&apos;s rights or remedies at law or in
      equity in case of breach by You (during the term of this Agreement) of any
      of your obligations under the present Agreement. Indemnification You agree
      to indemnify and hold the Company and its parents, subsidiaries,
      affiliates, officers, employees, agents, partners and licensors (if any)
      harmless from any claim or demand, including reasonable attorneys&apos;
      fees, due to or arising out of your: (a) use of the Application; (b)
      violation of this Agreement or any law or regulation; or (c) violation of
      any right of a third party. No Warranties The Application is provided to
      You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and
      defects without warranty of any kind. To the maximum extent permitted
      under applicable law, the Company, on its own behalf and on behalf of its
      affiliates and its and their respective licensors and service providers,
      expressly disclaims all warranties, whether express, implied, statutory or
      otherwise, with respect to the Application, including all implied
      warranties of merchantability, fitness for a particular purpose, title and
      non-infringement, and warranties that may arise out of course of dealing,
      course of performance, usage or trade practice. Without limitation to the
      foregoing, the Company provides no warranty or undertaking, and makes no
      representation of any kind that the Application will meet your
      requirements, achieve any intended results, be compatible or work with any
      other software, applications, systems or services, operate without
      interruption, meet any performance or reliability standards or be error
      free or that any errors or defects can or will be corrected. Without
      limiting the foregoing, neither the Company nor any of the company&apos;s
      provider makes any representation or warranty of any kind, express or
      implied: (i) as to the operation or availability of the Application, or
      the information, content, and materials or products included thereon; (ii)
      that the Application will be uninterrupted or error-free; (iii) as to the
      accuracy, reliability, or currency of any information or content provided
      through the Application; or (iv) that the Application, its servers, the
      content, or e-mails sent from or on behalf of the Company are free of
      viruses, scripts, trojan horses, worms, malware, timebombs or other
      harmful components. Some jurisdictions do not allow the exclusion of
      certain types of warranties or limitations on applicable statutory rights
      of a consumer, so some or all of the above exclusions and limitations may
      not apply to You. But in such a case the exclusions and limitations set
      forth in this section shall be applied to the greatest extent enforceable
      under applicable law. To the extent any warranty exists under law that
      cannot be disclaimed, the Company, not the Application Store, shall be
      solely responsible for such warranty. Limitation of Liability
      Notwithstanding any damages that You might incur, the entire liability of
      the Company and any of its suppliers under any provision of this Agreement
      and your exclusive remedy for all of the foregoing shall be limited to the
      amount actually paid by You for the Application. To the maximum extent
      permitted by applicable law, in no event shall the Company or its
      suppliers be liable for any special, incidental, indirect, or
      consequential damages whatsoever (including, but not limited to, damages
      for loss of profits, loss of data or other information, for business
      interruption, for personal injury, loss of privacy arising out of or in
      any way related to the use of or inability to use the Application,
      third-party software and/or third-party hardware used with the
      Application, or otherwise in connection with any provision of this
      Agreement), even if the Company or any supplier has been advised of the
      possibility of such damages and even if the remedy fails of its essential
      purpose. Some states/jurisdictions do not allow the exclusion or
      limitation of incidental or consequential damages, so the above limitation
      or exclusion may not apply to You. Severability and Waiver Severability If
      any provision of this Agreement is held to be unenforceable or invalid,
      such provision will be changed and interpreted to accomplish the
      objectives of such provision to the greatest extent possible under
      applicable law and the remaining provisions will continue in full force
      and effect. Waiver Except as provided herein, the failure to exercise a
      right or to require performance of an obligation under this Agreement
      shall not effect a party&apos;s ability to exercise such right or require
      such performance at any time thereafter nor shall the waiver of a breach
      constitute a waiver of any subsequent breach. Product Claims The Company
      does not make any warranties concerning the Application. To the extent You
      have any claim arising from or relating to your use of the Application,
      the Company, not the Application Store, is responsible for addressing any
      such claims, which may include, but not limited to: (i) any product
      liability claims; (ii) any claim that the Application fails to conform to
      any applicable legal or regulatory requirement; and (iii) any claim
      arising under consumer protection, or similar legislation. United States
      Legal Compliance You represent and warrant that (i) You are not located in
      a country that is subject to the United States government embargo, or that
      has been designated by the United States government as a &quot;terrorist
      supporting&quot; country, and (ii) You are not listed on any United States
      government list of prohibited or restricted parties. Changes to this
      Agreement The Company reserves the right, at its sole discretion, to
      modify or replace this Agreement at any time. If a revision is material we
      will provide at least 30 days&apos; notice prior to any new terms taking
      effect. What constitutes a material change will be determined at the sole
      discretion of the Company. By continuing to access or use the Application
      after any revisions become effective, You agree to be bound by the revised
      terms. If You do not agree to the new terms, You are no longer authorized
      to use the Application. Governing Law The laws of the Country, excluding
      its conflicts of law rules, shall govern this Agreement and your use of
      the Application. Your use of the Application may also be subject to other
      local, state, national, or international laws. Entire Agreement The
      Agreement constitutes the entire agreement between You and the Company
      regarding your use of the Application and supersedes all prior and
      contemporaneous written or oral agreements between You and the Company.
      You may be subject to additional terms and conditions that apply when You
      use or purchase other Company&apos;s services, which the Company will
      provide to You at the time of such use or purchase. Contact Us
      <p>If you have any questions about this Agreement, You can contact Us:</p>
      <p>By visiting this page on our website: www.aspecscire.com</p>
      <p>By sending us an email: sales@aspecscire.com</p>
    </div>
  );
};
