import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import TerrainMaps from './index';
import {
  actionSetDsms,
  actionSetDtms,
  actionSetView,
} from '../../redux/actions/terrainMaps';
import { TerrainMapsTypes } from './index.types';

const mapStateToProps = (state: any): TerrainMapsTypes.ReduxState => {
  return {
    dtmData: state.terrainMaps.dtmData,
    viewData: state.terrainMaps.viewData,
    dsmsData: state.terrainMaps.dsmsData,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): TerrainMapsTypes.DispatchProps => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
      setDtm: actionSetDtms,
      setView: actionSetView,
      setDsms: actionSetDsms,
    },
    dispatch
  );
};

export default connect<
  TerrainMapsTypes.ReduxState,
  TerrainMapsTypes.DispatchProps,
  TerrainMapsTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(TerrainMaps);
