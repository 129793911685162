import ApiBase from './ApiBase';
import { GenericApisReturnPromiseTypes } from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_CAPI_URL } from '../constants/urls';
import { undefinedOrNull } from '../utils/functs';

export default class ImagesApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = `/v1`;
  }

  public getImageUrl(guid: string) {
    return `${this.baseUrl}${this._baseUrl}/images/${guid}`;
  }

  public getThermalImageUrl(guid: string) {
    return `${this.baseUrl}${this._baseUrl}/images/${guid}/thermal`;
  }

  public getImageTilesUrl(
    guid: string,
    shareId?: string | null,
    isImage: boolean = false
  ): string {
    let tileUrl = '';
    let shareUrlParam = '';

    if (!undefinedOrNull(shareId) && shareId.trim() !== '') {
      shareUrlParam = `?vimana_share_token=${shareId}`;
    }

    if (!isImage) {
      tileUrl = `/tiles/${guid}.dzi`;
    }

    return `${this.baseUrl}${this._baseUrl}/images/${guid}${tileUrl}${shareUrlParam}`;
  }

  public getImagePartData(viewId: string, guid: string, path: string) {
    return this.get(
      `${this._baseUrl}/images/views/${viewId}/images/${guid}/tiles/${path}`,
      {
        headers: { 'Content-Type': 'text/xml' },
      }
    ).catch((error) => {
      return processErrorHandling({
        error,
        errorText: `There was an unexpected error when trying to fetch the tiled image.`,
      });
    });
  }

  public getTileImageData(guid: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/images/${guid}/tiles/${guid}.dzi`, {
      headers: { 'Content-Type': 'text/xml' },
    })
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the tiled image.`,
        });
      });
  }

  public postImageByViewId(
    viewId: string,
    latitude: number,
    longitude: number,
    data: any
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/images/views/${viewId}/images?latitude=${latitude}&longitude=${longitude}`,
      data
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the tiled image.`,
        });
      });
  }

  public patchImage(
    viewId: string,
    imageId: string,
    data: any
  ): GenericApisReturnPromiseTypes {
    return this.patch(
      `${this._baseUrl}/images/views/${viewId}/images/${imageId}`,
      data
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the tiled image.`,
        });
      });
  }
}
