import * as React from 'react';
import ResponsiveEmbed from 'react-responsive-embed';
import classnames from 'classnames';
import { VIMEO_EMBED_URL, YOUTUBE_EMBED_URL } from '../../constants/urls';
import DocumentationLink from '../DocumentationLink';
import styles from './index.module.scss';
import { getVideoorTitleForCurrentPathType, Video } from './videosList';

interface IState {
  currentVideo: number;
}

export default class Quickstart extends React.PureComponent<any, IState> {
  constructor(props: any) {
    super(props);

    this.state = { currentVideo: 0 };
  }

  public render() {
    const { currentVideo } = this.state;
    const { pathType } = this.props;
    const VIDEOS = getVideoorTitleForCurrentPathType(
      pathType || 'home',
      'videosList'
    );
    const video = VIDEOS[currentVideo];

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.docLinks}>
            <DocumentationLink
              href="https://vimana.vimanalabs.com/docs/"
              toolTipPosition="right"
            />
          </div>
          <div className={styles.title}>
            {getVideoorTitleForCurrentPathType(pathType, 'title')}
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.leftNav}>
            {(VIDEOS as Video[]).map((v, idx) => {
              return (
                <div
                  className={classnames(styles.navTitle, {
                    [styles.activeLink]: currentVideo === idx,
                  })}
                  onClick={() => {
                    this.setState({ currentVideo: idx });
                  }}
                >
                  {v.title}
                </div>
              );
            })}
          </div>
          <div className={styles.content}>
            {(video as Video).provider === 'youtube' && (
              <ResponsiveEmbed
                src={`${YOUTUBE_EMBED_URL}/${(video as Video).videoId}?rel=0`}
              />
            )}
            {(video as Video).provider === 'vimeo' && (
              <ResponsiveEmbed
                src={`${VIMEO_EMBED_URL}/${(video as Video).videoId}`}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
