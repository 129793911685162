import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchMissions } from '../../redux/actions/aois';
import Diagnostics from './index';
import { DiagnosticsTypes } from './index.types';

export const mapStateToProps = (state: any): DiagnosticsTypes.ReduxState => {
  return {
    loading: state.aois.loading,
    missions: state.aois.missions,
    error: state.aois.error,
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): DiagnosticsTypes.DispatchProps => {
  return bindActionCreators(
    {
      fetchMissions,
    },
    dispatch
  );
};

export default connect<
  DiagnosticsTypes.ReduxState,
  DiagnosticsTypes.DispatchProps,
  DiagnosticsTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(Diagnostics);
