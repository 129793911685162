import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ContourMaps from './index';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { ElevationDifferenceControlTypes } from './index.types';

const mapStateToProps = (): ElevationDifferenceControlTypes.ReduxState => {
  return {};
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ElevationDifferenceControlTypes.DispatchProps => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
    },
    dispatch
  );
};

export default connect<
  ElevationDifferenceControlTypes.ReduxState,
  ElevationDifferenceControlTypes.DispatchProps,
  ElevationDifferenceControlTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ContourMaps);
