import classnames from 'classnames';
import * as React from 'react';
import autobind from 'autobind-decorator';
import * as niceUtils from 'nice-utils';
import { Tooltip, Popover } from 'antd';
import { View } from 'src/api/views.types';
import MapView from 'src/components/View/MapView';
import ViewControls from '../ViewControls/ViewControls';
import style from './index.module.scss';
import TerrainMapsTabsLayout2ViewToggle from '../TerrainMaps/TerrainMapsTabsLayout2/ViewToggle';
import { TerrainMapsTabsLayout2StateSelectedDsmViewIdTypeTypes } from '../TerrainMaps/TerrainMapsTabsLayout2';
import { Button } from '../Button';
import { GenericObjectType, NullOrGenericObjectType } from '../../shapes/app';
import ModalNotification from '../ModalNotification/ModalNotification';
import { log } from '../../utils/log';
import { DrawControlIntentTypes } from '../DrawControl/DrawControl.types';
import { undefinedOrNull } from '../../utils/functs';
import { TerrainDrawControl } from '../View/MapView/OpenLayersRenderer/TerrainDrawControl';
import { RendererStateChange, ViewEvent } from '../View/index.types';
import {
  FeatureChangeEvent,
  FeatureObject,
  TerrainDrawMode,
} from '../View/MapView/OpenLayersRenderer/TerrainDrawControl/index.types';
import { AccessMap } from '../View/MapView/OpenLayersRenderer/AccessMap';

interface IProps {
  viewId: string;
  projectId: string;
  aoiId: string;
  compareView: any;
  fetchViews: () => any;
  fetchDownloads: (viewId: string) => void;
  fetchReports: (missionId: string) => void;
  fetchSingleIssue: (id: string) => void;
  resetCurrentView: () => void;
  clearIssueState: () => void;
  setLastView: (viewId: string, splitViewId: string) => void;
  saveDtmData?: () => Promise<boolean>;
  view: View;
  downloads?: any;
  reports?: any;
  splitView: any;
  hideControls: boolean;
  shareId?: string;
  viewDescriptor?: any;
  viewConfig?: any;
  boundary?: any;
  issueId?: any;
  issue: any;
  history: any;
  isDsm?: boolean;
  isAoiArtifact?: boolean;
  isTerrainMap?: boolean;
  isContourMaps?: boolean;
  vcpGeoJsonValues?: GenericObjectType | null;
  terrainMaskGeoJsonValues?: GenericObjectType | null;
  savedGroundAreasJsonValues?: GenericObjectType | null;
  automatedGroundAreasJsonValues?: GenericObjectType | null;
  aoiBoundaryJsonValues?: GenericObjectType | null;
  onToggleDsmViewType?: (viewType: string) => void;
  selectedDsmViewType?: TerrainMapsTabsLayout2StateSelectedDsmViewIdTypeTypes;
  onFullScreen?: () => void;
  onFetchTerrainMasks: () => void;
  onCsvExampleModalShow?: () => void;
  isFullScreen?: boolean;
  disableImportBtn: boolean;
  importBtnText: string;
  terrainMapsSurfaceViewTypeList: any[];
}

interface IState {
  zoom?: number;
  center?: [number, number];
  measureServiceShape: any;
  shapeList: any[];
  actionMenu: any;
  currentViewState?: any;
  styleLoaded: boolean;
  drawMode?: DrawControlIntentTypes | null;
  allowGeoJsonPlotting: boolean;
  selectedAreaIsGround: boolean;
  showRemoveSelectedAreaConfirmModal: boolean;
  showDisableSelectedVcpConfirmModal: boolean;
  showExitDtmEditingFullScreen: boolean;
  showResetMarkingsConfirmModal: boolean;
  showMarkingErrorModal: boolean;
  showMarkingUploadConfirmModal: boolean;
  showSaveErrorModal: boolean;
  markingErrorType: 'terrain_marking' | 'hole_marking' | 'editing' | null;
  polygonResetType: string;
  showResetPolygonPopover: boolean;
  resetPolygonModalText: string;
}

export default class ViewDsm extends React.PureComponent<IProps, IState> {
  private mapData: NullOrGenericObjectType = null;

  private uploadMaskRef = React.createRef<HTMLInputElement>();

  // added to ensure that we have API access to draw on Mapbox Maps
  private drawControl: GenericObjectType | null = null;

  private terrainDrawControl: TerrainDrawControl | null = null;

  // used to access the internal openlayers map object
  private accessMapRef: AccessMap | null = null;

  private defaultDrawControlTimeout = 1500;

  private timeoutOnMapboxStyleLoaded: any = undefined;

  private allowDeleteShapeList: boolean = true;

  private vcpGeoJsonValuesTemp: any[] = [];

  private terrainMaskGeoJsonValuesTemp: any[] = [];

  private removeSelectedAreaFeatures: GenericObjectType | null = [];

  private disableSelectedVcpFeatures: GenericObjectType | null = [];

  private undoStack: {
    additionalPolygons: GenericObjectType[];
    terrainMasks: GenericObjectType[];
    vcp: GenericObjectType[];
  }[] = [];

  private redoStack: {
    additionalPolygons: GenericObjectType[];
    terrainMasks: GenericObjectType[];
    vcp: GenericObjectType[];
  }[] = [];

  private layerUpdateTimeOut: any = null;

  private fetchTerrainMasksTimeOut: any = null;

  private toggleMapFullScreenTimeOut: any = null;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      measureServiceShape: undefined,
      shapeList: [],
      actionMenu: props.compareView ? 'compare' : undefined,
      // added to ensure that moving map & drawing polygon happens only AFTER the actual mapbox style is loaded
      styleLoaded: false,
      allowGeoJsonPlotting: true,
      selectedAreaIsGround: true,
      showRemoveSelectedAreaConfirmModal: false,
      showDisableSelectedVcpConfirmModal: false,
      showExitDtmEditingFullScreen: false,
      showResetMarkingsConfirmModal: false,
      showMarkingErrorModal: false,
      showSaveErrorModal: false,
      showMarkingUploadConfirmModal: false,
      markingErrorType: null,
      polygonResetType: '',
      showResetPolygonPopover: false,
      resetPolygonModalText: '',
    };
  }

  public componentDidMount(): void {
    const { fetchViews, resetCurrentView } = this.props;

    fetchViews()
      .then(() => resetCurrentView())
      .then(() => {
        /**/
      });

    this.handleFetchTerrainMasks();
  }

  public UNSAFE_componentWillReceiveProps({
    isFullScreen: nextIsFullScreen,
    terrainMaskGeoJsonValues: nextTerrainMaskGeoJsonValues,
    savedGroundAreasJsonValues: nextSavedGroundAreaGeoJsonValues,
    automatedGroundAreasJsonValues: nextAutomatedGroundAreasJsonValues,
    vcpGeoJsonValues: nextVcpGeoJsonValues,
  }: IProps): void {
    const {
      isFullScreen,
      terrainMaskGeoJsonValues,
      savedGroundAreasJsonValues,
      automatedGroundAreasJsonValues,
      vcpGeoJsonValues,
    } = this.props;
    const { allowGeoJsonPlotting } = this.state;

    if (isFullScreen !== nextIsFullScreen) {
      this.handleToggleMapFullScreen();
    }

    if (allowGeoJsonPlotting) {
      if (
        savedGroundAreasJsonValues !== nextSavedGroundAreaGeoJsonValues &&
        nextSavedGroundAreaGeoJsonValues
      ) {
        this.handleTerrainMaskGeoJsonValues(nextSavedGroundAreaGeoJsonValues);
        this.handleMarkingChange();
      } else if (
        terrainMaskGeoJsonValues !== nextTerrainMaskGeoJsonValues &&
        nextTerrainMaskGeoJsonValues &&
        // ensure terrain mask loads only if there is no saved mask
        !nextSavedGroundAreaGeoJsonValues
      ) {
        this.handleTerrainMaskGeoJsonValues(nextTerrainMaskGeoJsonValues);
        this.handleMarkingChange();
      } else if (
        automatedGroundAreasJsonValues !== nextAutomatedGroundAreasJsonValues &&
        nextAutomatedGroundAreasJsonValues &&
        // ensure AI generated mask loads only if there is no terrain mask & no saved mask
        !nextTerrainMaskGeoJsonValues &&
        !nextSavedGroundAreaGeoJsonValues
      ) {
        this.handleTerrainMaskGeoJsonValues(nextAutomatedGroundAreasJsonValues);
        this.handleMarkingChange();
      }

      if (vcpGeoJsonValues !== nextVcpGeoJsonValues) {
        this.handleVcpGeoJsonValues(nextVcpGeoJsonValues);
      }
    }
  }

  public componentWillUnmount(): void {
    this.unregisterEvents();

    if (this.mapData && this.drawControl && this.drawControl.draw) {
      try {
        this.mapData.removeControl(this.drawControl.draw);
      } catch (e) {
        log.error(
          e,
          'ViewV2 -> componentWillUnmount -> Error removing map controls'
        );
      }
    }
  }

  private unregisterEvents = () => {
    clearTimeout(this.toggleMapFullScreenTimeOut);

    clearTimeout(this.fetchTerrainMasksTimeOut);

    clearTimeout(this.layerUpdateTimeOut);
  };

  private saveMarkedTerrainAreas = async (): Promise<void> => {
    const { saveDtmData } = this.props;

    if (saveDtmData) {
      saveDtmData().then((success) => {
        if (!success) {
          this.setState({ showSaveErrorModal: true });
        }
      });
    }
  };

  private handleToggleMapFullScreen = (): void => {
    this.onMapLoaded().then((mapData) => {
      if (!mapData) {
        return;
      }

      if (mapData) {
        mapData.updateSize();
      }
    });
  };

  private onMapLoaded = (): Promise<GenericObjectType> => {
    return new Promise((resolve) => {
      this.timeoutOnMapboxStyleLoaded = setInterval(() => {
        const { styleLoaded } = this.state;

        if (!undefinedOrNull(this.accessMapRef?.getMap()) && styleLoaded) {
          clearInterval(this.timeoutOnMapboxStyleLoaded);

          return resolve(this.accessMapRef?.getMap());
        }
      }, 1);
    });
  };

  private handleFetchTerrainMasks = (): void => {
    const { onFetchTerrainMasks } = this.props;

    this.fetchTerrainMasksTimeOut = setTimeout(() => {
      onFetchTerrainMasks();
    }, this.defaultDrawControlTimeout);
  };

  private handleShapeList = (): void => {
    const { shapeList } = this.state;

    shapeList.map((item: GenericObjectType) => {
      // eslint-disable-next-line no-param-reassign
      item.crs = null;
      if (this.drawControl) {
        this.drawControl.draw.add(item);
      }

      return item;
    });
  };

  private handleDeleteShapeList = (features: any[]): void => {
    if (!features) {
      return;
    }

    features.forEach((feature: GenericObjectType) => {
      // eslint-disable-next-line no-param-reassign
      if (this.terrainDrawControl) {
        if (feature.geometry.type === 'Point')
          this.terrainDrawControl.removePointFeature(feature.id);
        else this.terrainDrawControl.removePolygonFeature(feature.id);
      }
    });
  };

  private validatePolygonGeometry(geometry: GenericObjectType) {
    if (geometry.type !== 'Polygon') {
      return false;
    }

    const validRings = geometry.coordinates.map((ring: number[][]) => {
      if (ring.length > 3) {
        return true;
      }

      return false;
    });

    if (validRings.indexOf(false) > -1) {
      return false;
    }

    return true;
  }

  private handleCaptureGeoJsonValuesChange = () => {
    if (!this.terrainDrawControl) {
      return;
    }

    const polygonFeatures = this.terrainDrawControl.getAllPolygonFeatures();
    const pointFeatures = this.terrainDrawControl.getAllPointFeatures();
    const { shapeList } = this.state;

    const shapeKeys = (shapeList || []).map((shp: GenericObjectType) => {
      return shp.id;
    });

    this.terrainMaskGeoJsonValuesTemp = [];
    this.vcpGeoJsonValuesTemp = [];

    if (polygonFeatures) {
      polygonFeatures.map((item: GenericObjectType) => {
        if (
          this.validatePolygonGeometry(item.geometry) &&
          // @todo: Optimize this. While trying to tweak, filter or manipulate the properties of geojson values geometry errors are thrown.
          shapeKeys.indexOf(item.id) === -1
        ) {
          this.terrainMaskGeoJsonValuesTemp.push(item);
        } else if (item.geometry.type === 'Point') {
          this.vcpGeoJsonValuesTemp.push(item);
        }

        return item;
      });
    }

    if (pointFeatures) {
      pointFeatures.forEach((f) => this.vcpGeoJsonValuesTemp.push(f));
    }
  };

  private handleTerrainMaskGeoJsonValues = (
    terrainMaskGeoJsonValues?: GenericObjectType | null
  ) => {
    this.handleCaptureGeoJsonValuesChange();

    if (this.allowDeleteShapeList) {
      this.handleDeleteShapeList(this.terrainMaskGeoJsonValuesTemp);
    }

    if (terrainMaskGeoJsonValues) {
      // eslint-disable-next-line no-param-reassign
      terrainMaskGeoJsonValues.crs = null; // removing CRS as mapbox-gl-draw does not like old CRS formats

      if (this.terrainDrawControl) {
        this.terrainDrawControl.addPolygonFeatures(
          terrainMaskGeoJsonValues.features
        );
      }
    }
  };

  private handleVcpGeoJsonValues = (
    vcpGeoJsonValues?: GenericObjectType | null
  ) => {
    this.handleCaptureGeoJsonValuesChange();

    if (this.allowDeleteShapeList) {
      this.handleDeleteShapeList(this.vcpGeoJsonValuesTemp);
    }

    if (vcpGeoJsonValues) {
      // eslint-disable-next-line no-param-reassign
      vcpGeoJsonValues.crs = null; // removing CRS as mapbox-gl-draw does not like old CRS formats
      if (this.terrainDrawControl) {
        this.terrainDrawControl.addPointFeatures(vcpGeoJsonValues.features);
      }
    }
  };

  private handleToggleDsmViewType = (
    viewType: TerrainMapsTabsLayout2StateSelectedDsmViewIdTypeTypes
  ): void => {
    const { onToggleDsmViewType, selectedDsmViewType } = this.props;

    if (selectedDsmViewType === viewType) {
      return;
    }

    this.handleCaptureGeoJsonValuesChange();

    this.setState({
      allowGeoJsonPlotting: false,
    });
    this.allowDeleteShapeList = false;

    if (onToggleDsmViewType) {
      onToggleDsmViewType(viewType);
    }
  };

  private handleReAddGeoJsonValues = (jsonValues: FeatureObject[]) => {
    if (jsonValues && this.terrainDrawControl) {
      const polygonFeatures = jsonValues.filter(
        (f) =>
          f.geometry.type === 'Polygon' || f.geometry.type === 'MultiPolygon'
      );
      const pointFeatures = jsonValues.filter(
        (f) => f.geometry.type === 'Point'
      );

      this.terrainDrawControl.addPolygonFeatures(polygonFeatures);
      this.terrainDrawControl.addPointFeatures(pointFeatures);
    }
  };

  private handleLayerUpdate = (): void => {
    this.layerUpdateTimeOut = setTimeout(() => {
      this.setState(
        {
          allowGeoJsonPlotting: true,
        },
        () => {
          const { shapeList } = this.state;

          if (this.terrainDrawControl) {
            this.handleReAddGeoJsonValues(this.terrainMaskGeoJsonValuesTemp);
            this.handleReAddGeoJsonValues(this.vcpGeoJsonValuesTemp);
            this.handleReAddGeoJsonValues(shapeList);

            this.allowDeleteShapeList = true;
          }
        }
      );
    }, this.defaultDrawControlTimeout);
  };

  @autobind
  private handleIntentChange(drawMode?: string | null): void {
    const { measureServiceShape, drawMode: prevDrawMode } = this.state;
    const state: any = {
      drawMode,
    };

    if (
      prevDrawMode &&
      measureServiceShape &&
      ['area', 'volume', 'distance', 'elevation'].indexOf(prevDrawMode) > -1
    ) {
      if (niceUtils.isArray(measureServiceShape)) {
        this.handleDeleteShapeList(measureServiceShape);
      } else if (this.drawControl) {
        this.drawControl.draw.delete(measureServiceShape.id);
      }
    }

    if (
      !drawMode ||
      ['measure', 'area', 'volume', 'distance', 'elevation'].indexOf(drawMode) <
        0
    ) {
      state.measureServiceShape = undefined;
    }

    this.setState(state);
  }

  private handleStyleLoad(): void {
    this.setState({ styleLoaded: true });
  }

  private getLayerColor(layer: any): {} | null {
    // eslint-disable-next-line no-nested-ternary
    const paintObj = layer ? (layer.paint ? layer.paint : {}) : {};
    const paintObjKeysArray = Object.keys(paintObj);

    for (let i = 0; i < paintObjKeysArray.length; i += 1) {
      if (paintObjKeysArray[i]) {
        if (paintObjKeysArray[i].endsWith('color')) {
          return paintObj[paintObjKeysArray[i]];
        }
      }
    }

    return null;
  }

  private isDrawControlAllowed = (): boolean => {
    const { styleLoaded, allowGeoJsonPlotting } = this.state;

    return !!(allowGeoJsonPlotting && styleLoaded);
  };

  private handleShapeCreate = ({
    features,
    isValid,
  }: FeatureChangeEvent): void => {
    const { actionMenu } = this.state;

    if (actionMenu === 'measure' || actionMenu === 'remove_selected_area') {
      return;
    }

    this.setState(
      ({ shapeList }) => {
        return {
          shapeList: [...shapeList, ...features],
        };
      },
      () => {
        this.handleShapeList();
        this.handleMarkingChange().then(() =>
          this.handleInvalidShape(!isValid)
        );
      }
    );
  };

  private handleShapeUpdate = ({
    features,
    isValid,
  }: FeatureChangeEvent): void => {
    const { shapeList } = this.state;
    let _shapeList: any[] = [];
    let changes = 0;

    // if (actionMenu === 'measure' || actionMenu === 'remove_selected_area') {
    //   return;
    // }

    // update shape in shapeList
    features.forEach((f: any) => {
      _shapeList = shapeList.map((shape: GenericObjectType) => {
        if (f.id === shape.id) {
          changes += 1;

          return f;
        }

        return shape;
      });
    });

    if (changes > 0) {
      this.setState({ shapeList: _shapeList }, () => {
        this.handleMarkingChange().then(() =>
          this.handleInvalidShape(!isValid)
        );
      });
    } else {
      // when existing mask is modified
      this.handleMarkingChange().then(() => this.handleInvalidShape(!isValid));
    }
  };

  private handleShapeSelection = ({ features }: FeatureChangeEvent): void => {
    const { actionMenu } = this.state;

    if (!features) {
      return;
    }

    // if (actionMenu === 'measure') {
    //   let measureServiceShape;
    //
    //   if (features.length === 1) {
    //     // eslint-disable-next-line prefer-destructuring
    //     measureServiceShape = features[0];
    //   } else {
    //     measureServiceShape = features;
    //   }
    //
    //   this.setState({
    //     measureServiceShape,
    //   });
    //
    //   return;
    // }

    if (
      actionMenu === 'remove_selected_area' &&
      features[0] &&
      (features[0].geometry.type === 'Polygon' ||
        features[0].geometry.type === 'MultiPolygon')
    ) {
      this.removeSelectedAreaFeatures = features;

      this.setState({
        // selectedAreaIsGround: !features[0].properties.coordPath,
        showRemoveSelectedAreaConfirmModal: true,
      });
    }

    if (
      actionMenu === 'disable_vcp' &&
      features[0] &&
      features[0].geometry.type === 'Point'
    ) {
      this.disableSelectedVcpFeatures = features;

      this.setState({
        showDisableSelectedVcpConfirmModal: true,
      });
    }
  };

  private handleShapeDelete = (): void => {
    /**/
  };

  private handleInvalidShape = (isInvalid: boolean) => {
    if (!isInvalid) return;

    const { actionMenu } = this.state;
    let markingErrorType: IState['markingErrorType'] = null;

    switch (actionMenu) {
      case 'mark_non_ground_areas': {
        markingErrorType = 'hole_marking';
        break;
      }

      case 'simple_select': {
        markingErrorType = 'editing';
        break;
      }

      case 'mark_ground_areas': {
        markingErrorType = 'terrain_marking';
        break;
      }

      default:
        break;
    }

    this.setState({
      markingErrorType,
      showMarkingErrorModal: true,
    });
  };

  private handleActionMenuChange = (
    menu: DrawControlIntentTypes
  ): void | undefined => {
    if (['measure'].indexOf(menu) > -1) {
      if (this.drawControl) {
        this.drawControl.draw.changeMode('static');
      }

      this.handleIntentChange('measure');
    } else if (
      [
        'mark_ground_areas',
        'mark_non_ground_areas',
        'remove_selected_area',
        'simple_select',
        'disable_vcp',
      ].indexOf(menu) > -1
    ) {
      this.handleIntentChange(menu);
    }

    this.setState(() => ({
      actionMenu: menu,
    }));
  };

  public handleRemoveSelectedAreaConfirmModalClose = (value: boolean) => {
    const { shapeList } = this.state;
    const features = this.removeSelectedAreaFeatures;

    let _shapeList: any[] = [];
    const _features: any[] = [];

    if (!features) {
      return;
    }

    if (value) {
      features.forEach((f: any) => {
        if (
          f.geometry.type === 'Polygon' ||
          f.geometry.type === 'MultiPolygon'
        ) {
          _features.push(f);
        }

        // TODO: implement logic for removing holes
        // if (
        //   f.properties &&
        //   f.properties.coordPath &&
        //   this.drawControl &&
        //   this.drawControl.draw
        // ) {
        //   const { featureId, ringIndex } = f.properties.coordPath;
        //   const feature = this.drawControl.draw.get(featureId);
        //
        //   if (feature && featureId && ringIndex) {
        //     this.drawControl.draw.delete([featureId]);
        //     const newFeatureGeoJSON = feature;
        //
        //     newFeatureGeoJSON.geometry.coordinates.splice(ringIndex, 1);
        //     this.drawControl.draw.add(newFeatureGeoJSON);
        //   }
        // }

        _shapeList = shapeList.filter(
          (shape: GenericObjectType) => f.id !== shape.id
        );
      });

      this.setState({ shapeList: _shapeList }, () => {
        this.handleDeleteShapeList(_features);
        this.handleMarkingChange();
      });
    } else {
      // TODO: review below logic, and see if its required. Possibly required for deleting features used to highlight a hole.
      // undo display of selected features
      // features.forEach((f: GenericObjectType) => {
      //   if (f.properties.coordPath) {
      //     this.handleDeleteShapeList([f]);
      //   } else if (this.drawControl && this.drawControl.draw) {
      //     this.drawControl.draw.delete([f.id]);
      //     this.drawControl.draw.add(f);
      //   }
      // });
    }

    if (this.terrainDrawControl) {
      this.terrainDrawControl.clearSelection();
    }

    this.removeSelectedAreaFeatures = null;
    this.setState({
      showRemoveSelectedAreaConfirmModal: false,
    });
  };

  public handleDisableSelectedVcpConfirmModalClose = (value: boolean) => {
    const { shapeList } = this.state;
    const features = this.disableSelectedVcpFeatures;

    let _shapeList: any[] = [];
    const _features: any[] = [];

    if (!features) {
      return;
    }

    if (value) {
      features.forEach((f: any) => {
        if (f.geometry.type === 'Point') {
          _features.push(f);
        }

        _shapeList = shapeList.filter(
          (shape: GenericObjectType) => f.id !== shape.id
        );
      });

      this.setState({ shapeList: _shapeList }, () => {
        this.handleDeleteShapeList(_features);
      });
    }

    this.disableSelectedVcpFeatures = null;
    this.setState({
      showDisableSelectedVcpConfirmModal: false,
    });
  };

  private getMenuItems = () => {
    const { actionMenu } = this.state;
    const items: any[] = [
      // {
      //   id: 'measure',
      //   label: 'Measure',
      //   selected: actionMenu === 'measure',
      //   renderControlBox: () => (
      //     <MeasureControlBoxContainerV1
      //       onIntentChange={this.handleIntentChange}
      //       shape={measureServiceShape}
      //       view={view}
      //     />
      //   ),
      // },
      {
        id: 'mark_ground_areas',
        label: 'Mark Terrain Areas',
        selected: actionMenu === 'mark_ground_areas',
        renderControlBox: () => null,
      },
      {
        id: 'mark_non_ground_areas',
        label: 'Mark Holes in Terrain Areas',
        selected: actionMenu === 'mark_non_ground_areas',
        renderControlBox: () => null,
      },
      {
        id: 'remove_selected_area',
        label: 'Remove Selected Area',
        selected: actionMenu === 'remove_selected_area',
        renderControlBox: () => null,
      },
      {
        id: 'simple_select',
        label: 'Edit an Area',
        selected: actionMenu === 'simple_select',
        renderControlBox: () => null,
      },
      {
        id: 'disable_vcp',
        label: 'Disable VCP',
        selected: actionMenu === 'disable_vcp',
        renderControlBox: () => null,
      },
    ];

    return items;
  };

  private handleInitialMap = (initialMap: any) => {
    this.mapData = initialMap;
  };

  private handleFullScreenToggle = (): void => {
    const geoJsonList = this.getGeoJsonValues();

    if (
      (!geoJsonList.additionalPolygons ||
        geoJsonList.additionalPolygons.length < 1) &&
      (!geoJsonList.terrainMasks || geoJsonList.terrainMasks.length < 1)
    ) {
      this.setState({
        showExitDtmEditingFullScreen: true,
      });

      return;
    }

    this.handleExitDtmEditingFullScreen();
  };

  private handleExitDtmEditingFullScreen = (value?: boolean): void => {
    const { onFullScreen } = this.props;
    const { showExitDtmEditingFullScreen } = this.state;

    if (showExitDtmEditingFullScreen) {
      this.setState({
        showExitDtmEditingFullScreen: false,
      });
    }

    if (value === false) {
      return;
    }

    if (!onFullScreen) {
      return;
    }

    onFullScreen();
  };

  public getGeoJsonValues = () => {
    const { shapeList } = this.state;

    this.handleCaptureGeoJsonValuesChange();

    return {
      vcp: this.vcpGeoJsonValuesTemp || [],
      terrainMasks: this.terrainMaskGeoJsonValuesTemp || [],
      additionalPolygons: shapeList || [],
    };
  };

  private handleShowResetMarkingModal = (): void => {
    this.setState(() => {
      return {
        showResetMarkingsConfirmModal: true,
      };
    });
  };

  private handleResetMarkings = (value?: boolean): void => {
    this.setState(() => {
      return {
        showResetMarkingsConfirmModal: false,
      };
    });

    if (!value) {
      return;
    }

    this.handleResetMarkingsBtn();
  };

  private handleResetMarkingsBtn = (): void => {
    const {
      terrainMaskGeoJsonValues,
      savedGroundAreasJsonValues,
      automatedGroundAreasJsonValues,
      aoiBoundaryJsonValues,
    } = this.props;
    const { polygonResetType } = this.state;

    if (this.drawControl) {
      this.drawControl.draw.deleteAll();
    }

    this.terrainMaskGeoJsonValuesTemp = [];
    this.vcpGeoJsonValuesTemp = [];

    if (polygonResetType === 'resetToLastSaved') {
      this.handleTerrainMaskGeoJsonValues(
        terrainMaskGeoJsonValues || savedGroundAreasJsonValues
      );
    } else if (polygonResetType === 'resetToAIMask') {
      this.handleTerrainMaskGeoJsonValues(automatedGroundAreasJsonValues);
    } else if (polygonResetType === 'resetFullGround') {
      this.handleTerrainMaskGeoJsonValues(aoiBoundaryJsonValues);
    }

    this.setState(
      {
        shapeList: [],
      },
      () => {
        this.handleMarkingChange();
      }
    );
  };

  private handlePolygonResetType = (
    polygonResetType: string,
    resetPolygonModalText: string
  ) => {
    this.setState(
      {
        polygonResetType,
        resetPolygonModalText,
        showResetPolygonPopover: false,
      },
      () => {
        this.handleShowResetMarkingModal();
      }
    );
  };

  private handleResetPolygonPopover = (showResetPolygonPopover: boolean) => {
    this.setState({
      showResetPolygonPopover,
    });
  };

  private handleMarkingChange = async () => {
    // placeholder to be used whenever there is user-change to the marking data
    const geoJsonValues = this.getGeoJsonValues();

    // ensure length of the undo stack never exceeds 10
    if (this.undoStack.length >= 10) {
      this.undoStack.shift();
    }

    this.undoStack.push(geoJsonValues);
    this.redoStack = [];
    await this.saveMarkedTerrainAreas();
  };

  private getMarkingErrorModalContent = (): string => {
    const { markingErrorType } = this.state;

    if (markingErrorType === 'editing') {
      return 'The attempted edit is invalid. Please ensure that holes are contained within the terrain areas, and do not intersect with each other. Terrain areas must be valid polygons that do not self-intersect.';
    }

    if (markingErrorType === 'hole_marking') {
      return 'Holes can only be marked within areas marked as terrain, and they should not overlap with existing holes.';
    }

    if (markingErrorType === 'terrain_marking') {
      return 'The area you have drawn is an invalid polygon. Polygons must not self-intersect.';
    }

    // default
    return 'There was an error while trying to change the areas marked as terrain.';
  };

  private handleMarkingErrorModalClose = () => {
    this.setState({
      showMarkingErrorModal: false,
    });

    // undo edit as previous change is invalid
    this.handleUndoOperation();
  };

  private handleUndoOperation = () => {
    // placeholder to handle undo
    const currentState:
      | {
          additionalPolygons: GenericObjectType[];
          terrainMasks: GenericObjectType[];
          vcp: GenericObjectType[];
        }
      | undefined = this.undoStack.pop();

    const stateToRestore:
      | {
          additionalPolygons: GenericObjectType[];
          terrainMasks: GenericObjectType[];
          vcp: GenericObjectType[];
        }
      | undefined = this.undoStack.pop();

    if (stateToRestore && currentState) {
      // clean up existing polygon
      if (this.allowDeleteShapeList && this.terrainDrawControl) {
        this.terrainDrawControl.clearPolygonFeatures();
      }

      // replace state with previous state
      if (this.terrainDrawControl) {
        const prevStateFeatures = [
          ...stateToRestore.additionalPolygons,
          ...stateToRestore.terrainMasks,
        ];

        this.setState(
          {
            shapeList: stateToRestore.additionalPolygons,
          },
          async () => {
            if (this.terrainDrawControl) {
              this.terrainDrawControl.addPolygonFeatures(prevStateFeatures);
            }

            await this.saveMarkedTerrainAreas();
          }
        );
      }

      this.undoStack.push(stateToRestore);
      this.redoStack.push(currentState);
    } else if (currentState) {
      this.undoStack.push(currentState);
    }
  };

  private handleRedoOperation = () => {
    // placeholder to handle redo
    const stateToRestore:
      | {
          additionalPolygons: GenericObjectType[];
          terrainMasks: GenericObjectType[];
          vcp: GenericObjectType[];
        }
      | undefined = this.redoStack.pop();

    if (stateToRestore) {
      // clean up existing polygon
      if (this.allowDeleteShapeList && this.terrainDrawControl) {
        this.terrainDrawControl.clearPolygonFeatures();
      }

      // replace state with previous state
      if (this.terrainDrawControl) {
        const prevFeatures = [
          ...stateToRestore.additionalPolygons,
          ...stateToRestore.terrainMasks,
        ];

        this.setState(
          {
            shapeList: stateToRestore.additionalPolygons,
          },
          async () => {
            if (this.terrainDrawControl) {
              this.terrainDrawControl.addPolygonFeatures(prevFeatures);
            }

            await this.saveMarkedTerrainAreas();
          }
        );
      }

      this.undoStack.push(stateToRestore);
    }
  };

  private RenderFullScreenBtn = (exit: boolean = false): JSX.Element => {
    return (
      <div className={style.fullScreenBtn}>
        {exit ? (
          <Button
            icon="fullscreen"
            type="secondary"
            onClick={() => {
              this.handleFullScreenToggle();
            }}
          />
        ) : (
          <Button
            icon="fullscreen-exit"
            type="secondary"
            text="Finish Marking"
            onClick={() => {
              this.handleFullScreenToggle();
            }}
          />
        )}
      </div>
    );
  };

  private getTerrainDrawMode = (
    actionMenu: any
  ): TerrainDrawMode | undefined => {
    if (!actionMenu) return undefined;

    switch (actionMenu) {
      case 'mark_ground_areas':
        return 'mark_terrain_areas';
      case 'mark_non_ground_areas':
        return 'mark_holes';
      case 'simple_select':
        return 'edit_areas';
      case 'remove_selected_area':
        return 'delete_areas';
      case 'disable_vcp':
        return 'delete_points';
      default:
        return undefined;
    }
  };

  private RenderDrawControl(): JSX.Element | null {
    const { actionMenu } = this.state;
    // const { isDsm } = this.props;
    // const isMenuActive =
    //   [
    //     'measure',
    //     'mark_ground_areas',
    //     'mark_non_ground_areas',
    //     'remove_selected_area',
    //     'simple_select',
    //     'disable_vcp',
    //   ].indexOf(actionMenu) > -1;
    const drawMode = this.getTerrainDrawMode(actionMenu);

    if (this.isDrawControlAllowed()) {
      return (
        <TerrainDrawControl
          drawMode={drawMode}
          key="terrain-draw-control"
          ref={(terrainDrawControl) => {
            this.terrainDrawControl = terrainDrawControl;
          }}
          events={{
            onShapeSelect: this.handleShapeSelection,
            onShapeUpdate: this.handleShapeUpdate,
            onShapeCreate: this.handleShapeCreate,
          }}
        />
        // <DrawControl
        //   onDrawUpdate={this.handleShapeUpdate}
        //   onDrawDelete={this.handleShapeDelete}
        //   onDrawSelectionChange={this.handleShapeSelection}
        //   onDrawCreate={this.handleShapeCreate}
        //   drawMode={isMenuActive ? drawMode : null}
        //   ref={(newDrawControl) => {
        //     if (newDrawControl) {
        //       this.drawControl = newDrawControl.getDrawControl();
        //     }
        //   }}
        //   isDsm={isDsm}
        // />
      );
    }

    this.drawControl = null;

    return null;
  }

  private uploadTerrainMask = async (): Promise<void> => {
    const files = this.uploadMaskRef.current?.files;

    if (files && files.length > 0 && this.uploadMaskRef.current) {
      const file = files[0];
      const data = JSON.parse(await file.text());

      // override current state with new upload
      if (this.terrainDrawControl)
        this.terrainDrawControl.clearPolygonFeatures();

      this.handleTerrainMaskGeoJsonValues(data);
      this.uploadMaskRef.current.value = '';
      this.setState({ showMarkingUploadConfirmModal: false });
      await this.handleMarkingChange();
    }
  };

  private downloadTerrainMask = () => {
    const { terrainMasks, additionalPolygons } = this.getGeoJsonValues();

    const geoJsonDownload = {
      type: 'FeatureCollection',
      features: [...terrainMasks, ...additionalPolygons],
    };

    const blob = new Blob([JSON.stringify(geoJsonDownload)], {
      type: 'application/json',
    });
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.setAttribute('download', 'terrain-mask.geojson');
    link.href = downloadUrl;
    link.click();

    window.URL.revokeObjectURL(downloadUrl);
  };

  private onEvent = (e: ViewEvent<unknown>) => {
    switch (e.type) {
      case 'renderer_state_changed': {
        const { data } = e as RendererStateChange;

        if (data.layers?.length) {
          this.handleInitialMap(data);
          this.handleStyleLoad();
          this.handleLayerUpdate();
        }

        break;
      }

      default:
        break;
    }
  };

  public render(): React.ReactNode {
    const {
      view,
      hideControls,
      selectedDsmViewType,
      importBtnText,
      disableImportBtn,
      onCsvExampleModalShow,
      terrainMapsSurfaceViewTypeList,
      terrainMaskGeoJsonValues,
      automatedGroundAreasJsonValues,
    } = this.props;
    const {
      selectedAreaIsGround,
      showRemoveSelectedAreaConfirmModal,
      showDisableSelectedVcpConfirmModal,
      showExitDtmEditingFullScreen,
      showResetMarkingsConfirmModal,
      showMarkingErrorModal,
      showResetPolygonPopover,
      showMarkingUploadConfirmModal,
      showSaveErrorModal,
      resetPolygonModalText,
    } = this.state;

    if (!view) {
      return null;
    }

    const resetContent = (
      <div>
        {terrainMaskGeoJsonValues ? (
          <div className={style.resetButtonContainer}>
            <Button
              className={style.resetButton}
              text="Reset to last saved state"
              onClick={() => {
                this.handlePolygonResetType(
                  'resetToLastSaved',
                  'Are you sure you want to reset all changes since the last generation?'
                );
              }}
            />
          </div>
        ) : null}
        {automatedGroundAreasJsonValues ? (
          <div className={style.resetButtonContainer}>
            <Button
              className={classnames(
                style.resetButton,
                terrainMaskGeoJsonValues ? style.removeAllPolygonButton : ''
              )}
              text="Reset to AI generated Terrain Mask"
              onClick={() => {
                this.handlePolygonResetType(
                  'resetToAIMask',
                  'Are you sure you want to reset to the AI generated terrain mask?'
                );
              }}
            />
          </div>
        ) : null}
        <div className={style.resetButtonContainer}>
          <Button
            className={classnames(
              style.resetButton,
              terrainMaskGeoJsonValues || automatedGroundAreasJsonValues
                ? style.removeAllPolygonButton
                : ''
            )}
            text="Mark entire area as Terrain"
            onClick={() => {
              this.handlePolygonResetType(
                'resetFullGround',
                'Are you sure you want to mark the entire area as terrain?'
              );
            }}
          />
        </div>
      </div>
    );

    const viewActionsMenu = this.getMenuItems();

    const viewEl = (
      // Mapbox display has been removed. Replace this with OpenLayers view display logic
      // TODO: figure out how to display Openlayers Maps in the div (or replace the div)
      // it should maintain full functionality of the DTM Marking.

      <MapView
        view={view}
        overrideControls={[]}
        onEvent={this.onEvent}
        onConfigCallbackChange={() => {}}
      >
        <AccessMap
          ref={(ref) => {
            this.accessMapRef = ref;
          }}
        />
        <TerrainMapsTabsLayout2ViewToggle
          onToggleDsmViewType={this.handleToggleDsmViewType.bind(this)}
          selectedDsmViewType={selectedDsmViewType}
          terrainMapsSurfaceViewTypeList={terrainMapsSurfaceViewTypeList}
        />

        <div className={style.topToolbarWrapper}>
          <div className={style.ctaWrapper}>
            <Tooltip
              placement="bottom"
              title="A CSV file containing Vertical Control Points (VCPs) captured with a Total Station."
            >
              <button
                disabled={disableImportBtn}
                onClick={onCsvExampleModalShow}
              >
                <i className="fa fa-download" aria-hidden="true" />
                <span>{importBtnText}</span>
              </button>
            </Tooltip>
          </div>
          <div className={style.ctaWrapper}>
            <Popover
              visible={showResetPolygonPopover}
              placement="bottom"
              content={resetContent}
              onVisibleChange={this.handleResetPolygonPopover}
              trigger="click"
            >
              <button>
                <i className="fa fa-refresh" aria-hidden="true" />
                <span>Reset</span>
              </button>
            </Popover>
          </div>
          <div className={style.ctaWrapper}>
            <button
              onClick={() => {
                this.handleUndoOperation();
              }}
            >
              <i className="fa fa-undo" aria-hidden="true" />
              <span>Undo</span>
            </button>
          </div>
          <div className={style.ctaWrapper}>
            <button
              onClick={() => {
                this.handleRedoOperation();
              }}
            >
              <i className="fa fa-repeat" aria-hidden="true" />
              <span>Redo</span>
            </button>
          </div>

          <div className={style.ctaWrapper}>
            <button
              onClick={() => {
                this.handleMarkingChange();
              }}
            >
              <i className="fa fa-save" aria-hidden="true" />
              <span>Save</span>
            </button>
          </div>
        </div>
        <div className={style.topRightToolbarWrapper}>
          <div className={style.ctaWrapper}>
            <button
              onClick={() => {
                this.downloadTerrainMask();
              }}
            >
              <i className="fa fa-download" aria-hidden="true" />
              <span>Export Terrain Areas</span>
            </button>
          </div>
          <div className={style.ctaWrapper}>
            <button
              onClick={() => {
                if (this.uploadMaskRef.current) {
                  this.uploadMaskRef.current.click();
                }
              }}
            >
              <i className="fa fa-upload" aria-hidden="true" />
              <span>Import Terrain Areas</span>
            </button>
          </div>
          <div className={style.ctaWrapper}>
            <button
              onClick={() => {
                this.handleFullScreenToggle();
              }}
            >
              <i className="fa fa-check" aria-hidden="true" />
              <span>Finish Marking</span>
            </button>
          </div>
          <input
            type="file"
            id="file"
            hidden
            ref={this.uploadMaskRef}
            multiple={false}
            accept=".geojson"
            className={style.fileUploadInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length > 0) {
                this.setState({ showMarkingUploadConfirmModal: true });
              }
            }}
          />
        </div>

        {this.RenderDrawControl()}
      </MapView>
    );

    return (
      <div className={style.container} data-testid="view-dsm-container">
        {viewEl}

        {hideControls ? null : (
          <ViewControls
            viewId={view.id}
            key={view.id}
            className={style.controls}
            items={viewActionsMenu}
            onChange={this.handleActionMenuChange}
          />
        )}

        {showRemoveSelectedAreaConfirmModal ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody={`Are you sure want to remove the selected ${
              selectedAreaIsGround ? 'terrain' : 'non-terrain'
            } area?`}
            shownotificationModal
            handleModalClose={this.handleRemoveSelectedAreaConfirmModalClose}
            handleModalCancel={this.handleRemoveSelectedAreaConfirmModalClose}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}

        {showDisableSelectedVcpConfirmModal ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody="Are you sure want to disable the selected VCP?"
            shownotificationModal
            handleModalClose={this.handleDisableSelectedVcpConfirmModalClose}
            handleModalCancel={this.handleDisableSelectedVcpConfirmModalClose}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}

        {showExitDtmEditingFullScreen ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody="You have not marked any terrain areas. The entire area will be considered as terrain. Are you sure you want to proceed?"
            shownotificationModal
            handleModalClose={this.handleExitDtmEditingFullScreen}
            handleModalCancel={this.handleExitDtmEditingFullScreen}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}

        {showResetMarkingsConfirmModal ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody={resetPolygonModalText}
            shownotificationModal
            handleModalClose={this.handleResetMarkings}
            handleModalCancel={this.handleResetMarkings}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}

        {showMarkingErrorModal ? (
          <ModalNotification
            notificationTitle="Marking Error"
            notificationBody={this.getMarkingErrorModalContent()}
            shownotificationModal={showMarkingErrorModal}
            handleModalClose={this.handleMarkingErrorModalClose}
            handleModalCancel={this.handleMarkingErrorModalClose}
            okButtonTitle="OK"
          />
        ) : null}

        {showSaveErrorModal ? (
          <ModalNotification
            notificationTitle="Save Error"
            notificationBody="There was an error while trying to save the marked terrain areas. Please try again in some time, or contact support."
            shownotificationModal={showSaveErrorModal}
            handleModalClose={() =>
              this.setState({ showSaveErrorModal: false })
            }
            handleModalCancel={() =>
              this.setState({ showSaveErrorModal: false })
            }
            okButtonTitle="OK"
          />
        ) : null}

        {showMarkingUploadConfirmModal ? (
          <ModalNotification
            notificationTitle="Confirm Terrain Area Upload"
            notificationBody="Are you sure you want to proceed with import the selected Terrain Area file? This will override the existing Terrain Areas."
            centered
            isConfirm
            shownotificationModal={showMarkingUploadConfirmModal}
            handleModalCancel={() =>
              this.setState({ showMarkingUploadConfirmModal: false })
            }
            handleModalClose={() => {
              this.uploadTerrainMask();
            }}
            okButtonTitle="Proceed"
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}
