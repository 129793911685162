import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import MeasureControlBox from './MeasureControlBox';
import {
  MeasureControlBoxPropsTypes,
  MeasureControlBoxDispatchPropsTypes,
  MeasureControlBoxStateTypes,
} from './MeasureControlBox.types';
import {
  resetElevationValue,
  resetVolumeType,
} from '../../redux/actions/volume';

const mapStateToProps = (): MeasureControlBoxStateTypes => {
  return {};
};

const mapDispatchToProps = (
  dispatch: Dispatch
): MeasureControlBoxDispatchPropsTypes => {
  return bindActionCreators(
    {
      resetElevationValue,
      resetVolumeType,
    },
    dispatch
  );
};

const MeasureControlBoxContainer = connect<
  MeasureControlBoxStateTypes,
  MeasureControlBoxDispatchPropsTypes,
  MeasureControlBoxPropsTypes
>(
  mapStateToProps,
  mapDispatchToProps
)(MeasureControlBox);

export default MeasureControlBoxContainer;
