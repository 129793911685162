import { Button, Modal, Select } from 'antd';
import * as React from 'react';
import DiagnosticOpApis from '../../../api/diagnostics';
import WorkbenchApis from '../../../api/workbench';
import { APP_BASE_URL } from '../../../constants/urls';
import { appFormatDateTime } from '../../../utils/date';
import ODMGCPs from '../OdmGcpMarking/ODMGCP';
import styles from './index.module.scss';
import SessionCost from './SessionCost';
import SessionOutputs from './SessionOutputs';
import { isVimanaLite } from '../../../utils/functs';
import StepLogsViewer from './StepLogs';
import CoordinateSystemDetails from './CoordinateSystemDetails';

interface IProps {
  mission: any;
  project: any;
  plans: any[];
  getFlightPlans: (projectId: string, aoiId: string, missionId: string) => void;
}

interface IState {
  loaded: boolean;
  sessions: any[];
  selectedPlan: string;
  selectedWorkflowType?: string;
  selectedSession?: string;
  configs: any;
  editorData: any;
  markerFiles?: [];
  colorFiles?: [];
  coordinateSystemDetails?: [];
  showProgressModal?: boolean;
  progressTitle?: string;
  showError?: string;
  showGCPModal?: boolean;
  sessionCostId?: string;
  sessionOutputsId?: string;
  stepLogsConfig?: { sessionId: string; stepId: string };
  showCSDModal?: boolean;
}

const wb = new WorkbenchApis();
const diagnostics = new DiagnosticOpApis();

class Process extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loaded: false,
      sessions: [],
      selectedPlan: '',
      configs: { loading: false, data: {} },
      editorData: {},
    };
  }

  public componentDidMount() {
    const { mission, getFlightPlans } = this.props;

    this.loadSessions(mission.id);
    getFlightPlans(mission.projectId, mission.aoiId, mission.id);
    this.loadArtifacts();
  }

  loadArtifacts = () => {
    const { mission } = this.props;

    diagnostics
      .getInputArtifacts(mission.projectId, mission.aoiId, 'GCP_MARKERS')
      .then(({ error, data }) => {
        if (error) {
          console.error(error);
        } else {
          this.setState({ markerFiles: data.files || [] });
        }
      });
    // diagnostics
    //   .getInputArtifacts(mission.projectId, mission.aoiId, 'DEM_COLORS')
    //   .then(({ error, data }) => {
    //     if (error) {
    //       console.error(error);
    //     } else {
    //       this.setState({ colorFiles: data.files || [] });
    //     }
    //   });
  };

  loadSessions(missionId: string) {
    const { selectedSession } = this.state;

    wb.getSessionsForMission(missionId).then(({ error, data }) => {
      if (error) {
        console.error(error);

        return;
      }

      if (data) {
        const sessions: any[] = data || [];

        sessions.sort((b, a) =>
          a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
        );

        this.setState(
          {
            loaded: true,
            sessions,
          },
          () => {
            if (selectedSession) {
              // this.selectSession(selectedSession);
            }

            // if any running sessions, reload after some time
            if (sessions.find((s: any) => s.status === 'started')) {
              window.setTimeout(() => {
                this.loadSessions(missionId);
              }, 5000);
            }
          }
        );
      }
    });
  }

  getSession(sessionId: string) {
    return wb.getSession(sessionId).then(({ data, error }) => {
      if (error) {
        console.error(error);
      }

      if (data) {
        const { sessions } = this.state;
        const newSessions: any[] = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const s of sessions || []) {
          if (s.sessionId === sessionId) {
            newSessions.push(data);
          } else {
            newSessions.push(s);
          }
        }

        this.setState({ sessions: newSessions });
      }
    });
  }

  doInModal(title: string, action: () => Promise<any>) {
    this.setState({ showProgressModal: true, progressTitle: title }, () => {
      action().finally(() => {
        this.setState({ showProgressModal: undefined });
      });
    });
  }

  createSession(selectedPlan: string, type: string | undefined) {
    this.doInModal('Creating workflow', () => {
      return wb
        .createSessionForFlightPlan(selectedPlan, type)
        .then(({ error, data }) => {
          if (error) {
            console.error(error);

            return;
          }

          if (data) {
            const { sessions } = this.state;

            this.setState({ sessions: [data.data, ...sessions] });
          }
        });
    });
  }

  runSession(sessionId: any) {
    this.doInModal('Starting workflow', () => {
      return wb.runSession(sessionId).then(({ error }) => {
        if (error) {
          console.error(error);

          return;
        }

        const { mission } = this.props;

        this.loadSessions(mission.id);
      });
    });
  }

  killSession(sessionId: any) {
    this.doInModal('Stopping workflow', () => {
      return wb.killSession(sessionId).then(({ error }) => {
        if (error) {
          console.error(error);

          return;
        }

        const { mission } = this.props;

        this.loadSessions(mission.id);
      });
    });
  }

  continueSession(sessionId: any, stepId: string) {
    this.doInModal('Continuing workflow', () => {
      return wb.continueSession(sessionId, stepId).then(({ error }) => {
        if (error) {
          console.error(error);

          return;
        }

        this.getSession(sessionId);
      });
    });
  }

  forkSession(sessionId: any, stepId: string) {
    this.doInModal('Forking workflow', () => {
      return wb.forkSession(sessionId, stepId).then(({ error }) => {
        if (error) {
          console.error(error);

          return;
        }

        this.getSession(sessionId);
      });
    });
  }

  updateSession(sessionId: any, editorData: any) {
    this.doInModal('Forking workflow', () => {
      return wb
        .patchSessionConfig(sessionId, editorData)
        .then(({ data, error }) => {
          if (error) {
            console.error(error);
          }

          if (data) {
            this.getSession(sessionId).then(() => {
              this.selectSession(sessionId);
            });
          }
        });
    });
  }

  showGCPModal(show: boolean) {
    this.setState({ showGCPModal: show });
  }

  getOverallProgress(session: any) {
    let sum = 0.0;
    let count = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const s of session.steps || []) {
      if (!s.disabled) {
        count += 1;
      }

      if (s.status === 'completed') {
        sum += s.progress || 1.0;
      } else {
        sum += s.progress || 0.0;
      }
    }

    return `${Math.round((100 * sum) / count)}%`;
  }

  getActions(session: any) {
    if (session.status === 'pending') {
      const id = `${session.sessionId}`;

      return (
        <>
          <Button onClick={(_) => this.runSession(id)} type="primary">
            Run
          </Button>
        </>
      );
    }

    if (session.status === 'started') {
      const id = `${session.sessionId}`;

      return (
        <>
          <Button onClick={(_) => this.killSession(id)} type="primary">
            Stop
          </Button>
        </>
      );
    }

    return <p />;
  }

  selectSession(sessionId: string) {
    const { sessions } = this.state;
    const session = sessions?.find((s: any) => s.sessionId === sessionId);

    if (!session) return;
    this.setState(
      {
        selectedSession: sessionId,
        configs: {
          loading: true,
          error: undefined,
          data: {},
        },
        editorData: { name: session?.name || '' },
      },
      () => {
        // load config
        wb.getSessionConfig(sessionId).then(({ error, data }) => {
          if (error) {
            this.setState({ configs: { loading: false, error } });
          } else {
            const confData = {};

            confData[sessionId] = data.data;
            this.setState({
              configs: {
                loading: false,
                error: undefined,
                data: confData,
              },
              editorData: this.createEditorData(
                data?.data?.workflowConfig || {},
                session
              ),
            });
          }
        });
      }
    );
  }

  getTopologicalOrderSteps(session: any) {
    const g = { nodes: {} as any, edges: [] as any[] };

    // eslint-disable-next-line no-restricted-syntax
    for (const s of session.steps || []) {
      g.nodes[s.id] = { id: s.id, inDegree: 0, step: s };
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const d of session.dependencies || []) {
      g.edges.push({ from: d.from, to: d.to });
    }

    const computeInDegrees = () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const id in g.nodes) {
        if (id) {
          const n = g.nodes[id];

          if (n) {
            n.inDegree = 0;
          }
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const e of g.edges) {
        const { to } = e;
        const n = g.nodes[to];

        if (n) {
          n.inDegree += 1;
        }
      }
    };
    const steps: any[] = [];
    let _0DegNodes = [];

    do {
      _0DegNodes = [];
      computeInDegrees();
      // eslint-disable-next-line no-restricted-syntax
      for (const id in g.nodes) {
        if (id) {
          const n = g.nodes[id];

          if (n && n.inDegree === 0) {
            _0DegNodes.push(n);
          }
        }
      }

      const removed = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const n of _0DegNodes) {
        delete g.nodes[n.id];
        removed.push(n.id);
        steps.push(n.step);
      }

      const newEdges: any[] = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const e of g.edges) {
        if (removed.indexOf(e.from) > -1 || removed.indexOf(e.to) > -1) {
          // ignore
        } else {
          newEdges.push(e);
        }
      }

      g.edges = newEdges;
    } while (_0DegNodes.length > 0);

    return steps;
  }

  renderSessionsTable() {
    const { plans } = this.props;
    const { sessions, selectedPlan, selectedSession, selectedWorkflowType } =
      this.state;

    const plan = (plans || []).find((p) => p.id === selectedPlan);

    const getWorkflowTypes = () => {
      switch (plan?.type) {
        case 'NADIR':
          return isVimanaLite()
            ? ['odm_workflow']
            : ['default_workflow', 'odm_workflow'];
        default:
          return undefined;
      }
    };
    const wfTypes: string[] | undefined = getWorkflowTypes();

    return (
      <table className={styles.sessionsTable}>
        <thead>
          <tr>
            <th colSpan={5}>
              <div style={{ flexGrow: 0 }}>
                <div>
                  Flight plan
                  <Select
                    value={selectedPlan}
                    onChange={(v: string) =>
                      this.setState({
                        selectedPlan: v,
                        selectedWorkflowType: undefined,
                      })
                    }
                    style={{ width: '20em' }}
                  >
                    <Select.Option key="--" value="">
                      -- Select Flight Plan --
                    </Select.Option>
                    {(plans || []).map((p) => (
                      <Select.Option
                        key={p.id}
                        value={p.id}
                        disabled={p.status !== 'UPLOADED'}
                      >
                        {p.type}
                      </Select.Option>
                    ))}
                  </Select>
                  {wfTypes && (
                    <>
                      Type:
                      <Select
                        value={selectedWorkflowType || ''}
                        onChange={(t: string) =>
                          this.setState({ selectedWorkflowType: t })
                        }
                        style={{ width: '20em' }}
                      >
                        <Select.Option key="--" value="">
                          -- Default --
                        </Select.Option>
                        {(wfTypes || []).map((t) => (
                          <Select.Option key={t} value={t}>
                            {t}
                          </Select.Option>
                        ))}
                      </Select>
                    </>
                  )}
                  <Button
                    disabled={!selectedPlan}
                    onClick={(_) =>
                      this.createSession(selectedPlan, selectedWorkflowType)
                    }
                    type="primary"
                  >
                    Create Processing Session
                  </Button>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Overall Progress</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {(sessions || []).map((s) => (
            <tr
              key={s.sessionId}
              className={s.sessionId === selectedSession ? styles.selected : ''}
              style={{ padding: '1em', cursor: 'pointer' }}
              onClick={() => this.selectSession(s.sessionId)}
            >
              <td style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  {s.name} ({appFormatDateTime(s.createdAt)})
                </div>
                <div>({s.sessionId})</div>
              </td>
              <td>{s.status}</td>
              <td>{this.getOverallProgress(s)}</td>
              <td>
                <a
                  onClick={() => this.setState({ sessionCostId: s.sessionId })}
                >
                  Cost
                </a>
              </td>
              <td>
                <a
                  onClick={() =>
                    this.setState({ sessionOutputsId: s.sessionId })
                  }
                >
                  Outputs
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderGraph() {
    const { sessions, selectedSession } = this.state;
    const session = (sessions || []).find(
      (s) => s.sessionId === selectedSession
    );

    if (!session) {
      return (
        <div className={styles.sessionGraph}>
          Select a session from the table on the left by clicking on a session
        </div>
      );
    }

    const stepClasses = (s: any) => {
      let classes = styles.step;

      if (s.disabled) {
        classes += ` ${styles.disabled}`;
      } else {
        classes += ` ${styles[s.status]}`;
      }

      return classes;
    };

    const steps = this.getTopologicalOrderSteps(session);

    const showForkButton = (step: any) => {
      if (session.status !== 'started') {
        if (step.status === 'error') {
          return true;
        }

        if (step.status === 'completed') {
          return (
            [
              'add_gcps',
              'split_chunks',
              'post_process_images',
              'odm_align',
              'run_odm',
              'optimize_cameras',
            ].indexOf(step.type) > -1
          );
        }
      }

      if (session.status === 'error' && step.status === 'pending') {
        const idx = steps.indexOf(step);

        if (idx > 0) {
          return steps[idx - 1].status === 'completed';
        }
      }

      return false;
    };

    const showContinueButton = (step: any) => {
      if (step.id === 'post_process_images') {
        return false;
      }

      if (showForkButton(step)) {
        return true;
      }

      if (
        step.type === 'correct_camera_centers' &&
        step.status === 'completed'
      ) {
        return true;
      }

      if (session.status === 'completed') {
        if (step.type === 'run_odm') {
          return true;
        }

        if (step.disabled) {
          const idx = session.steps.indexOf(step);

          if (idx > 0) {
            return !session.steps[idx - 1].disabled;
          }
        }
      }

      return false;
    };

    return (
      <div className={styles.sessionGraph}>
        <div className={styles.sessionStatus}>
          Session status: {session.status}
          <p style={{ float: 'right' }}>{this.getActions(session)}</p>
        </div>
        {steps.map((s: any) => (
          <div key={s.id} className={stepClasses(s)}>
            {s.id}
            <>
              <span style={{ fontStyle: 'italic', paddingLeft: '1em' }}>
                {s.status}
              </span>
              {s.type === 'add_photos' && s.status === 'completed' && (
                <div>
                  <a
                    href={`${APP_BASE_URL}/project/${session.projectId}/aoi/${session.aoiId}/gcpmarking/session/${session.sessionId}`}
                    style={{ color: 'blue' }}
                  >
                    GCP Marking
                  </a>
                </div>
              )}
              {s.type === 'odm_align' && s.status === 'completed' && (
                <div>
                  <a
                    style={{ color: 'blue' }}
                    onClick={() => this.showGCPModal(true)}
                  >
                    GCP Marking
                  </a>
                </div>
              )}
              {(s.status === 'error' || s.status === 'completed') && (
                <a
                  style={{ color: 'blue', padding: '0.5em' }}
                  onClick={() => {
                    this.setState({
                      stepLogsConfig: {
                        sessionId: session.sessionId,
                        stepId: s.id,
                      },
                    });
                  }}
                >
                  Show logs
                </a>
              )}
              {s.status === 'error' && (
                <p title={s.errorDetails || ''}>Error code: {s.errorCode}</p>
              )}
              {showForkButton(s) && (
                <Button
                  type="primary"
                  onClick={() => this.continueSession(session.sessionId, s.id)}
                >
                  <i className="fa fa-code-fork" aria-hidden="true" />
                </Button>
              )}
              {showContinueButton(s) && (
                <Button
                  type="primary"
                  onClick={() => this.continueSession(session.sessionId, s.id)}
                >
                  <i className="fa fa-share" aria-hidden="true" />
                </Button>
              )}
            </>
          </div>
        ))}
      </div>
    );
  }

  renderCoordinateDetails(guid: string) {
    return (
      <div style={{ border: '1px solid silver' }}>
        Coordinate System Details:
        {guid ? <span>{guid}</span> : 'None'}
        <a
          onClick={() => this.setState({ showCSDModal: true })}
          style={{ color: 'blue', backgroundColor: 'white', float: 'right' }}
        >
          Edit/Create
        </a>
      </div>
    );
  }

  renderColorizationConfig(cc: any) {
    const { editorData } = this.state;
    const syncCC = (k: string, v: any) => {
      // eslint-disable-next-line no-param-reassign
      cc[k] = v;
      this.setState({ editorData: { ...editorData, colorizationConfig: cc } });
    };

    return (
      <div style={{ border: '1px solid silver' }}>
        Colorization config
        <Select
          style={{ width: '30em' }}
          value={cc.colorization_type || 'single'}
          onChange={(e) => syncCC('colorization_type', e)}
        >
          <Select.Option key="formwork">formwork</Select.Option>
          <Select.Option key="single">single</Select.Option>
          <Select.Option key="time_series">time_series</Select.Option>
          <Select.Option key="color_file">color_file</Select.Option>
        </Select>
      </div>
    );
  }

  renderConfig() {
    const { sessions, selectedSession, configs, editorData, markerFiles } =
      this.state;
    const session = (sessions || []).find(
      (s) => s.sessionId === selectedSession
    );

    if (!session) {
      return (
        <div className={styles.sessionConfig}>
          Select a session from the table on the left by clicking on a session
        </div>
      );
    }

    if (configs.loading) {
      return (
        <div className={styles.sessionConfig}>Loading configuration...</div>
      );
    }

    if (configs.error) {
      return (
        <div className={styles.sessionConfig}>
          Error while loading configuration
        </div>
      );
    }

    const config = configs.data[selectedSession || ''];

    if (!config) {
      return (
        <div className={styles.sessionConfig}>
          Error while loading configuration
        </div>
      );
    }

    const readonly = ['pending', 'completed'].indexOf(session.status) === -1;

    const updateEditData = (k: string, v: any) => {
      const d = {};

      d[k] = v;
      this.setState({ editorData: { ...editorData, ...d } });
    };
    const canShowEditorFor = (stepType: string) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const s of session.steps) {
        if (s.type === stepType) {
          return s.status === 'pending';
        }
      }

      return false;
    };
    const showBoolConfig = (step: string, prop: string, label: string) => {
      if (canShowEditorFor(step)) {
        return (
          <div className={styles.fieldEditor}>
            {label}
            <input
              type="checkbox"
              checked={editorData[prop]}
              onChange={(e) => updateEditData(prop, e.target.checked)}
              disabled={readonly}
            />
          </div>
        );
      }

      return '';
    };
    const showTextConfig = (
      step: string,
      prop: string,
      label: string,
      type: string = 'text'
    ) => {
      if (canShowEditorFor(step)) {
        return (
          <div className={styles.fieldEditor}>
            {label}
            <input
              value={editorData[prop]}
              onChange={(e) =>
                updateEditData(
                  prop,
                  type === 'number'
                    ? parseFloat(e.target.value)
                    : e.target.value
                )
              }
              disabled={readonly}
              type={type}
            />
          </div>
        );
      }

      return '';
    };
    const showSelectConfig = (
      step: string,
      prop: string,
      options: { k: string; v: string }[],
      label: string
    ) => {
      if (canShowEditorFor(step)) {
        return (
          <div className={styles.fieldEditor}>
            {label}
            <Select
              value={editorData[prop]}
              onChange={(e: any) => updateEditData(prop, e)}
            >
              <Select.Option value="">-- Select One --</Select.Option>
              {(options || []).map((o) => {
                return (
                  <Select.Option key={`${prop}-${o.k}`} value={o.k}>
                    {o.v}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        );
      }

      return '';
    };

    return (
      <div className={styles.sessionConfig}>
        <div className={styles.fieldEditor}>
          Name:
          <input
            value={editorData.name || ''}
            onChange={(e) => updateEditData('name', e.target.value)}
            disabled={readonly}
          />
        </div>
        {showBoolConfig(
          'add_photos',
          'enableAddPhotos',
          "Enable 'Add Photos'?"
        )}
        {showBoolConfig('add_photos', 'rtkImages', 'Has RTK metadata?')}
        {showSelectConfig(
          'add_photos',
          'alignQuality',
          [
            { k: 'lowest', v: 'Lowest' },
            { k: 'low', v: 'Low' },
            { k: 'medium', v: 'Medium' },
            { k: 'high', v: 'High' },
            { k: 'highest', v: 'Highest' },
          ],
          'Align Quality'
        )}
        {showTextConfig(
          'add_photos',
          'keyPointLimit',
          'Key-Point Limit',
          'number'
        )}
        {showTextConfig(
          'add_photos',
          'tiePointLimit',
          'Tie-Point Limit',
          'number'
        )}
        {showBoolConfig('add_photos', 'rollingShutter', 'Rolling shutter?')}
        {showBoolConfig('add_photos', 'fixedCalibration', 'Fixed calibration?')}
        {showBoolConfig('add_photos', 'adaptiveFitting', 'Adaptive fitting?')}
        {showBoolConfig('add_photos', 'fitBoundingBox', 'Fit bounding box?')}
        {showBoolConfig(
          'add_photos',
          'boudingboxBuffer',
          'Buffer bounding box?'
        )}
        {showBoolConfig('add_gcps', 'noMarkers', 'Run without markers?')}
        {!editorData.noMarkers && canShowEditorFor('add_gcps') && (
          <>
            Marker file:
            <Select
              style={{ width: '30em' }}
              value={editorData.markerFilter?.guid || null}
              onChange={(v) => updateEditData('markerFilter', { guid: v })}
              disabled={readonly}
            >
              <Select.Option value={null}>
                -- Select Marker File --
              </Select.Option>
              {(markerFiles || []).map((f: any) => (
                <Select.Option value={f.guid}>
                  {f.name} - {new Date(f.capturedAt).toLocaleString()}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
        {showSelectConfig(
          'add_gcps',
          'coordinateUnits',
          [
            { k: 'METRES', v: 'Metres' },
            { k: 'FEET', v: 'Feet' },
            { k: 'US_SURVEY_FEET', v: 'US Survey Feet' },
          ],
          'Coordinate units'
        )}
        {showTextConfig(
          'add_gcps',
          'markerLocationAccuracy',
          'Marker Location Accuracy',
          'number'
        )}
        {showTextConfig(
          'add_gcps',
          'markerAccuracy',
          'Minimum Marker Accuracy',
          'number'
        )}
        {showBoolConfig(
          'split_chunks',
          'enableSplitChunks',
          'Continue at split chunks?'
        )}
        {showBoolConfig(
          'build_dense_cloud',
          'enableSplitChunks',
          'Build dense cloud?'
        )}
        {showSelectConfig(
          'build_dense_cloud',
          'quality',
          [
            { k: 'lowest', v: 'Lowest' },
            { k: 'low', v: 'Low' },
            { k: 'medium', v: 'Medium' },
            { k: 'high', v: 'High' },
            { k: 'highest', v: 'Highest' },
          ],
          'Dense Cloud Quality'
        )}
        {showBoolConfig(
          'export_dense_cloud',
          'exportDenseCloud',
          'Export dense cloud?'
        )}
        {canShowEditorFor('post_process_images') &&
          this.renderCoordinateDetails(editorData.coordSystemDetails)}
        {showTextConfig('post_process_images', 'epsgCode', 'EPSG code', 'text')}
        {canShowEditorFor('post_process_images') &&
          this.renderColorizationConfig(editorData.colorizationConfig)}
        {showBoolConfig(
          'correct_camera_centers',
          'enableCameraCenterUpdate',
          'Correct camera centers?'
        )}
        {showBoolConfig(
          'upload_to_mapbox',
          'uploadToMapbox',
          'Upload to mapbox?'
        )}
        {showBoolConfig(
          'generate_report',
          'generateReport',
          'Generate report?'
        )}
        {showTextConfig(
          'extract_virtual_gcps',
          'maxError',
          'Max error',
          'number'
        )}
        {showTextConfig(
          'extract_virtual_gcps',
          'minProjections',
          'Min projections',
          'number'
        )}
        {showSelectConfig(
          'odm_align',
          'odmAlignFeatureQuality',
          [
            { k: 'lowest', v: 'Lowest' },
            { k: 'low', v: 'Low' },
            { k: 'medium', v: 'Medium' },
            { k: 'high', v: 'High' },
            { k: 'highest', v: 'Ultra' },
          ],
          'Align Feature Quality'
        )}
        {showSelectConfig(
          'run_odm',
          'odmFeatureQuality',
          [
            { k: 'lowest', v: 'Lowest' },
            { k: 'low', v: 'Low' },
            { k: 'medium', v: 'Medium' },
            { k: 'high', v: 'High' },
            { k: 'highest', v: 'Ultra' },
          ],
          'Main Feature Quality'
        )}
        {showTextConfig('run_odm', 'odmChunkSize', 'ODM Split Size', 'number')}
        {showBoolConfig('run_odm', 'odmUseGcps', 'Use GCPs?')}
        {editorData.odmUseGcps && canShowEditorFor('run_odm') && (
          <>
            Marker file:
            <Select
              style={{ width: '30em' }}
              value={editorData.markerFilter?.guid || null}
              onChange={(v) => updateEditData('markerFilter', { guid: v })}
              disabled={readonly}
            >
              <Select.Option value={null}>
                -- Select Marker File --
              </Select.Option>
              {(markerFiles || []).map((f: any) => (
                <Select.Option value={f.guid}>
                  {f.name} - {new Date(f.capturedAt).toLocaleString()}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
        {showBoolConfig('run_odm', 'odmFastOrthophoto', 'Disable 3D view?')}
        {showTextConfig(
          'run_odm',
          'odmExtraArgs',
          'ODM Extra Arguments',
          'text'
        )}
        <div>
          <Button
            onClick={(_) => {
              this.updateSession(session.sessionId, editorData);
            }}
            type="primary"
            disabled={readonly}
          >
            Update
          </Button>
        </div>
      </div>
    );
  }

  createEditorData(wfc: any, session: any) {
    const getQualityFromAlignAccuracy = (acc: string) => {
      return acc.replace('Accuracy', '').toLowerCase();
    };
    const getQualityFromDenseCloudQuality = (qual: string) => {
      return qual.replace('Quality', '').toLowerCase();
    };

    const editorData = {
      name: session.name,
      enableAddPhotos: wfc.add_photos?.enabled || false,
      rtkImages: wfc.add_photos?.rtk_images || false,
      // align photos
      alignQuality: getQualityFromAlignAccuracy(
        wfc.align_photos?.accuracy || 'MediumAccuracy'
      ),
      boudingboxBuffer: wfc.align_photos?.bounding_box_buffer || undefined,
      keyPointLimit: wfc.align_photos?.keypoint_limit || 50000,
      tiePointLimit: wfc.align_photos?.tiepoint_limit || 10000,
      rollingShutter: wfc.align_photos?.rolling_shutter || false,
      fixedCalibration: wfc.align_photos?.fixed_calibration || false,
      adaptiveFitting: wfc.align_photos?.adaptive_fitting || false,
      fitBoundingBox: wfc.align_photos?.fit_bounding_box || true,
      // add_gcps
      coordinateUnits: wfc.add_gcps?.coordinate_units || 'METRES',
      noMarkers: wfc.add_gcps?.allow_no_markers || false,
      markerFilter: wfc.add_gcps?.marker_file
        ? { guid: wfc.add_gcps?.marker_file }
        : undefined,
      markerLocationAccuracy: wfc.add_gcps?.marker_location_accuracy || 0.001,
      // optimize
      markerAccuracy: wfc.optimize_camera?.marker_accuracy || 0.01,
      // split chuns
      enableSplitChunks: wfc.split_into_chunks?.enabled || false,
      // build dense cloud
      quality: getQualityFromDenseCloudQuality(
        wfc.build_dense_cloud?.quality || 'MediumQuality'
      ),
      // export dense cloud
      exportDenseCloud: wfc.export_dense_cloud?.enabled || false,
      // post process
      // TODO: other fields
      coordSystemDetails:
        wfc.post_processing?.coordinate_system_details?.guid || undefined,
      epsgCode: wfc.post_processing?.epsg_code || undefined,
      colorizationConfig: wfc.post_processing?.colorization_config || {
        colorization_type: 'single',
      },
      enableCameraCenterUpdate: wfc.correct_camera_centers?.enabled || false,
      uploadToMapbox: wfc.uploads?.upload_to_mapbox || false,
      generateReport: wfc.generate_report?.enabled || false,
      // virtual gcps
      maxError: wfc.virtual_gcp?.max_error || 0.05,
      minProjections: wfc.virtual_gcp?.min_projections || 5,
      // ODM stuff
      odmAlignFeatureQuality: (
        wfc.odm?.align_feature_quality || 'medium'
      ).replace('ultra', 'highest'),
      odmChunkSize: wfc.odm?.chunk_size || 100,
      odmFeatureQuality: (wfc.odm?.feature_quality || 'medium').replace(
        'ultra',
        'highest'
      ),
      odmUseGcps: !!wfc.odm?.use_gcps,
      odmFastOrthophoto: !!wfc.odm?.fast_orthophoto,
      odmExtraArgs: wfc.odm?.odm_extra_args || '',
      // end ODM stuff
    };

    return editorData;
  }

  handleCSDSelected(guid: string) {
    const { editorData } = this.state;
    const ed: any = editorData || {};

    ed.coordSystemDetails = guid;
    this.setState({ editorData: ed, showCSDModal: false });
  }

  handleMarkingFinished = () => {
    const { selectedSession } = this.state;

    this.showGCPModal(false);
    this.selectSession(selectedSession || '');
    this.loadArtifacts();
  };

  public render() {
    const { project } = this.props;
    const {
      loaded,
      showProgressModal,
      progressTitle,
      showGCPModal,
      selectedSession,
      sessions,
      sessionCostId,
      sessionOutputsId,
      stepLogsConfig,
      showCSDModal,
    } = this.state;

    if (!loaded) {
      return <div>Loading...</div>;
    }

    const session = sessions.find((s) => s.sessionId === selectedSession);

    return (
      <div className={styles.container}>
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
          <div className={styles.sessionList}>
            <div className={styles.sessionsTableWrapper}>
              {this.renderSessionsTable()}
            </div>
            {this.renderConfig()}
          </div>
          {this.renderGraph()}
        </div>
        <Modal
          title={null}
          visible={showProgressModal}
          afterClose={() => this.setState({ showProgressModal: undefined })}
          closable={false}
          footer={null}
        >
          {progressTitle}
        </Modal>
        <Modal
          visible={showGCPModal}
          onCancel={() => this.showGCPModal(false)}
          afterClose={() => this.showGCPModal(false)}
          closable
          footer={null}
          style={{ width: '100%' }}
          destroyOnClose
          width="100%"
        >
          <ODMGCPs
            session={session || {}}
            epsgCode={project.epsgCode}
            onMarkingFinished={this.handleMarkingFinished}
          />
        </Modal>
        <Modal
          title={`Cost of ${sessionCostId}`}
          visible={!!sessionCostId}
          onCancel={() => this.setState({ sessionCostId: undefined })}
          afterClose={() => this.setState({ sessionCostId: undefined })}
          closable
          footer={null}
          destroyOnClose
        >
          <SessionCost sessionId={sessionCostId || ''} />
        </Modal>
        <Modal
          title={`Outputs of ${sessionOutputsId}`}
          visible={!!sessionOutputsId}
          onCancel={() => this.setState({ sessionOutputsId: undefined })}
          afterClose={() => this.setState({ sessionOutputsId: undefined })}
          closable
          footer={null}
          destroyOnClose
        >
          <SessionOutputs sessionId={sessionOutputsId || ''} />
        </Modal>
        <Modal
          title={`Logs for ${stepLogsConfig?.sessionId} / ${stepLogsConfig?.stepId}`}
          visible={!!stepLogsConfig}
          onCancel={() => this.setState({ stepLogsConfig: undefined })}
          afterClose={() => this.setState({ stepLogsConfig: undefined })}
          closable
          footer={null}
          destroyOnClose
        >
          <StepLogsViewer
            sessionId={stepLogsConfig?.sessionId || ''}
            stepId={stepLogsConfig?.stepId || ''}
          />
        </Modal>
        <Modal
          title="Coordinate System Details for project"
          visible={!!showCSDModal}
          onCancel={() => this.setState({ showCSDModal: false })}
          afterClose={() => this.setState({ showCSDModal: false })}
          closable
          footer={null}
          destroyOnClose
        >
          <CoordinateSystemDetails
            projectId={project.id || ''}
            onCSDSelect={(guid: string) => {
              this.handleCSDSelected(guid);
            }}
          />
        </Modal>
      </div>
    );
  }
}

export default Process;
