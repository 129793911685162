import { useMemo, useReducer } from 'react';
import { EditLayerStyleRequest, LayerDescriptor } from 'src/api/mapStyle.types';

export interface EditLayerActions {
  setName: (name: string) => void;
  setSourceUrl: (url: string) => void;
  setZIndex: (zIndex: number) => void;
  setFillColor: (fillColor: string) => void;
  setStrokeColor: (strokeColor: string) => void;
  setStrokeWidth: (strokeWidth: number) => void;
  setIcon: (icon: string) => void;
  setFontFamily: (fontFamily: string) => void;
  setFontSize: (fontSize: number) => void;
  setFontColor: (fontColor: string) => void;
  setLabelOffset: (labelOffset: number) => void;
  setLabelPosition: (labelPosition: string) => void;
  setLabelFrequency: (labelFrequency: number) => void;
  setLabel: (label: string) => void;
}

export type EditLayerActionType =
  | 'set_name'
  | 'set_source_url'
  | 'set_z_index'
  | 'set_fill_color'
  | 'set_stroke_color'
  | 'set_stroke_width'
  | 'set_icon'
  | 'set_font_family'
  | 'set_font_size'
  | 'set_font_color'
  | 'set_label_offset'
  | 'set_label_position'
  | 'set_label_frequency'
  | 'set_label';

export interface EditLayerAction {
  type: EditLayerActionType;
  payload: unknown;
}

export const useEditLayerRequest = (
  layer: LayerDescriptor
): [EditLayerStyleRequest, EditLayerActions] => {
  const initialState: EditLayerStyleRequest = useMemo(() => {
    const { style } = layer;

    return {
      name: layer.name,
      // prevent unnecessary change in source url
      // sourceUrl: layer.url,
      zIndex: style?.z_index,
      fillColor: style?.fill_color,
      strokeColor: style?.stroke_color,
      strokeWidth: style?.stroke_width,
      icon: style?.icon,
      fontFamily: style?.font_family,
      fontSize: style?.font_size,
      fontColor: style?.font_color,
      labelOffset: style?.label_offset,
      labelPosition: style?.label_position,
      labelFrequency: style?.label_frequency,
      label: style?.label,
    };
  }, [layer]);

  const [state, dispatch] = useReducer(editLayerReducer, initialState);

  const actions: EditLayerActions = useMemo(() => {
    return {
      setName: (name) => dispatch({ type: 'set_name', payload: name }),
      setSourceUrl: (url) => dispatch({ type: 'set_source_url', payload: url }),
      setZIndex: (zIndex) => dispatch({ type: 'set_z_index', payload: zIndex }),
      setFillColor: (fillColor) =>
        dispatch({ type: 'set_fill_color', payload: fillColor }),
      setStrokeColor: (strokeColor) =>
        dispatch({ type: 'set_stroke_color', payload: strokeColor }),
      setStrokeWidth: (strokeWidth) =>
        dispatch({ type: 'set_stroke_width', payload: strokeWidth }),
      setIcon: (icon) => dispatch({ type: 'set_icon', payload: icon }),
      setFontFamily: (fontFamily) =>
        dispatch({ type: 'set_font_family', payload: fontFamily }),
      setFontSize: (fontSize) =>
        dispatch({ type: 'set_font_size', payload: fontSize }),
      setFontColor: (fontColor) =>
        dispatch({ type: 'set_font_color', payload: fontColor }),
      setLabelOffset: (labelOffset) =>
        dispatch({ type: 'set_label_offset', payload: labelOffset }),
      setLabelPosition: (labelPosition) =>
        dispatch({ type: 'set_label_position', payload: labelPosition }),
      setLabelFrequency: (labelFrequency) =>
        dispatch({ type: 'set_label_frequency', payload: labelFrequency }),
      setLabel: (label) => dispatch({ type: 'set_label', payload: label }),
    };
  }, [dispatch]);

  return [state, actions];
};

export const editLayerReducer = (
  state: EditLayerStyleRequest,
  action: EditLayerAction
): EditLayerStyleRequest => {
  switch (action.type) {
    case 'set_name':
      return { ...state, name: action.payload as string };
    case 'set_source_url':
      return { ...state, sourceUrl: action.payload as string };
    case 'set_z_index':
      return { ...state, zIndex: action.payload as number };
    case 'set_fill_color':
      return { ...state, fillColor: action.payload as string };
    case 'set_stroke_color':
      return { ...state, strokeColor: action.payload as string };
    case 'set_stroke_width':
      return { ...state, strokeWidth: action.payload as number };
    case 'set_icon':
      return { ...state, icon: action.payload as string };
    case 'set_font_family':
      return { ...state, fontFamily: action.payload as string };
    case 'set_font_size':
      return { ...state, fontSize: action.payload as number };
    case 'set_font_color':
      return { ...state, fontColor: action.payload as string };
    case 'set_label_offset':
      return { ...state, labelOffset: action.payload as number };
    case 'set_label_position':
      return { ...state, labelPosition: action.payload as string };
    case 'set_label_frequency':
      return { ...state, labelFrequency: action.payload as number };
    case 'set_label':
      return { ...state, label: action.payload as string };
    default:
      return { ...state };
  }
};
