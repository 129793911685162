import { Feature, Map } from 'ol';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Stroke, Style } from 'ol/style';
import TextStyle from 'ol/style/Text';
import * as React from 'react';

export interface Annotation {
  content: string;
  center: {
    x: number;
    y: number;
  };
}

export interface AnnotationData {
  annotations: Annotation[];
  orientation: 'top' | 'bottom' | 'left' | 'right';
}

interface IProps {
  olMap?: Map;
  annotationsData: AnnotationData;
}

interface IState {
  annotationLayer?: VectorLayer;
}

const ANNOTATION_Z_INDEX = 101;

export class AnnotationsControl extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { olMap, annotationsData } = this.props;

    this.renderAnnotations(olMap, annotationsData);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { olMap, annotationsData } = this.props;
    const { olMap: nextMap, annotationsData: nextData } = nextProps;

    if (olMap !== nextMap || annotationsData !== nextData) {
      this.renderAnnotations(nextMap, nextData);
    }
  }

  public componentWillUnmount() {
    const { olMap } = this.props;
    const { annotationLayer } = this.state;

    if (olMap && annotationLayer) {
      olMap.removeLayer(annotationLayer);
    }
  }

  private renderAnnotations(
    olMap: Map | undefined,
    annotationsData: AnnotationData | undefined
  ) {
    const { annotationLayer } = this.state;

    if (olMap) {
      if (annotationLayer) {
        olMap.removeLayer(annotationLayer);
      }

      const featureList = (annotationsData?.annotations || []).map(
        (a: Annotation) => {
          const { x, y } = a.center;
          const point = new Point(fromLonLat([x, y]));
          const feature = new Feature(point);

          feature.setProperties({
            data: a.content,
          });

          return feature;
        }
      );

      const source = new VectorSource({
        wrapX: false,
        features: featureList,
      });

      const layer = new VectorLayer({
        source,
        style: this.annotationStyleFunction,
        zIndex: ANNOTATION_Z_INDEX,
      });

      olMap.addLayer(layer);

      this.setState({
        // eslint-disable-next-line react/no-unused-state
        annotationLayer: layer,
      });
    }
  }

  private annotationStyleFunction = (feature: Feature) => {
    return new Style({
      text: this.annotationTextStyleFunction(feature),
    });
  };

  private annotationTextStyleFunction = (feature: Feature): TextStyle => {
    return new TextStyle({
      text: feature.getProperties()?.data || '',
      textBaseline: 'bottom',
      padding: [0, 0, 0, 10],
      font: 'bold 12px sans-serif',
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
    });
  };

  public render() {
    return <React.Fragment key="annotations-control" />;
  }
}
