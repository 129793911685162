import ApiBase from './ApiBase';
import { BASE_CAPI_URL } from '../constants/urls';

export default class User extends ApiBase {
  protected baseUrl: string = BASE_CAPI_URL;

  public updatePassword(
    newPassword: string,
    currentPassword: string
  ): Promise<any> {
    return this.patch('/v1/user/password', {
      newPassword,
      currentPassword,
    });
  }

  public getResetMail(email: string): Promise<any> {
    return this.post('/v1/user/reset/password/', {
      email,
    });
  }

  public resetPasswordWithCode(
    resetCode: string,
    password: string
  ): Promise<any> {
    return this.post('/v1/user/reset/password/change/', {
      resetCode,
      password,
    });
  }
}
