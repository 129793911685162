import { applyMiddleware, createStore } from 'redux';
import ReduxActionsPromise from 'redux-actions-promise';
import promiseMiddleware from 'redux-promise-middleware';
import reduxThunk from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import reducers from '../reducers/root';

const middleware = [
  reduxThunk,
  promiseMiddleware(),
  ReduxActionsPromise,
  loadingBarMiddleware({
    promiseTypeSuffixes: [
      'PENDING|STARTED',
      'FULFILLED|SUCCESS',
      'REJECTED|FAILED',
    ],
  }),
];

const enhancer = applyMiddleware(...middleware);

export default function configureStore() {
  return createStore(reducers, enhancer);
}
