import {
  TerrainMapsActionsNsSetDtm,
  TerrainMapsActionsSetDtmTypes,
  TerrainMapsActionsActionSetDtmTypes,
  TerrainMapsActionsNsSetView,
  TerrainMapsActionsActionSetViewTypes,
  TerrainMapsActionsSetViewTypes,
  TerrainMapsActionsActionSetDsmsTypes,
  TerrainMapsActionsNsSetDsms,
  TerrainMapsActionsSetDsmsTypes,
} from '../../shapes/terrainMaps';
import { DSMInfo, DTM } from '../../api/terrainMaps.types';
import { View } from '../../api/views.types';

export const SET_DTM: TerrainMapsActionsNsSetDtm = 'SET_DTM';

export const SET_VIEW: TerrainMapsActionsNsSetView = 'SET_VIEW';

export const SET_DSMS: TerrainMapsActionsNsSetDsms = 'SET_DSMS';

export const actionSetDtms: TerrainMapsActionsActionSetDtmTypes = (
  data?: DTM
): TerrainMapsActionsSetDtmTypes => {
  return {
    type: SET_DTM,
    payload: data,
  };
};

export const actionSetView: TerrainMapsActionsActionSetViewTypes = (
  data?: View
): TerrainMapsActionsSetViewTypes => {
  return {
    type: SET_VIEW,
    payload: data,
  };
};

export const actionSetDsms: TerrainMapsActionsActionSetDsmsTypes = (
  data?: DSMInfo[]
): TerrainMapsActionsSetDsmsTypes => {
  return {
    type: SET_DSMS,
    payload: data,
  };
};
