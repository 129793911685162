import { connect } from 'react-redux';
import {
  SnackbarStatetoPropsTypes,
  SnackbarDispatchToPropsTypes,
} from '../../shapes/snackbar';
import Snackbar from './Snackbar';
import { actionHideSnackbar } from '../../redux/actions/snackbar';

const mapStateToProps = (state: any): SnackbarStatetoPropsTypes => {
  return {
    snackbars: state.snackbar.snackbars,
  };
};

const mapDispatchToProps = (dispatch: any): SnackbarDispatchToPropsTypes => {
  return {
    dispatchHideSnackbar: (args) => dispatch(actionHideSnackbar(args)),
  };
};

const SnackbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Snackbar);

export default SnackbarContainer;
