// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SplitViewSelector_container__EomG3{width:100px;position:absolute;left:50px;top:10px;z-index:20;display:grid;grid-template-columns:1fr 1fr;grid-gap:10px}.SplitViewSelector_container__EomG3 .Dropdown-control{background:#49494d;min-width:140px;border-color:rgba(204,204,204,.2);color:#fff}.SplitViewSelector_container__EomG3 .Dropdown-option,.SplitViewSelector_container__EomG3 .is-selected{color:#fff}", "",{"version":3,"sources":["webpack://./src/components/SplitViewSelector/index.module.scss"],"names":[],"mappings":"AAEA,oCACE,WAAA,CACA,iBAAA,CACA,SAAA,CACA,QAAA,CACA,UAAA,CACA,YAAA,CACA,6BAAA,CACA,aAAA,CAIE,sDACE,kBAAA,CACA,eAAA,CACA,iCAAA,CACA,UAAA,CAGF,sGAEE,UAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  width: 100px;\n  position: absolute;\n  left: 50px;\n  top: 10px;\n  z-index: 20;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-gap: 10px;\n\n  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */\n  & :global {\n    & .Dropdown-control {\n      background: #49494d;\n      min-width: 140px;\n      border-color: rgba(204, 204, 204, 0.2);\n      color: #fff;\n    }\n\n    & .Dropdown-option,\n    & .is-selected {\n      color: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SplitViewSelector_container__EomG3"
};
export default ___CSS_LOADER_EXPORT___;
