import Papa from 'papaparse';

/**
 * @param {Array} csvData
 * @param {Object} config
 * @private
 */

const trim = (s) => (s || '').toLowerCase().replace(/^\s+|\s+$/g, '');

function _getObjArrayFromCsv(csvData) {
  const header = csvData[0];
  const csvObjArray = [];

  csvData.slice(1).forEach((row) => {
    const rowObj = {};
    let includeRow = false;
    const re = new RegExp('[a-z0-9A-Z]');

    for (let i = 0; i < header.length; i += 1) {
      const fieldName = trim(header[i]);

      if (row[i] !== undefined && re.test(row[i])) {
        includeRow = true;
      }

      rowObj[fieldName] = row[i];
    }

    if (includeRow) {
      csvObjArray.push(rowObj);
    }
  });

  return csvObjArray;
}

function _prepareDataAndValidateFile(csvData, config) {
  const file = {
    dataValid: true,
    errorMessage: '',
    data: [],
    warningMessage: '',
  };

  const csvObjArray = _getObjArrayFromCsv(csvData);

  csvObjArray.forEach((row, index) => {
    const curatedData = {};

    Object.keys(config).forEach((key) => {
      let value;
      let actualKey = key;

      if (Object.prototype.hasOwnProperty.call(row, key)) {
        value = row[key];
      } else if (
        Object.prototype.hasOwnProperty.call(row, config[key].alternateName)
      ) {
        value = row[config[key].alternateName];
        actualKey = config[key].alternateName;
      }

      if (config[key].isValid(value)) {
        curatedData[key] = value;
      } else if (config[key].required) {
        if (file.dataValid) {
          file.errorMessage = `Invalid value for '${
            config[key].displayLabel
          }' in row ${index + 1}`;
          file.dataValid = false;
        }
      } else if (
        Object.prototype.hasOwnProperty.call(row, actualKey) &&
        !file.warningMessage
      ) {
        file.warningMessage = `Invalid value for '${
          config[key].displayLabel
        }' in row ${index + 1}`;
      }
    });

    file.data.push(curatedData);
  });

  return file;
}

export default function CSVFileValidator(csvFile, config) {
  return new Promise((resolve, reject) => {
    Papa.parse(csvFile, {
      complete: (results) => {
        resolve(_prepareDataAndValidateFile(results.data, config));
      },
      error: (error, file) => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ error, file });
      },
    });
  });
}
