import * as React from 'react';
import { Radio, Typography, Popconfirm, message } from 'antd';
import { Button } from '../../Button';
import ViewsV2Apis from '../../../api/viewsV2';
import { undefinedOrNull } from '../../../utils/functs';
import { log } from '../../../utils/log';
import style from './index.module.scss';
import styles from './ViewVectors.module.scss';
import { PotreeVectorPreview } from './PotreeVectorPreview';
import ModalNotification from '../../ModalNotification/ModalNotification';

declare const Potree: any;

type VectorMode = 'create' | 'upload';

interface IProps {
  view: any;
  onViewFragsUpdate: (frags: any) => void;
  onUpdatingStatusChanged: (updating: boolean) => void;
}

interface IState {
  viewApis: ViewsV2Apis;
  vectors: any[];
  selectedVectorFile: File | null;
  updating: boolean;
  editStyle: VectorStyle | null;
  showMeasureImportError: boolean;
  mode: VectorMode;
  uploadDisabled: boolean;
}

interface VectorStyle {
  name: string;
  color: string;
  lineWidth: number;
  pointSize: number;
}

const { Paragraph } = Typography;

export class ViewVectors extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      viewApis: new ViewsV2Apis(),
      vectors: [],
      selectedVectorFile: null,
      updating: false,
      editStyle: null,
      showMeasureImportError: false,
      mode: 'upload',
      uploadDisabled: false,
    };
  }

  componentDidMount() {
    const { view } = this.props;

    if (view) {
      this.getVectors();
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { view } = this.props;
    const { mode, selectedVectorFile } = this.state;
    const { mode: prevMode } = prevState;

    if (prevProps.view !== view) {
      if (view) {
        this.getVectors();
      }
    }

    // when we switch from upload to create
    if (prevMode !== mode && prevMode === 'upload') {
      // eslint-disable-next-line
      this.setState({ selectedVectorFile: null }, () => {
        this.updateViewFrag();
      });
    }

    // when we finalize vector file
    if (
      selectedVectorFile !== prevState.selectedVectorFile &&
      !undefinedOrNull(selectedVectorFile) &&
      mode === 'create'
    ) {
      // eslint-disable-next-line
      this.setState({ uploadDisabled: true });
    }
  }

  public componentWillUnmount() {
    const { onViewFragsUpdate } = this.props;

    onViewFragsUpdate(undefined);
  }

  private getVectors() {
    this.setState({ vectors: [] }, () => {
      const { viewApis } = this.state;
      const { view } = this.props;

      viewApis.getViewVectors(view.id).then((res) => {
        const { data, error } = res;

        if (error) {
          log.error(error);
        } else {
          this.setState({ vectors: data });
        }
      });
    });
  }

  private uploadVectorFile() {
    const { selectedVectorFile, viewApis, editStyle } = this.state;
    const { view, onUpdatingStatusChanged } = this.props;

    if (selectedVectorFile) {
      const reader = new FileReader();
      const name = (selectedVectorFile.name || '').toLowerCase();
      const type = name.endsWith('.dxf') ? 'dxf' : 'geojson';

      reader.onloadend = () => {
        if (reader.result) {
          const b64 = (reader.result as any).replace(/^data:.+;base64,/, '');

          this.setState({ updating: true }, () => {
            onUpdatingStatusChanged(true);
          });

          viewApis
            .uploadViewVectorFile(view.id, {
              ...editStyle,
              type,
              base64Contents: b64,
            })
            .then((res) => {
              this.setState(
                {
                  updating: false,
                  selectedVectorFile: null,
                  uploadDisabled: false,
                },
                () => {
                  onUpdatingStatusChanged(false);
                  this.updateViewFrag();
                }
              );
              const { error } = res;

              if (error) {
                log.error(error);
              } else {
                this.getVectors();
              }
            });
        }
      };

      reader.readAsDataURL(selectedVectorFile);
    }
  }

  private deleteVectorFile(fileId: string) {
    const { viewApis } = this.state;
    const { onUpdatingStatusChanged } = this.props;

    this.setState({ updating: true }, () => {
      onUpdatingStatusChanged(true);
    });

    viewApis.deleteViewVectorFile(fileId).then((res) => {
      this.setState({ updating: false, selectedVectorFile: null }, () => {
        onUpdatingStatusChanged(false);
        this.updateViewFrag();
      });
      const { error } = res;

      if (error) {
        log.error(error);
      } else {
        this.getVectors();
      }
    });
  }

  private updateViewFrag() {
    const { editStyle, selectedVectorFile } = this.state;
    const { onViewFragsUpdate } = this.props;

    const style: any = { ...(editStyle || {}) };

    if (selectedVectorFile) {
      onViewFragsUpdate(
        <PotreeVectorPreview
          key="0"
          vectorFile={selectedVectorFile}
          style={style}
        />
      );
    } else {
      onViewFragsUpdate(undefined);
    }
  }

  private loadMeasurement() {
    const potreeViewer: any = (window as any).viewer;

    if (potreeViewer) {
      const { scene } = potreeViewer;
      const measurements = scene.measurements || [];
      const features = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const measure of measurements) {
        const fs = Potree.GeoJSONExporter.measurementToFeatures(measure);

        // eslint-disable-next-line no-restricted-syntax
        for (const f of fs || []) {
          if (f?.properties?.name) {
            features.push(f);
          }
        }
      }

      if (features && features.length) {
        const geoJson = {
          type: 'FeatureCollection',
          features,
        };

        const blob = new Blob([JSON.stringify(geoJson)], {
          type: 'data:application/octet-stream',
        });
        const file = new File([blob], 'measurement.json', {
          lastModified: new Date(0) as any,
          type: 'application/octet-stream',
        });

        this.selectFile(file, 'Measurement');
        potreeViewer.scene.removeAllMeasurements();
      } else {
        this.setState({ showMeasureImportError: true });
      }
    }
  }

  private selectFile(f: File, name: string | null = null) {
    this.setState(
      {
        selectedVectorFile: f,
        editStyle: {
          name: name || f.name,
          color: '#ff0000',
          lineWidth: 2,
          pointSize: 2,
        },
      },
      () => {
        this.updateViewFrag();
      }
    );
  }

  onModeChange = (e: any) => {
    this.setState({ mode: e.target.value });
  };

  confirm = () => {
    this.setState({ selectedVectorFile: null, uploadDisabled: false }, () => {
      this.updateViewFrag();
      message.success({
        content: 'Deleted successfully!',
        duration: 5,
        style: {
          backgroundColor: 'black',
          color: 'white',
        },
      });
    });
  };

  cancel = () => {
    console.error('Cancelled upload!');
  };

  render() {
    const {
      selectedVectorFile,
      mode,
      vectors,
      updating,
      editStyle,
      showMeasureImportError,
      uploadDisabled,
    } = this.state;

    const noMeasurementMessage =
      'There are no measurements which could be used. Only measurements of Point,LineString and Polygon types can be imported.';

    return (
      <>
        <div className={styles.vectorOptions}>
          <Radio.Group onChange={this.onModeChange} value={mode}>
            <Radio
              name="mode"
              value="upload"
              disabled={uploadDisabled}
              style={{ fontSize: 'small', marginRight: '5em' }}
            >
              Upload
            </Radio>
            <Radio name="mode" value="create" style={{ fontSize: 'small' }}>
              Create
            </Radio>
          </Radio.Group>
          {mode === 'create' && !selectedVectorFile && (
            <>
              <Paragraph
                style={{ marginTop: '1em', fontSize: 'smaller' }}
                className={styles.expandText}
                ellipsis={{ rows: 2, expandable: true }}
              >
                To create a vector file with points, lines and polygons, use the
                measurement tools in the &quot;Tools&quot; section of the Burger
                Menu (3D preview).
                <br />
                <br />
                Other Vector files that are visible, can be toggled ON/OFF using
                the controls in the &quot;Scene&quot; section of the Burger
                Menu.
              </Paragraph>
              <label className={style.inputField}>
                <button
                  className="ant-btn ant-btn-primary"
                  onClick={(_) => this.loadMeasurement()}
                >
                  Finalize Vector File
                </button>
              </label>
            </>
          )}
          {mode === 'upload' && !selectedVectorFile && (
            <>
              <label className={style.inputField}>
                <input
                  type="file"
                  accept=".json,.geojson,.dxf"
                  onChange={(e) => {
                    const { files } = e?.target || {};

                    if (files && files.length > 0) {
                      const file = files[0];

                      if (e.target) {
                        e.target.value = '';
                      }

                      this.selectFile(file);
                    }
                  }}
                />
              </label>
              <p style={{ fontSize: 'smaller' }}>
                Only GeoJSON and DXF formats are supported
              </p>
            </>
          )}
        </div>
        {selectedVectorFile && (
          <div className={styles.measureForm}>
            {mode === 'upload' && (
              <p style={{ fontSize: 'smaller', textAlign: 'center' }}>
                Vector file uploaded into 3-D preview
              </p>
            )}
            <label className={style.inputField}>
              <span className={style.inputLabel}>Name</span>
              <input
                value={editStyle?.name || ''}
                onChange={(e) => {
                  const style: any = editStyle;

                  style.name = e.target.value || '';
                  this.setState({
                    editStyle: style,
                  });
                }}
              />
            </label>
            <div className={styles.formControls}>
              <label className={style.inputField}>
                <span className={style.inputLabel}>Color</span>
                <input
                  type="color"
                  value={editStyle?.color || '#ff0000'}
                  onChange={(e) => {
                    const color = e.target.value;
                    const style: any = editStyle;

                    style.color = color;

                    this.setState({ editStyle: style }, () =>
                      this.updateViewFrag()
                    );
                  }}
                />
              </label>
              <label className={style.inputField}>
                <span className={style.inputLabel}>Line Width</span>
                <input
                  type="number"
                  step="1"
                  min="1"
                  max="10"
                  value={editStyle?.lineWidth || 2}
                  style={{ height: '1.95em' }}
                  onChange={(e) => {
                    const width = e.target.value;
                    const style: any = editStyle;

                    style.lineWidth = width;

                    this.setState({ editStyle: style }, () =>
                      this.updateViewFrag()
                    );
                  }}
                />
              </label>
              <label className={style.inputField}>
                <span className={style.inputLabel}>Point Size</span>
                <input
                  type="number"
                  step="1"
                  min="1"
                  max="10"
                  value={editStyle?.pointSize || 2}
                  style={{ height: '1.95em' }}
                  onChange={(e) => {
                    const size = e.target.value;
                    const style: any = editStyle;

                    style.pointSize = size;

                    this.setState({ editStyle: style }, () =>
                      this.updateViewFrag()
                    );
                  }}
                />
              </label>
            </div>
            <div className={styles.formActions}>
              <div>
                <button
                  className="ant-btn ant-btn-primary"
                  onClick={(_) => {
                    this.uploadVectorFile();
                  }}
                  disabled={updating}
                >
                  PUBLISH
                </button>
                <Popconfirm
                  title={
                    mode === 'create'
                      ? 'Are you sure you want to cancel the file creation process?'
                      : 'Are you sure you want to cancel the upload?'
                  }
                  onConfirm={this.confirm}
                  onCancel={this.cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button text="Cancel" type="secondary" />
                </Popconfirm>
              </div>
            </div>
          </div>
        )}
        <div style={{ borderTop: '1px solid red' }}>
          <h3 style={{ textAlign: 'center', marginTop: '0.5em' }}>
            Published Vector Files
          </h3>
          {(!vectors || !vectors.length) && (
            <span className={style.inputLabel}>
              No vector files uploaded yet
            </span>
          )}
          {(vectors || []).map((f: any) => {
            return (
              <div
                key={f.id}
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <p style={{ flexGrow: 1 }}>{f.name}</p>
                <Popconfirm
                  placement="left"
                  title={`Are you sure you want to delete "${f.name}" file?`}
                  onConfirm={() => f.id && this.deleteVectorFile(f.id)}
                  onCancel={this.cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <i
                    className="fa fa-trash"
                    style={{ paddingRight: '1em', cursor: 'pointer' }}
                  />
                </Popconfirm>
              </div>
            );
          })}
        </div>
        {showMeasureImportError && (
          <ModalNotification
            notificationTitle="Import Error"
            notificationBody={noMeasurementMessage}
            shownotificationModal
            handleModalClose={() =>
              this.setState({ showMeasureImportError: false })
            }
            error=""
            okButtonTitle="OK"
          />
        )}
      </>
    );
  }
}
