import rewind from '@mapbox/geojson-rewind';
import * as React from 'react';
import { isNullOrUndefined } from 'util';
import { MeasurementSubType, MeasurementType } from '../../index.types';
import LSE from './PlaneFit';

interface IProps {
  viewer?: any;
  measurementType?: MeasurementType;
  volumeType?: MeasurementSubType;
  planeElevation?: number;
  geoJson?: any;
  unitsPerMeter: number;
  onFeatureCreate: (geoJson: { features: any[] }) => void;
  onFeatureEdit: (geoJson: { features: any[] }) => void;
  onElevationChange?: (elevations: number[]) => void;
  onCancel?: () => void;
}

interface IState {
  measure?: any;
  profile?: any;
  volumeType?: MeasurementSubType;
  planeElevation?: number;
}

declare let Potree: any;
declare let THREE: any;
declare let proj4: any;
declare let $: any;

const PROJ_LAT_LNG =
  '+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees +no_defs';

export default class PotreeDrawControl extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { viewer } = this.props;

    if (!viewer) return;

    this.showMeasurement();
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      viewer: prevViewer,
      geoJson: prevGeoJson,
      measurementType: prevType,
    } = prevProps;
    const { viewer, geoJson, measurementType: type } = this.props;

    if (viewer !== prevViewer || prevGeoJson !== geoJson || type !== prevType) {
      this.showMeasurement();
    }
  }

  public componentWillUnmount() {
    const { viewer } = this.props;
    const { measure, profile } = this.state;

    if (!viewer) return;

    if (measure) {
      viewer.scene.removeMeasurement(measure);
    }

    if (profile) {
      viewer.scene.removeProfile(profile);
      viewer.profileWindow.hide();
      viewer.profileWindowController.reset();
    }
  }

  public setPlaneElevation = (planeElevation: number | undefined) => {
    this.setState({ planeElevation }, () => {
      this.syncPlane();
    });
  };

  public setVolumeType = (volumeType?: MeasurementSubType) => {
    this.setState({ volumeType }, () => {
      this.syncPlane();
    });
  };

  private showMeasurement = () => {
    const { geoJson, viewer } = this.props;
    const { measure, profile } = this.state;

    if (viewer) {
      if (measure) {
        viewer.scene.removeMeasurement(measure);
      }

      if (profile) {
        viewer.scene.removeProfile(profile);
        viewer.profileWindow.hide();
        viewer.profileWindowController.reset();
      }
    }

    if (geoJson) {
      this.insertStaticMeasure();
    } else {
      this.setupDrawControl();
    }
  };

  private insertStaticMeasure = () => {
    const { geoJson, viewer, measurementType, unitsPerMeter } = this.props;

    if (
      !viewer ||
      !geoJson ||
      !viewer?.scene?.pointclouds?.length ||
      !viewer?.scene?.pointclouds[0]?.projection
    )
      return;

    const { projection } = viewer.scene.pointclouds[0];
    const points = [];

    if (geoJson?.features && geoJson?.features?.length) {
      switch (measurementType) {
        case 'volume': {
          const feature = geoJson?.features[0];
          const coords = feature?.geometry?.coordinates[0] || [];

          // eslint-disable-next-line no-restricted-syntax
          for (const c of coords) {
            // z is not transformed by proj4, do it here
            const p = [c[0], c[1], c[2] * unitsPerMeter];
            const convertedPoint = proj4(PROJ_LAT_LNG, projection, p);

            points.push(convertedPoint);
          }

          break;
        }

        case 'elevation': {
          // eslint-disable-next-line no-restricted-syntax
          for (const f of geoJson?.features || []) {
            const c = f?.geometry?.coordinates;
            // z is not transformed by proj4, do it here
            const p = [c[0], c[1], c[2] * unitsPerMeter];
            const convertedPoint = proj4(PROJ_LAT_LNG, projection, p);

            points.push(convertedPoint);
          }

          break;
        }

        case 'profile': {
          // TODO: Adding static profile measurement will require careful handling of
          //       the profile close event.
          break;
        }

        default:
          break;
      }
    }

    const measure = this.createMeasureObject();

    viewer.scene.scene.add(measure);
    viewer.scene.addMeasurement(measure);

    // eslint-disable-next-line no-restricted-syntax
    for (const p of points) {
      measure.addMarker(p);
    }

    this.setState({ measure }, () => {
      this.syncPlane();
      this.updateMeasure();
    });
  };

  private setupDrawControl = () => {
    const { viewer, measurementType } = this.props;

    if (measurementType === 'profile') {
      this.startProfile();

      return;
    }

    const { domElement } = viewer.renderer;
    const measure = this.createMeasureObject();

    const measureTool = viewer.measuringTool;

    measureTool.dispatchEvent({
      type: 'start_inserting_measurement',
      measure,
    });

    viewer.scene.scene.add(measure);

    let adding = true;

    const cancel = {
      removeLastMarker: measure.maxMarkers > 3,
      callback: (_e?: any) => {},
    };

    const insertionCallback = (e: any) => {
      if (e.button === THREE.MOUSE.LEFT) {
        measure.addMarker(
          measure.points[measure.points.length - 1].position.clone()
        );

        if (measure.points.length >= measure.maxMarkers) {
          cancel.callback();
        }

        viewer.inputHandler.startDragging(
          measure.spheres[measure.spheres.length - 1]
        );
      } else if (e.button === THREE.MOUSE.RIGHT) {
        cancel.callback();
      }
    };

    cancel.callback = (_e: any) => {
      if (cancel.removeLastMarker) {
        measure.removeMarker(measure.points.length - 1);
      }

      domElement.removeEventListener('mouseup', insertionCallback, false);
      viewer.removeEventListener('cancel_insertions', cancel.callback);
      adding = false;

      const geoJson = this.createGeoJson(measure);
      const { onFeatureCreate } = this.props;

      this.updateMeasure();
      onFeatureCreate(geoJson as any);
      this.elevationsChanged();
      this.syncPlane();
    };

    if (measure.maxMarkers > 1) {
      viewer.addEventListener('cancel_insertions', cancel.callback);
      domElement.addEventListener('mouseup', insertionCallback, false);
    }

    measure.addEventListener('marker_added', (e: any) => {
      const { sphere, measurement } = e;

      sphere.addEventListener('drop', (_: any) => {
        if (!adding && measurement.spheres.indexOf(sphere) > -1) {
          const geoJson = this.createGeoJson(measure);
          const { onFeatureEdit } = this.props;

          this.updateMeasure();
          onFeatureEdit(geoJson as any);
          this.elevationsChanged();
          this.syncPlane();
        }
      });
    });

    measure.addMarker(new THREE.Vector3(0, 0, 0));
    viewer.inputHandler.startDragging(
      measure.spheres[measure.spheres.length - 1]
    );

    viewer.scene.addMeasurement(measure);

    this.setState({ measure });
  };

  private startProfile() {
    const { viewer } = this.props;
    const { domElement } = viewer.renderer;
    const { profileTool } = viewer;

    const profile = new Potree.Profile();

    profile.name = '';

    profileTool.dispatchEvent({
      type: 'start_inserting_profile',
      profile,
    });

    viewer.scene.scene.add(profile);

    const cancel: any = {
      callback: null,
    };

    const insertionCallback = (e: any) => {
      if (e.button === THREE.MOUSE.LEFT) {
        if (profile.points.length <= 1) {
          const camera = viewer.scene.getActiveCamera();
          const distance = camera.position.distanceTo(profile.points[0]);
          const clientSize = viewer.renderer.getSize(new THREE.Vector2());
          const pr = Potree.Utils.projectedRadius(
            1,
            camera,
            distance,
            clientSize.width,
            clientSize.height
          );
          const width = 10 / pr;

          profile.setWidth(width);
        }

        profile.addMarker(profile.points[profile.points.length - 1].clone());
        viewer.inputHandler.startDragging(
          profile.spheres[profile.spheres.length - 1]
        );
      } else if (e.button === THREE.MOUSE.RIGHT) {
        cancel.callback();
      }
    };

    cancel.callback = () => {
      profile.removeMarker(profile.points.length - 1);
      domElement.removeEventListener('mouseup', insertionCallback, false);
      viewer.removeEventListener('cancel_insertions', cancel.callback);
      viewer.profileWindow.show();
      viewer.profileWindow.addEventListener('hide', this.hideProfile, false);
      viewer.profileWindowController.setProfile(profile);
    };

    viewer.addEventListener('cancel_insertions', cancel.callback);
    domElement.addEventListener('mouseup', insertionCallback, false);

    profile.addMarker(new THREE.Vector3(0, 0, 0));
    viewer.inputHandler.startDragging(
      profile.spheres[profile.spheres.length - 1]
    );

    viewer.scene.addProfile(profile);

    //

    const measurementsRoot = $('#jstree_scene')
      .jstree()
      .get_json('measurements');
    const jsonNode = measurementsRoot.children.find(
      (child: any) => child.data.uuid === profile.uuid
    );

    $.jstree.reference(jsonNode.id).deselect_all();
    $.jstree.reference(jsonNode.id).select_node(jsonNode.id);

    this.setState({ profile });
  }

  private hideProfile = (event: { target: any }) => {
    const { target: ProfileWindow } = event;
    const { onCancel } = this.props;

    ProfileWindow.removeEventListener('hide', this.hideProfile);

    if (onCancel) {
      onCancel();
    }
  };

  private createMeasureObject = () => {
    const { measurementType } = this.props;
    const measure = new Potree.Measure();

    measure.showDistances = false;
    measure.showArea = false;
    measure.showAngles = false;
    measure.showCoordinates = measurementType !== 'elevation';
    measure.showHeight = false;
    measure.showCircle = false;
    measure.showAzimuth = false;
    measure.showEdges = measurementType !== 'elevation';
    measure.closed = measurementType === 'volume' || measurementType === 'area';
    measure.maxMarkers = Infinity;

    measure.name = '';
    measure.basePlane = null;

    measure.renderOrder = -10;

    return measure;
  };

  private updateMeasure = () => {
    const { measure } = this.state;
    const { measurementType } = this.props;

    if (measurementType === 'elevation') {
      for (let i = 0; i < measure.points.length; i += 1) {
        const coordinateLabel = measure.coordinateLabels[i];
        const msg = `P${i + 1}`;

        coordinateLabel.setText(msg);
        coordinateLabel.visible = true;
      }
    }

    if (measurementType === 'volume') {
      for (let i = 0; i < measure.points.length; i += 1) {
        const coordinateLabel = measure.coordinateLabels[i];
        const msg = `${measure.points[i].position.z.toFixed(2)}`;

        coordinateLabel.setText(msg);
        coordinateLabel.visible = true;
      }
    }
  };

  private elevationsChanged = () => {
    const { measure } = this.state;
    const { measurementType, onElevationChange, unitsPerMeter } = this.props;

    if (measurementType === 'volume') {
      const elevs = [];

      for (let i = 0; i < measure.points.length; i += 1) {
        elevs.push(measure.points[i].position.z / unitsPerMeter);
      }

      if (onElevationChange) {
        onElevationChange(elevs);
      }
    }
  };

  private createGeoJson = (measure: any) => {
    const { viewer, measurementType, unitsPerMeter } = this.props;

    if (
      !viewer ||
      !measure ||
      !viewer?.scene?.pointclouds?.length ||
      !viewer?.scene?.pointclouds[0]?.projection
    )
      return;

    const { projection } = viewer.scene.pointclouds[0];

    const coordinates = (measure?.points || []).map((p: any) => {
      const pos = p.position;
      // z is not automatically converted to meters, do it explicitly
      const point = [pos.x, pos.y, pos.z / unitsPerMeter];
      const convertedPoint = proj4(projection, PROJ_LAT_LNG, point);
      const rawPoint = [
        pos.x / unitsPerMeter,
        pos.y / unitsPerMeter,
        pos.z / unitsPerMeter,
      ];

      return {
        convertedPoint,
        rawPoint,
      };
    });

    let geoJson: any;

    if (measurementType === 'elevation') {
      geoJson = {
        features: coordinates.map((c: any) => {
          return {
            type: 'Feature',
            properties: {
              raw: {
                type: 'Point',
                coordinates: c.rawPoint,
              },
            },
            geometry: {
              type: 'Point',
              coordinates: c.convertedPoint,
            },
          };
        }),
      };
    } else {
      geoJson = {
        features: [
          {
            type: 'Feature',
            properties: {
              raw: {
                type: 'Polygon',
                coordinates: [coordinates.map((c: any) => c.rawPoint)],
              },
            },
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates.map((c: any) => c.convertedPoint)],
            },
          },
        ],
      };
    }

    return rewind(geoJson, false);
  };

  private syncPlane = () => {
    const { volumeType: volumeTypeProp, planeElevation: planeElevationProp } =
      this.props;
    const {
      measure,
      volumeType: volumeTypeState,
      planeElevation: planeElevationState,
    } = this.state;

    if (measure) {
      if (measure.basePlane) {
        measure.remove(measure.basePlane);
        measure.basePlane = null;
      }

      const vt = volumeTypeState || volumeTypeProp || undefined;
      const pe = planeElevationState || planeElevationProp || undefined;
      const tl = { x: Number.POSITIVE_INFINITY, y: Number.POSITIVE_INFINITY };
      const br = { x: Number.NEGATIVE_INFINITY, y: Number.NEGATIVE_INFINITY };

      // eslint-disable-next-line no-restricted-syntax
      for (const point of measure.points) {
        const p = point.position;

        if (p.x < tl.x) {
          tl.x = p.x;
        }

        if (p.y < tl.y) {
          tl.y = p.y;
        }

        if (p.x > br.x) {
          br.x = p.x;
        }

        if (p.y > br.y) {
          br.y = p.y;
        }
      }

      const vertices: any[] = [];

      if (vt === 'FlatPlane' && !isNullOrUndefined(pe)) {
        vertices.push({ x: tl.x, y: tl.y, z: pe });
        vertices.push({ x: tl.x, y: br.y, z: pe });
        vertices.push({ x: br.x, y: br.y, z: pe });
        vertices.push({ x: br.x, y: tl.y, z: pe });
      } else if (vt === 'BestFitPlane') {
        const points = measure.points.map((p: any) => p.position);
        const plane = LSE(points);

        const getZ = (x: any, y: any) => {
          return plane.A * x + plane.B * y + plane.C;
        };

        vertices.push({ x: tl.x, y: tl.y, z: getZ(tl.x, tl.y) });
        vertices.push({ x: tl.x, y: br.y, z: getZ(tl.x, br.y) });
        vertices.push({ x: br.x, y: br.y, z: getZ(br.x, br.y) });
        vertices.push({ x: br.x, y: tl.y, z: getZ(br.x, tl.y) });
      }

      if (vertices.length > 3) {
        // create a plane, add it as child of measure (its a THREE.Object3D) and set it as measure.basePlane to track
        const geometry = new THREE.Geometry();

        geometry.vertices.push(
          new THREE.Vector3(vertices[0].x, vertices[0].y, vertices[0].z), // vertex0
          new THREE.Vector3(vertices[1].x, vertices[1].y, vertices[1].z), // 1
          new THREE.Vector3(vertices[2].x, vertices[2].y, vertices[2].z), // 2
          new THREE.Vector3(vertices[3].x, vertices[3].y, vertices[3].z) // 3
        );

        geometry.faces.push(
          new THREE.Face3(0, 1, 2), // use vertices of rank 2,1,0
          new THREE.Face3(2, 3, 0) // vertices[3],1,2...
        );

        const material = new THREE.MeshBasicMaterial({
          color: 0x00ff00,
          side: THREE.DoubleSide,
          opacity: 0.4,
          transparent: true,
          depthTest: true,
          depthWrite: true,
        });
        const mesh = new THREE.Mesh(geometry, material);

        // mesh.position.z = planeElevation;

        measure.basePlane = mesh;
        measure.add(mesh);
      }
    }
  };

  public render() {
    return <></>;
  }
}
