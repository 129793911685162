import autobind from 'autobind-decorator';
import moment from 'moment';
import * as React from 'react';
import * as ControlBox from '../ControlBox';
import Slider from '../Slider';
import Dropdown, { Option as DropDownOption } from '../DropDown/DropDown';

import style from './CompareControlBox.module.scss';
import { undefinedOrNull } from '../../utils/functs';
import { ViewControlsV2TypeTypes } from '../ViewControlsV2/index.types';

interface IProps {
  intialOpacityLevel?: number;
  views?: any[];
  onOpacityChange?: (opacity: number) => void;
  itemType?: ViewControlsV2TypeTypes;
  onClose?: (itemType: ViewControlsV2TypeTypes) => void;
  history?: any;
  value?: string;
}

class CompareControlBox extends React.Component<IProps> {
  private isClosed: boolean;

  public componentDidMount(): void {
    this.isClosed = false;
  }

  @autobind
  private renderHeader(): React.ReactNode {
    return (
      <React.Fragment>
        <ControlBox.Title title="Compare" />
        <ControlBox.Icon onClick={this.handleClose} name="close" />
      </React.Fragment>
    );
  }

  @autobind
  private handleDesignSelect(option: DropDownOption): void {
    const { history } = this.props;

    history.push(`?compareViewId=${option.value}`);
  }

  @autobind
  private handleClose(): void {
    const { onClose, history, value, itemType } = this.props;

    if (this.isClosed) {
      return;
    }

    if (!undefinedOrNull(value)) {
      history.push(`?`);
    }

    if (onClose && !undefinedOrNull(itemType)) {
      onClose(itemType);
    }

    this.isClosed = true;
  }

  @autobind
  private handleOpacity(value: number): void {
    const { onOpacityChange } = this.props;

    if (onOpacityChange) {
      onOpacityChange((value || 0) / 100.0);
    }
  }

  private getDefaultValue = () => {
    const { intialOpacityLevel } = this.props;

    if (undefinedOrNull(intialOpacityLevel)) {
      return 50;
    }

    return intialOpacityLevel * 100;
  };

  public render(): React.ReactNode {
    const { views = [], value } = this.props;

    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={this.renderHeader}
      >
        <div className={style.item}>
          <label>CAD Version </label>
          <Dropdown
            value={value}
            onChange={this.handleDesignSelect}
            options={views.map((view) => ({
              value: view.id,
              label: `${
                view.name ? `${view.name}` : `${moment(view.date).format('ll')}`
              }`,
            }))}
            placeholder="Select an option"
          />
        </div>
        <div className={style.item}>
          <Slider
            disabled={!value}
            defaultValue={this.getDefaultValue()}
            onChange={this.handleOpacity}
            min={0}
            max={100}
          />
        </div>
      </ControlBox.Wrapper>
    );
  }
}

export default CompareControlBox;
