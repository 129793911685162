import { Modal } from 'antd';
import * as React from 'react';
import EpsgAPI from 'src/api/epsg';
import GcpMarkingApis from 'src/api/gcpMarking';
import MissionV2CapiApis from 'src/api/missionV2Capi';
import ProjectsAPI from 'src/api/projects';
import WorkbenchApis from 'src/api/workbench';
import { GcpList } from './GcpList';
import styles from './gcps.module.scss';
import ODMScene from './ODMScene';
import { Projections } from './Projections';
import { Review } from './Review';

declare let proj4: any;

interface IProps {
  session: any;
  epsgCode: string;
  onMarkingFinished: () => void;
}

interface IState {
  images?: any[];
  gcps?: any[];
  projectProj4Projection?: string;
  selectedGCP?: string;
  reviewing?: boolean;
  scene?: ODMScene;
  estimatedProjections: any;
  boundaryList?: any;
  error?: string;
  markedProjections?: any[];
  progressMessage?: string;
}

class ODMGCPs extends React.Component<IProps, IState> {
  private projectsApi = new ProjectsAPI();

  private missionV2CapiApis = new MissionV2CapiApis();

  private wbApis = new WorkbenchApis();

  private gcpMarkingApis = new GcpMarkingApis();

  private epsgApis = new EpsgAPI();

  constructor(props: IProps) {
    super(props);
    this.state = {
      images: [],
      gcps: [],
      estimatedProjections: [],
    };
  }

  componentDidMount(): void {
    const { session, epsgCode } = this.props;

    this.projectsApi
      .listAoiBoundary(session.projectId, session.aoiId)
      .then((res) => {
        const { error: apiError, data: boundaryData } = res;

        if (
          boundaryData &&
          boundaryData?.features &&
          boundaryData?.features[0]?.geometry?.coordinates
        ) {
          const coord = boundaryData.features[0].geometry.coordinates[0];

          const boundaryList = coord.map((a: [number, number]) => {
            return { lat: a[1], lng: a[0] };
          });

          this.setState({
            boundaryList,
          });
        }

        if (apiError) {
          console.error(apiError);
          this.setState({ error: 'Could not load boundary' });
        }
      });

    // load gcps
    this.gcpMarkingApis.listGcpBySession(session.sessionId).then((res) => {
      const { error: apiError, data: apiData } = res;

      if (apiError) {
        console.error(apiError);
        this.setState({ error: 'Could not load GCPs' });

        return;
      }

      this.setState({ gcps: apiData }, () => this.setGCPLatLon());
    });

    this.epsgApis.getEpsgProjection(epsgCode).then(({ data, error }) => {
      if (error) {
        this.setState({ error: 'Could not get proj4 projection of EPSG code' });

        return;
      }

      if (data) {
        this.setState({ projectProj4Projection: data }, () =>
          this.setGCPLatLon()
        );
      }
    });

    // mission images
    this.missionV2CapiApis.listUploadedImages(session.missionId).then((res) => {
      const { error: apiError, data: apiData } = res;

      if (apiError) {
        console.error(apiError);
        this.setState({ error: 'Could not load images' });

        return;
      }

      const images = (apiData || []).filter((i: any) => i.useInMaps);

      this.setState({
        images,
      });
    });

    // ODM_CAMERAS
    this.wbApis
      .getSessionArtifactContents(session.sessionId, 'ODM_CAMERAS')
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
          this.setState({ error: 'Could not load reconstruction data' });

          return;
        }

        if (data) {
          this.setState({ scene: new ODMScene(data.data) });
        }
      });
  }

  setGCPLatLon() {
    const { gcps, projectProj4Projection } = this.state;
    const latlon =
      '+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees +no_defs';

    if (gcps && projectProj4Projection) {
      gcps.forEach((g) => {
        const convertedPoint = proj4(projectProj4Projection, latlon, [
          g.x,
          g.y,
          g.z,
        ]);
        const newRef = g;

        (newRef as any).latlon = convertedPoint;
      });

      this.setState({ gcps: [...gcps] });
    }
  }

  selectGCP(id: string) {
    const { gcps, scene } = this.state;
    const currentGCP = (gcps || []).find((g) => g.id === id);

    if (currentGCP) {
      const projMap: any = {};

      if (scene) {
        const estimatedProjections = scene.projectUTMPointToPixels({
          x: currentGCP.x,
          y: currentGCP.y,
          z: currentGCP.z,
        });

        estimatedProjections.forEach((p) => {
          projMap[p.guid] = p;
        });
      }

      this.setState(
        {
          selectedGCP: id,
          estimatedProjections: projMap,
        },
        () => {
          this.gcpMarkingApis.listProjections(id).then(({ data, error }) => {
            if (error) {
              console.error(error);

              return;
            }

            if (data) {
              this.setState({ markedProjections: data });
            }
          });
        }
      );
    } else {
      this.setState({
        selectedGCP: id,
        estimatedProjections: [],
      });
    }
  }

  handleMarkProjection = (
    imageGuid: string,
    pixelX: number,
    pixelY: number
  ) => {
    const { selectedGCP, markedProjections } = this.state;
    const { session } = this.props;
    const projection = (markedProjections || []).find(
      (p) => p.imageGuid === imageGuid
    );

    if (!selectedGCP) return;
    if (projection) {
      this.gcpMarkingApis
        .updateProjection(session.sessionId, selectedGCP, projection.id, {
          ...projection,
          pixelX,
          pixelY,
        })
        .then(({ data: _data, error }) => {
          if (error) {
            console.error(error);

            return;
          }

          projection.pixelX = pixelX;
          projection.pixelY = pixelY;

          // update projections array
          this.setState({ markedProjections: [...(markedProjections || [])] });
        });
    } else {
      this.gcpMarkingApis
        .postProjection(session.sessionId, selectedGCP || '', {
          gcpId: selectedGCP,
          id: '',
          imageGuid,
          pixelX,
          pixelY,
        })
        .then(({ data, error }) => {
          if (error) {
            console.error(error);

            return;
          }

          this.setState({
            markedProjections: [...(markedProjections || []), data],
          });
        });
    }
  };

  handleRemoveProjection = (projectionId: string) => {
    const { selectedGCP, markedProjections } = this.state;
    const { session } = this.props;

    this.gcpMarkingApis
      .deleteProjection(session.sessionId, selectedGCP || '', projectionId)
      .then(({ error }) => {
        if (error) {
          console.error(error);

          return;
        }

        const newProjections = (markedProjections || []).filter(
          (p) => p.id !== projectionId
        );

        this.setState({ markedProjections: [...newProjections] });
      });
  };

  handleFinishMarking = () => {
    const { session, onMarkingFinished } = this.props;

    this.setState(
      { progressMessage: 'Finalizing GCP Marking and updating session' },
      () => {
        this.gcpMarkingApis
          .updateSession(session.sessionId, {})
          .then(({ error }) => {
            if (error) {
              console.error(error);
              this.setState({
                error: 'Could not finish marking and update session',
                progressMessage: undefined,
              });
            } else {
              this.setState(
                {
                  progressMessage: undefined,
                },
                () => {
                  onMarkingFinished();
                }
              );
            }
          });
      }
    );
  };

  handleStartReview = () => {
    this.setState({
      reviewing: true,
      selectedGCP: undefined,
      markedProjections: undefined,
    });
  };

  handleFindProjections = () => {
    const { scene, selectedGCP, markedProjections, gcps } = this.state;

    if (scene && selectedGCP && markedProjections && gcps) {
      const gcp = (gcps || []).find((g) => g.id === selectedGCP);

      if (gcp && (markedProjections || []).length > 1) {
        const projs = (markedProjections || []).map((p) => {
          return {
            guid: p.imageGuid,
            pixelX: p.pixelX,
            pixelY: p.pixelY,
          };
        });
        const point = scene.triangulate(projs);

        gcp.estimatedPosition = point;
        const [x, y, z] = point;

        const projMap: any = {};
        const estimatedProjections = scene.projectUTMPointToPixels({ x, y, z });

        estimatedProjections.forEach((p) => {
          projMap[p.guid] = p;
        });

        this.setState({ gcps: [...gcps], estimatedProjections: projMap });
      }
    }
  };

  handleEnableGcp = (gcpId: string, disabled: boolean) => {
    const { gcps } = this.state;

    this.gcpMarkingApis
      .updateMarkingGcp(gcpId, { disabled })
      .then(({ data, error }) => {
        if (error) {
          console.error(error);

          return;
        }

        if (data) {
          const newGcps = (gcps || []).map((g) => {
            if (g.id === gcpId) {
              return data;
            }

            return g;
          });

          this.setState({ gcps: newGcps }, () => this.setGCPLatLon());
        }
      });
  };

  handleChangeGcpType = (gcpId: string, checkpoint: boolean) => {
    const { gcps } = this.state;

    this.gcpMarkingApis
      .updateMarkingGcp(gcpId, { checkpoint })
      .then(({ data, error }) => {
        if (error) {
          console.error(error);

          return;
        }

        if (data) {
          const newGcps = (gcps || []).map((g) => {
            if (g.id === gcpId) {
              return data;
            }

            return g;
          });

          this.setState({ gcps: newGcps }, () => this.setGCPLatLon());
        }
      });
  };

  render(): React.ReactNode {
    const { epsgCode } = this.props;
    const {
      gcps,
      selectedGCP,
      boundaryList,
      images,
      estimatedProjections,
      markedProjections,
      error,
      projectProj4Projection,
      scene,
      progressMessage,
      reviewing,
    } = this.state;

    if (error) {
      return <div className={styles.container}>{error}</div>;
    }

    if (
      !gcps ||
      !images ||
      !projectProj4Projection ||
      !scene ||
      !boundaryList
    ) {
      // this component loads many things from server
      // we wait for all of the requests to finish before showing the UI
      // if any one fails, we show an error
      return <div className={styles.container}>Loading...</div>;
    }

    const currentGCP = (gcps || []).find((g) => g.id === selectedGCP);

    if (reviewing) {
      return (
        <div className={styles.container}>
          <div className={styles.title}>
            <span
              style={{
                textDecoration: 'bold',
                padding: '1px',
                marginRight: '1em',
              }}
            >
              <a
                onClick={() =>
                  this.setState({ selectedGCP: '', reviewing: undefined })
                }
              >
                &lt; Back to GCPs list
              </a>
            </span>
            Current GCP:{' '}
            {currentGCP?.name || 'Click a GCP marker in map to select a GCP'}
          </div>
          <Review
            boundaryList={boundaryList}
            gcps={gcps || []}
            selectedGcp={selectedGCP || ''}
            images={images || []}
            markedProjections={markedProjections || []}
            onProjectionRemoved={this.handleRemoveProjection}
            onGcpSelected={(id) =>
              this.setState({ markedProjections: undefined }, () => {
                this.selectGCP(id);
              })
            }
            onMarkingFinish={this.handleFinishMarking}
          />
        </div>
      );
    }

    if (currentGCP) {
      return (
        <div className={styles.container}>
          <div className={styles.title}>
            <span
              style={{
                textDecoration: 'bold',
                padding: '1px',
                marginRight: '1em',
              }}
            >
              <a onClick={() => this.setState({ selectedGCP: '' })}>
                &lt; Back to GCPs list
              </a>
            </span>
            Current GCP: {currentGCP.name}
          </div>
          <Projections
            boundaryList={boundaryList}
            images={images || []}
            projections={estimatedProjections}
            markedProjections={markedProjections || []}
            onProjectionMarked={this.handleMarkProjection}
            onProjectionRemoved={this.handleRemoveProjection}
            onFindProjections={this.handleFindProjections}
          />
          <Modal
            title={null}
            visible={!!progressMessage}
            afterClose={() => this.setState({ progressMessage: undefined })}
            closable={false}
            footer={null}
          >
            {progressMessage}
          </Modal>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.title}>Interpreting GCPs in EPSG:{epsgCode}</div>
        <GcpList
          boundaryList={boundaryList}
          gcps={gcps || []}
          onGcpSelected={(id) =>
            this.setState({ markedProjections: undefined }, () => {
              this.selectGCP(id);
            })
          }
          onStartReview={this.handleStartReview}
          onEnableGcp={this.handleEnableGcp}
          onChangeGcpType={this.handleChangeGcpType}
        />
        <Modal
          title={null}
          visible={!!progressMessage}
          afterClose={() => this.setState({ progressMessage: undefined })}
          closable={false}
          footer={null}
        >
          {progressMessage}
        </Modal>
      </div>
    );
  }
}

export default ODMGCPs;
