import bbox from '@turf/bbox';
import { multiPoint } from '@turf/helpers';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { Feature, Layer, Popup } from 'react-mapbox-gl';
import { createSelector } from 'reselect';
import { getMapboxToken } from 'src/utils/functs';
import MapboxView from '../MapboxView';
import style from './ProjectMapList.module.scss';
import { MAPBOX_STYLE_JIBIN_1_URL } from '../../constants/urls';

const boundSelector = createSelector(
  (projects) => projects,
  (projects: any[]) => {
    const bound = bbox(
      multiPoint(
        projects.map((project) => [project.latitude, project.longitude])
      )
    );

    return [
      [bound[1] - 5, bound[0] - 5],
      [bound[3] + 5, bound[2] + 5],
    ];
  }
);

export interface IProps {
  projects: any[];
  history: any;
}

interface IFeatureProps {
  projectId: string;
  latitude: number;
  longitude: number;
  name: string;
  address: string;
}

interface IState {
  activePoint?: IFeatureProps;
}

class ProjectMapList extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      activePoint: undefined,
    };
  }

  private renderPopup(): React.ReactNode {
    const { activePoint } = this.state;

    if (!activePoint) {
      return null;
    }

    const { latitude, longitude, name, address }: IFeatureProps = activePoint;

    return (
      <Popup coordinates={[longitude, latitude]}>
        <h1>{name}</h1>
        <div>{address}</div>
      </Popup>
    );
  }

  private renderPoints(): React.ReactNode {
    const { projects } = this.props;

    return projects.map(({ id, latitude, longitude, name, address }) => (
      <Feature
        key={id}
        properties={{
          projectId: id,
          latitude,
          longitude,
          name,
          address,
        }}
        coordinates={[longitude, latitude]}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
      />
    ));
  }

  @autobind
  private handleMouseEnter({ map, feature }: any): void {
    const { properties } = feature;
    const { getCanvas } = map;

    this.setState({
      activePoint: properties,
    });
    getCanvas().style.cursor = 'pointer';
  }

  @autobind
  private handleMouseLeave({ map }: any): void {
    const { getCanvas } = map;

    this.setState({
      activePoint: undefined,
    });
    getCanvas().style.cursor = '';
  }

  @autobind
  private handleClick({ feature }: any): void {
    const { properties } = feature;
    const { history } = this.props;

    history.push(`project/${properties.projectId}`);
  }

  public render(): React.ReactNode {
    const { projects } = this.props;

    const bound = boundSelector(projects);

    return (
      <MapboxView
        className={style.container}
        // eslint-disable-next-line react/style-prop-object
        style={MAPBOX_STYLE_JIBIN_1_URL}
        accessToken={getMapboxToken()}
        fitBounds={bound}
      >
        <Layer
          id="projects"
          type="symbol"
          layout={{
            'icon-image': 'pin-icon',
          }}
        >
          {this.renderPoints()}
        </Layer>
        {this.renderPopup()}
      </MapboxView>
    );
  }
}

export default ProjectMapList;
