// from https://github.com/mapbox/mapbox-gl-draw/blob/master/src/lib/theme.js

import deepmerge from 'deepmerge';
import { DrawControlThemeTypes } from './theme.types';
import { undefinedOrNull } from '../../utils/functs';
import { APP_PRIMARY_COLOR, ML_OBJECT_COLOR } from '../../constants/colors';

const defaultTheme: DrawControlThemeTypes[] = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': '#bfbfbf',
      'fill-outline-color': '#bfbfbf',
      'fill-opacity': 0.4,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#fff',
      'fill-outline-color': '#fff',
      'fill-opacity': 0.4,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#000',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fff',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#bfbfbf',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fff',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': APP_PRIMARY_COLOR,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': APP_PRIMARY_COLOR,
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#bfbfbf',
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': APP_PRIMARY_COLOR,
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#af0093',
      'fill-outline-color': APP_PRIMARY_COLOR,
      'fill-opacity': 0.2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': APP_PRIMARY_COLOR,
      'line-width': 3.5,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': APP_PRIMARY_COLOR,
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-point-stroke-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 6,
      'circle-color': APP_PRIMARY_COLOR,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 4,
      'circle-color': '#af0000',
    },
  },
  {
    id: 'gl-draw-metric-label',
    type: 'symbol',
    filter: ['==', 'meta', 'label'],
    layout: {
      'text-field': '{label}',
      'text-anchor': 'left',
      'text-size': 12,
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-offset': [0.25, 0.25],
    },
    paint: {
      'text-color': '#7d7d7d',
      'text-halo-color': '#fff',
      'text-halo-width': 1,
    },
  },
  {
    id: 'gl-draw-polygon-fill-ml-site-objects',
    type: 'fill',
    filter: [
      'all',
      ['==', 'mode', 'view_site_objects'],
      ['==', '$type', 'Polygon'],
      ['==', 'user_source', 'ml'],
    ],
    paint: {
      'fill-color': ML_OBJECT_COLOR.AI,
      'fill-outline-color': ML_OBJECT_COLOR.AI,
      'fill-opacity': 0.2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-ml-site-objects',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['==', 'mode', 'view_site_objects'],
      ['==', 'user_source', 'ml'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ML_OBJECT_COLOR.AI,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-fill-manual-site-objects',
    type: 'fill',
    filter: [
      'all',
      ['==', 'mode', 'view_site_objects'],
      ['==', '$type', 'Polygon'],
      ['==', 'user_source', 'manual'],
    ],
    paint: {
      'fill-color': ML_OBJECT_COLOR.MANUAL,
      'fill-outline-color': ML_OBJECT_COLOR.MANUAL,
      'fill-opacity': 0.2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-manual-site-objects',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['==', 'mode', 'view_site_objects'],
      ['==', 'user_source', 'manual'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ML_OBJECT_COLOR.MANUAL,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-fill-ml-create-object',
    type: 'fill',
    filter: [
      'all',
      ['==', 'mode', 'create_object'],
      ['==', '$type', 'Polygon'],
      ['==', 'user_source', 'ml'],
    ],
    paint: {
      'fill-color': ML_OBJECT_COLOR.AI,
      'fill-outline-color': ML_OBJECT_COLOR.AI,
      'fill-opacity': 0.2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-ml-create-object',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['==', 'mode', 'create_object'],
      ['==', 'user_source', 'ml'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ML_OBJECT_COLOR.AI,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-fill-manual-create-object',
    type: 'fill',
    filter: [
      'all',
      ['==', 'mode', 'create_object'],
      ['==', '$type', 'Polygon'],
      ['==', 'user_source', 'manual'],
    ],
    paint: {
      'fill-color': ML_OBJECT_COLOR.MANUAL,
      'fill-outline-color': ML_OBJECT_COLOR.MANUAL,
      'fill-opacity': 0.2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-manual-create-object',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['==', 'mode', 'create_object'],
      ['==', 'user_source', 'manual'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ML_OBJECT_COLOR.MANUAL,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-fill-ml-delete-object',
    type: 'fill',
    filter: [
      'all',
      ['==', 'mode', 'delete_object'],
      ['==', '$type', 'Polygon'],
      ['==', 'user_source', 'ml'],
    ],
    paint: {
      'fill-color': ML_OBJECT_COLOR.AI,
      'fill-outline-color': ML_OBJECT_COLOR.AI,
      'fill-opacity': 0.2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-ml-delete-object',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['==', 'mode', 'delete_object'],
      ['==', 'user_source', 'ml'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ML_OBJECT_COLOR.AI,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-fill-manual-delete-object',
    type: 'fill',
    filter: [
      'all',
      ['==', 'mode', 'delete_object'],
      ['==', '$type', 'Polygon'],
      ['==', 'user_source', 'manual'],
    ],
    paint: {
      'fill-color': ML_OBJECT_COLOR.MANUAL,
      'fill-outline-color': ML_OBJECT_COLOR.MANUAL,
      'fill-opacity': 0.2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-manual-delete-object',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['==', 'mode', 'delete_object'],
      ['==', 'user_source', 'manual'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ML_OBJECT_COLOR.MANUAL,
      'line-width': 2,
    },
  },
];

export const theme = (
  args?: DrawControlThemeTypes[]
): DrawControlThemeTypes[] => {
  if (undefinedOrNull(args)) {
    return defaultTheme;
  }

  return defaultTheme.map((a) => {
    const item = args.filter((k) => k.id === a.id);

    if (undefinedOrNull(item) || item.length < 1) {
      return a;
    }

    return deepmerge(a, item[0]);
  });
};
