import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Payments from '.';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { PaymentsTypes } from './index.types';

const mapStateToProps = (): PaymentsTypes.ReduxState => {
  return {};
};

const mapDispatchToProps = (dispatch: any): PaymentsTypes.DispatchProps => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
    },
    dispatch
  );
};

export default connect<
  PaymentsTypes.ReduxState,
  PaymentsTypes.DispatchProps,
  PaymentsTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(Payments);
