import * as React from 'react';
import classnames from 'classnames';
import { Redirect } from 'react-router';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Input, Modal, Typography } from 'antd';
import autobind from 'autobind-decorator';
import { Button } from '../../Button';
import styles from './index.module.scss';
import TerrainMapsApis from '../../../api/terrainMaps';
import {
  undefinedOrNull,
  undefinedOrNullChained,
  isNumber,
  getExtension,
  splitIntoLines,
  isNumeric,
} from '../../../utils/functs';
import {
  formatDateTime,
  momentDateNow,
  formatDateForDatePicker,
} from '../../../utils/date';
import TerrainMapsTabsLayout1Parameters from './Parameters';
import { TerrainMapsApisReturnPromiseTypes } from '../../../shapes/terrainMaps';
import { TerrainMapsProps } from '../index.types';
import HelpTooltip from '../../HelpTooltip';
import View from '../../View';
import { resetBodyOverFlow, setBodyOverflow } from '../../../utils/window';
import ModalNotification from '../../ModalNotification/ModalNotification';
import csvExample from '../image/csvExample.png';
import { WithForm } from '../../../shapes/app';
import { MapGeneratedStatus } from '../../MapGeneratedStatus';
import LoadingOverlay from '../../LoadingOverlay';
import { DTMSource } from '../../../api/terrainMaps.types';
import { Project } from '../../../api/projects.types';
import { User } from '../../../api/auth.types';

const terrainMapsApis = new TerrainMapsApis();
const { Text } = Typography;

interface IProps extends WithForm, TerrainMapsProps {
  importBtnText: string;
  sourceType: DTMSource;
  acceptedImportFiles: string;
  projectData?: Project;
  disableGenerateBtn: () => any;
  disableFinalizeBtn: () => any;
  finalizeBtnLoading: boolean;
  onFinalizeBtn: (dtmName: string) => any;
  unit: string;
  epsgCode: string;
  user?: User;
}

interface IState {
  uploadContourPointsFile: any | null;
  surveyDate: string;
  generateBtnLoading: boolean;
  newDtmCreated: boolean;
  isMapFullScreen: boolean;
  showEpsgConfirmModal: boolean;
  uploadContourPointsFileKey: number;
  showFinalizeConfirmModal: boolean;
  showCSVExampleModal: boolean;
  isDxfUploaded: boolean;
}

class TerrainMapsTabsLayout1 extends React.PureComponent<IProps, IState> {
  private uploadContourPointsFileRef = React.createRef<HTMLInputElement>();

  private defaultElevationAttributes: {} = {
    value: 'ELEV',
    label: 'ELEV',
  };

  public constructor(props: IProps) {
    super(props);

    this.state = {
      uploadContourPointsFile: null,
      surveyDate: momentDateNow(),
      generateBtnLoading: false,
      newDtmCreated: false,
      isMapFullScreen: false,
      showEpsgConfirmModal: false,
      showFinalizeConfirmModal: false,
      showCSVExampleModal: false,
      isDxfUploaded: false,
      uploadContourPointsFileKey: Date.now(),
    };
  }

  public componentDidMount(): void {
    this.handleInitSurveyDate();
  }

  private handleInitSurveyDate = (): void => {
    const { dtmData, sourceType, actionType } = this.props;

    if (
      actionType === 'edit' &&
      dtmData &&
      dtmData.sourceType === sourceType &&
      !undefinedOrNull(dtmData.date)
    ) {
      this.setState({
        surveyDate: formatDateForDatePicker(dtmData.date),
      });
    }
  };

  private handleUploadFile = (): void => {
    const node = this.uploadContourPointsFileRef.current;

    if (node) {
      node.click();
    }
  };

  private handleEpsgModalClose = (confirm: boolean): void => {
    if (confirm) {
      this.setState({
        showEpsgConfirmModal: false,
        uploadContourPointsFileKey: Date.now(),
      });

      this.processUploadFile();

      return;
    }

    this.setState(() => {
      return {
        uploadContourPointsFile: null,
        showEpsgConfirmModal: false,
        uploadContourPointsFileKey: Date.now(),
      };
    });
  };

  private handleFinalizeModalShow = (): void => {
    this.setState({
      showFinalizeConfirmModal: true,
    });
  };

  private handleFinalizeModalClose = (confirm: boolean): void => {
    if (confirm) {
      this.setState({
        showFinalizeConfirmModal: false,
      });
      this.finalizeDTM();

      return;
    }

    this.setState({
      showFinalizeConfirmModal: false,
    });
  };

  private handleUploadFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { sourceType, showSnackbar } = this.props;

    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      const isGeojson = file.name.endsWith('geojson');
      const isCsv = file.name.endsWith('csv');
      const isDxf = file.name.endsWith('dxf');
      let isDxfUploaded = false;

      if (sourceType === 'points' && !isCsv) {
        showSnackbar({
          body: 'Unsupported file type! Only CSV files can be used for Terrain Map creation.',
          type: 'error',
        });

        this.setState({
          uploadContourPointsFile: null,
        });

        return;
      }

      if (sourceType === 'contour' && !isDxf) {
        showSnackbar({
          body: 'Unsupported file type! Only DXF files can be used for Terrain Map creation.',
          type: 'error',
        });

        this.setState({
          uploadContourPointsFile: null,
        });

        return;
      }

      if (sourceType === 'contour' && isDxf) {
        isDxfUploaded = true;
      }

      this.setState(
        {
          uploadContourPointsFile: file,
          showEpsgConfirmModal: isCsv || isDxf,
          isDxfUploaded,
        },
        () => {
          if (isGeojson) {
            this.processUploadFile();
          }
        }
      );
    }
  };

  private processUploadFile = (): void => {
    const { showSnackbar } = this.props;
    const { uploadContourPointsFile } = this.state;

    if (!uploadContourPointsFile) {
      return;
    }

    const fileType = getExtension(uploadContourPointsFile.name);
    const fr = new FileReader();

    fr.onloadend = (ev: any) => {
      const fileContents = ev.target.result;

      try {
        switch (fileType) {
          case 'geojson':
            // eslint-disable-next-line no-case-declarations
            const geoJson = JSON.parse(fileContents);

            if (!undefinedOrNullChained(geoJson, 'features[0].properties')) {
              const { properties }: { properties: {} } = geoJson.features[0];
              const elevationAttributes: any[] = [];

              Object.keys(properties).map((a: string) => {
                const item = properties[a];

                if (isNumber(item)) {
                  elevationAttributes.push({
                    value: a,
                    label: a,
                  });
                }

                return item;
              });
            }

            break;

          case 'csv':
            //   eslint-disable-next-line no-case-declarations
            const csvJson: string[] | null = splitIntoLines(fileContents);

            if (!csvJson || csvJson.length < 1) {
              showSnackbar({
                body: 'Some error occured while parsing the csv file',
                type: 'error',
              });

              return;
            }

            if (csvJson[0] !== 'x,y,z') {
              showSnackbar({
                body: ` '${csvJson[0]}' is an invalid header. The header should be 'x,y,z', in lower-case. Click on 'CSV Example' to see the format`,
                type: 'error',
              });

              this.setState(() => {
                return {
                  uploadContourPointsFile: null,
                };
              });

              return;
            }

            break;

          default:
            break;
        }
      } catch {
        showSnackbar({
          body: 'Some error occured while importing the file',
          type: 'error',
        });
      }
    };

    fr.readAsText(uploadContourPointsFile);
  };

  @autobind
  private handleGenerateDtmBtn(): void {
    const { form, showSnackbar } = this.props;

    form.validateFields(
      ['dtmName', 'outputDTMResolution', 'surveyDate'],
      (err: any, values: any) => {
        if (!err) {
          if (!undefinedOrNull(values.outputDTMResolution)) {
            if (!isNumeric(values.outputDTMResolution)) {
              showSnackbar({
                type: 'error',
                body: `Output Terrain Map Resolution must be numeric.`,
              });

              return;
            }

            if (parseFloat(values.outputDTMResolution) <= 0) {
              showSnackbar({
                type: 'error',
                body: `Output Terrain Map Resolution must be a positive real number.`,
              });

              return;
            }
          }

          this.processGenerateDtmBtn(
            values.dtmName,
            parseFloat(values.outputDTMResolution)
          );
        }
      }
    );
  }

  private processGenerateDtmBtn = (
    selectedDTMName: string | null,
    selectedOutputDTMResolution: number | null
  ): void => {
    const {
      match,
      projectData,
      user,
      showSnackbar,
      setDtm,
      sourceType,
      dtmData,
      actionType,
    } = this.props;
    const { uploadContourPointsFile, surveyDate } = this.state;
    const { projectId, aoiId } = match.params;

    if (undefinedOrNull(uploadContourPointsFile)) {
      showSnackbar({
        body: `Import file is required`,
        type: 'error',
      });

      return;
    }

    const uploadContourPointsFileExtension = getExtension(
      uploadContourPointsFile.name
    );

    const bodyFormRequestData = {
      sourceType,
      date: formatDateTime(surveyDate),
      name: selectedDTMName,
      parameters: {
        elevationAttribute: 'ELEV',
        outputDTMResolution: selectedOutputDTMResolution,
      },
      artifactType: uploadContourPointsFileExtension,
    };
    const bodyFormData = new FormData();
    const bodyFormRequestDataBlob = new Blob(
      [JSON.stringify(bodyFormRequestData)],
      { type: 'application/json' }
    );

    bodyFormData.append('request', bodyFormRequestDataBlob);
    bodyFormData.append('sourceData', uploadContourPointsFile);

    this.setState({
      generateBtnLoading: true,
    });

    terrainMapsApis
      .postDtm(projectId, aoiId, bodyFormData, dtmData?.id || null)
      .then((res: TerrainMapsApisReturnPromiseTypes) => {
        if (undefinedOrNull(res)) {
          showSnackbar({
            body: `Some error occured. Try again!`,
            type: 'error',
          });

          this.setState({
            generateBtnLoading: false,
          });

          return;
        }

        if (res.error) {
          showSnackbar({
            body: res.error,
            type: 'error',
          });

          this.setState({
            generateBtnLoading: false,
          });

          return;
        }

        setDtm(res.data);

        if (actionType === 'edit') {
          this.setState({
            generateBtnLoading: false,
            newDtmCreated: false,
          });
        } else {
          this.setState({
            generateBtnLoading: false,
            newDtmCreated: true,
          });
        }

        let executionInfo = '';

        if (
          projectData &&
          projectData.projectType &&
          projectData.projectType === 'POST_PROCESSING_DEMO_PROJECT' &&
          user &&
          !user.staff
        ) {
          if (
            res.data.dtmGenerationCount != null &&
            res.data.dtmGenerationLimit != null
          ) {
            const { dtmGenerationCount, dtmGenerationLimit } = res.data;

            executionInfo = ` You have used up one Terrain Map, and you have ${
              dtmGenerationLimit - dtmGenerationCount
            } generations left for this demo project.`;
          }
        }

        showSnackbar({
          body: `Terrain Map is being generated.${executionInfo} Please wait, you will receive an email when Terrain Map is generated.`,
          type: 'info',
        });
      });
  };

  private handleDateChanged = (date: any, dateString: string) => {
    this.setState({ surveyDate: dateString });
  };

  private getDefaultSurveyDate = (): any => {
    const { dtmData, sourceType } = this.props;
    const { surveyDate } = this.state;

    if (
      dtmData &&
      dtmData.sourceType === sourceType &&
      !undefinedOrNull(dtmData.date)
    ) {
      return formatDateForDatePicker(dtmData.date);
    }

    return formatDateForDatePicker(surveyDate);
  };

  private getDefaultDTMName = (): any => {
    const { dtmData, sourceType } = this.props;

    if (
      dtmData &&
      dtmData.sourceType === sourceType &&
      !undefinedOrNull(dtmData.name)
    ) {
      return dtmData.name;
    }

    return null;
  };

  private isDtmFinalized = (): boolean => {
    const { dtmData } = this.props;

    return !!dtmData?.finalized;
  };

  private handleMapFullScreenToggle = (): void => {
    this.setState(
      ({ isMapFullScreen }) => {
        return {
          isMapFullScreen: !isMapFullScreen,
        };
      },
      () => {
        const { isMapFullScreen } = this.state;

        if (isMapFullScreen) {
          setBodyOverflow('y');

          return;
        }

        resetBodyOverFlow('y');
      }
    );
  };

  private finalizeDTM = () => {
    const { form, onFinalizeBtn } = this.props;

    form.validateFields(['dtmName'], (err: any, values: any) => {
      if (!err) {
        onFinalizeBtn(values.dtmName);
      }
    });
  };

  private handleCSVExampleModalShow = () => {
    this.setState({
      showCSVExampleModal: true,
    });
  };

  private handleCSVExampleModalClose = () => {
    this.setState({
      showCSVExampleModal: false,
    });
  };

  private finalizeConfirmBodyText = (): string => {
    const { dtmData } = this.props;

    if (dtmData && dtmData.finalized) {
      return 'Un-publishing this Terrain Map will remove the Terrain Map from public display. Any associated Contours & Cut and Fill Reports will be un-published as well. Are you sure you want to proceed?';
    }

    return `Are you sure you want to publish the Terrain Map?`;
  };

  private surveyDateHelpText = (): string => {
    const { sourceType } = this.props;

    if (sourceType === 'contour') {
      return 'Original date of the survey which was used to create the contour map.';
    }

    return 'Original date of the survey from which the points were obtained.';
  };

  public render(): React.ReactNode {
    const {
      showSnackbar,
      form,
      dtmData,
      importBtnText,
      sourceType,
      acceptedImportFiles,
      match,
      disableFinalizeBtn,
      disableGenerateBtn,
      finalizeBtnLoading,
      unit,
      epsgCode,
    } = this.props;
    const { projectId, aoiId } = match.params;
    const {
      uploadContourPointsFile,
      generateBtnLoading,
      newDtmCreated,
      isMapFullScreen,
      showEpsgConfirmModal,
      uploadContourPointsFileKey,
      showFinalizeConfirmModal,
      showCSVExampleModal,
      isDxfUploaded,
    } = this.state;

    const { getFieldDecorator } = form;

    if (newDtmCreated && dtmData?.id) {
      return (
        <Redirect
          to={`/project/${projectId}/aoi/${aoiId}/TerrainMaps/edit/${dtmData.id}`}
        />
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.inputFieldContainer}>
          <div className={styles.inputFieldWrapper}>
            <Form.Item label="Name">
              {getFieldDecorator('dtmName', {
                rules: [
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ],
                initialValue: this.getDefaultDTMName(),
              })(
                <Input
                  placeholder="Name"
                  style={{ margin: 0 }}
                  className={styles.inputField}
                  disabled={this.isDtmFinalized()}
                />
              )}
            </Form.Item>
            <HelpTooltip
              position="right"
              helpText="Name to be used for public display."
            />
          </div>
          <div className={styles.inputFieldWrapper}>
            <Form.Item label="Survey Date">
              {getFieldDecorator('surveyDate', {
                rules: [
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ],
                initialValue: this.getDefaultSurveyDate(),
              })(
                <DatePicker
                  allowClear={false}
                  placeholder="Select Date"
                  size="small"
                  onChange={this.handleDateChanged}
                  className={styles.inputField}
                  dropdownClassName="antCalendar"
                  disabled={this.isDtmFinalized()}
                />
              )}
            </Form.Item>
            <HelpTooltip
              position="right"
              helpText={this.surveyDateHelpText()}
            />
          </div>

          <div>
            <input
              type="file"
              id="uploadContourPointsFile"
              ref={this.uploadContourPointsFileRef}
              key={uploadContourPointsFileKey}
              hidden
              multiple={false}
              accept={acceptedImportFiles}
              onChange={(events: React.ChangeEvent<HTMLInputElement>) =>
                this.handleUploadFileChange(events)
              }
            />
            {sourceType === 'points' ? (
              <a
                className={styles.csvExample}
                onClick={() => {
                  this.handleCSVExampleModalShow();
                }}
              >
                CSV Example
              </a>
            ) : null}

            <Button
              icon="upload"
              text={importBtnText}
              className={styles.uploadInput}
              onClick={this.handleUploadFile}
              disabled={this.isDtmFinalized() || disableGenerateBtn()}
            />

            {uploadContourPointsFile ? (
              <div className={styles.uploadInputFileWrapper}>
                <i className="fa fa-file-o" aria-hidden="true" />
                <Text>{uploadContourPointsFile.name}</Text>
              </div>
            ) : null}
          </div>

          <div className={styles.interpolationParametersWrapper}>
            <TerrainMapsTabsLayout1Parameters
              showSnackbar={showSnackbar}
              onGenerateDtmBtn={this.handleGenerateDtmBtn}
              generateBtnLoading={generateBtnLoading}
              form={form}
              dtmData={dtmData}
              sourceType={sourceType}
              uploadContourPointsFile={uploadContourPointsFile}
              finalizeForm={this.isDtmFinalized()}
              disableGenerateBtn={disableGenerateBtn}
              unit={unit}
            />
          </div>

          {dtmData ? (
            <div className={styles.inputFieldWrapper}>
              <Button
                className={styles.finalizeBtn}
                text={dtmData.finalized ? 'UNPUBLISH' : 'PUBLISH'}
                disabled={disableFinalizeBtn()}
                loading={finalizeBtnLoading}
                loadingText={
                  dtmData.finalized ? 'Unpublishing...' : 'Publishing...'
                }
                onClick={() => {
                  this.handleFinalizeModalShow();
                }}
              />
              {!dtmData.finalized && (
                <HelpTooltip
                  position="right"
                  helpText="Publishing the Terrain Map will make it available for public display."
                />
              )}
            </div>
          ) : null}
        </div>

        <div
          className={classnames(styles.viewContainer, {
            [styles.fullScreen]: isMapFullScreen,
          })}
        >
          {dtmData ? (
            <div className={styles.postGeneratedContainer}>
              {dtmData.status === 'error' ? (
                MapGeneratedStatus({ data: dtmData, type: 'dtm', error: true })
              ) : dtmData.status === 'completed' && dtmData.viewId ? (
                <div
                  className={classnames(styles.viewWrapper, {
                    [styles.fullScreen]: isMapFullScreen,
                  })}
                >
                  <View
                    viewId={dtmData.viewId}
                    projectId={dtmData.projectId}
                    aoiId={dtmData.aoiId}
                    onEvent={() => {}}
                    view={dtmData.generatedView}
                    history={{} as any}
                  />
                </div>
              ) : (
                MapGeneratedStatus({
                  data: dtmData,
                  type: 'dtm',
                  generating: true,
                })
              )}
            </div>
          ) : (
            MapGeneratedStatus({
              data: dtmData || null,
              type: 'dtm',
            })
          )}
        </div>

        {showEpsgConfirmModal ? (
          <Modal
            title="Confirm action"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onOk={() => this.handleEpsgModalClose(true)}
            onCancel={() => this.handleEpsgModalClose(false)}
            className={styles.csvExampleContainer}
          >
            <div>
              <ul className={isDxfUploaded ? styles.list : null}>
                <li>{`Please confirm that the provided input file has coordinates in the Coordinate system defined as per the Project EPSG Code, "EPSG: ${epsgCode}".`}</li>
                {isDxfUploaded ? (
                  <li>
                    <span>The DXF file, is expected to contain</span>
                    <b> only contour lines </b>
                    <span>
                      which are 3D lines, in the coordinate system represented
                      by the Project EPSG Code. If non-contour 3D lines are
                      present in the DXF file, it will be assumed to be a
                      contour line, and handled accordingly, possibly resulting
                      in inaccurate outputs.
                    </span>
                  </li>
                ) : null}
              </ul>
            </div>
            <div className={styles.csvExampleModalDiv}>
              <Button
                className={styles.cancelButton}
                onClick={() => {
                  this.handleEpsgModalClose(false);
                }}
                transparent
                text="CANCEL"
              />
              <Button
                onClick={() => {
                  this.handleEpsgModalClose(true);
                }}
                text="Ok"
              />
            </div>
          </Modal>
        ) : null}

        {showFinalizeConfirmModal ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody={this.finalizeConfirmBodyText()}
            shownotificationModal
            handleModalClose={this.handleFinalizeModalClose}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}

        {showCSVExampleModal ? (
          <Modal
            title="Example CSV"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onCancel={this.handleCSVExampleModalClose}
            className={styles.csvExampleContainer}
          >
            <p className={styles.headertext}>
              {`The following is an example of an acceptable CSV. The header should be 'x,y,z', in lower-case, and in the specified order.`}
            </p>

            <img
              className={styles.ErrorImage}
              src={csvExample}
              alt="errorImg"
            />
            <div className={styles.csvExampleModalDiv}>
              <Button onClick={this.handleCSVExampleModalClose} text="Ok" />
            </div>
          </Modal>
        ) : null}
        {generateBtnLoading || finalizeBtnLoading ? <LoadingOverlay /> : null}
      </div>
    );
  }
}

export default Form.create<IProps>()(TerrainMapsTabsLayout1);
