import { Button, Popconfirm } from 'antd';
import React from 'react';
import PlotGoogleMap from 'src/components/PlotGoogleMap';
import {
  APP_PRIMARY_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_COLOR,
} from 'src/constants/colors';
import styles from './gcps.module.scss';

interface GcpListProps {
  boundaryList: any;
  gcps: any[];
  onGcpSelected: (id: string) => void;
  onStartReview: () => void;
  onEnableGcp: (gcpId: string, enabled: boolean) => void;
  onChangeGcpType: (gcpId: string, checkpoint: boolean) => void;
}

export const GcpList: React.FC<GcpListProps> = ({
  boundaryList,
  gcps: allGcps,
  onGcpSelected,
  onStartReview,
  onEnableGcp,
  onChangeGcpType,
}) => {
  const gcps = allGcps.filter((g) => g.latlon && g.latlon[0] && g.latlon[1]);
  const gcpMarkers: any[] = gcps.map((g, index) => {
    return {
      pos: {
        lat: g.latlon[1],
        lng: g.latlon[0],
      },
      index,
      icon: {
        selected: {
          types: ['DEFAULT'],
          color: APP_PRIMARY_COLOR,
          ignoreMarkerClusterer: false,
        },
        unselected: {
          types: ['DEFAULT'],
          color: GOOGLE_MAP_MARKER_DEFAULT_COLOR,
          ignoreMarkerClusterer: false,
        },
      },
      label: {
        text: g.name,
      },
    };
  });

  return (
    <div className={styles.gcpListContainer}>
      <div className={styles.gcpsMapColumn}>
        <div className={styles.gcpsMap}>
          <PlotGoogleMap
            width="100%"
            height="100%"
            boundaryPointsList={boundaryList ? [boundaryList] : null}
            markerPointsList={gcpMarkers}
            onMarkerClick={(idx: number) => {
              const g = gcps[idx];

              if (!g?.disabled) {
                onGcpSelected(g.id);
              }
            }}
            centerMapTo="boundary"
            markerClustererMaxZoom={20}
            gestureHandling="greedy"
            maintainZoomLevel
          />
        </div>
        <div className={styles.gcpsControls}>
          <Button type="primary" onClick={onStartReview}>
            Review
          </Button>
        </div>
      </div>
      <div className={styles.gcpList}>
        <table width="100%">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Location (E,N,Elev)</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {allGcps.map((g) => {
              return (
                <tr key={g.id}>
                  <td>
                    <a
                      onClick={() => {
                        if (!g.disabled) {
                          onGcpSelected(g.id);
                        }
                      }}
                    >
                      {g.name}
                    </a>
                  </td>
                  <td>{g.checkpoint ? 'ICP' : 'GCP'}</td>
                  <td>
                    {g.x}, {g.y}, {g.z}
                  </td>
                  <td>
                    <div onClick={(e) => e.stopPropagation()}>
                      <Popconfirm
                        title={`Are you sure you want to ${
                          g.disabled ? 'enable' : 'disable'
                        } this marker?`}
                        onConfirm={() => onEnableGcp(g.id, !g.disabled)}
                      >
                        <a>{g.disabled ? 'Enable' : 'Disable'}</a>
                      </Popconfirm>
                    </div>
                  </td>
                  <td>
                    <div>
                      <Popconfirm
                        title={`Are you sure you want to convert this marker to ${
                          g.checkpoint ? 'GCP' : 'ICP'
                        }?`}
                        onConfirm={() => onChangeGcpType(g.id, !g.checkpoint)}
                      >
                        <a>Convert to {g.checkpoint ? 'GCP' : 'ICP'}</a>
                      </Popconfirm>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
