import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
import { InteractionState } from './hooks';

export const WGS84 = 'EPSG:4326';
export const WEB_MERCATOR = 'EPSG:3857';

export const getDefaultStyle = (geometryType: 'Point' | 'Polygon'): Style => {
  const styles = {
    Point: new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: 'rgba(255, 0, 0, 1)',
        }),
        stroke: new Stroke({ color: 'red', width: 1 }),
      }),
    }),
    Polygon: new Style({
      stroke: new Stroke({
        color: 'blue',
        lineDash: [4],
        width: 3,
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.1)',
      }),
    }),
  };

  return styles[geometryType];
};

export const getDefaultDrawStyle = (
  geometryType: 'Point' | 'Polygon'
): Style => {
  const styles = {
    Point: new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: 'rgba(255, 0, 0, 1)',
        }),
        stroke: new Stroke({ color: 'red', width: 1 }),
      }),
    }),
    Polygon: new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(255, 0, 0, 0.1)',
      }),
    }),
  };

  return styles[geometryType];
};

export const getGrayedOutStyle = (geometryType: 'Point' | 'Polygon'): Style => {
  const styles = {
    Point: new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: 'grey',
        }),
        stroke: new Stroke({ color: 'grey', width: 1 }),
      }),
    }),
    Polygon: new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(255, 0, 0, 0.1)',
      }),
    }),
  };

  return styles[geometryType];
};

export const getHelpMessage = ({
  draw,
  modify,
  feature,
}: InteractionState): string | undefined => {
  if (draw)
    return !feature
      ? 'Click to start drawing.'
      : ' Double-click to close polygon.';

  if (modify) return 'Click & drag to edit.';

  return undefined;
};

export const convertToGeoJsonFeature = (feature: Feature): string => {
  const geojson = new GeoJSON();

  return geojson.writeFeature(feature, {
    dataProjection: WGS84,
    featureProjection: WEB_MERCATOR,
    rightHanded: true,
  });
};

export const getGeometryCenter = (geometry: SimpleGeometry): Coordinate => {
  return getCenter(geometry.getExtent());
};
