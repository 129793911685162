/* eslint-disable react/no-unused-state */
import * as React from 'react';
import classnames from 'classnames';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Input, Collapse, Slider, DatePicker, Tabs } from 'antd';
import { Step } from 'react-joyride';
import GeometryType from 'ol/geom/GeometryType';
import { GeoJSONFeature, GeoJSONFeatureCollection } from 'ol/format/GeoJSON';

import { Moment } from 'moment';
import { Button } from '../../../Button';
import View from '../../../View';
import SkeletonLoader from '../../../SkeletonLoader';
import HelpToolTip from '../../../HelpTooltip';
import LocalDrawingMap from '../LocalDrawingMap';
import DrawingAPIs from '../../../../api/drawing';
import { DesignTransform } from '../../../../api/drawing.types';
import ViewAPIs from '../../../../api/views';
import { View as ViewType } from '../../../../api/views.types';
import { DrawingCreatedEvent, DrawingEditedEvent } from '../../index.types';
import { ViewDisplayType } from '../LocalDrawingMap/index.types';
import {
  formatDateForDatePicker,
  formatDateTime,
} from '../../../../utils/date';
import { downscaleImage, getDrawingUrl } from '../utils';
import { DRAWING_EDIT_PATH } from '../../../../routes/paths';
import { CONTROLS } from '../../../View/coachmarkSteps';

import {
  DrawingManagerPropsType,
  DrawingManagerStateType,
  ContentType,
} from './index.types';
import style from './index.module.scss';

const { Option } = Select;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const MAX_REFRESH_ERROR_RETRIES = 5;
const AUTO_REFRESH_INTERVAL_MS = 5000;

class DrawingManager extends React.PureComponent<
  DrawingManagerPropsType,
  DrawingManagerStateType
> {
  private drawingFileUploadRef = React.createRef<HTMLInputElement>();

  private drawingApis = new DrawingAPIs();

  private viewApis = new ViewAPIs();

  private intervalTimer: any = null;

  private errorCount: number = 0;

  constructor(props: DrawingManagerPropsType) {
    super(props);

    this.state = {
      loading: false,
      viewLoading: false,
      uploadDrawingModal: false,
      p1Coordinates: {},
      p2Coordinates: {},
      uploadCoordinateType: 'arbitrary',
      viewDisplayType: 'image',
    };
  }

  public componentDidMount() {
    const { drawing } = this.props;

    if (drawing?.viewId) {
      this.fetchDrawingView(drawing.viewId);
    }

    if (drawing?.date) {
      this.setState({ drawingDate: drawing.date });
    }

    if (drawing) {
      if (
        drawing.status === 'started' ||
        drawing.status === 'pending' ||
        drawing.status === 'starting'
      ) {
        if (this.intervalTimer === null) {
          this.intervalTimer = setInterval(
            () => this.fetchDrawing(drawing.id),
            AUTO_REFRESH_INTERVAL_MS
          );
        }
      }
    }
  }

  public UNSAFE_componentWillReceiveProps({
    drawing: newDrawing,
  }: DrawingManagerPropsType) {
    const { drawing: oldDrawing } = this.props;

    if (newDrawing?.viewId && newDrawing.viewId !== oldDrawing?.viewId) {
      this.fetchDrawingView(newDrawing.viewId);
    }

    if (newDrawing?.date && newDrawing.date !== oldDrawing?.date) {
      this.setState({ drawingDate: newDrawing.date });
    }

    if (newDrawing) {
      const { status } = newDrawing;

      if (
        status === 'started' ||
        status === 'pending' ||
        status === 'starting'
      ) {
        if (this.intervalTimer === null) {
          this.intervalTimer = setInterval(
            () => this.fetchDrawing(newDrawing.id),
            AUTO_REFRESH_INTERVAL_MS
          );
        }
      }

      if (status === 'completed') {
        if (this.intervalTimer !== null) {
          clearInterval(this.intervalTimer);
        }
      }
    }
  }

  public isProcessing = () => {
    const { drawing } = this.props;

    if (drawing) {
      if (
        drawing.status === 'started' ||
        drawing.status === 'pending' ||
        drawing.status === 'starting'
      ) {
        return true;
      }
    }

    return false;
  };

  // API Functions

  private fetchDrawingView = (viewId: string) => {
    const { projectId, aoiId, showSnackbar } = this.props;

    this.viewApis
      .getView(projectId, aoiId, viewId)
      .then((res) => {
        this.setState({ drawingView: res.data });
      })
      .catch((err) => {
        if (showSnackbar) {
          showSnackbar({
            type: 'error',
            body: 'There was an error while trying to fetch the view associated with the Drawing.',
          });
        }

        console.error(
          'Error fetching view to be displayed in the preview',
          err
        );
      });
  };

  private fetchDrawing = (drawingId?: string): Promise<void> => {
    const { projectId, aoiId, onEvent, showSnackbar } = this.props;

    if (drawingId) {
      return this.drawingApis
        .getDrawingById(projectId, aoiId, drawingId)
        .then((res) => {
          const { error, data } = res;

          if (error) {
            console.error(
              `[${this.errorCount}/${MAX_REFRESH_ERROR_RETRIES}] Error while fetching drawing data.`,
              error
            );

            if (this.errorCount < MAX_REFRESH_ERROR_RETRIES) {
              this.errorCount += 1;
            } else {
              clearInterval(this.intervalTimer);
              this.errorCount = 0;

              if (showSnackbar) {
                showSnackbar({
                  body: error,
                  type: 'error',
                });
              }
            }

            // preventing state update when API errors out
            return;
          }

          onEvent(new DrawingEditedEvent(data));
        });
    }

    return Promise.resolve();
  };

  private createAndProcessDrawing = (
    transform: DesignTransform,
    details: { name: string; date: string },
    drawingFile: File
  ): Promise<void> => {
    const { projectId, aoiId, showSnackbar, onEvent, history } = this.props;
    const { name, date } = details;

    let drawingId: string;

    return this.drawingApis
      .createDrawing(projectId, aoiId, { name, date: formatDateTime(date) })
      .then((res) => {
        const { error, data } = res;

        if (error) {
          throw error;
        }

        drawingId = data.id;

        onEvent(new DrawingCreatedEvent(data));
      })
      .catch((err) => {
        console.error(
          'Encountered an error while trying to process the drawing file',
          err
        );

        if (showSnackbar) {
          showSnackbar({
            type: 'error',
            body: 'There was an error while trying to process the drawing file. Please try again, or contact support@aspecscire.com for assistance',
          });
        }
      })
      .then(async () => {
        if (drawingId) {
          await this.processDrawing(
            transform,
            { ...details, drawingId },
            drawingFile
          );
          history.push(getDrawingUrl(projectId, aoiId, 'edit', drawingId));
        }
      });
  };

  private processDrawing = (
    transform: DesignTransform,
    details: { drawingId: string; name: string; date: string },
    drawingFile: File
  ): Promise<void> => {
    const { projectId, aoiId, showSnackbar, onEvent } = this.props;
    const { name, date, drawingId } = details;

    return this.drawingApis
      .getUploadUrlsForDrawing(projectId, aoiId, drawingId)
      .then((res) => {
        const { error, data } = res;

        if (error) {
          throw error;
        }

        return data;
      })
      .then((data) => {
        const { imageUrl } = data.urls;

        return this.drawingApis.uploadFile(drawingFile, imageUrl);
      })
      .then(() => {
        return this.drawingApis
          .processDrawing(projectId, aoiId, drawingId, {
            transform,
            name,
            date: formatDateTime(date),
          })
          .then((res) => {
            const { data, error } = res;

            if (error) {
              throw error;
            }

            return data;
          });
      })
      .then((data) => {
        if (showSnackbar) {
          showSnackbar({
            type: 'success',
            body: "Overlay is being finalised for display. You will be notified when it's ready for publishing.",
          });
        }

        this.setState({
          alignParameters: undefined,
          p1Coordinates: {},
          p2Coordinates: {},
        });

        onEvent(new DrawingEditedEvent(data));
      })
      .catch((err) => {
        console.error(
          'Encountered an error while trying to finalize the drawing file for display.',
          err
        );

        if (showSnackbar) {
          showSnackbar({
            type: 'error',
            body: 'There was an error while trying to finalize the drawing file for display. Please try again, or contact support@aspecscire.com for assistance',
          });
        }
      });
  };

  public publishDrawing = () => {
    const { projectId, aoiId, showSnackbar, drawing, onEvent, form } =
      this.props;

    if (!drawing) {
      return;
    }

    form.validateFields(['name', 'date'], (err: any, values: any) => {
      if (err) {
        return;
      }

      const { name, date } = values;

      if (name === null || name.length === 0) {
        if (showSnackbar) {
          showSnackbar({
            type: 'error',
            body: 'Name must be specified to edit the drawing.',
          });
        }

        return;
      }

      if (date === null) {
        if (showSnackbar) {
          showSnackbar({
            type: 'error',
            body: 'Date must be specified to edit the drawing.',
          });
        }

        return;
      }

      this.setState({ loading: true }, () => {
        this.drawingApis
          .publishDrawing(projectId, aoiId, drawing.id, {
            published: !drawing.published,
            name,
            date: formatDateTime(date),
          })
          .then((res) => {
            const { error, data } = res;

            if (error) {
              if (showSnackbar) {
                showSnackbar({
                  type: 'error',
                  body: error,
                });
              }

              return;
            }

            if (showSnackbar) {
              showSnackbar({
                type: 'success',
                body: !drawing.published
                  ? 'The overlay has now been published and is available to all stakeholders in the "Compare" tool.'
                  : 'The overlay has been un-published and is not available in the "Compare" tool for any stakeholder.',
              });
            }

            return onEvent(new DrawingEditedEvent(data));
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      });
    });
  };

  // Event Functions -------------------------------------------------------------

  private handleUploadFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let file: File | null = null;

    if (event.target.files && event.target.files.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      file = event.target.files[0];
    }

    if (file === null) {
      // break if file has not been selected
      return;
    }

    const defaultDrawingName = file.name.split('.').slice(0, -1).join('.');

    this.setState(
      {
        viewLoading: true,
      },
      () => {
        downscaleImage(file as File)
          .then((res) => {
            const { width, height, scaleFactor, dataUrl } = res;

            this.setState({
              defaultName: defaultDrawingName,
              drawingFile: file as File,
              imageParameters: {
                width,
                height,
                downscaleFactor: scaleFactor,
              },
              drawingFileDataUrl: dataUrl,
            });
          })
          .finally(() => this.setState({ viewLoading: false }));
      }
    );
  };

  private handleSelectMosaic = () => {
    const node = this.drawingFileUploadRef.current;

    if (node) {
      node.click();
    }

    this.setState({
      uploadDrawingModal: false,
      p1Coordinates: {},
      p2Coordinates: {},
      alignParameters: undefined,
    });
  };

  private handleDateChange = (date: Moment, dateString: string) => {
    const { form } = this.props;

    form.setFieldsValue({ date: dateString });

    this.setState({ drawingDate: dateString });
  };

  private handleFeatureCreate = (feature: GeoJSONFeature) => {
    const { properties, geometry } = feature;
    const { p1Coordinates, p2Coordinates, viewDisplayType } = this.state;

    if (properties?.id && geometry.type === 'Point') {
      const { id } = properties;

      if (id === 'P1') {
        const { coordinates } = geometry as any;

        switch (viewDisplayType) {
          case 'image': {
            this.setState({
              p1Coordinates: {
                ...p1Coordinates,
                pixelX: coordinates[0],
                pixelY: coordinates[1],
              },
              drawingSettings: {
                type: 'edit',
                geometryType: GeometryType.POINT,
              },
            });
            break;
          }

          case 'aerial': {
            this.setState({
              p1Coordinates: {
                ...p1Coordinates,
                x: coordinates[0],
                y: coordinates[1],
              },
              drawingSettings: {
                type: 'edit',
                geometryType: GeometryType.POINT,
              },
            });
            break;
          }

          default:
            break;
        }

        return;
      }

      if (id === 'P2') {
        const { coordinates } = geometry as any;

        switch (viewDisplayType) {
          case 'image': {
            this.setState({
              p2Coordinates: {
                ...p2Coordinates,
                pixelX: coordinates[0],
                pixelY: coordinates[1],
              },
              drawingSettings: {
                type: 'edit',
                geometryType: GeometryType.POINT,
              },
            });
            break;
          }

          case 'aerial': {
            this.setState({
              p2Coordinates: {
                ...p2Coordinates,
                x: coordinates[0],
                y: coordinates[1],
              },
              drawingSettings: {
                type: 'edit',
                geometryType: GeometryType.POINT,
              },
            });
            break;
          }

          default:
            break;
        }
      }
    }
  };

  private handleFeatureEdit = (featureCollection: GeoJSONFeatureCollection) => {
    const { features } = featureCollection;

    features.forEach((feature) => {
      const { properties, geometry } = feature;
      const { p1Coordinates, p2Coordinates, viewDisplayType } = this.state;

      if (properties?.id && geometry.type === 'Point') {
        const { id } = properties;

        if (id === 'P1') {
          const { coordinates } = geometry as any;

          switch (viewDisplayType) {
            case 'image': {
              this.setState({
                p1Coordinates: {
                  ...p1Coordinates,
                  pixelX: coordinates[0],
                  pixelY: coordinates[1],
                },
              });
              break;
            }

            case 'aerial': {
              this.setState({
                p1Coordinates: {
                  ...p1Coordinates,
                  x: coordinates[0],
                  y: coordinates[1],
                },
              });
              break;
            }

            default:
              break;
          }

          return;
        }

        if (id === 'P2') {
          const { coordinates } = geometry as any;

          switch (viewDisplayType) {
            case 'image': {
              this.setState({
                p2Coordinates: {
                  ...p2Coordinates,
                  pixelX: coordinates[0],
                  pixelY: coordinates[1],
                },
              });
              break;
            }

            case 'aerial': {
              this.setState({
                p2Coordinates: {
                  ...p2Coordinates,
                  x: coordinates[0],
                  y: coordinates[1],
                },
              });
              break;
            }

            default:
              break;
          }
        }
      }
    });
  };

  private handleDrawingAlign = async () => {
    const {
      uploadCoordinateType,
      imageParameters,
      p1Coordinates,
      p2Coordinates,
    } = this.state;

    if (!imageParameters || !uploadCoordinateType) {
      console.error(
        'Image parameters and coordinate system must be specified to align drawing.'
      );

      return;
    }

    if (
      p1Coordinates.pixelX === undefined ||
      p1Coordinates.pixelY === undefined ||
      p1Coordinates.x === undefined ||
      p1Coordinates.y === undefined ||
      p2Coordinates.pixelX === undefined ||
      p2Coordinates.pixelY === undefined ||
      p2Coordinates.x === undefined ||
      p2Coordinates.y === undefined
    ) {
      console.error('Point coordinates must be specified before alignment');

      return;
    }

    const { width, height } = imageParameters;
    const pixelCenter = [width / 2.0, -height / 2.0];

    if (uploadCoordinateType === 'arbitrary') {
      const { x: x1, y: y1, pixelX: px1, pixelY: py1 } = p1Coordinates;
      const { x: x2, y: y2, pixelX: px2, pixelY: py2 } = p2Coordinates;

      const scale_fraction = {
        numerator: (x2 - x1) * (px2 - px1) + (y2 - y1) * (py2 - py1),
        denominator: (px2 - px1) * (px2 - px1) + (py2 - py1) * (py2 - py1),
      };
      const pixelScale = scale_fraction.numerator / scale_fraction.denominator;
      const scale =
        Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2) /
        Math.sqrt((px2 - px1) ** 2 + (py2 - py1) ** 2);

      const skew_fraction = {
        numerator: pixelScale * (py2 - py1) - (y2 - y1),
        denominator: px2 - px1,
      };
      const skew = skew_fraction.numerator / skew_fraction.denominator;

      const offset = [
        x1 - px1 * pixelScale - py1 * skew,
        y1 + px1 * skew - py1 * pixelScale,
      ];

      const effectiveCenter = [
        pixelCenter[0] * pixelScale + pixelCenter[1] * skew + offset[0],
        pixelCenter[1] * pixelScale - pixelCenter[0] * skew + offset[1],
      ];

      this.setState({
        alignParameters: {
          scale,
          center: {
            x: effectiveCenter[0],
            y: effectiveCenter[1],
          },
          _imageDimensions: {
            x: width,
            y: height,
          },
          // negative sign on gt[2], and gt[5], to account for y-axis being negative
          _geotransform: [
            offset[0],
            pixelScale,
            -skew,
            offset[1],
            -skew,
            -pixelScale,
          ],
          opacity: 0.5, // default value of opacity
          rotation: Math.atan2(skew, pixelScale),
        },
        viewDisplayType: 'combined',
      });

      return;
    }

    console.error('Unsupported coordinate type!');
  };

  private handleDrawingFinalize = () => {
    const { drawingId, actionType, showSnackbar, form } = this.props;
    const { alignParameters, drawingFile, imageParameters } = this.state;

    if (
      alignParameters &&
      drawingFile &&
      imageParameters &&
      ((actionType === 'edit' && drawingId) || actionType === 'new')
    ) {
      const { scale, rotation, _imageDimensions, center } = alignParameters;
      const { downscaleFactor } = imageParameters;

      const { x, y } = _imageDimensions;
      const pixelCenter = [x / 2.0, -y / 2.0];

      const skew = Math.sin(rotation) * scale;
      const pixelScale = Math.cos(rotation) * scale;
      const offset = [
        center.x - (pixelCenter[0] * pixelScale + pixelCenter[1] * skew),
        center.y - (pixelCenter[1] * pixelScale - pixelCenter[0] * skew),
      ];

      const transform: DesignTransform = {
        offsetX: offset[0],
        scaleX: pixelScale / downscaleFactor,
        skewX: -skew / downscaleFactor,
        offsetY: offset[1],
        skewY: -skew / downscaleFactor,
        scaleY: -pixelScale / downscaleFactor,
      };

      form.validateFields(['name', 'date'], (err, values) => {
        if (err) {
          return;
        }

        const { name, date } = values;

        if (name === null || name.length === 0) {
          if (showSnackbar) {
            showSnackbar({
              type: 'error',
              body: 'Name must be specified to process the drawing.',
            });
          }

          return;
        }

        if (date === null) {
          if (showSnackbar) {
            showSnackbar({
              type: 'error',
              body: 'Date must be specified to process the drawing.',
            });
          }

          return;
        }

        this.setState({ loading: true }, async () => {
          if (actionType === 'new') {
            await this.createAndProcessDrawing(
              transform,
              { name, date },
              drawingFile
            ).finally(() => {
              this.setState({ loading: false });
            });

            return;
          }

          if (drawingId) {
            await this.processDrawing(
              transform,
              { name, date, drawingId },
              drawingFile
            ).finally(() => {
              this.setState({ loading: false });
            });
          }
        });
      });
    }
  };

  // Render Functions ------------------------------------------------------------

  public getOverlayView = (): ViewType | undefined => {
    const { views } = this.props;
    const publishedViews = views?.filter((v) => v.certifiedForDisplay);

    if (!publishedViews || publishedViews.length === 0) {
      return undefined;
    }

    return publishedViews
      .sort((a: ViewType, b: ViewType) => {
        const date1 = new Date(a.date);
        const date2 = new Date(b.date);

        if (date1 > date2) {
          return 1;
        }

        if (date1 < date2) {
          return -1;
        }

        return 0;
      })
      .reverse() // ensure sorting is in descending order
      .find(
        (view) =>
          view.type === 'map' &&
          view.subType === 'aerial' &&
          view.certifiedForDisplay === true
      );
  };

  public isButtonDisabled = (contentType: ContentType): boolean => {
    const { drawing } = this.props;
    const { drawingFile, viewDisplayType, p1Coordinates, p2Coordinates } =
      this.state;

    switch (contentType) {
      case 'drawing_file_select': {
        if (drawingFile || !this.getOverlayView()) {
          return true;
        }

        return false;
      }

      case 'mark_point_1_button': {
        if (
          viewDisplayType === 'aerial' &&
          p1Coordinates.x !== undefined &&
          p1Coordinates.y !== undefined
        ) {
          return true;
        }

        if (
          viewDisplayType === 'image' &&
          p1Coordinates.pixelX !== undefined &&
          p1Coordinates.pixelY !== undefined
        ) {
          return true;
        }

        return false;
      }

      case 'mark_point_2_button': {
        if (
          viewDisplayType === 'aerial' &&
          p2Coordinates.x !== undefined &&
          p2Coordinates.y !== undefined
        ) {
          return true;
        }

        if (
          viewDisplayType === 'image' &&
          p2Coordinates.pixelX !== undefined &&
          p2Coordinates.pixelY !== undefined
        ) {
          return true;
        }

        return false;
      }

      case 'edit_drawing':
      case 'update_drawing': {
        if (this.isProcessing() || drawing?.published) {
          return true;
        }

        return false;
      }

      default:
        return false;
    }
  };

  public shouldDisplayContent = (contentType: ContentType): boolean => {
    const { actionType, drawing } = this.props;
    const {
      drawingFile,
      uploadCoordinateType,
      p1Coordinates,
      p2Coordinates,
      alignParameters,
      viewDisplayType,
    } = this.state;

    switch (contentType) {
      case 'drawing_file_select': {
        if (actionType === 'new') {
          return true;
        }

        if (
          actionType === 'edit' &&
          (drawing?.status === 'error' || drawing?.status === null)
        ) {
          return true;
        }

        return false;
      }

      case 'coordinate_system_select': {
        // only allow arbitrary coordinates for now
        // if (drawingFile) {
        //   return true;
        // }

        return false;
      }

      case 'marking_help':
      case 'mark_point_1_button': {
        if (drawingFile && uploadCoordinateType && !alignParameters) {
          return true;
        }

        return false;
      }

      case 'point_1_coordinates': {
        if (uploadCoordinateType && uploadCoordinateType === 'local') {
          return true;
        }

        return false;
      }

      case 'mark_point_2_button': {
        if (uploadCoordinateType && !alignParameters) {
          // mark second point, only if first one has been marked for the current view
          switch (viewDisplayType) {
            case 'image': {
              if (
                p1Coordinates.pixelX !== undefined &&
                p1Coordinates.pixelY !== undefined
              ) {
                return true;
              }

              break;
            }

            case 'aerial': {
              if (
                p1Coordinates.x !== undefined &&
                p1Coordinates.y !== undefined
              ) {
                return true;
              }

              break;
            }

            default:
              break;
          }
        }

        return false;
      }

      case 'point_2_coordinates': {
        if (
          uploadCoordinateType &&
          uploadCoordinateType === 'local' &&
          p1Coordinates.pixelX !== undefined &&
          p1Coordinates.pixelY !== undefined
        ) {
          return true;
        }

        return false;
      }

      case 'view_switch': {
        if (uploadCoordinateType && uploadCoordinateType === 'arbitrary') {
          // allow view switch only if both points are marked for the current view
          switch (viewDisplayType) {
            case 'image': {
              if (
                p1Coordinates.pixelX !== undefined &&
                p2Coordinates.pixelY !== undefined &&
                p2Coordinates.pixelX !== undefined &&
                p2Coordinates.pixelY !== undefined
              ) {
                return true;
              }

              break;
            }

            case 'aerial': {
              if (
                p1Coordinates.x !== undefined &&
                p1Coordinates.y !== undefined &&
                p2Coordinates.x !== undefined &&
                p2Coordinates.y !== undefined
              ) {
                return true;
              }

              break;
            }

            default:
              break;
          }
        }

        return false;
      }

      case 'align_drawing': {
        if (
          uploadCoordinateType &&
          p1Coordinates.pixelX !== undefined &&
          p2Coordinates.pixelY !== undefined &&
          p1Coordinates.x !== undefined &&
          p1Coordinates.y !== undefined &&
          p2Coordinates.pixelX !== undefined &&
          p2Coordinates.pixelY !== undefined &&
          p2Coordinates.x !== undefined &&
          p2Coordinates.y !== undefined &&
          alignParameters === undefined
        ) {
          return true;
        }

        return false;
      }

      case 'reset_align': {
        if (alignParameters) {
          return true;
        }

        return false;
      }

      case 'parameter_finetuning':
      case 'edit_drawing':
      case 'process_drawing': {
        if (
          uploadCoordinateType &&
          p1Coordinates.pixelX !== undefined &&
          p2Coordinates.pixelY !== undefined &&
          p1Coordinates.x !== undefined &&
          p1Coordinates.y !== undefined &&
          p2Coordinates.pixelX !== undefined &&
          p2Coordinates.pixelY !== undefined &&
          p2Coordinates.x !== undefined &&
          p2Coordinates.y !== undefined &&
          alignParameters !== undefined
        ) {
          return true;
        }

        return false;
      }

      case 'update_drawing': {
        if (actionType === 'edit' && alignParameters === undefined) {
          return true;
        }

        return false;
      }

      case 'publish_drawing': {
        if (actionType === 'edit' && drawing?.status === 'completed') {
          return true;
        }

        return false;
      }

      default:
        return false;
    }
  };

  public renderInputParameters() {
    const { drawing, form } = this.props;
    const {
      uploadCoordinateType,
      p1Coordinates,
      p2Coordinates,
      alignParameters,
      drawingDate,
      defaultName,
      loading,
    } = this.state;
    const { getFieldDecorator } = form;
    const defaultDrawingName = drawing?.name || defaultName;

    return (
      <>
        <div>
          <div
            className={
              this.shouldDisplayContent('drawing_file_select')
                ? style.bottomBorder
                : ''
            }
          >
            {this.shouldDisplayContent('drawing_file_select') ? (
              <div className={style.inputField}>
                <Button
                  className={style.button}
                  disabled={this.isButtonDisabled('drawing_file_select')}
                  onClick={() => this.setState({ uploadDrawingModal: true })}
                >
                  Upload Drawing File
                </Button>
              </div>
            ) : (
              <></>
            )}
            {this.shouldDisplayContent('coordinate_system_select') ? (
              <Select
                placeholder="Select upload coordinate type"
                value={uploadCoordinateType}
                className={style.inputField}
                onChange={(value: 'local' | 'arbitrary') => {
                  const { p1Coordinates, p2Coordinates } = this.state;

                  this.setState({
                    uploadCoordinateType: value,
                    // reset x, y coordinates on coordinate type change
                    p1Coordinates: {
                      ...p1Coordinates,
                      x: undefined,
                      y: undefined,
                    },
                    p2Coordinates: {
                      ...p2Coordinates,
                      x: undefined,
                      y: undefined,
                    },
                  });
                }}
              >
                <Option value="local">Local Coordinate System</Option>
                <Option value="arbitrary">Arbitrary Coordinate System</Option>
              </Select>
            ) : (
              <></>
            )}
          </div>
          <div
            className={
              this.shouldDisplayContent('marking_help')
                ? style.bottomBorder
                : ''
            }
          >
            {this.shouldDisplayContent('marking_help') ? (
              <>
                <div className={style.inputField}>
                  <span className={style.infoDisplay}>
                    To align the Drawing with the Aerial Map, mark two points
                    which represent the same locations on each of them. The
                    points should preferably be as far away from each other as
                    possible.
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
            {this.shouldDisplayContent('mark_point_1_button') ? (
              <div className={style.inputField}>
                <Button
                  className={style.button}
                  disabled={this.isButtonDisabled('mark_point_1_button')}
                  onClick={() => {
                    const { p1Coordinates, viewDisplayType } = this.state;

                    if (
                      (viewDisplayType === 'image' &&
                        p1Coordinates.pixelX !== undefined &&
                        p1Coordinates.pixelY !== undefined) ||
                      (viewDisplayType === 'aerial' &&
                        p1Coordinates.x !== undefined &&
                        p1Coordinates.y !== undefined)
                    ) {
                      // prevent re-marking
                      return;
                    }

                    this.setState({
                      drawingSettings: {
                        type: 'create_and_edit',
                        geometryType: GeometryType.POINT,
                        name: 'P1',
                      },
                    });
                  }}
                >
                  Mark Point 1
                </Button>
              </div>
            ) : (
              <></>
            )}
            {this.shouldDisplayContent('point_1_coordinates') ? (
              <div className={style.inputField}>
                <label>Point 1 - X</label>
                <Input
                  type="number"
                  value={p1Coordinates.x}
                  placeholder="Enter the X coordinate for Point 1"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { p1Coordinates } = this.state;

                    this.setState({
                      p1Coordinates: {
                        ...p1Coordinates,
                        x: Number(e.target.value),
                      },
                    });
                  }}
                />
                <label>Point 1 - Y</label>
                <Input
                  type="number"
                  value={p1Coordinates.y}
                  placeholder="Enter the Y coordinate for Point 1"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { p1Coordinates } = this.state;

                    this.setState({
                      p1Coordinates: {
                        ...p1Coordinates,
                        y: Number(e.target.value),
                      },
                    });
                  }}
                />
              </div>
            ) : (
              <></>
            )}
            {this.shouldDisplayContent('mark_point_2_button') ? (
              <div className={style.inputField}>
                <Button
                  className={style.button}
                  disabled={this.isButtonDisabled('mark_point_2_button')}
                  onClick={() => {
                    const { p2Coordinates, viewDisplayType } = this.state;

                    if (
                      (viewDisplayType === 'image' &&
                        p2Coordinates.pixelX !== undefined &&
                        p2Coordinates.pixelY !== undefined) ||
                      (viewDisplayType === 'aerial' &&
                        p2Coordinates.x !== undefined &&
                        p2Coordinates.y !== undefined)
                    ) {
                      // prevent re-marking
                      return;
                    }

                    this.setState({
                      drawingSettings: {
                        type: 'create_and_edit',
                        geometryType: GeometryType.POINT,
                        name: 'P2',
                      },
                    });
                  }}
                >
                  Mark Point 2
                </Button>
              </div>
            ) : (
              <></>
            )}
            {this.shouldDisplayContent('point_2_coordinates') ? (
              <div className={style.inputField}>
                <label>Point 2 - X</label>
                <Input
                  type="number"
                  value={p2Coordinates.x}
                  placeholder="Enter the X coordinate for Point 2"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { p2Coordinates } = this.state;

                    this.setState({
                      p2Coordinates: {
                        ...p2Coordinates,
                        x: Number(e.target.value),
                      },
                    });
                  }}
                />
                <label>Point 2 - Y</label>
                <Input
                  type="number"
                  value={p2Coordinates.y}
                  placeholder="Enter the Y coordinate for Point 2"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { p2Coordinates } = this.state;

                    this.setState({
                      p2Coordinates: {
                        ...p2Coordinates,
                        y: Number(e.target.value),
                      },
                    });
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>

          {this.shouldDisplayContent('align_drawing') ? (
            <>
              <div className={style.inputField}>
                <Button
                  className={style.button}
                  onClick={() => this.handleDrawingAlign()}
                >
                  Align Drawing
                </Button>
                <HelpToolTip
                  position="right"
                  helpText="Vimana will create the overlay after applying the transformations."
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {this.shouldDisplayContent('reset_align') ? (
            <div className={style.inputField}>
              <Button
                className={style.button}
                loading={loading}
                onClick={() =>
                  this.setState({
                    alignParameters: undefined,
                    viewDisplayType: 'image',
                  })
                }
              >
                Reset Alignment
              </Button>
              <HelpToolTip
                position="right"
                helpText="Alignment can be restarted afresh by marking the points again."
              />
            </div>
          ) : (
            <></>
          )}
          {this.shouldDisplayContent('parameter_finetuning') ? (
            <>
              <Collapse className={style.collapseContainer}>
                <Panel header="Alignment Parameters" key="1">
                  <div className={style.collapseInputs}>
                    <label className={style.label}>Center - X</label>
                    <div className={style.input}>
                      <Input
                        type="number"
                        value={alignParameters?.center.x}
                        step={0.01}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const { alignParameters } = this.state;

                          if (alignParameters) {
                            const { center } = alignParameters;

                            this.setState({
                              alignParameters: {
                                ...alignParameters,
                                center: {
                                  ...center,
                                  x: Number(e.target.value),
                                },
                              },
                            });
                          }
                        }}
                      />
                    </div>

                    <label className={style.label}>Center - Y</label>
                    <div className={style.input}>
                      <Input
                        type="number"
                        value={alignParameters?.center.y}
                        step={0.01}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const { alignParameters } = this.state;

                          if (alignParameters) {
                            const { center } = alignParameters;

                            this.setState({
                              alignParameters: {
                                ...alignParameters,
                                center: {
                                  ...center,
                                  y: Number(e.target.value),
                                },
                              },
                            });
                          }
                        }}
                      />
                    </div>

                    <label className={style.label}>Scale</label>
                    <div className={style.input}>
                      <Input
                        type="number"
                        value={alignParameters?.scale}
                        step={0.0001}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const { alignParameters } = this.state;

                          if (alignParameters) {
                            this.setState({
                              alignParameters: {
                                ...alignParameters,
                                scale: Number(e.target.value),
                              },
                            });
                          }
                        }}
                      />
                    </div>

                    <label className={style.label}>Rotation</label>
                    <div className={style.input}>
                      <Input
                        type="number"
                        value={alignParameters?.rotation}
                        step={0.0001}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const { alignParameters } = this.state;

                          if (alignParameters) {
                            this.setState({
                              alignParameters: {
                                ...alignParameters,
                                rotation: Number(e.target.value),
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <div
                className={classnames(style.sliderField, style.bottomBorder)}
              >
                <label className={style.label}>Drawing Opacity</label>
                <Slider
                  className={style.slider}
                  defaultValue={alignParameters?.opacity}
                  min={0}
                  max={1}
                  step={0.01}
                  tooltipVisible
                  onChange={(value: number) => {
                    const { alignParameters } = this.state;

                    if (alignParameters) {
                      this.setState({
                        alignParameters: {
                          ...alignParameters,
                          opacity: value,
                        },
                      });
                    }
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {this.shouldDisplayContent('edit_drawing') ? (
            <>
              <Form.Item label="Name">
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ],
                  initialValue: defaultDrawingName,
                })(
                  <Input
                    type="text"
                    disabled={this.isButtonDisabled('edit_drawing')}
                    placeholder="Enter name of drawing"
                    className={style.input}
                  />
                )}
              </Form.Item>
              <Form.Item label="Date">
                {getFieldDecorator('date', {
                  rules: [
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ],
                  initialValue:
                    drawing && formatDateForDatePicker(drawing.date),
                })(
                  <div className={style.inputField}>
                    <DatePicker
                      className={style.button}
                      allowClear={false}
                      disabled={this.isButtonDisabled('edit_drawing')}
                      value={
                        drawingDate && formatDateForDatePicker(drawingDate)
                      }
                      placeholder="Select Date of the Drawing"
                      size="small"
                      onChange={this.handleDateChange}
                      dropdownClassName="antCalendar"
                    />
                  </div>
                )}
              </Form.Item>
            </>
          ) : (
            <></>
          )}
          {this.shouldDisplayContent('process_drawing') ? (
            <>
              <div className={style.inputField}>
                <Button
                  className={style.button}
                  loading={loading}
                  onClick={() => this.handleDrawingFinalize()}
                >
                  Finalize
                </Button>
                <HelpToolTip
                  position="right"
                  helpText="The overlay will be finalised and stored in Vimana. This process will take time and on completion, the user will be notified."
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {this.shouldDisplayContent('update_drawing') ? (
            <>
              <Collapse
                className={style.collapseContainer}
                defaultActiveKey={
                  drawing?.status === 'completed' ? ['1'] : undefined
                }
              >
                <Panel header="Drawing Details" key="1">
                  <Form.Item label="Name">
                    <div className={style.input}>
                      {getFieldDecorator('name', {
                        rules: [
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ],
                        initialValue: drawing?.name,
                      })(
                        <Input
                          type="text"
                          disabled={this.isButtonDisabled('edit_drawing')}
                          placeholder="Enter name of drawing"
                        />
                      )}{' '}
                    </div>
                  </Form.Item>

                  <Form.Item label="Date">
                    {getFieldDecorator('date', {
                      rules: [
                        {
                          required: true,
                          message: 'This field is required',
                        },
                      ],
                      initialValue:
                        drawing && formatDateForDatePicker(drawing.date),
                    })(
                      <DatePicker
                        className={style.fullWidth}
                        allowClear={false}
                        disabled={this.isButtonDisabled('edit_drawing')}
                        value={
                          drawingDate && formatDateForDatePicker(drawingDate)
                        }
                        placeholder="Select Date of the Drawing"
                        size="small"
                        onChange={this.handleDateChange}
                        dropdownClassName="antCalendar"
                      />
                    )}
                  </Form.Item>
                </Panel>
              </Collapse>
            </>
          ) : (
            <></>
          )}
          {this.shouldDisplayContent('publish_drawing') ? (
            <>
              <div className={style.inputField}>
                <Button
                  className={style.button}
                  loading={loading}
                  onClick={() => this.publishDrawing()}
                >
                  {drawing?.published
                    ? 'Un-Publish Drawing'
                    : 'Publish Drawing'}
                </Button>
                <HelpToolTip
                  position="right"
                  helpText={
                    drawing?.published
                      ? 'The overlay will be removed from the Compare tool for all stakeholders.'
                      : 'The overlay will be made available to all stakeholders in the Compare tool.'
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }

  public renderViewPreview() {
    const { projectId, aoiId, history, showCoachMarks, resetCoachMarks } =
      this.props;
    const { drawingView } = this.state;

    const overlayView = this.getOverlayView();

    if (drawingView && overlayView) {
      // overriding certified for Display flag, for preview
      drawingView.certifiedForDisplay = true;

      return (
        <>
          <div className={style.viewWrapper}>
            <View
              projectId={projectId}
              aoiId={aoiId}
              viewId={overlayView.id}
              view={overlayView}
              viewList={[overlayView, drawingView]}
              compareView={drawingView}
              controlsWhitelist={['compare']}
              onEvent={() => {}}
              history={history}
              showCoachMarks={showCoachMarks}
              resetCoachMarks={resetCoachMarks}
              coachMarkOverride={{
                url: DRAWING_EDIT_PATH,
                coachmarkSteps: [
                  { ...CONTROLS.compare, disableBeacon: true } as Step,
                ],
                timeoutDuration: 1000,
              }}
            />
          </div>
        </>
      );
    }

    return (
      <div className={style.previewText}>
        A preview of the Drawing file, will be displayed here, once it has been
        uploaded and processed.
      </div>
    );
  }

  public renderViewPanel() {
    const { drawing, actionType } = this.props;
    const {
      drawingFileDataUrl,
      imageParameters,
      drawingSettings,
      viewDisplayType,
      alignParameters,
      viewLoading,
    } = this.state;
    const view = this.getOverlayView();

    if (this.isProcessing()) {
      return (
        <div className={style.previewText}>
          Your drawing file is being processed and prepared for display.
        </div>
      );
    }

    if (!view) {
      return (
        <div className={style.previewText}>
          An Aerial Map View must be created and published, as it is required to
          create a Drawing. Please come back after creating an Aerial Map View.
        </div>
      );
    }

    if (viewLoading) {
      return <SkeletonLoader />;
    }

    if (
      actionType === 'new' ||
      (actionType === 'edit' &&
        (drawing?.status === 'error' || drawing?.status === null))
    ) {
      if (!drawingFileDataUrl || !imageParameters) {
        return (
          <div className={style.previewText}>
            Select a Drawing File to proceed with the upload process. A preview
            of the drawing file will be displayed here, once selected.
          </div>
        );
      }

      return (
        <>
          {viewDisplayType === 'combined' ? (
            <>
              <LocalDrawingMap
                designDataUrl={drawingFileDataUrl}
                imageParameters={imageParameters}
                overlayView={view}
                drawingSettings={drawingSettings}
                alignParameters={alignParameters}
                onFeatureCreate={this.handleFeatureCreate}
                onFeatureEdit={this.handleFeatureEdit}
                viewDisplayType={viewDisplayType}
              />
            </>
          ) : (
            <></>
          )}
          <Tabs
            className={classnames(
              style.tabs,
              viewDisplayType === 'combined' ? style.hidden : ''
            )}
            activeKey={viewDisplayType}
            onChange={(key: ViewDisplayType) =>
              this.setState({ viewDisplayType: key })
            }
          >
            <TabPane tab="Drawing" key="image" className={style.tabPane}>
              <LocalDrawingMap
                designDataUrl={drawingFileDataUrl}
                imageParameters={imageParameters}
                overlayView={view}
                drawingSettings={drawingSettings}
                alignParameters={alignParameters}
                onFeatureCreate={this.handleFeatureCreate}
                onFeatureEdit={this.handleFeatureEdit}
                viewDisplayType="image"
              />
            </TabPane>
            <TabPane tab="Aerial" key="aerial" className={style.tabPane}>
              <LocalDrawingMap
                designDataUrl={drawingFileDataUrl}
                imageParameters={imageParameters}
                overlayView={view}
                drawingSettings={drawingSettings}
                alignParameters={alignParameters}
                onFeatureCreate={this.handleFeatureCreate}
                onFeatureEdit={this.handleFeatureEdit}
                viewDisplayType="aerial"
              />
            </TabPane>
          </Tabs>
        </>
      );
    }

    // logic will come here, only once processing is complete
    if (drawing?.status === 'completed') {
      // TODO: implement view preview here
      return <>{this.renderViewPreview()}</>;
    }

    return <>Processing is running</>;
  }

  public renderFileUploadElements() {
    return (
      <>
        <input
          type="file"
          id="drawingFileUploadRef"
          ref={this.drawingFileUploadRef}
          key="drawingFileUploadRef"
          hidden
          multiple={false}
          accept=".jpeg,.jpg,.png"
          onChange={(events: React.ChangeEvent<HTMLInputElement>) =>
            this.handleUploadFileChange(events)
          }
        />
      </>
    );
  }

  public renderModals() {
    const { uploadDrawingModal } = this.state;

    return (
      <>
        {uploadDrawingModal ? (
          <Modal
            title="Upload Drawing File"
            centered
            visible
            footer={null}
            onOk={() => this.setState({ uploadDrawingModal: false })}
            onCancel={() => this.setState({ uploadDrawingModal: false })}
          >
            <p>
              Select the drawing file to be uploaded. The drawing file is
              expected to meet the requirements listed below:{' '}
            </p>
            <ul className={style.requirements}>
              <li>
                The drawing file must be a JPEG, or PNG file, and the height and
                width should not exceed 20,000 px.
              </li>
              <li>
                It must be exported in the form you want it to appear visually.
              </li>
              <li>
                We recommend a black background for the drawings, for better
                visualisation in Vimana.
              </li>
            </ul>

            <div className={style.fileSelectContainer}>
              <Button
                className={style.fileSelect}
                onClick={() => this.handleSelectMosaic()}
              >
                Select File
              </Button>
            </div>
          </Modal>
        ) : (
          <></>
        )}
      </>
    );
  }

  public render() {
    const { drawing, views, actionType } = this.props;

    if (!views || (!drawing && actionType === 'edit')) {
      return <SkeletonLoader />;
    }

    return (
      <>
        <div className={style.container}>
          <div className={style.inputFieldContainer}>
            {this.renderInputParameters()}
          </div>
          <div className={style.viewContainer}>{this.renderViewPanel()}</div>
        </div>
        {this.renderFileUploadElements()}
        {this.renderModals()}
      </>
    );
  }
}

export default Form.create<DrawingManagerPropsType>()(DrawingManager);
