import autobind from 'autobind-decorator';
import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Tabs } from 'antd';
import { Password } from '../Antd/Password';
import style from './UserSettings.module.scss';
import { IUpdatePayload } from '../../redux/actions/settings';
import { SnackbarActionsActionShowSnackbarTypes } from '../../shapes/snackbar';

const { TabPane } = Tabs;

interface IProps {
  isOpen: boolean;
  isUpdating: boolean;
  isPasswordUpdated: boolean;
  whiteLabelingEnabled: boolean;
  userId: string;
  closeSettings: () => void;
  updateSettings: (data: IUpdatePayload) => any;
  showSnackbar: SnackbarActionsActionShowSnackbarTypes;
  form: any;
}

interface IState {
  currentTabIndex: string;
  allowWhiteLabelling: boolean | null;
  isTextFieldsValueChanges: boolean;
}

class UserSettings extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      currentTabIndex: '1',
      allowWhiteLabelling: null,
      isTextFieldsValueChanges: false,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    const { isPasswordUpdated, showSnackbar } = nextProps;

    if (isPasswordUpdated) {
      showSnackbar({
        body: `Password has been updated successfully`,
        type: 'success',
        isModal: false,
      });
    }
  }

  @autobind
  private handleUpdate(): void {
    const { updateSettings, form, closeSettings, showSnackbar } = this.props;
    const { currentTabIndex } = this.state;

    switch (currentTabIndex) {
      case '1':
        form.validateFields(
          ['newPassword', 'currentPassword'],
          (err: any, values: any) => {
            if (!err) {
              updateSettings({
                newPassword: values.newPassword.password1,
                currentPassword: values.currentPassword,
              }).catch(({ response }: any) => {
                if (response.status === 403) {
                  showSnackbar({
                    body: 'Current password is incorrect',
                    type: 'error',
                  });
                }
              });
            }
          }
        );

        break;

      default:
        closeSettings();
        break;
    }
  }

  @autobind
  private checkPassword(rule: any, value: any, callback: any): void {
    if (!value) {
      this.onTextFieldsValueChanges(false);

      callback();

      return;
    }

    const { password1, password2 } = value;

    if (password1.length < 6) {
      callback('Password must be atleast 6 chars');

      this.onTextFieldsValueChanges(false);

      return;
    }

    if (password1 === password2) {
      this.onTextFieldsValueChanges(true);

      callback();

      return;
    }

    this.onTextFieldsValueChanges(false);

    callback('Password missmatch');
  }

  private handleTabChange = (key: string) => {
    this.setState({
      currentTabIndex: key,
    });
  };

  private onTextFieldsValueChanges = (value: boolean) => {
    this.setState({
      isTextFieldsValueChanges: value,
    });
  };

  public render(): React.ReactNode {
    const { isOpen, isUpdating, closeSettings, form } = this.props;
    const { allowWhiteLabelling, currentTabIndex, isTextFieldsValueChanges } =
      this.state;

    if (!isOpen) {
      return null;
    }

    const { getFieldDecorator } = form;

    return (
      <Modal
        title="Settings"
        className={style.container}
        visible
        onCancel={closeSettings}
        onOk={this.handleUpdate}
        okText="Update"
        okButtonProps={{
          loading: isUpdating,
          disabled: !isTextFieldsValueChanges,
        }}
        maskClosable={false}
        footer={
          (allowWhiteLabelling === null || allowWhiteLabelling === false) &&
          currentTabIndex === '2'
            ? null
            : undefined
        }
      >
        <Tabs
          centered
          tabBarStyle={{ textAlign: 'center' }}
          defaultActiveKey={currentTabIndex}
          onChange={this.handleTabChange}
        >
          <TabPane
            tab={<span className={style.usrSettingTab}>General</span>}
            key="1"
          >
            <Form layout="vertical">
              <Form.Item label="Current Password">
                {getFieldDecorator('currentPassword', {
                  rules: [
                    {
                      required: true,
                      message: "Current password can't be empty",
                    },
                  ],
                })(
                  <Input.Password
                    type="password"
                    autoComplete="off"
                    placeholder="Current Password"
                    className={style.textInput}
                  />
                )}
              </Form.Item>
              <Form.Item label="New Password">
                {getFieldDecorator('newPassword', {
                  rules: [{ required: true, validator: this.checkPassword }],
                })(<Password />)}
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}

export default Form.create()(UserSettings);
