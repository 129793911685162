import { connect } from 'react-redux';
import { getSelectedView } from '../../redux/selectors/views';
import { clearIssueState, fetchSingleIssue } from '../../redux/actions/issues';
import { setElevationValue, setVolumeType } from '../../redux/actions/volume';
import {
  fetchDownloads,
  fetchReports,
  fetchViews,
  resetCurrentView,
  setLastView,
  updateViewLayers,
  resetViewLayers,
} from '../../redux/actions/views';
import ViewV2 from '.';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { GenericObjectType } from '../../shapes/app';
import { SnackbarReducersStateSnackbarsTypes } from '../../shapes/snackbar';
import { ILayer } from '../LegendControlBox/LegendControlBox';
import MLApis from '../../api/ml';
import MLClassApis from '../../api/mlClasses';
import { CreateObjectRequest } from '../../api/ml.types';
import { VolumeType } from '../VolumeCalculator/VolumeCalculator';

const mlApis = new MLApis();
const mlClassApis = new MLClassApis();

const getUserRole = (state: any, projectId: any): any => {
  if (state && state.auth && state.auth.user) {
    const filtered = state.auth.user.project_roles.filter(
      (p: any) => p.project_id === projectId
    );

    if (filtered.length) {
      return filtered[0].role;
    }
  }

  return null;
};

const mapStateToProps = (
  state: any,
  { projectId, viewId, compareViewId, splitViewId, view: viewFromProp }: any
): any => {
  return {
    viewsList: state.views.data,
    // if view has already been provided, do not try to fetch it again
    view: viewFromProp || getSelectedView(state, { viewId }),
    compareView: getSelectedView(state, {
      viewId: compareViewId,
    }),
    splitView: getSelectedView(state, { viewId: splitViewId }),
    issue: state.issue.singleIssue,
    downloads: state.views.downloads,
    reports: state.views.reports,
    userRole: getUserRole(state, projectId),
    userData: state?.auth?.user ?? null,
  };
};

const mapDispatchToProps = (
  dispatch: any,
  { projectId, aoiId, fetchViews: _fetchViews }: any
): GenericObjectType => {
  return {
    showSnackbar: ({ ...args }: SnackbarReducersStateSnackbarsTypes) =>
      dispatch(actionShowSnackbar({ ...args })),
    fetchViews: () =>
      _fetchViews ? _fetchViews() : dispatch(fetchViews(projectId, aoiId)),
    fetchDownloads: (viewId: string) => dispatch(fetchDownloads(viewId)),
    fetchReports: (viewId: string) => dispatch(fetchReports(viewId)),
    resetCurrentView: () => dispatch(resetCurrentView()),
    fetchSingleIssue: (id: string) => dispatch(fetchSingleIssue(id)),
    clearIssueState: () => dispatch(clearIssueState()),
    setLastView: (viewId: string, splitViewId: string) =>
      dispatch(setLastView(viewId, splitViewId)),
    setElevationValue: (elevation: number | null) =>
      dispatch(setElevationValue(elevation)),
    setVolumeType: (type: VolumeType) => dispatch(setVolumeType(type)),
    updateViewLayers: (layers: ILayer[]) => dispatch(updateViewLayers(layers)),
    resetViewLayers: () => dispatch(resetViewLayers()),
    fetchSiteObjects: (viewId: string) => mlApis.getViewObjects(viewId),
    fetchSiteObjectClasses: (projectId: string) =>
      mlClassApis.getProjectSiteObjectClasses(projectId),
    deleteSiteObject: (viewId: string, objectId: string) =>
      mlApis.deleteObject(viewId, objectId),
    createSiteObject: (
      viewId: string,
      createObjectRequest: CreateObjectRequest
    ) => mlApis.createObject(viewId, createObjectRequest),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewV2);
