import classnames from 'classnames';
import React from 'react';
import { Modal } from 'antd';
import {
  EditLayerStyleRequest,
  LayerDescriptor,
  LayerType,
} from 'src/api/mapStyle.types';
import {
  useMapStyleApi,
  useMapStyleContext,
} from 'src/components/Mission/MapMaker/hooks';
import {
  EditLayerActions,
  useEditLayerRequest,
} from 'src/components/Mission/MapMaker/StyleEditor/hooks';
import style from './index.module.scss';

export interface EditLayerModalProps {
  visible: boolean;
  layer: LayerDescriptor;
  onLayerEdit: (layer?: LayerDescriptor) => void;
}

export const EditLayerModal: React.FC<EditLayerModalProps> = ({
  visible,
  layer,
  onLayerEdit,
}) => {
  const api = useMapStyleApi();
  const mapStyle = useMapStyleContext();
  const [request, actions] = useEditLayerRequest(layer);

  if (!mapStyle) return <></>;

  return (
    <Modal
      title="Edit Layer Style"
      centered
      visible={visible}
      maskClosable
      onCancel={() => onLayerEdit()}
      onOk={async () => {
        if (!mapStyle) {
          onLayerEdit();

          return;
        }

        const { projectId, aoiId, id: styleId } = mapStyle.style;

        const l = await api
          .editStyleLayer(projectId, aoiId, styleId, layer.name, request)
          .catch((e) => {
            console.error('There was an error while editing the layer.', e);

            return undefined;
          });

        if (l) onLayerEdit(l);
      }}
      destroyOnClose
      okText="Save"
    >
      <h1 className={classnames(style.borderBottom, style.paddingBottom)}>
        Layer Details ({getLayerTypeName(layer.type)})
      </h1>
      {layer.type === 'raster' ? (
        <RasterLayerEditing request={request} actions={actions} />
      ) : (
        <VectorLayerEditing request={request} actions={actions} />
      )}
    </Modal>
  );
};

export const getLayerTypeName = (type: LayerType): string => {
  switch (type) {
    case 'raster':
      return 'Raster';
    case 'vector':
      return 'Vector';
    case 'vector_tiles':
      return 'Vector Tiles';
    default:
      return type;
  }
};

export interface LayerEditingProps {
  request: EditLayerStyleRequest;
  actions: EditLayerActions;
}

export const VectorLayerEditing: React.FC<LayerEditingProps> = ({
  request,
  actions,
}) => {
  return (
    <>
      <div className={style.editLayerGrid}>
        <div>
          <label>Name</label>
          <input
            className={style.uniformInput}
            type="text"
            defaultValue={request.name}
            onChange={(e) => e.target.value && actions.setName(e.target.value)}
          />
        </div>
        <div>
          <label>Z Index</label>
          <input
            className={style.uniformInput}
            type="number"
            defaultValue={request.zIndex}
            onChange={(e) => {
              const n = Number.parseInt(e.target.value, 10);

              if (!Number.isNaN(n)) actions.setZIndex(n);
            }}
          />
        </div>
        <div>
          <label>Fill Color</label>
          <input
            className={style.uniformInput}
            type="color"
            defaultValue={request.fillColor}
            onChange={(e) =>
              e.target.value && actions.setFillColor(e.target.value)
            }
          />
        </div>
        <div>
          <label>Stroke Color</label>
          <input
            className={style.uniformInput}
            type="color"
            defaultValue={request.strokeColor}
            onChange={(e) =>
              e.target.value && actions.setStrokeColor(e.target.value)
            }
          />
        </div>
        <div>
          <label>Stroke Width</label>
          <input
            className={style.uniformInput}
            type="number"
            defaultValue={request.strokeWidth}
            onChange={(e) => {
              const n = Number.parseInt(e.target.value, 10);

              if (!Number.isNaN(n)) actions.setStrokeWidth(n);
            }}
          />
        </div>
        <div>
          <label>Feature Label Field</label>
          <input
            className={style.uniformInput}
            type="text"
            defaultValue={request.label}
            onChange={(e) => e.target.value && actions.setLabel(e.target.value)}
          />
        </div>
        <div>
          <label>Font Family</label>
          <input
            className={style.uniformInput}
            type="text"
            defaultValue={request.fontFamily}
            onChange={(e) =>
              e.target.value && actions.setFontFamily(e.target.value)
            }
          />
        </div>
        <div>
          <label>Font Color</label>
          <input
            className={style.uniformInput}
            type="color"
            defaultValue={request.fontColor}
            onChange={(e) =>
              e.target.value && actions.setFontColor(e.target.value)
            }
          />
        </div>
        <div>
          <label>Font Size</label>
          <input
            className={style.uniformInput}
            type="number"
            defaultValue={request.fontSize}
            onChange={(e) => {
              const n = Number.parseInt(e.target.value, 10);

              if (!Number.isNaN(n)) actions.setFontSize(n);
            }}
          />
        </div>
      </div>
    </>
  );
};

export const RasterLayerEditing: React.FC<LayerEditingProps> = ({
  request,
  actions,
}) => {
  return (
    <>
      <div className={style.editLayerGrid}>
        <div>
          <label>Name</label>
          <input
            className={style.uniformInput}
            type="text"
            defaultValue={request.name}
            onChange={(e) => e.target.value && actions.setName(e.target.value)}
          />
        </div>
        <div>
          <label>Z Index</label>
          <input
            className={style.uniformInput}
            type="number"
            defaultValue={request.zIndex}
            onChange={(e) => {
              const n = Number.parseInt(e.target.value, 10);

              if (!Number.isNaN(n)) actions.setZIndex(n);
            }}
          />
        </div>
      </div>
    </>
  );
};
