import * as React from 'react';

declare const Potree: any;

interface IProps {
  viewer?: any;
}

export default class PotreeStateProvider extends React.Component<IProps> {
  public getPotreeState() {
    const { viewer } = this.props;

    if (viewer?.scene?.cameraP?.position) {
      const { x, y, z } = viewer.scene.view.position;
      const { x: x2, y: y2, z: z2 } = viewer.scene.view.getPivot();
      const config = {
        x,
        y,
        z,
        x2,
        y2,
        z2,
        pointBudget: Potree?.pointBudget,
      };

      return config;
    }

    return {};
  }

  public render() {
    return <></>;
  }
}
