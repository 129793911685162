import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import styles from './index.module.scss';
import {
  PaymentsAuthorizeNetPropsTypes,
  PaymentsAuthorizeNetStateTypes,
  PaymentsAuthorizeNetInputFieldsTypes,
} from './index.types';
import { authorizeNetData } from '../data';
import AuthorizeNetApis from '../../../api/authorizeNet';
import SkeletonLoader from '../../SkeletonLoader';
import { undefinedOrNull } from '../../../utils/functs';
import AuthorizeNetInputFields from './AuthorizeNetInputFields';
import {
  GenericObjectType,
  NullOrGenericObjectType,
} from '../../../shapes/app';
import { Button } from '../../Button';
import { paymentsHistoryUrl } from '../../../routes/urls';
import Loading from '../../Loading';

const { Text } = Typography;
const authorizeNetApis = new AuthorizeNetApis();

class PaymentsAuthorizeNet extends React.PureComponent<
  PaymentsAuthorizeNetPropsTypes,
  PaymentsAuthorizeNetStateTypes
> {
  public constructor(props: PaymentsAuthorizeNetPropsTypes) {
    super(props);

    this.state = {
      exchangeRates: null,
      totalConvertedRate: '0',
      transactionData: null,
      payBtnLoading: false,
      cancelBtnLoading: false,
      isIframeLoading: false,
      transactionStatus: null,
    };
  }

  public componentDidMount(): void {
    this.initAuthorizeFrameCommunication();
    this.fetchExchangeRates();
  }

  private initAuthorizeFrameCommunication = () => {
    const AUTHORIZE_NET_KEY = 'AuthorizeNetIFrame';

    window[AUTHORIZE_NET_KEY] = {};
    window[AUTHORIZE_NET_KEY].onReceiveCommunication = (querystr: string) => {
      console.info('[payments] ', querystr);
      const values: NullOrGenericObjectType = this.parseQueryString(querystr);
      const { action, response } = values;

      switch (action) {
        case 'transactResponse':
          if (response) {
            const responseObj: NullOrGenericObjectType = JSON.parse(response);

            if (!responseObj) {
              return;
            }

            if (responseObj.transId) {
              this.handleUpdateTransaction(responseObj.transId, response);
            }
          }

          break;
        case 'cancel':
          this.handleCancelTransaction();
          break;
        default:
          break;
      }
    };
  };

  private parseQueryString(str: string): GenericObjectType {
    const vars = [];
    const arr = str.split('&');
    let pair;

    for (let i = 0; i < arr.length; i += 1) {
      pair = arr[i].split('=');
      vars.push(pair[0]);
      vars[pair[0]] = unescape(pair[1]);
    }

    return vars;
  }

  private fetchExchangeRates = () => {
    const { showSnackbar } = this.props;

    authorizeNetApis.getExchangeRates().then((res) => {
      const { error: apiError, data: apiData } = res;

      if (apiError) {
        showSnackbar({
          type: 'error',
          body: apiError,
        });

        return;
      }

      this.setState({
        exchangeRates: apiData,
      });
    });
  };

  private getDefaultValueInputFields = () => {
    return {
      amount: '',
      currency: authorizeNetData.currencyList[0],
      description: '',
    };
  };

  private validateInputFields = (
    rule: any,
    value: PaymentsAuthorizeNetInputFieldsTypes,
    callback: (error?: string) => void
  ) => {
    if (
      parseFloat(value.amount) <= 0 ||
      undefinedOrNull(value.amount) ||
      value.amount.trim() === ''
    ) {
      callback('Amount must greater than zero!');

      return;
    }

    if (undefinedOrNull(value.description) || value.description.trim() === '') {
      callback('Invoice Number (Optional) & Project Name is Mandatory');

      return;
    }

    callback();
  };

  private handleChangeInputFields = (
    value: PaymentsAuthorizeNetInputFieldsTypes
  ) => {
    this.setTotalConvertedRate(value);
  };

  private setTotalConvertedRate = (
    value: PaymentsAuthorizeNetInputFieldsTypes
  ): void => {
    const { exchangeRates } = this.state;

    if (
      undefinedOrNull(exchangeRates) ||
      undefinedOrNull(value) ||
      undefinedOrNull(value.amount) ||
      undefinedOrNull(value.currency)
    ) {
      this.setState({
        totalConvertedRate: null,
      });

      return;
    }

    this.setState({
      totalConvertedRate: (
        parseFloat(value.amount || '0') / exchangeRates.rates[value.currency]
      ).toFixed(2),
    });
  };

  private handleCreateTransaction = (): void => {
    const { showSnackbar, form } = this.props;

    form.validateFields(
      (
        err: null | GenericObjectType,
        values: {
          inputFields: PaymentsAuthorizeNetInputFieldsTypes;
        }
      ) => {
        if (!err) {
          this.setState({
            payBtnLoading: true,
            transactionStatus: null,
          });

          const formData = {
            amount: values.inputFields.amount,
            currencyCode: values.inputFields.currency,
            description: values.inputFields.description,
          };

          authorizeNetApis.createTransaction(formData).then((res) => {
            const { error: apiError, data: apiData } = res;

            if (apiError) {
              showSnackbar({
                type: 'error',
                body: apiError,
              });

              this.setState({
                transactionData: null,
                payBtnLoading: false,
                isIframeLoading: false,
              });

              return;
            }

            this.setState({
              transactionData: apiData,
              payBtnLoading: false,
              isIframeLoading: true,
            });
          });
        }
      }
    );
  };

  private handleUpdateTransaction = (
    transId: string,
    response: string
  ): void => {
    const { showSnackbar } = this.props;

    const formData = {
      providerTransactionId: transId,
      providerResponse: response,
    };

    authorizeNetApis.updateTransaction(formData).then((res) => {
      const { error: apiError, data: apiData } = res;

      if (apiError) {
        showSnackbar({
          type: 'error',
          body: apiError,
        });

        this.setState({
          isIframeLoading: false,
          transactionData: null,
          payBtnLoading: false,
          transactionStatus: 'CANCELLED',
        });

        return;
      }

      this.setState({
        isIframeLoading: false,
        transactionData: null,
        payBtnLoading: false,
        transactionStatus: apiData.status,
      });
    });
  };

  private handleCancelTransaction = (): void => {
    const { showSnackbar } = this.props;
    const { transactionData } = this.state;

    this.setState({
      cancelBtnLoading: true,
    });

    if (!transactionData) {
      this.setState({
        transactionData: null,
        payBtnLoading: false,
        cancelBtnLoading: false,
        isIframeLoading: false,
      });

      showSnackbar({
        type: 'error',
        body: 'The payment was cancelled. Try again!',
      });

      return;
    }

    const formData = {
      transactionId: transactionData.transactionId,
      reason: '',
    };

    authorizeNetApis.cancelTransaction(formData).then((res) => {
      const { error: apiError } = res;

      if (apiError) {
        showSnackbar({
          type: 'error',
          body: apiError,
        });
      }

      this.setState({
        transactionData: null,
        payBtnLoading: false,
        cancelBtnLoading: false,
        transactionStatus: 'CANCELLED',
        isIframeLoading: false,
      });

      showSnackbar({
        type: 'error',
        body: 'The payment was cancelled. Try again!',
      });
    });
  };

  private authorizeNetFormLoaded = (node: HTMLFormElement) => {
    if (node) {
      node.submit();
    }
  };

  private isTransactionStatusSuccess = (): boolean => {
    const { transactionStatus } = this.state;

    let isSuccess = true;

    switch (transactionStatus) {
      case 'INITIATED':
      case 'AUTHORIZED':
      case 'SETTLED':
        break;
      default:
        isSuccess = false;
        break;
    }

    return isSuccess;
  };

  private handleIframeLoading = (value: boolean) => {
    this.setState({
      isIframeLoading: value,
    });
  };

  private UnsuccessfulTransactionStatusRender =
    (): React.ReactElement | null => {
      const { transactionStatus } = this.state;

      let statusText = null;

      switch (transactionStatus) {
        case 'FAILED':
          statusText = 'The payment has failed. Try again!';
          break;
        case 'REVIEW':
          statusText = 'Our payment partner has put your payment on hold.';
          break;
        case 'CANCELLED':
          statusText = 'The payment was cancelled. Try again!';
          break;
        default:
          break;
      }

      if (undefinedOrNull(statusText)) {
        return null;
      }

      return (
        <div className={styles.cancelledWrapper}>
          <Text>{statusText}</Text>
        </div>
      );
    };

  public render(): React.ReactNode {
    const { form } = this.props;
    const {
      exchangeRates,
      totalConvertedRate,
      transactionData,
      payBtnLoading,
      transactionStatus,
      cancelBtnLoading,
      isIframeLoading,
    } = this.state;

    const { getFieldDecorator } = form;
    const { UnsuccessfulTransactionStatusRender } = this;

    return (
      <div
        className={classnames(styles.container, {
          [styles.formContainer]: undefinedOrNull(transactionData),
        })}
      >
        {!undefinedOrNull(transactionStatus) &&
        this.isTransactionStatusSuccess() ? (
          <div className={styles.successWrapper}>
            <div className={styles.successText}>
              <Text>The payment was successful!</Text>
            </div>
            <div className={styles.paymentHistoryPage}>
              <Link to={paymentsHistoryUrl()}>Go to Payment History</Link>
            </div>
          </div>
        ) : undefinedOrNull(exchangeRates) ? (
          <SkeletonLoader size={2} />
        ) : !undefinedOrNull(transactionData) ? (
          <div>
            <div
              className={classnames(styles.authorizeNetIframeWrapper, {
                hide: !isIframeLoading,
                [styles.loading]: true,
              })}
            >
              <Loading type="ellipsis" />
            </div>
            <iframe
              title="Authorize.net Payment"
              id="add_payment"
              name="add_payment"
              className={classnames(styles.authorizeNetIframeWrapper, {
                hide: isIframeLoading,
              })}
              onLoad={() => {
                this.handleIframeLoading(false);
              }}
            />

            <div>Note: All fields are mandatory</div>
            <div className={styles.cancelBtn}>
              <Button
                text="Cancel Payment"
                loadingText="Cancelling Payment..."
                loading={cancelBtnLoading}
                onClick={() => {
                  this.handleCancelTransaction();
                }}
              />
            </div>

            <form
              action={transactionData.url}
              method="post"
              target="add_payment"
              className={styles.authorizeNetIframeForm}
              ref={this.authorizeNetFormLoaded}
            >
              <input
                type="text"
                name="token"
                value={transactionData.token}
                readOnly
              />
              <input type="submit" value="Submit" />
            </form>
          </div>
        ) : (
          <div>
            {<UnsuccessfulTransactionStatusRender />}
            <Form>
              <Form.Item label="Enter details">
                {getFieldDecorator('inputFields', {
                  initialValue: this.getDefaultValueInputFields(),
                  rules: [{ validator: this.validateInputFields }],
                })(
                  <AuthorizeNetInputFields
                    onChange={this.handleChangeInputFields}
                  />
                )}
              </Form.Item>
              <div className={styles.payTotalWrapper}>
                <Text>
                  {undefinedOrNull(totalConvertedRate)
                    ? `Invalid amount`
                    : `Pay Total: ${totalConvertedRate} ${exchangeRates.base}`}
                </Text>
              </div>
              <Form.Item>
                <Button
                  fullWidth
                  type="primary"
                  text="Pay"
                  loading={payBtnLoading}
                  loadingText="Processing Payment..."
                  onClick={() => {
                    this.handleCreateTransaction();
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

export default Form.create()(PaymentsAuthorizeNet);
