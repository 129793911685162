import * as React from 'react';
import styles from './index.module.scss';
import {
  ContextMenuMenuOptionsTypes,
  ContextMenuPropsTypes,
  ContextMenuStatesTypes,
} from './index.types';
import { undefinedOrNull } from '../../utils/functs';
import ConfirmPopup from '../ConfirmPopup';

class ContextMenu extends React.PureComponent<
  ContextMenuPropsTypes,
  ContextMenuStatesTypes
> {
  public constructor(props: ContextMenuPropsTypes) {
    super(props);

    this.state = {
      position: null,
    };
  }

  public componentDidMount(): void {
    this.registerEvents();
  }

  public componentDidUpdate({
    instanceId: prevInstanceId,
  }: Readonly<ContextMenuPropsTypes>) {
    const { instanceId } = this.props;

    if (instanceId !== prevInstanceId) {
      // reset context menu if instanceId changes
      this.setState({ position: null });
    }
  }

  public componentWillUnmount(): void {
    this.deregisterEvents();
  }

  private node = (): Element | Window => {
    const { nodeSelector } = this.props;

    if (undefinedOrNull(nodeSelector)) {
      return window;
    }

    const node = document.querySelector(nodeSelector);

    if (undefinedOrNull(node)) {
      return window;
    }

    return node;
  };

  private registerEvents = () => {
    this.node().addEventListener('contextmenu', (event: MouseEvent) => {
      event.preventDefault();

      this.setState({
        position: {
          x: event.clientX,
          y: event.clientY,
        },
        event,
      });

      this.node().addEventListener('click', this.activateClickToClearEvent);
    });
  };

  private deregisterEvents = () => {
    this.node().removeEventListener('contextmenu', (_) => {
      /**/
    });
    this.node().removeEventListener('click', this.activateClickToClearEvent);
  };

  private activateClickToClearEvent = () => {
    this.node().removeEventListener('click', this.activateClickToClearEvent);

    this.setState({
      position: null,
      event: undefined,
    });
  };

  private handleBtnClicks = (
    item: ContextMenuMenuOptionsTypes,
    confirmActionValue?: boolean
  ) => {
    const { event } = this.state;

    this.setState(
      {
        position: null,
        event: undefined,
      },
      () => {
        item.onClick(item.name, confirmActionValue, event || undefined);
      }
    );
  };

  public RenderButtonItem = ({
    item,
    allowClickThrough = true,
  }: {
    item: ContextMenuMenuOptionsTypes;
    allowClickThrough?: boolean;
  }) => {
    return (
      <button
        className={styles.menuItem}
        disabled={item.disabled}
        onClick={() => {
          if (!allowClickThrough) {
            return;
          }

          this.handleBtnClicks(item);
        }}
      >
        {item.label}
      </button>
    );
  };

  public render(): React.ReactNode {
    const { position } = this.state;
    const { menuOptionsList } = this.props;

    const { RenderButtonItem } = this;

    if (!position || !menuOptionsList || menuOptionsList.length < 1) {
      return null;
    }

    return (
      <div
        className={styles.container}
        style={{
          top: position.y,
          left: position.x,
        }}
      >
        {menuOptionsList.map((a) => {
          if (a.confirmAction) {
            return (
              <ConfirmPopup
                key={a.name}
                {...a.confirmAction}
                onModalClose={(value) => {
                  this.handleBtnClicks(a, value);
                }}
              >
                <RenderButtonItem item={a} allowClickThrough={false} />
              </ConfirmPopup>
            );
          }

          return <RenderButtonItem key={a.name} item={a} />;
        })}
      </div>
    );
  }
}

export default ContextMenu;
