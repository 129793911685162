import classnames from 'classnames';
import * as React from 'react';
import GeoJSON from 'ol/format/GeoJSON';
import GeometryType from 'ol/geom/GeometryType';

import MLObjectAPI from '../../../../api/ml';
import { CreateObjectRequest } from '../../../../api/ml.types';
import SiteObjectsControl from '../../MapView/OpenLayersRenderer/SiteObjectsControl';
import * as ControlBox from '../../../ControlBox';
import ModalNotification from '../../../ModalNotification/ModalNotification';
import { Button } from '../../../Button';
import style from './index.module.scss';
import {
  ObjectSubType,
  ObjectType,
  SiteObject,
  SiteObjectClass,
} from '../../../../shapes/ml';
import { View } from '../../../../api/views.types';
import {
  SiteObjectsChanged,
  HighlightImageListChanged,
  HighlightImageDetails,
} from '../../index.types';
import {
  SiteObjectsControlBoxPropsType,
  SiteObjectsControlBoxStateType,
  ManageObjectsIntent,
} from './index.types';

class SiteObjectsControlBox extends React.Component<
  SiteObjectsControlBoxPropsType,
  SiteObjectsControlBoxStateType
> {
  private mlAPI = new MLObjectAPI();

  constructor(props: SiteObjectsControlBoxPropsType) {
    super(props);

    this.state = {
      objectType: null,
      objectSubType: null,
    };
  }

  public componentDidMount() {
    const { projectMLClasses: projectSiteClasses } = this.props;

    const typeSet = new Set();

    projectSiteClasses.forEach((cls) => {
      typeSet.add(cls.type);
    });

    if (typeSet.values.length === 1) {
      // default type, if only one has been selected for the project
      this.setState({
        objectType: typeSet.values[0],
      });
    }
  }

  public componentDidUpdate({
    siteObjects: oldSiteObjects,
    rendererState: oldRendererState,
  }: SiteObjectsControlBoxPropsType) {
    const { siteObjects: newSiteObjects, rendererState: newRendererState } =
      this.props;

    if (newSiteObjects !== oldSiteObjects) {
      this.updateObjectDisplay();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loading: false,
      });
    } else if (
      newRendererState?.currentImage?.guid &&
      newRendererState.currentImage.guid !==
        oldRendererState?.currentImage?.guid
    ) {
      // update objects when the image selection changes
      this.updateObjectDisplay();
    }
  }

  public componentWillUnmount() {
    const { onRenderFragChange } = this.props;

    onRenderFragChange(undefined);
  }

  public getSubTypeName = (
    objectType: ObjectType,
    objectSubType: ObjectSubType
  ): string => {
    const { projectMLClasses: projectSiteClasses } = this.props;

    const siteClass = projectSiteClasses.find(
      (cls) => cls.type === objectType && cls.subType === objectSubType
    );

    return siteClass ? siteClass.name : 'N/A';
  };

  private getObjectsGeoJsonForDisplay = (
    siteObjects: SiteObject[],
    objectType: ObjectType,
    objectSubType: ObjectSubType,
    image?: { guid?: string; imageWidth?: number; imageHeight?: number }
  ): { type: 'FeatureCollection'; features: any[] } => {
    const filteredSiteObjects = siteObjects.filter((so) => {
      if (image?.guid && so.imageId !== image?.guid) {
        // accounting for optional imageId filter
        return false;
      }

      // fall-through when no image filter / successful image filter
      return (
        so.type === objectType && so.subType === objectSubType && !so.deleted
      );
    });
    const featuresList = filteredSiteObjects.map((so) => {
      const feature = JSON.parse(so.finalFeatureGeoJSON);
      const { geometry } = feature;

      feature.properties = feature.properties || {};
      feature.properties.id = so.id;
      feature.properties.source = so.source;

      if (
        image &&
        image?.imageHeight !== undefined &&
        image?.imageWidth !== undefined
      ) {
        const { imageWidth, imageHeight } = image;

        // scaling features for display
        if (geometry.type === GeometryType.MULTI_POLYGON) {
          geometry.coordinates = geometry.coordinates.map(
            (polygon: number[][][]) =>
              this.scalePolygonCoordinates(polygon, imageWidth, imageHeight)
          );
        } else if (geometry.type === GeometryType.POLYGON) {
          geometry.coordinates = this.scalePolygonCoordinates(
            geometry.coordinates,
            imageWidth,
            imageHeight
          );
        } else {
          console.error('Unsupported Geometry Type: ', geometry.type, so);
        }
      }

      return feature;
    });

    return {
      type: 'FeatureCollection',
      features: featuresList,
    };
  };

  private getDrawModeForIntent = (intent?: ManageObjectsIntent) => {
    switch (intent) {
      case 'create_object':
        return 'Polygon';
      case 'delete_object':
        return 'Select';
      default:
        return 'View';
    }
  };

  private isImageViewWithDetect(view: View): boolean {
    switch (view.type) {
      case 'perspective':
      case 'site_navigation':
      case 'inspection':
        return true;

      default:
        return false;
    }
  }

  private updateObjectDisplay = () => {
    const { siteObjects, onRenderFragChange, view, rendererState } = this.props;
    const { objectType, objectSubType, manageObjectsIntent } = this.state;
    const image =
      (this.isImageViewWithDetect(view) && rendererState?.currentImage) ||
      undefined;

    if (objectType && objectSubType) {
      onRenderFragChange(() => {
        return (
          <SiteObjectsControl
            shapeGeoJson={this.getObjectsGeoJsonForDisplay(
              siteObjects,
              objectType,
              objectSubType,
              image
            )}
            drawMode={this.getDrawModeForIntent(manageObjectsIntent)}
            onFeatureCreate={this.onFeatureCreate}
            onFeatureSelect={this.onFeatureSelect}
            geoJsonWriter={
              this.isImageViewWithDetect(view) ? new GeoJSON() : undefined
            }
          />
        );
      });
    } else {
      onRenderFragChange(undefined);
    }
  };

  private updateHighlightImageList = () => {
    const { siteObjects, onEvent, view } = this.props;
    const { objectType, objectSubType } = this.state;

    if (this.isImageViewWithDetect(view) && objectType && objectSubType) {
      const imageList: HighlightImageDetails[] = siteObjects
        .filter(
          (so) =>
            so.type === objectType &&
            so.subType === objectSubType &&
            !so.deleted &&
            so.imageId
        )
        .map((so) => ({
          guid: so.imageId as string,
        }));

      onEvent(new HighlightImageListChanged(imageList));

      return;
    }

    onEvent(new HighlightImageListChanged(undefined));
  };

  private scalePolygonCoordinates = (
    polygon: number[][][],
    xScale: number,
    yScale: number,
    inverse: boolean = false
  ): number[][][] => {
    return polygon.map((ring: number[][]) =>
      ring.map((point: number[]) => {
        // scale coordinates, and account for the fact that top-left is considered origin
        if (inverse) {
          return [point[0] / xScale, (point[1] * -1.0) / yScale];
        }

        return [point[0] * xScale, point[1] * -1.0 * yScale];
      })
    );
  };

  // Event handling functions -----------------

  private handleTypeChange = (type: ObjectType | null) => {
    this.setState(
      {
        objectType: type,
      },
      () => {
        this.updateObjectDisplay();
        this.updateHighlightImageList();
      }
    );
  };

  private handleSubTypeChange = (subType: ObjectSubType | null) => {
    this.setState(
      {
        objectSubType: subType,
      },
      () => {
        this.updateObjectDisplay();
        this.updateHighlightImageList();
      }
    );
  };

  private resetModals = () => {
    this.setState(
      {
        shape: undefined,
        manageObjectsIntent: undefined,
        confirmCreateModal: false,
        confirmDeleteModal: false,
      },
      () => {
        this.updateObjectDisplay();
      }
    );
  };

  private onFeatureCreate = (shape: { features: any[] }) => {
    if (shape.features.length) {
      this.setState({
        shape: shape.features[0],
        confirmCreateModal: true,
      });
    }
  };

  private onFeatureSelect = (shape: { features: any[] }) => {
    if (shape.features.length) {
      this.setState({
        shape: shape.features[0],
        confirmDeleteModal: true,
      });
    }
  };

  private handleFeatureCreateConfirmation = (ok?: boolean) => {
    // TODO: add logic to delete an object
    const { shape, objectType, objectSubType } = this.state;
    const { onEvent, view, rendererState } = this.props;

    if (ok && shape && objectSubType && objectSubType) {
      const feature = { ...shape };

      feature.properties.label = objectSubType;

      if (this.isImageViewWithDetect(view)) {
        if (
          !(
            rendererState?.currentImage?.guid &&
            rendererState?.currentImage?.imageHeight &&
            rendererState?.currentImage?.imageWidth
          )
        ) {
          console.error(
            'Current image missing metadata required to create object.',
            rendererState?.currentImage
          );
          this.resetModals();
          this.setState({ loading: false });

          return;
        }

        const { geometry } = feature;
        const { imageHeight, imageWidth } = rendererState.currentImage;

        // inverse-scaling for object creation
        if (geometry.type === GeometryType.MULTI_POLYGON) {
          geometry.coordinates = geometry.coordinates.map(
            (polygon: number[][][]) =>
              this.scalePolygonCoordinates(
                polygon,
                imageWidth,
                imageHeight,
                true
              )
          );
        } else if (geometry.type === GeometryType.POLYGON) {
          geometry.coordinates = this.scalePolygonCoordinates(
            geometry.coordinates,
            imageWidth,
            imageHeight,
            true
          );
        } else {
          console.error('Unsupported Geometry Type: ', geometry.type);
          this.resetModals();
          this.setState({ loading: false });

          return;
        }
      }

      const createSiteObjectRequest: CreateObjectRequest = {
        viewId: view.id,
        source: 'manual',
        type: objectType,
        subType: objectSubType,
        feature: JSON.stringify(feature),
        imageId:
          (this.isImageViewWithDetect(view) &&
            rendererState?.currentImage?.guid) ||
          undefined,
      };

      this.setState(
        {
          loading: true,
        },
        () => {
          this.mlAPI
            .createObject(view.id, createSiteObjectRequest)
            .then((res) => {
              const { error, data } = res;

              if (error) {
                console.error(
                  'There was an error while creating the site object.',
                  error
                );
                this.setState({
                  loading: false,
                });

                return;
              }

              // trigger fetch of ML objects again
              onEvent(new SiteObjectsChanged(data));
            })
            .finally(() => {
              this.resetModals();
            });
        }
      );
    } else {
      // resetting state
      this.resetModals();
    }
  };

  private handleFeatureDeleteConfirmation = (ok?: boolean) => {
    // TODO: add logic to delete an object
    const { shape } = this.state;
    const { onEvent, view } = this.props;

    if (ok && shape) {
      const objectId = shape.properties.id;

      if (!objectId) {
        console.error('Could not get Id for object to be deleted', shape);
        this.resetModals();
        this.setState({ loading: false });

        return;
      }

      this.setState(
        {
          loading: true,
        },
        () => {
          this.mlAPI
            .deleteObject(view.id, objectId)
            .then((res) => {
              const { error, data } = res;

              if (error) {
                console.error(
                  'There was an error while deleting the site object.',
                  error
                );
                this.setState({
                  loading: false,
                });

                return;
              }

              // trigger fetch of ML objects again
              onEvent(new SiteObjectsChanged(data));
            })
            .finally(() => {
              this.resetModals();
            });
        }
      );
    } else {
      this.resetModals();
    }
  };

  //  Render functions ------------------------------

  private renderObjectSubType = ({
    label,
    active = true,
    disable = false,
    onClick,
  }: any): JSX.Element => (
    <div
      onClick={() => {
        if (disable || !onClick) {
          return;
        }

        onClick();
      }}
      className={classnames(style.intent, {
        [style.active]: active,
        [style.disable]: disable,
      })}
    >
      {label}
    </div>
  );

  public renderObjectTypeSelection = (): JSX.Element | null => {
    const { projectMLClasses: projectSiteClasses } = this.props;
    const { objectType } = this.state;
    const { renderObjectSubType: RenderIntent } = this;

    const typeSet = new Set<string>();

    projectSiteClasses.forEach((cls) => {
      typeSet.add(cls.type);
    });

    return (
      <div className={style.intentList}>
        {Array.from(typeSet).map((cls: string) => (
          <RenderIntent
            active={objectType === cls}
            key={cls}
            label={cls}
            onClick={() => {
              this.handleTypeChange(cls);
            }}
          />
        ))}
      </div>
    );
  };

  public renderObjectSubTypeSelection = (): JSX.Element | null => {
    const { projectMLClasses: projectSiteClasses } = this.props;
    const { objectType, objectSubType } = this.state;
    const { renderObjectSubType: RenderIntent } = this;

    const siteClasses = projectSiteClasses.filter(
      (cls) => cls.type === objectType
    );

    return (
      <div className={style.intentList}>
        {siteClasses.map((cls: SiteObjectClass) => (
          <RenderIntent
            active={objectSubType === cls.subType}
            key={cls.id}
            label={cls.name}
            onClick={() => {
              this.handleSubTypeChange(cls.subType);
            }}
          />
        ))}
      </div>
    );
  };

  private renderHeader = (): React.ReactElement<React.ReactNode> => {
    const { onClose } = this.props;
    const { objectType, objectSubType } = this.state;

    return (
      <React.Fragment>
        {objectType || objectSubType ? (
          <ControlBox.Icon
            name="back"
            onClick={() => {
              if (objectSubType) {
                this.handleSubTypeChange(null);
              } else {
                this.handleTypeChange(null);
              }
            }}
          />
        ) : null}
        <ControlBox.Title
          className={style.title}
          title={
            objectType
              ? objectSubType
                ? `Object Sub-Type: ${this.getSubTypeName(
                    objectType,
                    objectSubType
                  )}`
                : 'Select Object Sub-Type'
              : 'Select Object Type'
          }
        />
        <ControlBox.Icon
          onClick={() => {
            if (!onClose) {
              return;
            }

            // resetting selection on close
            this.handleTypeChange(null);
            this.handleSubTypeChange(null);

            onClose();
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  private renderManageObjects = (): React.ReactElement<React.ReactNode> => {
    const { manageObjectsIntent, loading } = this.state;

    return (
      <div className={style.manageContainer}>
        <ControlBox.Title
          className={style.title}
          title="Manage Object Markings"
        />
        <div className={style.buttonList}>
          <Button
            className={style.button}
            type="secondary"
            onClick={() => {
              // TODO: add render frag change to add ability to draw polygon
              this.setState(
                {
                  manageObjectsIntent: 'create_object',
                },
                () => {
                  this.updateObjectDisplay();
                }
              );
            }}
          >
            {manageObjectsIntent === 'create_object' && loading ? (
              <i
                className="fa fa-circle-o-notch fa-spin"
                style={{ color: '#000', marginRight: '5px' }}
              />
            ) : (
              <></>
            )}
            Create
          </Button>
          <Button
            className={style.button}
            type="secondary"
            onClick={() => {
              // TODO: add render frag change to add ability to select & delete polygon
              this.setState(
                {
                  manageObjectsIntent: 'delete_object',
                },
                () => {
                  this.updateObjectDisplay();
                }
              );
            }}
          >
            {manageObjectsIntent === 'delete_object' && loading ? (
              <i
                className="fa fa-circle-o-notch fa-spin"
                style={{ color: '#000', marginRight: '5px' }}
              />
            ) : (
              <></>
            )}
            Delete
          </Button>
        </div>
      </div>
    );
  };

  private renderObjectLegend = () => {
    const { siteObjects, view, viewConfig } = this.props;
    const { objectSubType } = this.state;

    let filteredObjects = siteObjects.filter(
      (so) => so.subType === objectSubType
    );

    let imageIds = null;

    if (view && this.isImageViewWithDetect(view)) {
      let hiddenImagesGuidList: string[] = [];

      if (
        viewConfig &&
        viewConfig.hiddenImagesGuidList &&
        viewConfig.hiddenImagesGuidList.length > 0
      ) {
        hiddenImagesGuidList = viewConfig.hiddenImagesGuidList;
      }

      filteredObjects = filteredObjects.filter(
        (so) =>
          so.imageId !== null && !hiddenImagesGuidList.includes(so.imageId)
      );
      imageIds = new Set(filteredObjects.map((so) => so.imageId));
    }

    const aiObjects = filteredObjects.filter((so) => so.source === 'ml');
    const manualObjects = filteredObjects.filter(
      (so) => so.source === 'manual'
    );

    return (
      <React.Fragment>
        <ControlBox.Title className={style.title} title="Stats" />
        <div className={style.colorList}>
          {imageIds ? (
            <React.Fragment>
              <div className={style.countText}>{imageIds.size}</div>
              <div className={style.legendText}>Images Containing Objects</div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className={style.countText}>{aiObjects.length}</div>
              <div className={style.legendText}>AI Marked Objects</div>
              <div className={style.countText}>{manualObjects.length}</div>
              <div className={style.legendText}>Manually Marked Objects</div>
            </React.Fragment>
          )}
        </div>
        <ControlBox.Title className={style.title} title="Legend" />
        <div className={style.colorList}>
          <div className={style.aiColor} />
          <div className={style.legendText}>AI Marked Object</div>
          <div className={style.manualColor} />
          <div className={style.legendText}>Manually Marked Object</div>
        </div>
      </React.Fragment>
    );
  };

  private renderObjectInfo = () => {
    const { hasAdminAccess } = this.props;
    const { objectSubType } = this.state;

    if (objectSubType == null) {
      return;
    }

    return (
      <React.Fragment>
        {hasAdminAccess ? this.renderManageObjects() : null}
        {this.renderObjectLegend()}
      </React.Fragment>
    );
  };

  private renderContent = () => {
    const { objectType, objectSubType } = this.state;

    return (
      <React.Fragment>
        {objectType
          ? objectSubType
            ? this.renderObjectInfo()
            : this.renderObjectSubTypeSelection()
          : this.renderObjectTypeSelection()}
      </React.Fragment>
    );
  };

  private renderModals = () => {
    const { confirmCreateModal, confirmDeleteModal } = this.state;

    return (
      <React.Fragment>
        {confirmDeleteModal ? (
          <ModalNotification
            notificationTitle="Confirm Delete"
            notificationBody="Are you sure you want to delete this object? This action cannot be undone."
            shownotificationModal
            handleModalClose={this.handleFeatureDeleteConfirmation}
            handleModalCancel={this.handleFeatureDeleteConfirmation}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}
        {confirmCreateModal ? (
          <ModalNotification
            notificationTitle="Confirm Create"
            notificationBody="Are you sure you want to create a new object?"
            shownotificationModal
            handleModalClose={this.handleFeatureCreateConfirmation}
            handleModalCancel={this.handleFeatureCreateConfirmation}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}
      </React.Fragment>
    );
  };

  public render(): React.ReactNode {
    return (
      <React.Fragment>
        <ControlBox.Wrapper
          className={style.container}
          renderHeader={this.renderHeader}
        >
          {this.renderContent()}
        </ControlBox.Wrapper>
        {this.renderModals()}
      </React.Fragment>
    );
  }
}

export default SiteObjectsControlBox;
