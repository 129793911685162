import ApiBase from './ApiBase';
import { BASE_NAVIGATOR_API_URL } from '../constants/urls';

export default class EpsgAPI extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_NAVIGATOR_API_URL);

    this._baseUrl = `/v1/epsg`;
  }

  public getEpsgProjection(epsgCode: string): Promise<any> {
    return this.get(`${this._baseUrl}/${epsgCode}/proj4`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch(() => {
        return {
          error: `Some error occured while fetching the EPSG information. Try again`,
          data: [],
        };
      });
  }
}
