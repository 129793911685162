import classnames from 'classnames';
import * as React from 'react';
import * as ControlBox from '../ControlBox/ControlBox';
import ElevationCalculator from '../ElevationCalculator/ElevationCalculatorContainer';
import VolumeCalculator from '../VolumeCalculator/VolumeCalculatorContainer';
import AreaInfo from './AreaInfo';
import DistanceInfo from './DistanceInfo';
import style from './MeasureControlBox.module.scss';
import {
  MeasureControlBoxIntentDataTypes,
  MeasureControlBoxDispatchPropsTypes,
  MeasureControlBoxPropsTypes,
  MeasureControlBoxStateTypes,
  MeasureControlBoxIntentTypes,
} from './MeasureControlBox.types';
import { undefinedOrNull } from '../../utils/functs';

const INTENTS_LIST: { [key in MeasureControlBoxIntentTypes]: string } = {
  distance: 'Distance',
  area: 'Area',
  volume: 'Volume',
  elevation: 'Elevation',
};

type IProps = MeasureControlBoxDispatchPropsTypes & MeasureControlBoxPropsTypes;

class MeasureControlBox extends React.Component<
  IProps,
  MeasureControlBoxStateTypes
> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      intent: null,
    };
  }

  public componentDidMount() {
    const { selectedIntent } = this.props;

    if (selectedIntent) {
      this.setState({
        intent: selectedIntent,
      });
    }
  }

  private handleBackSelection = () => {
    const {
      handlePreviousShapeList,
      onIntentChange,
      handleSelectedMeasurementType,
    } = this.props;

    if (handlePreviousShapeList) {
      handlePreviousShapeList();
    }

    if (onIntentChange) {
      onIntentChange();
    }

    if (handleSelectedMeasurementType) {
      handleSelectedMeasurementType();
    }
  };

  public handleIntentChange = (
    intent: MeasureControlBoxIntentTypes | null = null
  ): void => {
    const { onIntentChange, handleSelectedMeasurementType, resetVolumeType } =
      this.props;

    this.setState(
      {
        intent,
      },
      () => {
        if (resetVolumeType) {
          resetVolumeType();
        }

        if (onIntentChange) {
          onIntentChange(intent);
        }

        if (handleSelectedMeasurementType) {
          handleSelectedMeasurementType(intent);
        }
      }
    );
  };

  private handleClose = (): void => {
    const {
      onClose,
      onIntentChange,
      itemType,
      resetElevationValue,
      resetVolumeType,
    } = this.props;

    resetVolumeType();
    resetElevationValue();

    if (onIntentChange) {
      onIntentChange();
    }

    if (onClose && !undefinedOrNull(itemType)) {
      onClose(itemType);
    }
  };

  private RenderIntent = ({
    label,
    active = true,
    disable = false,
    onClick,
  }: MeasureControlBoxIntentDataTypes): JSX.Element => (
    <div
      onClick={() => {
        if (disable || !onClick) {
          return;
        }

        onClick();
      }}
      className={classnames(style.intent, {
        [style.active]: active,
        [style.disable]: disable,
      })}
    >
      {label}
    </div>
  );

  public RenderIntentSelection = (): JSX.Element | null => {
    const { intent } = this.state;
    const { view } = this.props;

    const { RenderIntent } = this;

    let filter = ['distance', 'area', 'volume', 'elevation'];

    if (
      view &&
      (!view.certifiedForMeasurement || !(view.demId && view.demId.length > 0))
    ) {
      filter = ['distance', 'area'];
    }

    return (
      <div className={style.intentList}>
        {Object.keys(INTENTS_LIST)
          .filter(
            (key: MeasureControlBoxIntentTypes) => filter.indexOf(key) > -1
          )
          .map((key: MeasureControlBoxIntentTypes) => (
            <RenderIntent
              active={intent === key}
              key={key}
              label={INTENTS_LIST[key]}
              onClick={() => {
                this.handleIntentChange(key);
              }}
            />
          ))}
      </div>
    );
  };

  public renderMeasureHeader = (): React.ReactElement => {
    return (
      <React.Fragment>
        <ControlBox.Title title="Measure" />
        <ControlBox.Icon
          onClick={() => {
            this.handleClose();
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  public renderSelectionHeader = (): JSX.Element => {
    const { issue, issueId } = this.props;

    return (
      <React.Fragment>
        {!undefinedOrNull(issueId) && !undefinedOrNull(issue) ? (
          <ControlBox.Title title="Measurement" />
        ) : (
          <React.Fragment>
            <ControlBox.Icon
              onClick={() => {
                this.handleBackSelection();
              }}
              name="back"
            />
            <ControlBox.Title title="Selection" />
          </React.Fragment>
        )}

        <ControlBox.Icon
          onClick={() => {
            this.handleClose();
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  public RenderShapeComponents = (): JSX.Element | null => {
    const { shape, viewId } = this.props;
    const { intent } = this.state;

    if (!(intent && shape)) {
      return this.RenderIntentSelection();
    }

    if (intent === 'area') {
      return <AreaInfo shape={shape} />;
    }

    if (intent === 'distance') {
      return <DistanceInfo shape={shape} />;
    }

    if (intent === 'volume' && viewId) {
      return <VolumeCalculator viewId={viewId} shape={shape} />;
    }

    if (intent === 'elevation' && viewId) {
      return <ElevationCalculator viewId={viewId} shape={shape} />;
    }

    return null;
  };

  public render(): React.ReactNode {
    const { view } = this.props;
    const { intent } = this.state;

    const { RenderShapeComponents } = this;

    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={
          intent ? this.renderSelectionHeader : this.renderMeasureHeader
        }
      >
        {!view.certifiedForMeasurement ? (
          <p className={style.measureText}>
            Measurements made on this map might not be accurate
          </p>
        ) : null}
        <RenderShapeComponents />
      </ControlBox.Wrapper>
    );
  }
}

export default MeasureControlBox;
