import axios, { CancelToken, CancelTokenStatic } from 'axios';
import { BASE_CAPI_URL } from '../constants/urls';
import { NullOrGenericObjectType } from '../shapes/app';

export default class ApiBase {
  protected baseUrl: string;

  protected customHeaders: {};

  public constructor(baseUrl: string = BASE_CAPI_URL, headers: {} = {}) {
    this.baseUrl = baseUrl;
    this.customHeaders = headers;
  }

  private getConfig(
    options: NullOrGenericObjectType | undefined,
    cancelToken: CancelToken | undefined
  ) {
    let _options = {};

    if (options) {
      _options = {
        ...options,
      };
    }

    if (cancelToken) {
      _options = {
        ..._options,
        cancelToken,
      };
    }

    return {
      withCredentials: true,
      ...this.customHeaders,
      ..._options,
    };
  }

  protected get(
    resource: string,
    options?: NullOrGenericObjectType,
    cancelToken?: CancelToken
  ): Promise<any> {
    return axios.get(
      this.getFullURL(resource),
      this.getConfig(options, cancelToken)
    );
  }

  protected post(
    resource: string,
    data: any,
    options?: NullOrGenericObjectType,
    cancelToken?: CancelToken
  ): Promise<any> {
    return axios.post(
      `${this.baseUrl}${resource}`,
      data,
      this.getConfig(options, cancelToken)
    );
  }

  protected postForm(
    resource: string,
    data: any,
    cancelToken?: CancelToken
  ): Promise<any> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    return this.post(resource, data, config, cancelToken);
  }

  protected patch(
    resource: string,
    data: any,
    options?: NullOrGenericObjectType,
    cancelToken?: CancelToken
  ): Promise<any> {
    return axios.patch(
      this.getFullURL(resource),
      data,
      this.getConfig(options, cancelToken)
    );
  }

  protected delete(
    resource: string,
    options?: NullOrGenericObjectType,
    cancelToken?: CancelToken
  ): Promise<any> {
    return axios.delete(
      this.getFullURL(resource),
      this.getConfig(options, cancelToken)
    );
  }

  protected getFullURL(resource: string): string {
    return `${this.baseUrl}${resource}`;
  }

  public getCancelToken(): CancelTokenStatic {
    return axios.CancelToken;
  }
}
