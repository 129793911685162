import { combineReducers } from 'redux';

import { loadingBarReducer } from 'react-redux-loading-bar';
import { confirmReducers } from 'react-redux-confirm';
import projects from './projects';
import views from './views';
import aois from './aois';
import auth, { login } from './auth';
import issue from './issue';
import issuesV2 from './issuesV2';
import mission from './mission';
import settings from './settings';
import share from './share';
import volume from './volume';
import snackbar from './snackbar';
import terrainMaps from './terrainMaps';
import coachmarks from './coachmarks';

const rootReducer = combineReducers({
  snackbar,
  terrainMaps,
  projects,
  views,
  aois,
  auth,
  login,
  issue,
  mission,
  share,
  settings,
  volume,
  loadingBar: loadingBarReducer,
  confirmModal: confirmReducers,
  coachmarks,
  issuesV2,
});

export default rootReducer;
