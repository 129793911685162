// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Profileinfo_container__E-4GD{width:530px}.Profileinfo_label__9TQqS label{color:rgba(255,255,255,.8)}.Profileinfo_scrollable__qWncC{max-height:50vh;overflow-y:scroll}", "",{"version":3,"sources":["webpack://./src/components/View/ViewControls/MeasureControlBox/Profileinfo/index.module.scss"],"names":[],"mappings":"AAEA,8BACE,WAAA,CAGF,gCACE,0BAAA,CAGF,+BACE,eAAA,CACA,iBAAA","sourcesContent":["@import '../../../../../styles/scss/base/base';\n\n.container {\n  width: 530px;\n}\n\n.label label {\n  color: rgba(255, 255, 255, 0.8);\n}\n\n.scrollable {\n  max-height: 50vh;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Profileinfo_container__E-4GD",
	"label": "Profileinfo_label__9TQqS",
	"scrollable": "Profileinfo_scrollable__qWncC"
};
export default ___CSS_LOADER_EXPORT___;
