import React, { useEffect, useMemo, useRef } from 'react';
import { Map, View } from 'ol';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import {
  MapContext,
  useLayerManager,
  useMapContext,
  VectorContext,
} from './hooks';

export interface OSMMapContainerProps {
  view?: View;
}

export const OSMMapContainer: React.FC<OSMMapContainerProps> = ({
  children,
  view: viewFromProps,
}) => {
  const olMapRef = useRef<HTMLDivElement>(null);
  const map = useMemo(() => {
    return new Map({});
  }, []);
  const view = useMemo(() => {
    return (
      viewFromProps ||
      new View({ zoom: 3, maxZoom: 22, minZoom: 2, center: fromLonLat([0, 0]) })
    );
  }, [viewFromProps]);

  useEffect(() => {
    if (!olMapRef.current) return;

    map.setTarget(olMapRef.current);

    return () => {
      map.setTarget(undefined);
    };
  }, [olMapRef]);

  useEffect(() => {
    map.setView(view);
  }, [view]);

  return (
    <div
      ref={olMapRef}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <MapContext.Provider value={{ map, view }}>
        {children}
      </MapContext.Provider>
    </div>
  );
};

export const VectorLayerContainer: React.FC = ({ children }) => {
  const { map } = useMapContext();
  const { source, layer } = useMemo(() => {
    const source = new VectorSource({ wrapX: false });
    const layer = new VectorLayer({
      source,
    });

    return { source, layer };
  }, []);

  const { addLayer, removeLayer } = useLayerManager();

  useEffect(() => {
    if (
      !map
        .getLayers()
        .getArray()
        .find((l) => l === layer)
    )
      addLayer(layer);

    return () => {
      removeLayer(layer);
    };
  }, [map, layer]);

  return (
    <div data-testid="ol-vector-layer-container">
      <VectorContext.Provider value={{ source, layer }}>
        {children}
      </VectorContext.Provider>
    </div>
  );
};
