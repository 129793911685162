import * as React from 'react';
import { Route } from 'react-router';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import styles from './index.module.scss';
import CompareControlBox from '../CompareControlBox/CompareControlBoxContainer';
import DownloadControlBox from '../DownloadControlBox';
import DrawControl from '../DrawControl';
import ElevationScale from '../ElevationScale';
import Exterior360 from '../Exterior360/Exterior360Container';
import InfoControlBox from '../InfoControlBox';
import Interior360 from '../Interior360/Interior360Container';
import LegendControlBox from '../LegendControlBox';
import MapboxView from '../MapboxView';
import MapboxStyle from '../MapboxStyle';
import MarkIssueControlBox from '../MarkIssueControlBox/MarkIssueControlBoxContainer';
import MeasureControlBox from '../MeasureControlBox/MeasureControlBoxContainer';
import SiteWalkthrough from '../SiteWalkthrough/container';
import ReportsControlBox from '../ReportsControlBox';
import ShareControlBox from '../ShareControlBox/ShareControlBox';
import SplitView from '../SplitView/SplitViewContainer';
import SurveyView from '../SurveyView/SurveyView';
import ViewControlsV2 from '../ViewControlsV2';
import ViewFooter from '../ViewFooter/ViewFooterContainer';
import Dropdown from '../DropDown/DropDown';
import {
  undefinedOrNull,
  arrayIntersection,
  inArray,
  removeArrayByValue,
  isObject,
  getGeoJsonFeatures,
  isCertifiedView,
  filteredArrayValue,
  isEmpty,
} from '../../utils/functs';
import { Button } from '../Button';
import {
  GenericApisReturnTypes,
  GenericObjectType,
  NullOrGenericObjectType,
} from '../../shapes/app';
import DroneAnimation from '../DroneAnimation/DroneAnimation';
import { log } from '../../utils/log';
import {
  ViewV2StateTypes,
  ViewV2PropsTypes,
  ViewV2ActiveGeoJsonShapeListKeysTypes,
  ViewV2AllowedMeasureMenuSelectSequenceListTypes,
  ViewV2AllowedMeasureMenuSelectSequenceListObjectTypes,
  ViewV2ActiveGeoJsonShapeListTypes,
  ViewV2StateTypesPreservedMapMetaTypes,
  ViewV2StateMapStylesLayersIdDataTypes,
} from './index.types';
import {
  MAPBOX_STYLE_SATELLITE_V9_URL,
  VIMEO_EMBED_URL,
  YOUTUBE_EMBED_URL,
} from '../../constants/urls';
import ViewToolsApis from '../../api/viewTools';
import { viewUrl } from '../../routes/urls';
import ViewsV2Apis from '../../api/viewsV2';
import Loading from '../Loading';
import { sortByDate, sortByName } from '../../utils/helpers';
import {
  ViewControlsV2TypeTypes,
  ViewControlsV2ItemsTypes,
} from '../ViewControlsV2/index.types';
import { DrawControlIntentTypes } from '../DrawControl/DrawControl.types';
import { MeasureControlBoxIntentTypes } from '../MeasureControlBox/MeasureControlBox.types';
import ContextMenu from '../ContextMenu';
import LoadingOverlay from '../LoadingOverlay';
import SiteObjectControlBox from '../SiteObjectsControlBox';
import {
  SiteObjectClass,
  ObjectType,
  ObjectSubType,
  SiteObject,
} from '../../shapes/ml';
import ModalNotification from '../ModalNotification/ModalNotification';
import { CreateObjectRequest } from '../../api/ml.types';
import PotreeWrapper from '../Potree';
import ElevationDifference from '../OpenLayersView';

const viewToolsApis = new ViewToolsApis();
const viewsV2Apis = new ViewsV2Apis();

export default class ViewV2 extends React.Component<
  ViewV2PropsTypes,
  ViewV2StateTypes
> {
  private mapData: GenericObjectType | null = null;

  private drawControl: GenericObjectType | null = null;

  private timeoutOnMapboxStyleLoaded: any[] = [];

  private exteriorRef: GenericObjectType = React.createRef();

  private perspectiveRef: GenericObjectType = React.createRef();

  private inspectionRef: GenericObjectType = React.createRef();

  private siteWalkThroughRef: GenericObjectType = React.createRef();

  private defaultMapOpacityLevel = 0.5;

  private preservedMapMeta: ViewV2StateTypesPreservedMapMetaTypes | null;

  private preservedMapZoom: number | null = null;

  public constructor(props: ViewV2PropsTypes) {
    super(props);

    this.state = {
      viewConfigData: null,
      mapZoomLevel: null,
      mapCenter: null,
      mapOpacityLevel: this.defaultMapOpacityLevel,
      isMapMoveComplete: false, // added to ensure that moving map & drawing polygons happens only once
      isMapStyleLoaded: false, // added to ensure that moving map & drawing polygon happens only AFTER the actual mapbox style is loaded
      isMapOsmVisible: false,
      activeGeoJsonShapeList: {}, // current  geoJsonShapeData for measure box
      legendsDataList: [],
      selectedViewControlsMenuTypeList: null,
      selectedMeasurementIntentType: null,
      drawModeType: null,
      isDrawModeDisabled: false,
      projectVideoData: [],
      projectVideoSelectedIndex: 0,
      isProjectVideoPlayerLoading: false,
      projectVideoPlayerCreateTime: Date.now(),
      mapboxInspectPositions: null,
      showSiteWalkThroughViewInspectOnAerial: false,
      isViewItemLoading: false,
      viewControlsCreateTime: Date.now(),
      isGuideLineEnabled: false,
      mapStylesLayersIdData: {
        view: null,
        compareView: null,
      },
      siteObjects: [],
      projectSiteObjectClasses: [],
      siteObjectType: null,
      siteObjectSubType: null,
      showSiteObjectCreateConfirmationModal: false,
      showSiteObjectDeleteConfirmationModal: false,
    };
  }

  public componentDidMount(): void {
    const { viewId, view } = this.props;

    this.fetchApis();
    this.fetchVideoData(viewId, view);
    this.setInspectEnabledState(view);
  }

  public async UNSAFE_componentWillReceiveProps({
    viewId: nextViewId,
    isFullScreen: nextIsFullScreen,
    splitView: nextSplitView,
    view: nextView,
    issue: nextIssue,
    issueId: nextIssueId,
    compareView: nextCompareView,
    isTerrainMap: nextIsTerrainMap,
    isContourMaps: nextIsContourMaps,
    downloads: nextDownloads,
    reports: nextReports,
  }: Readonly<ViewV2PropsTypes>): Promise<void> {
    const {
      viewId,
      fetchDownloads,
      fetchReports,
      isFullScreen,
      splitView,
      view,
      issue,
      resetViewLayers,
      issueId,
      compareView,
      reports,
      downloads,
    } = this.props;
    let nextState = this.state;

    if (splitView !== nextSplitView) {
      if (!undefinedOrNull(nextSplitView)) {
        if (this.mapData && this.drawControl && this.drawControl.draw) {
          try {
            this.drawControl.draw.deleteAll();
          } catch (e) {
            log.error(e, 'View -> componentWillReceiveProps -> splitView');
          }
        }

        this.resetViewControlsMenu();

        return;
      }

      if (!undefinedOrNull(splitView)) {
        this.setState({
          isDrawModeDisabled: false,
        });

        return;
      }
    }

    // handling compare view load
    if (nextCompareView && compareView !== nextCompareView) {
      const { selectedViewControlsMenuTypeList } = this.state;
      const filteredViewControlsMenuTypeList = selectedViewControlsMenuTypeList
        ? selectedViewControlsMenuTypeList.filter(
            (val) => ['compare'].indexOf(val) === -1
          )
        : [];

      this.setState({
        selectedViewControlsMenuTypeList: [
          ...filteredViewControlsMenuTypeList,
          'compare',
        ],
      });
    }

    // we try to reset the view controls menu if there was a view tab changing
    // even after issueId goes undefined the issues will persists
    if (issueId !== nextIssueId && !nextIssueId) {
      await this.resetViewControlsMenu();

      nextState = this.state;
    } else if (
      issue !== nextIssue ||
      ((viewId !== nextViewId || view !== nextView) &&
        !undefinedOrNull(nextIssue))
    ) {
      if (!undefinedOrNull(nextIssueId)) {
        nextState = (await this.handleIssues(
          nextIssue,
          nextView
        )) as Readonly<ViewV2StateTypes>;

        this.resurrectGeoJsonOnTabChange(
          nextState.selectedViewControlsMenuTypeList
        );
      }
    }

    if (reports !== nextReports || downloads !== nextDownloads) {
      await this.handleViewTabViewControlsMenuListChange(
        nextView,
        !!nextIsTerrainMap,
        !!nextIsContourMaps,
        nextDownloads ?? [],
        nextReports ?? []
      );
    }

    const { selectedViewControlsMenuTypeList } = nextState;

    if (viewId !== nextViewId || view !== nextView) {
      // resetting view layers if any
      if (resetViewLayers) {
        resetViewLayers();
      }

      if (fetchDownloads) {
        fetchDownloads(nextViewId);
        fetchReports(nextViewId);
      }

      await this.fetchSiteObjectsData(nextViewId);

      // fetch video data
      this.fetchVideoData(nextViewId, nextView);

      // handle inspect map
      this.setInspectEnabledState(nextView);

      // resetting shape used for measurements when viewId changes
      if (undefinedOrNull(selectedViewControlsMenuTypeList)) {
        if (this.mapData && this.drawControl && this.drawControl.draw) {
          try {
            this.drawControl.draw.deleteAll();
          } catch (e) {
            log.error(
              e,
              'ViewV2 -> componentWillReceiveProps -> selectedMeasurementIntentType'
            );
          }
        }
      }

      // handle change tabs
      // do this only if the issue id is undefined. else it will mess up because the data flow latency
      this.handleViewTabChange(
        nextView,
        nextIssueId,
        !!nextIsTerrainMap,
        !!nextIsContourMaps,
        nextDownloads ?? [],
        nextReports ?? []
      );
    }

    // full screen
    if (isFullScreen !== nextIsFullScreen) {
      this.onMapboxStyleLoaded().then((mapData) => {
        if (!mapData) {
          return;
        }

        if (mapData) {
          mapData.resize();
        }
      });
    }
  }

  public UNSAFE_componentWillUpdate(
    // eslint-disable-next-line no-empty-pattern
    {},
    {
      projectVideoSelectedIndex: prevSelectedProjectVideoIndex,
    }: Readonly<ViewV2StateTypes>
  ): void {
    const { projectVideoSelectedIndex } = this.state;

    if (projectVideoSelectedIndex !== prevSelectedProjectVideoIndex) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({
        isProjectVideoPlayerLoading: true,
        projectVideoPlayerCreateTime: Date.now(),
      });
    }
  }

  public componentDidUpdate({
    view: prevView,
    viewsList: prevViewsList,
  }: Readonly<ViewV2PropsTypes>) {
    const { view, viewsList } = this.props;

    if (view !== prevView || viewsList !== prevViewsList) {
      this.setInspectEnabledState(view);
    }
  }

  public componentWillUnmount(): void {
    const { viewId, clearIssueState, setLastView, splitView, resetViewLayers } =
      this.props;

    if (resetViewLayers) {
      resetViewLayers();
    }

    this.setState(
      {
        selectedViewControlsMenuTypeList: null,
        isMapStyleLoaded: false,
      },
      () => {
        this.clearOnMapboxStyleLoadedTimeout();

        clearIssueState();

        const splitViewId = splitView ? splitView.id : null;

        if (setLastView) {
          setLastView(viewId, splitViewId);
        }
      }
    );
  }

  public fetchApis = async () => {
    const {
      fetchViews,
      fetchDownloads,
      fetchReports,
      resetCurrentView,
      fetchSingleIssue,
      fetchSiteObjects,
      fetchSiteObjectClasses,
      projectId,
      viewId,
      issueId,
    } = this.props;

    fetchViews()
      .then(() => resetCurrentView())
      .then(() => {
        if (fetchDownloads) {
          fetchDownloads(viewId);
        }

        if (fetchReports) {
          fetchReports(viewId);
        }

        if (fetchSiteObjects) {
          this.fetchSiteObjectsData(viewId);
        }

        if (fetchSiteObjectClasses) {
          this.fetchSiteClassesData(projectId);
        }
      });

    if (issueId) {
      fetchSingleIssue(issueId);
    }
  };

  private fetchSiteObjectsData = (viewId: string) => {
    const { fetchSiteObjects } = this.props;

    return new Promise<void>((resolve) => {
      if (fetchSiteObjects) {
        fetchSiteObjects(viewId).then((res) => {
          let siteObjects: SiteObject[] = [];

          if (!res.error) {
            siteObjects = res.data ?? [];
          }

          // clear site-objects on API error
          return this.setState(
            {
              siteObjects,
              siteObjectSubType: null,
            },
            resolve
          );
        });
      } else {
        return resolve();
      }
    });
  };

  private fetchSiteClassesData = (projectId: string) => {
    const { fetchSiteObjectClasses } = this.props;

    return new Promise<void>((resolve) => {
      if (fetchSiteObjectClasses) {
        fetchSiteObjectClasses(projectId).then((res) => {
          let siteObjectClasses: SiteObjectClass[] = [];

          if (!res.error) {
            siteObjectClasses = res.data ?? [];
          }

          // clear site-objects on API error
          return this.setState(
            {
              projectSiteObjectClasses: siteObjectClasses,
            },
            resolve
          );
        });
      } else {
        return resolve();
      }
    });
  };

  private fetchVideoData = (viewId: string, view: NullOrGenericObjectType) => {
    if (!view || view.type !== 'video') {
      this.setState({
        isProjectVideoPlayerLoading: false,
        projectVideoData: [],
      });

      return;
    }

    const { match, showSnackbar, viewDescriptor } = this.props;
    const { projectId, aoiId } = match.params;

    // handle public share video data
    if (!undefinedOrNull(viewDescriptor)) {
      this.setState({
        isProjectVideoPlayerLoading: viewDescriptor.videos.length >= 1,
        projectVideoData: viewDescriptor.videos || [],
      });

      return;
    }

    viewsV2Apis
      .getViewDescriptor(projectId, aoiId, viewId)
      .then((res: GenericApisReturnTypes) => {
        if (undefinedOrNull(res)) {
          showSnackbar({
            body: `Some error occured. Try again!`,
            type: 'error',
          });

          log.error(
            `Invalid viewsV2Apis getViewDescriptor return data!`,
            `ViewV2 -> fetchVideoData -> viewsV2Apis -> getViewDescriptor`
          );

          this.setState({
            isProjectVideoPlayerLoading: false,
            projectVideoData: [],
          });

          return null;
        }

        if (
          res.error ||
          !res.data ||
          !res.data.videos ||
          res.data.videos.length < 1
        ) {
          showSnackbar({
            body: res.error || 'Video fetch error',
            type: 'error',
          });

          log.error(
            res.error,
            `ViewV2 -> fetchVideoData -> viewsV2Apis -> getViewDescriptor`
          );

          this.setState({
            isProjectVideoPlayerLoading: false,
            projectVideoData: [],
          });

          return null;
        }

        this.setState({
          isProjectVideoPlayerLoading: true,
          projectVideoData: res.data.videos,
        });

        return res.data;
      });
  };

  private setInspectEnabledState = (view: GenericObjectType) => {
    const { shareId } = this.props;

    if (shareId) {
      this.setState({
        showSiteWalkThroughViewInspectOnAerial: false,
      });
    } else {
      const siteWalkThroughView = this.getViewToInspect();

      this.setState({
        showSiteWalkThroughViewInspectOnAerial:
          view &&
          view.type === 'map' &&
          view.subType === 'aerial' &&
          siteWalkThroughView !== null,
      });
    }
  };

  public handleIssues = (
    issue: NullOrGenericObjectType,
    view: NullOrGenericObjectType
  ) => {
    return new Promise((resolve) => {
      if (!view) {
        return resolve(this.state);
      }

      if (view.type === 'exterior_360') {
        return resolve(this.handleNonMapIssues(issue, false));
      }

      if (view.type === 'perspective' || view.type === 'inspection') {
        return resolve(this.handleNonMapIssues(issue));
      }

      if (view.type === 'site_navigation') {
        return resolve(this.handleNonMapIssues(issue));
      }

      if (view.type === 'map') {
        return resolve(this.handleMapIssues(issue));
      }

      return resolve(this.state);
    });
  };

  public handleMapIssues = (issue: NullOrGenericObjectType) => {
    const { setElevationValue, setVolumeType } = this.props;

    return new Promise((resolve) => {
      this.onMapboxStyleLoaded().then(async (mapData) => {
        if (undefinedOrNull(mapData)) {
          return resolve(this.state);
        }

        if (undefinedOrNull(issue)) {
          return resolve(this.state);
        }

        // Moving map if latitude, longitude, zoom are found
        const { latitude, longitude, zoom } = issue.viewConfig;
        const { shapeGeoJson } = issue;

        let geoJson: NullOrGenericObjectType = null;

        try {
          geoJson = JSON.parse(shapeGeoJson);
        } catch (err) {
          log.error(err, 'ViewV2.handleMapIssues shapeGeoJson');
        }

        // Moving to required Map location for issue
        if (latitude !== null && longitude !== null && zoom !== null) {
          this.setState(
            {
              mapCenter: [longitude, latitude],
              mapZoomLevel: zoom,
            },
            () => {
              mapData.flyTo({
                center: [longitude, latitude],
                zoom,
              });
            }
          );
        }

        const selectedViewControlsMenuTypeList:
          | ViewControlsV2TypeTypes[]
          | null = this.getSelectedViewControlsMenuTypeList(null, issue);

        const activeGeoJsonShapeList: ViewV2ActiveGeoJsonShapeListTypes = {};

        if (geoJson) {
          // removing CRS as mapbox-gl-draw does not like old CRS formats
          geoJson.crs = null;

          // resurrecting the geojson from issues api
          if (issue.viewConfig.measurementType) {
            // this is to make sure that 'elevation' has index of 0 in 'activeGeoJsonShapeList.measure' for the measure services to work
            if (issue.viewConfig.measurementType === 'elevation') {
              activeGeoJsonShapeList.measure = [geoJson.features];
            } else {
              activeGeoJsonShapeList.measure = [...geoJson.features];
            }

            if (issue.viewConfig.measurementType === 'volume') {
              if (issue.viewConfig.volumePlaneElevation) {
                setElevationValue(issue.viewConfig.volumePlaneElevation);
                setVolumeType('FlatPlane');
              } else {
                setVolumeType('BestFitPlane');
              }
            }
          } else {
            activeGeoJsonShapeList.issue = [...geoJson.features];
          }
        }

        if (this.drawControl) {
          this.drawControl.draw.add(geoJson);
        }

        this.setState(
          {
            selectedMeasurementIntentType:
              issue.viewConfig.measurementType || null,
            selectedViewControlsMenuTypeList,
            activeGeoJsonShapeList,
          },
          () => {
            return resolve(this.state);
          }
        );
      });
    });
  };

  public handleNonMapIssues = (
    issue: NullOrGenericObjectType,
    isGeoJsonAvailable = true
  ) => {
    return new Promise(async (resolve) => {
      if (undefinedOrNull(issue)) {
        return resolve(this.state);
      }

      const activeGeoJsonShapeList: ViewV2ActiveGeoJsonShapeListTypes = {};

      if (isGeoJsonAvailable) {
        const { shapeGeoJson } = issue;

        let geoJson: NullOrGenericObjectType = null;

        try {
          geoJson = JSON.parse(shapeGeoJson);
        } catch (err) {
          log.error(err, 'ViewV2.handleNonMapIssues shapeGeoJson');
        }

        if (!geoJson) {
          return resolve(this.state);
        }

        // resurrecting the geojson from issues api
        activeGeoJsonShapeList.issue = geoJson.features;
      }

      await this.setState(
        {
          activeGeoJsonShapeList,
          drawModeType: 'mark_issue',
          selectedViewControlsMenuTypeList: ['mark_issue'],
        },
        () => {
          return resolve(this.state);
        }
      );

      return resolve(this.state);
    });
  };

  private handleImagesSelectedMarkerIndexChange = () => {
    const { history, projectId, aoiId, viewId } = this.props;
    const { selectedViewControlsMenuTypeList } = this.state;

    // ensure detect feature stays up on image change
    let viewControlMenuList: ViewControlsV2TypeTypes[] | null = null;

    if (
      selectedViewControlsMenuTypeList !== null &&
      inArray(selectedViewControlsMenuTypeList, 'site_objects')
    ) {
      viewControlMenuList = ['site_objects'];
    }

    this.setState(
      () => {
        return {
          activeGeoJsonShapeList: {},
          drawModeType: null,
          selectedViewControlsMenuTypeList: viewControlMenuList,
        };
      },
      () => {
        if (history) {
          history.push(viewUrl(projectId, aoiId, viewId));
        }
      }
    );
  };

  private resetViewControlsMenu = () => {
    return new Promise((resolve) => {
      this.setState(
        {
          selectedViewControlsMenuTypeList: null,
          selectedMeasurementIntentType: null,
          activeGeoJsonShapeList: {},
        },
        () => {
          return resolve(this.state);
        }
      );
    });
  };

  private handleViewTabChange = async (
    view: GenericObjectType,
    issueId: string | undefined,
    isTerrainMap: boolean,
    isContourMaps: boolean,
    downloads: GenericObjectType[],
    reports: GenericObjectType[]
  ) => {
    await this.handleViewTabViewControlsMenuListChange(
      view,
      isTerrainMap,
      isContourMaps,
      downloads,
      reports
    );

    if (undefinedOrNull(issueId)) {
      this.handleViewTabChangeWithoutIssueId();
    }
  };

  private handleViewTabViewControlsMenuListChange = async (
    view: GenericObjectType,
    isTerrainMap: boolean,
    isContourMaps: boolean,
    downloads: GenericObjectType[],
    reports: GenericObjectType[]
  ) => {
    const { selectedViewControlsMenuTypeList } = this.state;

    const viewControlsMenuList = this.getViewControlsMenuList(
      view?.type,
      view?.subType,
      isTerrainMap,
      isContourMaps,
      view?.metadata,
      downloads ?? [],
      reports ?? []
    );

    return new Promise<void>((resolve) => {
      if (
        selectedViewControlsMenuTypeList &&
        selectedViewControlsMenuTypeList.length > 0 &&
        !inArray(viewControlsMenuList, selectedViewControlsMenuTypeList)
      ) {
        this.setState(
          {
            selectedViewControlsMenuTypeList: [],
          },
          () => {
            return resolve();
          }
        );
      } else {
        return resolve();
      }
    });
  };

  private handleViewTabChangeWithoutIssueId = () => {
    const { issue, issueId } = this.props;
    const {
      selectedViewControlsMenuTypeList,
      activeGeoJsonShapeList,
      selectedMeasurementIntentType,
    } = this.state;

    let nextSelectedViewControlsMenuTypeList: ViewControlsV2TypeTypes[] | null =
      null;
    let nextActiveGeoJsonShapeList: ViewV2ActiveGeoJsonShapeListTypes = {};
    let nextSelectedMeasurementIntentType: MeasureControlBoxIntentTypes | null =
      null;

    // if 'issues' and 'issueId' are open clear out the 'selectedViewControlsMenuTypeList', 'activeGeoJsonShapeList' and 'measurementIntent'
    if (undefinedOrNull(issue) || undefinedOrNull(issueId)) {
      nextSelectedViewControlsMenuTypeList = selectedViewControlsMenuTypeList
        ? [
            ...(removeArrayByValue(
              selectedViewControlsMenuTypeList,
              'mark_issue'
            ) as ViewControlsV2TypeTypes[]),
          ]
        : null; // removing 'issue' polygons on tab change

      nextActiveGeoJsonShapeList = {
        ...activeGeoJsonShapeList,
        issue: [], // removing 'issue' polygons on tab change
      };

      nextSelectedMeasurementIntentType = selectedMeasurementIntentType;
    }

    if (this.mapData && this.drawControl && this.drawControl.draw) {
      try {
        this.drawControl.draw.deleteAll();

        this.setState(
          () => {
            return {
              isDrawModeDisabled: true,
              selectedViewControlsMenuTypeList: null, // disable this before changing the tab to unmount the drawcontroller and later resurrect this value
              activeGeoJsonShapeList: nextActiveGeoJsonShapeList,
              selectedMeasurementIntentType: nextSelectedMeasurementIntentType,
            };
          },
          () => {
            this.resurrectGeoJsonOnTabChange(
              nextSelectedViewControlsMenuTypeList
            );
          }
        );
      } catch (e) {
        log.error(
          e,
          'ViewV2.handleViewTabChangeWithoutIssueId !selectedMeasurementIntentType'
        );
      }

      return;
    }

    this.resurrectGeoJsonOnTabChange(nextSelectedViewControlsMenuTypeList);
  };

  private resurrectGeoJsonOnTabChange = (
    selectedViewControlsMenuTypeList: ViewControlsV2TypeTypes[] | null
  ) => {
    // wait for the styles to load to readd the 'activeGeoJsonShapeList' polygons
    this.onMapboxStyleLoaded().then((_) => {
      this.setState(
        () => {
          return {
            selectedViewControlsMenuTypeList,
            isDrawModeDisabled: false,
          };
        },
        () => {
          const { activeGeoJsonShapeList, selectedMeasurementIntentType } =
            this.state;

          const geoJson: GenericObjectType[] = [];

          // 'elevation' will be an array of points in 'activeGeoJsonShapeList.measure' and for the measure services to work 'activeGeoJsonShapeList.measure[0]' has to be spread.
          if (
            activeGeoJsonShapeList.measure &&
            selectedMeasurementIntentType === 'elevation'
          ) {
            geoJson.push(
              ...((activeGeoJsonShapeList.measure[0] as GenericObjectType[]) ||
                [])
            );
          } else {
            geoJson.push(...(activeGeoJsonShapeList.measure || []));
          }

          geoJson.push(...(activeGeoJsonShapeList.issue || []));

          this.readdGeoJsonListValues(geoJson);
        }
      );
    });
  };

  private setDrawControl = (drawControl: DrawControl | null) => {
    const { selectedViewControlsMenuTypeList } = this.state;

    if (!drawControl || this.drawControl) {
      return;
    }

    this.drawControl = drawControl.getDrawControl();

    this.resurrectGeoJsonOnTabChange(selectedViewControlsMenuTypeList);
  };

  private getSelectedViewControlsMenuTypeList = (
    selectedViewControlsMenuTypeList: ViewControlsV2TypeTypes[] | null,
    issue?: NullOrGenericObjectType
  ): ViewControlsV2TypeTypes[] | null => {
    if (!issue) {
      return selectedViewControlsMenuTypeList;
    }

    const _selectedViewControlsMenuTypeList: ViewControlsV2TypeTypes[] | null =
      [];

    // follow the order in which the polygons from issues are resurrected
    // refer 'allowedMeasureMenuSelectSequenceList' for more.
    if (issue.viewConfig.compareViewId) {
      _selectedViewControlsMenuTypeList.push('compare');
    }

    if (issue.viewConfig.measurementType) {
      _selectedViewControlsMenuTypeList.push('measure');
    }

    _selectedViewControlsMenuTypeList.push('mark_issue');

    return _selectedViewControlsMenuTypeList;
  };

  private onMapboxStyleLoaded = (): Promise<GenericObjectType | null> => {
    return new Promise((resolve) => {
      const timeout = setInterval(() => {
        const isLoaded = !!this.mapData?.isStyleLoaded();

        if (isLoaded) {
          this.clearOnMapboxStyleLoadedTimeout(timeout);

          return resolve(this.mapData);
        }
      }, 100);

      this.timeoutOnMapboxStyleLoaded.push(timeout);
    });
  };

  private clearOnMapboxStyleLoadedTimeout = (timeout?: any) => {
    if (timeout) {
      clearInterval(timeout);
      // eslint-disable-next-line no-param-reassign
      timeout = null;

      return;
    }

    this.timeoutOnMapboxStyleLoaded.map((a: any) => {
      clearInterval(a);
      // eslint-disable-next-line no-param-reassign
      a = null;

      return a;
    });
  };

  private isInActionMenuType = (
    selectedViewControlsMenuTypeList: ViewControlsV2TypeTypes[] | null
  ): boolean => {
    const { selectedViewControlsMenuTypeList: _actionMenuTypeList } =
      this.state;

    if (!_actionMenuTypeList || !selectedViewControlsMenuTypeList) {
      return false;
    }

    const diff = arrayIntersection(
      _actionMenuTypeList,
      selectedViewControlsMenuTypeList
    );

    return diff && diff.length > 0;
  };

  private getActiveGeoJsonShapeList = (
    drawModeType: DrawControlIntentTypes | null,
    value: GenericObjectType
  ) => {
    const { activeGeoJsonShapeList } = this.state;

    let menuType: ViewV2ActiveGeoJsonShapeListKeysTypes | null = null;

    if (undefinedOrNull(drawModeType)) {
      return activeGeoJsonShapeList;
    }

    if (this.isItemInMeasure(drawModeType)) {
      menuType = 'measure';
    } else if (this.isItemInIssue(drawModeType)) {
      menuType = 'issue';
    } else if (this.isItemInSiteObjects(drawModeType)) {
      menuType = 'siteObjects';
    }

    if (undefinedOrNull(menuType)) {
      return activeGeoJsonShapeList;
    }

    const _activeGeoJsonShapeList = {
      ...activeGeoJsonShapeList,
    };

    if (undefinedOrNull(_activeGeoJsonShapeList[menuType])) {
      _activeGeoJsonShapeList[menuType] = [];
    }

    (_activeGeoJsonShapeList[menuType] as GenericObjectType[]).push(value);

    return _activeGeoJsonShapeList;
  };

  private updateActiveGeoJsonShapeList = (
    drawModeType: DrawControlIntentTypes | null,
    value: GenericObjectType
  ) => {
    const { activeGeoJsonShapeList } = this.state;

    let menuType: ViewV2ActiveGeoJsonShapeListKeysTypes | null = null;

    if (undefinedOrNull(drawModeType)) {
      return activeGeoJsonShapeList;
    }

    if (this.isItemInMeasure(drawModeType)) {
      menuType = 'measure';
    } else if (this.isItemInIssue(drawModeType)) {
      menuType = 'issue';
    } else if (this.isItemInSiteObjects(drawModeType)) {
      menuType = 'siteObjects';
    }

    if (undefinedOrNull(menuType)) {
      return activeGeoJsonShapeList;
    }

    const _activeGeoJsonShapeList = {
      ...activeGeoJsonShapeList,
    };

    if (drawModeType !== 'elevation') {
      // besides elevation, all draw modes only support a single feature
      _activeGeoJsonShapeList[menuType] = [];
    }

    (_activeGeoJsonShapeList[menuType] as GenericObjectType[]).push(value);

    return _activeGeoJsonShapeList;
  };

  private handleShapeCreate = (geoJson: NullOrGenericObjectType): void => {
    const { drawModeType } = this.state;

    if (!geoJson) {
      return;
    }

    if (drawModeType === 'create_object') {
      const { features } = geoJson;

      if (features && features.length > 0) {
        this.setState({
          showSiteObjectCreateConfirmationModal: true,
          activeGeoJsonShapeList: this.updateActiveGeoJsonShapeList(
            drawModeType,
            features[0]
          ),
          viewControlsCreateTime: Date.now(),
        });
      }

      return;
    }

    const activeGeoJsonShapeList: GenericObjectType =
      getGeoJsonFeatures(geoJson) || {};

    this.setState(() => {
      return {
        activeGeoJsonShapeList: this.getActiveGeoJsonShapeList(
          drawModeType,
          activeGeoJsonShapeList
        ),
        viewControlsCreateTime: Date.now(),
      };
    });
  };

  private handleShapeUpdate = (geoJson: NullOrGenericObjectType): void => {
    const { drawModeType } = this.state;

    if (!geoJson) {
      return;
    }

    const activeGeoJsonShapeList: GenericObjectType =
      getGeoJsonFeatures(geoJson) || {};

    this.setState(() => {
      return {
        activeGeoJsonShapeList: this.updateActiveGeoJsonShapeList(
          drawModeType,
          activeGeoJsonShapeList
        ),
        viewControlsCreateTime: Date.now(),
      };
    });
  };

  private onDrawSelectionChange = (args: NullOrGenericObjectType): void => {
    const { drawModeType, selectedMeasurementIntentType } = this.state;

    if (drawModeType === 'delete_object' && args) {
      const { features } = args;

      if (features && features.length > 0) {
        this.setState({
          showSiteObjectDeleteConfirmationModal: true,
          activeGeoJsonShapeList: this.updateActiveGeoJsonShapeList(
            drawModeType,
            features[0]
          ),
        });
      }

      return;
    }

    if (!args || selectedMeasurementIntentType !== 'elevation') {
      return;
    }

    this.handleShapeCreate(args);
  };

  private handleSiteObjectCreate = (value?: boolean) => {
    const {
      siteObjectType,
      siteObjectSubType,
      activeGeoJsonShapeList,
      viewConfigData,
    } = this.state;
    const { createSiteObject, viewId, view } = this.props;

    const { siteObjects } = activeGeoJsonShapeList;

    if (siteObjects && siteObjects.length === 1) {
      const featureObjectToCreate = siteObjects[0];

      featureObjectToCreate.properties = {
        label: siteObjectSubType,
      };

      if (this.drawControl && this.drawControl.draw) {
        this.drawControl.draw.delete(siteObjects[0].id);
      }

      // TODO: replace with create logic
      if (createSiteObject && value) {
        const createObjectRequest: CreateObjectRequest = {
          feature: JSON.stringify(featureObjectToCreate),
          type: siteObjectType,
          subType: siteObjectSubType,
          viewId,
          source: 'manual',
        };

        if (viewConfigData?.guid && view?.type === 'site_navigation') {
          createObjectRequest.imageId = viewConfigData.guid;
        }

        createSiteObject(viewId, createObjectRequest).then((res) => {
          if (!res.error) {
            this.setState(({ siteObjects }) => {
              return {
                siteObjects: [...siteObjects, res.data],
              };
            });

            if (this.drawControl && this.drawControl.draw) {
              // add feature to map, on create
              const feature = JSON.parse(res.data.finalFeatureGeoJSON);

              feature.properties.objectId = res.data.id;
              feature.properties.source = res.data.source;
              feature.properties.deleted = res.data.deleted;

              const geoJson = {
                type: 'FeatureCollection',
                crs: null,
                features: [feature],
              };

              this.drawControl.draw.add(geoJson);
            }
          }

          this.setState({
            showSiteObjectCreateConfirmationModal: false,
            drawModeType: 'site_objects',
            viewControlsCreateTime: Date.now(),
          });
        });

        return;
      }
    }

    this.setState({
      showSiteObjectCreateConfirmationModal: false,
      drawModeType: 'site_objects',
      viewControlsCreateTime: Date.now(),
    });
  };

  private handleSiteObjectDelete = (value?: boolean) => {
    const { deleteSiteObject, viewId } = this.props;
    const { activeGeoJsonShapeList } = this.state;

    // TODO: replace with delete logic
    const { siteObjects } = activeGeoJsonShapeList;

    if (siteObjects && siteObjects.length === 1 && deleteSiteObject && value) {
      let objectId: string | null = null;

      if (siteObjects[0].properties && siteObjects[0].properties.objectId) {
        objectId = siteObjects[0].properties.objectId;
      }

      if (objectId) {
        deleteSiteObject(viewId, objectId).then((res) => {
          if (!res.error) {
            this.setState(({ siteObjects }) => {
              return {
                siteObjects: [
                  ...siteObjects.filter((so) => so.id !== objectId),
                ],
              };
            });

            if (this.drawControl && this.drawControl.draw) {
              // removing deleted object from map
              this.drawControl.draw.delete(siteObjects[0].id);
            }
          }

          this.setState({
            showSiteObjectDeleteConfirmationModal: false,
            drawModeType: 'site_objects',
            viewControlsCreateTime: Date.now(),
          });
        });

        return;
      }
    }

    this.setState({
      showSiteObjectDeleteConfirmationModal: false,
      drawModeType: 'site_objects',
      viewControlsCreateTime: Date.now(),
    });
  };

  private deleteGeoJsonList = (
    geoJson: GenericObjectType[] | null | undefined
  ) => {
    if (!geoJson || !this.drawControl || !this.drawControl.draw) {
      return;
    }

    try {
      this.drawControl.draw.delete(geoJson.map((x: GenericObjectType) => x.id));
    } catch (e) {
      log.error(e, 'ViewV2.deleteGeoJsonList');
    }
  };

  private handleSelectedMeasurementType = (
    selectedMeasurementIntentType: MeasureControlBoxIntentTypes
  ) => {
    this.setState(({ drawModeType }) => {
      return {
        selectedMeasurementIntentType,
        drawModeType: !selectedMeasurementIntentType ? null : drawModeType,
        isDrawModeDisabled: false,
        viewControlsCreateTime: Date.now(),
      };
    });
  };

  private handleIntentChange = (
    drawModeType?: DrawControlIntentTypes | null
  ): void => {
    this.setState(
      ({ selectedViewControlsMenuTypeList, activeGeoJsonShapeList }) => {
        return {
          activeGeoJsonShapeList: drawModeType ? activeGeoJsonShapeList : {},
          drawModeType: selectedViewControlsMenuTypeList
            ? drawModeType || null
            : null,
        };
      }
    );
  };

  private handleViewControlsMenuItemChange = (
    menuItem: ViewControlsV2TypeTypes
  ): void => {
    const {
      issue,
      history,
      projectId,
      aoiId,
      viewId,
      issueId,
      clearIssueState,
    } = this.props;
    const {
      selectedViewControlsMenuTypeList,
      activeGeoJsonShapeList,
      mapOpacityLevel,
    } = this.state;

    let _selectedViewControlsMenuTypeList: ViewControlsV2TypeTypes[] | null =
      selectedViewControlsMenuTypeList || [];
    let _mapOpacityLevel = mapOpacityLevel;

    // if 'menuItem' do not exists in '_selectedViewControlsMenuTypeList' push it to the array
    if (!inArray(_selectedViewControlsMenuTypeList, menuItem)) {
      _selectedViewControlsMenuTypeList.push(menuItem);
    }
    // if the first 'menuItem' in the '_selectedViewControlsMenuTypeList' is removed then remove all the subsequent 'menuItems' from the selectedViewControlsMenuTypeList
    else if (_selectedViewControlsMenuTypeList.indexOf(menuItem) < 1) {
      _selectedViewControlsMenuTypeList = [];
    } else {
      // if 'menuItem' exists in '_selectedViewControlsMenuTypeList'
      _selectedViewControlsMenuTypeList = removeArrayByValue(
        _selectedViewControlsMenuTypeList,
        menuItem
      ) as ViewControlsV2TypeTypes[];

      // if it fails 'isEnabledViewControlsItem' remove it from the array.
      _selectedViewControlsMenuTypeList =
        _selectedViewControlsMenuTypeList.filter((a) =>
          this.isEnabledViewControlsItem(
            a,
            true,
            _selectedViewControlsMenuTypeList
          )
        );
    }

    const _activeGeoJsonShapeList = {
      ...activeGeoJsonShapeList,
    };

    // delete polygons on clicking mark_issue close btn
    if (
      this.isItemInIssue(menuItem) && // if menuItem matches the list
      !inArray(_selectedViewControlsMenuTypeList, menuItem) // and if 'menuItem' was removed from 'selectedViewControlsMenuTypeList' (closed case)
    ) {
      this.deleteGeoJsonList(_activeGeoJsonShapeList.issue);
      _activeGeoJsonShapeList.issue = [];
    }

    // delete polygons on clicking measure close btn
    if (
      this.isItemInMeasure(menuItem) && // if 'menuItem' matches the list
      !inArray(_selectedViewControlsMenuTypeList, menuItem) // and if 'menuItem' was removed from 'selectedViewControlsMenuTypeList' (closed case)
    ) {
      this.deleteGeoJsonList(_activeGeoJsonShapeList.measure);
      _activeGeoJsonShapeList.measure = [];
    }

    // remove the issue query string from the url
    if (
      issue &&
      issueId &&
      !inArray(_selectedViewControlsMenuTypeList, 'mark_issue')
    ) {
      if (history) {
        if (clearIssueState) {
          clearIssueState();
        }

        history.push({
          pathname: viewUrl(projectId, aoiId, viewId),
        });
      }
    }

    // reset map opacity to the default if 'compare' is closed
    if (!inArray(_selectedViewControlsMenuTypeList, 'compare')) {
      _mapOpacityLevel = this.defaultMapOpacityLevel;
    }

    if (
      !_selectedViewControlsMenuTypeList ||
      _selectedViewControlsMenuTypeList.length < 1
    ) {
      // set selectedViewControlsMenuTypeList as null if the '_selectedViewControlsMenuTypeList' array is empty or no value found
      _selectedViewControlsMenuTypeList = null;

      // delete all geoJson ploygons if the '_selectedViewControlsMenuTypeList' array is empty or no value found
      this.deleteGeoJsonList(_activeGeoJsonShapeList.issue);
      this.deleteGeoJsonList(_activeGeoJsonShapeList.measure);
      _activeGeoJsonShapeList.issue = [];
      _activeGeoJsonShapeList.measure = [];
    }

    this.setState(
      ({
        selectedMeasurementIntentType,
        isDrawModeDisabled,
        drawModeType,
      }) => ({
        selectedViewControlsMenuTypeList: _selectedViewControlsMenuTypeList,
        activeGeoJsonShapeList: _selectedViewControlsMenuTypeList
          ? _activeGeoJsonShapeList
          : {},
        selectedMeasurementIntentType: _selectedViewControlsMenuTypeList
          ? selectedMeasurementIntentType
          : null,
        isDrawModeDisabled: _selectedViewControlsMenuTypeList
          ? isDrawModeDisabled
          : false,
        drawModeType: _selectedViewControlsMenuTypeList ? drawModeType : null,
        mapOpacityLevel: _mapOpacityLevel,
      })
    );
  };

  private isItemInMeasure = (item: string) => {
    return inArray(
      ['elevation', 'distance', 'area', 'volume', 'measure'],
      item
    );
  };

  private isItemInIssue = (item: string) => {
    return inArray(['mark_issue'], item);
  };

  private isItemInSiteObjects = (item: string) => {
    return inArray(
      ['create_object', 'delete_object', 'edit_object', 'site_objects'],
      item
    );
  };

  private handleZoomCenterChange = (
    mapZoomLevel: number,
    mapCenter: [number, number]
  ): void => {
    this.setState(() => {
      return {
        mapZoomLevel,
        mapCenter,
      };
    });
  };

  private handleOsmToggle = (): void => {
    this.setState((state) => ({
      isMapOsmVisible: !state.isMapOsmVisible,
    }));
  };

  /*
   * After the MapBox styles are loaded this function is called.
   * It will have two args, type and id.
   * Use these values to manipulate what layer should be on top etc..
   * */

  private handleMapStyleLoaded = (
    type: ViewV2StateMapStylesLayersIdDataTypes,
    id: string | null
  ): void => {
    const { compareViewId } = this.props;

    this.setState(
      ({ mapStylesLayersIdData }) => {
        return {
          isMapStyleLoaded: true,
          mapStylesLayersIdData: {
            ...mapStylesLayersIdData,
            [type]: id,
          },
        };
      },
      () => {
        const { mapStylesLayersIdData } = this.state;

        try {
          if (this.mapData && compareViewId) {
            if (
              type === 'view' &&
              this.mapData.getLayer(mapStylesLayersIdData.compareView)
            ) {
              if (mapStylesLayersIdData.compareView) {
                this.mapData.moveLayer(mapStylesLayersIdData.compareView);
              }
            } else if (type === 'compareView') {
              if (mapStylesLayersIdData.compareView) {
                this.mapData.moveLayer(mapStylesLayersIdData.compareView);
              }
            }
          }
        } catch (e) {
          log.error(e, `ViewV2.handleMapStyleLoaded`);
        }
      }
    );
  };

  private handleSurveyViewLayerUpdate = (layers: any[]) => {
    const { updateViewLayers } = this.props;
    const legendsDataList = layers.map((layer) => ({
      id: layer.id,
      type: layer.type,
      name: layer.name || layer.id,
      enabled: true,
      color: this.getLayerColor(layer),
    }));

    if (legendsDataList && legendsDataList.length) {
      if (updateViewLayers) {
        updateViewLayers(legendsDataList);
      }

      this.setState({
        legendsDataList,
      });
    }
  };

  private checkLegendsType = (legendsDataList: any[]) => {
    let result = true;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of legendsDataList) {
      if (item.type !== 'background' && item.type !== 'raster') {
        // if there is even one entry that is not a background or raster type, then
        // enable the legend control box
        result = false;
        break;
      }
    }

    return result;
  };

  private getActiveLayers = (): string[] => {
    const { legendsDataList } = this.state;

    return (legendsDataList || [])
      .filter((legend) => legend.enabled)
      .map((legend) => legend.id);
  };

  private getLayerColor = (layer: any): string => {
    const paintObj = layer ? (layer.paint ? layer.paint : {}) : {};
    const paintObjKeysArray = Object.keys(paintObj);

    for (let i = 0; i < paintObjKeysArray.length; i += 1) {
      if (paintObjKeysArray[i]) {
        if (paintObjKeysArray[i].endsWith('color')) {
          return paintObj[paintObjKeysArray[i]];
        }
      }
    }

    return '';
  };

  private handleLegendToggle = (layerId: string, status: boolean) => {
    const { updateViewLayers } = this.props;
    let { legendsDataList } = this.state;

    legendsDataList = (legendsDataList || []).map((legend) => {
      let { enabled } = legend;

      if (legend.id === layerId) {
        enabled = status;
      }

      return {
        ...legend,
        enabled,
      };
    });

    if (updateViewLayers) {
      updateViewLayers(legendsDataList);
    }

    this.setState({
      legendsDataList,
    });
  };

  private getShapeGeoJsonCreator = () => {
    const { view } = this.props;
    const { activeGeoJsonShapeList } = this.state;

    let features: GenericObjectType[] = [];

    if (view.type === 'perspective') {
      features = this.perspectiveRef.current.getAnnotations();
    } else if (view.type === 'inspection') {
      features = this.inspectionRef.current.getAnnotations();
    } else if (view.type === 'site_navigation') {
      features = this.siteWalkThroughRef.current.getAnnotations();
    } else if (view.type === 'map') {
      if (
        activeGeoJsonShapeList.measure &&
        activeGeoJsonShapeList.measure.length > 0
      ) {
        if (!undefinedOrNull(activeGeoJsonShapeList.measure[0].geometry)) {
          features = [activeGeoJsonShapeList.measure[0]];
        } else {
          // eslint-disable-next-line prefer-destructuring
          features = activeGeoJsonShapeList.measure[0] as GenericObjectType[];
        }
      } else if (
        activeGeoJsonShapeList.issue &&
        activeGeoJsonShapeList.issue.length > 0
      ) {
        features = [...activeGeoJsonShapeList.issue];
      }
    } else if (view.type === 'exterior_360') {
      if (
        activeGeoJsonShapeList.issue &&
        activeGeoJsonShapeList.issue.length > 0
      ) {
        const allPointsTillNow = activeGeoJsonShapeList.issue;
        const lastSelectedPoint = allPointsTillNow[allPointsTillNow.length - 1];

        features = [lastSelectedPoint];
      }
    }

    if (features && features.length > 0) {
      const geoJson = {
        type: 'FeatureCollection',
        crs: {
          type: 'EPSG',
          properties: {
            urn: 'urn:ogc:def:crs:OGC:1.3:CRS84',
          },
        },
        features,
      };

      return JSON.stringify(geoJson);
    }

    return null;
  };

  private handleMLSelectionChange = (
    type: ObjectType | null,
    subType: ObjectSubType | null
  ) => {
    const { view } = this.props;

    if (view.type === 'map') {
      this.handleMLSelectionChangeInMaps(type, subType);
    } else if (view.type === 'site_navigation') {
      // the same function will be used for all image views (site_nav, perspective, inspection)
      this.handleMLSelectionChangeInImageView(type, subType);
    }
  };

  private handleMLSelectionChangeInMaps = (
    type: ObjectType | null,
    subType: ObjectSubType | null
  ) => {
    const { siteObjects } = this.state;

    this.setState(
      {
        siteObjectType: type,
        siteObjectSubType: subType,
        drawModeType: 'site_objects',
        viewControlsCreateTime: Date.now(),
      },
      () => {
        if (!(this.drawControl && this.drawControl.draw)) {
          return;
        }

        if (subType === null) {
          this.drawControl.draw.deleteAll();
        }

        const featureList = siteObjects
          .filter((so) => so.subType === subType && so.type === type)
          .map((so) => {
            const feature = JSON.parse(so.finalFeatureGeoJSON);

            feature.properties.objectId = so.id;
            feature.properties.source = so.source;
            feature.properties.deleted = so.deleted;

            return feature;
          });

        const geoJson = {
          type: 'FeatureCollection',
          crs: null,
          features: featureList,
        };

        this.drawControl.draw.add(geoJson);
      }
    );
  };

  private handleMLSelectionChangeInImageView = (
    type: ObjectType | null,
    subType: ObjectSubType | null
  ) => {
    this.setState({
      siteObjectType: type,
      siteObjectSubType: subType,
      viewControlsCreateTime: Date.now(),
    });
  };

  private getViewConfigCreator = () => {
    const { view, compareView } = this.props;
    const { viewConfigData, selectedMeasurementIntentType } = this.state;

    if (view.type === 'perspective' && this.perspectiveRef.current) {
      const mapZoom = this.perspectiveRef.current.getMapZoom();
      const mapCenter = this.perspectiveRef.current.getMapCenter();

      return () =>
        viewConfigData
          ? {
              ...viewConfigData,
              zoom: mapZoom || undefined,
              latitude: !undefinedOrNull(mapCenter) ? mapCenter[0] : undefined,
              longitude: !undefinedOrNull(mapCenter) ? mapCenter[1] : undefined,
            }
          : {};
    }

    if (view.type === 'inspection' && this.inspectionRef.current) {
      const mapZoom = this.inspectionRef.current.getMapZoom();
      const mapCenter = this.inspectionRef.current.getMapCenter();

      return () =>
        viewConfigData
          ? {
              ...viewConfigData,
              zoom: mapZoom || undefined,
              latitude: !undefinedOrNull(mapCenter) ? mapCenter[0] : undefined,
              longitude: !undefinedOrNull(mapCenter) ? mapCenter[1] : undefined,
            }
          : {};
    }

    if (view.type === 'site_navigation' && this.siteWalkThroughRef.current) {
      const mapZoom = this.siteWalkThroughRef.current.getMapZoom();
      const mapCenter = this.siteWalkThroughRef.current.getMapCenter();

      return () =>
        viewConfigData
          ? {
              selection: viewConfigData.selection,
              zoom: mapZoom || undefined,
              latitude: !undefinedOrNull(mapCenter) ? mapCenter[0] : undefined,
              longitude: !undefinedOrNull(mapCenter) ? mapCenter[1] : undefined,
            }
          : {};
    }

    if (!view || view.type !== 'map') {
      return () => viewConfigData || {};
    }

    return () => {
      const center = this.mapData ? this.mapData.getCenter() : null;

      return {
        zoom: this.mapData ? this.mapData.getZoom() : view.zoomDefault,
        longitude: center ? center.lng : view.centerLongitude,
        latitude: center ? center.lat : view.centerLatitude,
        measurementType: selectedMeasurementIntentType,
        compareViewId: !undefinedOrNull(compareView) ? compareView.id : null,
        rotation: 0,
        // volumePlaneElevation is set while creating an Issue in MarkIssueControlBox.tsx#handleCreateIssue
        volumePlaneElevation: null,
      };
    };
  };

  @autobind
  private getScreenshot(): string | undefined {
    const { view } = this.props;

    if (view.type === 'perspective') {
      return this.perspectiveRef.current.getScreenshot();
    }

    if (view.type === 'inspection') {
      return this.inspectionRef.current.getScreenshot();
    }

    if (view.type === 'site_navigation') {
      return this.siteWalkThroughRef.current.getScreenshot();
    }

    if (view.subType === 'exterior_360') {
      return this.exteriorRef.current.getScreenshot();
    }

    if (!this.mapData) {
      return;
    }

    const canvas = this.mapData.getCanvas();

    return canvas.toDataURL();
  }

  private isDrawControlAllowed = (isMenuActive: boolean): boolean => {
    const { view, issue, viewId, issueId } = this.props;
    const { isMapStyleLoaded } = this.state;

    if (!view || view.type !== 'map') {
      return false;
    }

    // ensure draw control rendered only if style loaded
    if (!isMapStyleLoaded) {
      return false;
    }

    if (isMenuActive) {
      return true;
    }

    return (
      !!(
        issue &&
        issue.shapeGeoJson &&
        isMapStyleLoaded &&
        issueId &&
        issue.viewId === viewId
      ) === true
    );
  };

  private getDrawModeType = (
    isMenuActive: boolean
  ): DrawControlIntentTypes | null => {
    const { issue, issueId } = this.props;
    const { drawModeType, activeGeoJsonShapeList, isDrawModeDisabled } =
      this.state;

    if (isDrawModeDisabled || !isMenuActive) {
      return null;
    }

    // do not let marking on the maps if issues are active
    if (!undefinedOrNull(issue) && !undefinedOrNull(issueId)) {
      return 'static';
    }

    // if activeGeoJsonShapeList.measure is active then 'mark_issue' should not be allowed to mark on the map
    if (
      isMenuActive &&
      activeGeoJsonShapeList.measure &&
      activeGeoJsonShapeList.measure.length > 0 &&
      drawModeType === 'mark_issue'
    ) {
      return 'static';
    }

    return drawModeType;
  };

  private handleOpacity = (mapOpacityLevel: number) => {
    this.setState({
      mapOpacityLevel,
    });
  };

  private allowedMeasureMenuSelectSequenceList =
    (): ViewV2AllowedMeasureMenuSelectSequenceListTypes => {
      const { compareView } = this.props;
      const { activeGeoJsonShapeList } = this.state;

      /**
       * Measure --> Mark
       * Compare ---> Mark
       * Compare ---> Measure ---> Mark
       */

      return [
        [
          'measure',
          {
            name: 'mark_issue',
            enabled:
              !undefinedOrNull(activeGeoJsonShapeList.measure) &&
              activeGeoJsonShapeList.measure.length > 0,
          },
        ],
        [
          'compare',
          {
            name: 'mark_issue',
            enabled: !undefinedOrNull(compareView),
          },
        ],
        [
          'compare',
          {
            name: 'measure',
            enabled: !undefinedOrNull(compareView),
          },
          {
            name: 'mark_issue',
            enabled: !undefinedOrNull(compareView),
          },
        ],
      ];
    };

  private readdGeoJsonListValues = (
    geoJsonList: NullOrGenericObjectType[]
  ): void => {
    if (!geoJsonList) {
      return;
    }

    geoJsonList.map((item: GenericObjectType) => {
      // eslint-disable-next-line no-param-reassign
      item.crs = null;

      if (this.drawControl) {
        this.drawControl.draw.add(item);
      }

      return item;
    });
  };

  private deleteShapeList = (features: NullOrGenericObjectType[]): void => {
    if (!features) {
      return;
    }

    features.map((item: GenericObjectType) => {
      // eslint-disable-next-line no-param-reassign
      if (this.drawControl) {
        this.drawControl.draw.delete(item.id);
      }

      return item;
    });
  };

  private isEnabledViewControlsItem = (
    menuItem: ViewControlsV2TypeTypes,
    strict = false, // if 'strict' is true every 'menuItem' should exist in the sequence path and follow it
    nextSelectedViewControlsMenuTypeList?: ViewControlsV2TypeTypes[] | null
  ) => {
    const { selectedViewControlsMenuTypeList } = this.state;

    let _selectedViewControlsMenuTypeList: ViewControlsV2TypeTypes[] | null =
      selectedViewControlsMenuTypeList;

    if (!undefinedOrNull(nextSelectedViewControlsMenuTypeList)) {
      _selectedViewControlsMenuTypeList = nextSelectedViewControlsMenuTypeList;
    }

    // enable all items if '_selectedViewControlsMenuTypeList' is empty
    if (
      undefinedOrNull(_selectedViewControlsMenuTypeList) ||
      _selectedViewControlsMenuTypeList.length < 1
    ) {
      return true;
    }

    let filteredSelectedItemList = this.allowedMeasureMenuSelectSequenceList();
    const selectedItemsListLength = _selectedViewControlsMenuTypeList.length;

    // enable for items not included in 'allowedMeasureMenuSelectSequenceList' if they are in index 0
    if (selectedItemsListLength < 1) {
      return !strict; // if 'strict' is true every 'menuItem' should exist in the sequence path and follow it
    }

    // filter out all the arrays in 'allowedMeasureMenuSelectSequenceList' which follows _selectedViewControlsMenuTypeList
    for (let i = 0; i < selectedItemsListLength; i += 1) {
      const item = _selectedViewControlsMenuTypeList[i];

      filteredSelectedItemList = filteredSelectedItemList.filter((a) => {
        if (!a[i]) {
          return false;
        }

        // when object found
        if (isObject(a[i])) {
          return (
            (a[i] as ViewV2AllowedMeasureMenuSelectSequenceListObjectTypes)
              .name === item &&
            (a[i] as ViewV2AllowedMeasureMenuSelectSequenceListObjectTypes)
              .enabled
          );
        }

        // when string
        return a[i] === item;
      });
    }

    // enable for items not included in 'allowedMeasureMenuSelectSequenceList' if they are in index 0
    if (
      filteredSelectedItemList.length < 1 &&
      _selectedViewControlsMenuTypeList[0] === menuItem
    ) {
      return true;
    }

    // filter out all the arrays from 'filteredSelectedItemList' which has the menuItem in it
    const allowedItemList = filteredSelectedItemList.filter((a) => {
      const menuItemPos = (_selectedViewControlsMenuTypeList || []).indexOf(
        menuItem
      );

      // if the 'menuitem' already exists in the '_selectedViewControlsMenuTypeList' return true
      if (menuItemPos > -1) {
        return (
          menuItemPos <= (_selectedViewControlsMenuTypeList || []).length - 1
        );
      }

      const item = a[(_selectedViewControlsMenuTypeList || []).length];

      // if the 'menuitem' already exists in the next index of the array of '_selectedViewControlsMenuTypeList'; return true
      // when the item is an object
      if (isObject(item)) {
        const _item =
          item as ViewV2AllowedMeasureMenuSelectSequenceListObjectTypes;

        return _item.name === menuItem && _item.enabled;
      }

      return item === menuItem;
    });

    return allowedItemList && allowedItemList.length > 0;
  };

  private handleViewFooterCenterClick = (view: GenericObjectType) => {
    this.setState({
      mapCenter: [view.centerLongitude, view.centerLatitude],
    });
  };

  private handleViewConfigChange = (
    viewConfigData: GenericObjectType,
    callback = () => {}
  ) => {
    this.setState({ viewConfigData }, () => callback());
  };

  private handleSiteWalkthroughReverseMapInspect = (
    preservedMapMeta: ViewV2StateTypesPreservedMapMetaTypes | null,
    siteWalkthroughViewData: NullOrGenericObjectType
  ) => {
    const { history } = this.props;

    this.preservedMapMeta = preservedMapMeta;
    this.preservedMapZoom = 19;

    const reverseInspectView = this.getAerialViewForReverseInspect(
      siteWalkthroughViewData
    );

    if (undefinedOrNull(reverseInspectView)) {
      return;
    }

    const { aoiId, projectId, id } = reverseInspectView;

    history.push(viewUrl(projectId, aoiId, id));
  };

  private handleInitialMap = (mapData: GenericObjectType) => {
    this.mapData = mapData;

    this.setState({
      isMapMoveComplete: false,
    });
  };

  private handleFullScreenToggle = (): void => {
    const { onFullScreen } = this.props;

    if (!onFullScreen) {
      return;
    }

    onFullScreen();
  };

  private getViewToInspect = (
    viewType = 'site_navigation'
  ): null | GenericObjectType => {
    const { viewsList, view } = this.props;

    if (!view || !viewsList) {
      return null;
    }

    const filteredViewList = viewsList.filter(
      (a: GenericObjectType) =>
        a.date === view.date && a.type === viewType && a.certifiedForDisplay
    );

    if (!filteredViewList || !filteredViewList[0]) {
      return null;
    }

    return filteredViewList[0];
  };

  private getAerialViewForReverseInspect = (
    siteWalkthroughViewData: NullOrGenericObjectType
  ): null | GenericObjectType => {
    const { viewsList } = this.props;

    let filteredViewsListByType: GenericObjectType[] = [];
    let output: NullOrGenericObjectType = null;

    if (!viewsList) {
      return null;
    }

    const sortedViewsListByDate = sortByDate(viewsList, 'date', true);

    filteredViewsListByType = sortedViewsListByDate.filter(
      (a) => a.type === 'map' && isCertifiedView(a)
    );

    filteredViewsListByType = filteredViewsListByType.filter(
      (a) => a.subType === 'aerial'
    );

    if (isEmpty(filteredViewsListByType)) {
      filteredViewsListByType = filteredViewsListByType.filter(
        (a) => a.subType === 'elevation'
      );

      if (isEmpty(filteredViewsListByType)) {
        filteredViewsListByType = filteredViewsListByType.filter(
          (a) => a.subType === 'survey'
        );
      }
    }

    // capture the View Data from site walkthrough view
    if (siteWalkthroughViewData) {
      // filter out the a view from viewsList which matches siteWalkthroughViewData
      output = filteredArrayValue(
        filteredViewsListByType.filter(
          (a) => a.date === siteWalkthroughViewData.date
        )
      );
    }

    if (!output) {
      return null;
    }

    return output;
  };

  private captureUrlParams = () => {
    const { match } = this.props;

    return match.params;
  };

  private handleMapboxContextMenu = (event: GenericObjectType): void => {
    this.setState({
      mapboxInspectPositions: {
        ...event.lngLat,
      },
    });
  };

  private handleInspectClick = (
    config = { latitude: 0, longitude: 0, elevation: 0 }
  ): void => {
    const { history, projectId, aoiId, viewId, view } = this.props;

    let formData = {
      latitude: config.latitude,
      longitude: config.longitude,
      elevation: config.elevation,
      viewId,
      targetViewId: null,
    };

    if (view.type === 'map') {
      const { mapboxInspectPositions } = this.state;

      if (!mapboxInspectPositions) {
        log.error(
          'Error occured while inspecting the map',
          'ViewV2 -> handleMapboxInspectClick'
        );

        return;
      }

      formData = {
        latitude: mapboxInspectPositions.lat,
        longitude: mapboxInspectPositions.lng,
        elevation: 0,
        viewId,
        targetViewId: null,
      };
    }

    this.setState({ isViewItemLoading: true });
    let inspsectionTargetView: any = null;

    if (view.type === 'map') {
      inspsectionTargetView = this.getViewToInspect();
    } else if (view.type === 'three_d') {
      inspsectionTargetView = this.getViewToInspect('inspection');
      if (inspsectionTargetView !== null) {
        formData.targetViewId = inspsectionTargetView.id;
      }
    }

    if (!inspsectionTargetView) {
      this.setState({
        mapboxInspectPositions: null,
        isViewItemLoading: false,
      });

      return;
    }

    let useApiToGetTargetImageGuid = true;

    if (view.type === 'map') {
      if (view.missionId === inspsectionTargetView.missionId) {
        // only use the api if both views in same mission, else guids will not match
        useApiToGetTargetImageGuid = false;
      }
    }

    if (useApiToGetTargetImageGuid) {
      viewToolsApis
        .getTargetImageForInspection(formData)
        .then((res) => {
          const { error: apiError, data: apiData } = res;

          this.setState({
            mapboxInspectPositions: null,
            isViewItemLoading: false,
          });

          if (apiError || undefinedOrNull(apiData.guid)) {
            log.error(apiError);
            throw new Error('Could not get image guid from API call');
          }

          history.push(
            viewUrl(projectId, aoiId, inspsectionTargetView.id, {
              gotoGuid: apiData.guid,
            })
          );
        })
        .catch((_) => {
          this.setState({
            mapboxInspectPositions: null,
            isViewItemLoading: false,
          });

          history.push(
            viewUrl(projectId, aoiId, inspsectionTargetView.id, {
              latitude: formData.latitude,
              longitude: formData.longitude,
            })
          );
        });
    } else {
      this.setState({
        mapboxInspectPositions: null,
        isViewItemLoading: false,
      });

      history.push(
        viewUrl(projectId, aoiId, inspsectionTargetView.id, {
          latitude: formData.latitude,
          longitude: formData.longitude,
        })
      );
    }
  };

  private getViewControlItems = (
    filter: ViewControlsV2TypeTypes[] | null = null
  ) => {
    const {
      view,
      compareView,
      downloads,
      reports,
      userRole,
      issue,
      issueId,
      projectId,
      aoiId,
      history,
    } = this.props;

    const {
      activeGeoJsonShapeList,
      legendsDataList,
      selectedMeasurementIntentType,
      mapOpacityLevel,
      siteObjects,
      projectSiteObjectClasses,
      siteObjectSubType: objectSubType,
      siteObjectType: objectType,
      viewConfigData,
    } = this.state;

    let items: ViewControlsV2ItemsTypes[] = [
      {
        id: 'site_objects',
        label: 'Detect',
        selected: this.isInActionMenuType(['site_objects']),
        disabled: !this.isEnabledViewControlsItem('site_objects'),
        renderControlBox: () => (
          <SiteObjectControlBox
            siteObjects={siteObjects}
            projectSiteClasses={projectSiteObjectClasses}
            onClose={this.handleViewControlsMenuItemChange}
            handleMLSelectionChange={this.handleMLSelectionChange}
            handleSelectedSiteObjectIntentType={this.handleIntentChange}
            objectSubType={objectSubType}
            objectType={objectType}
            itemType="site_objects"
            hasAdminAccess={userRole === 'project_admin'}
            view={view}
            viewConfig={viewConfigData}
          />
        ),
      },
      {
        id: 'legend',
        label: 'Legend',
        selected: this.isInActionMenuType(['legend']),
        disabled: !this.isEnabledViewControlsItem('legend'),
        renderControlBox: () => (
          <LegendControlBox
            legends={legendsDataList || []}
            onLegendToggle={this.handleLegendToggle}
            onClose={this.handleViewControlsMenuItemChange}
            itemType="legend"
          />
        ),
      },
      {
        id: 'info',
        label: 'Info',
        selected: this.isInActionMenuType(['info']),
        disabled: !this.isEnabledViewControlsItem('info'),
        renderControlBox: () => (
          <InfoControlBox
            metadata={view.metadata}
            onClose={this.handleViewControlsMenuItemChange}
            itemType="info"
          />
        ),
      },
      {
        id: 'measure',
        label: 'Measure',
        selected: this.isInActionMenuType(['measure']),
        disabled: !this.isEnabledViewControlsItem('measure'),
        renderControlBox: () => (
          <MeasureControlBox
            onIntentChange={this.handleIntentChange}
            handleSelectedMeasurementType={this.handleSelectedMeasurementType}
            selectedIntent={selectedMeasurementIntentType}
            shape={
              activeGeoJsonShapeList.measure &&
              activeGeoJsonShapeList.measure[0]
                ? activeGeoJsonShapeList.measure[0]
                : null
            }
            issueId={issueId}
            issue={issue}
            view={view}
            onClose={this.handleViewControlsMenuItemChange}
            viewId={view.id}
            itemType="measure"
          />
        ),
      },
      {
        id: 'mark_issue',
        label: 'Mark Issue',
        selected: this.isInActionMenuType(['mark_issue']),
        disabled: !this.isEnabledViewControlsItem('mark_issue'),
        renderControlBox: () => (
          <MarkIssueControlBox
            viewId={view.id}
            onScreenShot={this.getScreenshot}
            onIntentChange={this.handleIntentChange}
            shapeGeoJsonCreator={this.getShapeGeoJsonCreator}
            configCreator={this.getViewConfigCreator()}
            userRole={userRole}
            onClose={this.handleViewControlsMenuItemChange}
            itemType="mark_issue"
            issueId={issueId}
            issue={issue}
            projectId={projectId}
            aoiId={aoiId}
            history={history}
          />
        ),
      },
      {
        id: 'compare',
        label: 'Compare',
        selected: this.isInActionMenuType(['compare']),
        disabled: !this.isEnabledViewControlsItem('compare'),
        renderControlBox: () => (
          <CompareControlBox
            intialOpacityLevel={mapOpacityLevel}
            value={compareView ? compareView.id : null}
            onOpacityChange={this.handleOpacity}
            viewId={view.id}
            onClose={this.handleViewControlsMenuItemChange}
            itemType="compare"
          />
        ),
      },
      {
        id: 'download',
        label: 'Download',
        selected: this.isInActionMenuType(['download']),
        disabled: !this.isEnabledViewControlsItem('download'),
        renderControlBox: () => (
          <DownloadControlBox
            downloads={downloads || []}
            userRole={userRole}
            viewId={view.id}
            onClose={this.handleViewControlsMenuItemChange}
            itemType="download"
          />
        ),
      },
      {
        id: 'reports',
        label: 'Reports',
        selected: this.isInActionMenuType(['reports']),
        disabled: !this.isEnabledViewControlsItem('reports'),
        renderControlBox: () => (
          <ReportsControlBox
            reports={reports || []}
            onClose={this.handleViewControlsMenuItemChange}
            itemType="reports"
          />
        ),
      },
      {
        id: 'share',
        label: 'Share',
        selected: this.isInActionMenuType(['share']),
        disabled: !this.isEnabledViewControlsItem('share'),
        renderControlBox: () => (
          <ShareControlBox
            viewId={view.id}
            configCreator={this.getViewConfigCreator()}
            onClose={this.handleViewControlsMenuItemChange}
            itemType="share"
          />
        ),
      },
    ];

    if (!view.metadata || !view.metadata.length) {
      // removing info control if there is no view metadata
      items = items.filter((i) => i.id !== 'info');
    }

    if (!reports || reports.length === 0) {
      // removing download control if there is no reports
      items = items.filter((i) => i.id !== 'reports');
    }

    if (!downloads || downloads.length === 0) {
      // removing download control if there is no reports
      items = items.filter((i) => i.id !== 'download');
    }

    if (!siteObjects || siteObjects.length === 0) {
      // removing detect option if there are no objects
      items = items.filter((i) => i.id !== 'site_objects');
    }

    if (this.checkLegendsType(legendsDataList)) {
      items = items.filter((i) => i.id !== 'legend');
    }

    if (filter) {
      if (this.checkLegendsType(legendsDataList)) {
        items = items.filter((i) => i.id !== 'legend');
      }

      return items.filter((i) => filter.indexOf(i.id) > -1);
    }

    return items;
  };

  private RenderFullScreenBtn = (exit: boolean = false): JSX.Element => {
    return (
      <div className={styles.fullScreenBtn}>
        {exit ? (
          <Button
            icon="fullscreen"
            type="secondary"
            onClick={() => {
              this.handleFullScreenToggle();
            }}
          />
        ) : (
          <Button
            icon="fullscreen-exit"
            type="secondary"
            onClick={() => {
              this.handleFullScreenToggle();
            }}
          />
        )}
      </div>
    );
  };

  private videosOptions = () => {
    const { projectVideoData } = this.state;

    const _projectVideoData = [...projectVideoData];

    const sortedData = sortByName(
      _projectVideoData.map((a, index) => {
        return {
          label: a.name,
          value: `${index}`,
        };
      }),
      'label'
    );

    return sortedData;
  };

  private handleVideosOptionsChange = (option: GenericObjectType) => {
    this.setState({
      projectVideoSelectedIndex: option.value,
    });
  };

  private handleProjectVideoPlayerLoaded = () => {
    this.setState({
      isProjectVideoPlayerLoading: false,
    });
  };

  private getMapCenter = () => {
    const { view } = this.props;
    const { mapCenter } = this.state;

    if (this.preservedMapMeta) {
      const _preservedMapMeta = [
        this.preservedMapMeta.centerLongitude,
        this.preservedMapMeta.centerLatitude,
      ];

      this.preservedMapMeta = null;

      return _preservedMapMeta;
    }

    if (mapCenter) {
      return mapCenter;
    }

    return [view.centerLongitude, view.centerLatitude];
  };

  private getMapZoom = (): [number] => {
    const { view } = this.props;
    const { mapZoomLevel } = this.state;

    if (!undefinedOrNull(this.preservedMapZoom)) {
      const _preservedMapZoom: number = this.preservedMapZoom;

      this.preservedMapZoom = null;

      return [_preservedMapZoom];
    }

    return !undefinedOrNull(mapZoomLevel) ? [mapZoomLevel] : [view.zoomDefault];
  };

  private handleGuideLineChange = () => {
    this.setState(({ isGuideLineEnabled }) => {
      return {
        isGuideLineEnabled: !isGuideLineEnabled,
      };
    });
  };

  private showGuideLineFooterButton = () => {
    const { view } = this.props;

    return inArray(['elevation', 'aerial'], view.subType);
  };

  private getViewControlsMenuList = (
    viewType: string | undefined,
    viewSubType: string | undefined,
    isTerrainMap: boolean,
    isContourMaps: boolean,
    viewMetadata: GenericObjectType[],
    downloads: GenericObjectType[],
    reports: GenericObjectType[]
  ): ViewControlsV2TypeTypes[] => {
    const { siteObjects, legendsDataList } = this.state;

    let menuList: ViewControlsV2TypeTypes[] = [];

    switch (viewType) {
      case 'interior_360':
      case 'exterior_360':
      case 'site_navigation':
      case 'inspection':
      case 'perspective':
        menuList = ['mark_issue', 'reports', 'share', 'site_objects'];
        break;

      case 'three_d':
        menuList = ['share', 'download'];
        break;

      case 'video':
        menuList = ['share', 'reports'];
        break;

      case 'map':
      default:
        if (isTerrainMap || isContourMaps) {
          menuList = ['measure', 'download'];
        } else {
          menuList = [
            'mark_issue',
            'measure',
            'compare',
            'share',
            'reports',
            'download',
            'reports',
            'site_objects',
            'info',
          ];

          if (viewSubType === 'survey') {
            menuList.push('legend');
          }

          if (viewSubType === 'elevation_difference') {
            menuList = [];
          }
        }

        break;
    }

    if (!viewMetadata || viewMetadata.length < 1) {
      menuList = removeArrayByValue(
        menuList,
        'info'
      ) as ViewControlsV2TypeTypes[];
    }

    if (!reports || reports.length < 1) {
      menuList = removeArrayByValue(
        menuList,
        'reports'
      ) as ViewControlsV2TypeTypes[];
    }

    if (!downloads || downloads.length < 1) {
      menuList = removeArrayByValue(
        menuList,
        'download'
      ) as ViewControlsV2TypeTypes[];
    }

    if (!siteObjects || siteObjects.length < 1) {
      menuList = removeArrayByValue(
        menuList,
        'site_objects'
      ) as ViewControlsV2TypeTypes[];
    }

    if (this.checkLegendsType(legendsDataList)) {
      menuList = removeArrayByValue(
        menuList,
        'legend'
      ) as ViewControlsV2TypeTypes[];
    }

    return menuList;
  };

  private getViewSourceUrlType = (url: GenericObjectType | null) => {
    const defaultUrl = 'mapbox';

    if (!url) {
      return defaultUrl;
    }

    const vimanaTileUrlProtocol = 'vimana://';
    const split = url.match(vimanaTileUrlProtocol);

    const isVimanaProtol = !!(split && split.index === 0);

    if (isVimanaProtol) {
      return 'vimana';
    }

    return defaultUrl;
  };

  private RenderViewLoading = (): JSX.Element | null => {
    return (
      <div className={styles.loadingScreen}>
        <Loading type="ellipsis" />
      </div>
    );
  };

  private RenderPerspectiveView = (): JSX.Element | null => {
    const { issue, issueId } = this.props;

    if (issueId && !issue) {
      return <LoadingOverlay />;
    }

    return <></>;
  };

  private RenderInspectionView = (): JSX.Element | null => {
    const { issue, issueId } = this.props;

    if (issueId && !issue) {
      return <LoadingOverlay />;
    }

    return <></>;
  };

  private RenderSiteWalkthroughView = (): JSX.Element | null => {
    const {
      view,
      shareId,
      viewDescriptor,
      viewConfig,
      boundary,
      issue,
      issueId,
      gotoGuid,
      latitude,
      longitude,
      userData,
      userRole,
    } = this.props;

    const { drawModeType, siteObjects, siteObjectType, siteObjectSubType } =
      this.state;

    return (
      <SiteWalkthrough
        viewData={view}
        shareData={{
          viewDescriptorData: viewDescriptor,
          viewConfigData: viewConfig,
          boundaryData: boundary,
        }}
        gotoGuid={gotoGuid}
        viewType={view.type}
        shareId={shareId}
        issue={issue}
        issueId={issueId}
        ref={this.siteWalkThroughRef}
        onConfigChange={(currentViewState: GenericObjectType) => {
          this.handleViewConfigChange(currentViewState, () => {
            this.setState({
              // ensure view controls are updated on config change
              viewControlsCreateTime: Date.now(),
            });
          });
        }}
        onReverseMapLookUp={this.handleSiteWalkthroughReverseMapInspect}
        latitude={latitude}
        longitude={longitude}
        isMarkIssueActive={drawModeType === 'mark_issue'}
        showReverseInspect={
          !undefinedOrNull(this.getAerialViewForReverseInspect(view))
        }
        onImagesSelectedMarkerIndexChange={
          this.handleImagesSelectedMarkerIndexChange
        }
        userRole={userRole}
        userData={userData}
        siteObjects={siteObjects}
        siteObjectType={siteObjectType}
        siteObjectSubType={siteObjectSubType}
        drawControlIntent={drawModeType}
        handleShapeCreate={this.handleShapeCreate}
        handleShapeSelect={this.onDrawSelectionChange}
      />
    );
  };

  private RenderExterior360View = (): JSX.Element | null => {
    const { view, shareId, viewDescriptor, viewConfig, boundary, issueId } =
      this.props;

    const { drawModeType } = this.state;

    return (
      <Exterior360
        view={view}
        viewDescriptor={viewDescriptor}
        viewConfig={viewConfig}
        shareId={shareId}
        boundary={boundary}
        issueId={issueId}
        onShapeCreate={this.handleShapeCreate}
        isMarkIssueActive={drawModeType === 'mark_issue'}
        ref={this.exteriorRef}
        onConfigChange={(currentViewState: GenericObjectType) => {
          this.handleViewConfigChange(currentViewState);
        }}
      />
    );
  };

  private RenderInterior360View = (): JSX.Element | null => {
    const { view } = this.props;

    return (
      <Interior360
        view={view}
        onConfigChange={(currentViewState: GenericObjectType) => {
          this.handleViewConfigChange(currentViewState);
        }}
      />
    );
  };

  private RenderThreeDView = (): JSX.Element | null => {
    const { view } = this.props;

    if (view.type === 'three_d' && view.subType === 'potree') {
      return (
        <PotreeWrapper
          view={view}
          inspectionCallback={(
            position:
              | { latitude: number; longitude: number; elevation: number }
              | undefined
          ) => {
            this.handleInspectClick(position);
          }}
          inspectionTargetView={this.getViewToInspect('inspection')}
        />
      );
    }

    return (
      <iframe
        title="3D View"
        src={view.sourceUrl}
        className={styles.threeDView}
      />
    );
  };

  private RenderSplitView = (): JSX.Element | null => {
    const { view, splitView, viewsList, history } = this.props;
    let mapState = {};

    if (this.mapData) {
      const center = this.mapData.getCenter();

      mapState = {
        zoom: this.mapData.getZoom(),
        center: [center.lng, center.lat],
      };
    }

    return (
      <SplitView
        history={history}
        view1={view}
        view2={splitView}
        {...mapState}
        onMapMove={(zoom: number, center: [number, number]) =>
          this.handleZoomCenterChange(zoom, center)
        }
        viewsList={viewsList}
      />
    );
  };

  private RenderMapView = (): JSX.Element | null => {
    const { view, isAoiArtifact, projectId, aoiId } = this.props;

    const { RenderMapboxView, RenderElevationDifference } = this;

    if (
      !isAoiArtifact &&
      (view.subType === 'dtm' || view.subType === 'contour')
    ) {
      return (
        <DroneAnimation
          className="center"
          projectId={projectId}
          aoiId={aoiId}
        />
      );
    }

    const typeOfMap = this.getViewSourceUrlType(view?.sourceUrl);

    if (typeOfMap === 'vimana') {
      return <RenderElevationDifference view={view} />;
    }

    return <RenderMapboxView view={view} />;
  };

  private RenderElevationDifference = ({
    ...args
  }: {
    view: any;
  }): JSX.Element | null => {
    const { view } = args;

    return <ElevationDifference view={view} />;
  };

  private RenderMapboxView = ({
    ...args
  }: {
    view: GenericObjectType;
  }): JSX.Element | null => {
    const { compareView, isAoiArtifact, isFullScreen } = this.props;
    const { view } = args;
    const { RenderDrawControl } = this;

    const { isMapOsmVisible, mapOpacityLevel, isGuideLineEnabled } = this.state;

    return (
      <MapboxView
        onMapInit={(initialMap) => {
          this.handleInitialMap(initialMap);
        }}
        onContextMenuClick={this.handleMapboxContextMenu}
        defaultCenter={this.getMapCenter()}
        defaultZoom={this.getMapZoom()}
        minZoom={view.zoomMin}
        maxZoom={view.zoomMax}
        showControls
        showZoom
        showRotate
        style={isMapOsmVisible ? MAPBOX_STYLE_SATELLITE_V9_URL : null}
        accessToken={view.sourceToken}
      >
        {view.type === 'map' &&
        isGuideLineEnabled &&
        this.showGuideLineFooterButton() ? (
          <React.Fragment>
            <div className={styles.gridX} />
            <div className={styles.gridY} />
          </React.Fragment>
        ) : null}

        {view.type === 'map' && view.subType === 'survey' && !isAoiArtifact ? (
          <SurveyView
            key={view.id}
            view={view}
            onLayerUpdate={this.handleSurveyViewLayerUpdate}
            activeLayers={this.getActiveLayers()}
            type="view"
            onLoad={this.handleMapStyleLoaded}
          />
        ) : (
          <MapboxStyle
            key={view.id}
            style={view.sourceUrl}
            accessToken={view.sourceToken}
            type="view"
            onLoad={this.handleMapStyleLoaded}
          />
        )}

        {this.isInActionMenuType(['compare']) && compareView ? (
          <MapboxStyle
            key={compareView.id}
            accessToken={compareView.sourceToken}
            style={compareView.sourceUrl}
            opacity={mapOpacityLevel}
            type="compareView"
            onLoad={this.handleMapStyleLoaded}
          />
        ) : null}

        {view.type === 'map' &&
        ['elevation', 'dtm', 'survey', 'contour'].indexOf(view.subType) > -1 &&
        view.demColorMapping &&
        view.demColorMapping.length ? (
          <ElevationScale
            className={styles.scale}
            units={view.coordinateUnits || 'METRES'}
            dems={
              view.demColorMapping &&
              view.demColorMapping.length > 0 &&
              view.demColorMapping.slice().reverse()
            }
          />
        ) : null}

        {typeof isFullScreen !== 'undefined'
          ? isFullScreen
            ? this.RenderFullScreenBtn()
            : this.RenderFullScreenBtn(true)
          : null}

        <RenderDrawControl />
      </MapboxView>
    );
  };

  private RenderDrawControl = (): JSX.Element | null => {
    const { selectedViewControlsMenuTypeList } = this.state;
    let isMenuActive = false;

    if (!undefinedOrNull(selectedViewControlsMenuTypeList)) {
      isMenuActive = this.isInActionMenuType([
        'measure',
        'mark_issue',
        'site_objects',
      ]);
    }

    if (this.isDrawControlAllowed(isMenuActive)) {
      return (
        <DrawControl
          onDrawCreate={this.handleShapeCreate}
          onDrawUpdate={this.handleShapeUpdate}
          onDrawSelectionChange={this.onDrawSelectionChange}
          drawMode={this.getDrawModeType(isMenuActive)}
          selectedViewControlsMenuTypeList={selectedViewControlsMenuTypeList}
          ref={(newDrawControl: any) => {
            this.setDrawControl(newDrawControl);
          }}
        />
      );
    }

    this.drawControl = null;

    return null;
  };

  private RenderVideosView = (): JSX.Element | null => {
    const {
      projectVideoSelectedIndex,
      projectVideoData,
      isProjectVideoPlayerLoading,
      projectVideoPlayerCreateTime,
    } = this.state;

    const { RenderViewLoading } = this;

    if (!projectVideoData || !projectVideoData[projectVideoSelectedIndex]) {
      return <RenderViewLoading />;
    }

    let $videoEl = null;

    if (projectVideoData[projectVideoSelectedIndex].provider === 'youtube') {
      $videoEl = (
        <iframe
          key={projectVideoPlayerCreateTime}
          onLoad={() => {
            this.handleProjectVideoPlayerLoaded();
          }}
          title="youtube"
          src={`${YOUTUBE_EMBED_URL}/${projectVideoData[projectVideoSelectedIndex].videoId}`}
          className={classnames(styles.videoPlayer, {
            hide: isProjectVideoPlayerLoading,
          })}
          allowFullScreen
          frameBorder="0"
        />
      );
    } else if (projectVideoData[projectVideoSelectedIndex].provider === 'vimeo')
      $videoEl = (
        <iframe
          key={projectVideoPlayerCreateTime}
          onLoad={() => {
            this.handleProjectVideoPlayerLoaded();
          }}
          title="vimeo"
          src={`${VIMEO_EMBED_URL}/${projectVideoData[projectVideoSelectedIndex].videoId}`}
          className={classnames(styles.videoPlayer, {
            hide: isProjectVideoPlayerLoading,
          })}
          allowFullScreen
          frameBorder="0"
          allow="autoplay; fullscreen"
        />
      );

    return (
      <div className={styles.videoContainer}>
        <div className={styles.dropDownWrapper}>
          <Dropdown
            options={this.videosOptions()}
            value={`${projectVideoSelectedIndex}`}
            onChange={this.handleVideosOptionsChange}
          />
        </div>

        <div className={styles.videoPlayerWrapper}>
          {isProjectVideoPlayerLoading && (
            <div className={classnames(styles.videoPlayer, styles.loading)}>
              <RenderViewLoading />
            </div>
          )}

          {$videoEl && $videoEl}
        </div>
      </div>
    );
  };

  private RenderViewControls = ({
    viewControlItemsList,
  }: {
    viewControlItemsList: ViewControlsV2ItemsTypes[];
  }) => {
    const { view, hideControls } = this.props;
    const { selectedViewControlsMenuTypeList, viewControlsCreateTime } =
      this.state;

    if (hideControls) {
      return null;
    }

    return (
      <ViewControlsV2
        viewControlsCreateTime={viewControlsCreateTime}
        viewId={view.id}
        key={view.id}
        selectedViewControlsMenuTypeList={selectedViewControlsMenuTypeList}
        className={styles.controls}
        items={viewControlItemsList}
        onChange={this.handleViewControlsMenuItemChange}
        onIntentChange={this.handleIntentChange}
      />
    );
  };

  private RenderFooter = (): JSX.Element | null => {
    const { view, splitView, compareViewId } = this.props;

    if (splitView) {
      return null;
    }

    return (
      <div>
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/view/:viewId"
          render={({ match }) => {
            return (
              <ViewFooter
                {...match.params}
                compareViewId={compareViewId || undefined}
              />
            );
          }}
        />
        <Route
          exact
          path="/share/:shareId"
          render={({ match }) => {
            return <ViewFooter {...match.params} viewId={view.id} />;
          }}
        />
      </div>
    );
  };

  public render(): React.ReactNode {
    const { view, splitView, isTerrainMap, isContourMaps, downloads, reports } =
      this.props;
    const {
      showSiteWalkThroughViewInspectOnAerial,
      isViewItemLoading,
      showSiteObjectCreateConfirmationModal,
      showSiteObjectDeleteConfirmationModal,
    } = this.state;

    const {
      RenderPerspectiveView,
      RenderInspectionView,
      RenderSiteWalkthroughView,
      RenderExterior360View,
      RenderInterior360View,
      RenderViewLoading,
      RenderThreeDView,
      RenderSplitView,
      RenderVideosView,
      RenderMapView,
      RenderFooter,
      RenderViewControls,
    } = this;

    let $viewEl = null;

    if (!view || isViewItemLoading) {
      return (
        <div className={styles.container}>
          <RenderViewLoading />
        </div>
      );
    }

    if (splitView) {
      return <RenderSplitView />;
    }

    const viewControlItemsList: ViewControlsV2ItemsTypes[] =
      this.getViewControlItems(
        this.getViewControlsMenuList(
          view?.type,
          view?.subType,
          !!isTerrainMap,
          !!isContourMaps,
          view?.metadata,
          downloads ?? [],
          reports ?? []
        )
      );

    if (view.type === 'perspective') {
      $viewEl = <RenderPerspectiveView />;
    } else if (view.type === 'inspection') {
      $viewEl = <RenderInspectionView />;
    } else if (view.type === 'site_navigation') {
      $viewEl = <RenderSiteWalkthroughView />;
    } else if (view.type === 'exterior_360') {
      $viewEl = <RenderExterior360View />;
    } else if (view.type === 'interior_360') {
      $viewEl = <RenderInterior360View />;
    } else if (view.type === 'three_d') {
      $viewEl = <RenderThreeDView />;
    } else if (view.type === 'video') {
      $viewEl = <RenderVideosView />;
    } else {
      $viewEl = <RenderMapView />;
    }

    return (
      <div className={styles.container}>
        {$viewEl}

        <RenderViewControls viewControlItemsList={viewControlItemsList} />

        <RenderFooter />

        {showSiteWalkThroughViewInspectOnAerial && (
          <ContextMenu
            nodeSelector=".mapboxgl-map"
            menuOptionsList={[
              {
                label: 'Inspect',
                name: 'inspect',
                onClick: () => {
                  this.handleInspectClick();
                },
              },
            ]}
          />
        )}
        {showSiteObjectDeleteConfirmationModal ? (
          <ModalNotification
            notificationTitle="Confirm Delete"
            notificationBody="Are you sure you want to delete this object? This action cannot be undone."
            shownotificationModal
            handleModalClose={this.handleSiteObjectDelete}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}
        {showSiteObjectCreateConfirmationModal ? (
          <ModalNotification
            notificationTitle="Confirm Create"
            notificationBody="Are you sure you want to create a new object?"
            shownotificationModal
            handleModalClose={this.handleSiteObjectCreate}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}
      </div>
    );
  }
}
