import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { User } from '../../api/auth.types';
import {
  EPSGSelectionContext,
  OwnerOptionsContext,
  ProjectCreationContext,
  ProjectEditAction,
  ProjectEditProps,
  ProjectLocation,
  ProjectReducerActions,
  ProjectStateManagement,
  ProjectUpdateValidation,
} from './index.types';
import OrganisationAPI from '../../api/organisations';
import { Project, TemperatureUnits } from '../../api/projects.types';
import ProjectsAPI from '../../api/projects';
import { EpsgDetails } from '../../api/epsgDetails.types';
import EpsgDetailsAPI from '../../api/epsgDetails';
import { Owner } from '../../api/organisations.types';
import { convertArea } from '../../utils/helpers';

export const useOwnerOptions = (
  user: User,
  project?: Project
): OwnerOptionsContext => {
  const orgApis = useMemo(() => {
    return new OrganisationAPI();
  }, []);

  const [ownerOptions, setOwnerOptions] = useState<Owner[]>([
    { ownerType: 'user', name: user.name, ownerId: user.user_id },
  ]);
  const [defaultOwner, setDefaultOwner] = useState<Owner>({
    ownerId: user.user_id,
    name: user.name,
    ownerType: 'user',
  });

  useEffect(() => {
    let defOwner: Owner = {
      ownerType: 'user',
      name: user.name,
      ownerId: user.user_id,
    };

    if (project?.ownerId && project?.ownerType) {
      const { ownerType, ownerId } = project;

      const owner = ownerOptions.find(
        (o) => o.ownerId === ownerId && o.ownerType === ownerType
      );

      if (owner) defOwner = owner;
      else {
        defOwner = {
          ownerId,
          ownerType,
          name: 'Unknown',
        };
        setOwnerOptions([...ownerOptions, defOwner]);
      }
    }

    setDefaultOwner(defOwner);
  }, [ownerOptions, project, user]);

  useEffect(() => {
    orgApis
      .listUserOrganisations(true)
      .then((data) => {
        const orgOwners: Owner[] = data.map((o) => {
          return {
            ownerId: o.id,
            ownerType: 'organisation',
            name: o.name,
            whiteLabelingEnabled: o.whiteLabelingEnabled,
          };
        });

        setOwnerOptions([...ownerOptions, ...orgOwners]);
      })
      .catch(console.error);

    return () => {
      setOwnerOptions([
        {
          ownerType: 'user',
          name: user.name,
          ownerId: user.user_id,
        },
      ]);
    };
  }, [user]);

  return { ownerOptions, defaultOwner };
};

const projectReducer = (
  state: ProjectEditProps,
  action: ProjectEditAction<unknown>
): ProjectEditProps => {
  switch (action.type) {
    case 'owner_changed': {
      const { ownerType, ownerId, name: ownerName } = action.data as Owner;

      return { ...state, ownerId, ownerType, ownerName };
    }

    case 'temperature_units_changed':
      return { ...state, temperatureUnit: action.data as TemperatureUnits };
    case 'address_changed':
      return { ...state, address: action.data as string };
    case 'boundary_changed':
      return { ...state, boundaryFileContents: action.data as string };
    case 'location_changed':
      return { ...state, ...(action.data as ProjectLocation) };
    case 'area_changed':
      return { ...state, areaSquareMetres: action.data as number };
    case 'description_changed':
      return { ...state, description: action.data as string };
    case 'epsg_code_changed':
      return { ...state, epsgCode: action.data as string };
    case 'name_changed':
      return { ...state, name: action.data as string };
    case 'reset':
      return {};
    default:
      return state;
  }
};

export const useProjectReducer = (): [
  ProjectEditProps,
  ProjectReducerActions
] => {
  const [state, dispatch] = useReducer(projectReducer, {});

  return [
    state,
    {
      addressChanged: (address: string): void => {
        dispatch({ type: 'address_changed', data: address });
      },
      boundaryChanged: (boundary: string): void => {
        dispatch({ type: 'boundary_changed', data: boundary });
      },
      locationChanged: (location: ProjectLocation): void => {
        dispatch({ type: 'location_changed', data: location });
      },
      areaChanged: (area: number): void => {
        dispatch({ type: 'area_changed', data: area });
      },
      descriptionChanged: (description: string): void => {
        dispatch({ type: 'description_changed', data: description });
      },
      epsgCodeChanged: (epsgCode: string): void => {
        dispatch({ type: 'epsg_code_changed', data: epsgCode });
      },
      nameChanged: (name: string): void => {
        dispatch({ type: 'name_changed', data: name });
      },
      ownerChanged: (owner: Owner): void => {
        dispatch({ type: 'owner_changed', data: owner });
      },
      temperatureUnitChanged: (unit: TemperatureUnits): void => {
        dispatch({ type: 'temperature_units_changed', data: unit });
      },
      reset: () => {
        dispatch({ type: 'reset', data: undefined });
      },
    },
  ];
};

export const useProjectStateManagement = (
  user: User,
  project?: Project
): ProjectStateManagement => {
  const { ownerOptions, defaultOwner } = useOwnerOptions(user, project);
  const [projectState, actions] = useProjectReducer();

  useEffect(() => {
    if (!projectState.ownerId) actions.ownerChanged(defaultOwner);
  }, [defaultOwner]);

  return {
    ownerOptions,
    defaultOwner,
    projectState,
    actions,
  };
};

export const useProjectCreationContext = (): ProjectCreationContext => {
  const projectsApi = useMemo(() => {
    return new ProjectsAPI();
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const createProject = useMemo(() => {
    return async (project: ProjectEditProps): Promise<string | undefined> => {
      setLoading(true);

      return await projectsApi
        .createProject(project)
        .then((res) => res.id)
        .catch((err) => {
          console.error(err);

          return undefined;
        })
        .finally(() => setLoading(false));
    };
  }, []);

  return {
    loading,
    createProject,
  };
};

export const useEPSGSelection = (epsgCode?: string): EPSGSelectionContext => {
  const [searchEPSG, setSearchEPSG] = useState<string>();
  const [searchResults, setSearchResults] = useState<EpsgDetails[]>([]);

  const epsgDetailsApi = useMemo(() => {
    return new EpsgDetailsAPI();
  }, []);

  useEffect(() => {
    if (!searchEPSG?.length && epsgCode) setSearchEPSG(epsgCode);
  }, [epsgCode]);

  useEffect(() => {
    if (searchEPSG && searchEPSG.length >= 3) {
      epsgDetailsApi.searchEpsgDetailsByString(searchEPSG).then((res) => {
        if (res.error) {
          console.error(res.error);

          return;
        }

        setSearchResults(res.data);
      });
    }

    return () => {
      setSearchResults([]);
    };
  }, [searchEPSG]);

  return {
    searchResults,
    setSearchEPSG,
  };
};

export const isValidProjectCreateRequest = (
  project: ProjectEditProps
): ProjectUpdateValidation => {
  if (!project.ownerId?.length || !project.ownerType) {
    return { isValid: false, message: 'Owner must be specified.' };
  }

  if (!project.name?.length) {
    return { isValid: false, message: 'Project name must be specified.' };
  }

  return { isValid: true };
};

export const getOwnerName = (owner: Owner): string => {
  return `${owner.name} (${
    owner.ownerType.charAt(0).toUpperCase() + owner.ownerType.slice(1)
  })`;
};

export const toProjectBoundaryHeaderWithArea = (
  areaSquareMetres?: number | null
): React.ReactNode => {
  return areaSquareMetres ? (
    <>
      Project Boundary&nbsp;-&nbsp;
      {convertArea(areaSquareMetres)}&nbsp; (
      {convertArea(areaSquareMetres, 'acre')})
    </>
  ) : (
    'Project Boundary'
  );
};
