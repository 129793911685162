import React from 'react';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';

import { MAPBOX_STYLE_SATELLITE_V9_URL } from '../../../../../constants/urls';
import { SATELLITE_LAYER_Z_INDEX } from '../../../../../constants';
import { getMapboxStyleTileUrl } from '../utils';
import { SatelliteViewPropsType, SatelliteViewStateType } from './index.types';

export default class SatelliteView extends React.PureComponent<
  SatelliteViewPropsType,
  SatelliteViewStateType
> {
  constructor(props: SatelliteViewPropsType) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    this.initSatelliteView();
  }

  public componentWillUnmount() {
    this.resetSatelliteView();
  }

  private initSatelliteView = () => {
    const { olMap, accessToken } = this.props;

    if (olMap && accessToken) {
      const layer = new TileLayer({
        source: new XYZ({
          url: getMapboxStyleTileUrl(
            MAPBOX_STYLE_SATELLITE_V9_URL,
            accessToken
          ),
          crossOrigin: 'anonymous',
        }),
        zIndex: SATELLITE_LAYER_Z_INDEX,
      });

      this.setState(
        {
          layer,
        },
        () => {
          olMap.addLayer(layer);
        }
      );
    }
  };

  private resetSatelliteView = () => {
    const { olMap } = this.props;
    const { layer } = this.state;

    if (layer && olMap) {
      olMap.removeLayer(layer);
    }
  };

  public render() {
    return <></>;
  }
}
