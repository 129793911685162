import * as React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import Loading from '../Loading';
import { LoadingOverlayPropsTypes } from './index.types';

export default class LoadingOverlay extends React.PureComponent<LoadingOverlayPropsTypes> {
  public static defaultProps: Partial<LoadingOverlayPropsTypes> = {
    type: 'ellipsis',
    position: 'fixed',
  };

  public render() {
    const { position, overlayClassName } = this.props;

    return (
      <div className={styles.container}>
        <div
          className={classnames(
            overlayClassName,
            styles.overlayContainer,
            styles[position]
          )}
        >
          <div className={styles.overlayWrapper}>
            <Loading type="ellipsis" className={styles.ellipsisWrapper} />
          </div>
        </div>
      </div>
    );
  }
}
