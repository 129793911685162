import * as React from 'react';
import { Tooltip } from 'antd';
import styles from './index.module.scss';
import { FloatingToolbarPropsTypes } from './index.types';

export default class FloatingToolbar extends React.PureComponent<FloatingToolbarPropsTypes> {
  public render(): React.ReactNode {
    const { buttonList, top, left, bottom, right, style, className } =
      this.props;

    return (
      <div
        className={`${styles.container} ${className}`}
        style={{ top, left, bottom, right, ...style }}
      >
        {buttonList.map((item, index) => {
          return (
            <div
              key={index} // eslint-disable-line react/no-array-index-key
              className={styles.ctaWrapper}
              onClick={() => {
                item.onClick();
              }}
            >
              {item.tooltipText ? (
                <Tooltip placement="bottom" title={item.tooltipText}>
                  <button disabled={item.disabled}>
                    <i className={`fa fa-${item.icon}`} aria-hidden="true" />
                    <span>{item.label}</span>
                  </button>
                </Tooltip>
              ) : (
                <button disabled={item.disabled}>
                  <i className={`fa fa-${item.icon}`} aria-hidden="true" />
                  <span>{item.label}</span>
                </button>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
