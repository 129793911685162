import { LockOutlined, LoginOutlined, MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { undefinedOrNull } from '../../utils/functs';
import Logo from '../Logo';
import ModalNotification from '../ModalNotification/ModalNotification';
import style from './index.module.scss';
import SkeletonLoader from '../SkeletonLoader';
import { ResetPasswordProps, ResetPasswordState } from './index.types';

class Index extends React.Component<ResetPasswordProps, ResetPasswordState> {
  public constructor(props: ResetPasswordProps) {
    super(props);

    this.state = {
      showPopUp: false,
      showSuccessPopUp: false,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ResetPasswordProps): void {
    if (nextProps.success) {
      this.setState({
        showPopUp: true,
        showSuccessPopUp: true,
      });
    }
  }

  public componentWillUnmount(): void {
    const { clearAuthState } = this.props;

    clearAuthState();
  }

  public renderPasswordForm(): JSX.Element {
    const { form, error } = this.props;
    const { getFieldDecorator } = form;
    const { showSuccessPopUp } = this.state;

    return (
      <Form onSubmit={this.handlePasswordChange} className={style.container}>
        <Logo className={style.logo} />
        <Form.Item validateStatus={error ? 'error' : undefined}>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={
                <LockOutlined style={{ color: 'rgba(255,255,255,.25)' }} />
              }
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item
          validateStatus={error ? 'error' : undefined}
          help={error || undefined}
        >
          {getFieldDecorator('re_password', {
            rules: [
              {
                required: true,
                message:
                  'Passwords must match, and must have more than 6 characters!',
                validator: this.passwordValidator,
              },
            ],
          })(
            <Input
              prefix={
                <LockOutlined
                  style={{ color: 'rgba(255,255,255,.25)', padding: '5px 0' }}
                />
              }
              type="password"
              placeholder="Re-enter Password"
            />
          )}
        </Form.Item>
        <Button
          icon={<LoginOutlined />}
          type="primary"
          htmlType="submit"
          loading={false}
          block
        >
          RESET PASSWORD
        </Button>
        {showSuccessPopUp ? (
          <ModalNotification
            notificationTitle="Reset Password"
            notificationBody="Your password has been reset successfully"
            shownotificationModal
            handleModalClose={this.handleSubmitModal}
            okButtonTitle="OK"
            error=""
          />
        ) : null}
      </Form>
    );
  }

  @autobind
  public passwordValidator(rule: any, value: any, callback: any): void {
    const { form } = this.props;

    const passwordValue = form.getFieldValue('password');

    if (value && passwordValue && value === passwordValue && value.length > 6) {
      callback();
    } else {
      callback('Passwords empty or do not match!');
    }
  }

  @autobind
  public handlePasswordChange(e: any): void {
    const { code, resetPasswordWithCode, form } = this.props;

    if (!code) {
      return;
    }

    e.preventDefault();
    form.validateFields((err: any, values: any) => {
      if (!err) {
        this.setState(
          {
            showPopUp: true,
          },
          () => {
            resetPasswordWithCode(code, values.password);
          }
        );
      }
    });
  }

  public renderResetForm(): JSX.Element {
    const { form, error } = this.props;
    const { showPopUp } = this.state;

    const { getFieldDecorator } = form;

    if (!this.state) {
      return <SkeletonLoader size={3} position="fixed" />;
    }

    return (
      <Form onSubmit={this.handleReset} className={style.container}>
        <Logo className={style.logo} />
        <Form.Item
          validateStatus={error ? 'error' : undefined}
          help={error || undefined}
        >
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: error || 'Please input your email!',
              },
            ],
          })(
            <Input
              prefix={
                <MailOutlined style={{ color: 'rgba(255,255,255,.25)' }} />
              }
              type="email"
              placeholder="E-mail"
            />
          )}
        </Form.Item>
        <Button
          icon={<LoginOutlined />}
          type="primary"
          htmlType="submit"
          loading={false}
          block
        >
          {'GET RESET MAIL'}
        </Button>
        {showPopUp ? (
          <ModalNotification
            notificationTitle="Reset Password"
            notificationBody="An email with a link to reset your password has been sent to your email address."
            shownotificationModal
            handleModalClose={this.handleSubmitModal}
            okButtonTitle="OK"
            error=""
          />
        ) : null}
      </Form>
    );
  }

  @autobind
  public handleSubmitModal(): void {
    const { history } = this.props;

    history.push('/');
  }

  @autobind
  public handleReset(e: any): void {
    const { form, getResetMail } = this.props;

    e.preventDefault();

    form.validateFields((err: any, values: any) => {
      if (!err) {
        getResetMail(
          !undefinedOrNull(values.email)
            ? values.email.trim().toLowerCase()
            : ''
        );
      }
    });
  }

  public render(): React.ReactNode {
    const { code } = this.props;

    if (code) {
      return this.renderPasswordForm();
    }

    return this.renderResetForm();
  }
}

export default Form.create<ResetPasswordProps>()(Index);
