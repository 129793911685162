// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LocalDrawingMap_container__Dtns8{width:100%;height:100%}.LocalDrawingMap_loader__kp7KU{position:absolute;top:0}.LocalDrawingMap_olTooltipContainer__HfmEf{pointer-events:none}.LocalDrawingMap_olTooltipContainer__HfmEf .LocalDrawingMap_olTooltip__RxIVv{position:relative;background:#000;border-radius:4px;color:#fff;padding:4px 8px;opacity:.8;white-space:nowrap;font-size:13px;font-weight:bold}", "",{"version":3,"sources":["webpack://./src/components/UserSandbox/Drawings/LocalDrawingMap/index.module.scss"],"names":[],"mappings":"AAAA,kCACE,UAAA,CACA,WAAA,CAGF,+BACE,iBAAA,CACA,KAAA,CAGF,2CACE,mBAAA,CAEA,6EACE,iBAAA,CACA,eAAA,CACA,iBAAA,CACA,UAAA,CACA,eAAA,CACA,UAAA,CACA,kBAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n}\n\n.loader {\n  position: absolute;\n  top: 0;\n}\n\n.olTooltipContainer {\n  pointer-events: none;\n\n  .olTooltip {\n    position: relative;\n    background: rgb(0, 0, 0);\n    border-radius: 4px;\n    color: white;\n    padding: 4px 8px;\n    opacity: 0.8;\n    white-space: nowrap;\n    font-size: 13px;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LocalDrawingMap_container__Dtns8",
	"loader": "LocalDrawingMap_loader__kp7KU",
	"olTooltipContainer": "LocalDrawingMap_olTooltipContainer__HfmEf",
	"olTooltip": "LocalDrawingMap_olTooltip__RxIVv"
};
export default ___CSS_LOADER_EXPORT___;
