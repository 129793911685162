/* eslint-disable func-names */

import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import isEventAtCoordinates from '@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates';
import POPUP from './Popup';

const DrawLineString = {} as any;

DrawLineString.onSetup = function (opts: any) {
  // eslint-disable-next-line no-param-reassign
  opts = opts || {};
  const { featureId } = opts;

  let line;
  let currentVertexPosition;
  let direction = 'forward';

  if (featureId) {
    line = this.getFeature(featureId);
    if (!line) {
      throw new Error('Could not find a feature with the provided featureId');
    }

    let { from } = opts;

    if (
      from &&
      from.type === 'Feature' &&
      from.geometry &&
      from.geometry.type === 'Point'
    ) {
      from = from.geometry;
    }

    if (
      from &&
      from.type === 'Point' &&
      from.coordinates &&
      from.coordinates.length === 2
    ) {
      from = from.coordinates;
    }

    if (!from || !Array.isArray(from)) {
      throw new Error(
        'Please use the `from` property to indicate which point to continue the line from'
      );
    }

    const lastCoord = line.coordinates.length - 1;

    if (
      line.coordinates[lastCoord][0] === from[0] &&
      line.coordinates[lastCoord][1] === from[1]
    ) {
      currentVertexPosition = lastCoord + 1;
      // add one new coordinate to continue from
      line.addCoordinate(currentVertexPosition, ...line.coordinates[lastCoord]);
    } else if (
      line.coordinates[0][0] === from[0] &&
      line.coordinates[0][1] === from[1]
    ) {
      direction = 'backwards';
      currentVertexPosition = 0;
      // add one new coordinate to continue from
      line.addCoordinate(currentVertexPosition, ...line.coordinates[0]);
    } else {
      throw new Error(
        '`from` should match the point at either the start or the end of the provided LineString'
      );
    }
  } else {
    line = this.newFeature({
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.LINE_STRING,
        coordinates: [],
      },
    });
    currentVertexPosition = 0;
    this.addFeature(line);
    POPUP.setHTML('Click on map to select line');
  }

  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.activateUIButton(Constants.types.LINE);
  this.setActionableState({
    trash: true,
  });

  return {
    line,
    currentVertexPosition,
    direction,
  };
};

DrawLineString.clickAnywhere = function (state: any, e: any) {
  if (
    (state.currentVertexPosition > 0 &&
      isEventAtCoordinates(
        e,
        state.line.coordinates[state.currentVertexPosition - 1]
      )) ||
    (state.direction === 'backwards' &&
      isEventAtCoordinates(
        e,
        state.line.coordinates[state.currentVertexPosition + 1]
      ))
  ) {
    return this.changeMode('static', {
      featureIds: [state.line.id],
      showMetric: true,
    });
  }

  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  state.line.updateCoordinate(
    state.currentVertexPosition,
    e.lngLat.lng,
    e.lngLat.lat
  );
  if (state.direction === 'forward') {
    // eslint-disable-next-line no-param-reassign
    state.currentVertexPosition += 1;
    state.line.updateCoordinate(
      state.currentVertexPosition,
      e.lngLat.lng,
      e.lngLat.lat
    );
  } else {
    state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
  }

  if (state.currentVertexPosition > 0) {
    POPUP.setHTML('Double click to finish the line');
  }
};

DrawLineString.clickOnVertex = function (state: any) {
  return this.changeMode('static', {
    featureIds: [state.line.id],
    showMetric: true,
  });
};

DrawLineString.onMouseMove = function (state: any, e: any) {
  state.line.updateCoordinate(
    state.currentVertexPosition,
    e.lngLat.lng,
    e.lngLat.lat
  );
  if (CommonSelectors.isVertex(e)) {
    this.updateUIClasses({ mouse: Constants.cursors.POINTER });
  }

  if (!POPUP.isOpen()) {
    POPUP.addTo(this.map);
  }

  POPUP.setLngLat(e.lngLat);
};

DrawLineString.onTap = DrawLineString.onClick = function (state: any, e: any) {
  if (CommonSelectors.isVertex(e)) {
    return this.clickOnVertex(state, e);
  }

  this.clickAnywhere(state, e);
};

DrawLineString.onKeyUp = function (state: any, e: any) {
  if (CommonSelectors.isEnterKey(e)) {
    this.changeMode('static', {
      featureIds: [state.line.id],
      showMetric: true,
    });
  } else if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode('draw_line_string');
  }
};

DrawLineString.onStop = function (state: any) {
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) {
    return;
  }

  // remove last added coordinate
  state.line.removeCoordinate(`${state.currentVertexPosition}`);
  if (state.line.isValid()) {
    this.map.fire(Constants.events.CREATE, {
      features: [state.line.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode('static', {}, { silent: true });
  }

  POPUP.remove();
};

DrawLineString.onTrash = function (state: any) {
  this.deleteFeature([state.line.id], { silent: true });
  this.changeMode('static');
};

DrawLineString.toDisplayFeatures = (state: any, geojson: any, display: any) => {
  const isActiveLine = geojson.properties.id === state.line.id;

  // eslint-disable-next-line no-param-reassign
  geojson.properties.active = isActiveLine
    ? Constants.activeStates.ACTIVE
    : Constants.activeStates.INACTIVE;
  if (!isActiveLine) {
    return display(geojson);
  }

  // Only render the line if it has at least one real coordinate
  if (geojson.geometry.coordinates.length < 2) {
    return;
  }

  // eslint-disable-next-line no-param-reassign
  geojson.properties.meta = Constants.meta.FEATURE;
  display(
    createVertex(
      state.line.id,
      geojson.geometry.coordinates[
        state.direction === 'forward'
          ? geojson.geometry.coordinates.length - 2
          : 1
      ],
      `${
        state.direction === 'forward'
          ? geojson.geometry.coordinates.length - 2
          : 1
      }`,
      false
    )
  );

  display(geojson);
};

DrawLineString.onMouseOut = () => {
  POPUP.remove();
};

export default DrawLineString;
