import { Tooltip, Typography } from 'antd';
import GeoJSON from 'ol/format/GeoJSON';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Issue from '../../../../api/issue';
import { View } from '../../../../api/views.types';
import { issueUrl } from '../../../../routes/urls';
import { appFormatDate } from '../../../../utils/date';
import { isEmpty, undefinedOrNull } from '../../../../utils/functs';
import * as ControlBox from '../../../ControlBox/ControlBox';
import { Tag } from '../../../../api/issue.types';
import SelectIssueTagsModal from '../../../SelectIssueTagsModal';
import { ViewConfig, IssueCreated } from '../../index.types';
import OLDrawControl from '../../MapView/OpenLayersRenderer/DrawControl';
import { DrawMode } from '../../MapView/OpenLayersRenderer/DrawControl/index.types';
import { MapStateProvider } from '../../MapView/OpenLayersRenderer/MapStateProvider';
import { VectorLayerControl } from '../../MapView/OpenLayersRenderer/VectorLayerControl';
import HotspotControl from '../../PanoramaView/PannellumRenderer/HotspotControl';
import PanoramaStateProvider from '../../PanoramaView/PannellumRenderer/PanoramaStateProvider';
import { IssueControlProps } from '../index.types';
import styles from './index.module.scss';

const { Text } = Typography;

interface IState {
  creatingIssue: boolean;
  error?: string;
  geoJson?: any;
  editorState: {
    title: string;
    message: string;
    tags: string[];
  };
  allTags: Tag[];
  showTagEditorModal: boolean;
  tagsFetchError?: string;
  toastMessage?: any;
}

export default class IssueControlBox extends React.Component<
  IssueControlProps,
  IState
> {
  // this is a ref to the MapStateProvider component
  // used for getting map config and screenshot
  private mapStateProviderRef: React.RefObject<MapStateProvider> =
    React.createRef();

  // this is a ref to the PanoramaStateProvider component
  // used for getting pano config and screenshot
  private panoStateProviderRef: React.RefObject<PanoramaStateProvider> =
    React.createRef();

  private issueApi = new Issue();

  constructor(props: IssueControlProps) {
    super(props);

    this.state = {
      creatingIssue: false,
      // eslint-disable-next-line react/no-unused-state
      editorState: {
        title: '',
        message: '',
        tags: [],
      },
      allTags: [],
      showTagEditorModal: false,
    };
  }

  public componentDidMount() {
    const { issue, view, onRenderFragChange } = this.props;

    this.setState(
      {
        toastMessage: this.getToast(),
      },
      () => {
        onRenderFragChange(this.getRenderFragFn(issue, view));
      }
    );

    this.issueApi
      .fetchTagsByProject(view.projectId)
      .then((response) => {
        this.setState({ allTags: response.data });
      })
      .catch(() => {
        this.setState({
          tagsFetchError: 'Error in fetching tags data',
        });
      });
  }

  public UNSAFE_componentWillReceiveProps({
    issue: nextIssue,
  }: IssueControlProps) {
    const { issue, onRenderFragChange, view } = this.props;
    const { toastMessage } = this.state;

    if (nextIssue !== issue) {
      this.setState(
        {
          toastMessage: nextIssue ? undefined : toastMessage,
        },
        () => {
          onRenderFragChange(this.getRenderFragFn(nextIssue, view));
        }
      );
    }
  }

  public componentWillUnmount() {
    const { onRenderFragChange } = this.props;

    onRenderFragChange(() => null);
  }

  private getToast() {
    const { issue, view } = this.props;

    if (
      view?.type === 'exterior_360' ||
      (view?.type === 'interior_360' && !issue)
    ) {
      return (
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            top: '5px',
            zIndex: 1,
            background: 'rgba(102, 102, 102, 0.8)',
            borderRadius: '10px',
            transform: 'translate(-50%, 0%)',
            marginLeft: '50%',
            padding: '5px',
          }}
        >
          <span>Right-click to add/move the issue marker</span>
          <div style={{ marginLeft: '5px' }}>
            <i
              className="fa fa-times-circle"
              style={{ marginTop: '3px' }}
              aria-hidden="true"
              onClick={() => {
                this.setState(
                  {
                    toastMessage: undefined,
                  },
                  () => {
                    this.updateRenderFrag();
                  }
                );
              }}
            />
          </div>
        </div>
      );
    }

    return undefined;
  }

  private updateRenderFrag = () => {
    const { issue, view, onRenderFragChange } = this.props;

    onRenderFragChange(this.getRenderFragFn(issue, view));
  };

  private getRenderFragFn(issue: any, view: View): () => any {
    const { toastMessage } = this.state;

    switch (view.type) {
      case 'perspective':
      case 'inspection':
      case 'site_navigation':
      case 'map': {
        if (issue) {
          return () => {
            return (
              <VectorLayerControl
                key={`issue-frag-${issue.id}`}
                issue={issue}
                sourceProjection={view.type === 'map' ? 'EPSG:4326' : undefined}
              />
            );
          };
        }

        return () => {
          const drawMode: DrawMode =
            view.type === 'map' ? 'Polygon' : 'FreehandLineString';
          const geoJsonWriter =
            view.type === 'map' ? undefined : new GeoJSON({});
          const { measurementStatus } = this.props;

          return (
            <React.Fragment key="issue-frag-parent">
              {!measurementStatus?.geoJson && (
                <OLDrawControl
                  key="issue-frag-draw"
                  drawMode={drawMode}
                  allowEdit={view.type === 'map'}
                  allowMultipleFeatures={false}
                  onFeatureCreate={(geoJson: any) => {
                    this.setState({ geoJson });
                  }}
                  onFeatureEdit={(geoJson: any) => {
                    this.setState({ geoJson });
                  }}
                  geoJsonWriter={geoJsonWriter}
                />
              )}
              <MapStateProvider
                key="issue-frag-state-provider"
                ref={this.mapStateProviderRef}
                convertToLatLon={view.type === 'map'}
              />
            </React.Fragment>
          );
        };
      }

      case 'exterior_360':
      case 'interior_360': {
        return () => (
          <React.Fragment key="issue-frag-parent">
            {toastMessage}
            <HotspotControl
              key="issue-frag-draw"
              issue={issue}
              onChange={(geoJson: any) => {
                this.setState({ geoJson });
              }}
            />
            <PanoramaStateProvider
              key="issue-frag-state-provider"
              ref={this.panoStateProviderRef}
            />
          </React.Fragment>
        );
      }

      default:
        break;
    }

    return () => null;
  }

  private async getScreenshot() {
    if (this.mapStateProviderRef && this.mapStateProviderRef.current) {
      return this.mapStateProviderRef.current.getScreenshot();
    }

    if (this.panoStateProviderRef && this.panoStateProviderRef.current) {
      return this.panoStateProviderRef.current.getScreenshot();
    }

    return null;
  }

  private getDrawnShape() {
    const { geoJson } = this.state;

    return geoJson || null;
  }

  private getViewState(): ViewConfig {
    if (this.mapStateProviderRef?.current) {
      return this.mapStateProviderRef.current.getMapState();
    }

    if (this.panoStateProviderRef?.current) {
      return this.panoStateProviderRef.current.getPanoState();
    }

    return {};
  }

  private getViewConfig = (): ViewConfig => {
    const { getViewConfig, measurementStatus, compareStatus } = this.props;
    const viewConfig: ViewConfig = {
      ...(getViewConfig() || {}),
      ...this.getViewState(),
      measurementType: measurementStatus?.type,
      volumePlaneElevation: measurementStatus?.volumePlaneElevation,
      compareViewId: compareStatus?.comapreViewId || undefined,
    };

    return viewConfig;
  };

  private canSubmit = () => {
    const { editorState, creatingIssue } = this.state;
    const viewConfig = this.getViewConfig();
    const { title } = editorState;

    return (
      !creatingIssue &&
      !!title &&
      !undefinedOrNull(viewConfig?.latitude) &&
      !undefinedOrNull(viewConfig?.longitude) &&
      !undefinedOrNull(viewConfig?.zoom)
    );
  };

  private createIssue = async () => {
    if (!this.canSubmit()) {
      return;
    }

    this.setState({ creatingIssue: true }, async () => {
      const { view, measurementStatus, onEvent } = this.props;
      const viewConfig = this.getViewConfig();

      const { editorState } = this.state;
      const { title, message, tags } = editorState;
      const shapeGeoJson = JSON.stringify(
        measurementStatus?.geoJson || this.getDrawnShape()
      );
      const screenshot = (await this.getScreenshot()) || undefined;

      this.issueApi
        .createIssue(
          view.id,
          title,
          message,
          viewConfig,
          shapeGeoJson,
          screenshot,
          tags
        )
        .then((res) => {
          const { error, data } = res;

          if (error) {
            console.error(error);
            this.setState({
              creatingIssue: false,
              error:
                'Could not create issue. Please try again or contact support@aspecscire.com',
            });

            return;
          }

          if (data) {
            this.setState({ creatingIssue: false }, () => {
              onEvent(new IssueCreated(data));
            });
          }
        });
    });
  };

  private renderHeader = (): React.ReactElement<any> => {
    const { onClose, issue } = this.props;

    const title = issue ? 'Issue' : 'Mark Issue';

    return (
      <React.Fragment>
        <ControlBox.Title title={title} />
        {!issue && (
          <div
            onClick={() => {
              this.setState({ showTagEditorModal: true });
            }}
            className={styles.addTags}
          >
            <i className="fa fa-tag fa-2x" />
          </div>
        )}
        <ControlBox.Icon
          onClick={() => {
            onClose();
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  private renderIssueInfo = (): JSX.Element | null => {
    const { view, issue } = this.props;
    const { projectId, aoiId } = view;

    if (!issue || !projectId || !aoiId) {
      return null;
    }

    return (
      <div className={styles.issueInfoWrapper}>
        <p>{this.mapTagIdsToTagNames(issue.tagIds).join(', ')}</p>
        <div className={styles.titleWrapper}>
          <Text>{issue.title}</Text>
        </div>
        <div className={styles.descWrapper}>
          <Text>{issue.message}</Text>
        </div>
        <div className={styles.authorWrapper}>
          <Text>
            &nbsp;— {issue.author} on {appFormatDate(issue.createdAt)}
          </Text>
        </div>
        <div className={styles.issuesLinkWrapper}>
          <Link to={issueUrl(projectId, aoiId, issue.id, { reload: 1 })}>
            Go to the issue
          </Link>
        </div>
      </div>
    );
  };

  private mapTagIdsToTagNames(tagIds: string[]): string[] {
    const { allTags } = this.state;
    const tagMap: any = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const t of allTags) {
      tagMap[t.id] = t.tagName;
    }

    return tagIds.map((tId) => {
      return tagMap[tId] || tId;
    });
  }

  private handleTagsChanged = (tagDescs: string[]) => {
    const tagIds = tagDescs.map((tag) => tag.split(',')[0]);
    const { editorState } = this.state;

    this.setState({ editorState: { ...editorState, tags: tagIds } });
  };

  private renderForm() {
    const { userRole } = this.props;
    const { editorState, error, creatingIssue } = this.state;
    const { title, message, tags } = editorState;

    return (
      <>
        <div className={styles.markedTags}>
          {isEmpty(tags) ? (
            <p>No Tags selected..</p>
          ) : (
            this.mapTagIdsToTagNames(tags).join(', ')
          )}
        </div>
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
          }}
        >
          {error ? <div className={styles.errorDiv}>{error}</div> : null}

          <label>Title</label>
          <input
            disabled={creatingIssue}
            type="text"
            value={title}
            placeholder="Enter title"
            onChange={(e) => {
              const { editorState } = this.state;

              this.setState({
                editorState: { ...(editorState || {}), title: e.target.value },
              });
            }}
          />
          <label>Description</label>
          <textarea
            disabled={creatingIssue}
            onChange={(e) => {
              const { editorState } = this.state;

              this.setState({
                editorState: {
                  ...(editorState || {}),
                  message: e.target.value,
                },
              });
            }}
            value={message}
            placeholder="Briefly describe the issue"
          />
          {userRole === 'read_only' ? (
            <Tooltip
              placement="right"
              title="The use of this feature requires change in the user role . Please contact project admin if you need that."
            >
              <input
                disabled={userRole === 'read_only'}
                type="button"
                onClick={() => {}}
                value="SUBMIT ISSUE"
              />
            </Tooltip>
          ) : (
            <input
              disabled={creatingIssue || !this.canSubmit()}
              type="button"
              onClick={this.createIssue}
              value={!creatingIssue ? 'SUBMIT ISSUE' : 'SUBMITTING...'}
            />
          )}
        </form>
      </>
    );
  }

  public render() {
    const { error, showTagEditorModal, editorState, allTags, tagsFetchError } =
      this.state;
    const { issue } = this.props;

    if (error) {
      return <div>{error}</div>;
    }

    return (
      <>
        <ControlBox.Wrapper
          className={styles.container}
          renderHeader={this.renderHeader}
        >
          {issue ? this.renderIssueInfo() : this.renderForm()}
        </ControlBox.Wrapper>
        {showTagEditorModal ? (
          <SelectIssueTagsModal
            allTags={allTags}
            isModalVisible={showTagEditorModal}
            previouslyMarkedTags={editorState?.tags || []}
            handleMarkedIssues={this.handleTagsChanged}
            modalErrorMessage={tagsFetchError || ''}
            closeModal={() => {
              this.setState({ showTagEditorModal: false });
            }}
          />
        ) : null}
      </>
    );
  }
}
