import * as React from 'react';
import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import ProjectVideosCrud from './ProjectVideosCrud';
import { Button } from '../Button';
import { appFormatDate } from '../../utils/date';
import { AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE } from '../../constants';
import { GenericApisReturnTypes, GenericObjectType } from '../../shapes/app';
import { ProjectVideosProps, ProjectVideosState } from './index.types';
import { undefinedOrNull } from '../../utils/functs';
import { log } from '../../utils/log';
import { ProjectVideosCrudPropsTypes } from './ProjectVideosCrud/index.types';
import ViewsV2Apis from '../../api/viewsV2';
import {
  projectVideosEditPageUrl,
  projectVideosNewPageUrl,
} from '../../routes/urls';
import { sortByDate } from '../../utils/helpers';
import { DOCUMENTATION_URL_LIST } from '../../constants/urls';
import DocumentationLink from '../DocumentationLink';
import { View } from '../../api/views.types';

const { Title } = Typography;
const viewsV2Apis = new ViewsV2Apis();

class ProjectVideos extends React.Component<
  ProjectVideosProps,
  ProjectVideosState
> {
  public constructor(props: ProjectVideosProps) {
    super(props);

    this.state = {
      tableLoading: true,
      viewDataList: [],
    };
  }

  public componentDidMount(): void {
    this.fetchViewData();
  }

  public componentDidUpdate({
    actionType: prevActionType,
  }: Readonly<ProjectVideosCrudPropsTypes>): void {
    const { actionType } = this.props;

    if (actionType !== prevActionType) {
      this.fetchViewData();
    }
  }

  private fetchViewData = () => {
    const { match, showSnackbar } = this.props;

    const { projectId, aoiId } = match.params;

    viewsV2Apis
      .getViewList(projectId, aoiId)
      .then((res: GenericApisReturnTypes) => {
        if (undefinedOrNull(res)) {
          showSnackbar({
            body: `Some error occured. Try again!`,
            type: 'error',
          });

          log.error(
            `Invalid viewsV2Apis getView return data!`,
            `ProjectVideos -> fetchViewData -> viewsV2Apis -> getViewList`
          );

          this.setState({
            tableLoading: false,
            viewDataList: [],
          });

          return null;
        }

        if (res.error || !res.data) {
          showSnackbar({
            body: res.error || 'Invalid Data',
            type: 'error',
          });

          log.error(
            res.error,
            `ProjectVideos -> fetchViewData -> viewsV2Apis -> getViewList`
          );

          this.setState({
            tableLoading: false,
            viewDataList: [],
          });

          return null;
        }

        this.setState({
          tableLoading: false,
          viewDataList: sortByDate(
            this.filterProjectVideosFromViews(res.data) || [],
            'date'
          ) as View[],
        });

        return res.data;
      });
  };

  private filterProjectVideosFromViews = (
    viewList: GenericObjectType[]
  ): null | GenericObjectType[] => {
    if (!viewList || viewList.length < 1) {
      return null;
    }

    return viewList.filter((a: GenericObjectType) => a.type === 'video');
  };

  private showProjectAdminLinks = (): boolean => {
    const { user, match } = this.props;
    const { projectId } = match.params;

    if (!user) {
      return false;
    }

    if (user.staff === true) {
      return true;
    }

    if (projectId) {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of user.project_roles || []) {
        if ((role as any).project_id === projectId) {
          return (role as any).role === 'project_admin';
        }
      }
    }

    return false;
  };

  private handleRowClick = (record: GenericObjectType) => {
    const { history, match } = this.props;
    const { projectId, aoiId } = match.params;

    history.push(projectVideosEditPageUrl(projectId, aoiId, record.id));
  };

  public render(): React.ReactNode {
    const { actionType, match } = this.props;
    const { viewDataList, tableLoading } = this.state;
    const { projectId, aoiId } = match.params;

    const tableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (value: string) => value,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (value: string) => {
          return appFormatDate(value);
        },
      },
    ];

    if (actionType === 'new' || actionType === 'edit') {
      return <ProjectVideosCrud {...this.props} />;
    }

    return (
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <div className={styles.titleInnerWrapper}>
            <Title level={2}>Videos</Title>
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.videos}
              toolTipPosition="right"
            />
          </div>
          {this.showProjectAdminLinks() ? (
            <Link
              className={styles.createItemLink}
              to={projectVideosNewPageUrl(projectId, aoiId)}
            >
              <Button transparent text="+ NEW VIDEO" />
            </Link>
          ) : null}
        </div>

        <Table
          locale={{ emptyText: 'No videos added yet.' }}
          onRow={(record: GenericObjectType) => {
            return {
              onClick: () => {
                this.handleRowClick(record);
              },
            };
          }}
          rowKey={(listItem: any) => listItem.id}
          loading={tableLoading}
          columns={tableColumns}
          dataSource={viewDataList}
          pagination={{
            defaultPageSize: AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE,
          }}
        />
      </div>
    );
  }
}

export default ProjectVideos;
