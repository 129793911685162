// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AreaInfo_calcButton__kWIRQ{margin-top:10px;margin-bottom:10px;width:100%}", "",{"version":3,"sources":["webpack://./src/components/View/ViewControls/MeasureControlBox/AreaInfo/index.module.scss"],"names":[],"mappings":"AAEA,4BACE,eAAA,CACA,kBAAA,CACA,UAAA","sourcesContent":["@import '../../../../../styles/scss/base/base';\n\n.calcButton {\n  margin-top: 10px;\n  margin-bottom: 10px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calcButton": "AreaInfo_calcButton__kWIRQ"
};
export default ___CSS_LOADER_EXPORT___;
