import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login } from '../../redux/actions/auth';
import Login from './index';

const mapStateToProps = (state: any): {} => {
  return {
    ...state.login,
  };
};

const mapDispatchToProps = (dispatch: any): {} => {
  return bindActionCreators(
    {
      login,
    },
    dispatch
  );
};

const LoginContainer = connect<any, any, {}>(
  mapStateToProps,
  mapDispatchToProps
)(Login) as any;

export default LoginContainer;
