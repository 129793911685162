import classnames from 'classnames';
import * as React from 'react';
import { View as OLView } from 'ol';
import { fromLonLat } from 'ol/proj';
import { View } from '../../../api/views.types';
import { Button } from '../../Button';
import SplitViewSelector from './SplitViewSelector';
import { appFormatDate } from '../../../utils/date';

import { SplitViewPropsType, SplitViewStateType } from './index.types';
import styles from './index.module.scss';
import MapView from '../MapView';
import PanoramaView from '../PanoramaView';
import ViewSelector from '../ViewSelector';
import { OLViewInitialized } from '../index.types';
import { PanoramaViewState } from '../PanoramaView/PannellumRenderer';

export default class SplitView extends React.PureComponent<
  SplitViewPropsType,
  SplitViewStateType
> {
  constructor(props: SplitViewPropsType) {
    super(props);

    this.state = {
      lockView: false,
    };
  }

  public componentDidMount() {
    const {
      olView: externalOLView,
      onEvent,
      onFooterControlsFragChange,
      view,
    } = this.props;
    const olView = externalOLView || new OLView();

    if (view.type === 'map') {
      // ensure share olview is used only for map views
      onEvent(new OLViewInitialized(olView));

      this.setState(
        {
          olView,
        },
        () => {
          this.initializeSharedView(this.props);
        }
      );
    }

    if (onFooterControlsFragChange) {
      onFooterControlsFragChange(undefined);
    }
  }

  public initializeSharedView = (props: SplitViewPropsType) => {
    const { view, splitView } = props;
    const { olView } = this.state;

    if (!olView) {
      console.error('Shared Openlayers View has not been initialized.');

      return;
    }

    if (
      !view.zoomMin ||
      !view.zoomMax ||
      !view.zoomDefault ||
      !view.centerLatitude ||
      !view.centerLongitude
    ) {
      console.error(`View is missing parameters essential to initialization.`);

      return;
    }

    if (
      !splitView.zoomMin ||
      !splitView.zoomMax ||
      !splitView.zoomDefault ||
      !splitView.centerLatitude ||
      !splitView.centerLongitude
    ) {
      console.error(
        `Split View is missing parameters essential to initialization.`
      );

      return;
    }

    const minZoom = Math.max(view.zoomMin, splitView.zoomMin);
    const maxZoom = Math.min(view.zoomMax, splitView.zoomMax);
    const zoom = olView.getZoom();

    olView.setMinZoom(minZoom);
    olView.setMaxZoom(maxZoom);

    if (zoom !== undefined) {
      if (zoom > maxZoom) {
        olView.setZoom(maxZoom);
      } else if (zoom < minZoom) {
        olView.setZoom(minZoom);
      }
    }

    if (!olView.getCenter()) {
      olView.setCenter(fromLonLat([view.centerLongitude, view.centerLatitude]));
      olView.setZoom(view.zoomDefault);
    }
  };

  private handleLeftHalfChange = (viewId: string) => {
    const { history, splitView } = this.props;

    history.push(`${viewId}?splitViewId=${splitView.id}`);
  };

  private handleRightHalfChange = (viewId: string) => {
    const { history, view } = this.props;

    history.push(`${view.id}?splitViewId=${viewId}`);
  };

  private viewHasColorScale = (view: View) => {
    if (!view.subType || !view.demColorMapping?.length) {
      return false;
    }

    switch (view.subType) {
      case 'elevation':
      case 'dtm':
      case 'survey':
      case 'contour': {
        return true;
      }

      default: {
        return false;
      }
    }
  };

  private lockButton = () => {
    this.setState((state) => ({ lockView: !state.lockView }));
  };

  private showElevationDifferenceControls(): boolean {
    const { view, splitView } = this.props;

    if (
      (view.subType !== 'aerial' && view.subType !== 'elevation') ||
      !view.demId
    ) {
      return false;
    }

    if (
      (splitView.subType !== 'aerial' && splitView.subType !== 'elevation') ||
      !splitView.demId
    ) {
      return false;
    }

    if (view.demId === splitView.demId) {
      return false;
    }

    if (appFormatDate(view.date) === appFormatDate(splitView.date)) {
      return false;
    }

    return true;
  }

  private panoViewStateChange = (e: PanoramaViewState) => {
    const { panoView: oldView } = this.state;

    if (oldView) {
      const maxDiff = Math.max(
        Math.abs(oldView.yaw - e.yaw),
        Math.abs(oldView.pitch - e.pitch),
        Math.abs(oldView.hfov - e.hfov)
      );

      if (maxDiff < 0.001) return;
    }

    this.setState({ panoView: e });
  };

  public render() {
    const { view, splitView, viewList, children } = this.props;
    const { olView, panoView, lockView } = this.state;

    return (
      <React.Fragment>
        {view.type === 'map' ? (
          <>
            <div className={styles.leftHalf}>
              <MapView
                view={view}
                olView={olView}
                onConfigCallbackChange={() => {}}
                onEvent={() => {}}
                disableInspect
                key="split_view_left"
              >
                <SplitViewSelector
                  view={view}
                  viewsList={viewList}
                  onChange={this.handleLeftHalfChange}
                  className={classnames({
                    [styles.offsetSelector]: this.viewHasColorScale(view),
                  })}
                />
                <ViewSelector
                  view={view}
                  viewList={viewList}
                  onChange={this.handleLeftHalfChange}
                  style={
                    this.viewHasColorScale(view)
                      ? { position: 'absolute', top: '60px', left: '80px' }
                      : { position: 'absolute', top: '60px', left: '50px' }
                  }
                />
                {children}
              </MapView>
            </div>
            <div className={styles.rightHalf}>
              <MapView
                view={splitView}
                olView={olView}
                onConfigCallbackChange={() => {}}
                onEvent={() => {}}
                disableInspect
                key="split_view_right"
              >
                <SplitViewSelector
                  onChange={this.handleRightHalfChange}
                  view={splitView}
                  viewsList={viewList}
                  className={classnames({
                    [styles.offsetSelector]: this.viewHasColorScale(splitView),
                  })}
                />
                <ViewSelector
                  view={splitView}
                  viewList={viewList}
                  onChange={this.handleRightHalfChange}
                  style={
                    this.viewHasColorScale(splitView)
                      ? { position: 'absolute', top: '60px', left: '80px' }
                      : { position: 'absolute', top: '60px', left: '50px' }
                  }
                />
                {children}
              </MapView>
            </div>
          </>
        ) : view.type === 'interior_360' ? (
          <>
            <div className={styles.leftHalf}>
              <PanoramaView
                view={view}
                panoView={panoView}
                lockView={lockView}
                onViewStateChange={(e) => this.panoViewStateChange(e)}
                onConfigCallbackChange={() => {}}
                onEvent={() => {}}
                key="split_view_left"
              >
                <SplitViewSelector
                  view={view}
                  viewsList={viewList}
                  onChange={this.handleLeftHalfChange}
                  className={classnames({
                    [styles.offsetSelector]: this.viewHasColorScale(view),
                  })}
                />
                <ViewSelector
                  view={view}
                  viewList={viewList}
                  onChange={this.handleLeftHalfChange}
                  style={
                    this.viewHasColorScale(view)
                      ? { position: 'absolute', top: '60px', left: '80px' }
                      : { position: 'absolute', top: '60px', left: '50px' }
                  }
                />
              </PanoramaView>
            </div>
            <div className={styles.rightHalf}>
              <PanoramaView
                view={splitView}
                panoView={panoView}
                lockView={lockView}
                onViewStateChange={(e) => this.panoViewStateChange(e)}
                onConfigCallbackChange={() => {}}
                onEvent={() => {}}
                key="split_view_right"
              >
                <SplitViewSelector
                  onChange={this.handleRightHalfChange}
                  view={splitView}
                  viewsList={viewList}
                  className={classnames({
                    [styles.offsetSelector]: this.viewHasColorScale(splitView),
                  })}
                />
                <ViewSelector
                  view={splitView}
                  viewList={viewList}
                  onChange={this.handleRightHalfChange}
                  style={
                    this.viewHasColorScale(splitView)
                      ? { position: 'absolute', top: '60px', left: '80px' }
                      : { position: 'absolute', top: '60px', left: '50px' }
                  }
                />
              </PanoramaView>
            </div>
          </>
        ) : (
          <>
            <div className={styles.leftHalf}>
              <PanoramaView
                view={view}
                panoView={panoView}
                lockView={lockView}
                onViewStateChange={(e) => this.panoViewStateChange(e)}
                onConfigCallbackChange={() => {}}
                onEvent={() => {}}
                key="split_view_left"
              >
                <SplitViewSelector
                  view={view}
                  viewsList={viewList}
                  onChange={this.handleLeftHalfChange}
                  className={classnames({
                    [styles.offsetSelector]: this.viewHasColorScale(view),
                  })}
                />
              </PanoramaView>
            </div>
            <div className={styles.rightHalf}>
              <PanoramaView
                view={splitView}
                panoView={panoView}
                lockView={lockView}
                onViewStateChange={(e) => this.panoViewStateChange(e)}
                onConfigCallbackChange={() => {}}
                onEvent={() => {}}
                key="split_view_right"
              >
                <SplitViewSelector
                  onChange={this.handleRightHalfChange}
                  view={splitView}
                  viewsList={viewList}
                  className={classnames({
                    [styles.offsetSelector]: this.viewHasColorScale(splitView),
                  })}
                />
              </PanoramaView>
            </div>
          </>
        )}
        {view.type === 'exterior_360' || view.type === 'interior_360' ? (
          <div className={styles.elevationDifferenceControl}>
            <Button
              type="secondary"
              text={lockView ? 'Unlock' : 'Lock'}
              onClick={() => this.lockButton()}
            />
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
