import * as React from 'react';

import style from './Unit.module.scss';

export interface IUnitProps {
  label: string;
  children?: any;
}

const Unit = ({ label, children }: IUnitProps): JSX.Element => (
  <div className={style.container}>
    <table>
      <tbody>
        <tr>
          <td>
            <label>{label}</label>
          </td>
        </tr>
        <tr>
          <td>
            <div>{children}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default Unit;
