import ApiBase from './ApiBase';
import {
  GenericApisReturnPromiseTypes,
  GenericObjectType,
} from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_CAPI_URL } from '../constants/urls';
import { undefinedOrNull } from '../utils/functs';

export default class ViewsV2Apis extends ApiBase {
  private readonly _baseUrl: string;

  private readonly _baseUrlV2: string;

  public constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = `/v1`;
    this._baseUrlV2 = `/v2`;
  }

  public listViewConfig(
    projectId: string,
    aoiId: string,
    viewId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(
      `${this._baseUrl}/projects/${projectId}/aois/${aoiId}/views/${viewId}/config`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the view config.`,
        });
      });
  }

  public getImageTilesUrl(
    guid: string,
    shareId?: string | null,
    isImage: boolean = false
  ): string {
    let tileUrl = '';
    let shareUrlParam = '';

    if (!undefinedOrNull(shareId) && shareId.trim() !== '') {
      shareUrlParam = `?vimana_share_token=${shareId}`;
    }

    if (!isImage) {
      tileUrl = `/tiles/${guid}.dzi`;
    }

    return `${this.baseUrl}${this._baseUrl}/images/${guid}${tileUrl}${shareUrlParam}`;
  }

  public getViewList(
    projectId: string,
    aoiId?: string | null
  ): GenericApisReturnPromiseTypes {
    let url = `${this._baseUrlV2}/projects/${projectId}/views`;

    if (!undefinedOrNull(aoiId)) {
      url = `${this._baseUrlV2}/projects/${projectId}/aois/${aoiId}/views`;
    }

    return this.get(url)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the view list.`,
          isArray: true,
        });
      });
  }

  public getView(
    projectId: string,
    aoiId: string,
    viewId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(
      `${this._baseUrlV2}/projects/${projectId}/aois/${aoiId}/views/${viewId}`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the view.`,
        });
      });
  }

  public postView(
    projectId: string,
    aoiId: string,
    formData: GenericObjectType,
    viewId?: string | null
  ): GenericApisReturnPromiseTypes {
    if (!undefinedOrNull(viewId)) {
      return this.updateView(
        projectId,
        aoiId,

        viewId,
        formData
      );
    }

    return this.post(
      `${this._baseUrlV2}/projects/${projectId}/aois/${aoiId}/views`,
      formData
    )

      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to create a new view.`,
        });
      });
  }

  public updateView(
    projectId: string,
    aoiId: string,
    viewId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.patch(
      `${this._baseUrlV2}/projects/${projectId}/aois/${aoiId}/views/${viewId}`,
      formData
    )

      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to update the view.`,
        });
      });
  }

  public postViewDescriptor(
    projectId: string,
    aoiId: string,
    viewId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/projects/${projectId}/aois/${aoiId}/views/${viewId}/config`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to create a new view descriptor.`,
        });
      });
  }

  public getViewDescriptor(
    projectId: string,
    aoiId: string,
    viewId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(
      `${this._baseUrl}/projects/${projectId}/aois/${aoiId}/views/${viewId}/config`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the view descriptor.`,
          isArray: true,
        });
      });
  }

  public patchViewImageConfig(
    projectId: string,
    aoiId: string,
    viewId: string,
    guid: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.patch(
      `${this._baseUrl}/projects/${projectId}/aois/${aoiId}/views/${viewId}/config/images/${guid}`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to hide the image.`,
        });
      });
  }

  public getViewFiles(
    viewId: string,
    path: string
  ): GenericApisReturnPromiseTypes {
    return this.get(`/v1/images/views/${viewId}/files/${path}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error when trying to fetch the files associated with the view.',
        });
      });
  }

  public getViewVectors(viewId: string): GenericApisReturnPromiseTypes {
    return this.get(`/v1/vectors/views/${viewId}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error when trying to fetch the vectors associated with the view.',
        });
      });
  }

  public uploadViewVectorFile(
    viewId: string,
    req: any
  ): GenericApisReturnPromiseTypes {
    return this.post(`/v1/vectors/views/${viewId}`, req)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error when trying to upload the vector file.',
        });
      });
  }

  public getViewVectorContent(fileId: string): GenericApisReturnPromiseTypes {
    return this.get(`/v1/vectors/${fileId}/contents`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error when trying to fetch the vectors associated with the view.',
        });
      });
  }

  public deleteViewVectorFile(fileId: string): GenericApisReturnPromiseTypes {
    return this.delete(`/v1/vectors/${fileId}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error when trying to delete the vector file.',
        });
      });
  }

  public getViewFileUrl(viewId: string): string {
    return `${this.baseUrl}/v1/images/views/${viewId}/files`;
  }

  public listAllViews(projectId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrlV2}/projects/${projectId}/views`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the view list.`,
          isArray: true,
        });
      });
  }

  public getViewsByTypeAndPublishStatus(
    projectId: string,
    aoiId: string,
    type: string,
    status: boolean
  ): Promise<any> {
    return this.get(
      `/v2/projects/${projectId}/aois/${aoiId}/views?type=${type}&includeUnpublished=${status}`
    );
  }
}
