import ApiBase from './ApiBase';
import {
  GenericApisReturnPromiseTypes,
  GenericObjectType,
} from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_CAPI_URL } from '../constants/urls';

export default class DownloadV2Apis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = `/v1`;
  }

  public listDownloadTypes(): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/downloads/types`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the download type.`,
          isArray: true,
        });
      });
  }

  public postDownloadFilterList(
    projectId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/downloads/project/${projectId}/filter`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to post the download filter.`,
          isArray: true,
        });
      });
  }

  public postDownloadUrl(
    projectId: string,
    formData: GenericObjectType[]
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/downloads/project/${projectId}/urls`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to post the download url.`,
          isArray: true,
        });
      });
  }
}
