// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ViewVectors_measureForm__HUJiN{margin-bottom:.5em}.ViewVectors_formControls__7USpn{display:flex;justify-content:center;align-items:start}.ViewVectors_formActions__Kcz-d{display:flex;justify-content:center}.ViewVectors_formActions__Kcz-d button{margin-right:30px}.ViewVectors_vectorOptions__fr4wT{display:flex;flex-direction:column;align-items:center;justify-content:center;margin-bottom:.5em;margin-top:1em}.ViewVectors_expandText__9Y6mD a{color:red;cursor:pointer;transition:color .3s}", "",{"version":3,"sources":["webpack://./src/components/Mission/Publish/ViewVectors.module.scss"],"names":[],"mappings":"AAAA,gCACE,kBAAA,CAGF,iCACE,YAAA,CACA,sBAAA,CACA,iBAAA,CAGF,gCACE,YAAA,CACA,sBAAA,CAGF,uCACE,iBAAA,CAGF,kCACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CAGF,iCACE,SAAA,CACA,cAAA,CACA,oBAAA","sourcesContent":[".measureForm {\n  margin-bottom: 0.5em;\n}\n\n.formControls {\n  display: flex;\n  justify-content: center;\n  align-items: start;\n}\n\n.formActions {\n  display: flex;\n  justify-content: center;\n}\n\n.formActions button {\n  margin-right: 30px;\n}\n\n.vectorOptions {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 0.5em;\n  margin-top: 1em;\n}\n\n.expandText a {\n  color: red;\n  cursor: pointer;\n  transition: color 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"measureForm": "ViewVectors_measureForm__HUJiN",
	"formControls": "ViewVectors_formControls__7USpn",
	"formActions": "ViewVectors_formActions__Kcz-d",
	"vectorOptions": "ViewVectors_vectorOptions__fr4wT",
	"expandText": "ViewVectors_expandText__9Y6mD"
};
export default ___CSS_LOADER_EXPORT___;
