import { WasmPyramid } from '@aspecscire/measurements-wasm';

export default class JsPyramid {
  base: string;

  wasm: WasmPyramid;

  layers: string[];

  totalRead: number = 0;

  constructor(base: string, json: Object) {
    this.base = base;
    this.wasm = new WasmPyramid(json);
    this.layers = this.wasm.layers();
  }

  static async fromUrl(base: string): Promise<JsPyramid> {
    const res = await fetch(`${base}/pyramid-index.json`, {
      cache: 'no-cache',
    });
    const json = await res.json();
    const pyr = new JsPyramid(base, json);

    return pyr;
  }

  public reader = async (
    left: number,
    top: number,
    right: number,
    bot: number,
    path: string
  ): Promise<Float64Array | null> => {
    const width = right - left;
    const height = bot - top;
    const res = await fetch(
      `${this.base}/${path}?left=${left}&top=${top}&width=${width}&height=${height}`,
      {
        cache: 'force-cache',
        credentials: 'include',
      }
    );

    if (!res.ok) {
      throw Error('fetch failed!');
    }

    const buff = await res.arrayBuffer();
    const vals = new Float64Array(buff);

    this.totalRead += vals.length;

    return vals;
  };
}
