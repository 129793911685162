// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LegendControlBox_container__Rxi5k .LegendControlBox_item__HBE9M{display:flex;padding:.5em;cursor:pointer}.LegendControlBox_container__Rxi5k .LegendControlBox_item__HBE9M .LegendControlBox_colorCircle__R951E{height:20px;width:20px;border-radius:50%;margin-left:auto;margin-right:10px}", "",{"version":3,"sources":["webpack://./src/components/View/ViewControls/LegendControlBox/index.module.scss"],"names":[],"mappings":"AACE,iEACE,YAAA,CACA,YAAA,CACA,cAAA,CAEA,sGACE,WAAA,CACA,UAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".container {\n  .item {\n    display: flex;\n    padding: 0.5em;\n    cursor: pointer;\n\n    .colorCircle {\n      height: 20px;\n      width: 20px;\n      border-radius: 50%;\n      margin-left: auto;\n      margin-right: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LegendControlBox_container__Rxi5k",
	"item": "LegendControlBox_item__HBE9M",
	"colorCircle": "LegendControlBox_colorCircle__R951E"
};
export default ___CSS_LOADER_EXPORT___;
