import classnames from 'classnames';
import * as React from 'react';
import { undefinedOrNull } from '../../../utils/functs';
import { getPlanTypeLabel } from '../../../utils/helpers';
import DropDown from '../../DropDown/DropDown';
import ViewFlightPlanning from '../ViewFlightPlan/ViewFlightPlan';
import styles from './FlightPlanning.module.scss';
import SkeletonLoader from '../../SkeletonLoader';
import { SnackbarActionsActionShowSnackbarTypes } from '../../../shapes/snackbar';

interface IProps {
  showSnackbar: SnackbarActionsActionShowSnackbarTypes;
  aoiId: string;
  projectId: string;
  missionId: string;
  mission?: any;
  user: any;
  drones: any[];
  plans: any[];
  epsg?: any;
  onSaveMission?: (req: any) => Promise<any>;
  reloadMission?: () => void;
  getDrones: () => void;
  getFlightPlans: (projectId: string, aoiId: string, missionId: string) => void;
  updateFlightPlan: (plan: any) => void;
}

interface IState {
  selectedDrone: string;
  selectedCamera: string;
  selectedFlightIndex: number;
  cameras: any[];
}

class FlightPlanning extends React.Component<IProps, IState> {
  private MIN_FLIGHT_PLAN_ITEM: number = 1;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      selectedDrone: '',
      selectedCamera: '',
      selectedFlightIndex: -1,
      cameras: [],
    };
  }

  public componentDidMount(): void {
    const { aoiId, projectId, missionId, mission, getDrones, getFlightPlans } =
      this.props;
    const { droneId, cameraId } = mission;

    getDrones();
    getFlightPlans(projectId, aoiId, missionId);
    this.updateDroneAndCameraId(droneId, cameraId);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    const { mission } = this.props;

    if (
      mission.droneId !== nextProps.mission.droneId ||
      mission.cameraId !== nextProps.mission.cameraId
    ) {
      const { droneId, cameraId } = nextProps.mission;

      this.updateDroneAndCameraId(droneId, cameraId);
    }
  }

  private handleDroneChange = (event: any) => {
    let selectCamera = '';
    const cameras = this.getCameras(event.value);

    if (cameras && cameras.length === 1) {
      selectCamera = cameras[0].value;
    }

    this.setState({
      selectedDrone: event.value,
      cameras,
      selectedCamera: selectCamera,
    });
  };

  private handleFlightPlanChange = (event: any) => {
    const { updateFlightPlan, plans } = this.props;
    const selectedFlightIndex = parseInt(event.value, 10);

    this.setState({
      selectedFlightIndex,
    });

    updateFlightPlan(plans[selectedFlightIndex]);
  };

  private handleCameraChange = (event: any) => {
    this.setState({ selectedCamera: event.value });
  };

  private updateDroneAndCameraId = (droneId: any, cameraId: any) => {
    if (droneId && cameraId) {
      this.setState({
        selectedCamera: cameraId,
        selectedDrone: droneId,
      });
    }
  };

  private handlePlanChanged = (plan: any) => {
    const { updateFlightPlan, reloadMission } = this.props;

    updateFlightPlan(plan);
    if (reloadMission) {
      reloadMission();
    }
  };

  private getCameras = (id: string) => {
    const { drones } = this.props || [];
    const droneId = id;

    if (!droneId || !drones) {
      return [];
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const d of drones) {
      if (d.id === droneId) {
        return (
          d.cameras.map((camera: any) => {
            return { label: camera.name, value: camera.id };
          }) || []
        );
      }
    }

    return [];
  };

  public render(): React.ReactNode {
    const { selectedFlightIndex, selectedDrone, selectedCamera, cameras } =
      this.state;

    const {
      plans,
      drones,
      aoiId,
      projectId,
      missionId,
      user,
      onSaveMission,
      showSnackbar,
      epsg,
    } = this.props;
    const selectedFlightPlan =
      plans.length > this.MIN_FLIGHT_PLAN_ITEM
        ? plans[selectedFlightIndex]
        : plans[0];

    if (!this.state || !drones) {
      return <div>Loading...</div>;
    }

    return (
      <div className={styles.Container}>
        <div className={styles.title}>
          <h3>DRONE PARAMETERS</h3>
        </div>
        <div className={styles.dronesContainer}>
          <table>
            <tbody>
              <tr>
                <td>
                  <label
                    className={classnames(styles.name, styles.droneFontSize)}
                  >
                    Drone
                  </label>
                </td>
                <td className={styles.padLeft}>
                  <label
                    className={classnames(styles.name, styles.droneFontSize)}
                  >
                    Camera
                  </label>
                </td>
              </tr>
              <tr>
                <td className={styles.padRight}>
                  <DropDown
                    value={selectedDrone}
                    onChange={this.handleDroneChange}
                    options={drones.map((drone) => {
                      return { label: drone.name, value: drone.id };
                    })}
                    placeholder="Select a drone"
                  />
                </td>
                <td className={styles.padLeft}>
                  <DropDown
                    value={selectedCamera}
                    onChange={this.handleCameraChange}
                    options={cameras}
                    placeholder="Select a camera"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {undefinedOrNull(plans) || plans.length < 1 ? (
          <div className={styles.selectedFlightPlanning}>
            <SkeletonLoader
              size={1}
              position="absolute"
              className={styles.skeletonLoading}
            />
          </div>
        ) : null}

        {plans.length > this.MIN_FLIGHT_PLAN_ITEM && (
          <div className={styles.selectedFlightPlanning}>
            <DropDown
              value={
                !undefinedOrNull(selectedFlightPlan)
                  ? getPlanTypeLabel(selectedFlightPlan.type)
                  : ''
              }
              onChange={this.handleFlightPlanChange}
              options={(plans || []).map((a, index) => {
                return {
                  label: getPlanTypeLabel(a.type),
                  value: index.toString(), // Work around; cb do not hand back any other object keys.
                };
              })}
              placeholder="Select a Flight Plan"
            />
          </div>
        )}

        {!undefinedOrNull(selectedFlightPlan) && (
          <div className={styles.title}>
            <ViewFlightPlanning
              key={selectedFlightPlan.id}
              aoiId={aoiId}
              projectId={projectId}
              missionId={missionId}
              drones={drones}
              flightPlan={selectedFlightPlan}
              epsg={epsg}
              onSaveMission={onSaveMission}
              planTypeLabel={getPlanTypeLabel(selectedFlightPlan.type)}
              handlePlanChanged={this.handlePlanChanged}
              user={user}
              droneId={selectedDrone}
              cameraId={selectedCamera}
              showSnackbar={showSnackbar}
            />
          </div>
        )}
      </div>
    );
  }
}

export default FlightPlanning;
