import { defineAction } from 'redux-define';
import {
  SnackbarActionsNsShowTypes,
  SnackbarActionsNsHideTypes,
} from '../shapes/snackbar';

export type AuthType = string;

export const AUTH_FETCH: AuthType = 'AUTH_FETCH';
export const AUTH_FETCH_STARTED: AuthType = '@api/AUTH_FETCH/STARTED';
export const AUTH_FETCH_SUCCESS: AuthType = '@api/AUTH_FETCH/SUCCESS';

export const LOGIN = 'LOGIN';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';

export const CHECK_LOGIN = 'CHECK_LOGIN';
export const CHECK_LOGIN_FULFILLED = 'CHECK_LOGIN_FULFILLED';
export const CHECK_LOGIN_PENDING = 'CHECK_LOGIN_PENDING';
export const CHECK_LOGIN_REJECTED = 'CHECK_LOGIN_REJECTED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED';

export const INIT_APP = 'INIT_APP';

export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_PENDING = 'FETCH_PROJECTS_PENDING';
export const FETCH_PROJECTS_FULFILLED = 'FETCH_PROJECTS_FULFILLED';
export const FETCH_PROJECTS_REJECTED = 'FETCH_PROJECTS_REJECTED';
export const SET_PROJECTS_FILTER = 'SET_PROJECTS_FILTER';
export const CLEAR_PROJECTS_FILTER = 'CLEAR_PROJECTS_FILTER';

export const REJECTED = 'REJECTED';
export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const OPEN = 'OPEN';
export const CLOSE = 'CLOSE';

export const SETTINGS = defineAction('SETTINGS');
export const SETTINGS_MODAL = defineAction('MODAL', [OPEN, CLOSE], SETTINGS);
export const SETTINGS_UPDATE = defineAction(
  'UPDATE',
  [PENDING, REJECTED, FULFILLED],
  SETTINGS
);

export const ORGANISATION = defineAction('ORGANISATION');
export const ORGANISATION_MODAL = defineAction(
  'MODAL',
  [OPEN, CLOSE],
  ORGANISATION
);

export const CREATE_MISSION = 'CREATE_MISSION';
export const CREATE_MISSION_PENDING = 'CREATE_MISSION_PENDING';
export const CREATE_MISSION_FULFILLED = 'CREATE_MISSION_FULFILLED';
export const CREATE_MISSION_REJECTED = 'CREATE_MISSION_REJECTED';

export const SUBMIT_MISSION = 'SUBMIT_MISSION';
export const SUBMIT_MISSION_PENDING = 'SUBMIT_MISSION_PENDING';
export const SUBMIT_MISSION_FULFILLED = 'SUBMIT_MISSION_FULFILLED';
export const SUBMIT_MISSION_REJECTED = 'SUBMIT_MISSION_REJECTED';

export const RESET_MISSION_STATE = 'RESET_MISSION_STATE';

export const GET_MISSION = 'GET_MISSION';
export const GET_MISSION_PENDING = 'GET_MISSION_PENDING';
export const GET_MISSION_FULFILLED = 'GET_MISSION_FULFILLED';
export const GET_MISSION_REJECTED = 'GET_MISSION_REJECTED';

export const GET_DRONES = 'GET_DRONES';
export const GET_DRONES_PENDING = 'GET_DRONES_PENDING';
export const GET_DRONES_FULFILLED = 'GET_DRONES_FULFILLED';
export const GET_DRONES_REJECTED = 'GET_DRONES_REJECTED';

export const GET_EPSG_BY_ID = 'GET_EPSG_BY_ID';
export const GET_EPSG_BY_ID_PENDING = 'GET_EPSG_BY_ID_PENDING';
export const GET_EPSG_BY_ID_FULFILLED = 'GET_EPSG_BY_ID_FULFILLED';
export const GET_EPSG_BY_ID_REJECTED = 'GET_EPSG_BY_ID_REJECTED';

export const SEARCH_EPSG_BY_TEXT = 'SEARCH_EPSG_BY_TEXT';
export const SEARCH_EPSG_BY_TEXT_PENDING = 'SEARCH_EPSG_BY_TEXT_PENDING';
export const SEARCH_EPSG_BY_TEXT_FULFILLED = 'SEARCH_EPSG_BY_TEXT_FULFILLED';
export const SEARCH_EPSG_BY_TEXT_REJECTED = 'SEARCH_EPSG_BY_TEXT_REJECTED';

export const GET_INITIAL_FLIGHT_PLAN = 'GET_INITIAL_FLIGHT_PLAN';
export const GET_INITIAL_FLIGHT_PLAN_PENDING =
  'GET_INITIAL_FLIGHT_PLAN_PENDING';
export const GET_INITIAL_FLIGHT_PLAN_FULFILLED =
  'GET_INITIAL_FLIGHT_PLAN_FULFILLED';
export const GET_INITIAL_FLIGHT_PLAN_REJECTED =
  'GET_INITIAL_FLIGHT_PLAN_REJECTED';

export const UPDATE_FLIGHT_PLAN = 'UPDATE_FLIGHT_PLAN';

export const GET_GCP_PLANS = 'GET_GCP_PLANS';
export const GET_GCP_PLANS_PENDING = 'GET_GCP_PLANS_PENDING';
export const GET_GCP_PLANS_FULFILLED = 'GET_GCP_PLANS_FULFILLED';
export const GET_GCP_PLANS_REJECTED = 'GET_GCP_PLANS_REJECTED';

export const UPDATE_GCP_PLAN = 'UPDATE_GCP_PLAN';

export const GET_FLIGHT_PLAN_IMAGES = 'GET_FLIGHT_PLAN_IMAGES';
export const GET_FLIGHT_PLAN_IMAGES_PENDING = 'GET_FLIGHT_PLAN_IMAGES_PENDING';
export const GET_FLIGHT_PLAN_IMAGES_FULFILLED =
  'GET_FLIGHT_PLAN_IMAGES_FULFILLED';
export const GET_FLIGHT_PLAN_IMAGES_REJECTED =
  'GET_FLIGHT_PLAN_IMAGES_REJECTED';

export const GET_GCP_PLAN_DATA = 'GET_GCP_PLAN_DATA';
export const GET_GCP_PLAN_DATA_PENDING = 'GET_GCP_PLAN_DATA_PENDING';
export const GET_GCP_PLAN_DATA_FULFILLED = 'GET_GCP_PLAN_DATA_FULFILLED';
export const GET_GCP_PLAN_DATA_REJECTED = 'GET_GCP_PLAN_DATA_REJECTED';

export const UPDATE_GCP_POINTS_DATA = 'UPDATE_GCP_POINTS_DATA';

export const SHOW_SNACKBAR: SnackbarActionsNsShowTypes = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR: SnackbarActionsNsHideTypes = 'HIDE_SNACKBAR';
