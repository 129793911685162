import classnames from 'classnames';
import * as React from 'react';

import style from './index.module.scss';

type IconSize = 'small' | 'large' | null;

interface IProps {
  name: string;
  size?: IconSize;
  className?: string;
  onClick?: () => void;
}

class Icon extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { name, size, className, ...rest } = this.props;
    const computedClassName = classnames(
      className,
      style.icon,
      style[`icon_${name}`],
      size && style[`size_${size}`]
    );

    return <span className={computedClassName} {...rest} />;
  }
}

export default Icon;
