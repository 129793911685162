import axios from 'axios';

import ApiBase from './ApiBase';
import {
  ListManualUploadsResponse,
  ManualUploadResponse,
  ManualUploadCreatedResponse,
} from './manualUploads.types';
import { CoordinateUnits } from '../shapes/manualUploads';
import { processErrorHandling } from '../utils/helpers';
import { BASE_WORKBENCH_API_URL } from '../constants/urls';

export default class ManualUploadsApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_WORKBENCH_API_URL);

    this._baseUrl = `/v1/projects`;
  }

  public async listUploads(
    projectId: string,
    aoiId: string
  ): Promise<ListManualUploadsResponse> {
    return this.get(`${this._baseUrl}/${projectId}/aois/${aoiId}/uploads`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list processed data uploads.`,
          isArray: true,
        });
      });
  }

  public async listMissionUploads(
    projectId: string,
    aoiId: string,
    missionId: string
  ): Promise<ListManualUploadsResponse> {
    return this.get(
      `${this._baseUrl}/${projectId}/aois/${aoiId}/uploads/missions/${missionId}`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list processed data uploads.`,
          isArray: true,
        });
      });
  }

  public async fetchUpload(
    projectId: string,
    aoiId: string,
    uploadId: string
  ): Promise<ManualUploadResponse> {
    return this.get(
      `${this._baseUrl}/${projectId}/aois/${aoiId}/uploads/${uploadId}`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the manual data upload.`,
          isArray: false,
        });
      });
  }

  public async createPhotogrammetryUpload(
    projectId: string,
    aoiId: string,
    missionId: string
  ): Promise<ManualUploadCreatedResponse> {
    return this.post(`${this._baseUrl}/${projectId}/aois/${aoiId}/uploads`, {
      uploadType: 'PG_UPLOAD',
      name: 'Mosaic & DEM Upload',
      missionId,
    })
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the manual data upload.`,
          isArray: false,
        });
      });
  }

  public async createInspectionUpload(
    projectId: string,
    aoiId: string
  ): Promise<ManualUploadCreatedResponse> {
    return this.post(`${this._baseUrl}/${projectId}/aois/${aoiId}/uploads`, {
      uploadType: 'INSPECTION_UPLOAD',
      name: 'Inspection Upload',
    })
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the manual data upload.`,
          isArray: false,
        });
      });
  }

  public async createPointCloudUpload(
    projectId: string,
    aoiId: string,
    coordinateUnits: CoordinateUnits,
    missionId: string
  ): Promise<ManualUploadCreatedResponse> {
    return this.post(`${this._baseUrl}/${projectId}/aois/${aoiId}/uploads`, {
      uploadType: 'POINT_CLOUD_UPLOAD',
      name: 'Point cloud Upload',
      coordinateUnits,
      missionId,
    })
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the manual data upload.`,
          isArray: false,
        });
      });
  }

  public async createDTMUpload(
    projectId: string,
    aoiId: string,
    missionId: string
  ): Promise<ManualUploadCreatedResponse> {
    return this.post(`${this._baseUrl}/${projectId}/aois/${aoiId}/uploads`, {
      uploadType: 'DTM_UPLOAD',
      name: 'DTM Upload',
      missionId,
    })
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the manual data upload.`,
          isArray: false,
        });
      });
  }

  public async createThermalUpload(
    projectId: string,
    aoiId: string,
    missionId: string
  ): Promise<ManualUploadCreatedResponse> {
    return this.post(`${this._baseUrl}/${projectId}/aois/${aoiId}/uploads`, {
      uploadType: 'THERMAL_MOSAIC_UPLOAD',
      name: 'Thermal Upload',
      missionId,
    })
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the manual data upload.`,
          isArray: false,
        });
      });
  }

  public async getFileReUploadUrls(
    projectId: string,
    aoiId: string,
    uploadId: string
  ): Promise<ManualUploadCreatedResponse> {
    return this.get(
      `${this._baseUrl}/${projectId}/aois/${aoiId}/uploads/${uploadId}/urls`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error when trying to fetch the urls for re-upload.',
          isArray: false,
        });
      });
  }

  public async triggerUploadProcessing(
    projectId: string,
    aoiId: string,
    uploadId: string,
    data: {
      coordinateUnits?: CoordinateUnits;
    }
  ): Promise<ManualUploadResponse> {
    return this.post(
      `${this._baseUrl}/${projectId}/aois/${aoiId}/uploads/${uploadId}/process`,
      data
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to trigger processing.`,
          isArray: false,
        });
      });
  }

  public async uploadFile(
    fileToUpload: File,
    uploadUrl: string,
    includeCredentials: boolean = false
  ) {
    return axios
      .put(uploadUrl, fileToUpload, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        withCredentials: includeCredentials,
      })
      .catch((err) => {
        console.error(
          `There was an error uploading the file to storage. Upload URL: ${uploadUrl}`,
          err
        );

        return Promise.reject();
      });
  }
}
