import { CameraOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { Marker } from 'google-maps-react';
import * as React from 'react';
import _ from 'lodash';
import FloorMapInset from '../../Interior360Manager/FloorMapInset';
import { undefinedOrNull } from '../../../utils/functs';
import { View } from '../../../api/views.types';
import ViewsV2Apis from '../../../api/viewsV2';
import {
  APP_PRIMARY_COLOR,
  GOOGLE_MAP_MARKER_SELECTED_COLOR,
} from '../../../constants/colors';
import BoundaryMap from '../../Mission/BoundaryMap';
import Resizer from '../../Resizer';
import {
  BaseRendererProps,
  ImageSelectionChanged,
  ViewConfig,
} from '../index.types';
import styles from './index.module.scss';
import PannellumRender, {
  PanoramaImage,
  PanoramaViewState,
} from './PannellumRenderer';
import PanoramaStateProvider from './PannellumRenderer/PanoramaStateProvider';

type InsetState = 'minimized' | 'window' | 'maximized';

interface IProps extends BaseRendererProps {
  view: View;
  userRole?: string;
  potreeInsetViewIdOverride?: string;
  onViewStateChange?: (state: PanoramaViewState) => void;
  panoView?: PanoramaViewState;
  lockView?: boolean;
}

interface IState {
  viewer?: any;
  selectedImageGuid?: string;
  viewDescriptor?: { images: PanoramaImage[]; floorLevelId?: string };
  viewableImages?: PanoramaImage[];
  viewConfig?: ViewConfig;
  insetState: InsetState;
  insetSize: { height: any; width: any };
  yaw: number;
  pitch: number;
  hfov: number;
  previousImage?: PanoramaImage;
  panoToastMessage?: any;
}

export default class PanoramaView extends React.Component<IProps, IState> {
  private viewsV2Apis = new ViewsV2Apis();

  private stateProviderRef = React.createRef<PanoramaStateProvider>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      insetState: 'window',
      insetSize: { height: '100%', width: 400 },
      yaw: 0,
      pitch: -45,
      hfov: 100,
    };
  }

  public componentDidMount() {
    const { share, view, viewConfig } = this.props;

    // console.log(this.props, this._reactInternalFiber.key);

    if (share) {
      this.setState(
        { insetState: 'minimized', viewConfig: share?.viewConfig },
        () => {
          this.setDescriptorData(share?.viewDescriptor);
        }
      );
    } else {
      this.setState({ viewConfig }, () => {
        this.fetchViewDescriptor(view);
      });
    }

    const { onConfigCallbackChange, onFooterControlsFragChange } = this.props;

    onConfigCallbackChange((): ViewConfig => {
      const image = this.getCurrentImage();

      return {
        selection: image?.id || '',
      };
    });

    if (onFooterControlsFragChange) {
      onFooterControlsFragChange(
        <div style={{ width: 'auto', zIndex: 10 }}>
          <CameraOutlined
            className={styles.icon}
            title="Screenshot"
            onClick={() => {
              if (this.stateProviderRef.current) {
                this.stateProviderRef.current.saveScreenshot();
              }
            }}
          />
        </div>
      );
    }

    this.setState({ panoToastMessage: this.getToast() });
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { view: prevView, panoView: prevPanoView } = this.props;
    const { view, viewConfig, panoView, lockView } = nextProps;
    const { viewConfig: viewConfigFromState } = this.state;

    if (view?.id !== prevView?.id && view?.id) {
      this.fetchViewDescriptor(view);
    } else if (!_.isEqual(viewConfig, viewConfigFromState)) {
      // reset descriptor data with this viewConfig
      this.setState({ viewConfig }, () => {
        const { viewDescriptor } = this.state;

        this.setDescriptorData(viewDescriptor);
      });
    } else if (panoView !== prevPanoView && panoView && lockView) {
      this.setState({
        yaw: panoView.yaw,
        pitch: panoView.pitch,
        hfov: panoView.hfov,
      });
    }
  }

  private panoStateChanged = (e: PanoramaViewState) => {
    const { onViewStateChange } = this.props;

    if (onViewStateChange) {
      onViewStateChange(e);
    }
  };

  private getToast() {
    const { view } = this.props;

    if (view?.type === 'exterior_360') {
      return (
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            bottom: '5px',
            left: 0,
            zIndex: 1,
            background: 'rgba(102, 102, 102, 0.8)',
            borderRadius: '10px',
            transform: 'translate(-50%, 0%)',
            marginLeft: '50%',
            padding: '5px',
            verticalAlign: 'bottom',
          }}
        >
          <span>CLICK AND DRAG TO LOOK AROUND</span>
          <div style={{ marginLeft: '5px' }}>
            <i
              className="fa fa-times-circle"
              style={{ marginTop: '3px' }}
              aria-hidden="true"
              onClick={() => {
                this.setState({ panoToastMessage: '' });
              }}
            />
          </div>
        </div>
      );
    }

    return undefined;
  }

  private fetchViewDescriptor(view: View) {
    const previousImage = this.getCurrentImage() || undefined;

    this.setState(
      {
        viewDescriptor: undefined,
        selectedImageGuid: undefined,
        previousImage,
      },
      () => {
        this.viewsV2Apis
          .listViewConfig(view.projectId, view.aoiId, view.id)
          .then((res) => {
            const { error, data } = res;

            if (error) {
              console.error(error);

              return;
            }

            if (data) {
              this.setDescriptorData(data);
            }
          });
      }
    );
  }

  private setDescriptorData(descriptor: any) {
    if (!descriptor) {
      return;
    }

    const { previousImage: prevImage, viewConfig } = this.state;
    const images: PanoramaImage[] = (
      (descriptor?.images as PanoramaImage[]) || []
    ).filter((img) => !img.hidden || img.id === viewConfig?.selection);
    let selectedImageGuid: any;

    if (prevImage) {
      // find closest image to previous image
      const { latitude, longitude } = prevImage;
      const distancefn = (img: PanoramaImage): number => {
        return Math.sqrt(
          ((latitude || 0) - (img.latitude || 0)) ** 2 +
            ((longitude || 0) - (img.longitude || 0)) ** 2
        );
      };

      let closest: PanoramaImage | undefined;
      let minDist = Number.POSITIVE_INFINITY;

      // eslint-disable-next-line no-restricted-syntax
      for (const i of images) {
        const dist = distancefn(i);

        if (dist < minDist) {
          minDist = dist;
          closest = i;
        }
      }

      if (closest) {
        selectedImageGuid = closest.id;
      }
    }

    if (!selectedImageGuid && viewConfig?.selection) {
      // view config has a selection
      const img = images.find((img) => img.id === viewConfig?.selection);

      if (img) {
        selectedImageGuid = viewConfig?.selection;
      }
    }

    if (!selectedImageGuid) {
      const defaultImage = images.find((img: any) => img.default);

      // check if selected image in state is actually present in descriptor

      // find new selection from descriptor
      if (defaultImage?.id) {
        selectedImageGuid = defaultImage?.id;
      } else if (images.length > 0) {
        selectedImageGuid = images[0]?.id;
      }
    }

    const newState: any = {
      viewDescriptor: descriptor,
      viewableImages: images,
      previousImage: undefined,
    };

    if (!prevImage) {
      // if we are retaining state from a previous view, don't set from config
      // because we want to be as close as possible to previous view
      if (!undefinedOrNull(viewConfig?.longitude)) {
        newState.yaw = viewConfig?.longitude;
      }

      if (!undefinedOrNull(viewConfig?.latitude)) {
        newState.pitch = viewConfig?.latitude;
      }

      if (!undefinedOrNull(viewConfig?.zoom)) {
        newState.hfov = viewConfig?.zoom;
      }
    }

    this.setState(newState, () => {
      if (selectedImageGuid) {
        this.selectImage(selectedImageGuid);
      }
    });
  }

  private selectImage = (id: string) => {
    const prevImage = this.getCurrentImage();

    this.setState(
      {
        selectedImageGuid: id,
      },
      () => {
        const { onEvent } = this.props;

        if (prevImage && prevImage.id !== id) {
          // only send event if an actual change happens
          onEvent(
            new ImageSelectionChanged({
              previousImage: prevImage.id,
              currentImage: id,
            })
          );
        }
      }
    );
  };

  private getCurrentImage(): PanoramaImage | undefined {
    const { selectedImageGuid, viewableImages } = this.state;

    if (selectedImageGuid) {
      // eslint-disable-next-line no-restricted-syntax
      for (const img of viewableImages || []) {
        if (img?.id === selectedImageGuid) {
          return img;
        }
      }
    }

    return undefined;
  }

  private renderInset() {
    const { selectedImageGuid, yaw, hfov, viewableImages, insetState } =
      this.state;
    const { view, share } = this.props;
    const { projectId, aoiId } = view;
    const images = viewableImages || [];

    const markers: any[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const img of images) {
      const selected = selectedImageGuid === img.id;

      const icon = selected
        ? {
            // Can ONLY use SVG path for rotation to work
            path: this.describeArc(0, 0, 50, 180 - hfov / 2, 180 + hfov / 2),
            scale: 1,
            fillColor: GOOGLE_MAP_MARKER_SELECTED_COLOR,
            fillOpacity: 0.3,
            rotation: 180 + yaw,
            zIndex: 1,
            strokeColor: 'transparent',
          }
        : {
            path: this.svgCirclePath(0, 0, 8),
            scale: 1,
            fillColor: APP_PRIMARY_COLOR,
            fillOpacity: 1,
            zIndex: 10,
          };
      const marker = (
        <Marker
          key={img.id}
          position={{ lat: img.latitude, lng: img.longitude }}
          icon={icon}
          onClick={
            selected
              ? () => {
                  /**/
                }
              : this.getMarkerClickHandler(img)
          }
          zIndex={selected ? 99 : 999}
        />
      );

      markers.push(marker);
      if (selected) {
        markers.push(
          <Marker
            key={`${img.id}-dup`}
            position={{ lat: img.latitude, lng: img.longitude }}
            icon={{
              path: this.svgCirclePath(0, 0, 8),
              scale: 1,
              fillColor: APP_PRIMARY_COLOR,
              fillOpacity: 1,
              zIndex: 10,
            }}
          />
        );
      }
    }

    return insetState === 'minimized' ? null : (
      <BoundaryMap
        projectId={projectId}
        aoiId={aoiId}
        points={[...images]}
        boundary={share?.boundary}
      >
        {markers}
      </BoundaryMap>
    );
  }

  private svgCirclePath(cx: number, cy: number, r: number) {
    const path = `M ${cx - r}, ${cy}
      a ${r},${r} 0 1,0 ${r * 2},0
      a ${r},${r} 0 1,0 -${r * 2},0
    `;

    return path;
  }

  private polarToCartesian(
    centerX: any,
    centerY: any,
    radius: any,
    angleInDegrees: any
  ) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  private describeArc(
    x: any,
    y: any,
    radius: any,
    startAngle: any,
    endAngle: any
  ) {
    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);

    return `M ${x},${y} ${start.x},${start.y} ${end.x},${end.y} Z`;
  }

  private getMarkerClickHandler = (img: any) => {
    return () => {
      this.selectImage(img.id);
    };
  };

  private renderFloorMapInset = () => {
    const { view } = this.props;
    const { projectId, aoiId } = view;
    const {
      viewDescriptor,
      yaw,
      // selectedImageGuid,
      viewableImages,
      insetState,
    } = this.state;

    if (!undefinedOrNull(viewDescriptor)) {
      const images = viewableImages || [];

      return insetState === 'minimized' ? null : (
        <FloorMapInset
          projectId={projectId}
          aoiId={aoiId}
          planId={viewDescriptor.floorLevelId || ''}
          view={view}
          currentPano={this.getCurrentImage()}
          parent="viewer"
          panoImages={images}
          updateCurrentPano={(id) => {
            this.selectImage(id);
          }}
          viewerYaw={yaw}
        />
      );
    }

    return null;
  };

  public render() {
    const { view, children, share, lockView } = this.props;
    const {
      insetState,
      insetSize,
      yaw,
      pitch,
      hfov,
      selectedImageGuid,
      viewableImages,
      panoToastMessage,
    } = this.state;
    const insetMaximized = insetState === 'maximized';
    const insetMinimized = insetState === 'minimized';
    const initialView = { yaw, pitch, hfov };

    if (!viewableImages || viewableImages.length < 1) {
      return <div />;
    }

    return (
      <div className={styles.container}>
        <div
          className={classnames(styles.containerInnerWrapper, {
            [styles.splitPanes]: insetMaximized,
          })}
        >
          <Resizer
            allowedDirections={['right']}
            onDrag={(e: any) =>
              this.setState({ insetSize: { ...insetSize, width: e.right } })
            }
            showHotspot={{
              right: insetMaximized,
            }}
            style={{ maxWidth: '50%' }}
          >
            <div
              style={insetMaximized ? insetSize : {}}
              className={classnames(styles.mapContainer, {
                [styles.float]: !insetMaximized,
                [styles.sidePane]: insetMaximized,
                [styles.minimized]: insetState === 'minimized',
              })}
            >
              {document.body.clientHeight >= 500 && (
                <div
                  className={styles.informationContainer}
                  style={insetMaximized ? { opacity: 1 } : {}}
                >
                  <div className={styles.item} style={{ width: '100%' }}>
                    <div
                      className={styles.windowButtons}
                      style={{ marginLeft: 'auto', height: '21px' }}
                    >
                      <i
                        className={`fa fa-window-${
                          insetMinimized ? 'restore' : 'minimize'
                        } ${styles.windowButton}`}
                        aria-hidden="true"
                        onClick={() => {
                          this.setState({
                            insetState: insetMinimized ? 'window' : 'minimized',
                          });
                        }}
                      />
                      <i
                        className={`fa fa-${
                          insetMaximized ? 'compress' : 'expand'
                        } ${styles.windowButton}`}
                        aria-hidden="true"
                        onClick={() => {
                          this.setState({
                            insetState: insetMaximized ? 'window' : 'maximized',
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div
                id="panoInsetMap"
                className={classnames(styles.plotGoogleMapWrapper, {
                  [styles.sidePane]: insetMaximized,
                  hide: insetState === 'minimized',
                })}
              >
                {view.type === 'interior_360'
                  ? this.renderFloorMapInset()
                  : this.renderInset()}
              </div>
            </div>
          </Resizer>
          <div
            className={classnames(styles.tilingWrapper, {
              [styles.sidePane]: insetMaximized,
            })}
          >
            {panoToastMessage}
            {viewableImages && selectedImageGuid && (
              <PannellumRender
                viewId={view.id}
                selectedImage={selectedImageGuid}
                images={viewableImages}
                initialView={initialView}
                shareId={share?.id}
                lockView={lockView}
                onViewStateChange={(state) => {
                  this.setState({ ...state });

                  if (
                    state.yaw !== 0 ||
                    state.pitch !== -45 ||
                    state.hfov !== 100
                  ) {
                    this.panoStateChanged({ ...state });
                  }
                }}
                onSceneChange={this.selectImage}
              >
                {children}
                <PanoramaStateProvider
                  key="panorama_state_provider_screenshot"
                  ref={this.stateProviderRef}
                />
              </PannellumRender>
            )}
          </div>
        </div>
      </div>
    );
  }
}
