import autobind from 'autobind-decorator';
import classnames from 'classnames';
import * as React from 'react';
import Dropdown from '../DropDown/DropDown';
import style from './SurveyTypeSelector.module.scss';

interface IOption {
  value: string;
  label: string;
}

interface IProps {
  options: IOption[];
  viewId: string;
  history: any;
  className?: string;
  onSurveyChange?: (id: string) => void;
}

class SurveyTypeSelector extends React.Component<IProps> {
  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { viewId } = this.props;

    if (viewId !== nextProps.viewId) {
      // console.log('=============================');
      // console.log('props changes from ', viewId);
      // console.log('to this =>', nextProps.viewId);
      // console.log('==============================');
    }
  }

  @autobind
  private handleOption(option: IOption): void {
    const { history, onSurveyChange } = this.props;

    if (onSurveyChange) {
      onSurveyChange(option.value);
    } else {
      history.push(option.value);
    }
  }

  public render(): React.ReactNode {
    const { options, className, viewId } = this.props;

    options.sort((a: any, b: any) => {
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
    });

    return (
      <div className={classnames(style.container, className)}>
        <Dropdown
          options={options}
          value={viewId}
          onChange={this.handleOption}
        />
      </div>
    );
  }
}

export default SurveyTypeSelector;
