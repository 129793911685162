import classnames from 'classnames';
import _ from 'lodash';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'minireset.css';
import queryString from 'query-string';
import React, { Component } from 'react';
import * as ReactGA from 'react-ga';
import { IntlProvider } from 'react-intl';
import LoadingBar from 'react-redux-loading-bar';
import { matchPath, Route } from 'react-router-dom';
import { Button, Modal } from 'antd';
import ConfigApi from 'src/api/config';
import { AppConfig, Config } from 'src/utils/config';

import {
  AOI_LISTING_PAGE_PATH,
  DOWNLOADS_LIST_WITHOUT_AOI_PATH,
  DOWNLOADS_LIST_WITH_AOI_PATH,
  INTERIOR360_VIEWS_EDIT_PATH,
  INTERIOR360_VIEWS_NEW_PATH,
  PROJECT_VIDEOS_EDIT_PAGE_PATH,
  PROJECT_VIDEOS_NEW_PAGE_PATH,
  SHARE_PATH,
  VIEW_PATH,
} from '../../routes/paths';
import '../../styles/scss/global.scss';
import {
  extractProjectIdFromURL,
  isVimanaLite,
  shouldShowPage,
  undefinedOrNull,
} from '../../utils/functs';
import { setFavicon, setTitle } from '../../utils/window';

import BrandingAPIs from '../../api/branding';
import { analytics } from '../../utils/analytics';
import Aoi from '../Aoi';
import AoiData from '../AoiData';
import ArchivedProjects from '../ArchivedProjects/ArchivedProjects';
import Coachmark from '../Coachmark/container';
import ConfirmModal from '../ConfirmModal/ConfirmModalContainer';
import ContourMaps from '../ContourMaps/container';
import CreateAOI from '../CreateAOI/container';
import CreateProjectV2 from '../CreateProjectV2/container';
import CutAndFillReport from '../CutAndFillReport/container';
import Diagnostics from '../Diagnostics/DiagnosticsOperations/container';
import Downloads from '../Downloads';
import EditAoi from '../EditAoi/container';
import EditProjectV2 from '../EditProjectV2/container';
import ElevationDifferenceCrud from '../ElevationDifferenceControl/ElevationDifferenceCrudContainer/container';
import GcpMarking from '../GcpMarking/container';
import { showProjectAdminLinks } from '../Header';
import Header from '../Header/container';
import Interior360Editor from '../Interior360Manager/Interior360Editor';
import Issues from '../IssuesV2/container';
import Login from '../Login/container';
import SelfSignUp from '../Login/SelfSignUp';
import ManageProjectML from '../ManageProjectML/container';
import ManageUsers from '../ManageUsers/ManageUsers';
import Mission from '../Mission/MissionContainer';
import ModalNotification from '../ModalNotification/ModalNotification';
import Payments from '../Payments/container';
import PaymentsHistory from '../Payments/PaymentHistory';
import Project from '../Project/Project';
import ProjectList from '../ProjectList';
import ProjectVideos from '../ProjectVideos/container';
import Quickstart from '../Quickstart';
import ResetPassword from '../ResetPassword/container';
import Share from '../Share/ShareContainer';
import Signup from '../Signup/SignupContainer';
import SkeletonLoader from '../SkeletonLoader';
import Snackbar from '../Snackbar';
import TerrainMaps from '../TerrainMaps/container';
import UserSandbox from '../UserSandbox/container';
import OrganisationSettings from '../UserSettings/OrganisationSettings/container';
import UserSettings from '../UserSettings/UserSettingsContainer';
import VerifyEmail from '../VerifyEmail/VerifyEmail';
import TestView from '../View/test';
import { EULA } from './EULA';
import style from './index.module.scss';
import { AppProps, AppState } from './index.types';

const brandingAPIs = new BrandingAPIs();
const configApi = new ConfigApi();
const eulaSeenKey = 'vimana_lite.eula.seen';

export default class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    let seenEula = false;

    try {
      seenEula = !!localStorage.getItem(eulaSeenKey);
    } catch (e) {
      // in case of issues, prioritize user experience
      seenEula = true;
    }

    this.state = {
      seenEula,
    };
  }

  componentDidMount() {
    const { checkLogin, location, user } = this.props;
    const qs = queryString.parse(location.search);

    if (!undefinedOrNull(qs.partnerId)) {
      setFavicon(qs.partnerId);
    } else {
      setFavicon('default');
    }

    analytics.init();

    checkLogin();
    this.checkBranding();

    if (user) {
      analytics.identify(user.email);
    }
  }

  componentDidUpdate(prevProps: AppProps) {
    const { runCoachMarks, steps, user } = this.props;

    if (!_.isEqual(steps, prevProps.steps) && steps.length !== 0) {
      runCoachMarks();
    }

    if (!_.isEqual(user, prevProps.user) && user) {
      analytics.identify(user.email);
      this.updateConfig();
    }
  }

  updateConfig = async () => {
    const config: Partial<AppConfig> = await configApi
      .getAppConfig()
      .catch((e) => {
        console.error(
          'There was an error while trying to fetch app config.',
          e
        );

        return {};
      });

    Config.updateConfig(config);
  };

  checkBranding() {
    brandingAPIs
      .checkDefaultBranding()
      .then((res) => {
        const { isEnabled, data, error } = res;

        if (error) {
          console.error(error);
        }

        if (isEnabled && data && data.name) {
          setTitle(data.name);
        } else {
          setTitle('Vimana 3.0');
        }
      })
      .catch((err) => {
        console.error('There was an error while trying to update title.', err);
      });
  }

  closeModalAndRedirect = () => {
    const { hideSnackbar } = this.props;

    window.location.replace('/');
    hideSnackbar();
  };

  renderLogin() {
    const { location } = this.props;
    const qs = queryString.parse(location.search);

    if (location.pathname.startsWith('/reset')) {
      return (
        <Route
          exact
          path="/reset"
          render={({ location, history, match }) => {
            const qs = queryString.parse(location.search);

            return (
              <ResetPassword
                code={qs.code as string | undefined}
                history={history}
                match={match}
                location={location}
              />
            );
          }}
        />
      );
    }

    return <Login partnerId={qs.partnerId} />;
  }

  isUnprotectedPath() {
    const { location } = this.props;

    return (
      location &&
      location.pathname &&
      (location.pathname.startsWith('/signup/') ||
        location.pathname.startsWith('/share/') ||
        location.pathname.startsWith('/verifyEmail/') ||
        location.pathname.startsWith('/register') ||
        location.pathname.startsWith('/demo'))
    );
  }

  checkAuth() {
    const { user, location } = this.props;

    // users with project_admin access level
    const strictlyAdminPaths = [
      '/archivedProjects',
      '/project/:projectId/edit',
      '/project/:projectId/manageML',
      '/project/:projectId/aoi/:aoiId/edit',
      '/project/:projectId/manageUsers',
      '/project/:projectId/createAOI',
    ];

    // users with project_admin, project_viewer, read_only and gis access level
    const normalUserPaths = [
      '/project/:projectId/aoi/:aoiId/issues/:issueId',
      '/project/:projectId/aoi/:aoiId/issues',
      '/project/:projectId/issues/:issueId',
      '/project/:projectId/issues',
      '/project/:projectId/aoi/:aoiId',
      VIEW_PATH,
    ];

    // users that have access to project with any access role
    const openPaths = ['/project/:projectId'];

    const matchedPath = matchPath(location.pathname, {
      path: strictlyAdminPaths,
      exact: true,
      strict: false,
    });

    const matchedNormalPath = matchPath(location.pathname, {
      path: normalUserPaths,
      exact: true,
      strict: false,
    });

    const matchedOpenPath = matchPath(location.pathname, {
      path: openPaths,
      exact: true,
      strict: false,
    });

    if (!undefinedOrNull(matchedPath)) {
      return showProjectAdminLinks(
        extractProjectIdFromURL(location.pathname),
        user
      );
    }

    if (!undefinedOrNull(matchedNormalPath)) {
      return shouldShowPage(
        extractProjectIdFromURL(location.pathname),
        ['project_admin', 'project_viewer', 'read_only'],
        user
      );
    }

    if (!undefinedOrNull(matchedOpenPath)) {
      return shouldShowPage(
        extractProjectIdFromURL(location.pathname),
        ['project_admin', 'project_viewer', 'read_only', 'field_staff'],
        user
      );
    }

    return true;
  }

  isFullScreenPath() {
    const { location } = this.props;
    const whiteListPath = [
      VIEW_PATH,
      SHARE_PATH,
      '/project/:projectId/aoi/:aoiId/gcpmarking/session/:sessionId/gcp/:gcpId',
      '/project/:projectId/aoi/:aoiId/InspectionUpload/edit/:uploadId/:featureAction?',
      '/quickstart',
    ];

    const match = whiteListPath.filter((a) =>
      matchPath(location.pathname, {
        path: a,
        exact: true,
        strict: false,
      })
    );

    return match.length > 0;
  }

  renderUserSettings() {
    const { user } = this.props;

    if (user) {
      return <UserSettings />;
    }

    return <></>;
  }

  renderOrganisationSettings() {
    const { user } = this.props;

    if (user) {
      return <OrganisationSettings />;
    }

    return <></>;
  }

  RenderLoading() {
    return <SkeletonLoader size={3} position="fixed" />;
  }

  RenderEula = () => {
    const { seenEula } = this.state;

    return (
      isVimanaLite() &&
      !seenEula && (
        <Modal
          visible={!seenEula}
          destroyOnClose
          closable={false}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                this.setState({ seenEula: true }, () => {
                  try {
                    localStorage.setItem(eulaSeenKey, 'true');
                  } catch (e) {
                    // ignore
                  }
                });
              }}
            >
              I Agree
            </Button>,
          ]}
        >
          <EULA />
        </Modal>
      )
    );
  };

  render() {
    const {
      authenticated,
      loading,
      user,
      share,
      location,
      authorized,
      unAuthorized,
      history,
      match,
    } = this.props;

    const qs = queryString.parse(location.search);
    const isFullScreenPath = this.isFullScreenPath();

    const language =
      (navigator.languages && navigator.languages[0]) || navigator.language;

    if (loading) {
      return this.RenderLoading();
    }

    if (!authenticated && !this.isUnprotectedPath()) {
      return this.renderLogin();
    }

    if (location && location.pathname) {
      ReactGA.pageview(location.pathname);
    }

    if (!this.checkAuth()) {
      unAuthorized();
    }

    if (location && location.pathname.startsWith('/signup/')) {
      return (
        <Route
          exact
          path="/signup/:code"
          render={({ match, location }) => {
            const qs = queryString.parse(location.search);

            return (
              <Signup
                {...match.params}
                {...qs}
                authenticated={authenticated}
                partnerId={qs.partnerId}
              />
            );
          }}
        />
      );
    }

    if (location && location.pathname.startsWith('/verifyEmail/')) {
      return (
        <Route
          path="/verifyEmail/:code"
          render={({ match, location }) => {
            const qs = queryString.parse(location.search);
            const partnerId = !qs.partnerId
              ? undefined
              : (qs.partnerId as string);
            // TODO: handle situation where email is not provided
            const email = !qs.email ? '' : (qs.email as string);

            return (
              <VerifyEmail
                {...match.params}
                {...qs}
                authenticated={authenticated}
                partnerId={partnerId}
                email={email}
              />
            );
          }}
        />
      );
    }

    if (location && location.pathname.startsWith('/register')) {
      return (
        <Route
          path="/register"
          render={({ match, location }) => {
            const qs = queryString.parse(location.search);
            const partnerId = !qs.partnerId
              ? undefined
              : (qs.partnerId as string);

            return (
              <SelfSignUp
                {...match.params}
                {...qs}
                authenticated={authenticated}
                partnerId={partnerId}
              />
            );
          }}
        />
      );
    }

    if (location && location.pathname.startsWith('/quickstart')) {
      return (
        <Route
          path="/quickstart/:pathType"
          render={({ match, location }) => {
            const qs = queryString.parse(location.search);

            return (
              <Quickstart {...match.params} {...qs} partnerId={qs.partnerId} />
            );
          }}
        />
      );
    }

    return (
      <IntlProvider locale={language}>
        <React.Fragment>
          <div
            className={classnames(
              !isFullScreenPath
                ? style.nonFullScreenContainer
                : style.fullScreenContainer
            )}
          >
            <div className={style.header}>
              <Header
                user={user}
                share={share}
                partnerId={qs.partnerId as string | undefined}
                match={match}
                location={location}
                history={history}
              />
            </div>
            <div
              className={classnames({
                [style.bodyContainer]: !isFullScreenPath,
              })}
            >
              <Route
                exact
                path="/archivedProjects"
                render={() => {
                  return <ArchivedProjects />;
                }}
              />
              <Route
                exact
                path={SHARE_PATH}
                render={({ match, location }) => {
                  const qs = queryString.parse(location.search);

                  return (
                    <Share location={location} {...match.params} {...qs} />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId"
                render={({ match }) => {
                  return <Project {...match.params} user={user} />;
                }}
              />
              <Route
                exact
                path="/project/:projectId/edit"
                component={EditProjectV2}
              />
              <Route
                exact
                path="/project/:projectId/manageML"
                render={({ match }) => {
                  return <ManageProjectML {...match.params} />;
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId"
                component={Aoi}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/edit"
                render={({ match, history }) => {
                  return (
                    <EditAoi {...match.params} history={history} user={user} />
                  );
                }}
              />
              <Route
                exact
                path={VIEW_PATH}
                render={({ match, location, history }) => {
                  const qs = queryString.parse(location.search);

                  return (
                    <TestView
                      location={location}
                      match={match}
                      {...match.params}
                      gotoGuid={qs.gotoGuid}
                      {...qs}
                      history={history}
                    />
                  );
                }}
              />
              <Route
                path="/project/:projectId/aoi/:aoiId/sandbox"
                render={({ match, location, history }) => {
                  const qs = queryString.parse(location.search);

                  return (
                    <UserSandbox history={history} {...match.params} {...qs} />
                  );
                }}
              />
              <Route
                exact
                path={[
                  '/project/:projectId/issues',
                  '/project/:projectId/issues/:issueId',
                  '/project/:projectId/aoi/:aoiId/issues',
                  '/project/:projectId/aoi/:aoiId/issues/:issueId',
                ]}
                render={({ match, location, history }) => {
                  const qs = queryString.parse(location.search);

                  return (
                    <React.Fragment>
                      <Issues
                        match={match}
                        aoiId={
                          (match.params as { aoiId?: string }).aoiId ||
                          undefined
                        }
                        issueId={(match.params as { issueId: string }).issueId}
                        projectId={
                          (match.params as { projectId: string }).projectId
                        }
                        history={history}
                        queryParams={qs}
                        location={location}
                      />
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={AOI_LISTING_PAGE_PATH}
                render={({ match, history, location }) => {
                  return (
                    <AoiData
                      match={match}
                      history={history}
                      location={location}
                      user={user}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/elevationDifference/new/:featureAction?"
                render={({ match, history }) => {
                  return (
                    <ElevationDifferenceCrud
                      match={match}
                      location={location}
                      actionType="new"
                      history={history}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/elevationDifference/edit/:elevationDifferenceId/:featureAction?"
                render={({ match, history }) => {
                  return (
                    <ElevationDifferenceCrud
                      match={match}
                      location={location}
                      actionType="edit"
                      history={history}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/TerrainMaps/new/:featureAction?"
                render={({ match, history }) => {
                  return (
                    <TerrainMaps
                      match={match}
                      location={location}
                      actionType="new"
                      history={history}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/TerrainMaps/edit/:dtmId/:featureAction?"
                render={({ match, history }) => {
                  return (
                    <TerrainMaps
                      location={location}
                      match={match}
                      actionType="edit"
                      history={history}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/ContourMaps/new"
                render={({ match, history, location }) => {
                  return (
                    <ContourMaps
                      match={match}
                      history={history}
                      location={location}
                      actionType="new"
                    />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/ContourMaps/edit/:contourId"
                render={({ match, history, location }) => {
                  return (
                    <ContourMaps
                      match={match}
                      history={history}
                      location={location}
                      actionType="edit"
                    />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/CutAndFillReport/new"
                render={({ match, history, location }) => {
                  return (
                    <CutAndFillReport
                      match={match}
                      history={history}
                      location={location}
                      actionType="new"
                    />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/CutAndFillReport/edit/:reportId"
                render={({ match, history, location }) => {
                  return (
                    <CutAndFillReport
                      match={match}
                      history={history}
                      location={location}
                      actionType="edit"
                    />
                  );
                }}
              />
              <Route
                exact
                path={DOWNLOADS_LIST_WITH_AOI_PATH}
                render={({ match, history, location }) => {
                  return (
                    <Downloads
                      match={match}
                      location={location}
                      history={history}
                    />
                  );
                }}
              />
              <Route
                exact
                path={DOWNLOADS_LIST_WITHOUT_AOI_PATH}
                render={({ match, history, location }) => {
                  return (
                    <Downloads
                      match={match}
                      location={location}
                      history={history}
                    />
                  );
                }}
              />
              <Route
                exact
                path={PROJECT_VIDEOS_NEW_PAGE_PATH}
                render={({ match, history }) => {
                  return (
                    <ProjectVideos
                      match={match}
                      history={history}
                      location={location}
                      actionType="new"
                    />
                  );
                }}
              />
              <Route
                exact
                path={PROJECT_VIDEOS_EDIT_PAGE_PATH}
                render={({ match, history, location }) => {
                  return (
                    <ProjectVideos
                      match={match}
                      history={history}
                      location={location}
                      actionType="edit"
                    />
                  );
                }}
              />
              <Route
                exact
                path={INTERIOR360_VIEWS_NEW_PATH}
                render={({ match, history }) => {
                  const { projectId, aoiId } = match.params;

                  return (
                    <Interior360Editor
                      action="new"
                      projectId={projectId}
                      aoiId={aoiId}
                      history={history}
                    />
                  );
                }}
              />
              <Route
                exact
                path={INTERIOR360_VIEWS_EDIT_PATH}
                render={({ match, history }) => {
                  const { projectId, aoiId, viewId } = match.params;

                  return (
                    <Interior360Editor
                      action="edit"
                      projectId={projectId}
                      aoiId={aoiId}
                      viewId={viewId}
                      history={history}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/missions/:missionId"
                render={({ match, history }) => {
                  return (
                    <Mission {...match.params} user={user} history={history} />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/missions/:missionId/diagnostics"
                render={({ match, history, location }) => {
                  return (
                    <Diagnostics
                      match={match}
                      location={location}
                      user={user}
                      history={history}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/CreateMission"
                render={({ match, history }) => {
                  return <Mission {...match.params} history={history} />;
                }}
              />
              <Route exact path="/createProject" component={CreateProjectV2} />
              <Route
                exact
                path="/project/:projectId/createAOI"
                render={({ match }) => {
                  return <CreateAOI {...match.params} />;
                }}
              />
              <Route
                exact
                path="/project/:projectId/manageUsers"
                render={({ match }) => {
                  return <ManageUsers {...match.params} />;
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/gcpmarking/session/:sessionId?"
                render={({ match, history, location }) => {
                  return (
                    <GcpMarking
                      match={match}
                      history={history}
                      location={location}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/gcpmarking/session/:sessionId/gcp/:gcpId"
                render={({ match, history, location }) => {
                  return (
                    <GcpMarking
                      match={match}
                      history={history}
                      location={location}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/project/:projectId/aoi/:aoiId/gcpmarking/session/:sessionId/gcp/:gcpId/action/:gcpActionType"
                render={({ match, history, location }) => {
                  return (
                    <GcpMarking
                      match={match}
                      history={history}
                      location={location}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/payments"
                render={({ match, location, history }) => {
                  return (
                    <Payments
                      match={match}
                      history={history}
                      location={location}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/payments/history"
                render={({ match, location, history }) => {
                  return (
                    <PaymentsHistory
                      match={match}
                      history={history}
                      location={location}
                    />
                  );
                }}
              />
              <Route exact path="/" component={ProjectList} />
            </div>
            {this.renderUserSettings()}
            {this.renderOrganisationSettings()}
          </div>
          <LoadingBar className={style.loader} />
          {!authorized && (
            <ModalNotification
              notificationTitle="Access Error"
              notificationBody="You do not have access to this project. Redirecting to dashboard"
              shownotificationModal
              zIndex={10010}
              handleModalClose={() => this.closeModalAndRedirect()}
              handleModalCancel={() => this.closeModalAndRedirect()}
            />
          )}
          <ConfirmModal />
          <Snackbar />
          <Coachmark />
        </React.Fragment>
      </IntlProvider>
    );
  }
}
