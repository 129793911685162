import * as React from 'react';
import { Modal, Select } from 'antd';
import style from './EditUsers.module.scss';
import { Button } from '../Button';

interface IProps {
  userRole: any;
  handleEditModal: any;
  UpdateUser: any;
}

interface IState {
  isEditing: boolean;
  newRole?: string;
  role: any[];
}

const { Option } = Select;

export default class EditUserRole extends React.Component<IProps, IState> {
  public constructor(props: any) {
    super(props);
    this.state = {
      newRole: '',
      isEditing: false,
      role: [
        { name: 'Project Admin', id: 'project_admin' },
        { name: 'Project Viewer', id: 'project_viewer' },
        { name: 'Project Read Only', id: 'read_only' },
        { name: 'Field Staff', id: 'field_staff' },
      ],
    };
  }

  public componentDidMount(): void {
    const { userRole } = this.props;
    const prevrole = userRole.role;

    this.setState({ newRole: prevrole });
  }

  private setRole = (value: string) => {
    this.setState({ newRole: value });
  };

  private handelEdit = () => {
    const { handleEditModal, userRole, UpdateUser } = this.props;
    const { newRole } = this.state;
    const { email } = userRole;
    const role = newRole;

    this.setState({ isEditing: false });
    handleEditModal();
    UpdateUser(email, role);
  };

  private handleEditModalClose = () => {
    this.setState({ isEditing: false });
  };

  private updateUser = () => {
    this.setState({ isEditing: true });
  };

  public render(): React.ReactNode {
    const { userRole } = this.props;
    const { isEditing, newRole, role } = this.state;

    return (
      <div>
        <div className={style.container}>
          <table className={style.table}>
            <tbody>
              <tr>
                <td>
                  Name :&nbsp;
                  <span className={style.paratext}>
                    {userRole.name !== ' ' ? userRole.name : 'NA (Yet To Join)'}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  Email :&nbsp;
                  <span className={style.paratext}>{userRole.email}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <form>
                    <div>
                      <label className={style.roleLabel}>Role : </label>
                      <div className={style.selectContainer}>
                        <Select
                          className={style.select}
                          value={newRole || userRole.role}
                          onChange={this.setRole}
                        >
                          {role.map((role: any) => {
                            return (
                              <Option value={role.id} key={role.id}>
                                {role.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </form>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={style.controls}>
          <Button onClick={this.updateUser} text="Update" />
        </div>
        <Modal
          title="Update Confirmation"
          centered
          footer={null}
          visible={isEditing}
          onCancel={this.handleEditModalClose}
          maskClosable={false}
        >
          <p className={style.headertext}>
            Are you sure that you want to update this user&apos;s role?
          </p>
          <div className={style.btndeleteDiv}>
            <Button
              className={style.btndelete}
              onClick={this.handleEditModalClose}
              text="No"
            />
            <Button
              className={style.btndelete}
              onClick={this.handelEdit}
              text="Yes"
            />
          </div>
        </Modal>
      </div>
    );
  }
}
