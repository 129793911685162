import * as React from 'react';
import flattenChildren from 'react-flatten-children';
import _ from 'lodash';
import ImagesApis from '../../../../api/images';
import { BASE_CAPI_URL } from '../../../../constants/urls';
import { undefinedOrNull } from '../../../../utils/functs';
import { ViewConfig } from '../../index.types';

declare const pannellum: any;

export interface PanoramaImage {
  id: string;
  default?: boolean;
  hidden?: boolean;
  tiled?: boolean;
  maxLevels?: number;
  tileSize?: number;
  cubeSize?: string;
  hotspots?: any[];
  imageYaw?: number;
  panoYaw?: number;
  latitude?: number;
  longitude?: number;
}

export interface PanoramaViewState {
  yaw: number;
  pitch: number;
  hfov: number;
}

interface IProps {
  viewId: string;
  selectedImage: string;
  images: PanoramaImage[];
  className?: string;
  shareId?: string;
  initialView?: PanoramaViewState;
  onViewStateChange: (state: PanoramaViewState) => void;
  onSceneChange?: (newSceneId: string) => void;
  disableControls?: boolean;
  lockView?: boolean;
}

interface IState {
  elementId: string;
  viewer?: any;
  pannellumConfig?: any;
}

export default class PannellumRender extends React.Component<IProps, IState> {
  private imageAPI: ImagesApis = new ImagesApis();

  constructor(props: IProps) {
    super(props);

    this.state = {
      elementId: `pannellum-${Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(2, 10)}`,
    };
  }

  public componentDidMount() {
    const { viewId, selectedImage, images, initialView, shareId } = this.props;

    this.renderViewer(viewId, selectedImage, images, initialView, shareId).then(
      ({ viewer, config }: any) => {
        this.setState({ viewer, pannellumConfig: config }, () => {
          if (initialView) {
            this.setViewerState(initialView);
          }
        });
      }
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const {
      viewId: nextViewId,
      selectedImage: nextSelectedImage,
      images: nextImages,
      initialView: nextViewState,
      shareId,
      lockView,
    } = nextProps;
    const {
      viewId,
      selectedImage,
      images,
      initialView: prevInitialView,
    } = this.props;

    if (viewId !== nextViewId || !_.isEqual(images, nextImages)) {
      const viewer = this.getViewer();

      // remove all hotspots first
      if (viewer) {
        // eslint-disable-next-line no-restricted-syntax
        for (const img of images || []) {
          const hotspots = this.getHotspots(img, images || []);

          // eslint-disable-next-line no-restricted-syntax
          for (const hs of hotspots) {
            try {
              viewer.removeHotSpot(hs.id);
            } catch (err) {
              console.error(err, hs);
            }
          }
        }
      }

      this.renderViewer(
        nextViewId,
        nextSelectedImage,
        nextImages,
        nextViewState,
        shareId
      ).then(({ viewer, config }: any) => {
        this.setState({ viewer, pannellumConfig: config });
      });
    } else if (selectedImage !== nextSelectedImage) {
      const { viewer, pannellumConfig } = this.state;
      const { onSceneChange } = this.props;

      if (nextViewState && pannellumConfig) {
        // update config and update yaw,pitch,zoom etc.
        const scene = (pannellumConfig?.scenes || {})[nextSelectedImage];

        if (scene) {
          scene.yaw = nextViewState.yaw;
          scene.pitch = nextViewState.pitch;
          scene.hfov = nextViewState.hfov;
        }
      }

      if (viewer) {
        viewer.loadScene(nextSelectedImage);
        if (onSceneChange) {
          onSceneChange(viewer.getScene());
        }
      }
    } else if (nextViewState !== prevInitialView && nextViewState && lockView) {
      this.setViewerState(nextViewState);
    }
  }

  public componentWillUnmount() {
    const { viewer } = this.state;

    if (viewer) {
      try {
        viewer.destroy();
      } catch (e) {
        console.error(e);
      }
    }
  }

  public getViewer(): any {
    const { viewer } = this.state;

    return viewer;
  }

  private panoStateChanged = (e: PanoramaViewState) => {
    const { images, selectedImage, onViewStateChange } = this.props;
    const image = (images || []).find((i) => i.id === selectedImage);

    let { yaw } = e;
    const { pitch, hfov } = e;

    // update the target yaw/pitch in all the hotspots in view
    if (image) {
      const hotspots = this.getHotspots(image, images);

      // eslint-disable-next-line no-restricted-syntax
      for (const hs of hotspots) {
        hs.targetYaw = yaw;
        hs.targetPitch = pitch;
        hs.targetHfov = hfov;
      }
    }

    yaw += this.getDeltaYaw();
    yaw = ((yaw % 360.0) + 360.0) % 360.0;
    onViewStateChange({ yaw, pitch, hfov });
  };

  private renderViewer(
    viewId: string,
    selectedImage: string,
    images: PanoramaImage[],
    viewState: PanoramaViewState | undefined = undefined,
    shareId: string | undefined = undefined
  ) {
    const { elementId } = this.state;
    const node = document.getElementById(elementId);

    return this.getConfig(
      viewId,
      selectedImage,
      images,
      viewState,
      shareId
    ).then((config) => {
      const viewer = pannellum.viewer(node, config);

      viewer.on('animatefinished', this.panoStateChanged);
      viewer.on('scenechange', () => {
        const { onSceneChange } = this.props;

        if (onSceneChange) {
          onSceneChange(viewer.getScene());
        }
      });

      return { viewer, config };
    });
  }

  private getConfig(
    viewId: string,
    selectedImage: string,
    images: PanoramaImage[],
    viewState: PanoramaViewState | undefined = undefined,
    shareId: string | undefined = undefined
  ): Promise<any> {
    return Promise.all(
      images.map((i) =>
        this.getSinglePanoConfig(viewId, i, images, viewState, shareId)
      )
    ).then((ps) => {
      const scenes = {};

      ps.forEach((cfg) => {
        scenes[cfg.id] = { ...cfg };
      });

      return {
        default: {
          firstScene: selectedImage,
          sceneFadeDuration: 5000,
        },
        scenes,
      };
    });
  }

  private getSinglePanoConfig(
    viewId: string,
    image: PanoramaImage,
    images: PanoramaImage[],
    viewState: PanoramaViewState | undefined = undefined,
    shareId: string | undefined = undefined
  ): Promise<any> {
    let resolveFn: any;
    const promise = new Promise((resolve) => {
      resolveFn = resolve;
    });
    const { yaw, pitch, hfov } = viewState || { yaw: 0, pitch: -45, hfov: 100 };
    const imageUrl = `${BASE_CAPI_URL}/v1/images/views/${viewId}/images/${image.id}`;
    const { disableControls } = this.props;
    // only include hotspots to images actually in the list
    const hotSpots = disableControls ? [] : this.getHotspots(image, images);

    const imageYaw = image.imageYaw || 0;
    const panoYaw = image.panoYaw || 0;

    const imgConfig: any = {
      id: image.id,
      autoLoad: true,
      crossOrigin: 'use-credentials',
      compass: !disableControls,
      showControls: !disableControls,
      northOffset: imageYaw - panoYaw,
      yaw,
      pitch,
      hfov,
      hotSpots,
      friction: 1.0,
    };

    this.imageAPI
      .getImagePartData(viewId, image.id, 'config.json')
      .then((res) => {
        const { data } = res;

        if (data?.type === 'multires') {
          Object.assign(imgConfig, {
            type: 'multires',
            multiRes: {
              ...(data?.multiRes || {}),
              crossOrigin: 'use-credentials',
              basePath: `${imageUrl}/tiles`,
              path: `/%l/%s%y_%x.jpg${
                shareId ? `?vimana_share_token=${shareId}&dummy=` : '?dummy='
              }`,
              fallbackPath: `/fallback/%s.jpg${
                shareId ? `?vimana_share_token=${shareId}&dummy=` : '?dummy='
              }`,
              extension: '',
            },
          });
        } else {
          const shareUrl = shareId
            ? `?vimana_share_token=${shareId}&dummy=`
            : '?dummy=';

          Object.assign(imgConfig, {
            type: 'equirectangular',
            panorama: `${imageUrl}${shareUrl}`,
          });
        }

        resolveFn(imgConfig);
      })
      .catch((_) => {
        const shareUrl = shareId
          ? `?vimana_share_token=${shareId}&dummy=`
          : '?dummy=';

        Object.assign(imgConfig, {
          type: 'equirectangular',
          panorama: `${imageUrl}${shareUrl}`,
        });
        resolveFn(imgConfig);
      });

    return promise;
  }

  private getHotspots(
    image: PanoramaImage,
    images: PanoramaImage[],
    viewState: PanoramaViewState | undefined = undefined
  ) {
    // only include hotspots to images actually in the list
    const hotSpots = (image.hotspots || [])
      .filter((hs: any) => {
        return !!images
          .filter((i) => !i.hidden)
          .find((i) => i.id === hs?.sceneId);
      })
      .map((hs: any, idx: any) => {
        // eslint-disable-next-line no-param-reassign
        hs.id = `nav-hotspot-${image.id}-${idx}`;
        const { yaw, pitch, hfov } = viewState || {
          yaw: undefinedOrNull(hs.targetYaw) ? 0 : hs.targetYaw,
          pitch: undefinedOrNull(hs.targetPitch) ? -45 : hs.targetPitch,
          hfov: undefinedOrNull(hs.targetHfov) ? 100 : hs.targetHfov,
        };

        // eslint-disable-next-line no-param-reassign
        hs.targetYaw = yaw;
        // eslint-disable-next-line no-param-reassign
        hs.targetPitch = pitch;
        // eslint-disable-next-line no-param-reassign
        hs.targetHfov = hfov;

        return hs;
      });

    return hotSpots;
  }

  private getDeltaYaw() {
    const { selectedImage, images } = this.props;
    const image = (images || []).find((i) => i.id === selectedImage);

    if (!image) return 0;

    return (image.imageYaw ?? 0) - (image.panoYaw ?? 0);
  }

  private setViewerState(vs: PanoramaViewState) {
    const viewer = this.getViewer();

    if (!viewer) return;

    let { yaw, pitch, hfov } = vs;

    yaw = (yaw || 0) - this.getDeltaYaw();
    pitch = pitch || -45;
    hfov = hfov || 100;

    viewer.setYaw(yaw);
    viewer.setPitch(pitch);
    viewer.setHfov(hfov);
  }

  private getViewerState(): ViewConfig | undefined {
    const viewer = this.getViewer();

    if (!viewer) return;

    return {
      longitude: viewer.getYaw() + this.getDeltaYaw(),
      latitude: viewer.getPitch(),
      zoom: viewer.getHfov(),
    };
  }

  private getScreenshot = (): Promise<string | null> => {
    const viewer = this.getViewer();

    if (viewer) {
      return new Promise((resolve) => {
        const dataUrl = viewer
          .getRenderer()
          .render(
            (viewer.getPitch() / 180) * Math.PI,
            (viewer.getYaw() / 180) * Math.PI,
            (viewer.getHfov() / 180) * Math.PI,
            { returnImage: true }
          );

        resolve(dataUrl);
      });
    }

    return Promise.resolve(null);
  };

  public render() {
    const { children, className } = this.props;
    const { viewer, elementId } = this.state;

    const childrenWithProps = React.Children.map(
      flattenChildren(children),
      (child) => {
        if (React.isValidElement(child as any)) {
          return React.cloneElement(child as any, { viewer });
        }

        return child;
      }
    );

    return (
      <div
        id={elementId}
        className={className}
        style={{ width: '100%', height: '100%' }}
      >
        {childrenWithProps}
      </div>
    );
  }
}
