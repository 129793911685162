import { APP_BASE_URL } from '../constants/urls';
import { GenericApisReturnPromiseTypes } from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import ApiBase from './ApiBase';

export default class CostApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(`${APP_BASE_URL}/costapi/api`);

    this._baseUrl = `/v1`;
  }

  public getSessionCost(sessionId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/cost/sessions/${sessionId}`)
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to get session cost`,
        });
      });
  }
}
