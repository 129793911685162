import { Tooltip } from 'antd';
import * as React from 'react';
import DownloadAPIs from '../../../../api/download';
import { humanFileSize, undefinedOrNull } from '../../../../utils/functs';
import * as ControlBox from '../../../ControlBox/ControlBox';
import { Spinner } from '../../../Spinner/Spinner';
import { DownloadItem } from '../../../ViewDataManager/index.types';
import { DownloadsControlProps } from '../index.types';
import style from './index.module.scss';

const DOWNLOAD_NAME_MAPPING = {
  DEM_COLOR_GLOBAL_TIF: 'Global Colorized Elevation Profile',
  ORTHOMOSAIC_GLOBAL_TIF: 'Global Orthomosaic',
  DEM_RAW_GLOBAL_TIF: 'Global Elevation Profile',
  DEM_COLOR_LOCAL_TIF: 'Colorized Elevation Profile',
  ORTHOMOSAIC_LOCAL_TIF: 'Orthomosaic',
  DEM_RAW_LOCAL_TIF: 'Elevation Profile',
};

interface IState {
  itemLoading: boolean;
}

export default class DownloadsControlBox extends React.Component<
  DownloadsControlProps,
  IState
> {
  private downloadAPI = new DownloadAPIs();

  constructor(props: DownloadsControlProps) {
    super(props);

    this.state = { itemLoading: false };
  }

  public renderHeader = (): JSX.Element => {
    const { onClose } = this.props;

    return (
      <React.Fragment>
        <ControlBox.Title title="Download" />
        <ControlBox.Icon
          onClick={() => {
            onClose();
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  protected renderItem = ({
    id,
    name,
    sizeBytes,
    documentType,
  }: DownloadItem) => {
    const { view, userRole } = this.props;
    const finalData = (
      <div
        onClick={() => {
          // eslint-disable-next-line no-unused-expressions
          userRole !== 'read_only'
            ? this.downloadURL(view.id, id, documentType)
            : null;
        }}
        className={style.report_item}
      >
        <div>
          <i className="fa fa-download" />
        </div>
        <div className={style.meta_info}>
          <div>{DOWNLOAD_NAME_MAPPING[documentType] || name}</div>
          <div>
            <span>{`Size: ${humanFileSize(sizeBytes)}`}</span>
          </div>
        </div>
      </div>
    );

    if (userRole === 'read_only') {
      return (
        <Tooltip
          placement="right"
          title="The use of this feature requires change in the user role . Please contact project admin if you need that."
        >
          {finalData}
        </Tooltip>
      );
    }

    return finalData;
  };

  protected downloadURL = (
    viewId: string,
    id: string,
    documentType: string
  ) => {
    this.setLoadingItem(true);

    this.downloadAPI.getURL(viewId, id, documentType).then((urlData) => {
      this.setLoadingItem(false);
      if (undefinedOrNull(urlData)) {
        return;
      }

      window.location.href = urlData;
    });
  };

  protected setLoadingItem = (value: boolean): void => {
    this.setState({
      itemLoading: value,
    });
  };

  public render(): React.ReactNode {
    const { downloads } = this.props;
    const { itemLoading } = this.state;

    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={this.renderHeader}
      >
        {itemLoading && (
          <div className={style.spinner}>
            <div className={style.spinnerInnerWrapper}>
              <Spinner text="Downloading file..." />
            </div>
          </div>
        )}

        {downloads.map((download) => this.renderItem(download))}
      </ControlBox.Wrapper>
    );
  }
}
