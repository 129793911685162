import MeasurementApi from '../../api/Measurement';
import { VolumeType } from '../../components/VolumeCalculator/VolumeCalculator';

const measurementApi = new MeasurementApi();

const volumeFetched = (data: any): {} => {
  return {
    type: 'VOLUME_FETCH_SUCCESS',
    payload: data,
  };
};

const volumeFetchFailed = (payload: any): {} => {
  return {
    type: 'VOLUME_FETCH_FAILED',
    payload,
  };
};

const volumeFetchStarted = (): {} => {
  return {
    type: 'VOLUME_FETCH_STARTED',
  };
};

export const elevationFetched = (data: any[]): {} => {
  return {
    type: 'ELEVATION_FETCH_SUCCESS',
    payload: data,
  };
};

export const elevationFetchFailed = (payload: any): {} => {
  return {
    type: 'ELEVATION_FETCH_FAILED',
    payload,
  };
};

export const elevationFetchStarted = (): {} => {
  return {
    type: 'ELEVATION_FETCH_STARTED',
  };
};

const updatePlaneElevation = (elevation: number | null): {} => {
  return {
    type: 'PLANE_ELEVATION_UPDATE',
    payload: elevation,
  };
};

const resetPlaneElevation = (): {} => {
  return {
    type: 'PLANE_ELEVATION_RESET',
  };
};

const resetVolume = (): {} => {
  return {
    type: 'VOLUME_VALUE_RESET',
  };
};

const setVolumeViewId = (viewId: string): {} => {
  return {
    type: 'SET_VOLUME_VIEW_ID',
    payload: viewId,
  };
};

const setVolumeElevationList = (data: any): {} => {
  return {
    type: 'SET_VOLUME_ELEVATION_LIST',
    payload: data,
  };
};

const setVolumeViewUnits = (viewUnits: string): {} => {
  return {
    type: 'SET_VOLUME_VIEW_UNITS',
    payload: viewUnits,
  };
};

const _setVolumeType = (volumeType: VolumeType): {} => {
  return {
    type: 'SET_VOLUME_TYPE',
    payload: volumeType,
  };
};

const _resetVolumeType = (): {} => {
  return {
    type: 'VOLUME_TYPE_RESET',
  };
};

const clearVolumeViewData = (): {} => {
  return {
    type: 'CLEAR_VOLUME_VIEW_DATA',
  };
};

export const fetchVolume =
  (
    viewId: string,
    points: number[][],
    elevation: number,
    volumeType: string
  ): any =>
  (dispatch: any) => {
    dispatch(volumeFetchStarted());
    measurementApi
      .fetchVolume(viewId, points, elevation, volumeType)
      .then((response) => {
        dispatch(volumeFetched(response.data));
      })
      .catch((err) => {
        let result = { message: 'Unknown error' };

        if (!err.response) {
          result = { message: 'Network error' };
        }

        if (err?.response?.data) {
          result = err?.response?.data;
        }

        dispatch(volumeFetchFailed(result));
      });
  };

export const fetchElevation =
  (viewId: string, points: number[][]): any =>
  (dispatch: any) => {
    dispatch(elevationFetchStarted());
    measurementApi
      .fetchElevation(viewId, points)
      .then((response) => dispatch(elevationFetched(response.data)))
      .catch(({ response }) => {
        dispatch(
          elevationFetchFailed(
            (response && response.data) || { message: 'Something went wrong' }
          )
        );
      });
  };

export const setElevationValue =
  (elevation: number | null): any =>
  (dispatch: any) => {
    dispatch(updatePlaneElevation(elevation));
  };

export const resetElevationValue = (): any => (dispatch: any) => {
  dispatch(resetPlaneElevation());
};

export const resetVolumeValues = (): any => (dispatch: any) => {
  dispatch(resetVolume());
};

export const updateVolumeViewId =
  (viewId: string): any =>
  (dispatch: any) => {
    dispatch(setVolumeViewId(viewId));
  };

export const updateVolumeElevationList =
  (data: any): any =>
  (dispatch: any) => {
    dispatch(setVolumeElevationList(data));
  };

export const updateVolumeViewUnits =
  (viewUnits: string): any =>
  (dispatch: any) => {
    dispatch(setVolumeViewUnits(viewUnits));
  };

export const setVolumeType =
  (volumeType: VolumeType): any =>
  (dispatch: any) => {
    dispatch(_setVolumeType(volumeType));
  };

export const resetVolumeType = (): any => (dispatch: any) => {
  dispatch(_resetVolumeType());
};

export const resetVolumeViewData = (): any => (dispatch: any) => {
  dispatch(clearVolumeViewData());
};
