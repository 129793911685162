// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DrawingsListing_title__GOZRp{display:inline-block}.DrawingsListing_newDrawing__wXlNj{float:right;margin-top:10px}.DrawingsListing_titleWrapper__1Q2s1{display:inline-flex}.DrawingsListing_titleWrapper__1Q2s1 span{margin-top:5px}", "",{"version":3,"sources":["webpack://./src/components/UserSandbox/Drawings/DrawingsListing/index.module.scss"],"names":[],"mappings":"AAAA,8BACE,oBAAA,CAGF,mCACE,WAAA,CACA,eAAA,CAGF,qCACE,mBAAA,CAEA,0CACE,cAAA","sourcesContent":[".title {\n  display: inline-block;\n}\n\n.newDrawing {\n  float: right;\n  margin-top: 10px;\n}\n\n.titleWrapper {\n  display: inline-flex;\n\n  span {\n    margin-top: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "DrawingsListing_title__GOZRp",
	"newDrawing": "DrawingsListing_newDrawing__wXlNj",
	"titleWrapper": "DrawingsListing_titleWrapper__1Q2s1"
};
export default ___CSS_LOADER_EXPORT___;
