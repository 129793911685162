// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DrawControl_olTooltipContainer__iB1OJ{pointer-events:none}.DrawControl_olTooltipContainer__iB1OJ .DrawControl_olTooltip__MXLV-{position:relative;background:rgba(0,0,0,.5);border-radius:4px;color:#fff;padding:4px 8px;opacity:.7;white-space:nowrap;font-size:12px}.DrawControl_hidden__ncDms{display:none}", "",{"version":3,"sources":["webpack://./src/components/Interior360Manager/FloorMapInset/DrawControl/index.module.scss"],"names":[],"mappings":"AAAA,uCACE,mBAAA,CAEA,qEACE,iBAAA,CACA,yBAAA,CACA,iBAAA,CACA,UAAA,CACA,eAAA,CACA,UAAA,CACA,kBAAA,CACA,cAAA,CAIJ,2BACE,YAAA","sourcesContent":[".olTooltipContainer {\n  pointer-events: none;\n\n  .olTooltip {\n    position: relative;\n    background: rgba(0, 0, 0, 0.5);\n    border-radius: 4px;\n    color: white;\n    padding: 4px 8px;\n    opacity: 0.7;\n    white-space: nowrap;\n    font-size: 12px;\n  }\n}\n\n.hidden {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"olTooltipContainer": "DrawControl_olTooltipContainer__iB1OJ",
	"olTooltip": "DrawControl_olTooltip__MXLV-",
	"hidden": "DrawControl_hidden__ncDms"
};
export default ___CSS_LOADER_EXPORT___;
