import * as React from 'react';
import ProjectsAPI from 'src/api/projects';

interface IState {
  error?: any;
  projects: any[];
}

class ArchivedProjects extends React.Component<any, IState> {
  private projectsAPI = new ProjectsAPI();

  constructor(props: any) {
    super(props);
    this.state = {
      projects: [],
    };
  }

  componentDidMount(): void {
    this.projectsAPI
      .getArchivedProjects()
      .then((projects) => {
        this.setState({ projects });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render(): React.ReactNode {
    const { error, projects } = this.state;

    if (error) {
      return (
        <div>
          Could not get list of archived projects. You may not have enough
          permissions to view this page.
        </div>
      );
    }

    return (
      <div>
        <div>Archived Projects:</div>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Link to edit page (new tab)</th>
              <th>Archived At</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((p) => {
              return (
                <tr>
                  <td>
                    <a
                      href={`/project/${p.id}/edit`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {p.name}
                    </a>
                  </td>
                  <td>
                    {p.archivedAt ? (
                      <span>{new Date(p.archivedAt).toString()}</span>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ArchivedProjects;
