import React from 'react';
import {
  ViewPreviewManagerProps,
  ViewPreviewManagerState,
} from './index.types';
import ViewAPIs from '../../api/views';
import ViewDisplay from '../View';

export class ViewPreviewManager extends React.Component<
  ViewPreviewManagerProps,
  ViewPreviewManagerState
> {
  private viewsAPI: ViewAPIs = new ViewAPIs();

  constructor(props: ViewPreviewManagerProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount = () => {
    const { viewId } = this.props;

    if (viewId) {
      this.fetchDownloads(viewId);
    }
  };

  public componentDidUpdate = ({
    viewId: prevViewId,
  }: ViewPreviewManagerProps) => {
    const { viewId } = this.props;

    if (viewId !== prevViewId) {
      this.fetchDownloads(viewId);
    }
  };

  private fetchDownloads = (viewId: string) => {
    this.setState({ downloads: undefined }, () => {
      if (!viewId) return;
      this.viewsAPI.getDownloads(viewId).then((res) => {
        const { data, error } = res;

        if (error) {
          console.error(error);

          return;
        }

        if (data) {
          this.setState({ downloads: data });
        }
      });
    });
  };

  public render = () => {
    const { aoiId, projectId, viewId, viewList } = this.props;
    const { downloads } = this.state;

    return (
      <div>
        <ViewDisplay
          view={viewList?.find((v) => v.id === viewId)}
          viewId={viewId}
          projectId={projectId}
          aoiId={aoiId}
          downloads={downloads}
          controlsWhitelist={['measure', 'download']}
          history={{} as any}
          onEvent={() => {}}
        />
      </div>
    );
  };
}
