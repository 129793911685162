import autobind from 'autobind-decorator';
import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Tabs, Select } from 'antd';
import style from './index.module.scss';
import { undefinedOrNull } from '../../../utils/functs';
import BrandingAPIs from '../../../api/branding';
import OrganisationAPIs from '../../../api/organisations';
import {
  BrandingModelColorList,
  ICheckBranding,
  BrandingModel,
} from '../../../shapes/branding';
import DocumentationLink from '../../DocumentationLink';
import WhiteLabellingForm from '../../WhiteLabellingForm';
import SkeletonLoader from '../../SkeletonLoader';
import { DOCUMENTATION_URL_LIST } from '../../../constants/urls';
import {
  OrganisationSettingsPropsType,
  OrganisationSettingsStateType,
  OrganisationWhitelabelInfo,
} from './index.types';
import OrganisationUserListing from './OrganisationUserListing';
import OrganisationDemoProjects from './OrganisationDemoProjects';

const { Option } = Select;
const { TabPane } = Tabs;
const brandingApis = new BrandingAPIs();
const organisationApis = new OrganisationAPIs();

class OrganisationSettings extends React.Component<
  OrganisationSettingsPropsType,
  OrganisationSettingsStateType
> {
  public constructor(props: OrganisationSettingsPropsType) {
    super(props);

    this.state = {
      currentTabIndex: '1',
      isTextFieldsValueChanges: false,
      selectedColorList: {},
      loading: false,
      organisationList: [],
    };
  }

  public componentDidMount(): void {
    this.fetchOrganisations();
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: OrganisationSettingsPropsType
  ): void {
    const { isPasswordUpdated, showSnackbar } = nextProps;

    if (isPasswordUpdated) {
      showSnackbar({
        body: `Password has been updated successfully`,
        type: 'success',
        isModal: false,
      });
    }
  }

  private fetchOrganisations = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        organisationApis.listUserOrganisations().then(async (orgs) => {
          const orgWhitelabellingInfo: OrganisationWhitelabelInfo[] = [];
          const orgDataPromises = orgs.map(async (o) => {
            const orgInfo = o.whiteLabelingEnabled
              ? await this.fetchWhiteLabeling(o.id)
              : undefined;
            const users = await organisationApis
              .getUsersInOrganisation(o.id)
              .then((users) =>
                users.sort((a, b) => {
                  if (a.email > b.email) return 1;

                  if (a.email === b.email) return 0;

                  return -1;
                })
              )
              .catch(() => []);
            const ownedProjects = await organisationApis
              .getOwnedProjects(o.id)
              .then((projects) =>
                projects.sort((a, b) => {
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

                  if (a.name.toLowerCase() === b.name.toLowerCase()) return 0;

                  return -1;
                })
              )
              .catch(() => []);
            const demoProjects = await organisationApis
              .getDemoProjectsOfOrganisation(o.id)
              .then((demoProjects) =>
                demoProjects.sort((a, b) => {
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

                  if (a.name.toLowerCase() === b.name.toLowerCase()) return 0;

                  return -1;
                })
              )
              .catch(() => []);

            orgWhitelabellingInfo.push({
              organisation: o,
              whiteLabelingEnabled: o.whiteLabelingEnabled,
              ...orgInfo,
              users: users || [],
              ownedProjects: ownedProjects || [],
              demoProjects: demoProjects || [],
            });
          });

          await Promise.all(orgDataPromises);

          this.setState(({ selectedOrganisation }) => {
            return {
              organisationList: orgWhitelabellingInfo,
              selectedOrganisation:
                orgWhitelabellingInfo.length > 0
                  ? // preserving selectedOrganisation, if present
                    orgWhitelabellingInfo.find(
                      (o) =>
                        o.organisation.id ===
                        selectedOrganisation?.organisation.id
                    ) || orgWhitelabellingInfo[0]
                  : undefined,
              loading: false,
            };
          });
        });
      }
    );
  };

  private fetchWhiteLabeling(
    orgId: string
  ): Promise<{ brandingData?: BrandingModel }> {
    return brandingApis
      .checkBranding(orgId)
      .then((res: ICheckBranding) => {
        if (!undefinedOrNull(res.data) && res.isEnabled) {
          return {
            brandingData: { ...res.data },
          };
        }

        return {};
      })
      .catch(() => {
        return {};
      });
  }

  @autobind
  private handleUpdate(): void {
    const { form, closeOrganisationSettings, showSnackbar } = this.props;
    const { currentTabIndex, selectedColorList, selectedOrganisation } =
      this.state;

    if (!selectedOrganisation) {
      console.error('An organisation must be selected to perform update');

      return;
    }

    switch (currentTabIndex) {
      case '2':
        form.validateFields(['brandingName'], (err: any, values: any) => {
          if (!err) {
            brandingApis
              .updateBranding(selectedOrganisation.organisation.id, {
                ...selectedOrganisation.brandingData,
                name: values.brandingName,
                url: form.getFieldValue('brandingWebsite'),
                ...selectedColorList,
              })
              .then((res: any) => {
                if (!res.error) {
                  showSnackbar({
                    body: 'Update was successful',
                    type: 'success',
                    isModal: false,
                  });
                  this.setState(
                    {
                      isTextFieldsValueChanges: false,
                    },
                    () => {
                      this.fetchOrganisations();
                    }
                  );
                } else {
                  showSnackbar({
                    body: `${res.error}`,
                    type: 'error',
                  });
                }
              })
              .catch((err) => {
                showSnackbar({
                  body: `${err}`,
                  type: 'error',
                });
              });
          }
        });

        break;

      case '1':
        form.validateFields(['name'], (err: any, values: any) => {
          if (!err) {
            organisationApis
              .editOrganisation(selectedOrganisation.organisation.id, {
                name: values.name,
              })
              .then(() => {
                showSnackbar({
                  body: 'Update was successful',
                  type: 'success',
                  isModal: false,
                });

                this.fetchOrganisations();
              })
              .catch((err) => {
                let message = '';

                if (err?.response?.data?.message) {
                  message = `Error: ${err.response.data.message}`;
                }

                showSnackbar({
                  type: 'error',
                  body: `There was an error while trying to update the organisation. ${message}`,
                });
              });
          }
        });

        break;

      default:
        closeOrganisationSettings();
        break;
    }
  }

  private handleTabChange = (key: string) => {
    this.setState({
      currentTabIndex: key,
    });
  };

  private onTextFieldsValueChanges = (value: boolean) => {
    this.setState({
      isTextFieldsValueChanges: value,
    });
  };

  private handleSelectedColorList = (value: BrandingModelColorList) => {
    this.setState(() => {
      return {
        selectedColorList: value,
      };
    });
  };

  private addUserToOrg = (emailId: string) => {
    const { showSnackbar } = this.props;
    const { selectedOrganisation } = this.state;

    if (selectedOrganisation) {
      organisationApis
        .addUserToOrg(selectedOrganisation.organisation.id, {
          emailId,
        })
        .then(() => {
          showSnackbar({
            body: 'Update was successful',
            type: 'success',
            isModal: false,
          });

          this.fetchOrganisations();
        })
        .catch((err) => {
          let message = '';

          if (err?.response?.data?.message) {
            message = `Error: ${err.response.data.message}`;
          }

          showSnackbar({
            type: 'error',
            body: `There was an error while trying to add the user to the organisation. ${message}`,
          });
        });
    }
  };

  private removeUserFromOrg = (userId: string) => {
    const { showSnackbar } = this.props;
    const { selectedOrganisation } = this.state;

    if (selectedOrganisation) {
      organisationApis
        .removeUserFromOrganisation(
          selectedOrganisation.organisation.id,
          userId
        )
        .then(() => {
          showSnackbar({
            body: 'Update was successful',
            type: 'success',
            isModal: false,
          });

          this.fetchOrganisations();
        })
        .catch((err) => {
          let message = '';

          if (err?.response?.data?.message) {
            message = `Error: ${err.response.data.message}`;
          }

          showSnackbar({
            type: 'error',
            body: `There was an error while trying to remove the user from the organisation. ${message}`,
          });
        });
    }
  };

  private addDemoProjectToOrg = (projectId: string) => {
    const { showSnackbar } = this.props;
    const { selectedOrganisation } = this.state;

    if (selectedOrganisation) {
      organisationApis
        .addDemoProjectToOrganisation(selectedOrganisation.organisation.id, {
          projectId,
        })
        .then(() => {
          showSnackbar({
            body: 'Update was successful.',
            type: 'success',
            isModal: false,
          });

          this.fetchOrganisations();
        })
        .catch((err) => {
          let message = '';

          if (err?.response?.data?.message) {
            message = `Error: ${err.response.data.message}`;
          }

          showSnackbar({
            type: 'error',
            body: `There was an error while trying to add the Demo Project to the organisation. ${message}`,
          });
        });
    }
  };

  private removeDemoProjectFromOrg = (id: string) => {
    const { showSnackbar } = this.props;
    const { selectedOrganisation } = this.state;

    if (selectedOrganisation) {
      organisationApis
        .removeDemoProjectToOrganisation(
          selectedOrganisation.organisation.id,
          id
        )
        .then(() => {
          showSnackbar({
            body: 'Update was successful.',
            type: 'success',
            isModal: false,
          });

          this.fetchOrganisations();
        })
        .catch((err) => {
          let message = '';

          if (err?.response?.data?.message) {
            message = `Error: ${err.response.data.message}`;
          }

          showSnackbar({
            type: 'error',
            body: `There was an error while trying to remove the Demo Project from the organisation. ${message}`,
          });
        });
    }
  };

  public renderContent() {
    const { form, showSnackbar, userId } = this.props;
    const { currentTabIndex, loading, selectedOrganisation, organisationList } =
      this.state;
    const { getFieldDecorator } = form;

    if (loading) {
      return <SkeletonLoader />;
    }

    // TODO: add placeholder for when there are no organisations associated with the user

    return (
      <React.Fragment>
        <div className={style.orgSelectContainer}>
          <label className={style.orgLabel}>Organisation</label>
          <Select
            className={style.orgSelect}
            onSelect={(value: string) => {
              const { organisationList } = this.state;
              const org = organisationList.find(
                (o) => o.organisation.id === value
              );

              if (org) {
                this.setState({
                  selectedOrganisation: org,
                });
              }
            }}
            defaultValue={selectedOrganisation?.organisation.id}
          >
            {organisationList.map((o) => {
              return (
                <Option value={o.organisation.id} key={o.organisation.id}>
                  {o.organisation.name}
                </Option>
              );
            })}
          </Select>
        </div>
        {selectedOrganisation ? (
          <Tabs
            centered
            tabBarStyle={{ textAlign: 'center' }}
            defaultActiveKey={currentTabIndex}
            onChange={this.handleTabChange}
          >
            <TabPane
              tab={<span className={style.usrSettingTab}>General</span>}
              key="1"
            >
              <Form layout="vertical">
                <Form.Item label="Name">
                  {getFieldDecorator('name', {
                    initialValue: selectedOrganisation.organisation.name,
                    rules: [
                      {
                        required: true,
                        message: "Name can't be empty",
                      },
                    ],
                  })(<Input placeholder="Organisation Name" />)}
                </Form.Item>
              </Form>
            </TabPane>
            <TabPane
              tab={<span className={style.usrSettingTab}>White Labelling</span>}
              key="2"
            >
              <WhiteLabellingForm
                form={form}
                allowWhiteLabelling={selectedOrganisation.whiteLabelingEnabled}
                brandingData={selectedOrganisation.brandingData}
                showSnackbar={showSnackbar}
                userId={userId}
                organisationId={selectedOrganisation.organisation.id}
                serviceType="settings"
                onValuesChanged={(a) => {
                  this.onTextFieldsValueChanges(a);
                }}
                onSelectedColorListChange={this.handleSelectedColorList}
              />
            </TabPane>

            <TabPane
              tab={<span className={style.usrSettingTab}>Admins</span>}
              key="3"
            >
              <OrganisationUserListing
                organisation={selectedOrganisation.organisation}
                users={selectedOrganisation.users}
                addUser={this.addUserToOrg}
                removeUser={this.removeUserFromOrg}
              />
            </TabPane>
            <TabPane
              tab={<span className={style.usrSettingTab}>Demo Projects</span>}
              key="4"
            >
              <OrganisationDemoProjects
                organisation={selectedOrganisation.organisation}
                ownedProjects={selectedOrganisation.ownedProjects}
                demoProjects={selectedOrganisation.demoProjects}
                addDemoProject={this.addDemoProjectToOrg}
                removeDemoProject={this.removeDemoProjectFromOrg}
              />
            </TabPane>
          </Tabs>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  }

  public render(): React.ReactNode {
    const { isOpen, isUpdating, closeOrganisationSettings } = this.props;
    const { currentTabIndex, isTextFieldsValueChanges, selectedOrganisation } =
      this.state;

    if (!isOpen) {
      return null;
    }

    return (
      <Modal
        title={
          <>
            <span className={style.titleText}>Organisation Settings</span>
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.organisation}
              toolTipPosition="right"
            />
          </>
        }
        className={style.container}
        visible
        onCancel={closeOrganisationSettings}
        onOk={this.handleUpdate}
        okText="Update"
        okButtonProps={{
          loading: isUpdating,
          disabled:
            (currentTabIndex === '2' && !isTextFieldsValueChanges) ||
            !selectedOrganisation,
        }}
        maskClosable={false}
        footer={
          currentTabIndex === '3' || currentTabIndex === '4' ? null : undefined
        }
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

export default Form.create()(OrganisationSettings);
