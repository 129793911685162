// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectUsersModal_shareModalContainer__5PIZe .ProjectUsersModal_shareModalTitle__UEap7{font-size:16px;display:block;margin-bottom:10px;font-weight:bold}.ProjectUsersModal_shareModalContainer__5PIZe .ProjectUsersModal_shareModalBtnWrapper__j51ae{margin-top:10px;text-align:right}.ProjectUsersModal_shareModalContainer__5PIZe button+button{margin-left:10px}.ProjectUsersModal_successMessage__a0BvI{color:green}.ProjectUsersModal_errorMessage__qmSc6{color:red}", "",{"version":3,"sources":["webpack://./src/components/IssuesV2/IssueDetail/ProjectUsersModal/index.module.scss"],"names":[],"mappings":"AAGE,wFACE,cAAA,CACA,aAAA,CACA,kBAAA,CACA,gBAAA,CAGF,6FACE,eAAA,CACA,gBAAA,CAGF,4DACE,gBAAA,CAIJ,yCACE,WAAA,CAGF,uCACE,SAAA","sourcesContent":["@import '../../../../styles/scss/base/base';\n\n.shareModalContainer {\n  .shareModalTitle {\n    font-size: 16px;\n    display: block;\n    margin-bottom: 10px;\n    font-weight: bold;\n  }\n\n  .shareModalBtnWrapper {\n    margin-top: 10px;\n    text-align: right;\n  }\n\n  button + button {\n    margin-left: 10px;\n  }\n}\n\n.successMessage {\n  color: green;\n}\n\n.errorMessage {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareModalContainer": "ProjectUsersModal_shareModalContainer__5PIZe",
	"shareModalTitle": "ProjectUsersModal_shareModalTitle__UEap7",
	"shareModalBtnWrapper": "ProjectUsersModal_shareModalBtnWrapper__j51ae",
	"successMessage": "ProjectUsersModal_successMessage__a0BvI",
	"errorMessage": "ProjectUsersModal_errorMessage__qmSc6"
};
export default ___CSS_LOADER_EXPORT___;
