// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportPreviewModal_container__Vqp76{width:75vw !important}.ReportPreviewModal_container__Vqp76 .ReportPreviewModal_ant-modal-body__X0\\+YA{padding:0 !important}.ReportPreviewModal_preview__\\+JmzF{width:100%;height:75vh}.ReportPreviewModal_text__D\\+9WA{font-size:1.5em;font-weight:600}", "",{"version":3,"sources":["webpack://./src/components/IssuesV2/ReportPreviewModal/index.module.scss"],"names":[],"mappings":"AAAA,qCACE,qBAAA,CAEA,gFACE,oBAAA,CAIJ,oCACE,UAAA,CACA,WAAA,CAGF,iCACE,eAAA,CACA,eAAA","sourcesContent":[".container {\n  width: 75vw !important;\n\n  & .ant-modal-body {\n    padding: 0 !important;\n  }\n}\n\n.preview {\n  width: 100%;\n  height: 75vh;\n}\n\n.text {\n  font-size: 1.5em;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReportPreviewModal_container__Vqp76",
	"ant-modal-body": "ReportPreviewModal_ant-modal-body__X0+YA",
	"preview": "ReportPreviewModal_preview__+JmzF",
	"text": "ReportPreviewModal_text__D+9WA"
};
export default ___CSS_LOADER_EXPORT___;
