import ApiBase from './ApiBase';
import { TerrainMasksApisReturnTypes } from '../shapes/terrainMasks';
import { BASE_CAPI_URL } from '../constants/urls';

export default class TerrainMasksApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = `/v1/missions`;
  }

  public getTerrainMasks(missionId: string): TerrainMasksApisReturnTypes {
    return this.get(`${this._baseUrl}/${missionId}/mask/data`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch(() => {
        return {
          error: `Some error occured while fetching the terrain masks. Try again`,
          data: [],
        };
      });
  }
}
