import TypeMenuStyle from '../TypeMenu/TypeMenu.module.scss';
import FooterStyle from '../ViewFooter/ViewFooter.module.scss';

export const CONTROLS = {
  legend: {
    content: 'Toggle layers (vector or raster) seen on the map',
    target: '#legend',
    placement: 'left',
  },
  measure: {
    content:
      'Use this tool to take measurements on the map, such as distance and volume',
    target: '#measure',
    placement: 'left',
  },
  mark_issue: {
    content:
      'Annotate and create issues to communicate and collaborate among the stakeholders',
    target: '#mark_issue',
    placement: 'left',
  },
  compare: {
    content: 'Compare as-built to as-designed by overlaying CAD designs',
    target: '#compare',
    placement: 'left',
  },
  site_objects: {
    content: 'Automated object detection using machine learning',
    target: '#site_objects',
    placement: 'left',
  },
};

export const HEADER = {
  Elevation: {
    content: 'Colorised elevation map of the site',
    target: '.elevation',
  },
  Survey: {
    content:
      'GIS analytics outputs such as Terrain Models, Planimetric Maps and Contour Maps',
    target: '.survey',
  },
  ViewTypeDropdown: {
    content: 'Use this dropdown to change view type (Maps & Non-Maps)',
    target: `.${TypeMenuStyle.container}`,
    disableBeacon: true,
  },
  'Split View': {
    content: 'Visualise maps side-by-side',
    target: '.split_view',
  },
};

const FOOTER_SCREENSHOT_COACHMARK_STEP = {
  content: 'Tool for taking screenshot.',
  target: `.${FooterStyle.controls}`,
  placement: 'left',
};

export const FOOTER = {
  dates_drawer: {
    content: 'Use the date selector pull-up to switch view dates',
    target: `.${FooterStyle.icon}`,
    placement: 'top',
  },
  perspective: FOOTER_SCREENSHOT_COACHMARK_STEP,
  inspection: FOOTER_SCREENSHOT_COACHMARK_STEP,
  exterior_360: FOOTER_SCREENSHOT_COACHMARK_STEP,
  interior_360: FOOTER_SCREENSHOT_COACHMARK_STEP,
  site_navigation: FOOTER_SCREENSHOT_COACHMARK_STEP,
  map: {
    content: 'Turn on/off crosshair or satellite overlay, take a screenshot.',
    target: `.${FooterStyle.controls}`,
    placement: 'left',
  },
};

export const EXTRA_CONTROLS = {
  perspective: [
    {
      content: 'Camera location on the site map and orientation.',
      target: '#imageInsetMap',
      placement: 'right',
    },
  ],
  inspection: [
    {
      content: 'See camera location in 3D space as well as 2D Map',
      target: '#imageInsetMap',
      placement: 'right',
    },
    {
      content:
        'Click on an arrow on the click wheel to navigate in the desired direction.',
      target: '#imageNavButtons',
      placement: 'top',
    },
  ],
  exterior_360: [
    {
      content: 'Pano location on the site map and orientation.',
      target: '#panoInsetMap',
      placement: 'right',
    },
  ],
  interior_360: [
    {
      content: 'See pano positions in floor plan',
      target: '#panoInsetMap',
      placement: 'right',
    },
  ],
  site_navigation: [
    {
      content: 'Camera location on the site map and orientation.',
      target: '#imageInsetMap',
      placement: 'right',
    },
    {
      content:
        'Click on an arrow on the click wheel to navigate in the desired direction.',
      target: '#imageNavButtons',
      placement: 'top',
    },
  ],
};
// for Detect option and legend option we can try using **Popovers**
// which will openup by default exaplining what it does and close it when done
