import ApiBase from './ApiBase';
import {
  GenericApisReturnPromiseTypes,
  GenericApisReturnTypes,
} from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { OwnerType } from './organisations.types';
import { Project, ProjectArchivalInfo } from './projects.types';

export default class ProjectsAPI extends ApiBase {
  public getProjects(): Promise<any> {
    return this.get('/v2/projects', {}).then((res) => {
      return res.data.projects;
    });
  }

  public getArchivedProjects(): Promise<any> {
    return this.get('/v2/archived_projects', {}).then((res) => {
      return res.data;
    });
  }

  public getProject(id: string): Promise<any> {
    return this.get(`/v2/projects/${id}`, {}).then((res) => {
      return res.data;
    });
  }

  public getProjectViewAccessCount(id: string): Promise<{
    users: { userId: string; email: string; viewCount: number }[];
  }> {
    return this.get(`/v2/projects/${id}/viewAccessCount`).then((res) => {
      return res.data;
    });
  }

  public getProjectStatus(id: string): Promise<GenericApisReturnTypes> {
    return this.get(`/v2/projects/${id}/status`).then((res) => {
      return {
        error: null,
        data: res.data,
      };
    });
  }

  public deleteProject(id: string): Promise<any> {
    return this.delete(`/v2/projects/${id}`);
  }

  public createProject(req: any): Promise<Project> {
    return this.post('/v2/projects', req, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      return res.data;
    });
  }

  public updateProject(id: string, req: any): Promise<any> {
    return this.patch(`/v2/projects/${id}`, req, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      return res.data;
    });
  }

  public updateProjectArchivalStatus(
    id: string,
    archived: boolean,
    restore: boolean
  ): Promise<ProjectArchivalInfo> {
    return this.patch(
      `/v2/projects/${id}/archive_status`,
      {
        archived,
        restore,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then((res) => {
      return res.data;
    });
  }

  public updateAoi(projectId: string, aoiId: string, req: any): Promise<any> {
    return this.patch(`/v2/projects/${projectId}/aois/${aoiId}`, req, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      return {
        projectId,
        aoiId,
        data: res.data,
      };
    });
  }

  public hideAoi(
    projectId: string,
    aoiId: string,
    hide: boolean
  ): Promise<any> {
    return this.patch(
      `/v2/projects/${projectId}/aois/${aoiId}/hide`,
      { hide },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  public fetchProjectBoundary(projectId: string): Promise<any> {
    return this.get(`/v2/projects/${projectId}/boundary`).then((res) => {
      return res.data;
    });
  }

  public fetchAoiBoundary(projectId: string, aoiId: string): Promise<any> {
    return this.get(`/v2/projects/${projectId}/aois/${aoiId}/boundary`).then(
      (res) => {
        return res.data;
      }
    );
  }

  public listAoiBoundary(
    projectId: string,
    aoiId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(`/v2/projects/${projectId}/aois/${aoiId}/boundary`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the AOI Boundary.`,
        });
      });
  }

  public createAoi(
    projectId: string,
    name: string,
    boundaryFileContents: string
  ): Promise<any> {
    return this.post(`/v2/projects/${projectId}/aois`, {
      name,
      boundaryFileContents,
    }).then((res) => {
      return res.data;
    });
  }

  public transferProjectOwnership(
    projectId: string,
    newOwner: {
      ownerId: string;
      ownerType: OwnerType;
    }
  ): Promise<any> {
    return this.patch(`/v2/projects/${projectId}/owner`, newOwner).then(
      (res) => {
        return res.data;
      }
    );
  }

  public getEPSGcodeById(id: any): Promise<any> {
    return this.get(`/v1/coords/${id}`);
  }

  public getProjectTypes = (): Promise<any> => {
    return this.get('/v1/info/projectTypes').then((res) => res.data);
  };
}
