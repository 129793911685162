import React, { useEffect, useMemo, useRef } from 'react';
import { Map, MapBrowserEvent } from 'ol';
import { useHelpToolTip } from 'src/components/OSMMap/hooks';

export interface OverlayHelperProps {
  olMap?: Map;
  overlayContent?: string;
}

export const OverlayHelper: React.FC<OverlayHelperProps> = ({
  overlayContent,
  olMap,
}) => {
  const overlayMessage = useRef<string | undefined>(overlayContent);
  const { overlay, setOverlayPosition, setElementDisplay, setHelpText } =
    useHelpToolTip();

  useEffect(() => {
    overlayMessage.current = overlayContent;
  }, [overlayContent]);

  const onPointerMove = useMemo(
    () => (e: MapBrowserEvent) => {
      setOverlayPosition(e.coordinate);
      const message = overlayMessage.current;

      if (message) {
        setElementDisplay(true);
        setHelpText(message);
      } else {
        setElementDisplay(false);
      }
    },
    []
  );

  useEffect(() => {
    if (olMap) {
      olMap.addOverlay(overlay);
      olMap.on('pointermove', onPointerMove);
    }

    return () => {
      if (olMap) {
        olMap.removeOverlay(overlay);
        olMap.un('pointermove', onPointerMove);
      }
    };
  }, [olMap, overlay]);

  return <div data-testid="overlay-helper" />;
};
