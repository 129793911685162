import { Tooltip } from 'antd';
import * as React from 'react';
import * as ControlBox from '../ControlBox/ControlBox';
import Icon from '../Icon';
import style from './ShareControlBox.module.scss';
import { APP_BASE_URL, BASE_CAPI_URL } from '../../constants/urls';
import { ViewControlsV2TypeTypes } from '../ViewControlsV2/index.types';

interface IProps {
  itemType?: ViewControlsV2TypeTypes;
  onClose?: (itemType: ViewControlsV2TypeTypes | null) => void;
  viewId: string;
  configCreator: () => any;
}

interface IState {
  shareId?: string;
  copied?: boolean;
}

class ShareControlBox extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  private renderHeader = () => {
    const { onClose, itemType } = this.props;

    return (
      <React.Fragment>
        <ControlBox.Title title="Share" />
        <ControlBox.Icon
          onClick={() => {
            if (!onClose) {
              return;
            }

            onClose(itemType || null);
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  private createShare = (isPublic: boolean) => {
    const { viewId, configCreator } = this.props;

    fetch(`${BASE_CAPI_URL}/v1/shares/create`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        viewId,
        viewConfig: configCreator(),
        public: isPublic,
      }),
    })
      .then((res) => res.json())
      .then((share) => {
        this.setState({ shareId: share.id });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  private copy = () => {
    document.execCommand('copy');
    this.setState({
      copied: true,
    });
    window.setTimeout(() => {
      this.setState({ copied: false });
    }, 5000);
  };

  private canCopy = () => {
    try {
      return document.queryCommandSupported('copy');
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  public render(): React.ReactNode {
    const { shareId, copied } = this.state;

    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={this.renderHeader}
      >
        <div className={style.container}>
          {shareId ? (
            <div>
              <div className={style.innerContainer}>
                <input
                  ref={(node: any) => {
                    try {
                      node.focus();
                      node.select();
                    } catch (e) {
                      /**/
                    }
                  }}
                  value={`${APP_BASE_URL}/share/${shareId}`}
                />
                {this.canCopy() && (
                  <Tooltip title="Copy share link">
                    <button onClick={this.copy} className={style.copy}>
                      <i className="fa fa-clipboard" />
                    </button>
                  </Tooltip>
                )}
              </div>
              {copied && (
                <div className={style.copyWrapper}>Copied to clipboard!</div>
              )}
            </div>
          ) : (
            <div>
              <div className={style.slink}>
                <button onClick={() => this.createShare(true) as any}>
                  <Icon name="link" size="small" />
                  <div className={style.publicLink}>Create Public Link</div>
                </button>
              </div>
              <div className={style.slinkdesc}>Anyone can view this.</div>
              <div className={style.slink}>
                <button onClick={() => this.createShare(false) as any}>
                  <Icon name="link" size="small" />
                  <div className={style.privateLink}>Create Private Link</div>
                </button>
              </div>
              <div className={style.slinkdesc}>
                Only people who have account can view.
              </div>
            </div>
          )}
        </div>
      </ControlBox.Wrapper>
    );
  }
}

export default ShareControlBox;
