import React, { useEffect } from 'react';
import { Coordinate } from 'ol/coordinate';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { fromLonLat } from 'ol/proj';
import { useMapContext, useViewManager } from './hooks';

export interface CenterMapProps {
  centerAndZoom?: { center: Coordinate; zoom: number };
  geometryWithScaleBuffer?: { geometry: SimpleGeometry; scaleBuffer?: number };
}

export const CenterMap: React.FC<CenterMapProps> = ({
  centerAndZoom,
  geometryWithScaleBuffer,
}) => {
  const { map, view } = useMapContext();
  const { centerMap, zoomMap, fitGeometry } = useViewManager();

  useEffect(() => {
    if (!centerAndZoom) return;

    const { center, zoom } = centerAndZoom;

    centerMap(fromLonLat(center));
    zoomMap(zoom);
  }, [centerAndZoom, map, view]);

  useEffect(() => {
    if (!geometryWithScaleBuffer) return;

    const { geometry, scaleBuffer } = geometryWithScaleBuffer;

    fitGeometry(geometry, scaleBuffer);
  }, [geometryWithScaleBuffer, map, view]);

  return <div data-testid="ol-center-map" />;
};
