import * as React from 'react';
import { Tooltip } from 'antd';

/**
 * Tooltip to be used within Modals, where zIndex has been assigned. Currently, with the way Modals, and Tooltips
 * are handled by antd, any Modal where zIndex is specified will not work with a Tooltip, regardless of the value
 * of zIndex specified for the the Tooltip.
 *
 * This component aims to fix that, by setting a value of zIndex for the toolTip container, such that it is equivalent
 * or greater than the zIndex of the Modal that is intended to contain it.
 *
 * This ensure that the tooltip is visible.
 */
export default class ModalTooltip extends React.PureComponent<any> {
  containerRef?: { current: HTMLDivElement };

  public componentDidMount() {
    const { zIndex } = this.props;

    this.containerRef = {
      current: createTooltipContainerWithZIndex({ zIndex }),
    };
  }

  public componentDidUpdate({ zIndex: prevZIndex }: { zIndex: number }) {
    const { zIndex } = this.props;

    if (zIndex !== prevZIndex) {
      if (this.containerRef?.current)
        this.containerRef.current.style.zIndex = `${zIndex}`;
    }
  }

  public componentWillUnmount() {
    if (this.containerRef?.current) {
      document.body.removeChild(this.containerRef.current);
    }
  }

  public render() {
    const { zIndex, children, ...rest } = this.props;

    return (
      // @ts-ignore
      <Tooltip
        {...rest}
        overlayStyle={{ zIndex }}
        getPopupContainer={() => createTooltipContainerWithZIndex({ zIndex })}
        destroyTooltipOnHide={false}
      >
        {children}
      </Tooltip>
    );
  }
}

export const createTooltipContainerWithZIndex = ({
  zIndex,
}: {
  zIndex: number;
}) => {
  const divRef = document.createElement('div');

  divRef.style.position = 'absolute';
  divRef.style.top = '0px';
  divRef.style.left = '0px';
  divRef.style.width = '100%';
  divRef.style.zIndex = `${zIndex}`;
  divRef.setAttribute('test-id', 'container');

  document.body.appendChild(divRef);

  return divRef;
};
