import * as React from 'react';
import { SliderItem } from 'react-compound-slider';
import styles from './styles.module.scss';

// *******************************************************
// TICK COMPONENT
// *******************************************************
interface TickProps {
  tick: SliderItem;
  format?: (val: number) => string;
}

export const Tick: React.FC<TickProps> = ({ tick, format = (d) => d }) => {
  return (
    <div>
      <div
        className={styles.tick}
        style={{
          top: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
};
