import booleanClockwise from '@turf/boolean-clockwise';
import { lineString } from '@turf/helpers';
import * as React from 'react';
import styles from './index.module.scss';
import { undefinedOrNull } from '../../../../utils/functs';

interface IProps {
  onMarkerClick: (guid: string) => void;
  selectedMarkerGuid?: string;
  images: any[];
}

interface IState {
  guids: string[];
  interval?: any;
}

class NavigationButtons2WayController extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const allImages = this.orderImages([...(props.images || [])]);
    const images = this.ensureImagesAreClockwise(allImages);
    const guids = images.map((img) => img.guid) || [];

    this.state = { guids, interval: null };
  }

  public componentDidUpdate(nextProps: IProps) {
    const { images: prevImages } = nextProps;
    const { images: propImages } = this.props;

    if (prevImages !== propImages) {
      const allImages = this.orderImages([...(propImages || [])]);
      const images = this.ensureImagesAreClockwise(allImages);
      const guids = images.map((img) => img.guid);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ guids });
    }
  }

  private orderImages(images: any[]) {
    // if we have display order for all images, use that
    if (!images.find((i: any) => undefinedOrNull(i.displayOrder))) {
      images.sort((a: any, b: any) => {
        return a.displayOrder < b.displayOrder
          ? -1
          : a.displayOrder === b.displayOrder
          ? 0
          : 1;
      });
    } else {
      this.imageOrder(images);
    }

    return images;
  }

  private imageOrder = (images: any[]) => {
    return images.sort((a: any, b: any) => {
      return a.capturedAt < b.capturedAt
        ? -1
        : a.capturedAt > b.capturedAt
        ? 1
        : 0;
    });
  };

  private ensureImagesAreClockwise(images: any[]) {
    const line = lineString([
      ...images.map((image) => {
        return [parseFloat(image.longitude), parseFloat(image.latitude)];
      }),
      [parseFloat(images[0].longitude), parseFloat(images[0].latitude)],
    ]);

    if (!booleanClockwise(line)) {
      return [...images].reverse();
    }

    return [...images];
  }

  private prev() {
    const { guids } = this.state;
    const { selectedMarkerGuid, onMarkerClick } = this.props;
    const totalMarkerPoints = guids.length;
    const selectedMarkerIndex = selectedMarkerGuid
      ? guids.indexOf(selectedMarkerGuid)
      : 0;
    let prevIdx = selectedMarkerIndex - 1;

    if (prevIdx < 0) {
      prevIdx = totalMarkerPoints - 1;
      if (prevIdx < 0) {
        // if no images, then idx will become -1
        prevIdx = 0;
      }
    }

    onMarkerClick(guids[prevIdx]);
  }

  private next() {
    const { guids } = this.state;
    const { selectedMarkerGuid, onMarkerClick } = this.props;
    const totalMarkerPoints = guids.length;
    const selectedMarkerIndex = selectedMarkerGuid
      ? guids.indexOf(selectedMarkerGuid)
      : 0;
    let nextIdx = selectedMarkerIndex + 1;

    if (nextIdx > totalMarkerPoints - 1) {
      nextIdx = 0;
    }

    onMarkerClick(guids[nextIdx]);
  }

  private startAutoPlay() {
    const interval = window.setInterval(() => {
      this.next();
    }, 5000);

    this.setState({ interval });
  }

  private stopAutoPlay() {
    const { interval } = this.state;

    if (interval !== null) {
      window.clearInterval(interval);
      this.setState({ interval: null });
    }
  }

  public render(): React.ReactNode {
    const { guids, interval } = this.state;
    const { selectedMarkerGuid } = this.props;
    const selectedMarkerIndex = selectedMarkerGuid
      ? guids.indexOf(selectedMarkerGuid)
      : 0;
    const totalMarkerPoints = guids.length;

    return (
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <button
            className={styles.prevBtn}
            onClick={() => {
              this.next();
            }}
          >
            <i className="fa fa-chevron-left" />
          </button>

          <div className={styles.selectedIndexInfoWrapper}>
            <div className={styles.center}>
              {selectedMarkerIndex + 1}
              &nbsp;/&nbsp;
              {totalMarkerPoints}
            </div>
          </div>

          <div>
            {interval ? (
              <button
                onClick={() => this.stopAutoPlay()}
                title="Stop auto play"
              >
                <i className="fa fa-pause" aria-hidden="true" />
              </button>
            ) : (
              <button
                onClick={() => this.startAutoPlay()}
                title="Auto play next image every 5s"
              >
                <i className="fa fa-play" aria-hidden="true" />
              </button>
            )}
          </div>

          <button
            className={styles.nextBtn}
            onClick={() => {
              this.prev();
            }}
          >
            <i className="fa fa-chevron-right" />
          </button>
        </div>
      </div>
    );
  }
}

export default NavigationButtons2WayController;
