import * as React from 'react';
import MapboxStyle, {
  IProps as MapboxStyleProps,
} from '../MapboxStyle/MapboxStyle';
import SurveyTypeSelector from '../SurveyTypeSelector/SurveyTypeSelectorContainer';
import style from './SurveyView.module.scss';

type MapProps = Pick<
  MapboxStyleProps,
  Exclude<keyof MapboxStyleProps, 'style' | 'accessToken'>
>;

interface IOwnProps {
  view: any;
}

type IProps = IOwnProps & MapProps;

class SurveyView extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { view } = this.props;

    return (
      <div className={style.container}>
        <MapboxStyle
          {...this.props}
          style={view.sourceUrl}
          accessToken={view.sourceToken}
        />
        <SurveyTypeSelector viewId={view.id} />
      </div>
    );
  }
}

export default SurveyView;
