// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SurveyView_container__lcCtV{width:auto}", "",{"version":3,"sources":["webpack://./src/components/SurveyView/SurveyView.module.scss"],"names":[],"mappings":"AAEA,6BACE,UAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SurveyView_container__lcCtV"
};
export default ___CSS_LOADER_EXPORT___;
