import * as React from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import {
  MAPBOX_API_URL,
  MAPBOX_STYLE_PROTOCOL_URL,
} from '../../constants/urls';
import { ViewV2StateMapStylesLayersIdDataTypes } from '../ViewV2/index.types';
import { GenericObjectType } from '../../shapes/app';
import { filteredArrayValue } from '../../utils/functs';

interface ILayer {
  id: string;
  source: string;
  type: any;
  paint: any;
  minzoom: number;
  maxzoom: number;
  layout: any;
}
interface ISource {
  id: string;
  tileSize: number;
  type: any;
  url: string;
  tiles: string[];
}

interface IState {
  layers: ILayer[];
  sources: ISource[];
}

export interface IProps {
  style: string;
  opacity?: number;
  accessToken: string;
  onLayerUpdate?: (layers: ILayer[]) => void;
  type: ViewV2StateMapStylesLayersIdDataTypes;
  onLoad?: (
    type: ViewV2StateMapStylesLayersIdDataTypes,
    id: string | null
  ) => void;
  activeLayers?: string[];
}

class MapboxStyle extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    opacity: 1,
  };

  public constructor(props: any) {
    super(props);

    this.state = {
      layers: [],
      sources: [],
    };
  }

  public componentDidMount(): void {
    const { style, accessToken } = this.props;

    this.fetchStyle(style, accessToken);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    const { style } = this.props;

    if (nextProps.style !== style) {
      this.fetchStyle(nextProps.style, nextProps.accessToken);
    }
  }

  private renderSources(): React.ReactNode {
    const { sources } = this.state;

    return sources.map((source) => {
      let tileJson: any = {
        type: source.type,
      };

      if (source.url) {
        tileJson = {
          ...tileJson,
          url: source.url,
        };
      }

      if (source.tiles) {
        tileJson = {
          ...tileJson,
          tiles: source.tiles,
        };
      }

      return (
        <Source key={source.id} id={source.id} tileJsonSource={tileJson} />
      );
    });
  }

  private renderLayers(): React.ReactNode {
    const { layers } = this.state;
    const { opacity, activeLayers } = this.props;

    return layers
      .filter((layer) => layer.type !== 'background')
      .map((layer) => {
        const { paint, type } = layer;
        const layerPaint = {
          ...paint,
        };

        if (layerPaint && type === 'raster') {
          layerPaint['raster-opacity'] = opacity;
        }

        const layout = {
          visibility: 'visible',
          ...layer.layout,
        };

        if (activeLayers && activeLayers.indexOf(layer.id) === -1) {
          layout.visibility = 'none';
        }

        return (
          <Layer
            key={layer.id}
            id={layer.id}
            sourceId={layer.source}
            sourceLayer={layer['source-layer']}
            type={type}
            layout={layout}
            minZoom={layer.minzoom}
            maxZoom={layer.maxzoom}
            paint={layerPaint}
          />
        );
      });
  }

  private async fetchStyle(style: string, accessToken: string): Promise<any> {
    const { onLayerUpdate, type, onLoad } = this.props;

    this.setState({
      sources: [],
      layers: [],
    });
    if (style.startsWith(MAPBOX_STYLE_PROTOCOL_URL)) {
      // eslint-disable-next-line no-param-reassign
      style = style.replace(MAPBOX_STYLE_PROTOCOL_URL, MAPBOX_API_URL);

      // eslint-disable-next-line no-param-reassign
      style = `${style}?access_token=${accessToken}`;
    }

    const styleData = await fetch(style).then((data) => data.json());

    const sources: ISource[] = Object.keys(styleData.sources || {}).map(
      (source: string) => {
        return {
          id: source,
          ...styleData.sources[source],
        };
      }
    );
    const metadatas: any = styleData.metadata || [];

    const layers: ILayer[] = (styleData.layers || []).map((layer: any) => {
      return {
        ...metadatas[layer.id],
        ...layer,
      };
    });

    if (onLayerUpdate) {
      onLayerUpdate(layers);
    }

    this.setState(
      {
        sources,
        layers,
      },
      () => {
        if (onLoad && styleData && styleData.layers) {
          const rasterLayerList = styleData.layers.filter(
            (a: GenericObjectType) => a.type === 'raster'
          );

          const rasterLayer = filteredArrayValue(rasterLayerList);

          if (!rasterLayer) {
            return;
          }

          onLoad(type, rasterLayer.id);
        }
      }
    );
  }

  public render(): React.ReactNode {
    return (
      <React.Fragment>
        {this.renderSources()}
        {this.renderLayers()}
      </React.Fragment>
    );
  }
}

export default MapboxStyle;
