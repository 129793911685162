import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionShowSnackbar } from '../../../../redux/actions/snackbar';
import ProjectUsersModal from '.';

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      actionShowSnackbar,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ProjectUsersModal);
