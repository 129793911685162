import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactGA from 'react-ga';
import { addLocaleData } from 'react-intl';
import * as en from 'react-intl/locale-data/en';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/pannellum/build/pannellum.css';
import { hot } from 'react-hot-loader/root';
import App from './components/App/container';
import { store } from './redux/store';

const possibleLocale = navigator.language.split('-')[0] || 'en';

async function getLang(locale: string): Promise<any> {
  // Refer: https://stackoverflow.com/questions/40783673/typescript-import-from-variable-instead-of-literal-string
  return await import(`react-intl/locale-data/${locale}`);
}

getLang(possibleLocale).then((lang: any) => {
  addLocaleData(lang);
});
addLocaleData(en);

const GA_ID = process.env.REACT_APP_GA_ID
  ? `${process.env.REACT_APP_GA_ID}`
  : null;

if (GA_ID) {
  ReactGA.initialize(GA_ID);
}

const AppContainer = () => {
  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </Provider>
  );
};

const HotApp = hot(AppContainer);

ReactDOM.render(
  <HotApp />,
  document.getElementById('app') || document.createElement('div')
);
