// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShapeInfo_container__yIl45{display:grid;grid-template-columns:1fr 1fr}.ShapeInfo_scrollable__9n3-b{max-height:50vh;overflow-y:scroll}", "",{"version":3,"sources":["webpack://./src/components/MeasureControlBox/ShapeInfo.module.scss"],"names":[],"mappings":"AAEA,4BACE,YAAA,CACA,6BAAA,CAGF,6BACE,eAAA,CACA,iBAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n}\n\n.scrollable {\n  max-height: 50vh;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ShapeInfo_container__yIl45",
	"scrollable": "ShapeInfo_scrollable__9n3-b"
};
export default ___CSS_LOADER_EXPORT___;
