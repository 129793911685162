import classnames from 'classnames';
import * as React from 'react';
import style from './DroneAnimation.module.scss';
import droneImage from './images/drone.svg';
import SkeletonLoader from '../SkeletonLoader';

interface IProps {
  className?: string;
  projectId: string;
  aoiId: string;
  loading?: boolean;
  missions?: any[];
  fetchMissions?: (projectId: string, aoiId: string) => void;
}

interface IState {
  status?: string;
}

class DroneAnimation extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      status: undefined,
    };
  }

  public componentDidMount(): void {
    const { projectId, aoiId, fetchMissions } = this.props;

    if (fetchMissions) {
      fetchMissions(projectId, aoiId);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    if (nextProps.missions) {
      this.setState({ status: this.getAOIStatus(nextProps.missions) });
    }
  }

  public getAOIStatus(missions: { status: string }[]): string {
    if (missions) {
      const processingList =
        missions.filter((m) => m.status === 'PROCESSING') || [];
      const processedList =
        missions.filter((m) => m.status === 'PROCESSED') || [];

      if (processingList.length > 0 || processedList.length > 0) {
        return 'processing';
      }
    }

    return 'pending';
  }

  public renderInfoText(): JSX.Element {
    const { status } = this.state;

    return (
      <div>
        <div className={style.text}>
          {status === 'processing'
            ? 'We are processing your data.'
            : 'We are waiting for your data to reach us.'}
        </div>
      </div>
    );
  }

  public render(): React.ReactNode {
    const { className, loading } = this.props;

    if (loading) {
      return <SkeletonLoader position="fixed" />;
    }

    return (
      <div className={classnames(style.container, className)}>
        <img src={droneImage} className={style.drone} alt="" />
        {this.renderInfoText()}
      </div>
    );
  }
}

export default DroneAnimation;
