import AoiAPIs from '../../api/aois';

const api = new AoiAPIs();

export function fetchMissions(projectId: string, aoiId: string): {} {
  return {
    type: 'FETCH_MISSIONS',
    payload: api.fetchMissions(projectId, aoiId),
  };
}

export function fetchFloorPlans(projectId: string, aoiId: string): {} {
  return {
    type: 'FETCH_FLOORPLANS',
    payload: api.fetchFloorplans(projectId, aoiId),
  };
}

export function fetchCADs(projectId: string, aoiId: string): {} {
  return {
    type: 'FETCH_CADS',
    payload: api.fetchCADs(projectId, aoiId),
  };
}

export function resetAOIState(): {} {
  return {
    type: 'RESET_AOI_STATE',
  };
}
