import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { GenericObjectType } from '../../shapes/app';
import { isCertifiedView } from '../../utils/functs';
import { getViewCertifiedOnlyAoiList } from '../../utils/localStorage';

interface ISubType {
  name: string;
  label: string;
}

const MAX_MENU_ITEM = 5;

const subType: ISubType[] = [
  { name: 'aerial', label: 'Aerial' },
  { name: 'elevation', label: 'Elevation' },
  { name: 'satellite', label: 'Satellite' },
  { name: 'thermal_mosaic', label: 'Thermal' },
  { name: 'ndvi', label: 'NDVI' },
  { name: 'survey', label: 'Survey' },
  { name: 'exterior_360', label: 'Exterior 360' },
  { name: 'interior_360', label: 'Interior 360' },
  { name: 'split_view', label: 'Split View' },
  { name: 'nadir', label: 'Nadir' },
];
const type = {
  map: 'maps',
  perspective: 'perspective',
  inspection: 'inspection',
  exterior_360: 'exterior 360',
  interior_360: 'interior 360',
  three_d: '3D View',
  video: 'Videos',
  site_navigation: 'Site Walkthrough',
};

export const getSubTypes = (): ISubType[] => subType;
const getTypes = (): any => type;

const getViews = (state: any): any[] => {
  return state.views.data;
};
const getViewId = (state: any, props: any): string => props.viewId;
const getSplitViewId = (state: any, props: any): string | undefined =>
  props.splitViewId;

export const getSelectedView = createSelector(
  getViews,
  getViewId,
  (views, viewId) => {
    return views.find((view) => view.id === viewId);
  }
);

export const isValidSplitView = (view: any): boolean => {
  return view.subType !== 'design' && view.subType !== 'elevation_difference';
};

export const getHeaderMenu = createSelector(
  getSubTypes,
  getViews,
  getSelectedView,
  getSplitViewId,
  (menus, views, selectedView, splitViewId) => {
    const menuObjs: any = {};
    let newSplitViewId: string | undefined;
    const selectedViewDate =
      selectedView && new Date(selectedView.date).getTime();

    const certfiedOnlyAoiList = getViewCertifiedOnlyAoiList();

    views
      .filter(
        (view: any) =>
          view.subType !== 'dtm' &&
          view.subType !== 'contour' &&
          view.subType !== 'elevation_difference' &&
          (certfiedOnlyAoiList.indexOf(view.aoiId) > -1
            ? view.certifiedForDisplay
            : true) &&
          view.type === (selectedView?.type || 'map') // ensure only views of the appropriate type are considered
      )
      .forEach((element) => {
        if (!element.subType) {
          // eslint-disable-next-line no-param-reassign
          element.subType = element.type;
        }

        if (!menuObjs[element.subType]) {
          menuObjs[element.subType] = element;
        } else {
          // find nearest view's date with selected view's date.
          const viewDate1 = new Date(menuObjs[element.subType].date).getTime();
          const viewDate2 = new Date(element.date).getTime();

          if (
            Math.abs(selectedViewDate - viewDate2) <=
            Math.abs(selectedViewDate - viewDate1)
          ) {
            menuObjs[element.subType] = element;
          }
        }

        if (
          !newSplitViewId ||
          (selectedView && newSplitViewId === selectedView.id)
        ) {
          if (isValidSplitView(element)) {
            newSplitViewId = element.id;
          }
        }
      });

    menuObjs.split_view = {
      id: selectedView && selectedView.id,
      meta: { splitViewId: splitViewId || newSplitViewId },
      // ensure type matches selected view, otherwise default to map view
      type: selectedView?.type || 'map',
      subType: 'split_view',
    };

    return menus
      .filter((menu) => menuObjs[menu.name])
      .map((menu) => {
        const menuObj = menuObjs[menu.name];

        return {
          name: menu.label,
          viewId: menuObj.id,
          type: menuObj.type,
          subType: menuObj.subType,
          meta: menuObj.meta,
          isActive: (() => {
            if (splitViewId && menuObj.subType === 'split_view') {
              return true;
            }

            if (
              selectedView &&
              !splitViewId &&
              menuObj.subType !== 'split_view'
            ) {
              return selectedView.subType === menuObj.subType;
            }

            return false;
          })(),
        };
      });
  }
);

export const getFooterMenu = createSelector(
  getViews,
  getSelectedView,
  (views, selectedView) => {
    const filteredMenuList = views
      // Filter view with same type and subType as same as selected view
      .filter(
        (view) =>
          view.type === selectedView.type &&
          view.subType === selectedView.subType
      )
      // Remove duplicate, and selectedView's date from list, helpfull in survey view.
      .filter((view, index, self) => {
        return (
          self.findIndex((v) => v.id === view.id) === index &&
          view.id !== selectedView.id
        );
      })
      .concat(selectedView)
      .sort((view1: GenericObjectType, view2: GenericObjectType): any => {
        const view1Date = new Date(view1.date).getTime();
        const view2Date = new Date(view2.date).getTime();

        return view2Date - view1Date;
      })
      .map((view) => {
        return {
          date: moment(view.date).tz('Etc/GMT').format('ll'),
          projectId: view.projectId,
          aoiId: view.aoiId,
          viewId: view.id,
          isActive: view.id === selectedView.id,
          certifiedForDisplay: view.certifiedForDisplay,
        };
      });

    const sortedFilteredMenuList = [];

    for (let i = 0; i < views.length; i += 1) {
      const _filteredMenuList = filteredMenuList.filter(
        (item) => views[i].id === item.viewId
      );

      if (_filteredMenuList && _filteredMenuList.length > 0) {
        sortedFilteredMenuList.push(_filteredMenuList[0]);
      }
    }

    return sortedFilteredMenuList;
  }
);

export const getTrimmedFooterMenu = createSelector(getFooterMenu, (menus) => {
  let trimmedMenu = menus.slice(0, MAX_MENU_ITEM);
  const activeMenuIndex = menus.findIndex((menu) => menu.isActive);

  if (activeMenuIndex >= MAX_MENU_ITEM) {
    trimmedMenu = [
      ...trimmedMenu.slice(0, MAX_MENU_ITEM - 1),
      menus[activeMenuIndex],
    ];
  }

  return trimmedMenu;
});

export const getMenuTypes = createSelector(
  getTypes,
  getViews,
  (types, views) => {
    const menuObjs = {};

    views
      .filter((a: GenericObjectType) => isCertifiedView(a))
      .forEach((element) => {
        if (element.type === 'map') {
          if (element.subType === 'aerial' && !menuObjs[element.type]) {
            menuObjs[element.type] = element;
            menuObjs[element.type].certifiedForDisplayCount = 0;
          }
        } else if (!menuObjs[element.type]) {
          menuObjs[element.type] = element;
          menuObjs[element.type].certifiedForDisplayCount = 0;
        }

        if (element.certifiedForDisplay && menuObjs[element.type]) {
          menuObjs[element.type].certifiedForDisplayCount += 1;
        }
      });

    return Object.keys(types).map((value) => {
      const menuObj = menuObjs[value];

      if (!menuObj) {
        return {
          name: value,
        };
      }

      return {
        name: value,
        label: types[value],
        viewId: menuObj.id,
        projectId: menuObj.projectId,
        aoiId: menuObj.aoiId,
        certifiedForDisplayCount: menuObj.certifiedForDisplayCount,
      };
    });
  }
);

export const getSelectedViewType = createSelector(getSelectedView, (view) => {
  if (!view) {
    return;
  }

  return view.type;
});

export const getDesignViews = createSelector(getViews, (views) => {
  return views.filter(
    (view) => view.type === 'map' && view.subType === 'design'
  );
});

export const getSurveyViews = createSelector(getViews, (views) => {
  return views.filter(
    (view) => view.type === 'map' && view.subType === 'survey'
  );
});

export const getSplitViewTypes = createSelector(
  getViews,
  getSubTypes,
  (views, subTypes) => {
    const mapViews = views.filter((view) => view.type === 'map');

    return subTypes
      .filter((value) => mapViews.find((view) => view.subType === value.name))
      .map((value) => {
        const selectedView = mapViews.find(
          (view) => view.subType === value.name
        );

        return {
          value: value.name,
          label: value.label,
          viewId: selectedView.id,
        };
      });
  }
);

export const getSplitViewDates = createSelector(
  getViews,
  getSelectedView,
  (views, selectedView) => {
    const selectedViewSubType = selectedView.subType;

    return views
      .filter((value) => value.subType === selectedViewSubType)
      .map((value) => ({
        value: value.id,
        label: moment(value.date.split('T')[0]).format('ll'),
      }));
  }
);

export const getSurveyTypes = createSelector(
  getSurveyViews,
  getSelectedView,
  (views, selectedView) => {
    return views
      .filter((view) => view.date === selectedView.date)
      .map((view) => ({
        value: view.id,
        label: view.name,
      }));
  }
);
