import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchMissions, resetAOIState } from '../../../redux/actions/aois';
import MissionsListing from './index';
import { MissionsListingTypes } from './index.types';

const mapStateToProps = (state: any): MissionsListingTypes.ReduxState => {
  return {
    loading: state.aois.loading,
    missions: state.aois.missions,
    error: state.aois.error,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): MissionsListingTypes.DispatchProps => {
  return bindActionCreators(
    {
      fetchMissions,
      resetAOIState,
    },
    dispatch
  );
};

export default connect<
  MissionsListingTypes.ReduxState,
  MissionsListingTypes.DispatchProps,
  MissionsListingTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(MissionsListing);
