import * as React from 'react';
import { Typography } from 'antd';
import styles from './index.module.scss';
import { NavigationBoxPropsTypes } from './index.types';
import Compass from '../Compass';
import { roundDecimals, undefinedOrNull } from '../../utils/functs';

const { Text } = Typography;

class NavigationBox extends React.PureComponent<NavigationBoxPropsTypes> {
  private isClientMobile = () => {
    const { clientWidth } = document.body;

    return clientWidth >= 500 && clientWidth <= 700;
  };

  public render(): React.ReactNode {
    const { altitude, direction, rotation, temperatureRange } = this.props;
    const { clientHeight } = document.body;

    let altitudeValue = altitude.value || 0;

    if (altitude.round) {
      altitudeValue = roundDecimals(altitudeValue);
    }

    return clientHeight >= 500 ? (
      <div
        className={
          this.isClientMobile() ? styles.mobileVersion : styles.container
        }
      >
        <div className={styles.compassWrapper}>
          <Compass direction={direction} rotation={rotation} />
        </div>
        <div className={styles.informationWrapper}>
          {!undefinedOrNull(altitude) && (
            <Text>
              Altitude: &nbsp;
              {`${altitudeValue} ${altitude.unit}`}
            </Text>
          )}
        </div>
        {!!temperatureRange && (
          <div className={styles.informationWrapper}>
            <Text>
              Temp: &nbsp;
              {`${roundDecimals(temperatureRange.min)} - ${roundDecimals(
                temperatureRange.max
              )} °${temperatureRange.unit}`}
            </Text>
          </div>
        )}
      </div>
    ) : null;
  }
}

export default NavigationBox;
