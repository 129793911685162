// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResetPassword_container__IAlqW{width:400px;background:var(--forground);border-radius:var(--radius);border:1px solid #1b1b1b;padding:30px 20px;margin:100px auto auto}.ResetPassword_container__IAlqW .ant-input,.ResetPassword_container__IAlqW .ant-btn{height:50px}.ResetPassword_container__IAlqW input{color:#fff}.ResetPassword_logo__cbLs1{padding:10px;margin:auto auto 20px;display:block;text-align:center}.ResetPassword_forgot_password__LKYl9{margin-top:20px;color:#fff}.ResetPassword_error__n53dO{color:red;margin-bottom:15px}.ResetPassword_resetPassword__L0opk{color:rgba(255,255,255,.25);padding:5px 0}", "",{"version":3,"sources":["webpack://./src/components/ResetPassword/index.module.scss"],"names":[],"mappings":"AAEA,gCACE,WAAA,CACA,2BAAA,CACA,2BAAA,CACA,wBAAA,CACA,iBAAA,CACA,sBAAA,CAGA,oFAEE,WAAA,CAKJ,sCACE,UAAA,CAGF,2BACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,iBAAA,CAGF,sCACE,eAAA,CACA,UAAA,CAGF,4BACE,SAAA,CACA,kBAAA,CAGF,oCACE,2BAAA,CACA,aAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  width: 400px;\n  background: var(--forground);\n  border-radius: var(--radius);\n  border: 1px solid #1b1b1b;\n  padding: 30px 20px;\n  margin: 100px auto auto;\n\n  /* stylelint-disable selector-pseudo-class-no-unknown */\n  & :global(.ant-input),\n  & :global(.ant-btn) {\n    height: 50px;\n  }\n  /* stylelint-enable selector-pseudo-class-no-unknown */\n}\n\n.container input {\n  color: white;\n}\n\n.logo {\n  padding: 10px;\n  margin: auto auto 20px;\n  display: block;\n  text-align: center;\n}\n\n.forgot_password {\n  margin-top: 20px;\n  color: #fff;\n}\n\n.error {\n  color: #f00;\n  margin-bottom: 15px;\n}\n\n.resetPassword {\n  color: rgba(255, 255, 255, 0.25);\n  padding: 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ResetPassword_container__IAlqW",
	"logo": "ResetPassword_logo__cbLs1",
	"forgot_password": "ResetPassword_forgot_password__LKYl9",
	"error": "ResetPassword_error__n53dO",
	"resetPassword": "ResetPassword_resetPassword__L0opk"
};
export default ___CSS_LOADER_EXPORT___;
