import * as React from 'react';

import { Clock, PerspectiveCamera, Scene, WebGLRenderer } from 'three';
import {
  GeoTransform,
  Loader3DTiles,
  PointCloudColoring,
} from 'three-loader-3dtiles';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { MapView, OpenStreetMapsProvider } from 'geo-three';
import ViewsV2Apis from 'src/api/viewsV2';
import { BaseRendererProps } from '../index.types';

export default class ThreeDTilesView extends React.Component<BaseRendererProps> {
  private viewApi = new ViewsV2Apis();

  private rendererRef = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    const { onFooterControlsFragChange, view } = this.props;

    if (onFooterControlsFragChange) {
      onFooterControlsFragChange(undefined);
    }

    const viewFileUrl = this.viewApi.getViewFileUrl(view.id);
    const canvasParent: any = this.rendererRef.current;

    const scene = new Scene();
    const camera = new PerspectiveCamera();

    camera.position.set(100, 0, 0);

    const renderer = new WebGLRenderer();
    const clock = new Clock();
    const controls = new OrbitControls(camera, canvasParent);

    controls.listenToKeyEvents(window);
    canvasParent.appendChild(renderer.domElement);

    // const provider = new OpenStreetMapsProvider();
    // const _map = new MapView(MapView.PLANAR, provider);
    // scene.add(_map);

    loadTileset();

    let tilesRuntime: any;

    async function loadTileset() {
      const result = await Loader3DTiles.load({
        url: `${viewFileUrl}/tileset.json`,
        renderer,
        options: {
          dracoDecoderPath:
            'https://cdn.jsdelivr.net/npm/three@0.137.0/examples/js/libs/draco',
          pointCloudColoring: PointCloudColoring.RGB,
          maximumScreenSpaceError: 6,
          geoTransform: GeoTransform.Mercator,
        },
      });

      const { model, runtime } = result;

      // make model right side up
      model.rotateX(Math.PI / 2);
      camera.position.copy(model.position);
      camera.translateY(100);
      controls.target.copy(model.position);
      controls.update();

      tilesRuntime = runtime;
      scene.add(model);
    }

    function render() {
      const dt = clock.getDelta();

      controls.update();
      if (tilesRuntime) {
        tilesRuntime.update(dt, renderer, camera);
      }

      renderer.render(scene, camera);
      window.requestAnimationFrame(render);
    }

    onWindowResize();

    function onWindowResize() {
      renderer.setSize(canvasParent.clientWidth, canvasParent.clientHeight);
      camera.aspect = canvasParent.clientWidth / canvasParent.clientHeight;
      camera.updateProjectionMatrix();
    }

    window.addEventListener('resize', onWindowResize);

    render();
  }

  public render() {
    return (
      <div ref={this.rendererRef} style={{ width: '100%', height: '100%' }} />
    );
  }
}
