import React from 'react';
import DiagnosticOpApis from 'src/api/diagnostics';

interface SessionOutputProps {
  sessionId: string;
}

interface SessionOutputState {
  error?: string;
  outputFiles?: any;
}

class SessionOutputs extends React.Component<
  SessionOutputProps,
  SessionOutputState
> {
  private diagnosticApis = new DiagnosticOpApis();

  constructor(props: SessionOutputProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    const { sessionId } = this.props;

    this.diagnosticApis
      .getOutputArtifactForSession(sessionId)
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
          this.setState({ error: 'Could not load session outputs' });

          return;
        }

        this.setState({ outputFiles: data });
      });
  }

  download(guid: string) {
    this.diagnosticApis.getArtifactLink(guid).then(({ data, error }) => {
      if (error) {
        console.error(error);

        return;
      }

      if (data?.url) {
        const win = window.open('', '_blank');

        if (win) {
          win.location.href = data?.url;
        }
      }
    });
  }

  render(): React.ReactNode {
    const { error, outputFiles } = this.state;

    if (error) {
      return <div>{error}</div>;
    }

    if (!outputFiles) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        {(outputFiles?.files || []).map((f: any) => {
          return (
            <p>
              <a onClick={() => this.download(f.guid)}>{f.document_type}</a>
            </p>
          );
        })}
      </div>
    );
  }
}

export default SessionOutputs;
