import { createAction } from 'redux-actions';
import {
  SETTINGS_MODAL,
  SETTINGS_UPDATE,
  ORGANISATION_MODAL,
} from '../actions';
import { ISettings } from '../reducers/settings';

import User from '../../api/User';

const userApi = new User();

export interface IUpdatePayload {
  newPassword: string;
  currentPassword: string;
}

export const openSettings = createAction(SETTINGS_MODAL.OPEN);
export const closeSettings = createAction(SETTINGS_MODAL.CLOSE);
export const updateSettings = createAction(
  SETTINGS_UPDATE.ACTION,
  (values: IUpdatePayload) =>
    async (dispatch: any, getState: () => ISettings) => {
      await userApi.updatePassword(values.newPassword, values.currentPassword);
    }
);

export const openOrganisationSettings = createAction(ORGANISATION_MODAL.OPEN);
export const closeOrganisationSettings = createAction(ORGANISATION_MODAL.CLOSE);
