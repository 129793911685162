import React from 'react';
import { Tooltip } from 'antd';
import Icon from '../../Icon';
import { IssuesHeaderIconsProps } from './index.types';
import style from './index.module.scss';

export default class IssuesHeaderIcons extends React.PureComponent<IssuesHeaderIconsProps> {
  public render() {
    const { openManageTagsModal, openReportPreviewModal, canSeeReportIcon } =
      this.props;

    return (
      <>
        {canSeeReportIcon ? (
          <Tooltip placement="bottom" title="Issues Report">
            <div
              className={style.issuesReportIcon}
              onClick={openReportPreviewModal}
            >
              <Icon name="pdf_download" size="large" />
            </div>
          </Tooltip>
        ) : null}
        <Tooltip placement="bottom" title="Manage Tags">
          <div className={style.issuesHeaderIcon} onClick={openManageTagsModal}>
            <i className="fa fa-tag fa-2x" />
          </div>
        </Tooltip>
      </>
    );
  }
}
