import {
  External360FlightPlan,
  NadirFlightPlan,
  PerspectiveFlightPlan,
} from '@aspecscire/navigator-wasm';
import { GeoJSON, WKT } from 'ol/format';
import { Polygon } from 'ol/geom';

export interface External360Input {
  capturePoints: Array<[number, number, number]>;
  altitude: number;
  speed: number;
}

export interface External360Output {
  plans: Array<string>;
  num_splits: number;
  speed: number;
}

export interface NadirInput {
  boundary: string;
  angle?: number;
  buffer?: number;
  altitude: number;
  takeOffAltitude: number;
  horizontalFieldOfView: number;
  verticalFieldOfView: number;
  horizontalOverlap: number;
  verticalOverlap: number;
  maxFlightTime: number;
  stayForDuration: number;
  joinThreshold: number;
}

export interface NadirOutput {
  plans: Array<string>;
  num_splits: number;
  speed: number;
  interval: number;
  angle: number;
  buffer?: number;
}

export interface PerspectiveInput {
  boundary: string;
  gimbalPitch: number;
  maxFlightTime: number;
  altitude: number;
  speed: number;
}

export interface PerspectiveOutput {
  plans: Array<string>;
  num_splits: number;
  speed: number;
  interval: number;
}

export const convertBoundaryToWkt = (
  boundary: string | object
): string | undefined => {
  const geojson = new GeoJSON();
  const wkt = new WKT();
  let geojsonBoundary: Polygon | undefined;

  try {
    geojsonBoundary = geojson.readFeatures(boundary)[0].getGeometry() as
      | Polygon
      | undefined;
  } catch (e) {
    console.error('Error while reading boundary data', e);

    return undefined;
  }

  if (geojsonBoundary === undefined) {
    console.error('Error extracting boundary geometry.', boundary);

    return undefined;
  }

  return wkt.writeGeometry(geojsonBoundary);
};

export const createExternal360FlightPlan = (
  input: External360Input
): External360Output => {
  const { capturePoints, altitude, speed } = input;
  const flightPlan = new External360FlightPlan(capturePoints, altitude, speed);

  return flightPlan.get_response() as External360Output;
};

export const createNadirFlightPlan = (input: NadirInput): NadirOutput => {
  const {
    boundary,
    angle,
    buffer,
    altitude,
    takeOffAltitude,
    horizontalFieldOfView,
    verticalFieldOfView,
    horizontalOverlap,
    verticalOverlap,
    maxFlightTime,
    stayForDuration,
    joinThreshold,
  } = input;

  const wktBoundary = convertBoundaryToWkt(boundary);

  if (wktBoundary === undefined) {
    console.error('Could not extract Wkt String from boundary.');

    throw new Error('Error in extracting wktString from boundary.');
  }

  const isValidAngle = angle === undefined || !Number.isNaN(angle);

  const flightPlan = new NadirFlightPlan(
    wktBoundary,
    isValidAngle ? angle : undefined,
    buffer,
    altitude,
    takeOffAltitude,
    horizontalFieldOfView,
    verticalFieldOfView,
    horizontalOverlap,
    verticalOverlap,
    maxFlightTime,
    stayForDuration,
    !buffer,
    joinThreshold,
    false
  );

  return flightPlan.get_response() as NadirOutput;
};

export const createPerspectiveFlightPlan = (
  input: PerspectiveInput
): PerspectiveOutput => {
  const { boundary, maxFlightTime, altitude, speed, gimbalPitch } = input;

  const wktBoundary = convertBoundaryToWkt(boundary);

  if (wktBoundary === undefined) {
    console.error('Could not extract Wkt String from boundary.');

    throw new Error('Error in extracting wktString from boundary.');
  }

  const flightPlan = new PerspectiveFlightPlan(
    wktBoundary,
    gimbalPitch,
    maxFlightTime,
    altitude,
    speed,
    false
  );

  return flightPlan.get_response() as PerspectiveOutput;
};
