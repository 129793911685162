import classnames from 'classnames';
import { saveAs } from 'file-saver';
import { Marker } from 'google-maps-react';
import * as insideGeo from 'point-in-geopolygon';
import * as React from 'react';
import { Modal, Tabs } from 'antd';
import { Button } from '../../Button';
import greenMarker from '../../Exterior360/marker_green.png';
import redMarker from '../../Exterior360/marker_red.png';
import BoundaryMap from '../BoundaryMap';
import style from './GCPs.module.scss';
import { DATA_AUTO_POLLING_TIMEOUT } from '../../../constants';
import { BASE_CAPI_URL } from '../../../constants/urls';
import { log } from '../../../utils/log';
import MissionV2NavigatorApis from '../../../api/missionV2Navigator';
import { GenericObjectType } from '../../../shapes/app';
import HelpTooltip from '../../HelpTooltip/HelpTooltip';
import { createGCPPlan } from './utils';
import {
  GcpPlanSaveRequest,
  GcpPlanData,
  GroundControlPoint,
} from '../../../api/mission.types';
import { FlightPlanStatus } from '../../../shapes/missions';

const { TabPane } = Tabs;
const missionV2NavigatorApis = new MissionV2NavigatorApis();

interface IProps {
  aoiId: string;
  projectId: string;
  missionId: string;
  epsgCode: string;
  plan: any;
  gcpPlanData?: GcpPlanData;
  onPlanChange?: (p: any) => void;
  getGcpPlanData: (planId: string) => void;
  getGcpPlans: (projectId: string, aoiId: string, missionId: string) => void;
  updateGcpPointsData: (newGcpPointsData: any) => void;
  updateGcpPlanData: (plan: GcpPlanData) => void;
  openUploadModal: (plan: GenericObjectType) => void;
  history: any;
}

interface IState {
  maxCount: string;
  radius: string;
  icp: string;
  tabIndex: string;
  boundary: any;
  downloading: boolean;
  isFinalizing: boolean;
  enableGenerateBtn: boolean;
  enableCancelBtn: boolean;
}

class GCPs extends React.PureComponent<IProps, IState> {
  private pollGcpDateInterval: any;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      maxCount: '0',
      radius: '0',
      icp: '0',
      tabIndex: '0',
      boundary: [],
      downloading: false,
      isFinalizing: false,
      enableGenerateBtn: true,
      enableCancelBtn: true,
    };
    this.handleNumberChange = this.handleNumberChange.bind(this);

    this.pollGcpDateInterval = null;
  }

  public componentDidMount(): void {
    const { getGcpPlanData, plan } = this.props;
    const planId = plan.id;

    getGcpPlanData(planId);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    const { gcpPlanData } = this.props;

    if (
      nextProps.gcpPlanData?.gcpProcessStatus !== 'INITIATED' &&
      nextProps.plan.status !== 'APPROVED'
    ) {
      this.setState({
        enableGenerateBtn: true,
        enableCancelBtn: true,
      });

      this.clearPollGcpDateInterval();
    } else {
      this.setState({
        enableGenerateBtn: false,
      });
      if (nextProps.gcpPlanData?.gcpProcessStatus === 'INITIATED') {
        this.pollGcpData();
      }
    }

    if (gcpPlanData?.parameters !== nextProps.gcpPlanData?.parameters) {
      this.handleGcpParameters(nextProps.gcpPlanData?.parameters);
      this.handleTabIndex(nextProps.gcpPlanData);
    }
  }

  public componentWillUnmount(): void {
    this.clearPollGcpDateInterval();
  }

  private pollGcpData = (): void => {
    this.pollGcpDateInterval = window.setTimeout(() => {
      this.loadGcpData();
    }, DATA_AUTO_POLLING_TIMEOUT);
  };

  private loadGcpData = () => {
    const { plan, getGcpPlanData, projectId, aoiId, missionId, getGcpPlans } =
      this.props;
    const planId = plan.id;

    getGcpPlanData(planId);
    getGcpPlans(projectId, aoiId, missionId);
  };

  public handleGcpParameters = (parameters: any) => {
    if (parameters) {
      const maxCount =
        parameters.max_count !== null && parameters.chosen_param === 'count'
          ? parameters.max_count
          : 0;
      const radius =
        parameters.radius !== null && parameters.chosen_param === 'coverage'
          ? parameters.radius
          : 0;
      const icp = parameters.icp_count !== null ? parameters.icp_count : 0;

      this.setState({
        maxCount,
        radius,
        icp,
      });
    }
  };

  public handleTabIndex = (gcpPlanData: any) => {
    let tabIndex = '0';

    if (
      gcpPlanData &&
      gcpPlanData?.parameters &&
      gcpPlanData?.parameters.chosen_param === 'coverage'
    ) {
      tabIndex = '1';
    }

    this.setState({ tabIndex });
  };

  public handleNumberChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value,
    } as any);
  };

  public getBoundary = (data: any) => {
    if (data) {
      this.setState({
        boundary: data,
      });
    }
  };

  public addGcp = (lat: any, lng: any) => {
    const { plan } = this.props;
    const planId = plan.id;
    const req = {
      gcp_plan_id: planId,
      latitude: lat,
      longitude: lng,
    };

    fetch(`${BASE_CAPI_URL}/v1/edit_gcp_point`, {
      method: 'POST',
      body: JSON.stringify(req),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status > 299) {
          throw res;
        }

        return res.json();
      })
      .then((gcp) => {
        this.addGcpMarker(gcp);
      })
      .catch(() => {
        /**/
      });
  };

  private addGcpMarker = (gcp: GroundControlPoint) => {
    const { gcpPlanData, updateGcpPointsData } = this.props;
    const newGcpData = gcpPlanData?.gcpPoints;

    newGcpData?.push(gcp);
    updateGcpPointsData(newGcpData);
  };

  private generateBtnText = (): string => {
    const { gcpPlanData } = this.props;

    if (gcpPlanData?.gcpProcessStatus === 'INITIATED') {
      return 'Generating plan...';
    }

    return 'Generate';
  };

  private uploadDataBtnText = (): string => {
    const { plan, gcpPlanData } = this.props;

    return (plan.gcpDataStatus === 'UPLOADED' ||
      plan.gcpDataStatus === 'APPROVED') &&
      gcpPlanData?.gcpProcessStatus !== 'INITIATED'
      ? 'View Uploaded Data'
      : 'Upload Data';
  };

  private allowGenerateBtn = (): boolean => {
    const { gcpPlanData, plan } = this.props;
    const { enableGenerateBtn } = this.state;

    if (gcpPlanData?.gcpProcessStatus === 'INITIATED') {
      return false;
    }

    if (['CREATED', 'GENERATED'].indexOf(plan.status) < 0) {
      return false;
    }

    if (!this.canSubmit()) {
      return false;
    }

    return enableGenerateBtn;
  };

  private allowPlanFinalizeBtn = (): boolean => {
    const { gcpPlanData, plan } = this.props;
    const { enableGenerateBtn } = this.state;

    if (
      gcpPlanData?.gcpProcessStatus === 'INITIATED' ||
      plan.status !== 'GENERATED'
    ) {
      return false;
    }

    return enableGenerateBtn;
  };

  private allowDataViewButton = (): boolean => {
    const { gcpPlanData } = this.props;

    return gcpPlanData?.gcpProcessStatus !== 'INITIATED';
  };

  private gcpGenerationInProgress = (): boolean => {
    const { gcpPlanData } = this.props;

    return gcpPlanData?.gcpProcessStatus === 'INITIATED';
  };

  private enableCancelBtn = (): boolean => {
    const { enableCancelBtn } = this.state;

    return enableCancelBtn;
  };

  private allowDownloadBtn = (): boolean => {
    const { gcpPlanData } = this.props;
    const { downloading } = this.state;

    if (gcpPlanData?.gcpProcessStatus === 'INITIATED') {
      return false;
    }

    if (
      ['GENERATED', 'UPLOADED', 'COMPLETED', 'APPROVED'].indexOf(
        gcpPlanData?.status || ''
      ) < 0
    ) {
      return false;
    }

    if (downloading) {
      return false;
    }

    let validGcpPresent = false;

    for (let i = 0; i < (gcpPlanData?.gcpPoints ?? []).length; i += 1) {
      const gcpPoints = gcpPlanData?.gcpPoints ?? [];

      if (
        !gcpPoints[i].deleted &&
        !Number.isNaN(parseFloat(`${gcpPoints[i].latitude}`)) &&
        !Number.isNaN(parseFloat(`${gcpPoints[i].longitude}`))
      ) {
        validGcpPresent = true;
        break;
      }
    }

    return validGcpPresent;
  };

  private clearPollGcpDateInterval = (): void => {
    window.clearInterval(this.pollGcpDateInterval);
    this.pollGcpDateInterval = null;
  };

  private handleFinalizeModalClose = () => {
    this.setState({
      isFinalizing: false,
    });
  };

  private onMarkerDoubleClick = (id: any) => {
    fetch(`${BASE_CAPI_URL}/v1/delete_gcp_point`, {
      method: 'DELETE',
      body: JSON.stringify({ gcp_id: id }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then()
      .then((res) => {
        if (res.status > 299) {
          throw res;
        }

        return res.json();
      })
      .then((res) => {
        if (res.status === 'success') {
          this.deletePointById(id);
        }
      })
      .catch(() => {
        /**/
      });
  };

  private deletePointById = (id: any) => {
    const { gcpPlanData, updateGcpPointsData } = this.props;
    const tempGcpPointsData = gcpPlanData?.gcpPoints.filter(
      (data: any) => data.id !== id
    );

    updateGcpPointsData(tempGcpPointsData);
  };

  private onMarkerDragEnd = (coord: any, id: any) => {
    const { plan } = this.props;
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const req = {
      gcp_plan_id: plan.id,
      gcp_id: id,
      latitude: lat,
      longitude: lng,
    };
    const gcp = this.getGCPById(id);
    const { boundary } = this.state;

    if (gcp && boundary && insideGeo.feature(boundary, [lng, lat]) !== -1) {
      fetch(`${BASE_CAPI_URL}/v1/edit_gcp_point`, {
        method: 'POST',
        body: JSON.stringify(req),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status > 299) {
            throw res;
          }

          return res.json();
        })
        .then(() => {
          this.updatePoint({
            gcp_id: id,
            latitude: lat,
            longitude: lng,
          });
        })
        .catch(() => {
          // revert GCP to old location
          this.updatePoint({
            gcp_id: id,
            latitude: gcp.latitude,
            longitude: gcp.longitude,
          });
        });
    } else {
      this.updatePoint({
        gcp_id: id,
        latitude: gcp?.latitude,
        longitude: gcp?.longitude,
      });
    }
  };

  private getGCPById = (id: string) => {
    const { gcpPlanData } = this.props;

    // eslint-disable-next-line no-restricted-syntax
    for (const gcp of gcpPlanData?.gcpPoints || []) {
      if (gcp.id === id) {
        return gcp;
      }
    }

    return null;
  };

  private updatePoint = (req: any) => {
    const { gcpPlanData, updateGcpPointsData } = this.props;
    const tempGcpPointsData = gcpPlanData?.gcpPoints;

    // eslint-disable-next-line array-callback-return
    tempGcpPointsData?.map((p) => {
      if (p.id === req.gcp_id) {
        // eslint-disable-next-line no-param-reassign
        p.latitude = req.latitude;
        // eslint-disable-next-line no-param-reassign
        p.longitude = req.longitude;
      }
    });
    updateGcpPointsData(tempGcpPointsData);
  };

  private renderStatusUpdate = () => {
    if (this.gcpGenerationInProgress()) {
      return (
        <div>
          <p className={style.renderWarning}>
            The GCP plan generation is in progress. Click CANCEL to terminate
            it.
          </p>
        </div>
      );
    }

    const { tabIndex } = this.state;

    return (
      <div>
        <p className={style.renderWarning}>
          Please ensure that
          {tabIndex === '0' ? ' the max GCP count ' : ' the radius '}
          is a non zero value to enable the generate button.
        </p>
      </div>
    );
  };

  private canSubmit = () => {
    const { icp, maxCount, radius, tabIndex } = this.state;

    return (
      ((tabIndex === '0' && maxCount && parseInt(maxCount, 10) > 0) ||
        (tabIndex === '1' && radius && parseInt(radius, 10) > 0)) &&
      parseInt(icp, 10) >= 0
    );
  };

  private createReq = (): Omit<GcpPlanSaveRequest, 'gcps' | 'icps'> => {
    const { icp, maxCount, radius, tabIndex } = this.state;

    return {
      coverageRadius: tabIndex === '0' ? 0 : parseInt(radius, 10),
      gcpCount: tabIndex === '1' ? 0 : parseInt(maxCount, 10),
      icpCount: parseInt(icp, 10),
      mode: tabIndex === '0' ? 'count' : 'coverage',
    };
  };

  private handleGenerate = async () => {
    const { boundary, icp, maxCount, radius } = this.state;

    this.setState({ enableGenerateBtn: false });
    const req = this.createReq();

    const plan = createGCPPlan({
      boundary,
      icpCount: parseInt(icp, 10),
      gcpCount: parseInt(maxCount, 10),
      coverageRadius: parseInt(radius, 10),
      mode: 'count',
    });

    const gcps = plan?.gcps.map((p) => p.getFirstCoordinate()) || [];
    const icps = plan?.icps.map((p) => p.getFirstCoordinate()) || [];

    await this.saveGcpPlan({
      ...req,
      gcps,
      icps,
    });
  };

  private handleDownload = () => {
    this.setState({ downloading: true });
    const { plan } = this.props;
    const planId = plan.id;

    this.downloadGcpKmlFile(planId);
  };

  private handleTabChange = (tabIndex: string): void => {
    this.setState({ tabIndex });
  };

  private updateGcpPlanState = (gcpPlan: GcpPlanData) => {
    const { updateGcpPointsData, onPlanChange } = this.props;

    updateGcpPointsData(gcpPlan.gcpPoints);
    if (onPlanChange) onPlanChange(gcpPlan);
  };

  private saveGcpPlan = (req: GcpPlanSaveRequest) => {
    const { plan } = this.props;

    return fetch(`${BASE_CAPI_URL}/v1/gcp_plan_data/${plan.id}`, {
      method: 'POST',
      body: JSON.stringify(req),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (res) => {
        if (res.status === 200) {
          const data = await res.json();

          this.updateGcpPlanState(data);
        }
      })
      .finally(() => {
        this.setState({ enableGenerateBtn: true });
      });
  };

  private downloadGcpKmlFile = (planId: any) => {
    fetch(`${BASE_CAPI_URL}/v1/download_gcp_kml?gcpPlanId=${planId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status > 299) {
          throw response;
        }

        response.blob().then((responseBlob) => {
          const fileName = 'gcp_plan.zip';
          const blob = new Blob([responseBlob], {
            type: response.headers.get('Content-Type') || undefined,
          });

          saveAs(blob, fileName);
          this.setState({ downloading: false });
        });
      })
      .catch(() => {
        this.setState({ downloading: false });
      });
  };

  private updatePlanStatus = (gcpStatus: string) => {
    const { plan, onPlanChange, gcpPlanData, updateGcpPlanData } = this.props;

    const formData = {
      status: gcpStatus,
    };

    missionV2NavigatorApis
      .updatePlanStatus(plan.id, formData)
      .then((res) => {
        const { error: apiError } = res;

        if (apiError) {
          log.error(apiError, 'EditMission -> surveyPlanning -> GCPUpload');

          return;
        }

        const { status } = res.data.data;

        if (onPlanChange) {
          onPlanChange({ ...plan, status });
        }

        if (gcpPlanData)
          updateGcpPlanData({
            ...gcpPlanData,
            status: status as FlightPlanStatus,
          });
      })
      .finally(() => {
        this.setState({
          isFinalizing: false,
        });
      });
  };

  private handleFinalizeConfirmed = () => {
    this.updatePlanStatus('APPROVED');
  };

  private openFinalizeModel = () => {
    this.setState({
      isFinalizing: true,
    });
  };

  private showDeducedValue = (
    gcpPlanData: GenericObjectType,
    paramType: string
  ): string => {
    if (
      gcpPlanData &&
      gcpPlanData?.parameters &&
      gcpPlanData?.parameters.chosen_param
    ) {
      if (
        paramType === 'coverage' &&
        gcpPlanData?.parameters.radius &&
        gcpPlanData?.parameters.chosen_param === 'count'
      ) {
        return `Coverage radius for each control point: ${gcpPlanData?.parameters.radius} meters`;
      }

      if (
        paramType === 'count' &&
        gcpPlanData?.parameters.max_count &&
        gcpPlanData?.parameters.chosen_param === 'coverage'
      ) {
        return `Number of GCPs generated: ${gcpPlanData?.parameters.max_count}`;
      }
    }

    return '';
  };

  public render(): React.ReactNode {
    const { projectId, aoiId, plan, gcpPlanData } = this.props;
    const { downloading, icp, maxCount, radius, tabIndex, isFinalizing } =
      this.state;
    const canEdit = plan.status !== 'UPLOADED';

    const gcpPoints = gcpPlanData?.gcpPoints || [];
    const deducedVal = this.showDeducedValue(
      gcpPlanData,
      tabIndex === '0' ? 'coverage' : 'count'
    );

    return (
      <React.Fragment>
        <div>
          <HelpTooltip
            helpText={
              <div className={classnames(style.flexHalfLeft, style.padLeft)}>
                <li style={{ listStyleType: 'circle' }} className={style.list}>
                  GCP Plan will be a KML file that can be imported into Google
                  Maps on your phone and used on site.
                </li>
                <li style={{ listStyleType: 'circle' }} className={style.list}>
                  Along with the plan, an instruction and recommendation file
                  will also be generated. Please follow the instructions
                  provided.
                </li>
                <li style={{ listStyleType: 'circle' }} className={style.list}>
                  To improve accuracy, place more numbers of GCPs, specially in
                  areas with varying terrain elevations. Please follow GCP
                  placement guidelines specified in the instructions document.
                </li>
                <li style={{ listStyleType: 'circle' }} className={style.list}>
                  Upload CSV Format is mentioned in the instructions. It is very
                  important to adhere to this format.
                </li>
              </div>
            }
          />
          <div>
            <div>
              <div>
                {canEdit ? (
                  <label className={style.instructionLabel}>
                    Drag GCPs (marker pins) to change their position. Double
                    click a GCP to delete. Click within the boundary to add a
                    new GCP.
                  </label>
                ) : (
                  <label className={style.instructionLabel}>
                    This plan can no longer be edited
                  </label>
                )}
              </div>
              <div className={style.boundaryMapCont}>
                <BoundaryMap
                  projectId={projectId}
                  aoiId={aoiId}
                  className={style.boundaryMap}
                  addPoint={
                    canEdit
                      ? this.addGcp
                      : () => {
                          /**/
                        }
                  }
                  getBoundary={this.getBoundary}
                  synBoundaryOnce
                >
                  {gcpPoints.map((p) => (
                    <Marker
                      draggable={canEdit}
                      onDragend={
                        canEdit
                          ? (firstArg: any, secondArg: any, coord: any) =>
                              this.onMarkerDragEnd(coord, p.id)
                          : () => {
                              /**/
                            }
                      }
                      onDblclick={
                        canEdit
                          ? () => this.onMarkerDoubleClick(p.id)
                          : () => {
                              /**/
                            }
                      }
                      key={p.id}
                      position={{
                        lat: p.latitude,
                        lng: p.longitude,
                      }}
                      icon={{
                        url: p.checkpoint ? greenMarker : redMarker,
                      }}
                    />
                  ))}
                </BoundaryMap>
              </div>
              <div className={style.gcpGenContainer}>
                <Tabs
                  centered
                  tabBarStyle={{ textAlign: 'center' }}
                  className={style.antdTab}
                  activeKey={tabIndex}
                  onChange={(tabIndex: string) =>
                    this.handleTabChange(tabIndex)
                  }
                >
                  <TabPane
                    tab={<span className={style.tabSpan}>GCP Count</span>}
                    key="0"
                  >
                    {deducedVal ? (
                      <p className={style.renderWarning}>{deducedVal}</p>
                    ) : null}

                    <div className={style.gcpGenFormInput}>
                      <div className={style.gcpGenFormInputInnerWrapper}>
                        <input
                          name="maxCount"
                          value={maxCount}
                          type="number"
                          step="1"
                          onChange={this.handleNumberChange}
                        />
                      </div>
                      <div>
                        <label className={style.instructionLabel}>
                          Max count (Max number of GCPs to be generated)
                        </label>
                      </div>
                    </div>
                    <div className={style.gcpGenFormInput}>
                      <div className={style.gcpGenFormInputInnerWrapper}>
                        <input
                          name="icp"
                          value={icp}
                          type="number"
                          step="1"
                          onChange={this.handleNumberChange}
                        />
                      </div>
                      <div>
                        <label className={style.instructionLabel}>
                          Number of ICPs (Independent Check Points)
                        </label>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    tab={<span className={style.tabSpan}>GCP Coverage</span>}
                    key="1"
                  >
                    {deducedVal ? (
                      <p className={style.renderWarning}>{deducedVal}</p>
                    ) : null}
                    <div className={style.gcpGenFormInput}>
                      <div>
                        <input
                          name="radius"
                          value={radius}
                          type="number"
                          step="1"
                          onChange={this.handleNumberChange}
                        />
                      </div>
                      <div>
                        <label className={style.instructionLabel}>
                          Radius (Coverage radius for each control point in
                          meters)
                        </label>
                      </div>
                    </div>
                    <div className={style.gcpGenFormInput}>
                      <div>
                        <input
                          name="icp"
                          value={icp}
                          type="number"
                          step="1"
                          onChange={this.handleNumberChange}
                        />
                      </div>
                      <div>
                        <label className={style.instructionLabel}>
                          Number of ICPs (Independent Check Points)
                        </label>
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
                {this.renderStatusUpdate()}
                <div className={style.gcpActions}>
                  <div className={style.gcpUpdateContainer}>
                    <div className={style.gcpUpdateAction}>
                      <Button
                        className={style.actionBtn}
                        disabled={!this.allowGenerateBtn()}
                        onClick={this.handleGenerate}
                        text={this.generateBtnText()}
                      />
                    </div>
                    <div className={style.gcpUpdateAction}>
                      <Button
                        className={style.actionBtn}
                        disabled={!this.allowPlanFinalizeBtn()}
                        onClick={this.openFinalizeModel}
                        text="FINALIZE PLAN"
                      />
                    </div>
                  </div>
                  <div className={style.gcpDownloadContainer}>
                    <Button
                      fullWidth
                      className={style.actionBtn}
                      disabled={!this.allowDownloadBtn()}
                      onClick={this.handleDownload}
                      loading={downloading}
                      loadingText="Downloading..."
                      text="Download"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Finalize this plan."
          centered
          footer={null}
          visible={isFinalizing}
          onCancel={this.handleFinalizeModalClose}
          destroyOnClose
          maskClosable={false}
        >
          <p className={style.headertext}>
            You will not be able to make changes to this plan after this.
          </p>
          <div className={style.btnFinishDiv}>
            <Button
              className={style.btnFinalize}
              onClick={this.handleFinalizeModalClose}
              text="No"
            />
            <Button
              className={style.btnFinalize}
              onClick={this.handleFinalizeConfirmed}
              text="Yes"
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default GCPs;
