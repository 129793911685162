import * as React from 'react';

import ViewDataManager from '../ViewDataManager/container';
import ViewV2 from '../ViewV2/container';

export default class TestView extends React.PureComponent<any> {
  public render() {
    const { test } = this.props;

    return test ? (
      <ViewV2 {...this.props} />
    ) : (
      <ViewDataManager {...(this.props as any)} />
    );
  }
}
