import * as React from 'react';
import style from './Pagination.module.scss';

interface IProps {
  pages: number;
  page: number;
  PageButtonComponent: any;
  onPageChange: any;
  previousText: string;
  nextText: string;
}

interface IState {
  visiblePages: any;
}

const defaultButton = (props: any): React.ReactNode => {
  const { children } = props;

  return <button {...props}>{children}</button>;
};

export default class Pagination extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.changePage = this.changePage.bind(this);
  }

  private filterPages = (visiblePages: any, totalPages: any) => {
    return visiblePages.filter((page: any) => page <= totalPages);
  };

  private getVisiblePages = (page: any, total: any) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    }

    if (total > 7) {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      }

      if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      }

      return [1, 2, 3, 4, 5, total];
    }
  };

  private changePage(type: string): undefined | void {
    const { page, onPageChange } = this.props;
    let activePage = null;

    if (type === 'prev') {
      activePage = page - 1;
    } else {
      activePage = page + 1;
    }

    if (page === activePage) {
      return;
    }

    onPageChange(activePage);
  }

  public render(): React.ReactNode {
    const {
      PageButtonComponent = defaultButton,
      page,
      pages,
      previousText,
      nextText,
    } = this.props;
    const activePage = page + 1;

    return (
      <div className={style.controls}>
        <div className="Table__prevPageWrapper">
          <PageButtonComponent
            className={style.controlsbtn}
            onClick={() => {
              if (activePage === 1) {
                return;
              }

              this.changePage('prev');
            }}
            disabled={activePage === 1}
          >
            {previousText}
          </PageButtonComponent>
        </div>
        <div className="Table__nextPageWrapper">
          <PageButtonComponent
            className={style.controlsbtn}
            onClick={() => {
              if (activePage === pages) {
                return;
              }

              this.changePage('next');
            }}
            disabled={activePage === pages}
          >
            {nextText}
          </PageButtonComponent>
        </div>
      </div>
    );
  }
}
