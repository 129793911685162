import { PaymentsMethodsTypes } from './index.types';

export const paymentMethodsListData: PaymentsMethodsTypes[] = [
  {
    type: 'authorizeNet',
    label: 'Debit/Credit Cards',
    default: true,
  },
];

export const authorizeNetData = {
  currencyList: ['USD', 'INR'],
};
