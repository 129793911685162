import * as React from 'react';
import { Upload, Tooltip } from 'antd';
import style from './UploadButton.module.scss';
import { Button } from '../Button';
import _BrandingApi from '../../api/branding';
import { isArray, undefinedOrNull } from '../../utils/functs';
import HelpTooltip from '../HelpTooltip';
import { SnackbarActionsActionShowSnackbarTypes } from '../../shapes/snackbar';
import ProjectV2Apis from '../../api/projectsV2';
import { WhiteLabellingServiceTypeTypes } from './index.types';

const BrandingApi = new _BrandingApi();
const projectV2Apis = new ProjectV2Apis();

interface IProps {
  size: string;
  showSnackbar: SnackbarActionsActionShowSnackbarTypes;
  onAlerts: (key: string, isSuccess: boolean, error: null | string) => void;
  handleLogoUpdate: () => void;
  serviceType: WhiteLabellingServiceTypeTypes;
  projectId?: string;
  organisationId?: string;
}

interface IState {
  logoFileList: any[];
  uploading: boolean;
  uploadKey: number;
}

export default class UploadButton extends React.PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      logoFileList: [],
      uploading: false,
      uploadKey: Date.now(),
    };
  }

  private handleUpload = (size: string) => {
    const {
      handleLogoUpdate,
      showSnackbar,
      serviceType,
      projectId,
      organisationId,
    } = this.props;
    const { logoFileList } = this.state;
    const formData = new FormData();

    logoFileList.forEach((file: any) => {
      formData.append('image', file);
    });

    this.setState({
      uploading: true,
    });

    if (serviceType === 'project' && !undefinedOrNull(projectId)) {
      projectV2Apis
        .uploadLogo(projectId, size as 'small' | 'large', formData)
        .then((res): void => {
          const { error, errorMessage } = res;

          if (!undefinedOrNull(error)) {
            this.setState({
              uploading: false,
            });

            showSnackbar({
              body: errorMessage ?? error,
              type: 'error',
            });

            return;
          }

          this.setState({
            uploading: false,
            logoFileList: [],
            uploadKey: Date.now(),
          });

          handleLogoUpdate();

          showSnackbar({
            body: 'Upload was successful',
            type: 'success',
            isModal: false,
          });
        });

      return;
    }

    if (undefinedOrNull(organisationId)) {
      console.error('Organisation Id must be specified, to upload logo');

      return;
    }

    BrandingApi.uploadLogo(organisationId, size, formData).then((res): void => {
      const { error, errorMessage } = res;

      if (!undefinedOrNull(error)) {
        this.setState({
          uploading: false,
        });

        showSnackbar({
          body: errorMessage ?? error,
          type: 'error',
        });

        return;
      }

      this.setState({
        uploading: false,
        logoFileList: [],
        uploadKey: Date.now(),
      });

      handleLogoUpdate();

      showSnackbar({
        body: 'Upload was successful',
        type: 'success',
        isModal: false,
      });
    });
  };

  public uploadProps = () => {
    const { logoFileList } = this.state;
    const { onAlerts } = this.props;

    return {
      onRemove: (file: any) => {
        this.setState(({ logoFileList }) => {
          const index = logoFileList.indexOf(file);
          const newLogoFileList = logoFileList.slice();

          newLogoFileList.splice(index, 1);

          return {
            logoFileList: newLogoFileList,
          };
        });
      },

      beforeUpload: (file: any) => {
        if (file.type !== 'image/png') {
          onAlerts(
            'uploadBrandingAlerts',
            false,
            'Image format should be type png'
          );
        } else {
          this.setState((state) => ({
            logoFileList: [...state.logoFileList, file],
          }));
        }

        return false;
      },
      // TODO: Fix types and remove 'any'
      listType: 'picture' as any,
      logoFileList,
      showUploadList: false,
    };
  };

  private getButtonText = () => {
    const { serviceType, size } = this.props;

    if (size !== 'small') {
      return 'SELECT LOGIN SCREEN LOGO';
    }

    if (serviceType === 'settings') {
      return 'SELECT BRAND LOGO';
    }

    return 'SELECT PROJECT LOGO';
  };

  private getUploadedPreviewImageData = () => {
    const { logoFileList } = this.state;

    if (!logoFileList || !isArray(logoFileList) || logoFileList.length < 1) {
      return null;
    }

    const imageUrl = URL.createObjectURL(logoFileList[0]);
    const imageName = logoFileList[0].name;

    return {
      imageUrl,
      imageName,
    };
  };

  private resetLogoFileList = () => {
    this.setState({
      logoFileList: [],
      uploadKey: Date.now(),
    });
  };

  private getHelpText = () => {
    const { size } = this.props;

    switch (size) {
      case 'large':
        return 'Logo should be in png format, height and width should not exceed 2000 px.';
      case 'small':
        return 'Logo should be in png format, height and width should not exceed 500 px.';
      default:
        return 'Logo should be in png format, height and width should not exceed 200 px';
    }
  };

  public render() {
    const { size, serviceType } = this.props;
    const { uploading, logoFileList, uploadKey } = this.state;

    const uploadedPreviewImageData = this.getUploadedPreviewImageData();

    return (
      <div className={style.container}>
        <div className={style.uploadLogoWrapper}>
          {logoFileList.length > 0 ? (
            <React.Fragment>
              {logoFileList.length === 0 || uploading ? (
                <button
                  type="button"
                  onClick={this.handleUpload.bind(this, size)}
                  disabled
                  className={`ant-btn ant-btn-primary ${style.uploadBtn} ${style.pickBtn}`}
                >
                  Uploading...
                </button>
              ) : (
                <Tooltip
                  placement="topRight"
                  title="Click to upload"
                  overlayStyle={{ zIndex: 10001 }}
                  visible
                >
                  <button
                    type="button"
                    onClick={this.handleUpload.bind(this, size)}
                    className={`ant-btn ant-btn-primary ${style.uploadBtn} ${style.pickBtn}`}
                  >
                    Upload
                  </button>
                </Tooltip>
              )}
            </React.Fragment>
          ) : null}

          <Upload {...this.uploadProps()} key={uploadKey}>
            {logoFileList.length < 1 ? (
              <Button
                type="secondary"
                text={
                  serviceType === 'settings'
                    ? 'UPLOAD LOGO'
                    : 'SELECT PROJECT LOGO'
                }
                icon="upload"
                className={style.pickBtn}
              />
            ) : null}

            <HelpTooltip position="bottom" helpText={this.getHelpText()} />
          </Upload>
        </div>

        {uploadedPreviewImageData && logoFileList.length > 0 && (
          <div className={style.previewContainer}>
            <div className={style.previewHeadingWrapper}>
              <span>Preview</span>
              <span>
                <i
                  className="fa fa-times-circle"
                  onClick={() => {
                    if (uploading) {
                      return;
                    }

                    this.resetLogoFileList();
                  }}
                />
              </span>
            </div>
            <div>
              <img
                src={uploadedPreviewImageData.imageUrl}
                alt={uploadedPreviewImageData.imageName}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
