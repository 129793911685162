import ApiBase from './ApiBase';
import { isString } from '../utils/functs';

export default class Issue extends ApiBase {
  public async createIssue(
    viewId: string,
    title: string,
    message: string,
    config: any,
    shapeGeoJson: string | null,
    screenshot?: string | PromiseLike<string | null>,
    tagIds?: string[]
  ): Promise<any> {
    let screenshotContents: string | undefined;

    if (screenshot) {
      if (isString(screenshot)) {
        // eslint-disable-next-line prefer-destructuring
        screenshotContents = screenshot.split(',')[1];
      } else {
        await screenshot.then((res: null | string) => {
          if (!res) {
            screenshotContents = undefined;

            return;
          }

          // eslint-disable-next-line prefer-destructuring
          screenshotContents = res.split(',')[1];
        });
      }
    }

    return this.post('/v1/issues', {
      viewId,
      title,
      message,
      config,
      shapeGeoJson,
      screenshotContents,
      tagIds,
    });
  }

  public fetchIssues(): Promise<any> {
    return this.get('/v1/issues').then((response) => {
      // Sorting issues before passing to state
      response.data.sort((a: any, b: any) => {
        if (a.createdAt > b.createdAt) {
          return 1;
        }

        if (a.createdAt < b.createdAt) {
          return -1;
        }

        return 0;
      });

      return response;
    });
  }

  public fetchIssuesByAOI(
    projectId: string,
    aoiId: string,
    showResolved: boolean = false,
    offset: number = 0,
    limit: number = 10,
    tagId: string = ''
  ): Promise<any> {
    return this.get(
      `/v1/issues/project/${projectId}?resolved=${showResolved}&offset=${offset}&limit=${limit}&aoiId=${aoiId}&tagId=${tagId}`
    );
  }

  public fetchIssuesByProject(
    projectId: string,
    showResolved: boolean = false,
    offset: number = 0,
    limit: number = 10,
    tagId: string = ''
  ): Promise<any> {
    return this.get(
      `/v1/issues/project/${projectId}?resolved=${showResolved}&offset=${offset}&limit=${limit}&tagId=${tagId}`
    );
  }

  public fetchIssuesStatusByProject(projectId: string): Promise<any> {
    return this.get(`/v1/issues/project/${projectId}/status`);
  }

  public fetchIssuesStatusByAOI(
    projectId: string,
    aoiId: string
  ): Promise<any> {
    return this.get(`/v1/issues/project/${projectId}/status?aoiId=${aoiId}`);
  }

  public fetchIssue(issueId: string): Promise<any> {
    return this.get(`/v1/issues/${issueId}`);
  }

  public fetchIssueComments(
    issueId: string,
    offset: number = 0,
    limit: number = 10
  ): Promise<any> {
    return this.get(
      `/v1/issues/${issueId}/comments?offset=${offset}&limit=${limit}`
    );
  }

  public createComment(issueId: string, commentMessage: string): Promise<any> {
    return this.post(`/v1/issues/${issueId}/comments`, {
      message: commentMessage,
    });
  }

  public resolveIssue(issueId: string): Promise<any> {
    return this.post(`/v1/issues/${issueId}/resolve`, {});
  }

  public fetchTagsByProject(projectId: string): Promise<any> {
    return this.get(`/v1/issues/${projectId}/tags`);
  }

  public createTagForProject(projectId: string, tagText: string): Promise<any> {
    return this.post(`/v1/issues/${projectId}/tags`, {
      tagName: tagText,
    });
  }

  // delete single tag
  public deleteTag(projectId: string, issueTagId: string): Promise<any> {
    return this.delete(`/v1/issues/${projectId}/tags/${issueTagId}`);
  }

  // deletes multiple tags
  public deleteTags(projectId: string, tagIds: string[]): Promise<any> {
    return this.post(`/v1/issues/${projectId}/tags/bulkDelete`, {
      tagIds,
    });
  }

  // updates tag text for given tagId if we make typo mistake
  public updateTag(
    projectId: string,
    issueTagId: string,
    tagText: string
  ): Promise<any> {
    return this.patch(`/v1/issues/${projectId}/tags/${issueTagId}`, {
      tagName: tagText,
    });
  }

  public updateIssue(
    issueId: string,
    data: { title?: string; message?: string; tagIds?: Array<string> }
  ): Promise<any> {
    const { title, message, tagIds } = data;

    return this.patch(`/v1/issues/${issueId}`, {
      title,
      message,
      tagIds,
    });
  }

  public shareIssueByTagId(tagId: string, userIds: string[]): Promise<any> {
    return this.post(`/v1/issues/issues/shares/${tagId}`, {
      userIds,
    });
  }
}
