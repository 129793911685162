import { Map } from 'ol';
import Layer from 'ol/layer/Layer';
import * as React from 'react';
import { getViewRendererLayers } from '../utils';
import { DEFAULT_OVERLAY_LAYER_Z_INDEX } from '../../../../../constants';

interface IProps {
  olMap?: Map;
  view: any;
  opacity: number;
}

interface IState {
  layers: Layer[];
}

export default class OverlayControl extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      layers: [],
    };
  }

  public componentDidMount() {
    const { olMap, view, opacity } = this.props;

    this.updateLayers(olMap, view, opacity);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { olMap: nextMap, view: nextView, opacity: nextOpacity } = nextProps;
    const { olMap, view, opacity } = this.props;

    if (olMap !== nextMap || view !== nextView) {
      this.updateLayers(nextMap, nextView, opacity);
    }

    if (opacity !== nextOpacity) {
      const { layers } = this.state;

      // eslint-disable-next-line no-restricted-syntax
      for (const l of layers) {
        l.setOpacity(nextOpacity / 100 || 0.0);
      }
    }
  }

  public componentWillUnmount() {
    const { layers } = this.state;
    const { olMap } = this.props;

    if (olMap && layers.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const l of layers) {
        olMap.removeLayer(l);
      }
    }
  }

  private updateLayers(olMap: Map | undefined, view: any, _opacity: number) {
    const { layers } = this.state;

    if (olMap) {
      // eslint-disable-next-line no-restricted-syntax
      for (const l of layers || []) {
        olMap.removeLayer(l);
      }
    }

    getViewRendererLayers(view, () => {
      return { min: 0, max: 0 };
    }).then((renderLayers) => {
      const layers = (renderLayers || [])
        .filter((rl) => rl?._layer)
        .map((rl) => rl._layer) as Layer[];

      if (olMap) {
        layers.forEach((l, idx) => {
          l.setZIndex(DEFAULT_OVERLAY_LAYER_Z_INDEX + idx);
          l.setOpacity(_opacity / 100);
          olMap.addLayer(l);
        });

        this.setState({ layers });
      }
    });
  }

  public render() {
    return <React.Fragment />;
  }
}
