import { Tooltip } from 'antd';
import * as React from 'react';
import { APP_BASE_URL, BASE_CAPI_URL } from '../../../../constants/urls';
import * as ControlBox from '../../../ControlBox/ControlBox';
import Icon from '../../../Icon';
import { ViewConfig } from '../../index.types';
import { MapStateProvider } from '../../MapView/OpenLayersRenderer/MapStateProvider';
import PanoramaStateProvider from '../../PanoramaView/PannellumRenderer/PanoramaStateProvider';
import { ShareControlProps } from '../index.types';
import style from './index.module.scss';

interface IState {
  share?: any;
  copied?: boolean;
  isPublic: boolean;
}

export default class ShareControlBox extends React.Component<
  ShareControlProps,
  IState
> {
  // this is a ref to the MapStateProvider component
  // used for getting map config and screenshot
  private mapStateProviderRef: React.RefObject<MapStateProvider> =
    React.createRef();

  // this is a ref to the PanoramaStateProvider component
  // used for getting pano config and screenshot
  private panoStateProviderRef: React.RefObject<PanoramaStateProvider> =
    React.createRef();

  constructor(props: ShareControlProps) {
    super(props);

    this.state = {
      isPublic: false,
    };
  }

  public componentDidMount() {
    const { view, onRenderFragChange } = this.props;

    switch (view.type) {
      case 'perspective':
      case 'inspection':
      case 'site_navigation':
      case 'map': {
        onRenderFragChange(() => (
          <MapStateProvider
            key="issue-frag-state-provider"
            ref={this.mapStateProviderRef}
            convertToLatLon={view.type === 'map'}
          />
        ));
        break;
      }

      case 'exterior_360':
      case 'interior_360': {
        onRenderFragChange(() => (
          <PanoramaStateProvider
            key="issue-frag-state-provider"
            ref={this.panoStateProviderRef}
          />
        ));
        break;
      }

      default:
        break;
    }
  }

  public componentWillUnmount() {
    const { onRenderFragChange } = this.props;

    onRenderFragChange(() => null);
  }

  private getViewState(): ViewConfig {
    if (this.mapStateProviderRef?.current) {
      return this.mapStateProviderRef.current.getMapState();
    }

    if (this.panoStateProviderRef?.current) {
      return this.panoStateProviderRef.current.getPanoState();
    }

    return {};
  }

  private getViewConfig = () => {
    const { getViewConfig } = this.props;

    const viewConfig: ViewConfig = {
      ...(getViewConfig() || {}),
      ...this.getViewState(),
    };

    return viewConfig;
  };

  private renderHeader = () => {
    const { onClose } = this.props;

    return (
      <React.Fragment>
        <ControlBox.Title title="Share" />
        <ControlBox.Icon
          onClick={() => {
            onClose();
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  private createShare = () => {
    const { view } = this.props;
    const { isPublic } = this.state;

    fetch(`${BASE_CAPI_URL}/v1/shares/create`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        viewId: view.id,
        viewConfig: this.getViewConfig(),
        public: isPublic,
      }),
    })
      .then((res) => res.json())
      .then((share) => {
        this.setState({ share });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  private copy = () => {
    document.execCommand('copy');
    this.setState({
      copied: true,
    });
    window.setTimeout(() => {
      this.setState({ copied: false });
    }, 5000);
  };

  private canCopy = () => {
    try {
      return document.queryCommandSupported('copy');
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  private handleShareLinkCreation = (isPublic: boolean) => {
    this.setState(
      {
        isPublic,
      },
      () => {
        this.createShare();
      }
    );
  };

  public render(): React.ReactNode {
    const { share, copied, isPublic } = this.state;

    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={this.renderHeader}
      >
        <div className={style.container}>
          {share?.id ? (
            <div>
              <div className={style.innerContainer}>
                <input
                  ref={(node: any) => {
                    try {
                      node.focus();
                      node.select();
                    } catch (e) {
                      /**/
                    }
                  }}
                  value={
                    isPublic || share.partnerId === null
                      ? `${APP_BASE_URL}/share/${share.id}`
                      : `${APP_BASE_URL}/share/${share.id}?partnerId=${share.partnerId}`
                  }
                />
                {this.canCopy() && (
                  <Tooltip title="Copy share link">
                    <button onClick={this.copy} className={style.copy}>
                      <i className="fa fa-clipboard" />
                    </button>
                  </Tooltip>
                )}
              </div>
              {copied && (
                <div className={style.copyWrapper}>Copied to clipboard!</div>
              )}
            </div>
          ) : (
            <div>
              <div className={style.slink}>
                <button
                  onClick={() => {
                    this.handleShareLinkCreation(true);
                  }}
                >
                  <Icon name="link" size="small" />
                  <div className={style.publicLink}>Create Public Link</div>
                </button>
              </div>
              <div className={style.slinkdesc}>Anyone can view this.</div>
              <div className={style.slink}>
                <button
                  onClick={() => {
                    this.handleShareLinkCreation(false);
                  }}
                >
                  <Icon name="link" size="small" />
                  <div className={style.privateLink}>Create Private Link</div>
                </button>
              </div>
              <div className={style.slinkdesc}>
                Only people who have account can view.
              </div>
            </div>
          )}
        </div>
      </ControlBox.Wrapper>
    );
  }
}
