import ApiBase from './ApiBase';
import { GenericApisReturnPromiseTypes } from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_CAPI_URL } from '../constants/urls';

export default class FloorPlan extends ApiBase {
  private readonly _baseUrl: string;

  constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = '/v2';
  }

  public postFloorPlan(
    projectId: string,
    aoiId: string,
    data: any
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/projects/${projectId}/aois/${aoiId}/floorPlans`,
      data
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to create the floorPlan.`,
        });
      });
  }

  public listFloorPlans(
    projectId: string,
    aoiId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(
      `${this._baseUrl}/projects/${projectId}/aois/${aoiId}/floorPlans`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the floorPlans.`,
        });
      });
  }

  public getFloorPlanImageUrl(
    projectId: string,
    aoiId: string,
    planId: string
  ): string {
    return `${this.baseUrl}${this._baseUrl}/projects/${projectId}/aois/${aoiId}/floorPlans/${planId}/image`;
  }
}
