import * as React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button as ButtonAntd, Tooltip } from 'antd';
import { undefinedOrNull } from '../../utils/functs';
import styles from './index.module.scss';
import { GenericObjectType } from '../../shapes/app';

interface IProps {
  text?: string;
  size?: 'large' | 'small';
  icon?: undefined | string;
  loading?: boolean;
  loadingText?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  transparent?: boolean;
  onClick?: () => void;
  width?: number | string;
  height?: number | string;
  href?: string;
  type?: 'primary' | 'secondary';
  className?: string;
  style?: any;
  htmlType?: string;
  shape?: 'circle' | 'round';
  toolTip?: boolean;
  helpText?: string;
  toolTipPosition: string;
}

export class Button extends React.Component<IProps> {
  public static defaultProps = {
    type: 'primary',
    toolTipPosition: 'bottom',
  };

  private getLoadingText = () => {
    const { loadingText, text, loading, children } = this.props;

    if (loading && !undefinedOrNull(loadingText) && loadingText.trim() !== '') {
      return loadingText.trim();
    }

    if (!undefinedOrNull(text)) {
      return text;
    }

    if (!undefinedOrNull(children)) {
      return children;
    }

    return null;
  };

  private RenderButton = () => {
    const {
      type,
      icon,
      loading,
      onClick,
      fullWidth,
      transparent,
      disabled,
      width,
      height,
      href,
      className,
      style,
      htmlType,
      shape,
    } = this.props;

    let optionalProps: GenericObjectType = {};

    if (!undefinedOrNull(onClick)) {
      optionalProps = {
        ...optionalProps,
        href,
        onClick,
      };
    }

    return (
      <ButtonAntd
        style={{ ...style, width, height }}
        className={`${styles.button} ${className}`}
        // TODO: fix type and remove 'any'
        type={type as any}
        disabled={disabled}
        loading={loading}
        icon={icon && icon.length > 0 ? <LegacyIcon type={icon} /> : undefined}
        block={fullWidth}
        ghost={transparent}
        htmlType={htmlType as any}
        shape={shape}
        {...optionalProps}
      >
        {this.getLoadingText()}
      </ButtonAntd>
    );
  };

  public render(): React.ReactNode {
    const { helpText, toolTipPosition } = this.props;
    const { RenderButton } = this;

    if (!undefinedOrNull(helpText)) {
      return (
        // TODO: fix type and remove 'any'
        <Tooltip placement={toolTipPosition as any} title={helpText}>
          <RenderButton />
        </Tooltip>
      );
    }

    return <RenderButton />;
  }
}
