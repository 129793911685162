// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OpenLayersView_container__Jrdgf{height:100%;width:100%;display:flex;flex-flow:column}.OpenLayersView_mapContainer__cmdmB{height:100%;width:100%;display:flex}.OpenLayersView_colorScale__nj7UW{top:0;left:0}.OpenLayersView_map__m1HUG{left:60px}", "",{"version":3,"sources":["webpack://./src/components/OpenLayersView/index.module.scss"],"names":[],"mappings":"AAAA,iCACE,WAAA,CACA,UAAA,CACA,YAAA,CACA,gBAAA,CAGF,oCACE,WAAA,CACA,UAAA,CACA,YAAA,CAGF,kCACE,KAAA,CACA,MAAA,CAGF,2BACE,SAAA","sourcesContent":[".container {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-flow: column;\n}\n\n.mapContainer {\n  height: 100%;\n  width: 100%;\n  display: flex;\n}\n\n.colorScale {\n  top: 0;\n  left: 0;\n}\n\n.map {\n  left: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OpenLayersView_container__Jrdgf",
	"mapContainer": "OpenLayersView_mapContainer__cmdmB",
	"colorScale": "OpenLayersView_colorScale__nj7UW",
	"map": "OpenLayersView_map__m1HUG"
};
export default ___CSS_LOADER_EXPORT___;
