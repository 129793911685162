import * as React from 'react';
import { Link } from 'react-router-dom';
import { Table, Typography } from 'antd';
import { DOCUMENTATION_URL_LIST } from '../../constants/urls';
import styles from './index.module.scss';
import { hasAdminAccess, undefinedOrNull } from '../../utils/functs';
import {
  Interior360EditorPropsTypes,
  Interior360EditorStateTypes,
} from './index.types';
import { Button } from '../Button';
import DocumentationLink from '../DocumentationLink';
import { GenericObjectType } from '../../shapes/app';
import { AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE } from '../../constants';
import ViewAPIs from '../../api/viewsV2';
import FloorPlans from '../../api/floorPlans';
import { appFormatDate } from '../../utils/date';
import {
  newInterior360ViewUrl,
  editInterior360viewUrl,
} from '../../routes/urls';
import { log } from '../../utils/log';

const { Title } = Typography;

const viewApis = new ViewAPIs();
const floorPlansApi = new FloorPlans();

class Interior360Manager extends React.PureComponent<
  Interior360EditorPropsTypes,
  Interior360EditorStateTypes
> {
  constructor(props: Interior360EditorPropsTypes) {
    super(props);
    this.state = {
      tableLoading: false,
      interior360Views: [],
      floorPlans: [],
    };
  }

  public componentDidMount(): void {
    this.setState(
      (prevState) => {
        return {
          tableLoading: !prevState.tableLoading,
        };
      },
      () => {
        this.fetchInterior360Views();
      }
    );
  }

  private fetchInterior360Views = () => {
    const { match } = this.props;
    const { projectId, aoiId } = match.params;

    viewApis
      .getViewsByTypeAndPublishStatus(projectId, aoiId, 'interior_360', true)
      .then((res) => {
        this.setState(
          { tableLoading: false, interior360Views: res.data },
          () => {
            this.fetchFloorPlans();
          }
        );
      })
      .catch((err) => {
        console.error(err);
        this.setState({ tableLoading: false });
      });
  };

  private fetchFloorPlans = () => {
    const { match } = this.props;
    const { projectId, aoiId } = match.params;

    floorPlansApi
      .listFloorPlans(projectId, aoiId)
      .then((res) => {
        this.setState({ floorPlans: res.data });
      })
      .catch((err) => {
        log.error(err);
      });
  };

  private handleRowClick = (record: GenericObjectType) => {
    const { history, match } = this.props;
    const { projectId, aoiId } = match.params;

    if (!history) {
      return;
    }

    // links to update view form
    history.push(editInterior360viewUrl(projectId, aoiId, record.id));
  };

  render(): React.ReactNode {
    const { user, match } = this.props;
    const { tableLoading, interior360Views, floorPlans } = this.state;
    const { projectId, aoiId } = match.params;

    const tableColumns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (value: string) => appFormatDate(value),
      },
      {
        title: 'Level',
        dataIndex: 'level',
        key: 'level',
        render: (value: string, data: any) => {
          if (!undefinedOrNull(data.sourceUrl) && floorPlans.length > 0) {
            const floorLevelId = data.sourceUrl.substring(
              data.sourceUrl.lastIndexOf('/') + 1
            );
            const floorInfo = floorPlans.find(
              (floorPlan) => floorPlan.id === floorLevelId
            );

            return !undefinedOrNull(floorInfo) ? floorInfo.floor : '--';
          }

          return '--';
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value: string, data: any) => {
          if (data.certifiedForDisplay) {
            return 'PUBLISHED';
          }

          return 'UNPUBLISHED';
        },
      },
    ];

    return (
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <div className={styles.titleInnerWrapper}>
            <Title level={2}>Interior 360 Views</Title>
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.interior360}
              toolTipPosition="right"
            />
          </div>

          {hasAdminAccess(user, projectId) ? (
            <Link
              className={styles.createItemLink}
              to={newInterior360ViewUrl(projectId, aoiId)}
            >
              <Button transparent text="+ NEW INTERIOR 360 VIEW" />
            </Link>
          ) : null}
        </div>
        <Table
          locale={{ emptyText: 'No Interior 360 view generated yet.' }}
          onRow={(record: GenericObjectType) => {
            return {
              onClick: () => {
                this.handleRowClick(record);
              },
            };
          }}
          rowKey={(interior360Views: any) => interior360Views.id}
          columns={tableColumns}
          loading={tableLoading}
          dataSource={interior360Views}
          pagination={{
            defaultPageSize: AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE,
          }}
        />
      </div>
    );
  }
}

export default Interior360Manager;
