import { Modal } from 'antd';
import * as React from 'react';
import { ConfirmInjectedProps } from 'react-redux-confirm';
import style from './ConfirmModal.module.scss';

class ConfirmModal extends React.Component<ConfirmInjectedProps> {
  public render(): React.ReactNode {
    const { isOpen, onCancel, onConfirm, message, options } = this.props;

    return (
      <Modal
        visible={isOpen}
        className={style.container}
        onCancel={onCancel}
        onOk={onConfirm}
        {...options.modalProps}
      >
        {message}
      </Modal>
    );
  }
}

export default ConfirmModal;
