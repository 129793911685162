// import { posthog } from 'posthog-js';
// import * as fullstory from '@fullstory/browser';
// import LogRocket from 'logrocket';

export const analytics = {
  init: (_userId?: string) => {
    // // fullstory
    // fullstory.init({ orgId: 'o-1D5ZW4-na1' }, () => {
    //   if (userId && userId.length > 0) fullstory.identify(userId);
    // });
    // // posthog
    // posthog.init('phc_JvciSnXSv2jFeyEeNgcaBCEA3fHbo4eAKZYE0TkTsfx', {
    //   api_host: 'https://app.posthog.com',
    //   loaded: (p) => {
    //     if (userId && userId.length > 0) p.identify(userId);
    //   },
    // });
    // // logrocket
    // LogRocket.init('on2f45/vimana');
  },
  identify: (_userId: string) => {
    // if (userId && userId.length > 0) {
    //   fullstory.identify(userId);
    //   posthog.identify(userId);
    //   LogRocket.identify(userId);
    // }
  },
  logout: () => {
    // fullstory.restart();
    // posthog.reset(true);
    // LogRocket.startNewSession();
  },
};
