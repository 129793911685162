import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

export interface CenteredContentProps {
  containerClass?: string;
  contentClass?: string;
}

export const CenteredContent: React.FC<CenteredContentProps> = ({
  children,
  containerClass,
  contentClass,
}) => {
  return (
    <div className={classNames(styles.container, containerClass)}>
      <div className={classNames(styles.contentContainer, contentClass)}>
        {children}
      </div>
    </div>
  );
};
