import pointInPolygon from 'point-in-polygon';
import {
  GoogleMapObjTypes,
  GoogleMapsPositionTypes,
  GoogleMapsGlobalObjTypes,
  GoogleMapsNavigationIconMarkerTypes,
  GoogleHaloMarkerTypes,
  PlotGoogleMapMarkerPointsTypes,
  PlotGoogleMapMarkerPointsIconsKeysTypes,
  GoogleMapMarkerIconTypes,
  PlotGoogleMapMarkerClustererIconTypes,
  PlotGoogleMapCustomActionButtonTypes,
  GoogleMapMarkerStyleOptionsSymbolTypes,
  PlotGoogleMapPropsCustomHaloMarkerHaloTypes,
  PlotGoogleMapPropsCustomHaloMarkerPointTypes,
  GoogleMapMarkerStyleOptionsIconPropertyTypes,
  CoordinatesListTypes,
} from './index.types';
import { GenericObjectType, NullOrGenericObjectType } from '../../shapes/app';
import {
  getGoogleApiJsUrl,
  loadExternalScript,
  undefinedOrNull,
} from '../../utils/functs';
import defaultMarkerIcon from '../../images/marker.png';
import yellowFilledFlagMarker from '../../images/yellow-flag-filled-marker.png';
import blueFilledFlagMarker from '../../images/blue-flag-filled-marker.png';
import redMarkerClustererCamera from '../../images/red-marker-clusterer-camera.png';
import googleMapMarkersGrey from '../../images/PlotGoogleMap/google-map-marker-grey.png';
import googleMapMarkersBlue from '../../images/PlotGoogleMap/google-map-marker-blue.png';
import googleMapMarkersYellow from '../../images/PlotGoogleMap/google-map-marker-yellow.png';
import googleMapMarkersRed from '../../images/PlotGoogleMap/google-map-marker-red.png';
import googleMapMarkersGreen from '../../images/PlotGoogleMap/google-map-marker-green.png';
import googleMapMarkersCyan from '../../images/PlotGoogleMap/google-map-marker-cyan.png';

import {
  GOOGLE_MAP_MARKER_SELECTED_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR,
  GOOGLE_MAP_MARKER_YELLOW_COLOR,
  GOOGLE_MAP_MARKER_HIGHLIGHT_COLOR,
  ML_OBJECT_COLOR,
} from '../../constants/colors';
import { GOOGLE_GLOBAL_KEY } from '../../constants/tokens';

export const loadGoogleMapApi = (callback?: () => void): void => {
  const setGoogleGlobalKey = () => {
    window[GOOGLE_GLOBAL_KEY] = {};
  };

  loadExternalScript(
    getGoogleApiJsUrl(),
    window[GOOGLE_GLOBAL_KEY],
    setGoogleGlobalKey
  ).then((_) => {
    if (callback) {
      callback();
    }
  });
};

export const findClosestLatLong = (
  positionList: GoogleMapsPositionTypes[] | null,
  point: GoogleMapsPositionTypes | null
): {
  pos: GoogleMapsPositionTypes;
  index: number;
} | null => {
  if (!positionList || !point) {
    return null;
  }

  const calculatedPosList = positionList.map(
    (position: GoogleMapsPositionTypes) => {
      return Math.sqrt(
        (point.lat - position.lat) ** 2 + (point.lng - position.lng) ** 2
      );
    }
  );

  const sortedPosList = calculatedPosList.concat().sort((a, b) => a - b);

  if (!sortedPosList || sortedPosList.length < 1) {
    return null;
  }

  const leastDistantPos = calculatedPosList.indexOf(sortedPosList[0]);

  return {
    pos: positionList[leastDistantPos],
    index: leastDistantPos,
  };
};

export const googleMapTailIconMarker = (
  googleMaps: GenericObjectType | null,
  rotation: number,
  strokeColor: string = GOOGLE_MAP_MARKER_SELECTED_COLOR,
  fillColor: string = GOOGLE_MAP_MARKER_SELECTED_COLOR,
  scale: number = 8
): GoogleMapMarkerStyleOptionsSymbolTypes | null => {
  if (!googleMaps) {
    return null;
  }

  return {
    path: googleMaps.SymbolPath.FORWARD_OPEN_ARROW,
    strokeColor,
    fillColor,
    strokeWeight: 1.5,
    scale,
    rotation,
    fillOpacity: 0.3,
    strokeOpacity: 0.5,
  };
};

export const googleMapSymbolIconMarker = ({
  map,
  rotation = 0,
  strokeColor = GOOGLE_MAP_MARKER_SELECTED_COLOR,
  fillColor = GOOGLE_MAP_MARKER_SELECTED_COLOR,
  scale = 8,
  symbol = 'CIRCLE',
}: {
  map: GenericObjectType;
  rotation?: number;
  strokeColor?: string;
  fillColor?: string;
  scale?: number;
  symbol?: string;
}): GoogleMapMarkerStyleOptionsSymbolTypes => {
  return {
    path: map.SymbolPath[symbol],
    strokeColor,
    fillColor,
    strokeWeight: 1.5,
    scale,
    rotation,
    fillOpacity: 0.3,
    strokeOpacity: 0.5,
  };
};

export const googleMapDefaultMarkerIconDescribeCircle = (
  radius: number
): string => {
  return (
    `M ${-radius},0a${radius},${radius} 0 1,0 ${radius * 2},0` +
    `a${radius},${radius} 0 1,0 ${-radius * 2},0 Z`
  );
};

export const googleMapDefaultMarkerIcon = (
  fillColor?: string,
  radius: number = 2
): GoogleMapMarkerStyleOptionsSymbolTypes => {
  let _fillColor = fillColor;

  if (undefinedOrNull(fillColor)) {
    _fillColor = GOOGLE_MAP_MARKER_DEFAULT_COLOR;
  }

  return {
    path: googleMapDefaultMarkerIconDescribeCircle(radius),
    scale: 3,
    fillColor: _fillColor,
    fillOpacity: 0.8,
    strokeWeight: 0.2,
  };
};

export const googleMapDefaultMarkerPngIcon = (
  googleMapsObj: GoogleMapsGlobalObjTypes,
  fillColor?: string
): GoogleMapMarkerStyleOptionsIconPropertyTypes => {
  let markerUrl: string;

  switch (fillColor) {
    case GOOGLE_MAP_MARKER_DEFAULT_COLOR:
    default:
      markerUrl = googleMapMarkersGrey;
      break;

    case GOOGLE_MAP_MARKER_SELECTED_COLOR:
      markerUrl = googleMapMarkersRed;
      break;

    case GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR:
      markerUrl = googleMapMarkersBlue;
      break;

    case GOOGLE_MAP_MARKER_YELLOW_COLOR:
      markerUrl = googleMapMarkersYellow;
      break;

    case ML_OBJECT_COLOR.MANUAL:
      markerUrl = googleMapMarkersGreen;
      break;

    case GOOGLE_MAP_MARKER_HIGHLIGHT_COLOR:
      markerUrl = googleMapMarkersCyan;
      break;
  }

  return {
    url: markerUrl,
    anchor: googleMapsObj ? new googleMapsObj.Point(7, 7) : null,
    labelOrigin: null,
  };
};

export const setGoogleMapsMarkerPosition = (
  googleMapsObj: GoogleMapsGlobalObjTypes,
  marker: GenericObjectType,
  pos: GoogleMapsPositionTypes
) => {
  if (!googleMapsObj || !marker) {
    return;
  }

  const latlng = new googleMapsObj.LatLng(pos.lat, pos.lng);

  marker.setPosition(latlng);
};

export const googleMapImageMarkerIcon = (
  type: GoogleMapMarkerIconTypes,
  color?: string
): string => {
  if (type === 'FLAG_TRIANGLE_FILLED' && color === 'yellow') {
    return yellowFilledFlagMarker;
  }

  if (type === 'FLAG_TRIANGLE_FILLED' && color === 'blue') {
    return blueFilledFlagMarker;
  }

  return defaultMarkerIcon;
};

const getGoogleMapDefaultMarkerPngIcon = (
  googleMapsObj: GoogleMapsGlobalObjTypes,
  color: string,
  labelOriginPoint: any
) => {
  const icon = googleMapDefaultMarkerPngIcon(googleMapsObj, color);

  icon.labelOrigin = labelOriginPoint;

  return icon;
};

export const googleMapMarkerIcon = (
  googleMapsObj: GoogleMapsGlobalObjTypes,
  mapObj: GoogleMapObjTypes,
  markerPoints: undefined | PlotGoogleMapMarkerPointsTypes[],
  index: number,
  colorTypeKey: PlotGoogleMapMarkerPointsIconsKeysTypes,
  combinedMarkersList: GenericObjectType,
  labelOriginPoint?: GenericObjectType
): GenericObjectType | string => {
  let color;

  switch (colorTypeKey) {
    default:
    case 'selected':
      color = GOOGLE_MAP_MARKER_SELECTED_COLOR;
      break;

    case 'unselected':
      color = GOOGLE_MAP_MARKER_DEFAULT_COLOR;
      break;
  }

  if (
    undefinedOrNull(googleMapsObj) ||
    undefinedOrNull(markerPoints) ||
    undefinedOrNull(markerPoints[index])
  ) {
    return getGoogleMapDefaultMarkerPngIcon(
      googleMapsObj,
      color,
      labelOriginPoint
    );
  }

  const marker: PlotGoogleMapMarkerPointsTypes = markerPoints[index];

  if (undefinedOrNull(marker.icon)) {
    return getGoogleMapDefaultMarkerPngIcon(
      googleMapsObj,
      color,
      labelOriginPoint
    );
  }

  if (marker.icon[colorTypeKey].types[0] === 'DEFAULT') {
    return getGoogleMapDefaultMarkerPngIcon(
      googleMapsObj,
      marker.icon[colorTypeKey].color,
      labelOriginPoint
    );
  }

  if (marker.icon[colorTypeKey].types[0] === 'FLAG_TRIANGLE_FILLED') {
    return {
      url: googleMapImageMarkerIcon(
        marker.icon[colorTypeKey].types[0],
        marker.icon[colorTypeKey].color
      ),
      labelOrigin: labelOriginPoint,
    };
  }

  if (marker.icon[colorTypeKey].types[0] === 'FOV') {
    let rotation = 0;
    let scale = 0;

    if (marker.piggyback) {
      // eslint-disable-next-line prefer-destructuring
      rotation = marker.piggyback.rotation;
      // eslint-disable-next-line prefer-destructuring
      scale = marker.piggyback.scale;
    }

    // todo: implement support for halo; right now the marker is present no matter clustered or whether halo is present.
    if (!combinedMarkersList.default) {
      // eslint-disable-next-line no-param-reassign
      combinedMarkersList.default = new googleMapsObj.Marker();

      combinedMarkersList.default.setIcon(
        googleMapDefaultMarkerIcon(marker.icon[colorTypeKey].color)
      );

      combinedMarkersList.default.setMap(mapObj);
    }

    setGoogleMapsMarkerPosition(
      googleMapsObj,
      combinedMarkersList.default,
      marker.pos
    );

    const icon = googleMapTailIconMarker(
      googleMapsObj,
      rotation,
      marker.icon[colorTypeKey].color,
      marker.icon[colorTypeKey].color,
      scale
    );

    if (icon) {
      icon.labelOrigin = labelOriginPoint;

      return icon;
    }
  } else if (marker.icon[colorTypeKey].types[0] === 'HALO') {
    // todo: implement support for halo; right now the marker is present no matter clustered or whether halo is present.
    if (!combinedMarkersList.default) {
      // eslint-disable-next-line no-param-reassign
      combinedMarkersList.default = new googleMapsObj.Marker();

      combinedMarkersList.default.setIcon(
        googleMapDefaultMarkerIcon(marker.icon[colorTypeKey].color)
      );

      combinedMarkersList.default.setMap(mapObj);
    }

    setGoogleMapsMarkerPosition(
      googleMapsObj,
      combinedMarkersList.default,
      marker.pos
    );

    const icon = googleMapSymbolIconMarker({
      map: googleMapsObj,
      scale: 30,
    });

    if (icon) {
      icon.labelOrigin = labelOriginPoint;

      return icon;
    }
  }

  return getGoogleMapDefaultMarkerPngIcon(
    googleMapsObj,
    marker.icon[colorTypeKey].color,
    labelOriginPoint
  );
};

export const processGoogleMapClick = (
  googleMapsObj: GoogleMapsGlobalObjTypes,
  map: GoogleMapObjTypes,
  markerPoints: GoogleMapsPositionTypes[] | undefined,
  pos: GoogleMapsPositionTypes,
  markerClickCb: (index: number) => void
): void => {
  if (
    undefinedOrNull(googleMapsObj) ||
    undefinedOrNull(map) ||
    undefinedOrNull(markerPoints)
  ) {
    return;
  }

  const closestMarker = findClosestLatLong(markerPoints, pos);

  if (undefinedOrNull(closestMarker)) {
    return;
  }

  markerClickCb(closestMarker.index);
};

export const defaultHaloMarker = () => {
  return {
    halo: null,
    point: null,
  };
};

export const drawNavigationMarker = (
  googleMapsObj: GoogleMapsGlobalObjTypes,
  map: GoogleMapObjTypes,
  marker: GenericObjectType,
  rotation: number,
  navigationIconMarker: GoogleMapsNavigationIconMarkerTypes
): GoogleMapsNavigationIconMarkerTypes => {
  if (!googleMapsObj || !map) {
    return null;
  }

  marker.setIcon(googleMapDefaultMarkerIcon(GOOGLE_MAP_MARKER_SELECTED_COLOR));

  // Reset Navigation Icon Marker if exists
  if (navigationIconMarker) {
    navigationIconMarker.setMap(null);
  }

  const _navigationIconMarker = new googleMapsObj.Marker({
    position: marker.getPosition(),
    icon: googleMapTailIconMarker(googleMapsObj, rotation),
  });

  if (!undefinedOrNull(_navigationIconMarker)) {
    _navigationIconMarker.setMap(map);
  }

  // Bind the new Navigation Icon Marker with point marker
  if (_navigationIconMarker) {
    _navigationIconMarker.bindTo('position', marker);
  }

  return _navigationIconMarker;
};

export const showNavigationIconMarker = (
  map: GoogleMapObjTypes,
  navigationIconMarker: GoogleMapsNavigationIconMarkerTypes
) => {
  if (!map) {
    return;
  }

  if (!undefinedOrNull(navigationIconMarker)) {
    navigationIconMarker.setMap(map);
  }
};

export const hideNavigationIconMarker = (
  navigationIconMarker: GoogleMapsNavigationIconMarkerTypes
) => {
  if (!undefinedOrNull(navigationIconMarker)) {
    navigationIconMarker.setMap(null);
  }
};

export const handleNavigationIconMarkerOnMapZoomChange = (
  map: GoogleMapObjTypes,
  navigationIconMarker: GoogleMapsNavigationIconMarkerTypes
) => {
  if (undefinedOrNull(map)) {
    return;
  }

  showNavigationIconMarker(map, navigationIconMarker);
};

export const drawHaloMarker = (
  googleMapsObj: GoogleMapsGlobalObjTypes,
  map: GoogleMapObjTypes,
  haloMarker: GoogleHaloMarkerTypes,
  pos: GoogleMapsPositionTypes,
  customMarker?: PlotGoogleMapPropsCustomHaloMarkerHaloTypes | null
): NullOrGenericObjectType => {
  if (undefinedOrNull(googleMapsObj) || undefinedOrNull(map)) {
    return null;
  }

  if (!undefinedOrNull(haloMarker.halo)) {
    haloMarker.halo.setMap(null);
  }

  let haloIcon: GoogleMapMarkerStyleOptionsSymbolTypes;

  if (customMarker) {
    haloIcon = googleMapSymbolIconMarker({
      map: googleMapsObj,
      scale: customMarker.scale,
      fillColor: customMarker.fillColor,
      strokeColor: customMarker.strokeColor,
      rotation: customMarker.rotation,
      symbol: customMarker.type,
    });
  } else {
    haloIcon = googleMapSymbolIconMarker({ map: googleMapsObj, scale: 30 });
  }

  const halo = new googleMapsObj.Marker({
    position: pos,
    icon: haloIcon,
    zIndex: 10001,
  });

  if (!undefinedOrNull(halo)) {
    halo.setMap(map);
  }

  return halo;
};

export const drawHaloPointMarker = (
  googleMapsObj: GoogleMapsGlobalObjTypes,
  map: GoogleMapObjTypes,
  haloMarker: GoogleHaloMarkerTypes,
  pos: GoogleMapsPositionTypes,
  customMarker?: PlotGoogleMapPropsCustomHaloMarkerPointTypes | null
): NullOrGenericObjectType => {
  if (undefinedOrNull(googleMapsObj) || undefinedOrNull(map)) {
    return null;
  }

  if (!undefinedOrNull(haloMarker.point)) {
    haloMarker.point.setMap(null);
  }

  let pointIcon: GoogleMapMarkerStyleOptionsSymbolTypes;

  if (customMarker) {
    pointIcon = googleMapDefaultMarkerIcon(customMarker.color);
  } else {
    pointIcon = googleMapDefaultMarkerIcon(GOOGLE_MAP_MARKER_SELECTED_COLOR);
  }

  const point = new googleMapsObj.Marker({
    position: pos,
    icon: pointIcon,
    zIndex: 10008,
  });

  if (!undefinedOrNull(point)) {
    point.setMap(map);
  }

  return point;
};

export const showHaloMarker = (
  map: GoogleMapObjTypes,
  haloMarker: GoogleHaloMarkerTypes
) => {
  if (!map) {
    return;
  }

  if (!undefinedOrNull(haloMarker.halo)) {
    haloMarker.halo.setMap(map);
  }

  if (!undefinedOrNull(haloMarker.point)) {
    haloMarker.point.setMap(map);
  }
};

export const hideHaloMarker = (haloMarker: GoogleHaloMarkerTypes) => {
  if (!undefinedOrNull(haloMarker.halo)) {
    haloMarker.halo.setMap(null);
  }

  if (!undefinedOrNull(haloMarker.point)) {
    haloMarker.point.setMap(null);
  }
};

export const handleHaloMarkerOnMapZoomChange = (
  map: GoogleMapObjTypes,
  haloMarker: GoogleHaloMarkerTypes
) => {
  if (undefinedOrNull(map)) {
    return;
  }

  showHaloMarker(map, haloMarker);
};

export const handleMarkerLabelOnMapZoomChange = (
  map: GoogleMapObjTypes,
  markerPoints: PlotGoogleMapMarkerPointsTypes[],
  markers: GenericObjectType[]
) => {
  if (undefinedOrNull(map)) {
    return;
  }

  const zoom = map.getZoom();

  for (let i = 0; i < markerPoints.length; i += 1) {
    const item = markerPoints[i];
    const marker = markers[i];
    const label = marker.getLabel();

    if (
      !undefinedOrNull(item.label) &&
      !undefinedOrNull(item.label.showMinZoom)
    ) {
      if (zoom >= item.label.showMinZoom) {
        if (undefinedOrNull(label)) {
          marker.setLabel(item.label.text);
        }
      } else if (!undefinedOrNull(label)) {
        marker.setLabel(null);
      }
    }
  }
};

export const googleMapMarkerClustererStyles = (
  icon?: PlotGoogleMapMarkerClustererIconTypes
) => {
  let _icon = defaultMarkerIcon;
  let height = 48;
  let width = 48;

  if (icon === 'RED_CAMERA') {
    _icon = redMarkerClustererCamera;
    height = 24;
    width = 24;
  }

  return [
    {
      url: _icon,
      height,
      width,
    },
    {
      url: _icon,
      height,
      width,
    },
    {
      url: _icon,
      height,
      width,
    },
  ];
};

export const getMarkerPointsPos = (
  markerPoints: PlotGoogleMapMarkerPointsTypes[]
): GoogleMapsPositionTypes[] => {
  return markerPoints.map((a) => {
    return { lat: a.pos.lat, lng: a.pos.lng };
  });
};

export const createCustomActionButton = (
  controlDiv: GenericObjectType,
  text: string,
  title: string
) => {
  const controlUI = document.createElement('div');

  controlUI.style.backgroundColor = '#fff';
  controlUI.style.border = '2px solid #fff';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0 1px 4px -1px';
  controlUI.style.cursor = 'pointer';
  controlUI.style.marginTop = '10px';
  controlUI.style.marginRight = '10px';
  controlUI.style.textAlign = 'center';
  controlUI.title = title;
  controlDiv.appendChild(controlUI);

  const controlText = document.createElement('div');

  controlText.style.color = 'rgb(25, 25, 25)';
  controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
  controlText.style.fontSize = '16px';
  controlText.style.lineHeight = '38px';
  controlText.style.paddingLeft = '11px';
  controlText.style.paddingRight = '11px';
  controlText.innerHTML = text;
  controlUI.appendChild(controlText);

  return controlUI;
};

export const updateCustomActionButton = (
  ctaObj: NullOrGenericObjectType,
  text: string,
  title: string
) => {
  if (!ctaObj) {
    return ctaObj;
  }

  /* eslint-disable no-param-reassign */

  ctaObj.title = title;

  const textDiv: HTMLDivElement | null = ctaObj.querySelector('div');

  if (!textDiv) {
    return ctaObj;
  }

  textDiv.innerHTML = text;

  /* eslint-enable no-param-reassign */

  return ctaObj;
};

export const getExpandCustomActionButtonStyles = (
  item: PlotGoogleMapCustomActionButtonTypes,
  isExpanded: boolean
) => {
  let title = '';
  let icon = '';

  if (!isExpanded) {
    icon = `<i class="fa fa-expand" aria-hidden="true" />`;
  } else {
    icon = `<i class="fa fa-compress" aria-hidden="true" />`;
  }

  if (!undefinedOrNull(item.data)) {
    if (isExpanded) {
      title = item.data.expandedTitle;
    } else {
      title = item.data.collapsedTitle;
    }
  }

  return {
    icon,
    title,
  };
};

export const registerExpandCustomActionButton = (
  googleMapsObj: GoogleMapsGlobalObjTypes,
  map: GoogleMapObjTypes,
  item: PlotGoogleMapCustomActionButtonTypes
): void => {
  if (undefinedOrNull(googleMapsObj) || undefinedOrNull(map)) {
    return;
  }

  const expandCustomActionButtonStyles = getExpandCustomActionButtonStyles(
    item,
    false
  );

  const controlDiv = document.createElement('div');

  if (!controlDiv) {
    return;
  }

  // @ts-ignore
  controlDiv.isExpanded = false;

  const ctaObj: GenericObjectType = createCustomActionButton(
    controlDiv,
    expandCustomActionButtonStyles.icon,
    expandCustomActionButtonStyles.title
  );

  ctaObj.addEventListener('click', () => {
    if (undefinedOrNull(item.callback)) {
      return;
    }

    item.callback({
      toggle: true,
    });

    // @ts-ignore
    controlDiv.isExpanded = !controlDiv.isExpanded;

    const expandCustomActionButtonStyles = getExpandCustomActionButtonStyles(
      item,
      // @ts-ignore
      controlDiv.isExpanded
    );

    updateCustomActionButton(
      ctaObj,
      expandCustomActionButtonStyles.icon,
      expandCustomActionButtonStyles.title
    );
  });

  map.controls[googleMapsObj.ControlPosition.TOP_RIGHT].push(ctaObj);
};

export const checkPointsWithinBounds = (
  pointsList: GoogleMapsPositionTypes[],
  boundsList: CoordinatesListTypes
) => {
  const pointsCoords = pointsList.map((a) => [a.lat, a.lng]);

  return pointsCoords
    .map((a) => {
      return pointInPolygon(a, boundsList) ? a : null;
    })
    .filter((a) => a);
};

export const checkMarkerPointsWithinBounds = (
  markerPointsList: GenericObjectType[],
  boundsList: CoordinatesListTypes
): GenericObjectType[] => {
  return markerPointsList
    .map((a) => {
      const pointsCoords = [parseFloat(a.latitude), parseFloat(a.longitude)];

      return pointInPolygon(pointsCoords, boundsList) ? a : null;
    })
    .filter((a) => a) as GenericObjectType[];
};

export const coordsToGoogleMapPositionList = (
  coordinatesList: CoordinatesListTypes
): GoogleMapsPositionTypes[] => {
  return coordinatesList.map((a) => ({
    lat: parseFloat(`${a[0]}`),
    lng: parseFloat(`${a[1]}`),
  }));
};

export const googleMapPositionToCoordsList = (
  posList: GoogleMapsPositionTypes[]
): CoordinatesListTypes => {
  return posList.map((a) => {
    return [a.lat, a.lng];
  });
};
