import autobind from 'autobind-decorator';
import * as React from 'react';
import { Input } from 'antd';

import styles from './Password.module.scss';

interface IState {
  password1: string;
  password2: string;
}

interface IProps {
  onChange?: (value: IValue) => void;
}

interface IValue {
  password1?: string;
  password2?: string;
}

export class Password extends React.Component<IProps, IState> {
  public constructor(props: any) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
    };
  }

  @autobind
  public handlePassword1(e: any): void {
    this.setState(
      {
        password1: e.target.value,
      },
      () => {
        const { password1, password2 } = this.state;

        this.triggerChange({ password1, password2 });
      }
    );
  }

  @autobind
  public handlePassword2(e: any): void {
    this.setState(
      {
        password2: e.target.value,
      },
      () => {
        const { password1, password2 } = this.state;

        this.triggerChange({ password1, password2 });
      }
    );
  }

  @autobind
  private triggerChange(changedValue: IValue): void {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props;

    if (onChange) {
      // eslint-disable-next-line prefer-object-spread
      onChange(Object.assign({}, this.state, changedValue));
    }
  }

  public render(): React.ReactNode {
    return (
      <Input.Group>
        <Input.Password
          onChange={this.handlePassword1}
          autoComplete="off"
          placeholder="New password"
          type="password"
          className={styles.textInput}
          style={{ marginBottom: 15 }}
        />
        <Input.Password
          onChange={this.handlePassword2}
          autoComplete="off"
          placeholder="Retype password"
          type="password"
          className={styles.textInput}
          style={{ marginBottom: 15 }}
        />
      </Input.Group>
    );
  }
}
