import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  clearProjectFilter,
  setProjectFilter,
} from '../../redux/actions/projects';

import style from './ProjectFilter.module.scss';
import { undefinedOrNull } from '../../utils/functs';

interface IProps {
  filter: string;
  setProjectFilter: (filter: string) => void;
  clearProjectFilter: () => void;
}

interface IState {
  filter: string;
}

class ProjectFilter extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    const { filter } = this.props;

    this.state = {
      filter: filter || '',
    };
  }

  private setFilter = (event: any) => {
    const filter = event.target.value;
    const { setProjectFilter } = this.props;

    this.setState({ filter });
    setProjectFilter(filter);
  };

  private clearSearchBox = () => {
    const { setProjectFilter } = this.props;

    this.setState({ filter: '' }, () => {
      setProjectFilter('');
    });
  };

  public render(): React.ReactNode {
    const { filter } = this.state;

    return (
      <div className={style.container}>
        <i className="fa fa-search" />
        <div className={style.searchBoxWrapper}>
          <input
            placeholder="Search Projects"
            value={filter}
            onChange={this.setFilter}
            className={style.searchBox}
            type="search"
          />
          {filter !== '' && !undefinedOrNull(filter) && (
            <i
              className={`fa fa-times-circle ${style.searchBoxClear}`}
              onClick={() => {
                this.clearSearchBox();
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any): { filter: string } => {
  let filter = '';

  if (state && state.projects) {
    filter = state.projects.filter || '';
  }

  return {
    filter,
  };
};

const mapDispatchToProps = (
  dispatch: any
): {
  setProjectFilter: (filter: string) => {};
  clearProjectFilter: () => {};
} => {
  return bindActionCreators(
    {
      setProjectFilter,
      clearProjectFilter,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFilter);
