import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ElevationDifferenceCrudContainer from './index';
import { actionShowSnackbar } from '../../../redux/actions/snackbar';
import { fetchViewById } from '../../../redux/actions/views';
import { ElevationDifferenceCrudContainerTypes } from './index.types';

const mapStateToProps =
  (): ElevationDifferenceCrudContainerTypes.ReduxState => {
    return {};
  };

const mapDispatchToProps = (
  dispatch: any
): ElevationDifferenceCrudContainerTypes.DispatchProps => {
  return bindActionCreators(
    {
      showSnackBar: actionShowSnackbar,
      fetchViewById,
    },
    dispatch
  );
};

export default connect<
  ElevationDifferenceCrudContainerTypes.ReduxState,
  ElevationDifferenceCrudContainerTypes.DispatchProps,
  ElevationDifferenceCrudContainerTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ElevationDifferenceCrudContainer);
