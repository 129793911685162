// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalNotification_ModalNotificationContainer__qDSxc{position:relative;top:30% !important}.ModalNotification_heading__lGNx1{font-size:18px;margin-bottom:0 !important}.ModalNotification_modalButtonDiv__DtQaP{padding-top:15px;text-align:right}.ModalNotification_modalButtonDiv__DtQaP .ModalNotification_okBtn__IzmZ5{margin-left:15px}", "",{"version":3,"sources":["webpack://./src/components/ModalNotification/ModalNotification.module.scss"],"names":[],"mappings":"AAEA,qDACE,iBAAA,CACA,kBAAA,CAGF,kCACE,cAAA,CACA,0BAAA,CAGF,yCACE,gBAAA,CACA,gBAAA,CAEA,yEACE,gBAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.ModalNotificationContainer {\n  position: relative;\n  top: 30% !important;\n}\n\n.heading {\n  font-size: 18px;\n  margin-bottom: 0 !important;\n}\n\n.modalButtonDiv {\n  padding-top: 15px;\n  text-align: right;\n\n  .okBtn {\n    margin-left: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalNotificationContainer": "ModalNotification_ModalNotificationContainer__qDSxc",
	"heading": "ModalNotification_heading__lGNx1",
	"modalButtonDiv": "ModalNotification_modalButtonDiv__DtQaP",
	"okBtn": "ModalNotification_okBtn__IzmZ5"
};
export default ___CSS_LOADER_EXPORT___;
