import ApiBase from './ApiBase';
import { ListClassResponse, ProjectMLEnabledResponse } from './mlClasses.types';
import { BASE_CAPI_URL } from '../constants/urls';
import { processErrorHandling } from '../utils/helpers';

export default class MLApis extends ApiBase {
  public constructor() {
    super(`${BASE_CAPI_URL}/v2`);
  }

  public async getSiteObjectClasses(): Promise<ListClassResponse> {
    return this.get('/projects/classes')
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an expected error while trying to fetch the object classes',
          isArray: true,
        });
      });
  }

  public async getProjectSiteObjectClasses(
    projectId: string
  ): Promise<ListClassResponse> {
    return this.get(`/projects/${projectId}/classes`)
      .then((res) => {
        return {
          error: null,
          data: res.data || [],
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to fetch the object classes associated with the Project.',
          isArray: true,
        });
      });
  }

  public async updateProjectSiteObjectClasses(
    projectId: string,
    classIds: string[]
  ): Promise<ListClassResponse> {
    return this.post(`/projects/${projectId}/classes`, {
      siteObjectClassIds: classIds,
    })
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to update the object classes associated with the Project.',
          isArray: true,
        });
      });
  }

  public async checkProjectMLEnabled(
    projectId: string
  ): Promise<ProjectMLEnabledResponse> {
    return this.get(`/projects/${projectId}/classes/enabled`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while checking if ML has been enabled for this project.',
          isArray: false,
        });
      });
  }
}
