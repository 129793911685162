import ApiBase from './ApiBase';
import {
  GenericApisReturnPromiseTypes,
  GenericObjectType,
} from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_CAPI_URL } from '../constants/urls';
import { objectListToQueryString } from '../utils/functs';

export default class ProjectV2Apis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = `/v2`;
  }

  public getProjectBranding(projectId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/projects/${projectId}/branding`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the project branding.`,
        });
      });
  }

  public updateProjectBranding(
    projectId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/projects/${projectId}/branding`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to update project branding.`,
        });
      });
  }

  public uploadLogo(
    projectId: string,
    size: 'small' | 'large',
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/projects/${projectId}/logo${objectListToQueryString({
        size,
      })}`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to uploading project logo.`,
        });
      });
  }

  public getProjectLogo(
    projectId: string,
    size: 'small' | 'large' | 'favicon'
  ): string {
    return `${BASE_CAPI_URL}/${
      this._baseUrl
    }/projects/${projectId}/logo${objectListToQueryString({
      size,
    })}`;
  }
}
