import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { HeaderTypes } from './index.types';
import {
  openManageTagsModal,
  openReportPreviewModal,
} from '../../redux/actions/issuesV2';
import Header from './index';

const mapStateToProps = (): HeaderTypes.ReduxState => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch): HeaderTypes.DispatchProps => {
  return bindActionCreators(
    {
      openManageTagsModal,
      openReportPreviewModal,
    },
    dispatch
  );
};

const HeaderContainer = connect<
  HeaderTypes.ReduxState,
  HeaderTypes.DispatchProps,
  HeaderTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(Header);

export default HeaderContainer;
