import React from 'react';
import DroneAnimationStatic from '../../../DroneAnimation/DroneAnimationStatic';
import styles from './index.module.scss';

export const NoImagePlaceholder: React.FC = () => {
  return (
    <div className={styles.container}>
      <DroneAnimationStatic
        message={"You'll see your images here, after you've uploaded them!"}
        className={styles.droneContainer}
      />
    </div>
  );
};
