/* eslint-disable react/no-array-index-key, max-classes-per-file */

import classnames from 'classnames';
import * as React from 'react';

import style from './DroneAnimation.module.scss';
import droneImage from './images/drone.svg';

interface IProps {
  className?: string;
  message: string;
}

class DroneAnimationStatic extends React.Component<IProps> {
  public renderInfoText(): JSX.Element {
    const { message } = this.props;

    return (
      <div>
        <div className={style.text}>{message}</div>
      </div>
    );
  }

  public render(): React.ReactNode {
    const { className } = this.props;

    return (
      <div className={classnames(style.container, className)}>
        <img src={droneImage} className={style.drone} alt="" />
        {this.renderInfoText()}
      </div>
    );
  }
}

export default DroneAnimationStatic;

// eslint-disable-next-line react/no-multi-comp
export class AccessDenied extends React.Component<{ className?: string }> {
  public render(): React.ReactNode {
    const { className } = this.props;

    return (
      <DroneAnimationStatic
        className={classnames(style.container, 'center', className)}
        message="You do not have access to this resource!"
      />
    );
  }
}
