import * as React from 'react';
import { Typography } from 'antd';
import styles from './index.module.scss';
import GcpMarkingApis from '../../api/gcpMarking';
import GcpMarkingEdit from './GcpMarkingEdit';
import {
  gcpMarkingGcpIdUrl,
  gcpMarkingProjectionsActionUrl,
  gcpMarkingListingPageUrl,
} from '../../routes/urls';
import SkeletonLoader from '../SkeletonLoader';
import { undefinedOrNull, undefinedOrNullChained } from '../../utils/functs';
import { GcpMarkingPropsTypes, GcpMarkingStateTypes } from './index.types';
import GcpMarkingListing from './GcpMarkingListing';
import ModalNotification from '../ModalNotification/ModalNotification';
import { GoogleMapsPositionTypes } from '../PlotGoogleMap/index.types';
import { log } from '../../utils/log';
import ProjectsAPI from '../../api/projects';

const gcpMarkingApis = new GcpMarkingApis();
const projectsApi = new ProjectsAPI();
const { Text } = Typography;

class GcpMarking extends React.PureComponent<
  GcpMarkingPropsTypes,
  GcpMarkingStateTypes
> {
  public constructor(props: GcpMarkingPropsTypes) {
    super(props);

    this.state = {
      boundaryListData: null,
      gcpListData: [],
      rmsErrorListData: [],
      gcpListLoading: true,
      finalizeBtnLoading: false,
      finalizeSessionConfirmModal: false,
    };
  }

  public componentDidMount(): void {
    this.fetchGcpsBySessions();
    this.fetchRmsError();
    this.fetchAoiBoundary();
  }

  private captureUrlParams = () => {
    const { match } = this.props;

    return match.params;
  };

  private fetchGcpsBySessions = (): void => {
    const { showSnackbar } = this.props;
    const { sessionId } = this.captureUrlParams();

    if (!undefinedOrNull(sessionId)) {
      gcpMarkingApis.listGcpBySession(sessionId).then((res) => {
        const { error: apiError, data: apiData } = res;

        if (apiError) {
          showSnackbar({
            type: 'error',
            body: apiError,
          });

          return;
        }

        this.setState(() => {
          return {
            gcpListData: apiData,
            gcpListLoading: false,
          };
        });
      });
    }
  };

  private fetchRmsError = (): void => {
    const { sessionId } = this.captureUrlParams();

    if (!undefinedOrNull(sessionId)) {
      gcpMarkingApis.listRmsError(sessionId).then((res) => {
        const { error: apiError, data: apiData } = res;

        if (apiError) {
          return;
        }

        this.setState(() => {
          return {
            rmsErrorListData: apiData,
          };
        });
      });
    }
  };

  private fetchAoiBoundary = (): void => {
    const { projectId, aoiId } = this.captureUrlParams();

    projectsApi.listAoiBoundary(projectId, aoiId).then((res) => {
      const { error: apiError, data: apiData } = res;

      let boundaryList: GoogleMapsPositionTypes[] | null = null;

      if (
        !undefinedOrNullChained(apiData, `features[0].geometry.coordinates[0]`)
      ) {
        const coord = apiData.features[0].geometry.coordinates[0];

        boundaryList = coord.map((a: [number, number]) => {
          return { lat: a[1], lng: a[0] };
        });
      }

      this.setState(() => {
        return {
          boundaryListData: boundaryList,
        };
      });

      if (!undefinedOrNull(apiError)) {
        log.error(apiError, 'GcpMarking -> fetchAoiBoundary');
      }
    });
  };

  private handleGcpMarkingListTableRowClick = (gcpId: null | string) => {
    const { history } = this.props;
    const { sessionId, projectId, aoiId } = this.captureUrlParams();

    if (!gcpId) {
      return;
    }

    history.push(gcpMarkingGcpIdUrl(projectId, aoiId, sessionId, gcpId));
  };

  private handleBackFromProjectionsClick = () => {
    const { history } = this.props;
    const { sessionId, projectId, aoiId, gcpId } = this.captureUrlParams();

    history.push(gcpMarkingGcpIdUrl(projectId, aoiId, sessionId, gcpId));
  };

  private handleGcpMarkingFinalizeBtnClick = () => {
    this.setState({
      finalizeSessionConfirmModal: true,
    });
  };

  private processFinalizeSession = (value: boolean) => {
    const { showSnackbar } = this.props;

    this.setState({
      finalizeSessionConfirmModal: false,
    });

    if (!value) {
      return;
    }

    const { sessionId } = this.captureUrlParams();

    if (!undefinedOrNull(sessionId)) {
      this.setState(() => {
        return {
          finalizeBtnLoading: true,
        };
      });

      gcpMarkingApis.updateSession(sessionId, {}).then((res) => {
        const { error: apiError } = res;

        if (apiError) {
          this.setState(() => {
            return {
              finalizeBtnLoading: false,
            };
          });

          showSnackbar({
            type: 'error',
            body: apiError,
          });

          return;
        }

        this.setState(() => {
          return {
            finalizeBtnLoading: false,
          };
        });

        showSnackbar({
          type: 'success',
          body: 'The session was finalized successfully.',
        });
      });
    }
  };

  private handleCompleteProjectionsBtnClick = (reviewDone: boolean = false) => {
    const { gcpId } = this.captureUrlParams();

    const { history, showSnackbar } = this.props;
    const { sessionId, projectId, aoiId } = this.captureUrlParams();

    if (reviewDone) {
      if (!undefinedOrNull(sessionId)) {
        const formData = {
          markingComplete: true,
        };

        gcpMarkingApis.updateMarkingGcp(gcpId, formData).then((res) => {
          const { error: apiError } = res;

          if (apiError) {
            showSnackbar({
              type: 'error',
              body: apiError,
            });

            return;
          }

          showSnackbar({
            type: 'success',
            body: 'The GCP was marked successfully.',
          });

          history.push(gcpMarkingListingPageUrl(projectId, aoiId, sessionId));
        });
      }

      return;
    }

    history.push(
      gcpMarkingProjectionsActionUrl(
        projectId,
        aoiId,
        sessionId,
        gcpId,
        'review'
      )
    );
  };

  public render(): React.ReactNode {
    const { showSnackbar } = this.props;
    const {
      gcpListData,
      gcpListLoading,
      finalizeBtnLoading,
      finalizeSessionConfirmModal,
      rmsErrorListData,
      boundaryListData,
    } = this.state;
    const { sessionId, gcpId, gcpActionType, aoiId, projectId } =
      this.captureUrlParams();

    if (!undefinedOrNull(sessionId) && gcpListLoading) {
      return <SkeletonLoader position="fixed" />;
    }

    return !undefinedOrNull(gcpId) ? (
      <GcpMarkingEdit
        gcpId={gcpId}
        sessionId={sessionId}
        projectId={projectId}
        aoiId={aoiId}
        gcpActionType={gcpActionType}
        gcpListData={gcpListData}
        boundaryListData={boundaryListData}
        onBackFromProjectionsBtn={this.handleBackFromProjectionsClick}
        onCompleteProjectionsBtn={this.handleCompleteProjectionsBtnClick}
        {...this.props}
      />
    ) : (
      <div className={styles.container}>
        {sessionId ? (
          <GcpMarkingListing
            gcpListData={gcpListData}
            boundaryListData={boundaryListData}
            gcpListLoading={gcpListLoading}
            rmsErrorListData={rmsErrorListData}
            onGcpMarkingListTableRowClick={
              this.handleGcpMarkingListTableRowClick
            }
            finalizeBtnLoading={finalizeBtnLoading}
            onGcpMarkingFinalizeBtnClick={this.handleGcpMarkingFinalizeBtnClick}
            showSnackbar={showSnackbar}
            fetchGcpsBySessions={this.fetchGcpsBySessions}
          />
        ) : (
          <div className={styles.loadingErrorMsg}>
            <Text>
              An error occured while trying to list the projections. Try again
            </Text>
          </div>
        )}

        {finalizeSessionConfirmModal ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody="Are you sure want to finalize this session?"
            shownotificationModal
            handleModalClose={this.processFinalizeSession}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}
      </div>
    );
  }
}

export default GcpMarking;
