import React, { useEffect, useMemo } from 'react';
import { Coordinate } from 'ol/coordinate';
import { Feature } from 'ol';
import { MultiPoint } from 'ol/geom';
import { StyleLike } from 'ol/style/Style';
import { useVectorManager } from './hooks';
import { getDefaultStyle, WEB_MERCATOR, WGS84 } from './utils';

export interface ReadOnlyPointsProps {
  coordinates: Coordinate[];
  style?: StyleLike;
  zIndex?: number;
}

export const ReadOnlyPoints: React.FC<ReadOnlyPointsProps> = ({
  coordinates,
  style,
  zIndex,
}) => {
  const { addFeatures, removeFeatures, setZIndex, setStyle } =
    useVectorManager();

  const { geometryList, featureList } = useMemo(() => {
    const geometry = new MultiPoint(coordinates);

    // maps are in web mercator by default.
    geometry.transform(WGS84, WEB_MERCATOR);
    const points = geometry.getPoints();
    const featureList = points.map((p) => new Feature(p));

    return { geometryList: points, featureList };
  }, [coordinates]);

  useEffect(() => {
    if (coordinates.length === 0) return;

    addFeatures(featureList);

    return () => {
      removeFeatures(featureList);
    };
  }, [featureList, geometryList, coordinates]);

  useEffect(() => {
    setStyle(style ?? getDefaultStyle('Point'));
    setZIndex(zIndex ?? 10);

    return () => {
      setStyle(undefined);
      setZIndex(0);
    };
  }, [style, zIndex]);

  return <div data-testid="ol-read-only-points" />;
};
