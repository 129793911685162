import * as React from 'react';
import styles from './index.module.scss';
import {
  GcpMarkingEditReviewPropsTypes,
  GcpMarkingEditReviewStateTypes,
} from './index.types';
import OpenSeaDragon from '../../OpenSeaDragon';
import { undefinedOrNull } from '../../../utils/functs';
import { Button } from '../../Button';
import ModalNotification from '../../ModalNotification/ModalNotification';
import ImagesApis from '../../../api/images';

const imagesApis = new ImagesApis();

class GcpMarkingEditReview extends React.PureComponent<
  GcpMarkingEditReviewPropsTypes,
  GcpMarkingEditReviewStateTypes
> {
  public constructor(props: GcpMarkingEditReviewPropsTypes) {
    super(props);

    this.state = {
      deleteProjectionConfirmModalData: null,
    };
  }

  private handleDeleteProjection = (gcpId: string, id: string) => {
    this.setState({
      deleteProjectionConfirmModalData: {
        gcpId,
        projectionId: id,
      },
    });
  };

  private processDeleteProjection = (value: boolean) => {
    const { onDeleteProjection } = this.props;
    const { deleteProjectionConfirmModalData } = this.state;

    this.setState({
      deleteProjectionConfirmModalData: null,
    });

    if (!value || undefinedOrNull(deleteProjectionConfirmModalData)) {
      return;
    }

    onDeleteProjection(
      deleteProjectionConfirmModalData.gcpId,
      deleteProjectionConfirmModalData.projectionId
    );
  };

  private getGcpCurrentImage = (guid: string) => {
    const { gcpImagesListData } = this.props;

    if (!gcpImagesListData) {
      return null;
    }

    const filteredGcpImagesListData = gcpImagesListData.filter(
      (a) => a.guid === guid
    );

    return filteredGcpImagesListData && filteredGcpImagesListData[0]
      ? filteredGcpImagesListData[0]
      : null;
  };

  public render(): React.ReactNode {
    const { projectionListData } = this.props;
    const { deleteProjectionConfirmModalData } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.thumbWrapper}>
          {!undefinedOrNull(projectionListData) ? (
            projectionListData.map((projectionItem) => {
              const currentGcpImage = this.getGcpCurrentImage(
                projectionItem.imageGuid
              );

              if (undefinedOrNull(currentGcpImage)) {
                return null;
              }

              const key = `${projectionItem.id}-${projectionItem.pixelX}-${projectionItem.pixelY}`;

              return (
                <div className={styles.itemThumbsWrapper} key={key}>
                  <div className={styles.ctaWrapper}>
                    <Button
                      icon="delete"
                      shape="circle"
                      type="secondary"
                      onClick={() => {
                        this.handleDeleteProjection(
                          projectionItem.gcpId,
                          projectionItem.id
                        );
                      }}
                      helpText="Delete this projection"
                    />
                  </div>
                  <OpenSeaDragon
                    tileSrc={`${imagesApis.getImageTilesUrl(
                      currentGcpImage.guid
                    )}`}
                    imageTileSrc={`${imagesApis.getImageTilesUrl(
                      currentGcpImage.guid,
                      null,
                      true
                    )}`}
                    guid={currentGcpImage.guid}
                    projection={projectionItem}
                    className={styles.itemThumbs}
                    zoomTo={25}
                    isMarkerSaved={
                      !undefinedOrNull(projectionItem) &&
                      !undefinedOrNull(projectionItem.gcpId)
                    }
                  />
                </div>
              );
            })
          ) : (
            <div>
              <p>No projections found.</p>
            </div>
          )}
        </div>

        {!undefinedOrNull(deleteProjectionConfirmModalData) ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody="Are you sure want to delete the projection?"
            shownotificationModal
            handleModalClose={this.processDeleteProjection}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}
      </div>
    );
  }
}

export default GcpMarkingEditReview;
