import React from 'react';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import { Button } from 'src/components/Button';
import style from './index.module.scss';

export interface ListStyleLayersProps {
  layers?: LayerDescriptor[];
  deleteLayer: (l: LayerDescriptor) => void;
  editLayer: (l: LayerDescriptor) => void;
}

/**
 * List layers associated with a style.
 * Responsibilities:
 *   - list all style layers with appropriate details
 *   - allow layer edit & delete for each layer in the list
 * @constructor
 */
export const ListStyleLayers: React.FC<ListStyleLayersProps> = ({
  layers,
  deleteLayer,
  editLayer,
}) => {
  return (
    <div>
      {layers?.map((layer) => (
        <LayerStyleListItem
          key={layer.name}
          layer={layer}
          onDeleteLayer={(l) => deleteLayer(l)}
          onEditLayer={(l) => editLayer(l)}
        />
      )) || <></>}
    </div>
  );
};

export interface LayerStyleListItemProps {
  layer: LayerDescriptor;
  onDeleteLayer: (layer: LayerDescriptor) => void;
  onEditLayer: (layer: LayerDescriptor) => void;
}

export const LayerStyleListItem: React.FC<LayerStyleListItemProps> = ({
  layer,
  onDeleteLayer,
  onEditLayer,
}) => {
  return (
    <div className={style.layerGrid}>
      <span className={style.largeText}>{layer.name}</span>
      <span className={style.startFlex}>
        <Button type="secondary" onClick={() => onDeleteLayer(layer)}>
          Delete
        </Button>
      </span>
      <span className={style.endFlex}>
        <Button
          type="secondary"
          onClick={() => onEditLayer(layer)}
          style={{ float: 'right' }}
        >
          Edit
        </Button>
      </span>
    </div>
  );
};
