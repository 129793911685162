import { Checkbox, Modal, Input } from 'antd';
import moment from 'moment';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ModalNotification from '../../ModalNotification/ModalNotification';
import { Button } from '../../Button';
import styles from './CreateMission.module.scss';
import MissionAPIs from '../../../api/mission';
import { reactKey, undefinedOrNull } from '../../../utils/functs';
import SkeletonLoader from '../../SkeletonLoader';
import { BASE_CAPI_URL } from '../../../constants/urls';
import { convertToUtc } from '../../../utils/date';
import { SnackbarActionsActionShowSnackbarTypes } from '../../../shapes/snackbar';

interface IProps {
  showSnackbar: SnackbarActionsActionShowSnackbarTypes;
  aoiId: string;
  projectId: string;
  mission?: any;
  onSaveMission?: (req: any) => Promise<any>;
  onSubmitMission?: (req: any) => Promise<any>;
  handleChange: () => void;
  incrementTabIndex: (step?: number) => void;
  handleRedirectId: (id: any) => void;
  canEdit: boolean;
  canDelete: boolean;
  history: any;
}

interface IState {
  loaded: boolean;
  addedTags: string[];
  epsgCode?: any;
  error: any;
  // reqs: any;
  name: string;
  scheduledAt: any;
  tags: string[];
  selectedTag: string;
  createMissionModal: boolean;
  projectType?: string;
  requirements: Requirement[];
  saveMission: boolean;
  creating: boolean;
  deleting: boolean;
  submitting: boolean;
  finalSubmit: boolean;
  confirmDeleteMission: boolean;
}

type REQUIREMENT_ID =
  | 'AERIAL_MAPS'
  | 'GROUND_CONTROL_POINTS'
  | 'THREE_D_VIEW'
  | 'THERMAL_MAP'
  | 'INSPECTION'
  | 'PERSPECTIVE'
  | 'EXTERIOR_360'
  | 'THERMAL_INSPECTION';

interface Requirement {
  id: REQUIREMENT_ID;
  name: string;
  description: string;
  selected: boolean;
  depends?: REQUIREMENT_ID;
}

const missionAPIs = new MissionAPIs();

class CreateMission extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    const { mission } = this.props;

    this.state = {
      loaded: false,
      addedTags: [],
      error: null,
      // reqs: {},
      name: '',
      scheduledAt: moment(),
      tags: mission ? mission.tags || [] : [],
      selectedTag: '',
      createMissionModal: false,
      saveMission: false,
      creating: false,
      deleting: false,
      submitting: false,
      finalSubmit: false,
      requirements: this.getDefaultRequirements(),
      confirmDeleteMission: false,
    };
  }

  public componentDidMount(): void {
    const { projectId, mission } = this.props;

    const requirements: Requirement[] = this.getDefaultRequirements();

    if (mission && mission.requirements) {
      /* eslint-disable */
      for (const r of mission.requirements) {
        for (const dr of requirements) {
          if (dr.id === r.id) {
            dr.selected = true;
            break;
          }
        }
      }
      /* eslint-enable */
    }

    this.syncReqData();

    if (requirements) {
      this.setState({
        requirements,
      });
    }

    fetch(`${BASE_CAPI_URL}/v2/projects/${projectId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((x) => x.json())
      .then((projectDetails) => {
        const epsg = projectDetails.epsgCode;
        const projectType = projectDetails.type;

        this.setState(
          {
            projectType,
            loaded: true,
            epsgCode: epsg,
          },
          () => {
            const { projectType } = this.state;

            if (projectType === 'Construction') {
              const addedTags = [
                'Site Preparation',
                'Land Study',
                'Marking & Foundation',
                'Formwork',
                'Electricals',
                'Interiors',
                'Land Grading',
                'Piling',
                'Construction',
              ];

              this.setState({ addedTags });
            } else {
              const addedTags = [
                'Land Acquisition',
                'Land Study',
                'Campus Mapping',
                'Maintenance Survey',
                'Road Widening',
              ];

              this.setState({ addedTags });
            }
          }
        );
      })
      .catch(() => {
        /**/
      });
  }

  public syncReqData() {
    const { mission } = this.props;

    if (mission) {
      this.setState({
        name: mission.name,
        scheduledAt: moment(mission.scheduledAt).tz('Etc/GMT'),
      });
    }
  }

  private handleSubmitModal = () => {
    const { handleChange } = this.props;

    this.setState(
      {
        saveMission: false,
      },
      () => {
        handleChange();
      }
    );
  };

  private hideCreateMissionModal = () => {
    this.setState({
      createMissionModal: false,
      submitting: false,
    });
  };

  private showCreateMissionModal = () => {
    this.setState({
      createMissionModal: true,
      submitting: false,
    });
  };

  private addTag = () => {
    const { selectedTag, tags } = this.state;

    if (tags.indexOf(selectedTag) === -1 && selectedTag !== 'Select Mission') {
      this.setState({ tags: [...tags, selectedTag] });
    }
  };

  private getTagDeleteHandler = (tag: any) => {
    const { tags } = this.state;

    return () => this.setState({ tags: tags.filter((t) => t !== tag) });
  };

  private handleNameChanged = (event: any) => {
    this.setState({
      name: event.target.value,
    });
  };

  private handleDateChanged = (date: any) => {
    this.setState({ scheduledAt: date });
  };

  private canSubmit = () => {
    const { creating, requirements, scheduledAt } = this.state;

    if (!this.checkIsSelected(requirements).length) {
      return false;
    }

    if (!this.state || !scheduledAt || creating) {
      return false;
    }

    return true;
  };

  private checkIsSelected = (arr: any) => {
    return arr.filter((item: any) => {
      return item.selected;
    });
  };

  private getSelectedRequirementsArr = (requests: Requirement[]) => {
    const selectedArr = [...this.checkIsSelected(requests)];

    return selectedArr.map((item: any) => {
      return {
        id: item.id,
        parameters: {},
      };
    });
  };

  private getSelectedReqsModal = (requirements: Requirement[]) => {
    const selectedArr = [...this.checkIsSelected(requirements)];
    const selectedReqs = selectedArr.map((item: any) => {
      return {
        name: item.name,
      };
    });

    return selectedReqs.map((item: any) => {
      if (item.name === 'Aerial View') {
        return (
          <div key={reactKey(item.name)}>
            <div>{item.name.split('_').join(' ')}</div>
            <div>Elevation View</div>
          </div>
        );
      }

      return <div key={item.name}>{item.name.split('_').join(' ')}</div>;
    });
  };

  private getRequestData = () => {
    const { requirements, name, scheduledAt, tags } = this.state;
    const reqData: any = {
      name,
      scheduledAt: convertToUtc(scheduledAt.format()),
      requirements: this.getSelectedRequirementsArr(requirements),
      tags,
    };

    return reqData;
  };

  private handleConfirmDeleteModalClose = (proceed: boolean) => {
    if (proceed) {
      this.setState(
        {
          deleting: true,
        },
        () => {
          const { projectId, aoiId, mission, history } = this.props;

          missionAPIs
            .deleteMission(projectId, aoiId, mission.id)
            .then(() => {
              this.setState({
                deleting: false,
              });
              history.push(`/project/${projectId}/aoi/${aoiId}/list`);
            })
            .catch((err: any) => {
              this.setState({
                error: err,
                deleting: false,
              });
            });
        }
      );
    }

    this.setState({
      confirmDeleteMission: false,
    });
  };

  private handleDelete = () => {
    this.setState({
      confirmDeleteMission: true,
    });
  };

  private handleSave = () => {
    const { onSaveMission } = this.props;

    this.setState(
      {
        creating: true,
      },
      () => {
        if (onSaveMission) {
          onSaveMission(this.getRequestData())
            .then(() => {
              this.setState({
                saveMission: true,
                creating: false,
              });
            })
            .catch((err: any) => {
              this.setState({
                error: err,
                saveMission: false,
                creating: false,
              });
            });
        }
      }
    );
  };

  private handleSubmit = () => {
    const { incrementTabIndex, mission, onSubmitMission } = this.props;

    if (mission) {
      this.setState(
        {
          creating: true,
          finalSubmit: true,
        },
        () => {
          if (onSubmitMission) {
            onSubmitMission(this.getRequestData())
              .then(() => {
                this.setState(
                  {
                    createMissionModal: false,
                    creating: false,
                    finalSubmit: false,
                  },
                  () => {
                    incrementTabIndex();
                  }
                );
              })
              .catch((err: any) => {
                this.setState({
                  error: err,
                  createMissionModal: false,
                  creating: false,
                  finalSubmit: false,
                });
              });
          }
        }
      );
    } else {
      this.createAndSubmit(this.getRequestData());
    }
  };

  private createAndSubmit = (req: any) => {
    const { aoiId, projectId, handleRedirectId } = this.props;

    this.setState(
      {
        creating: true,
        finalSubmit: true,
      },
      () => {
        return missionAPIs
          .createMission(projectId, aoiId, req)
          .then((res) => {
            return missionAPIs
              .updateMission(projectId, aoiId, res.data.id, {
                ...req,
                status: 'PENDING',
              })
              .then((res) => {
                handleRedirectId(res.data.id);
              })
              .catch((err) => {
                try {
                  this.setState({
                    error: err.json(),
                  });
                } catch (e) {
                  this.setState({
                    error: { message: '' },
                  });
                }
              });
          })
          .catch((err) => {
            if (undefinedOrNull(err)) {
              this.setState({
                error: { message: `Oops... Some error occured. Try again.` },
              });

              return;
            }

            try {
              this.setState({
                error: err.json(),
              });
            } catch (e) {
              this.setState({
                error: { message: e },
              });
            }
          });
      }
    );
  };

  private getDefaultRequirements(): Requirement[] {
    return [
      {
        id: 'AERIAL_MAPS',
        name: 'Aerial View',
        description: 'Orthorectified as-is plan view of the site',
        selected: false,
      },
      {
        id: 'GROUND_CONTROL_POINTS',
        name: 'Use Ground Control Points',
        description:
          'Ground Control Points (GCP) are used for creating high accuracy models',
        selected: false,
        depends: 'AERIAL_MAPS',
      },
      {
        id: 'THREE_D_VIEW',
        name: '3-D View',
        description: '3-dimensional view of the site as a point cloud',
        selected: false,
        depends: 'AERIAL_MAPS',
      },
      {
        id: 'THERMAL_MAP',
        name: 'Thermal Map',
        description: 'Orthorectified plan view from thermal images',
        selected: false,
      },
      {
        id: 'INSPECTION',
        name: 'Inspection View',
        description:
          'Images for inspecting a structure, visualised using a 3D viewer',
        selected: false,
      },
      {
        id: 'PERSPECTIVE',
        name: 'Perspective View',
        description:
          'Oblique images of the site, visualised using a context map',
        selected: false,
      },
      {
        id: 'EXTERIOR_360',
        name: 'Exterior 360 View',
        description:
          'Panoramic 360 degrees views taken from specified aerial points',
        selected: false,
      },
      {
        id: 'THERMAL_INSPECTION',
        name: 'Thermal Inspection',
        description:
          'Thermal images for inspecting a structure, visualised using a 3D viewer',
        selected: false,
      },
    ];
  }

  private getRequirement = (id: REQUIREMENT_ID): Requirement | null => {
    const { requirements } = this.state;

    /* eslint-disable */
    for (const r of requirements) {
      if (r.id === id) {
        return r;
      }
    }
    /* eslint-enable */

    return null;
  };

  private isReqSelected = (id: REQUIREMENT_ID): boolean => {
    const req = this.getRequirement(id);

    return req !== null ? req.selected : false;
  };

  private handleRequirementToggle = (id: REQUIREMENT_ID) => {
    const req = this.getRequirement(id);

    if (req) {
      req.selected = !req.selected;

      if (req.selected) {
        if (req.depends) {
          const dep = this.getRequirement(req.depends);

          if (dep) {
            dep.selected = true;
          }
        }
      } else {
        // uncheck anything which depends on this
        const { requirements } = this.state;
        /* eslint-disable */
        for (const r of requirements) {
          if (r.depends === id) {
            r.selected = false;
          }
        }
        /* eslint-enable */
      }

      const { requirements } = this.state;

      this.setState({ requirements });
    }
  };

  private renderRequirementSelector = (
    req: Requirement | null,
    config: any = {}
  ) => {
    if (!req) {
      return <React.Fragment />;
    }

    const { mission } = this.props;
    const readOnly = mission && mission.status !== 'CREATED';

    return (
      <React.Fragment>
        <div className={styles.flexHalfLeft}>
          <div className={styles.paddingLeft}>
            <Checkbox
              checked={this.isReqSelected(req.id)}
              onChange={() => {
                this.handleRequirementToggle(req.id);
              }}
              disabled={readOnly}
            />
            <span className={styles.SuppReqName}>
              {config.name ? config.name : req.name}
            </span>
          </div>
          <label className={styles.labelReqName}>
            {config.description ? config.description : req.description}
          </label>
        </div>
      </React.Fragment>
    );
  };

  public render(): React.ReactNode {
    const {
      addedTags,
      creating,
      deleting,
      createMissionModal,
      error,
      epsgCode,
      finalSubmit,
      name,
      loaded,
      requirements,
      saveMission,
      scheduledAt,
      selectedTag,
      submitting,
      tags,
      confirmDeleteMission,
    } = this.state;
    const { mission, canEdit, canDelete } = this.props;
    const { getRequirement, renderRequirementSelector } = this;

    if (!loaded) {
      return <SkeletonLoader size={2} position="fixed" />;
    }

    if (error) {
      return (
        <div className={styles.errorContainer}>
          An error occured while creating the mission
          <p>{error && error.message ? error.message : null}</p>
          <button onClick={() => this.setState({ error: null })}>
            Try again
          </button>
        </div>
      );
    }

    const readOnly = mission && mission.status !== 'CREATED';
    const canSave = !mission || (mission && mission.status === 'CREATED');
    // if mission can be edited, or hasn't been created yet
    const canEditMission = canEdit || !mission;
    // mission can only be deleted if it exists
    const canDeleteMission = canDelete && mission;

    return (
      <React.Fragment>
        <div className={styles.centerBodyDiv}>
          <table className={styles.requirementTable}>
            <tbody>
              <tr className={styles.category}>
                <td>
                  <h3 className={styles.categoryTitleMaps}>MAPS</h3>
                  <div className={styles.requirements}>
                    <div className={styles.flexRow}>
                      {getRequirement('AERIAL_MAPS') &&
                        renderRequirementSelector(
                          getRequirement('AERIAL_MAPS')
                        )}
                      {getRequirement('AERIAL_MAPS') &&
                        renderRequirementSelector(
                          getRequirement('AERIAL_MAPS'),
                          {
                            name: 'Elevation View',
                            description:
                              'Colorised elevation profile of the site',
                          }
                        )}
                    </div>
                    <div className={styles.flexRow}>
                      {getRequirement('GROUND_CONTROL_POINTS') &&
                        renderRequirementSelector(
                          getRequirement('GROUND_CONTROL_POINTS')
                        )}
                      {getRequirement('THREE_D_VIEW') &&
                        renderRequirementSelector(
                          getRequirement('THREE_D_VIEW')
                        )}
                    </div>
                    <div className={styles.flexRow}>
                      {getRequirement('THERMAL_MAP') &&
                        renderRequirementSelector(
                          getRequirement('THERMAL_MAP')
                        )}
                    </div>
                  </div>
                </td>
              </tr>
              <tr className={styles.category}>
                <td>
                  <h3 className={styles.categoryTitle}>NON-MAPS</h3>
                  <div className={styles.requirements}>
                    <div className={styles.flexRow}>
                      {getRequirement('INSPECTION') &&
                        renderRequirementSelector(getRequirement('INSPECTION'))}
                      {getRequirement('THERMAL_INSPECTION') &&
                        renderRequirementSelector(
                          getRequirement('THERMAL_INSPECTION')
                        )}
                    </div>

                    <div className={styles.flexRow}>
                      {getRequirement('PERSPECTIVE') &&
                        renderRequirementSelector(
                          getRequirement('PERSPECTIVE')
                        )}
                      {getRequirement('EXTERIOR_360') &&
                        renderRequirementSelector(
                          getRequirement('EXTERIOR_360')
                        )}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className={styles.form}>
            <div className="form-inline">
              <div className={styles.missinNameDiv}>
                <h3 className={styles.categoryTitle}>MISSION NAME</h3>
                <Input
                  value={name}
                  onChange={this.handleNameChanged}
                  disabled={!canEditMission}
                  placeholder="Optional"
                  className={styles.inputField}
                />
              </div>
              <div className={styles.missionDetailWrapper}>
                <h3 className={styles.categoryTitle}>MISSION DATE</h3>
                <div className={styles.DateStyle}>
                  <DatePicker
                    selected={scheduledAt}
                    onChange={this.handleDateChanged}
                    disabled={!canEditMission}
                  />
                </div>
              </div>
              {mission && mission.status !== 'CREATED' ? (
                <div className={styles.missionDetailWrapper}>
                  {mission.tags ? (
                    <div>
                      <h3 className={styles.categoryTitle}>MISSION TAGS</h3>
                      <div className={styles.missionTagsDiv}>
                        {mission.tags.map((tag: any) => (
                          <div key={reactKey(tag)} className={styles.tag}>
                            {tag}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className={styles.tagsContainer}>
                  <h3 className={styles.categoryTitle}>MISSION TAGS</h3>
                  <div className={styles.MissionTagFlexRow}>
                    <div className={styles.TagFlexLeft}>
                      {
                        <select
                          value={[selectedTag]}
                          onChange={(event: any) => {
                            if (event.target.value) {
                              this.setState(
                                { selectedTag: event.target.value },
                                () => this.addTag()
                              );
                            }
                          }}
                          className={styles.dropDown}
                          multiple
                        >
                          {addedTags.map((addedTag: any) => (
                            <option key={reactKey(addedTag)} value={addedTag}>
                              {addedTag}
                            </option>
                          ))}
                        </select>
                      }
                    </div>
                    <div className={styles.tags}>
                      {tags.map((tag) => (
                        <div key={tag} className={styles.tag}>
                          {tag}
                          {readOnly ? null : (
                            <i
                              className="fa fa-trash"
                              onClick={this.getTagDeleteHandler(tag)}
                              style={{
                                cursor: 'pointer',
                                paddingLeft: '0.5em',
                                color: 'red',
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.submit}>
                {canDeleteMission ? (
                  <Button
                    disabled={!this.canSubmit()}
                    onClick={this.handleDelete}
                    text="DELETE"
                    loading={deleting}
                    loadingText="DELETING..."
                  />
                ) : null}
                {canEditMission ? (
                  <Button
                    disabled={!this.canSubmit()}
                    onClick={this.handleSave}
                    text="SAVE"
                    loading={creating}
                    loadingText="SAVING..."
                  />
                ) : null}
                {canSave ? (
                  <Button
                    disabled={!this.canSubmit()}
                    onClick={this.showCreateMissionModal}
                    text="SAVE & CONTINUE"
                    loading={submitting}
                    loadingText="CREATING MISSION..."
                  />
                ) : null}
              </div>
            </div>
          </div>
          <Modal
            title="Confirm Mission"
            centered
            footer={null}
            visible={createMissionModal}
            onCancel={this.hideCreateMissionModal}
            destroyOnClose
            maskClosable={false}
          >
            <p className={styles.headertext}>
              Are you sure that you want to submit this mission?
            </p>
            <div className={styles.bottombutton}>
              <table className={styles.modalTable}>
                <tbody>
                  {name ? (
                    <tr>
                      <td>Name</td>
                      <td>{name}</td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>Scheduled On</td>
                    <td>{moment(scheduledAt).tz('Etc/GMT').format('ll')}</td>
                  </tr>
                  <tr>
                    <td>Requirements</td>
                    <td>{this.getSelectedReqsModal(requirements)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className={styles.noteText}>
              You can now proceed with planning the required flights and GCP
              placements.
              <br />
              {epsgCode === null ? (
                <p>
                  &nbsp; Note: You must select and save the EPSG code in Survey
                  Planning tab before your data can be processed.
                </p>
              ) : null}
            </p>
            <div className={styles.bottomBtnDiv}>
              <Button
                className={styles.confirmbtn}
                onClick={this.hideCreateMissionModal}
                text="CANCEL"
              />

              <Button
                className={styles.confirmbtn}
                onClick={this.handleSubmit}
                loading={finalSubmit}
                loadingText="SUBMITTING  MISSION..."
                text="SUBMIT"
              />
            </div>
          </Modal>
        </div>
        {saveMission ? (
          <ModalNotification
            notificationTitle="Mission Saved"
            notificationBody="Your mission is saved and you can view the mission in the mission list"
            shownotificationModal
            handleModalClose={this.handleSubmitModal}
            error={error}
            okButtonTitle="OK"
          />
        ) : null}
        {confirmDeleteMission ? (
          <ModalNotification
            notificationTitle="Confirm Mission Deletion"
            notificationBody="Are you sure you want to delete this mission? This operation cannot be undone."
            shownotificationModal
            handleModalClose={this.handleConfirmDeleteModalClose}
            isConfirm
            okButtonTitle="PROCEED"
            cancelButtonTitle="CANCEL"
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default CreateMission;
