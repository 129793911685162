// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ViewSelector_container__HVUvo{min-width:200px;position:absolute;left:80px;top:10px;z-index:10}", "",{"version":3,"sources":["webpack://./src/components/View/ViewSelector/index.module.scss"],"names":[],"mappings":"AAEA,+BACE,eAAA,CACA,iBAAA,CACA,SAAA,CACA,QAAA,CACA,UAAA","sourcesContent":["@import '../../../styles/scss/base/base';\n\n.container {\n  min-width: 200px;\n  position: absolute;\n  left: 80px;\n  top: 10px;\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ViewSelector_container__HVUvo"
};
export default ___CSS_LOADER_EXPORT___;
