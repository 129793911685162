import * as React from 'react';
import { Typography, Radio } from 'antd';
import styles from './index.module.scss';
import { PaymentsPropsTypes, PaymentsStateTypes } from './index.types';
import { paymentMethodsListData } from './data';
import PaymentsAuthorizeNet from './AuthorizeNet';
import { undefinedOrNull } from '../../utils/functs';

const { Text } = Typography;

class Payments extends React.PureComponent<
  PaymentsPropsTypes,
  PaymentsStateTypes
> {
  public constructor(props: PaymentsPropsTypes) {
    super(props);

    this.state = {
      selectedPaymentMethod: 'authorizeNet',
    };
  }

  private PaymentMethodRender = () => {
    const { selectedPaymentMethod } = this.state;

    if (!undefinedOrNull(selectedPaymentMethod)) {
      const filteredPaymentMethodsListData = paymentMethodsListData.filter(
        (a) => a.type === selectedPaymentMethod
      );

      if (
        filteredPaymentMethodsListData &&
        filteredPaymentMethodsListData[0] &&
        filteredPaymentMethodsListData[0].type === 'authorizeNet'
      ) {
        return <PaymentsAuthorizeNet {...this.props} />;
      }
    }

    return (
      <div className={styles.noPaymentMethodSelected}>
        <Text strong>Please select a payment method to continue</Text>
      </div>
    );
  };

  public render(): React.ReactNode {
    const { PaymentMethodRender } = this;

    return (
      <div className={styles.container}>
        <div className={styles.paymentMethodPane}>
          <Text strong>Choose a payment method</Text>
          <div className={styles.paymentMethodsWrapper}>
            {paymentMethodsListData.map((item) => {
              return (
                <Radio key={item.type} checked={item.default}>
                  {item.label}
                </Radio>
              );
            })}
          </div>
        </div>
        <div className={styles.paymentProcessPane}>
          <PaymentMethodRender />
        </div>
      </div>
    );
  }
}

export default Payments;
