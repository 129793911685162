import autobind from 'autobind-decorator';
import classnames from 'classnames';
import * as React from 'react';
import Popover from 'react-popover';
import { Link } from 'react-router-dom';
import style from './TypeMenu.module.scss';
import { viewUrl } from '../../routes/urls';
import { GenericObjectType } from '../../shapes/app';
import { showCertifiedOnly } from '../../utils/localStorage';

interface IProps {
  viewId: string;
  menus: any[];
  activeType: string;
  aoiId: string;
  readOnly?: boolean;
}

const menuTypes = {
  map: 'maps',
  perspective: 'perspective',
  inspection: 'inspection',
  exterior_360: 'exterior 360',
  interior_360: 'interior 360',
  three_d: '3D View',
  video: 'Videos',
  site_navigation: 'Site Walkthrough',
};

const TypeMenuItem = ({ className, type, isActive }: any): JSX.Element => (
  <div
    className={classnames(style.menuItem, className, isActive && style.active)}
  >
    <div className={classnames(style.icon, style[type])} />
    <div className={style.name}>{menuTypes[type]}</div>
  </div>
);

interface IState {
  open: boolean;
  viewCertifiedOnly: boolean;
}

class TypeMenu extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      open: false,
      viewCertifiedOnly: false,
    };
  }

  public componentDidMount(): void {
    const { aoiId } = this.props;
    const viewCertifiedOnly = showCertifiedOnly(aoiId);

    this.setState({ viewCertifiedOnly });
  }

  @autobind
  private handleClick(): void {
    this.setState((state) => ({ open: !state.open }));
  }

  private menuItemViewable(menu: GenericObjectType) {
    const { viewCertifiedOnly } = this.state;

    return (
      menu.viewId &&
      ((menu.certifiedForDisplayCount > 0 && viewCertifiedOnly) ||
        !viewCertifiedOnly)
    );
  }

  public render(): React.ReactNode {
    const { open } = this.state;
    const { menus, activeType, readOnly } = this.props;

    if (!menus) {
      return null;
    }

    if (readOnly) {
      return (
        <div onClick={this.handleClick} className={style.readOnlyContainer}>
          <TypeMenuItem type={activeType} />
        </div>
      );
    }

    const DropdownMenu = (
      <div className={style.dropDownContainer}>
        {menus.map((menu) => {
          let menuElm = (
            <TypeMenuItem
              key={menu.name}
              isActive={menu.name === activeType}
              className={classnames(
                style.dropDownItem,
                this.menuItemViewable(menu) || style.disabled
              )}
              type={menu.name}
            />
          );

          if (this.menuItemViewable(menu)) {
            menuElm = (
              <Link
                key={menu.name}
                onClick={this.handleClick}
                to={viewUrl(menu.projectId, menu.aoiId, menu.viewId)}
              >
                {menuElm}
              </Link>
            );
          }

          return menuElm;
        })}
      </div>
    );

    return (
      <Popover
        onOuterAction={this.handleClick}
        isOpen={open}
        place="below"
        enterExitTransitionDurationMs={100}
        body={DropdownMenu}
      >
        <div onClick={this.handleClick} className={style.container}>
          <TypeMenuItem type={activeType} />
          <div
            className={classnames(style.icon, open ? style.up : style.down)}
          />
        </div>
      </Popover>
    );
  }
}

export default TypeMenu;
