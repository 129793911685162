import * as React from 'react';
import styles from './index.module.scss';
import { CompassPropsTypes } from './index.types';
import { undefinedOrNull } from '../../utils/functs';

class Compass extends React.PureComponent<CompassPropsTypes> {
  private needleRotation = () => {
    const { rotation, direction } = this.props;

    if (!undefinedOrNull(rotation)) {
      return rotation;
    }

    if (!undefinedOrNull(direction)) {
      switch (direction) {
        case 'n':
        default:
          return 0;
        case 'ne':
          return 45;
        case 'e':
          return 90;
        case 'se':
          return 135;
        case 's':
          return 180;
        case 'sw':
          return 225;
        case 'w':
          return 270;
        case 'nw':
          return 315;
      }
    }

    return 0;
  };

  private needleDirectionStyles = () => {
    const rotation = this.needleRotation();

    return {
      WebkitTransform: `rotate(${rotation}deg)`,
      msTransform: `rotate(${rotation}deg)`,
      transform: `rotate(${rotation}deg)`,
    };
  };

  public render(): React.ReactNode {
    return (
      <div className={styles.container}>
        <div
          className={styles.compassWrapper}
          style={this.needleDirectionStyles()}
        >
          <div className={styles.needleTop} />
          <div className={styles.btCenter} />
          <div className={styles.needleBottom} />
        </div>
      </div>
    );
  }
}

export default Compass;
