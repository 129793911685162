import {
  BorderInnerOutlined,
  CameraOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import * as React from 'react';
import DynamicScale from 'src/components/DynamicScale';
import { ValueRange } from 'src/components/OpenLayersMap/index.types';
import { ELEVATION_DIFF_COLORS } from '../../../../constants/colors';
import { ViewEvent } from '../../index.types';
import OpenLayersRenderer from '../../MapView/OpenLayersRenderer';
import { MapStateProvider } from '../../MapView/OpenLayersRenderer/MapStateProvider';
import style from './index.module.scss';
import {
  ElevationDifferenceViewPropsType,
  ElevationDifferenceViewStateType,
} from './index.types';

export default class ElevationDifference extends React.PureComponent<
  ElevationDifferenceViewPropsType,
  ElevationDifferenceViewStateType
> {
  private stateProviderRef = React.createRef<MapStateProvider>();

  constructor(props: ElevationDifferenceViewPropsType) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    this.initializeViewState(this.props);

    const { onFooterControlsFragChange } = this.props;

    if (onFooterControlsFragChange) {
      onFooterControlsFragChange(
        <div style={{ width: 'auto', zIndex: 10 }}>
          <BorderInnerOutlined onClick={() => {}} className={style.icon} />
          <EnvironmentOutlined onClick={() => {}} className={style.icon} />
          <CameraOutlined
            onClick={() => {
              if (this.stateProviderRef.current) {
                this.stateProviderRef.current.saveScreenshot();
              }
            }}
            className={style.icon}
          />
        </div>
      );
    }
  }

  public UNSAFE_componentWillReceiveProps(
    newProps: ElevationDifferenceViewPropsType
  ) {
    const { view: newView } = newProps;
    const { view: oldView } = this.props;

    if (newView && newView.id !== oldView.id) {
      this.initializeViewState(newProps);
    }
  }

  private initializeViewState = (props: ElevationDifferenceViewPropsType) => {
    const { view } = props;

    if (view && view?.demColorMapping?.length) {
      const { demColorMapping } = view;
      const elevations = demColorMapping.map((cm) => cm.elevation);
      const maxElevation = Math.ceil(Math.max(...elevations));
      const minElevation = Math.floor(Math.min(...elevations));

      this.setState({
        elevationRange: {
          min: minElevation,
          max: maxElevation,
        },
        sourceElevationRange: {
          min: minElevation,
          max: maxElevation,
        },
        colors: ELEVATION_DIFF_COLORS,
      });

      return;
    }

    console.error(
      'View missing parameters required to intialize elevation difference.'
    );
  };

  private handleViewEvent = (e: ViewEvent<any>) => {
    switch (e.type) {
      case 'renderer_state_changed': {
        this.setState({
          rendererState: e.data,
        });

        break;
      }

      default:
        break;
    }
  };

  private updateSource = () => {
    const { rendererState } = this.state;

    if (rendererState && rendererState.layers) {
      rendererState.layers.forEach((l) => {
        if (l._layer) {
          l._layer.getSource().changed();
        }
      });
    }
  };

  private onValueRangeUpdate = (value: ValueRange) => {
    this.setState(
      {
        elevationRange: value,
      },
      () => {
        this.updateSource();
      }
    );
  };

  public render() {
    const { view, olView, children } = this.props;
    const { sourceElevationRange, elevationRange, colors } = this.state;

    let unitsLabel = 'm';

    if (view.coordinateUnits === 'FEET') {
      unitsLabel = 'ft';
    } else if (view.coordinateUnits === 'US_SURVEY_FEET') {
      unitsLabel = 'usft';
    }

    return (
      <div className={style.container}>
        <div className={style.mapContainer}>
          {sourceElevationRange && colors ? (
            <DynamicScale
              sourceRange={sourceElevationRange}
              colors={colors}
              onUpdate={this.onValueRangeUpdate}
              units={unitsLabel}
              clampMax={false}
              clampMin={false}
            />
          ) : null}
          <OpenLayersRenderer
            view={view}
            viewId={view.id}
            sourceUrl={view.sourceUrl}
            olView={olView}
            valueRange={elevationRange}
            onConfigCallbackChange={() => {}}
            onEvent={this.handleViewEvent}
            className={style.map}
          >
            {children}
            <MapStateProvider ref={this.stateProviderRef} convertToLatLon />
          </OpenLayersRenderer>
        </div>
      </div>
    );
  }
}
