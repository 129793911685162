import { applyMiddleware, compose, createStore } from 'redux';
import ReduxActionsPromise from 'redux-actions-promise';
import promiseMiddleware from 'redux-promise-middleware';
import { createLogger } from 'redux-logger';
import reduxThunk from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import reducers from '../reducers/root';

const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

// Logging Middleware
const reduxLogger = createLogger({
  level: 'info',
  collapsed: true,
});

const middleware = [
  reduxLogger,
  reduxThunk,
  promiseMiddleware(),
  ReduxActionsPromise,
  loadingBarMiddleware({
    promiseTypeSuffixes: [
      'PENDING|STARTED',
      'FULFILLED|SUCCESS',
      'REJECTED|FAILED',
    ],
  }),
];

const enhancer = composeEnhancers(
  applyMiddleware(...middleware)
  // other store enhancers if any
);

export default function configureStore() {
  return createStore(reducers, enhancer);
}
