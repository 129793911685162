import * as React from 'react';
import classnames from 'classnames';
import style from './Unit.module.scss';

export interface IUnitProps {
  label: string;
  children?: any;
}

export interface IUnit2Props {
  label: string;
  value1: any;
  value2: any;
  className?: string;
}

const Unit = ({ label, children }: IUnitProps): JSX.Element => (
  <div className={style.container}>
    <table>
      <tbody>
        <tr>
          <td>
            <label>{label}</label>
          </td>
        </tr>
        <tr>
          <td>
            <div>{children}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export const Unit2 = ({
  label,
  value1,
  value2,
  className,
}: IUnit2Props): JSX.Element => (
  <>
    <label className={style.unitLabel}>{label}</label>
    <div className={classnames(style.unit2container, className)}>
      <table>
        <tbody>
          <tr>
            <td>
              <div>{value1}</div>
            </td>
            <td>
              <div>{value2}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
);

export default Unit;
