import { Select } from 'antd';
import React from 'react';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import { Button } from 'src/components/Button';
import style from './index.module.scss';

export interface LayerSelectorProps {
  layers?: LayerDescriptor[];
  selectedLayer?: LayerDescriptor;
  select: (l: LayerDescriptor) => void;
  clear: () => void;
}
export const LayerSelector: React.FC<LayerSelectorProps> = ({
  selectedLayer,
  layers,
  select,
  clear,
}) => {
  return (
    <div className={style.selectorGrid}>
      <Select
        className={style.fullWidth}
        value={selectedLayer?.name}
        placeholder="Select a vector layer to edit"
        onChange={(name) => {
          const layer = layers?.find((l) => l.name === name);

          if (layer) select(layer);
        }}
      >
        {layers
          ?.filter((l) => l.type === 'vector')
          .map((l) => (
            <Select.Option key={l.name} value={l.name}>
              {l.name}
            </Select.Option>
          ))}
      </Select>
      <Button onClick={() => clear()}>Clear</Button>
    </div>
  );
};
