import ApiBase from './ApiBase';
import { GenericApisReturnPromiseTypes } from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_CAPI_URL } from '../constants/urls';

export default class DiagnosticOpApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = `/v1`;
  }

  public getInputArtifacts(
    projectId: string,
    aoiId: string,
    docType: string
  ): GenericApisReturnPromiseTypes {
    return this.get(
      `${this._baseUrl}/upload/search?project_id=${projectId}&block_id=${aoiId}&upload_type=input_artifact&document_type=${docType}`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch sessions for a plan`,
        });
      });
  }

  public getOutputArtifactForSession(
    sessionId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(
      `${this._baseUrl}/upload/search?session_id=${sessionId}&upload_type=output_artifact`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch sessions for a plan`,
        });
      });
  }

  public getArtifactLink(guid: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/upload/download?guid=${guid}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch sessions for a plan`,
        });
      });
  }
}
