import { Step } from 'react-joyride';

export function showCoachMarks(steps: Step[]) {
  return {
    type: 'SHOW_COACHMARKS',
    payload: steps,
  };
}

export function runCoachMarks() {
  return {
    type: 'RUN_COACHMARKS',
  };
}

export function resetCoachMarks() {
  return {
    type: 'RESET_COACHMARKS',
  };
}
