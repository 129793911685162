import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import { RendererState } from 'src/components/View/index.types';
import {
  MapMakerDrawControl,
  MapMakerDrawMode,
} from 'src/components/View/MapView/OpenLayersRenderer/MapMakerDrawControl';
import { BaseControlProps } from 'src/components/View/ViewControls/index.types';
import { useLayerManagement } from 'src/components/View/ViewControls/MapMakerControls/hooks';
import { LayerControl } from 'src/components/View/ViewControls/MapMakerControls/LayerControl';
import { LayerSelector } from 'src/components/View/ViewControls/MapMakerControls/LayerSelector';
import { NoLayerPlaceholder } from 'src/components/View/ViewControls/MapMakerControls/NoLayerPlaceholder';
import * as ControlBox from '../../../../ControlBox/ControlBox';
import style from './index.module.scss';

export interface AddShapeControlBoxProps extends BaseControlProps {
  layers?: LayerDescriptor[];
  selectedLayer?: LayerDescriptor;
  rendererState?: RendererState;
}
export const AddShapeControlBox: React.FC<AddShapeControlBoxProps> = ({
  layers,
  onEvent,
  selectedLayer,
  onClose,
  onRenderFragChange,
  rendererState,
}) => {
  const {
    layer,
    resetLayerFeatures,
    updateInitialState,
    saveLayer,
    selectLayer,
    clearLayer,
  } = useLayerManagement(onEvent, selectedLayer, rendererState);
  const [geometryType, setGeometryType] = useState<
    'point' | 'line' | 'polygon'
  >();

  useEffect(() => {
    if (layer && geometryType) {
      updateInitialState(layer, layer.getSource().getFeatures());
      onRenderFragChange(() => (
        <MapMakerDrawControl
          layer={layer}
          drawMode={getDrawMode(geometryType)}
        />
      ));
    }

    return () => {
      resetLayerFeatures();
      updateInitialState(undefined);
      onRenderFragChange(undefined);
    };
    // The eslint-disable is so that we can ignore onRenderFragChange as a dependency.
    // There's no easy way to memoize it, and each render, a new version of the
    // function is created, causing the effect to be called more than necessary.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geometryType, layer, resetLayerFeatures, updateInitialState]);

  return (
    <ControlBox.Wrapper
      className={style.container}
      renderHeader={() => (
        <Header
          onClose={() => {
            resetLayerFeatures();
            onClose();
          }}
        />
      )}
    >
      <LayerSelector
        select={selectLayer}
        clear={clearLayer}
        layers={layers}
        selectedLayer={selectedLayer}
      />
      {selectedLayer ? (
        <>
          <div className={classnames(style.controlGrid, style.padTop)}>
            <div
              className={classnames(style.intent, {
                [style.active]: geometryType === 'point',
              })}
              onClick={() => setGeometryType('point')}
            >
              Point
            </div>
            <div
              className={classnames(style.intent, {
                [style.active]: geometryType === 'line',
              })}
              onClick={() => setGeometryType('line')}
            >
              Line
            </div>
            <div
              className={classnames(style.intent, {
                [style.active]: geometryType === 'polygon',
              })}
              onClick={() => setGeometryType('polygon')}
            >
              Polygon
            </div>
          </div>
          <div className={style.padTop}>
            <LayerControl reset={resetLayerFeatures} save={saveLayer} />
          </div>
        </>
      ) : (
        <NoLayerPlaceholder />
      )}
    </ControlBox.Wrapper>
  );
};

export const Header: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <>
      <ControlBox.Title title="Add Shape" />
      <ControlBox.Icon
        onClick={() => {
          onClose();
        }}
        name="close"
      />
    </>
  );
};

export const getDrawMode = (
  geometry: 'point' | 'line' | 'polygon'
): MapMakerDrawMode => {
  switch (geometry) {
    case 'point':
      return 'add_point';
    case 'line':
      return 'add_line';
    case 'polygon':
      return 'add_polygon';
    default:
      return 'add_polygon';
  }
};
