import * as React from 'react';
import SkeletonLoader from '../SkeletonLoader';

interface IProps {
  view: {};
  onConfigChange?: (config: {}) => void;
}

interface IState {
  viewConfig: {};
}

class Interior360 extends React.Component<IProps, IState> {
  public render(): React.ReactNode {
    return <SkeletonLoader size={2} position="fixed" />;
  }
}

export default Interior360;
