import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserSandbox from '.';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import {
  showCoachMarks,
  resetCoachMarks,
} from '../../redux/actions/coachmarks';
import { UserSandboxTypes } from './index.types';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
      showCoachMarks,
      resetCoachMarks,
    },
    dispatch
  );
};

export default connect<
  UserSandboxTypes.ReduxState,
  UserSandboxTypes.DispatchProps,
  UserSandboxTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(UserSandbox);
