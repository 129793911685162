// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FloorMapInset_container__kISoY{height:300px;width:400px}", "",{"version":3,"sources":["webpack://./src/components/Interior360Manager/FloorMapInset/index.module.scss"],"names":[],"mappings":"AAEA,gCACE,YAAA,CACA,WAAA","sourcesContent":["@import '../../../styles/scss/base/base';\n\n.container {\n  height: #{$floorMapInsetHeight}px;\n  width: #{$floorMapInsetWidth}px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FloorMapInset_container__kISoY"
};
export default ___CSS_LOADER_EXPORT___;
