import ApiBase from './ApiBase';

export default class DownloadAPIs extends ApiBase {
  public getURL(
    viewId: string,
    id: string,
    documentType: string
  ): Promise<any> {
    return this.get(
      `/v1/downloads/view/${viewId}/${id}/url?documentType=${documentType}`
    ).then(({ data }) => {
      if (data) {
        return data.signedUrl;
      }

      return null;
    });
  }
}
