import classnames from 'classnames';
import turfLength from '@turf/length';
import { MapBrowserEvent } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import GeometryType from 'ol/geom/GeometryType';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import { Draw, Modify } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import OverlayPositioning from 'ol/OverlayPositioning';
import Projection from 'ol/proj/Projection';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import * as React from 'react';
import { APP_PRIMARY_COLOR } from '../../../../../constants/colors';
import { DRAW_LAYER_Z_INDEX } from '../../../../../constants';
import style from './index.module.scss';
import {
  DrawControlPropsType,
  DrawControlStateType,
  DrawMode,
} from './index.types';
import { getColorWithOpacity } from '../utils';

const GEOJSON_WRITER = new GeoJSON({
  dataProjection: new Projection({ code: 'EPSG:4326' }),
  featureProjection: new Projection({ code: 'EPSG:3857' }),
});

/**
 * DrawControl for Openlayers to allow users to:
 *  - Draw / Edit:
 *      - Polygons
 *      - Linestrings
 *      - Points
 *  - Display static features
 *  - Select / Delete features
 *  - Show appropriate help-texst for drawing
 */

export default class DrawControl extends React.PureComponent<
  DrawControlPropsType,
  DrawControlStateType
> {
  private helpToolTipElement: HTMLElement | null = null;

  private helpToolTipOverlay: Overlay | null = null;

  constructor(props: DrawControlPropsType) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    this.initializeDrawControl(this.props);
  }

  public UNSAFE_componentWillReceiveProps(newProps: DrawControlPropsType) {
    const { drawMode: newDrawMode, olMap: newOlMap, allowEdit } = newProps;
    const {
      drawMode: oldDrawMode,
      olMap: oldOlMap,
      allowEdit: oldAllowEdit,
    } = this.props;

    if (oldOlMap === undefined && newOlMap) {
      this.initializeDrawControl(newProps);
    }

    if (newDrawMode !== oldDrawMode) {
      // re-init draw control on drawMode change
      this.resetDrawControl();
      this.initializeDrawControl(newProps);
    } else {
      // update modify control w/o resetting draw control
      // eslint-disable-next-line no-lonely-if
      if (allowEdit !== oldAllowEdit && newOlMap) {
        if (allowEdit) {
          this.initializeModify(newOlMap);
        } else {
          this.resetModify();
        }
      }
    }
  }

  public componentWillUnmount() {
    this.resetDrawControl();
  }

  private createHelpToolTip = (olMap?: Map) => {
    if (!olMap) {
      return;
    }

    if (this.helpToolTipElement) {
      // eslint-disable-next-line no-unused-expressions
      this.helpToolTipElement.parentNode?.removeChild(this.helpToolTipElement);
    }

    this.helpToolTipElement = document.createElement('div');
    this.helpToolTipElement.className = classnames(style.olTooltip, 'hidden');

    this.helpToolTipOverlay = new Overlay({
      element: this.helpToolTipElement,
      offset: [20, 0],
      positioning: OverlayPositioning.CENTER_LEFT,
      className: style.olTooltipContainer,
    });

    olMap.addOverlay(this.helpToolTipOverlay);
  };

  private getDrawingTypeFromDrawMode = (drawMode: DrawMode): GeometryType => {
    switch (drawMode) {
      case 'FreehandLineString':
      case 'LineStringWithDistance':
      case 'LineString': {
        return GeometryType.LINE_STRING;
      }

      case 'Point': {
        return GeometryType.POINT;
      }

      case 'Polygon':
      default: {
        return GeometryType.POLYGON;
      }
    }
  };

  private initializeDrawControl({
    olMap,
    drawMode,
    allowEdit,
    allowMultipleFeatures,
    olDrawSource,
  }: DrawControlPropsType) {
    if (olMap) {
      const vectorSource =
        olDrawSource ||
        new VectorSource({
          wrapX: false,
          features: [],
        });

      const layer = new VectorLayer({
        style: () => {
          return new Style({
            stroke: new Stroke({
              color: APP_PRIMARY_COLOR,
              width: 4,
            }),
            fill: new Fill({
              color: getColorWithOpacity(APP_PRIMARY_COLOR, 0.2),
            }),
            image: new CircleStyle({
              radius: 6,
              fill: new Fill({
                color: APP_PRIMARY_COLOR,
              }),
            }),
          });
        },
        zIndex: DRAW_LAYER_Z_INDEX,
      });

      layer.setSource(vectorSource);
      vectorSource.on('addfeature', this.onGlobalFeatureCreate);

      olMap.addLayer(layer);

      let draw;

      // add draw control if empty features
      if (vectorSource.getFeatures().length === 0) {
        draw = new Draw({
          source: vectorSource,
          type: this.getDrawingTypeFromDrawMode(drawMode),
          freehand: drawMode === 'FreehandLineString',
        });

        draw.on('drawend', this.onFeatureCreate);
        draw.on('drawstart', this.onDrawStart);

        olMap.addInteraction(draw);
      }

      // IMPORTANT: ensure this event listener is unmounted on component unmount
      document.addEventListener('keyup', this.onEscape);

      if (allowMultipleFeatures) {
        olMap.on('dblclick', this.onMultiFeatureCreate);
      }

      // adding event handlers
      this.createHelpToolTip(olMap);
      olMap.on('pointermove', this.onPointerMove);
      olMap.getViewport().addEventListener('mouseout', this.onMouseOut);

      this.setState(
        {
          source: vectorSource,
          draw,
          layer,
        },
        () => {
          if (allowEdit) {
            this.initializeModify(olMap);
          }
        }
      );

      return;
    }

    console.error('Map must be initialized before draw control.');
  }

  private resetDrawControl() {
    const { olMap } = this.props;
    const { draw, modify, layer, source } = this.state;

    if (olMap) {
      if (draw) olMap.removeInteraction(draw);
      if (modify) olMap.removeInteraction(modify);
      if (layer) olMap.removeLayer(layer);

      // to prevent event listener from persisting, when component is unmounted
      olMap.un('dblclick', this.onMultiFeatureCreate);
      olMap.un('pointermove', this.onPointerMove);
      olMap.getViewport().removeEventListener('mouseout', this.onMouseOut);
    }

    if (source) {
      source.un('addfeature', this.onGlobalFeatureCreate);
    }

    // IMPORTANT: global event, which must be unmounted
    document.removeEventListener('keyup', this.onEscape);

    this.setState({
      draw: undefined,
      modify: undefined,
      layer: undefined,
      source: undefined,
    });
  }

  private initializeModify = (olMap: Map) => {
    const { source } = this.state;

    if (source) {
      const modify = new Modify({ source });

      modify.on('modifyend', this.onFeatureEdit);
      olMap.addInteraction(modify);

      this.setState({
        modify,
      });

      return;
    }

    console.error('Could not find source to initialize modify with');
  };

  private resetModify = () => {
    const { olMap } = this.props;
    const { modify } = this.state;

    if (olMap && modify) {
      olMap.removeInteraction(modify);
    }
  };

  private getHelpMessageForLineSegmentDistance = (
    e: MapBrowserEvent
  ): string | null => {
    const { olMap } = this.props;

    if (olMap) {
      const geometryList: Geometry[] = [];

      olMap.forEachFeatureAtPixel(
        e.pixel,
        (feature) => {
          const geometry = feature.getGeometry();

          if (geometry && geometry.getType() === GeometryType.LINE_STRING) {
            geometryList.push(geometry as Geometry);
          }
        },
        {
          hitTolerance: 10,
          layerFilter: () => true,
        }
      );

      if (geometryList.length > 0) {
        const geometry = geometryList[0] as LineString;
        const coordinates = geometry.getCoordinates();
        const geoJsonWriter = this.getGeoJsonWriter();

        const lines = [];
        const distances = [];

        for (let i = 0; i < coordinates.length - 1; i += 1) {
          const line = new LineString([coordinates[i], coordinates[i + 1]]);
          const linePoint = line.getClosestPoint(e.coordinate);

          // assuming that if the line has been picked up, the closest line segment is the one we want to get
          // the distance of.
          lines.push(line);
          distances.push(new LineString([e.coordinate, linePoint]).getLength());
        }

        const minIndex = distances.indexOf(Math.min(...distances));
        const lineIntersection = geoJsonWriter.writeGeometryObject(
          lines[minIndex]
        );

        if (lineIntersection) {
          const meterLength = +turfLength(lineIntersection, {
            units: 'meters',
          }).toFixed(2);
          const footLength = parseFloat((meterLength * 3.28084).toFixed(2));

          return `${meterLength} m (${footLength} ft)`;
        }
      }
    }

    return null;
  };

  private getHelpMessageForDrawing = (): string | null => {
    const { drawMode, allowMultipleFeatures } = this.props;
    const { sketchFeature } = this.state;

    switch (drawMode) {
      case 'Polygon': {
        let completePolygon = false;

        if (sketchFeature && sketchFeature.getGeometry()) {
          const g = sketchFeature.getGeometry();

          // checking against 3, as:
          // the initial set of points are the ones have been drawn,
          // the pen-ultimate is the current location of cursor,
          // and the last point is the first point (to ensure polygon is closed)
          if (
            g &&
            GEOJSON_WRITER.writeGeometryObject(g).coordinates[0].length > 3
          ) {
            completePolygon = true;
          }
        }

        return completePolygon
          ? 'Double-click to close polygon.'
          : 'Click to start drawing.';
      }

      case 'Point': {
        if (allowMultipleFeatures) {
          return 'Click to add Point. Double Click to finish.';
        }

        return 'Click to add Point.';
      }

      case 'LineStringWithDistance':
      case 'LineString': {
        return sketchFeature
          ? 'Double-click to finish.'
          : 'Click to start drawing.';
      }

      case 'FreehandLineString': {
        return 'Click & drag to draw.';
      }

      default: {
        return null;
      }
    }
  };

  // Event handling functions ------------------------

  private onDrawStart = (e: DrawEvent) => {
    this.setState({
      // storing state of intermediate feature
      sketchFeature: e.feature,
    });
  };

  private onEscape = (e: KeyboardEvent) => {
    const { draw } = this.state;

    // 27 = ESC button
    if (e.keyCode === 27) {
      if (draw) {
        this.resetDrawControl();
        this.initializeDrawControl(this.props);
      }

      this.setState({
        sketchFeature: undefined,
      });
    }
  };

  private onPointerMove = (e: MapBrowserEvent) => {
    const { allowEdit, drawMode } = this.props;
    const { draw } = this.state;

    if (!this.helpToolTipElement || !this.helpToolTipOverlay) {
      return;
    }

    this.helpToolTipOverlay.setPosition(e.coordinate);

    if (draw) {
      const helpMessage = this.getHelpMessageForDrawing();

      if (helpMessage) {
        this.helpToolTipElement.innerHTML = helpMessage;
        this.helpToolTipElement.classList.remove('hidden');
      } else {
        this.helpToolTipElement.classList.add('hidden');
      }

      return;
    }

    if (drawMode === 'LineStringWithDistance') {
      const helpMessage = this.getHelpMessageForLineSegmentDistance(e);

      if (helpMessage) {
        this.helpToolTipElement.innerHTML = helpMessage;
        this.helpToolTipElement.classList.remove('hidden');

        // prevent override with edit help message, if distance info present
        return;
      }
    }

    if (allowEdit) {
      const helpMessage = 'Click & drag to edit.';

      this.helpToolTipElement.innerHTML = helpMessage;
      this.helpToolTipElement.classList.remove('hidden');
    } else {
      this.helpToolTipElement.classList.add('hidden');
    }
  };

  private onMouseOut = () => {
    if (this.helpToolTipElement) {
      this.helpToolTipElement.classList.add('hidden');
    }
  };

  private getGeoJsonWriter(): GeoJSON {
    const { geoJsonWriter } = this.props;

    return geoJsonWriter || GEOJSON_WRITER;
  }

  private onFeatureCreate = (e: DrawEvent) => {
    const { onFeatureCreate, allowMultipleFeatures } = this.props;

    // setting a unique id to every feature created
    e.feature.setId(this.uuid());

    if (onFeatureCreate && !allowMultipleFeatures) {
      const featureObject = this.getGeoJsonWriter().writeFeatureObject(
        e.feature,
        {
          rightHanded: true,
        }
      );

      featureObject.properties = {
        id: e.feature.getId(),
      };

      onFeatureCreate({
        features: [featureObject],
      });
    }

    this.onGlobalFeatureCreate();
  };

  private onGlobalFeatureCreate = () => {
    const { allowMultipleFeatures, olMap } = this.props;
    const { draw } = this.state;

    if (!allowMultipleFeatures && olMap && draw) {
      // remove ability to draw if only a single feature is allowed
      olMap.removeInteraction(draw);
      this.setState({
        draw: undefined,
      });
    }

    this.setState({
      sketchFeature: undefined,
    });
  };

  private onMultiFeatureCreate = () => {
    const { olMap, onFeatureCreate } = this.props;
    const { draw, source } = this.state;

    if (source && onFeatureCreate) {
      const featureObjects = source.getFeatures().map((f) => {
        const o = this.getGeoJsonWriter().writeFeatureObject(f, {
          rightHanded: true,
        });

        o.properties = {
          id: f.getId(),
        };

        return o;
      });

      onFeatureCreate({
        features: featureObjects,
      });
    }

    if (olMap && draw) {
      // removing ability to add more features
      olMap.removeInteraction(draw);
      this.setState({
        draw: undefined,
      });
    }
  };

  private onFeatureEdit = (_e: ModifyEvent) => {
    const { onFeatureEdit } = this.props;
    const { source } = this.state;

    if (onFeatureEdit && source) {
      const features = source.getFeatures();
      const featureObjects = features.map((f) => {
        const o = this.getGeoJsonWriter().writeFeatureObject(f);

        o.properties = {
          id: f.getId(),
        };

        return o;
      });

      onFeatureEdit({
        features: featureObjects,
      });
    }
  };

  private uuid() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c) => {
        // eslint-disable-next-line no-bitwise
        const r = (dt + Math.random() * 16) % 16 | 0;

        dt = Math.floor(dt / 16);

        // eslint-disable-next-line no-bitwise
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }

  public render() {
    // placeholder as component will be rendered within the OpenLayers Map
    return <React.Fragment />;
  }
}
