import * as React from 'react';
import _ from 'lodash';
import OLView from 'ol/View';

import { undefinedOrNull } from '../../utils/functs';
import { getCoachMarkPreference } from '../../utils/localStorage';
import { VIEW_PATH } from '../../routes/paths';
import { View } from '../../api/views.types';
import SkeletonLoader from '../SkeletonLoader';
import ImageView from './ImageView';
import style from './index.module.scss';
import {
  CoachMarkOverrideMetadata,
  RendererState,
  ViewConfig,
  ViewDisplayPropsType,
  ViewDisplayStateType,
  ViewEvent,
} from './index.types';
import MapView from './MapView';
import SplitView from './SplitView';
import PanoramaView from './PanoramaView';
import ThreeDView from './ThreeDView';
import ThreeDTilesView from './ThreeDTilesView';
import VideoView from './VideoView';
import ViewControls from './ViewControls';
import { ControlTypes, VALID_CONTROL_TYPES } from './ViewControls/index.types';
import ViewSelector from './ViewSelector';
import { HEADER, CONTROLS, FOOTER, EXTRA_CONTROLS } from './coachmarkSteps';

export default class ViewDisplay extends React.PureComponent<
  ViewDisplayPropsType,
  ViewDisplayStateType
> {
  constructor(props: ViewDisplayPropsType) {
    super(props);

    this.state = {
      controlSelection: {
        activeControlIds: [],
      },
      rendererState: {},
    };
  }

  public componentDidMount() {
    const {
      registerViewConfigProvider,
      view,
      showCoachMarks,
      coachMarkOverride,
    } = this.props;

    if (registerViewConfigProvider) {
      registerViewConfigProvider(() => {
        const { renderConfigCallback } = this.state;

        if (renderConfigCallback) {
          return renderConfigCallback();
        }

        return {};
      });
    }

    if (view && showCoachMarks && coachMarkOverride) {
      setTimeout(() => {
        this.launchCustomCoachMarks(view, coachMarkOverride);
      }, coachMarkOverride?.timeoutDuration || 4000);
    }
  }

  public componentDidUpdate(prevProps: ViewDisplayPropsType): void {
    const {
      view,
      headerMenus,
      showCoachMarks,
      viewId,
      resetCoachMarks,
      coachMarkOverride,
    } = this.props;

    // first view and headerMenu load
    if (
      undefinedOrNull(prevProps.view) &&
      !undefinedOrNull(view) &&
      !undefinedOrNull(showCoachMarks) &&
      !undefinedOrNull(resetCoachMarks)
    ) {
      resetCoachMarks();

      if (!undefinedOrNull(coachMarkOverride)) {
        setTimeout(() => {
          this.launchCustomCoachMarks(view, coachMarkOverride);
        }, coachMarkOverride?.timeoutDuration || 4000);
      } else if (!undefinedOrNull(headerMenus) && headerMenus.length > 0) {
        if (view.type !== 'video' && view.type !== 'three_d') {
          setTimeout(() => {
            this.launchCoachmarks(view, headerMenus);
          }, 4000);
        }
      }
    }

    // view changes
    if (
      prevProps.viewId !== viewId &&
      !undefinedOrNull(view) &&
      !undefinedOrNull(showCoachMarks) &&
      !undefinedOrNull(resetCoachMarks)
    ) {
      resetCoachMarks();
      // showCoachMarks(this.getViewCoachmarkSteps(view, headerMenus));

      if (!undefinedOrNull(coachMarkOverride)) {
        setTimeout(() => {
          this.launchCustomCoachMarks(view, coachMarkOverride);
        }, coachMarkOverride?.timeoutDuration || 4000);
      } else if (!undefinedOrNull(headerMenus) && headerMenus.length > 0) {
        if (view.type !== 'video' && view.type !== 'three_d') {
          setTimeout(() => {
            this.launchCoachmarks(view, headerMenus);
          }, 4000);
        }
      }
    }
  }

  public componentWillUnmount(): void {
    const { resetCoachMarks } = this.props;

    if (!undefinedOrNull(resetCoachMarks)) {
      resetCoachMarks();
    }
  }

  private getViewCoachmarkSteps = (view: View, headerMenus: any[]) => {
    let headerData: any[] = [{ name: 'ViewTypeDropdown' }];

    if (view.type === 'map') {
      headerData = [...headerData, ...headerMenus];
    }

    const headerSteps = headerData.map((headerInfo) => HEADER[headerInfo.name]);
    const controlSteps = this.getViewControls().map(
      (control) => CONTROLS[control]
    );
    const extraControls = !undefinedOrNull(EXTRA_CONTROLS[view.type])
      ? EXTRA_CONTROLS[view.type]
      : [];
    // eslint-disable-next-line
    const footerSteps = [FOOTER['dates_drawer'], FOOTER[view.type]];

    // useful to differentiate one coachmark group from another
    // when we change from inspection to perspective sometimes, arrays are same and coachmark may not run then
    const differenceStep = [
      {
        content: view.type === 'map' ? view.subType : view.type,
        target: `#${view.type}`,
      },
    ];

    return _.without(
      [
        ...headerSteps,
        ...differenceStep,
        ...controlSteps,
        ...extraControls,
        ...footerSteps,
      ],
      undefined
    );
  };

  private launchCoachmarks = (view: View, headerMenus: any[]) => {
    const { showCoachMarks } = this.props;

    if (!undefinedOrNull(showCoachMarks)) {
      if (undefinedOrNull(getCoachMarkPreference(VIEW_PATH, view.type))) {
        showCoachMarks(this.getViewCoachmarkSteps(view, headerMenus));
      }
    }
  };

  private launchCustomCoachMarks = (
    view: View,
    coachMarksOverride: CoachMarkOverrideMetadata
  ) => {
    const { showCoachMarks } = this.props;
    const { url, coachmarkSteps } = coachMarksOverride;

    if (!undefinedOrNull(showCoachMarks) && coachmarkSteps.length > 0) {
      if (
        undefinedOrNull(getCoachMarkPreference(url || VIEW_PATH, view.type))
      ) {
        showCoachMarks(coachmarkSteps);
      }
    }
  };

  private onConfigCallbackChange = (callback: () => ViewConfig) => {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      renderConfigCallback: callback,
    });
  };

  private onViewEvent = (e: ViewEvent<any>) => {
    const { onEvent } = this.props;

    // sending event to parent
    onEvent(e);

    // handling view events
    switch (e.type) {
      case 'renderer_state_changed': {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          rendererState: e.data as RendererState,
        });
        break;
      }

      case 'highlist_image_list_changed': {
        this.setState({
          highlightImageList: e.data,
        });
        break;
      }

      case 'ol_view_init': {
        this.setState({
          olView: e.data as OLView,
        });
        break;
      }

      default:
        break;
    }
  };

  private onRenderFragChange = (frag: any, controlType: ControlTypes) => {
    const { controlSelection } = this.state;
    let controlData = controlSelection[controlType] || {};

    controlData = { ...controlData, renderChildrenFrag: frag };
    controlSelection[controlType] = controlData;
    // control type specific logic should go here

    this.setState({
      controlSelection: { ...controlSelection },
    });
  };

  public getViewControls = (): ControlTypes[] => {
    // TODO: replace with logic appropriate to the relevant view
    const {
      view,
      downloads,
      reports,
      siteObjects,
      projectMLClasses,
      controlsWhitelist,
    } = this.props;

    const controlList: ControlTypes[] = [];

    if (view?.metadata?.length) {
      controlList.push('info');
    }

    switch (view?.type) {
      case 'map':
        if (view?.subType === 'survey') {
          controlList.push('legend');
        }

        controlList.push('measure');
        controlList.push('mark_issue');
        controlList.push('compare');
        controlList.push('elevation_difference');
        break;
      case 'perspective':
      case 'inspection':
      case 'site_navigation':
      case 'interior_360':
      case 'exterior_360':
        controlList.push('mark_issue');
        break;
      case 'three_d':
        if (view?.certifiedForMeasurement) {
          controlList.push('measure');
        }

        break;
      default:
        break;
    }

    if (downloads?.length) {
      controlList.push('download');
    }

    if (reports?.length) {
      controlList.push('reports');
    }

    if (siteObjects?.length && projectMLClasses?.length) {
      controlList.push('site_objects');
    }

    controlList.push('share');

    return controlList.filter(
      (ct) => (controlsWhitelist || []).indexOf(ct) !== -1
    );
  };

  private handleViewChange = (viewId: string) => {
    const { history } = this.props;

    history.push(viewId);
  };

  private renderFragments() {
    const { controlSelection } = this.state;
    const { children, controlsWhitelist, view, viewList, splitView } =
      this.props;

    return (
      <>
        {VALID_CONTROL_TYPES.filter(
          (ct) => (controlsWhitelist || []).indexOf(ct) !== -1
        ).map((t) => {
          if (controlSelection[t] && controlSelection[t].renderChildrenFrag) {
            return controlSelection[t].renderChildrenFrag();
          }

          return null;
        })}
        {splitView ? (
          <></>
        ) : (
          <ViewSelector
            view={view}
            viewList={viewList}
            onChange={this.handleViewChange}
          />
        )}
        {children}
      </>
    );
  }

  public renderView() {
    const {
      view,
      splitView,
      compareView,
      viewList,
      userRole,
      issue,
      share,
      elevationDifferences,
      overlayGeojson,
      showSatellite,
      history,
      onFooterControlsFragChange,
    } = this.props;

    const { olView } = this.state;

    if (!view) {
      return <SkeletonLoader />;
    }

    const { viewConfig } = issue || share || view || {};

    switch (view.type) {
      case 'map': {
        if (view && splitView) {
          return (
            <SplitView
              view={view}
              olView={olView}
              splitView={splitView}
              viewList={viewList}
              history={history}
              elevationDifferences={elevationDifferences}
              onFooterControlsFragChange={onFooterControlsFragChange}
              onEvent={this.onViewEvent}
            >
              {this.renderFragments()}
            </SplitView>
          );
        }

        const linkedRGBSiteNav = (viewList || []).find(
          (v) =>
            v.type === 'site_navigation' &&
            v.subType !== 'sitenav_thermal' &&
            v.certifiedForDisplay &&
            v.date === view.date
        );
        const linkedThermalSiteNav = (viewList || []).find(
          (v) =>
            v.type === 'site_navigation' &&
            v.subType === 'sitenav_thermal' &&
            v.certifiedForDisplay &&
            v.date === view.date
        );
        const linkedsiteNav =
          view.subType === 'thermal_mosaic'
            ? linkedThermalSiteNav || linkedRGBSiteNav
            : linkedRGBSiteNav || linkedThermalSiteNav;

        return (
          <MapView
            view={view}
            olView={olView}
            linkedSiteWalkthroughView={linkedsiteNav}
            viewConfig={viewConfig}
            share={share}
            compareView={compareView}
            overlayGeojson={overlayGeojson}
            showSatellite={showSatellite}
            onConfigCallbackChange={this.onConfigCallbackChange}
            onEvent={this.onViewEvent}
            onFooterControlsFragChange={onFooterControlsFragChange}
          >
            {this.renderFragments()}
          </MapView>
        );
      }

      case 'perspective':
      case 'inspection':
      case 'site_navigation': {
        const { potreeInsetViewIdOverride, history, isStaff } = this.props;
        const { highlightImageList } = this.state;

        const linkedThermalMap = (viewList || []).find(
          (v) =>
            v.type === 'map' &&
            v.subType === 'thermal_mosaic' &&
            v.certifiedForDisplay &&
            v.date === view.date
        );
        const linkedAerialMap = (viewList || []).find(
          (v) =>
            v.type === 'map' &&
            v.subType === 'aerial' &&
            v.certifiedForDisplay &&
            v.date === view.date
        );
        const linkedMapView: View | undefined =
          view.subType === 'inspection_thermal' ||
          view.subType === 'sitenav_thermal'
            ? linkedThermalMap || linkedAerialMap
            : linkedAerialMap || linkedThermalMap;

        return (
          <ImageView
            view={view}
            viewConfig={viewConfig}
            share={share}
            linkedMapView={linkedMapView}
            history={history}
            userRole={userRole || undefined}
            onConfigCallbackChange={this.onConfigCallbackChange}
            onEvent={this.onViewEvent}
            onFooterControlsFragChange={onFooterControlsFragChange}
            potreeInsetViewIdOverride={potreeInsetViewIdOverride}
            highlistImageList={highlightImageList}
            isStaff={isStaff}
          >
            {this.renderFragments()}
          </ImageView>
        );
      }

      case 'interior_360':
      case 'exterior_360': {
        if (view && splitView) {
          return (
            <SplitView
              view={view}
              // olView={olView}
              splitView={splitView}
              viewList={viewList}
              history={history}
              // elevationDifferences={elevationDifferences}
              onFooterControlsFragChange={onFooterControlsFragChange}
              onEvent={this.onViewEvent}
            />
          );
        }

        return (
          <PanoramaView
            view={view}
            viewConfig={viewConfig}
            share={share}
            onConfigCallbackChange={this.onConfigCallbackChange}
            onEvent={this.onViewEvent}
            onFooterControlsFragChange={onFooterControlsFragChange}
            userRole={userRole || undefined}
          >
            {this.renderFragments()}
          </PanoramaView>
        );
      }

      case 'three_d': {
        if (view.subType === 'three_d_tiles') {
          return (
            <ThreeDTilesView
              view={view}
              share={share}
              viewConfig={viewConfig}
              onConfigCallbackChange={this.onConfigCallbackChange}
              onEvent={this.onViewEvent}
              onFooterControlsFragChange={onFooterControlsFragChange}
            >
              {this.renderFragments()}
            </ThreeDTilesView>
          );
        }

        return (
          <ThreeDView
            view={view}
            share={share}
            viewConfig={viewConfig}
            onConfigCallbackChange={this.onConfigCallbackChange}
            onEvent={this.onViewEvent}
            onFooterControlsFragChange={onFooterControlsFragChange}
          >
            {this.renderFragments()}
          </ThreeDView>
        );
      }

      case 'video': {
        return (
          <VideoView
            view={view}
            share={share}
            onConfigCallbackChange={this.onConfigCallbackChange}
            onEvent={this.onViewEvent}
            onFooterControlsFragChange={onFooterControlsFragChange}
          >
            {this.renderFragments()}
          </VideoView>
        );
      }

      default:
        break;
    }

    // TODO: replace default return to contain an appropriate error message
    return <div className={style.container} />;
  }

  public render() {
    const { renderConfigCallback, rendererState, olView } = this.state;

    const {
      projectId,
      aoiId,
      viewId,
      view,
      splitView,
      viewList,
      elevationDifferences,
      issue,
      userRole,
      downloads,
      reports,
      siteObjects,
      projectMLClasses,
    } = this.props;

    if (!view) {
      return <SkeletonLoader />;
    }

    return (
      <div className={style.container}>
        {this.renderView()}
        <ViewControls
          projectId={projectId}
          aoiId={aoiId}
          viewId={viewId}
          view={view}
          olView={olView}
          splitView={splitView}
          items={this.getViewControls()}
          className={style.controls}
          rendererState={rendererState}
          onRenderFragChange={this.onRenderFragChange}
          getViewConfig={
            renderConfigCallback ||
            (() => {
              return {} as ViewConfig;
            })
          }
          onEvent={this.onViewEvent}
          userRole={userRole}
          issue={issue}
          downloads={downloads}
          reports={reports}
          viewList={viewList}
          elevationDifferences={elevationDifferences}
          siteObjects={siteObjects}
          projectMLClasses={projectMLClasses}
        />
      </div>
    );
  }
}
