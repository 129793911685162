import { Map } from 'ol';
import Delete from 'ol-ext/interaction/Delete';
import GeometryType from 'ol/geom/GeometryType';
import { Draw, Interaction, Modify } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import React, { useEffect, useState } from 'react';
import { OverlayHelper } from 'src/components/View/MapView/OpenLayersRenderer/TerrainDrawControl/OverlayHelper';

export type MapMakerDrawMode =
  | 'add_point'
  | 'add_line'
  | 'add_polygon'
  | 'edit'
  | 'delete';

export interface MapMakerDrawControlProps {
  olMap?: Map;
  drawMode: MapMakerDrawMode;
  layer: VectorLayer;
}

export const MapMakerDrawControl: React.FC<MapMakerDrawControlProps> = ({
  drawMode,
  layer,
  olMap,
}) => {
  const [overlayMessage, setOverlayMessage] = useState<string>();

  useEffect(() => {
    if (!olMap) return;

    const interaction = getInteraction(layer, drawMode);

    if (interaction) {
      olMap.addInteraction(interaction);
      setOverlayMessage(getOverlayMessage(drawMode));
    }

    return () => {
      if (interaction) olMap.removeInteraction(interaction);
      setOverlayMessage(undefined);
    };
  }, [olMap, drawMode, layer]);

  return (
    <div id="map-maker-draw-control">
      <OverlayHelper olMap={olMap} overlayContent={overlayMessage || ''} />
    </div>
  );
};

export const getInteraction = (
  layer: VectorLayer,
  drawMode: MapMakerDrawMode
): Interaction | undefined => {
  switch (drawMode) {
    case 'add_point':
      return new Draw({ source: layer.getSource(), type: GeometryType.POINT });
    case 'add_line':
      return new Draw({
        source: layer.getSource(),
        type: GeometryType.LINE_STRING,
      });
    case 'add_polygon':
      return new Draw({
        source: layer.getSource(),
        type: GeometryType.POLYGON,
      });

    case 'edit':
      return new Modify({ source: layer.getSource() });

    case 'delete':
      return new Delete({ layers: [layer] });

    default:
      return undefined;
  }
};

const getOverlayMessage = (drawMode?: MapMakerDrawMode): string | undefined => {
  if (drawMode === undefined) return;
  let overlayMessage: string | undefined;

  switch (drawMode) {
    case 'add_line': {
      overlayMessage = 'Click to draw a line. Double click to save.';
      break;
    }

    case 'add_point': {
      overlayMessage = 'Click to add a point';
      break;
    }

    case 'add_polygon': {
      overlayMessage = 'Click to draw a polygon. Double click to save.';
      break;
    }

    case 'edit': {
      overlayMessage = 'Click and drag to edit.';
      break;
    }

    case 'delete': {
      overlayMessage = 'Click on a shape to delete';
      break;
    }

    default:
      break;
  }

  return overlayMessage;
};
