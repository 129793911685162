import * as math from 'mathjs';
import {
  HighlightImageDetails,
  GlobalCoordinates,
} from 'src/components/View/index.types';
import { TriangulationAlgoInput } from 'src/components/View/ImageView';
import {
  getCameraRotation,
  getRotationMatrix,
  getKMatrix,
  normalize,
  calcDistance,
  calcAngle,
} from './math';
import ImageMetadata from '../../api/images.types';

export class CamProjections {
  images: readonly ImageMetadata[];

  latRef: number;

  longRef: number;

  elevRef: number;

  fov: number;

  K: number[][];

  latLongScale: number[];

  positionList: Record<string, unknown>[];

  angleThreshold: number;

  distanceFilter: boolean;

  angleFilter: boolean;

  constructor(imageMetadata: Readonly<ImageMetadata[]>) {
    this.images = imageMetadata;
    try {
      this.latRef = parseFloat(this.images[0].latitude);
      this.longRef = parseFloat(this.images[0].longitude);
      this.elevRef = this.images[0].elevation;
      this.latLongScale = this.latToScale(this.latRef);
      this.positionList = [];

      if (this.images[0].h_fov === undefined) {
        this.fov = 73.7;
      } else {
        this.fov = this.images[0].h_fov;
      }

      this.K = getKMatrix(
        (this.fov * Math.PI) / 180,
        this.images[0].imageWidth,
        this.images[0].imageHeight
      );
      this.updateImageMetadata();
      this.angleThreshold = this.fov / 2;
      this.distanceFilter = true;
      this.angleFilter = true;
    } catch (e) {
      console.error(
        'There was an error initializing the camera projections.',
        e
      );
    }
  }

  getMetadataFromGuid(guid: any) {
    return this.images.find((img) => guid === img.guid);
  }

  latToScale(latitude: number) {
    const LAT_CONV = 111132.92;
    const LON_CONV = 111412.84;

    const lat = (latitude * Math.PI) / 180;
    const latc =
      LAT_CONV - 559.82 * Math.cos(2 * lat) + 1.175 * Math.cos(4 * lat);
    const lonc = LON_CONV * Math.cos(lat) - 93.5 * Math.cos(3 * lat);

    return [latc, lonc];
  }

  updateImageMetadata() {
    this.positionList = this.images.map((img) => {
      return {
        ...this.convertLatLonToWorld(
          parseFloat(img.latitude),
          parseFloat(img.longitude),
          img.elevation
        ),
        guid: img.guid,
      };
    });
  }

  convertLatLonToWorld(lat: number, lon: number, elev: number) {
    const deltaLat = lat - this.latRef;
    const deltaLong = lon - this.longRef;

    const yVar = deltaLat * this.latLongScale[0];
    const xVar = deltaLong * this.latLongScale[1];
    const zVar = elev - this.elevRef;

    return {
      x: xVar,
      y: yVar,
      z: zVar,
    };
  }

  convertToLatLong(point: number[]) {
    const deltaLat = point[1] / this.latLongScale[0];
    const deltaLong = point[0] / this.latLongScale[1];
    const zVar = point[2] + this.elevRef;

    const lat = deltaLat + this.latRef;
    const long = deltaLong + this.longRef;

    return { latitude: lat, longitude: long, elevation: zVar };
  }

  getXYZForGuid(guid: string) {
    const selImage = this.positionList.find((img) => guid === img.guid);

    if (selImage !== undefined) {
      return [selImage.x, selImage.y, selImage.z];
    }

    return [];
  }

  getWorldToPxTransform(imMetadata: ImageMetadata, K: number[][]) {
    const yaw = (imMetadata.gimbalYaw * Math.PI) / 180;
    const pitch = (imMetadata.gimbalPitch * Math.PI) / 180;
    const roll = (imMetadata.gimbalRoll * Math.PI) / 180;

    const pos = this.getXYZForGuid(imMetadata.guid);
    const R = getCameraRotation(yaw, pitch, roll);
    const ART = getRotationMatrix(R, pos);

    return math.multiply(K, ART);
  }

  convertPxToRealVec(queryPx: any, imMetadata: ImageMetadata, K: number[][]) {
    const wPxTransform = this.getWorldToPxTransform(imMetadata, K);

    wPxTransform[3] = [0, 0, 0, 1]; // Make matrix 4x4 inorder to invert it
    const inverse = math.inv(wPxTransform);
    let pt = math.multiply(inverse, [queryPx.x, queryPx.y, 1, 1]);

    pt = [pt[0] / pt[3], pt[1] / pt[3], pt[2] / pt[3]];
    pt = math.subtract(pt, this.getXYZForGuid(imMetadata.guid));

    return normalize(pt);
  }

  convertRealPtToPx(
    queryPt: number[],
    imMetadata: ImageMetadata,
    K: number[][]
  ) {
    const transform = this.getWorldToPxTransform(imMetadata, K);
    const px = math.multiply(transform, [
      queryPt[0],
      queryPt[1],
      queryPt[2],
      1,
    ]);

    return [px[0] / px[2], px[1] / px[2], 1];
  }

  validPixel(px: number[], imMetadata: ImageMetadata) {
    let boolVar = false;

    if (px[0] >= 0 && px[1] >= 0) {
      if (px[0] <= imMetadata.imageWidth && px[1] <= imMetadata.imageHeight) {
        boolVar = true;
      }
    }

    return boolVar;
  }

  findDirectionVectors(input: TriangulationAlgoInput[], K: number[][]) {
    const paramList = [];

    for (let i = 0; i < input.length; i += 1) {
      const curMetadata = this.getMetadataFromGuid(input[i].guid);

      if (curMetadata !== undefined) {
        const rayVec = this.convertPxToRealVec(input[i].pixel, curMetadata, K);

        paramList.push({
          guid: input[i].guid,
          pos: this.getXYZForGuid(curMetadata.guid),
          dir: rayVec,
        });
      }
    }

    return paramList;
  }

  doTriangulation(dataList: Record<string, unknown>[]) {
    // Find the closest point by Least Square Solution using eq. 13 in http://cal.cs.illinois.edu/~johannes/research/LS_line_intersect.pdf.
    // Find R and q matrix
    let R = math.zeros(3, 3);
    let q = math.zeros(3, 1);

    dataList.forEach((data) => {
      const I = math.identity(3);
      const temp1 = math.multiply(math.transpose([data.dir]), [data.dir]);
      const temp2 = math.subtract(I, temp1);
      const temp3 = math.multiply(temp2, math.transpose([data.pos]));

      R = math.add(R, temp2);
      q = math.add(q, temp3);
    });

    const realPt = math.multiply(math.inv(R), q); // 3x1 matrix

    return [realPt.get([0, 0]), realPt.get([1, 0]), realPt.get([2, 0])]; // Array of length 3
  }

  calculateDistanceThreshold(
    point: number[],
    userPoints: TriangulationAlgoInput[]
  ) {
    // Find the minimum distance between the user selected cameras and the triangulation point
    const distances = [];

    for (let i = 0; i < userPoints.length; i += 1) {
      const imMetadata = this.getMetadataFromGuid(userPoints[i].guid);

      if (imMetadata !== undefined) {
        distances.push(
          calcDistance(point, this.getXYZForGuid(imMetadata.guid))
        );
      }
    }

    return Math.max(...distances);
  }

  checkAngle(
    center: number[],
    camPoint: unknown[],
    userCamPoints: unknown[][]
  ) {
    for (let i = 0; i < userCamPoints.length; i += 1) {
      const angle = calcAngle(center, camPoint, userCamPoints[i]);

      if (angle >= 0 && angle <= this.angleThreshold) {
        return true;
      }

      if (-1 * this.angleThreshold <= angle && angle <= 0) {
        return true;
      }
    }

    return false;
  }

  getImagesWithValidAngle(
    point: number[],
    allImages: readonly ImageMetadata[],
    userPoints: TriangulationAlgoInput[]
  ) {
    const valImages = [];
    const userCamPosList: unknown[][] = [];

    for (let i = 0; i < userPoints.length; i += 1) {
      const imMetadata = this.getMetadataFromGuid(userPoints[i].guid);

      if (imMetadata !== undefined) {
        userCamPosList.push(this.getXYZForGuid(imMetadata.guid));
      }
    }

    for (let j = 0; j < allImages.length; j += 1) {
      let pos: unknown[] = [];

      pos = this.getXYZForGuid(allImages[j].guid);

      if (pos !== undefined) {
        const boolValue = this.checkAngle(point, pos, userCamPosList);

        if (boolValue === true) {
          valImages.push(allImages[j]);
        }
      }
    }

    return valImages;
  }

  getImagesWithValidDistance(
    point: number[],
    allImages: ImageMetadata[],
    threshold: number
  ) {
    const valImages = [];

    for (let j = 0; j < allImages.length; j += 1) {
      const dist = calcDistance(point, this.getXYZForGuid(allImages[j].guid));

      if (dist <= 2 * threshold) {
        valImages.push(allImages[j]);
      }
    }

    return valImages;
  }

  public getImagesWithPOI = (inputMap: TriangulationAlgoInput[]) => {
    const filteredImageList: HighlightImageDetails[] = [];
    // Donot triangulate if user has selected only one point

    if (inputMap.length < 2) {
      return filteredImageList;
    }

    const dirVec = this.findDirectionVectors(inputMap, this.K);
    let realLatLongPt: GlobalCoordinates;

    if (dirVec.length > 0) {
      let validImages: ImageMetadata[] = JSON.parse(
        JSON.stringify(this.images)
      );
      const realPt = this.doTriangulation(dirVec);
      const realLatLongPt = this.convertToLatLong(realPt);

      const maxDistance = this.calculateDistanceThreshold(realPt, inputMap);

      if (this.angleFilter) {
        validImages = this.getImagesWithValidAngle(
          realPt,
          this.images,
          inputMap
        );
      }

      if (this.distanceFilter) {
        validImages = this.getImagesWithValidDistance(
          realPt,
          validImages,
          maxDistance
        );
      }

      validImages.forEach((img) => {
        const px = this.convertRealPtToPx(realPt, img, this.K);

        if (this.validPixel(px, img)) {
          filteredImageList.push({
            guid: img.guid,
            pixel: { x: px[0], y: px[1] },
            triangulatedPoint: realLatLongPt,
          });
          // console.log(img.guid, { x: px[0], y: px[1] });
        }
      });
    }

    // Explicitly adding the user marked guids to filtered list
    const filteredGuids =
      filteredImageList?.map((point) => {
        return point.guid;
      }) || [];

    inputMap.forEach((input) => {
      if (
        typeof input.guid === 'string' &&
        !filteredGuids.includes(input.guid)
      ) {
        filteredImageList.push({
          guid: input.guid,
          pixel: input.pixel,
          triangulatedPoint: realLatLongPt,
        });
      }
    });

    return filteredImageList;
  };
}
