// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmPopup_modalContainer__FHpdB .ConfirmPopup_ctaContainer__juoPs{text-align:right;padding-top:10px}.ConfirmPopup_modalContainer__FHpdB .ConfirmPopup_ctaContainer__juoPs button+button{margin-left:10px}", "",{"version":3,"sources":["webpack://./src/components/ConfirmPopup/index.module.scss"],"names":[],"mappings":"AAWE,sEACE,gBAAA,CACA,gBAAA,CAEA,oFACE,gBAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  //\n}\n\n.modalContainer {\n  .bodyContainer {\n    //\n  }\n\n  .ctaContainer {\n    text-align: right;\n    padding-top: 10px;\n\n    button + button {\n      margin-left: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "ConfirmPopup_modalContainer__FHpdB",
	"ctaContainer": "ConfirmPopup_ctaContainer__juoPs"
};
export default ___CSS_LOADER_EXPORT___;
