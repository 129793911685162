import classnames from 'classnames';
import * as React from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import { fromLonLat, toLonLat } from 'ol/proj';

import { OpenLayersMapPropType, ViewState } from './index.types';

import styles from './index.module.scss';
import { undefinedOrNull } from '../../utils/functs';

export default class OpenLayersMap extends React.PureComponent<OpenLayersMapPropType> {
  private olMapReference = React.createRef<HTMLDivElement>();

  private map: Map;

  public componentDidMount() {
    const mapEl = this.olMapReference.current;
    const { layers, centerCoordinates, zoomLevels, onMapMove } = this.props;

    if (mapEl == null) {
      return;
    }

    const view = new View({
      center: fromLonLat([
        centerCoordinates.longitude,
        centerCoordinates.latitude,
      ]),
      zoom: zoomLevels.initial,
      maxZoom: zoomLevels.max,
      minZoom: zoomLevels.min,
    });

    this.map = new Map({
      target: mapEl,
      layers: [...layers],
      view,
    });

    if (onMapMove) {
      view.on('change:center', (e) => {
        const coordinates = toLonLat(e.target.getCenter());
        const zoom = e.target.getZoom();

        if (coordinates && coordinates.length > 1 && !undefinedOrNull(zoom)) {
          onMapMove(coordinates[0], coordinates[1], zoom);
        }
      });

      view.on('change:resolution', (e) => {
        const coordinates = toLonLat(e.target.getCenter());
        const zoom = e.target.getZoom();

        if (coordinates && coordinates.length > 1 && !undefinedOrNull(zoom)) {
          onMapMove(coordinates[0], coordinates[1], zoom);
        }
      });
    }
  }

  UNSAFE_componentWillReceiveProps({ currentState }: OpenLayersMapPropType) {
    const { currentState: prevState } = this.props;

    if (
      !this.compareState(prevState, currentState) &&
      !undefinedOrNull(currentState) &&
      this.map
    ) {
      const { center, zoom } = currentState;
      const view = this.map.getView();

      if (view) {
        const coordinates = view.getCenter();
        const currentZoom = view.getZoom();

        if (center && center.longitude && center.latitude) {
          if (!coordinates) {
            view.setCenter(fromLonLat([center.longitude, center.latitude]));
          } else {
            const currentCenter = toLonLat(coordinates);

            if (
              center.longitude !== currentCenter[0] ||
              center.latitude !== currentCenter[1]
            ) {
              view.setCenter(fromLonLat([center.longitude, center.latitude]));
            }
          }
        }

        if (zoom) {
          if (!currentZoom) {
            view.setZoom(zoom);
          } else if (currentZoom !== zoom) {
            view.setZoom(zoom);
          }
        }
      }
    }
  }

  private compareState = (
    oldState?: ViewState,
    newState?: ViewState
  ): boolean => {
    if (!undefinedOrNull(oldState) && !undefinedOrNull(newState)) {
      const { center: oldCenter, zoom: oldZoom } = oldState;
      const { center: newCenter, zoom: newZoom } = newState;

      if (!undefinedOrNull(oldCenter) && !undefinedOrNull(newCenter)) {
        if (oldCenter.latitude !== newCenter.latitude) {
          return false;
        }

        if (oldCenter.longitude !== newCenter.longitude) {
          return false;
        }
      } else if (!undefinedOrNull(oldCenter) || !undefinedOrNull(newCenter)) {
        return false;
      }

      if (!undefinedOrNull(oldZoom) && !undefinedOrNull(newZoom)) {
        if (oldZoom !== newZoom) {
          return false;
        }
      } else if (!undefinedOrNull(oldZoom) || !undefinedOrNull(newZoom)) {
        return false;
      }
    } else if (!undefinedOrNull(oldState) || !undefinedOrNull(newState)) {
      return false;
    }

    return true;
  };

  public render() {
    const { className } = this.props;

    return (
      <div
        className={classnames(styles.olMapContainer, className)}
        ref={this.olMapReference}
      />
    );
  }
}
