import { Modal, Pagination } from 'antd';
import * as React from 'react';
import { Button } from '../../Button';
import style from './UploadImagesErrors.module.scss';
import SkeletonLoader from '../../SkeletonLoader';
import { MissionImageUploadsSelectedUploadImagesListTypes } from './index.types';

interface IProps {
  erroredImages: MissionImageUploadsSelectedUploadImagesListTypes[];
  showModal: boolean;
  hideErrorsModal: any;
}

interface IState {
  pageSize: number;
  pageData: MissionImageUploadsSelectedUploadImagesListTypes[];
}

class UploadImagesErrors extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    const { erroredImages } = this.props;

    this.state = {
      pageSize: 10,
      pageData: erroredImages.slice(0, 10),
    };
  }

  public paginate = (array: any, pageSize: number, pageNumber: number) => {
    const _pageNumber = pageNumber - 1;

    return array.slice(_pageNumber * pageSize, (_pageNumber + 1) * pageSize);
  };

  public handlePageChange = (current: any) => {
    const { erroredImages } = this.props;
    const { pageSize } = this.state;
    const pageData = this.paginate(erroredImages, pageSize, current);

    this.setState({
      pageData,
    });
  };

  public render(): React.ReactNode {
    const { erroredImages, hideErrorsModal, showModal } = this.props;
    const { pageData, pageSize } = this.state;

    if (!this.state && this.props) {
      return <SkeletonLoader size={2} position="static" />;
    }

    return (
      <div>
        <Modal
          width="70%"
          title="Errors"
          centered
          footer={null}
          visible={showModal}
          onCancel={hideErrorsModal}
          destroyOnClose
          maskClosable={false}
          className={style.ErrorsContainer}
          zIndex={10002}
        >
          <p className={style.headertext}>
            These error were encountered while uploading images
          </p>
          <div className={style.ErrorBody}>
            <table className={style.tablePadLeft}>
              <tbody>
                {pageData.map((item, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={index}>
                      <td className={style.firstColumn}>
                        <img
                          className={style.ErrorImage}
                          src={URL.createObjectURL(item.file)}
                          alt="errorImg"
                        />

                        <div>{item.file.name}</div>
                      </td>
                      <td className={style.secondColumn}>{item.error}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={style.btndeleteDiv}>
            <div className={style.paginationDiv}>
              <Pagination
                onChange={this.handlePageChange}
                total={(erroredImages ?? []).length}
                pageSize={pageSize}
                defaultCurrent={1}
              />
            </div>
            <div>
              <Button
                className={style.btndelete}
                onClick={hideErrorsModal}
                text="Close"
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default UploadImagesErrors;
