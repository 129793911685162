import turfLength from '@turf/length';
import * as React from 'react';
import { convertLength } from '../../utils/helpers';
import Shapeinfo from './ShapeInfo';
import Unit from './Unit';

export interface ILengthProps {
  shape: any;
}

const DistanceInfo = ({ shape }: ILengthProps): JSX.Element => {
  const length = +turfLength(shape, { units: 'meters' }).toFixed(2);

  return (
    <Shapeinfo>
      <Unit label="Distance">{convertLength(length, 'meter')}</Unit>
      <Unit label="">{convertLength(length, 'feet')}</Unit>
    </Shapeinfo>
  );
};

export default DistanceInfo;
