import React, { useEffect, useMemo } from 'react';
import 'ol/ol.css';
import { Control } from 'ol/control';
import classnames from 'classnames';
import { useMapContext, useVectorManager } from './hooks';
import style from './index.module.scss';

export interface ResetControlProps {
  onReset?: () => void;
}

export const ResetControl: React.FC<ResetControlProps> = ({ onReset }) => {
  const { map } = useMapContext();
  const { clearAllFeatures } = useVectorManager();
  const { control, button } = useMemo(() => {
    const button = document.createElement('button');

    button.innerHTML = '<i class="fa fa-trash" />';
    const container = document.createElement('div');

    container.className = classnames(
      style.olResetControl,
      'ol-unselectable ol-control'
    );
    container.appendChild(button);

    const control = new Control({
      element: container,
    });

    return {
      control,
      button,
    };
  }, []);

  useEffect(() => {
    const clickHandler = () => {
      clearAllFeatures();
      onReset?.();
    };

    button.addEventListener('click', clickHandler);

    return () => {
      button.removeEventListener('click', clickHandler);
    };
  }, [onReset, button]);

  useEffect(() => {
    map.addControl(control);

    return () => {
      map.removeControl(control);
    };
  }, [map]);

  return <div data-testid="ol-reset-control" />;
};
