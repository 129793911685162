import _ from 'lodash';
import { ProjectUser } from '../../api/projects.types';
import {
  Issue,
  IssueComment,
  IssuesStatus,
  IssuesPageConfig,
  Tag,
  LoadingState,
  HasMoreState,
  IssueProcessState,
  IssuesModalVisibilityState,
} from '../../api/issue.types';

interface IssueState {
  issues: Issue[];
  singleIssue?: Issue;
  hasMore?: HasMoreState;
  comments: IssueComment[];
  issuesStatus?: IssuesStatus;
  tags: Tag[];
  loading: LoadingState;
  processState: IssueProcessState;
  pageConfig?: IssuesPageConfig;
  projectUsers: ProjectUser[];
  commentPage?: number;
  showModals?: IssuesModalVisibilityState;
}

const initialState: IssueState = {
  issues: [],
  comments: [],
  tags: [],
  projectUsers: [],
  loading: {},
  processState: {},
};

export default function issue(state = initialState, action: any): IssueState {
  const { type, payload } = action;
  const { loading, hasMore, processState, showModals } = state;

  switch (type) {
    case 'V2_ISSUES_SHOW_MANAGE_TAG_MODAL': {
      return {
        ...state,
        showModals: { ...showModals, manageTags: true },
      };
    }

    case 'V2_ISSUES_HIDE_MANAGE_TAG_MODAL': {
      return {
        ...state,
        showModals: { ...showModals, manageTags: false },
      };
    }

    case 'V2_ISSUES_SHOW_REPORT_PREVIEW_MODAL': {
      return {
        ...state,
        showModals: { ...showModals, reportPreview: true },
      };
    }

    case 'V2_ISSUES_HIDE_REPORT_PREVIEW_MODAL': {
      return {
        ...state,
        showModals: { ...showModals, reportPreview: false },
      };
    }

    case 'V2_SINGLE_ISSUE_FETCH_STARTED': {
      return {
        ...state,
        singleIssue: undefined,
        loading: { ...loading, singleIssue: true },
      };
    }

    case 'V2_SINGLE_ISSUE_FETCH_SUCCESS':
      return {
        ...state,
        singleIssue: payload,
        loading: { ...loading, singleIssue: false },
      };

    case 'V2_SINGLE_ISSUE_FETCH_FAILED':
      return {
        ...state,
        singleIssue: undefined,
        loading: { ...loading, singleIssue: false },
      };

    case 'V2_ISSUE_FETCH_STARTED':
      return {
        ...state,
        issues: [],
        loading: { ...loading, issues: true },
      };

    case 'V2_ISSUE_STATUS_FETCH_STARTED':
      return {
        ...state,
        issuesStatus: undefined,
        loading: { ...loading, status: true },
        hasMore: { ...hasMore, issues: undefined },
      };

    case 'V2_ISSUE_FETCH_SUCCESS':
      return {
        ...state,
        issues: payload.items,
        hasMore: { ...hasMore, issues: payload.hasMore },
        loading: { ...loading, issues: false },
      };

    case 'V2_ISSUE_STATUS_FETCH_SUCCESS':
      return {
        ...state,
        issuesStatus: payload,
        loading: { ...loading, status: false },
      };

    case 'V2_ISSUE_FETCH_FAILED':
      return {
        ...state,
        issues: [],
        hasMore: { ...hasMore, issues: undefined },
        loading: { ...loading, issues: false },
      };

    case 'V2_ISSUE_STATUS_FETCH_FAILED':
      return {
        ...state,
        issuesStatus: undefined,
        loading: { ...loading, status: false },
      };

    case 'V2_ISSUE_PAGE_CONFIG_UPDATED':
      return {
        ...state,
        pageConfig: payload,
      };

    case 'V2_ISSUE_PAGE_CONFIG_CLEARED':
      return {
        ...state,
        pageConfig: undefined,
      };

    case 'V2_ISSUE_RESOLVE_STARTED':
      return {
        ...state,
        loading: { ...loading, resolve: true },
      };

    case 'V2_ISSUE_RESOLVE_SUCCESS': {
      const { issues: _issues, singleIssue: _singleIssue } = state;
      let issues = _issues;
      let singleIssue;

      if (_singleIssue && payload.id === _singleIssue.id) {
        singleIssue = payload;
      }

      if (_issues.find((s) => s.id === payload.id)) {
        issues = [payload, ..._issues.filter((s) => s.id !== payload.id)];
      }

      return {
        ...state,
        singleIssue,
        issues,
        loading: { ...loading, resolve: false },
      };
    }

    case 'V2_ISSUE_RESOLVE_FAILED':
      return {
        ...state,
        loading: { ...loading, resolve: false },
      };

    case 'V2_ISSUE_TAGS_UPDATE_STARTED':
      return {
        ...state,
        processState: { ...processState, editIssueTags: undefined },
        loading: { ...loading, editIssueTags: true },
      };

    case 'V2_ISSUE_TAGS_UPDATE_SUCCESS': {
      const { issues: _issues, singleIssue: _singleIssue } = state;
      let issues = _issues;
      let singleIssue;

      if (_singleIssue && payload.id === _singleIssue.id) {
        singleIssue = payload;
      }

      if (_issues.find((s) => s.id === payload.id)) {
        issues = [payload, ..._issues.filter((s) => s.id !== payload.id)];
      }

      return {
        ...state,
        singleIssue,
        issues,
        processState: { ...processState, editIssueTags: 'success' },
        loading: { ...loading, editIssueTags: false },
      };
    }

    case 'V2_ISSUE_TAGS_UPDATE_FAILED':
      return {
        ...state,
        processState: { ...processState, editIssueTags: 'failed' },
        loading: { ...loading, editIssueTags: false },
      };

    case 'V2_ISSUE_SELECTED':
      return {
        ...state,
        comments: payload.comments,
        commentPage: 0,
        hasMore: { ...hasMore, comments: payload.hasMoreComments },
      };

    case 'V2_ISSUE_CLEARED':
      return {
        ...state,
        singleIssue: undefined,
        comments: [],
        commentPage: undefined,
        hasMore: { ...hasMore, comments: undefined },
      };

    case 'V2_COMMENT_FETCH_STARTED':
      return {
        ...state,
        loading: { ...loading, comments: true },
        hasMore: { ...hasMore, comments: undefined },
      };

    case 'V2_COMMENT_FETCH_SUCCESS':
      return {
        ...state,
        comments: payload.data.items,
        commentPage: payload.commentPage,
        hasMore: { ...hasMore, comments: payload.data.hasMore },
        loading: { ...loading, comments: false },
      };

    case 'V2_COMMENT_FETCH_FAILED':
      return {
        ...state,
        hasMore: { ...hasMore, comments: undefined },
        loading: { ...loading, comments: false },
      };

    case 'V2_COMMENT_CREATE_SUCCESS': {
      const { data, issueId } = payload;
      const { issues, singleIssue } = state;
      const issue = (singleIssue ? [singleIssue, ...issues] : issues).find(
        (s) => s.id === issueId
      );

      if (issue) {
        // update comments associated with an issue
        const { comments } = issue;

        issue.comments = [data, ...comments];
      }

      return {
        ...state,
        comments: [data, ...state.comments],
      };
    }

    case 'V2_TAG_FETCH_STARTED':
      return {
        ...state,
        tags: [],
        loading: { ...loading, tags: true },
      };

    case 'V2_TAGS_FETCHED_SUCCESS':
      return {
        ...state,
        tags: payload,
        loading: { ...loading, tags: false },
      };

    case 'V2_TAGS_FETCH_FAILED':
      return {
        ...state,
        loading: { ...loading, tags: false },
      };

    case 'V2_TAG_CREATION_STARTED':
      return {
        ...state,
        processState: { ...processState, createTag: undefined },
        loading: { ...loading, createTag: true },
      };

    case 'V2_TAG_CREATED_SUCCESS':
      return {
        ...state,
        processState: { ...processState, createTag: 'success' },
        loading: { ...loading, createTag: false },
        tags: [...state.tags, payload],
      };

    case 'V2_TAG_CREATED_FAILED':
      return {
        ...state,
        processState: { ...processState, createTag: 'failed' },
        loading: { ...loading, createTag: false },
      };

    case 'V2_TAG_DELETE_STARTED':
      return {
        ...state,
        processState: { ...processState, deleteTags: undefined },
        loading: { ...loading, deleteTags: true },
      };

    case 'V2_TAG_DELETE_SUCCESS': {
      const { tags } = state;

      const remainingTagIds = _.difference(
        tags.map((tag) => tag.id),
        payload
      );

      const remainingTags = tags.filter((tag) =>
        remainingTagIds.includes(tag.id)
      );

      return {
        ...state,
        tags: remainingTags,
        processState: { ...processState, deleteTags: 'success' },
        loading: { ...loading, deleteTags: false },
      };
    }

    case 'V2_TAG_DELETE_FAILED':
      return {
        ...state,
        processState: { ...processState, deleteTags: 'failed' },
        loading: { ...loading, deleteTags: false },
      };

    case 'V2_TAG_STATUS_RESET':
      return {
        ...state,
        processState: {
          ...processState,
          createTag: undefined,
          deleteTags: undefined,
        },
      };

    case 'V2_PROJECT_USERS_FETCH_STARTED':
      return {
        ...state,
        projectUsers: [],
      };

    case 'V2_PROJECT_USERS_FETCH_SUCCESS':
      return {
        ...state,
        projectUsers: payload,
      };

    case 'V2_PROJECT_USERS_FETCH_FAILED':
      return {
        ...state,
        projectUsers: [],
      };

    case 'V2_RESET_ISSUES_STATE': {
      return { ...initialState };
    }

    case 'V2_RESET_ISSUES_PROCESS_STATE':
      return {
        ...state,
        processState: {},
      };

    default:
      return state;
  }
}
