import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  clearProjectFilter,
  fetchProjects,
  setScrollPosition,
} from '../../redux/actions/projects';
import { resetIssuesReducer } from '../../redux/actions/issuesV2';
import ProjectList from './ProjectList';
import { actionShowSnackbar } from '../../redux/actions/snackbar';

const mapStateToProps = (state: any): any => {
  return {
    loaded: state.projects.loadedAll,
    loading: state.projects.loading,
    filter: state.projects.filter || '',
    projects: state.projects.loadedAll ? state.projects.filteredProjects : [],
    scrollPosition: state.projects.scrollPosition,
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      fetchProjects,
      clearProjectFilter,
      setScrollPosition,
      resetIssuesReducer,
      showSnackbar: actionShowSnackbar,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
