import ApiBase from './ApiBase';
import {
  GenericApisReturnPromiseTypes,
  GenericObjectType,
} from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_CAPI_URL, BASE_NAVIGATOR_API_URL } from '../constants/urls';

export default class MissionV2NavigatorApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super('');

    this._baseUrl = `${BASE_NAVIGATOR_API_URL}/v1`;
  }

  public updatePlanStatus(
    gcpPlanId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.patch(
      `${this._baseUrl}/edit_gcp_plan_status/${gcpPlanId}`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to update the plan status.`,
        });
      });
  }

  public getGcpProcessingStatus(
    missionId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/get_gcp_processing_status/${missionId}`)
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the GCP processing status.`,
        });
      });
  }

  public getGcpPlanData(gcpPlanId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/gcp_plan_data/${gcpPlanId}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the GCP plan data.`,
        });
      });
  }

  public postSegregateImages(
    missionId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${BASE_CAPI_URL}/v1/image_classification/${missionId}`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to segregate images.`,
        });
      });
  }
}
