import moment from 'moment';
import * as React from 'react';
import classnames from 'classnames';
import { Popover, Table, Typography } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import ModalNotification from '../../ModalNotification/ModalNotification';
import { AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE } from '../../../constants';
import { GenericObjectType } from '../../../shapes/app';
import { sortByDate } from '../../../utils/helpers';
import DocumentationLink from '../../DocumentationLink';
import { DOCUMENTATION_URL_LIST } from '../../../constants/urls';
import { inArray, percentage } from '../../../utils/functs';
import { MissionListingState, MissionsListingProps } from './index.types';
import { Mission } from '../../../api/mission.types';
import styles from './index.module.scss';
import { Button } from '../../Button';

const { Title, Text } = Typography;

class MissionsListing extends React.Component<
  MissionsListingProps,
  MissionListingState
> {
  public constructor(props: MissionsListingProps) {
    super(props);
    const { resetAOIState } = this.props;

    resetAOIState();

    this.state = {
      missionStatusFilter: '',
      redirect: false,
    };
  }

  public componentDidMount(): void {
    const { match, fetchMissions } = this.props;
    const { aoiId, projectId } = match.params;

    fetchMissions(projectId, aoiId);
  }

  private handleModalClose = () => {
    this.setState({
      redirect: true,
    });
  };

  private onFilterChange = (event: any) => {
    this.setState({ missionStatusFilter: event.value });
  };

  private showProjectAdminLinks = (): boolean => {
    const { user, match } = this.props;
    const { projectId } = match.params;

    if (!user) {
      return false;
    }

    if (user.staff) {
      return true;
    }

    if (projectId) {
      //  eslint-disable-next-line no-restricted-syntax
      for (const role of user.project_roles || []) {
        if ((role as any).project_id === projectId) {
          return (role as any).role === 'project_admin';
        }
      }
    }

    return false;
  };

  private handleRowClick = (record: GenericObjectType) => {
    const { history, match } = this.props;
    const { projectId, aoiId } = match.params;

    if (!history) {
      return;
    }

    history.push(`/project/${projectId}/aoi/${aoiId}/missions/${record.id}`);
  };

  private RenderTableStatusCol = ({
    value,
    row,
  }: {
    value: GenericObjectType[];
    row: GenericObjectType;
  }) => {
    const missionRequirementsTypeTextDict = {
      AERIAL_MAPS: 'Map',
      EXTERIOR_360: 'External 360',
      PERSPECTIVE: 'Perspective',
      THREE_D_VIEW: '3D View',
      INSPECTION: 'Inspection View',
      THERMAL_MAP: 'Thermal Map',
    };

    const requirementsList = (value ?? []).filter((a) =>
      inArray(
        [
          'AERIAL_MAPS',
          'PERSPECTIVE',
          'EXTERIOR_360',
          'THREE_D_VIEW',
          'INSPECTION',
          'THERMAL_MAP',
        ],
        a.id
      )
    );

    if (row?.status === 'PROCESSED') {
      return (
        <div className={styles.statusColWrapper}>
          <div
            className={classnames(styles.success)}
            style={{
              width: `100%`,
            }}
          />
        </div>
      );
    }

    const totalRequirements = Object.keys(requirementsList).length;
    let successRequirements = 0;
    let processingRequirements = 0;

    const statusPopoverEl = requirementsList.map((a) => {
      if (a.status === 'SUCCESS') {
        successRequirements += 1;
      }

      if (a.status === 'PROCESSING') {
        processingRequirements += 1;
      }

      return (
        <div key={a.id}>
          <Text>
            {missionRequirementsTypeTextDict[a.id]}: {a.status}
          </Text>
        </div>
      );
    });

    const successPerc = percentage(successRequirements, totalRequirements, 2);
    const processingPerc = percentage(
      processingRequirements,
      totalRequirements,
      2
    );

    return (
      <div>
        <Popover placement="bottom" content={<div>{statusPopoverEl}</div>}>
          <div className={styles.statusColWrapper}>
            {successPerc > 0 && (
              <div
                className={classnames(styles.success)}
                style={{
                  width: `${successPerc}%`,
                }}
              />
            )}

            {processingPerc > 0 && (
              <div
                className={styles.processing}
                style={{
                  width: `${processingPerc}%`,
                }}
              />
            )}
          </div>
        </Popover>
      </div>
    );
  };

  public render(): React.ReactNode {
    const { match, missions, loading, error } = this.props;
    const { redirect, missionStatusFilter } = this.state;
    const { RenderTableStatusCol } = this;

    let filteredMissions = (missions || []).filter((m: any) => {
      if (missionStatusFilter.length) {
        return m.status === missionStatusFilter;
      }

      return true;
    });

    filteredMissions = sortByDate(
      filteredMissions,
      'scheduledAt',
      true
    ) as Mission[];

    const { aoiId, projectId } = match.params;
    const tableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name: string) => name,
        width: 250,
      },
      {
        title: 'Status',
        dataIndex: 'requirements',
        key: 'requirements',
        render: (value: GenericObjectType[], list: any) => {
          return <RenderTableStatusCol value={value} row={list} />;
        },
      },
      {
        title: 'Flight Date',
        dataIndex: 'scheduledAt',
        key: 'scheduledAt',
        render: (scheduledAt: any) =>
          moment(scheduledAt).tz('Etc/GMT').format('ll'),
        width: 250,
      },
    ];

    if (error) {
      return (
        <ModalNotification
          notificationTitle="Error"
          shownotificationModal
          handleModalClose={this.handleModalClose}
          error={error}
        />
      );
    }

    if (redirect) {
      return <Redirect to={`/project/${projectId}`} />;
    }

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <div className={styles.titleInnerWrapper}>
              <Title level={2}>Missions</Title>
              <DocumentationLink
                href={DOCUMENTATION_URL_LIST.missions}
                toolTipPosition="right"
              />
            </div>
          </div>
          <div className={styles.aoiDataContainer}>
            {this.showProjectAdminLinks() ? (
              <Link
                className={styles.createItemLink}
                to={`/project/${projectId}/aoi/${aoiId}/CreateMission`}
              >
                <Button
                  onClick={() => {
                    /**/
                  }}
                  transparent
                  text="+ NEW MISSION"
                  className={styles.headBtn}
                />
              </Link>
            ) : null}
          </div>
        </div>
        <Table
          locale={{ emptyText: 'No Mission generated yet.' }}
          onRow={(record: GenericObjectType) => {
            return {
              onClick: () => {
                this.handleRowClick(record);
              },
            };
          }}
          rowKey={(record: any) => record.id}
          columns={tableColumns}
          dataSource={filteredMissions}
          loading={loading}
          pagination={{
            defaultPageSize: AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE,
          }}
        />
      </div>
    );
  }
}

export default MissionsListing;
