import React, { useState } from 'react';
import { Modal, Input } from 'antd';

export interface InspectionViewDetailsModalProps {
  visible?: boolean;
  numberOfSelectedImages?: number;
  createView: (viewName?: string) => void;
}

const InspectionViewDetailsModal: React.FC<InspectionViewDetailsModalProps> = ({
  visible,
  numberOfSelectedImages,
  createView,
}) => {
  const [viewName, setViewName] = useState<string | undefined>();

  return (
    <Modal
      title="Confirm View Creation"
      centered
      visible={visible}
      onOk={() => createView(viewName)}
      onCancel={() => createView(undefined)}
      destroyOnClose
      zIndex={10006}
    >
      <div>
        <p>Do you wish to create a View from the selected images?</p>
        <p>
          Number of selected images: <b>{numberOfSelectedImages}</b>
        </p>
        <div>
          <label>
            <b>View Name:</b>
          </label>
          <Input
            placeholder="Enter the name of the View."
            onChange={(e) => setViewName(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default InspectionViewDetailsModal;
