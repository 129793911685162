import OLView from 'ol/View';
import { ElevationDifference } from 'src/api/elevationDifference.types';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import { SiteObject, SiteObjectClass } from '../../../shapes/ml';
import { MetadataItem, View } from '../../../api/views.types';
import { DownloadItem } from '../../ViewDataManager/index.types';
import {
  ViewConfig,
  ViewEvent,
  MeasurementStatus,
  RendererState,
  CompareStatus,
  MeasurementType,
} from '../index.types';

export interface ViewControlsPropsType {
  className?: string;
  projectId: string;
  aoiId: string;
  viewId: string;
  view: View;
  olView?: OLView;
  splitView?: View;
  items: ControlTypes[];
  rendererState?: RendererState;
  onRenderFragChange: (frag: JSX.Element, controlType: ControlTypes) => void;
  getViewConfig: () => ViewConfig;
  onEvent: (e: ViewEvent<any>) => void;
  userRole?: string;
  issue?: any;
  downloads?: DownloadItem[];
  reports?: any[];
  viewList?: View[];
  elevationDifferences?: ElevationDifference[];
  siteObjects?: SiteObject[];
  projectMLClasses?: SiteObjectClass[];
  layers?: LayerDescriptor[];
}

export interface ViewControlsStateType {
  currentItem?: ControlTypes;
  selectedItems: ControlBoxRender[];
  measurementStatus?: MeasurementStatus;
  comapareStatus?: CompareStatus;
  measureType?: MeasurementType;
  selectedLayer?: LayerDescriptor;
}

export type ControlTypes =
  | 'mark_issue'
  | 'measure'
  | 'info'
  | 'legend'
  | 'compare'
  | 'share'
  | 'reports'
  | 'download'
  | 'site_objects'
  | 'elevation_difference'
  | 'add_shape'
  | 'edit_shape'
  | 'remove_shape';

export const SPLIT_VIEW_VALID_CONTROL_TYPES: ControlTypes[] = [
  'measure',
  'elevation_difference',
];

export const VALID_CONTROL_TYPES: ControlTypes[] = [
  'mark_issue',
  'measure',
  'info',
  'legend',
  'compare',
  'share',
  'reports',
  'download',
  'site_objects',
];

export interface ControlBoxRender {
  id: ControlTypes;
  renderControlBox: () => JSX.Element;
}

export interface BaseControlProps {
  view: View;
  splitView?: View;
  enabled: boolean;
  open: boolean;
  userRole?: string;
  onClose: () => void;
  onRenderFragChange: (frag: any) => void;
  onEvent: (e: ViewEvent<any>) => void;
}

export interface IssueControlProps extends BaseControlProps {
  issue?: any;
  getViewConfig: () => ViewConfig;
  measurementStatus?: MeasurementStatus;
  compareStatus?: CompareStatus;
}

export interface CompareControlProps extends BaseControlProps {
  designViews: View[];
  compareStatus?: CompareStatus;
}

export interface DownloadsControlProps extends BaseControlProps {
  downloads: DownloadItem[];
}

export interface ShareControlProps extends BaseControlProps {
  getViewConfig: () => ViewConfig;
}

export interface ReportsControlProps extends BaseControlProps {
  reports: any[];
}

export interface InfoControlProps extends BaseControlProps {
  metadata: MetadataItem[];
}

export interface LegendControlProps extends BaseControlProps {
  rendererState?: RendererState;
}
