import * as React from 'react';
import { getValidGeoJson } from '../../../../../utils/helpers';

interface IProps {
  viewer?: any; // pannellum viewer instance
  issue?: any;
  onChange: (geoJson: any) => void;
}

interface IState {
  hotspot?: any;
}

const ISSUE_HOTSPOT_ID = 'vimana-issue-hotspot';

export default class HotspotControl extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { viewer, issue } = this.props;

    if (viewer?.getContainer()) {
      viewer.getContainer().addEventListener('contextmenu', this.onRightclick);

      if (issue) {
        const hotspot = this.renderHotspot(viewer, issue);

        this.setState({ hotspot });
      }
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { viewer: nextViewer, issue: nextIssue } = nextProps;
    const { viewer, issue } = this.props;

    if (nextViewer !== viewer) {
      if (viewer?.getContainer()) {
        viewer.removeHotSpot(ISSUE_HOTSPOT_ID);
        viewer
          .getContainer()
          .addEventListener('contextmenu', this.onRightclick);
      }

      if (nextViewer?.getContainer()) {
        nextViewer
          .getContainer()
          .addEventListener('contextmenu', this.onRightclick);

        if (nextIssue) {
          const hotspot = this.renderHotspot(nextViewer, nextIssue);

          this.setState({ hotspot });
        }
      }
    }

    if (issue !== nextIssue) {
      if (nextViewer) {
        // remove old hotspot, add new one
        nextViewer.removeHotSpot(ISSUE_HOTSPOT_ID);

        if (nextIssue) {
          const hotspot = this.renderHotspot(nextViewer, nextIssue);

          this.setState({ hotspot });
        }
      }
    }
  }

  public componentWillUnmount() {
    const { viewer } = this.props;

    if (viewer?.getContainer()) {
      viewer.removeHotSpot(ISSUE_HOTSPOT_ID);
      viewer
        .getContainer()
        .removeEventListener('contextmenu', this.onRightclick);
    }
  }

  public getHotspotPosition = () => {
    const { hotspot } = this.state;

    if (hotspot) {
      return { yaw: hotspot.yaw, pitch: hotspot.pitch };
    }

    return null;
  };

  private onRightclick = (e: any) => {
    const { viewer, issue, onChange } = this.props;

    if (issue) {
      return;
    }

    if (viewer) {
      const [pitch, yaw] = viewer.mouseEventToCoords(e);
      const hotspot = {
        id: ISSUE_HOTSPOT_ID,
        yaw,
        pitch,
        text: '',
        type: 'info',
      };

      viewer.removeHotSpot(ISSUE_HOTSPOT_ID);
      viewer.addHotSpot(hotspot);

      this.setState({ hotspot }, () => {
        const geoJson = getValidGeoJson(
          {
            type: 'Point',
            coordinates: [hotspot?.yaw, hotspot?.pitch],
          },
          { text: '', type: 'info', zoom: viewer.getHfov() }
        );

        onChange(geoJson);
      });
    }
  };

  private renderHotspot(viewer: any, issue: any) {
    if (issue?.shapeGeoJson) {
      const geoJson = JSON.parse(issue?.shapeGeoJson);

      if (geoJson?.features?.length) {
        const feature = geoJson?.features[0];
        const [yaw, pitch] = feature?.geometry?.coordinates || [0, 0];
        const { text, type } = feature?.properties || {};
        const hotspot = {
          id: ISSUE_HOTSPOT_ID,
          yaw,
          pitch,
          text,
          type,
          targetPitch: pitch || -90,
          targetYaw: yaw || 0,
          targetHfov: issue?.viewConfig?.zoom || undefined,
        };

        viewer.addHotSpot(hotspot);

        return hotspot;
      }
    }

    return undefined;
  }

  public render() {
    return <React.Fragment />;
  }
}
