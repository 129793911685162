// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo_container__NNjhd{display:inline-block;width:auto;padding:15px}.Logo_container__NNjhd img{height:30px;width:auto}", "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.module.scss"],"names":[],"mappings":"AAEA,uBACE,oBAAA,CACA,UAAA,CACA,YAAA,CAEA,2BACE,WAAA,CACA,UAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  display: inline-block;\n  width: auto;\n  padding: 15px;\n\n  img {\n    height: 30px;\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Logo_container__NNjhd"
};
export default ___CSS_LOADER_EXPORT___;
