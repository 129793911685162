export const AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE = 7;

export const GCP_MARKERS_PAGINATION_DEFAULT_PAGE_SIZE = 7;

export const ISSUES_PAGINATION_DEFAULT_PAGE_SIZE = 10;

export const ISSUE_COMMENTS_PAGINATION_DEFAULT_PAGE_SIZE = 5;

export const DATA_AUTO_POLLING_TIMEOUT = 5000; // in ms

export const COORDS_TO_METERS_VALUE = 111320; // in ms

export const MAPBOX_ZOOM_OFFSET = 1; // refer https://openlayers.org/en/v6.0.1/examples/mapbox-layer.html

export const SATELLITE_LAYER_Z_INDEX = 0;

export const DEFAULT_LAYER_Z_INDEX = 10;

export const DEFAULT_OVERLAY_LAYER_Z_INDEX = 50;

export const DRAW_LAYER_Z_INDEX = 100;

export const SNACKBAR_Z_INDEX = 20000;

export const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID || 'vimana';

export const USE_MEASUREMENT_API: string =
  process.env.REACT_APP_USE_LEGACY_MEASUREMENTS || 'false';
