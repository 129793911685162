import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ContourMaps from './index';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { ContourMapsTypes } from './index.types';

const mapStateToProps = (): ContourMapsTypes.ReduxState => {
  return {};
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ContourMapsTypes.DispatchProps => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
    },
    dispatch
  );
};

export default connect<
  ContourMapsTypes.ReduxState,
  ContourMapsTypes.DispatchProps,
  ContourMapsTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ContourMaps);
