/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-continue */
/* eslint-disable no-param-reassign */

import memoizeOne from 'memoize-one';
import * as React from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';

import { ColorList } from 'src/constants/colors';
import SliderRail from './rail';
import { Handle } from './handle';
import { Track } from './track';
import { Tick } from './ticks';
import styles from './styles.module.scss';
import { ValueRange } from '../OpenLayersMap/index.types';

type ChangeCB = (vals: ValueRange) => void;

interface IProps {
  sourceRange: ValueRange;
  defaultValue?: ValueRange;
  colors: ColorList;
  onChange?: ChangeCB;
  onUpdate?: ChangeCB;
  units: string;
  readonly?: boolean;
  clampMin: boolean;
  clampMax: boolean;
}

export default class DynamicScale extends React.PureComponent<IProps> {
  private callCb(values: ReadonlyArray<number>, func: ChangeCB) {
    const [max, min] = values;

    func({ min, max });
  }

  onChange = (values: ReadonlyArray<number>) => {
    const { onChange } = this.props;

    if (onChange) this.callCb(values, onChange);
  };

  onUpdate = (values: ReadonlyArray<number>) => {
    const { onUpdate } = this.props;

    if (onUpdate) this.callCb(values, onUpdate);
  };

  render() {
    const {
      sourceRange,
      colors,
      units,
      readonly,
      clampMax,
      clampMin,
      defaultValue,
    } = this.props;
    const { domain, sampleValues } = getNormalizedRange(sourceRange);

    return (
      <Slider
        vertical
        mode={2}
        step={VALUE_STEP}
        domain={domain}
        className={styles.container}
        onChange={this.onChange}
        onUpdate={this.onUpdate}
        values={defaultValue ? [defaultValue.min, defaultValue.max] : domain}
        disabled={readonly}
        reversed
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        {!readonly && (
          <Handles>
            {({ handles, getHandleProps, activeHandleID }) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    showValue={handle.id === activeHandleID}
                    handle={handle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
        )}
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                  colors={colors}
                  clampMax={clampMax}
                  clampMin={clampMin}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks values={sampleValues}>
          {({ ticks }) => {
            return (
              <div className="slider-ticks">
                {ticks.map((tick) => (
                  <Tick
                    key={tick.id}
                    tick={tick}
                    format={(v) => `${v.toFixed(ROUND_FORMAT_DIGITS)}${units}`}
                  />
                ))}
              </div>
            );
          }}
        </Ticks>
      </Slider>
    );
  }
}

const ROUND_MUL = 1;
const ROUND_FORMAT_DIGITS = 0;
const VALUE_STEP = 0.1;

const NUM_SAMPLES = 10;
const MAX_NUM_SAMPLES = 12;

interface NormalizedRange {
  domain: [number, number];
  sampleValues: number[];
}

function normalizedValue(val: number, ty: 'ceil' | 'floor' | 'round'): number {
  val *= ROUND_MUL;
  if (ty === 'ceil') {
    val = Math.ceil(val);
  } else if (ty === 'floor') {
    val = Math.floor(val);
  } else {
    val = Math.round(val);
  }

  return val / ROUND_MUL;
}

function _getNormalizedRange(range: ValueRange): NormalizedRange {
  let { min, max } = range;

  min = normalizedValue(min, 'floor');
  max = normalizedValue(max, 'ceil');

  const delta = (max - min) / (NUM_SAMPLES - 1);
  const minDelta = (max - min) / (MAX_NUM_SAMPLES - 1);
  const values = [min];

  let currValue = min;
  let prevValue = min;

  for (let i = 0; i < NUM_SAMPLES - 2; i += 1) {
    currValue += delta;
    const norm = normalizedValue(currValue, 'round');

    if (norm - prevValue < minDelta) continue;
    if (max - norm < minDelta) break;
    prevValue = norm;
    values.push(norm);
  }

  values.push(max);

  return { domain: [min, max], sampleValues: values };
}

const getNormalizedRange = memoizeOne(_getNormalizedRange);
