import turfLength from '@turf/length';
import * as React from 'react';
import { getDistance3D, convertLength } from '../../../../utils/helpers';
import { Unit2 } from './InfoHelpers/Unit';
import { View } from '../../../../api/views.types';

export interface ILengthProps {
  shape: any;
  view: View;
}

const renderNormalDistance = (length: number) => {
  return (
    <>
      <Unit2
        label="Distance"
        value1={convertLength(length, 'meter')}
        value2={convertLength(length, 'feet')}
      />
    </>
  );
};

const render3dDistance = (length: number) => {
  return (
    <>
      <Unit2
        label="3D Distance"
        value1={convertLength(length, 'meter')}
        value2={convertLength(length, 'feet')}
      />
    </>
  );
};

const DistanceInfo = ({ shape, view }: ILengthProps): JSX.Element => {
  const feature = shape?.features?.[0];
  const rawGeometry = feature?.properties?.raw;
  let distance3D;

  if (rawGeometry?.type === 'Polygon' && rawGeometry?.coordinates) {
    distance3D = getDistance3D(rawGeometry.coordinates[0]);
  }

  const length = feature
    ? +turfLength(feature, { units: 'meters' }).toFixed(2)
    : 0.0;

  return (
    <>
      {view.type === 'three_d' && distance3D !== undefined
        ? render3dDistance(distance3D)
        : renderNormalDistance(length)}
    </>
  );
};

export default DistanceInfo;
