import classnames from 'classnames';
import { Checkbox, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Step } from 'react-joyride';
import AoiItem from '../AoiItem/AoiItem';
import DropDown from '../DropDown/DropDown';
import Icon from '../Icon';
import style from './AoiList.module.scss';
import SkeletonLoader from '../SkeletonLoader';
import {
  hasAdminAccess,
  hasManageAccess,
  shiftArrayIndexToFront,
  undefinedOrNull,
} from '../../utils/functs';
import { GenericObjectType } from '../../shapes/app';
import { getCoachMarkPreference } from '../../utils/localStorage';
import { RouterParamsTypes } from '../../shapes/routerParams';
import { AOI_PAGE_STEPS } from '../UserMenu/coachmarkGroups';

interface IProps extends RouterParamsTypes {
  projectId: string;
  project: any;
  loading: boolean;
  fetchProject: any;
  showCoachMarks: (steps: Step[]) => void;
  resetCoachMarks: () => void;
  user: any;
}

interface IState {
  tagsFilter: string;
  showHidden: boolean;
}

export default class AoiList extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      tagsFilter: '',
      showHidden: false,
    };
  }

  public componentDidMount(): void {
    const { project, fetchProject, projectId, location } = this.props;

    if (!project) {
      fetchProject(projectId);
    }

    const { showCoachMarks } = this.props;

    setTimeout(() => {
      if (undefinedOrNull(getCoachMarkPreference(location.pathname))) {
        showCoachMarks(AOI_PAGE_STEPS);
      }
    }, 1000);
  }

  public UNSAFE_componentWillUpdate(nextProps: IProps): void {
    const { fetchProject, projectId } = this.props;

    if (nextProps.project === null) {
      fetchProject(projectId);
    }
  }

  public componentWillUnmount(): void {
    // reset coachmarks
    const { resetCoachMarks } = this.props;

    resetCoachMarks();
  }

  private setTagsFilter = (event: any) => {
    this.setState({ tagsFilter: event.value });
  };

  private getAllTags = () => {
    const { project } = this.props;

    if (project && project.aois) {
      const tags: string[] = ['All'];

      // eslint-disable-next-line no-restricted-syntax
      for (const aoi of project.aois) {
        const aoiTags = aoi.tags || [];

        // eslint-disable-next-line no-restricted-syntax
        for (const tag of aoiTags) {
          if (tags.indexOf(tag) === -1) {
            tags.push(tag);
          }
        }
      }

      return tags;
    }

    return [];
  };

  private renderAoiList(): JSX.Element[] | JSX.Element {
    const { project, user } = this.props;
    const { tagsFilter, showHidden } = this.state;

    if (project) {
      const filteredAois = project.aois
        .filter((aoi: any) => {
          if (!tagsFilter) {
            return true;
          }

          return (aoi.tags || []).indexOf(tagsFilter) !== -1;
        })
        .filter((aoi: any) => {
          if (!aoi.deleted) {
            return true;
          }

          return !!(showHidden && hasAdminAccess(user, project.id));
        });

      let overallSiteIndex = 0;

      for (let i = 0; i < filteredAois.length; i += 1) {
        const item = filteredAois[i];

        if (item.defaultAOI) {
          overallSiteIndex = i;

          break;
        }
      }

      return shiftArrayIndexToFront(filteredAois, overallSiteIndex).map(
        (aoi: GenericObjectType) => (
          // @ts-ignore
          <AoiItem
            key={aoi.id}
            {...aoi}
            showViewLinks={this.showViewLinks()}
            showManageLinks={
              hasAdminAccess(user, project.id) ||
              hasManageAccess(user, project.id)
            }
            center={{
              latitude: project.latitude,
              longitude: project.longitude,
            }}
          />
        )
      );
    }

    return <SkeletonLoader size={1} position="fixed" />;
  }

  private showViewLinks = (): boolean => {
    const { user, project } = this.props;

    if (!user) {
      return false;
    }

    if (user.staff === true) {
      return true;
    }

    if (project && project.id) {
      let roleStr: string | null = null;

      // eslint-disable-next-line no-restricted-syntax
      for (const role of user.project_roles || []) {
        if ((role as any).project_id === project.id) {
          roleStr = (role as any).role;
        }
      }

      if (roleStr) {
        return ['project_admin', 'project_viewer'].indexOf(roleStr) > -1;
      }
    }

    return false;
  };

  private handleCheckboxToggle = (e: CheckboxChangeEvent) => {
    this.setState({ showHidden: e.target.checked });
  };

  public render(): React.ReactNode {
    const { project, loading, user } = this.props;
    const { tagsFilter } = this.state;

    if (loading || !project) {
      return (
        <div className="center">
          <SkeletonLoader position="fixed" />
        </div>
      );
    }

    return (
      <div className={style.container}>
        <div className={style.header}>
          <div className={style.controls}>
            <fieldset className={style.fieldset}>
              <legend>TAGS</legend>
              <DropDown
                value={tagsFilter}
                onChange={this.setTagsFilter}
                controlClassName={style.DropdownControl}
                menuClassName={style.DropdownMenu}
                arrowClassName={style.DropdownArrow}
                placeholderClassName={style.DropDownPlaceHolder}
                options={this.getAllTags().map((tag: any) => {
                  return tag === 'All'
                    ? { label: tag, value: '' }
                    : { label: tag, value: tag };
                })}
                placeholder="All"
              />
            </fieldset>
            {hasAdminAccess(user, project.id) ? (
              <Checkbox
                className={classnames([style.editProject, style.hideCheckbox])}
                onChange={this.handleCheckboxToggle}
              >
                Show Hidden AOIs
              </Checkbox>
            ) : null}
            {hasAdminAccess(user, project.id) ? (
              <div className={style.createItemLinkWrapper}>
                <Link
                  className={style.createItemLink}
                  to={`/project/${project.id}/edit`}
                >
                  <Icon name="edit" size="small" />
                  <div className={style.editProject}>Edit Project</div>
                </Link>
              </div>
            ) : null}
            {hasAdminAccess(user, project.id) ? (
              <div className={style.createItemLinkWrapper}>
                <Link
                  className={style.createItemLink}
                  to={`/project/${project.id}/manageML`}
                >
                  <Icon name="ml" size="small" />
                  <Tooltip
                    placement="bottom"
                    title="Enable detection of select objects using AI"
                  >
                    <div className={style.editProject}>Manage Detect</div>
                  </Tooltip>
                </Link>
              </div>
            ) : null}
            {hasAdminAccess(user, project.id) ? (
              <div className={style.createItemLinkWrapper}>
                <Link
                  className={style.createItemLink}
                  to={`/project/${project.id}/manageUsers`}
                >
                  <Icon name="manage" size="small" />
                  <div className={style.editProject}>Manage Users</div>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
        <div className={style.content}>{this.renderAoiList()}</div>
      </div>
    );
  }
}
