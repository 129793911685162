import React from 'react';
import classnames from 'classnames';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Input, Typography, Collapse, Radio } from 'antd';
import { ChromePicker, ColorResult } from 'react-color';
import { fetchLogo as fetchBrandingLogo } from '../../api/branding';
import styles from './index.module.scss';
import { inArray, undefinedOrNull } from '../../utils/functs';
import WhiteLabellingUploadButton from './UploadButton';
import { APP_BASE_URL } from '../../constants/urls';
import { SALES_EMAIL } from '../../constants/emails';
import {
  WhiteLabellingFormPropsTypes,
  WhiteLabellingFormStateTypes,
} from './index.types';
import ProjectV2Apis from '../../api/projectsV2';
import {
  BrandingModelColorList,
  BrandingModelColorListKeys,
} from '../../shapes/branding';

const radioGroupStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '15px',
};

const { Text } = Typography;
const { Panel } = Collapse;
const projectV2Apis = new ProjectV2Apis();

export default class WhiteLabellingForm extends React.Component<
  WhiteLabellingFormPropsTypes,
  WhiteLabellingFormStateTypes
> {
  constructor(props: WhiteLabellingFormPropsTypes) {
    super(props);

    this.state = {
      loginLogoError: false,
      projectLogoError: false,
      faviconLogoError: false,
      uploadLogoKey: Date.now(),
      uploadBrandingAlerts: {
        isSuccess: null,
        error: null,
      },
      updateBrandingAlerts: {
        isSuccess: null,
        error: null,
      },
      showColorPicker: {},
      colorPickerPreviewList: {},
      currentLogoButton: 'small',
    };
  }

  private handleLogoBtnSelection = (e: any) => {
    this.setState({ currentLogoButton: e.target.value });
  };

  private handleLoginLogoError = () => {
    this.setState({
      loginLogoError: true,
    });
  };

  private handleProjectLogoError = () => {
    this.setState({
      projectLogoError: true,
    });
  };

  private handleFaviconLogoError = () => {
    this.setState({
      faviconLogoError: true,
    });
  };

  public handleLogoUpdate = () => {
    this.setState({
      uploadLogoKey: Date.now(),
      projectLogoError: false,
      loginLogoError: false,
    });
  };

  private handleAlerts = (
    key: 'updateBrandingAlerts' | 'uploadBrandingAlerts',
    isSuccess: null | boolean,
    error: null | string
  ): void => {
    const updatedAlert: any = {
      [key]: {
        isSuccess,
        error,
      },
    };

    this.setState({
      ...updatedAlert,
    });
  };

  private handleColorPicker = (
    type: BrandingModelColorListKeys,
    value: boolean
  ) => {
    this.setState(({ showColorPicker }) => {
      return {
        showColorPicker: {
          ...showColorPicker,
          [type]: value,
        },
      };
    });
  };

  private handleColorPickerPreviewListChange = (
    type: BrandingModelColorListKeys,
    color: ColorResult
  ) => {
    this.setState(
      ({ colorPickerPreviewList }) => {
        return {
          colorPickerPreviewList: {
            ...colorPickerPreviewList,
            [type]: color.hex,
          },
        };
      },
      () => {
        const { onSelectedColorListChange, onValuesChanged } = this.props;
        const { colorPickerPreviewList } = this.state;

        if (!onSelectedColorListChange) {
          return;
        }

        onSelectedColorListChange(colorPickerPreviewList);
        onValuesChanged(true);
      }
    );
  };

  private getLogoUrl = (
    organisationId: string | undefined,
    size: 'small' | 'large' | 'favicon'
  ) => {
    const { serviceType, projectId } = this.props;

    if (serviceType === 'project' && projectId) {
      return projectV2Apis.getProjectLogo(projectId, size);
    }

    return fetchBrandingLogo(undefined, size, organisationId, true);
  };

  private onTextValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: 'url' | 'name'
  ) => {
    const { brandingData, onValuesChanged } = this.props;

    if (brandingData) {
      onValuesChanged(brandingData[key] !== e.target.value);
    }
  };

  private RenderContactSales = (): JSX.Element => {
    return (
      <Text>
        Contact&nbsp;
        <a className={styles.RenderContactSales} href={`mailto:${SALES_EMAIL}`}>
          {SALES_EMAIL}
        </a>
        &nbsp;for White Labelling your brand
      </Text>
    );
  };

  private RenderBrandingColorPicker = () => {
    const { brandingData } = this.props;
    const { colorPickerPreviewList, showColorPicker } = this.state;

    const colorDict: {
      [key in BrandingModelColorListKeys]: {
        label: string;
      };
    } = {
      textColor: { label: 'Text Color' },
      primaryColor: { label: 'Button Color' },
      foregroundColor: { label: 'Foreground Color' },
      backgroundColor: { label: 'Background Color' },
    };

    const previewColorList: BrandingModelColorList = {};

    if (brandingData) {
      Object.keys(brandingData).map((a) => {
        const item = brandingData[a];

        if (inArray(['url', 'name'], a)) {
          return;
        }

        if (undefinedOrNull(colorPickerPreviewList[a])) {
          previewColorList[a] = item;
        } else {
          previewColorList[a] = colorPickerPreviewList[a];
        }

        return a;
      });
    }

    return (
      <Collapse className={styles.collapseContainer}>
        <Panel header="Theming" key="1">
          <div className={styles.whitelabellingThemeContainer}>
            <div className={styles.colorPickerContainer}>
              {Object.keys(colorDict).map((a) => {
                const key: BrandingModelColorListKeys =
                  a as BrandingModelColorListKeys;
                const item = colorDict[key];

                return (
                  <div key={key}>
                    <span className={styles.colorPickerBtnWrapper}>
                      <button
                        onClick={() => {
                          this.handleColorPicker(key, true);
                        }}
                        className={styles.colorPickerBtn}
                        style={{
                          background:
                            colorPickerPreviewList[key] ??
                            brandingData?.[key] ??
                            undefined,
                        }}
                      />
                      {item.label}
                    </span>

                    {showColorPicker[key] ? (
                      <div className={styles.popover}>
                        <div
                          className={styles.cover}
                          onClick={() => {
                            this.handleColorPicker(key, false);
                          }}
                        />
                        <ChromePicker
                          onChange={(color) =>
                            this.handleColorPickerPreviewListChange(key, color)
                          }
                          color={
                            colorPickerPreviewList[key] ??
                            brandingData?.[key] ??
                            undefined
                          }
                          disableAlpha
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>

          <Text className={styles.captionText}>
            These changes will only apply to login and signup pages
          </Text>
        </Panel>
      </Collapse>
    );
  };

  render(): React.ReactNode {
    const {
      form,
      showSnackbar,
      allowWhiteLabelling,
      brandingData,
      serviceType,
      projectId,
      organisationId,
    } = this.props;
    const {
      loginLogoError,
      projectLogoError,
      faviconLogoError,
      uploadLogoKey,
      uploadBrandingAlerts,
      updateBrandingAlerts,
      currentLogoButton,
    } = this.state;

    const { RenderBrandingColorPicker } = this;
    const { getFieldDecorator } = form;

    if (allowWhiteLabelling === null || allowWhiteLabelling === false) {
      return (
        <Text>
          To enable white labelling of projects and to upload your brand logo,
          please contact sales@aspecscire.com
        </Text>
      );
    }

    return !allowWhiteLabelling ? (
      <div className={styles.whiteLabelling}>{this.RenderContactSales()}</div>
    ) : (
      <div className={styles.container}>
        {undefinedOrNull(uploadBrandingAlerts.isSuccess) ? null : (
          <Alert
            closable
            onClose={() => {
              this.handleAlerts('uploadBrandingAlerts', null, null);
            }}
            className={styles.alert}
            message={
              uploadBrandingAlerts.isSuccess
                ? `Upload was successful`
                : uploadBrandingAlerts.error
            }
            type={uploadBrandingAlerts.isSuccess ? `success` : `error`}
            showIcon
          />
        )}
        {undefinedOrNull(updateBrandingAlerts.isSuccess) ? null : (
          <Alert
            closable
            onClose={() => {
              this.handleAlerts('updateBrandingAlerts', null, null);
            }}
            className={styles.alert}
            message={
              updateBrandingAlerts.isSuccess
                ? `Update was successful`
                : updateBrandingAlerts.error
            }
            type={updateBrandingAlerts.isSuccess ? `success` : `error`}
            showIcon
          />
        )}
        <Form layout="vertical">
          <Form.Item
            label={
              serviceType === 'settings'
                ? 'Upload your brand logos'
                : 'Upload your project logo'
            }
          >
            <div className={styles.whiteLabellingUploadButtonWrapper}>
              {serviceType === 'settings' ? (
                <React.Fragment>
                  <Radio.Group
                    name="logos"
                    onChange={this.handleLogoBtnSelection}
                    value={currentLogoButton}
                    style={radioGroupStyle}
                  >
                    <Radio value="small">BRAND LOGO</Radio>
                    <Radio value="large">LOGIN SCREEN LOGO</Radio>
                    <Radio value="favicon">FAVICON LOGO</Radio>
                  </Radio.Group>
                  <WhiteLabellingUploadButton
                    size={currentLogoButton}
                    showSnackbar={showSnackbar}
                    onAlerts={this.handleAlerts}
                    handleLogoUpdate={this.handleLogoUpdate}
                    serviceType={serviceType}
                    projectId={projectId}
                    organisationId={organisationId}
                  />
                </React.Fragment>
              ) : (
                <WhiteLabellingUploadButton
                  size="small"
                  showSnackbar={showSnackbar}
                  onAlerts={this.handleAlerts}
                  handleLogoUpdate={this.handleLogoUpdate}
                  serviceType={serviceType}
                  projectId={projectId}
                  organisationId={organisationId}
                />
              )}
            </div>
          </Form.Item>
          <Form.Item
            label={
              serviceType === 'settings' ? 'Current logos' : 'Current logo'
            }
          >
            <div className={styles.whiteLabellingPreviewWrapper}>
              <div className={styles.uploadLogoWrapper}>
                {!projectLogoError ? (
                  <img
                    key={uploadLogoKey}
                    className={styles.logoImage}
                    src={`${this.getLogoUrl(
                      organisationId,
                      'small'
                    )}&v=${uploadLogoKey}`}
                    alt="Project logo"
                    onError={this.handleProjectLogoError}
                  />
                ) : (
                  <p>No project logo has been uploaded</p>
                )}
              </div>

              {serviceType === 'settings' && organisationId && (
                <div
                  className={classnames(
                    styles.uploadLogoWrapper,
                    styles.loginScreenlogo
                  )}
                >
                  {!loginLogoError ? (
                    <img
                      key={uploadLogoKey}
                      className={styles.logoImage}
                      src={`${this.getLogoUrl(
                        organisationId,
                        'large'
                      )}&v=${uploadLogoKey}`}
                      alt="Login screen logo"
                      onError={this.handleLoginLogoError}
                    />
                  ) : (
                    <p>No login screen logo has been uploaded</p>
                  )}
                </div>
              )}

              {serviceType === 'settings' && organisationId && (
                <div
                  className={classnames(
                    styles.uploadLogoWrapper,
                    styles.loginScreenlogo
                  )}
                >
                  {!faviconLogoError ? (
                    <img
                      key={uploadLogoKey}
                      className={styles.logoImage}
                      src={`${this.getLogoUrl(
                        organisationId,
                        'favicon'
                      )}&v=${uploadLogoKey}`}
                      alt="Favicon logo"
                      onError={this.handleFaviconLogoError}
                    />
                  ) : (
                    <p>No favicon logo has been uploaded</p>
                  )}
                </div>
              )}
            </div>
          </Form.Item>

          {serviceType === 'settings' && organisationId && (
            <React.Fragment>
              <RenderBrandingColorPicker />

              <Form.Item label="Login URL">
                <div className={styles.loginUrlDiv}>
                  <p>{`${APP_BASE_URL}/?partnerId=${organisationId}`}</p>
                </div>
              </Form.Item>
              <Form.Item label="Signup URL">
                <div className={styles.loginUrlDiv}>
                  <p>{`${APP_BASE_URL}/register/?partnerId=${organisationId}`}</p>
                </div>
              </Form.Item>
            </React.Fragment>
          )}

          <Form.Item label="Brand Name">
            {getFieldDecorator('brandingName', {
              rules: [
                {
                  required: true,
                  message: "Brand name can't be empty",
                },
              ],
              initialValue: brandingData?.name,
            })(
              <Input
                placeholder="Brand Name"
                style={{ margin: 0 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.onTextValueChange(e, 'name');
                }}
              />
            )}
          </Form.Item>

          <Form.Item label="Brand Website">
            {getFieldDecorator('brandingWebsite', {
              initialValue: brandingData?.url,
            })(
              <Input
                placeholder="Brand Website"
                style={{ margin: 0 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.onTextValueChange(e, 'url');
                }}
              />
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}
