import * as React from 'react';
import { Link } from 'react-router-dom';
import style from './index.module.scss';
import { listDownloadsUrl } from '../../../routes/urls';

interface IProps {
  projectId: string;
  aoiId?: string;
  disabled: boolean;
}

class DownloadButton extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { projectId, aoiId, disabled } = this.props;

    return disabled ? (
      <a
        className={style.container}
        title="You do not have permissions to view downloads for this project"
        style={{ cursor: 'not-allowed' }}
      >
        <i className="fa fa-download" />
      </a>
    ) : (
      <Link
        to={listDownloadsUrl(projectId, aoiId)}
        className={style.container}
        title="Downloads"
      >
        <i className="fa fa-download" />
      </Link>
    );
  }
}

export default DownloadButton;
