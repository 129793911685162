import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ProjectVideos from '.';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { ProjectVideosTypes } from './index.types';

const mapStateToProps = (state: any): ProjectVideosTypes.ReduxState => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ProjectVideosTypes.DispatchProps => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
    },
    dispatch
  );
};

const Container = connect<
  ProjectVideosTypes.ReduxState,
  ProjectVideosTypes.DispatchProps,
  ProjectVideosTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectVideos);

export default Container;
