import { SVD } from 'svd-js';

/**
 * Implementation of a Least Square Evaluation function as descripted in
 * https://www.geometrictools.com/Documentation/LeastSquaresFitting.pdf (par. 3)
 * This will return a plane in the form Ax + By + C = z
 */

// TODO CHANGE COMMENT WITH THE NEW IMPLEMENTATION
export default (points: any) => {
  // INPUT VALIDATION

  // Check if the input array is well-formed
  if (!(points instanceof Array)) {
    throw new TypeError(
      'This function accepts only an Array of points as input'
    );
  }

  if (points.length < 3) {
    throw new TypeError('You need at least three points to define a plane');
  }

  points.forEach((point) => {
    if (!(point instanceof Object)) {
      throw new TypeError(
        'The input should contains only points ' +
          'with the following structure: {x:<number>, y:<number>, z:<number>}'
      );
      // eslint-disable-next-line no-restricted-globals
    } else if (isNaN(point.x) || isNaN(point.y) || isNaN(point.z)) {
      throw new TypeError(
        'The input should contains only points ' +
          'with the following structure: {x:<number>, y:<number>, z:<number>}'
      );
    }
  });

  // Check if all points are aligned with the y-axis
  let allYEquals = true;
  const prevY = points[0].y;

  for (let i = 1; i < points.length && allYEquals; i += 1) {
    if (points[i].y !== prevY) {
      allYEquals = false;
    }
  }

  if (allYEquals) {
    throw new TypeError('Unable to find a plane for vertical points');
  }

  const meanCoordinates = (pointsArray: any[]) => {
    let xs = 0;
    let ys = 0;
    let zs = 0;

    pointsArray.forEach((point) => {
      xs += point.x;
      ys += point.y;
      zs += point.z;
    });

    return {
      xMean: xs / pointsArray.length,
      yMean: ys / pointsArray.length,
      zMean: zs / pointsArray.length,
    };
  };

  const means = meanCoordinates(points);
  const pointsNormalized: number[][] = [];

  for (let i = 0; i < points.length; i += 1) {
    pointsNormalized[i] = new Array(3);
  }

  points.forEach((point, index) => {
    pointsNormalized[index][0] = point.x - means.xMean;
    pointsNormalized[index][1] = point.y - means.yMean;
    pointsNormalized[index][2] = point.z - means.zMean;
  });

  const { v, q } = SVD(pointsNormalized);

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const idx = minIndex(q);
  const normalize = -1 / v[2][idx];

  const A = v[0][idx] * normalize;
  const B = v[1][idx] * normalize;
  const C = -(
    means.xMean * A +
    means.yMean * B +
    means.zMean * v[2][idx] * normalize
  );

  return { A, B, C };
};

const minIndex = (arr: number[]): number => {
  const len = arr.length;
  let min = Number.MAX_VALUE;
  let minIdx = len - 1;

  for (let idx = 0; idx < len; idx += 1) {
    if (arr[idx] < min) {
      min = arr[idx];
      minIdx = idx;
    }
  }

  return minIdx;
};
