import React from 'react';
import { CenteredContent } from 'src/components/CenteredContent';
import style from './index.module.scss';

export const NoLayerPlaceholder: React.FC = () => {
  return (
    <div className={style.placeholder}>
      <CenteredContent>Select a layer to proceed</CenteredContent>
    </div>
  );
};
