// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BoundaryMissing_title__0bBdk{display:flex;justify-content:center;align-items:center;height:80%}.BoundaryMissing_title__0bBdk h3{font-size:18px}", "",{"version":3,"sources":["webpack://./src/components/Mission/BoundaryMissing/index.module.scss"],"names":[],"mappings":"AAEA,8BACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CAEA,iCACE,cAAA","sourcesContent":["@import '../../../styles/scss/base/base';\n\n.title {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 80%;\n\n  h3 {\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "BoundaryMissing_title__0bBdk"
};
export default ___CSS_LOADER_EXPORT___;
