export const APP_PRIMARY_COLOR = '#ec1c24';

export const APP_BG_COLOR = '#1f2022';

export const GOOGLE_MAP_MARKER_DEFAULT_COLOR = '#999';

export const GOOGLE_MAP_MARKER_SELECTED_COLOR = APP_PRIMARY_COLOR;

export const GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR = '#3d5afe';

export const GOOGLE_MAP_MARKER_YELLOW_COLOR = '#ffd54f';

export const GOOGLE_MAP_MARKER_HIGHLIGHT_COLOR = '#00ffff';

export const ML_OBJECT_COLOR = {
  AI: '#2196f3',
  MANUAL: '#4caf50',
  REMOVED: '#f44336',
};

// Maximum number of colors to be displayed in the elevation color scale
export const MAX_NO_COLORS_IN_SCALE = 17;

export type ColorList = ReadonlyArray<[number, number, number]>;

export const VIRIDIS: ColorList = [
  [68, 1, 84],
  [70, 7, 90],
  [71, 13, 96],
  [71, 19, 101],
  [72, 24, 106],
  [72, 29, 111],
  [72, 35, 116],
  [72, 40, 120],
  [71, 45, 123],
  [70, 50, 126],
  [69, 55, 129],
  [68, 59, 132],
  [66, 64, 134],
  [64, 69, 136],
  [62, 73, 137],
  [61, 78, 138],
  [58, 83, 139],
  [56, 88, 140],
  [54, 92, 141],
  [52, 96, 141],
  [50, 100, 142],
  [49, 104, 142],
  [47, 108, 142],
  [45, 112, 142],
  [44, 115, 142],
  [42, 119, 142],
  [41, 123, 142],
  [39, 127, 142],
  [38, 130, 142],
  [36, 134, 142],
  [35, 138, 141],
  [33, 142, 141],
  [32, 146, 140],
  [31, 150, 139],
  [31, 154, 138],
  [31, 158, 137],
  [31, 161, 135],
  [33, 165, 133],
  [35, 169, 131],
  [38, 173, 129],
  [42, 176, 127],
  [47, 180, 124],
  [53, 183, 121],
  [59, 187, 117],
  [66, 190, 113],
  [74, 193, 109],
  [82, 197, 105],
  [90, 200, 100],
  [101, 203, 94],
  [110, 206, 88],
  [119, 209, 83],
  [129, 211, 77],
  [139, 214, 70],
  [149, 216, 64],
  [160, 218, 57],
  [170, 220, 50],
  [181, 222, 43],
  [192, 223, 37],
  [202, 225, 31],
  [213, 226, 26],
  [223, 227, 24],
  [234, 229, 26],
  [244, 230, 30],
  [253, 231, 37],
];
export const MAGMA: ColorList = [
  [0, 0, 4],
  [2, 1, 9],
  [3, 3, 18],
  [6, 5, 26],
  [10, 8, 34],
  [14, 11, 43],
  [19, 13, 52],
  [24, 15, 61],
  [29, 17, 71],
  [34, 17, 80],
  [41, 17, 90],
  [47, 17, 99],
  [54, 16, 107],
  [61, 15, 113],
  [68, 15, 118],
  [74, 16, 121],
  [82, 19, 124],
  [89, 21, 126],
  [95, 24, 127],
  [101, 26, 128],
  [107, 29, 129],
  [114, 31, 129],
  [120, 34, 129],
  [126, 36, 130],
  [132, 38, 129],
  [139, 41, 129],
  [145, 43, 129],
  [152, 45, 128],
  [158, 47, 127],
  [165, 49, 126],
  [171, 51, 124],
  [178, 53, 123],
  [186, 56, 120],
  [192, 58, 118],
  [199, 61, 115],
  [205, 64, 113],
  [211, 67, 110],
  [217, 70, 107],
  [223, 74, 104],
  [228, 79, 100],
  [233, 84, 98],
  [237, 90, 95],
  [241, 96, 93],
  [244, 103, 92],
  [246, 110, 92],
  [248, 118, 92],
  [250, 125, 94],
  [251, 133, 96],
  [252, 142, 100],
  [253, 150, 104],
  [254, 157, 108],
  [254, 165, 113],
  [254, 172, 118],
  [254, 180, 123],
  [254, 187, 129],
  [254, 194, 135],
  [254, 202, 141],
  [254, 209, 148],
  [254, 216, 154],
  [253, 224, 161],
  [253, 231, 169],
  [252, 238, 176],
  [252, 246, 184],
  [252, 253, 191],
];
export const YIQ_WAVE: ColorList = [
  [45, 35, 255],
  [52, 34, 255],
  [57, 34, 255],
  [61, 36, 255],
  [63, 39, 255],
  [64, 44, 255],
  [63, 50, 255],
  [61, 58, 255],
  [58, 66, 255],
  [54, 76, 255],
  [49, 87, 255],
  [43, 99, 255],
  [36, 112, 255],
  [28, 125, 255],
  [21, 138, 255],
  [12, 152, 255],
  [4, 166, 255],
  [0, 180, 204],
  [0, 194, 152],
  [0, 207, 101],
  [0, 220, 51],
  [0, 232, 3],
  [0, 244, 0],
  [0, 254, 0],
  [0, 255, 0],
  [0, 255, 0],
  [0, 255, 0],
  [0, 255, 0],
  [0, 255, 0],
  [0, 255, 0],
  [0, 255, 0],
  [0, 255, 0],
  [0, 255, 0],
  [0, 255, 0],
  [0, 255, 0],
  [4, 255, 0],
  [18, 255, 0],
  [34, 254, 0],
  [50, 243, 0],
  [68, 230, 0],
  [87, 216, 0],
  [107, 201, 0],
  [128, 185, 0],
  [150, 169, 0],
  [173, 151, 0],
  [196, 134, 0],
  [219, 115, 0],
  [243, 97, 0],
  [255, 78, 12],
  [255, 60, 46],
  [255, 42, 79],
  [255, 24, 112],
  [255, 7, 142],
  [255, 0, 171],
  [255, 0, 198],
  [255, 0, 221],
  [255, 0, 242],
  [255, 0, 255],
  [255, 0, 255],
  [255, 0, 255],
  [255, 0, 255],
  [255, 0, 255],
  [255, 0, 255],
  [255, 0, 255],
];

export const NDVI: ColorList = [
  [66, 33, 18], // brown
  [159, 81, 42],
  [205, 169, 21],
  [254, 254, 0],
  [230, 237, 0],
  [208, 223, 0],
  [185, 207, 0],
  [162, 192, 0],
  [139, 176, 0],
  [118, 157, 2],
  [93, 142, 0],
  [69, 128, 0],
  [45, 112, 0],
  [23, 97, 0],
  [0, 82, 0], // green
];

// Colors to be used for Elevation Difference
export const ELEVATION_DIFF_COLORS: ColorList = VIRIDIS;
export const THERMAL_COLORS: ColorList = MAGMA;
export const NDVI_COLORS: ColorList = NDVI;

export const DYNAMIC_MAPS: Record<any, ColorList> = {
  yiq: YIQ_WAVE,
  viridis: VIRIDIS,
  magma: MAGMA,
  ndvi: NDVI,
};
