// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HelpTooltips_tooltipWrapper__vra2d{padding-left:10px;border:none;background-color:unset !important}.HelpTooltips_tooltipWrapper__vra2d i{font-size:30px;color:#f3f3f3}.HelpTooltips_helptoolTip__HDajV{vertical-align:middle}.HelpTooltips_tooltipTextWrapper__WrXJ3{font-size:16px;font-weight:bold;cursor:pointer;text-decoration:underline}.HelpTooltips_popoverWrapper__jqrJy{max-width:400px}", "",{"version":3,"sources":["webpack://./src/components/HelpTooltip/HelpTooltips.module.scss"],"names":[],"mappings":"AAEA,oCACE,iBAAA,CACA,WAAA,CACA,iCAAA,CAEA,sCACE,cAAA,CACA,aAAA,CAIJ,iCACE,qBAAA,CAGF,wCACE,cAAA,CACA,gBAAA,CACA,cAAA,CACA,yBAAA,CAGF,oCACE,eAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.tooltipWrapper {\n  padding-left: 10px;\n  border: none;\n  background-color: unset !important;\n\n  i {\n    font-size: 30px;\n    color: #f3f3f3;\n  }\n}\n\n.helptoolTip {\n  vertical-align: middle;\n}\n\n.tooltipTextWrapper {\n  font-size: 16px;\n  font-weight: bold;\n  cursor: pointer;\n  text-decoration: underline;\n}\n\n.popoverWrapper {\n  max-width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipWrapper": "HelpTooltips_tooltipWrapper__vra2d",
	"helptoolTip": "HelpTooltips_helptoolTip__HDajV",
	"tooltipTextWrapper": "HelpTooltips_tooltipTextWrapper__WrXJ3",
	"popoverWrapper": "HelpTooltips_popoverWrapper__jqrJy"
};
export default ___CSS_LOADER_EXPORT___;
