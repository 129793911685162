import * as React from 'react';
import style from './Snackbar.module.scss';
import {
  SnackbarReducersStateSnackbarsTypes,
  SnackbarPropsTypes,
} from '../../shapes/snackbar';
import SnackbarItem from './SnackbarItem';

class Snackbar extends React.Component<SnackbarPropsTypes> {
  public render(): React.ReactNode {
    const { dispatchHideSnackbar, snackbars } = this.props;

    return (
      <div className={style.container}>
        {snackbars.map((snackbar: SnackbarReducersStateSnackbarsTypes) => (
          <SnackbarItem
            key={snackbar.timestamp}
            snackbar={snackbar}
            onHideSnackbar={() => dispatchHideSnackbar(snackbar)}
          />
        ))}
      </div>
    );
  }
}

export default Snackbar;
