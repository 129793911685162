import classnames from 'classnames';
import * as React from 'react';
import style from './MenuItem.module.scss';

interface IProps {
  name: string;
  isActive?: boolean;
  disabled?: boolean;
}

class MenuItem extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    isActive: false,
  };

  public render(): React.ReactNode {
    const { name, isActive, disabled } = this.props;

    return (
      <div
        className={classnames(
          style.container,
          disabled ? style.disabled : null,
          isActive ? style.active : style.inactive
        )}
      >
        {name === 'Survey' ? 'Analytics' : name}
      </div>
    );
  }
}

export default MenuItem;
