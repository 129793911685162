import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ManageProjectML from './index';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { ManageMLTypes } from './index.types';

const mapStateToProps = (): any => {
  return {};
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
    },
    dispatch
  );
};

const ManageProjectMLContainer = connect<
  ManageMLTypes.ReduxState,
  ManageMLTypes.DispatchProps,
  ManageMLTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ManageProjectML);

export default ManageProjectMLContainer;
