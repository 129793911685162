import * as React from 'react';
import { Typography } from 'antd';
import styles from './index.module.scss';
import Loading from '../Loading';
import { NullOrGenericObjectType } from '../../shapes/app';

const { Text } = Typography;

const statusText = {
  thermal: {
    error:
      'There was an error while generating the Thermal Map. Please try running the Thermal Map generation again or contact support@aspecscire.com for assistance.',
    initial: 'The Thermal Map will be displayed here after generation.',
    generating: 'Thermal Map is being generated',
  },
  dtm: {
    error:
      'There was an error while generating the Terrain Map. Please try running the Terrain Map generation again or contact support@aspecscire.com for assistance.',
    initial: 'The Terrain Map will be displayed here after generation.',
    generating: 'Map is being generated',
  },
  contour: {
    error:
      'There was an error while generating the Contour. Please try running the Contour generation again or contact support@aspecscire.com for assistance.',
    initial: 'The Contour Map will be displayed here after generation.',
    generating: 'Map is being generated',
  },
  cutAndFillReport: {
    error:
      'There was an error while generating the Cut And Fill Report. Please try running the Cut And Fill Report generation again or contact support@aspecscire.com for assistance.',
    initial:
      'The PDF report will be displayed here, after it has been generated.',
    generating: 'Cut And Fill Report is being generated',
  },
  manualUpload: {
    error:
      'There was an error while processing the uploaded GeoTiffs. Please contact support@aspecscire.com for assistance',
    initial:
      'A preview of the Aerial and Elevation views will be displayed here after processing.',
    generating: 'The uploaded files are being processed.',
  },
  pointCloudUpload: {
    error:
      'There was an error while processing the uploaded point cloud. Please contact support@aspecscire.com for assistance',
    initial:
      'A preview of the 3D Point Cloud view will be displayed here after processing.',
    generating: 'The uploaded point cloud is being processed.',
  },
  elevationDifference: {
    error:
      'There was an error while creating the elevation Diference. Please try running the Elevation Difference creation again, or contact support@aspecscire for assistance.',
    initial:
      'A preview of the Elevation Difference will be displayed here after generation.',
    generating: 'The elevation difference is being created.',
  },
};

export const MapGeneratedStatus = ({
  data,
  type,
  generating = false,
  error = false,
}: {
  data: NullOrGenericObjectType;
  type:
    | 'dtm'
    | 'contour'
    | 'cutAndFillReport'
    | 'manualUpload'
    | 'pointCloudUpload'
    | 'elevationDifference'
    | 'thermal';
  generating?: boolean;
  error?: boolean;
}): JSX.Element => {
  return (
    <div className={styles.container}>
      {error ? (
        data && data.errorDetails ? (
          <Text strong>{data.errorDetails}</Text>
        ) : (
          <Text strong>{statusText[type].error}</Text>
        )
      ) : generating ? (
        <div className={styles.mapGenerating}>
          <Text strong>{statusText[type].generating}</Text>
          <Loading type="ellipsis" className={styles.ellipsisLoading} />
        </div>
      ) : (
        <div>
          <Text strong>{statusText[type].initial}</Text>
        </div>
      )}
    </div>
  );
};
