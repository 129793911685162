import { Button, Select } from 'antd';
import * as React from 'react';
import { View } from '../../../api/views.types';
import ViewAPIs from '../../../api/views';

interface Props {
  projectId: string;
  aoiId: string;
  missionId: string;
  onViewCreated: (view: View) => void;
}

export default class CreateView extends React.Component<Props, any> {
  private viewApi = new ViewAPIs();

  constructor(props: any) {
    super(props);
    this.state = { type: '', creating: false };
  }

  private createView = () => {
    const { projectId, aoiId, missionId, onViewCreated } = this.props;
    const { type } = this.state;

    this.setState({ creating: true }, () => {
      this.viewApi
        .createView(projectId, aoiId, { missionId, type })
        .then(({ data, error }) => {
          if (error) {
            console.error(error);
          }

          if (data) {
            onViewCreated(data);
          }
        })
        .finally(() => {
          this.setState({ creating: false });
        });
    });
  };

  render() {
    const { type, creating } = this.state;

    return (
      <div>
        <div>
          View type:
          <Select
            value={type}
            onChange={(type: any) => this.setState({ type })}
          >
            <Select.Option value="">-- Select a type --</Select.Option>
            <Select.Option value="video">video</Select.Option>
            <Select.Option value="interior_360">interior_360</Select.Option>
            <Select.Option value="exterior_360">exterior_360</Select.Option>
          </Select>
        </div>
        <div>
          <Button
            type="primary"
            disabled={!type || creating}
            onClick={() => this.createView()}
          >
            Create
          </Button>
        </div>
      </div>
    );
  }
}
