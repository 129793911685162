import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreateAOI from '.';

import { createAoi } from '../../redux/actions/projects';

const mapStateToProps = (state: any, props: any): any => {
  return {
    loading: state.projects.loading,
    aoi: state.projects.aoi,
    project: state.projects.data.find((p: any) => p.id === props.projectId),
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      createAoi,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAOI);
