import ApiBase from './ApiBase';
import { BASE_CAPI_ML_API_URL } from '../constants/urls';
import {
  ListObjectsResponse,
  SiteObjectResponse,
  CreateObjectRequest,
} from './ml.types';
import { processErrorHandling } from '../utils/helpers';

export default class MLApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(`${BASE_CAPI_ML_API_URL}/v1`);
  }

  public async getViewObjects(viewId: string): Promise<ListObjectsResponse> {
    return this.get(`/view/${viewId}/objects`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to fetch site objects for the view.',
          isArray: true,
        });
      });
  }

  public async editObjectFeature(
    viewId: string,
    objectId: string,
    featureGeoJSON: string
  ): Promise<SiteObjectResponse> {
    return this.patch(`/view/${viewId}/objects/${objectId}`, {
      feature: featureGeoJSON,
    })
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to edit a site object.',
          isArray: false,
        });
      });
  }

  public async deleteObject(
    viewId: string,
    objectId: string
  ): Promise<SiteObjectResponse> {
    return this.delete(`/view/${viewId}/objects/${objectId}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to delete a site object.',
          isArray: false,
        });
      });
  }

  public async createObject(
    viewId: string,
    createObjectRequest: CreateObjectRequest
  ): Promise<SiteObjectResponse> {
    return this.post(`/view/${viewId}/objects`, createObjectRequest)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to create a site object.',
          isArray: false,
        });
      });
  }
}
