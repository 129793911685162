import autobind from 'autobind-decorator';
import classnames from 'classnames';
import * as React from 'react';
import Icon from '../Icon';
import style from './ViewControls.module.scss';

interface IProps {
  className?: string;
  viewId: string;
  items: IMenuItem[];
  onChange?: (id: string | null) => void;
}

interface IMenuContentProps {
  onClose?: () => void;
  viewId?: string;
}

interface IState {
  items: IMenuItem[];
}
interface IMenuItem {
  id: string;
  label: string;
  renderControlBox: () => React.ReactElement<IMenuContentProps>;
  selected?: boolean;
}

class ViewControls extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = { items: props.items || [] };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    this.setState({ items: nextProps.items });
  }

  public renderMenuContentWrapper(): JSX.Element | null {
    const { viewId } = this.props;
    const elem = this.renderMenuContent();

    if (!elem) {
      return null;
    }

    const clonedElem = React.cloneElement(elem, {
      onClose: this.handleClose,
      viewId,
    });

    return <div className={style.controlBox}>{clonedElem}</div>;
  }

  public renderMenuContent(): React.ReactElement<IMenuContentProps> | null {
    if (!this.state) {
      return null;
    }

    const { items } = this.state;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items || []) {
      if (item.selected) {
        return item.renderControlBox();
      }
    }

    return null;
  }

  private renderMenu(): any {
    const { items } = this.state;

    return (items || []).map((item) => {
      return (
        <div
          onClick={() => this.handleClick(item)}
          className={classnames(
            style.menuItem,
            item.selected ? style.active : null
          )}
          key={item.id}
        >
          <div>
            <Icon name={item.id} />
          </div>

          {item.label}
        </div>
      );
    });
  }

  private handleClick(item: IMenuItem): void {
    const { items } = this.state;
    const { onChange } = this.props;
    const origFlag = !!item.selected;

    // eslint-disable-next-line no-restricted-syntax
    for (const i of items || []) {
      i.selected = false;
    }

    // eslint-disable-next-line no-param-reassign
    item.selected = !origFlag;

    // @todo: what's the point of this code??
    this.setState({ items: [...items] });

    if (onChange) {
      onChange(item.selected ? item.id : null);
    }
  }

  @autobind
  private handleClose(): void {
    const { items } = this.state;
    const { onChange } = this.props;

    // eslint-disable-next-line no-restricted-syntax
    for (const i of items || []) {
      i.selected = false;
    }

    // @todo: what's the point of this code??
    this.setState({ items: [...items] });

    if (onChange) {
      onChange(null);
    }
  }

  public render(): React.ReactNode {
    const { className } = this.props;

    return (
      <div className={classnames(style.container, className)}>
        <div className={style.menu}>{this.renderMenu()}</div>
        {this.renderMenuContentWrapper()}
      </div>
    );
  }
}

export default ViewControls;
