import {
  SnackbarReducersStateSnackbarsTypes,
  SnackbarReducersStateTypes,
  SnackbarReducersActionTypes,
} from '../../shapes/snackbar';
import { HIDE_SNACKBAR, SHOW_SNACKBAR } from '../actions';

const initialState: SnackbarReducersStateTypes = {
  snackbars: [],
};

export default function snackbar(
  state: SnackbarReducersStateTypes = initialState,
  action: SnackbarReducersActionTypes
): SnackbarReducersStateTypes {
  const { type, payload } = action;

  switch (type) {
    case HIDE_SNACKBAR:
      return {
        ...state,
        snackbars: state.snackbars.filter(
          (snackbar: SnackbarReducersStateSnackbarsTypes) =>
            snackbar.timestamp !== payload.timestamp
        ),
      };

    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbars: [...state.snackbars, payload],
      };

    default:
      return state;
  }
}
