import classnames from 'classnames';
import * as React from 'react';
import isEqual from 'react-fast-compare';
import Dropdown from '../DropDown/DropDown';
import {
  filteredArrayValue,
  inArray,
  undefinedOrNull,
} from '../../utils/functs';
import {
  SplitViewSelectorProps,
  SplitViewSelectorState,
  ViewDateOption,
} from './index.types';
import { View } from '../../api/views.types';
import style from './index.module.scss';

class SplitViewSelector extends React.Component<
  SplitViewSelectorProps,
  SplitViewSelectorState
> {
  constructor(props: SplitViewSelectorProps) {
    super(props);

    const { dates, selectedDate, viewsList } = this.props;

    this.state = {
      currentSelectedDate: this.getSelectedDate(
        dates,
        selectedDate,
        undefined,
        viewsList
      ),
    };
  }

  UNSAFE_componentWillReceiveProps({
    dates: nextDates,
    selectedDate: nextSelectedDate,
    viewsList: nextViewsList,
  }: Readonly<SplitViewSelectorProps>): void {
    const { dates, selectedDate, viewsList, onChange } = this.props;

    if (
      !isEqual(dates, nextDates) ||
      !isEqual(selectedDate, nextSelectedDate) ||
      !isEqual(viewsList, nextViewsList)
    ) {
      this.setState(
        ({ currentSelectedDate }) => {
          return {
            currentSelectedDate: this.getSelectedDate(
              nextDates,
              nextSelectedDate,
              currentSelectedDate,
              nextViewsList
            ),
          };
        },
        () => {
          const { currentSelectedDate } = this.state;

          // Todo: This page requires refactort.
          // The logic to handle change in the splitViewId in the url is screwed up.
          // Reason: The new date persistance logic was added over the existing flow of data
          if (onChange && currentSelectedDate) {
            onChange(currentSelectedDate);
          }
        }
      );
    }
  }

  private handleSelection1 = (event: any): void => {
    const { onChange, subTypes } = this.props;
    // TODO: use diff selector to get entire option

    const selectedOption = subTypes.find(
      (option) => option.value === event.value
    );

    if (selectedOption && onChange) {
      onChange(selectedOption.viewId);
    }
  };

  private handleSelection2 = (option: any): void => {
    const { onChange } = this.props;

    if (onChange) {
      this.setState(
        () => {
          return {
            currentSelectedDate: option.value,
          };
        },
        () => {
          onChange(option.value);
        }
      );
    }
  };

  private getSelectedDate = (
    dates: ViewDateOption[],
    selectedDate: string,
    currentSelectedDate?: string,
    viewsList?: View[]
  ) => {
    if (undefinedOrNull(currentSelectedDate)) {
      return selectedDate;
    }

    const filteredViewListById = filteredArrayValue(
      (viewsList ?? []).filter((a) => a.id === currentSelectedDate)
    );

    const prevViewDate = filteredViewListById?.date;

    if (undefinedOrNull(prevViewDate)) {
      return selectedDate;
    }

    const availableDateList = (dates ?? []).map((a) => a.value);

    const filteredViewListByDate = filteredArrayValue(
      (viewsList ?? []).filter(
        (a) => a.date === prevViewDate && inArray(availableDateList, a.id)
      )
    );

    if (undefinedOrNull(filteredViewListByDate)) {
      return selectedDate;
    }

    return filteredViewListByDate.id;
  };

  public render(): React.ReactNode {
    const { dates, selectedSubType, subTypes, className } = this.props;
    const { currentSelectedDate } = this.state;

    return (
      <div className={classnames(style.container, className)}>
        <Dropdown
          options={subTypes}
          value={selectedSubType}
          onChange={this.handleSelection1}
        />

        {currentSelectedDate && (
          <Dropdown
            options={dates}
            value={currentSelectedDate}
            onChange={this.handleSelection2}
          />
        )}
      </div>
    );
  }
}

export default SplitViewSelector;
