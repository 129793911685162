import * as React from 'react';
import { Modal } from 'antd';
import { Button } from '../Button';
import styles from './index.module.scss';
import { ConfirmPopupPropsTypes, ConfirmPopupStateTypes } from './index.types';
import SkeletonLoader from '../SkeletonLoader';

class ConfirmPopup extends React.Component<
  ConfirmPopupPropsTypes,
  ConfirmPopupStateTypes
> {
  public static defaultProps: Partial<ConfirmPopupPropsTypes> = {
    zIndex: 10004,
    cancelButtonText: 'CANCEL',
    okButtonText: 'OK',
    maskClosable: true,
    title: 'Confirm action',
  };

  public constructor(props: ConfirmPopupPropsTypes) {
    super(props);

    this.state = {
      isModalActive: false,
    };
  }

  private handleBtnAction = (value: boolean) => {
    const { onModalClose } = this.props;

    this.setState(
      {
        isModalActive: false,
      },
      () => {
        if (onModalClose) {
          onModalClose(value);
        }
      }
    );
  };

  private handleActivateOnClick = () => {
    this.setState({
      isModalActive: true,
    });
  };

  public render(): React.ReactNode {
    const {
      title,
      body,
      zIndex,
      cancelButtonText,
      okButtonText,
      maskClosable,
      loading,
      children,
    } = this.props;
    const { isModalActive } = this.state;

    return (
      <div className={styles.container}>
        <div onClick={this.handleActivateOnClick}>{children}</div>

        <Modal
          title={title}
          centered
          footer={null}
          visible={isModalActive}
          onOk={() => {
            this.handleBtnAction(true);
          }}
          onCancel={() => {
            this.handleBtnAction(false);
          }}
          destroyOnClose
          maskClosable={maskClosable}
          zIndex={zIndex}
        >
          <div className={styles.modalContainer}>
            {loading ? (
              <SkeletonLoader size={1} position="static" />
            ) : (
              <React.Fragment>
                <div className={styles.bodyContainer}>{body}</div>
                <div className={styles.ctaContainer}>
                  <Button
                    transparent
                    onClick={() => {
                      this.handleBtnAction(false);
                    }}
                  >
                    {cancelButtonText}
                  </Button>
                  <Button
                    onClick={() => {
                      this.handleBtnAction(true);
                    }}
                  >
                    {okButtonText}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default ConfirmPopup;
