import classnames from 'classnames';
import { Marker, Polyline } from 'google-maps-react';
import * as JSZip from 'jszip';
import * as React from 'react';
import { undefinedOrNull } from '../../../utils/functs';
import { Button } from '../../Button';
import BoundaryMap from '../BoundaryMap';
import style from './index.module.scss';
import { APP_BASE_URL, BASE_CAPI_URL } from '../../../constants/urls';
import { log } from '../../../utils/log';
import {
  GenericObjectType,
  NullOrGenericObjectType,
} from '../../../shapes/app';
import { FlightPlan } from '../../../shapes/missions';
import {
  createExternal360FlightPlan,
  createNadirFlightPlan,
  createPerspectiveFlightPlan,
  External360Output,
  NadirOutput,
  PerspectiveOutput,
} from './utils';

interface IProps {
  aoiId: string;
  projectId: string;
  missionId: string;
  plan: any;
  drones: any;
  onPlanChange: any;
  flightApp: string;
  epsg?: any;
  user?: any;
  droneId?: string;
  cameraId?: string;
  onSaveMission?: (req: GenericObjectType) => Promise<NullOrGenericObjectType>;
}

interface IState {
  altitude: string;
  takeoffAltitude: string;
  fTime: string;
  hOverlap: string;
  vOverlap: string;
  angle: any;
  gimbalPitch: string;
  speed: string;
  polyLinePoints: any[];
  buffer: boolean;
  extMarkerPoints: any[];
  loading: boolean;
  boundary?: any;
}

class FlightPlanGenerator extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      altitude: '100',
      takeoffAltitude: '0',
      fTime: '18',
      hOverlap: '80',
      vOverlap: '80',
      angle: '',
      buffer: false,
      gimbalPitch: '-45',
      speed: '10',
      polyLinePoints: [],
      extMarkerPoints: [],
      loading: false,
    };
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.disableEdit = this.disableEdit.bind(this);
  }

  public componentDidMount(): void {
    const { plan } = this.props;
    const planId = plan.id;

    if (plan.status === 'APPROVED' || plan.status === 'UPLOADED') {
      fetch(`${APP_BASE_URL}/capi/api/v1/flightplans/${planId}/artifact`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status === 0) {
            return Promise.resolve(response.blob());
          }

          return Promise.reject(new Error(response.statusText));
        })
        .then(JSZip.loadAsync)
        .then((zip: any) => {
          const tempPolyLinePoints: any = [];
          const promiseList: any[] = [];
          const {
            angle,
            altitude,
            takeoffAltitude,
            buffer,
            hOverlap,
            fTime,
            gimbalPitch,
            speed,
            vOverlap,
          } = this.state;

          // eslint-disable-next-line no-restricted-syntax
          for (const file in zip.files) {
            if (file.endsWith('.json')) {
              promiseList.push(
                zip
                  .file(file)
                  .async('string')
                  .then((res: any) => {
                    const params = this.convertToProjectUnits(JSON.parse(res));

                    this.setState({
                      altitude: params.altitude
                        ? params.altitude.toString()
                        : altitude,
                      takeoffAltitude: params.takeoffAltitude
                        ? params.takeoffAltitude.toString()
                        : takeoffAltitude,
                      hOverlap: params.hOverlap
                        ? (params.hOverlap * 100).toString()
                        : hOverlap,
                      vOverlap: params.vOverlap
                        ? (params.vOverlap * 100).toString()
                        : vOverlap,
                      fTime: params.max_flight_time
                        ? params.max_flight_time
                        : fTime,
                      angle: params.angle ? params.angle : angle,
                      buffer: params.noBuffer ? !params.noBuffer : !buffer,
                      gimbalPitch: params.gimbal_pitch
                        ? params.gimbal_pitch
                        : gimbalPitch,
                      speed: params.speed ? params.speed : speed,
                    });
                  })
              );
            } else if (file.endsWith('.csv')) {
              promiseList.push(
                zip
                  .file(file)
                  .async('string')
                  .then((planCsv: any) => {
                    const data = this.getJson(planCsv);
                    const splitLinePoints = this.getPolyLinePoints(data);

                    tempPolyLinePoints.push(splitLinePoints);
                  })
              );
            }
          }

          Promise.all(promiseList).then(() => {
            const tempExtMarkerPoints: any = [];

            if (plan.type === 'EXTERNAL_360') {
              // eslint-disable-next-line array-callback-return
              tempPolyLinePoints.map((p: any) => {
                const id = this.generateUniqueid();
                const point = { ...p[0], id };

                tempExtMarkerPoints.push(point);
              });
            }

            this.setState({
              polyLinePoints: tempPolyLinePoints,
              extMarkerPoints: tempExtMarkerPoints || null,
            });
          });
        })
        .catch(() => {
          /**/
        });
    }
  }

  public handleNumberChange(event: any): void {
    this.setState({
      [event.target.name]: event.target.value,
    } as any);
  }

  public handleCheckChange(event: any): void {
    this.setState({
      [event.target.name]: event.target.checked,
    } as any);
  }

  public addFlightPlanMarker = (latitude: any, longitude: any) => {
    const { extMarkerPoints } = this.state;
    const tempMakerPoints = extMarkerPoints;
    const markerId = this.generateUniqueid();
    const obj = {
      lat: latitude,
      lng: longitude,
      id: markerId,
    };

    tempMakerPoints.push(obj);
    this.setState({
      extMarkerPoints: tempMakerPoints,
    });
  };

  public getBoundary = (data: any) => {
    if (data) {
      this.setState({
        boundary: data,
      });
    }
  };

  private allowDownloadBtn = (): boolean => {
    const { plan } = this.props;

    return ['APPROVED'].indexOf(plan.status) > -1;
  };

  private allowGenerateBtn = (): boolean => {
    const { plan } = this.props;
    const { loading } = this.state;

    const readOnly =
      plan.type === 'EXTERNAL_360' ? !this.canExtSubmit() : !this.canSubmit();

    if (['CREATED', 'GENERATED', 'APPROVED'].indexOf(plan.status) < 0) {
      return false;
    }

    if (readOnly) {
      return false;
    }

    if (loading) {
      return false;
    }

    return true;
  };

  private deleteMarker = (point: any) => {
    const { extMarkerPoints } = this.state;
    const { id } = point;
    const tempExtMarkerPoints = extMarkerPoints.filter((p: any) => p.id !== id);

    this.setState({
      extMarkerPoints: tempExtMarkerPoints,
    });
  };

  private renderWarning = () => {
    const { plan } = this.props;

    if (!this.canSubmit()) {
      return (
        <div>
          <p className={style.renderWarning}>
            1. Please ensure that all the fields have valid values to enable the
            generate button.
          </p>
          <p className={style.renderWarning}>
            2. Make sure you have selected the appropriate drone and associated
            camera.
          </p>
        </div>
      );
    }

    if (!this.canExtSubmit()) {
      return (
        <div>
          {plan.type === 'EXTERNAL_360' ? (
            <p className={style.paraColor}>
              1. Mark at least one point in the map to enable Generate Button.
            </p>
          ) : null}
        </div>
      );
    }

    return null;
  };

  private renderMarker = () => {
    const { plan } = this.props;
    const { extMarkerPoints, polyLinePoints } = this.state;
    const canEdit = plan.status !== 'UPLOADED';

    if (plan.type !== 'EXTERNAL_360') {
      if (polyLinePoints) {
        return polyLinePoints.map((p: any[], index: any) => {
          // eslint-disable-next-line react/no-array-index-key
          return <Marker key={`marker_${index}`} position={p[0]} />;
        });
      }

      return null;
    }

    if (!(plan.type !== 'EXTERNAL_360')) {
      if (extMarkerPoints) {
        return extMarkerPoints.map((p: any[], index: any) => {
          return (
            <Marker
              onDblclick={
                canEdit
                  ? () => this.deleteMarker(p)
                  : () => {
                      /**/
                    }
              }
              // eslint-disable-next-line react/no-array-index-key
              key={`marker_${index}`}
              position={p}
            />
          );
        });
      }

      return null;
    }

    return null;
  };

  private renderPolyLine(): React.ReactNode {
    const { polyLinePoints } = this.state;

    if (polyLinePoints.length > 0) {
      return polyLinePoints.map((p: any[], index: any) => {
        return (
          <Polyline
            // eslint-disable-next-line react/no-array-index-key
            key={`path_${index}`}
            path={p}
            strokeColor={this.getRandomColor()}
            strokeWeight={2}
          />
        );
      });
    }

    return null;
  }

  private getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  };

  private canSubmit = () => {
    const { plan, droneId, cameraId } = this.props;
    const { altitude, fTime } = this.state;

    return plan && droneId && cameraId && altitude && fTime;
  };

  private canExtSubmit = () => {
    const { plan, droneId, cameraId } = this.props;
    const { altitude, fTime, extMarkerPoints } = this.state;

    return (
      plan &&
      droneId &&
      cameraId &&
      altitude &&
      fTime &&
      extMarkerPoints.length !== 0
    );
  };

  private createReq = () => {
    const { drones, droneId, cameraId } = this.props;
    const {
      altitude,
      takeoffAltitude,
      angle,
      buffer,
      extMarkerPoints,
      fTime,
      gimbalPitch,
      hOverlap,
      speed,
      vOverlap,
    } = this.state;
    const camera = drones
      .find((d: any) => d.id === droneId)
      .cameras.find((c: any) => c.id === cameraId);
    const tcapturePoints = extMarkerPoints.map((p) => [
      p.lng,
      p.lat,
      parseFloat(altitude),
    ]);

    const params = {
      altitude: parseFloat(altitude),
      takeoffAltitude: Number.isNaN(parseFloat(takeoffAltitude))
        ? 0
        : parseFloat(takeoffAltitude),
      hFOV: camera.hfov,
      vFOV: camera.vfov,
      hOverlap: parseFloat(hOverlap) / 100,
      vOverlap: parseFloat(vOverlap) / 100,
      reverse: false,
      heading: 0.0,
      stayFor: 5,
      point_downwards: true,
      movie: false,
      falseStart: false,
      max_flight_time: parseFloat(fTime),
      buffer_time: 5.3,
      aspectRatio: '3:2',
      angle: parseFloat(angle),
      noBuffer: !buffer,
      gimbal_pitch: parseFloat(gimbalPitch),
      join_threshold: 5.0,
      speed: parseFloat(speed),
      capture_points: tcapturePoints,
      droneId,
      cameraId,
    };

    return this.convertToSIUnits(params);
  };

  private handleGenerate = async () => {
    const { onSaveMission, droneId, cameraId } = this.props;

    if (cameraId && droneId && onSaveMission) {
      const req = {
        droneId,
        cameraId,
      };

      onSaveMission(req).catch((res) => {
        log.error(
          res,
          'Project -> Aoi -> Manage -> Mission -> Flight Planning -> Generate'
        );
      });
    }

    const flightPlan = this.getFlightPlan();

    if (!flightPlan) {
      console.error('There was an error while generating flightplan');

      return;
    }

    this.setState({ loading: true });

    await this.approveFlightPlan(flightPlan).finally(() =>
      this.setState({ loading: false })
    );
  };

  private convertPlansToPolylinePoints = (plans: Array<string>) => {
    return plans.map((p: any) => {
      const data = this.getJson(p);

      return this.getPolyLinePoints(data);
    });
  };

  private getFlightPlan = ():
    | External360Output
    | NadirOutput
    | PerspectiveOutput
    | undefined => {
    const { plan } = this.props;
    const { boundary } = this.state;

    if (boundary === undefined) {
      console.error('Cannot generate flightplan with an undefined boundary');

      return;
    }

    const request = this.createReq();

    const { type } = plan as FlightPlan;

    let generatedFlightplan:
      | PerspectiveOutput
      | NadirOutput
      | External360Output;

    switch (type) {
      case 'PERSPECTIVE': {
        generatedFlightplan = createPerspectiveFlightPlan({
          boundary,
          speed: request.speed,
          altitude: request.altitude,
          gimbalPitch: request.gimbal_pitch,
          maxFlightTime: request.max_flight_time,
        });

        break;
      }

      case 'NADIR': {
        generatedFlightplan = createNadirFlightPlan({
          boundary,
          buffer: request.noBuffer ? undefined : 5.3,
          altitude: request.altitude,
          takeOffAltitude: request.takeoffAltitude,
          horizontalFieldOfView: request.hFOV,
          verticalFieldOfView: request.vFOV,
          angle: request.angle,
          maxFlightTime: request.max_flight_time,
          horizontalOverlap: request.hOverlap,
          verticalOverlap: request.vOverlap,
          joinThreshold: request.join_threshold,
          stayForDuration: request.stayFor,
        });

        break;
      }

      case 'EXTERNAL_360': {
        generatedFlightplan = createExternal360FlightPlan({
          capturePoints: request.capture_points,
          altitude: request.altitude,
          speed: request.speed,
        });

        break;
      }

      default: {
        console.error('Unsupported Flightplan type!', type);

        return undefined;
      }
    }

    this.setState({
      polyLinePoints: this.convertPlansToPolylinePoints(
        generatedFlightplan.plans
      ),
    });

    return generatedFlightplan;
  };

  private approveFlightPlan = (
    flightPlan: External360Output | NadirOutput | PerspectiveOutput
  ) => {
    const { plan, onPlanChange, flightApp } = this.props;
    const planId = plan.id;
    const req = this.createReq();

    return fetch(`${APP_BASE_URL}/capi/api/v1/flightplans/${planId}/approve`, {
      method: 'POST',
      body: JSON.stringify({
        inputParameters: `${JSON.stringify(req)}`,
        flightPlan,
        flightApp,
      }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status > 299) {
          throw res;
        }

        return res.json();
      })
      .then((x) => {
        onPlanChange({ ...x, id: planId });
      })
      .catch(() => {
        /**/
      });
  };

  private getJson = (x: any) => {
    const jsonArr = [];
    const arr = x.split('\n');
    const headers = arr[0].split(',');

    for (let i = 1; i < arr.length - 1; i += 1) {
      const data = arr[i].split(',');
      const obj = {};

      for (let j = 0; j < data.length; j += 1) {
        obj[headers[j].trim()] = data[j].trim();
      }

      jsonArr.push(obj);
    }

    return jsonArr;
  };

  private getPolyLinePoints = (x: any) => {
    const points: any[] = [];

    // eslint-disable-next-line array-callback-return
    x.map((p: any) => {
      points.push({
        lat: parseFloat(p.latitude),
        lng: parseFloat(p.longitude),
        'altitude(m)': parseFloat(p['altitude(m)']),
      });
    });

    return points;
  };

  private generateUniqueid = () => {
    return Date.now() + Math.random();
  };

  private getProjectUnits = (type: 'distance' | 'speed') => {
    const { epsg } = this.props;

    // distance
    if (type === 'distance') {
      if (!undefinedOrNull(epsg)) {
        switch (epsg.unit) {
          case 'usFT':
            return 'ft';

          default:
            return 'm';
        }
      } else {
        return 'm';
      }
    }

    // speed
    if (type === 'speed') {
      if (!undefinedOrNull(epsg)) {
        switch (epsg.unit) {
          case 'usFT':
            return 'mi/h';

          default:
            return 'km/h';
        }
      } else {
        return 'km/h';
      }
    }

    return null;
  };

  private renderNadirParams = () => {
    const { user, plan } = this.props;
    const {
      angle,
      altitude,
      buffer,
      fTime,
      hOverlap,
      polyLinePoints,
      vOverlap,
      takeoffAltitude,
    } = this.state;

    if (plan.type === 'NADIR') {
      if (user.role === 'aspecscire_staff') {
        return (
          <div>
            <div className={style.controls}>
              <div className={style.nameContainer}>
                <div>
                  <label className={style.labelFields}>
                    Flight Altitude - Above Ground Level (
                    {this.getProjectUnits('distance')})
                  </label>
                </div>
                <div>
                  <input
                    name="altitude"
                    value={altitude}
                    disabled={this.disableEdit()}
                    type="number"
                    step="0.1"
                    onChange={this.handleNumberChange}
                  />
                </div>
              </div>
              <div className={style.controlNameContainer}>
                <div>
                  <label className={style.labelFields}>
                    Take-off Point Altitude - Above Ground Level (
                    {this.getProjectUnits('distance')})
                  </label>
                </div>
                <div>
                  <input
                    name="takeoffAltitude"
                    value={takeoffAltitude}
                    disabled={this.disableEdit()}
                    type="number"
                    step="1"
                    onChange={this.handleNumberChange}
                  />
                </div>
              </div>
            </div>
            <div className={style.controls}>
              <div className={style.nameContainer}>
                <div>
                  <label className={style.labelFields}>Side Overlap % </label>
                </div>
                <div>
                  <input
                    name="hOverlap"
                    value={hOverlap}
                    disabled={this.disableEdit()}
                    type="number"
                    step="0.1"
                    onChange={this.handleNumberChange}
                  />
                </div>
              </div>
              <div className={style.controlNameContainer}>
                <div>
                  <label className={style.labelFields}>Front Overlap %</label>
                </div>
                <div>
                  <input
                    name="vOverlap"
                    value={vOverlap}
                    disabled={this.disableEdit()}
                    type="number"
                    step="0.1"
                    onChange={this.handleNumberChange}
                  />
                </div>
              </div>
            </div>
            <div className={style.controls}>
              <div className={style.nameContainer}>
                <div>
                  <label className={style.labelFields}>
                    Angle (deg) - Leave empty to auto-calculate
                  </label>
                </div>
                <div>
                  <input
                    name="angle"
                    value={angle}
                    disabled={this.disableEdit()}
                    type="number"
                    step="0.1"
                    onChange={this.handleNumberChange}
                  />
                </div>
              </div>
              <div className={style.bufferBoundaryDiv}>
                <div className={style.bufferDiv}>
                  <label
                    className={classnames(style.labelFields, style.bufferLabel)}
                  >
                    <input
                      name="buffer"
                      checked={buffer}
                      disabled={this.disableEdit()}
                      type="checkbox"
                      onChange={this.handleCheckChange}
                      className={style.bufferInput}
                    />
                    Buffer Boundary
                  </label>
                </div>
              </div>
            </div>
            <div className={style.controls}>
              <div className={style.nameContainer}>
                <div>
                  <label className={style.labelFields}>
                    Maximum Flight Time Per Battery (min)
                  </label>
                </div>
                <div>
                  <input
                    name="fTime"
                    value={fTime}
                    disabled={this.disableEdit()}
                    type="number"
                    step="1"
                    onChange={this.handleNumberChange}
                  />
                </div>
              </div>

              <div className={style.controlNameContainer} />
            </div>
            <div>
              <p>
                <span className={style.labelFields}>Number Of Splits: </span>
                {polyLinePoints.length}
              </p>
            </div>
          </div>
        );
      }

      if (user.role !== 'aspecscire_staff') {
        return (
          <div>
            <div className={style.controls}>
              <div className={style.nameContainer}>
                <div>
                  <label className={style.labelFields}>
                    Flight Altitude - Above Ground Level (
                    {this.getProjectUnits('distance')})
                  </label>
                </div>
                <div>
                  <input
                    name="altitude"
                    value={altitude}
                    disabled={this.disableEdit()}
                    type="number"
                    step="0.1"
                    onChange={this.handleNumberChange}
                  />
                </div>
              </div>
              <div className={style.controlNameContainer}>
                <div>
                  <label className={style.labelFields}>
                    Take-off Point Altitude - Above Ground Level (
                    {this.getProjectUnits('distance')})
                  </label>
                </div>
                <div>
                  <input
                    name="takeoffAltitude"
                    value={takeoffAltitude}
                    disabled={this.disableEdit()}
                    type="number"
                    step="1"
                    onChange={this.handleNumberChange}
                  />
                </div>
              </div>
            </div>
            <div className={style.controls}>
              <div className={style.nameContainer}>
                <div>
                  <label className={style.labelFields}>
                    Angle (deg) - Leave empty to auto-calculate
                  </label>
                </div>
                <div>
                  <input
                    name="angle"
                    value={angle}
                    disabled={this.disableEdit()}
                    type="number"
                    step="0.1"
                    onChange={this.handleNumberChange}
                  />
                </div>
              </div>
              <div className={style.controlNameContainer}>
                <div>
                  <label className={style.labelFields}>
                    Maximum Flight Time Per Battery (min)
                  </label>
                </div>
                <div>
                  <input
                    name="fTime"
                    value={fTime}
                    disabled={this.disableEdit()}
                    type="number"
                    step="1"
                    onChange={this.handleNumberChange}
                  />
                </div>
              </div>
            </div>
            <div>
              <p>
                <span className={style.labelFields}>Number Of Splits: </span>
                {polyLinePoints.length}
              </p>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }

    return null;
  };

  private renderExtParams = () => {
    const { plan } = this.props;
    const { altitude } = this.state;

    if (plan.type === 'EXTERNAL_360') {
      return (
        <div>
          <div className={style.controls}>
            <div className={style.extNameContainer}>
              <div>
                <label className={style.labelFields}>
                  Flight Altitude - Above Ground Level (
                  {this.getProjectUnits('distance')})
                </label>
              </div>
              <div>
                <input
                  name="altitude"
                  value={altitude}
                  disabled={this.disableEdit()}
                  type="number"
                  step="0.1"
                  onChange={this.handleNumberChange}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <div />;
  };

  private disableEdit(): boolean {
    const { plan } = this.props;

    return ['UPLOADED', 'COMPLETED'].indexOf(plan.status) > -1;
  }

  private updateParamDataInState = (updateParam: GenericObjectType): void => {
    const { polyLinePoints } = this.state;

    for (let i = 0; i < polyLinePoints.length; i += 1) {
      for (let j = 0; j < polyLinePoints[i].length; j += 1) {
        polyLinePoints[i][j] = {
          ...polyLinePoints[i][j],
          ...updateParam,
        };
      }
    }

    this.setState({ polyLinePoints });
  };

  private renderPerspectiveParams = () => {
    const { plan } = this.props;
    const { altitude, fTime, gimbalPitch, polyLinePoints, speed } = this.state;

    if (plan.type === 'PERSPECTIVE') {
      return (
        <div>
          <div className={style.controls}>
            <div className={style.nameContainer}>
              <div>
                <label className={style.labelFields}>
                  Flight Altitude - Above Ground Level (
                  {this.getProjectUnits('distance')})
                </label>
              </div>
              <div>
                <input
                  name="altitude"
                  value={altitude}
                  disabled={this.disableEdit()}
                  type="number"
                  step="0.1"
                  onChange={this.handleNumberChange}
                />
              </div>
            </div>
            <div className={style.controlNameContainer}>
              <div>
                <label className={style.labelFields}>
                  Maximum Flight Time Per Battery (min)
                </label>
              </div>
              <div>
                <input
                  name="fTime"
                  value={fTime}
                  disabled={this.disableEdit()}
                  type="number"
                  step="1"
                  onChange={this.handleNumberChange}
                />
              </div>
            </div>
          </div>
          <div className={style.controls}>
            <div className={style.nameContainer}>
              <div>
                <label className={style.labelFields}>Gimbal Pitch (deg)</label>
              </div>
              <div>
                <input
                  name="gimbalPitch"
                  value={gimbalPitch}
                  disabled={this.disableEdit()}
                  type="number"
                  step="0.1"
                  onChange={this.handleNumberChange}
                />
              </div>
            </div>
            <div className={style.controlNameContainer}>
              <div>
                <label className={style.labelFields}>
                  Speed ({this.getProjectUnits('speed')})
                </label>
              </div>
              <div>
                <input
                  name="speed"
                  value={speed}
                  disabled={this.disableEdit()}
                  type="number"
                  step="0.1"
                  onChange={this.handleNumberChange}
                />
              </div>
            </div>
          </div>
          {polyLinePoints.length !== 0 ? (
            <div>
              <p className={style.labelFields}>
                Number Of Splits:
                {polyLinePoints.length}
              </p>
            </div>
          ) : null}
        </div>
      );
    }

    return <div />;
  };

  private convertToProjectUnits = (params: any) => {
    const { epsg } = this.props;

    const METERS_TO_FEET_CONVERSION_UNIT = 3.28084;
    const KMPH_TO_MPH_CONVERSION_UNIT = 0.621371;

    if (!undefinedOrNull(epsg)) {
      switch (epsg.unit) {
        case 'usFT':
          return {
            ...params,
            altitude: Math.round(
              params.altitude * METERS_TO_FEET_CONVERSION_UNIT
            ),
            takeoffAltitude: Math.round(
              params.takeoffAltitude * METERS_TO_FEET_CONVERSION_UNIT
            ),
            speed: Math.round(params.speed * KMPH_TO_MPH_CONVERSION_UNIT),
          };

        default:
          return params;
      }
    } else {
      return params;
    }
  };

  // this gets used to create object which sent to backend
  // rounding ??
  private convertToSIUnits = (params: any) => {
    const { epsg } = this.props;

    const FEET_TO_METERS_CONVERSION_UNIT = 0.3048;
    const MPH_TO_KMPH_CONVERSION_UNIT = 1.60934;

    if (!undefinedOrNull(epsg)) {
      switch (epsg.unit) {
        case 'usFT': {
          const altitude = params.altitude * FEET_TO_METERS_CONVERSION_UNIT;
          const capturePoints = params.capture_points.map((p: any) => [
            p[0],
            p[1],
            altitude,
          ]);

          return {
            ...params,
            altitude,
            takeoffAltitude:
              params.takeoffAltitude * FEET_TO_METERS_CONVERSION_UNIT,
            speed: params.speed * MPH_TO_KMPH_CONVERSION_UNIT,
            capture_points: capturePoints,
          };
        }

        default:
          return params;
      }
    } else {
      return params;
    }
  };

  public render(): React.ReactNode {
    const { projectId, aoiId, plan } = this.props;
    const { loading } = this.state;
    const canEdit = plan.status !== 'UPLOADED';

    return (
      <div className={style.fullWidth}>
        {plan.type === 'EXTERNAL_360' ? (
          <div>
            {canEdit ? (
              <label
                className={classnames(style.instructionLabel, style.fontStyle)}
              >
                Click within the boundary to add a new marker point. Double
                click on a marker to delete it.
              </label>
            ) : (
              <label
                className={classnames(style.instructionLabel, style.fontStyle)}
              >
                This plan can no longer be edited.
              </label>
            )}
          </div>
        ) : null}
        <div className={style.map}>
          <BoundaryMap
            projectId={projectId}
            aoiId={aoiId}
            addPoint={
              // eslint-disable-next-line no-nested-ternary
              canEdit
                ? plan.type === 'EXTERNAL_360'
                  ? this.addFlightPlanMarker
                  : null
                : () => {
                    /**/
                  }
            }
            getBoundary={this.getBoundary}
          >
            {this.renderPolyLine()}
            {this.renderMarker()}
          </BoundaryMap>
        </div>
        {this.renderNadirParams()}
        {this.renderPerspectiveParams()}
        {this.renderExtParams()}
        {this.renderWarning()}
        <div className={style.buttonControl}>
          <div className={style.buttonLeft}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${BASE_CAPI_URL}/../../capi/api/v1/flightplans/${plan.id}/artifact`}
            >
              <Button
                className={style.actionBtn}
                disabled={!this.allowDownloadBtn()}
                text="Download"
              />
            </a>
          </div>

          <div className={style.buttonRight}>
            <Button
              className={style.actionBtn}
              disabled={!this.allowGenerateBtn()}
              onClick={this.handleGenerate}
              text="Generate"
              loading={loading}
              loadingText="Generating plan..."
            />
          </div>
        </div>
      </div>
    );
  }
}

export default FlightPlanGenerator;
