// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GcpMarkingEditReview_container__XYX-c{min-height:378px}.GcpMarkingEditReview_container__XYX-c .GcpMarkingEditReview_thumbWrapper__N3kJ1{width:100%}.GcpMarkingEditReview_container__XYX-c .GcpMarkingEditReview_thumbWrapper__N3kJ1 .GcpMarkingEditReview_itemThumbsWrapper__1BKoe{position:relative;float:left;margin-right:20px;margin-bottom:20px}.GcpMarkingEditReview_container__XYX-c .GcpMarkingEditReview_thumbWrapper__N3kJ1 .GcpMarkingEditReview_itemThumbsWrapper__1BKoe .GcpMarkingEditReview_ctaWrapper__MlYpo{position:absolute;z-index:1;right:5px;top:5px}.GcpMarkingEditReview_container__XYX-c .GcpMarkingEditReview_thumbWrapper__N3kJ1 .GcpMarkingEditReview_itemThumbsWrapper__1BKoe .GcpMarkingEditReview_itemThumbs__xLEOo{width:200px;height:200px}", "",{"version":3,"sources":["webpack://./src/components/GcpMarking/GcpMarkingEditReview/index.module.scss"],"names":[],"mappings":"AAEA,uCACE,gBAAA,CAEA,iFACE,UAAA,CAEA,gIACE,iBAAA,CACA,UAAA,CACA,iBAAA,CACA,kBAAA,CAEA,wKACE,iBAAA,CACA,SAAA,CACA,SAAA,CACA,OAAA,CAGF,wKACE,WAAA,CACA,YAAA","sourcesContent":["@import 'src/styles/scss/base/base';\n\n.container {\n  min-height: 378px;\n\n  .thumbWrapper {\n    width: 100%;\n\n    .itemThumbsWrapper {\n      position: relative;\n      float: left;\n      margin-right: 20px;\n      margin-bottom: 20px;\n\n      .ctaWrapper {\n        position: absolute;\n        z-index: 1;\n        right: 5px;\n        top: 5px;\n      }\n\n      .itemThumbs {\n        width: 200px;\n        height: 200px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GcpMarkingEditReview_container__XYX-c",
	"thumbWrapper": "GcpMarkingEditReview_thumbWrapper__N3kJ1",
	"itemThumbsWrapper": "GcpMarkingEditReview_itemThumbsWrapper__1BKoe",
	"ctaWrapper": "GcpMarkingEditReview_ctaWrapper__MlYpo",
	"itemThumbs": "GcpMarkingEditReview_itemThumbs__xLEOo"
};
export default ___CSS_LOADER_EXPORT___;
