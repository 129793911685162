import { Input, Select } from 'antd';
import * as React from 'react';
import _ from 'lodash';
import rewind from '@mapbox/geojson-rewind';
import turfArea from '@turf/area';
import { DOCUMENTATION_URL_LIST } from '../../../../../constants/urls';
import { GenericObjectType } from '../../../../../shapes/app';
import {
  toMetersEquivalent,
  undefinedOrNull,
  shouldUseLegacyMeasurements,
} from '../../../../../utils/functs';
import { convertVolume, getValidGeoJson } from '../../../../../utils/helpers';
import { Button } from '../../../../Button';
import DocumentationLink from '../../../../DocumentationLink';
import { Unit2 } from '../InfoHelpers/Unit';
import { Spinner } from '../../../../Spinner/Spinner';
import MeasurementAPIs from '../../../../../api/Measurement';

import { MeasurementSubType } from '../../../index.types';
import { VolumeInfoPropsType, VolumeInfoStateType } from './index.types';
import style from './index.module.scss';

import { bestFitVolume, calcVolume } from '../../../../../api/wasm/calculate';

declare let proj4: any;

const SelectOption = Select.Option;
const measurementAPIs = new MeasurementAPIs();

class VolumeInfo extends React.Component<
  VolumeInfoPropsType,
  VolumeInfoStateType
> {
  public constructor(props: VolumeInfoPropsType) {
    super(props);

    this.state = {
      loading: false,
      blobUrl: '#',
    };
  }

  public componentDidMount() {
    const {
      view,
      shape,
      hasLinkedIssue,
      planeElvation,
      subType,
      elevationList,
    } = this.props;
    const url = this.getPolygonBlobURL(shape.geometry);

    this.setState({ blobUrl: url }, () => {
      if (hasLinkedIssue) {
        this.calculateVolume(
          view.id,
          shape,
          planeElvation || null,
          subType || 'BestFitPlane',
          elevationList || []
        );
      }
    });
  }

  public UNSAFE_componentWillReceiveProps({
    view: newView,
    shape,
    subType,
    elevationList,
  }: VolumeInfoPropsType) {
    const {
      view: oldView,
      planeElvation,
      shape: oldShape,
      subType: oldSubType,
    } = this.props;

    if (oldView.id !== newView.id && shape && subType) {
      this.calculateVolume(
        newView.id,
        shape,
        planeElvation || null,
        subType,
        elevationList || []
      );
    }

    if (!_.isEqual(oldShape, shape)) {
      const { blobUrl } = this.state;

      if (blobUrl !== '#') {
        URL.revokeObjectURL(blobUrl);

        const url = this.getPolygonBlobURL(shape.geometry);

        this.setState({ blobUrl: url });
      }

      // clean up volume values on shape change
      return this.setState({ volume: undefined });
    }

    if (oldSubType !== subType) {
      // clean up volume on subtype change
      return this.setState({ volume: undefined });
    }
  }

  public componentWillUnmount() {
    const { blobUrl } = this.state;

    URL.revokeObjectURL(blobUrl);
  }

  private _internalCalcVolume = (
    viewId: string,
    shape: any,
    elevation: number | null,
    volumeType: MeasurementSubType,
    elevations: number[]
  ) => {
    if (shouldUseLegacyMeasurements()) {
      return measurementAPIs
        .fetchVolume(viewId, this.getPoints(shape), elevation, volumeType)
        .then((res) => {
          const net = res?.data?.volume?.cubic_metres || undefined;

          if (net) {
            return { net };
          }

          return undefined;
        });
    }

    return measurementAPIs
      .getViewProj4String(viewId)
      .then((projection) => {
        const points = this.getPoints(shape);
        const convertedPoints = [];
        const latlon =
          '+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees +no_defs';

        // eslint-disable-next-line
        for (const p of points) {
          const convertedPoint = proj4(latlon, projection, [p[0], p[1]]);

          convertedPoints.push(convertedPoint);
        }

        const wkt = `POLYGON ((${convertedPoints
          .map((p) => `${p[0]} ${p[1]}`)
          .join(',')} ))`;
        const promises = [calcVolume(viewId, wkt)];

        if (volumeType === 'FlatPlane') {
          promises.push(
            new Promise((resolve) => {
              const polygonArea = +turfArea(shape).toFixed(2);
              const baseVolume = polygonArea * (elevation || 0);

              resolve(baseVolume);
            })
          );
        } else {
          promises.push(
            new Promise((resolve) => {
              bestFitVolume(wkt, elevations).then((v) => {
                resolve(v);
              });
            })
          );
        }

        return Promise.all(promises);
      })
      .then(([rawVolume, baseVolume]) => {
        const net = baseVolume + rawVolume;

        return { net };
      });
  };

  private calculateVolume = (
    viewId: string,
    shape: any,
    elevation: number | null,
    volumeType: MeasurementSubType,
    elevations: number[]
  ) => {
    if (volumeType === 'FlatPlane' && elevation === null) {
      console.error(
        'Elevation must be specified to calculate volume against a flat plane.'
      );
      this.setState({
        volume: undefined,
      });

      return;
    }

    if (!shape?.geometry?.coordinates?.[0]) {
      console.error(
        'Error while extracting points from the drawn polygon, for Volume Calculation.'
      );
      this.setState({
        volume: undefined,
      });

      return;
    }

    this.setState(
      {
        volume: undefined,
        loading: true,
        error: undefined,
      },
      () => {
        this._internalCalcVolume(
          viewId,
          shape,
          elevation,
          volumeType,
          elevations
        )
          .then((volume) => {
            this.setState({
              volume,
              loading: false,
              error: undefined,
            });
          })
          .catch((err) => {
            let result = { message: 'Unknown error' };

            if (!err.response) {
              result = { message: 'Network error' };
            }

            if (err?.response?.data) {
              result = err?.response?.data;
            }

            this.setState({
              error: result,
              loading: false,
              volume: undefined,
            });
          });
      }
    );
  };

  private calculateCutFillVolume = (
    viewId: string,
    shape: any,
    elevation: number | null,
    volumeType: MeasurementSubType
  ) => {
    if (volumeType === 'FlatPlane' && elevation === null) {
      console.error(
        'Elevation must be specified to calculate volume against a flat plane.'
      );
      this.setState({
        volume: undefined,
      });

      return;
    }

    if (!shape?.geometry?.coordinates?.[0]) {
      console.error(
        'Error while extracting points from the drawn polygon, for Volume Calculation.'
      );
      this.setState({
        volume: undefined,
      });

      return;
    }

    this.setState(
      {
        volume: undefined,
        loading: true,
        error: undefined,
      },
      () => {
        measurementAPIs
          .fetchCutFillVolume(
            viewId,
            this.getPoints(shape),
            elevation,
            volumeType
          )
          .then((res) => {
            this.setState({
              volume: res?.data?.volume?.cubic_metres || undefined,
              loading: false,
              error: undefined,
            });
          })
          .catch((err) => {
            let result = { message: 'Unknown error' };

            if (!err.response) {
              result = { message: 'Network error' };
            }

            if (err?.response?.data) {
              result = err?.response?.data;
            }

            this.setState({
              error: result,
              loading: false,
              volume: undefined,
            });
          });
      }
    );
  };

  private getPoints(shape: any) {
    return shape.geometry.coordinates[0].map((c: any) => [c[0], c[1]]);
  }

  private handleElevation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const elev = Number(event.target.value);
    const { onVolumePlaneElevationChange } = this.props;

    if (
      elev !== null &&
      !Number.isNaN(elev) &&
      event.target.value.trim().length > 0
    ) {
      onVolumePlaneElevationChange(elev);
    } else {
      onVolumePlaneElevationChange(undefined);
    }
  };

  private getViewUnits(): string {
    const { view } = this.props;

    return this._getViewUnits(view);
  }

  private _getViewUnits(view?: GenericObjectType): string {
    if (!view) {
      return 'm';
    }

    if (!view.coordinateUnits) {
      return 'm';
    }

    if (view.coordinateUnits === 'FEET') {
      return 'ft';
    }

    if (view.coordinateUnits === 'US_SURVEY_FEET') {
      return 'usFT';
    }

    return 'm';
  }

  private getAverageElevation(): number | null {
    const { elevationList } = this.props;

    if (elevationList && elevationList.length > 2) {
      // polygons have first and last point as the same, hence causing a point to be sampled twice
      // removing the first point from elevation calculation
      const uniquePoints = elevationList.slice(1);
      const sum = uniquePoints.reduce((a, b) => a + b, 0);

      return toMetersEquivalent(
        sum / uniquePoints.length,
        this.getViewUnits() === 'm' ? 'meters' : 'feet'
      );
    }

    return null;
  }

  private isVolumeButtonEnabled() {
    const { subType: _volumeType, planeElvation } = this.props;
    const volumeType = _volumeType || 'FlatPlane';

    if (volumeType === 'FlatPlane') {
      return !(planeElvation === null || Number.isNaN(planeElvation || NaN));
    }

    return true;
  }

  private getPolygonBlobURL(polygon: GenericObjectType): string {
    const validGeoJsonPolygon = rewind(getValidGeoJson(polygon));
    const blob = new Blob([JSON.stringify(validGeoJsonPolygon)], {
      type: 'text/geojson',
    });
    const url = URL.createObjectURL(blob);

    return url;
  }

  public renderCutFillVolume(): React.ReactNode {
    const { volume } = this.state;
    const { view, shape, planeElvation, subType: _volumeType } = this.props;
    const volumeType = _volumeType || 'FlatPlane';

    if (!volume) {
      return <></>;
    }

    if (
      volume &&
      !undefinedOrNull(volume.cut) &&
      !undefinedOrNull(volume.fill)
    ) {
      return (
        <>
          <Unit2
            label="Cut Volume"
            value1={volume && convertVolume(volume.cut, 'meter')}
            value2={volume && convertVolume(volume.cut, 'yard')}
          />
          <Unit2
            label="Fill Volume"
            value1={volume && convertVolume(volume.fill, 'meter')}
            value2={volume && convertVolume(volume.fill, 'yard')}
          />
        </>
      );
    }

    return (
      <>
        <Button
          disabled={
            !(this.isVolumeButtonEnabled() && shouldUseLegacyMeasurements())
          }
          onClick={() => {
            this.calculateCutFillVolume(
              view.id,
              shape,
              planeElvation || null,
              volumeType
            );
          }}
          className={style.calcButton}
          type="secondary"
        >
          Calculate Cut &amp; Fill
        </Button>
      </>
    );
  }

  public render(): React.ReactNode {
    const {
      view,
      shape,
      subType: _volumeType,
      planeElvation,
      elevationList,
      onVolumeSubTypeChange,
    } = this.props;
    const { blobUrl, volume, loading, error } = this.state;
    const volumeType = _volumeType || 'FlatPlane';

    if (loading) {
      return <Spinner />;
    }

    if (error) {
      return (
        <div className={style.container}>
          {error.message || 'Something went wrong'}
        </div>
      );
    }

    return (
      <div>
        <div className={style.volumeTypeContainer}>
          <Select
            value={volumeType}
            defaultValue={volumeType}
            onChange={(val: any) => {
              onVolumeSubTypeChange(val);
            }}
            className={style.volumeTypeSelect}
            dropdownStyle={{ zIndex: 20001 }}
          >
            <SelectOption value="FlatPlane">Horizontal Plane</SelectOption>
            <SelectOption value="BestFitPlane">Best-fit Plane</SelectOption>
          </Select>
          <DocumentationLink
            className={style.toolTipWrapper}
            href={DOCUMENTATION_URL_LIST.volume}
            toolTipPosition="right"
          />
        </div>
        {volumeType === 'FlatPlane' && (
          <div>
            <div className={style.titleWrapper}>
              <label>Plane Elevation ({this.getViewUnits()})</label>
            </div>
            {this.getAverageElevation() !== null ? (
              <p className={style.elevationHint}>
                Average Elevation: {this.getAverageElevation()}{' '}
                {this.getViewUnits()}
              </p>
            ) : null}
            <Input
              defaultValue={`${planeElvation}`}
              type="number"
              placeholder="Plane elevation to calculate volume against"
              onChange={this.handleElevation}
            />
          </div>
        )}
        <Button
          disabled={!this.isVolumeButtonEnabled()}
          onClick={() => {
            this.calculateVolume(
              view.id,
              shape,
              planeElvation || null,
              volumeType,
              elevationList || []
            );
          }}
          className={style.calcButton}
          type="secondary"
        >
          Calculate Volume
        </Button>
        <a
          className={style.downloadLinkContainer}
          href={blobUrl}
          download="polygon.geojson"
        >
          <i className="fa fa-download" aria-hidden="true" />
          Download Polygon
        </a>
        {volume !== null && volume !== undefined ? (
          <div>
            <hr className={style.line} />
            <Unit2
              label="Net Volume (Cut - Fill)"
              value1={volume && convertVolume(volume.net, 'meter')}
              value2={volume && convertVolume(volume.net, 'yard')}
            />
            {this.renderCutFillVolume()}
          </div>
        ) : null}
      </div>
    );
  }
}

export default VolumeInfo;
