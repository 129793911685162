import { USE_MEASUREMENT_API } from 'src/constants';
import { GOOGLE_MAP_KEY, MAPBOX_TOKEN_1 } from 'src/constants/tokens';

export interface AppConfig {
  mapboxToken: string;
  googleMapsToken: string;
  useLegacyMeasurements: boolean;
}

const ConfigProvider = () => {
  const config: AppConfig = {
    mapboxToken: MAPBOX_TOKEN_1,
    googleMapsToken: GOOGLE_MAP_KEY,
    useLegacyMeasurements: USE_MEASUREMENT_API.toLowerCase() === 'true',
  };

  return {
    getConfig: () => config,
    updateConfig: (update: Partial<AppConfig>) => {
      if (update.mapboxToken) config.mapboxToken = update.mapboxToken;

      if (update.googleMapsToken)
        config.googleMapsToken = update.googleMapsToken;

      if (
        update.useLegacyMeasurements !== undefined &&
        update.useLegacyMeasurements !== null
      )
        config.useLegacyMeasurements = update.useLegacyMeasurements;
    },
  };
};

// ensure there is only one instance of the config, with the state being captured in the closure.
export const Config = ConfigProvider();
