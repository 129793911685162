import * as React from 'react';
import { Input, Select } from 'antd';
import styles from './AuthorizeNetInputFields.module.scss';
import {
  PaymentsAuthorizeNetInputFieldsPropsTypes,
  PaymentsAuthorizeNetInputFieldsStateTypes,
} from './AuthorizeNetInputFields.types';
import { authorizeNetData } from '../data';
import { PaymentsAuthorizeNetInputFieldsTypes } from './index.types';
import { undefinedOrNull } from '../../../utils/functs';

const { Option } = Select;

export default class AuthorizeNetInputFields extends React.PureComponent<
  PaymentsAuthorizeNetInputFieldsPropsTypes,
  PaymentsAuthorizeNetInputFieldsStateTypes
> {
  public static getDerivedStateFromProps(
    nextProps: PaymentsAuthorizeNetInputFieldsPropsTypes
  ) {
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || {}),
      };
    }

    return null;
  }

  public constructor(props: PaymentsAuthorizeNetInputFieldsPropsTypes) {
    super(props);

    const { value } = props;

    this.state = {
      amount: value?.amount || '',
      currency: value?.currency || null,
      description: value?.description || '',
    };
  }

  private handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let amount = e.target.value;

    if (
      !undefinedOrNull(amount) &&
      amount.trim() !== '' &&
      !/^\d+(\.(\d{1,2})?)?$/.test(amount)
    ) {
      return;
    }

    while (parseInt(amount, 10) > 0 && amount.charAt(0) === '0') {
      amount = amount.substr(1);
    }

    if (undefinedOrNull(amount) || amount.trim() === '') {
      amount = '0';
    }

    if (!('value' in this.props)) {
      this.setState({
        amount,
      });
    }

    this.triggerChange({
      amount,
    });
  };

  private handleCurrencyChange = (currency: string) => {
    if (!('value' in this.props)) {
      this.setState({ currency });
    }

    this.triggerChange({ currency });
  };

  private handleRemarksChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!('value' in this.props)) {
      this.setState({ description: value });
    }

    this.triggerChange({ description: value });
  };

  private triggerChange = (
    changedValue: Partial<PaymentsAuthorizeNetInputFieldsTypes>
  ) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ ...this.state, ...changedValue });
    }
  };

  public render() {
    const { amount, currency, description } = this.state;

    return (
      <div className={styles.container}>
        <Input
          type="text"
          placeholder="Amount"
          value={amount}
          onChange={this.handleAmountChange}
          className={styles.amountInputField}
        />
        <Select
          placeholder="Select a currency"
          value={currency}
          onChange={this.handleCurrencyChange}
          className={styles.currencySelectField}
        >
          {authorizeNetData.currencyList.map((a) => {
            return (
              <Option value={a} key={a}>
                {a}
              </Option>
            );
          })}
        </Select>
        <Input
          type="text"
          placeholder="Invoice Number (Optional) & Project Name - Max 200 Chars"
          value={description}
          onChange={this.handleRemarksChange}
          maxLength={200}
        />
      </div>
    );
  }
}
