import * as React from 'react';
import { useEffect, useState } from 'react';
import { Feature, Map } from 'ol';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import TextStyle from 'ol/style/Text';
import CircleStyle from 'ol/style/Circle';
import infoIcon from './info.png';

export interface ImageAnnotation {
  content?: string;
  center: {
    x: number;
    y: number;
  };
  source: PIXEL_SOURCE;
}

export interface AnnotationData {
  annotations: ImageAnnotation[];
}

interface ImageAnnotationProps {
  olMap?: Map;
  annotationsData: AnnotationData;
}

const ANNOTATION_Z_INDEX = 101;

export enum PIXEL_SOURCE {
  system,
  user,
}

export const annotationStyleFunction = (feature: Feature) => {
  const image = new CircleStyle({
    radius: 5,
    fill: new Fill({ color: '#cf4906' }),
    stroke: new Stroke({ color: '#cf4906', width: 1.25 }),
  });

  return [
    new Style({
      image,
      text: annotationTextStyleFunction(feature),
    }),
    userIconStyleFunction(),
  ];
};

export const projectionIconStyleFunction = (feature: Feature) => {
  const image = new CircleStyle({
    radius: 4,
    fill: new Fill({ color: '#00ff00' }),
    stroke: new Stroke({ color: '#00ff00', width: 1.25 }),
  });

  return new Style({
    image,
    text: annotationTextStyleFunction(feature),
  });
};

export const userIconStyleFunction = () => {
  const icon = new Icon({
    anchor: [0.485, 0.5],
    src: infoIcon,
    scale: 0.3,
  });

  return new Style({
    image: icon,
  });
};

export const annotationTextStyleFunction = (feature: Feature): TextStyle => {
  return new TextStyle({
    text: feature.getProperties()?.data || '',
    textBaseline: 'bottom',
    padding: [0, 0, 0, 10],
    font: 'bold 12px sans-serif',
    stroke: new Stroke({
      color: '#fff',
      width: 2,
    }),
  });
};

export const getAnnotationLayer = (data: AnnotationData): VectorLayer => {
  const featureList = (data?.annotations || []).map((a: ImageAnnotation) => {
    const { x, y } = a.center;
    const point = new Point([x, y]);
    const feature = new Feature(point);

    feature.setProperties({
      data: a.content,
    });

    return feature;
  });

  const source = new VectorSource({
    wrapX: false,
    features: featureList,
  });

  if (
    data?.annotations.length > 0 &&
    data?.annotations[0].source === PIXEL_SOURCE.system
  ) {
    return new VectorLayer({
      source,
      style: projectionIconStyleFunction,
      zIndex: ANNOTATION_Z_INDEX,
    });
  }

  return new VectorLayer({
    source,
    style: annotationStyleFunction,
    zIndex: ANNOTATION_Z_INDEX,
  });
};

export const ImageAnnotationControl: React.FC<ImageAnnotationProps> = ({
  olMap,
  annotationsData,
}) => {
  const [annotationLayer, setAnnotationLayer] = useState<
    VectorLayer | undefined
  >();

  useEffect(() => {
    if (olMap && annotationLayer) {
      olMap.addLayer(annotationLayer);
    }

    return () => {
      if (olMap && annotationLayer) {
        olMap.removeLayer(annotationLayer);
      }
    };
  }, [olMap, annotationLayer]);

  useEffect(() => {
    setAnnotationLayer(getAnnotationLayer(annotationsData));
  }, [olMap, annotationsData]);

  return <React.Fragment key="image-annotations-control" />;
};
