// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PotreeInset_slider_container__PAvvA{z-index:1;position:absolute;bottom:10px;left:10%;width:80%}.PotreeInset_slider_container__PAvvA .PotreeInset_caption__C4Cio{font-size:small;margin-bottom:-15px;text-align:center;background-color:rgba(255,255,255,.2196078431);margin-left:5px;margin-right:5px}", "",{"version":3,"sources":["webpack://./src/components/View/ImageView/PotreeInset/index.module.scss"],"names":[],"mappings":"AAAA,qCACE,SAAA,CACA,iBAAA,CACA,WAAA,CACA,QAAA,CACA,SAAA,CAEA,iEACE,eAAA,CACA,mBAAA,CACA,iBAAA,CACA,8CAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".slider_container {\n  z-index: 1;\n  position: absolute;\n  bottom: 10px;\n  left: 10%;\n  width: 80%;\n\n  .caption {\n    font-size: small;\n    margin-bottom: -15px;\n    text-align: center;\n    background-color: rgba(255, 255, 255, 0.2196078431372549);\n    margin-left: 5px;\n    margin-right: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider_container": "PotreeInset_slider_container__PAvvA",
	"caption": "PotreeInset_caption__C4Cio"
};
export default ___CSS_LOADER_EXPORT___;
