import * as React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { AccessDenied } from '../DroneAnimation/DroneAnimationStatic';
import Loading from '../Loading';
import styles from './index.module.scss';
import { Button } from '../Button';
import { hasAdminAccess, undefinedOrNull } from '../../utils/functs';
import { convertArea } from '../../utils/helpers';
import { aoiListingPageUrl } from '../../routes/urls';
import { EditAoiPropsTypes, EditAoiStateTypes } from './index.types';
import AoiV2Apis from '../../api/aoiV2';
import ProjectsAPI from '../../api/projects';
import { log } from '../../utils/log';
import { NullOrGenericObjectType, GenericObjectType } from '../../shapes/app';
import ModalNotification from '../ModalNotification/ModalNotification';
import { BASE_CAPI_URL } from '../../constants/urls';
import BoundaryEditor from '../CreateAOI/BoundaryEditor';

const { Title } = Typography;
const aoiV2Apis = new AoiV2Apis();
const projectApis = new ProjectsAPI();

class EditAoi extends React.Component<EditAoiPropsTypes, EditAoiStateTypes> {
  public constructor(props: EditAoiPropsTypes) {
    super(props);
    this.state = {
      name: '',
      aoiArea: 0,
      unit: '',
      showArea: false,
      showToggleAOIHideConfirmModal: false,
      aoisBoundaryDataList: [],
      aoiUploadError: null,
      aoisDataList: null,
    };

    this.submit = this.submit.bind(this);
    this.nameChanged = this.nameChanged.bind(this);
  }

  public componentDidMount(): void {
    const { aoiId, projectId, fetchAoiBoundary, aoi, project } = this.props;

    this.fetchAois();
    this.handleProject(aoi, project, projectId);

    fetchAoiBoundary(projectId, aoiId);
  }

  public UNSAFE_componentWillReceiveProps({
    aoi: nextAoi,
    project: nextProject,
    projectId: nextProjectId,
  }: EditAoiPropsTypes): void {
    const { project } = this.props;

    if (nextProject && nextProject !== project) {
      this.handleProject(nextAoi, nextProject, nextProjectId);
    }
  }

  private fetchAois = () => {
    const { projectId } = this.props;

    aoiV2Apis.listAois(projectId).then((res) => {
      const { error: apiError, data: apiData } = res;

      // console.log(res.data);

      if (apiError) {
        log.error(apiError, 'CreateAOI.fetchAois');

        return;
      }

      this.setState(
        {
          aoisDataList: apiData,
        },
        this.fetchAllAoiBoundaries
      );
    });
  };

  private fetchAllAoiBoundaries = async () => {
    const { projectId } = this.props;
    const { aoisDataList } = this.state;

    const promisifiedResult: Promise<GenericObjectType | null>[] = [];
    const results: GenericObjectType[] = [];

    if (!aoisDataList) {
      return;
    }

    aoisDataList
      .filter((a) => a.defaultAOI === false)
      .map((a) => {
        promisifiedResult.push(
          new Promise((resolve) => {
            aoiV2Apis.getAoiBoundary(projectId, a.id).then((res) => {
              const { error: apiError, data: apiData } = res;

              if (apiError) {
                log.error(apiError, 'CreateAOI.fetchAllAoiBoundaries');

                return resolve(null);
              }

              results.push(apiData);

              return resolve(apiData);
            });
          })
        );

        return promisifiedResult;
      });

    await Promise.all(promisifiedResult);

    this.setState({
      aoisBoundaryDataList: results || [],
    });
  };

  private handleProject = async (
    aoi: NullOrGenericObjectType,
    project: NullOrGenericObjectType,
    projectId: string
  ) => {
    const { fetchProject } = this.props;
    const { showArea, unit } = this.state;

    if (!project || !aoi) {
      fetchProject(projectId);

      return;
    }

    const { epsgCode } = project;
    let _showArea = showArea;
    let _unit = unit;
    let epsgData = null;

    if (!undefinedOrNull(epsgCode) && epsgCode !== '') {
      epsgData = await this.fetchEpsgData(epsgCode);

      if (!undefinedOrNull(epsgData)) {
        _showArea = true;
        _unit = epsgData.unit;
      }
    } else {
      _showArea = true;
    }

    this.setState(() => {
      return {
        name: aoi.name,
        aoiArea: aoi.areaSquareMetres,
        showArea: _showArea,
        unit: _unit,
      };
    });
  };

  private fetchEpsgData = (epsgId: string) => {
    return aoiV2Apis.getEpsgData(epsgId).then((res) => {
      if (res.error) {
        log.error(res.error, 'EditAoi.fetchEpsgData');

        return null;
      }

      return res.data;
    });
  };

  public nameChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ name: event.target.value });
  };

  private handleAoiArea = (aoiArea: number) => {
    this.setState({ aoiArea });
  };

  private onBoundaryChange = (contents: any) => {
    this.setState({ boundary: contents });
  };

  private submit = (): void => {
    const { projectId, aoiId, aoi, fetchAoiBoundary, showSnackbar } =
      this.props;
    const { name, boundary } = this.state;

    if ((aoi && name && aoi.name !== name) || boundary) {
      this.setState({ updating: true });
      projectApis
        .updateAoi(projectId, aoiId, { name, boundaryFileContents: boundary })
        .then(() => {
          fetchAoiBoundary(projectId, aoiId);
          this.fetchAois();
        })
        .catch((err) => {
          this.setState({ aoiUploadError: err });
          // log.error(`could not update boundary: ${err}`);
        })
        .finally(() => {
          showSnackbar({
            type: 'success',
            isModal: false,
            body: 'The AOI has been updated successfully.',
          });
          this.setState({ updating: false });
        });
    }
  };

  private toggleHideAOI = () => {
    this.setState({
      showToggleAOIHideConfirmModal: true,
    });
  };

  private toggleHideAOIConfirmModalClose = (confirm: boolean) => {
    const { projectId, aoiId, aoi, history, fetchProject } = this.props;

    if (confirm && aoi) {
      projectApis.hideAoi(projectId, aoiId, !aoi.deleted).then(() => {
        fetchProject(projectId);
        history.push(`/project/${projectId}`);
      });
    }
  };

  public render(): React.ReactNode {
    const { project, aoiId, projectId, user, aoi } = this.props;
    const {
      name,
      boundary,
      aoiArea,
      unit,
      showArea,
      showToggleAOIHideConfirmModal,
      aoisBoundaryDataList,
      aoiUploadError,
      updating,
    } = this.state;

    if (!project || !aoi) {
      return <Loading />;
    }

    if (!hasAdminAccess(user, projectId)) {
      return <AccessDenied />;
    }

    const { epsgCode } = project;

    return (
      <div className={styles.container}>
        <div className={styles.formcont}>
          {aoiUploadError ? (
            <ModalNotification
              notificationTitle="Error while updating AOI"
              shownotificationModal={!!aoiUploadError}
              error="Could not update AOI. Please try again later or contact support@aspecscire.com"
              handleModalClose={() => {
                this.setState({ aoiUploadError: null });
              }}
            />
          ) : null}
          <Title level={3} className={styles.title}>
            Edit AOI
            {!aoi.defaultAOI ? (
              <Button
                className={styles.deleteBtn}
                text={aoi.deleted ? 'Show' : 'Hide'}
                onClick={this.toggleHideAOI}
              />
            ) : null}
          </Title>
          <input
            type="text"
            placeholder="Enter AOI Name"
            value={name}
            onChange={this.nameChanged}
          />
          <div className={styles.aoiBoundaryDiv}>
            <Title level={4} className={styles.title}>
              AOI Boundary
              <Button
                className={styles.downloadBoundaryBtn}
                disabled={!aoi.boundaryId}
                type="secondary"
                text="Download Boundary"
                href={`${BASE_CAPI_URL}/v2/projects/${projectId}/aois/${aoiId}/boundary?download=true`}
                onClick={() => {}}
              />
            </Title>
          </div>

          {aoisBoundaryDataList && (
            <BoundaryEditor
              onBoundaryChange={this.onBoundaryChange}
              computePolygonArea={this.handleAoiArea}
              hideUpload={false}
              projectId={projectId}
              aoiId={aoiId}
              project={project}
              useAoiBoundary
              createProject={false}
              polygonsDataList={aoisBoundaryDataList}
              currentParent="CreateAOI"
            />
          )}
          {!undefinedOrNull(aoiArea) && aoiArea !== 0 && showArea ? (
            <div className={styles.areaContainer}>
              <label>Area :</label>
              <span className={styles.area}>
                {epsgCode !== '' && (unit === 'usFT' || unit === 'foot')
                  ? convertArea(aoiArea, 'feet')
                  : convertArea(aoiArea, 'meter')}
                &nbsp; &nbsp;
                <span>(</span>
                {convertArea(aoiArea, 'acre')}
                <span>)</span>
              </span>
            </div>
          ) : null}
          <div className={styles.bottombtn}>
            <Link
              className={styles.backBtn}
              to={aoiListingPageUrl(projectId, aoiId)}
            >
              <Button text="Back" type="secondary" />
            </Link>

            {(aoi && name && aoi.name !== name) || boundary ? (
              <Button
                text="Done"
                onClick={() => this.submit()}
                loading={updating}
                loadingText="Updating area of interest..."
              />
            ) : (
              <Button
                disabled
                text="Done"
                onClick={() => {
                  this.submit();
                }}
                loading={updating}
                loadingText="Updating area of interest..."
              />
            )}
          </div>
        </div>
        {showToggleAOIHideConfirmModal ? (
          <ModalNotification
            shownotificationModal
            notificationTitle={
              aoi.deleted ? 'Confirm Showing AOI' : 'Confirm Hiding AOI'
            }
            notificationBody={
              aoi.deleted
                ? 'Are you sure you want to show the AOI? Non-admin users will be able to see the AOI.'
                : 'Are you sure you want to hide the AOI? Non-admin users will not be able to see the AOI anymore.'
            }
            isConfirm
            handleModalClose={this.toggleHideAOIConfirmModalClose}
          />
        ) : null}
      </div>
    );
  }
}

export default EditAoi;
