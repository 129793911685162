import * as React from 'react';
import axios from 'axios';
import { FormattedNumber } from 'react-intl';
import { GenericApisReturnTypes, GenericObjectType } from '../shapes/app';
import { flattenArray, getTime } from './functs';
import { log } from './log';
import { DEMColorMapping } from '../api/views.types';
import { MAX_NO_COLORS_IN_SCALE } from '../constants/colors';

export type UnitType =
  | 'meter'
  | 'feet'
  | 'hectare'
  | 'acre'
  | 'yard'
  | 'celsius'
  | 'fahrenheit';

export function convertVolume(
  meterValue: number,
  unit: UnitType = 'meter'
): any {
  if (unit === 'meter') {
    const val = parseFloat(meterValue.toFixed(2));

    return (
      <span>
        <FormattedNumber value={val} />
        &nbsp;m
        <sup>3</sup>
      </span>
    );
  }

  if (unit === 'feet') {
    const val = parseFloat((meterValue * 35.3147).toFixed(2));

    return (
      <span>
        <FormattedNumber value={val} />
        &nbsp;ft
        <sup>3</sup>
      </span>
    );
  }

  if (unit === 'yard') {
    const val = parseFloat((meterValue * 1.30795).toFixed(2));

    return (
      <span>
        <FormattedNumber value={val} />
        &nbsp;yd
        <sup>3</sup>
      </span>
    );
  }

  return null;
}

export function convertArea(meterValue: number, unit: UnitType = 'meter'): any {
  if (unit === 'meter') {
    const val = parseFloat(meterValue.toFixed(2));

    return (
      <span>
        <FormattedNumber value={val} />
        &nbsp;m
        <sup>2</sup>
      </span>
    );
  }

  if (unit === 'feet') {
    const val = parseFloat((meterValue * 10.7639).toFixed(2));

    return (
      <span>
        <FormattedNumber value={val} />
        &nbsp;ft
        <sup>2</sup>
      </span>
    );
  }

  if (unit === 'hectare') {
    const val = parseFloat((meterValue / 10000).toFixed(2));

    return (
      <span>
        <FormattedNumber value={val} />
        &nbsp;hectare
      </span>
    );
  }

  if (unit === 'acre') {
    const val = parseFloat((meterValue * 0.0002471).toFixed(2));

    return (
      <span>
        <FormattedNumber value={val} />
        &nbsp;acres
      </span>
    );
  }

  return null;
}

export function fahrenheitDiff(meterValue: number, unit: UnitType): any {
  if (unit === 'fahrenheit') {
    const val = parseFloat((meterValue * 1.8).toFixed(2));

    return (
      <span>
        <FormattedNumber value={val} />
        &nbsp;°F
      </span>
    );
  }
}

export function convertLength(meterValue: number, unit: UnitType): any {
  if (unit === 'meter') {
    const val = parseFloat(meterValue.toFixed(2));

    return (
      <span>
        <FormattedNumber value={val} />
        &nbsp;m
      </span>
    );
  }

  if (unit === 'feet') {
    const val = parseFloat((meterValue * 3.28084).toFixed(2));

    return (
      <span>
        <FormattedNumber value={val} />
        &nbsp;ft
      </span>
    );
  }

  if (unit === 'celsius') {
    const val = parseFloat(meterValue.toFixed(2));

    return (
      <span>
        <FormattedNumber value={val} />
        &nbsp;°C
      </span>
    );
  }

  if (unit === 'fahrenheit') {
    const val = parseFloat((meterValue * 1.8 + 32).toFixed(2));

    return (
      <span>
        <FormattedNumber value={val} />
        &nbsp;°F
      </span>
    );
  }

  return null;
}

export function convertLengthValue(
  meterValue: number,
  unit: 'meter' | 'feet' = 'meter'
): number {
  if (unit === 'feet') {
    const val = parseFloat((meterValue * 3.28084).toFixed(2));

    return val;
  }

  // default to meter
  const val = parseFloat(meterValue.toFixed(2));

  return val;
}

/**
 * Function to get the 3D distance between 2 points
 * @param {number[]} p1 The first 3D point
 * @param {number[]} p2 The second 3D point
 * @returns {number} The 3D distance between the 2 points
 */
export function getPointDistance3D(p1: number[], p2: number[]): number {
  if (p1.length !== 3)
    throw new TypeError(
      `Expected P1 to have length 3. P1 length: ${p1.length}`
    );
  if (p2.length !== 3)
    throw new TypeError(
      `Expected P2 to have length 3. P2 length: ${p2.length}`
    );

  return Math.sqrt(
    (p2[0] - p1[0]) ** 2 + (p2[1] - p1[1]) ** 2 + (p2[2] - p1[2]) ** 2
  );
}

/**
 * Function to calculate 3D Distance along a linestring.
 * @param {number[][]} points The 3D points that make up the linestring
 * @returns {number} The 3D disance represented by the points along the linestring
 */
export function getDistance3D(points: number[][]): number {
  let distance: number = 0.0;

  points.slice(1).forEach((point, idx) => {
    distance += getPointDistance3D(points[idx], point);
  });

  return distance;
}

export const getPlanTypeLabel = (type: string): string => {
  if (!type) {
    return '';
  }

  if (type === 'EXTERNAL_360') {
    return '360º PANORAMA';
  }

  return type;
};

export const processErrorHandling = ({
  error,
  errorText = `There was an unexpected error!`,
  isArray = false,
}: {
  error?: GenericObjectType | null;
  errorText?: string;
  isArray?: boolean;
}): GenericApisReturnTypes => {
  const _error = `${errorText} Please try again later, or contact support@aspecscire.com for further assistance!`;
  const status = error?.response?.status ?? null;
  const errorMessage = error?.response?.data.message ?? null;
  const errorData = error?.response?.data ?? null;
  const networkError = !error?.response?.status
    ? 'Unable to access the network'
    : null;

  if (error && axios.isCancel(error)) {
    return {
      error: 'The network request was cancelled by the user',
      errorMessage: null,
      errorData,
      data: isArray ? [] : {},
      isCancelled: true,
    };
  }

  log.error(
    errorMessage,
    "Network error encountered. This error won't show up in the production build."
  );

  return {
    error: _error,
    errorMessage,
    errorData,
    data: isArray ? [] : {},
    status,
    networkError,
  };
};

export const sortByDate = (
  arrayList: GenericObjectType[],
  compareField: string,
  desc = false
): GenericObjectType[] => {
  arrayList.sort((a: any, b: any) => {
    if (desc) {
      return getTime(b[compareField]) - getTime(a[compareField]);
    }

    return getTime(a[compareField]) - getTime(b[compareField]);
  });

  return arrayList;
};

export const sortByRelativeDate = (
  sortedArray1: GenericObjectType[],
  array1CompareField: string,
  array2CompareField: string,
  array2Desc: boolean = false
): GenericObjectType[] => {
  const sortedByArray1Tree = {};

  for (let i = 0; i < sortedArray1.length; i += 1) {
    const item = sortedArray1[i];

    if (!sortedByArray1Tree[item[array1CompareField]]) {
      sortedByArray1Tree[item[array1CompareField]] = [];
    }

    sortedByArray1Tree[item[array1CompareField]].push(item);
  }

  const sortedByArray2Tree = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(sortedByArray1Tree)) {
    const itemList = sortedByArray1Tree[key];

    sortedByArray2Tree.push(
      sortByDate(itemList, array2CompareField, array2Desc)
    );
  }

  return flattenArray(sortedByArray2Tree);
};

export const _sortByName = (
  a: GenericObjectType,
  b: GenericObjectType,
  compareField: string
) => {
  if (a[compareField] < b[compareField]) {
    return -1;
  }

  if (a[compareField] > b[compareField]) {
    return 1;
  }

  return 0;
};

export const sortByName = (arrayList: any[], compareField: string): any[] => {
  return arrayList.sort((a: any, b: any) => {
    return _sortByName(a, b, compareField);
  });
};

export const sortByRelativeValue = (
  sortedArray1: GenericObjectType[],
  array1CompareField: string,
  array2CompareField: string
): GenericObjectType[] => {
  const sortedByArray1Tree = {};

  for (let i = 0; i < sortedArray1.length; i += 1) {
    const item = sortedArray1[i];

    if (!sortedByArray1Tree[item[array1CompareField]]) {
      sortedByArray1Tree[item[array1CompareField]] = [];
    }

    sortedByArray1Tree[item[array1CompareField]].push(item);
  }

  const sortedByArray2Tree = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(sortedByArray1Tree)) {
    const itemList = sortedByArray1Tree[key];

    sortedByArray2Tree.push(sortByName(itemList, array2CompareField));
  }

  return flattenArray(sortedByArray2Tree);
};

export const getValidGeoJson = (
  polygon: GenericObjectType,
  properties?: GenericObjectType
): GenericObjectType => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: { ...properties },
      geometry: {
        ...polygon,
      },
    },
  ],
});

export const thinColorMapping = (
  colorMapping: DEMColorMapping[]
): DEMColorMapping[] => {
  let thinColorMapping: DEMColorMapping[] = [];

  if (colorMapping.length > MAX_NO_COLORS_IN_SCALE) {
    const stepSize = Math.ceil(colorMapping.length / MAX_NO_COLORS_IN_SCALE);
    const interArray = colorMapping.slice(stepSize, -1);

    thinColorMapping.push(colorMapping[0]);

    for (let i = 0; i < interArray.length; i += stepSize) {
      thinColorMapping.push(interArray[i]);
    }

    thinColorMapping.push(colorMapping[colorMapping.length - 1]);
  } else {
    thinColorMapping = colorMapping;
  }

  return thinColorMapping;
};

export const getViewSourceUrlType = (
  url: string | null
): 'vimana' | 'mapbox' | null => {
  const defaultUrl = 'mapbox';

  if (!url) {
    return null;
  }

  const vimanaTileUrlProtocol = 'vimana://';
  const split = url.match(vimanaTileUrlProtocol);

  const isVimanaProtol = !!(split && split.index === 0);

  if (isVimanaProtol) {
    return 'vimana';
  }

  return defaultUrl;
};

export const debounce = (fn: any): ((e: any) => void) => {
  // Setup a timer
  let timeout: number;

  // Return a function to run debounced
  return (e: any) => {
    // If there's a timer, cancel it
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(() => {
      fn(e);
    });
  };
};
