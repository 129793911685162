import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SiteWalkthroughView from '.';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { SiteWalkthroughViewPropsTypes } from './index.types';

const mapStateToProps = (): {} => {
  return {};
};

const mapDispatchToProps = (dispatch: any): {} => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
    },
    dispatch
  );
};

const SiteWalkthroughViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteWalkthroughView);

export default React.forwardRef((props: SiteWalkthroughViewPropsTypes, ref) => (
  <SiteWalkthroughViewContainer {...props} forwardRef={ref} />
)) as any;
