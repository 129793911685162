import ApiBase from './ApiBase';
import {
  Organisation,
  OrganisationUser,
  EditOrganisationRequest,
  AddUserRequest,
  DemoProject,
  OrganisationDemoProject,
  AddOrganisationDemoProjectRequest,
} from './organisations.types';

export default class OrganisationAPI extends ApiBase {
  public listUserOrganisations(
    fetchAll: boolean = false
  ): Promise<Organisation[]> {
    return this.get(`/v1/organisations?all=${fetchAll}`).then((res) => {
      return res.data;
    });
  }

  public getOrganisation(orgId: string): Promise<Organisation> {
    return this.get(`/v1/organisations/${orgId}`).then((res) => {
      return res.data;
    });
  }

  public editOrganisation(
    orgId: string,
    editOrgRequest: EditOrganisationRequest
  ): Promise<Organisation> {
    return this.patch(`/v1/organisations/${orgId}`, editOrgRequest).then(
      (res) => {
        return res.data;
      }
    );
  }

  public getUsersInOrganisation(orgId: string): Promise<OrganisationUser[]> {
    return this.get(`/v1/organisations/${orgId}/users`).then((res) => {
      return res.data;
    });
  }

  public addUserToOrg(
    orgId: string,
    addUserRequest: AddUserRequest
  ): Promise<OrganisationUser[]> {
    return this.post(`/v1/organisations/${orgId}/users`, addUserRequest).then(
      (res) => {
        return res.data;
      }
    );
  }

  public removeUserFromOrganisation(
    orgId: string,
    userId: string
  ): Promise<OrganisationUser[]> {
    return this.delete(`/v1/organisations/${orgId}/users/${userId}`).then(
      (res) => {
        return res.data;
      }
    );
  }

  public getOwnedProjects(orgId: string): Promise<DemoProject[]> {
    return this.get(`/v1/organisations/${orgId}/demoprojects/owned`).then(
      (res) => {
        return res.data;
      }
    );
  }

  public getDemoProjectsOfOrganisation(
    orgId: string
  ): Promise<OrganisationDemoProject[]> {
    return this.get(`/v1/organisations/${orgId}/demoprojects`).then((res) => {
      return res.data;
    });
  }

  public addDemoProjectToOrganisation(
    orgId: string,
    addDemoProjectRequest: AddOrganisationDemoProjectRequest
  ): Promise<OrganisationDemoProject> {
    return this.post(
      `/v1/organisations/${orgId}/demoprojects`,
      addDemoProjectRequest
    ).then((res) => {
      return res.data;
    });
  }

  public removeDemoProjectToOrganisation(
    orgId: string,
    demoProjectId: string
  ): Promise<OrganisationDemoProject[]> {
    return this.delete(
      `/v1/organisations/${orgId}/demoprojects/${demoProjectId}`
    ).then((res) => {
      return res.data;
    });
  }
}
