import React from 'react';
import { Modal } from 'antd';
import classnames from 'classnames';
import { ReportPreviewModalProps } from './index.types';
import styles from './index.module.scss';
import { BASE_CAPI_URL } from '../../../constants/urls';
import Loading from '../../Loading';
import Icon from '../../Icon';

interface ReportPreviewModalState {
  loading: boolean;
}

export default class ReportPreviewModal extends React.PureComponent<
  ReportPreviewModalProps,
  ReportPreviewModalState
> {
  constructor(props: ReportPreviewModalProps) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  hideSpinner = () => {
    this.setState({
      loading: false,
    });
  };

  private getReportPdfUrl = (): string => {
    const { pageConfig } = this.props;
    const { projectId, aoiId, tagId, showResolved } = pageConfig;
    const params: string[][] = Object.entries({
      aoiId,
      tagId,
      resolved: showResolved,
    }).filter(([_, value]) => value !== undefined) as string[][];
    const searchParams = new URLSearchParams(params);

    return `${BASE_CAPI_URL}/v1/issues/project/${projectId}/pdf?${searchParams.toString()}`;
  };

  printIframe() {
    (window.frames as any).reportPreviewIframe.focus();
    (window.frames as any).reportPreviewIframe.contentWindow.print();
  }

  public render() {
    const { closeReportPreviewModal, showModal } = this.props;
    const { loading } = this.state;

    return (
      <Modal
        title={
          <p>
            Issues Report{' '}
            <button
              style={{
                float: 'right',
                marginRight: '1em',
                background: 'transparent',
              }}
              onClick={this.printIframe}
            >
              <Icon name="pdf_download" />
            </button>
          </p>
        }
        visible={showModal}
        footer={null}
        onCancel={closeReportPreviewModal}
        className={styles.container}
        maskClosable={false}
      >
        <div>
          {loading ? (
            <div className={classnames('center', styles.text)}>
              <Loading />
              <br /> This may take a few minutes ....
            </div>
          ) : null}

          <iframe
            id="reportPreviewIframe"
            title="Issue Report"
            src={this.getReportPdfUrl()}
            onLoad={this.hideSpinner}
            className={styles.preview}
          />
        </div>
      </Modal>
    );
  }
}
