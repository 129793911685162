import ApiBase from './ApiBase';
import { GenericApisReturnPromiseTypes } from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_CAPI_URL } from '../constants/urls';

export default class AoiV2Apis extends ApiBase {
  private readonly _baseUrl: string;

  private readonly _baseUrlV2: string;

  public constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = `/v1`;
    this._baseUrlV2 = `/v2`;
  }

  public getEpsgData(epsgId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/coords/${epsgId}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the EPSG data.`,
        });
      });
  }

  public searchEpsgCodes(searchText: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/coords?search=${searchText}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to search the EPSG codes.`,
          isArray: true,
        });
      });
  }

  public listProjectTypes(): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/info/projectTypes`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the project types.`,
          isArray: true,
        });
      });
  }

  public listAois(projectId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrlV2}/projects/${projectId}/aois`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the AOIs.`,
          isArray: true,
        });
      });
  }

  public getAoiBoundary(
    projectId: string,
    aoiId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(
      `${this._baseUrlV2}/projects/${projectId}/aois/${aoiId}/boundary`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the AOI boundary.`,
          isArray: true,
        });
      });
  }
}
