import * as React from 'react';
import classnames from 'classnames';
import { Redirect } from 'react-router';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Typography } from 'antd';
import ViewAPIs from 'src/api/views';
import { Button } from '../../Button';
import styles from './index.module.scss';
import { appFormatDate } from '../../../utils/date';
import SkeletonLoader from '../../SkeletonLoader';
import {
  isNumeric,
  toMetersEquivalent,
  undefinedOrNull,
  undefinedOrNullChained,
} from '../../../utils/functs';
import ContourMapsApis from '../../../api/contourMaps';
import { ContourMapsApisReturnPromiseTypes } from '../../../shapes/contourMaps';
import View from '../../View';
import { resetBodyOverFlow, setBodyOverflow } from '../../../utils/window';
import HelpTooltip from '../../HelpTooltip/HelpTooltip';
import ModalNotification from '../../ModalNotification/ModalNotification';
import { DATA_AUTO_POLLING_TIMEOUT } from '../../../constants';
import { MapGeneratedStatus } from '../../MapGeneratedStatus';
import LoadingOverlay from '../../LoadingOverlay';
import { ContourMapsCrudProps, ContourMapsCrudState } from './index.types';

const SelectOption = Select.Option;
const { Title } = Typography;
const contourMapsApis = new ContourMapsApis();
const viewApis = new ViewAPIs();

class ContourMapsCrud extends React.Component<
  ContourMapsCrudProps,
  ContourMapsCrudState
> {
  public constructor(props: ContourMapsCrudProps) {
    super(props);

    this.state = {
      contourData: null,
      selectedDtm: null,
      generateBtnLoading: false,
      finalizeBtnLoading: false,
      newContourCreated: false,
      isMapFullScreen: false,
      showFinalizeConfirmModal: false,
      showGenerateConfirmModal: false,
    };
  }

  public componentDidMount(): void {
    const { actionType } = this.props;

    if (actionType === 'edit') {
      this.fetchContour();
    }
  }

  public UNSAFE_componentWillUpdate(
    nextProps: Readonly<ContourMapsCrudProps>,
    { contourData: nextContourData }: Readonly<ContourMapsCrudState>
  ): void {
    const { actionType } = this.props;

    if (
      actionType === 'edit' &&
      nextContourData &&
      Object.keys(nextContourData).length > 0 &&
      !nextContourData.finalized &&
      ['pending', 'started', 'starting'].indexOf(nextContourData.status) > -1
    ) {
      // Auto polling
      setTimeout(() => {
        this.fetchContour();
      }, DATA_AUTO_POLLING_TIMEOUT);
    }
  }

  private fetchContour = () => {
    const { view } = this.state;
    const { match, showSnackbar } = this.props;
    const { projectId, aoiId, contourId } = match.params;

    contourMapsApis.getContour(projectId, aoiId, contourId).then((res) => {
      if (res.error) {
        showSnackbar({
          body: res.error,
          type: 'error',
        });

        return;
      }

      const contourData = res.data;

      if (contourData.viewId && !view) {
        viewApis
          .getView(projectId, aoiId, contourData.viewId)
          .then((viewRes) => {
            if (viewRes.error) {
              showSnackbar({
                body: viewRes.error,
                type: 'error',
              });

              return;
            }

            if (viewRes.data) {
              this.setState({ view: viewRes.data });
            }
          });
      }

      this.setState({
        contourData,
      });
    });
  };

  private getElevationInfoByKey = (key: string) => {
    const { elevationInfo } = this.props;
    const keyParts = key.split('_');

    if (!elevationInfo) {
      return null;
    }

    return (
      elevationInfo.find(
        (info) =>
          info.elevationSource === keyParts[0] && info.id === keyParts[1]
      ) || null
    );
  };

  private handleDtmDateSelect = (selectedDtmId: string): void => {
    const selectedDtm = this.getElevationInfoByKey(selectedDtmId);

    this.setState({
      selectedDtm,
    });
  };

  private processGenerateContourBtn = (): void => {
    const { form, match, showSnackbar, actionType, projectType, user } =
      this.props;
    const { contourData } = this.state;
    const { projectId, aoiId } = match.params;

    form.validateFields(
      ['contourName', 'offset', 'elevationId', 'contourInterval'],
      (err: any, values: any) => {
        if (!err) {
          const { contourInterval, contourName, offset, elevationId } = values;

          if (!undefinedOrNull(contourInterval)) {
            if (!isNumeric(contourInterval)) {
              showSnackbar({
                type: 'error',
                body: `Contour Interval must be numeric.`,
              });

              return;
            }

            if (parseFloat(contourInterval) <= 0) {
              showSnackbar({
                type: 'error',
                body: `Contour Interval must be a positive real number.`,
              });

              return;
            }
          }

          if (!undefinedOrNull(offset)) {
            if (offset.length !== 0) {
              if (!isNumeric(offset)) {
                showSnackbar({
                  type: 'error',
                  body: `Offset must be a numeric value, or empty.`,
                });

                return;
              }
            }
          }

          const elevation = this.getElevationInfoByKey(elevationId || '');

          if (!elevation) {
            showSnackbar({
              type: 'error',
              body: 'Elevation source must be specified to generate a contour.',
            });

            return;
          }

          this.setState({
            generateBtnLoading: true,
          });

          const formData = {
            // ensure name is removed from elevationInfo, before calling API
            elevationInfo: { ...elevation, name: undefined },
            name: contourName,
            parameters: {
              contourInterval: Number(contourInterval),
              offset: offset.length === 0 ? null : Number(offset),
            },
          };

          contourMapsApis
            .postContour(
              projectId,
              aoiId,
              formData,
              contourData && Object.keys(contourData).length > 0
                ? contourData.id
                : null
            )
            .then((res: ContourMapsApisReturnPromiseTypes) => {
              if (undefinedOrNull(res)) {
                showSnackbar({
                  body: `Some error occured. Try again!`,
                  type: 'error',
                });

                this.setState({
                  generateBtnLoading: false,
                });

                return;
              }

              if (res.error) {
                showSnackbar({
                  body: res.error,
                  type: 'error',
                });

                this.setState({
                  generateBtnLoading: false,
                });

                return;
              }

              if (actionType === 'edit') {
                this.setState({
                  generateBtnLoading: false,
                  newContourCreated: false,
                  contourData: res.data,
                });
              } else {
                this.setState({
                  generateBtnLoading: false,
                  newContourCreated: true,
                  contourData: res.data,
                });
              }

              let executionInfo = '';

              if (
                projectType &&
                projectType === 'POST_PROCESSING_DEMO_PROJECT' &&
                user &&
                !user.staff
              ) {
                if (
                  res.data.contourGenerationCount != null &&
                  res.data.contourGenerationLimit != null
                ) {
                  const { contourGenerationCount, contourGenerationLimit } =
                    res.data;

                  executionInfo = ` You have used up one Contour Map, and you have ${
                    contourGenerationLimit - contourGenerationCount
                  } generations left for this demo project.`;
                }
              }

              showSnackbar({
                body: `Contour map is being generated.${executionInfo} Please wait, you will receive an email when the map is generated.`,
                type: 'info',
              });
            });
        }
      }
    );
  };

  private handleFinalizeModalShow = (): void => {
    this.setState({
      showFinalizeConfirmModal: true,
    });
  };

  private handleFinalizeModalClose = (confirm: boolean): void => {
    if (confirm) {
      this.setState({
        showFinalizeConfirmModal: false,
      });

      this.handleFinalizeBtn();

      return;
    }

    this.setState({
      showFinalizeConfirmModal: false,
    });
  };

  public handleFinalizeBtn = () => {
    const { match, showSnackbar, form } = this.props;
    const { contourData } = this.state;
    const { projectId, aoiId, contourId } = match.params;

    if (!contourData) {
      showSnackbar({
        body: `Some error occured. Try again!`,
        type: 'error',
      });

      return;
    }

    form.validateFields(['contourName'], (err: any, values: any) => {
      if (!err) {
        const bodyFormData = {
          finalized: !contourData.finalized,
          name: values.contourName,
        };

        this.setState({
          finalizeBtnLoading: true,
        });

        contourMapsApis
          .updateContour(projectId, aoiId, bodyFormData, contourId)
          .then((res: ContourMapsApisReturnPromiseTypes) => {
            if (undefinedOrNull(res)) {
              showSnackbar({
                body: `Some error occured. Try again!`,
                type: 'error',
              });

              this.setState({
                finalizeBtnLoading: false,
              });

              return;
            }

            if (res.error) {
              showSnackbar({
                body: res.error,
                type: 'error',
              });

              this.setState({
                finalizeBtnLoading: false,
              });

              return;
            }

            this.setState(
              {
                finalizeBtnLoading: false,
              },
              () => {
                this.fetchContour();
              }
            );

            showSnackbar({
              body: `The Contour was ${
                !contourData.finalized ? 'published' : 'unpublished'
              } successfully`,
              type: 'success',
              isModal: false,
            });
          });
      }
    });
  };

  private getDefaultElevationDate = (): any => {
    const { elevationInfo } = this.props;
    const { contourData } = this.state;

    if (elevationInfo && contourData) {
      const { elevationInfo } = contourData;
      const selectedElevation = this.getElevationInfoByKey(
        `${elevationInfo.elevationSource}_${elevationInfo.id}`
      );

      if (selectedElevation) {
        return {
          initialValue: `${selectedElevation.elevationSource}_${selectedElevation.id}`,
        };
      }
    }

    return {};
  };

  private getDefaultContourInterval = (): number | null => {
    const { contourData } = this.state;

    if (
      contourData &&
      Object.keys(contourData).length > 0 &&
      !undefinedOrNullChained(contourData, 'parameters.contourInterval')
    ) {
      return contourData.parameters.contourInterval;
    }

    return null;
  };

  private getDefaultContourName = (): string | null => {
    const { contourData } = this.state;

    if (
      contourData &&
      Object.keys(contourData).length > 0 &&
      !undefinedOrNullChained(contourData, 'name')
    ) {
      return contourData.name;
    }

    return null;
  };

  private getDefaultOffset = (): number | null => {
    const { contourData } = this.state;

    if (
      contourData &&
      Object.keys(contourData).length > 0 &&
      !undefinedOrNullChained(contourData, 'parameters.offset')
    ) {
      return contourData.parameters.offset;
    }

    return 0;
  };

  private generateBtnName = (): string => {
    const { contourData } = this.state;

    if (!contourData || Object.keys(contourData).length < 1) {
      return 'GENERATE CONTOUR';
    }

    return 'REGENERATE CONTOUR';
  };

  private disableGenerateBtn = (): boolean => {
    const { contourData } = this.state;

    if (!contourData || Object.keys(contourData).length < 1) {
      return false;
    }

    if (contourData.finalized) {
      return true;
    }

    switch (contourData.status) {
      case 'started':
      case 'starting':
        return true;

      case 'pending':
      case 'completed':
      case 'error':
      default:
        return false;
    }
  };

  private disableFinalizeBtn = (): boolean => {
    const { contourData } = this.state;

    if (!contourData || Object.keys(contourData).length < 1) {
      return false;
    }

    switch (contourData.status) {
      case 'pending':
      case 'error':
      case 'started':
      case 'starting':
        return true;

      case 'completed':
      default:
        return false;
    }
  };

  private heading = (): string => {
    const { actionType } = this.props;

    if (actionType === 'edit') {
      return 'Edit Contour Map';
    }

    return 'New Contour Map';
  };

  private finalizeConfirmBodyText = (): string => {
    const { contourData } = this.state;

    if (contourData && contourData.finalized) {
      return `Un-publishing this Contour will remove the Contour from public Display. Are you sure you want to proceed?`;
    }

    return `Are you sure you want to publish the Contour?`;
  };

  private handleMapFullScreenToggle = (): void => {
    this.setState(
      ({ isMapFullScreen }) => {
        return {
          isMapFullScreen: !isMapFullScreen,
        };
      },
      () => {
        const { isMapFullScreen } = this.state;

        if (isMapFullScreen) {
          setBodyOverflow('y');

          return;
        }

        resetBodyOverFlow('y');
      }
    );
  };

  private validateFieldValue = (rule: any, value: any, callback: any) => {
    const { unit } = this.props;

    if (!undefinedOrNull(value)) {
      if (value !== '' && value < toMetersEquivalent(0.1, unit)) {
        callback(
          `Field value must be greater than ${toMetersEquivalent(0.1, unit)}`
        );
      }
    }

    callback();
  };

  private validateOffsetValue = (rule: any, value: any, callback: any) => {
    const { form } = this.props;

    if (!undefinedOrNull(value)) {
      if (
        value !== '' &&
        (value >= parseFloat(form.getFieldValue('contourInterval')) ||
          value <= -parseFloat(form.getFieldValue('contourInterval')))
      ) {
        callback(
          'Field value must be smaller in magnitude than contour interval'
        );
      }

      callback();
    }

    callback();
  };

  private handleShowGenerateConfirmModal = (): void => {
    this.setState(({ showGenerateConfirmModal }) => {
      return {
        showGenerateConfirmModal: !showGenerateConfirmModal,
      };
    });
  };

  private handleGenerateConfirmModal = (value: boolean): void => {
    this.setState(() => {
      return {
        showGenerateConfirmModal: false,
      };
    });

    if (!value) {
      return;
    }

    this.processGenerateContourBtn();
  };

  private generateConfirmBodyText = (): string => {
    const { contourData } = this.state;

    if (!contourData || Object.keys(contourData).length < 1) {
      return 'Are you sure you want to start Contour Map generation with these parameters?';
    }

    return `Are you sure you want to re-generate this Contour Map with these parameters? The existing Contour Map will be cleaned up.`;
  };

  private renderElevationOptions = () => {
    const { elevationInfo } = this.props;

    if (!elevationInfo) {
      return [];
    }

    return elevationInfo.map((info) => {
      const type = info.elevationSource === 'dem' ? 'Elevation' : '(Terrain)';

      return (
        <SelectOption
          key={`${info.elevationSource}_${info.id}`}
          value={`${info.elevationSource}_${info.id}`}
        >
          {info.name ? `${info.name} - ` : ''}
          {`${type} - ${appFormatDate(info.date)}`}
        </SelectOption>
      );
    });
  };

  public render(): React.ReactNode {
    const { form, match, unit, elevationInfo } = this.props;
    const {
      contourData,
      generateBtnLoading,
      newContourCreated,
      finalizeBtnLoading,
      isMapFullScreen,
      showFinalizeConfirmModal,
      showGenerateConfirmModal,
      view,
    } = this.state;
    const { getFieldDecorator } = form;
    const { projectId, aoiId } = match.params;

    if (
      newContourCreated &&
      contourData &&
      Object.keys(contourData).length > 0
    ) {
      return (
        <Redirect
          to={`/project/${projectId}/aoi/${aoiId}/ContourMaps/edit/${contourData.id}`}
        />
      );
    }

    if (!elevationInfo) {
      return (
        <div className={styles.container}>
          <SkeletonLoader className={styles.skeletonLoader} size={2} />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.headingWrapper}>
          <Title level={3}>{this.heading()}</Title>
        </div>

        <div className={styles.innerContainer}>
          <div className={styles.inputFieldContainer}>
            <Form layout="vertical">
              <div className={styles.inputFieldWrapper}>
                <Form.Item label="Name">
                  {getFieldDecorator('contourName', {
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                    initialValue: this.getDefaultContourName(),
                  })(
                    <Input
                      placeholder="Name"
                      style={{ margin: 0 }}
                      className={styles.inputField}
                      disabled={contourData ? contourData.finalized : false}
                    />
                  )}
                </Form.Item>
                <HelpTooltip
                  position="right"
                  helpText="Name to be used for public display."
                />
              </div>
              <div className={styles.inputFieldWrapper}>
                <Form.Item label="Elevation Date">
                  {getFieldDecorator('elevationId', {
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                    ...this.getDefaultElevationDate(),
                  })(
                    <Select
                      placeholder="Pick a date"
                      optionFilterProp="value"
                      className={styles.inputField}
                      onChange={this.handleDtmDateSelect}
                      disabled={contourData ? contourData.finalized : false}
                    >
                      {this.renderElevationOptions()}
                    </Select>
                  )}
                  <HelpTooltip
                    position="right"
                    helpText="Date of the Elevation source used for generating the contours."
                  />
                </Form.Item>
              </div>

              <div className={styles.inputFieldWrapper}>
                <Form.Item label={`Contour Interval (${unit})`}>
                  {getFieldDecorator('contourInterval', {
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                      {
                        validator: this.validateFieldValue,
                      },
                    ],
                    initialValue: this.getDefaultContourInterval(),
                  })(
                    <Input
                      placeholder="Contour Interval"
                      style={{ margin: 0 }}
                      className={styles.inputField}
                      type="number"
                      disabled={contourData ? contourData.finalized : false}
                    />
                  )}
                </Form.Item>
                <HelpTooltip
                  position="right"
                  helpText="Contour elevation interval, in the unit of measurement of the Coordinate System represented by the Project's EPSG Code."
                />
              </div>

              <div className={styles.inputFieldWrapper}>
                <Form.Item label={`Offset (${unit})`}>
                  {getFieldDecorator('offset', {
                    rules: [
                      {
                        required: false,
                        message: 'This field is required',
                      },
                      {
                        validator: this.validateOffsetValue,
                      },
                    ],
                    initialValue: this.getDefaultOffset(),
                  })(
                    <Input
                      placeholder="Offset"
                      style={{ margin: 0 }}
                      type="number"
                      className={styles.inputField}
                      disabled={contourData ? contourData.finalized : false}
                    />
                  )}
                </Form.Item>
                <HelpTooltip
                  position="right"
                  helpText="Offset is a numeric value which will be added to all contour values."
                />
              </div>

              <div className={styles.btnWrapper}>
                <Button
                  text={this.generateBtnName()}
                  onClick={() => {
                    this.handleShowGenerateConfirmModal();
                  }}
                  loadingText="Generating..."
                  loading={generateBtnLoading}
                  disabled={this.disableGenerateBtn()}
                  className={styles.generateBtn}
                />

                {contourData && Object.keys(contourData).length > 0 ? (
                  <div className={styles.inputFieldWrapper}>
                    <Button
                      className={styles.finalizeBtn}
                      text={contourData.finalized ? 'UNPUBLISH' : 'PUBLISH'}
                      disabled={this.disableFinalizeBtn()}
                      loading={finalizeBtnLoading}
                      loadingText={
                        contourData.finalized
                          ? 'Unpublishing...'
                          : 'Publishing...'
                      }
                      onClick={() => {
                        this.handleFinalizeModalShow();
                      }}
                    />
                    {!contourData.finalized && (
                      <HelpTooltip
                        position="right"
                        helpText="Publishing the Contour will make it available for public display."
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </Form>
          </div>

          <div className={styles.viewContainer}>
            {contourData ? (
              <div
                className={classnames(styles.postGeneratedContainer, {
                  [styles.fullScreen]: isMapFullScreen,
                })}
              >
                {contourData.status === 'error' ? (
                  MapGeneratedStatus({
                    data: contourData,
                    type: 'contour',
                    error: true,
                  })
                ) : contourData.status === 'completed' && !!view ? (
                  <div
                    className={classnames(styles.viewWrapper, {
                      [styles.fullScreen]: isMapFullScreen,
                    })}
                  >
                    <View
                      viewId={view.id}
                      projectId={view.projectId}
                      aoiId={view.aoiId}
                      onEvent={() => {}}
                      view={view}
                      history={{} as any}
                    />
                  </div>
                ) : (
                  MapGeneratedStatus({
                    data: contourData,
                    type: 'contour',
                    generating: true,
                  })
                )}
              </div>
            ) : (
              MapGeneratedStatus({
                data: contourData,
                type: 'contour',
              })
            )}
          </div>

          {showFinalizeConfirmModal ? (
            <ModalNotification
              notificationTitle="Confirm action"
              notificationBody={this.finalizeConfirmBodyText()}
              shownotificationModal
              handleModalClose={this.handleFinalizeModalClose}
              cancelButtonTitle="NO"
              okButtonTitle="YES"
              isConfirm
            />
          ) : null}

          {showGenerateConfirmModal ? (
            <ModalNotification
              notificationTitle="Confirm action"
              notificationBody={this.generateConfirmBodyText()}
              shownotificationModal
              handleModalClose={this.handleGenerateConfirmModal}
              cancelButtonTitle="NO"
              okButtonTitle="YES"
              isConfirm
            />
          ) : null}
          {generateBtnLoading || finalizeBtnLoading ? <LoadingOverlay /> : null}
        </div>
      </div>
    );
  }
}

export default Form.create<ContourMapsCrudProps>()(ContourMapsCrud);
