import { connect } from 'react-redux';
import {
  createMission,
  getDrones,
  getEPSGcodeById,
  getFlightPlans,
  getGcpPlanData,
  getGcpPlans,
  getMission,
  resetReducerState,
  searchEPSGcodeByText,
  submitMission,
  updateFlightPlan,
  updateGcpPlan,
  updateGcpPointsData,
} from '../../redux/actions/mission';
import NewMission from './NewMission';
import { SnackbarReducersStateSnackbarsTypes } from '../../shapes/snackbar';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { MissionTypes } from './index.types';

const mapStateToProps = (state: any): any => {
  return {
    missionState: state.mission,
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return {
    showSnackbar: ({ ...args }: SnackbarReducersStateSnackbarsTypes) =>
      dispatch(actionShowSnackbar({ ...args })),
    createMission: (projectId: string, aoiId: string, body: any) =>
      dispatch(createMission(projectId, aoiId, body)),
    resetReducerState: () => dispatch(resetReducerState()),
    submitMission: (
      projectId: string,
      aoiId: string,
      missionId: string,
      body: any
    ) => dispatch(submitMission(projectId, aoiId, missionId, body)),
    getMission: (projectId: string, aoiId: string, missionId: string) =>
      dispatch(getMission(projectId, aoiId, missionId)),
    getDrones: () => dispatch(getDrones()),
    getEPSGcodeById: (id: any) => dispatch(getEPSGcodeById(id)),
    searchEPSGcodeByText: (searchText: string) =>
      dispatch(searchEPSGcodeByText(searchText)),
    getFlightPlans: (projectId: string, aoiId: string, missionId: string) =>
      dispatch(getFlightPlans(projectId, aoiId, missionId)),
    updateFlightPlan: (plan: any) => dispatch(updateFlightPlan(plan)),
    getGcpPlans: (projectId: string, aoiId: string, missionId: string) =>
      dispatch(getGcpPlans(projectId, aoiId, missionId)),
    updateGcpPlan: (plan: any) => dispatch(updateGcpPlan(plan)),
    getGcpPlanData: (planId: string) => dispatch(getGcpPlanData(planId)),
    updateGcpPointsData: (newGcpPointsData: any) =>
      dispatch(updateGcpPointsData(newGcpPointsData)),
  };
};

export default connect<
  MissionTypes.ReduxState,
  MissionTypes.DispatchProps,
  MissionTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(NewMission);
