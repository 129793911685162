import * as React from 'react';
import ShapeInfo from '../MeasureControlBox/ShapeInfo';
import Unit from '../MeasureControlBox/Unit';
import { Spinner } from '../Spinner/Spinner';
import { convertLength } from '../../utils/helpers';

import style from './ElevationCalculator.module.scss';

export interface IOwnProps {
  shape: any;
  viewId: string;
}

export interface IStateProps {
  elevation?: number;
  loading: boolean;
  error: any;
}

export interface IDispatchProps {
  fetchElevation: () => void;
}

type IProps = IOwnProps & IDispatchProps & IStateProps;

class ElevationCalculator extends React.Component<IProps> {
  public componentDidMount(): void {
    const { fetchElevation } = this.props;

    fetchElevation();
  }

  private renderPoints(): any[] {
    const { elevation } = this.props;
    const elevationArray: any[] = Array.isArray(elevation)
      ? elevation
      : [elevation];

    return elevationArray.map((elevationItem: any, index: number) => {
      const elevationValue = {
        meter: convertLength(elevationItem, 'meter'),
        feet: convertLength(elevationItem, 'feet'),
      };

      return this.renderElevation(`P${index + 1}`, elevationValue, index);
    });
  }

  private renderElevation(
    label: string,
    elevation: any,
    index: number | string
  ): any {
    return (
      <React.Fragment key={index}>
        <Unit label={`${label}`}>{elevation.meter}</Unit>
        <Unit label="">{elevation.feet}</Unit>
      </React.Fragment>
    );
  }

  public render(): React.ReactNode {
    const { elevation, loading, error } = this.props;
    const elevationArray = Array.isArray(elevation) ? elevation : [elevation];

    if (loading) {
      return <Spinner />;
    }

    if (error) {
      return (
        <div className={style.container}>
          {error.message || 'Something went wrong'}
        </div>
      );
    }

    if (!elevation) {
      return null;
    }

    const elevationSize = elevationArray.length;
    const elevationDiff: any = elevationSize >= 2 && {
      meter: convertLength(
        elevationArray[elevationSize - 2] - elevation[elevationSize - 1],
        'meter'
      ),
      feet: convertLength(
        elevationArray[elevationSize - 2] - elevation[elevationSize - 1],
        'feet'
      ),
      label: `P${elevationSize - 1}-P${elevationSize}`,
    };

    return (
      <ShapeInfo scrollable>
        <div className={style.label}>
          <Unit label="Elevation" />
        </div>
        <Unit label="" />
        {this.renderPoints()}
        {elevationDiff &&
          this.renderElevation(elevationDiff.label, elevationDiff, 'diff')}
      </ShapeInfo>
    );
  }
}

export default ElevationCalculator;
