import * as React from 'react';
import moment from 'moment';
import { DownOutlined } from '@ant-design/icons';
import {
  Drawer,
  Modal,
  Tooltip,
  Typography,
  Select,
  Checkbox,
  Radio,
  Table,
  Popover,
  Tabs,
  Dropdown,
  Menu,
  Popconfirm,
  Slider,
  Tag,
  DatePicker,
} from 'antd';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Canceler, CancelTokenStatic } from 'axios';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import { Point, Polygon } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { Feature } from 'ol';
import styles from './index.module.scss';
import ProjectsAPI from '../../../api/projects';
import {
  MissionImageUploadsPropsTypes,
  MissionImageUploadsSelectedUploadImagesListTypes,
  MissionImageUploadsStateTypes,
  MissionImageUploadsStatusTypes,
  MissionImageUploadsTaskActionBtnsTypes,
  MissionImageUploadsUploadInformationTypes,
  MissionImageUploadsViewType,
  MissionRequirementsType,
  SelectedFlightPlanType,
  SelectedMissionRequirementsType,
  UploadedImagesUseInEnabledListTypes,
  UploadedImagesUseInTypes,
  UploadServiceType,
} from './index.types';
import MissionV2CapiApis from '../../../api/missionV2Capi';
import { log } from '../../../utils/log';
import SkeletonLoader from '../../SkeletonLoader';
import ModalTooltip, {
  createTooltipContainerWithZIndex,
} from '../../ModalTooltip';
import {
  filteredArrayValue,
  getBoundaryPoints,
  inArray,
  percentage,
  undefinedOrNull,
  round,
  arrayInsert,
  arrayRemoveDuplicate,
  DataFilterTypes,
  filterData,
  generateRange,
  isVimanaLite,
} from '../../../utils/functs';
import ApiBase from '../../../api/ApiBase';
import PlotGoogleMap from '../../PlotGoogleMap';
import {
  GenericObjectType,
  GenericStringType,
  NullOrGenericObjectType,
} from '../../../shapes/app';
import {
  CoordinatesListTypes,
  PlotGoogleMapDrawingManagerStore,
  PlotGoogleMapDrawManagerModesTypes,
  PlotGoogleMapMarkerPointsTypes,
} from '../../PlotGoogleMap/index.types';
import {
  APP_PRIMARY_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_COLOR,
  GOOGLE_MAP_MARKER_SELECTED_COLOR,
} from '../../../constants/colors';
import LoadingOverlay from '../../LoadingOverlay';
import { Button } from '../../Button';
import UploadImagesErrors from './UploadImagesErrors';
import MissionV2NavigatorApis from '../../../api/missionV2Navigator';
import { _sortByName, sortByName } from '../../../utils/helpers';
import {
  getDateTimeFromTimestamp,
  getDateTimeWithoutTz,
  getTimestamp,
} from '../../../utils/date';
import { checkMarkerPointsWithinBounds } from '../../PlotGoogleMap/helpers';
import ConfirmPopup from '../../ConfirmPopup';
import ViewAPIs from '../../../api/views';
import InspectionViewDetailsModal from '../InspectionViewDetailsModal';
import { getAoiFromProjectData } from '../../AoiData';
import { OSMMapContainer, VectorLayerContainer } from '../../OSMMap';
import { SatelliteTiles } from '../../OSMMap/SatelliteTiles';
import { ReadOnlyPolygon } from '../../OSMMap/ReadOnlyPolygon';
import { ReadOnlyPoints } from '../../OSMMap/ReadOnlyPoints';
import { CenterMap } from '../../OSMMap/CenterMap';
import { getGrayedOutStyle, WEB_MERCATOR, WGS84 } from '../../OSMMap/utils';
import { SelectFeatures } from '../../OSMMap/SelectFeatures';
import { DrawAndEditPolygon } from '../../OSMMap/DrawAndEditPolygon';
import { ResetControl } from '../../OSMMap/ResetControl';

const viewsApi = new ViewAPIs();
const missionV2CapiApis = new MissionV2CapiApis();
const missionV2NavigatorApis = new MissionV2NavigatorApis();
const projectsApi = new ProjectsAPI();
const apiBase = new ApiBase();
const { Text, Paragraph } = Typography;
const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const selectedMissionRequirementsTypeTextDict: {
  [key in SelectedMissionRequirementsType]: {
    option: string;
    button: string | null;
  };
} = {
  AERIAL_MAPS: {
    option: 'IMAGES FOR MAP PROCESSING',
    button: 'Map',
  },
  EXTERIOR_360: {
    option: 'IMAGES FOR EXTERNAL 360 VIEW PROCESSING',
    button: 'External 360',
  },
  PERSPECTIVE: {
    option: 'IMAGES FOR PERSPECTIVE VIEW PROCESSING',
    button: 'Perspective',
  },
  INSPECTION: {
    option: 'IMAGES FOR INSPECTION PROCESSING',
    button: 'Inspection',
  },
  THERMAL_MAP: {
    option: 'IMAGES FOR THERMAL MAP PROCESSING',
    button: 'Thermal Map',
  },
  THERMAL_INSPECTION: {
    option: 'IMAGES FOR THERMAL INSPECTION',
    button: 'Thermal Inspection',
  },
};

const flightPlanTypeDict: {
  [key in SelectedFlightPlanType]: string;
} = {
  NADIR: 'NADIR',
  EXTERNAL_360: 'EXTERNAL 360',
  PERSPECTIVE: 'PERSPECTIVE',
  INSPECTION: 'INSPECTION',
  THERMAL_NADIR: 'THERMAL NADIR',
  THERMAL_INSPECTION: 'THERMAL INSPECTION',
  ALL: 'MULTIPLE',
};

const uploadedFlightPlanTypeList: {
  [key in SelectedFlightPlanType]: { label: string };
} = {
  NADIR: {
    label: `Images from ${flightPlanTypeDict.NADIR} flight plan`,
  },
  PERSPECTIVE: {
    label: `Images from ${flightPlanTypeDict.PERSPECTIVE} flight plan`,
  },
  EXTERNAL_360: {
    label: `Images from ${flightPlanTypeDict.EXTERNAL_360} flight plan`,
  },
  INSPECTION: {
    label: `Images from ${flightPlanTypeDict.INSPECTION} flight plan`,
  },
  THERMAL_NADIR: {
    label: `Images from ${flightPlanTypeDict.THERMAL_NADIR} flight plan`,
  },
  THERMAL_INSPECTION: {
    label: `Images from ${flightPlanTypeDict.THERMAL_INSPECTION} flight plan`,
  },
  ALL: {
    label: `Images from ${flightPlanTypeDict.ALL.toLowerCase()} flight plans`,
  },
};

const requirementsListToUseInDict: {
  [key in MissionRequirementsType]: UploadedImagesUseInTypes;
} = {
  AERIAL_MAPS: 'useInMaps',
  PERSPECTIVE: 'useInPerspective',
  EXTERIOR_360: 'useInPanorama',
  INSPECTION: 'useInInspection',
  THERMAL_MAP: 'useInThermalMap',
  THERMAL_INSPECTION: 'useInThermalInspection',
};

const requirementsListToFlightPlanDict: {
  [key in MissionRequirementsType]: SelectedFlightPlanType;
} = {
  AERIAL_MAPS: 'NADIR',
  PERSPECTIVE: 'PERSPECTIVE',
  EXTERIOR_360: 'EXTERNAL_360',
  INSPECTION: 'INSPECTION',
  THERMAL_MAP: 'THERMAL_NADIR',
  THERMAL_INSPECTION: 'THERMAL_INSPECTION',
};

const pageLimitOptionsList = [10, 50, 100];

const dataTableExifDict = {
  originalFilename: 'Image Name',
  capturedAtTimestamp: 'Timestamp',
  capturedAt: 'Timestamp',
  forMaps: 'Maps',
  forThermalMap: 'Thermal Map',
  forPerspective: 'Perspective',
  for360: 'External 360',
  forSiteNav: 'Site-Navigation',
  forInspection: 'Inspection',
  forThermalInspection: 'Thermal Inspection',
  gimbalYaw: 'Yaw',
  gimbalPitch: 'Pitch',
  latitudeLongitude: 'Latitude/Longitude',
  latitudeLongitudeUntrimmed: 'Latitude/Longitude',
  elevation: 'Elevation',
  fNumber: 'F-Stop',
  exposureTime: 'Exposure',
  isoSpeed: 'ISO',
  thermalImage: 'Thermal Image',
};

const mapExifDict = {
  flightSourceType: 'Flight Source',
  artifactType: 'Artifact Type',
  elevation: 'Elevation',
  latitude: 'Latitude',
  longitude: 'Longitude',
  gimbalPitch: 'Gimbal Pitch',
  gimbalRoll: 'Gimbal Roll',
  gimbalYaw: 'Gimbal Yaw',
  imageWidth: 'Image Width',
  imageHeight: 'Image Height',
  verticalResolution: 'Vertical Resolution',
  horizontalResolution: 'Horizontal Resolution',
  cameraMake: 'Camera Make',
  cameraModel: 'Camera Model',
  aperture: 'Aperture',
  exposureTime: 'Exposure Time',
  isoSpeed: 'ISO Speed',
  focalLength: 'Focal Length',
  type: 'Type',
};

export default class UploadImages extends React.Component<
  MissionImageUploadsPropsTypes,
  MissionImageUploadsStateTypes
> {
  private IMAGE_UPLOAD_FAILED_WAIT_TIMEOUT: number = 5000; // ms

  private IMAGE_UPLOAD_MAX_RETRIES: number = 2;

  private IMAGE_UPLOAD_QUEUE_SIZE: number = 4;

  private IMAGE_UPLOAD_ERROR_LIST:
    | MissionImageUploadsSelectedUploadImagesListTypes[]
    | null = null;

  private imageUploadCanceler: Canceler[] = [];

  private ImageUploadCancelToken: CancelTokenStatic = apiBase.getCancelToken();

  private plotGoogleMapRef: any;

  private tooltipContainerRef?: { current: HTMLDivElement };

  private latLongFilterBoundary: CoordinatesListTypes[] = [];

  // donot turn it on; the caching is breaking many things.
  private allowMissionImagesDataSourceListCache = false;

  private missionImagesDataSourceListCache: {
    filtered: GenericObjectType[];
    unFiltered: GenericObjectType[];
  } = {
    filtered: [],
    unFiltered: [],
  };

  public constructor(props: MissionImageUploadsPropsTypes) {
    super(props);

    this.state = {
      selectedUploadImagesList: null,
      isAllFilteredImagesSelected: false,
      missionImagesList: null,
      flightPlansDataList: null,
      selectedFlightPlanType: 'ALL',
      selectedMissionRequirementsType: 'AERIAL_MAPS',
      updateImageTypeLoading: false,
      boundaryPointsList: [],
      isImagesUploading: false,
      mapGeneratedTime: Date.now(),
      selectedMarkerIndex: 0,
      showExifDrawer: false,
      showMarkersOnMap: false,
      showUploadBtnModal: false,
      isFinishUploadBtnLoading: false,
      showImageUploadErrorModal: false,
      isSegregateImagesBtnLoading: false,
      isTriggerImagesBtnLoading: false,
      selectedListViewRowKeysList: [],
      listItemsPerPage: pageLimitOptionsList[0],
      manageTabViewIndex: '0',
      dataListFilterStore: {},
      dataListFilterStoreTemp: {},
      showTriggerModal: false,
      showForcefulTriggerModal: false,
      showLoadingModal: false,
      showInspectionViewModal: false,
    };
  }

  public componentDidMount(): void {
    this.fetchUploadedMissionsImages();
    this.fetchBoundary();
    this.fetchFlightPlans();
    this.tooltipContainerRef = {
      current: createTooltipContainerWithZIndex({ zIndex: 20000 }),
    };
  }

  public UNSAFE_componentWillReceiveProps({
    missionId: nextMissionId,
  }: Readonly<MissionImageUploadsPropsTypes>): void {
    const { missionId } = this.props;

    if (missionId !== nextMissionId) {
      this.fetchUploadedMissionsImages();
      this.fetchBoundary();
    }
  }

  public componentDidUpdate(
    // eslint-disable-next-line no-empty-pattern
    {},
    {
      missionImagesList: prevMissionImagesList,
    }: Readonly<MissionImageUploadsStateTypes>
  ): void {
    const { missionImagesList } = this.state;

    if (missionImagesList !== prevMissionImagesList) {
      this.missionImagesDataSourceListCache = {
        filtered: [],
        unFiltered: [],
      };
    }
  }

  public componentWillUnmount() {
    if (this.tooltipContainerRef?.current) {
      document.body.removeChild(this.tooltipContainerRef.current);
    }
  }

  private fetchUploadedMissionsImages = (regenerateMap = true) => {
    const { missionId, showSnackbar } = this.props;

    missionV2CapiApis.listUploadedImages(missionId).then((res) => {
      const { error: apiError, data: apiData } = res;

      if (apiError) {
        showSnackbar({ type: 'error', body: apiError });

        log.error(apiError, `UploadImages.fetchFlightPlanImages`);

        return;
      }

      let stateProps = {};

      if (regenerateMap) {
        stateProps = {
          mapGeneratedTime: Date.now(),
        };
      }

      this.setState({
        missionImagesList: apiData || [],
        showMarkersOnMap: true,
        ...stateProps,
      });
    });
  };

  private fetchBoundary(): void {
    const { aoiId, projectId } = this.props;

    projectsApi.listAoiBoundary(projectId, aoiId).then((res) => {
      const { error: apiError, data: apiData } = res;

      this.setState(
        () => {
          return {
            boundaryPointsList: getBoundaryPoints(apiData) || [],
          };
        },
        () => this.boundaryUpdated()
      );

      if (!undefinedOrNull(apiError)) {
        log.error(apiError, 'UploadImages.fetchBoundary');
      }
    });
  }

  private boundaryUpdated = () => {
    const { boundaryPointsList } = this.state;

    if (boundaryPointsList && boundaryPointsList.length)
      this.setState({
        boundaryGeometryAndBuffer: {
          geometry: new Polygon([
            boundaryPointsList.map((p) => [p.lng, p.lat]),
          ]).transform(WGS84, WEB_MERCATOR) as Polygon,
          scaleBuffer: 1.2,
        },
      });
  };

  private fetchFlightPlans(): void {
    const { aoiId, projectId, missionId } = this.props;

    missionV2CapiApis
      .listFlightPlansByAoi(projectId, aoiId, missionId)
      .then((res) => {
        const { error: apiError, data: apiData } = res;

        this.setState(() => {
          return {
            flightPlansDataList: apiData || [],
          };
        });

        if (!undefinedOrNull(apiError)) {
          log.error(apiError, 'UploadImages.fetchFlightPlans');
        }
      });
  }

  private updateUploadedMissionsImages = (
    missionImageData: GenericObjectType[]
  ) => {
    const { missionImagesList } = this.state;

    const _missionImagesList = missionImagesList;

    if (!_missionImagesList) {
      return;
    }

    for (let i = 0; i < missionImageData.length; i += 1) {
      const item = missionImageData[i];
      let itemIndex = 0;

      for (let j = 0; j < _missionImagesList.length; j += 1) {
        const missionImagesItem = _missionImagesList[j];

        if (missionImagesItem.id === item.id) {
          itemIndex = j;

          break;
        }
      }

      _missionImagesList[itemIndex] = item;
    }

    this.missionImagesDataSourceListCache = {
      filtered: [],
      unFiltered: [],
    };

    this.setState({
      missionImagesList: _missionImagesList,
      updateImageTypeLoading: false,
    });
  };

  private handleFileChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const el: HTMLInputElement = e.target;

    if (undefinedOrNull(el) || undefinedOrNull(el.files)) {
      this.setState({
        selectedUploadImagesList: null,
      });

      return;
    }

    const selectedUploadImagesList: MissionImageUploadsSelectedUploadImagesListTypes[] =
      Array.from(el.files).map((a, index) => {
        return {
          index,
          file: a,
          uploaded: false,
          error: null,
          retries: 0,
          status: 'NONE',
        };
      });

    this.setState(
      {
        selectedUploadImagesList,
      },
      () => {
        this.handleUploadBtn();
      }
    );
  };

  private handleUploadImages = async () => {
    const { showSnackbar, onImageUploading } = this.props;
    const { selectedUploadImagesList } = this.state;

    if (!selectedUploadImagesList || selectedUploadImagesList.length < 1) {
      showSnackbar({
        type: 'error',
        body: 'No image selected.',
      });

      this.setState(
        {
          isImagesUploading: false,
          showMarkersOnMap: true,
        },
        () => {
          if (onImageUploading) {
            onImageUploading(false);
          }
        }
      );

      return;
    }

    this.setState(
      {
        isImagesUploading: true,
        showMarkersOnMap: false,
      },
      () => {
        if (onImageUploading) {
          onImageUploading(true);
        }
      }
    );

    await this.doUploadImages();

    this.setState(
      ({ selectedUploadImagesList }) => {
        let stateProps = {};

        // reset the 'retries' props in the 'selectedUploadImagesList' for facilitating the smooth RETRY button action
        if (selectedUploadImagesList) {
          stateProps = {
            selectedUploadImagesList: selectedUploadImagesList.map((a) => {
              const _a = a;

              _a.retries = 0;

              return _a;
            }),
          };
        }

        return {
          ...stateProps,
          isImagesUploading: false,
        };
      },
      () => {
        this.fetchUploadedMissionsImages();

        if (onImageUploading) {
          onImageUploading(false);
        }
      }
    );
  };

  private doUploadImages = async (): Promise<
    MissionImageUploadsSelectedUploadImagesListTypes[] | null
  > => {
    const { missionId } = this.props;
    const {
      selectedUploadImagesList,
      selectedFlightPlanType,
      flightPlansDataList,
    } = this.state;

    if (!selectedUploadImagesList) {
      return selectedUploadImagesList;
    }

    const _selectedUploadImagesList = selectedUploadImagesList;

    const uploadQueueImagesList = _selectedUploadImagesList
      .filter((a) => !a.uploaded && a.retries < this.IMAGE_UPLOAD_MAX_RETRIES)
      .slice(0, this.IMAGE_UPLOAD_QUEUE_SIZE);

    if (!uploadQueueImagesList || uploadQueueImagesList.length < 1) {
      return selectedUploadImagesList;
    }

    const promiseQueueList = [];
    let uploadServiceType: UploadServiceType = 'missions';
    let uploadServiceTypeId: string = missionId;

    if (selectedFlightPlanType !== 'ALL') {
      const filteredFlightPlansDataList = filteredArrayValue(
        (flightPlansDataList || []).filter(
          (a) => a.type === selectedFlightPlanType
        )
      );

      if (filteredFlightPlansDataList) {
        uploadServiceTypeId = filteredFlightPlansDataList.id;
        uploadServiceType = 'flightPlans';
      }
    }

    for (let i = 0; i < uploadQueueImagesList.length; i += 1) {
      const item = uploadQueueImagesList[i];

      const formData = new FormData();

      formData.append('image', item.file, item.file.name);

      const uploadPromise: Promise<MissionImageUploadsStatusTypes> =
        new Promise((resolve) => {
          return missionV2CapiApis
            .postUploadedImages(
              uploadServiceType,
              uploadServiceTypeId,
              formData,
              new this.ImageUploadCancelToken((c) => {
                this.imageUploadCanceler.push(c);
              })
            )
            .then((res) => {
              const {
                error: apiError,
                errorMessage: apiErrorMessage,
                status: apiStatus,
                isCancelled: apiIsCancelled,
                networkError: apiNetworkError,
              } = res;

              let status: MissionImageUploadsStatusTypes = 'SUCCESS';

              if (apiNetworkError) {
                status = 'FAILED';
              } else if (apiIsCancelled) {
                status = 'CANCELLED';
              } else if (apiError) {
                status = 'FAILED';

                if (apiStatus === 409) {
                  status = 'DUPLICATE';
                }
              }

              const selectedUploadImage = _selectedUploadImagesList[item.index];

              let customError = null;

              if (apiNetworkError) {
                customError = `Could not upload ${item.file.name} - ${apiNetworkError}`;
              } else if (apiErrorMessage) {
                customError = `${apiErrorMessage} - ${item.file.name}`;
              } else {
                customError = `There was an unexpected error while trying to upload the image - ${item.file.name}`;
              }

              _selectedUploadImagesList[item.index] = {
                ...selectedUploadImage,
                uploaded: inArray(['SUCCESS', 'DUPLICATE'], status),
                error: inArray(['FAILED', 'CANCELLED'], status)
                  ? customError
                  : null,
                retries: selectedUploadImage.retries + 1,
                status,
              };

              return resolve(status);
            });
        });

      promiseQueueList.push(uploadPromise);
    }

    const uploadQueueStatusList = await Promise.all(promiseQueueList);

    await new Promise<void>((resolve) => {
      this.setState(
        {
          selectedUploadImagesList: _selectedUploadImagesList,
        },
        resolve
      );
    });

    // if 'cancel' button was clicked; do not proceed further
    if (inArray(uploadQueueStatusList, 'CANCELLED')) {
      const { selectedUploadImagesList } = this.state;

      return selectedUploadImagesList;
    }

    // if an error was occured while uploading wait for 'IMAGE_UPLOAD_FAILED_WAIT_TIMEOUT' to continue with uploading the queue
    if (inArray(uploadQueueStatusList, 'FAILED')) {
      await new Promise<void>((resolve) => {
        setTimeout(resolve, this.IMAGE_UPLOAD_FAILED_WAIT_TIMEOUT);
      });

      return this.doUploadImages();
    }

    return this.doUploadImages();
  };

  private cancelUploading = () => {
    if (!this.imageUploadCanceler) {
      return;
    }

    for (let i = 0; i < this.imageUploadCanceler.length; i += 1) {
      const item = this.imageUploadCanceler[i];

      item();
    }
  };

  private getImageUploadStatus = () => {
    const { selectedUploadImagesList } = this.state;

    let imageUploadAttempted = false;
    let imageUploadFailed = false;
    let imageUploadCancelled = false;

    if (
      selectedUploadImagesList &&
      selectedUploadImagesList.length > 0 &&
      selectedUploadImagesList[0].status !== 'NONE'
    ) {
      imageUploadAttempted = true;
    }

    if (imageUploadAttempted) {
      const uploadedFiles = filteredArrayValue(
        (
          selectedUploadImagesList as MissionImageUploadsSelectedUploadImagesListTypes[]
        ).filter((a) => !a.uploaded)
      );

      imageUploadFailed = !!uploadedFiles;

      imageUploadCancelled = uploadedFiles?.status === 'CANCELLED';
    }

    return {
      imageUploadAttempted,
      imageUploadFailed,
      imageUploadCancelled,
    };
  };

  private getUploadInformation =
    (): MissionImageUploadsUploadInformationTypes => {
      const { selectedUploadImagesList, missionImagesList } = this.state;

      const imagesUploadedInServer = missionImagesList || [];
      const imagesInQueue = selectedUploadImagesList || [];
      const pendingImagesInQueue = imagesInQueue.filter((a) => !a.uploaded);
      const uploadedImagesInQueue = imagesInQueue.filter((a) => a.uploaded);
      const duplicateImagesInQueue = imagesInQueue.filter(
        (a) => a.status === 'DUPLICATE'
      );
      const failedImagesInQueue = imagesInQueue.filter(
        (a) => a.status === 'FAILED'
      );
      const successImagesInQueue = imagesInQueue.filter(
        (a) => a.status === 'SUCCESS'
      );

      return {
        totalImagesUploadedInServer: imagesUploadedInServer.length,
        totalImagesInQueue: imagesInQueue.length,
        totalPendingImagesInQueue: Math.max(
          pendingImagesInQueue.length - failedImagesInQueue.length,
          0
        ),
        totalUploadedImagesInQueue: uploadedImagesInQueue.length,
        totalDuplicateImagesInQueue: duplicateImagesInQueue.length,
        totalFailedImagesInQueue: failedImagesInQueue.length,
        totalSuccessImagesInQueue: successImagesInQueue.length,
        percUploadCompletedInQueue:
          percentage(uploadedImagesInQueue.length, imagesInQueue.length) || 0,
      };
    };

  private handleFeatureSelect = (
    features: Feature[],
    fractionDigits: number = 12
  ) => {
    const markerPointsList = this.getImagesMarkerPointsList();

    if (features.length === 0 || !markerPointsList) return;

    const geometry = features[0]
      .getGeometry()
      ?.transform(WEB_MERCATOR, WGS84) as Point;

    const markerLocations = markerPointsList.map(
      (p) =>
        `${p.pos.lng.toFixed(fractionDigits)}/${p.pos.lat.toFixed(
          fractionDigits
        )}`
    );
    const coordinates = geometry
      .getCoordinates()
      .map((p) => p.toFixed(fractionDigits));
    const index = markerLocations.findIndex(
      (p) => p === `${coordinates[0]}/${coordinates[1]}`
    );

    this.setState({
      selectedMarkerIndex: index,
      showExifDrawer: true,
    });
  };

  private handleMarkerClick = (index: number) => {
    this.setState({
      selectedMarkerIndex: index,
      showExifDrawer: true,
    });
  };

  private handleShowExifDrawer = (show: boolean = true) => {
    this.setState({
      showExifDrawer: show,
    });
  };

  private getImageFromId = (id: string | null) => {
    if (!id) {
      return null;
    }

    const { unFiltered: unfilteredMissionImagesDataSourceList } =
      this.getMissionImagesDataSourceList();

    return filteredArrayValue(
      (unfilteredMissionImagesDataSourceList ?? []).filter((a) => a.id === id)
    );
  };

  private getImageFromGuid = (guid: string | null) => {
    if (!guid) {
      return null;
    }

    const { unFiltered: unfilteredMissionImagesDataSourceList } =
      this.getMissionImagesDataSourceList();

    return filteredArrayValue(
      (unfilteredMissionImagesDataSourceList ?? []).filter(
        (a) => a.guid === guid
      )
    );
  };

  private getImageFromIndex = (index: number) => {
    if (!index) {
      return null;
    }

    const { unFiltered: unfilteredMissionImagesDataSourceList } =
      this.getMissionImagesDataSourceList();

    return unfilteredMissionImagesDataSourceList[index] ?? null;
  };

  private getImagesMarkerPointsList = ():
    | PlotGoogleMapMarkerPointsTypes[]
    | null => {
    const { showMarkersOnMap } = this.state;

    const filteredMissionImagesList =
      this.getMissionImagesDataSourceList().filtered;

    if (!filteredMissionImagesList || !showMarkersOnMap) {
      return null;
    }

    const selectedListViewRowKeysList = this.getSelectedListViewRowKeysList();

    return filteredMissionImagesList.map(
      (img: GenericObjectType, index: number) => {
        return {
          pos: {
            lat: parseFloat(img.latitude),
            lng: parseFloat(img.longitude),
          },
          index,
          icon: {
            selected: {
              types: ['DEFAULT'],
              color: GOOGLE_MAP_MARKER_SELECTED_COLOR,
            },
            unselected: {
              types: ['DEFAULT'],
              color: inArray(selectedListViewRowKeysList, img.id)
                ? GOOGLE_MAP_MARKER_SELECTED_COLOR
                : GOOGLE_MAP_MARKER_DEFAULT_COLOR,
            },
          },
          piggyback: img,
        };
      }
    );
  };

  private getLatLongFilterImagesMarkerPointsList = ():
    | PlotGoogleMapMarkerPointsTypes[]
    | null => {
    const { showMarkersOnMap, dataListFilterStore } = this.state;

    const fieldName = 'latitudeLongitudeUntrimmed';

    const { filtered: filteredMissionImagesList } =
      this.getMissionImagesDataSourceList();

    const { filtered: filteredMissionImagesListExcludingLatLangFilter } =
      this.getMissionImagesDataSourceList([fieldName]);

    if (!showMarkersOnMap) {
      return null;
    }

    let filteredDataListFilterStoreList: string[] = [];

    if (dataListFilterStore[fieldName]) {
      filteredDataListFilterStoreList = dataListFilterStore[fieldName]
        .value as string[];
    }

    const filteredLatLongList = filteredMissionImagesList.map(
      (a) => a[fieldName]
    );

    return filteredMissionImagesListExcludingLatLangFilter.map(
      (img: GenericObjectType, index: number) => {
        let unselectedColor = GOOGLE_MAP_MARKER_DEFAULT_COLOR;

        if (
          inArray(filteredDataListFilterStoreList, img[fieldName]) ||
          inArray(filteredLatLongList, img[fieldName])
        ) {
          unselectedColor = GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR;
        }

        return {
          pos: {
            lat: parseFloat(img.latitude),
            lng: parseFloat(img.longitude),
          },
          index,
          icon: {
            selected: {
              types: ['DEFAULT'],
              color: GOOGLE_MAP_MARKER_SELECTED_COLOR,
            },
            unselected: {
              types: ['DEFAULT'],
              color: unselectedColor,
              ignoreMarkerClusterer: false,
            },
          },
        };
      }
    );
  };

  private getTriggerProcessingMarkerPointsList = ():
    | PlotGoogleMapMarkerPointsTypes[]
    | null => {
    const { selectedMissionRequirementsType } = this.state;

    const { unFiltered: missionImagesList } =
      this.getMissionImagesDataSourceList();

    if (!missionImagesList) {
      return null;
    }

    return missionImagesList
      .filter(
        (a) =>
          a[requirementsListToUseInDict[selectedMissionRequirementsType]] ===
          true
      )
      .map((img: GenericObjectType, index: number) => {
        return {
          pos: {
            lat: parseFloat(img.latitude),
            lng: parseFloat(img.longitude),
          },
          index,
          icon: {
            selected: {
              types: ['DEFAULT'],
              color: GOOGLE_MAP_MARKER_SELECTED_COLOR,
            },
            unselected: {
              types: ['DEFAULT'],
              color: GOOGLE_MAP_MARKER_SELECTED_COLOR,
            },
          },
        };
      });
  };

  private handleUploadBtn = () => {
    const { flightPlansDataList } = this.state;

    if (!flightPlansDataList) {
      return;
    }

    if (flightPlansDataList.length < 2) {
      this.setState(
        {
          selectedFlightPlanType:
            flightPlansDataList.length === 1
              ? flightPlansDataList[0].type
              : 'ALL',
        },
        () => {
          this.handleShowUploadBtnModal(true);
        }
      );

      return;
    }

    this.handleShowUploadBtnModal(true);
  };

  private handleShowUploadBtnModal = (value: boolean = false) => {
    this.setState({
      showUploadBtnModal: value,
    });
  };

  private handleSegregateImages = () => {
    const { missionId, showSnackbar } = this.props;

    this.setState({
      isSegregateImagesBtnLoading: true,
    });

    missionV2NavigatorApis.postSegregateImages(missionId, {}).then((res) => {
      const { error: apiError, errorMessage } = res;

      this.setState({
        isSegregateImagesBtnLoading: false,
      });

      if (apiError) {
        showSnackbar({
          type: 'error',
          body: errorMessage || apiError,
        });

        log.error(
          errorMessage || apiError,
          'UploadImages.handleSegregateImages'
        );

        return;
      }

      this.fetchUploadedMissionsImages();
    });
  };

  private handleTriggerImagesBtnClick = async (force = false) => {
    const { selectedMissionRequirementsType } = this.state;

    const triggeredMissionDataRequirementsList =
      this.getTriggeredMissionDataRequirementsList();

    if (
      !undefinedOrNull(triggeredMissionDataRequirementsList.AERIAL_MAPS) &&
      !triggeredMissionDataRequirementsList.AERIAL_MAPS &&
      selectedMissionRequirementsType === 'AERIAL_MAPS'
    ) {
      const { error, errorCode } = await this.processTriggerImagesBtnClick(
        force,
        'AERIAL_MAPS',
        false
      );

      if (error && errorCode === 'NO_GCPS_FOUND') {
        return;
      }

      return;
    }

    if (!this.hasValidBoundaryForTrigger(selectedMissionRequirementsType)) {
      return;
    }

    await this.processTriggerImagesBtnClick(
      force,
      selectedMissionRequirementsType
    );
  };

  private hasValidBoundaryForTrigger = (
    selectedMissionRequirementsType: SelectedMissionRequirementsType
  ): boolean => {
    const { showSnackbar, projectData, aoiId, projectId } = this.props;
    const aoi = getAoiFromProjectData(projectData, aoiId);

    if (
      !aoi?.boundaryId &&
      ['PERSPECTIVE', 'AERIAL_MAPS'].indexOf(selectedMissionRequirementsType) >
        -1
    ) {
      showSnackbar({
        type: 'error',
        body: (
          <div>
            Processing cannot be triggered as it requires a boundary. Use the
            <Link to={`/project/${projectId}/aoi/${aoiId}/edit`}>
              Edit AOI screen
            </Link>{' '}
            to add a boundary.
          </div>
        ),
      });

      return false;
    }

    return true;
  };

  private processTriggerImagesBtnClick = (
    force = false,
    selectedMissionRequirementsType: SelectedMissionRequirementsType,
    showSuccessMessage = true
  ): Promise<{ error: boolean; errorCode: string | null }> => {
    const { missionId, showSnackbar, aoiId, projectId, onReloadMissionData } =
      this.props;

    return new Promise((resolve) => {
      this.setState({
        showForcefulTriggerModal: false,
        isTriggerImagesBtnLoading: true,
      });

      missionV2CapiApis
        .postMissionRequirementProcessing(
          projectId,
          aoiId,
          missionId,
          selectedMissionRequirementsType,
          {},
          force
        )
        .then((res) => {
          const {
            error: apiError,
            errorMessage: apiErrorMessage,
            errorData: apiErrorData,
            status: apiStatus,
          } = res;

          if (
            apiStatus === 400 &&
            apiErrorData?.errorCode === 'NO_GCPS_FOUND'
          ) {
            this.setState(
              {
                isTriggerImagesBtnLoading: false,
                showForcefulTriggerModal: true,
              },
              () => {
                return resolve({
                  error: true,
                  errorCode: apiErrorData.errorCode,
                });
              }
            );

            return;
          }

          this.setState({
            isTriggerImagesBtnLoading: false,
          });

          if (apiError) {
            showSnackbar({
              type: 'error',
              body: apiErrorMessage ?? apiError,
            });

            log.error(apiError, 'UploadImages.handleTriggerImagesBtnClick');

            return resolve({
              error: true,
              errorCode: 'NO_GCPS_FOUND',
            });
          }

          if (showSuccessMessage) {
            showSnackbar({
              type: 'success',
              body: 'Trigger request has been submitted. You will be notified when the outputs are ready.',
            });
          }

          onReloadMissionData();
          this.fetchUploadedMissionsImages();

          return resolve({
            error: false,
            errorCode: null,
          });
        });
    });
  };

  private handleShowErrorsModal = (value: boolean = false) => {
    this.setState({
      showImageUploadErrorModal: value,
    });

    if (value) {
      this.showErrorsModal();

      return;
    }

    this.IMAGE_UPLOAD_ERROR_LIST = null;
  };

  private showErrorsModal = () => {
    const { selectedUploadImagesList } = this.state;

    if (selectedUploadImagesList) {
      this.IMAGE_UPLOAD_ERROR_LIST = null;
    }
  };

  private setSelectedListViewRowKeysList = (selectedRowKeys: string[]) => {
    this.setState(() => {
      return {
        selectedListViewRowKeysList: selectedRowKeys,
      };
    });
  };

  private handleFilterImagesChange = (value: RadioChangeEvent) => {
    this.setState(() => {
      return {
        selectedMissionRequirementsType: value.target
          .value as MissionRequirementsType,
        mapGeneratedTime: Date.now(),
        selectedListViewRowKeysList: [],
      };
    });
  };

  private getSelectedListViewRowKeysList = () => {
    const { selectedListViewRowKeysList } = this.state;

    return arrayRemoveDuplicate(selectedListViewRowKeysList as string[]);
  };

  private handleItemsPerPageChange = (value: number) => {
    this.setState({
      listItemsPerPage: value,
    });
  };

  private handleManageTabChange = (value: string) => {
    this.setState({
      manageTabViewIndex: `${value}`,
    });
  };

  private handleUpdateImageType = (
    checkedValues: string[],
    prevCheckedValues: string[],
    selectedMarkerIndex: number | null
  ) => {
    const { missionId, showSnackbar } = this.props;
    const { filtered: missionImagesList } =
      this.getMissionImagesDataSourceList();

    if (
      undefinedOrNull(selectedMarkerIndex) ||
      undefinedOrNull(missionImagesList)
    ) {
      return;
    }

    if (!checkedValues || !missionImagesList[selectedMarkerIndex]) {
      return;
    }

    this.setState({
      updateImageTypeLoading: true,
    });

    const selectedMissionImage = missionImagesList[selectedMarkerIndex];

    const formData: UploadedImagesUseInEnabledListTypes = {
      useInPanorama: false,
      useInPerspective: false,
      useInMaps: false,
      useInInspection: false,
      useInThermalMap: false,
      useInThermalInspection: false,
    };

    checkedValues.map((a) => {
      formData[a] = true;

      return a;
    });

    missionV2CapiApis
      .updateMissionImageType(missionId, selectedMissionImage.guid, formData)
      .then((res) => {
        const { data: apiData, error: apiError } = res;

        if (apiError) {
          showSnackbar({
            type: 'error',
            body: apiError,
          });

          log.error(apiError, 'UploadImages.handleUpdateImageType');

          this.setState({
            updateImageTypeLoading: false,
          });

          return;
        }

        this.updateUploadedMissionsImages([apiData]);
      });
  };

  private createInspectionView = (viewName: string) => {
    const { missionId, projectId, aoiId, showSnackbar } = this.props;
    const { missionImagesList } = this.state;

    if (undefinedOrNull(missionImagesList)) {
      return;
    }

    this.setState({
      updateImageTypeLoading: true,
    });
    const formData = {
      missionId,
      type: 'inspection',
      subTye: 'inspection_default',
      imageIds: this.getSelectedListViewRowKeysList(),
      name: viewName,
    };

    this.setState({
      showLoadingModal: true,
    });

    viewsApi.createView(projectId, aoiId, formData).then((res) => {
      this.setState({
        showLoadingModal: false,
      });

      const { error: apiError } = res;

      if (apiError) {
        showSnackbar({
          type: 'error',
          body: apiError,
        });

        log.error(apiError, 'UploadImages.handleBulkUpdateListViewImageType');

        this.setState({
          updateImageTypeLoading: false,
        });
      } else {
        showSnackbar({
          type: 'success',
          body: 'Inspection view has been created, please find it in Publish tab',
        });
      }
    });
  };

  private handleBulkUpdateListViewImageType = (value: boolean, key: string) => {
    const { missionId, showSnackbar } = this.props;
    const { missionImagesList } = this.state;

    if (undefinedOrNull(missionImagesList)) {
      return;
    }

    this.setState({
      updateImageTypeLoading: true,
    });

    const formData = {
      ids: this.getSelectedListViewRowKeysList(),
      [key]: value,
    };

    missionV2CapiApis
      .updateBulkMissionImageType(missionId, formData)
      .then((res) => {
        const { data: apiData, error: apiError } = res;

        if (apiError) {
          showSnackbar({
            type: 'error',
            body: apiError,
          });

          log.error(apiError, 'UploadImages.handleBulkUpdateListViewImageType');

          this.setState({
            updateImageTypeLoading: false,
          });

          return;
        }

        this.updateUploadedMissionsImages(apiData);
      });
  };

  private getSelectedMissionImageTypeList = (
    selectedMarkerIndex: number | null
  ) => {
    const { filtered: missionImagesList } =
      this.getMissionImagesDataSourceList();

    if (
      undefinedOrNull(selectedMarkerIndex) ||
      undefinedOrNull(missionImagesList)
    ) {
      return [];
    }

    if (!missionImagesList[selectedMarkerIndex]) {
      return [];
    }

    const selectedMissionImage = missionImagesList[selectedMarkerIndex];

    return (
      [
        'useInMaps',
        'useInPerspective',
        'useInPanorama',
        'useInSiteNav',
      ] as UploadedImagesUseInTypes[]
    ).filter((a) => selectedMissionImage[a]);
  };

  private getExifImagePreviewFromSelectedIndex = (
    selectedMarkerIndex: number | null
  ) => {
    const { filtered: missionImagesList } =
      this.getMissionImagesDataSourceList();

    if (
      undefinedOrNull(selectedMarkerIndex) ||
      undefinedOrNull(missionImagesList)
    ) {
      return null;
    }

    if (!missionImagesList[selectedMarkerIndex]) {
      return null;
    }

    const selectedMissionImage = missionImagesList[selectedMarkerIndex];

    return missionV2CapiApis.getImagePreview(selectedMissionImage.guid);
  };

  private getExifImagePreviewFromImageGuid = (guid: string | null) => {
    if (undefinedOrNull(guid)) {
      return null;
    }

    return missionV2CapiApis.getImagePreview(guid);
  };

  private getTriggeredMissionDataRequirementsList = (
    filterOnlyAvailableRequirement: boolean = true
  ) => {
    const { missionData } = this.props;

    const requirementsList = missionData?.requirements ?? [];

    let optionsList: { [key in MissionRequirementsType]?: boolean } = {};

    if (!filterOnlyAvailableRequirement) {
      optionsList = {
        AERIAL_MAPS: false,
        PERSPECTIVE: false,
        EXTERIOR_360: false,
        INSPECTION: false,
        THERMAL_MAP: false,
      };
    }

    requirementsList.map((a: GenericObjectType) => {
      if (
        !inArray(
          [
            'AERIAL_MAPS',
            'PERSPECTIVE',
            'EXTERIOR_360',
            'INSPECTION',
            'THERMAL_MAP',
            'THERMAL_INSPECTION',
          ],
          a.id
        )
      ) {
        return a;
      }

      optionsList[a.id] = a.status !== 'PENDING';

      return a;
    });

    return optionsList;
  };

  private getAvailableMissionRequirementsType =
    (): MissionRequirementsType[] => {
      const optionsList = this.getTriggeredMissionDataRequirementsList();

      return Object.keys(optionsList)
        .map((a) => {
          return a;
        })
        .filter((a) => a) as MissionRequirementsType[];
    };

  private getTriggeredMissionList = () => {
    const optionsList = this.getTriggeredMissionDataRequirementsList();

    return Object.keys(optionsList)
      .map((a) => {
        const item = optionsList[a];
        const { button } = selectedMissionRequirementsTypeTextDict[a];

        if (!item) {
          return null;
        }

        return button;
      })
      .filter((a) => a);
  };

  private getFlightPlanIdToFlightType = (flightPlanId: string) => {
    const { flightPlansDataList } = this.state;

    const selectedFlightPlanList = (flightPlansDataList || []).filter(
      (a: GenericObjectType) => a.id === flightPlanId
    );

    const selectedFlightPlan = filteredArrayValue(selectedFlightPlanList);

    return selectedFlightPlan?.type ?? null;
  };

  private getAvailableFlightPlansDataList = () => {
    const { flightPlansDataList } = this.state;

    return (flightPlansDataList || []).map((a: GenericObjectType) => a.type);
  };

  private getMetaDataValue = (
    key: string,
    metaDataValue: string | number | null
  ) => {
    const value = metaDataValue;

    if (undefinedOrNull(value)) {
      return `N/A`;
    }

    if (key === 'exposureTime' && typeof value === 'number') {
      const denominator = parseInt(`${1 / value}`, 10);

      return `1/${denominator}`;
    }

    if (key === 'fNumber' && typeof value === 'number') {
      return `f/${value}`;
    }

    if (key === 'capturedAt') {
      return getDateTimeWithoutTz(`${value}`);
    }

    if (key === 'capturedAtTimestamp') {
      return getDateTimeFromTimestamp(parseInt(`${value}`, 10));
    }

    if (key === 'latitude') {
      return round(value, 8);
    }

    if (key === 'longitude') {
      return round(value, 8);
    }

    return value;
  };

  private handleDataListFilterStore = (
    key: string,
    data:
      | {
          type: 'equals';
          value: (number | string)[];
        }
      | {
          type: 'range';
          value: [number, number];
        },
    moveToTemporary: boolean = true,
    byPassEmptyValueCheck = false
  ) => {
    const { dataListFilterStore } = this.state;
    const { type, value } = data;

    if (
      !byPassEmptyValueCheck &&
      type === 'equals' &&
      (!value || value.length < 1)
    ) {
      return;
    }

    const item = {
      [key]: {
        type,
        value,
      },
    };

    const _dataListFilterStore = {
      ...dataListFilterStore,
      ...(item as DataFilterTypes),
    };

    this.setState(
      () => {
        return {
          dataListFilterStoreTemp: _dataListFilterStore,
        };
      },
      () => {
        if (moveToTemporary) {
          return;
        }

        this.processDataListFilterStore(true);
      }
    );
  };

  private processDataListFilterStore = (moveToState: boolean) => {
    if (!moveToState) {
      this.setState(({ dataListFilterStore }) => {
        return {
          dataListFilterStoreTemp: dataListFilterStore,
        };
      });

      return;
    }

    const { selectedListViewRowKeysList, dataListFilterStoreTemp } = this.state;

    const { filtered: filteredMissionImagesDataSourceList } =
      this.getMissionImagesDataSourceList(undefined, dataListFilterStoreTemp);

    const allowedSelectedListViewRowKeysList: string[] = [];
    const filteredMissionImagesDataSourceIdList =
      filteredMissionImagesDataSourceList.map((a) => a.id);

    selectedListViewRowKeysList.map((a) => {
      if (!inArray(filteredMissionImagesDataSourceIdList, a)) {
        return;
      }

      allowedSelectedListViewRowKeysList.push(a);

      return a;
    });

    this.missionImagesDataSourceListCache.filtered = [];

    this.setState(({ dataListFilterStoreTemp }) => {
      return {
        dataListFilterStore: dataListFilterStoreTemp,
        showExifDrawer: false,
        selectedMarkerIndex: 0,
        mapGeneratedTime: Date.now(),
        selectedListViewRowKeysList: allowedSelectedListViewRowKeysList,
      };
    });
  };

  private getMissionImagesDataSourceList = (
    excludedFilters?: string[],
    _dataListFilterStore?: DataFilterTypes
  ) => {
    const { missionImagesList, dataListFilterStore } = this.state;

    let unFiltered: GenericObjectType[] = [];
    let filtered: GenericObjectType[] = [];

    if (
      this.allowMissionImagesDataSourceListCache &&
      this.missionImagesDataSourceListCache.unFiltered &&
      this.missionImagesDataSourceListCache.unFiltered.length > 0
    ) {
      unFiltered = this.missionImagesDataSourceListCache.unFiltered;
    } else {
      unFiltered = (missionImagesList ?? []).map((a, index) => {
        const latitude = this.getMetaDataValue('latitude', a.latitude);
        const longitude = this.getMetaDataValue('longitude', a.longitude);

        return {
          ...a,
          index,
          forMaps: a.useInMaps ? 'Y' : 'N',
          forThermalMap: a.useInThermalMap ? 'Y' : 'N',
          forPerspective: a.useInPerspective ? 'Y' : 'N',
          for360: a.useInPanorama ? 'Y' : 'N',
          forSiteNav: a.useInSiteNav ? 'Y' : 'N',
          forInspection: a.useInInspection ? 'Y' : 'N',
          forThermalInspection: a.useInThermalInspection ? 'Y' : 'N',
          capturedAtTimestamp: getTimestamp(a.capturedAt),
          latitudeLongitudeUntrimmed: `${a.latitude}/${a.longitude}`,
          latitudeLongitude: `${latitude}/${longitude}`,
          thermalImage: a.thermalImage ? 'Y' : 'N',
        };
      });
    }

    if (
      this.allowMissionImagesDataSourceListCache &&
      this.missionImagesDataSourceListCache.filtered &&
      this.missionImagesDataSourceListCache.filtered.length > 0
    ) {
      filtered = this.missionImagesDataSourceListCache.filtered;
    } else {
      filtered = filterData(
        unFiltered,
        _dataListFilterStore ?? dataListFilterStore,
        excludedFilters
      );
    }

    this.missionImagesDataSourceListCache = {
      unFiltered,
      filtered,
    };

    return {
      unFiltered,
      filtered,
    };
  };

  private handleLatLongFilterPolygonsAddedEvent = (
    data: {
      overlay: GenericObjectType;
      coordinates: CoordinatesListTypes;
    } | null,
    type: PlotGoogleMapDrawManagerModesTypes | null,
    dataList: PlotGoogleMapDrawingManagerStore | null,
    fieldName: string
  ): void => {
    const { unFiltered } = this.getMissionImagesDataSourceList();

    let _markerPointsList: GenericObjectType[] = [];

    if (!data || !type || !dataList) {
      this.latLongFilterBoundary = [];

      this.handleDataListFilterStore(
        fieldName,
        {
          value: [],
          type: 'equals',
        },
        false,
        true
      );

      return;
    }

    const { coordinates: boundsCoordinates } = dataList[type];

    boundsCoordinates.map((a) => {
      const markerPoints = checkMarkerPointsWithinBounds(unFiltered, a) ?? [];

      if (!markerPoints || markerPoints.length < 1) {
        return a;
      }

      _markerPointsList = [..._markerPointsList, ...markerPoints];

      return a;
    });

    const markerPointsCoordsList: string[] = (_markerPointsList ?? []).map(
      (a) => `${a.latitude}/${a.longitude}`
    );

    this.latLongFilterBoundary = boundsCoordinates;

    this.handleDataListFilterStore(
      fieldName,
      {
        value: markerPointsCoordsList,
        type: 'equals',
      },
      false,
      true
    );
  };

  private handleOpenLayersLatLongFilterPolygonsAdded = (
    geojson: string | undefined,
    fieldName: string
  ): void => {
    const { unFiltered } = this.getMissionImagesDataSourceList();

    let _markerPointsList: GenericObjectType[] = [];

    if (!geojson) {
      this.latLongFilterBoundary = [];

      this.handleDataListFilterStore(
        fieldName,
        {
          value: [],
          type: 'equals',
        },
        false,
        true
      );

      return;
    }

    const geojsonObject = JSON.parse(geojson) as any;

    const { coordinates: boundsCoordinates } = geojsonObject.geometry;

    if (!boundsCoordinates || !boundsCoordinates.length) {
      console.error('Invalid geometry used for filtering', geojsonObject);

      this.handleDataListFilterStore(
        fieldName,
        {
          value: [],
          type: 'equals',
        },
        false,
        true
      );

      return;
    }

    this.latLongFilterBoundary = boundsCoordinates;

    (boundsCoordinates as Coordinate[][]).map((a) => {
      const markerPoints =
        checkMarkerPointsWithinBounds(
          unFiltered,
          a.map((p) => [p[1], p[0]]) as CoordinatesListTypes
        ) ?? [];

      if (!markerPoints || markerPoints.length < 1) {
        return a;
      }

      _markerPointsList = [..._markerPointsList, ...markerPoints];

      return a;
    });

    const markerPointsCoordsList: string[] = (_markerPointsList ?? []).map(
      (a) => `${a.latitude}/${a.longitude}`
    );

    this.latLongFilterBoundary = boundsCoordinates;

    this.handleDataListFilterStore(
      fieldName,
      {
        value: markerPointsCoordsList,
        type: 'equals',
      },
      false,
      true
    );
  };

  private handleResetFilterByType = (type: string) => {
    this.missionImagesDataSourceListCache.filtered = [];

    this.setState(
      ({ dataListFilterStore }) => {
        const _dataListFilterStore = dataListFilterStore;

        if (_dataListFilterStore[type]) {
          delete _dataListFilterStore[type];
        }

        return {
          dataListFilterStore: {
            ..._dataListFilterStore,
          },
          showExifDrawer: false,
          mapGeneratedTime: Date.now(),
        };
      },
      () => {
        if (type === 'latitudeLongitudeUntrimmed') {
          this.latLongFilterBoundary = [];
        }
      }
    );
  };

  private handleTriggerProcessingBtnClick = (value: boolean) => {
    this.setState({
      showTriggerModal: value,
    });
  };

  private handleForcefulTriggerProcessingBtnClick = (value: boolean) => {
    this.setState({
      showForcefulTriggerModal: value,
    });
  };

  private handleManageUploadedImagesClick = () => {
    const { onUploadImagesActionTypeChange } = this.props;

    if (!onUploadImagesActionTypeChange) {
      return;
    }

    onUploadImagesActionTypeChange(true, 'manage');
  };

  private getCalendarDisabledDate = (
    current: moment.Moment,
    startDateTime: number,
    endDateTime: number
  ) => {
    if (!current) {
      return false;
    }

    if (current < moment.unix(startDateTime)) {
      return true;
    }

    if (current > moment.unix(endDateTime)) {
      return true;
    }

    return false;
  };

  private getCalendarDisabledTime = (
    current: moment.Moment,
    type: string,
    startTimeList: [number, number, number],
    endTimeList: [number, number, number]
  ) => {
    // @todo: use type === "start" and current object to capture the the start and end dates and handle the disabled hours, min and sec

    const totalHours = generateRange(0, 23);
    const totalMinSec = generateRange(0, 59);
    const startDisabledHours = totalHours.slice(0, startTimeList[0]);
    const endDisabledHours = totalHours.slice(endTimeList[0] + 1);
    const startDisabledMin = totalMinSec.slice(0, startTimeList[1]);
    const endDisabledMin = totalMinSec.slice(endTimeList[1] + 1);
    const startDisabledSeconds = totalMinSec.slice(0, startTimeList[2]);
    const endDisabledSeconds = totalMinSec.slice(endTimeList[2] + 1);

    return {
      disabledHours: () => [...startDisabledHours, ...endDisabledHours],
      disabledMinutes: () => [...startDisabledMin, ...endDisabledMin],
      disabledSeconds: () => [...startDisabledSeconds, ...endDisabledSeconds],
    };
  };

  private getUploadBtnModalData = () => {
    const { selectedFlightPlanType } = this.state;

    const triggeredMissionDataRequirementsList =
      this.getTriggeredMissionDataRequirementsList();
    const totalTriggeredMissionDataRequirementsList = Object.keys(
      triggeredMissionDataRequirementsList
    ).length;
    let nonPendingTriggeredMissionDataRequirementsList = 0;

    const tiggeredList = arrayRemoveDuplicate(
      Object.keys(triggeredMissionDataRequirementsList)
        .map((a) => {
          const item = triggeredMissionDataRequirementsList[a];

          if (!item) {
            return null;
          }

          nonPendingTriggeredMissionDataRequirementsList += 1;

          return requirementsListToFlightPlanDict[a];
        })
        .filter((a) => a)
    );

    const allRequirementsTriggered =
      totalTriggeredMissionDataRequirementsList ===
      nonPendingTriggeredMissionDataRequirementsList;

    const isSelectedFlightPlanTypeTiggered = inArray(
      tiggeredList,
      selectedFlightPlanType
    );

    return {
      isSelectedFlightPlanTypeTiggered,
      allRequirementsTriggered,
      tiggeredList,
      totalTriggeredMissionDataRequirementsList,
      triggeredMissionDataRequirementsList,
    };
  };

  private RenderUploadActionBtns = () => {
    const { missionData } = this.props;
    const { isImagesUploading } = this.state;

    const { allRequirementsTriggered } = this.getUploadBtnModalData();

    const disableButtons =
      isImagesUploading ||
      !missionData ||
      allRequirementsTriggered ||
      missionData.imageUploadStatus === 'FINISHED';

    return (
      <div className={styles.uploadActionBtnsWrapper}>
        <Tooltip
          placement="top"
          title={
            allRequirementsTriggered
              ? 'Processing for all outputs have been triggered'
              : 'Select images to upload'
          }
          overlayStyle={{ zIndex: allRequirementsTriggered ? 10002 : 10000 }}
        >
          <label className={styles.inputButtonLabel}>
            <input
              type="file"
              multiple
              accept="*.jpg,*.jpeg"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                this.handleFileChanges(e);
              }}
              disabled={disableButtons}
            />

            <div
              className={classnames('ant-btn', styles.uploadBtn, {
                [styles.disabled]: disableButtons,
              })}
            >
              <i className="fa fa-cloud-upload" aria-hidden="true" />
              <span>SELECT FILES</span>
            </div>
          </label>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            allRequirementsTriggered
              ? 'Processing for all outputs have been triggered'
              : 'Select folder containing images to upload'
          }
          overlayStyle={{ zIndex: allRequirementsTriggered ? 10002 : 10000 }}
        >
          <label className={styles.inputButtonLabel}>
            <input
              ref={(node: any) => {
                if (node) {
                  // eslint-disable-next-line no-param-reassign
                  node.directory = true;
                  // eslint-disable-next-line no-param-reassign
                  node.webkitdirectory = true;
                }
              }}
              type="file"
              multiple
              accept="*.jpg,*.jpeg"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                this.handleFileChanges(e);
              }}
              disabled={disableButtons}
            />

            <div
              className={classnames('ant-btn', styles.uploadBtn, {
                [styles.disabled]: disableButtons,
              })}
            >
              <i className="fa fa-cloud-upload" aria-hidden="true" />
              <span>SELECT FOLDER</span>
            </div>
          </label>
        </Tooltip>
      </div>
    );
  };

  private RenderTaskActionBtns = () => {
    const { isImagesUploading, selectedUploadImagesList } = this.state;

    let activeBtn: MissionImageUploadsTaskActionBtnsTypes = 'START';
    let isStartUploadBtnActive = true;

    // if images are currently uploading activate CANCEL button
    if (isImagesUploading) {
      activeBtn = 'CANCEL';
    } else if (selectedUploadImagesList) {
      const { imageUploadAttempted, imageUploadFailed } =
        this.getImageUploadStatus();

      // if images upload attempt were made and there are non uploaded images in the 'selectedUploadImagesList' activate RETRY button
      if (imageUploadAttempted) {
        // disable start upload button if there was a previous attempt; only retry and cancel should be active further.
        isStartUploadBtnActive = false;

        if (imageUploadFailed) {
          activeBtn = 'RETRY';
        }
      }
    }

    const uploadDisabled =
      !isStartUploadBtnActive ||
      !selectedUploadImagesList ||
      selectedUploadImagesList.length < 1;

    return (
      <div className={styles.taskActionBtnsWrapper}>
        {activeBtn === 'CANCEL' ? (
          <Tooltip
            placement="bottom"
            title="Cancel"
            overlayStyle={{ zIndex: 10002 }}
          >
            {/* TODO: change button to label with similar styling */}
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className={styles.inputButtonLabel}
              onClick={() => {
                this.cancelUploading();
              }}
            >
              <div
                className={classnames(
                  'ant-btn ant-btn-primary',
                  styles.actionBtn
                )}
              >
                <i className="fa fa-times" aria-hidden="true" />
                <span>CANCEL UPLOAD</span>
              </div>
            </label>
          </Tooltip>
        ) : activeBtn === 'RETRY' ? (
          <Tooltip
            placement="bottom"
            title="Retry"
            overlayStyle={{ zIndex: 10002 }}
          >
            {/* TODO: change button to label with similar styling */}
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className={styles.inputButtonLabel}
              onClick={() => {
                this.handleUploadBtn();
              }}
            >
              <div
                className={classnames(
                  'ant-btn ant-btn-primary',
                  styles.actionBtn
                )}
              >
                <i className="fa fa-repeat" aria-hidden="true" />
                <span>RETRY UPLOAD</span>
              </div>
            </label>
          </Tooltip>
        ) : (
          <Tooltip
            placement="bottom"
            title="Upload"
            overlayStyle={{ zIndex: 10002 }}
          >
            {/* TODO: change button to label with similar styling */}
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className={styles.inputButtonLabel}
              onClick={
                uploadDisabled
                  ? () => {}
                  : () => {
                      this.handleUploadBtn();
                    }
              }
            >
              <div
                className={classnames(
                  'ant-btn',
                  uploadDisabled ? '' : 'ant-btn-primary',
                  styles.actionBtn,
                  {
                    [styles.disabled]: uploadDisabled,
                  }
                )}
              >
                <i className="fa fa-upload" aria-hidden="true" />
                <span>START UPLOAD</span>
              </div>
            </label>
          </Tooltip>
        )}
      </div>
    );
  };

  private RenderProgressBarSection = (
    args: MissionImageUploadsUploadInformationTypes
  ): JSX.Element | null => {
    const { isImagesUploading } = this.state;

    const { percUploadCompletedInQueue, totalImagesInQueue } = args;

    return (
      <div className={styles.progressContainer}>
        {isImagesUploading ? (
          <div className={styles.progressbarWrapper}>
            <div className={styles.progressbarInfoWrapper}>
              <Text className={styles.count}>
                {`Uploading: ${totalImagesInQueue} files | ${percUploadCompletedInQueue}% complete`}
              </Text>
            </div>

            <div className={styles.progressbar}>
              <div
                style={{
                  width: `${percUploadCompletedInQueue}%`,
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  private RenderAlertInfoSection = (): JSX.Element | null => {
    const { isImagesUploading } = this.state;

    if (isImagesUploading) {
      return null;
    }

    const { imageUploadCancelled, imageUploadFailed, imageUploadAttempted } =
      this.getImageUploadStatus();

    if (!imageUploadAttempted) {
      return null;
    }

    return (
      <div className={styles.alertContainer}>
        {imageUploadCancelled ? (
          <div>
            <i className="fa fa-times" aria-hidden="true" />
            <Text>The upload was cancelled</Text>
          </div>
        ) : !imageUploadFailed ? (
          <div>
            <i className="fa fa-check-circle" aria-hidden="true" />
            <Text>The upload was successful</Text>
          </div>
        ) : (
          <div>
            <i className="fa fa-times" aria-hidden="true" />
            <span>
              <a
                onClick={() => {
                  this.handleShowErrorsModal(true);
                }}
              >
                The upload failed
              </a>
            </span>
          </div>
        )}
      </div>
    );
  };

  private RenderUploadInfoSection = (
    args: MissionImageUploadsUploadInformationTypes
  ): JSX.Element | null => {
    const {
      totalImagesInQueue,
      totalSuccessImagesInQueue,
      totalFailedImagesInQueue,
      totalPendingImagesInQueue,
      totalDuplicateImagesInQueue,
    } = args;

    return (
      <div className={styles.infoContainer}>
        <div className={classnames(styles.item, styles.success)}>
          <div>{totalSuccessImagesInQueue}</div>
          <p>Uploaded</p>
        </div>

        <div className={classnames(styles.item, styles.failed)}>
          <div>{totalFailedImagesInQueue}</div>
          <p>Failed</p>
        </div>

        <div className={styles.item}>
          <div>{totalDuplicateImagesInQueue}</div>
          <p>Duplicates</p>
        </div>

        <div className={styles.item}>
          <div>{totalPendingImagesInQueue}</div>
          <p>Pending</p>
        </div>

        <div className={styles.item}>
          <div>{totalImagesInQueue}</div>
          <p>Total in Queue</p>
        </div>
      </div>
    );
  };

  public RenderImageClassificationSection = () => {
    const {
      missionImagesList,
      flightPlansDataList,
      isSegregateImagesBtnLoading,
      isImagesUploading,
    } = this.state;

    const imageCount: {
      [key in SelectedFlightPlanType]: number | null;
    } = {
      NADIR: null,
      PERSPECTIVE: null,
      EXTERNAL_360: null,
      INSPECTION: null,
      THERMAL_NADIR: null,
      THERMAL_INSPECTION: null,
      ALL: 0,
    };

    const _flightPlansDataList: GenericObjectType = {};

    (flightPlansDataList || []).map((a: GenericObjectType) => {
      _flightPlansDataList[a.id] = {
        ...a,
      };

      // we are marking the available flight plans for this instance
      // so that only the available count are displayed
      imageCount[a.type] = 0;

      return _flightPlansDataList;
    });

    (missionImagesList || []).map((a) => {
      if (!a.flightPlanId) {
        imageCount.ALL = (imageCount.ALL || 0) + 1;
      }

      if (_flightPlansDataList[a.flightPlanId]) {
        const { type } = _flightPlansDataList[a.flightPlanId];

        imageCount[type] = (imageCount[type] || 0) + 1;
      }

      return a;
    });

    const listData: JSX.Element[] = [];

    Object.keys(imageCount).map((a) => {
      const count = imageCount[a];

      if (undefinedOrNull(count)) {
        return null;
      }

      listData.push(
        <React.Fragment key={a}>
          <div className={styles.contentWrapper}>
            <span className={styles.title}>
              {uploadedFlightPlanTypeList[a].label}
            </span>

            <span className={styles.desc}>Total - {count}</span>
          </div>

          <div className={styles.actionbarWrapper}>
            {a === 'ALL' && (
              <span>
                <Popconfirm
                  placement="top"
                  title={
                    <div>
                      <span>
                        {imageCount.ALL} images will be segregated based on
                        image source type. Proceed?
                      </span>
                    </div>
                  }
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    this.handleSegregateImages();
                  }}
                  overlayStyle={{ zIndex: 10002 }}
                >
                  <Button
                    loading={isSegregateImagesBtnLoading}
                    loadingText="Segregating Images..."
                    disabled={
                      !missionImagesList ||
                      missionImagesList.length < 1 ||
                      !imageCount.ALL
                    }
                    className={styles.button}
                  >
                    SEGREGATE IMAGES
                  </Button>
                </Popconfirm>
              </span>
            )}
          </div>
        </React.Fragment>
      );

      return a;
    });

    return (
      <div className={styles.imageClassificationContainer}>
        <div className={styles.titleWrapper}>
          <Text>Image Classification</Text>
        </div>

        <div className={styles.bodyWrapper}>
          {listData.map((a, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.bodyItemWrapper} key={index}>
              {a}
            </div>
          ))}
        </div>

        {!isImagesUploading && (
          <div className={styles.manageUploadedImagesWrapper}>
            <a onClick={this.handleManageUploadedImagesClick}>
              Manage Uploaded Images
            </a>
          </div>
        )}
      </div>
    );
  };

  private RenderMapViewSection = (): JSX.Element | null => {
    const {
      boundaryPointsList,
      mapGeneratedTime,
      selectedMarkerIndex,
      boundaryGeometryAndBuffer,
    } = this.state;

    const markerPointsList = this.getImagesMarkerPointsList();

    const pointsOnMap = markerPointsList ?? [];
    const currentImage = pointsOnMap[selectedMarkerIndex]?.piggyback;

    const pitch = currentImage ? currentImage.gimbalPitch : 0;
    const yaw = currentImage ? currentImage.gimbalYaw : 0;
    let customHaloMarker: any;

    if (!(pitch > -91 && pitch <= -89)) {
      customHaloMarker = {
        icon: {
          halo: {
            type: 'FORWARD_OPEN_ARROW',
            scale: 10,
            fillColor: APP_PRIMARY_COLOR,
            strokeColor: APP_PRIMARY_COLOR,
            rotation: 180 + yaw,
          },
          point: {
            color: APP_PRIMARY_COLOR,
            type: 'DEFAULT',
          },
        },
      };
    }

    return (
      <div className={styles.mapContainer}>
        <div className={styles.mapWrapper}>
          <div>
            {isVimanaLite() ? (
              <div style={{ height: '70vh', width: '100%' }}>
                <OSMMapContainer>
                  <VectorLayerContainer>
                    <SatelliteTiles />
                    <ReadOnlyPolygon
                      coordinates={[
                        boundaryPointsList.map((p) => [p.lng, p.lat]),
                      ]}
                    />
                  </VectorLayerContainer>
                  <VectorLayerContainer>
                    <ReadOnlyPoints
                      coordinates={
                        markerPointsList?.map((p) => [p.pos.lng, p.pos.lat]) ??
                        []
                      }
                      style={getGrayedOutStyle('Point')}
                    />
                    <SelectFeatures
                      onFeaturesSelect={this.handleFeatureSelect}
                    />
                  </VectorLayerContainer>
                  <CenterMap
                    geometryWithScaleBuffer={boundaryGeometryAndBuffer}
                  />
                  <VectorLayerContainer>
                    <ReadOnlyPoints
                      coordinates={[
                        markerPointsList?.map((p) => [p.pos.lng, p.pos.lat])[
                          selectedMarkerIndex
                        ] ?? [],
                      ]}
                      zIndex={100}
                    />
                  </VectorLayerContainer>
                </OSMMapContainer>
              </div>
            ) : (
              <PlotGoogleMap
                selectedMarkerIndex={selectedMarkerIndex}
                mapGeneratedTime={mapGeneratedTime}
                width="100%"
                height="70vh"
                boundaryPointsList={[boundaryPointsList]}
                markerPointsList={markerPointsList ?? []}
                centerMapTo={
                  boundaryPointsList.length > 0 ? 'boundary' : 'marker'
                }
                gestureHandling="auto"
                onMarkerClick={this.handleMarkerClick}
                ref={(n) => {
                  if (!n) {
                    return;
                  }

                  this.plotGoogleMapRef = n;
                }}
                maintainZoomLevel={(markerPointsList ?? []).length > 0}
                customHaloMarker={customHaloMarker}
              />
            )}
            {!markerPointsList ? <LoadingOverlay position="absolute" /> : null}
          </div>
        </div>
      </div>
    );
  };

  private addPaddingForDateString = (date: string): string[] => {
    const TIME_STRING_LENGTH = 8; // hh:mm:ss
    const lastSpaceIndex = date.lastIndexOf(' ');
    const secondLastSpaceIndex = lastSpaceIndex - TIME_STRING_LENGTH;
    const datePart = date.slice(0, secondLastSpaceIndex).padEnd(2, ' ');
    const timePart = date
      .slice(secondLastSpaceIndex, lastSpaceIndex)
      .padEnd(2, ' ');
    const AMorPMPart = date.slice(lastSpaceIndex);

    return [datePart, timePart, AMorPMPart];
  };

  private RenderSliderFilter = ({
    dataSourceList,
    fieldName,
    setValues,
    confirm,
  }: {
    dataSourceList: GenericObjectType[];
    fieldName: string;
    setValues?: (
      itemList: GenericObjectType[],
      dataStoreValue: [number, number] | (number | string)[],
      dataSourceList: GenericObjectType[]
    ) => {
      min?: number;
      max?: number;
      minText?: string;
      maxText?: string;
      selectedMinText?: string;
      selectedMaxText?: string;
    };
    confirm: (props: FilterConfirmProps) => void;
  }): JSX.Element => {
    const { dataListFilterStore, dataListFilterStoreTemp } = this.state;

    const itemList = dataSourceList
      .map((a: GenericObjectType) => a[fieldName])
      .filter((a) => !undefinedOrNull(a));

    if (!itemList || itemList.length < 1) {
      return (
        <div className={styles.filterContainer}>
          <div className={styles.filtersTextWrapper}>
            <Text>Filters are not available</Text>
          </div>
        </div>
      );
    }

    let min: number;
    let max: number;
    let minText: string;
    let maxText: string;
    let selectedMinText: string;
    let selectedMaxText: string;

    let dataFilterTypeValue = dataListFilterStore[fieldName] ?? {};

    if (dataListFilterStoreTemp[fieldName]) {
      dataFilterTypeValue = dataListFilterStoreTemp[fieldName];
    }

    const { value: dataStoreValue } = dataFilterTypeValue;

    if (setValues) {
      const _values = setValues(itemList, dataStoreValue, dataSourceList);

      min = _values.min ?? Math.min(...itemList);
      max = _values.max ?? Math.max(...itemList);

      minText = _values.minText ?? `${min}`;
      maxText = _values.maxText ?? `${max}`;

      selectedMinText = _values.selectedMinText ?? `${min}`;
      selectedMaxText = _values.selectedMaxText ?? `${max}`;
    } else {
      min = Math.min(...itemList);
      max = Math.max(...itemList);

      minText = `${min}`;
      maxText = `${max}`;

      if (fieldName === 'capturedAtTimestamp') {
        const selectedMin = dataStoreValue ? dataStoreValue[0] : min;

        selectedMinText = moment
          .unix(selectedMin as number)
          .format('MMM DD, YYYY hh:mm:ss A');

        const selectedMax = dataStoreValue ? dataStoreValue[1] : max;

        selectedMaxText = moment
          .unix(selectedMax as number)
          .format('MMM DD, YYYY hh:mm:ss A');
      } else {
        selectedMinText = `${dataStoreValue ? dataStoreValue[0] : min}`;
        selectedMaxText = `${dataStoreValue ? dataStoreValue[1] : max}`;
      }
    }

    const selectedDefaultValue: [number, number] = [
      dataStoreValue ? Number(dataStoreValue[0]) : min,
      dataStoreValue ? Number(dataStoreValue[1]) : max,
    ];

    return (
      <div className={styles.filterContainer}>
        <div className={styles.sliderWrapper}>
          {fieldName !== 'capturedAtTimestamp' ? (
            <div className={styles.minMaxTextWrapper}>
              <Text>{minText}</Text>
              <Text>{maxText}</Text>
            </div>
          ) : null}
          <Slider
            min={min}
            max={max}
            range
            onChange={(value: [number, number]) => {
              this.handleDataListFilterStore(fieldName, {
                value,
                type: 'range',
              });
            }}
            step={fieldName === 'capturedAtTimestamp' ? 1 : 0.01}
            defaultValue={selectedDefaultValue}
            getTooltipPopupContainer={() =>
              this.tooltipContainerRef?.current as HTMLDivElement
            }
            tipFormatter={(value) => {
              if (fieldName !== 'capturedAtTimestamp' || value === undefined) {
                return value;
              }

              return moment
                .unix(value as number)
                .format('MMM DD, YYYY hh:mm:ss A');
            }}
          />
        </div>
        <div className={styles.sliderWrapper}>
          {fieldName === 'capturedAtTimestamp' ? (
            <div>
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text strong>START</Text>
                <Text strong>END</Text>
              </p>
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  {this.addPaddingForDateString(selectedMinText).map((part) => (
                    <Text style={{ paddingRight: '0.4em' }}>{part}</Text>
                  ))}
                </div>
                <div>
                  {this.addPaddingForDateString(selectedMaxText).map((part) => (
                    <Text style={{ paddingRight: '0.4em' }}>{part}</Text>
                  ))}
                </div>
              </p>
            </div>
          ) : (
            <div>Showing: {`${selectedMinText} to ${selectedMaxText}`}</div>
          )}
        </div>
        <div className={styles.ctaWrapper}>
          <Button
            onClick={() => {
              this.processDataListFilterStore(false);
              confirm({ closeDropdown: true });
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.processDataListFilterStore(true);
              confirm({ closeDropdown: true });
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  private RenderCheckboxFilter = ({
    dataSourceList,
    fieldName,
    confirm,
  }: {
    dataSourceList: GenericObjectType[];
    fieldName: string;
    confirm: (props: FilterConfirmProps) => void;
  }): JSX.Element => {
    const { dataListFilterStore, dataListFilterStoreTemp } = this.state;

    const sortedFilterItemList = sortByName(dataSourceList, fieldName);
    const filterItemList = sortedFilterItemList
      .map((a: GenericObjectType) => a[fieldName])
      .filter((a) => !undefinedOrNull(a));

    const filterItemListNoDuplicates = arrayRemoveDuplicate(filterItemList);

    const _filterItemList = filterItemListNoDuplicates.map((a) => ({
      label: this.getMetaDataValue(fieldName, a),
      value: a,
    }));

    let defaultValue: string[] = [];
    let dataFilterTypeValue = dataListFilterStore[fieldName];

    if (dataListFilterStoreTemp[fieldName]) {
      dataFilterTypeValue = dataListFilterStoreTemp[fieldName];
    }

    if (dataFilterTypeValue) {
      defaultValue = dataFilterTypeValue.value as string[];
    } else {
      defaultValue = filterItemListNoDuplicates as string[];
    }

    if (!filterItemList || filterItemList.length < 1) {
      return (
        <div className={styles.filterContainer}>
          <div className={styles.filtersTextWrapper}>
            <Text>Filters are not available</Text>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.filterContainer}>
        <CheckboxGroup
          options={_filterItemList}
          onChange={(value: (number | string)[]) => {
            this.handleDataListFilterStore(fieldName, {
              value,
              type: 'equals',
            });
          }}
          className={styles.optionWrapper}
          value={defaultValue}
        />

        <div className={styles.ctaWrapper}>
          <Button
            onClick={() => {
              this.processDataListFilterStore(false);
              confirm({ closeDropdown: true });
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.processDataListFilterStore(true);
              confirm({ closeDropdown: true });
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  private RenderLatLongMapFilter = ({
    dataSourceList,
    fieldName,
  }: {
    dataSourceList: PlotGoogleMapMarkerPointsTypes[] | null;
    fieldName: string;
  }): JSX.Element | null => {
    const { boundaryPointsList, boundaryGeometryAndBuffer } = this.state;

    const markerPointsList = dataSourceList;
    const { filtered: filteredDataSource } =
      this.getMissionImagesDataSourceList();

    return (
      <div className={styles.mapContainer}>
        <div className={styles.infoWrapper}>
          <Text>Filtered Images: {(filteredDataSource ?? []).length}</Text>
        </div>
        <div className={styles.mapWrapper}>
          <div>
            {isVimanaLite() ? (
              <>
                <div style={{ height: '450px', width: '100%' }}>
                  <OSMMapContainer>
                    <VectorLayerContainer>
                      <SatelliteTiles />
                      <ReadOnlyPolygon
                        coordinates={[
                          boundaryPointsList.map((p) => [p.lng, p.lat]),
                        ]}
                      />
                    </VectorLayerContainer>
                    <VectorLayerContainer>
                      <ReadOnlyPoints
                        coordinates={
                          markerPointsList?.map((p) => [
                            p.pos.lng,
                            p.pos.lat,
                          ]) ?? []
                        }
                        style={getGrayedOutStyle('Point')}
                      />
                    </VectorLayerContainer>
                    <VectorLayerContainer>
                      <ReadOnlyPoints
                        coordinates={filteredDataSource?.map((p) => [
                          p.longitude,
                          p.latitude,
                        ])}
                        zIndex={100}
                      />
                      <CenterMap
                        geometryWithScaleBuffer={boundaryGeometryAndBuffer}
                      />
                    </VectorLayerContainer>
                    <VectorLayerContainer>
                      <DrawAndEditPolygon
                        coordinates={this.latLongFilterBoundary}
                        onGeoJsonFeatureEdit={async (f) =>
                          this.handleOpenLayersLatLongFilterPolygonsAdded(
                            f,
                            fieldName
                          )
                        }
                        onGeoJsonFeatureCreate={async (f) =>
                          this.handleOpenLayersLatLongFilterPolygonsAdded(
                            f,
                            fieldName
                          )
                        }
                      />
                      <ResetControl
                        onReset={() =>
                          this.handleOpenLayersLatLongFilterPolygonsAdded(
                            undefined,
                            fieldName
                          )
                        }
                      />
                    </VectorLayerContainer>
                  </OSMMapContainer>
                </div>
              </>
            ) : (
              <PlotGoogleMap
                width="100%"
                height="450px"
                boundaryPointsList={[boundaryPointsList]}
                markerPointsList={markerPointsList ?? []}
                showHaloMarker
                centerMapTo={
                  boundaryPointsList.length > 0 ? 'boundary' : 'marker'
                }
                gestureHandling="auto"
                drawManagerOptions={{
                  modesOptions: {
                    polygon: {
                      editable: true,
                    },
                  },
                  events: (data, type, dataList) => {
                    this.handleLatLongFilterPolygonsAddedEvent(
                      data,
                      type,
                      dataList,
                      fieldName
                    );
                  },
                }}
                uniqueShape
                shapeList={{
                  polygon: this.latLongFilterBoundary.map((a) => ({
                    editable: true,
                    draggable: false,
                    coordinates: a,
                  })),
                }}
                maintainZoomLevel={(markerPointsList ?? []).length > 0}
              />
            )}

            {!markerPointsList ? <LoadingOverlay position="absolute" /> : null}
          </div>
        </div>
      </div>
    );
  };

  private RenderDateTimeFilter = ({
    dataSourceList,
    fieldName,
    confirm,
  }: {
    dataSourceList: GenericObjectType[];
    fieldName: string;
    confirm: (props: FilterConfirmProps) => void;
  }): JSX.Element => {
    const { dataListFilterStore, dataListFilterStoreTemp } = this.state;

    const timeFormat = 'HH:mm:ss';

    const sortedSourceItemList = sortByName(dataSourceList, fieldName);
    const itemList = sortedSourceItemList.map((a: GenericObjectType) =>
      parseInt(`${a[fieldName]}`, 10)
    );
    const itemListNoDuplicates: number[] = arrayRemoveDuplicate(
      itemList
    ) as number[];

    let defaultStoreValueList: number[] = [];
    let itemDataStoreValue = dataListFilterStore[fieldName];

    if (dataListFilterStoreTemp[fieldName]) {
      itemDataStoreValue = dataListFilterStoreTemp[fieldName];
    }

    if (itemDataStoreValue) {
      defaultStoreValueList = itemDataStoreValue.value as number[];
    } else {
      defaultStoreValueList = itemListNoDuplicates as number[];
    }

    const startDateTime = Math.min(...itemListNoDuplicates);
    const endDateTime = Math.max(...itemListNoDuplicates);

    let defaultValue = [startDateTime, endDateTime];

    if (defaultStoreValueList && defaultStoreValueList.length > 1) {
      defaultValue = [
        Math.min(...defaultStoreValueList),
        Math.max(...defaultStoreValueList),
      ];
    }

    /*
        const startTime = moment.unix(startDateTime).format(timeFormat);
        const endTime = moment.unix(endDateTime).format(timeFormat);

          const startTimeList = startTime
          .split(':')
          .map(a => parseInt(`${a}`, 10)) as [number, number, number];
        const endTimeList: [number, number, number] = endTime
          .split(':')
          .map(a => parseInt(`${a}`, 10)) as [number, number, number];
        */

    return (
      <div className={styles.filterContainer}>
        <RangePicker
          showTime={{ format: timeFormat }}
          format="MMM DD, YYYY hh:mm A"
          placeholder={['Start Time', 'End Time']}
          dropdownClassName={`antCalendar ${styles.calendarContainer}`}
          defaultValue={[
            moment.unix(defaultValue[0]),
            moment.unix(defaultValue[1]),
          ]}
          disabledDate={(current: moment.Moment) => {
            return this.getCalendarDisabledDate(
              current,
              startDateTime,
              endDateTime
            );
          }}
          /* disabledTime={(current: moment.Moment, type: string) => {
            return this.getCalendarDisabledTime(
              current,
              type,
              startTimeList,
              endTimeList
            );
          }} */
          onOk={(value: [moment.Moment, moment.Moment]) => {
            this.handleDataListFilterStore(fieldName, {
              value: [value[0].unix(), value[1].unix()],
              type: 'range',
            });
          }}
          className={styles.dateWrapper}
        />

        <div className={styles.ctaWrapper}>
          <Button
            onClick={() => {
              this.processDataListFilterStore(false);
              confirm({ closeDropdown: true });
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.processDataListFilterStore(true);
              confirm({ closeDropdown: true });
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  private RenderFilterModal = ({
    filterNode,
    fieldName,
    isLatLongMap,
    visible,
    confirm,
  }: {
    filterNode: JSX.Element | null;
    fieldName: string;
    isLatLongMap?: boolean;
    visible?: boolean;
    confirm: (props?: FilterConfirmProps) => void;
  }): JSX.Element | null => {
    const { flightPlansDataList } = this.state;

    if (!flightPlansDataList) {
      return null;
    }

    return (
      <Modal
        title={dataTableExifDict[fieldName]}
        centered
        footer={null}
        visible={visible}
        onCancel={() => {
          confirm({ closeDropdown: true });
        }}
        destroyOnClose
        maskClosable
        zIndex={10003}
        className={classnames(styles.filterModal, {
          [styles.latLongMap]: isLatLongMap,
        })}
      >
        <div>{filterNode}</div>
      </Modal>
    );
  };

  private RenderListViewSection = (): JSX.Element | null => {
    const { listItemsPerPage } = this.state;

    const {
      RenderCheckboxFilter,
      RenderSliderFilter,
      RenderLatLongMapFilter,
      RenderFilterModal,
    } = this;

    const availableMissionRequirementsType =
      this.getAvailableMissionRequirementsType();

    const { filtered: filteredDataSource, unFiltered: unFilteredDataSource } =
      this.getMissionImagesDataSourceList();

    const forMapsCol: ColumnType<unknown> = {
      title: dataTableExifDict.forMaps,
      dataIndex: 'forMaps',
      render: (value: boolean) => (
        <span className={classnames(styles.widthFix)}>{value}</span>
      ),
      sorter: (a: GenericStringType, b: GenericStringType) => {
        return _sortByName(a, b, 'forMaps');
      },
      sortDirections: ['descend', 'ascend'],
      width: 100,
      filterDropdown: ({ confirm, visible }) => {
        return (
          <RenderFilterModal
            confirm={confirm}
            visible={visible}
            filterNode={
              <RenderCheckboxFilter
                dataSourceList={unFilteredDataSource}
                fieldName="forMaps"
                confirm={confirm}
              />
            }
            fieldName="forMaps"
          />
        );
      },
    };

    const forThermalMapCol: ColumnType<unknown> = {
      title: dataTableExifDict.forThermalMap,
      dataIndex: 'forThermalMap',
      render: (value: boolean) => (
        <span className={classnames(styles.widthFix)}>{value}</span>
      ),
      sorter: (a: GenericStringType, b: GenericStringType) => {
        return _sortByName(a, b, 'forThermalMap');
      },
      sortDirections: ['descend', 'ascend'],
      width: 100,
      filterDropdown: ({ confirm, visible }) => {
        return (
          <RenderFilterModal
            confirm={confirm}
            visible={visible}
            filterNode={
              <RenderCheckboxFilter
                dataSourceList={unFilteredDataSource}
                fieldName="forThermalMap"
                confirm={confirm}
              />
            }
            fieldName="forThermalMap"
          />
        );
      },
    };

    const forPerspectiveCol: ColumnType<unknown> = {
      title: dataTableExifDict.forPerspective,
      dataIndex: 'forPerspective',
      render: (value: boolean) => (
        <span className={classnames(styles.widthFix)}>{value}</span>
      ),
      sorter: (a: GenericStringType, b: GenericStringType) => {
        return _sortByName(a, b, 'forPerspective');
      },
      sortDirections: ['descend', 'ascend'],
      width: 120,
      filterDropdown: ({ confirm, visible }) => {
        return (
          <RenderFilterModal
            confirm={confirm}
            visible={visible}
            filterNode={
              <RenderCheckboxFilter
                dataSourceList={unFilteredDataSource}
                fieldName="forPerspective"
                confirm={confirm}
              />
            }
            fieldName="forPerspective"
          />
        );
      },
    };

    const for360Col: ColumnType<unknown> = {
      title: dataTableExifDict.for360,
      dataIndex: 'for360',
      render: (value: boolean) => (
        <span className={classnames(styles.widthFix)}>{value}</span>
      ),
      sorter: (a: GenericStringType, b: GenericStringType) => {
        return _sortByName(a, b, 'for360');
      },
      sortDirections: ['descend', 'ascend'],
      width: 120,
      filterDropdown: ({ confirm, visible }) => {
        return (
          <RenderFilterModal
            confirm={confirm}
            visible={visible}
            filterNode={
              <RenderCheckboxFilter
                dataSourceList={unFilteredDataSource}
                fieldName="for360"
                confirm={confirm}
              />
            }
            fieldName="for360"
          />
        );
      },
    };

    const forInspectionCol: ColumnType<unknown> = {
      title: dataTableExifDict.forInspection,
      dataIndex: 'forInspection',
      render: (value: boolean) => (
        <span className={classnames(styles.widthFix)}>{value}</span>
      ),
      sorter: (a: GenericStringType, b: GenericStringType) => {
        return _sortByName(a, b, 'forInspection');
      },
      sortDirections: ['descend', 'ascend'],
      width: 120,
      filterDropdown: ({ confirm, visible }) => {
        return (
          <RenderFilterModal
            confirm={confirm}
            visible={visible}
            filterNode={
              <RenderCheckboxFilter
                dataSourceList={unFilteredDataSource}
                fieldName="forInspection"
                confirm={confirm}
              />
            }
            fieldName="forInspection"
          />
        );
      },
    };

    const forThermalInspectionCol: ColumnType<unknown> = {
      title: dataTableExifDict.forThermalInspection,
      dataIndex: 'forThermalInspection',
      render: (value: boolean) => (
        <span className={classnames(styles.widthFix)}>{value}</span>
      ),
      sorter: (a: GenericStringType, b: GenericStringType) => {
        return _sortByName(a, b, 'forThermalInspection');
      },
      sortDirections: ['descend', 'ascend'],
      width: 150,
      filterDropdown: ({ confirm, visible }) => {
        return (
          <RenderFilterModal
            confirm={confirm}
            visible={visible}
            filterNode={
              <RenderCheckboxFilter
                dataSourceList={unFilteredDataSource}
                fieldName="forThermalInspection"
                confirm={confirm}
              />
            }
            fieldName="forThermalInspection"
          />
        );
      },
    };

    let columns: ColumnType<unknown>[] = [
      {
        title: dataTableExifDict.originalFilename,
        dataIndex: 'originalFilename',
        render: (value: string, row: NullOrGenericObjectType) => {
          const { RenderListViewImagePreviewSection } = this;

          return (
            <Popover
              content={
                <RenderListViewImagePreviewSection guid={row?.guid ?? null} />
              }
              title="Image Preview"
              trigger="hover"
              overlayStyle={{ zIndex: 10002 }}
              placement="rightTop"
            >
              <span
                className={classnames(
                  styles.widthFix,
                  styles.listViewImageNames
                )}
              >
                {value}
              </span>
            </Popover>
          );
        },
        sorter: (a: GenericStringType, b: GenericStringType) => {
          return _sortByName(a, b, 'originalFilename');
        },
        sortDirections: ['descend', 'ascend'],
        width: 150,
      },
      {
        title: dataTableExifDict.capturedAt,
        dataIndex: 'capturedAtTimestamp',
        render: (value: string) => {
          const _value = this.getMetaDataValue('capturedAtTimestamp', value);

          return <span className={classnames(styles.widthFix)}>{_value}</span>;
        },
        sorter: (a: GenericStringType, b: GenericStringType) => {
          return _sortByName(a, b, 'capturedAtTimestamp');
        },
        sortDirections: ['descend', 'ascend'],
        width: 200,
        filterDropdown: ({ confirm, visible }) => {
          return (
            <RenderFilterModal
              confirm={confirm}
              visible={visible}
              filterNode={
                <RenderSliderFilter
                  dataSourceList={unFilteredDataSource}
                  fieldName="capturedAtTimestamp"
                  confirm={confirm}
                />
              }
              fieldName="capturedAtTimestamp"
            />
          );
        },
      },
      {
        title: dataTableExifDict.gimbalYaw,
        dataIndex: 'gimbalYaw',
        render: (value: string) => (
          <span className={classnames(styles.widthFix)}>{value}</span>
        ),
        sorter: (a: GenericStringType, b: GenericStringType) => {
          return _sortByName(a, b, 'gimbalYaw');
        },
        sortDirections: ['descend', 'ascend'],
        width: 100,
        filterDropdown: ({ confirm, visible }) => {
          return (
            <RenderFilterModal
              confirm={confirm}
              visible={visible}
              filterNode={
                <RenderSliderFilter
                  dataSourceList={unFilteredDataSource}
                  fieldName="gimbalYaw"
                  confirm={confirm}
                />
              }
              fieldName="gimbalYaw"
            />
          );
        },
      },
      {
        title: dataTableExifDict.gimbalPitch,
        dataIndex: 'gimbalPitch',
        render: (value: string) => (
          <span className={classnames(styles.widthFix)}>{value}</span>
        ),
        sorter: (a: GenericStringType, b: GenericStringType) => {
          return _sortByName(a, b, 'gimbalPitch');
        },
        sortDirections: ['descend', 'ascend'],
        width: 100,
        filterDropdown: ({ confirm, visible }) => {
          return (
            <RenderFilterModal
              confirm={confirm}
              visible={visible}
              filterNode={
                <RenderSliderFilter
                  dataSourceList={unFilteredDataSource}
                  fieldName="gimbalPitch"
                  confirm={confirm}
                />
              }
              fieldName="gimbalPitch"
            />
          );
        },
      },
      {
        title: dataTableExifDict.elevation,
        dataIndex: 'elevation',
        render: (value: string) => {
          return <span className={classnames(styles.widthFix)}>{value}</span>;
        },
        sorter: (a: GenericStringType, b: GenericStringType) => {
          return _sortByName(a, b, 'elevation');
        },
        sortDirections: ['descend', 'ascend'],
        width: 100,
        filterDropdown: ({ confirm, visible }) => {
          return (
            <RenderFilterModal
              confirm={confirm}
              visible={visible}
              filterNode={
                <RenderSliderFilter
                  dataSourceList={unFilteredDataSource}
                  fieldName="elevation"
                  confirm={confirm}
                />
              }
              fieldName="elevation"
            />
          );
        },
      },
      {
        title: dataTableExifDict.fNumber,
        dataIndex: 'fNumber',
        render: (value: string) => {
          const _value = this.getMetaDataValue('fNumber', value);

          return <span className={classnames(styles.widthFix)}>{_value}</span>;
        },
        sorter: (a: GenericStringType, b: GenericStringType) => {
          return _sortByName(a, b, 'fNumber');
        },
        sortDirections: ['descend', 'ascend'],
        width: 100,
        filterDropdown: ({ confirm, visible }) => {
          return (
            <RenderFilterModal
              confirm={confirm}
              visible={visible}
              filterNode={
                <RenderCheckboxFilter
                  dataSourceList={unFilteredDataSource}
                  fieldName="fNumber"
                  confirm={confirm}
                />
              }
              fieldName="fNumber"
            />
          );
        },
      },
      {
        title: dataTableExifDict.exposureTime,
        dataIndex: 'exposureTime',
        render: (value: string) => {
          const _value = this.getMetaDataValue('exposureTime', value);

          return <span className={classnames(styles.widthFix)}>{_value}</span>;
        },
        sorter: (a: GenericStringType, b: GenericStringType) => {
          return _sortByName(a, b, 'exposureTime');
        },
        sortDirections: ['descend', 'ascend'],
        width: 120,
        filterDropdown: ({ confirm, visible }) => {
          return (
            <RenderFilterModal
              confirm={confirm}
              visible={visible}
              filterNode={
                <RenderCheckboxFilter
                  dataSourceList={unFilteredDataSource}
                  fieldName="exposureTime"
                  confirm={confirm}
                />
              }
              fieldName="exposureTime"
            />
          );
        },
      },
      {
        title: dataTableExifDict.latitudeLongitude,
        dataIndex: 'latitudeLongitude',
        render: (value: string) => {
          return <span className={classnames(styles.widthFix)}>{value}</span>;
        },
        sorter: (a: GenericStringType, b: GenericStringType) => {
          return _sortByName(a, b, 'latitude');
        },
        sortDirections: ['descend', 'ascend'],
        width: 200,
        filterDropdown: ({ confirm, visible }) => {
          return (
            <RenderFilterModal
              confirm={confirm}
              visible={visible}
              filterNode={
                <RenderLatLongMapFilter
                  dataSourceList={this.getLatLongFilterImagesMarkerPointsList()}
                  fieldName="latitudeLongitudeUntrimmed"
                />
              }
              fieldName="latitudeLongitudeUntrimmed"
              isLatLongMap
            />
          );
        },
      },
      {
        title: dataTableExifDict.isoSpeed,
        dataIndex: 'isoSpeed',
        render: (value: string) => {
          return (
            <span className={classnames(styles.widthFix)}>
              {!undefinedOrNull(value) ? value : '---'}
            </span>
          );
        },
        sorter: (a: GenericStringType, b: GenericStringType) => {
          return _sortByName(a, b, 'isoSpeed');
        },
        sortDirections: ['descend', 'ascend'],
        width: 130,
        filterDropdown: ({ confirm, visible }) => {
          return (
            <RenderFilterModal
              confirm={confirm}
              visible={visible}
              filterNode={
                <RenderSliderFilter
                  dataSourceList={unFilteredDataSource}
                  fieldName="isoSpeed"
                  confirm={confirm}
                />
              }
              fieldName="isoSpeed"
            />
          );
        },
      },
    ];

    let lastInsertedOptionalColIndex = 1;

    if (inArray(availableMissionRequirementsType, 'AERIAL_MAPS')) {
      lastInsertedOptionalColIndex += 1;

      columns = arrayInsert(columns, lastInsertedOptionalColIndex, forMapsCol);
    }

    if (inArray(availableMissionRequirementsType, 'THERMAL_MAP')) {
      lastInsertedOptionalColIndex += 1;

      columns = arrayInsert(
        columns,
        lastInsertedOptionalColIndex,
        forThermalMapCol
      );
    }

    if (inArray(availableMissionRequirementsType, 'PERSPECTIVE')) {
      lastInsertedOptionalColIndex += 1;

      columns = arrayInsert(
        columns,
        lastInsertedOptionalColIndex,
        forPerspectiveCol
      );
    }

    if (inArray(availableMissionRequirementsType, 'EXTERIOR_360')) {
      lastInsertedOptionalColIndex += 1;

      columns = arrayInsert(columns, lastInsertedOptionalColIndex, for360Col);
    }

    if (inArray(availableMissionRequirementsType, 'INSPECTION')) {
      lastInsertedOptionalColIndex += 1;

      columns = arrayInsert(
        columns,
        lastInsertedOptionalColIndex,
        forInspectionCol
      );
    }

    if (inArray(availableMissionRequirementsType, 'THERMAL_INSPECTION')) {
      lastInsertedOptionalColIndex += 1;

      columns = arrayInsert(
        columns,
        lastInsertedOptionalColIndex,
        forThermalInspectionCol
      );
    }

    if (
      inArray(availableMissionRequirementsType, 'THERMAL_INSPECTION') ||
      inArray(availableMissionRequirementsType, 'THERMAL_MAP')
    ) {
      lastInsertedOptionalColIndex += 1;

      columns = arrayInsert(columns, lastInsertedOptionalColIndex, {
        title: 'Thermal Image',
        dataIndex: 'thermalImage',
        render: (value: string) => {
          return <span className={classnames(styles.widthFix)}>{value}</span>;
        },
        sorter: (a: GenericStringType, b: GenericStringType) => {
          return _sortByName(a, b, 'thermalImage');
        },
        sortDirections: ['descend', 'ascend'],
        width: 150,
        filterDropdown: ({ confirm, visible }) => {
          return (
            <RenderFilterModal
              confirm={confirm}
              visible={visible}
              filterNode={
                <RenderCheckboxFilter
                  dataSourceList={unFilteredDataSource}
                  fieldName="thermalImage"
                  confirm={confirm}
                />
              }
              fieldName="thermalImage"
            />
          );
        },
      } as ColumnType<unknown>);
    }

    const rowSelection = {
      selectedRowKeys: this.getSelectedListViewRowKeysList(),
      onChange: (selectedRowKeys: string[]) => {
        this.setSelectedListViewRowKeysList(selectedRowKeys);
      },
      onSelectAll: (selected: boolean) => {
        this.setState({ isAllImagesOnPageSelected: selected });
      },
    };

    let totalTableWidth = 140;

    columns.map((a) => {
      totalTableWidth += a.width as number;

      return a;
    });

    return (
      <div className={styles.listViewContainer}>
        <Table
          // TODO: check if tableLayout affects output
          // tableLayout="fixed"
          locale={{ emptyText: 'No images available' }}
          // TODO: Fix Types and remove 'any'
          columns={columns as any[]}
          dataSource={filteredDataSource}
          rowKey={(item: GenericObjectType) => item.id}
          // TODO: Fix Types and remove 'any'
          rowSelection={rowSelection as any}
          scroll={{ y: 500, x: totalTableWidth }}
          pagination={{
            pageSize: listItemsPerPage,
          }}
        />
      </div>
    );
  };

  private RenderToolbar = ({
    type,
  }: {
    type: MissionImageUploadsViewType;
  }): JSX.Element | null => {
    const { updateImageTypeLoading } = this.state;

    const availableMissionRequirementsType =
      this.getAvailableMissionRequirementsType();
    const selectedListViewRowKeysList = this.getSelectedListViewRowKeysList();
    const disabledOptionsList = this.getTriggeredMissionDataRequirementsList();
    const triggeredMissionList = this.getTriggeredMissionList();

    const {
      unFiltered: unFilteredMissionImagesList,
      filtered: filteredMissionImagesList,
    } = this.getMissionImagesDataSourceList();

    const totalRowsSelected = selectedListViewRowKeysList.length;

    const allowedLabelList: {
      [key in UploadedImagesUseInTypes]: {
        y: number;
        n: number;
      };
    } = {
      useInMaps: {
        y: 0,
        n: 0,
      },
      useInPerspective: {
        y: 0,
        n: 0,
      },
      useInPanorama: {
        y: 0,
        n: 0,
      },
      useInInspection: {
        y: 0,
        n: 0,
      },
      useInThermalMap: {
        y: 0,
        n: 0,
      },
      useInThermalInspection: {
        y: 0,
        n: 0,
      },
    };

    unFilteredMissionImagesList.map((a) => {
      if (!inArray(selectedListViewRowKeysList, a.id)) {
        return a;
      }

      Object.keys(allowedLabelList).map((lKey) => {
        if (undefinedOrNull(a[lKey])) {
          return lKey;
        }

        if (a[lKey]) {
          allowedLabelList[lKey].y += 1;
        } else {
          allowedLabelList[lKey].n += 1;
        }

        return lKey;
      });

      return a;
    });

    const optionsList: {
      [key in UploadedImagesUseInTypes]: {
        label: {
          enable: string;
          disable: string;
        };
        value: UploadedImagesUseInTypes;
        isDisabled: boolean;
        isHidden: boolean;
      };
    } = {
      useInMaps: {
        label: {
          enable: `Include selected image${
            totalRowsSelected > 1 ? 's' : ''
          } for Maps processing`,
          disable: `Exclude selected image${
            totalRowsSelected > 1 ? 's' : ''
          } for Maps processing`,
        },
        value: 'useInMaps',
        isDisabled:
          disabledOptionsList.AERIAL_MAPS ||
          totalRowsSelected < 1 ||
          updateImageTypeLoading,
        isHidden: !inArray(availableMissionRequirementsType, 'AERIAL_MAPS'),
      },
      useInPerspective: {
        label: {
          enable: `Include selected image${
            totalRowsSelected > 1 ? 's' : ''
          } for Perspective processing`,
          disable: `Exclude selected image${
            totalRowsSelected > 1 ? 's' : ''
          } for Perspective processing`,
        },
        value: 'useInPerspective',
        isDisabled:
          disabledOptionsList.PERSPECTIVE ||
          totalRowsSelected < 1 ||
          updateImageTypeLoading,
        isHidden: !inArray(availableMissionRequirementsType, 'PERSPECTIVE'),
      },
      useInPanorama: {
        label: {
          enable: `Include selected image${
            totalRowsSelected > 1 ? 's' : ''
          } for External 360 processing`,
          disable: `Exclude selected image${
            totalRowsSelected > 1 ? 's' : ''
          } for External 360 processing`,
        },
        value: 'useInPanorama',
        isDisabled:
          disabledOptionsList.EXTERIOR_360 ||
          totalRowsSelected < 1 ||
          updateImageTypeLoading,
        isHidden: !inArray(availableMissionRequirementsType, 'EXTERIOR_360'),
      },
      useInInspection: {
        label: {
          enable: `Create Inspection view from selected image${
            totalRowsSelected > 1 ? 's' : ''
          }`,
          disable: '',
        },
        value: 'useInInspection',
        isDisabled: false,
        isHidden: !inArray(availableMissionRequirementsType, 'INSPECTION'),
      },
      useInThermalMap: {
        label: {
          enable: `Include selected image${
            totalRowsSelected > 1 ? 's' : ''
          } for Thermal Map processing`,
          disable: `Exclude selected image${
            totalRowsSelected > 1 ? 's' : ''
          } from Thermal Map processing`,
        },
        value: 'useInThermalMap',
        isDisabled:
          disabledOptionsList.THERMAL_MAP ||
          totalRowsSelected < 1 ||
          updateImageTypeLoading,
        isHidden: !inArray(availableMissionRequirementsType, 'THERMAL_MAP'),
      },
      useInThermalInspection: {
        label: {
          enable: `Include selected image${
            totalRowsSelected > 1 ? 's' : ''
          } for Thermal Inspection processing`,
          disable: `Exclude selected image${
            totalRowsSelected > 1 ? 's' : ''
          } from Thermal Inspection processing`,
        },
        value: 'useInThermalInspection',
        isDisabled:
          disabledOptionsList.THERMAL_INSPECTION ||
          totalRowsSelected < 1 ||
          updateImageTypeLoading,
        isHidden: !inArray(
          availableMissionRequirementsType,
          'THERMAL_INSPECTION'
        ),
      },
    };

    const totalSelectedMissionImagesList = (unFilteredMissionImagesList ?? [])
      .length;

    const menuOptionList: React.ReactNode[] = [];

    Object.keys(optionsList).map((a) => {
      const item = optionsList[a];

      if (item.isHidden || item.isDisabled) {
        return null;
      }

      if (item.value === 'useInInspection') {
        menuOptionList.push(
          <Menu.Item
            key={`${a}-0`}
            onClick={() => {
              this.setState({
                showInspectionViewModal: true,
              });
            }}
            disabled={item.isDisabled}
          >
            {item.label.enable}
          </Menu.Item>
        );
      } else {
        if (allowedLabelList[a].n > 0) {
          menuOptionList.push(
            <Menu.Item
              key={`${a}-0`}
              onClick={() => {
                this.handleBulkUpdateListViewImageType(true, a);
              }}
              disabled={item.isDisabled}
            >
              {item.label.enable}
            </Menu.Item>
          );
        }

        if (allowedLabelList[a].y > 0) {
          menuOptionList.push(
            <Menu.Item
              key={`${a}-1`}
              onClick={() => {
                this.handleBulkUpdateListViewImageType(false, a);
              }}
              disabled={item.isDisabled}
            >
              {item.label.disable}
            </Menu.Item>
          );
        }
      }

      return a;
    });

    const menuWrapperEl = <Menu>{menuOptionList}</Menu>;

    const filteredChipsEl = this.getFilterToolbarItems();

    return (
      <div className={styles.toolbarContainer}>
        <div className={styles.actionToolbarContainer}>
          <div className={styles.firstSectionWrapper}>
            <Dropdown
              overlay={menuWrapperEl}
              disabled={
                updateImageTypeLoading ||
                totalRowsSelected < 1 ||
                !menuOptionList ||
                menuOptionList.length < 1
              }
            >
              <button
                type="button"
                disabled={updateImageTypeLoading || totalRowsSelected < 1}
                className={`ant-btn ant-btn-primary ${styles.bulkActionDropDownBtn}`}
              >
                <span>
                  Actions <DownOutlined />
                </span>
              </button>
            </Dropdown>

            {triggeredMissionList && triggeredMissionList.length > 0 && (
              <div className={styles.triggerListWrapper}>
                <Text>{`Triggered: ${triggeredMissionList.join(', ')}`}</Text>
              </div>
            )}
          </div>

          {type === 'map' && (
            <div className={styles.middleSection}>
              <Text>FOR IMAGES FILTERING OR SELECTION, USE LIST VIEW</Text>
            </div>
          )}

          <div className={styles.infoWrapper}>
            <Text className={styles.countWrapper}>
              {`${totalSelectedMissionImagesList} items, ${
                (filteredMissionImagesList ?? []).length
              } filtered`}
            </Text>
            {type === 'list' && (
              <div className={styles.selectWrapper}>
                <Select
                  className={styles.select}
                  dropdownStyle={{ zIndex: 10002 }}
                  onChange={this.handleItemsPerPageChange}
                  placeholder="Items Per Page"
                >
                  {pageLimitOptionsList.map((a) => {
                    return (
                      <Option value={a} key={a}>
                        {a}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            )}
          </div>
        </div>

        {filteredChipsEl && filteredChipsEl.length > 0 && (
          <div className={styles.filterToolbarItemContainer}>
            <Text className={styles.appliedFiltersTitle}>
              Applied Filters:&nbsp;
            </Text>
            <div className={styles.appliedFiltersChipWrapper}>
              {filteredChipsEl}
            </div>
          </div>
        )}
      </div>
    );
  };

  private getFilterToolbarItems = () => {
    const { dataListFilterStore } = this.state;

    return Object.keys(dataListFilterStore)
      .filter((a) => {
        const item = dataListFilterStore[a];

        return !undefinedOrNull(item) && item.value.length > 0;
      })
      .map((a: string) => {
        const value = (dataListFilterStore[a].value as (string | number)[])
          .map((v) => this.getMetaDataValue(a, v))
          .join(dataListFilterStore[a].type === 'range' ? ' to ' : ', ');

        let chipValue = `${dataTableExifDict[a]} = "${value}"`;

        switch (a) {
          case 'latitudeLongitudeUntrimmed':
            chipValue = dataTableExifDict[a];

            break;

          default:
            break;
        }

        return (
          <div key={a} className={styles.appliedFiltersChipWrapper}>
            <Tag
              className={styles.appliedFiltersChip}
              closable
              onClose={() => {
                this.handleResetFilterByType(a);
              }}
            >
              {chipValue}
            </Tag>
          </div>
        );
      });
  };

  private isTriggeringDisabled = (): boolean => {
    const { projectData, aoiId } = this.props;
    const { selectedMissionRequirementsType } = this.state;

    const aoi = getAoiFromProjectData(projectData, aoiId);

    const optionsList = this.getTriggeredMissionDataRequirementsList();
    const availableMissionRequirementsType =
      this.getAvailableMissionRequirementsType();
    const btnDisabled = optionsList[selectedMissionRequirementsType];
    const markerPointsList = this.getTriggerProcessingMarkerPointsList();

    if (selectedMissionRequirementsType === 'AERIAL_MAPS') {
      if (!(projectData && projectData.epsgCode)) {
        return true;
      }

      if (!aoi?.boundaryId) {
        return true;
      }
    }

    return (
      !selectedMissionRequirementsType ||
      btnDisabled ||
      (markerPointsList ?? []).length < 1 ||
      !inArray(
        availableMissionRequirementsType,
        selectedMissionRequirementsType
      )
    );
  };

  private RenderListViewImagePreviewSection = (args: {
    guid: string | null;
  }): JSX.Element | null => {
    const imagePreview = this.getExifImagePreviewFromImageGuid(args.guid);

    return (
      <span>
        {!undefinedOrNull(imagePreview) ? (
          <img
            src={imagePreview}
            className={styles.listViewImagePreview}
            alt="Preview"
          />
        ) : (
          <SkeletonLoader position="static" size={1} />
        )}
      </span>
    );
  };

  private RenderTriggerActionBtn = (): JSX.Element | null => {
    const { missionData } = this.props;
    const { isTriggerImagesBtnLoading } = this.state;

    return (
      <div className={styles.triggerProcessingBtnContainer}>
        <Button
          disabled={missionData?.status === 'PROCESSED'}
          type="primary"
          loading={isTriggerImagesBtnLoading}
          loadingText="Triggering..."
          onClick={() => {
            this.handleTriggerProcessingBtnClick(true);
          }}
        >
          Trigger Processing
        </Button>
      </div>
    );
  };

  private RenderTriggerModal = (): JSX.Element | null => {
    const {
      showTriggerModal,
      selectedMissionRequirementsType,
      boundaryPointsList,
      isTriggerImagesBtnLoading,
      mapGeneratedTime,
      boundaryGeometryAndBuffer,
    } = this.state;

    const { RenderTriggerOptions, RenderEPSGInfo } = this;

    const optionsList = this.getTriggeredMissionDataRequirementsList();
    const availableMissionRequirementsType =
      this.getAvailableMissionRequirementsType();
    const markerPointsList = this.getTriggerProcessingMarkerPointsList();

    let nadirTriggerPopupTextEl: React.ReactNode | null = null;

    if (
      !optionsList.AERIAL_MAPS &&
      inArray(availableMissionRequirementsType, 'AERIAL_MAPS') &&
      inArray(['AERIAL_MAPS'], selectedMissionRequirementsType)
    ) {
      nadirTriggerPopupTextEl = (
        <React.Fragment>
          {selectedMissionRequirementsTypeTextDict.AERIAL_MAPS.button} will be
          automatically triggered.
          <br />
        </React.Fragment>
      );
    }

    return (
      <Modal
        title="Trigger Processing"
        centered
        footer={null}
        visible={showTriggerModal}
        onCancel={() => {
          this.handleTriggerProcessingBtnClick(false);
        }}
        destroyOnClose
        maskClosable
        zIndex={10003}
        className={styles.triggerModalContainer}
      >
        <div className={styles.triggerModalInnerWrapper}>
          <div>
            <RenderTriggerOptions />
            <div className={styles.triggerSpacer} />
            <RenderEPSGInfo />

            <div className={styles.ctaWrapper}>
              <ConfirmPopup
                body={
                  <div>
                    <span>
                      {nadirTriggerPopupTextEl}
                      You will not be able to add images for this output once
                      triggered.
                      <br />
                      Are you sure you want to trigger processing?
                    </span>
                  </div>
                }
                onModalClose={(value) => {
                  if (!value) {
                    return;
                  }

                  this.handleTriggerImagesBtnClick();
                }}
              >
                <Button
                  disabled={this.isTriggeringDisabled()}
                  loading={isTriggerImagesBtnLoading}
                  loadingText="Triggering..."
                >
                  {`Trigger ${
                    selectedMissionRequirementsTypeTextDict[
                      selectedMissionRequirementsType
                    ].button
                      ? `${selectedMissionRequirementsTypeTextDict[selectedMissionRequirementsType].button} `
                      : ''
                  }Processing`}
                </Button>
              </ConfirmPopup>
            </div>
          </div>

          <div className={styles.mapContainer}>
            <div>
              <Text className={styles.title}>{`${
                (markerPointsList ?? []).length
              } images selected for processing`}</Text>

              {isVimanaLite() ? (
                <div style={{ height: '442px', width: '350px' }}>
                  <OSMMapContainer>
                    <VectorLayerContainer>
                      <SatelliteTiles />
                      <ReadOnlyPolygon
                        coordinates={[
                          boundaryPointsList.map((p) => [p.lng, p.lat]),
                        ]}
                      />
                    </VectorLayerContainer>
                    <VectorLayerContainer>
                      <ReadOnlyPoints
                        coordinates={
                          markerPointsList?.map((p) => [
                            p.pos.lng,
                            p.pos.lat,
                          ]) ?? []
                        }
                      />
                    </VectorLayerContainer>
                    {boundaryGeometryAndBuffer ? (
                      <CenterMap
                        geometryWithScaleBuffer={boundaryGeometryAndBuffer}
                      />
                    ) : (
                      <></>
                    )}
                  </OSMMapContainer>
                </div>
              ) : (
                <PlotGoogleMap
                  mapGeneratedTime={mapGeneratedTime}
                  width="442px"
                  height="350px"
                  boundaryPointsList={[boundaryPointsList]}
                  markerPointsList={markerPointsList ?? []}
                  showHaloMarker
                  centerMapTo={
                    boundaryPointsList.length > 0 ? 'boundary' : 'marker'
                  }
                  gestureHandling="auto"
                />
              )}

              {!markerPointsList ? (
                <LoadingOverlay position="absolute" />
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  private RenderForcefulTriggerModal = (): JSX.Element | null => {
    const { showForcefulTriggerModal, isTriggerImagesBtnLoading } = this.state;

    return (
      <Modal
        title="Confirmation"
        centered
        footer={null}
        visible={showForcefulTriggerModal}
        onCancel={() => {
          this.handleForcefulTriggerProcessingBtnClick(false);
        }}
        destroyOnClose
        maskClosable
        zIndex={10003}
        className={styles.forcefulTriggerModalContainer}
      >
        <div className={styles.triggerModalInnerWrapper}>
          <div className={styles.bodyWrapper}>
            <Text>
              {`Mission requires Ground Control Points (GCPs), but the same hasn't
              been uploaded yet. Proceed without GCPs?`}
            </Text>
          </div>

          <div className={styles.ctaWrapper}>
            <Button
              transparent
              onClick={() => {
                this.handleForcefulTriggerProcessingBtnClick(false);
              }}
              text="No"
            />
            <Button
              onClick={() => {
                this.handleTriggerImagesBtnClick(true);
              }}
              loading={isTriggerImagesBtnLoading}
              loadingText="Triggering..."
              text="Yes"
            />
          </div>
        </div>
      </Modal>
    );
  };

  private RenderTriggerOptions = (): JSX.Element | null => {
    const { selectedMissionRequirementsType } = this.state;
    const { projectData, aoiId } = this.props;

    const aoi = getAoiFromProjectData(projectData, aoiId);
    const optionsList = this.getTriggeredMissionDataRequirementsList();

    return (
      <div className={styles.renderTriggerContainer}>
        <Radio.Group
          onChange={this.handleFilterImagesChange}
          value={selectedMissionRequirementsType}
        >
          {Object.keys(selectedMissionRequirementsTypeTextDict).map(
            (a: SelectedMissionRequirementsType, idx) => {
              const { option } = selectedMissionRequirementsTypeTextDict[a];
              const placement = idx === 0 ? 'top' : 'right';

              if (undefinedOrNull(optionsList[a])) {
                return (
                  <ModalTooltip
                    key={a}
                    placement={placement}
                    title="Not selected in the mission purpose."
                    zIndex={10004}
                  >
                    <Radio value={a} disabled>
                      {option}
                    </Radio>
                  </ModalTooltip>
                );
              }

              if (optionsList[a]) {
                return (
                  <ModalTooltip
                    key={a}
                    placement={placement}
                    title="Already triggered for processing."
                    zIndex={10004}
                  >
                    <Radio value={a} disabled={optionsList[a]}>
                      {option}
                    </Radio>
                  </ModalTooltip>
                );
              }

              if (a === 'AERIAL_MAPS') {
                if (!aoi?.boundaryId && !projectData?.epsgCode) {
                  return (
                    <ModalTooltip
                      key={a}
                      placement={placement}
                      title="AOI Boundary and Project EPSG must be specified to trigger Maps Processing."
                      zIndex={10004}
                    >
                      <Radio value={a} disabled>
                        {option}
                      </Radio>
                    </ModalTooltip>
                  );
                }

                if (!projectData?.epsgCode) {
                  return (
                    <ModalTooltip
                      key={a}
                      placement={placement}
                      title="Project EPSG must be specified to trigger Maps Processing."
                      zIndex={10004}
                    >
                      <Radio value={a} disabled>
                        {option}
                      </Radio>
                    </ModalTooltip>
                  );
                }

                if (!aoi?.boundaryId) {
                  return (
                    <ModalTooltip
                      key={a}
                      placement={placement}
                      title="AOI Boundary must be specified to trigger Maps Processing."
                      zIndex={10004}
                    >
                      <Radio value={a} disabled>
                        {option}
                      </Radio>
                    </ModalTooltip>
                  );
                }
              }

              if (a === 'PERSPECTIVE' && !aoi?.boundaryId) {
                return (
                  <ModalTooltip
                    key={a}
                    placement={placement}
                    title="AOI Boundary must be specified to trigger Perspective Processing."
                    zIndex={10004}
                  >
                    <Radio value={a} disabled>
                      {option}
                    </Radio>
                  </ModalTooltip>
                );
              }

              return (
                <Radio value={a} key={a} disabled={optionsList[a]}>
                  {option}
                </Radio>
              );
            }
          )}
        </Radio.Group>
      </div>
    );
  };

  private getUnitLabel = (unit: string) => {
    switch (unit) {
      case 'meter':
        return 'meters';
      case 'foot':
        return 'feet';
      case 'usFT':
        return 'US Survey feet';
      default:
        return unit;
    }
  };

  private RenderEPSGInfo = (): JSX.Element => {
    const { projectData, units, projectId } = this.props;

    return (
      <div>
        <p>
          {projectData && projectData.epsgCode ? (
            <>
              The Project EPSG is <b>EPSG:{projectData.epsgCode}</b>.
            </>
          ) : (
            <>
              The Project EPSG has not been set. It can be set{' '}
              <Link to={`/project/${projectId}/edit`}>
                <u>here</u>
              </Link>
              .
            </>
          )}
          {units ? (
            <>
              {' '}
              All values are expected to be in <b>{this.getUnitLabel(units)}</b>
            </>
          ) : (
            <> The units will be determined by the Project EPSG.</>
          )}
        </p>
      </div>
    );
  };

  private RenderUploadBtnModal = (): JSX.Element | null => {
    const {
      showUploadBtnModal,
      isFinishUploadBtnLoading,
      selectedFlightPlanType,
      flightPlansDataList,
    } = this.state;

    if (!flightPlansDataList) {
      return null;
    }

    const {
      isSelectedFlightPlanTypeTiggered,
      tiggeredList,
      allRequirementsTriggered,
    } = this.getUploadBtnModalData();

    const hasPlan = (type: SelectedFlightPlanType) => {
      return flightPlansDataList.filter((p) => p.type === type).length > 0;
    };

    return (
      <Modal
        title="Select Images Source"
        centered
        footer={null}
        visible={showUploadBtnModal}
        onCancel={() => {
          this.handleShowUploadBtnModal();
        }}
        destroyOnClose
        maskClosable={false}
        zIndex={10002}
      >
        <div className={styles.uploadModalBtnWrapper}>
          <div className={styles.bodyWrapper}>
            {allRequirementsTriggered && (
              <div className={styles.triggeredWrapper}>
                <Text>Processing for all outputs have been triggered</Text>
              </div>
            )}

            {flightPlansDataList.filter((p) => p.type !== 'INSPECTION').length >
              0 && (
              <React.Fragment>
                <div className={styles.radioOption}>AOI Site Flights</div>
                {flightPlansDataList
                  .filter(
                    (p) =>
                      p.type !== 'INSPECTION' && p.type !== 'THERMAL_INSPECTION'
                  )
                  .map((p) => {
                    return (
                      <Tooltip
                        key={p.type}
                        placement="top"
                        title="Already triggered for processing"
                        overlayStyle={{
                          zIndex: inArray(tiggeredList, p.type) ? 10004 : 0,
                        }}
                      >
                        <div className={styles.radioOption}>
                          <span style={{ paddingLeft: '2em' }} />
                          <Radio
                            checked={selectedFlightPlanType === p.type}
                            onChange={(_e: any) => {
                              this.setState({ selectedFlightPlanType: p.type });
                            }}
                            disabled={inArray(tiggeredList, p.type)}
                          />
                          <span>
                            {uploadedFlightPlanTypeList[p.type].label}
                          </span>
                        </div>
                      </Tooltip>
                    );
                  })}
                <div className={styles.radioOption}>
                  <span style={{ paddingLeft: '2em' }} />
                  <Radio
                    checked={selectedFlightPlanType === 'ALL'}
                    onChange={(_e: any) => {
                      this.setState({ selectedFlightPlanType: 'ALL' });
                    }}
                  />
                  <span>{uploadedFlightPlanTypeList.ALL.label}</span>
                </div>
              </React.Fragment>
            )}
            {(hasPlan('INSPECTION') || hasPlan('THERMAL_INSPECTION')) && (
              <React.Fragment>
                <div className={styles.radioOption}>
                  Structure Inspection Flights
                </div>
                {hasPlan('INSPECTION') && (
                  <Tooltip
                    key="INSPECTION"
                    placement="top"
                    title="Already triggered for processing"
                    style={{
                      zIndex: inArray(tiggeredList, 'INSPECTION') ? 10004 : 0,
                    }}
                  >
                    <div className={styles.radioOption}>
                      <span style={{ paddingLeft: '2em' }} />
                      <Radio
                        checked={selectedFlightPlanType === 'INSPECTION'}
                        onChange={(_e: any) => {
                          this.setState({
                            selectedFlightPlanType: 'INSPECTION',
                          });
                        }}
                        disabled={inArray(tiggeredList, 'INSPECTION')}
                      />
                      <span>{uploadedFlightPlanTypeList.INSPECTION.label}</span>
                    </div>
                  </Tooltip>
                )}
                {hasPlan('THERMAL_INSPECTION') && (
                  <Tooltip
                    key="THERMAL_INSPECTION"
                    placement="top"
                    title="Already triggered for processing"
                    style={{
                      zIndex: inArray(tiggeredList, 'THERMAL_INSPECTION')
                        ? 10004
                        : 0,
                    }}
                  >
                    <div className={styles.radioOption}>
                      <span style={{ paddingLeft: '2em' }} />
                      <Radio
                        checked={
                          selectedFlightPlanType === 'THERMAL_INSPECTION'
                        }
                        onChange={(_e: any) => {
                          this.setState({
                            selectedFlightPlanType: 'THERMAL_INSPECTION',
                          });
                        }}
                        disabled={inArray(tiggeredList, 'THERMAL_INSPECTION')}
                      />
                      <span>
                        {uploadedFlightPlanTypeList.THERMAL_INSPECTION.label}
                      </span>
                    </div>
                  </Tooltip>
                )}
              </React.Fragment>
            )}
          </div>

          <div className={styles.buttonsWrapper}>
            <Button
              transparent
              onClick={() => {
                this.handleShowUploadBtnModal();
              }}
              text="Cancel"
            />

            <Button
              onClick={() => {
                this.handleShowUploadBtnModal();
                this.handleUploadImages();
              }}
              loading={isFinishUploadBtnLoading}
              loadingText="Finishing..."
              text="Start Uploading"
              disabled={
                allRequirementsTriggered || isSelectedFlightPlanTypeTiggered
              }
            />
          </div>
        </div>
      </Modal>
    );
  };

  private RenderImageUploadErrorsModal = (): JSX.Element | null => {
    const { showImageUploadErrorModal, selectedUploadImagesList } = this.state;

    if (!showImageUploadErrorModal) {
      return null;
    }

    return (
      <UploadImagesErrors
        erroredImages={(selectedUploadImagesList ?? []).filter(
          (a) => a.error || a.status === 'FAILED'
        )}
        showModal={showImageUploadErrorModal}
        hideErrorsModal={() => {
          this.handleShowErrorsModal();
        }}
      />
    );
  };

  private RenderImageExifInformationDrawer = (): JSX.Element | null => {
    const { showExifDrawer, selectedMarkerIndex, updateImageTypeLoading } =
      this.state;

    const optionsList = this.getTriggeredMissionDataRequirementsList();

    const { filtered: missionImagesList } =
      this.getMissionImagesDataSourceList();

    const checkboxesOptions = [
      {
        label: 'Maps',
        value: 'useInMaps',
        disabled: optionsList.AERIAL_MAPS ?? true,
      },
      {
        label: 'Perspective',
        value: 'useInPerspective',
        disabled: optionsList.PERSPECTIVE ?? true,
      },
      {
        label: 'External 360',
        value: 'useInPanorama',
        disabled: optionsList.EXTERIOR_360 ?? true,
      },
      {
        label: 'Inspection',
        value: 'useInInspection',
        disabled: optionsList.INSPECTION ?? true,
      },
      {
        label: 'Thermal Inspection',
        value: 'useInThermalInspection',
        disabled: optionsList.THERMAL_INSPECTION ?? true,
      },
    ];

    const imagePreview =
      this.getExifImagePreviewFromSelectedIndex(selectedMarkerIndex);
    const selectedMissionImageTypeList =
      this.getSelectedMissionImageTypeList(selectedMarkerIndex);

    const _missionImagesList = (missionImagesList ?? []).map((a) => {
      const flightSourceType = a.flightPlanId
        ? flightPlanTypeDict[this.getFlightPlanIdToFlightType(a.flightPlanId)]
        : 'UNCLASSIFIED';

      return {
        ...a,
        flightSourceType,
      };
    });

    return (
      <Drawer
        width={400}
        mask={false}
        maskClosable={false}
        destroyOnClose
        title="Image Properties"
        placement="right"
        onClose={() => {
          this.handleShowExifDrawer(false);
        }}
        zIndex={10004}
        visible={showExifDrawer}
        className={styles.drawerWrapper}
      >
        <div className={styles.bodyWrapper}>
          {!_missionImagesList || !_missionImagesList[selectedMarkerIndex] ? (
            <SkeletonLoader size={2} />
          ) : (
            <div>
              {Object.keys(_missionImagesList[selectedMarkerIndex]).map((a) => {
                if (undefinedOrNull(mapExifDict[a])) {
                  return null;
                }

                const value = _missionImagesList[selectedMarkerIndex][a];

                return (
                  <div className={styles.exifDataListWrapper} key={a}>
                    <Text className={styles.key}>{mapExifDict[a]}</Text>
                    <Text>:&nbsp;</Text>
                    <Text className={styles.value}>
                      {this.getMetaDataValue(a, value)}
                    </Text>
                  </div>
                );
              })}

              <div className={styles.updateImageTypeActionBtnsWrapper}>
                <Paragraph>Use in:</Paragraph>
                <CheckboxGroup
                  options={checkboxesOptions}
                  value={selectedMissionImageTypeList}
                  onChange={(nextSelectedMissionImageTypeList: string[]) => {
                    this.handleUpdateImageType(
                      nextSelectedMissionImageTypeList,
                      selectedMissionImageTypeList,
                      selectedMarkerIndex
                    );
                  }}
                  disabled={updateImageTypeLoading}
                />
              </div>

              <div className={styles.exifDataListImageWrapper}>
                <Paragraph>Image Preview:</Paragraph>
                {!undefinedOrNull(imagePreview) ? (
                  <img src={imagePreview} alt="Preview" />
                ) : (
                  <SkeletonLoader position="static" size={2} />
                )}
              </div>
            </div>
          )}
        </div>
      </Drawer>
    );
  };

  private RenderRowSelection = ({
    active,
  }: {
    active?: boolean;
  }): JSX.Element => {
    const { isAllFilteredImagesSelected } = this.state;
    const selectedListViewRowKeysList = this.getSelectedListViewRowKeysList();
    const { filtered: filteredDataSource } =
      this.getMissionImagesDataSourceList();

    if (!active) {
      return (
        <p className={styles.allRowSelection}>
          {selectedListViewRowKeysList.length} image
          {selectedListViewRowKeysList.length > 1 ? 's are' : ' is'} selected
        </p>
      );
    }

    return (
      <div className={styles.allRowSelection}>
        {isAllFilteredImagesSelected ? (
          <>
            <Text className={styles.rowsSelectedLabel}>
              All {selectedListViewRowKeysList.length} images in list are
              selected
            </Text>
            <Button
              text="Clear Selection"
              style={{ fontSize: 'smaller' }}
              transparent
              onClick={() => {
                this.handleAllRowSelection('clear');
              }}
            />
          </>
        ) : (
          <>
            <Text className={styles.rowsSelectedLabel}>
              {selectedListViewRowKeysList.length} images are selected on this
              page
            </Text>
            <Button
              text={`Select All ${filteredDataSource.length} images in list`}
              style={{ fontSize: 'smaller' }}
              transparent
              onClick={() => {
                this.handleAllRowSelection('select');
              }}
            />
          </>
        )}
      </div>
    );
  };

  private handleAllRowSelection = (type: 'select' | 'clear') => {
    const { filtered: filteredDataSource } =
      this.getMissionImagesDataSourceList();

    this.setState({ showLoadingModal: true }, () => {
      if (type === 'select') {
        const tableRowKeys = filteredDataSource.map((image) => image.id);

        this.setState({ isAllFilteredImagesSelected: true }, () => {
          this.setSelectedListViewRowKeysList(tableRowKeys);
        });
      } else {
        this.setState(
          {
            isAllFilteredImagesSelected: false,
            isAllImagesOnPageSelected: false,
          },
          () => {
            this.setSelectedListViewRowKeysList([]);
          }
        );
      }

      this.setState({ showLoadingModal: false });
    });
  };

  public render(): React.ReactNode {
    const { missionData, uploadImagesActionType } = this.props;
    const {
      missionImagesList,
      manageTabViewIndex,
      isAllImagesOnPageSelected,
      showLoadingModal,
      showInspectionViewModal,
    } = this.state;

    const {
      RenderTaskActionBtns,
      RenderUploadActionBtns,
      RenderProgressBarSection,
      RenderAlertInfoSection,
      RenderUploadInfoSection,
      RenderImageClassificationSection,
      RenderUploadBtnModal,
      RenderImageUploadErrorsModal,
      RenderMapViewSection,
      RenderListViewSection,
      RenderToolbar,
      RenderImageExifInformationDrawer,
      RenderTriggerActionBtn,
      RenderTriggerModal,
      RenderForcefulTriggerModal,
      RenderRowSelection,
    } = this;

    if (!missionImagesList || !missionData) {
      return (
        <div className={styles.loadingWrapper}>
          <SkeletonLoader position="absolute" />
        </div>
      );
    }

    const uploadInformation = this.getUploadInformation();

    // upload images action
    if (uploadImagesActionType === 'upload') {
      return (
        <div className={styles.container}>
          <div className={styles.actionBtnsWrapper}>
            <RenderUploadActionBtns />

            <RenderTaskActionBtns />
          </div>

          <RenderProgressBarSection {...uploadInformation} />

          <RenderAlertInfoSection />

          <RenderUploadInfoSection {...uploadInformation} />

          <RenderImageClassificationSection />

          <RenderUploadBtnModal />

          <RenderImageUploadErrorsModal />
        </div>
      );
    }

    // manage upload images action
    return (
      <div className={styles.container}>
        <Tabs
          centered
          tabBarStyle={{ textAlign: 'center' }}
          className={styles.manageTabWrapper}
          size="large"
          tabBarGutter={30}
          activeKey={manageTabViewIndex}
          onChange={this.handleManageTabChange}
        >
          <TabPane
            className={styles.tabWrapper}
            key="0"
            tab={<span>List View</span>}
          >
            <RenderToolbar type="list" />
            <RenderRowSelection active={isAllImagesOnPageSelected} />
            <RenderListViewSection />
          </TabPane>
          <TabPane
            className={styles.tabWrapper}
            key="1"
            tab={<span>Map View</span>}
          >
            <RenderToolbar type="map" />

            <RenderMapViewSection />
            <RenderImageExifInformationDrawer />
          </TabPane>

          <RenderTriggerActionBtn key="3" />
        </Tabs>

        <RenderTriggerModal />
        <RenderForcefulTriggerModal />
        {showLoadingModal ? <LoadingOverlay /> : <></>}
        {showInspectionViewModal ? (
          <InspectionViewDetailsModal
            visible
            numberOfSelectedImages={
              this.getSelectedListViewRowKeysList().length
            }
            createView={(viewName?: string) => {
              this.setState({ showInspectionViewModal: false });

              if (viewName?.length) this.createInspectionView(viewName);
            }}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}
