// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ControlBox_container__kjeTb{background:#49494d;margin:auto;width:100%;pointer-events:auto}.ControlBox_header__w8LUH{min-height:20px;border-bottom:1px solid #58585b;display:flex}.ControlBox_icon__jZzag{padding:13px;cursor:pointer}.ControlBox_title__aEInu{flex:1 1;font-weight:600;line-height:50px;font-size:18px}.ControlBox_title__aEInu:first-child{padding:0 0 0 15px}", "",{"version":3,"sources":["webpack://./src/components/ControlBox/ControlBox.module.scss"],"names":[],"mappings":"AAEA,6BACE,kBAAA,CACA,WAAA,CACA,UAAA,CACA,mBAAA,CAGF,0BACE,eAAA,CACA,+BAAA,CACA,YAAA,CAGF,wBACE,YAAA,CACA,cAAA,CAGF,yBACE,QAAA,CACA,eAAA,CACA,gBAAA,CACA,cAAA,CAEA,qCACE,kBAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  background: #49494d;\n  margin: auto;\n  width: 100%;\n  pointer-events: auto;\n}\n\n.header {\n  min-height: 20px;\n  border-bottom: 1px solid #58585b;\n  display: flex;\n}\n\n.icon {\n  padding: 13px;\n  cursor: pointer;\n}\n\n.title {\n  flex: 1;\n  font-weight: 600;\n  line-height: 50px;\n  font-size: 18px;\n\n  &:first-child {\n    padding: 0 0 0 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ControlBox_container__kjeTb",
	"header": "ControlBox_header__w8LUH",
	"icon": "ControlBox_icon__jZzag",
	"title": "ControlBox_title__aEInu"
};
export default ___CSS_LOADER_EXPORT___;
