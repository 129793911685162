import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchSingleIssue } from '../../redux/actions/issues';
import Exterior360 from './Exterior360';

const mapStateToProps = (state: any): any => {
  return {
    issue: state.issue.singleIssue,
    isCreatingIssue: state.issue.creating,
    loadingSingleIssue: state.issue.loadingSingleIssue,
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      fetchSingleIssue,
    },
    dispatch
  );
};

const Exterior360Container = connect(
  mapStateToProps,
  mapDispatchToProps
)((props: any) => <Exterior360 ref={props.forwardRef} {...props} />);

export default React.forwardRef((props, ref) => (
  // @ts-ignore
  <Exterior360Container {...props} forwardRef={ref} />
)) as any;
