import * as React from 'react';
import ReactPixel from 'react-facebook-pixel';
import Logo from '../Logo';
import { Button } from '../Button';
import style from './VerifyEmail.module.scss';
import ModalNotification from '../ModalNotification/ModalNotification';
import { BASE_CAPI_URL } from '../../constants/urls';
import { setBrandingStyles, resetBrandingStyles } from '../Login/helpers';
import { BrandingModel, ICheckBranding } from '../../shapes/branding';
import { undefinedOrNull } from '../../utils/functs';
import BrandingApi from '../../api/branding';

interface IProps {
  authenticated: boolean;
  email: string;
  code: string;
  partnerId?: string;
}

interface IState {
  error: string;
  verfying: boolean;
  showVerifyErrorModalHeading: string;
  showVerifyErrorModal: boolean;
  isUserRegisteredSuccessfully: boolean;
  brandingData: BrandingModel | null;
}

const brandingApi = new BrandingApi();

class VerifyEmail extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      error: '',
      verfying: false,
      showVerifyErrorModal: false,
      showVerifyErrorModalHeading: '',
      isUserRegisteredSuccessfully: false,
      brandingData: null,
    };
  }

  public componentDidMount(): void {
    const { email } = this.props;

    const FB_PIXEL_ID = process.env.REACT_APP_FB_PIXEL_ID
      ? `${process.env.REACT_APP_FB_PIXEL_ID}`
      : null;

    if (!undefinedOrNull(email) && !undefinedOrNull(FB_PIXEL_ID)) {
      ReactPixel.init(FB_PIXEL_ID);
      ReactPixel.pageView();
    }

    this.fetchBrandingData();
  }

  public componentWillUnmount(): void {
    resetBrandingStyles();
  }

  private fetchBrandingData = () => {
    const { partnerId } = this.props;

    if (!undefinedOrNull(partnerId)) {
      brandingApi.checkBranding(partnerId).then((res: ICheckBranding) => {
        if (!undefinedOrNull(res)) {
          this.setState(
            {
              brandingData: {
                ...res.data,
              },
            },
            () => {
              const { brandingData } = this.state;

              setBrandingStyles(brandingData);
            }
          );
        }
      });
    } else {
      // default branding when there is no partnerId
      brandingApi.checkDefaultBranding().then((res: ICheckBranding) => {
        if (!undefinedOrNull(res)) {
          this.setState(
            {
              brandingData: {
                ...res.data,
              },
            },
            () => {
              const { brandingData } = this.state;

              setBrandingStyles(brandingData);
            }
          );
        }
      });
    }
  };

  private verifyRegistration = () => {
    const { email, code, partnerId } = this.props;

    this.setState({
      verfying: true,
    });

    fetch(`${BASE_CAPI_URL}/v1/signup/verify`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: code.trim(),
        email: email.trim(),
        partnerId: partnerId ? partnerId.trim() : null,
      }),
    })
      .then((res) => {
        if (res.status === 204 || res.status === 200) {
          this.setState({
            error:
              'User is successfully verified. Please check your mail for sample test data.',
            showVerifyErrorModal: true,
            showVerifyErrorModalHeading: 'Success',
            isUserRegisteredSuccessfully: true,
            verfying: false,
          });

          if (undefinedOrNull(partnerId)) {
            ReactPixel.trackCustom('FinishedRegistration', {
              content_name: email,
            });
          }

          return true;
        }

        throw res;
      })
      .then((_res) => {
        console.info(_res);
      })
      .catch((err) => {
        err
          .json()
          .then((error: any) => {
            this.setState({
              verfying: false,
              error: error.message,
              showVerifyErrorModal: true,
              showVerifyErrorModalHeading: 'Error while verifying',
            });
          })
          .catch(() => {
            this.setState({
              error: 'Could not sign up, please contact support@aspecscire.com',
              showVerifyErrorModalHeading: 'Error while verifying',
              showVerifyErrorModal: true,
              verfying: false,
            });
          });
      });
  };

  private handleVerifyErrorModal = () => {
    const { showVerifyErrorModal } = this.state;

    this.setState(
      {
        showVerifyErrorModal: !showVerifyErrorModal,
      },
      () => {
        const { isUserRegisteredSuccessfully } = this.state;

        if (isUserRegisteredSuccessfully) {
          window.location.href = `${window.location.origin}/`;
        }
      }
    );
  };

  public render(): React.ReactNode {
    const { authenticated, email, partnerId } = this.props;
    const {
      verfying,
      error,
      showVerifyErrorModal,
      showVerifyErrorModalHeading,
    } = this.state;

    return (
      <div className={style.container}>
        <Logo
          className={style.logo}
          authenticated={authenticated}
          partnerId={partnerId}
        />
        <div className={style.VerifyEmailText}>
          <p>
            You are verifying the registration for
            <span>
              &nbsp;
              {email}
            </span>
          </p>
        </div>
        <Button
          icon="login"
          htmlType="submit"
          loading={verfying}
          loadingText="VERFYING..."
          fullWidth
          text="VERIFY"
          onClick={this.verifyRegistration}
        />
        {showVerifyErrorModal ? (
          <ModalNotification
            notificationTitle={showVerifyErrorModalHeading}
            notificationBody={error}
            shownotificationModal
            handleModalClose={this.handleVerifyErrorModal}
            okButtonTitle="OK"
          />
        ) : null}
      </div>
    );
  }
}

export default VerifyEmail;
