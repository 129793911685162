import classnames from 'classnames';
import * as React from 'react';

import style from './ElevationScale.module.scss';

interface IDem {
  elevation: number;
  color: string;
}
interface IProps {
  dems: IDem[];
  className?: string;
  units: string;
}

class ElevationScale extends React.Component<IProps> {
  private renderUnits(): string {
    const { units } = this.props;

    if (units === 'FEET' || units === 'US_SURVEY_FEET') {
      return 'ft';
    }

    return 'm';
  }

  private getDecimalPlaceToDisplay = (dems: IDem[]): number => {
    const diff = dems
      .slice(1)
      .map((element, idx) => {
        return element.elevation - dems[idx].elevation;
      })
      .map(Math.abs)
      .map(Math.floor);

    if (diff.indexOf(0) > -1) {
      return 1;
    }

    return 0;
  };

  contrastToWhite = (hexColor: string) => {
    const whiteIlluminance = 1;
    const illuminance = this.calculateIlluminance(hexColor);

    if (!illuminance) {
      return null;
    }

    return whiteIlluminance / illuminance;
  };

  isContrastOkToWhite = (hexColor: string) => {
    const whiteContrast = this.contrastToWhite(hexColor);

    return whiteContrast ? whiteContrast > 4.5 : true;
  };

  hex2Rgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  calculateIlluminance = (hexColor: string) => {
    const rgbColor = this.hex2Rgb(hexColor);

    if (!rgbColor) {
      return null;
    }

    const { r, g, b } = rgbColor;
    const a = [r, g, b].map((color: number) => {
      const v = color / 255;

      return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
    });

    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  };

  private renderDem(): React.ReactNode {
    const { dems } = this.props;

    if (!dems) {
      return;
    }

    const decimalResolution = this.getDecimalPlaceToDisplay(dems);

    return dems.map((dem, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={style.demItem}
        style={{
          backgroundColor: dem.color,
          color: this.isContrastOkToWhite(dem.color) ? '#fff' : '#000',
        }}
      >
        {`${dem.elevation.toFixed(decimalResolution)} ${this.renderUnits()}`}
      </div>
    ));
  }

  public render(): React.ReactNode {
    const { className } = this.props;

    return (
      <div className={classnames(style.container, className)}>
        {this.renderDem()}
      </div>
    );
  }
}

export default ElevationScale;
