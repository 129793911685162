/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as math from 'mathjs';

export function xRotation(theta) {
  const R = [
    [1, 0, 0],
    [0, Math.cos(theta), -Math.sin(theta)],
    [0, Math.sin(theta), Math.cos(theta)],
  ];

  return R;
}

export function yRotation(theta) {
  const R = [
    [Math.cos(theta), 0, Math.sin(theta)],
    [0, 1, 0],
    [-Math.sin(theta), 0, Math.cos(theta)],
  ];

  return R;
}

export function zRotation(theta) {
  const R = [
    [Math.cos(theta), -Math.sin(theta), 0],
    [Math.sin(theta), Math.cos(theta), 0],
    [0, 0, 1],
  ];

  return R;
}

export function getCameraRotation(yaw, pitch, roll) {
  /* Compute the axis from the camera (intrinsic) rotation angles
    (yaw, pitch and roll).
    When all three three rotation angles are 0., the camera is pointing towards
    positive Y-axis (North), with image left-to-right along positive X-axis
    (East), and bottom-to-top along positive Z-axis (Upward).
    :param yaw: cw rotation about image height axis
    :param pitch: ccw rotation about image width axis
    :param roll: ccw rotation about view axis
    :returns: axis as a 3x3 rotation matrix. The column vectors are the new X,
    Y, and Z axes.  To rotate a vector v, pre-multiply with R: w = Rv.
  */
  const R = math.multiply(
    zRotation(-yaw),
    math.multiply(xRotation(pitch), yRotation(roll))
  );

  return R;
}

export function getRotationMatrix(R, camPos) {
  // handle Translation ; assuming YPR=0
  const T = [
    [1, 0, 0, -camPos[0]],
    [0, 1, 0, -camPos[1]],
    [0, 0, 1, -camPos[2]],
  ];

  // Take transpose of R since we want to get the camera to axes: Y:North, X:East, Z:Top
  // Transpose = Inverse for rotation matrices
  const rTranspose = math.transpose(R);
  const A = xRotation(Math.PI / 2);
  // the complete rotation matrix = ART; A - rotation from above camera axes to: Z:North, X:East, Y:Down
  const rotMatrix = math.multiply(A, math.multiply(rTranspose, T));

  return rotMatrix;
}

export function getKMatrix(fov, width, height) {
  // fx = w/2 / tan(hfov/2)
  // fy = h/2 / tan(vfov/2)
  // cx = w/2
  // cy = h/2

  const temp = 1 / 2 / Math.tan(fov / 2);
  const fx = temp * width;
  const fy = temp * height;
  const cx = width / 2.0;
  const cy = height / 2.0;
  const K = [
    [fx, 0, cx],
    [0, fy, cy],
    [0, 0, 1],
  ];

  return K;
}

export function normalize(vector) {
  const mag = vector[0] ** 2 + vector[1] ** 2 + vector[2] ** 2;
  const sqrtMag = Math.sqrt(mag);

  return [vector[0] / sqrtMag, vector[1] / sqrtMag, vector[2] / sqrtMag];
}

export function calcDistance(point1, point2) {
  const mag =
    (point1[0] - point2[0]) ** 2 +
    (point1[1] - point2[1]) ** 2 +
    (point1[2] - point2[2]) ** 2;

  return Math.sqrt(mag);
}

export function calcAngle(center, pt1, pt2) {
  const vec1 = [pt1[0] - center[0], pt1[1] - center[1], pt1[2] - center[2]];
  const vec2 = [pt2[0] - center[0], pt2[1] - center[1], pt2[2] - center[2]];

  // Check if any of them are zero vectors
  const isVec1Zero = vec1.every((item) => item === 0);
  const isVec2Zero = vec2.every((item) => item === 0);

  if (isVec1Zero === true || isVec2Zero === true) {
    return Infinity;
  }

  const normVec1 = normalize(vec1);
  const normVec2 = normalize(vec2);

  let dotProduct = math.dot(normVec1, normVec2);

  if (dotProduct < -1) {
    dotProduct = -1;
  }

  if (dotProduct > 1) {
    dotProduct = 1;
  }

  return Math.acos(dotProduct) * (180 / Math.PI);
}
