import classnames from 'classnames';
import * as React from 'react';
import * as ControlBox from '../ControlBox';
import { Button } from '../Button';
import style from './index.module.scss';
import { ObjectSubType, ObjectType, SiteObjectClass } from '../../shapes/ml';
import { SiteObjectsControlBoxPropsType } from './index.types';

class InfoControlBox extends React.Component<SiteObjectsControlBoxPropsType> {
  public componentDidMount() {
    const {
      projectSiteClasses,
      objectType,
      objectSubType,
      handleMLSelectionChange,
    } = this.props;

    const typeSet = new Set();

    projectSiteClasses.forEach((cls) => {
      typeSet.add(cls.type);
    });

    if (!objectType && typeSet.values.length === 1) {
      // default type, if only one has been selected for the project
      handleMLSelectionChange(typeSet.values[0], objectSubType);
    }
  }

  private handleTypeChange = (type: ObjectType | null) => {
    const { handleMLSelectionChange } = this.props;

    handleMLSelectionChange(type, null);
  };

  private handleSubTypeChange = (subType: ObjectSubType | null) => {
    const { handleMLSelectionChange, objectType } = this.props;

    handleMLSelectionChange(objectType, subType);
  };

  private renderObjectSubType = ({
    label,
    active = true,
    disable = false,
    onClick,
  }: any): JSX.Element => (
    <div
      onClick={() => {
        if (disable || !onClick) {
          return;
        }

        onClick();
      }}
      className={classnames(style.intent, {
        [style.active]: active,
        [style.disable]: disable,
      })}
    >
      {label}
    </div>
  );

  public renderObjectTypeSelection = (): JSX.Element | null => {
    const { objectType, projectSiteClasses } = this.props;
    const { renderObjectSubType: RenderIntent } = this;

    const typeSet = new Set<string>();

    projectSiteClasses.forEach((cls) => {
      typeSet.add(cls.type);
    });

    return (
      <div className={style.intentList}>
        {Array.from(typeSet).map((cls: string) => (
          <RenderIntent
            active={objectType === cls}
            key={cls}
            label={cls}
            onClick={() => {
              this.handleTypeChange(cls);
            }}
          />
        ))}
      </div>
    );
  };

  public renderObjectSubTypeSelection = (): JSX.Element | null => {
    const { objectType, objectSubType, projectSiteClasses } = this.props;
    const { renderObjectSubType: RenderIntent } = this;

    const siteClasses = projectSiteClasses.filter(
      (cls) => cls.type === objectType
    );

    return (
      <div className={style.intentList}>
        {siteClasses.map((cls: SiteObjectClass) => (
          <RenderIntent
            active={objectSubType === cls.subType}
            key={cls.id}
            label={cls.name}
            onClick={() => {
              this.handleSubTypeChange(cls.subType);
            }}
          />
        ))}
      </div>
    );
  };

  public getSubTypeName = (
    objectType: ObjectType,
    objectSubType: ObjectSubType
  ): string => {
    const { projectSiteClasses } = this.props;

    const siteClass = projectSiteClasses.find(
      (cls) => cls.type === objectType && cls.subType === objectSubType
    );

    return siteClass ? siteClass.name : 'N/A';
  };

  private renderHeader = (): React.ReactElement<React.ReactNode> => {
    const {
      onClose,
      itemType,
      handleMLSelectionChange,
      objectType,
      objectSubType,
    } = this.props;

    return (
      <React.Fragment>
        {objectType || objectSubType ? (
          <ControlBox.Icon
            name="back"
            onClick={() => {
              if (objectSubType) {
                this.handleSubTypeChange(null);
              } else {
                this.handleTypeChange(null);
              }
            }}
          />
        ) : null}
        <ControlBox.Title
          className={style.title}
          title={
            objectType
              ? objectSubType
                ? `Object Sub-Type: ${this.getSubTypeName(
                    objectType,
                    objectSubType
                  )}`
                : 'Select Object Sub-Type'
              : 'Select Object Type'
          }
        />
        <ControlBox.Icon
          onClick={() => {
            if (!onClose) {
              return;
            }

            // reset subType selection on close
            handleMLSelectionChange(null, null);
            onClose(itemType || null);
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  private renderManageObjects = (): React.ReactElement<React.ReactNode> => {
    const { handleSelectedSiteObjectIntentType } = this.props;

    return (
      <div className={style.manageContainer}>
        <ControlBox.Title
          className={style.title}
          title="Manage Object Markings"
        />
        <div className={style.buttonList}>
          <Button
            className={style.button}
            text="Create"
            type="secondary"
            onClick={() => {
              handleSelectedSiteObjectIntentType('create_object');
            }}
          />
          <Button
            className={style.button}
            text="Delete"
            type="secondary"
            onClick={() => {
              handleSelectedSiteObjectIntentType('delete_object');
            }}
          />
        </div>
      </div>
    );
  };

  private renderObjectLegend = () => {
    const { siteObjects, objectSubType, view, viewConfig } = this.props;

    let filteredObjects = siteObjects.filter(
      (so) => so.subType === objectSubType
    );

    let imageIds = null;

    if (view && view.type === 'site_navigation') {
      let hiddenImagesGuidList: string[] = [];

      if (
        viewConfig &&
        viewConfig.hiddenImagesGuidList &&
        viewConfig.hiddenImagesGuidList.length > 0
      ) {
        hiddenImagesGuidList = viewConfig.hiddenImagesGuidList;
      }

      filteredObjects = filteredObjects.filter(
        (so) =>
          so.imageId !== null && !hiddenImagesGuidList.includes(so.imageId)
      );
      imageIds = new Set(filteredObjects.map((so) => so.imageId));
    }

    const aiObjects = filteredObjects.filter((so) => so.source === 'ml');
    const manualObjects = filteredObjects.filter(
      (so) => so.source === 'manual'
    );

    return (
      <React.Fragment>
        <ControlBox.Title className={style.title} title="Stats" />
        <div className={style.colorList}>
          {imageIds ? (
            <React.Fragment>
              <div className={style.countText}>{imageIds.size}</div>
              <div className={style.legendText}>Images Containing Objects</div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className={style.countText}>{aiObjects.length}</div>
              <div className={style.legendText}>AI Marked Objects</div>
              <div className={style.countText}>{manualObjects.length}</div>
              <div className={style.legendText}>Manually Marked Objects</div>
            </React.Fragment>
          )}
        </div>
        <ControlBox.Title className={style.title} title="Legend" />
        <div className={style.colorList}>
          <div className={style.aiColor} />
          <div className={style.legendText}>AI Marked Object</div>
          <div className={style.manualColor} />
          <div className={style.legendText}>Manually Marked Object</div>
        </div>
      </React.Fragment>
    );
  };

  private renderObjectInfo = () => {
    const { hasAdminAccess, objectSubType } = this.props;

    if (objectSubType == null) {
      return;
    }

    return (
      <React.Fragment>
        {hasAdminAccess ? this.renderManageObjects() : null}
        {this.renderObjectLegend()}
      </React.Fragment>
    );
  };

  private renderContent = () => {
    const { objectType, objectSubType } = this.props;

    return (
      <React.Fragment>
        {objectType
          ? objectSubType
            ? this.renderObjectInfo()
            : this.renderObjectSubTypeSelection()
          : this.renderObjectTypeSelection()}
      </React.Fragment>
    );
  };

  public render(): React.ReactNode {
    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={this.renderHeader}
      >
        {this.renderContent()}
      </ControlBox.Wrapper>
    );
  }
}

export default InfoControlBox;
