import * as React from 'react';
import PotreeWrapper from '../../Potree';
import { BaseRendererProps } from '../index.types';

export default class ThreeDView extends React.Component<BaseRendererProps> {
  public componentDidMount() {
    const { onFooterControlsFragChange } = this.props;

    if (onFooterControlsFragChange) {
      onFooterControlsFragChange(undefined);
    }
  }

  public render() {
    const { view, viewConfig, children } = this.props;

    return (
      <PotreeWrapper view={view} viewConfig={viewConfig}>
        {children}
      </PotreeWrapper>
    );
  }
}
