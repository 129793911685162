import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { closeSettings, updateSettings } from '../../redux/actions/settings';
import UserSettings from './UserSettings';

const mapStateToProps = (state: any): any => {
  return {
    isOpen: state.settings.isOpen,
    isUpdating: state.settings.isUpdating,
    isPasswordUpdated: state.settings.isPasswordUpdated,
    whiteLabelingEnabled: state.auth.user.whiteLabelingEnabled,
    userId: state.auth.user.user_id,
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
      closeSettings,
      updateSettings,
    },
    dispatch
  );
};

const UserSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettings);

export default UserSettingsContainer;
