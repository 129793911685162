import React, { useEffect, useMemo } from 'react';
import { Select } from 'ol/interaction';
import { Feature, MapBrowserEvent } from 'ol';
import { SelectEvent } from 'ol/interaction/Select';
import { useMapContext, useVectorContext } from './hooks';

export interface SelectFeaturesProps {
  onFeaturesSelect?: (features: Feature[]) => void;
}

export const SelectFeatures: React.FC<SelectFeaturesProps> = ({
  onFeaturesSelect,
}) => {
  const { map } = useMapContext();
  const { layer } = useVectorContext();

  const select = useMemo(() => {
    return new Select({ layers: [layer] });
  }, [layer]);

  useEffect(() => {
    const iconChangeOnMove = (e: MapBrowserEvent) => {
      const hit = map.forEachFeatureAtPixel(e.pixel, (feat, l) => {
        return l === layer;
      });

      if (hit) map.getTargetElement().style.cursor = 'pointer';
      else map.getTargetElement().style.cursor = '';
    };

    map.on('pointermove', iconChangeOnMove);

    return () => {
      map.un('pointermove', iconChangeOnMove);
    };
  }, [map, layer]);

  useEffect(() => {
    map.addInteraction(select);

    return () => {
      map.removeInteraction(select);
    };
  }, [map, select]);

  useEffect(() => {
    const onSelect = (e: SelectEvent) => onFeaturesSelect?.(e.selected);

    select.on('select', onSelect);

    return () => {
      select.un('select', onSelect);
    };
  }, [select, onFeaturesSelect]);

  return <div data-testid="ol-select-features" />;
};
