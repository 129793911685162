// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OpenLayers_container__Zrysz{position:relative;width:100%;height:100%}.OpenLayers_container__Zrysz .OpenLayers_mapWrapper__eBb8v{width:100%;height:100%}.OpenLayers_container__Zrysz .OpenLayers_imageLoadingWrapper__knDuF{display:flex;justify-content:center;flex-direction:column;align-items:center;position:absolute;width:100%;height:100%;top:0}.OpenLayers_container__Zrysz .OpenLayers_screenShotBtnWrapper__7WYKz{display:none}.OpenLayers_olTooltip__AU5di{position:relative;background:rgba(0,0,0,.5);border-radius:4px;color:#fff;padding:4px 8px;opacity:.7;white-space:nowrap;font-size:12px}", "",{"version":3,"sources":["webpack://./src/components/OpenLayers/index.module.scss","webpack://./src/styles/scss/base/mixins/_horz-vert-center.scss"],"names":[],"mappings":"AAEA,6BACE,iBAAA,CACA,UAAA,CACA,WAAA,CAEA,2DACE,UAAA,CACA,WAAA,CAGF,oECTA,YAAA,CACA,sBAAA,CACA,qBAAA,CAGE,kBAAA,CDOA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,KAAA,CAGF,qEACE,YAAA,CAIJ,6BACE,iBAAA,CACA,yBAAA,CACA,iBAAA,CACA,UAAA,CACA,eAAA,CACA,UAAA,CACA,kBAAA,CACA,cAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n\n  .mapWrapper {\n    width: 100%;\n    height: 100%;\n  }\n\n  .imageLoadingWrapper {\n    @include horz-vert-center();\n\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n  }\n\n  .screenShotBtnWrapper {\n    display: none;\n  }\n}\n\n.olTooltip {\n  position: relative;\n  background: rgba(0, 0, 0, 0.5);\n  border-radius: 4px;\n  color: white;\n  padding: 4px 8px;\n  opacity: 0.7;\n  white-space: nowrap;\n  font-size: 12px;\n}\n","// example: @include horz-vert-center();\n\n@mixin horz-vert-center($alignItem: true) {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n\n  @if $alignItem {\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OpenLayers_container__Zrysz",
	"mapWrapper": "OpenLayers_mapWrapper__eBb8v",
	"imageLoadingWrapper": "OpenLayers_imageLoadingWrapper__knDuF",
	"screenShotBtnWrapper": "OpenLayers_screenShotBtnWrapper__7WYKz",
	"olTooltip": "OpenLayers_olTooltip__AU5di"
};
export default ___CSS_LOADER_EXPORT___;
