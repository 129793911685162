import moment from 'moment';
import * as React from 'react';
import style from './index.module.scss';

interface IProps {
  author: string;
  message: string;
  capturedAt: Date;
}

class IssueComment extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { author, message, capturedAt } = this.props;

    return (
      <div className={style.commentContainer}>
        <h4>{author}</h4>
        <h5>{` - ${moment(capturedAt).format('ll')}`}</h5>
        <section>{message}</section>
      </div>
    );
  }
}

export default IssueComment;
