import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { Link } from 'react-router-dom';
import * as ControlBox from '../ControlBox/ControlBox';
import styles from './MarkIssueControlBox.module.scss';
import { DrawControlIntentTypes } from '../DrawControl/DrawControl.types';
import { ViewControlsV2TypeTypes } from '../ViewControlsV2/index.types';
import { undefinedOrNull, isEmpty } from '../../utils/functs';
import { GenericObjectType, NullOrGenericObjectType } from '../../shapes/app';
import { issueUrl } from '../../routes/urls';
import { appFormatDate } from '../../utils/date';
import { RouterParamsTypes } from '../../shapes/routerParams';
import LoadingOverlay from '../LoadingOverlay';
import { Tag } from '../../api/issue.types';
import SelectIssueTagsModal from '../SelectIssueTagsModal';
import Issue from '../../api/issue';
import { VolumeType } from '../VolumeCalculator/VolumeCalculator';

const { Text } = Typography;

interface IProps extends Pick<RouterParamsTypes, 'history'> {
  itemType?: ViewControlsV2TypeTypes;
  onClose?: (itemType: ViewControlsV2TypeTypes) => void;
  viewId: string;
  createIssue?: (
    viewId: string,
    title: string,
    message: string,
    config: object,
    shapeGeoJson: string | null,
    screenshot?: string,
    selectedTagIds?: string[]
  ) => Promise<NullOrGenericObjectType>;
  creating?: boolean;
  configCreator: () => GenericObjectType;
  shapeGeoJsonCreator: () => string | null;
  onIntentChange: (intent?: DrawControlIntentTypes) => void;
  onScreenShot: () => string | undefined;
  userRole: string;
  issue?: NullOrGenericObjectType;
  issueId?: string;
  projectId?: string;
  aoiId?: string;
  planeElevation?: number | null;
  volumeType?: VolumeType;
}

interface IState {
  title: string;
  message: string;
  created: boolean;
  error: string | null;
  createdIssue: NullOrGenericObjectType;
  loadingFormSubmit: boolean;
  showModal: boolean;
  selectedTags: Array<string>;
  allTags: Tag[];
  selectTagsModalError: string;
}

const issueApi = new Issue();

class MarkIssueControlBox extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      title: '',
      message: '',
      error: null,
      created: false,
      createdIssue: null,
      loadingFormSubmit: false,
      showModal: false,
      selectedTags: [],
      allTags: [],
      selectTagsModalError: '',
    };
  }

  public componentDidMount(): void {
    const { projectId } = this.props;

    if (!undefinedOrNull(projectId)) {
      issueApi
        .fetchTagsByProject(projectId)
        .then((response) => {
          this.setState({ allTags: response.data });
        })
        .catch(() => {
          this.setState({
            selectTagsModalError: 'Error in fetching tags data',
          });
        });
    }
  }

  @autobind
  public handleTitle(e: any): void {
    this.setState({
      title: e.target.value,
    });
  }

  @autobind
  public handleMessage(e: any): void {
    this.setState({
      message: e.target.value,
    });
  }

  @autobind
  public async handleCreateIssue(): Promise<void> {
    const {
      createIssue,
      viewId,
      onScreenShot,
      configCreator,
      shapeGeoJsonCreator,
      planeElevation,
      volumeType,
    } = this.props;
    const { title, message, selectedTags } = this.state;
    const config = configCreator();
    const screenshot = onScreenShot();

    this.setState({
      loadingFormSubmit: true,
    });

    if (
      planeElevation !== undefined &&
      planeElevation !== null &&
      config.measurementType === 'volume' &&
      volumeType === 'FlatPlane'
    ) {
      config.volumePlaneElevation = planeElevation;
    }

    const selectedTagIds = selectedTags.map((tag) => tag.split(',')[0]);

    if (title === null || title.trim().length === 0) {
      this.setState({
        error: 'Please provide a title for the issue!',
        loadingFormSubmit: false,
      });
    } else if (createIssue) {
      const createdIssue = await createIssue(
        viewId,
        title,
        message,
        config,
        shapeGeoJsonCreator(),
        screenshot,
        selectedTagIds
      );

      this.setState({
        loadingFormSubmit: false,
        error: !undefinedOrNull(createdIssue)
          ? null
          : 'There was an unexpected error when trying to submit the issue. Please try again later, or contact support@aspecscire.com for further assistance!',
        title: '',
        message: '',
        created: true,
        createdIssue,
      });
    }
  }

  @autobind
  public handleIssueTagging(): void {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  }

  @autobind
  private markTagsOnIssue(tagNames: Array<string>): void {
    this.setState({ selectedTags: tagNames });
  }

  @autobind
  private RenderHeader(): React.ReactElement<any> {
    const { onClose, itemType, issue, issueId } = this.props;

    const isIssueEditing = !undefinedOrNull(issue) && !undefinedOrNull(issueId);

    const title = isIssueEditing ? 'Issue' : 'Mark Issue';

    return (
      <React.Fragment>
        <ControlBox.Title title={title} />
        {!isIssueEditing && (
          <div onClick={this.handleIssueTagging} className={styles.addTags}>
            <i className="fa fa-tag fa-2x" />
          </div>
        )}
        <ControlBox.Icon
          onClick={() => {
            if (!onClose || undefinedOrNull(itemType)) {
              return;
            }

            onClose(itemType);
          }}
          name="close"
        />
      </React.Fragment>
    );
  }

  public RenderForm = (): JSX.Element | null => {
    const { creating, userRole } = this.props;
    const { title, message, error, selectedTags } = this.state;

    const tagsToRender = selectedTags.map((tag) => tag.split(',')[1]);

    return (
      <>
        <div className={styles.markedTags}>
          {isEmpty(selectedTags) ? (
            <p>No Tags selected..</p>
          ) : (
            tagsToRender.join(', ')
          )}
        </div>
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
          }}
        >
          {error ? <div className={styles.errorDiv}>{error}</div> : null}

          <label>Title</label>
          <input
            disabled={creating}
            type="text"
            value={title}
            placeholder="Enter title"
            onChange={this.handleTitle}
          />
          <label>Description</label>
          <textarea
            disabled={creating}
            onChange={this.handleMessage}
            value={message}
            placeholder="Briefly describe the issue"
          />
          {userRole === 'read_only' ? (
            <Tooltip
              placement="right"
              title="The use of this feature requires change in the user role . Please contact project admin if you need that."
            >
              <input
                disabled={userRole === 'read_only'}
                type="button"
                onClick={this.handleCreateIssue}
                value="SUBMIT ISSUE"
              />
            </Tooltip>
          ) : (
            <input
              disabled={creating}
              type="button"
              onClick={this.handleCreateIssue}
              value={!creating ? 'SUBMIT ISSUE' : 'SUBMITTING...'}
            />
          )}
        </form>
      </>
    );
  };

  private mapTagIdsToTagNames = (tagIds: string[] | undefined): string[] => {
    const { allTags } = this.state;
    const tagNames: string[] = [];

    if (!undefinedOrNull(tagIds)) {
      for (let i = 0; i < tagIds.length; i += 1) {
        const tagData = allTags.find((tag) => tag.id === tagIds[i]);

        if (!undefinedOrNull(tagData)) {
          tagNames.push(tagData.tagName);
        }
      }
    }

    return tagNames;
  };

  public RenderIssueInfo = (): JSX.Element | null => {
    const { issue, projectId, aoiId } = this.props;

    if (undefinedOrNull(issue) || !projectId || !aoiId) {
      return null;
    }

    return (
      <div className={styles.issueInfoWrapper}>
        <p>{this.mapTagIdsToTagNames(issue.tagIds).join(', ')}</p>
        <div className={styles.titleWrapper}>
          <Text>{issue.title}</Text>
        </div>
        <div className={styles.descWrapper}>
          <Text>{issue.message}</Text>
        </div>
        <div className={styles.authorWrapper}>
          <Text>
            &nbsp;— {issue.author} on {appFormatDate(issue.createdAt)}
          </Text>
        </div>
        <div className={styles.issuesLinkWrapper}>
          <Link to={issueUrl(projectId, aoiId, issue.id, { reload: 1 })}>
            Go to the issue
          </Link>
        </div>
      </div>
    );
  };

  public RenderFormSubmit = (): JSX.Element | null => {
    const { creating, projectId, aoiId } = this.props;
    const { createdIssue } = this.state;

    if (creating) {
      return (
        <div className={styles.loading}>
          <LoadingOutlined />
        </div>
      );
    }

    return (
      <div className={styles.issueInfoWrapper}>
        <div className={styles.titleWrapper}>
          <span>The issue was submitted successfully</span>
        </div>

        {!undefinedOrNull(createdIssue) &&
          !undefinedOrNull(projectId) &&
          !undefinedOrNull(aoiId) && (
            <div className={styles.issuesLinkWrapper}>
              <Link
                to={issueUrl(projectId, aoiId, createdIssue.id, { reload: 1 })}
              >
                Go to the issue
              </Link>
            </div>
          )}
      </div>
    );
  };

  public render(): React.ReactNode {
    const { issue, issueId } = this.props;
    const {
      created,
      error,
      loadingFormSubmit,
      showModal,
      allTags,
      selectTagsModalError,
      selectedTags,
    } = this.state;

    const { RenderForm, RenderFormSubmit, RenderIssueInfo } = this;

    return (
      <React.Fragment>
        <ControlBox.Wrapper
          className={styles.container}
          renderHeader={this.RenderHeader}
        >
          {(undefinedOrNull(error) || error.trim() === '') &&
          !undefinedOrNull(issueId) &&
          !undefinedOrNull(issue) ? (
            <RenderIssueInfo />
          ) : (undefinedOrNull(error) || error.trim() === '') && created ? (
            <RenderFormSubmit />
          ) : (
            <RenderForm />
          )}
        </ControlBox.Wrapper>

        {loadingFormSubmit ? <LoadingOverlay /> : null}
        {showModal ? (
          <SelectIssueTagsModal
            allTags={allTags}
            isModalVisible={showModal}
            previouslyMarkedTags={selectedTags}
            handleMarkedIssues={this.markTagsOnIssue}
            modalErrorMessage={selectTagsModalError}
            closeModal={this.handleIssueTagging}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default MarkIssueControlBox;
