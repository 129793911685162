import ShareAPIs from '../../api/Share';

const api = new ShareAPIs();

export function fetchShare(shareId: string): {} {
  return {
    type: 'FETCH_SHARE',
    payload: api.fetchShare(shareId),
  };
}

export function clearShareState(): {} {
  return {
    type: 'CLEAR_SHARE',
  };
}
