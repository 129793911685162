import { connect } from 'react-redux';
import LegendControlBox from './LegendControlBox';

const mapStateToProps = (state: any): any => {
  return {
    legends: state.views.legends,
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return {};
};

const LegendControlBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LegendControlBox);

export default LegendControlBoxContainer;
