import * as React from 'react';
import classnames from 'classnames';
import style from './SnackbarItem.module.scss';
import { SNACKBAR_Z_INDEX } from '../../constants';
import { SnackbarReducersStateSnackbarsTypes } from '../../shapes/snackbar';
import ModalNotification from '../ModalNotification/ModalNotification';

interface IProps {
  snackbar: SnackbarReducersStateSnackbarsTypes;
  onHideSnackbar: () => void;
}

class SnackbarItem extends React.Component<IProps> {
  private getTitle = (): string => {
    const { snackbar } = this.props;

    if (!snackbar.title) {
      switch (snackbar.type) {
        case 'error':
        default:
          return 'Error';
        case 'info':
          return 'Information!';
        case 'warning':
          return 'Warning!';
        case 'success':
          return 'Success!';
      }
    }

    return snackbar.title;
  };

  private getIcon = (): string => {
    const { snackbar } = this.props;

    if (!snackbar.title) {
      switch (snackbar.type) {
        case 'error':
        default:
          return 'fa-times';
        case 'info':
          return 'fa fa-info';
        case 'warning':
          return 'fa-exclamation';
        case 'success':
          return 'fa-check';
      }
    }

    return snackbar.title;
  };

  public render(): React.ReactNode {
    const { onHideSnackbar, snackbar } = this.props;

    if (snackbar.isModal) {
      return (
        <ModalNotification
          notificationTitle={this.getTitle()}
          notificationBody={snackbar.body}
          handleModalClose={onHideSnackbar}
          shownotificationModal
          zIndex={SNACKBAR_Z_INDEX}
        />
      );
    }

    return (
      <div className={classnames(style.container, style[snackbar.type])}>
        <div className={style.innerContainer}>
          <div className={style.iconWrapper}>
            <i
              className={classnames(`fa`, ` fa-3x`, this.getIcon())}
              aria-hidden="true"
            />
          </div>
          <div className={style.bodyWrapper}>
            <p className={style.title}>{this.getTitle()}</p>
            <p className={style.body}>{snackbar.body}</p>
          </div>
        </div>
        <div className={style.closeWrapper}>
          <i
            onClick={() => {
              onHideSnackbar();
            }}
            className="fa fa-times"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }
}

export default SnackbarItem;
