import { connect } from 'react-redux';
import {
  getHeaderMenu,
  getSelectedViewType,
} from '../../redux/selectors/views';
import MapViewHeader from './MapViewHeader';

const mapStateToProps = (
  state: any,
  { viewId, projectId, aoiId, splitViewId }: any
): {} => {
  return {
    projectId,
    aoiId,
    viewId,
    menus: getHeaderMenu(state, { viewId, splitViewId }),
    activeType: getSelectedViewType(state, { viewId }),
  };
};

const mapDispatchToProps = (): {} => {
  return {};
};

const MapViewHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapViewHeader);

export default MapViewHeaderContainer;
