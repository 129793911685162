import * as React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

interface IProps {
  size: number;
  position: 'fixed' | 'absolute' | 'static';
  className?: string;
}

export default class SkeletonLoader extends React.PureComponent<IProps> {
  public static defaultProps: Partial<IProps> = {
    size: 3,
    position: 'static',
  };

  public componentDidMount(): void {
    const { position } = this.props;

    if (position === 'fixed') {
      // @todo: work on it issue: https://github.com/AspecScire/vimana_web_ng/issues/1214
      // setBodyOverflow('y');
    }
  }

  public componentWillUnmount(): void {
    const { position } = this.props;

    if (position === 'fixed') {
      // @todo: work on it issue: https://github.com/AspecScire/vimana_web_ng/issues/1214
      // resetBodyOverFlow('y');
    }
  }

  private printItems = () => {
    const { size } = this.props;

    const _return = [];

    for (let i = 0; i < size; i += 1) {
      _return.push(
        <div key={i} className={styles.innerWrapper}>
          <div className={styles.avatar} />
          <div className={styles.line} />
          <div className={styles.line} />
        </div>
      );
    }

    return _return;
  };

  public render(): React.ReactNode {
    const { position, className } = this.props;

    return (
      <div
        className={classnames(styles.container, styles[position], className)}
      >
        <div className={styles.skeleton}>{this.printItems()}</div>
      </div>
    );
  }
}
