import classnames from 'classnames';
import * as React from 'react';
import style from './ShapeInfo.module.scss';

const ShapeInfo = ({ scrollable, children }: any): JSX.Element => (
  <div
    className={classnames(
      style.container,
      scrollable ? style.scrollable : null
    )}
  >
    {children}
  </div>
);

export default ShapeInfo;
