import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionShowSnackbar } from '../../../redux/actions/snackbar';
import { closeOrganisationSettings } from '../../../redux/actions/settings';
import UserSettings from '.';

const mapStateToProps = (state: any): any => {
  return {
    isOpen: state.settings.isOrganisationOpen,
    userId: state.auth.user.user_id,
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
      closeOrganisationSettings,
    },
    dispatch
  );
};

const OrganisationSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettings);

export default OrganisationSettingsContainer;
