import autobind from 'autobind-decorator';
import * as React from 'react';
import { RouteComponentProps, withRouter, matchPath } from 'react-router';
import { Step } from 'react-joyride';
import { Popover } from '../Antd';
import Icon from '../Icon';
import style from './UserMenu.module.scss';
import Logo from '../Logo';
import OrganisationAPIs from '../../api/organisations';
import { paymentsHistoryUrl, paymentsUrl } from '../../routes/urls';
import {
  objectListToQueryString,
  undefinedOrNull,
  uuid,
} from '../../utils/functs';
import { Organisation } from '../../api/organisations.types';
import { View } from '../../api/views.types';
import { VIEW_PATH } from '../../routes/paths';
import {
  getCoachMarkPreference,
  getValidCoachMarkUrl,
} from '../../utils/localStorage';
import { analytics } from '../../utils/analytics';
import { getCoachmarkStepsForURLPath } from './coachmarkGroups';

interface ViewPathParams {
  viewId: string;
  projectId: string;
  aoiId: string;
}

interface IProps {
  logout?: any;
  views?: View[];
  openSettings?: () => void;
  openOrganisationSettings?: () => void;
  showCoachMarks: (steps: Step[]) => void;
  resetCoachMarks: () => void;
  name: string;
  partnerId?: string;
}

interface IState {
  isPopupOpen: boolean;
  organisations: Organisation[];
}

const organisationApis = new OrganisationAPIs();

class UserMenu extends React.Component<IProps & RouteComponentProps, IState> {
  public constructor(props: IProps & RouteComponentProps) {
    super(props);
    this.state = {
      isPopupOpen: false,
      organisations: [],
    };
  }

  public componentDidMount() {
    organisationApis
      .listUserOrganisations()
      .then((res) => {
        this.setState({
          organisations: res,
        });
      })
      .catch((err) =>
        console.error(
          'There was an error while fetching organisations for the specified user',
          err
        )
      );
  }

  @autobind
  private handleClose(visible: boolean): void {
    if (visible) {
      return;
    }

    this.setState({
      isPopupOpen: false,
    });
  }

  @autobind
  private handleOpen(): void {
    this.setState({
      isPopupOpen: true,
    });
  }

  @autobind
  private handleLogout(): void {
    const { logout, history, partnerId } = this.props;

    logout(() => {
      analytics.logout();
      history.push(`/${objectListToQueryString({ partnerId })}`);
    });
  }

  private handlePayClick = () => {
    const { history } = this.props;

    history.push(paymentsUrl());
  };

  private handlePaymentHistoryClick = () => {
    const { history } = this.props;

    history.push(paymentsHistoryUrl());
  };

  private shouldShowProductTourButton = () => {
    const { location } = this.props;

    const viewURLPath = matchPath(location.pathname, {
      path: VIEW_PATH,
      exact: true,
      strict: false,
    });

    if (!undefinedOrNull(viewURLPath)) {
      const params = viewURLPath.params as ViewPathParams;
      const currentView = this.getCurrentView(params.viewId);

      if (!undefinedOrNull(currentView)) {
        return !undefinedOrNull(
          getCoachMarkPreference(location.pathname, currentView.type)
        );
      }
    }

    return !undefinedOrNull(getCoachMarkPreference(location.pathname));
  };

  private getCurrentView = (viewId: string) => {
    const { views } = this.props;

    if (!undefinedOrNull(views)) {
      return views.find((view) => view.id === viewId);
    }

    return null;
  };

  private handleFeatureTour = () => {
    const { location, showCoachMarks, resetCoachMarks } = this.props;

    // show_coachmarks based on current route path
    const genericURL = getValidCoachMarkUrl(location.pathname);

    if (!undefinedOrNull(genericURL)) {
      // remove
      resetCoachMarks(); // this added because user tries to access this

      const steps = [
        getCoachmarkStepsForURLPath(genericURL)[0],
        { content: `${uuid()}`, target: '#vimana-dummy-id' }, // this step helps to differentiate coachmark group in App
        ...getCoachmarkStepsForURLPath(genericURL).slice(1),
      ];

      showCoachMarks(steps.filter((s) => s !== undefined));
    }

    const viewURLPath = matchPath(location.pathname, {
      path: VIEW_PATH,
      exact: true,
      strict: false,
    });

    if (!undefinedOrNull(viewURLPath)) {
      const params = viewURLPath.params as ViewPathParams;
      const currentView = this.getCurrentView(params.viewId);

      if (!undefinedOrNull(currentView)) {
        resetCoachMarks();
        showCoachMarks([
          getCoachmarkStepsForURLPath(`${VIEW_PATH}:${currentView.type}`)[0],
          { content: `${uuid()}`, target: '#vimana-dummy-id' }, // this step helps to differentiate coachmark group in index.tsx
          ...getCoachmarkStepsForURLPath(
            `${VIEW_PATH}:${currentView.type}`
          ).slice(1),
        ]);
      }
    }
  };

  private renderPopoverContent(): JSX.Element {
    const { organisations } = this.state;
    const { openSettings, openOrganisationSettings, name } = this.props;

    return (
      <div className={style.userCont}>
        <div className={style.name}>
          Hi,&nbsp;
          {name}
        </div>
        <a onClick={() => openSettings && openSettings()}>
          <div className={style.menuItem}>User Settings</div>
        </a>
        {organisations && organisations.length > 0 ? (
          <>
            <a
              onClick={() =>
                openOrganisationSettings && openOrganisationSettings()
              }
            >
              <div className={style.menuItem}>Organisations</div>
            </a>
            <a
              onClick={() => {
                this.handlePayClick();
              }}
            >
              <div className={style.menuItem}>Pay</div>
            </a>
            <a
              onClick={() => {
                this.handlePaymentHistoryClick();
              }}
            >
              <div className={style.menuItem}>Payment History</div>
            </a>
          </>
        ) : (
          <></>
        )}
        {this.shouldShowProductTourButton() ? (
          <a onClick={this.handleFeatureTour}>
            <div className={style.menuItem}>FEATURE TOUR</div>
          </a>
        ) : null}
        <a onClick={this.handleLogout}>
          <div className={style.menuItem}>Logout</div>
        </a>
        <div className={style.waterMarkContainer}>
          <Logo className={style.waterMarkLogo} /> Vimana by Aspec Scire Inc.
        </div>
      </div>
    );
  }

  public render(): React.ReactNode {
    const { isPopupOpen } = this.state;

    return (
      <div className={style.container}>
        <Popover
          content={this.renderPopoverContent()}
          placement="bottomRight"
          visible={isPopupOpen}
          onVisibleChange={this.handleClose}
        >
          <div onClick={this.handleOpen} className={style.userIcon}>
            <Icon name="user" size="small" />
          </div>
        </Popover>
      </div>
    );
  }
}

export default withRouter(UserMenu);
