import { Checkbox, Modal, Select, Switch } from 'antd';
import * as React from 'react';
import ViewsV2Apis from '../../../api/viewsV2';
import { View } from '../../../api/views.types';
import { appFormatDate } from '../../../utils/date';
import { log } from '../../../utils/log';
import ModalNotification from '../../ModalNotification/ModalNotification';
import { MapStateProvider } from '../../View/MapView/OpenLayersRenderer/MapStateProvider';
import PanoramaStateProvider from '../../View/PanoramaView/PannellumRenderer/PanoramaStateProvider';
import PotreeStateProvider from '../../View/ThreeDView/PotreeStateProvider';
import style from './index.module.scss';
import { ViewVectors } from './ViewVectors';

const SelectOption = Select.Option;

interface IProps {
  projectId: string;
  aoiId: string;
  missionId: string;
  view: View;
  allViews: View[];
  aoiViews: View[];
  showAdminFields?: boolean;
  previewMode: 'preview' | 'edit';
  getViewConfigFn?: () => any;
  onViewUpdated: (view: any) => void;
  onViewPropsUpdate: (p: any) => void;
  onUpdatingStatusChanged: (updating: boolean) => void;
  onViewFragsUpdate: (frags: any) => void;
  onEditorFragUpdate: (frag: any) => void;
  onPreviewModeChange: (previewMode: 'preview' | 'edit') => void;
  controlManageModalVisibility?: (show: boolean) => void;
}

interface IState {
  viewApis: ViewsV2Apis;
  viewUpdateRequest: any;
  updating: boolean;
  editorHasChanges: boolean;
  showUnpublishOthersModal: boolean;
  updateError?: string;
  selected3DViewId?: string | null;
  showUpdateSuccessModal: boolean;
  showRetriggerMessage: boolean;
}

export default class ViewEditor extends React.Component<IProps, IState> {
  // this is a ref to the MapStateProvider component
  // used for getting map config and screenshot
  private mapStateProviderRef: React.RefObject<MapStateProvider> =
    React.createRef();

  // this is a ref to the PanoramaStateProvider component
  // used for getting pano config and screenshot
  private panoStateProviderRef: React.RefObject<PanoramaStateProvider> =
    React.createRef();

  // this is a ref to the PotreeStateProvider component
  // used for getting pano config and screenshot
  private potreeStateProviderRef: React.RefObject<PotreeStateProvider> =
    React.createRef();

  constructor(props: IProps) {
    super(props);

    let viewUpdateRequest = {};

    if (props.view) {
      viewUpdateRequest = this.syncUpdateRequest(
        props.view,
        props.showAdminFields || false
      );
    }

    this.state = {
      viewApis: new ViewsV2Apis(),
      viewUpdateRequest,
      updating: false,
      editorHasChanges: false,
      showUnpublishOthersModal: false,
      showUpdateSuccessModal: false,
      showRetriggerMessage: false,
    };
  }

  public componentDidMount() {
    const { view, showAdminFields } = this.props;

    if (view) {
      const viewUpdateRequest = this.syncUpdateRequest(
        view,
        showAdminFields || false
      );

      this.setState({ viewUpdateRequest }, () => {
        if (view.type === 'inspection') {
          this.getDefaultInsetViewId(view);
        }

        this.updateFrags(undefined, view);
      });
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { view, showAdminFields } = this.props;
    const { view: nextView } = nextProps;

    if (view.id !== nextView.id) {
      const viewUpdateRequest = this.syncUpdateRequest(
        nextView,
        showAdminFields || false
      );

      this.setState(
        {
          viewUpdateRequest,
          updating: false,
          editorHasChanges: false,
          selected3DViewId: null,
        },
        () => {
          if (nextView.type === 'inspection') {
            this.getDefaultInsetViewId(nextView);
          }

          this.updateFrags(undefined, nextView);
        }
      );
    }
  }

  private getDefaultInsetViewId(view: any) {
    const { viewApis, viewUpdateRequest } = this.state;
    const { onViewPropsUpdate } = this.props;
    const { projectId, aoiId, id } = view;

    viewApis
      .getViewDescriptor(projectId, aoiId, id)
      .then((res) => {
        const { error, data } = res;

        if (error) {
          log.error(error, 'InspectionUploadCrud.getViewDescriptor');

          return;
        }

        this.setState(
          {
            selected3DViewId: data.potreeInsetViewId,
            viewUpdateRequest: {
              insetViewId: data.potreeInsetViewId,
              ...viewUpdateRequest,
            },
          },
          () => {
            onViewPropsUpdate({
              potreeInsetViewIdOverride: data.potreeInsetViewId,
            });
          }
        );
      })
      .catch((error) => {
        log.error(error, 'InspectionUploadCrud.getViewDescriptor');
      });
  }

  private syncUpdateRequest(view: any, showAdminFields: boolean) {
    const viewUpdateRequest: any = {};

    if (view) {
      viewUpdateRequest.certifiedForDisplay = view.certifiedForDisplay;
      viewUpdateRequest.name = view.name || '';

      if (showAdminFields) {
        viewUpdateRequest.certifiedForAdmin = view.certifiedForAdmin;
        viewUpdateRequest.certifiedForMeasurement =
          view.certifiedForMeasurement;
      }

      if (view.type === 'map') {
        viewUpdateRequest.zoomDefault = view.zoomDefault || 15;
        viewUpdateRequest.zoomMax = view.zoomMax || 15;
        viewUpdateRequest.zoomMin = view.zoomMin || 15;
      }
    }

    return viewUpdateRequest;
  }

  private updateDefaultViewConfig() {
    let viewState: any;

    if (this.mapStateProviderRef?.current) {
      viewState = this.mapStateProviderRef.current.getMapState();
    } else if (this.panoStateProviderRef?.current) {
      viewState = this.panoStateProviderRef.current.getPanoState();
    } else if (this.potreeStateProviderRef?.current) {
      viewState = this.potreeStateProviderRef.current.getPotreeState();
    }

    const { viewApis, viewUpdateRequest } = this.state;
    const { view, getViewConfigFn } = this.props;
    const { insetViewId } = viewUpdateRequest || {};

    if (getViewConfigFn) {
      viewState = { ...(getViewConfigFn() || {}), ...viewState };
    }

    const req: any = {
      defaultViewConfig: viewState,
    };

    if (view.type === 'inspection') {
      req.insetViewId = insetViewId;
    }

    if (viewState && view) {
      const { projectId, aoiId, id: viewId } = view;

      this.setState({ updating: true }, () => {
        viewApis
          .updateView(projectId, aoiId, viewId, req)
          .then((res) => {
            const { data, error } = res;

            if (error) {
              console.error(error);
            } else if (data) {
              view.viewConfig = data;
            }
          })
          .finally(() => {
            this.setState({ updating: false });
          });
      });
    }
  }

  private closeUpdateSuccessModal = () => {
    this.setState({ showUpdateSuccessModal: false });
  };

  private showRetriggerMessage = (): boolean => {
    const { view } = this.props;
    const { viewUpdateRequest } = this.state;

    if (
      view.certifiedForDisplay !== viewUpdateRequest.certifiedForDisplay &&
      !viewUpdateRequest.certifiedForDisplay
    ) {
      switch (view.type) {
        case 'inspection':
        case 'perspective':
          return true;
        default:
          break;
      }
    }

    return false;
  };

  private update() {
    const { view } = this.props;

    if (view) {
      this.doUpdate(view, false);
    }
  }

  private doUpdate(view: any, unpublishOthers: boolean) {
    if (!view) {
      return;
    }

    const { allViews, onViewUpdated, onUpdatingStatusChanged } = this.props;

    this.setState({ updating: true, showUnpublishOthersModal: false }, () => {
      onUpdatingStatusChanged(true);
    });

    const { viewUpdateRequest, viewApis } = this.state;
    const { projectId, aoiId, id } = view;
    const updateErrorMsg =
      'Error while updating view status, please try again or contact support@aspecscire.com';

    const updater = () => {
      viewApis
        .updateView(projectId, aoiId, id, viewUpdateRequest)
        .then((res) => {
          this.setState({
            updating: false,
            showRetriggerMessage: this.showRetriggerMessage(),
            showUpdateSuccessModal: true,
          });
          onUpdatingStatusChanged(false);
          const { data: updatedView, error } = res;

          if (updatedView) {
            onViewUpdated(updatedView);
          }

          if (error) {
            throw error;
          }
        })
        .catch((err: any) => {
          this.setState({ updating: false, updateError: updateErrorMsg });
          onUpdatingStatusChanged(false);
          log.error(err);
        });
    };

    if (unpublishOthers) {
      const otherPublishedViews = allViews.filter(
        (v: any) =>
          v.type === view.type &&
          v.subType === view.subType &&
          v.id !== view.id &&
          v.certifiedForDisplay
      );

      if (otherPublishedViews && otherPublishedViews.length) {
        const v = otherPublishedViews[0];

        viewApis
          .updateView(projectId, aoiId, v.id, {
            certifiedForDisplay: false,
          })
          .then((res) => {
            const { data: updatedView, error } = res;

            if (updatedView) {
              onViewUpdated(updatedView);
              updater();
            }

            if (error) {
              throw error;
            }
          })
          .catch((err: any) => {
            this.setState({ updating: false, updateError: updateErrorMsg });
            onUpdatingStatusChanged(false);
            log.error(err);
          });
      }
    } else {
      updater();
    }
  }

  private renderViewSpecificEditors() {
    const {
      view,
      aoiViews,
      previewMode,
      onViewPropsUpdate,
      onPreviewModeChange,
    } = this.props;
    const { selected3DViewId, viewUpdateRequest } = this.state;

    if (view.type === 'inspection') {
      return (
        <label className={style.inputField}>
          <span className={style.inputLabel}>Select 3D view for inset</span>
          <Select
            placeholder="Pick a view"
            style={{ display: 'block' }}
            onChange={(vId: any) => {
              this.setState(
                {
                  selected3DViewId: vId,
                  viewUpdateRequest: {
                    ...viewUpdateRequest,
                    insetViewId: vId,
                  },
                  editorHasChanges: true,
                },
                () => {
                  onViewPropsUpdate({ potreeInsetViewIdOverride: vId });
                }
              );
            }}
            value={selected3DViewId}
            defaultValue={selected3DViewId}
          >
            <SelectOption key="" value={undefined}>
              {'None'}
            </SelectOption>
            {(aoiViews || [])
              .filter((v) => v.type === 'three_d')
              .map((item: any) => {
                return (
                  <SelectOption key={item.id} value={item.id}>
                    &apos;{item.name ? item.name : item.id}&apos; on{' '}
                    {appFormatDate(item.createdAt)}
                  </SelectOption>
                );
              })}
          </Select>
        </label>
      );
    }

    if (view.type === 'map') {
      return (
        <>
          <label className={style.inputField}>
            <span className={style.inputLabel}>Default Zoom</span>
            <input
              type="number"
              value={viewUpdateRequest.zoomDefault}
              onChange={(e) => {
                this.setState({
                  viewUpdateRequest: {
                    ...viewUpdateRequest,
                    zoomDefault: parseFloat(e.target.value),
                  },
                  editorHasChanges: true,
                });
              }}
            />
          </label>
          <label className={style.inputField}>
            <span className={style.inputLabel}>Max Zoom</span>
            <input
              type="number"
              value={viewUpdateRequest.zoomMax}
              onChange={(e) => {
                this.setState({
                  viewUpdateRequest: {
                    ...viewUpdateRequest,
                    zoomMax: parseFloat(e.target.value),
                  },
                  editorHasChanges: true,
                });
              }}
            />
          </label>
          <label className={style.inputField}>
            <span className={style.inputLabel}>Min Zoom</span>
            <input
              type="number"
              value={viewUpdateRequest.zoomMin}
              onChange={(e) => {
                this.setState({
                  viewUpdateRequest: {
                    ...viewUpdateRequest,
                    zoomMin: parseFloat(e.target.value),
                  },
                  editorHasChanges: true,
                });
              }}
            />
          </label>
        </>
      );
    }

    if (view.type === 'exterior_360' && !view.certifiedForDisplay) {
      return (
        <label className={style.inputField}>
          <span className={style.inputLabel}>Edit panoramas</span>
          <Switch
            checked={previewMode === 'edit'}
            onChange={(flag: boolean) =>
              onPreviewModeChange(flag ? 'edit' : 'preview')
            }
          />
        </label>
      );
    }

    return <></>;
  }

  private updateFrags = (innerEditorFrags: any, view: View) => {
    const { onViewFragsUpdate } = this.props;
    let providerFrag: any;

    switch (view.type) {
      case 'perspective':
      case 'inspection':
      case 'site_navigation':
      case 'map': {
        providerFrag = (
          <MapStateProvider
            key="editor-frag-state-provider"
            ref={this.mapStateProviderRef}
            convertToLatLon={view.type === 'map'}
          />
        );
        break;
      }

      case 'exterior_360':
      case 'interior_360': {
        providerFrag = (
          <PanoramaStateProvider
            key="editor-frag-state-provider"
            ref={this.panoStateProviderRef}
          />
        );
        break;
      }

      case 'three_d': {
        providerFrag = (
          <PotreeStateProvider
            key="editor-frag-state-provider"
            ref={this.potreeStateProviderRef}
          />
        );
        break;
      }

      default:
        break;
    }

    onViewFragsUpdate(
      <>
        {innerEditorFrags}
        {providerFrag}
      </>
    );
  };

  private handleManageModalOpen = () => {
    const { controlManageModalVisibility } = this.props;

    if (controlManageModalVisibility) {
      this.setState({ showUpdateSuccessModal: false });
      controlManageModalVisibility(true);
    }
  };

  public render() {
    const {
      viewUpdateRequest,
      updating,
      editorHasChanges,
      showUnpublishOthersModal,
      updateError,
      showUpdateSuccessModal,
      showRetriggerMessage,
    } = this.state;
    const {
      view,
      showAdminFields,
      children,
      previewMode,
      onEditorFragUpdate,
      onUpdatingStatusChanged,
    } = this.props;

    return (
      <React.Fragment>
        <label className={style.inputField}>
          <span className={style.inputLabel}>Name</span>
          <input
            disabled={previewMode === 'edit' || updating}
            value={viewUpdateRequest.name || view.name || ''}
            onChange={(e) => {
              this.setState({
                viewUpdateRequest: {
                  ...viewUpdateRequest,
                  name: e.target.value,
                },
                editorHasChanges: true,
              });
            }}
          />
        </label>

        {showAdminFields && (
          <label className={style.inputField}>
            <Checkbox
              disabled={previewMode === 'edit' || updating}
              checked={viewUpdateRequest.certifiedForAdmin}
              onChange={(e: any) =>
                this.setState({
                  viewUpdateRequest: {
                    ...viewUpdateRequest,
                    certifiedForAdmin: e.target.checked,
                  },
                  editorHasChanges: true,
                })
              }
            />
            <span className={style.inputLabelRight}>
              Visible to project admins
            </span>
          </label>
        )}
        <label className={style.inputField}>
          <Checkbox
            disabled={previewMode === 'edit' || updating}
            checked={viewUpdateRequest.certifiedForDisplay}
            onChange={(e: any) =>
              this.setState({
                viewUpdateRequest: {
                  ...viewUpdateRequest,
                  certifiedForDisplay: e.target.checked,
                },
                editorHasChanges: true,
              })
            }
          />
          <span className={style.inputLabelRight}>Published</span>
        </label>
        {showAdminFields && (
          <label className={style.inputField}>
            <Checkbox
              disabled={previewMode === 'edit' || updating}
              checked={viewUpdateRequest.certifiedForMeasurement}
              onChange={(e: any) =>
                this.setState({
                  viewUpdateRequest: {
                    ...viewUpdateRequest,
                    certifiedForMeasurement: e.target.checked,
                  },
                  editorHasChanges: true,
                })
              }
            />
            <span className={style.inputLabelRight}>
              Certified for measurement
            </span>
          </label>
        )}
        {this.renderViewSpecificEditors()}
        {children}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            className={`ant-btn ant-btn-primary ${style.inputField}`}
            style={{ display: 'block' }}
            disabled={
              view.certifiedForDisplay || previewMode === 'edit' || updating
            }
            onClick={(_) => this.updateDefaultViewConfig()}
          >
            Set initial view from current preview state
          </button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            className={`ant-btn ant-btn-primary ${style.inputField}`}
            disabled={previewMode === 'edit' || updating || !editorHasChanges}
            onClick={(_) => this.update()}
          >
            Update
          </button>
          {view.type === 'three_d' && (
            <button
              className="ant-btn ant-btn-primary"
              onClick={(_) => {
                onEditorFragUpdate(
                  <ViewVectors
                    view={view}
                    onViewFragsUpdate={(innerEditorFrags: any) =>
                      this.updateFrags(innerEditorFrags, view)
                    }
                    onUpdatingStatusChanged={onUpdatingStatusChanged}
                  />
                );
              }}
              disabled={view.certifiedForDisplay}
            >
              Manage Vector Layers
            </button>
          )}
        </div>
        <Modal
          title="Publish View"
          centered
          footer={null}
          visible={showUnpublishOthersModal}
          onCancel={() => this.setState({ showUnpublishOthersModal: false })}
          destroyOnClose
          maskClosable={false}
        >
          <div>
            Another view of the same type is already published. Do you want to
            unpublish it and publish the selected view instead?
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                className="ant-btn"
                disabled={updating}
                onClick={(_) =>
                  this.setState({ showUnpublishOthersModal: false })
                }
              >
                CANCEL
              </button>
              <button
                className="ant-btn ant-btn-primary"
                disabled={updating}
                onClick={(_) => {
                  this.doUpdate(view, true);
                }}
              >
                PUBLISH
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          title="Update Success"
          centered
          visible={showUpdateSuccessModal}
          footer={null}
          onCancel={() => this.closeUpdateSuccessModal()}
          onOk={() => this.closeUpdateSuccessModal()}
          destroyOnClose
          maskClosable={false}
        >
          <p>The view has been updated successfully.</p>

          {showRetriggerMessage ? (
            <p>
              Note: You can edit the images associated with the view, and update
              the view by triggering it again from the Manage Section of this
              mission. click{' '}
              <span style={{ textDecorationLine: 'underline' }}>
                <a
                  onClick={() => {
                    this.handleManageModalOpen();
                  }}
                >
                  here
                </a>
              </span>{' '}
              to go manage uploaded images
            </p>
          ) : (
            <></>
          )}
        </Modal>
        {updateError && (
          <ModalNotification
            notificationTitle="Update Error"
            notificationBody={updateError}
            shownotificationModal
            handleModalClose={() => this.setState({ updateError: undefined })}
            error={updateError}
            okButtonTitle="OK"
          />
        )}
      </React.Fragment>
    );
  }
}
