import React, { useState } from 'react';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import { Button } from 'src/components/Button';
import { CenteredContent } from 'src/components/CenteredContent';
import { AddLayerModal } from 'src/components/Mission/MapMaker/StyleEditor/AddLayerModal';
import style from './index.module.scss';

export interface NoLayersPlaceholder {
  onLayerAdd: (layer: LayerDescriptor) => void;
}

export const NoLayersPlaceholder: React.FC<NoLayersPlaceholder> = ({
  onLayerAdd,
}) => {
  const [showAddLayer, setShowAddLayer] = useState<boolean>(false);

  return (
    <CenteredContent>
      <div className={style.center}>
        <h2 className={style.placeholder}>Add a layer to get started!</h2>
        <div>
          <Button
            onClick={() => {
              setShowAddLayer(true);
            }}
          >
            Add new layer
          </Button>
        </div>
      </div>
      {showAddLayer ? (
        <AddLayerModal
          visible
          onCreateLayer={(l) => {
            setShowAddLayer(false);

            if (l) onLayerAdd(l);
          }}
        />
      ) : (
        <></>
      )}
    </CenteredContent>
  );
};
