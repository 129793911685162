import * as React from 'react';
import { Modal, Checkbox, Typography } from 'antd';
import { Tag } from '../../api/issue.types';
import DocumentationLink from '../DocumentationLink';
import { DOCUMENTATION_URL_LIST } from '../../constants/urls';
import styles from './index.module.scss';

interface IProps {
  allTags: Tag[];
  isModalVisible: boolean;
  modalErrorMessage: string;
  previouslyMarkedTags: string[];
  handleMarkedIssues: (tags: string[]) => void;
  closeModal: () => void;
}

const selectedTagsCheckBoxes = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: '10px',
  width: '100%',
};

const { Title } = Typography;

class SelectIssueTagsModal extends React.Component<IProps> {
  private selectTags = (checkedValues: any) => {
    const { handleMarkedIssues } = this.props;

    handleMarkedIssues(checkedValues);
  };

  private handleOK = () => {
    const { closeModal } = this.props;

    closeModal();
  };

  private handleCancel = () => {
    const { closeModal } = this.props;

    closeModal();
  };

  public render(): React.ReactNode {
    const { allTags, modalErrorMessage, previouslyMarkedTags, isModalVisible } =
      this.props;

    return (
      <Modal
        title={
          <div className={styles.titleWrapper}>
            <Title level={3}>Select Tags</Title>
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.issueTagging}
              toolTipPosition="right"
            />
          </div>
        }
        visible={isModalVisible}
        centered
        width="600"
        onOk={this.handleOK}
        onCancel={this.handleCancel}
        destroyOnClose
        zIndex={10001}
      >
        {modalErrorMessage || (
          <Checkbox.Group
            className={styles.selectedTags}
            style={selectedTagsCheckBoxes}
            defaultValue={previouslyMarkedTags}
            onChange={this.selectTags}
          >
            {allTags.length ? (
              allTags.map((tag) => (
                <Checkbox value={`${tag.id},${tag.tagName}`}>
                  {tag.tagName}
                </Checkbox>
              ))
            ) : (
              <p>No tags available for this project!</p>
            )}
          </Checkbox.Group>
        )}
      </Modal>
    );
  }
}

export default SelectIssueTagsModal;
