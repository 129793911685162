import ApiBase from './ApiBase';
import { GenericApisReturnPromiseTypes } from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_CAPI_URL } from '../constants/urls';

export default class CoordinateSystemApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = `/v2`;
  }

  public getCoordinateDetails(
    projectId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/projects/${projectId}/coordinates`)
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to get project coordinate systems`,
        });
      });
  }

  public createCoordinateDetails(
    projectId: string,
    req: any
  ): GenericApisReturnPromiseTypes {
    return this.post(`${this._baseUrl}/projects/${projectId}/coordinates`, req)
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to create project coordinate systems`,
        });
      });
  }

  public updateCoordinateDetails(
    projectId: string,
    req: any
  ): GenericApisReturnPromiseTypes {
    return this.patch(`${this._baseUrl}/projects/${projectId}/coordinates`, req)
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to update project coordinate systems`,
        });
      });
  }
}
