import _ from 'lodash';
import { Tag } from '../../api/issue.types';

interface IIssueState {
  creating: boolean;
  error: any;
  data: { id: any }[];
  moreIssues: boolean;
  moreComments: boolean;
  comments: any[];
  loadingComments: boolean;
  loadingIssues: boolean;
  singleIssue?: any | null;
  unresolvedIssuesExist?: boolean;
  resolvedIssuesExist?: boolean;
  loadingIssuesStatus: boolean;
  creatingTag: boolean;
  loadingTags: boolean;
  deletingTags: boolean;
  tags: Tag[];
  tagCreatedSuccessMessage: string;
  tagCreatedErrorMessage: string;
  tagsDeletedSucessMessage: string;
  tagsDeletedErrorMessage: string;
  loadingSingleIssue: boolean;
}

const initialState: IIssueState = {
  creating: false,
  data: [],
  comments: [],
  tags: [],
  moreIssues: false,
  moreComments: false,
  error: undefined,
  loadingTags: false,
  creatingTag: false,
  deletingTags: false,
  tagCreatedSuccessMessage: '',
  tagCreatedErrorMessage: '',
  tagsDeletedSucessMessage: '',
  tagsDeletedErrorMessage: '',
  loadingComments: false,
  loadingIssues: false,
  singleIssue: undefined,
  unresolvedIssuesExist: undefined,
  resolvedIssuesExist: undefined,
  loadingIssuesStatus: false,
  loadingSingleIssue: false,
};

export default function issue(state = initialState, action: any): IIssueState {
  const { type, payload } = action;

  switch (type) {
    case 'SINGLE_ISSUE_FETCH_SUCCESS':
      return {
        ...state,
        singleIssue: payload,
        loadingIssues: false,
        loadingSingleIssue: false,
      };

    case 'SINGLE_ISSUE_FETCH_STARTED': {
      return {
        ...state,
        singleIssue: null,
        loadingSingleIssue: true,
      };
    }

    case 'ISSUE_FETCH_STARTED':
      return {
        ...state,
        data: [],
        loadingIssues: true,
      };

    case 'ISSUE_STATUS_FETCH_STARTED':
      return {
        ...state,
        unresolvedIssuesExist: undefined,
        resolvedIssuesExist: undefined,
        loadingIssuesStatus: true,
      };

    case 'ISSUE_FETCH_SUCCESS':
      return {
        ...state,
        data: payload.items,
        moreIssues: payload.hasMore,
        loadingIssues: false,
      };

    case 'ISSUE_STATUS_FETCH_SUCCESS':
      return {
        ...state,
        unresolvedIssuesExist: payload.unresolvedIssuesExist,
        resolvedIssuesExist: payload.resolvedIssuesExist,
        loadingIssuesStatus: false,
      };

    case 'ISSUE_RESOLVE_SUCCESS': {
      const { data } = state;
      const newData = data.filter((d) => d.id !== payload.id);

      return {
        ...state,
        data: [...newData, payload],
      };
    }

    case 'ISSUE_CREATE_SUCCESS':
      return {
        ...state,
        creating: false,
      };

    case 'ISSUE_CREATE_STARTED':
      return {
        ...state,
        creating: true,
      };

    case 'ISSUE_CREATE_FAILED':
      return {
        ...state,
        creating: false,
      };

    case 'COMMENT_FETCH_STARTED':
      return {
        ...state,
        loadingComments: true,
      };

    case 'COMMENT_FETCH_SUCCESS':
      return {
        ...state,
        comments: payload.items,
        moreComments: payload.hasMore,
        loadingComments: false,
      };

    case 'COMMENT_CREATE_SUCCESS':
      return {
        ...state,
        comments: [payload, ...state.comments],
      };

    case 'ISSUE_STATE_CLEARED':
      return {
        ...state,
        data: [],
        comments: [],
        singleIssue: undefined,
        unresolvedIssuesExist: undefined,
        resolvedIssuesExist: undefined,
      };

    case 'TAG_FETCH_STARTED':
      return {
        ...state,
        tags: [],
        loadingTags: true,
      };

    case 'TAGS_FETCHED_SUCCESS':
      return {
        ...state,
        tags: payload,
        loadingTags: false,
      };

    case 'TAGS_FETCH_FAILED':
      return {
        ...state,
        loadingTags: false,
      };

    case 'TAG_CREATION_STARTED':
      return {
        ...state,
        creatingTag: true,
      };

    case 'TAG_CREATED_SUCCESS':
      return {
        ...state,
        creatingTag: false,
        tagCreatedSuccessMessage: 'New Tag Created!',
        tags: [...state.tags, payload],
      };

    case 'TAG_CREATED_FAILED':
      return {
        ...state,
        tagCreatedErrorMessage: 'Sorry, tag could not be created!',
        creatingTag: false,
      };

    case 'TAG_DELETE_STARTED':
      return {
        ...state,
        deletingTags: true,
      };

    case 'TAG_DELETE_SUCCESS': {
      const { tags } = state;

      const remainingTagIds = _.difference(
        tags.map((tag) => tag.id),
        payload
      );

      const remainingTags = tags.filter((tag) =>
        remainingTagIds.includes(tag.id)
      );

      return {
        ...state,
        tags: remainingTags,
        tagsDeletedSucessMessage: 'Selected tags removed from project',
        deletingTags: false,
      };
    }

    case 'TAG_DELETE_FAILED':
      return {
        ...state,
        tagsDeletedErrorMessage: 'One or more tags are already in use',
        deletingTags: false,
      };

    case 'TAGS_STATUS_MESSAGES_RESET':
      return {
        ...state,
        tagCreatedSuccessMessage: '',
        tagCreatedErrorMessage: '',
        tagsDeletedSucessMessage: '',
        tagsDeletedErrorMessage: '',
      };

    default:
      return state;
  }
}
