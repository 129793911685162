import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { checkLogin, unAuthorized } from '../../redux/actions/auth';
import { actionHideSnackbar } from '../../redux/actions/snackbar';
import { showCoachMarks, runCoachMarks } from '../../redux/actions/coachmarks';
import App from './index';

const mapStateToProps = (state: any, { location }: any): any => {
  return {
    authenticated: state.auth.authenticated,
    authorized: state.auth.authorized,
    user: state.auth.user,
    share: state.share.data,
    loading: state.auth.loading,
    steps: state.coachmarks.steps,
    location,
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      checkLogin,
      unAuthorized,
      hideSnackbar: actionHideSnackbar,
      showCoachMarks,
      runCoachMarks,
    },
    dispatch
  );
};

const AppContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(App)
);

export default AppContainer;
