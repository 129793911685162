import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

export interface BoundaryMissingProps {
  projectId: string;
  aoiId: string;
}

export const BoundaryMissing: React.FC<BoundaryMissingProps> = ({
  projectId,
  aoiId,
}) => {
  return (
    <div className={styles.title}>
      <h3>
        This functionality is disabled as the AOI doesn&rsquo;t have a boundary.
        To enable this functionality, use the&nbsp;
        <Link to={`/project/${projectId}/aoi/${aoiId}/edit`}>
          Edit AOI screen
        </Link>{' '}
        to add a boundary.
      </h3>
    </div>
  );
};
