import React from 'react';
import { GetRailProps } from 'react-compound-slider';
import styles from './styles.module.scss';

interface SliderRailProps {
  getRailProps: GetRailProps;
}

const SliderRail: React.FC<SliderRailProps> = ({ getRailProps }) => {
  return <div className={styles.rail} {...getRailProps()} />;
};

export default SliderRail;
