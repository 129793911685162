import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { EditProjectV2 } from './index';
import { EditProjectV2Types } from './index.types';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { updateProjectManually } from '../../redux/actions/projects';

const mapStateToProps = (state: any): EditProjectV2Types.ReduxState => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): EditProjectV2Types.DispatchProps => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
      updateProjectManually,
    },
    dispatch
  );
};

export const CreateProjectV2Container = connect<
  EditProjectV2Types.ReduxState,
  EditProjectV2Types.DispatchProps,
  EditProjectV2Types.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(EditProjectV2);

export default CreateProjectV2Container;
