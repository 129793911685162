import classnames from 'classnames';
import * as React from 'react';
import _ from 'lodash';
import { convertISODateToDayMonthYearFormat } from '../../../../utils/date';
import {
  undefinedOrNull,
  firstLetterUpperCase,
  isDefined,
} from '../../../../utils/functs';
import Dropdown from '../../../DropDown';
import style from './index.module.scss';
import { View } from '../../../../api/views.types';

interface IOption {
  label: string;
  value: string;
  viewId: string;
}

interface IProps {
  className?: string;
  onChange: (viewId: string) => void;
  viewsList?: View[];
  view: View;
}

class SplitViewSelector extends React.Component<IProps> {
  private handleSelection1 = (event: any): void => {
    const { onChange, viewsList, view: selectedView } = this.props;

    const subTypes = this.getSubTypes();

    // functionality -> when we change between subtypes, we should retain last selected date from previous subtype
    // if for current subtype last selected date doesnt exist in list of dates then show the first date from that default list generated by getViews method

    // how to perform above action? mentioned in below steps =>
    // 1. find all the views associated with changed subtype
    // 2. then filter views with same date as current view
    // 3. if created array is not empty
    // 4. then set first element's viewId else use selectedOption by default

    const [viewWithSameSubTypeAndDate] = !undefinedOrNull(viewsList)
      ? viewsList
          .filter((view) => view.subType === event.value)
          .filter(
            (viewSameSubtype) =>
              convertISODateToDayMonthYearFormat(viewSameSubtype.date) ===
              convertISODateToDayMonthYearFormat(selectedView.date)
          )
      : [];
    // functionality mentioned above achived by above block of code

    const selectedOption = subTypes.find(
      (option) => option.value === event.value
    );

    if (selectedOption && onChange) {
      onChange(viewWithSameSubTypeAndDate?.id || selectedOption.viewId);
    }
  };

  private handleSelection2 = (option: any): void => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(option.value);
    }
  };

  // this method returns option {label,value} object array
  // returns list of objects which have unique label property
  private getViews = (subType?: string): any[] => {
    const { viewsList, view: selectedView } = this.props;

    const selectedViewSubType = subType || selectedView.subType;

    if (!undefinedOrNull(viewsList)) {
      const views = viewsList
        .filter((value) => value.subType === selectedViewSubType)
        .map((value) => ({
          value: value.id,
          label: convertISODateToDayMonthYearFormat(value.date),
        }));

      return _.uniqBy(views, 'label');
    }

    return [];
  };

  // this method returns selected view object wrapped in Option object {label,value} if it's absent from list of views generated by getViews
  private getAbsentView = () => {
    const { view: selectedView } = this.props;
    const views = this.getViews();

    if (undefinedOrNull(views.find((view) => view.value === selectedView.id))) {
      return {
        label: convertISODateToDayMonthYearFormat(selectedView.date),
        value: selectedView.id,
      };
    }

    return null;
  };

  private getSubTypes = (): IOption[] => {
    const { viewsList, view: viewFromProp } = this.props;

    if (!undefinedOrNull(viewsList)) {
      const blackListedSubTypes = ['design', 'dtm', 'elevation_difference'];
      const mapViews = viewsList.filter((view) => view.type === 'map');

      const allSubTypes = mapViews
        .map((mapView) => mapView.subType)
        .filter(isDefined) // removes undefined values from array
        .filter((subType) => !blackListedSubTypes.includes(subType));

      const uniqueSubtypes = allSubTypes
        .filter((value, index) => allSubTypes.indexOf(value) === index)

        .map((subType) => {
          let label = '';

          if (subType.includes('_')) {
            label = subType.split('_').map(firstLetterUpperCase).join(' ');
          } else {
            label = firstLetterUpperCase(subType);
          }

          return {
            name: subType,
            label: label === 'Survey' ? 'Analytics' : label,
          };
        });

      return uniqueSubtypes.map((uniqueSubType) => {
        const selectedView =
          mapViews.find((view) => view.subType === uniqueSubType.name) ||
          viewFromProp;

        return {
          value: uniqueSubType.name,
          label: uniqueSubType.label,
          viewId: selectedView.id,
        };
      });
    }

    return [];
  };

  public render(): React.ReactNode {
    const { className, view: selectedView } = this.props;

    let views = this.getViews(); // returns viewsIds mapped to their dates

    const subTypes = this.getSubTypes();

    const absentView = this.getAbsentView();

    if (!undefinedOrNull(absentView)) {
      views = _.uniqBy([absentView, ...views], 'label');
    }

    return (
      <div>
        {selectedView.type === 'map' ? (
          <div
            className={`${classnames(
              style.container,
              className
            )} no-include-screenshot`}
          >
            <Dropdown
              options={subTypes}
              value={selectedView.subType}
              onChange={this.handleSelection1}
            />

            {views.length > 0 && (
              <Dropdown
                options={views}
                value={selectedView.id}
                onChange={this.handleSelection2}
              />
            )}
          </div>
        ) : (
          <div
            className={`${classnames(
              style.container,
              className
            )} no-include-screenshot`}
          >
            {views.length > 0 && (
              <Dropdown
                options={views}
                value={selectedView.id}
                onChange={this.handleSelection2}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default SplitViewSelector;
