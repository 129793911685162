/* eslint-disable no-console */
import { best_fit_volume_from_wkt } from '@aspecscire/measurements-wasm';
import JsPyramid from './pyramid';
import { BASE_CAPI_URL } from '../../constants/urls';

let pyramid: JsPyramid | null = null;

async function getPyramid(viewId: string): Promise<JsPyramid> {
  if (pyramid) return pyramid;
  const pyrJson = await (
    await fetch(`${BASE_CAPI_URL}/v1/measurements/tiles/${viewId}/metadata`, {
      credentials: 'include',
    })
  ).json();
  const pyr = new JsPyramid(
    `${BASE_CAPI_URL}/v1/measurements/tiles/${viewId}/data`,
    pyrJson
  );

  pyramid = pyr;

  return pyramid;
}

// async function convertCoordinates(
//   viewId: string,
//   points: { latitude: number; longitude: number }[]
// ): Promise<number[][]> {
//   return Promise.resolve([]);
// }

async function bestFitVolume(
  wkt: string,
  elevations: number[]
): Promise<number> {
  return best_fit_volume_from_wkt(wkt, Float64Array.from(elevations));
}

async function calcVolume(viewId: string, pointsWkt: string): Promise<number> {
  const jsPyramid = await getPyramid(viewId);

  const pyr = jsPyramid.wasm.clone();
  const v = await pyr.raw_volume_from_wkt(pointsWkt, jsPyramid.reader);

  // pyramid.totalRead = 0;
  // console.info('calcing volume');
  // console.time('volumeCalc');
  // const volume = await check_pyramid(
  //   pyramid.wasm.clone(),
  //   pyramid.reader,
  //   scale
  // );
  // console.info(`Volume = ${volume}`);
  // console.info(`Processed ${pyramid.totalRead / 1024 / 1024} mega pixels.`);
  // console.timeEnd('volumeCalc');

  return v;
}

export { bestFitVolume, getPyramid, calcVolume };
