import ApiBase from './ApiBase';

export default class ViewAPIs extends ApiBase {
  public getViews(projectId: string, aoiId: string): Promise<any> {
    return this.get(`/v2/projects/${projectId}/aois/${aoiId}/views`);
  }

  public getDownloads(viewId: string): Promise<any> {
    return this.get(`/v1/downloads/view/${viewId}`);
  }

  public getReports(viewId: string): Promise<any> {
    return this.get(`/v1/reports/view/${viewId}`);
  }

  public getView(
    projectId: string,
    aoiId: string,
    viewId: string
  ): Promise<any> {
    return this.get(`/v2/projects/${projectId}/aois/${aoiId}/views/${viewId}`);
  }

  public createView(
    projectId: string,
    aoiId: string,
    request: any
  ): Promise<any> {
    return this.post(`/v2/projects/${projectId}/aois/${aoiId}/views`, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
