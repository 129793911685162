export interface Video {
  title: string;
  provider: string;
  videoId: string;
}

export const getVideoorTitleForCurrentPathType = (
  pathType: string,
  type: 'videosList' | 'title'
): Video[] | string => {
  switch (pathType) {
    case 'home':
      return type === 'videosList'
        ? [
            {
              title: 'Vimana - Quick Start',
              provider: 'youtube',
              videoId: 'X3cZpHwj-nI',
            },
            {
              title: 'Create Project in Vimana',
              provider: 'youtube',
              videoId: 'tXRzmHLZPIk',
            },
          ]
        : 'QUICK START VIDEOS';

    case 'manageProject':
      return type === 'videosList'
        ? [
            {
              title: 'Create Mission',
              provider: 'youtube',
              videoId: 'Xat-05C8Ks0',
            },
            {
              title: 'Create Nadir Flight Plan',
              provider: 'youtube',
              videoId: 'kkcDmgjn7Rs',
            },
            {
              title: 'Create 360 Panorama Flight Plan',
              provider: 'youtube',
              videoId: 'Vcv8wq4jB1Q',
            },
            {
              title: 'Create Perspective Flight Plan',
              provider: 'youtube',
              videoId: '_B8XVrNWYwA',
            },
            {
              title: 'Create GCP Plan',
              provider: 'youtube',
              videoId: 'eLdznths9N8',
            },
            {
              title: 'Upload & Manage images',
              provider: 'youtube',
              videoId: 'Cn18iZZaB3Y',
            },
            {
              title: 'Create Panorama Walkthrough',
              provider: 'youtube',
              videoId: 'w1EF13m7iRw',
            },
          ]
        : 'MANAGE PROJECT VIDEOS';

    default:
      return type === 'videosList' ? [] : '';
  }
};
