import { getViewCertifiedOnlyAoiList } from '../../utils/localStorage';
import { GenericObjectType } from '../../shapes/app';
import { inArray, isCertifiedView } from '../../utils/functs';
import { sortByDate, sortByRelativeValue } from '../../utils/helpers';

const initialState = {
  data: [],
  loading: false,
  loaded: false,
};

const VIEWS_FETCH_FULFILLED = 'VIEWS_FETCH_FULFILLED';
const VIEWS_FETCH_PENDING = 'VIEWS_FETCH_PENDING';
const SINGLE_VIEW_FETCH_PENDING = 'SINGLE_VIEW_FETCH_PENDING';
const SINGLE_VIEW_FETCH_FULFILLED = 'SINGLE_VIEW_FETCH_FULFILLED';

export const getDefaultView = (payload: any) => {
  const certifiedOnlyAoiList = getViewCertifiedOnlyAoiList();
  let hasCertifiedViews = true;
  let displayableViews = payload ?? [];

  if (certifiedOnlyAoiList.length > 0) {
    displayableViews = displayableViews.filter((view: GenericObjectType) => {
      return (
        (view.certifiedForDisplay &&
          inArray(certifiedOnlyAoiList, view.aoiId)) ||
        !inArray(certifiedOnlyAoiList, view.aoiId)
      );
    });

    if (displayableViews.length < 1) {
      hasCertifiedViews = false;
    }
  }

  const sortedViews = sortByDate(displayableViews, 'date', true);

  const orderOfTypes = [
    'map',
    'inspection',
    'perspective',
    'exterior_360',
    'interior_360',
    'three_d',
    'video',
    'site_navigation',
  ];

  const orderOfMapSubTypes = ['aerial', 'elevation', 'survey'];

  for (let i = 0; i < sortedViews.length; i += 1) {
    const view = sortedViews[i];

    let typePos = orderOfTypes.indexOf(view.type);

    if (typePos < 0) {
      typePos = orderOfTypes.length;
    }

    sortedViews[i].typeOrder = typePos;
  }

  const sortedViewsByTypeOrder = sortByRelativeValue(
    sortedViews,
    'date',
    'typeOrder'
  );

  let filteredMapViewsList = sortedViewsByTypeOrder;

  if (sortedViewsByTypeOrder[0] && sortedViewsByTypeOrder[0]?.type === 'map') {
    filteredMapViewsList = sortedViewsByTypeOrder.filter(
      (a) => a.type === 'map' && inArray(orderOfMapSubTypes, a.subType)
    );

    for (let i = 0; i < filteredMapViewsList.length; i += 1) {
      const view = filteredMapViewsList[i];

      let typePos = orderOfMapSubTypes.indexOf(view.subType);

      if (typePos < 0) {
        typePos = orderOfMapSubTypes.length;
      }

      filteredMapViewsList[i].orderOfMapSubTypes = typePos;
    }
  }

  const sortedFilteredMapViewsList = sortByRelativeValue(
    filteredMapViewsList,
    'date',
    'orderOfMapSubTypes'
  );

  const filteredNonMapViewsList = sortedViewsByTypeOrder.filter(
    (v) => v.type !== 'map'
  );

  const satelliteViews = sortedViewsByTypeOrder.filter(
    (v) => v.type === 'map' && v.subType === 'satellite'
  );

  const finalSortedViewsList = [
    ...sortedFilteredMapViewsList,
    ...filteredNonMapViewsList,
    ...satelliteViews,
  ];

  for (let i = 0; i < finalSortedViewsList.length; i += 1) {
    const view = finalSortedViewsList[i];

    if (hasCertifiedViews) {
      if (view.type === 'map') {
        const displayableViewType = isCertifiedView(view);

        if (displayableViewType) {
          return view.id;
        }
      }

      return view.id;
    }
  }

  return finalSortedViewsList[0] ? finalSortedViewsList[0].id : null;
};

export default function VIEWS(state = initialState, action: any): any {
  const { type, payload } = action;

  switch (type) {
    case 'MANUAL_UPDATE_VIEWS':
    case VIEWS_FETCH_FULFILLED:
      return {
        data: payload.data,
        loaded: true,
        loading: false,
        currentViewId: getDefaultView(payload.data || []),
      };

    case 'RESET_VIEWS_DATA':
      return {
        ...state,
        ...initialState,
      };

    case 'DOWNLOADS_FETCH_PENDING':
      return {
        ...state,
        downloads: [],
      };

    case 'DOWNLOADS_FETCH_FULFILLED':
      return {
        ...state,
        downloads: payload.data,
      };

    case 'REPORTS_FETCH_FULFILLED':
      return {
        ...state,
        reports: payload.data,
      };

    case 'REPORTS_FETCH_PENDING':
      return {
        ...state,
        reports: [],
      };

    case VIEWS_FETCH_PENDING:
      return {
        data: [],
        loading: true,
        currentViewId: null,
      };

    case '@api/PROJECTS_FETCH/STARTED':
    case 'RESET_CURRENT_VIEW':
      return {
        ...state,
        currentViewId: null,
        reports: [],
        downloads: [],
      };

    case SINGLE_VIEW_FETCH_PENDING:
      return {
        ...state,
        singleView: null,
      };

    case SINGLE_VIEW_FETCH_FULFILLED:
      return {
        ...state,
        singleView: payload.data,
      };

    case 'MANUAL_ADD_VIEW':
    case 'VIEW_FETCH_BY_ID_FULFILLED': {
      const view = payload.data;
      const filteredViews = state.data.filter((val: any) => val.id !== view.id);

      return {
        ...state,
        data: [...filteredViews, view],
      };
    }

    case 'SET_LAST_VIEW':
      return {
        ...state,
        lastViewId: payload[0],
        lastSplitViewId: payload[1],
      };

    case 'UPDATE_VIEW_LAYERS': {
      return {
        ...state,
        legends: payload,
      };
    }

    case 'RESET_VIEW_LAYERS': {
      return {
        ...state,
        legends: undefined,
      };
    }

    default:
      return state;
  }
}
