import React from 'react';
import { GetTrackProps, SliderItem } from 'react-compound-slider';
import { ColorList } from '../../constants/colors';
import styles from './styles.module.scss';

// *******************************************************
// TRACK COMPONENT
// *******************************************************
interface TrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  disabled?: boolean;
  colors: ColorList;
  clampMin: boolean;
  clampMax: boolean;
}

function renderColors(colors: ColorList): React.ReactNode {
  return colors.map(([r, g, b], idx) => (
    <div
      // eslint-disable-next-line react/no-array-index-key
      key={idx}
      className={styles.trackItem}
      style={{ backgroundColor: `rgb(${r}, ${g}, ${b})` }}
    />
  ));
}

function renderFirstColor(
  target: SliderItem,
  colors: ColorList
): React.ReactNode {
  const [r, g, b] = colors[0];

  return (
    <div
      className={styles.trackRemaining}
      style={{
        bottom: `0px`,
        height: `${100 - target.percent}%`,
        backgroundColor: `rgb(${r}, ${g}, ${b})`,
      }}
    />
  );
}

function renderLastColor(
  target: SliderItem,
  colors: ColorList
): React.ReactNode {
  const [r, g, b] = colors[colors.length - 1];

  return (
    <div
      className={styles.trackRemaining}
      style={{
        top: `0px`,
        height: `${target.percent}%`,
        backgroundColor: `rgb(${r}, ${g}, ${b})`,
      }}
    />
  );
}

export const Track: React.FC<TrackProps> = ({
  source,
  target,
  colors,
  getTrackProps,
  clampMin,
  clampMax,
}) => {
  return (
    <>
      {clampMin && renderFirstColor(target, colors)}
      <div
        className={styles.track}
        style={{
          top: `${source.percent}%`,
          height: `${target.percent - source.percent}%`,
        }}
        {...getTrackProps()}
      >
        {renderColors(colors)}
      </div>
      {clampMax && renderLastColor(source, colors)}
    </>
  );
};
