import * as React from 'react';
import { Unit2 } from '../InfoHelpers/Unit';
import { Spinner } from '../../../../Spinner/Spinner';
import { convertLength, fahrenheitDiff } from '../../../../../utils/helpers';
import MeasurementAPIs from '../../../../../api/Measurement';

import { ElevationInfoPropsType, ElevationInfoStateType } from './index.types';
import style from './index.module.scss';

const measurementAPIs = new MeasurementAPIs();

export default class ElevationInfo extends React.Component<
  ElevationInfoPropsType,
  ElevationInfoStateType
> {
  constructor(props: ElevationInfoPropsType) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  public componentDidMount(): void {
    this.fetchElevation(this.props);
  }

  public UNSAFE_componentWillReceiveProps(newProps: ElevationInfoPropsType) {
    const { shape: newShape, viewId: newViewId } = newProps;
    const { shape: oldShape, viewId: oldViewId } = this.props;

    if (newShape !== oldShape || newViewId !== oldViewId) {
      this.fetchElevation(newProps);
    }
  }

  private getPointsFromShape = (shape: any): number[][] => {
    if (shape && shape.features && shape.features.length > 0) {
      return shape.features
        .map((f: any) => {
          const coords = f?.geometry?.coordinates || [];

          return [coords[0], coords[1]];
        })
        .filter((f: number[][] | null) => f);
    }

    return [];
  };

  private fetchElevation = ({ shape, viewId }: ElevationInfoPropsType) => {
    const points = this.getPointsFromShape(shape);
    // convert points

    this.setState(
      {
        loading: true,
      },
      () => {
        measurementAPIs
          .fetchElevation(viewId, points)
          .then((res) => {
            const elevation = res.data.elevation.metres;

            this.setState(
              {
                elevation,
                loading: false,
                error: undefined,
              },
              () => {
                const { onElevationChange } = this.props;

                if (onElevationChange) {
                  onElevationChange(elevation);
                }
              }
            );
          })
          .catch((res) => {
            console.error('Encountered Error while fetching elevation', res);

            this.setState({
              elevation: undefined,
              loading: false,
              error: 'Something went wrong.',
            });
          });
      }
    );
  };

  private renderPoints(): any[] {
    const { elevation } = this.state;
    const { view } = this.props;
    const elevationArray = elevation || [];

    return elevationArray.map((elevationItem: any, index: number) => {
      const elevationValue =
        view && view.subType !== 'thermal_mosaic'
          ? {
              meter: convertLength(elevationItem, 'meter'),
              feet: convertLength(elevationItem, 'feet'),
            }
          : {
              celsius: convertLength(elevationItem, 'celsius'),
              fahrenheit: convertLength(elevationItem, 'fahrenheit'),
            };

      return this.renderElevation(`P${index + 1}`, elevationValue, index);
    });
  }

  private renderElevation(
    label: string,
    elevation: any,
    index: number | string
  ): any {
    const { view } = this.props;

    return (
      <React.Fragment key={index}>
        {view && view.subType !== 'thermal_mosaic' ? (
          <Unit2
            label={`${label}`}
            value1={elevation.meter}
            value2={elevation.feet}
            className={style.elevation}
          />
        ) : (
          <Unit2
            label={`${label}`}
            value1={elevation.celsius}
            value2={elevation.fahrenheit}
            className={style.elevation}
          />
        )}
      </React.Fragment>
    );
  }

  public render(): React.ReactNode {
    const { elevation, loading, error } = this.state;
    const elevationArray = elevation || [];
    const { view } = this.props;

    if (loading) {
      return <Spinner />;
    }

    if (error) {
      return (
        <div className={style.container}>
          {error.message || 'Something went wrong'}
        </div>
      );
    }

    if (!elevation) {
      return null;
    }

    const elevationSize = elevationArray.length;
    const elevationDiff: any =
      view && view.subType !== 'thermal_mosaic'
        ? elevationSize >= 2 && {
            meter: convertLength(
              elevationArray[elevationSize - 2] - elevation[elevationSize - 1],
              'meter'
            ),
            feet: convertLength(
              elevationArray[elevationSize - 2] - elevation[elevationSize - 1],
              'feet'
            ),
            label: `P${elevationSize - 1}-P${elevationSize}`,
          }
        : elevationSize >= 2 && {
            celsius: convertLength(
              elevationArray[elevationSize - 2] - elevation[elevationSize - 1],
              'celsius'
            ),
            fahrenheit: fahrenheitDiff(
              elevationArray[elevationSize - 2] - elevation[elevationSize - 1],
              'fahrenheit'
            ),
            label: `P${elevationSize - 1}-P${elevationSize}`,
          };

    return (
      <div className={style.scrollable}>
        <div className={style.label}>
          {view.subType !== 'thermal_mosaic' ? (
            <label>Elevation</label>
          ) : (
            <label>Temperature</label>
          )}
        </div>
        {this.renderPoints()}
        {elevationDiff &&
          this.renderElevation(elevationDiff.label, elevationDiff, 'diff')}
      </div>
    );
  }
}
