import React, { useEffect, useMemo } from 'react';
import GeometryType from 'ol/geom/GeometryType';
import { StyleLike } from 'ol/style/Style';
import { Feature, MapBrowserEvent } from 'ol';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import { Coordinate } from 'ol/coordinate';
import { Polygon } from 'ol/geom';
import {
  useDrawManager,
  useHelpToolTip,
  useMapContext,
  useVectorContext,
  useVectorManager,
} from './hooks';
import {
  convertToGeoJsonFeature,
  getDefaultDrawStyle,
  getHelpMessage,
  WEB_MERCATOR,
  WGS84,
} from './utils';

export interface EditablePolygonProps {
  coordinates?: Coordinate[][];
  style?: StyleLike;
  onGeoJsonFeatureCreate?: (feature: string) => Promise<void>;
  onGeoJsonFeatureEdit?: (feature: string) => Promise<void>;
}

export const DrawAndEditPolygon: React.FC<EditablePolygonProps> = ({
  coordinates,
  style,
  onGeoJsonFeatureCreate,
  onGeoJsonFeatureEdit,
}) => {
  const { map } = useMapContext();
  const {
    setStyle,
    addDraw,
    addModify,
    removeModify,
    removeDraw,
    interactionState,
    draw,
    modify,
  } = useDrawManager(GeometryType.POLYGON);
  const { overlay, setOverlayPosition, setElementDisplay, setHelpText } =
    useHelpToolTip();
  const { source } = useVectorContext();
  const { addFeature, removeFeature } = useVectorManager();

  const { feature } = useMemo(() => {
    if (!coordinates || coordinates.length === 0 || coordinates[0].length === 0)
      return { geometry: undefined, feature: undefined };

    const geometry = new Polygon(coordinates);

    // maps are in web mercator by default.
    geometry.transform(WGS84, WEB_MERCATOR);
    const feature = new Feature(geometry);

    return { geometry, feature };
  }, [coordinates]);

  useEffect(() => {
    if (feature) {
      addFeature(feature);
      removeDraw();
    }

    return () => {
      if (feature) {
        removeFeature(feature);
        addDraw();
      }
    };
  }, []);

  useEffect(() => {
    const onClear = () => {
      addDraw();
    };

    source.on('clear', onClear);

    return () => {
      source.un('clear', onClear);
    };
  }, [source, draw]);

  useEffect(() => {
    if (!feature) addDraw();
    addModify();

    return () => {
      removeDraw();
      removeModify();
    };
  }, [map]);

  useEffect(() => {
    map.addOverlay(overlay);

    return () => {
      map.removeOverlay(overlay);
    };
  }, [map, overlay]);

  const onFeatureCreate = useMemo(
    () => (e: DrawEvent) => {
      removeDraw();
      onGeoJsonFeatureCreate?.(convertToGeoJsonFeature(e.feature));
    },
    []
  );

  const onFeatureEdit = useMemo(
    () => (e: ModifyEvent) => {
      const feature = e.features.getArray()[0];

      if (feature) onGeoJsonFeatureEdit?.(convertToGeoJsonFeature(feature));
    },
    []
  );

  useEffect(() => {
    draw.on('drawend', onFeatureCreate);

    return () => {
      draw.un('drawend', onFeatureCreate);
    };
  }, [draw]);

  useEffect(() => {
    modify.on('modifyend', onFeatureEdit);

    return () => {
      modify.un('modifyend', onFeatureEdit);
    };
  }, [modify]);

  const onPointerMove = useMemo(
    () => (e: MapBrowserEvent) => {
      setOverlayPosition(e.coordinate);
      const message = getHelpMessage(interactionState.current);

      if (message) {
        setElementDisplay(true);
        setHelpText(message);
      } else {
        setElementDisplay(false);
      }
    },
    []
  );

  useEffect(() => {
    map.on('pointermove', onPointerMove);

    return () => {
      map.un('pointermove', onPointerMove);
    };
  }, [map, onPointerMove]);

  useEffect(() => {
    setStyle(style ?? getDefaultDrawStyle('Polygon'));
  }, [style, setStyle]);

  return <div data-testid="ol-editable-polygon" />;
};
