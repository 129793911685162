import * as React from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import { DocumentationLinkProps } from './index.types';
import { undefinedOrNull } from '../../utils/functs';

class DocumentationLink extends React.Component<DocumentationLinkProps> {
  public static defaultProps: Partial<DocumentationLinkProps> = {
    helpText: 'Documentation',
    toolTipPosition: 'bottom',
  };

  public render(): React.ReactNode {
    const { helpText, toolTipPosition, to, href, className } = this.props;

    return (
      <span className={`${styles.container} ${className}`}>
        <Tooltip placement={toolTipPosition as any} title={helpText}>
          {!undefinedOrNull(to) ? (
            <Link to={to}>
              <button className={styles.toolTipWrapper}>
                <i className="fa fa-book" aria-hidden="true" />
              </button>
            </Link>
          ) : !undefinedOrNull(href) ? (
            <a href={href} rel="noreferrer noopener" target="_blank">
              <button className={styles.toolTipWrapper}>
                <i className="fa fa-book" aria-hidden="true" />
              </button>
            </a>
          ) : null}
        </Tooltip>
      </span>
    );
  }
}

export default DocumentationLink;
