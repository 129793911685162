import * as React from 'react';
import { Input, Modal } from 'antd';
import classnames from 'classnames';

import { Button } from '../../../Button';
import {
  Organisation,
  OrganisationUser,
} from '../../../../api/organisations.types';
import style from './index.module.scss';

interface IProps {
  organisation: Organisation;
  users: OrganisationUser[];
  addUser: (emailId: string) => void;
  removeUser: (userId: string) => void;
}

interface IState {
  confirmAddPopup: boolean;
  confirmDeletePopup: boolean;
  emailIdToAdd?: string;
  userIdToDelete?: string;
}

export default class OrganisationUserListing extends React.PureComponent<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      confirmAddPopup: false,
      confirmDeletePopup: false,
    };
  }

  private isValidEmail = (): boolean => {
    const { emailIdToAdd } = this.state;

    if (!emailIdToAdd) {
      return false;
    }

    // email regex
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return !!re.test(emailIdToAdd);
  };

  private confirmUserAdd = (ok: boolean) => {
    const { addUser } = this.props;
    const { emailIdToAdd } = this.state;

    if (ok && emailIdToAdd) {
      addUser(emailIdToAdd);
    }

    this.setState({
      confirmAddPopup: false,
    });
  };

  private confirmUserDelete = (ok: boolean) => {
    const { removeUser } = this.props;
    const { userIdToDelete } = this.state;

    if (ok && userIdToDelete) {
      removeUser(userIdToDelete);
    }

    this.setState({
      confirmDeletePopup: false,
      userIdToDelete: undefined,
    });
  };

  public renderModals() {
    const { users, organisation } = this.props;
    const {
      confirmAddPopup,
      confirmDeletePopup,
      emailIdToAdd,
      userIdToDelete,
    } = this.state;
    const userToDelete =
      userIdToDelete && users.find((u) => u.id === userIdToDelete);

    return (
      <>
        {confirmAddPopup && emailIdToAdd ? (
          <Modal
            visible
            title="Add Admin to Organisation"
            onOk={() => this.confirmUserAdd(true)}
            onCancel={() => this.confirmUserAdd(false)}
            zIndex={10010}
            centered={false}
            destroyOnClose
            maskClosable={false}
            className={style.notificationModal}
          >
            <p>
              Are you sure you want to add <b>{emailIdToAdd}</b> to the
              organisation?
            </p>
            {organisation.domains && organisation.domains.length > 0 ? (
              <>
                <p>Supported domains are:</p>
                <ul>
                  {organisation.domains.map((d) => (
                    <li key={d}>
                      <b>{d}</b>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <></>
            )}
          </Modal>
        ) : (
          <></>
        )}
        {confirmDeletePopup && userToDelete ? (
          <Modal
            visible
            title="Remove Admin from Organisation"
            onOk={() => this.confirmUserDelete(true)}
            onCancel={() => this.confirmUserDelete(false)}
            zIndex={10010}
            centered={false}
            destroyOnClose
            maskClosable={false}
            className={style.notificationModal}
          >
            <>
              <p>
                Are you sure you want to remove the following admin from the
                organisation?`
              </p>
              <ul>
                <li>
                  <b>
                    {userToDelete.name} ({userToDelete.email})
                  </b>
                </li>
              </ul>
            </>
          </Modal>
        ) : (
          <></>
        )}
      </>
    );
  }

  public render() {
    const { users, organisation } = this.props;

    return (
      <>
        <div className={style.addUserContainer}>
          <span className={style.addUserEmail}>
            <Input
              placeholder="Enter email-id of the admin"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({ emailIdToAdd: e.target.value || undefined });
              }}
            />
          </span>
          <Button
            text="+ Add Admin"
            className={style.addUserBtn}
            onClick={() => this.setState({ confirmAddPopup: true })}
            disabled={!this.isValidEmail()}
            toolTip={!this.isValidEmail()}
            // TODO: fix help text for the button
            helpText="Email-Id should be valid"
            toolTipPosition="top"
          />
        </div>
        {users && users.length ? (
          <table className={style.usersTable}>
            <thead className={style.headingStyle}>
              <tr>
                <td>Name</td>
                <td className={style.emailCol}>Email</td>
                <td />
              </tr>
            </thead>
            <tbody>
              {users.map((u, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr className={style.singleRowStyle} key={idx}>
                  <td className={classnames(style.capitalize, style.active)}>
                    {u.name}
                  </td>
                  <td className={classnames(style.active, style.emailCol)}>
                    {u.email}
                  </td>
                  <td
                    className={classnames(
                      style.controls,
                      style.capitalize,
                      style.rowEditStyle
                    )}
                  >
                    <Button
                      onClick={() =>
                        this.setState({
                          confirmDeletePopup: true,
                          userIdToDelete: u.id,
                        })
                      }
                      text="Delete"
                      icon="delete"
                      className={style.deleteBtn}
                      disabled={u.id === organisation.createdBy}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <></>
        )}
        {this.renderModals()}
      </>
    );
  }
}
