import autobind from 'autobind-decorator';
import * as React from 'react';
import { Drawer, Modal } from 'antd';
import ElevationScale from '../ElevationScale/ElevationScale';
import DocumentationLink from '../DocumentationLink';
import MapboxView from '../MapboxView';
import MapboxStyle from '../MapboxStyle/MapboxStyle';
import SplitViewSelector from '../SplitViewSelector/container';
import style from './SplitView.module.scss';
import { SnackbarActionsActionShowSnackbarTypes } from '../../shapes/snackbar';
import SurveyTypeSelector from '../SurveyTypeSelector/SurveyTypeSelectorContainer';
import { GenericObjectType } from '../../shapes/app';
import { Button } from '../Button';
import OpenLayersView from '../OpenLayersView';
import ElevationDifferenceAPIs from '../../api/elevationDifference';
import { ElevationDifference } from '../../api/elevationDifference.types';
import { DOCUMENTATION_URL_LIST } from '../../constants/urls';
import { appFormatDate } from '../../utils/date';

const elevationDifferenceAPIs = new ElevationDifferenceAPIs();
const MAPBOX_ZOOM_OFFSET = 1;

interface IProps {
  view1: any;
  view2: any;
  history: any;
  zoom?: number;
  center?: [number, number];
  onMapMove: (zoom: number, center: [number, number]) => void;
  viewsList: GenericObjectType[] | null;
  showSnackbar?: SnackbarActionsActionShowSnackbarTypes;
}

interface IState {
  zoom: number | null;
  center: [number, number] | null;
  map1?: any;
  map2?: any;
  isDrawerVisible: boolean;
  elevationDifferences: ElevationDifference[];
  elevationDifferenceView: any;
  referenceDate: string | null;
  targetDate: string | null;
  isConfirmRequestPopupOpen: boolean;
  loading: boolean;
}

class SplitView extends React.Component<IProps, IState> {
  public constructor(props: any) {
    super(props);
    this.state = {
      zoom: null,
      center: null,
      isDrawerVisible: false,
      elevationDifferences: [],
      elevationDifferenceView: null,
      referenceDate: null,
      targetDate: null,
      isConfirmRequestPopupOpen: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { view1, view2 } = this.props;

    elevationDifferenceAPIs
      .getElevationDifferencesForAOI(view1.projectId, view1.aoiId)
      .then((res) => {
        if (res.error) {
          console.error('could not fetch elevation difference objects.', res);

          return;
        }

        this.setState(
          {
            elevationDifferences: res.data,
          },
          () => {
            const elevationDiffData = this.getElevationDifferenceView(
              view1,
              view2
            );

            this.setState({
              elevationDifferenceView:
                elevationDiffData?.elevationDifferenceView,
              referenceDate: elevationDiffData?.referenceDate,
              targetDate: elevationDiffData?.targetDate,
            });
          }
        );
      });
  }

  UNSAFE_componentWillReceiveProps({
    view1: newView1,
    view2: newView2,
  }: IProps) {
    const { view1: oldView1, view2: oldView2 } = this.props;
    const { elevationDifferences, isDrawerVisible } = this.state;

    if (!isDrawerVisible) {
      if (
        newView1 &&
        newView2 &&
        elevationDifferences.length > 0 &&
        (newView1 !== oldView1 || newView2 !== oldView2)
      ) {
        const elevationDiffData = this.getElevationDifferenceView(
          newView1,
          newView2
        );

        this.setState({
          elevationDifferenceView: elevationDiffData?.elevationDifferenceView,
          referenceDate: elevationDiffData?.referenceDate,
          targetDate: elevationDiffData?.targetDate,
        });
      }
    }
  }

  @autobind
  private handleFirstViewChange(viewId: string): void {
    const { history, view2 } = this.props;

    history.push(`${viewId}?&splitViewId=${view2.id}`);
  }

  @autobind
  private handleSecondViewChange(viewId: string): void {
    const { history, view1 } = this.props;

    history.push(`${view1.id}?&splitViewId=${viewId}`);
  }

  @autobind
  private handleZoom(map: any, event: any): void {
    const { onMapMove } = this.props;
    const center = map.getCenter();

    this.setState(
      {
        zoom: event.target.getZoom(),
        center: [center.lng, center.lat],
      },
      () => {
        onMapMove(event.target.getZoom(), center);
      }
    );
  }

  @autobind
  private handleDrag(map: any, event: any): void {
    const { onMapMove } = this.props;
    const center = event.target.getCenter();
    const zoom = event.target.getZoom();

    this.setState(
      {
        zoom,
        center: [center.lng, center.lat],
      },
      () => {
        onMapMove(zoom, center);
      }
    );
  }

  @autobind
  private handleElevationDifferenceMove(
    longitude: number,
    latitude: number,
    zoom: number
  ) {
    const { onMapMove } = this.props;

    this.setState(
      {
        zoom: zoom - MAPBOX_ZOOM_OFFSET,
        center: [longitude, latitude],
      },
      () => {
        onMapMove(zoom - MAPBOX_ZOOM_OFFSET, [longitude, latitude]);
      }
    );
  }

  private controlDrawerVisibility(showDrawer: boolean): void {
    const { view1, view2 } = this.props;

    if (!showDrawer) {
      const elevationDiffData = this.getElevationDifferenceView(view1, view2);

      this.setState({
        elevationDifferenceView: elevationDiffData?.elevationDifferenceView,
        referenceDate: elevationDiffData?.referenceDate,
        targetDate: elevationDiffData?.targetDate,
      });
    }

    this.setState({
      isDrawerVisible: showDrawer,
    });
  }

  private showElevationDifferenceControls(): boolean {
    const { view1, view2 } = this.props;

    if (
      (view1.subType !== 'aerial' && view1.subType !== 'elevation') ||
      !view1.demId
    ) {
      return false;
    }

    if (
      (view2.subType !== 'aerial' && view2.subType !== 'elevation') ||
      !view2.demId
    ) {
      return false;
    }

    if (view1.demId === view2.demId) {
      return false;
    }

    return true;
  }

  private getElevationDifferenceView(view1: any, view2: any): any | null {
    const { elevationDifferences } = this.state;
    const { viewsList } = this.props;

    const filterElevationDiffs = elevationDifferences.filter((ed) => {
      if (
        view1.demId === ed.targetDemId &&
        view2.demId === ed.sourceDemId &&
        ed.requestStatus === 'published' &&
        ed.viewId
      ) {
        return true;
      }

      return false;
    });

    if (filterElevationDiffs.length === 1) {
      const elevationDiff = filterElevationDiffs[0];

      const elevationDiffView = viewsList?.find((view) => {
        return (
          view.subType === 'elevation_difference' &&
          view.id === elevationDiff.viewId &&
          view.certifiedForDisplay
        );
      });

      if (elevationDiffView) {
        return {
          elevationDifferenceView: elevationDiffView,
          referenceDate: view2.date,
          targetDate: view1.date,
          elevationDifference: elevationDiff,
        };
      }
    }

    return null;
  }

  private elevationDifferenceExists(): boolean {
    const { elevationDifferenceView } = this.state;

    if (elevationDifferenceView) {
      return true;
    }

    return false;
  }

  private handleConfirmRequestPopup = (makeRequest: boolean): void => {
    if (makeRequest) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.requestElevationDifference();
        }
      );
    } else {
      this.setState({
        isConfirmRequestPopupOpen: false,
      });
    }
  };

  private requestElevationDifference(): void {
    // TODO: implement logic to fire request to create the elevation diffence between the specified DEMs
    const { view1, view2, showSnackbar } = this.props;

    // eslint-disable-next-line
    if (view1 && view1.demId && view2 && view2.demId) {
      const elevDiffRequest = {
        sourceDemId: view2.demId,
        sourceDate: view2.date,
        targetDemId: view1.demId,
        targetDate: view1.date,
      };

      elevationDifferenceAPIs
        .requestElevationDifference(
          view1.projectId,
          view1.aoiId,
          elevDiffRequest
        )
        .then((res) => {
          this.setState({
            isConfirmRequestPopupOpen: false,
            loading: false,
          });

          if (res.error) {
            // TODO: replace this with snackBar
            console.error(
              'Encountered error while requesting Elevation Difference Creation',
              elevDiffRequest
            );

            if (showSnackbar) {
              showSnackbar({
                body: 'There was an error while requesting an Elevation Difference. Please try again later, or contact support@aspecscire.com for assistance.',
                type: 'error',
              });
            }

            return;
          }

          this.setState((previousState) => {
            return {
              elevationDifferences: [
                ...previousState.elevationDifferences,
                res.data,
              ],
            };
          });

          if (showSnackbar) {
            showSnackbar({
              type: 'success',
              body: 'A request for the required Elevation Difference has been submitted successfully.',
            });
          }
        });
    }
  }

  public render(): React.ReactNode {
    const {
      view1,
      view2,
      center: centerProps,
      zoom: zoomProps,
      viewsList,
    } = this.props;
    const {
      zoom,
      center,
      isDrawerVisible,
      elevationDifferenceView,
      referenceDate,
      targetDate,
      isConfirmRequestPopupOpen,
      loading,
    } = this.state;

    if (!view1 || !view2) {
      return null;
    }

    const actualCenter = center ||
      centerProps || [view1.centerLongitude, view1.centerLatitude];
    const actualZoom = zoom || zoomProps || view1.zoomDefault;

    return (
      <div className={style.container}>
        <div className={style.map}>
          <MapboxView
            center={actualCenter}
            zoom={[actualZoom]}
            onZoom={this.handleZoom}
            onMove={this.handleDrag}
            accessToken={view1.sourceToken}
          >
            <MapboxStyle
              style={view1.sourceUrl}
              opacity={1}
              accessToken={view1.sourceToken}
            />
          </MapboxView>
          <SplitViewSelector
            onChange={this.handleFirstViewChange}
            viewId={view1.id}
            viewsList={viewsList}
          />
          {view1.subType === 'survey' ? (
            <React.Fragment>
              {view1.demColorMapping && view1.demColorMapping.length > 0 ? (
                <ElevationScale
                  className={style.scale}
                  units={view1.coordinateUnits || 'METRES'}
                  dems={
                    view1.demColorMapping &&
                    view1.demColorMapping.length > 0 &&
                    view1.demColorMapping.slice().reverse()
                  }
                />
              ) : null}
              <SurveyTypeSelector
                className={style.scale}
                viewId={view1.id}
                onSurveyChange={this.handleFirstViewChange}
              />
            </React.Fragment>
          ) : null}
          {view1.subType === 'elevation' ? (
            <ElevationScale
              className={style.scale}
              units={view1.coordinateUnits || 'METRES'}
              dems={
                view1.demColorMapping &&
                view1.demColorMapping.length > 0 &&
                view1.demColorMapping.slice().reverse()
              }
            />
          ) : null}
        </div>
        <div className={style.gap} />
        <div className={style.map}>
          <MapboxView
            className={style.map}
            center={actualCenter}
            showControls
            showZoom
            showRotate
            zoom={[actualZoom]}
            onZoom={this.handleZoom}
            onMove={this.handleDrag}
            accessToken={view2.sourceToken}
          >
            <MapboxStyle
              style={view2.sourceUrl}
              opacity={1}
              accessToken={view2.sourceToken}
            />
          </MapboxView>
          <SplitViewSelector
            onChange={this.handleSecondViewChange}
            viewId={view2.id}
            viewsList={viewsList}
          />
          {view2.subType === 'survey' ? (
            <React.Fragment>
              {view2.demColorMapping && view2.demColorMapping.length > 0 ? (
                <ElevationScale
                  className={style.scale}
                  units={view2.coordinateUnits || 'METRES'}
                  dems={
                    view2.demColorMapping &&
                    view2.demColorMapping.length > 0 &&
                    view2.demColorMapping.slice().reverse()
                  }
                />
              ) : null}
              <SurveyTypeSelector
                className={style.scale}
                viewId={view2.id}
                onSurveyChange={this.handleSecondViewChange}
              />
            </React.Fragment>
          ) : null}
          {view2.subType === 'elevation' ? (
            <ElevationScale
              className={style.scale}
              units={view2.coordinateUnits || 'METRES'}
              dems={
                view2.demColorMapping &&
                view2.demColorMapping.length > 0 &&
                view2.demColorMapping.slice().reverse()
              }
            />
          ) : null}
          {this.showElevationDifferenceControls() ? (
            <div className={style.elevationDifferenceControl}>
              <Button
                type="secondary"
                onClick={() => {
                  if (this.elevationDifferenceExists()) {
                    this.controlDrawerVisibility(true);
                  } else {
                    this.setState({
                      isConfirmRequestPopupOpen: true,
                    });
                  }
                }}
                text={
                  this.elevationDifferenceExists()
                    ? 'View Elevation Difference'
                    : 'Request Elevation Difference'
                }
              />
            </div>
          ) : null}
        </div>
        <Drawer
          className={style.drawer}
          placement="right"
          title={
            <React.Fragment>
              <span className={style.title}>Elevation Difference</span>
              <DocumentationLink
                href={DOCUMENTATION_URL_LIST.viewElevationDifference}
                toolTipPosition="right"
              />
            </React.Fragment>
          }
          maskClosable={false}
          mask={false}
          closable
          onClose={() => {
            this.controlDrawerVisibility(false);
          }}
          visible={isDrawerVisible}
          width="50vw"
        >
          {referenceDate && targetDate ? (
            <div className={style.date}>
              <span className={style.item}>
                Target: {appFormatDate(targetDate)}
              </span>
              <span className={style.item}>
                Reference: {appFormatDate(referenceDate)}
              </span>
            </div>
          ) : null}

          <OpenLayersView
            view={elevationDifferenceView}
            center={
              actualCenter && actualCenter.length > 1
                ? {
                    longitude: actualCenter[0],
                    latitude: actualCenter[1],
                  }
                : undefined
            }
            zoom={actualZoom ? actualZoom + MAPBOX_ZOOM_OFFSET : undefined}
            onMapMove={this.handleElevationDifferenceMove}
          />
        </Drawer>
        {isConfirmRequestPopupOpen ? (
          <Modal
            title="Confirm Request"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onCancel={() => this.handleConfirmRequestPopup(false)}
            className={style.popupContainer}
          >
            <p className={style.headertext}>
              Are you sure you want to request an Elevation Difference? It will
              be calculated as{' '}
              <b>
                Elevation Difference = Target Date Elevation - Reference Date
                Elevation
              </b>
              .
            </p>
            <p>
              <b>Target Date: {appFormatDate(view1.date)}</b>
              <br />
              <b>Reference Date: {appFormatDate(view2.date)}</b>
            </p>

            <div className={style.popupModalDiv}>
              <Button
                onClick={() => this.handleConfirmRequestPopup(false)}
                text="Cancel"
                type="secondary"
                className={style.cancelButton}
              />
              <Button
                onClick={() => this.handleConfirmRequestPopup(true)}
                text="Confirm"
                loading={loading}
              />
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default SplitView;
