export const MAX_IMAGE_SIZE = [8000, 8000];

export const getDrawingUrl = (
  projectId: string,
  aoiId: string,
  actionType: 'new' | 'edit',
  drawingId?: string
) => {
  if (actionType === 'edit') {
    if (!drawingId) {
      console.error('Drawing ID must be specified to get Drawing Edit URL!');

      return '.';
    }

    return `/project/${projectId}/aoi/${aoiId}/sandbox/drawing/edit/${drawingId}`;
  }

  return `/project/${projectId}/aoi/${aoiId}/sandbox/drawing/new`;
};

export const getUploadDrawingUrl = (
  projectId: string,
  aoiId: string,
  drawingId: string
) => {
  return `/project/${projectId}/aoi/${aoiId}/sandbox/drawing/upload/${drawingId}`;
};

export const getImageFileUrl = (file: File): Promise<string> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (!e.target?.result) {
        return reject();
      }

      const sourceUrl = e.target.result as string;

      resolve(sourceUrl);
    };

    reader.readAsDataURL(file);
  });
};

export const getImageDetails = (
  file: File
): Promise<{ width: number; height: number }> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (!e.target?.result) {
        return reject();
      }

      const sourceUrl = e.target.result as string;

      const image = new Image();

      image.onload = () => {
        const { width, height } = image;

        return resolve({ width, height });
      };

      image.src = sourceUrl;
    };

    reader.readAsDataURL(file);
  });
};

export const getScaleFactor = (width: number, height: number): number => {
  if (width <= MAX_IMAGE_SIZE[0] && height <= MAX_IMAGE_SIZE[1]) {
    return 1.0;
  }

  let scaleFactor = 1.0;

  if (width > MAX_IMAGE_SIZE[0]) {
    scaleFactor = width / MAX_IMAGE_SIZE[0];
  }

  if (height / scaleFactor > MAX_IMAGE_SIZE[1]) {
    scaleFactor *= height / (scaleFactor * MAX_IMAGE_SIZE[1]);
  }

  return scaleFactor;
};

export const downscaleImage = (
  imageFile: File,
  scaleFactor?: number
): Promise<{
  dataUrl: string;
  width: number;
  height: number;
  scaleFactor: number;
}> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (!e.target?.result) {
        return reject();
      }

      const sourceUrl = e.target.result as string;

      const image = new Image();

      image.onload = () => {
        const { width, height } = image;
        const _scaleFactor = scaleFactor || getScaleFactor(width, height);
        const canvas = document.createElement('canvas');

        // hiding canvas display
        canvas.style.display = 'none';
        canvas.width = width / _scaleFactor;
        canvas.height = height / _scaleFactor;

        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.drawImage(
            image,
            0,
            0,
            width,
            height,
            0,
            0,
            width / _scaleFactor,
            height / _scaleFactor
          );

          return resolve({
            dataUrl: canvas.toDataURL(),
            width: width / _scaleFactor,
            height: height / _scaleFactor,
            scaleFactor: _scaleFactor,
          });
        }

        return reject(new Error('Could not get access to canvas context'));
      };

      image.src = sourceUrl;
    };

    reader.readAsDataURL(imageFile);
  });
};
