import * as React from 'react';
import * as ControlBox from '../../../ControlBox/ControlBox';
import { LegendControlProps } from '../index.types';
import style from './index.module.scss';

export default class LegendControlBox extends React.Component<LegendControlProps> {
  componentDidMount() {}

  componentDidUpdate() {}

  private renderHeader = () => {
    const { onClose } = this.props;

    return (
      <React.Fragment>
        <ControlBox.Title title="Legend" />
        <ControlBox.Icon
          onClick={() => {
            onClose();
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  public render() {
    const { rendererState } = this.props;
    const layers = (rendererState?.layers || []).filter((l) => !!l?.id);

    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={this.renderHeader}
      >
        {layers.map((l) => {
          const layerData = l?._data;
          const paint = layerData?.paint || {};
          const visible = l.visible();
          let color;

          // eslint-disable-next-line no-restricted-syntax
          for (const prop in paint) {
            if (prop.endsWith('color')) {
              color = paint[prop];
              break;
            }
          }

          return (
            <div
              key={l.id}
              className={style.item}
              style={{
                opacity: visible ? 1 : 0.4,
              }}
              onClick={(_) => {
                l.setVisible(!visible);
                this.forceUpdate();
              }}
            >
              {l.label}
              <span
                className={style.colorCircle}
                style={{
                  backgroundColor: color,
                }}
              />
            </div>
          );
        })}
      </ControlBox.Wrapper>
    );
  }
}
