import { Tabs } from 'antd';
import * as React from 'react';
import style from './index.module.scss';
import { ManualUpload } from '../../../shapes/manualUploads';
import ManualUploadsApis from '../../../api/manualUploads';
import MosaicDEMUploadCrud from './MosaicDEMUploadCrud';
import PointCloudUploadCrud from './PointCloudUploadCrud';
import DTMUploadCrud from './DTMUploadCrud';
import ThermalUploadCrud from './ThermalUploadCrud';
import {
  ProcessedDataUploaderProps,
  ProcessedDataUploaderState,
  TAB_TYPE,
  UploadType,
} from './index.types';

const { TabPane } = Tabs;

export default class ProcessedDataUploader extends React.Component<
  ProcessedDataUploaderProps,
  ProcessedDataUploaderState
> {
  public constructor(props: ProcessedDataUploaderProps) {
    super(props);

    let activeTabKey: TAB_TYPE = 'map';

    if (props.hideMapUpload) {
      activeTabKey = 'pointcloud';

      if (props.hidePointcloudUpload) {
        activeTabKey = 'dtm';

        if (props.hideDTMUpload) {
          activeTabKey = 'thermal';
        }
      }
    }

    this.state = {
      uploads: null,
      tabKey: activeTabKey,
      uploadsApi: new ManualUploadsApis(),
    };
  }

  public componentDidMount() {
    const { uploadsApi } = this.state;
    const { projectId, aoiId, missionId } = this.props;

    uploadsApi
      .listMissionUploads(projectId, aoiId, missionId)
      .then((res) => {
        this.setState({ uploads: res.data });
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  private getUpload(type: UploadType): ManualUpload | undefined {
    const { uploads } = this.state;

    return (uploads || []).find((u) => u.uploadType === type);
  }

  private onUploadUpdated = (updated: ManualUpload) => {
    const { uploads } = this.state;
    const newUploads = [];

    /*eslint-disable */
    let replaced = false;
    for (const u of uploads || []) {
      if (u.uploadType === updated.uploadType) {
        newUploads.push(updated);
        replaced = true;
      } else {
        newUploads.push(u);
      }
    }

    if (!replaced) {
      newUploads.push(updated);
    }
    this.setState({ uploads: newUploads });
    /* eslint-enable */
  };

  public render() {
    const { projectData } = this.props;
    const { uploads, error, tabKey } = this.state;

    if (uploads === null) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <div>{error}</div>;
    }

    const {
      projectId,
      aoiId,
      missionId,
      fetchViewById,
      showSnackBar,
      hideDTMUpload,
      hideMapUpload,
      hidePointcloudUpload,
      hideThermalUpload,
    } = this.props;
    const pgUpload = this.getUpload('PG_UPLOAD');
    const pointCloudUpload = this.getUpload('POINT_CLOUD_UPLOAD');
    const dtmUpload = this.getUpload('DTM_UPLOAD');
    const thermalUpload = this.getUpload('THERMAL_MOSAIC_UPLOAD');

    return (
      <div className={style.container}>
        <Tabs
          centered
          activeKey={tabKey}
          onChange={(tabKey: TAB_TYPE) => this.setState({ tabKey })}
        >
          {!hideMapUpload && (
            // eslint-disable-next-line no-plusplus
            <TabPane tab="Mosaic & DEM Upload" key="map">
              {tabKey === 'map' && (
                <MosaicDEMUploadCrud
                  projectId={projectId}
                  aoiId={aoiId}
                  missionId={missionId}
                  uploadData={pgUpload}
                  projectData={projectData}
                  updateUploadData={this.onUploadUpdated.bind(this)}
                  actionType={pgUpload ? 'edit' : 'new'}
                  fetchViewById={fetchViewById}
                  showSnackBar={showSnackBar || (() => {})}
                />
              )}
            </TabPane>
          )}
          {!hidePointcloudUpload && (
            // eslint-disable-next-line no-plusplus
            <TabPane tab="Point Cloud Upload" key="pointcloud">
              {tabKey === 'pointcloud' && (
                <PointCloudUploadCrud
                  projectId={projectId}
                  aoiId={aoiId}
                  missionId={missionId}
                  uploadData={pointCloudUpload}
                  projectData={projectData}
                  updateUploadData={this.onUploadUpdated.bind(this)}
                  actionType={pointCloudUpload ? 'edit' : 'new'}
                  fetchViewById={fetchViewById}
                  showSnackBar={showSnackBar || (() => {})}
                />
              )}
            </TabPane>
          )}
          {!hideDTMUpload && (
            // eslint-disable-next-line no-plusplus
            <TabPane tab="DTM Upload" key="dtm">
              {tabKey === 'dtm' && (
                <DTMUploadCrud
                  projectId={projectId}
                  aoiId={aoiId}
                  missionId={missionId}
                  uploadData={dtmUpload}
                  projectData={projectData}
                  updateUploadData={this.onUploadUpdated}
                  actionType={dtmUpload ? 'edit' : 'new'}
                  fetchViewById={fetchViewById}
                  showSnackBar={showSnackBar || (() => {})}
                />
              )}
            </TabPane>
          )}
          {!hideThermalUpload && (
            <TabPane tab="Thermal Data Upload" key="thermal">
              <ThermalUploadCrud
                projectId={projectId}
                aoiId={aoiId}
                missionId={missionId}
                uploadData={thermalUpload}
                projectData={projectData}
                updateUploadData={this.onUploadUpdated}
                actionType={thermalUpload ? 'edit' : 'new'}
                fetchViewById={fetchViewById}
                showSnackBar={showSnackBar || (() => {})}
              />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}
