// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportsControlBox_report_item__Gq\\+qE{padding:10px 15px;font-size:14px}.ReportsControlBox_report_item__Gq\\+qE.fa{font-size:20px;vertical-align:middle;margin-right:10px}", "",{"version":3,"sources":["webpack://./src/components/ReportsControlBox/ReportsControlBox.module.scss"],"names":[],"mappings":"AAEA,uCACE,iBAAA,CACA,cAAA,CAGA,0CACE,cAAA,CACA,qBAAA,CACA,iBAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.report_item {\n  padding: 10px 15px;\n  font-size: 14px;\n\n  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */\n  &:global(.fa) {\n    font-size: 20px;\n    vertical-align: middle;\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"report_item": "ReportsControlBox_report_item__Gq+qE"
};
export default ___CSS_LOADER_EXPORT___;
