import { LockOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Input, Modal } from 'antd';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { Redirect } from 'react-router';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import Logo from '../Logo/Logo';
import style from './Signup.module.scss';
import {
  BASE_CAPI_URL,
  TERMS_AND_CONDITIONS_URL,
  PRIVACY_POLICY_URL,
} from '../../constants/urls';
import { setStyle, undefinedOrNull } from '../../utils/functs';
import { setBodyStyle } from '../../utils/window';
import { resetBrandingStyles, setBrandingStyles } from '../Login/helpers';
import { BrandingModel, ICheckBranding } from '../../shapes/branding';
import BrandingApi from '../../api/branding';
import LoadingOverlay from '../LoadingOverlay';

interface IProps {
  logout?: any;
  email: string;
  code: string;
  form: any;
  loading: boolean;
  error?: string;
  authenticated: boolean;
  partnerId?: string;
}

interface IState {
  firstName: string;
  lastName: string;
  password: string;
  password2: string;
  licencedDroneOperator: boolean;
  surveyor: boolean;
  architect: boolean;
  phoneNumber: string;
  company: string;
  jobTitle: string;
  recaptcha: string;
  error: string;
  isError: boolean;
  islogin: boolean;
  cancelSignUP: boolean;
  checked: boolean;
  signingUp: boolean;
  isUserAleardyRegistered: boolean;
  isPageLoading: boolean;
  brandingData: BrandingModel | null;
}

class Signup extends React.Component<IProps, IState> {
  private brandingApi = new BrandingApi();

  public constructor(props: IProps) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      password: '',
      password2: '',
      licencedDroneOperator: false,
      surveyor: false,
      architect: false,
      phoneNumber: '',
      company: '',
      jobTitle: '',
      recaptcha: '',
      error: '',
      isError: false,
      islogin: false,
      cancelSignUP: false,
      checked: false,
      signingUp: false,
      isUserAleardyRegistered: false,
      isPageLoading: true,
      brandingData: null,
    };
  }

  public componentDidMount(): void {
    const { authenticated } = this.props;

    if (authenticated) {
      this.setState({
        islogin: true,
      });
    }

    this.fetchProjectBranding();

    setBodyStyle('background-color', '#434344');
  }

  public componentWillUnmount(): void {
    setStyle(document.documentElement, {});

    resetBrandingStyles();
  }

  private fetchProjectBranding = () => {
    const { partnerId } = this.props;

    if (undefinedOrNull(partnerId)) {
      this.brandingApi.checkDefaultBranding().then((res: ICheckBranding) => {
        if (!undefinedOrNull(res)) {
          this.setState(
            {
              brandingData: {
                ...res.data,
              },
            },
            () => {
              const { brandingData } = this.state;

              setBrandingStyles(brandingData);
            }
          );
        } else {
          this.setState({
            brandingData: null,
          });
        }

        this.setState({
          isPageLoading: false,
        });
      });

      return;
    }

    this.brandingApi.checkBranding(partnerId).then((res: ICheckBranding) => {
      if (!undefinedOrNull(res)) {
        this.setState(
          {
            brandingData: {
              ...res.data,
            },
          },
          () => {
            const { brandingData } = this.state;

            setBrandingStyles(brandingData);
          }
        );
      } else {
        this.setState({
          brandingData: null,
        });
      }

      this.setState({
        isPageLoading: false,
      });
    });
  };

  private handleCheck = () => {
    const { checked } = this.state;

    this.setState({
      checked: !checked,
    });
  };

  private handlelogoutUser = () => {
    const { logout } = this.props;

    this.setState(
      {
        islogin: false,
      },
      () => {
        logout();
      }
    );
  };

  private handleIsLoginModalClose = () => {
    this.setState({
      islogin: false,
      cancelSignUP: true,
    });
  };

  private handleLicencedDroneOperator = () => {
    const { licencedDroneOperator } = this.state;

    this.setState({
      licencedDroneOperator: !licencedDroneOperator,
    });
  };

  private handleSurveyor = () => {
    const { surveyor } = this.state;

    this.setState({
      surveyor: !surveyor,
    });
  };

  private handleArchitect = () => {
    const { architect } = this.state;

    this.setState({
      architect: !architect,
    });
  };

  private signUp = () => {
    const {
      firstName,
      lastName,
      password,
      licencedDroneOperator,
      surveyor,
      architect,
      phoneNumber,
      company,
      jobTitle,
      recaptcha,
    } = this.state;
    const { code, email, partnerId } = this.props;

    this.setState({
      signingUp: true,
    });

    fetch(`${BASE_CAPI_URL}/v1/signup/create`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        password,
        licencedDroneOperator,
        surveyor,
        architect,
        phoneNumber,
        company,
        jobTitle,
        recaptcha,
        code: code.trim(),
        email: email.trim().toLowerCase(),
        partnerId: partnerId ? partnerId.trim() : null,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }

        if (res.status === 404) {
          this.setState({
            isUserAleardyRegistered: true,
          });
        }

        throw res;
      })
      .then(() => {
        // track user registration successfully by fb pixel here
        // ReactPixel.track('Lead'); commented out because this signup is user invited
        window.location.href = `${window.location.origin}/`;
      })
      .catch((err) => {
        err
          .json()
          .then((error: any) => {
            this.setState({
              error: error.message,
              isError: true,
              signingUp: false,
            });
          })
          .catch(() => {
            this.setState({
              error: 'Could not sign up, please contact support@aspecscire.com',
              isError: true,
              signingUp: false,
            });
          });
      });
  };

  private handleErrorModalClose = () => {
    this.setState(
      {
        isError: false,
      },
      () => {
        const { isUserAleardyRegistered } = this.state;

        if (isUserAleardyRegistered) {
          window.location.href = `${window.location.origin}/`;
        }
      }
    );
  };

  @autobind
  private handleSubmit(e: any): void {
    const { form } = this.props;
    const { password, password2, checked } = this.state;

    e.preventDefault();

    form.validateFields((err: any, values: any) => {
      if (!err) {
        this.setState(
          {
            firstName: values.FirstName,
            lastName: values.LastName,
            password: values.Password,
            password2: values.ConfirmPassword,
            phoneNumber: form.getFieldValue('phoneNumber'),
            company: form.getFieldValue('company'),
            jobTitle: form.getFieldValue('jobTitle'),
          },
          () => {
            let error = '';
            let isError = false;

            if (password === password2 && checked) {
              this.signUp();
            } else if (password !== password2) {
              error = 'Password and confirm password should be same';
              isError = true;
            } else if (!checked) {
              error =
                'You must agree to the terms of use before you can sign up';
              isError = true;
            }

            this.setState({
              isError,
              error,
            });
          }
        );
      }
    });
  }

  private redirectToLogin = () => {
    window.location.href = `${window.location.origin}/`;
  };

  private handleCapchaChange = (value: string) => {
    this.setState({
      recaptcha: value,
    });
  };

  public render(): JSX.Element {
    const { form, error, authenticated, partnerId } = this.props;
    const {
      cancelSignUP,
      checked,
      islogin,
      isError,
      error: errorState,
      signingUp,
      isUserAleardyRegistered,
      licencedDroneOperator,
      surveyor,
      architect,
      isPageLoading,
    } = this.state;
    const { getFieldDecorator } = form;

    if (cancelSignUP) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        {!authenticated ? (
          <Form onSubmit={this.handleSubmit} className={style.container}>
            <Logo
              className={style.logo}
              partnerId={partnerId}
              authenticated={authenticated}
              size="large"
            />
            <Form.Item>
              {getFieldDecorator('FirstName', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter your first name',
                  },
                ],
              })(
                <Input
                  prefix={<UserOutlined className={style.textInput} />}
                  placeholder="*First Name"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('LastName', {
                rules: [
                  { required: true, message: 'Please enter your last name' },
                ],
              })(
                <Input
                  prefix={<UserOutlined className={style.textInput} />}
                  placeholder="*Last Name"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('Password', {
                rules: [
                  { required: true, message: 'Please enter your password' },
                ],
              })(
                <Input
                  prefix={<LockOutlined className={style.textInput} />}
                  type="password"
                  placeholder="*Password"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('ConfirmPassword', {
                rules: [
                  {
                    required: true,
                    message: 'Please confirm your password',
                  },
                ],
              })(
                <Input
                  prefix={<LockOutlined className={style.textInput} />}
                  type="password"
                  placeholder="*Confirm Password"
                />
              )}
            </Form.Item>
            <Form.Item className={style.licencedDroneOperatorCheckbox}>
              {getFieldDecorator('licencedDroneOperator')(
                <Checkbox
                  checked={licencedDroneOperator}
                  onChange={this.handleLicencedDroneOperator}
                >
                  Professional Drone Operator (with License)?
                </Checkbox>
              )}
            </Form.Item>
            <Form.Item className={style.surveyorCheckox}>
              {getFieldDecorator('surveyor')(
                <Checkbox checked={surveyor} onChange={this.handleSurveyor}>
                  Surveying Professional
                </Checkbox>
              )}
            </Form.Item>
            <Form.Item className={style.architectCheckbox}>
              {getFieldDecorator('architect')(
                <Checkbox checked={architect} onChange={this.handleArchitect}>
                  Architecture / Construction Professional
                </Checkbox>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('phoneNumber')(
                <Input
                  prefix={<MobileOutlined className={style.textInput} />}
                  placeholder="Phone Number"
                  type="tel"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('jobTitle')(
                <Input
                  prefix={<UserOutlined className={style.textInput} />}
                  placeholder="Title"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('company')(
                <Input
                  prefix={<UserOutlined className={style.textInput} />}
                  placeholder="Company Name"
                />
              )}
            </Form.Item>
            {error && <label className={style.error}>{error}</label>}
            <div className={style.agreedTermsAndPolicyText}>
              <Checkbox checked={checked} onChange={() => this.handleCheck()} />
              <span className={style.spanColor}>
                I agree to the&nbsp;
                <a
                  className={style.terms}
                  href={TERMS_AND_CONDITIONS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
              </span>
              &nbsp;&nbsp;and&nbsp;&nbsp;
              <span className={style.privatePolicyText}>
                <a
                  target="_blank"
                  href={PRIVACY_POLICY_URL}
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </span>
            </div>
            <div className={style.googlerRecaptcha}>
              <ReCAPTCHA
                size="normal"
                sitekey="6Le5zKgUAAAAAI0yFeZrW3zQJ9suZZnBzRXTkQCZ"
                onChange={this.handleCapchaChange}
              />
            </div>
            <Button
              icon="login"
              htmlType="submit"
              loading={signingUp}
              loadingText="SIGNING UP..."
              fullWidth
              text="SIGN UP"
              className={style.signupBtn}
            />
            <div>
              <span>Already registered? &nbsp;</span>
              {/* TODO: change button to label with similar styling */}
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <label
                onClick={this.redirectToLogin}
                className={style.loginRedirectText}
              >
                Login
              </label>
            </div>
          </Form>
        ) : (
          <Modal
            title="Already Signed In"
            centered
            footer={null}
            visible={islogin}
            onCancel={this.handleIsLoginModalClose}
            destroyOnClose
            maskClosable={false}
          >
            <p className={style.headertext}>
              You are already logged in, in order to signup with another
              account, please log out
            </p>
            <div className={style.btnErrorDiv}>
              <Link to="/">
                <Button className={style.btnError} text="Cancel" />
              </Link>
              <Button
                className={style.btnError}
                onClick={this.handlelogoutUser}
                text="Ok"
              />
            </div>
          </Modal>
        )}
        <Modal
          title="Error while signing up"
          centered
          footer={null}
          visible={isError}
          onCancel={this.handleErrorModalClose}
          destroyOnClose
          maskClosable={false}
        >
          <p className={style.headertext}>{errorState}</p>
          <div className={style.btnErrorDiv}>
            <Button
              className={style.btnError}
              onClick={this.handleErrorModalClose}
              text={isUserAleardyRegistered ? 'GO TO LOGIN' : 'OK'}
            />
          </div>
        </Modal>

        {isPageLoading && (
          <LoadingOverlay overlayClassName={style.pageLoading} />
        )}
      </div>
    );
  }
}

export default Form.create()(Signup);
