import * as React from 'react';
import { Table, Typography } from 'antd';
import styles from './index.module.scss';
import {
  PaymentsHistoryPropsTypes,
  PaymentsHistoryStateTypes,
} from './index.types';
import { sortByDate } from '../../../utils/helpers';
import { GCP_MARKERS_PAGINATION_DEFAULT_PAGE_SIZE } from '../../../constants';
import PaymentsApis from '../../../api/payments';
import { log } from '../../../utils/log';
import { appFormatDate } from '../../../utils/date';
import { roundDecimals } from '../../../utils/functs';

const paymentsApis = new PaymentsApis();

const { Title } = Typography;

class PaymentsHistory extends React.PureComponent<
  PaymentsHistoryPropsTypes,
  PaymentsHistoryStateTypes
> {
  public constructor(props: PaymentsHistoryPropsTypes) {
    super(props);

    this.state = {
      transactionsListData: [],
      transactionsListLoading: true,
    };
  }

  public componentDidMount(): void {
    this.fetchTransactionsList();
  }

  private fetchTransactionsList = (): void => {
    paymentsApis.getTransactionsList().then((res) => {
      const { error: apiError, data: apiData } = res;

      if (apiError) {
        log.error(apiError, 'Payments -> History -> fetchTransactionsList');

        return;
      }

      this.setState(() => {
        return {
          transactionsListData: apiData.transactions,
          transactionsListLoading: false,
        };
      });
    });
  };

  public render(): React.ReactNode {
    const { transactionsListData, transactionsListLoading } = this.state;

    const transactionsListTableColumns = [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (value: string) => appFormatDate(value),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (value: string) => `${roundDecimals(parseFloat(value))} USD`,
      },
      {
        title: 'Remarks',
        dataIndex: 'description',
        key: 'description',
        render: (value: string) => value,
      },
      {
        title: `Status`,
        dataIndex: 'status',
        key: 'status',
        render: (value: string) => value,
      },
    ];

    return (
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <Title level={2}>Payment History</Title>
        </div>

        <div className={styles.transactionsListTable}>
          <Table
            locale={{
              emptyText: 'No transaction history available.',
            }}
            rowKey={(item: any) => item.id}
            loading={transactionsListLoading}
            columns={transactionsListTableColumns}
            dataSource={sortByDate(transactionsListData, 'createdAt')}
            pagination={{
              defaultPageSize: GCP_MARKERS_PAGINATION_DEFAULT_PAGE_SIZE,
            }}
          />
        </div>
      </div>
    );
  }
}

export default PaymentsHistory;
