import { handleActions } from 'redux-actions';
import {
  SETTINGS_MODAL,
  SETTINGS_UPDATE,
  ORGANISATION_MODAL,
} from '../actions';

export interface ISettings {
  isOpen: boolean;
  isUpdating: boolean;
  isPasswordUpdated: boolean;
  isOrganisationOpen: boolean;
}

const initialState: ISettings = {
  isOpen: false,
  isUpdating: false,
  isPasswordUpdated: false,
  isOrganisationOpen: false,
};

const settings = handleActions(
  {
    [SETTINGS_MODAL.OPEN]: (state) => ({
      ...state,
      isOpen: true,
      isUpdating: false,
      isPasswordUpdated: false,
    }),

    [SETTINGS_MODAL.CLOSE]: (state) => ({
      ...state,
      isOpen: false,
    }),

    [ORGANISATION_MODAL.OPEN]: (state) => ({
      ...state,
      isOrganisationOpen: true,
    }),

    [ORGANISATION_MODAL.CLOSE]: (state) => ({
      ...state,
      isOrganisationOpen: false,
    }),

    [SETTINGS_UPDATE.PENDING]: (state) => ({
      ...state,
      isUpdating: true,
      isPasswordUpdated: false,
    }),

    [SETTINGS_UPDATE.REJECTED]: (state) => ({
      ...state,
      isUpdating: false,
      isPasswordUpdated: false,
    }),

    [SETTINGS_UPDATE.FULFILLED]: (state) => {
      return {
        ...state,
        isUpdating: false,
        isOpen: false,
        isPasswordUpdated: true,
      };
    },
  },
  initialState
);

export default settings;
