import classnames from 'classnames';
import * as React from 'react';
import _ from 'lodash';
import { List } from 'antd';
import DroneAnimationStatic from '../../DroneAnimation/DroneAnimationStatic';
import DropDown, { Option } from '../../DropDown/DropDown';
import IssueDetail from '../IssueDetail';
import IssueItem from '../IssueItem';
import style from './index.module.scss';
import SkeletonLoader from '../../SkeletonLoader';
import { IssueListProps, IssueListState } from './index.types';
import { Issue } from '../../../api/issue.types';
import {
  ChangeIssueEvent,
  ChangeFilterEvent,
  IssuesEvent,
  ChangePageEvent,
  ClearProcessStateEvent,
} from '../index.types';

class IssueList extends React.Component<IssueListProps, IssueListState> {
  public constructor(props: IssueListProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { issueId, issues } = this.props;

    if (issues.length > 0) {
      const issue = issueId ? issues.find((s) => s.id === issueId) : issues[0];

      this.selectIssue(issue);
    }
  }

  public UNSAFE_componentWillReceiveProps({
    issueId: newIssueId,
    issues: newIssues,
  }: IssueListProps) {
    const { issueId, issues, onEvent } = this.props;

    if (issueId !== newIssueId || !_.isEqual(issues, newIssues)) {
      const issue = newIssueId
        ? newIssues.find((s) => s.id === newIssueId)
        : newIssues[0];

      this.selectIssue(issue);
    }

    if (issueId !== newIssueId) {
      onEvent(new ClearProcessStateEvent());
    }
  }

  public handleTagFilter = (event: Option) => {
    const { onEvent, pageConfig } = this.props;
    const tagId = event.value;

    onEvent(
      new ChangeFilterEvent({
        ...pageConfig,
        tagId: tagId.length > 0 ? tagId : undefined,
      })
    );
  };

  public selectIssue = (issue?: Issue) => {
    const { onEvent } = this.props;

    if (issue) onEvent(new ChangeIssueEvent(issue));
    this.setState({ issue });
  };

  public onEvent = (e: IssuesEvent<any>) => {
    const { onEvent } = this.props;

    onEvent(e);
  };

  public changeIssuesPage = (direction: string) => {
    const { pageConfig, onEvent } = this.props;
    const page = pageConfig.page || 0;

    if (direction === 'prev') {
      if (page > 0) {
        onEvent(new ChangePageEvent(page - 1));
      }
    } else if (direction === 'next') {
      onEvent(new ChangePageEvent(page + 1));
    }
  };

  public renderToggleResolve(): JSX.Element {
    const { pageConfig } = this.props;

    return (
      <DropDown
        value={pageConfig.showResolved ? 'true' : 'false'}
        onChange={this.onDropDownChange}
        options={[
          { value: 'false', label: 'Hide Resolved' },
          { value: 'true', label: 'Show Resolved' },
        ]}
      />
    );
  }

  public onDropDownChange = (option: Option): void => {
    const { onEvent, pageConfig } = this.props;
    const showResolved = option.value === 'true' || undefined;

    onEvent(new ChangeFilterEvent({ ...pageConfig, showResolved }));
  };

  public renderItems(): JSX.Element {
    const { issues, project, loading } = this.props;
    const { issue: selectedIssue } = this.state;

    return (
      <List
        dataSource={issues}
        loading={loading?.issues || loading?.singleIssue}
        renderItem={(issue: Issue) => {
          let aoiName;

          if (project) {
            const aoi = project.aois.find((x: any) => x.id === issue.aoiId);

            aoiName = aoi?.name;
          }

          return (
            <List.Item style={{ display: 'block' }}>
              <IssueItem
                key={issue.id}
                {...issue}
                className={classnames(
                  selectedIssue && issue.id === selectedIssue.id
                    ? style.active
                    : undefined
                )}
                aoiName={aoiName}
                onClick={() => {
                  this.renderIssueData(issue);
                }}
              />
            </List.Item>
          );
        }}
      />
    );
  }

  public renderIssueData(issue: Issue): void {
    const { onEvent } = this.props;

    onEvent(new ChangeIssueEvent(issue));
  }

  public renderDetails(): JSX.Element | null {
    const { issue } = this.state;
    const {
      user,
      userRole,
      projectId,
      tags,
      project,
      projectUsers,
      commentPage,
      loading,
      processState,
      pageConfig,
      comments,
      hasMore,
      showSnackbar,
    } = this.props;

    if (issue && comments) {
      let aoiName = 'AOI';

      if (project) {
        const aoi = project.aois.find((x: any) => x.id === issue.aoiId);

        aoiName = aoi ? aoi.name : 'AOI';
      }

      return (
        <IssueDetail
          issue={issue}
          aoiName={aoiName}
          onEvent={this.onEvent}
          showSnackbar={showSnackbar}
          tags={tags}
          projectUsers={projectUsers}
          user={user}
          hasMoreComments={hasMore?.comments}
          comments={comments}
          commentPage={commentPage}
          pageConfig={pageConfig}
          loading={loading}
          processState={processState}
          userRole={userRole}
          issueId={issue.id}
          projectId={projectId}
        />
      );
    }

    return null;
  }

  public renderPagination(): JSX.Element | null {
    const { hasMore, pageConfig } = this.props;
    const page = pageConfig.page || 0;

    // if issues are less than 10 then no pagination indicated
    if (page === 0 && !hasMore?.issues) {
      return null;
    }

    const prevButton =
      page > 0 ? (
        <button
          className={style.changePageButton}
          onClick={() => {
            this.changeIssuesPage('prev');
          }}
        >
          <i className="fa fa-arrow-left" />
        </button>
      ) : null;

    const nextButton = hasMore?.issues ? (
      <button
        className={style.changePageButton}
        onClick={() => {
          this.changeIssuesPage('next');
        }}
      >
        <i className="fa fa-arrow-right" />
      </button>
    ) : null;

    return (
      <div className={style.paginationControls}>
        {prevButton}
        <div className={style.pageInfo}>{`${page + 1}`}</div>
        {nextButton}
      </div>
    );
  }

  public render(): React.ReactNode {
    const { issuesStatus, tags, pageConfig } = this.props;

    if (!issuesStatus) {
      return (
        <SkeletonLoader
          size={3}
          position="absolute"
          className={style.skeletonLoading}
        />
      );
    }

    const { unresolvedIssuesExist, resolvedIssuesExist } = issuesStatus;

    if (!unresolvedIssuesExist && !resolvedIssuesExist) {
      return (
        <DroneAnimationStatic
          className="center"
          message="No issues have been created!"
        />
      );
    }

    return (
      <div className={style.container}>
        <div className={style.list}>
          <div className={style.header}>{this.renderToggleResolve()}</div>
          <DropDown
            options={tags
              .map((tag) => ({
                value: tag.id,
                label: tag.tagName,
              }))
              .concat({ value: '', label: 'All Tags' })}
            onChange={this.handleTagFilter}
            value={pageConfig?.tagId}
            placeholder="Select a tag"
          />
          <div id="itemsDiv" className={style.itemDiv}>
            {this.renderItems()}
          </div>
          {this.renderPagination()}
        </div>
        <div className={style.details}>{this.renderDetails()}</div>
      </div>
    );
  }
}

export default IssueList;
