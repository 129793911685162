import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { CreateProjectV2 } from './index';
import { CreateProjectV2Types } from './index.types';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { insertProjectUserRole } from '../../redux/actions/auth';

const mapStateToProps = (state: any): CreateProjectV2Types.ReduxState => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): CreateProjectV2Types.DispatchProps => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
      insertProjectUserRole,
    },
    dispatch
  );
};

export const CreateProjectV2Container = connect<
  CreateProjectV2Types.ReduxState,
  CreateProjectV2Types.DispatchProps,
  CreateProjectV2Types.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(CreateProjectV2);

export default CreateProjectV2Container;
