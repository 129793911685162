import rewind from '@mapbox/geojson-rewind';
import turfArea from '@turf/area';
import turfLength from '@turf/length';
import _ from 'lodash';
import * as React from 'react';
import { Unit2 } from '../InfoHelpers/Unit';

import MeasurementAPIs from '../../../../../api/Measurement';
import { View } from '../../../../../api/views.types';
import {
  convertArea,
  convertLength,
  getValidGeoJson,
} from '../../../../../utils/helpers';
import { Button } from '../../../../Button';
import { Spinner } from '../../../../Spinner/Spinner';

import style from './index.module.scss';

export interface IProps {
  shape: any;
  view: View;
}

export interface IState {
  surfaceArea: number | null;
  loading: boolean;
  blobUrl: string;
}

const measurementApis = new MeasurementAPIs();

class AreaInfo extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      surfaceArea: null,
      loading: false,
      blobUrl: '#',
    };
  }

  public componentDidMount() {
    this.getSurfaceArea();
    const { shape } = this.props;
    const blobURL = this.getPolygonBlobURL(shape.features[0]);

    this.setState({ blobUrl: blobURL });
  }

  public UNSAFE_componentWillReceiveProps(newProps: IProps) {
    const { shape: newShape } = newProps;
    const { shape: oldShape } = this.props;

    if (!_.isEqual(oldShape, newShape)) {
      this.setState(
        {
          surfaceArea: null,
        },
        () => {
          this.getSurfaceArea(newProps);
        }
      );
    }
  }

  private getPolygonBlobURL(polygon: any): string {
    const validGeoJsonPolygon = rewind(getValidGeoJson(polygon));
    const blob = new Blob([JSON.stringify(validGeoJsonPolygon)], {
      type: 'text/geojson',
    });
    const url = URL.createObjectURL(blob);

    return url;
  }

  public getAreaAndLength(shape: any) {
    const feature = shape?.features?.[0];
    const area = feature ? +turfArea(feature).toFixed(2) : 0.0;
    const length = feature
      ? +turfLength(feature, { units: 'meters' }).toFixed(2)
      : 0.0;

    return {
      area,
      length,
    };
  }

  public getSurfaceArea(props?: IProps) {
    const { shape, view } = props || this.props;
    const feature = shape?.features?.[0];
    const rawGeometry = feature?.properties?.raw;

    if (rawGeometry?.type === 'Polygon' && rawGeometry?.coordinates) {
      this.setState(
        {
          loading: true,
        },
        () => {
          measurementApis
            .fetchSurfaceArea(view.id, rawGeometry.coordinates[0])
            .then((res: any) => {
              const surface_area = res?.data?.surface_area?.square_units;

              if (surface_area !== null && surface_area !== undefined) {
                this.setState({
                  surfaceArea: surface_area,
                  loading: false,
                });
              }
            })
            .catch((err) => {
              console.error(
                'Error while trying to calculate surface area.',
                err
              );
              this.setState({ surfaceArea: null, loading: false });
            });
        }
      );

      return;
    }

    this.setState({
      surfaceArea: null,
      loading: false,
    });
  }

  public renderSurfaceArea() {
    const { view } = this.props;
    const { surfaceArea } = this.state;

    if (view.type === 'three_d') {
      return (
        <>
          {surfaceArea === null ? (
            <>
              <Button
                onClick={() => {
                  this.getSurfaceArea();
                }}
                className={style.calcButton}
                type="secondary"
              >
                Calculate Surface Area
              </Button>
            </>
          ) : (
            <>
              <Unit2
                label="Surface Area"
                value1={convertArea(surfaceArea, 'meter')}
                value2={convertArea(surfaceArea, 'feet')}
              />
            </>
          )}
        </>
      );
    }

    return <></>;
  }

  public renderNormalArea = () => {
    const { shape, view } = this.props;
    const { area, length } = this.getAreaAndLength(shape);

    if (view.type === 'three_d') {
      // Don't display 2d area and perimeter for 3D views
      return <></>;
    }

    return (
      <>
        <Unit2
          label="Area"
          value1={convertArea(area, 'meter')}
          value2={convertArea(area, 'feet')}
        />
        <Unit2
          label="Perimeter"
          value1={convertLength(length, 'meter')}
          value2={convertLength(length, 'feet')}
        />
      </>
    );
  };

  public render() {
    const { loading, blobUrl: blobURL } = this.state;

    if (loading) {
      return <Spinner />;
    }

    return (
      <>
        {this.renderNormalArea()}
        {this.renderSurfaceArea()}
        <a
          className={style.downloadLinkContainer}
          href={blobURL}
          download="polygon.geojson"
        >
          <i className="fa fa-download" aria-hidden="true" />
          Download Polygon
        </a>
      </>
    );
  }
}

export default AreaInfo;
