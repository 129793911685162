import classnames from 'classnames';
import * as React from 'react';
import { Modal, Select } from 'antd';
import { Spinner } from '../Spinner/Spinner';
import AddUsers from './AddUsers';
import EditUser from './EditUsers';
import style from './ManageUsers.module.scss';
import { Button } from '../Button';
import ProjectAPIs from '../../api/projects';
import { BASE_CAPI_URL } from '../../constants/urls';

interface IProps {
  projectId: string;
}

interface IState {
  loading: boolean;
  viewCountLoading: boolean;
  users: any[];
  filterUser: any[];
  showAddUSers: boolean;
  isEditing: boolean;
  isdeleting: boolean;
  userPrevRole: any;
  roleFilter: string;
  deletingUserEmail: string;
  error?: string;
  role: any[];
  isDeleted: boolean;
  userViewCount: {
    [key: string]: number;
  };
}

const { Option } = Select;
const projectApis = new ProjectAPIs();

export default class ManageUsers extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      viewCountLoading: true,
      users: [],
      filterUser: [],
      isEditing: false,
      isdeleting: false,
      userPrevRole: null,
      showAddUSers: false,
      deletingUserEmail: '',
      roleFilter: 'All',
      role: [
        { name: 'All', id: 'All' },
        { name: 'Project Admin', id: 'project_admin' },
        { name: 'Project Viewer', id: 'project_viewer' },
        { name: 'Project Read Only', id: 'read_only' },
        { name: 'Field Staff', id: 'field_staff' },
      ],
      isDeleted: false,
      userViewCount: {},
    };
  }

  public componentDidMount(): void {
    this.loadUsers();
    this.loadUserViewCounts();
  }

  private loadUsers = () => {
    this.setState({ loading: true, users: [] });
    const { projectId } = this.props;

    fetch(`${BASE_CAPI_URL}/v2/projects/${projectId}/users`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((x) => x.json())
      .then((usersRes) => {
        this.setState({
          users: usersRes.users,
          filterUser: usersRes.users,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: 'Could not load users. Please try again in some time.',
        });
      });
  };

  private loadUserViewCounts = () => {
    const { projectId } = this.props;

    this.setState(
      {
        viewCountLoading: true,
      },
      () => {
        projectApis
          .getProjectViewAccessCount(projectId)
          .then((res) => {
            const userViewCount = {};

            res.users.forEach((u) => {
              userViewCount[u.userId] = `${u.viewCount}`;
            });

            this.setState({
              userViewCount,
            });
          })
          .catch((err) => {
            console.error(
              'There was an error trying to fetch the view count associated with the users.',
              err
            );
          })
          .finally(() => {
            this.setState({
              viewCountLoading: false,
            });
          });
      }
    );
  };

  private openAddUsersDialog = () => {
    this.setState({ showAddUSers: true });
  };

  private handelIsDeleting = () => {
    this.setState({ isdeleting: false });
  };

  private handleClose = () => {
    this.setState({ showAddUSers: false });
    this.loadUsers();
  };

  private handleEditModalClose = () => {
    this.setState({ isEditing: false });
  };

  private handleDeleteModalClose = () => {
    this.setState({ isdeleting: false });
  };

  private handleDeleteConfirmed = () => {
    this.setState({ isDeleted: true });
    this.handledeleteUser();
  };

  private handledeleteUser = () => {
    const { deletingUserEmail } = this.state;
    const { projectId } = this.props;

    fetch(
      `${BASE_CAPI_URL}/v2/projects/${projectId}/users/${deletingUserEmail}`,
      {
        method: 'DELETE',
        credentials: 'include',
      }
    )
      .then(() => {
        this.setState({
          isdeleting: false,
          deletingUserEmail: '',
          isDeleted: false,
        });
        this.loadUsers();
      })
      .catch((err) => console.error(err));
  };

  private openDeletePopUp = (email: string) => {
    this.setState({ isdeleting: true, deletingUserEmail: email });
  };

  private UpdateUser = (email: string, role: string) => {
    const { projectId } = this.props;
    const body = {
      role,
    };

    fetch(`${BASE_CAPI_URL}/v2/projects/${projectId}/users/${email}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(() => {
        this.loadUsers();
        this.loadUserViewCounts();
      })
      .catch((err) => console.error(err));
  };

  private editUser = (user: any) => {
    this.setState({
      userPrevRole: user,
      isEditing: true,
    });
  };

  private setRoleFilter = (value: string) => {
    const { users } = this.state;

    this.setState({ roleFilter: value });
    const filteredArray = users;

    if (value === 'All') {
      this.setState({ filterUser: filteredArray });
    } else {
      const finalResult = filteredArray.filter((item) => {
        return item.role === value;
      });

      this.setState({ filterUser: finalResult });
    }
  };

  public render(): React.ReactNode {
    const {
      error,
      deletingUserEmail,
      filterUser,
      isdeleting,
      isDeleted,
      isEditing,
      loading,
      role,
      roleFilter,
      showAddUSers,
      users,
      userPrevRole,
      userViewCount,
      viewCountLoading,
    } = this.state;

    if (this.state && loading) {
      return (
        <div className={style.container}>
          <Spinner text="Loading users..." />
        </div>
      );
    }

    if (this.state && error) {
      return (
        <div className={style.container}>
          <div>{error}</div>
          <div className={style.addBtnContainer}>
            <Button text="Try again" onClick={this.loadUsers} />
          </div>
        </div>
      );
    }

    return (
      <div className={style.container}>
        <div className={style.addBtnContainer}>
          <div className={style.controls}>
            <div className={style.roleFiledset}>
              <label>Filter By Role</label>
              <Select
                className={style.select}
                value={roleFilter}
                onChange={this.setRoleFilter}
              >
                {role.map((role: any) => {
                  return (
                    <Option value={role.id} key={role.id}>
                      {role.name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <Button
            className={style.utilityButton}
            text="Add Users"
            onClick={this.openAddUsersDialog}
          />
        </div>
        {users && users.length ? (
          <table className={style.usersTable}>
            <thead className={style.headingStyle}>
              <tr>
                <td>Name</td>
                <td>Email</td>
                <td>Role</td>
                <td>View Count (Last 6 months)</td>
                <td />
                <td />
              </tr>
            </thead>
            <tbody>
              {filterUser.map((u, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr className={style.singleRowStyle} key={idx}>
                  <td
                    className={classnames(
                      style.capitalize,
                      u.active ? style.active : style.inactive
                    )}
                  >
                    {u.active ? u.name : 'NA (Yet to join)'}
                  </td>
                  <td className={u.active ? style.active : style.inactive}>
                    {u.email}
                  </td>
                  <td
                    className={classnames(
                      style.capitalize,
                      u.active ? style.active : style.inactive
                    )}
                  >
                    {u.role.replace(/_/g, ' ')}
                  </td>
                  <td
                    className={classnames(
                      style.viewCount,
                      u.active ? style.active : style.inactive
                    )}
                  >
                    {viewCountLoading ? (
                      <i
                        className={classnames('fa', 'fa-spinner', 'fa-spin')}
                      />
                    ) : (
                      userViewCount[u.userId] || 'N / A'
                    )}
                  </td>
                  <td
                    className={classnames(
                      style.controls,
                      style.capitalize,
                      style.rowEditStyle
                    )}
                  >
                    <Button
                      onClick={() => this.editUser(u)}
                      text="Edit"
                      icon="edit"
                      className={style.editBtn}
                      disabled={!u.editable}
                    />
                  </td>
                  <td
                    className={classnames(
                      style.controls,
                      style.capitalize,
                      style.rowEditStyle
                    )}
                  >
                    <Button
                      onClick={() => this.openDeletePopUp(u.email)}
                      text="Delete"
                      icon="delete"
                      className={style.deleteBtn}
                      disabled={!u.editable}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No users in this project</div>
        )}
        <Modal
          title="Add Users"
          centered
          footer={null}
          visible={showAddUSers}
          onCancel={this.handleClose}
          destroyOnClose
          maskClosable={false}
        >
          <AddUsers {...this.props} />
        </Modal>
        <Modal
          title="Edit User Role"
          centered
          footer={null}
          visible={isEditing}
          onCancel={this.handleEditModalClose}
          destroyOnClose
          maskClosable={false}
        >
          <EditUser
            userRole={userPrevRole}
            handleEditModal={this.handleEditModalClose}
            UpdateUser={this.UpdateUser}
          />
        </Modal>
        <Modal
          title="Delete Confirmation"
          centered
          footer={null}
          visible={isdeleting}
          onCancel={this.handleDeleteModalClose}
          destroyOnClose
          maskClosable={false}
        >
          <p className={style.headertext}>
            Are you sure that you want to remove &nbsp;
            {deletingUserEmail || null} from this project ?&nbsp;
          </p>
          <div className={style.btndeleteDiv}>
            <Button
              className={style.btndelete}
              onClick={this.handelIsDeleting}
              text="No"
            />
            <Button
              className={style.btndelete}
              onClick={this.handleDeleteConfirmed}
              loading={isDeleted}
              loadingText="Deleting..."
              text="Yes"
            />
          </div>
        </Modal>
      </div>
    );
  }
}
