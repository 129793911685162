import { connect } from 'react-redux';
import { fetchViews } from '../../redux/actions/views';
import { getSelectedView } from '../../redux/selectors/views';
import Interior360 from './Interior360';

const mapStateToProps = (state: object, props: object): {} => {
  return {
    view: getSelectedView(state, props),
  };
};

const mapDispatchToProps = (dispatch: any, { projectId, aoiId }: any): {} => {
  return {
    fetchViews: () => dispatch(fetchViews(projectId, aoiId)),
  };
};

const Interior360Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Interior360);

export default Interior360Container;
