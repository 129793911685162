import axios from 'axios';

import ApiBase from './ApiBase';
import { BASE_WORKBENCH_API_URL } from '../constants/urls';
import { processErrorHandling } from '../utils/helpers';
import {
  ListDesignDrawingResponse,
  CreateDrawingRequest,
  DesignDrawingResponse,
  EditDrawingRequest,
  DesignUrlResponse,
  ProcessDrawingRequest,
  PublishDrawingRequest,
} from './drawing.types';

export default class DrawingAPIs extends ApiBase {
  public constructor() {
    super(`${BASE_WORKBENCH_API_URL}/v1`);
  }

  public getDrawingsForAOI(
    projectId: string,
    aoiId: string
  ): Promise<ListDesignDrawingResponse> {
    return this.get(`/projects/${projectId}/aois/${aoiId}/drawings`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to fetch the Drawings.',
          isArray: true,
        });
      });
  }

  public getDrawingById(
    projectId: string,
    aoiId: string,
    drawingId: string
  ): Promise<DesignDrawingResponse> {
    return this.get(
      `/projects/${projectId}/aois/${aoiId}/drawings/${drawingId}`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to fetch the specfied Drawing.',
          isArray: false,
        });
      });
  }

  public createDrawing(
    projectId: string,
    aoiId: string,
    request: CreateDrawingRequest
  ): Promise<DesignDrawingResponse> {
    return this.post(`/projects/${projectId}/aois/${aoiId}/drawings`, request)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to create a new Drawing.',
          isArray: false,
        });
      });
  }

  public editDrawing(
    projectId: string,
    aoiId: string,
    designId: string,
    request: EditDrawingRequest
  ): Promise<DesignDrawingResponse> {
    return this.patch(
      `/projects/${projectId}/aois/${aoiId}/drawings/${designId}`,
      request
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to edit the Drawing.',
          isArray: false,
        });
      });
  }

  public processDrawing(
    projectId: string,
    aoiId: string,
    drawingId: string,
    request: ProcessDrawingRequest
  ): Promise<DesignDrawingResponse> {
    return this.post(
      `/projects/${projectId}/aois/${aoiId}/drawings/${drawingId}/process`,
      request
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to start processing for the version of the Drawing.',
          isArray: false,
        });
      });
  }

  public publishDrawing(
    projectId: string,
    aoiId: string,
    drawingId: string,
    request: PublishDrawingRequest
  ): Promise<DesignDrawingResponse> {
    return this.patch(
      `/projects/${projectId}/aois/${aoiId}/drawings/${drawingId}/publish`,
      request
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to publish the version of the Drawing.',
          isArray: false,
        });
      });
  }

  public getUploadUrlsForDrawing(
    projectId: string,
    aoiId: string,
    drawingId: string
  ): Promise<DesignUrlResponse> {
    return this.get(
      `/projects/${projectId}/aois/${aoiId}/drawings/${drawingId}/urls`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while trying to fetch the upload URLS for the version of the Drawing.',
          isArray: false,
        });
      });
  }

  public async uploadFile(fileToUpload: File, uploadUrl: string) {
    return axios
      .put(uploadUrl, fileToUpload, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      })
      .catch((err) => {
        console.error(
          `There was an error uploading the file to storage. Upload URL: ${uploadUrl}`,
          err
        );

        return Promise.reject();
      });
  }
}
