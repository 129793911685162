import * as React from 'react';
import { ViewConfig } from '../../../index.types';

interface IProps {
  viewer?: any; // pannellum viewer instance
}

export default class PanoramaStateProvider extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public getScreenshot = (): Promise<string | null> => {
    const { viewer } = this.props;

    if (viewer) {
      return new Promise((resolve) => {
        const dataUrl = viewer
          .getRenderer()
          .render(
            (viewer.getPitch() / 180) * Math.PI,
            (viewer.getYaw() / 180) * Math.PI,
            (viewer.getHfov() / 180) * Math.PI,
            { returnImage: true }
          );

        resolve(dataUrl);
      });
    }

    return Promise.resolve(null);
  };

  public saveScreenshot = () => {
    this.getScreenshot().then((ss: any) => {
      if (ss) {
        const link = document.createElement('a');

        link.download = 'screenshot.png';
        link.href = ss;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  public getPanoState(): ViewConfig {
    const { viewer } = this.props;

    if (viewer) {
      return {
        zoom: viewer.getHfov(),
        latitude: viewer.getPitch(),
        longitude: viewer.getYaw(),
      };
    }

    return {};
  }

  public render() {
    return <React.Fragment />;
  }
}
