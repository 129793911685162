import { connect } from 'react-redux';
import { getSelectedViewType } from '../../redux/selectors/views';
import TypeMenu from './TypeMenu';
import { undefinedOrNull, isCertifiedView } from '../../utils/functs';
import { GenericObjectType } from '../../shapes/app';

const types = {
  map: 'maps',
  perspective: 'perspective',
  inspection: 'inspection',
  exterior_360: 'exterior 360',
  interior_360: 'interior 360',
  three_d: '3D View',
  video: 'Videos',
  site_navigation: 'Site Walkthrough',
};

const getMapViewwithSubtypeAndDate = (
  mapViews: any[],
  subType: string,
  date: any,
  value: any
) => {
  const mapViewWithSubtypeAndDate = mapViews.find(
    (mapView: any) => mapView.subType === subType && mapView.date === date
  );

  if (undefinedOrNull(mapViewWithSubtypeAndDate)) {
    const viewWithTypeAndLatestDate = mapViews
      .filter((mapView) => mapView.subType === subType)
      .sort((view1: GenericObjectType, view2: GenericObjectType): any => {
        const view1Date = new Date(view1.date).getTime();
        const view2Date = new Date(view2.date).getTime();

        return view2Date - view1Date;
      });

    if (viewWithTypeAndLatestDate.length > 0) {
      return {
        name: value,
        label: types[value],
        viewId: viewWithTypeAndLatestDate[0].id,
        projectId: viewWithTypeAndLatestDate[0].projectId,
        aoiId: viewWithTypeAndLatestDate[0].aoiId,
        certifiedForDisplayCount: viewWithTypeAndLatestDate[0]
          .certifiedForDisplay
          ? 1
          : 0,
      };
    }

    return {
      name: value,
    };
  }

  return {
    name: value,
    label: types[value],
    viewId: mapViewWithSubtypeAndDate.id,
    projectId: mapViewWithSubtypeAndDate.projectId,
    aoiId: mapViewWithSubtypeAndDate.aoiId,
    certifiedForDisplayCount: mapViewWithSubtypeAndDate.certifiedForDisplay
      ? 1
      : 0,
  };
};

const getMenuTypes = (state: any, viewId: string) => {
  const { data: views } = state.views;

  const currentView = views.find((view: any) => view.id === viewId);

  if (currentView) {
    const [maps, nonMaps] = [
      Object.keys(types).slice(0, 1),
      Object.keys(types).slice(1),
    ];

    // aerial, elevation, survey, or thermal_mosaic view required to enable Map type
    const mapTypes = maps.map((value) => {
      const mapViews = views.filter(
        (a: GenericObjectType) => isCertifiedView(a) && a.type === value
      );

      // certified map views
      if (mapViews.length > 0) {
        const aerialMapView = getMapViewwithSubtypeAndDate(
          mapViews,
          'aerial',
          currentView.date,
          value
        );
        const elevationMapView = getMapViewwithSubtypeAndDate(
          mapViews,
          'elevation',
          currentView.date,
          value
        );
        const surveyMapView = getMapViewwithSubtypeAndDate(
          mapViews,
          'survey',
          currentView.date,
          value
        );
        const thermalMapView = getMapViewwithSubtypeAndDate(
          mapViews,
          'thermal_mosaic',
          currentView.date,
          value
        );

        if (!undefinedOrNull(aerialMapView.viewId)) {
          return aerialMapView;
        }

        if (!undefinedOrNull(elevationMapView.viewId)) {
          return elevationMapView;
        }

        if (!undefinedOrNull(surveyMapView.viewId)) {
          return surveyMapView;
        }

        if (!undefinedOrNull(thermalMapView.viewId)) {
          return thermalMapView;
        }

        return {
          name: value,
        };
      }

      // if there are no certified map views
      return {
        name: value,
      };
    });

    const nonMapTypes = nonMaps.map((value) => {
      const requiredview = views
        .filter((a: GenericObjectType) => isCertifiedView(a))
        .find(
          (view: any) => view.type === value && view.date === currentView.date
        );

      if (undefinedOrNull(requiredview)) {
        // find view with current type and latest date

        const viewWithTypeAndLatestDate = views
          .filter(
            (a: GenericObjectType) => isCertifiedView(a) && a.type === value
          )
          .sort((view1: GenericObjectType, view2: GenericObjectType): any => {
            const view1Date = new Date(view1.date).getTime();
            const view2Date = new Date(view2.date).getTime();

            return view2Date - view1Date;
          });

        if (viewWithTypeAndLatestDate.length > 0) {
          return {
            name: value,
            label: types[value],
            viewId: viewWithTypeAndLatestDate[0].id,
            projectId: viewWithTypeAndLatestDate[0].projectId,
            aoiId: viewWithTypeAndLatestDate[0].aoiId,
            certifiedForDisplayCount: viewWithTypeAndLatestDate[0]
              .certifiedForDisplay
              ? 1
              : 0,
          };
        }
        // if current AOI card has no map or external_360 or any other type of views

        return {
          name: value,
        };
      }

      return {
        name: value,
        label: types[value],
        viewId: requiredview.id,
        projectId: requiredview.projectId,
        aoiId: requiredview.aoiId,
        certifiedForDisplayCount: requiredview.certifiedForDisplay ? 1 : 0,
      };
    });

    return [...mapTypes, ...nonMapTypes];
  }

  return [];
};

const mapStateToProps = (state: any, { viewId }: any): any => {
  return {
    menus: getMenuTypes(state, viewId),
    activeType: getSelectedViewType(state, { viewId: viewId.split('/')[0] }),
  };
};

const TypeMenuContainer = connect(mapStateToProps, null)(TypeMenu);

export default TypeMenuContainer;
