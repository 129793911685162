import classnames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { Issue } from '../../../api/issue.types';
import style from './index.module.scss';

interface IProps extends Issue {
  onClick: () => void;
  aoiName?: string;
  className?: string;
}

class IssueItem extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { title, author, createdAt, resolved, aoiName, className, onClick } =
      this.props;
    const createdAtDate = new Date(createdAt);

    return (
      <div
        className={classnames(style.container, className)}
        onClick={() => onClick()}
      >
        <section className={style.time}>
          {moment(createdAtDate).fromNow()}
          {resolved ? (
            <div>
              <i
                className="fa fa-check-circle-o"
                aria-hidden="true"
                style={{ float: 'right' }}
              />
            </div>
          ) : null}
        </section>
        <h2 className={style.title}>{title}</h2>
        <section className={style.username}>{aoiName}</section>
        <section className={style.designation}>{author}</section>
      </div>
    );
  }
}

export default IssueItem;
