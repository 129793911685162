import { Button } from 'antd';
import React, { useState } from 'react';
import PlotGoogleMap from 'src/components/PlotGoogleMap';
import ImageRenderer from 'src/components/View/ImageView/ImageRenderer';
import {
  ImageAnnotationControl,
  PIXEL_SOURCE,
} from 'src/components/View/ImageView/ImageRenderer/ImageAnnotations';
import {
  APP_PRIMARY_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_COLOR,
  GOOGLE_MAP_MARKER_HIGHLIGHT_COLOR,
} from 'src/constants/colors';
import styles from './gcps.module.scss';

interface ProjectionProps {
  boundaryList: any;
  images: any[];
  projections: any;
  markedProjections: any[];
  onProjectionMarked: (
    imageGuid: string,
    pixelX: number,
    pixelY: number
  ) => void;
  onProjectionRemoved: (projectionId: string) => void;
  onFindProjections: () => void;
}

export const Projections: React.FC<ProjectionProps> = ({
  boundaryList,
  images,
  projections,
  markedProjections,
  onProjectionMarked,
  onProjectionRemoved,
  onFindProjections,
}) => {
  const [selectedImage, setSelectedImage] = useState('');
  const [olMap, setOlMap] = useState(null as any);

  const handleClick = (e: MouseEvent) => {
    if (olMap) {
      const pixelCoords = olMap.getEventCoordinate(e);

      onProjectionMarked(selectedImage, pixelCoords[0], -pixelCoords[1]);
    }
  };

  const imageMarkers: any[] = (images || []).map((i, index) => {
    const hasEstimatesProjection = !!projections[i.guid];
    const hasMarkedProjection = !!(markedProjections || []).find(
      (p) => p.imageGuid === i.guid
    );
    const color = hasMarkedProjection
      ? GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR
      : hasEstimatesProjection
      ? GOOGLE_MAP_MARKER_HIGHLIGHT_COLOR
      : GOOGLE_MAP_MARKER_DEFAULT_COLOR;

    return {
      pos: {
        lat: parseFloat(i.latitude),
        lng: parseFloat(i.longitude),
      },
      index,
      icon: {
        selected: {
          types: ['DEFAULT'],
          color: APP_PRIMARY_COLOR,
          ignoreMarkerClusterer: false,
        },
        unselected: {
          types: ['DEFAULT'],
          color,
          ignoreMarkerClusterer: false,
        },
      },
    };
  });
  const image = (images || []).find((i: any) => i.guid === selectedImage);
  const selectedMarkerIndex = (images || []).findIndex(
    (i: any) => i.guid === selectedImage
  );
  const markedProjection = (markedProjections || []).find(
    (p) => p.imageGuid === selectedImage
  );
  const projection: any = markedProjection || projections[selectedImage || ''];

  return (
    <div className={styles.gcpProjectionsContainer}>
      <div className={styles.projectionsMapColumn}>
        <div className={styles.projectionsMap}>
          <PlotGoogleMap
            width="100%"
            height="100%"
            boundaryPointsList={boundaryList ? [boundaryList] : null}
            markerPointsList={imageMarkers}
            onMarkerClick={(idx: number) => {
              setSelectedImage(images[idx].guid);
            }}
            selectedMarkerIndex={selectedMarkerIndex}
            centerMapTo="boundary"
            markerClustererMaxZoom={20}
            gestureHandling="greedy"
            maintainZoomLevel
          />
        </div>
        <div className={styles.projectionControls}>
          <Button
            type="primary"
            onClick={() => onFindProjections()}
            disabled={(markedProjections || []).length < 2}
          >
            Find remaining projections
          </Button>
        </div>
      </div>
      <div className={styles.projectionColumn}>
        <div
          className={styles.projectionImage}
          onDoubleClick={(e) => {
            handleClick(e as any);
          }}
        >
          {image && (
            <ImageRenderer
              image={image}
              rotation={0}
              viewConfig={{
                latitude: -image.imageHeight / 2,
                longitude: image.imageWidth / 2,
              }}
              setOlMap={(m) => {
                m?.on('dblclick', (e) => {
                  e.stopPropagation();
                });
                setOlMap(m);
              }}
            >
              {projection && (
                <ImageAnnotationControl
                  annotationsData={{
                    annotations: [
                      {
                        center: {
                          x: projection.pixelX,
                          y: -projection.pixelY,
                        },
                        source: PIXEL_SOURCE.user,
                      },
                    ],
                  }}
                />
              )}
            </ImageRenderer>
          )}
        </div>
        <div className={styles.projectionControls}>
          <Button
            type="primary"
            disabled={!markedProjection}
            onClick={() => onProjectionRemoved(markedProjection?.id)}
          >
            Delete Projection
          </Button>
          Image guid: {image?.guid}
        </div>
      </div>
    </div>
  );
};
