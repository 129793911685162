import classnames from 'classnames';
import * as React from 'react';
import jsonexport from 'jsonexport';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { Button } from '../../Button';
import CSVFileValidator from './CsvFileValidator';
import style from './UploadGCP.module.scss';
import MissionV2NavigatorApis from '../../../api/missionV2Navigator';
import 'react-table/react-table.css';
import Pagination from './Pagination';
import { BASE_CAPI_URL } from '../../../constants/urls';
import styles from '../../TerrainMaps/TerrainMapsTabsLayout2/index.module.scss';
import { log } from '../../../utils/log';
import { undefinedOrNull, undefinedOrNullChained } from '../../../utils/functs';
import { GcpPlanData } from '../../../api/mission.types';

const missionV2NavigatorApis = new MissionV2NavigatorApis();

interface IProps {
  aoiId: string;
  projectId: string;
  missionId: string;
  plan: any;
  onPlanChange?: (p: any) => void;
  gcpPlanData: GcpPlanData;
  getGcpPlanData: (planId: string) => void;
  epsgCode?: string;
  units?: string;
}

interface IState {
  errMsg: any;
  warningMsg: any;
  data: any;
  csv: any;
  canUpload: boolean;
  showExistingGcp: boolean;
  showModal: boolean;
  finishUploadModal: boolean;
  modalMsg: any;
  isUploadFinished: boolean;
  gcpUploadInProgress: boolean;
  showSampleCsv: boolean;
  isFinalized: boolean;
  gcpProcessStatus: string;
}

function getGCPDisplayHeaders() {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (row: any) => {
        const { value } = row;

        return <div style={{ textAlign: 'center' }}>{value}</div>;
      },
    },
    {
      Header: 'Easting',
      accessor: 'x',
      Cell: (row: any) => {
        const { value } = row;

        return <div style={{ textAlign: 'center' }}>{value}</div>;
      },
    },
    {
      Header: 'Northing',
      accessor: 'y',
      Cell: (row: any) => {
        const { value } = row;

        return <div style={{ textAlign: 'center' }}>{value}</div>;
      },
    },
    {
      Header: 'Elevation',
      accessor: 'z',
      Cell: (row: any) => {
        const { value } = row;

        return <div style={{ textAlign: 'center' }}>{value}</div>;
      },
    },
    {
      Header: 'Latitude',
      accessor: 'latitude',
      Cell: (row: any) => {
        const { value } = row;

        return <div style={{ textAlign: 'center' }}>{value}</div>;
      },
    },
    {
      Header: 'Longitude',
      accessor: 'longitude',
      Cell: (row: any) => {
        const { value } = row;

        return <div style={{ textAlign: 'center' }}>{value}</div>;
      },
    },
  ];
}

class UploadGCP extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      errMsg: null,
      warningMsg: null,
      data: null,
      csv: null,
      canUpload: false,
      showModal: false,
      showExistingGcp: true,
      finishUploadModal: false,
      modalMsg: '',
      isUploadFinished: false,
      gcpUploadInProgress: false,
      showSampleCsv: false,
      isFinalized: props.plan.gcpDataStatus === 'APPROVED',
      gcpProcessStatus: 'uninitiated',
    };
    this.fileChanged = this.fileChanged.bind(this);
  }

  public componentDidMount(): void {
    const { missionId } = this.props;

    missionV2NavigatorApis.getGcpProcessingStatus(missionId).then((res) => {
      const { error: apiError } = res;

      if (apiError) {
        log.error(
          apiError,
          'GcpMarkingEdit -> handleSaveProjection -> gcpMarkingApis.postProjection'
        );

        return;
      }

      this.setState({ gcpProcessStatus: res.data.data.status });
    });
  }

  private fileChanged = (event: any) => {
    const element: HTMLInputElement = event.target;

    this.setState({ showExistingGcp: false });
    if (element != null && element.files != null) {
      const { files } = element;

      this.CSVValidate(files[0]);
    }
  };

  private CSVValidate = (file: any) => {
    // *IMPORTANT* : 1. All the optional headers are to be defined after all other types are defined.
    //               2. Refer csv-file-validator npm for further documentation, the code fot this app is manipulated.

    const config = {
      name: {
        required: true,
        isValid: (x: any) => {
          return /[a-zA-Z0-9]/.test(x);
        },
        alternateName: 'label',
        displayLabel: 'Name',
      },
      x: {
        required: true,
        isValid: (x: any) => {
          return !Number.isNaN(parseFloat(x));
        },
        alternateName: 'easting',
        displayLabel: 'Easting',
      },
      y: {
        required: true,
        isValid: (x: any) => {
          return !Number.isNaN(parseFloat(x));
        },
        alternateName: 'northing',
        displayLabel: 'Northing',
      },
      z: {
        required: true,
        isValid: (x: any) => {
          return !Number.isNaN(parseFloat(x));
        },
        alternateName: 'elevation',
        displayLabel: 'Elevation',
      },
      latitude: {
        required: false,
        isValid: (x: any) => {
          return !Number.isNaN(parseFloat(x));
        },
        displayLabel: 'Latitude',
      },
      longitude: {
        required: false,
        isValid: (x: any) => {
          return !Number.isNaN(parseFloat(x));
        },
        displayLabel: 'Longitude',
      },
    };

    CSVFileValidator(file, config)
      .then((csvData: any) => {
        const csvdataobjects = csvData.data;

        this.setState({
          errMsg: csvData.errorMessage,
          warningMsg: csvData.warningMessage,
          canUpload: csvData.dataValid,
          data: csvdataobjects,
          csv: file,
        });
      })
      .catch(() => {
        /**/
      });
  };

  private previewData = () => {
    const { csv, data } = this.state;
    const columns = getGCPDisplayHeaders();

    if (!csv) {
      return null;
    }

    return (
      <div>
        <div className={style.warningText}>
          {!data || data.length === 0 ? (
            <p>Please ensure that file is in correct format</p>
          ) : null}
        </div>
        {this.renderEPSGInfo()}
        <p>Displaying Chosen CSV Data</p>
        <ReactTable
          PaginationComponent={Pagination}
          data={data}
          columns={columns}
          defaultPageSize={6}
          showPagination={data.length > 6}
        />
      </div>
    );
  };

  private getUnitLabel = (unit: string) => {
    switch (unit) {
      case 'meter':
        return 'meters';
      case 'foot':
        return 'feet';
      case 'usFT':
        return 'US Survey feet';
      default:
        return unit;
    }
  };

  private renderEPSGInfo = () => {
    const { epsgCode, units, projectId } = this.props;

    return (
      <p>
        {epsgCode ? (
          <>
            The Project EPSG is <b>EPSG:{epsgCode}</b>.
          </>
        ) : (
          <>
            The Project EPSG has not been set. It can be set{' '}
            <Link to={`/project/${projectId}/edit`}>
              <u>here</u>
            </Link>
            .
          </>
        )}
        {units ? (
          <>
            {' '}
            All values are expected to be in <b>{this.getUnitLabel(units)}</b>
          </>
        ) : (
          <> The units will be determined by the Project EPSG.</>
        )}
      </p>
    );
  };

  private canUpload = () => {
    const { canUpload } = this.state;

    return canUpload;
  };

  private showSampleCsv = () => {
    const { showSampleCsv } = this.state;

    return showSampleCsv;
  };

  private toggleSampleCsvDisplay = () => {
    const { showSampleCsv } = this.state;

    this.setState({ showSampleCsv: !showSampleCsv });
  };

  private gcpUploadInProgress = () => {
    const { gcpUploadInProgress } = this.state;

    return gcpUploadInProgress;
  };

  private canMarkFinishUpload = (checkValidGcps: boolean = true) => {
    const { gcpPlanData, plan } = this.props;
    const { gcpProcessStatus } = this.state;

    let validGcpPresent = true;

    for (let i = 0; i < gcpPlanData.gcpPoints.length; i += 1) {
      if (
        !gcpPlanData.gcpPoints[i].deleted &&
        (Number.isNaN(parseFloat(`${gcpPlanData.gcpPoints[i].x}`)) ||
          Number.isNaN(parseFloat(`${gcpPlanData.gcpPoints[i].y}`)) ||
          Number.isNaN(parseFloat(`${gcpPlanData.gcpPoints[i].z}`)))
      ) {
        validGcpPresent = false;
      }
    }

    return (
      ['UPLOADED', 'APPROVED'].indexOf(plan.gcpDataStatus) > -1 &&
      (checkValidGcps ? validGcpPresent : true) && // ignore validGcpPresent if we're not considering them
      gcpProcessStatus !== 'INITIATED'
    );
  };

  private renderWarning = () => {
    const { showExistingGcp } = this.state;

    if (showExistingGcp) {
      return (
        <div className={style.csvInstructionText}>{this.renderGcpPoints()}</div>
      );
    }

    return <div />;
  };

  private renderFileUploadSegment = () => {
    const { csv } = this.state;

    return (
      <div>
        <div className={style.uploadInputDiv}>
          <input
            className={classnames(
              style.uploadInput,
              csv ? style.widthWithCsv : style.widthWithoutCsv
            )}
            type="file"
            accept=".csv"
            onChange={this.fileChanged}
          />
          {csv ? (
            <div className={style.controlsButton}>
              <Button
                disabled={!this.canUpload()}
                onClick={this.handleUpload}
                loading={this.gcpUploadInProgress()}
                text="Upload"
              />
            </div>
          ) : null}
        </div>
        <br />
        <br />
        <div className={style.sampleLinkDiv}>
          <a onClick={this.toggleSampleCsvDisplay}>Sample CSV</a>
        </div>
      </div>
    );
  };

  private renderGcpPoints = () => {
    const { gcpPlanData } = this.props;

    const gcpData = gcpPlanData.gcpPoints
      ? gcpPlanData.gcpPoints.map((item: any) => {
          // eslint-disable-next-line no-param-reassign
          item.latitude = Number.isNaN(parseFloat(item.latitude))
            ? '-'
            : parseFloat(item.latitude).toFixed(6);
          // eslint-disable-next-line no-param-reassign
          item.longitude = Number.isNaN(parseFloat(item.longitude))
            ? '-'
            : parseFloat(item.longitude).toFixed(6);

          return item;
        })
      : [];
    const columns = getGCPDisplayHeaders();

    if (gcpData.length === 0) {
      return (
        <div>
          <p>No GCP Data Points to show.</p>
        </div>
      );
    }

    return (
      <div>
        {this.renderEPSGInfo()}
        <p>Displaying the GCP Data Points.</p>
        <ReactTable
          PaginationComponent={Pagination}
          data={gcpData}
          columns={columns}
          defaultPageSize={6}
          showPagination={gcpData.length > 6}
        />
      </div>
    );
  };

  private renderErrors = () => {
    const { errMsg, warningMsg } = this.state;

    if (errMsg) {
      return <p className={style.textColor}>{`Error: ${errMsg}`}</p>;
    }

    if (warningMsg) {
      return <p className={style.textColor}>{`Warning: ${warningMsg}`}</p>;
    }

    return <div />;
  };

  private handleUpload = () => {
    const { csv } = this.state;
    const { plan, getGcpPlanData, onPlanChange } = this.props;
    const formdata = new FormData();

    this.setState({ gcpUploadInProgress: true });

    formdata.append('file', csv);
    const planId = plan.id;

    formdata.append('gcpPlanId', planId);
    fetch(`${BASE_CAPI_URL}/v1/gcp_data_upload`, {
      method: 'POST',
      body: formdata,
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ gcpUploadInProgress: false });
        if (res.status === 'success') {
          plan.gcpDataStatus = 'UPLOADED';
          if (onPlanChange) {
            onPlanChange(plan);
          }

          this.setState({
            modalMsg: 'File successfully uploaded',
            showModal: true,
          });
          getGcpPlanData(planId);
        } else {
          const msg = `There was an error uploading the file
            ${res.status}:
            ${res.message}`;

          this.setState({
            modalMsg: msg,
            showModal: true,
          });
        }
      })
      .catch((e) => {
        log.error(e, 'UploadGCP.handleUpload');
      });
  };

  private handleModal = () => {
    const { showModal } = this.state;

    this.setState({ showModal: !showModal });
  };

  private handleFinishModal = () => {
    const { finishUploadModal } = this.state;

    this.setState({ finishUploadModal: !finishUploadModal });
  };

  private handleFinish = () => {
    this.setState({ isUploadFinished: true });
    const { isFinalized } = this.state;

    if (isFinalized) {
      this.updatePlanDataStatus('UPLOADED');
    } else {
      this.updatePlanDataStatus('APPROVED');
    }
  };

  private updatePlanDataStatus = (gcpDataStatus: string) => {
    const { plan, onPlanChange } = this.props;

    const formData = {
      gcpDataStatus,
      status: gcpDataStatus === 'APPROVED' ? 'APPROVED' : 'GENERATED',
    };

    missionV2NavigatorApis.updatePlanStatus(plan.id, formData).then((res) => {
      const { error: apiError } = res;

      this.setState({
        showModal: false,
        finishUploadModal: false,
        isUploadFinished: false,
      });

      if (apiError) {
        log.error(apiError, 'EditMission -> surveyPlanning -> GCPUpload');

        return;
      }

      this.setState({
        isFinalized: res.data.data.gcpDataStatus === 'APPROVED',
      });

      if (onPlanChange && !undefinedOrNullChained(res, 'data.data')) {
        plan.gcpDataStatus = res.data.data.gcpDataStatus;
        plan.status = res.data.data.status;
        onPlanChange(plan);
      }
    });
  };

  private finalizeDataButtonText = () => {
    const { isFinalized } = this.state;

    return isFinalized ? 'UN-FINALIZE DATA' : 'FINALIZE DATA';
  };

  private downloadFile(text: string, name: string, type: string) {
    const file = new Blob([text], { type });

    // @ts-ignore
    const isIE = false || !!document.documentMode;

    if (isIE) {
      (window.navigator as any).msSaveOrOpenBlob(file, name);
    } else {
      const a = document.createElement('a');

      a.href = URL.createObjectURL(file);
      a.download = name;
      document.body.appendChild(a);
      a.style.display = 'none';
      a.click();
    }
  }

  private downloadGCPCSVFile = () => {
    const { gcpPlanData } = this.props;

    // TODO: create csv from gcpPlanData, and download as file
    const header = gcpPlanData.gcpPoints.map((column) => {
      return {
        Name: column.name,
        Easting: column.x,
        Northing: column.y,
        Elevation: column.z,
      };
    });

    if (undefinedOrNull(gcpPlanData)) {
      return;
    }

    const options = {
      textDelimiter: ',',
    };

    jsonexport(header, options, (err: string, csv: string) => {
      if (err) {
        console.error(err);
      } else {
        this.downloadFile(csv, 'gcp_data.csv', 'text/csv');
      }
    });
  };

  public render(): React.ReactNode {
    const {
      isFinalized,
      modalMsg,
      isUploadFinished,
      finishUploadModal,
      showModal,
    } = this.state;
    const { gcpPlanData } = this.props;

    return (
      <div>
        <Modal
          title="Response"
          centered
          footer={null}
          visible={showModal}
          onCancel={this.handleModal}
          destroyOnClose
          maskClosable={false}
        >
          <p className={style.headertext}>{modalMsg}</p>
          <div className={style.btndeleteDiv}>
            <Button
              className={style.btndelete}
              onClick={this.handleModal}
              text="Close"
            />
          </div>
        </Modal>
        <Modal
          title={
            isFinalized
              ? 'Un-finalize Ground Control Point Data'
              : 'Finalize Ground Control Point Data'
          }
          centered
          footer={null}
          visible={finishUploadModal}
          onCancel={this.handleFinishModal}
          destroyOnClose
          maskClosable={false}
        >
          <p className={style.headertext}>
            {isFinalized
              ? 'After un-finalising, you will be able to update GCPs. Continue?'
              : 'After finalising, you will not be able to update GCPs. Continue?'}
          </p>
          <div className={style.btndeleteDiv}>
            <Button
              className={style.btndelete}
              onClick={this.handleFinishModal}
              text="No"
            />
            <Button
              className={style.btndelete}
              onClick={this.handleFinish}
              loading={isUploadFinished}
              loadingText={isFinalized ? 'Reverting...' : 'Finalising...'}
              text="Yes"
            />
          </div>
        </Modal>
        <Modal
          title="Sample GCP CSV"
          centered
          footer={null}
          visible={this.showSampleCsv()}
          onCancel={this.toggleSampleCsvDisplay}
          destroyOnClose
          maskClosable={false}
          width={600}
        >
          <p className={styles.headertext}>
            {`A sample acceptable CSV. The field names are case insensitive and can be in any order.`}
            <br />
            {`Except 'Latitude' and 'Longitude', the rest are mandatory.`}
          </p>

          <textarea className={style.csvSample} readOnly>
            {'Name,Easting,Northing,Elevation,Latitude,Longitude\n' +
              'GCP6,248912.891,1930232.504,502.915,12.4553,77.121232\n' +
              'ICP1,248777.071,1930433.196,505.554,12.23412,77.23123\n' +
              'ICP2,249023.142,1930299.486,495.4,12.34535,77.234242\n' +
              'GCP8,248972.71,1930425.689,503.137,12.1234234,77.2342547\n' +
              'GCP9,248903.662,1930458.496,505.299,12.5334234,77.6123253'}
          </textarea>
          <p className={styles.headertext}>
            {`Note: The fields Easting,Northing and Elevation can also be passed as X, Y and Z respectively.`}
          </p>
        </Modal>
        {!isFinalized ? this.renderFileUploadSegment() : null}
        {this.renderWarning()}
        {this.renderErrors()}
        {this.previewData()}
        <div className={style.finishDiv}>
          <Button
            disabled={!this.canMarkFinishUpload(!isFinalized)} // ignore validGcp check if plan already finalized
            onClick={this.handleFinishModal}
            className={style.finishBtn}
            text={this.finalizeDataButtonText()}
          />
          {gcpPlanData.gcpPoints ? (
            <Button
              disabled={!this.canMarkFinishUpload()}
              text="Download existing data"
              style={{ marginLeft: 5 }}
              onClick={this.downloadGCPCSVFile}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default UploadGCP;
