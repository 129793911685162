/* eslint-disable func-names */

import isEventAtCoordinates from '@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates';
import POPUP from './Popup';
import { GenericObjectType } from '../../shapes/app';

const LotsOfPointsMode: GenericObjectType = {};

LotsOfPointsMode.onSetup = function (opts: GenericObjectType) {
  this.updateUIClasses({
    mouse: 'add',
  });
  const state: GenericObjectType = {};

  state.count = opts.count || 0;
  state.ids = [];
  POPUP.setHTML('Click on map to select points').addTo(this.map);

  return state;
};

LotsOfPointsMode.onStop = function () {
  this.updateUIClasses({
    mouse: 'none',
  });
  POPUP.remove();
};

LotsOfPointsMode.onClick = function (
  state: GenericObjectType,
  e: GenericObjectType
) {
  if (
    state.count >= 1 &&
    isEventAtCoordinates(
      e,
      this.getFeature(state.ids[state.ids.length - 1]).getCoordinates()
    )
  ) {
    this.changeMode('static', { featureIds: state.ids });

    return;
  }

  // eslint-disable-next-line no-param-reassign
  state.count += 1;

  const point = this.newFeature({
    type: 'Feature',
    properties: {
      label: state.count,
    },
    geometry: {
      type: 'Point',
      coordinates: [e.lngLat.lng, e.lngLat.lat],
    },
  });

  this.addFeature(point); // puts the point on the map
  state.ids.push(point.id);
  if (state.count >= 1) {
    POPUP.setHTML('Double click on map to finish selection');
  }
};

LotsOfPointsMode.onKeyUp = function (
  state: GenericObjectType,
  e: GenericObjectType
) {
  if (e.keyCode === 27) {
    this.changeMode('static', { featureIds: state.ids });
  }
};

LotsOfPointsMode.toDisplayFeatures = function (
  state: GenericObjectType,
  geojson: GenericObjectType,
  display: (geojson: GenericObjectType) => void
) {
  display(geojson);
};

LotsOfPointsMode.onMouseMove = function (
  state: GenericObjectType,
  event: GenericObjectType
) {
  POPUP.setLngLat(event.lngLat);
};

export default LotsOfPointsMode;
