import React from 'react';
import { Button } from 'src/components/Button';
import style from 'src/components/View/ViewControls/MapMakerControls/AddShapeControlBox/index.module.scss';

export interface LayerControlProps {
  reset: () => void;
  save: () => void;
  disabled?: boolean;
}
export const LayerControl: React.FC<LayerControlProps> = ({
  reset,
  save,
  disabled,
}) => {
  return (
    <div className={style.controlGrid}>
      <Button type="secondary" onClick={() => reset()} disabled={disabled}>
        Reset
      </Button>
      <Button onClick={() => save()} disabled={disabled}>
        Save
      </Button>
    </div>
  );
};
