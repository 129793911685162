import * as React from 'react';
import { Link } from 'react-router-dom';
import AoiDropDown from '../AoiDropDown/AoiDropDownContainer';
import MenuItem from '../MenuItem/MenuItem';
import TypeMenu from '../TypeMenu/TypeMenuContainer';
import style from './MapViewHeader.module.scss';
import { viewUrl } from '../../routes/urls';
import { objectListToQueryString } from '../../utils/functs';

interface IMenu {
  name: string;
  viewId?: string | undefined;
  isActive?: boolean | undefined;
  meta?: any;
  type?: string;
  subType?: string;
}

interface IProps {
  menus: IMenu[];
  activeType: string;
  projectId: string;
  aoiId: string;
  viewId: string;
  compareViewId?: string;
  issueId?: string;
}

class MapViewHeader extends React.Component<IProps> {
  private renderSubtypeMenu(): React.ReactNode {
    const { menus, activeType, projectId, aoiId, compareViewId }: IProps =
      this.props;

    if (
      activeType !== 'map' &&
      activeType !== 'exterior_360' &&
      activeType !== 'interior_360'
    ) {
      return;
    }

    return menus
      .filter((m) => m.subType !== 'design') // don't show design header subtype
      .map((menu, index) => {
        let search = menu.meta
          ? Object.keys(menu.meta).reduce(
              (searchAcc, metaKey) =>
                `${searchAcc}&${metaKey}=${menu.meta && menu.meta[metaKey]}`,
              ''
            )
          : undefined;

        // this is done to retain the query string while switching the tabs in the views page
        const queryString = {
          compareViewId,
        };

        const qs = objectListToQueryString(queryString, false);

        if (qs && qs.trim() !== '') {
          let seperator = '?';

          if (search) {
            seperator = `${search}&`;
          }

          search = `${seperator}${qs}`;
        }

        if (activeType === 'map' && menu.type === 'map' && menu.viewId) {
          return (
            <Link
              className={`${menu.subType}`}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              to={{
                pathname: viewUrl(projectId, aoiId, menu.viewId),
                search,
              }}
            >
              <MenuItem {...menu} />
            </Link>
          );
        }

        if (
          activeType === 'exterior_360' &&
          menu.type === 'exterior_360' &&
          menu.viewId
        ) {
          return (
            <Link
              className={`${menu.subType}`}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              to={{
                pathname: viewUrl(projectId, aoiId, menu.viewId),
                search,
              }}
            >
              <MenuItem {...menu} />
            </Link>
          );
        }

        if (
          activeType === 'interior_360' &&
          menu.type === 'interior_360' &&
          menu.viewId
        ) {
          return (
            <Link
              className={`${menu.subType}`}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              to={{
                pathname: viewUrl(projectId, aoiId, menu.viewId),
                search,
              }}
            >
              <MenuItem {...menu} />
            </Link>
          );
        }

        return null;
      });
  }

  public render(): React.ReactNode {
    const { viewId, projectId, aoiId }: IProps = this.props;

    return (
      <div className={style.container}>
        <AoiDropDown projectId={projectId} aoiId={aoiId} />
        <TypeMenu viewId={viewId} aoiId={aoiId} />
        {this.renderSubtypeMenu()}
      </div>
    );
  }
}

export default MapViewHeader;
