import moment from 'moment';
import * as React from 'react';
import { Switch, Table, Typography } from 'antd';
import { Redirect } from 'react-router-dom';
import ModalNotification from '../ModalNotification/ModalNotification';
import styles from './index.module.scss';
import { AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE } from '../../constants';
import { GenericObjectType } from '../../shapes/app';
import { sortByDate } from '../../utils/helpers';
import { setCertifiedOnly, showCertifiedOnly } from '../../utils/localStorage';
import { DiagnosticsProps, DiagnosticsState } from './index.types';

const { Title } = Typography;

class Diagnostics extends React.Component<DiagnosticsProps, DiagnosticsState> {
  public constructor(props: DiagnosticsProps) {
    super(props);
    const { match } = props;
    const { aoiId } = match.params;

    this.state = {
      redirect: false,
      viewCertifiedOnly: showCertifiedOnly(aoiId),
    };
  }

  public componentDidMount(): void {
    const { match, fetchMissions } = this.props;
    const { aoiId, projectId } = match.params;

    fetchMissions(projectId, aoiId);
  }

  private handleModalClose = () => {
    this.setState({
      redirect: true,
    });
  };

  private handleRowClick = (record: GenericObjectType) => {
    const { history, match } = this.props;
    const { projectId, aoiId } = match.params;

    if (!history) {
      return;
    }

    history.push(
      `/project/${projectId}/aoi/${aoiId}/missions/${record.id}/diagnostics`
    );
  };

  public render(): React.ReactNode {
    const { match, missions, loading, error } = this.props;
    const { redirect, viewCertifiedOnly } = this.state;
    const filteredMissions = sortByDate(missions || [], 'scheduledAt');

    const { projectId, aoiId } = match.params;

    const tableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name: string) => name,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value: string, list: any) => {
          if (list.finalized) {
            return 'FINALIZED';
          }

          return value ? value.toUpperCase() : null;
        },
      },
      {
        title: 'Flight Date',
        dataIndex: 'scheduledAt',
        key: 'scheduledAt',
        render: (scheduledAt: any) => moment(scheduledAt).format('ll'),
      },
    ];

    if (error) {
      return (
        <ModalNotification
          notificationTitle="Error"
          shownotificationModal
          handleModalClose={this.handleModalClose}
          error={error}
        />
      );
    }

    if (redirect) {
      return <Redirect to={`/project/${projectId}`} />;
    }

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <div className={styles.titleInnerWrapper}>
              <Title level={2}>Diagnostics</Title>
            </div>
          </div>
          <div className={styles.certifiedViewSwitchContainer}>
            <span className={styles.switchText}>
              <strong>SHOW ONLY CERTIFIED VIEWS</strong>
            </span>
            <Switch
              defaultChecked={viewCertifiedOnly}
              onChange={(val: boolean) => setCertifiedOnly(aoiId, val)}
            />
          </div>
        </div>
        <Table
          locale={{ emptyText: 'No Mission generated yet.' }}
          onRow={(record: GenericObjectType) => {
            return {
              onClick: () => {
                this.handleRowClick(record);
              },
            };
          }}
          rowKey={(record: any) => record.id}
          columns={tableColumns}
          dataSource={filteredMissions}
          loading={loading}
          pagination={{
            defaultPageSize: AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE,
          }}
        />
      </div>
    );
  }
}

export default Diagnostics;
