import autobind from 'autobind-decorator';
import classnames from 'classnames';
import memily from 'memily';
import * as React from 'react';
import { Document, Page } from 'react-pdf';

import {
  CloseOutlined,
  DownloadOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from '@ant-design/icons';

import style from './PDFViewer.module.scss';

interface IProps {
  file: string;
  name?: string;
  onClose?: () => void;
  position?: 'fixed' | 'absolute';
  onFullScreen?: () => void;
  isFullScreen?: boolean;
}

interface IState {
  numPages: number;
}

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

function getFile(file: any): { url: any; withCredentials: boolean } {
  return {
    url: file,
    withCredentials: true,
  };
}

const getFileMemoized = memily(getFile);

class PDFViewer extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      numPages: 0,
    };
  }

  @autobind
  private renderPages(): any[] {
    const pages = [];
    const { numPages } = this.state;

    for (let i = 0; i < numPages; i += 1) {
      pages.push(<Page key={i + 1} pageNumber={i + 1} />);
    }

    return pages;
  }

  @autobind
  private handlePDFLoaded(pdf: any): void {
    this.setState({
      numPages: pdf.numPages,
    });
  }

  private handleFullScreenToggle = (): void => {
    const { onFullScreen } = this.props;

    if (!onFullScreen) {
      return;
    }

    onFullScreen();
  };

  private RenderFullScreenBtn = (exit: boolean = false): JSX.Element => {
    return exit ? (
      <FullscreenOutlined
        onClick={() => {
          this.handleFullScreenToggle();
        }}
      />
    ) : (
      <FullscreenExitOutlined
        onClick={() => {
          this.handleFullScreenToggle();
        }}
      />
    );
  };

  public render(): React.ReactNode {
    const { onClose, file, name, position, isFullScreen } = this.props;

    return (
      <div className={classnames(style.container, style[position || `fixed`])}>
        <div className={style.header}>
          <div className={style.spacer} />
          <div className={style.right}>
            <a
              download={name}
              target="_blank"
              href={file}
              rel="noopener noreferrer"
            >
              <DownloadOutlined />
            </a>

            {typeof isFullScreen !== 'undefined'
              ? isFullScreen
                ? this.RenderFullScreenBtn()
                : this.RenderFullScreenBtn(true)
              : null}

            {onClose && <CloseOutlined onClick={onClose} />}
          </div>
        </div>
        <div className={style.content}>
          <Document
            options={options}
            file={getFileMemoized(file)}
            onLoadSuccess={this.handlePDFLoaded}
            className={classnames(
              style.reactPdfContainer,
              style[position || `fixed`]
            )}
          >
            {this.renderPages()}
          </Document>
        </div>
      </div>
    );
  }
}

export default PDFViewer;
