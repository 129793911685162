import { HIDE_SNACKBAR, SHOW_SNACKBAR } from '../actions';
import {
  SnackbarActionsShowSnackbarTypes,
  SnackbarReducersStateSnackbarsTypeTypes,
  SnackbarReducersStateSnackbarsTypes,
  SnackbarActionsActionShowSnackbarTypes,
  SnackbarActionsActionHideSnackbarTypes,
} from '../../shapes/snackbar';

const showSnackbar = (
  title: undefined | string,
  body: JSX.Element | string,
  type: SnackbarReducersStateSnackbarsTypeTypes,
  isModal: boolean
): SnackbarActionsShowSnackbarTypes => {
  return {
    type: SHOW_SNACKBAR,
    payload: {
      timestamp: Date.now(),
      title,
      body,
      type,
      isModal,
    },
  };
};

export const actionHideSnackbar = (
  payload: SnackbarReducersStateSnackbarsTypes
): SnackbarActionsActionHideSnackbarTypes => ({
  type: HIDE_SNACKBAR,
  payload,
});

export const actionShowSnackbar: SnackbarActionsActionShowSnackbarTypes =
  ({ title, body, type, timeout = 10000, isModal = true }): any =>
  (dispatch: any, getState: any): void => {
    const snackbarAction = showSnackbar(title, body, type, isModal);

    dispatch(showSnackbar(title, body, type, isModal));

    if (!isModal && typeof timeout !== 'undefined' && timeout !== null) {
      setTimeout(
        () => dispatch(actionHideSnackbar(snackbarAction.payload)),
        timeout
      );
    }
  };
