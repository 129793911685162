import autobind from 'autobind-decorator';
import * as React from 'react';
import * as ControlBox from '../ControlBox/ControlBox';
import PDFViewer from '../PDFViewer/PDFViewer';
import style from './ReportsControlBox.module.scss';
import { APP_BASE_URL } from '../../constants/urls';
import { ViewControlsV2TypeTypes } from '../ViewControlsV2/index.types';
import { undefinedOrNull } from '../../utils/functs';

interface IProps {
  itemType?: ViewControlsV2TypeTypes;
  onClose?: (itemType: ViewControlsV2TypeTypes) => void;
  reports: any[];
}

interface IState {
  selectedReport?: any;
}

interface IReportItem {
  name: string;
  id: string;
  mimeType: string;
  onClick: any;
}

function ReportItem({ name, id, mimeType, onClick }: IReportItem): JSX.Element {
  const url = `${APP_BASE_URL}/capi/api/v1/reports/${id}/artifact`;
  const handleClick =
    mimeType === 'application/pdf'
      ? (e: any) => {
          e.preventDefault();
          onClick(url);
        }
      : () => {
          /**/
        };

  return (
    <a
      onClick={handleClick}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={style.report_item}>
        <i
          className={
            mimeType === 'application/pdf'
              ? 'fa fa-file-pdf-o'
              : 'fa fa-download'
          }
        />
        &nbsp;
        {name}
      </div>
    </a>
  );
}

class ReportsControlBox extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      selectedReport: undefined,
    };
  }

  @autobind
  public renderHeader(): JSX.Element {
    const { onClose, itemType } = this.props;

    return (
      <React.Fragment>
        <ControlBox.Title title="Reports" />
        <ControlBox.Icon
          onClick={() => {
            if (!onClose || undefinedOrNull(itemType)) {
              return;
            }

            onClose(itemType);
          }}
          name="close"
        />
      </React.Fragment>
    );
  }

  @autobind
  private handleClick(report?: any, url?: string): void {
    if (!report) {
      this.setState({
        selectedReport: undefined,
      });

      return;
    }

    this.setState({
      selectedReport: {
        ...report,
        url,
      },
    });
  }

  private renderReportViewer = (report: any) => {
    if (report.mimeType === 'application/pdf') {
      return (
        <PDFViewer
          onClose={() => this.handleClick()}
          file={report.url}
          name={report.name}
        />
      );
    }

    return null;
  };

  public render(): React.ReactNode {
    const { reports } = this.props;
    const { selectedReport } = this.state;

    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={this.renderHeader}
      >
        {reports.map((report) => (
          <ReportItem
            key={report.id}
            onClick={this.handleClick.bind(this, report)}
            {...report}
          />
        ))}
        {selectedReport && this.renderReportViewer(selectedReport)}
      </ControlBox.Wrapper>
    );
  }
}

export default ReportsControlBox;
