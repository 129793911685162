// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoControlBox_container__pHYmC{width:auto;height:50vh;overflow-y:scroll}.InfoControlBox_container__pHYmC table{width:100%}.InfoControlBox_container__pHYmC table tr{padding-left:10px;padding-right:10px;border-bottom:1px solid #4f4f4f}.InfoControlBox_container__pHYmC table tr:last-child{border-bottom:none}.InfoControlBox_container__pHYmC table td{width:50%}.InfoControlBox_infoName__9RPt7{padding:10px;opacity:.85}.InfoControlBox_infoValue__qlLno{padding:10px;opacity:.85;text-align:right}.InfoControlBox_enabled__4fpkq{opacity:1}", "",{"version":3,"sources":["webpack://./src/components/View/ViewControls/InfoControlBox/index.module.scss"],"names":[],"mappings":"AAEA,iCACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,uCACE,UAAA,CAGF,0CACE,iBAAA,CACA,kBAAA,CACA,+BAAA,CAGF,qDACE,kBAAA,CAGF,0CACE,SAAA,CAGF,gCACE,YAAA,CACA,WAAA,CAGF,iCACE,YAAA,CACA,WAAA,CACA,gBAAA,CAGF,+BACE,SAAA","sourcesContent":["@import '../../../../styles/scss/base/base';\n\n.container {\n  width: auto;\n  height: 50vh;\n  overflow-y: scroll;\n}\n\n.container table {\n  width: 100%;\n}\n\n.container table tr {\n  padding-left: 10px;\n  padding-right: 10px;\n  border-bottom: 1px solid #4f4f4f;\n}\n\n.container table tr:last-child {\n  border-bottom: none;\n}\n\n.container table td {\n  width: 50%;\n}\n\n.infoName {\n  padding: 10px;\n  opacity: 0.85;\n}\n\n.infoValue {\n  padding: 10px;\n  opacity: 0.85;\n  text-align: right;\n}\n\n.enabled {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "InfoControlBox_container__pHYmC",
	"infoName": "InfoControlBox_infoName__9RPt7",
	"infoValue": "InfoControlBox_infoValue__qlLno",
	"enabled": "InfoControlBox_enabled__4fpkq"
};
export default ___CSS_LOADER_EXPORT___;
