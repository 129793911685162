import * as React from 'react';
import classnames from 'classnames';
import { Prompt } from 'react-router';
import OLView from 'ol/View';
import { fromLonLat } from 'ol/proj';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Tabs } from 'antd';

import ElevationDifferenceAPIs from '../../../api/elevationDifference';
import { resetBodyOverFlow, setBodyOverflow } from '../../../utils/window';
import { appFormatDate, formatDateTime } from '../../../utils/date';
import { Button } from '../../Button';
import styles from './index.module.scss';
import {
  ElevationDifference,
  CreateElevationDifferenceRequest,
} from '../../../api/elevationDifference.types';
import { editElevationDifferenceUrl } from '../../../routes/urls';
import {
  ElevationDifferenceCrudPropsType,
  ElevationDifferenceStateType,
  PopupTypes,
  ContentType,
  ViewType,
  ViewSource,
} from './index.types';
import ViewsV2Apis from '../../../api/viewsV2';
import { log } from '../../../utils/log';
import { MapGeneratedStatus } from '../../MapGeneratedStatus';
import MapView from '../../View/MapView';
import ElevationDifferenceView from '../../View/SplitView/ElevationDifferenceView';
import Dropdown, { Option } from '../../DropDown/DropDown';

const MAX_REFRESH_ERROR_RETRIES = 5;
const AUTO_REFRESH_INTERVAL_MS = 5000;

const { TabPane } = Tabs;
const viewsV2Apis = new ViewsV2Apis();
const elevationDifferenceAPIs = new ElevationDifferenceAPIs();
const SelectOption = Select.Option;

class ElevationDifferenceCrud extends React.Component<
  ElevationDifferenceCrudPropsType,
  ElevationDifferenceStateType
> {
  private intervalTimer: any = null;

  private errorCount: number = 0;

  public constructor(props: ElevationDifferenceCrudPropsType) {
    super(props);

    this.state = {
      popupOpen: {
        confirmCreate: false,
        confirmProcess: false,
        publish: false,
        unpublish: false,
      },
      loading: false,
      isMapFullScreen: false,
      sourceViewTypeToDisplay: 'elevation',
      targetViewTypeToDisplay: 'elevation',
    };
  }

  public componentDidMount() {
    const { elevationDifference } = this.props;

    this.fetchViewList();

    // start auto-refresh on component load
    if (elevationDifference) {
      this.fetchViewAssociatedWithElevationDifference(elevationDifference);

      if (
        elevationDifference.status === 'started' ||
        elevationDifference.status === 'pending' ||
        elevationDifference.status === 'starting'
      ) {
        if (this.intervalTimer === null) {
          this.intervalTimer = setInterval(
            () => this.fetchLatestElevationDifference(elevationDifference.id),
            AUTO_REFRESH_INTERVAL_MS
          );
        }
      }
    }
  }

  public UNSAFE_componentWillReceiveProps(
    newProps: ElevationDifferenceCrudPropsType
  ) {
    const { elevationDifference: newElevationDifference } = newProps;
    const { elevationDifference: oldElevationDifference } = this.props;

    // start auto-refresh when processing starts
    if (newElevationDifference) {
      // fetching view data when elevationDifference obtained for the first time
      if (!oldElevationDifference) {
        this.fetchViewAssociatedWithElevationDifference(newElevationDifference);
      } else if (
        newElevationDifference.viewId &&
        !oldElevationDifference.viewId
      ) {
        this.fetchViewAssociatedWithElevationDifference(newElevationDifference);
      }

      if (
        newElevationDifference.status === 'started' ||
        newElevationDifference.status === 'pending' ||
        newElevationDifference.status === 'starting'
      ) {
        if (this.intervalTimer === null) {
          this.intervalTimer = setInterval(
            () =>
              this.fetchLatestElevationDifference(newElevationDifference.id),
            AUTO_REFRESH_INTERVAL_MS
          );
        }
      }

      if (newElevationDifference.status === 'completed') {
        if (this.intervalTimer !== null) {
          clearInterval(this.intervalTimer);
        }
      }
    }
  }

  public componentDidUpdate() {
    const { loading } = this.state;

    if (loading) {
      // preventing navigation away from the page while APIs are pending
      window.onbeforeunload = () => {
        return 'Are you sure you want to navigate away?';
      };
    } else {
      // allowing navigation away from the page
      window.onbeforeunload = () => {
        return null;
      };
    }
  }

  public componentWillUnmount() {
    if (this.intervalTimer) {
      // clearing auto-refresh on unmount
      clearInterval(this.intervalTimer);
    }
  }

  private fetchLatestElevationDifference = (elevationDifferenceId: string) => {
    const { updateElevationDifference, match, showSnackBar } = this.props;
    const { projectId, aoiId } = match.params;

    if (elevationDifferenceId) {
      elevationDifferenceAPIs
        .getElevationDifferenceById(projectId, aoiId, elevationDifferenceId)
        .then((res) => {
          if (res.error) {
            if (this.errorCount < MAX_REFRESH_ERROR_RETRIES) {
              this.errorCount += 1;
              console.error(
                `[${this.errorCount}/${MAX_REFRESH_ERROR_RETRIES}] Error while fetching elevation difference data.`,
                res.error
              );
            } else {
              clearInterval(this.intervalTimer);
              showSnackBar({
                body: res.error,
                type: 'error',
              });
            }

            // preventing state update when API errors out
            return;
          }

          return updateElevationDifference(res.data);
        });
    }
  };

  private fetchViewList = () => {
    const { match } = this.props;

    const { projectId, aoiId } = match.params;

    viewsV2Apis
      .getViewList(projectId, aoiId)
      .then((res) => {
        const { error, data } = res;

        if (error) {
          log.error(error, 'ElevationDifferenceCrud.fetchViewList');

          return;
        }

        this.setState({
          viewsList: data,
        });
      })
      .catch((error) => {
        log.error(error, 'ElevationDifferenceCrud.fetchViewList');
      });
  };

  public fetchViewAssociatedWithElevationDifference = (
    elevationDifference: ElevationDifference
  ) => {
    const { match } = this.props;
    const { projectId, aoiId } = match.params;

    if (elevationDifference && elevationDifference.viewId) {
      viewsV2Apis
        .getView(projectId, aoiId, elevationDifference.viewId)
        .then((res) => {
          const { error, data } = res;

          if (error) {
            log.error(error, 'ElevationDifferenceCrud.fetchViewList');

            return;
          }

          this.setState(
            {
              elevationDifferenceView: data,
            },
            () => {
              const { elevationDifferenceView, olView } = this.state;

              if (!olView && elevationDifferenceView) {
                this.setState({
                  olView: this.getOLViewForContext(
                    elevationDifferenceView as any
                  ),
                });
              }
            }
          );
        });
    }
  };

  public isPublished = () => {
    // placeholder for published status
    const { elevationDifference } = this.props;

    if (
      elevationDifference &&
      elevationDifference.requestStatus === 'published'
    ) {
      return true;
    }

    return false;
  };

  public isInputDisabled = () => {
    const { elevationDifference } = this.props;

    if (elevationDifference) {
      return true;
    }

    return false;
  };

  // ----- Function to handle state change -------------------------------------------------

  public handleShowPopup = (popupType: PopupTypes) => {
    const { popupOpen } = this.state;

    popupOpen[popupType] = true;

    this.setState({
      popupOpen,
    });
  };

  public handlePopupCloseOnCancel = (popupType: PopupTypes) => {
    const { popupOpen, loading } = this.state;

    if (loading) {
      // prevent pop-up close when APIs are running
      return;
    }

    popupOpen[popupType] = false;

    this.setState({
      popupOpen,
    });
  };

  public handleElevationDifferenceCreate = () => {
    const {
      form,
      showSnackBar,
      elevationDifference: uploadData,
      actionType,
    } = this.props;

    if (uploadData || actionType === 'edit') {
      return showSnackBar({
        body: 'The Elevation Difference has already been created.',
        type: 'error',
      });
    }

    form.validateFields(['sourceDEM', 'targetDEM'], (err: any, values: any) => {
      if (err) {
        return;
      }

      const { sourceDEM, targetDEM } = values;

      if (sourceDEM === null || sourceDEM.length === 0) {
        return showSnackBar({
          body: 'Reference DEM must be specified.',
          type: 'error',
        });
      }

      if (targetDEM === null || targetDEM.length === 0) {
        return showSnackBar({
          body: 'Target DEM must be specified.',
          type: 'error',
        });
      }

      if (sourceDEM === targetDEM) {
        return showSnackBar({
          body: 'Reference and Target DEM should not be the same.',
          type: 'error',
        });
      }

      this.setState(
        {
          loading: true,
        },
        () => {
          return this.createNewElevationDifference(sourceDEM, targetDEM);
        }
      );
    });
  };

  public handleElevationDifferenceProcess = () => {
    const { form, showSnackBar, elevationDifference } = this.props;

    if (!elevationDifference) {
      return showSnackBar({
        body: 'An Elevation Difference object must be created, before it can be processed.',
        type: 'error',
      });
    }

    form.validateFields(['sourceDEM', 'targetDEM'], (err: any, values: any) => {
      if (err) {
        return;
      }

      const { sourceDEM, targetDEM } = values;

      if (sourceDEM === null || sourceDEM.length === 0) {
        return showSnackBar({
          body: 'Reference DEM must be specified.',
          type: 'error',
        });
      }

      if (targetDEM === null || targetDEM.length === 0) {
        return showSnackBar({
          body: 'Target DEM must be specified.',
          type: 'error',
        });
      }

      if (sourceDEM === targetDEM) {
        return showSnackBar({
          body: 'Reference and Target DEM should not be the same.',
          type: 'error',
        });
      }

      this.setState(
        {
          loading: true,
        },
        () => {
          return this.processElevationDifference(elevationDifference.id);
        }
      );
    });
  };

  public createNewElevationDifference = (
    sourceDEM: string,
    targetDEM: string
  ) => {
    const { showSnackBar, match, history } = this.props;
    const { viewsList } = this.state;
    const { projectId, aoiId } = match.params;

    const sourceView = viewsList?.find((v) => v.demId === sourceDEM);
    const targetView = viewsList?.find((v) => v.demId === targetDEM);

    if (!sourceView || !targetView) {
      return showSnackBar({
        body: 'There was an error creating the Elevation Difference, with the specified reference and Target DEMs',
        type: 'error',
      });
    }

    const createElevationDifferenceRequest: CreateElevationDifferenceRequest = {
      sourceDemId: sourceDEM,
      targetDemId: targetDEM,
      sourceDate: formatDateTime(sourceView.date),
      targetDate: formatDateTime(targetView.date),
    };

    return elevationDifferenceAPIs
      .createElevationDifference(
        projectId,
        aoiId,
        createElevationDifferenceRequest
      )
      .then((res) => {
        if (res.error) {
          const { popupOpen } = this.state;

          popupOpen.confirmCreate = false;
          this.setState({ loading: false, popupOpen });

          return showSnackBar({
            body: res.error,
            type: 'error',
          });
        }

        const elevationDifferenceId = res.data.id;

        // trigger processing on Elevation Difference creation
        return this.processElevationDifference(elevationDifferenceId).then(
          () => {
            this.setState({
              loading: false,
            });

            return history.push(
              editElevationDifferenceUrl(
                projectId,
                aoiId,
                elevationDifferenceId
              )
            );
          }
        );
      });
  };

  public processElevationDifference = (
    elevationDifferenceId: string
  ): Promise<void> => {
    const { showSnackBar, match, updateElevationDifference } = this.props;
    const { projectId, aoiId } = match.params;

    return elevationDifferenceAPIs
      .processElevationDifference(projectId, aoiId, elevationDifferenceId)
      .then((res) => {
        this.setState({ loading: false });
        this.handlePopupCloseOnCancel('confirmProcess');

        if (res.error) {
          return showSnackBar({
            body: res.error,
            type: 'error',
          });
        }

        updateElevationDifference(res.data);
      });
  };

  public handlePublish = () => {
    const {
      form,
      showSnackBar,
      match,
      elevationDifference,
      updateElevationDifference,
    } = this.props;
    const { projectId, aoiId } = match.params;

    form.validateFields(['sourceDEM', 'targetDEM'], (err: any, values: any) => {
      if (err) {
        return;
      }

      if (!elevationDifference) {
        // this case should not happen, as the publish button is displayed only if processing has finished
        return;
      }

      const { sourceDEM, targetDEM } = values;

      if (sourceDEM === null || sourceDEM.length === 0) {
        return showSnackBar({
          body: 'Reference DEM must be specified.',
          type: 'error',
        });
      }

      if (targetDEM === null || targetDEM.length === 0) {
        return showSnackBar({
          body: 'Target DEM must be specified.',
          type: 'error',
        });
      }

      if (sourceDEM === targetDEM) {
        return showSnackBar({
          body: 'Reference and Target DEM should not be the same.',
          type: 'error',
        });
      }

      this.setState(
        {
          loading: true,
        },
        () => {
          return elevationDifferenceAPIs
            .publishElevationDifference(
              projectId,
              aoiId,
              elevationDifference.id
            )
            .then((res) => {
              this.setState({ loading: false });

              if (res.error) {
                return showSnackBar({
                  body: res.error,
                  type: 'error',
                });
              }

              updateElevationDifference(res.data);

              this.handlePopupCloseOnCancel('publish');
            });
        }
      );
    });
  };

  public handleUnpublish = () => {
    const {
      showSnackBar,
      match,
      elevationDifference,
      updateElevationDifference,
    } = this.props;
    const { projectId, aoiId } = match.params;

    if (!elevationDifference) {
      // this case should not happen, as the unpublish button is displayed only if processing has finished
      return;
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        return elevationDifferenceAPIs
          .unPublishElevationDifference(
            projectId,
            aoiId,
            elevationDifference.id
          )
          .then((res) => {
            this.setState({ loading: false });

            if (res.error) {
              return showSnackBar({
                body: res.error,
                type: 'error',
              });
            }

            updateElevationDifference(res.data);

            this.handlePopupCloseOnCancel('unpublish');
          });
      }
    );
  };

  private handleMapFullScreenToggle = (): void => {
    this.setState(
      ({ isMapFullScreen }) => {
        return {
          isMapFullScreen: !isMapFullScreen,
        };
      },
      () => {
        const { isMapFullScreen } = this.state;

        if (isMapFullScreen) {
          setBodyOverflow('y');

          return;
        }

        resetBodyOverFlow('y');
      }
    );
  };

  public getViewSourceOptions = (): Option[] => {
    return [
      {
        label: 'Reference Views',
        value: 'source',
      },
      {
        label: 'Target Views',
        value: 'target',
      },
      {
        label: 'Output View',
        value: 'output',
      },
    ];
  };

  public getViewTypeOptions = (): Option[] => {
    return [
      {
        label: 'Aerial View',
        value: 'aerial',
      },
      {
        label: 'Elevation View',
        value: 'elevation',
      },
    ];
  };

  public handleDropDownValueChanged = (dropdownType: ViewSource) => {
    if (dropdownType === 'source') {
      return (args: Option) => {
        this.setState({
          sourceViewTypeToDisplay: args.value as ViewType,
        });
      };
    }

    if (dropdownType === 'target') {
      return (args: Option) => {
        this.setState({
          targetViewTypeToDisplay: args.value as ViewType,
        });
      };
    }

    return () => {};
  };

  public getOLViewForContext = (view?: any) => {
    if (!view) {
      return undefined;
    }

    const _olView = new OLView();

    if (view?.zoomDefault) {
      _olView.setZoom(view?.zoomDefault);
    }

    _olView.setCenter(
      fromLonLat([view.centerLongitude || 0, view.centerLatitude || 0])
    );

    return _olView;
  };

  // ------ Functions to handle if content should be displayed or enabled --------------------

  public shouldDisplayContent = (contentType: ContentType) => {
    // placeholder for logic to control if certain content should be displayed
    const { elevationDifference, actionType, form } = this.props;

    if (contentType == null) {
      return false;
    }

    if (contentType === 'sourceDEM') {
      return true;
    }

    if (contentType === 'targetDEM') {
      if (form.getFieldValue('sourceDEM')) {
        return true;
      }
    }

    if (contentType === 'createElevationDifference') {
      if (!elevationDifference && actionType === 'new') {
        if (
          form.getFieldValue('sourceDEM') &&
          form.getFieldValue('targetDEM')
        ) {
          return true;
        }
      }
    }

    if (contentType === 'processElevationDifference') {
      if (
        elevationDifference &&
        actionType === 'edit' &&
        (!elevationDifference.status || elevationDifference.status === 'error')
      ) {
        if (
          form.getFieldValue('sourceDEM') &&
          form.getFieldValue('targetDEM')
        ) {
          return true;
        }
      }
    }

    if (contentType === 'publish') {
      if (
        elevationDifference &&
        elevationDifference.viewId &&
        elevationDifference.status === 'completed'
      ) {
        return true;
      }
    }

    return false;
  };

  public renderInputParameters() {
    const { form, elevationDifference } = this.props;
    const { getFieldDecorator } = form;
    const { viewsList } = this.state;

    return (
      <div className={styles.inputFieldContainer}>
        {this.shouldDisplayContent('sourceDEM') ? (
          <div className={styles.inputFieldWrapper}>
            <Form.Item label="Reference Date">
              {getFieldDecorator('sourceDEM', {
                rules: [
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ],
                initialValue: elevationDifference?.sourceDemId,
              })(
                <Select
                  placeholder="Select the reference DEM by date"
                  optionFilterProp="id"
                  className={styles.inputField}
                  onChange={() => {
                    /**/
                  }}
                  defaultValue={elevationDifference?.sourceDemId}
                  disabled={this.isInputDisabled()}
                >
                  {(viewsList || [])
                    .filter(
                      (v: any) =>
                        v.type === 'map' &&
                        v.subType === 'aerial' &&
                        v.certifiedForDisplay &&
                        v.certifiedForMeasurement &&
                        v.demId
                    )
                    .map((item: any) => {
                      return (
                        <SelectOption key={item.id} value={item.demId}>
                          {appFormatDate(item.date)}
                        </SelectOption>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </div>
        ) : null}
        {this.shouldDisplayContent('targetDEM') ? (
          <div className={styles.inputFieldWrapper}>
            <Form.Item label="Target Date">
              {getFieldDecorator('targetDEM', {
                rules: [
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ],
                initialValue: elevationDifference?.targetDemId,
              })(
                <Select
                  placeholder="Select the target DEM by date"
                  optionFilterProp="id"
                  className={styles.inputField}
                  onChange={() => {
                    /**/
                  }}
                  defaultValue={elevationDifference?.targetDemId}
                  disabled={this.isInputDisabled()}
                >
                  {(viewsList || [])
                    .filter(
                      (v: any) =>
                        v.type === 'map' &&
                        v.subType === 'aerial' &&
                        v.certifiedForDisplay &&
                        v.certifiedForMeasurement &&
                        v.demId
                    )
                    .map((item: any) => {
                      return (
                        <SelectOption key={item.id} value={item.demId}>
                          {appFormatDate(item.date)}
                        </SelectOption>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </div>
        ) : null}
        {this.shouldDisplayContent('createElevationDifference') ? (
          <div className={styles.inputFieldWrapper}>
            <Button
              text="Create Elevation Difference"
              className={styles.uploadInput}
              onClick={() => this.handleShowPopup('confirmCreate')}
            />
          </div>
        ) : null}
        {this.shouldDisplayContent('processElevationDifference') ? (
          <div className={styles.inputFieldWrapper}>
            <Button
              text="Process Elevation Difference"
              className={styles.uploadInput}
              onClick={() => this.handleShowPopup('confirmProcess')}
            />
          </div>
        ) : null}
        {this.shouldDisplayContent('publish') ? (
          <div className={styles.inputFieldWrapper}>
            <Button
              text={!this.isPublished() ? 'publish' : 'un-publish'}
              className={styles.uploadInput}
              onClick={() =>
                !this.isPublished()
                  ? this.handleShowPopup('publish')
                  : this.handleShowPopup('unpublish')
              }
            />
          </div>
        ) : null}
      </div>
    );
  }

  public renderViewWithDropdown = (viewSource: ViewSource) => {
    const { elevationDifference } = this.props;
    const {
      viewsList,
      sourceViewTypeToDisplay,
      targetViewTypeToDisplay,
      isMapFullScreen,
      olView,
    } = this.state;

    if (!elevationDifference) {
      return;
    }

    const viewTypeToDisplay: ViewType =
      viewSource === 'source'
        ? sourceViewTypeToDisplay
        : targetViewTypeToDisplay;
    const demId: string =
      viewSource === 'source'
        ? elevationDifference.sourceDemId
        : elevationDifference.targetDemId;

    const viewToDisplay = viewsList?.find(
      (view) => view.subType === viewTypeToDisplay && view.demId === demId
    );

    if (viewToDisplay && olView) {
      return (
        <div
          className={classnames(styles.viewContainer, {
            [styles.fullScreen]: isMapFullScreen,
          })}
        >
          <div
            className={classnames(styles.viewSwitcher, {
              [styles.elevationView]: viewTypeToDisplay === 'elevation',
            })}
          >
            <Dropdown
              options={this.getViewTypeOptions()}
              value={viewTypeToDisplay}
              onChange={this.handleDropDownValueChanged(viewSource)}
            />
          </div>
          <div className={styles.postGeneratedContainer}>
            <div
              className={classnames(styles.viewWrapper, {
                [styles.fullScreen]: isMapFullScreen,
              })}
            >
              <MapView
                view={viewToDisplay as any}
                olView={olView}
                onConfigCallbackChange={() => {}}
                onEvent={() => {}}
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  public renderViewPreview() {
    const { elevationDifference } = this.props;
    const { isMapFullScreen, elevationDifferenceView, olView } = this.state;

    if (
      elevationDifference &&
      elevationDifference.viewId &&
      elevationDifference.status === 'completed' &&
      elevationDifferenceView &&
      olView
    ) {
      return (
        <div
          className={classnames(styles.viewContainer, {
            [styles.fullScreen]: isMapFullScreen,
          })}
        >
          <Tabs defaultActiveKey="output">
            <TabPane tab="Reference" key="source">
              {this.renderViewWithDropdown('source')}
            </TabPane>
            <TabPane tab="Target" key="target">
              {this.renderViewWithDropdown('target')}
            </TabPane>
            <TabPane tab="Differential Map" key="output">
              <div className={styles.viewContainer}>
                <div className={styles.postGeneratedContainer}>
                  <div className={styles.viewWrapper}>
                    {/* <View
                      viewId={elevationDifferenceView.id}
                      projectId={elevationDifferenceView.projectId}
                      aoiId={elevationDifferenceView.aoiId}
                      onEvent={() => {}}
                      view={elevationDifferenceView}
                      history={{} as any}
                    /> */}
                    <ElevationDifferenceView
                      view={elevationDifferenceView as any}
                      olView={olView}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      );
    }

    return (
      <div className={styles.viewContainer}>
        <div className={styles.postGeneratedContainer}>
          {MapGeneratedStatus({
            data: elevationDifference || null,
            type: 'elevationDifference',
            generating: elevationDifference
              ? elevationDifference.status === 'started'
              : false,
            error: elevationDifference
              ? elevationDifference.status === 'error'
              : false,
          })}
        </div>
      </div>
    );
  }

  public renderPopups() {
    // placeholder to display pop-ups
    const { popupOpen, loading } = this.state;

    return (
      <React.Fragment>
        {popupOpen.confirmCreate ? (
          <Modal
            title="Confirm"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onCancel={() => this.handlePopupCloseOnCancel('confirmCreate')}
            className={styles.popupContainer}
          >
            <p className={styles.headertext}>
              Are you sure you want to create an Elevation Difference session
              with the specified reference and target dates?
            </p>

            <div className={styles.popupModalDiv}>
              <Button
                onClick={() => this.handleElevationDifferenceCreate()}
                loading={loading}
                text="Confirm"
              />
            </div>
          </Modal>
        ) : null}
        {popupOpen.confirmProcess ? (
          <Modal
            title="Confirm"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onCancel={() => this.handlePopupCloseOnCancel('confirmProcess')}
            className={styles.popupContainer}
          >
            <p className={styles.headertext}>
              Are you sure you want to process an Elevation Difference session
              with the specified reference and target dates? It will be
              calculated as{' '}
              <b>
                Elevation Difference = Target Date Elevation - Reference Date
                Elevation
              </b>
              .
            </p>
            <p>
              This will take some time to finish, and you will receive an email
              on completion.
            </p>

            <div className={styles.popupModalDiv}>
              <Button
                onClick={() => this.handleElevationDifferenceProcess()}
                loading={loading}
                text="Confirm"
              />
            </div>
          </Modal>
        ) : null}
        {popupOpen.publish ? (
          <Modal
            title="Publish"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onCancel={() => this.handlePopupCloseOnCancel('publish')}
            className={styles.popupContainer}
          >
            <p className={styles.headertext}>
              Are you sure you want to publish this Elevation Difference? It
              will be made available for public display and cannot be edited
              until it is un-published.
            </p>

            <div className={styles.popupModalDiv}>
              <Button
                onClick={() => this.handlePublish()}
                text="Confirm"
                loading={loading}
              />
            </div>
          </Modal>
        ) : null}
        {popupOpen.unpublish ? (
          <Modal
            title="Un-Publish"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onCancel={() => this.handlePopupCloseOnCancel('unpublish')}
            className={styles.popupContainer}
          >
            <p className={styles.headertext}>
              Are you sure you want to un-publish the Elevation Difference? It
              will remove the views from public display.
            </p>

            <div className={styles.popupModalDiv}>
              <Button
                onClick={() => this.handleUnpublish()}
                text="Confirm"
                loading={loading}
              />
            </div>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }

  public render() {
    const { loading } = this.state;

    return (
      <React.Fragment>
        <Prompt message="Are you sure you want to leave?" when={loading} />
        <div className={styles.container}>
          {this.renderInputParameters()}
          {this.renderViewPreview()}
        </div>
        {this.renderPopups()}
      </React.Fragment>
    );
  }
}

export default Form.create<ElevationDifferenceCrudPropsType>()(
  ElevationDifferenceCrud
);
