import { Step } from 'react-joyride';
import { VIEW_PATH, DRAWING_EDIT_PATH } from '../../routes/paths';
import AOIItemStyle from '../AoiItem/AoiItem.module.scss';
import DownloadButtonStyle from '../Header/DownloadButton/index.module.scss';
import FooterStyle from '../ViewFooter/ViewFooter.module.scss';
import TypeMenuStyle from '../TypeMenu/TypeMenu.module.scss';
import IssueIconButtonStyle from '../Header/IssueIconButton/index.module.scss';

const NON_MAP_FOOTER_STEPS: Step[] = [
  {
    content: 'Use the date selector pull-up to switch view dates',
    target: `.${FooterStyle.icon}`,
    placement: 'top',
  },
  {
    content: 'Tool for taking screenshot.',
    target: `.${FooterStyle.controls}`,
    placement: 'left',
  },
];

const NON_MAP_HEADER_AND_CONTROLS: Step[] = [
  {
    content: 'Use this dropdown to change view type (Maps & Non-Maps)',
    target: `.${TypeMenuStyle.container}`,
    disableBeacon: true,
  },
  {
    content:
      'Annotate and create issues to communicate and collaborate among the stakeholders',
    target: '#mark_issue',
    placement: 'left',
  },
];

export const AOI_PAGE_STEPS: Step[] = [
  {
    content: 'Click on this tile to view outputs.',
    target: `.${AOIItemStyle.imageContainer}`,
    placement: 'right',
    disableBeacon: true,
  },
  {
    content: ' Use the wrench icon to enter "Manage AoI" section',
    target: `.${AOIItemStyle.manageBtn}`,
    placement: 'right',
  },
  {
    content: 'Click here to view issues associated with this project.',
    target: `.${IssueIconButtonStyle.container}`,
  },
  {
    content: 'Download processed data and analytics from here.',
    target: `.${DownloadButtonStyle.container}`,
  },
];

const MAP_VIEW_STEPS: Step[] = [
  {
    content: 'Use this dropdown to change view type (Maps & Non-Maps)',
    target: `.${TypeMenuStyle.container}`,
    disableBeacon: true,
  },
  {
    content: 'Colorised elevation map of the site',
    target: '.elevation',
  },
  {
    content:
      'GIS analytics outputs such as Terrain Models, Planimetric Maps and Contour Maps',
    target: '.survey',
  },
  {
    content: 'Visualise maps side-by-side',
    target: '.split_view',
  },
  {
    content:
      'Use this tool to take measurements on the map, such as distance and volume',
    target: '#measure',
    placement: 'left',
  },
  {
    content:
      'Annotate and create issues to communicate and collaborate among the stakeholders',
    target: '#mark_issue',
    placement: 'left',
  },
  {
    content: 'Compare as-built to as-designed by overlaying CAD designs',
    target: '#compare',
    placement: 'left',
  },
  {
    content: 'Use the date selector pull-up to switch view dates',
    target: `.${FooterStyle.icon}`,
    placement: 'top',
  },
  {
    content: 'Turn on/off crosshair or satellite overlay, take a screenshot.',
    target: `.${FooterStyle.controls}`,
    placement: 'top',
  },
];

const PERSPECTIVE_VIEW_STEPS: Step[] = [
  ...NON_MAP_HEADER_AND_CONTROLS,
  {
    content: 'Camera location on the site map and orientation.',
    target: '#imageInsetMap',
    placement: 'right',
  },
  ...NON_MAP_FOOTER_STEPS,
];

const SITE_NAVIGATION_VIEW_STEPS: Step[] = [
  ...NON_MAP_HEADER_AND_CONTROLS,
  {
    content: 'Camera location on the site map and orientation.',
    target: '#imageInsetMap',
    placement: 'right',
  },
  {
    content:
      'Click on an arrow on the click wheel to navigate in the desired direction.',
    target: '#imageNavButtons',
    placement: 'top',
  },
  ...NON_MAP_FOOTER_STEPS,
];

const INSPECTION_VIEW_STEPS: Step[] = [
  ...NON_MAP_HEADER_AND_CONTROLS,
  {
    content: 'See camera location in 3D space as well as 2D Map',
    target: '#imageInsetMap',
    placement: 'right',
  },
  {
    content:
      'Click on an arrow on the click wheel to navigate in the desired direction.',
    target: '#imageNavButtons',
    placement: 'top',
  },
  ...NON_MAP_FOOTER_STEPS,
];

const EXT360_VIEW_STEPS: Step[] = [
  ...NON_MAP_HEADER_AND_CONTROLS,
  {
    content: 'Pano location on the site map and orientation.',
    target: '#panoInsetMap',
    placement: 'right',
  },
  ...NON_MAP_FOOTER_STEPS,
];

const INT360_VIEW_STEPS: Step[] = [
  ...NON_MAP_HEADER_AND_CONTROLS,
  {
    content: 'See pano positions in floor plan',
    target: '#panoInsetMap',
    placement: 'right',
  },
  ...NON_MAP_FOOTER_STEPS,
];

const DRAWING_EDIT_STEPS: Step[] = [
  {
    content: 'Compare as-built to as-designed by overlaying CAD designs',
    target: '#compare',
    placement: 'left',
    disableBeacon: true,
  },
];

export const getCoachmarkStepsForURLPath = (urlPath: string) => {
  switch (urlPath) {
    case '/project/:projectId':
      return AOI_PAGE_STEPS;

    case DRAWING_EDIT_PATH:
      return DRAWING_EDIT_STEPS;

    case `${VIEW_PATH}:map`:
      return MAP_VIEW_STEPS;

    case `${VIEW_PATH}:perspective`:
      return PERSPECTIVE_VIEW_STEPS;

    case `${VIEW_PATH}:inspection`:
      return INSPECTION_VIEW_STEPS;

    case `${VIEW_PATH}:site_navigation`:
      return SITE_NAVIGATION_VIEW_STEPS;

    case `${VIEW_PATH}:interior_360`:
      return INT360_VIEW_STEPS;

    case `${VIEW_PATH}:exterior_360`:
      return EXT360_VIEW_STEPS;

    default:
      return [];
  }
};
