import * as math from 'mathjs';
import { xRotation, yRotation, zRotation, normalize } from './math';
import { kdTree as KdTree } from './kdTree.js';
import ImageMetadata from '../../api/images.types';

export class Cameras {
    images: Readonly<ImageMetadata[]>;
    latRef: number;
    longRef: number;
    elevRef: number;
    latLongScale: number[];
    maxDistance: number;
    count: number;
    xyzList: Record<string,Object>[];
    x: number[];
    y: number[];
    z: number[];
    negX: number[];
    negY: number[];
    negZ: number[];
    tree: KdTree;
    constructor(imageMetadata: Readonly<ImageMetadata[]>, searchRadius:number) {
        this.images = imageMetadata;
        this.latRef = parseFloat(this.images[0].latitude);
        this.longRef = parseFloat(this.images[0].longitude);
        this.elevRef = this.images[0].elevation;
        this.latLongScale = this.latToScale(this.latRef);
        this.maxDistance = searchRadius;
        this.count = this.images.length;
        this.xyzList = [];
        
        this.x = [1, 0, 0];
        this.y = [0, 1, 0];
        this.z = [0, 0, 1];
        this.negX = [-1, 0, 0];
        this.negY = [0, -1, 0];
        this.negZ = [0, 0, -1];
      }

      latToScale(lat:number) {
        const LAT_CONV = 111132.92;
        const LON_CONV = 111412.84;
    
        lat = (lat * Math.PI) / 180;
        const latc =
          LAT_CONV - 559.82 * Math.cos(2 * lat) + 1.175 * Math.cos(4 * lat);
        const lonc = LON_CONV * Math.cos(lat) - 93.5 * Math.cos(3 * lat);
    
        return [latc, lonc];
      }  

      updateImageMetadata() {
        for (let i = 0; i < this.images.length; i++) {
          const deltaLat = parseFloat(this.images[i].latitude) - this.latRef;
          const deltaLong = parseFloat(this.images[i].longitude) - this.longRef;
    
          const yVar = deltaLat * this.latLongScale[0];
          const xVar = deltaLong * this.latLongScale[1];
          const zVar = this.images[i].elevation - this.elevRef;
          this.xyzList.push({x:xVar, y:yVar, z:zVar, guid:this.images[i].guid});
        }
      }
    
      distance(a:any, b:any) {
        const dx = a.x - b.x;
        const dy = a.y - b.y;
        const dz = a.z - b.z;
    
        return math.sqrt(dx * dx + dy * dy + dz * dz);
      }
    
      buildKdTree() {
        this.updateImageMetadata();
        this.tree = new KdTree(this.xyzList, this.distance, [
          'x',
          'y',
          'z'
        ]);
      }

      getCamerasAxes(vec:number[], roll:number, pitch:number, yaw:number) {
        const camR = yRotation(vec, roll);
        const camP = xRotation(camR, pitch);
    
        return zRotation(camP, -yaw);
      }

      getXYZForGuid(guid:string,images:Record<string,Object>[]){
        const selImage = images.find(img => guid === img.guid);
        if(selImage){
          return [selImage.x, selImage.y, selImage.z];
        }
        return [];
      }
      
      findDirections(queryCam:ImageMetadata, forceYawToNorth:boolean) {
        let gimbalPitch = 0;
    
        if (queryCam.gimbalPitch <= -88 && queryCam.gimbalPitch >= -92) {
          gimbalPitch = -90;
        }
    
        const pitch = (gimbalPitch * Math.PI) / 180;
        const roll = (queryCam.gimbalRoll * Math.PI) / 180;
        let yaw = 0;
        if (!(forceYawToNorth)) {
           yaw = (queryCam.gimbalYaw * Math.PI) / 180;
        }
        
        //get camera axes of all vectors
        const camRight = this.getCamerasAxes(this.x, roll, pitch, yaw);
        const camLeft = this.getCamerasAxes(this.negX, roll, pitch, yaw);
        const camFront = this.getCamerasAxes(this.y, roll, pitch, yaw);
        const camBack = this.getCamerasAxes(this.negY, roll, pitch, yaw);
        const camTop = this.getCamerasAxes(this.z, roll, pitch, yaw);
        const camDown = this.getCamerasAxes(this.negZ, roll, pitch, yaw);
    
        return {
          front: camFront,
          back: camBack,
          top: camTop,
          down: camDown,
          right: camRight,
          left: camLeft
        };
      }

      getSortedNeighbors(queryCam:any) {
        const neighboursList = this.tree.nearest(
          queryCam,
          this.count,
          this.maxDistance
        );
        let sortedList = [];
    
        for (let i = 0; i < neighboursList.length; i++) {
          const item = neighboursList[i];
          const newJson = item[0];
    
          newJson.distance = item[1];
          sortedList.push(newJson);
        }
    
        // NOTE: we filter out images which are too close
        // when images are too close navigation gets stuck in a loop
        sortedList = sortedList.splice(0)
          .sort((a, b) => a.distance - b.distance)
          .filter(a => a.distance > 1);
    
        return sortedList;
      }
    
      findNearest(queryCam:ImageMetadata|undefined, forceYawToNorth:boolean) {
        const neighbors = {
          front: null,
          back: null,
          top: null,
          down: null,
          right: null,
          left: null
        };
        
        if (queryCam === undefined){
          console.log("The selected image is undefined");
          return neighbors;
        }
        const deltaLatq = parseFloat(queryCam.latitude) - this.latRef;
        const deltaLongq = parseFloat(queryCam.longitude) - this.longRef;
    
        const qy = deltaLatq * this.latLongScale[0];
        const qx = deltaLongq * this.latLongScale[1];
        const qz = queryCam.elevation - this.elevRef;
    
        const sortedList = this.getSortedNeighbors({guid:queryCam.guid, x:qx, y:qy, z:qz});
        const directions = this.findDirections(queryCam, forceYawToNorth);
    
        let count = 6; //Number of directions
    
        for (let j = 0; j < sortedList.length; j++) {
          let dirList = [];
    
          if (sortedList[j].guid !== queryCam.guid) {
            const queryPt = [qx, qy, qz];
            const neighborPt = this.getXYZForGuid(sortedList[j].guid, this.xyzList);
            if(neighborPt.length>0) {
              let neighborVec = math.subtract(neighborPt, queryPt);
      
              neighborVec = normalize(neighborVec);
              const dirRight = math.dot(neighborVec, directions.right);
              const dirLeft = math.dot(neighborVec, directions.left);
              const dirFront = math.dot(neighborVec, directions.front);
              const dirBack = math.dot(neighborVec, directions.back);
              const dirTop = math.dot(neighborVec, directions.top);
              const dirDown = math.dot(neighborVec, directions.down);
      
              dirList = [
                { dir: 'right', val: dirRight },
                { dir: 'left', val: dirLeft },
                { dir: 'top', val: dirTop },
                { dir: 'down', val: dirDown },
                { dir: 'front', val: dirFront },
                { dir: 'back', val: dirBack }
              ];
              dirList = dirList.splice(0).sort((a, b) => a.val - b.val);
              //Consider the neighbor with the maximum dot product
              if (!neighbors[dirList[5].dir]) {
                neighbors[dirList[5].dir] = sortedList[j].guid;
                count = count - 1;
              }
      
              if (count === 0) {
                break;
              }
          }
        }
      }
        return neighbors;
    }
      
}