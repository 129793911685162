import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ViewPreviewManagerTypes } from './index.types';
import { ViewPreviewManager } from './index';

const mapStateToProps = (state: any): ViewPreviewManagerTypes.ReduxState => {
  return {
    viewList: state.views.data,
  };
};

const mapDispatchToProps = (
  dispatch: any
): ViewPreviewManagerTypes.DispatchProps => {
  return bindActionCreators({}, dispatch);
};

export default connect<
  ViewPreviewManagerTypes.ReduxState,
  ViewPreviewManagerTypes.DispatchProps,
  ViewPreviewManagerTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ViewPreviewManager);
