import * as React from 'react';
import { Slider } from 'antd';
import TileLayer from 'ol/layer/Tile';

import ViewsV2Apis from '../../api/viewsV2';
import OpenLayersMap from '../OpenLayersMap';
import SkeletonLoader from '../SkeletonLoader';
import { thinColorMapping } from '../../utils/helpers';
import { DynamicColorSource } from '../OpenLayersMap/DynamicColorSource';
import {
  OpenLayersMapPropsType,
  OpenLayersMapStateType,
  ElevationColorMapping,
} from './index.types';
import style from './index.module.scss';
import ElevationScale from '../ElevationScale/ElevationScale';
import { undefinedOrNull } from '../../utils/functs';

const viewV2Apis = new ViewsV2Apis();

export default class OpenLayersView extends React.PureComponent<
  OpenLayersMapPropsType,
  OpenLayersMapStateType
> {
  constructor(props: OpenLayersMapPropsType) {
    super(props);
    this.state = {
      viewData: undefined,
      source: undefined,
      elevationSliderValue: undefined,
      sourceElevationRange: undefined,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.getViewDisplayMetadata().then((data) => {
          this.setState({ ...data, loading: false });
        });
      }
    );
  }

  UNSAFE_componentWillReceiveProps({ view: newView }: OpenLayersMapPropsType) {
    const { view: oldView } = this.props;

    if (newView && newView.id !== oldView?.id) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.getViewDisplayMetadata().then((data) => {
            this.setState({ ...data, loading: false });
          });
        }
      );
    }
  }

  private async getViewDisplayMetadata() {
    // TODO: implement function to take view data, and extract the necessary info to display view using OpenLayers
    const { view, center, zoom } = this.props;

    if (view === undefined) {
      return {};
    }

    const index = await viewV2Apis
      .getViewFiles(view.id, 'index.json')
      .then((res) => {
        if (res.error) {
          console.error('Error fetching view index: ', res.error);

          return null;
        }

        return res.data;
      });

    const { widgets, layers: indexLayers } = index;
    let colors;
    let elevations;

    if (index.loader !== 'dynamic_elevation') {
      console.error(`Unsupported view loader! Loader: ${index.loader}`);

      return {};
    }

    if (!widgets) {
      console.error('Could not get widget info, from view index: ', index);

      return {};
    }

    if (!indexLayers) {
      console.error('Could not get layers info, from view index: ', index);

      return {};
    }

    const colorScaleWidget = widgets.find(
      (w: any) => w.type === 'elevation_color_scale'
    );
    const elevationSliderWidget = widgets.find(
      (w: any) => w.type === 'elevation_range_slider'
    );
    const tileLayer = indexLayers.find((l: any) => l.type === 'tiles');

    if (
      colorScaleWidget &&
      colorScaleWidget.config &&
      colorScaleWidget.config.colors
    ) {
      colors = colorScaleWidget.config.colors;
    }

    if (
      elevationSliderWidget &&
      elevationSliderWidget.config &&
      elevationSliderWidget.config.elevationRange
    ) {
      elevations = elevationSliderWidget.config.elevationRange;
    }

    if (!tileLayer || !tileLayer.descriptor) {
      console.error(
        'Could not extract tile layer from view index: ',
        indexLayers
      );

      return {};
    }

    const url = viewV2Apis.getViewFileUrl(view.id);

    const source = new DynamicColorSource({
      url,
      getColorRange: () => {
        const { elevationSliderValue } = this.state;

        if (!elevationSliderValue) {
          return { min: 0, max: 0 };
        }

        return elevationSliderValue;
      },
      colors,
      index: tileLayer.descriptor,
    });

    const layers = [
      new TileLayer({
        source,
      }),
    ];

    if (
      undefinedOrNull(view.centerLatitude) ||
      undefinedOrNull(view.centerLongitude)
    ) {
      console.error(
        `View is missing center coordinates. X: ${view.centerLongitude}, Y: ${view.centerLatitude}`
      );

      return {};
    }

    const centerCoordinates = {
      longitude: center?.longitude || view.centerLongitude,
      latitude: center?.latitude || view.centerLatitude,
    };

    if (
      undefinedOrNull(view.zoomDefault) ||
      undefinedOrNull(view.zoomMax) ||
      undefinedOrNull(view.zoomMin)
    ) {
      console.error(
        `View is missing zoom values. Default: ${view.zoomDefault}, Min: ${view.zoomMin}, Max: ${view.zoomMax}`
      );

      return {};
    }

    const zoomLevels = {
      initial: zoom || view.zoomDefault,
      max: view.zoomMax,
      min: view.zoomMin,
    };

    return {
      viewData: {
        layers,
        centerCoordinates,
        zoomLevels,
        isRawElevation: view.subType === 'elevation_difference',
      },
      elevationSliderValue: elevations
        ? {
            min: Math.round(elevations.min),
            max: Math.round(elevations.max),
          }
        : undefined,
      sourceElevationRange: elevations
        ? {
            min: Math.round(elevations.min),
            max: Math.round(elevations.max),
          }
        : undefined,
      colors,
      source,
    };
  }

  updateSource() {
    const { source } = this.state;

    if (source) {
      source.changed();
    }
  }

  async loadJson(url: string, opts = {}) {
    return fetch(url, opts).then((res) => res.json());
  }

  minChanged(minVal: number) {
    const { elevationSliderValue } = this.state;
    let min;

    if (!elevationSliderValue) {
      return;
    }

    if (!undefinedOrNull(elevationSliderValue.max)) {
      min = Math.min(elevationSliderValue.max - 0.01, minVal);

      this.setState({
        elevationSliderValue: {
          ...elevationSliderValue,
          min,
        },
      });
      this.updateSource();
    }
  }

  maxChanged(maxVal: number) {
    const { elevationSliderValue } = this.state;
    let max;

    if (!elevationSliderValue) {
      return;
    }

    if (elevationSliderValue.min) {
      max = Math.max(elevationSliderValue.min + 0.01, maxVal);

      this.setState({
        elevationSliderValue: {
          ...elevationSliderValue,
          max,
        },
      });
      this.updateSource();
    }
  }

  sliderValueChanged = (value: [number, number]) => {
    const { elevationSliderValue } = this.state;

    if (!elevationSliderValue) {
      return;
    }

    const { min, max } = elevationSliderValue;

    if (value[0] !== min) {
      this.minChanged(value[0]);
    } else if (value[1] !== max) {
      this.maxChanged(value[1]);
    }
  };

  convertIntegerToColor(colorValue: number): string {
    let hex = colorValue.toString(16);

    if (hex.length < 2) {
      hex = `0${hex}`;
    }

    return hex;
  }

  getElevationColorMapping(): ElevationColorMapping[] | null {
    const { elevationSliderValue, colors } = this.state;

    if (!colors || !elevationSliderValue) {
      return null;
    }

    const { min, max } = elevationSliderValue;
    const colorCount = Object.keys(colors).length;

    if (colorCount === 0) {
      return null;
    }

    const stepSize = (max - min) / (colorCount - 1);
    const colorMap = [];

    for (let idx = 0; idx < colorCount; idx += 1) {
      const color = colors[idx];
      const colorString = `#${color
        .map(this.convertIntegerToColor)
        .reduce((combinedString: string, colorString: string) => {
          return combinedString + colorString;
        }, '')}`;
      const colorMapItem = {
        elevation: min + stepSize * idx,
        color: colorString,
      };

      colorMap.push(colorMapItem);
    }

    return thinColorMapping(colorMap).reverse();
  }

  public renderRangeControls() {
    const { view } = this.props;
    const { elevationSliderValue, sourceElevationRange } = this.state;
    const marks = {};

    if (!sourceElevationRange || !elevationSliderValue || !view) {
      return;
    }

    const units = view.coordinateUnits || 'METRES';
    const unitAbbrv = units === 'METRES' ? 'm' : 'ft';

    const { min: sliderMin, max: sliderMax } = sourceElevationRange;

    marks[sliderMin] = {
      style: {
        color: '#fff',
        width: '60px',
        left: '30px',
        textAlign: 'left',
      },
      label: `${sliderMin.toFixed(1)} ${unitAbbrv}`,
    };
    marks[sliderMax] = {
      style: {
        color: '#fff',
        width: '60px',
        left: 'calc(100% - 30px)',
        textAlign: 'right',
      },
      label: `${sliderMax.toFixed(1)} ${unitAbbrv}`,
    };

    return (
      <div>
        <Slider
          step={0.5}
          range
          min={Number(sliderMin.toFixed(1))}
          max={Number(sliderMax.toFixed(1))}
          defaultValue={[sourceElevationRange.min, sourceElevationRange.max]}
          marks={marks}
          value={[elevationSliderValue.min, elevationSliderValue.max]}
          onChange={this.sliderValueChanged}
        />
      </div>
    );
  }

  public render() {
    const { viewData, loading } = this.state;
    const { view, center, zoom, onMapMove } = this.props;
    const elevationColorMapping = this.getElevationColorMapping();

    if (viewData && !loading && view) {
      return (
        <div className={style.container}>
          {this.renderRangeControls()}
          <div className={style.mapContainer}>
            {elevationColorMapping ? (
              <ElevationScale
                dems={elevationColorMapping}
                units={view.coordinateUnits || 'METRES'}
                className={style.colorScale}
              />
            ) : null}
            <OpenLayersMap
              {...viewData}
              className={style.map}
              currentState={center || zoom ? { center, zoom } : undefined}
              onMapMove={onMapMove}
            />
          </div>
        </div>
      );
    }

    return <SkeletonLoader size={3} position="absolute" />;
  }
}
