import { Button, Modal, Select } from 'antd';
import classnames from 'classnames';
import * as React from 'react';
import VectorSource from 'ol/source/Vector';
import MeasurementAPIs from '../../../../api/Measurement';
import { View } from '../../../../api/views.types';
import { getUnits, undefinedOrNull } from '../../../../utils/functs';
import { convertLengthValue } from '../../../../utils/helpers';
import * as ControlBox from '../../../ControlBox';
import {
  MeasurementChanged,
  MeasurementStatus,
  MeasurementSubType,
  MeasurementType,
} from '../../index.types';
import {
  Annotation,
  AnnotationData,
  AnnotationsControl,
} from '../../MapView/OpenLayersRenderer/AnnotationsControl';
import OLDrawControl from '../../MapView/OpenLayersRenderer/DrawControl';
import { DrawMode } from '../../MapView/OpenLayersRenderer/DrawControl/index.types';
import { VectorLayerControl } from '../../MapView/OpenLayersRenderer/VectorLayerControl';
import PotreeDrawControl from '../../ThreeDView/PotreeDrawControl';
import AreaInfo from './AreaInfo';
import DistanceInfo from './DistanceInfo';
import ElevationInfo from './ElevationInfo';
import ProfileInfo from './Profileinfo';
import style from './index.module.scss';
import {
  MeasureControlBoxIntentDataTypes,
  MeasureControlBoxPropsType,
  MeasureControlBoxStateType,
} from './index.types';
import VolumeInfo from './VolumeInfo';

const SelectOption = Select.Option;

const INTENTS_LIST: { [key in MeasurementType]: string } = {
  distance: 'Distance',
  area: 'Area',
  volume: 'Volume',
  elevation: 'Elevation',
  profile: 'Profile',
};

const measurementAPIs = new MeasurementAPIs();

class MeasureControlBox extends React.Component<
  MeasureControlBoxPropsType,
  MeasureControlBoxStateType
> {
  private potreeDrawControlRef = React.createRef<PotreeDrawControl>();

  public constructor(props: MeasureControlBoxPropsType) {
    super(props);

    this.state = {
      type: null,
      subType: null,
      geoJson: null,
      allowShapeEdit: true,
      measurementName: 'Measurement',
      drawSource: new VectorSource({
        wrapX: false,
        features: [],
      }),
    };
  }

  public componentDidMount() {
    const { type, subType, geoJson, planeElevation, hasLinkedIssue, view } =
      this.props;
    const {
      type: currentType,
      subType: currentSubType,
      geoJson: currentGeoJson,
    } = this.state;

    // initialize with specified props
    if (type || subType || geoJson || planeElevation) {
      this.setState(
        {
          type: type || currentType,
          subType: subType || currentSubType,
          geoJson: geoJson || currentGeoJson,
          planeElevation,
        },
        () => {
          if (hasLinkedIssue) {
            const { geoJson } = this.state;

            this.updateAnnotations(geoJson as any, this.props);
          }
        }
      );
    }

    measurementAPIs
      .fetchMeasurements(view.aoiId)
      .then((res) => {
        if (res?.data?.length) {
          this.setState({ savedMeasurements: res?.data });
        }
      })
      .catch((err) => console.error(err));
  }

  public UNSAFE_componentWillReceiveProps(
    newProps: MeasureControlBoxPropsType
  ) {
    const { view: newView, splitView: newSplitView } = newProps;
    const { view: oldView, splitView: oldSplitView } = this.props;
    const { geoJson, type } = this.state;

    if (oldView.id !== newView.id) {
      if (
        (!newView.certifiedForMeasurement ||
          !(newView.demId && newView.demId.length > 0)) &&
        (type === 'elevation' || type === 'volume' || type === 'profile')
      ) {
        this.handleMeasurementTypeChange(null);
      } else if (geoJson) {
        this.updateAnnotations(geoJson as { features: any[] }, newProps);
      }
    }

    if (newSplitView?.id !== oldSplitView?.id) {
      if (
        newSplitView &&
        (!newSplitView.certifiedForMeasurement ||
          !(newSplitView.demId && newSplitView.demId?.length > 0)) &&
        (type === 'elevation' || type === 'volume' || type === 'profile')
      ) {
        this.handleMeasurementTypeChange(null);
      }
    }
  }

  public componentWillUnmount() {
    const { onRenderFragChange, onEvent } = this.props;

    onRenderFragChange(undefined);
    onEvent(new MeasurementChanged({}));
  }

  private passDataToViewControl = () => {
    const { measureTypeCallback } = this.props;
    const { type } = this.state;

    if (measureTypeCallback) {
      measureTypeCallback(type);
    }
  };

  private handleBackSelection = () => {
    this.handleMeasurementTypeChange(null);
  };

  private getDrawModeForMeasurementType = (type: MeasurementType): DrawMode => {
    switch (type) {
      default:
      case 'area':
      case 'volume': {
        return 'Polygon';
      }

      case 'distance': {
        return 'LineStringWithDistance';
      }

      case 'elevation': {
        return 'Point';
      }

      case 'profile': {
        return 'LineString';
      }
    }
  };

  private updateRenderFrag() {
    const { onRenderFragChange, hasLinkedIssue, view } = this.props;
    const {
      type,
      allowShapeEdit,
      annotationData,
      subType,
      planeElevation,
      selectedMeasurement,
      selectedProfilePoint,
    } = this.state;

    let unitsPerMeter = 1;

    switch (view.coordinateUnits) {
      case 'FEET':
        unitsPerMeter = 3.28084;
        break;
      case 'US_SURVEY_FEET':
        unitsPerMeter = 3.2808333333;
        break;
      default:
        unitsPerMeter = 1;
        break;
    }

    if (selectedMeasurement) {
      // we don't support editing saved measurements currently, just render the geojson
      if (view?.type === 'map') {
        onRenderFragChange(() => {
          return (
            <>
              <VectorLayerControl
                key={`measure-frag-${selectedMeasurement}`}
                issue={{
                  shapeGeoJson: JSON.stringify(
                    this.getGeoJsonForSavedMeasure()
                  ),
                }}
                sourceProjection="EPSG:4326"
              />
              {annotationData && (
                <AnnotationsControl
                  annotationsData={annotationData}
                  key="measurement_annotations"
                />
              )}
            </>
          );
        });
      } else if (view?.type === 'three_d') {
        onRenderFragChange(() => {
          return (
            <PotreeDrawControl
              ref={this.potreeDrawControlRef}
              measurementType={type || undefined}
              planeElevation={planeElevation}
              volumeType={subType || undefined}
              geoJson={this.getGeoJsonForSavedMeasure()}
              unitsPerMeter={unitsPerMeter}
              onFeatureCreate={this.handleFeatureCreate}
              onFeatureEdit={this.handleFeatureEdit}
              onCancel={this.handleBackSelection}
            />
          );
        });
      }

      return;
    }

    if (view?.type === 'three_d' && type) {
      onRenderFragChange(() => {
        return (
          <PotreeDrawControl
            ref={this.potreeDrawControlRef}
            measurementType={type || undefined}
            planeElevation={planeElevation}
            volumeType={subType || undefined}
            unitsPerMeter={unitsPerMeter}
            onFeatureCreate={this.handleFeatureCreate}
            onFeatureEdit={this.handleFeatureEdit}
            onElevationChange={(elevs) =>
              this.setState({ elevationList: elevs })
            }
            onCancel={this.handleBackSelection}
          />
        );
      });
    } else if (view?.type === 'map' && type) {
      const { drawSource } = this.state;

      onRenderFragChange(() => {
        return (
          <React.Fragment key="measure-frags-wrapper">
            {!hasLinkedIssue && (
              <OLDrawControl
                olDrawSource={drawSource}
                drawMode={this.getDrawModeForMeasurementType(type)}
                allowEdit={allowShapeEdit || false}
                allowMultipleFeatures={type === 'elevation'}
                onFeatureCreate={this.handleFeatureCreate}
                onFeatureEdit={this.handleFeatureEdit}
                key="measurement_draw_control"
              />
            )}
            {annotationData && (
              <AnnotationsControl
                annotationsData={annotationData}
                key="measurement_annotations"
              />
            )}
            {selectedProfilePoint && (
              <VectorLayerControl
                key={`measure-frag-${selectedMeasurement}`}
                issue={{
                  shapeGeoJson: JSON.stringify({
                    type: 'FeatureCollection',
                    crs: {
                      type: 'name',
                      properties: {
                        name: 'EPSG:4326',
                      },
                    },
                    features: [
                      {
                        type: 'Feature',
                        geometry: {
                          type: 'Point',
                          coordinates: [
                            selectedProfilePoint.lng,
                            selectedProfilePoint.lat,
                          ],
                        },
                      },
                    ],
                  }),
                }}
                sourceProjection="EPSG:4326"
              />
            )}
          </React.Fragment>
        );
      });
    } else {
      onRenderFragChange(undefined);
    }
  }

  public handleMeasurementTypeChange = (
    type: MeasurementType | null = null
  ): void => {
    const subType: MeasurementSubType | null =
      type === 'volume' ? 'FlatPlane' : null;

    this.setState(
      {
        type,
        subType,
        geoJson: null,
        annotationData: undefined,
        selectedMeasurement: undefined,
        drawSource: new VectorSource({
          wrapX: false,
          features: [],
        }),
      },
      () => {
        this.sendMeasurementChangeEvent();
        this.updateRenderFrag();
        this.passDataToViewControl();
      }
    );
  };

  private handleClose = (): void => {
    const { onClose } = this.props;

    onClose();
  };

  private updateAnnotations = (
    geoJson: { features: any[] },
    props?: MeasureControlBoxPropsType
  ) => {
    const { type } = this.state;
    const actualProps = props || this.props;

    if (!geoJson?.features) {
      console.error('No features present to update annotations');

      return;
    }

    if (type === 'elevation') {
      const annotations: Annotation[] = geoJson.features.map(
        (f: any, index: number) => {
          const { coordinates } = f.geometry;

          return {
            content: `P${index + 1}`,
            center: {
              x: coordinates[0],
              y: coordinates[1],
            },
          };
        }
      );

      const annotationData: AnnotationData = {
        annotations,
        orientation: 'top',
      };

      this.setState(
        {
          annotationData,
        },
        () => {
          this.updateRenderFrag();
        }
      );
    } else if (type === 'volume') {
      // TODO: finish annotation update on volume polygon create / edit
      // ensure that a race condition does not take place
      const { view } = actualProps;

      let annotationData: AnnotationData | undefined;
      let elevationList: number[] | undefined;
      const points = (geoJson?.features[0]?.geometry?.coordinates[0] || []).map(
        (p: number[]) => [p[0], p[1]]
      );

      this.setState(
        { annotationData: undefined, allowShapeEdit: false },
        () => {
          this.updateRenderFrag();
        }
      );

      measurementAPIs
        .fetchElevation(view.id, points)
        .then((res) => {
          if (!undefinedOrNull(res)) {
            const elevations: number[] = res.data.elevation.metres;
            const viewUnits = this.getViewUnits(view);

            if (elevations.length !== points.length) {
              console.error('Mismatch b/w API request and response');

              return;
            }

            const annotations: Annotation[] = points.map(
              (p: number[], index: number) => {
                const e = convertLengthValue(
                  elevations[index],
                  viewUnits === 'm' ? 'meter' : 'feet'
                );

                return {
                  content: `${e} ${viewUnits}`,
                  center: {
                    x: p[0],
                    y: p[1],
                  },
                };
              }
            );

            // updating top-level annotationData to be used in .finally
            annotationData = {
              annotations,
              orientation: 'top',
            };
            elevationList = elevations;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.setState(
            {
              elevationList,
              annotationData,
              allowShapeEdit: true,
            },
            () => {
              this.updateRenderFrag();
            }
          );
        });
    }
  };

  private handleFeatureCreate = (geoJson: { features: any[] }) => {
    const { view } = this.props;

    if (view?.type === 'map') {
      // based on the assumption that there will only be one feature at any given time
      this.updateAnnotations(geoJson);
    }

    this.setState(
      {
        geoJson,
      },
      () => {
        this.sendMeasurementChangeEvent();
      }
    );
  };

  private handleFeatureEdit = (geoJson: { features: any[] }) => {
    const { view } = this.props;

    if (view?.type === 'map') {
      // based on the assumption that there will only be one feature at any given time
      // this allows the update process to be the same as the create process
      this.updateAnnotations(geoJson);
    }

    this.setState(
      {
        geoJson,
      },
      () => {
        this.sendMeasurementChangeEvent();
      }
    );
  };

  private sendMeasurementChangeEvent() {
    // send event
    const { onEvent } = this.props;
    const { type, subType, geoJson, planeElevation } = this.state;

    const status: MeasurementStatus = {
      type: type || undefined,
      subType: subType || undefined,
      volumePlaneElevation: planeElevation,
      geoJson,
    };

    onEvent(new MeasurementChanged(status));
  }

  private getViewUnits = (view: View): 'm' | 'ft' => {
    if (!view) {
      return 'm';
    }

    return getUnits(view.coordinateUnits);
  };

  private renderMeasurementType = ({
    label,
    active = true,
    disable = false,
    onClick,
  }: MeasureControlBoxIntentDataTypes): JSX.Element => (
    <div
      onClick={() => {
        if (disable || !onClick) {
          return;
        }

        onClick();
      }}
      className={classnames(style.intent, {
        [style.active]: active,
        [style.disable]: disable,
      })}
    >
      {label}
    </div>
  );

  private saveMeasurement = () => {
    const { view } = this.props;
    const {
      type,
      subType,
      geoJson,
      planeElevation,
      elevationList,
      savedMeasurements,
      measurementName,
    } = this.state;

    const req: any = {
      name: measurementName || 'Measurement',
      measurementType: type,
      volumeType: subType || '',
      planeElevation: planeElevation || 0.0,
    };

    // geo json from 3d view will already be in 3d, make maps geojson 3d
    if (view?.type === 'map' && (type === 'volume' || type === 'elevation')) {
      const points = geoJson?.features[0]?.geometry?.coordinates[0];

      for (let idx = 0; idx < points.length; idx += 1) {
        const p = points[idx];
        const e = (elevationList || [])[idx];

        points[idx] = [p[0], p[1], e];
      }
    }

    req.geoJson = JSON.stringify(geoJson);

    measurementAPIs
      .saveMeasurement(view.aoiId, req)
      .then((res) => {
        const addedMeasurement = res.data;

        this.setState({
          savedMeasurements: [addedMeasurement, ...(savedMeasurements || [])],
          selectedMeasurement: addedMeasurement.id,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  private selectMeasurement = (id: string) => {
    if (id && this.getSelectedMeasurement(id)) {
      const measure = this.getSelectedMeasurement(id);
      const stateUpdate: any = {
        selectedMeasurement: id,
        type: measure.measurementType,
      };

      if (measure.measurementType === 'volume' && measure.volumeType) {
        stateUpdate.subType = measure.volumeType;
      }

      if (
        measure.measurementType === 'volume' &&
        measure.volumeType === 'FlatPlane'
      ) {
        stateUpdate.planeElevation = measure.planeElevation;
      }

      this.setState(stateUpdate, () => {
        this.handleFeatureCreate(this.getGeoJsonForSavedMeasure());
        this.updateRenderFrag();
      });
    } else {
      this.setState({ selectedMeasurement: undefined });
    }
  };

  private getSelectedMeasurement = (altId: string | undefined = undefined) => {
    const { savedMeasurements, selectedMeasurement: id } = this.state;
    const searchId = altId || id;

    return (savedMeasurements || []).find((m: any) => m.id === searchId);
  };

  private getGeoJsonForSavedMeasure = () => {
    let geoJson: any;
    const measure = this.getSelectedMeasurement();

    if (measure) {
      const { geoJson: geoJsonStr } = measure;

      geoJson = JSON.parse(geoJsonStr);
    }

    return geoJson;
  };

  public getFilteredMeasurementTypes = (): {
    filter: MeasurementType[];
    disabledMeasurements: boolean;
  } => {
    const { view, splitView } = this.props;

    let disabledMeasurements: boolean = false;
    let filter: MeasurementType[] = [
      'distance',
      'area',
      'volume',
      'elevation',
      'profile',
    ];

    if (
      view &&
      (!view.certifiedForMeasurement || !(view.demId && view.demId.length > 0))
    ) {
      filter = ['distance', 'area'];
    }

    if (
      view &&
      view.certifiedForMeasurement &&
      view.demId &&
      view.demId.length > 0 &&
      (view.subType === 'thermal_mosaic' || view.subType === 'ndvi')
    ) {
      filter = ['distance', 'area', 'elevation'];
    }

    if (view.type === 'three_d') {
      filter.push('profile');
    }

    if (splitView) {
      filter = ['profile'];

      if (
        !(
          splitView.certifiedForMeasurement &&
          view.certifiedForMeasurement &&
          splitView.demId &&
          view.demId
        )
      ) {
        disabledMeasurements = true;
      }
    }

    return { filter, disabledMeasurements };
  };

  public RenderIntentSelection = (): JSX.Element | null => {
    const { view } = this.props;
    const { type, savedMeasurements, selectedMeasurement } = this.state;
    const { renderMeasurementType: RenderIntent } = this;
    const { filter, disabledMeasurements } = this.getFilteredMeasurementTypes();

    return (
      <>
        <div className={style.intentList}>
          {Object.keys(INTENTS_LIST)
            .filter((key: MeasurementType) => filter.indexOf(key) > -1)
            .map((key: MeasurementType) => {
              let label = INTENTS_LIST[key];

              if (key === 'elevation') {
                if (view.subType === 'thermal_mosaic') {
                  label = 'Temperature';
                } else if (view.subType === 'ndvi') {
                  label = 'NDVI Value';
                }
              }

              return (
                <RenderIntent
                  active={type === key}
                  key={key}
                  label={label}
                  disable={disabledMeasurements}
                  onClick={() => {
                    this.handleMeasurementTypeChange(key);
                  }}
                />
              );
            })}
        </div>
        <div className={style.savedMeasurements}>
          <div className={style.savedMeasurementHeader}>Saved Measurements</div>
          <div>
            <Select
              style={{ width: '90%' }}
              value={selectedMeasurement || ''}
              onChange={this.selectMeasurement}
            >
              <SelectOption key="0" value="">
                Select
              </SelectOption>
              {(savedMeasurements || []).map((m: any) => {
                return (
                  <SelectOption key={m.id} value={m.id}>
                    {m.name}
                  </SelectOption>
                );
              })}
            </Select>
          </div>
        </div>
      </>
    );
  };

  public renderMeasureHeader = (): React.ReactElement<{}> => {
    return (
      <React.Fragment>
        <ControlBox.Title title="Measure" />
        <ControlBox.Icon
          onClick={() => {
            this.handleClose();
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  public getMeasurementTypeTitle = (): string => {
    const { view } = this.props;
    const { type } = this.state;

    switch (type) {
      case 'area':
        return 'Area';
      case 'volume':
        return 'Volume';
      case 'elevation':
        if (view.subType === 'thermal_mosaic') {
          return 'Temperature';
        }

        if (view.subType === 'ndvi') {
          return 'NDVI Value';
        }

        return 'Elevation';

      case 'profile':
        return view.subType === 'thermal_mosaic'
          ? 'Temperature Profile'
          : 'Elevation Profile';
      case 'distance':
        return 'Distance';
      default:
        return 'Selection';
    }
  };

  public renderSelectionHeader = (): JSX.Element => {
    return (
      <React.Fragment>
        <ControlBox.Icon
          onClick={() => {
            this.handleBackSelection();
          }}
          name="back"
        />
        <ControlBox.Title title={this.getMeasurementTypeTitle()} />
        <ControlBox.Icon
          onClick={() => {
            this.handleClose();
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  public RenderShapeComponents = (): JSX.Element | null => {
    const { view, splitView, hasLinkedIssue } = this.props;
    const { type, subType, geoJson, elevationList, planeElevation } =
      this.state;

    if (!(type && geoJson && geoJson.features)) {
      return this.RenderIntentSelection();
    }

    if (type === 'area') {
      return <AreaInfo shape={geoJson} view={view} />;
    }

    if (type === 'distance') {
      return <DistanceInfo shape={geoJson} view={view} />;
    }

    if (type === 'profile') {
      return (
        <ProfileInfo
          shape={geoJson}
          view={view}
          splitView={splitView}
          viewId={view.id}
          onPointSelected={(p) => {
            this.setState({ selectedProfilePoint: p }, () => {
              this.updateRenderFrag();
            });
          }}
        />
      );
    }

    if (type === 'volume' && view) {
      return (
        <VolumeInfo
          view={view}
          shape={geoJson.features?.[0]}
          subType={subType}
          elevationList={elevationList}
          planeElvation={planeElevation}
          hasLinkedIssue={hasLinkedIssue}
          onVolumeSubTypeChange={(subType: MeasurementSubType) => {
            this.setState({ subType }, () => {
              this.sendMeasurementChangeEvent();

              if (this.potreeDrawControlRef.current) {
                this.potreeDrawControlRef.current.setVolumeType(subType);
              }
            });
          }}
          onVolumePlaneElevationChange={(planeElevation) => {
            this.setState({ planeElevation }, () => {
              this.sendMeasurementChangeEvent();

              if (this.potreeDrawControlRef.current) {
                this.potreeDrawControlRef.current.setPlaneElevation(
                  planeElevation
                );
              }
            });
          }}
        />
      );
    }

    if (type === 'elevation' && view.id) {
      return (
        <ElevationInfo
          view={view}
          viewId={view.id}
          shape={geoJson}
          onElevationChange={(elevations) =>
            this.setState({ elevationList: elevations })
          }
        />
      );
    }

    return null;
  };

  public render(): React.ReactNode {
    const { view } = this.props;
    const {
      type,
      geoJson,
      elevationList,
      selectedMeasurement,
      showMeasurementSaveModal,
      measurementName,
    } = this.state;
    const { RenderShapeComponents } = this;
    let enableSave = false;

    switch (type) {
      case 'volume':
        enableSave = view?.type === 'three_d' ? true : !!elevationList?.length;
        break;
      case 'elevation':
        enableSave = view?.type === 'three_d' ? true : !!elevationList?.length;
        break;
      default:
        break;
    }

    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={
          type ? this.renderSelectionHeader : this.renderMeasureHeader
        }
      >
        {!view.certifiedForMeasurement ? (
          <p className={style.measureText}>
            Measurements made on this map might not be accurate
          </p>
        ) : null}
        <RenderShapeComponents />
        {type &&
        geoJson &&
        geoJson.features &&
        !selectedMeasurement &&
        enableSave ? (
          <div>
            <Button
              type="primary"
              style={{ width: '100%' }}
              onClick={(_e: any) =>
                this.setState({ showMeasurementSaveModal: true })
              }
              disabled={!enableSave}
            >
              Save Measurement
            </Button>
          </div>
        ) : null}
        {showMeasurementSaveModal ? (
          <Modal
            title="Save As"
            centered
            visible={showMeasurementSaveModal}
            okText="Save"
            onOk={() => {
              this.setState({ showMeasurementSaveModal: false }, () => {
                this.saveMeasurement();
              });
            }}
            cancelText="Cancel"
            onCancel={() => this.setState({ showMeasurementSaveModal: false })}
            destroyOnClose
            maskClosable={false}
            zIndex={10001}
          >
            <div>
              <input
                value={measurementName || ''}
                onChange={(e: any) =>
                  this.setState({ measurementName: e.target.value })
                }
              />
            </div>
          </Modal>
        ) : null}
      </ControlBox.Wrapper>
    );
  }
}

export default MeasureControlBox;
