import * as React from 'react';
import { Modal, Checkbox, Typography } from 'antd';
import { Button } from '../../../Button';
import { GenericObjectType } from '../../../../shapes/app';
import styles from './index.module.scss';
import Issue from '../../../../api/issue';
import { SnackbarActionsActionShowSnackbarTypes } from '../../../../shapes/snackbar';

interface IProps {
  actionShowSnackbar?: SnackbarActionsActionShowSnackbarTypes;
  showModal: boolean;
  projectUsersList: GenericObjectType[];
  tagId: string;
  handleModalStatus: () => void;
}

interface IState {
  shareIssueLoading: boolean;
  selectedShareToUserIdsList: string[];
  shareIssuesSuccessMessage: string;
  shareIssuesErrorMessage: string;
}

const { Text } = Typography;
const issueApi = new Issue();

class ProjectUsersModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      shareIssueLoading: false,
      selectedShareToUserIdsList: [],
      shareIssuesSuccessMessage: '',
      shareIssuesErrorMessage: '',
    };
  }

  private processShareIssueBtn = () => {
    const { tagId, actionShowSnackbar } = this.props;
    const { selectedShareToUserIdsList } = this.state;

    this.setState({ shareIssueLoading: true });

    issueApi
      .shareIssueByTagId(tagId, selectedShareToUserIdsList)
      .then(() => {
        this.setState(
          {
            shareIssueLoading: false,
            shareIssuesSuccessMessage: 'Issues with selected tag shared!',
            shareIssuesErrorMessage: '',
          },
          () => {
            this.setState({ selectedShareToUserIdsList: [] });
            if (actionShowSnackbar) {
              actionShowSnackbar({
                body: 'Issues with selected tag shared',
                type: 'success',
              });
            }

            this.handleShareIssueBtn();
          }
        );
      })
      .catch((err) => {
        console.error(err);
        this.setState(
          {
            shareIssueLoading: false,
            shareIssuesErrorMessage: 'No issues with this tag exist!',
            shareIssuesSuccessMessage: '',
          },
          () => {
            this.setState({ selectedShareToUserIdsList: [] });
            if (actionShowSnackbar) {
              actionShowSnackbar({
                body: 'No issues with this tag exist',
                type: 'error',
              });
            }

            this.handleShareIssueBtn();
          }
        );
      });
  };

  private handleShareIssueBtn = () => {
    const { handleModalStatus } = this.props;

    handleModalStatus();
  };

  private handleShareCheckboxChange = (value: string[]): void => {
    this.setState({
      selectedShareToUserIdsList: value,
    });
  };

  public render(): React.ReactNode {
    const { projectUsersList, showModal } = this.props;
    const {
      selectedShareToUserIdsList,
      shareIssueLoading,
      shareIssuesErrorMessage,
      shareIssuesSuccessMessage,
    } = this.state;

    const optionsList: { label: string; value: string }[] = [];

    (projectUsersList ?? [])
      .filter((a) => a.role !== 'field_staff')
      .map((a) => {
        const value = { label: `${a.email} - ${a.name}`, value: a.userId };

        optionsList.push(value);

        return a;
      });

    return (
      <Modal
        title="Confirm action"
        centered
        footer={null}
        visible={showModal}
        zIndex={10008}
        destroyOnClose
        maskClosable={false}
        onCancel={() => {
          this.handleShareIssueBtn();
        }}
        className={styles.shareModalContainer}
      >
        <div>
          <Text className={styles.shareModalTitle}>Select Users</Text>

          <Checkbox.Group
            options={optionsList}
            onChange={this.handleShareCheckboxChange}
          />
        </div>
        <div className={styles.shareModalBtnWrapper}>
          <Button
            className={styles.cancelButton}
            onClick={() => {
              this.handleShareIssueBtn();
            }}
            transparent
          >
            Cancel
          </Button>
          <Button
            disabled={
              !selectedShareToUserIdsList ||
              selectedShareToUserIdsList.length < 1
            }
            loading={shareIssueLoading}
            loadingText="Sharing..."
            onClick={() => {
              this.processShareIssueBtn();
            }}
          >
            OK
          </Button>
          {shareIssuesSuccessMessage && (
            <p className={styles.successMessage}>{shareIssuesSuccessMessage}</p>
          )}
          {shareIssuesErrorMessage && (
            <p className={styles.errorMessage}>{shareIssuesErrorMessage}</p>
          )}
        </div>
      </Modal>
    );
  }
}

export default ProjectUsersModal;
