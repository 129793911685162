import { GeoJSON } from 'ol/format';
import { useMemo, useRef } from 'react';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import { WEB_MERCATOR, WGS84 } from 'src/components/OSMMap/utils';
import {
  ClearLayerEvent,
  RendererState,
  SelectLayerEvent,
  UpdateLayerEvent,
  ViewEvent,
} from 'src/components/View/index.types';

const geoJsonWriter = new GeoJSON({
  dataProjection: WGS84,
  featureProjection: WEB_MERCATOR,
});

export interface LayerManagementInterface {
  layer?: VectorLayer;
  resetLayerFeatures: () => void;
  updateInitialState: (layer?: VectorLayer, features?: Feature[]) => void;
  saveLayer: () => void;
  selectLayer: (l: LayerDescriptor) => void;
  clearLayer: () => void;
}
export const useLayerManagement = (
  onEvent: (e: ViewEvent<any>) => void,
  selectedLayer?: LayerDescriptor,
  rendererState?: RendererState
): LayerManagementInterface => {
  const initialStateRef = useRef<{ layer?: VectorLayer; features?: string }>(
    {}
  );

  const layer = useMemo(() => {
    if (!selectedLayer || !rendererState?.layers) return undefined;

    const l = rendererState.layers.find((l) => l.id === selectedLayer.name);

    if (l?._layer instanceof VectorLayer) return l._layer;

    return undefined;
  }, [rendererState, selectedLayer]);

  const resetLayerFeatures = useMemo(
    () => () => {
      const { layer, features } = initialStateRef.current;

      if (layer && features) {
        layer.getSource().clear();
        layer.getSource().addFeatures(geoJsonWriter.readFeatures(features));
      }
    },
    []
  );

  const updateInitialState = useMemo(
    () => (layer?: VectorLayer, features?: Feature[]) => {
      initialStateRef.current.layer = layer;
      initialStateRef.current.features = geoJsonWriter.writeFeatures(
        features || []
      );
    },
    []
  );

  const saveLayer = useMemo(
    () => () => {
      if (layer && selectedLayer) {
        updateInitialState(layer, layer.getSource().getFeatures());
        onEvent(
          new UpdateLayerEvent({
            layer: selectedLayer,
            geojsonContents: geoJsonWriter.writeFeatures(
              layer.getSource().getFeatures()
            ),
          })
        );
      }
    },
    [layer, selectedLayer, onEvent, updateInitialState]
  );

  const clearLayer = useMemo(
    () => () => {
      onEvent(new ClearLayerEvent());
    },
    [onEvent]
  );

  const selectLayer = useMemo(
    () => (l: LayerDescriptor) => {
      onEvent(new SelectLayerEvent(l));
    },
    [onEvent]
  );

  return {
    layer,
    resetLayerFeatures,
    updateInitialState,
    saveLayer,
    selectLayer,
    clearLayer,
  };
};
