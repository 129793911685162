// TODO: ensure file is compliant with eslint and remove the above line
import { Tabs } from 'antd';
import classnames from 'classnames';
import * as React from 'react';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import { AddShapeControlBox } from 'src/components/View/ViewControls/MapMakerControls/AddShapeControlBox';
import { EditShapeControlBox } from 'src/components/View/ViewControls/MapMakerControls/EditShapeControlBox';
import { RemoveShapeControlBox } from 'src/components/View/ViewControls/MapMakerControls/RemoveShapeControlBox';
import * as ControlBox from '../../ControlBox/ControlBox';
import Icon from '../../Icon';
import {
  ControlBoxCloseEvent,
  ControlBoxOpenEvent,
  MeasurementSubType,
  MeasurementType,
  ViewEvent,
} from '../index.types';
import CompareControlBox from './CompareControlBox';
import ElevationDifferenceControlBox from './ElevationDifferenceControlBox';
import DownloadsControlBox from './DownloadsControlBox';
import style from './index.module.scss';
import {
  ControlBoxRender,
  ControlTypes,
  ViewControlsPropsType,
  ViewControlsStateType,
} from './index.types';
import InfoControlBox from './InfoControlBox';
import IssueControlBox from './IssueControlBox';
import LegendControlBox from './LegendControlBox';
import MeasureControlBox from './MeasureControlBox';
import ReportsControlBox from './ReportsControlBox';
import ShareControlBox from './ShareControlBox';
import SiteObjectsControlBox from './SiteObjectsControlBox';

const { TabPane } = Tabs;

class ViewControls extends React.Component<
  ViewControlsPropsType,
  ViewControlsStateType
> {
  public constructor(props: ViewControlsPropsType) {
    super(props);

    this.state = {
      selectedItems: [],
      measureType: undefined,
    };
  }

  public componentDidMount() {
    const { issue } = this.props;

    if (issue) {
      this.handleSelectItem('mark_issue', () => {
        // check if issue has measurement data
        if (issue.viewConfig?.measurementType) {
          this.handleSelectItem('measure');
        }
      });
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ViewControlsPropsType) {
    const { issue: nextIssue, view: nextView } = nextProps;
    const { issue, view } = this.props;

    if (view !== nextView) {
      // if measure is open, and only measure is open, and next view is map
      // and next view is within same aoi, keep it open, else reset all controls

      const { selectedItems, currentItem, measureType } = this.state;
      const onlyMeasureOpen =
        selectedItems.length === 1 && currentItem === 'measure';
      const isThermalish =
        nextView.subType === 'thermal_mosaic' || nextView.subType === 'ndvi';

      const closeControls =
        (isThermalish && measureType === 'volume') ||
        (isThermalish && measureType === 'profile')
          ? true
          : !onlyMeasureOpen ||
            view.type !== nextView?.type ||
            view.aoiId !== nextView?.aoiId;

      if (closeControls) {
        this.setState({
          selectedItems: [],
          measurementStatus: undefined,
          // eslint-disable-next-line react/no-unused-state
          comapareStatus: undefined,
        });
      }
    }

    if (issue !== nextIssue) {
      if (nextIssue) {
        // issue was loaded
        const controls: ControlBoxRender[] = [
          this.getItemControlBox('mark_issue'),
        ];

        if (nextIssue.viewConfig?.measurementType) {
          controls.push(this.getItemControlBox('measure'));
        }

        if (nextIssue?.viewConfig?.compareViewId) {
          controls.push(this.getItemControlBox('compare'));
        }

        if (nextIssue?.viewConfig?.compareViewId) {
          controls.push(this.getItemControlBox('elevation_difference'));
        }

        this.setState({
          comapareStatus: nextIssue?.viewConfig?.compareViewId
            ? {
                comapreViewId: nextIssue?.viewConfig?.compareViewId,
                opacity: 50,
              }
            : undefined,
          selectedItems: controls,
          currentItem: 'mark_issue',
        });
      } else {
        // issue was removed
        this.setState({
          selectedItems: [],
          measurementStatus: undefined,
          // eslint-disable-next-line react/no-unused-state
          comapareStatus: undefined,
        });
      }
    }
  }

  private onRenderFragChange = (controlType: ControlTypes) => {
    const { onRenderFragChange } = this.props;

    return (frag: any) => {
      onRenderFragChange(frag, controlType);
    };
  };

  private handleTabChange = (itemId: ControlTypes) => {
    this.setState({ currentItem: itemId });
  };

  private handleSelectItem = (
    item: ControlTypes,
    cb: () => void = () => {}
  ) => {
    const { selectedItems } = this.state;
    const { onEvent } = this.props;
    const selectedItemIds = selectedItems.map((item) => item.id);

    if (selectedItemIds.indexOf(item) > -1) {
      const remainingItems = selectedItemIds.filter((i) => i !== item);

      this.setState(
        {
          selectedItems: remainingItems
            .map(this.getItemControlBox)
            .filter((item) => item !== null) as ControlBoxRender[],
          currentItem:
            remainingItems.length > 0 ? remainingItems[0] : undefined,
        },
        () => {
          onEvent(new ControlBoxCloseEvent({ itemType: item }));
          cb();
        }
      );
    } else {
      this.setState(
        {
          selectedItems: [
            ...selectedItems,
            this.getItemControlBox(item),
          ].filter((item) => item !== null) as ControlBoxRender[],
          currentItem: item,
        },
        () => {
          onEvent(new ControlBoxOpenEvent({ itemType: item }));
          cb();
        }
      );
    }
  };

  private isItemDisabled = (item: ControlTypes): boolean => {
    const { selectedItems, currentItem } = this.state;
    const issueGroupControls: ControlTypes[] = [
      'mark_issue',
      'measure',
      'compare',
    ];
    const onlyHasItemsOpen = (items: ControlTypes[]): boolean => {
      return (
        selectedItems.filter((i) => items.indexOf(i.id) === -1).length === 0
      );
    };
    const onlyIssueGroupItemsOpen = onlyHasItemsOpen(issueGroupControls);

    switch (item) {
      case 'mark_issue': {
        /*
        mark issue is enabled if:
        - nothing else is open
        - only mark issue is open
        - no other control besides (measure and/or compare) is open
        - if measure is open, it must have an active measurement
        */
        if (!onlyIssueGroupItemsOpen) {
          return true;
        }

        if (
          selectedItems.find((i) => i.id === 'measure') &&
          !this.hasMeasurement()
        ) {
          // if measure control is open, but no active measurement
          return true;
        }

        if (
          selectedItems.find((i) => i.id === 'compare') &&
          !this.hasCompare()
        ) {
          // compare control is open but no comparision
          return true;
        }

        return false;
      }

      case 'compare': {
        if (!onlyHasItemsOpen(['measure', 'compare'])) {
          return true;
        }

        if (
          selectedItems.find((i) => i.id === 'measure') &&
          !this.hasMeasurement()
        ) {
          // if measure control is open, but no active measurement
          return true;
        }

        return false;
      }

      case 'measure': {
        if (!onlyHasItemsOpen(['compare'])) {
          return true;
        }

        return false;
      }

      default:
        return !(
          selectedItems.length === 0 ||
          (selectedItems.length === 1 && currentItem === item)
        );
    }
  };

  private hasMeasurement() {
    const { measurementStatus } = this.state;

    return !!measurementStatus?.type && !!measurementStatus?.geoJson;
  }

  private hasCompare() {
    const { comapareStatus } = this.state;

    return !!comapareStatus?.comapreViewId;
  }

  private getItemLabel = (item: ControlTypes): string => {
    switch (item) {
      case 'mark_issue':
        return 'Mark Issue';
      case 'measure':
        return 'Measure';
      case 'info':
        return 'Info';
      case 'legend':
        return 'Legend';
      case 'compare':
        return 'Compare';
      case 'elevation_difference':
        return 'Elevation Difference';
      case 'share':
        return 'Share';
      case 'reports':
        return 'Reports';
      case 'download':
        return 'Download';
      case 'site_objects':
        return 'Detect';
      case 'add_shape':
        return 'Add Shape';
      case 'edit_shape':
        return 'Edit Shape';
      case 'remove_shape':
        return 'Remove Shape';
      default:
        return item;
    }
  };

  private isControlOpen(item: ControlTypes) {
    const { selectedItems } = this.state;
    const selectedItemIds = selectedItems.map((i) => i.id);

    return selectedItemIds.includes(item);
  }

  private closeControlBox = (item: ControlTypes): (() => void) => {
    return () => {
      if (this.isControlOpen(item)) {
        this.handleSelectItem(item, () => {
          if (item === 'mark_issue') {
            // if we opened controls because of an issue, we should close them
            const { issue } = this.props;

            if (issue) {
              this.setState({ currentItem: undefined, selectedItems: [] });
            }
          }
        });
      }
    };
  };

  private handleControlEvent = (e: ViewEvent<any>) => {
    if (e.type === 'measurement_changed') {
      this.setState({ measurementStatus: e.data });
    } else if (e.type === 'comapre_status_changed') {
      this.setState({ comapareStatus: e.data });
    } else {
      const { onEvent } = this.props;

      onEvent(e);
    }
  };

  private getItemControlBox = (item: ControlTypes) => {
    // TODO: figure out how to prevent MeasureControlBox re-render on state change in the View component
    switch (item) {
      case 'measure': {
        return {
          id: item,
          renderControlBox: () => {
            const { view, issue, splitView } = this.props;

            const type: MeasurementType = issue?.viewConfig?.measurementType;
            const volumePlaneElevation =
              issue?.viewConfig?.volumePlaneElevation || undefined;
            const subType: MeasurementSubType | undefined = volumePlaneElevation
              ? 'FlatPlane'
              : type === 'volume'
              ? 'BestFitPlane'
              : undefined;
            const geoJson = issue?.shapeGeoJson
              ? JSON.parse(issue?.shapeGeoJson)
              : undefined;

            return (
              <MeasureControlBox
                view={view}
                splitView={splitView}
                open
                enabled={!this.isItemDisabled(item)}
                onRenderFragChange={this.onRenderFragChange(item)}
                onClose={this.closeControlBox(item)}
                onEvent={this.handleControlEvent}
                type={type}
                subType={subType}
                geoJson={geoJson}
                planeElevation={volumePlaneElevation}
                hasLinkedIssue={!!type}
                measureTypeCallback={(e: any) =>
                  this.setState({ measureType: e })
                }
              />
            );
          },
        };
      }

      case 'mark_issue': {
        return {
          id: item,
          renderControlBox: () => {
            const { measurementStatus, comapareStatus } = this.state;
            const { view, issue, getViewConfig, userRole } = this.props;

            return (
              <IssueControlBox
                view={view}
                open
                enabled={!this.isItemDisabled(item)}
                onRenderFragChange={this.onRenderFragChange(item)}
                onClose={this.closeControlBox(item)}
                issue={issue}
                getViewConfig={getViewConfig}
                userRole={userRole}
                onEvent={this.handleControlEvent}
                measurementStatus={measurementStatus}
                compareStatus={comapareStatus}
              />
            );
          },
        };
      }

      case 'info': {
        return {
          id: item,
          renderControlBox: () => {
            const { view } = this.props;

            return (
              <InfoControlBox
                view={view}
                metadata={view.metadata || []}
                open
                enabled={!this.isItemDisabled(item)}
                onRenderFragChange={this.onRenderFragChange(item)}
                onClose={this.closeControlBox(item)}
                onEvent={this.handleControlEvent}
              />
            );
          },
        };
      }

      case 'download': {
        return {
          id: item,
          renderControlBox: () => {
            const { downloads, view, userRole } = this.props;

            return (
              <DownloadsControlBox
                view={view}
                downloads={downloads || []}
                open
                userRole={userRole}
                enabled={!this.isItemDisabled(item)}
                onRenderFragChange={this.onRenderFragChange(item)}
                onClose={this.closeControlBox(item)}
                onEvent={this.handleControlEvent}
              />
            );
          },
        };
      }

      case 'reports': {
        return {
          id: item,
          renderControlBox: () => {
            const { reports, view, userRole } = this.props;

            return (
              <ReportsControlBox
                view={view}
                reports={reports || []}
                open
                userRole={userRole}
                enabled={!this.isItemDisabled(item)}
                onRenderFragChange={this.onRenderFragChange(item)}
                onClose={this.closeControlBox(item)}
                onEvent={this.handleControlEvent}
              />
            );
          },
        };
      }

      case 'legend': {
        return {
          id: item,
          renderControlBox: () => {
            const { view, userRole, rendererState } = this.props;

            return (
              <LegendControlBox
                view={view}
                open
                userRole={userRole}
                enabled={!this.isItemDisabled(item)}
                onRenderFragChange={this.onRenderFragChange(item)}
                onClose={this.closeControlBox(item)}
                onEvent={this.handleControlEvent}
                rendererState={rendererState}
              />
            );
          },
        };
      }

      case 'share': {
        return {
          id: item,
          renderControlBox: () => {
            const { view, userRole, getViewConfig } = this.props;

            return (
              <ShareControlBox
                view={view}
                open
                userRole={userRole}
                enabled={!this.isItemDisabled(item)}
                onRenderFragChange={this.onRenderFragChange(item)}
                onClose={this.closeControlBox(item)}
                getViewConfig={getViewConfig}
                onEvent={this.handleControlEvent}
              />
            );
          },
        };
      }

      case 'compare': {
        return {
          id: item,
          renderControlBox: () => {
            const { view, viewList, userRole } = this.props;
            const { comapareStatus } = this.state;

            const linkedViews = (viewList || [])
              .filter((v) => v.type === 'map' && v.certifiedForDisplay)
              .filter((v) => {
                if (view.subType === 'ndvi') {
                  return v.subType === 'design' || v.subType === 'aerial';
                }

                return v.subType === 'design';
              });

            return (
              <CompareControlBox
                view={view}
                open
                userRole={userRole}
                enabled={!this.isItemDisabled(item)}
                onRenderFragChange={this.onRenderFragChange(item)}
                onClose={this.closeControlBox(item)}
                onEvent={this.handleControlEvent}
                designViews={linkedViews}
                compareStatus={comapareStatus}
              />
            );
          },
        };
      }

      case 'elevation_difference': {
        return {
          id: item,
          renderControlBox: () => {
            const { view, splitView, viewList, elevationDifferences, olView } =
              this.props;

            return (
              <ElevationDifferenceControlBox
                view={view}
                olView={olView}
                splitView={splitView}
                onClose={this.closeControlBox(item)}
                viewList={viewList}
                elevationDifferences={elevationDifferences}
              />
            );
          },
        };
      }

      case 'site_objects': {
        return {
          id: item,
          renderControlBox: () => {
            const {
              view,
              siteObjects,
              projectMLClasses,
              onEvent,
              rendererState,
            } = this.props;

            return (
              <SiteObjectsControlBox
                view={view}
                open
                rendererState={rendererState}
                enabled={!this.isItemDisabled(item)}
                onRenderFragChange={this.onRenderFragChange(item)}
                onClose={this.closeControlBox(item)}
                onEvent={onEvent}
                siteObjects={siteObjects || []}
                projectMLClasses={projectMLClasses || []}
                // TODO: ensure appropriate logic for admin access check
                hasAdminAccess
              />
            );
          },
        };
      }

      case 'add_shape': {
        return {
          id: item,
          renderControlBox: () => {
            const { layers, view, onEvent, rendererState } = this.props;
            const { selectedLayer } = this.state;

            return (
              <AddShapeControlBox
                rendererState={rendererState}
                selectedLayer={selectedLayer}
                layers={layers}
                view={view}
                onEvent={(e) => {
                  if (e.type === 'layer_select') {
                    this.setState({ selectedLayer: e.data as LayerDescriptor });
                  }

                  if (e.type === 'layer_clear')
                    this.setState({ selectedLayer: undefined });

                  onEvent(e);
                }}
                enabled={false}
                onClose={this.closeControlBox(item)}
                onRenderFragChange={this.onRenderFragChange(item)}
                open
              />
            );
          },
        };
      }

      case 'edit_shape': {
        return {
          id: item,
          renderControlBox: () => {
            const { layers, view, onEvent, rendererState } = this.props;
            const { selectedLayer } = this.state;

            return (
              <EditShapeControlBox
                rendererState={rendererState}
                selectedLayer={selectedLayer}
                layers={layers}
                view={view}
                onEvent={(e) => {
                  if (e.type === 'layer_select') {
                    this.setState({ selectedLayer: e.data as LayerDescriptor });
                  }

                  if (e.type === 'layer_clear')
                    this.setState({ selectedLayer: undefined });

                  onEvent(e);
                }}
                enabled={false}
                onClose={this.closeControlBox(item)}
                onRenderFragChange={this.onRenderFragChange(item)}
                open
              />
            );
          },
        };
      }

      case 'remove_shape': {
        return {
          id: item,
          renderControlBox: () => {
            const { layers, view, onEvent, rendererState } = this.props;
            const { selectedLayer } = this.state;

            return (
              <RemoveShapeControlBox
                rendererState={rendererState}
                selectedLayer={selectedLayer}
                layers={layers}
                view={view}
                onEvent={(e) => {
                  if (e.type === 'layer_select') {
                    this.setState({ selectedLayer: e.data as LayerDescriptor });
                  }

                  if (e.type === 'layer_clear')
                    this.setState({ selectedLayer: undefined });

                  onEvent(e);
                }}
                enabled={false}
                onClose={this.closeControlBox(item)}
                onRenderFragChange={this.onRenderFragChange(item)}
                open
              />
            );
          },
        };
      }

      default: {
        console.error('Could not find control box for specified item: ', item);

        return {
          id: item,
          renderControlBox: () => {
            return (
              <ControlBox.Wrapper
                renderHeader={() => (
                  <React.Fragment>
                    <ControlBox.Title title={this.getItemLabel(item)} />
                    <ControlBox.Icon
                      onClick={this.closeControlBox(item)}
                      name="close"
                    />
                  </React.Fragment>
                )}
              />
            );
          },
        };
      }
    }
  };

  private renderMenuItems = (): JSX.Element[] | null => {
    const { items } = this.props;
    const { selectedItems } = this.state;
    const selectedItemIds = selectedItems.map((item) => item.id);

    if (!items) {
      return null;
    }

    return items.map((item) => {
      const selected = selectedItemIds.indexOf(item) > -1;
      const disabled = this.isItemDisabled(item);

      return (
        <div
          id={`${item}`}
          key={item}
          onClick={() => {
            if (disabled) {
              return;
            }

            this.handleSelectItem(item);
          }}
          className={classnames(style.menuItem, {
            [style.active]: selected,
            [style.disabled]: disabled,
          })}
        >
          <div>
            <Icon name={item} />
          </div>

          {this.getItemLabel(item)}
        </div>
      );
    });
  };

  private RenderMenuContentWrapper = (): JSX.Element | null => {
    const { selectedItems, currentItem } = this.state;

    if (!selectedItems || selectedItems.length < 1) {
      return null;
    }

    // MapMaker view controls don't need multi-tab support
    if (
      selectedItems.length === 1 &&
      ['add_shape', 'edit_shape', 'remove_shape'].indexOf(selectedItems[0].id) >
        -1
    ) {
      return (
        <div
          className={classnames(style.controlBoxWrapper, style.mapMakerControl)}
        >
          {selectedItems[0].renderControlBox()}
        </div>
      );
    }

    return (
      <div className={style.controlBoxWrapper}>
        <Tabs
          defaultActiveKey={selectedItems[0].id}
          onChange={this.handleTabChange}
          activeKey={currentItem}
        >
          {selectedItems.map((a) => {
            return (
              <TabPane tab={this.getItemLabel(a.id)} key={a.id} forceRender>
                {a.renderControlBox()}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    );
  };

  public render(): React.ReactNode {
    const { className } = this.props;

    const { RenderMenuContentWrapper } = this;

    return (
      <div className={classnames(style.container, className)}>
        <div className={style.menu}>{this.renderMenuItems()}</div>
        <RenderMenuContentWrapper />
      </div>
    );
  }
}

export default ViewControls;
