import autobind from 'autobind-decorator';
import * as deepEqual from 'deep-equal';
import * as React from 'react';
import ReactMapboxGl, {
  RotationControl,
  ScaleControl,
  ZoomControl,
} from 'react-mapbox-gl';
import './index.module.scss';
import { GenericObjectType } from '../../shapes/app';
import { MAPBOX_STYLE_JIBIN_2_URL } from '../../constants/urls';

export interface IMapboxProps {
  minZoom?: number;
  maxZoom?: number;
  zoom?: [number] | undefined;
  defaultZoom?: [number] | undefined;
  center?: number[] | undefined;
  defaultCenter?: number[] | undefined;
  containerStyle?: any;
  showControls?: boolean;
  showZoom?: boolean;
  showRotate?: boolean;
  children?: any;
  onZoomEnd?: any;
  onDragEnd?: any;
  onZoom?: any;
  onDrag?: any;
  onMove?: any;
  className?: string;
  accessToken: string;
  onMapInit?: (map: any) => void;
  style?: string | null;
  fitBounds?: any;
  onContextMenuClick?: (event: GenericObjectType) => void;
}

export interface IMapboxState {
  zoom?: [number] | undefined;
  center?: number[] | undefined;
}

export default class MapboxView extends React.Component<
  IMapboxProps,
  IMapboxState
> {
  public static defaultProps: Partial<IMapboxProps> = {
    showControls: false,
  };

  private MapboxGL: any;

  public constructor(props: IMapboxProps) {
    super(props);
    const {
      zoom,
      center,
      defaultZoom,
      defaultCenter,
      minZoom,
      maxZoom,
      accessToken,
    } = props;

    this.state = {
      zoom: zoom || defaultZoom,
      center: center || defaultCenter,
    };

    this.MapboxGL = ReactMapboxGl({
      accessToken,
      preserveDrawingBuffer: true,
      dragRotate: false,
      minZoom,
      maxZoom,
    });
  }

  public UNSAFE_componentWillReceiveProps({
    zoom: zoomNext,
    center: centerNext,
  }: IMapboxProps): void {
    const { zoom, center } = this.props;

    if (!deepEqual(zoom, zoomNext) || !deepEqual(center, centerNext)) {
      this.setState({
        zoom: zoomNext,
        center: centerNext,
      });
    }
  }

  @autobind
  private handleZoom(map: any, event: any): void {
    const { onZoom } = this.props;
    const center = map.getCenter();
    const zoom = map.getZoom();

    this.setState(
      {
        zoom: [zoom],
        center,
      },
      onZoom
        ? () => {
            onZoom(map, event);
          }
        : undefined
    );
  }

  @autobind
  private handleDrag(map: any, event: any): void {
    const { onDrag } = this.props;
    const center = map.getCenter();

    this.setState(
      {
        center,
      },
      onDrag
        ? () => {
            onDrag(map, event);
          }
        : undefined
    );
  }

  public render(): React.ReactNode {
    const {
      containerStyle,
      showControls,
      showZoom,
      showRotate,
      children,
      className,
      style,
      onMapInit,
      onContextMenuClick,
      ...rest
    } = this.props;

    const { center, zoom } = this.state;
    const { MapboxGL } = this;

    return (
      <MapboxGL
        onStyleLoad={(map: GenericObjectType) => {
          if (onMapInit) {
            onMapInit(map);
          }
        }}
        onContextMenu={(
          map: GenericObjectType,
          event: React.SyntheticEvent<any>
        ) => {
          if (onContextMenuClick) {
            onContextMenuClick(event);
          }
        }}
        className={className}
        movingMethod="jumpTo"
        containerStyle={{
          ...containerStyle,
          height: '100%',
          width: '100%',
        }}
        center={center}
        zoom={zoom}
        onZoomEnd={this.handleZoom}
        onDrag={this.handleDrag}
        style={style || MAPBOX_STYLE_JIBIN_2_URL}
        {...rest}
        trackResize
      >
        {children}
        {showControls && showRotate && (
          <RotationControl
            style={{ marginTop: 5, border: 'none', marginBottom: 10 }}
            position="bottom-right"
          />
        )}
        {showControls && showZoom && (
          <ZoomControl style={{ border: 'none' }} position="bottom-right" />
        )}
        {showControls && (
          <ScaleControl
            style={{
              background: 'none',
              boxShadow: 'none',
              border: 'none',
              marginRight: 30,
            }}
            position="bottom-right"
          />
        )}
      </MapboxGL>
    );
  }
}
