import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import PlotGoogleMap from 'src/components/PlotGoogleMap';
import ImageRenderer from 'src/components/View/ImageView/ImageRenderer';
import {
  ImageAnnotationControl,
  PIXEL_SOURCE,
} from 'src/components/View/ImageView/ImageRenderer/ImageAnnotations';
import {
  APP_PRIMARY_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_COLOR,
} from 'src/constants/colors';

import styles from './gcps.module.scss';

interface ReviewProps {
  boundaryList: any;
  images: any[];
  gcps: any[];
  selectedGcp: string;
  markedProjections: any[];
  onProjectionRemoved: (projectionId: string) => void;
  onGcpSelected: (id: string) => void;
  onMarkingFinish: () => void;
}

export const Review: React.FC<ReviewProps> = ({
  boundaryList,
  images,
  gcps: allGcps,
  selectedGcp,
  markedProjections,
  onProjectionRemoved,
  onGcpSelected,
  onMarkingFinish,
}) => {
  const [confirmFinish, setConfirmFinish] = useState<boolean>(false);

  const gcps = allGcps.filter((g) => g.latlon);
  const gcpMarkers: any[] = gcps.map((g, index) => {
    return {
      pos: {
        lat: g.latlon[1],
        lng: g.latlon[0],
      },
      index,
      icon: {
        selected: {
          types: ['DEFAULT'],
          color: APP_PRIMARY_COLOR,
          ignoreMarkerClusterer: false,
        },
        unselected: {
          types: ['DEFAULT'],
          color: GOOGLE_MAP_MARKER_DEFAULT_COLOR,
          ignoreMarkerClusterer: false,
        },
      },
      label: {
        text: g.name,
      },
    };
  });
  const selectedMarkerIndex = (gcps || []).findIndex(
    (g: any) => g.id === selectedGcp
  );

  return (
    <div className={styles.gcpListContainer}>
      <div className={styles.gcpsMapColumn}>
        <div className={styles.gcpsMap}>
          <PlotGoogleMap
            width="100%"
            height="100%"
            boundaryPointsList={boundaryList ? [boundaryList] : null}
            markerPointsList={gcpMarkers}
            onMarkerClick={(idx: number) => onGcpSelected(gcps[idx].id)}
            selectedMarkerIndex={selectedMarkerIndex}
            centerMapTo="boundary"
            markerClustererMaxZoom={20}
            gestureHandling="greedy"
            maintainZoomLevel
          />
        </div>
        <div className={styles.gcpsControls}>
          <Button type="primary" onClick={() => setConfirmFinish(true)}>
            Finish Marking
          </Button>
        </div>
      </div>
      <div className={styles.gcpList}>
        <div className={styles.reviewGrid}>
          {markedProjections.map((projection) => {
            const image = images.find((i) => i.guid === projection.imageGuid);
            const projectionId = projection.id;

            if (image) {
              return (
                <div className={styles.reviewItem}>
                  <div style={{ height: '2em' }}>
                    <a onClick={() => onProjectionRemoved(projectionId)}>
                      Delete
                    </a>
                    <span style={{ marginLeft: '2em' }}>
                      Guid: {image.guid}
                    </span>
                  </div>
                  <div style={{ height: 'calc(100% - 2em)', width: '100%' }}>
                    <ImageRenderer
                      image={image}
                      rotation={0}
                      viewConfig={{
                        latitude: -projection.pixelY,
                        longitude: projection.pixelX,
                        zoom: 6,
                      }}
                    >
                      {projection && (
                        <ImageAnnotationControl
                          annotationsData={{
                            annotations: [
                              {
                                center: {
                                  x: projection.pixelX,
                                  y: -projection.pixelY,
                                },
                                source: PIXEL_SOURCE.user,
                              },
                            ],
                          }}
                        />
                      )}
                    </ImageRenderer>
                  </div>
                </div>
              );
            }

            return <></>;
          })}
        </div>
      </div>
      <Modal
        visible={confirmFinish}
        onOk={() => {
          setConfirmFinish(false);
          onMarkingFinish();
        }}
        onCancel={() => setConfirmFinish(false)}
        afterClose={() => setConfirmFinish(false)}
      >
        Are you sure you want to finish and update the workflow to use current
        GCPs and projections?
      </Modal>
    </div>
  );
};
