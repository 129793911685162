// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Parameters_container__852-B .Parameters_interPolationParamsInputWrapper__tGweV{align-items:center;display:flex;width:100%}.Parameters_container__852-B .Parameters_interPolationParamsInputWrapper__tGweV .Parameters_inputField__m5yHu{width:320px}.Parameters_container__852-B .Parameters_titleWrapper__RIAVn{padding-bottom:10px}.Parameters_container__852-B .Parameters_generateBtn__JXXWC{margin-top:15px;width:320px}", "",{"version":3,"sources":["webpack://./src/styles/scss/base/app-extends/_aoi-data.scss","webpack://./src/components/TerrainMaps/TerrainMapsTabsLayout1/Parameters.module.scss"],"names":[],"mappings":"AAUA,gFACuB,kBAAA,CAMrB,YAAA,CACA,UAAA,CAEA,8GACE,WAAA,CClBF,6DACE,mBAAA,CAOF,4DACE,eAAA,CACA,WAAA","sourcesContent":["%aoi-data-crud-container {\n  @include margin-auto();\n\n  background-color: $projectCrudContainerBgColor;\n  max-width: #{$projectCrudContainerMaxWidth}px;\n  width: 90%;\n  padding: 15px;\n  min-height: 500px;\n}\n\n%aoi-data-crud-input-field-wrapper {\n  @include align-items(center);\n\n  display: -webkit-box;\n  display: -moz-box;\n  display: -ms-flexbox;\n  display: -webkit-flex;\n  display: flex;\n  width: 100%;\n\n  .inputField {\n    width: #{$projectCrudContainerElementMaxWidth}px;\n  }\n}\n","@import 'src/styles/scss/base/base';\n\n.container {\n  .titleWrapper {\n    padding-bottom: 10px;\n  }\n\n  .interPolationParamsInputWrapper {\n    @extend %aoi-data-crud-input-field-wrapper;\n  }\n\n  .generateBtn {\n    margin-top: 15px;\n    width: #{$projectCrudContainerElementMaxWidth}px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Parameters_container__852-B",
	"interPolationParamsInputWrapper": "Parameters_interPolationParamsInputWrapper__tGweV",
	"inputField": "Parameters_inputField__m5yHu",
	"titleWrapper": "Parameters_titleWrapper__RIAVn",
	"generateBtn": "Parameters_generateBtn__JXXWC"
};
export default ___CSS_LOADER_EXPORT___;
