// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FloorLevelManager_successMessage__D1Hmm{color:green}.FloorLevelManager_errorMessage__jykfw{color:red}.FloorLevelManager_uploader__hI\\+Da{display:flex;flex-direction:column;justify-content:center;align-items:center}.FloorLevelManager_uploadButton__izcy2{display:block}.FloorLevelManager_imageValidationError__x4Yxz{background-color:#ce0008;color:#f5f5f5;font-size:large}", "",{"version":3,"sources":["webpack://./src/components/Interior360Manager/FloorLevelManager/index.module.scss"],"names":[],"mappings":"AAAA,yCACE,WAAA,CAGF,uCACE,SAAA,CAGF,oCACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAGF,uCACE,aAAA,CAGF,+CACE,wBAAA,CACA,aAAA,CACA,eAAA","sourcesContent":[".successMessage {\n  color: green;\n}\n\n.errorMessage {\n  color: red;\n}\n\n.uploader {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.uploadButton {\n  display: block;\n}\n\n.imageValidationError {\n  background-color: #ce0008;\n  color: whitesmoke;\n  font-size: large;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successMessage": "FloorLevelManager_successMessage__D1Hmm",
	"errorMessage": "FloorLevelManager_errorMessage__jykfw",
	"uploader": "FloorLevelManager_uploader__hI+Da",
	"uploadButton": "FloorLevelManager_uploadButton__izcy2",
	"imageValidationError": "FloorLevelManager_imageValidationError__x4Yxz"
};
export default ___CSS_LOADER_EXPORT___;
