import ApiBase from './ApiBase';
import {
  GenericApisReturnPromiseTypes,
  GenericObjectType,
} from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_NAVIGATOR_API_URL } from '../constants/urls';

export default class ViewToolsApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_NAVIGATOR_API_URL);

    this._baseUrl = `/v1`;
  }

  public getTargetImageForInspection(
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.post(`${this._baseUrl}/imagelocator`, formData)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the nearest image from the view coordinates.`,
        });
      });
  }

  public checkCameraArtifact(formData: {
    viewId: string;
  }): GenericApisReturnPromiseTypes {
    return this.post(`${this._baseUrl}/checkCameraArtifact`, formData)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to check the camera artifact.`,
        });
      });
  }
}
