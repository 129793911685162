import { Modal, Popconfirm, Table, Typography } from 'antd';
import * as React from 'react';
import { BASE_NAVIGATOR_API_URL } from '../../../constants/urls';
import GcpMarkingApis from '../../../api/gcpMarking';
import { GCP_MARKERS_PAGINATION_DEFAULT_PAGE_SIZE } from '../../../constants';
import { GenericObjectType } from '../../../shapes/app';
import { undefinedOrNull } from '../../../utils/functs';
import { sortByName } from '../../../utils/helpers';
import { Button } from '../../Button';
import PlotGoogleMap from '../../PlotGoogleMap';
import { PlotGoogleMapMarkerPointsTypes } from '../../PlotGoogleMap/index.types';
import styles from './index.module.scss';
import {
  GcpMarkingListingPropsTypes,
  GcpMarkingListingStateTypes,
} from './index.types';

const { Title } = Typography;
const gcpMarkingApis = new GcpMarkingApis();

class GcpMarkingListing extends React.PureComponent<
  GcpMarkingListingPropsTypes,
  GcpMarkingListingStateTypes
> {
  public constructor(props: GcpMarkingListingPropsTypes) {
    super(props);

    this.state = {
      markerPointsList: [],
      selectedMarkerIndex: 0,
      mapGeneratedTime: Date.now(),
      gcpImagesList: [],
    };
  }

  public componentDidMount(): void {
    const { gcpListData } = this.props;

    this.setMarkerPoints();
    this.getGcpImages(gcpListData);
  }

  public componentDidUpdate({
    gcpListData: prevGcpListData,
  }: Readonly<GcpMarkingListingPropsTypes>): void {
    const { gcpListData } = this.props;

    if (gcpListData !== prevGcpListData) {
      this.setMarkerPoints();
      this.getGcpImages(gcpListData);
    }
  }

  public getGcpImages = (gcpList: any[]) => {
    if (!gcpList || gcpList.length === 0) {
      return;
    }

    const { gcpPlanId } = gcpList[0];

    fetch(`${BASE_NAVIGATOR_API_URL}/v1/marker_image_list/${gcpPlanId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({ gcpImagesList: data });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  private setMarkerPoints = () => {
    const { gcpListData } = this.props;

    const filteredGcpListData: PlotGoogleMapMarkerPointsTypes[] = (
      gcpListData || []
    )
      .filter(
        (a) => !(undefinedOrNull(a.latitude) || undefinedOrNull(a.longitude))
      )
      .map((a, index) => {
        return {
          pos: {
            lat: parseFloat(a.latitude),
            lng: parseFloat(a.longitude),
          },
          index,
          icon: {
            selected: {
              types: ['FLAG_TRIANGLE_FILLED'],
              color: !a.markingComplete ? 'yellow' : 'blue',
              ignoreMarkerClusterer: false,
            },
            unselected: {
              types: ['FLAG_TRIANGLE_FILLED'],
              color: !a.markingComplete ? 'yellow' : 'blue',
              ignoreMarkerClusterer: false,
            },
          },
          piggyback: {
            gcpId: a.id,
          },
          label: {
            text: a.name,
            showMinZoom: 18,
          },
        };
      });

    this.setState({
      mapGeneratedTime: Date.now(),
      markerPointsList: filteredGcpListData || [],
    });
  };

  private disableFinishMarkingAllBtn = () => {
    const { gcpListData } = this.props;

    if (undefinedOrNull(gcpListData)) {
      return true;
    }

    const filteredGcpListData = gcpListData.filter(
      (a) => !a.markingComplete && !a.disabled
    );

    return !(filteredGcpListData && undefinedOrNull(filteredGcpListData[0]));
  };

  private handleDisableGcp = (sessionId: string, disabled: boolean) => {
    const { showSnackbar, fetchGcpsBySessions } = this.props;

    if (!undefinedOrNull(sessionId)) {
      const formData = {
        disabled: !disabled,
      };

      gcpMarkingApis.updateMarkingGcp(sessionId, formData).then((res) => {
        const { error: apiError } = res;

        if (apiError) {
          showSnackbar({
            type: 'error',
            body: apiError,
          });

          return;
        }

        fetchGcpsBySessions();

        showSnackbar({
          type: 'success',
          body: 'The GCP was updated successfully',
        });
      });
    }
  };

  private handleChangeCheckpoint = (sessionId: string, checkpoint: boolean) => {
    const { showSnackbar, fetchGcpsBySessions } = this.props;

    if (!undefinedOrNull(sessionId)) {
      const formData = {
        checkpoint: !checkpoint,
      };

      gcpMarkingApis.updateMarkingGcp(sessionId, formData).then((res) => {
        const { error: apiError } = res;

        if (apiError) {
          showSnackbar({
            type: 'error',
            body: apiError,
          });

          return;
        }

        fetchGcpsBySessions();

        showSnackbar({
          type: 'success',
          body: 'The GCP was updated successfully',
        });
      });
    }
  };

  private handleMarkerClick = (index: number) => {
    const { onGcpMarkingListTableRowClick } = this.props;
    const { markerPointsList } = this.state;

    this.setState(
      {
        selectedMarkerIndex: index,
      },
      () => {
        if (
          !undefinedOrNull(markerPointsList) &&
          !undefinedOrNull(markerPointsList[index])
        ) {
          const selectedMarkerPoint = markerPointsList[index];

          if (!undefinedOrNull(selectedMarkerPoint.piggyback)) {
            onGcpMarkingListTableRowClick(selectedMarkerPoint.piggyback.gcpId);
          }
        }
      }
    );
  };

  private handleOk = () => {
    this.setState({ previewGcpGuid: undefined });
  };

  private handleCancel = () => {
    this.setState({ previewGcpGuid: undefined });
  };

  public render(): React.ReactNode {
    const {
      gcpListData,
      gcpListLoading,
      onGcpMarkingListTableRowClick,
      finalizeBtnLoading,
      onGcpMarkingFinalizeBtnClick,
      rmsErrorListData,
      boundaryListData,
    } = this.props;
    const {
      markerPointsList,
      selectedMarkerIndex,
      mapGeneratedTime,
      previewGcpGuid,
      gcpImagesList,
    } = this.state;

    const gcpMarkingListTableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (value: string) => value,
      },

      {
        title: 'Preview',
        dataIndex: 'id',
        key: 'preview',
        render: (id: any) => {
          return (
            <a
              onClick={(e: any) => {
                e.stopPropagation();
                this.setState({
                  previewGcpGuid: id,
                });
              }}
            >
              <i className="fa fa-picture-o" aria-hidden="true" />
            </a>
          );
        },
      },

      {
        title: 'Type',
        dataIndex: 'checkpoint',
        key: 'checkpoint',
        render: (value: string) => {
          if (value) {
            return 'ICP';
          }

          return 'GCP';
        },
      },
      {
        title: 'X, Y, Z',
        dataIndex: 'x',
        key: 'x',
        render: (value: string, data: null | GenericObjectType) => {
          if (!data) {
            return '--';
          }

          return `${!undefinedOrNull(data.x) ? data.x : '-'}, ${
            !undefinedOrNull(data.y) ? data.y : '-'
          }, ${!undefinedOrNull(data.z) ? data.z : '-'}`;
        },
      },
      {
        title: 'RMS Error',
        dataIndex: 'id',
        key: 'rmsError',
        render: (value: string) => {
          if (
            undefinedOrNull(rmsErrorListData) ||
            undefinedOrNull(rmsErrorListData.markers)
          ) {
            return '--';
          }

          const filteredRmsErrorListData = rmsErrorListData.markers.filter(
            (a: GenericObjectType) => a.id === value
          );

          if (
            undefinedOrNull(filteredRmsErrorListData) ||
            undefinedOrNull(filteredRmsErrorListData[0])
          ) {
            return '--';
          }

          return filteredRmsErrorListData[0].error;
        },
      },
      {
        title: `Status`,
        dataIndex: 'markingComplete',
        key: 'markingComplete',
        render: (value: string) => (value ? 'Completed' : 'Pending'),
      },
      {
        title: `Actions`,
        dataIndex: 'id',
        key: 'id',
        render: (value: string, data: GenericObjectType) => (
          <div
            className={styles.tableActionCtaWrapper}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Popconfirm
              title={`Are you sure want to ${
                data.disabled ? 'enable' : 'disable'
              } the GCP?`}
              okText="Yes"
              cancelText="Cancel"
              onConfirm={() => this.handleDisableGcp(value, data.disabled)}
            >
              <span className={styles.tableActionCtaItem}>
                {data.disabled ? 'Enable' : 'Disable'}
              </span>
            </Popconfirm>
            <Popconfirm
              title={`Are you sure want to convert this ${
                data.checkpoint ? 'ICP' : 'GCP'
              } to ${!data.checkpoint ? 'ICP' : 'GCP'} ?`}
              okText="Yes"
              cancelText="Cancel"
              onConfirm={() =>
                this.handleChangeCheckpoint(value, data.checkpoint)
              }
            >
              <span className={styles.tableActionCtaItem}>
                Convert to &nbsp;
                {!data.checkpoint ? 'ICP' : 'GCP'}
              </span>
            </Popconfirm>
          </div>
        ),
      },
    ];

    return (
      <div className={styles.container}>
        <Title level={2}>Review Projections</Title>

        <div className={styles.splitRowContainer}>
          <div className={styles.googleMapWrapper}>
            <PlotGoogleMap
              mapGeneratedTime={mapGeneratedTime}
              width="400px"
              height="300px"
              boundaryPointsList={boundaryListData ? [boundaryListData] : null}
              markerPointsList={markerPointsList}
              selectedMarkerIndex={selectedMarkerIndex}
              onMarkerClick={this.handleMarkerClick}
              centerMapTo="boundary"
              initialZoom={16}
              showHaloMarker={false}
              markerClustererMaxZoom={18}
              gestureHandling="greedy"
            />
          </div>

          <div className={styles.gcpMarkingListTableWrapper}>
            <Table
              locale={{
                emptyText: 'No GCPs available. Please upload the GCP data.',
              }}
              onRow={(record: GenericObjectType) => {
                return {
                  onClick: () => {
                    const filteredRecord = Object.keys(record).filter(() => {
                      return record.disabled;
                    });

                    if (filteredRecord && filteredRecord[0]) {
                      return;
                    }

                    onGcpMarkingListTableRowClick(record.id);
                  },
                };
              }}
              rowKey={(item: any) => item.id}
              loading={gcpListLoading}
              columns={gcpMarkingListTableColumns}
              dataSource={sortByName(gcpListData, 'name')}
              pagination={{
                defaultPageSize: GCP_MARKERS_PAGINATION_DEFAULT_PAGE_SIZE,
              }}
            />
          </div>
        </div>

        <div className={styles.ctaWrapper}>
          <Button
            disabled={this.disableFinishMarkingAllBtn()}
            text="Finish Marking All GCPs"
            loadingText="Finalizing..."
            loading={finalizeBtnLoading}
            type="primary"
            onClick={() => {
              onGcpMarkingFinalizeBtnClick();
            }}
          />
        </div>

        {previewGcpGuid ? (
          <Modal
            title="Images captured from navigator"
            visible={!!previewGcpGuid}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            <div className="row">
              <div className="column" style={{ display: 'flex' }}>
                {gcpImagesList
                  .filter((gcpImage: any) => gcpImage.gcpId === previewGcpGuid)
                  .map((gcpImage: any) => (
                    <img
                      src={`${BASE_NAVIGATOR_API_URL}/v1/marker_image/${gcpImage.guid}`}
                      alt="navigator captured images"
                      style={{ width: 100, height: 100, padding: 5 }}
                    />
                  ))}
                {gcpImagesList.filter(
                  (gcpImage: any) => gcpImage.gcpId === previewGcpGuid
                ).length === 0 ? (
                  <p>No images found for this GCP</p>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default GcpMarkingListing;
