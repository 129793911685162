import * as math from 'mathjs';

export function xRotation(vector, theta) {
  const R = [
    [1, 0, 0],
    [0, Math.cos(theta), -Math.sin(theta)],
    [0, Math.sin(theta), Math.cos(theta)]
  ];

  return math.multiply(R, vector);
}

export function yRotation(vector, theta) {
  const R = [
    [Math.cos(theta), 0, Math.sin(theta)],
    [0, 1, 0],
    [-Math.sin(theta), 0, Math.cos(theta)]
  ];

  return math.multiply(R, vector);
}

export function zRotation(vector, theta) {
  const R = [
    [Math.cos(theta), -Math.sin(theta), 0],
    [Math.sin(theta), Math.cos(theta), 0],
    [0, 0, 1]
  ];

  return math.multiply(R, vector);
}

export function normalize(vector) {
  const mag =
    Math.pow(vector[0], 2) + Math.pow(vector[1], 2) + Math.pow(vector[2], 2);
  const sqrtMag = Math.sqrt(mag);

  return [vector[0] / sqrtMag, vector[1] / sqrtMag, vector[2] / sqrtMag];
}
