// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ElevationCalculator_container__Lo5by{width:auto}.ElevationCalculator_label__Gl5ol label{color:rgba(255,255,255,.8)}", "",{"version":3,"sources":["webpack://./src/components/ElevationCalculator/ElevationCalculator.module.scss"],"names":[],"mappings":"AAEA,sCACE,UAAA,CAGF,wCACE,0BAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  width: auto;\n}\n\n.label label {\n  color: rgba(255, 255, 255, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ElevationCalculator_container__Lo5by",
	"label": "ElevationCalculator_label__Gl5ol"
};
export default ___CSS_LOADER_EXPORT___;
