import { matchPath } from 'react-router-dom';
import { DRAWING_EDIT_PATH, VIEW_PATH } from '../routes/paths';
import { ViewType } from '../api/views.types';
import { log } from './log';
import { undefinedOrNull } from './functs';

export const setLocalStorageValue = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageValue = (key: string) => {
  return localStorage.getItem(key);
};

export const getViewCertifiedOnlyAoiList = () => {
  let aoiList: string[] = [];

  try {
    aoiList = JSON.parse(getLocalStorageValue('viewCertifiedOnly') || '[]');
  } catch (e) {
    log.error('Error parsing viewCertifiedOnly data in local storage');
  }

  return Array.isArray(aoiList) ? aoiList : [];
};

export const setCertifiedOnly = (aoiId: string, flagVal: boolean) => {
  let aoiList = getViewCertifiedOnlyAoiList();

  if (flagVal && aoiList.indexOf(aoiId) <= -1) {
    aoiList.push(aoiId);
  } else if (!flagVal && aoiList.indexOf(aoiId) > -1) {
    aoiList = aoiList.filter((eachAoi) => {
      return eachAoi !== aoiId;
    });
  }

  setLocalStorageValue('viewCertifiedOnly', JSON.stringify(aoiList));
};

export const showCertifiedOnly = (aoiId: string) => {
  const aoiList = getViewCertifiedOnlyAoiList();

  return aoiList.indexOf(aoiId) > -1;
};

export const getValidCoachMarkUrl = (url: string): string | undefined => {
  // path with most number of matching parameters will be chosen
  const pathList = ['/project/:projectId', DRAWING_EDIT_PATH];

  let finalPath: string | undefined;
  let paramCount = 0;

  pathList.forEach((path) => {
    const match = matchPath(url, {
      path,
      exact: true,
      strict: false,
    });

    if (match?.params && Object.keys(match.params).length > paramCount) {
      finalPath = match.path;
      paramCount = Object.keys(match.params).length;
    }
  });

  return finalPath;
};

export const setCoachMarkPreference = (url: string, viewType?: ViewType) => {
  const genericURL = getValidCoachMarkUrl(url);

  if (!undefinedOrNull(genericURL)) {
    setLocalStorageValue(genericURL, 'hide');
  }

  if (!undefinedOrNull(viewType)) {
    setLocalStorageValue(`${VIEW_PATH}:${viewType}`, 'hide');
  }
};

export const getCoachMarkPreference = (_url: string, _viewType?: ViewType) => {
  // Disabling auto-trigger for coachMarks. You can enable it by un-commenting
  // the lines below and commenting out the last line `return 'hide'`

  // const genericURL = getValidCoachMarkUrl(url);
  //
  // if (!undefinedOrNull(genericURL)) {
  //   return getLocalStorageValue(genericURL);
  // }
  //
  // if (!undefinedOrNull(viewType)) {
  //   return getLocalStorageValue(`${VIEW_PATH}:${viewType}`);
  // }
  //
  // return null;

  return 'hide';
};
