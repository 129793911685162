import { FETCH_PROJECTS_FULFILLED, FETCH_PROJECTS_PENDING } from '../actions';

const initialState = {
  loadedAll: false,
  loaded: false,
  loading: false,
  updating: false,
  data: [],
  filter: '',
  filteredProjects: [],
  createdProject: null,
  boundary: null,
  aoi: null,
  scrollPosition: null,
};

const addProjectToList = (list: any, proj: any): any => {
  if (!proj) {
    return list || [];
  }

  if (!list) {
    return [proj];
  }

  // @ts-ignore
  return [proj, ...list.filter((p) => p.id !== proj.id)];
};

const updateAOIForProject = (
  list: any,
  projectId: string,
  aoi: string | any
): any => {
  if (!projectId || !aoi) {
    return list || [];
  }

  if (!list) {
    return [];
  }

  // @ts-ignore
  const project = list.find((p) => p.id === projectId);

  if (!project) {
    return list || [];
  }

  // depending on reference to update project object in list
  project.aois = [
    aoi,
    ...project.aois.filter((a: { id: any }) => a.id !== aoi.id),
  ];

  return list;
};

export default function projects(state = initialState, action: any): any {
  const { type, payload } = action;

  switch (type) {
    case FETCH_PROJECTS_PENDING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_PROJECTS_FULFILLED:
      return {
        ...state,
        loadedAll: true,
        loading: false,
        data: payload,
        filteredProjects: payload,
      };

    case 'FETCH_PROJECT_FULFILLED':
      return {
        ...state,
        data: addProjectToList(state ? state.data : [], payload),
        filteredProjects: addProjectToList(state ? state.data : [], payload),
      };

    case 'FETCH_PROJECT_REJECTED':
      return { ...state };

    case 'CREATE_PROJECT_INIT':
      return {
        ...state,
        createdProject: null,
      };

    case 'CREATE_PROJECT_FULFILLED':
      return {
        ...state,
        data: state.data ? [payload, ...state.data] : [payload],
        filteredProjects: state.data ? [payload, ...state.data] : [payload],
        createdProject: payload,
      };

    case 'CREATE_AOI_PENDING':
      return {
        ...state,
        aoi: null,
        loading: true,
      };

    case 'CREATE_AOI_REJECTED':
      return {
        ...state,
        aoi: null,
        loading: false,
      };

    case 'CREATE_AOI_FULFILLED':
      return {
        ...state,
        aoi: payload,
        data: updateAOIForProject(
          state ? state.data : [],
          payload.projectId,
          payload
        ),
        filteredProjects: updateAOIForProject(
          state ? state.filteredProjects : [],
          payload.projectId,
          payload
        ),
        loading: false,
      };

    case 'SET_PROJECTS_FILTER': {
      const filter = payload;
      const filtered = (state.data || []).filter((p) => {
        if (!filter) {
          return true;
        }

        // @ts-ignore
        return `${p.name}`.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      });

      return {
        ...state,
        filter,
        filteredProjects: filtered,
      };
    }

    case 'CLEAR_PROJECTS_FILTER': {
      return {
        ...state,
        filter: '',
        filteredProjects: state.data,
      };
    }

    case 'CLEAR_PROJECTS': {
      return {
        ...state,
        data: [],
        filteredProjects: [],
      };
    }

    case 'UPDATE_AOI_PENDING':
    case 'UPDATE_PROJECT_PENDING': {
      return {
        ...state,
        updating: true,
      };
    }

    case 'UPDATE_AOI_REJECTED':
    case 'UPDATE_PROJECT_REJECTED': {
      return {
        ...state,
        updating: false,
      };
    }

    case 'UPDATE_PROJECT_FULFILLED': {
      return {
        ...state,
        data: addProjectToList(state ? state.data : [], payload),
        filteredProjects: addProjectToList(state ? state.data : [], payload),
        updating: false,
      };
    }

    case 'UPDATE_PROJECT_MANUALLY': {
      return {
        ...state,
        data: addProjectToList(state ? state.data : [], payload),
        filteredProjects: addProjectToList(state ? state.data : [], payload),
      };
    }

    case 'UPDATE_AOI_FULFILLED': {
      return {
        ...state,
        data: updateAOIForProject(
          state ? state.data : [],
          payload.projectId,
          payload.data
        ),
        filteredProjects: updateAOIForProject(
          state ? state.filteredProjects : [],
          payload.projectId,
          payload.data
        ),
        updating: false,
      };
    }

    case 'FETCH_AOI_BOUNDARY_FULFILLED':
    case 'FETCH_PROJECT_BOUNDARY_FULFILLED': {
      return {
        ...state,
        boundary: payload,
      };
    }

    case 'UPDATE_SCROLL_POSITION': {
      return {
        ...state,
        scrollPosition: payload,
      };
    }

    default:
      return state;
  }
}
