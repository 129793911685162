import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Select } from 'antd';
import AoiV2Apis from 'src/api/aoiV2';
import {
  CreateMapStyleRequest,
  EditMapStyleRequest,
  MapStyleDetails,
} from 'src/api/mapStyle.types';
import { Button } from 'src/components/Button';
import { CenteredContent } from 'src/components/CenteredContent';
import {
  MapStyleContext,
  useMapStyleApi,
} from 'src/components/Mission/MapMaker/hooks';
import SkeletonLoader from 'src/components/SkeletonLoader';
import { SnackbarActionsActionShowSnackbarTypes } from 'src/shapes/snackbar';
import { getCentroid } from 'src/utils/functs';
import { StyleEditor } from './StyleEditor';
import style from './index.module.scss';

const aoiApis = new AoiV2Apis();

export interface MapMakerContainerProps {
  projectId: string;
  aoiId: string;
  missionId?: string;
  showSnackbar: SnackbarActionsActionShowSnackbarTypes;
}

/**
 * Container for everything MapMaker related.
 * Responsibilities:
 *  - fetch all styles, and list them out.
 *  - if no style present, display appropriate placeholder.
 *  - create a new style, if required.
 *  - select style, and display preview and layer editor
 * @constructor
 */
export const MapMakerContainer: React.FC<MapMakerContainerProps> = ({
  projectId,
  aoiId,
  missionId,
  showSnackbar,
}) => {
  const api = useMapStyleApi();
  const [styles, setStyles] = useState<Array<MapStyleDetails> | undefined>(
    undefined
  );
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [selectedStyle, selectStyle] = useState<MapStyleDetails | undefined>(
    undefined
  );
  const [centroid, setCentroid] = useState<{ x: number; y: number }>();

  // TODO: simplify and move to ./hooks.ts
  useEffect(() => {
    api.listMapStylesForAoi(projectId, aoiId, missionId).then((styles) => {
      setStyles(styles);
    });

    aoiApis.getAoiBoundary(projectId, aoiId).then((res) => {
      setCentroid(getCentroid(res.data));
    });
  }, [api, projectId, aoiId, missionId]);

  const createMapStyle = useMemo(
    () => async (req: CreateMapStyleRequest) => {
      setCreateModal(false);

      if (req?.name) {
        const { name } = req;

        api
          .createMapStyle(projectId, aoiId, {
            name,
            missionId,
            latitude: centroid?.y,
            longitude: centroid?.x,
          })
          .then((mapStyle) => {
            setStyles((styles) => [...(styles || []), mapStyle]);
            selectStyle(mapStyle);
          });
      }
    },
    [centroid, projectId, aoiId, api, missionId]
  );

  const editMapStyle = useMemo(
    () => async (req: EditMapStyleRequest) => {
      setEditModal(false);

      if (req?.name && selectedStyle) {
        api
          .editMapStyle(projectId, aoiId, selectedStyle.style.id, req)
          .then((details) => {
            setStyles((styles) => {
              const _styles = (styles || []).filter(
                (s) => s.style.id !== details.style.id
              );

              return [..._styles, details];
            });
            selectStyle(details);
          });
      }
    },
    [projectId, aoiId, api, selectedStyle]
  );

  const refreshMapStyle = useMemo(
    () => async () => {
      if (selectedStyle) {
        const { projectId, aoiId, id: styleId } = selectedStyle.style;

        await api.getMapStyle(projectId, aoiId, styleId).then((details) => {
          setStyles((styles) => {
            const _styles = (styles || []).filter(
              (s) => s.style.id !== details.style.id
            );

            return [..._styles, details];
          });
          selectStyle(details);
        });
      }
    },
    [selectedStyle, api]
  );

  if (styles === undefined) {
    return <SkeletonLoader />;
  }

  if (styles.length === 0) {
    return (
      <CenteredContent containerClass={style.minHeight}>
        <div className={style.center}>
          <h2 className={style.placeholder}>
            No styles yet! <br />
            Create a style to get started.
          </h2>
          <div>
            <Button onClick={() => setCreateModal(true)}>
              Create New Style
            </Button>
          </div>
        </div>
        <CreateMapStyleModal
          createStyle={createMapStyle}
          visible={createModal}
        />
      </CenteredContent>
    );
  }

  return (
    <div
      className={classnames(style.container, style.columnFlex, style.minHeight)}
    >
      <StyleSelector
        styles={styles}
        selectedStyle={selectedStyle}
        onStyleSelect={selectStyle}
        onCreateStyle={() => setCreateModal(true)}
        onEditStyle={() => setEditModal(true)}
      />
      {selectedStyle ? (
        <MapStyleContext.Provider value={selectedStyle}>
          <StyleEditor
            showSnackbar={showSnackbar}
            refreshMapStyle={refreshMapStyle}
          />
        </MapStyleContext.Provider>
      ) : (
        <StyleNotSelectedPlaceholder />
      )}
      <CreateMapStyleModal createStyle={createMapStyle} visible={createModal} />
      <EditMapStyleModal editStyle={editMapStyle} visible={editModal} />
    </div>
  );
};

export const StyleNotSelectedPlaceholder: React.FC = () => {
  return (
    <CenteredContent>
      <div className={style.center}>
        <h2 className={style.placeholder}>Select a style to proceed.</h2>
      </div>
    </CenteredContent>
  );
};

export interface StyleSelectorProps {
  styles: MapStyleDetails[];
  selectedStyle?: MapStyleDetails;
  onStyleSelect: (mapStyle: MapStyleDetails) => void;
  onCreateStyle: () => void;
  onEditStyle: () => void;
}

export const StyleSelector: React.FC<StyleSelectorProps> = ({
  styles,
  selectedStyle,
  onStyleSelect,
  onCreateStyle,
  onEditStyle,
}) => {
  return (
    <div className={style.selectorContainer}>
      <label>Style:</label>
      <Select
        className={style.fullWidth}
        placeholder="Select a style to proceed"
        value={selectedStyle?.style.id}
        onSelect={(id: string) => {
          const selectedStyle = styles.find((s) => s.style.id === id);

          if (selectedStyle) onStyleSelect(selectedStyle);
        }}
      >
        {styles.map((s) => (
          <Select.Option key={s.style.id} value={s.style.id}>
            {s.view.name}
          </Select.Option>
        ))}
      </Select>
      {selectedStyle ? (
        <Button
          type="secondary"
          className={style.marginLeft}
          onClick={onEditStyle}
        >
          Edit Style
        </Button>
      ) : (
        <></>
      )}
      <Button className={style.marginLeft} onClick={onCreateStyle}>
        Create Style
      </Button>
    </div>
  );
};

export interface CreateMapStyleModalProps {
  createStyle: (details?: { name: string }) => Promise<void>;
  visible: boolean;
}

export const CreateMapStyleModal: React.FC<CreateMapStyleModalProps> = ({
  createStyle,
  visible,
}) => {
  const [name, setName] = useState<string | undefined>(undefined);

  if (!visible) {
    return <></>;
  }

  return (
    <Modal
      title="Create New Style"
      centered
      visible={visible}
      destroyOnClose
      onCancel={() => createStyle()}
      okText="Create"
      onOk={() => {
        if (name) createStyle({ name });
      }}
    >
      <div>
        <label>Name</label>
        <input
          placeholder="Enter name for the style"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export interface EditMapStyleModalProps {
  editStyle: (req?: EditMapStyleRequest) => Promise<void>;
  visible: boolean;
}

export const EditMapStyleModal: React.FC<EditMapStyleModalProps> = ({
  editStyle,
  visible,
}) => {
  const [name, setName] = useState<string | undefined>(undefined);

  if (!visible) {
    return <></>;
  }

  return (
    <Modal
      title="Edit Style"
      centered
      visible={visible}
      destroyOnClose
      onCancel={() => editStyle()}
      okText="Edit"
      onOk={() => {
        if (name) editStyle({ name });
      }}
    >
      <div>
        <label>Name</label>
        <input
          placeholder="Enter name for the style"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
    </Modal>
  );
};
