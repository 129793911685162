import { connect } from 'react-redux';

import { Step } from 'react-joyride';
import { getHeaderMenu } from '../../redux/selectors/views';
import {
  showCoachMarks,
  resetCoachMarks,
} from '../../redux/actions/coachmarks';
import { showCertifiedOnly } from '../../utils/localStorage';
import {
  addViewManually,
  fetchViews,
  resetCurrentView,
  setLastView,
} from '../../redux/actions/views';

import ViewDataManager from '.';
import { ViewDataManagerPropsType, ViewDataManagerTypes } from './index.types';
import { View } from '../../api/views.types';
import { actionShowSnackbar } from '../../redux/actions/snackbar';

const getUserRole = (state: any, projectId: any): any => {
  if (state && state.auth && state.auth.user) {
    const filtered = state.auth.user.project_roles.filter(
      (p: any) => p.project_id === projectId
    );

    if (filtered.length) {
      return filtered[0].role;
    }
  }

  return null;
};

export const getCertifiedViewsOnly = (views: any[], isStaff: any) => {
  if (isStaff) {
    return views.filter((view) => {
      if (view.certifiedForDisplay) {
        return true;
      }

      return !showCertifiedOnly(view.aoiId);
    });
  }

  return views.filter((view) => view.certifiedForDisplay);
};

const mapStateToProps = (
  state: any,
  { viewId, projectId, splitViewId }: ViewDataManagerPropsType
): ViewDataManagerTypes.ReduxProps => {
  return {
    viewList: getCertifiedViewsOnly(state.views.data, state.auth.user?.staff),
    userRole: getUserRole(state, projectId),
    isStaff: state.auth.user?.staff,
    headerMenus: getHeaderMenu(state, { viewId, splitViewId }),
  };
};

const mapDispatchToProps = (
  dispatch: any,
  { projectId, aoiId }: ViewDataManagerPropsType
): ViewDataManagerTypes.DispatchProps => {
  return {
    fetchViews: () => dispatch(fetchViews(projectId, aoiId)),
    resetCurrentView: () => dispatch(resetCurrentView()),
    setLastView: (viewId: string, splitViewId: string) =>
      dispatch(setLastView(viewId, splitViewId)),
    showCoachMarks: (steps: Step[]) => dispatch(showCoachMarks(steps)),
    resetCoachMarks: () => dispatch(resetCoachMarks()),
    addViewManually: (view: View) => dispatch(addViewManually(view)),
    showSnackbar: (props) => dispatch(actionShowSnackbar(props)),
  };
};

const ViewDataManagerContainer = connect<
  ViewDataManagerTypes.ReduxProps,
  ViewDataManagerTypes.DispatchProps,
  ViewDataManagerTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ViewDataManager);

export default ViewDataManagerContainer;
