import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import FloorPlans from '../../api/floorPlans';
import ImagesApis from '../../api/images';
import { View } from '../../api/views.types';
import ViewsV2Apis from '../../api/viewsV2';
import { DOCUMENTATION_URL_LIST } from '../../constants/urls';
import {
  editInterior360viewUrl,
  newInterior360ViewUrl,
} from '../../routes/urls';
import { GenericApisReturnTypes } from '../../shapes/app';
import {
  convertToUtc,
  formatDateForDatePicker,
  momentDateNow,
} from '../../utils/date';
import { undefinedOrNull } from '../../utils/functs';
import { log } from '../../utils/log';
import { Button } from '../Button';
import DocumentationLink from '../DocumentationLink';
import Icon from '../Icon';
import Loading from '../Loading';
import PannellumRender from '../View/PanoramaView/PannellumRenderer';
import FloorLevelManager from './FloorLevelManager';
import FloorMapInset from './FloorMapInset';
import DrawControl from './FloorMapInset/DrawControl';
import styles from './Interior360Editor.module.scss';
import Toast from './Toast';

declare const $: any;

const { Title } = Typography;
const { Option } = Select;

type actionType = 'new' | 'edit';

interface Interior360EditorPropTypes {
  action: actionType;
  projectId: string;
  aoiId: string;
  viewId?: string;
  view?: any;
  history?: any;
}

interface Interior360EditorStateTypes {
  view: View | null;
  viewDescriptor?: any;
  interior360Views: any[];
  showManageLevelModal: boolean;
  showUserConfirmationModal: boolean;
  showHotspotsModal: boolean;
  updatingViewData: boolean;
  updateAndPublishingViewData: boolean;
  updatingHotspots: boolean;
  currentPanoImage: any | null;
  uploadingPano: boolean;
  viewPublishSuccess: string;
  viewPublishError: string | null;
  viewUpdateSuccess: string;
  viewUpdateError: string | null;
  hotspotsUpdateError?: string;
  currentLevel?: string;
  currentDate?: any;
  floorPlans: any[];
  hotspotPosition?: { yaw: number; pitch: number };
  hotspotTargetPanorama?: string;
  hotspotTitle?: string;
  viewerYaw: number;
}

interface IOption {
  label: string;
  value: string;
}

const viewV2Api = new ViewsV2Apis();
const floorPlansApi = new FloorPlans();
const imagesApis = new ImagesApis();

class Interior360Editor extends React.PureComponent<
  Interior360EditorPropTypes,
  Interior360EditorStateTypes
> {
  private PUBLISH_CONFIRMATION_MESSAGE =
    'The changes will be published and will be available for all stakeholders. Please confirm.';

  private UNPUBLISH_CONFIRMATION_MESSAGE =
    'The view will be unpublished and will be unavailable to stakeholders. Please confirm.';

  private viewerRef = React.createRef<PannellumRender>();

  public constructor(props: Interior360EditorPropTypes) {
    super(props);
    this.state = {
      view: null,
      showManageLevelModal: false,
      showUserConfirmationModal: false,
      showHotspotsModal: false,
      floorPlans: [],
      currentPanoImage: null,
      uploadingPano: false,
      updatingViewData: false,
      updateAndPublishingViewData: false,
      updatingHotspots: false,
      viewPublishSuccess: '',
      viewPublishError: null,
      viewUpdateSuccess: '',
      viewUpdateError: null,
      interior360Views: [],
      currentDate: momentDateNow(),
      viewerYaw: 0,
    };
  }

  public componentDidMount(): void {
    const { view, projectId, aoiId, viewId, action } = this.props;

    if (view) {
      this.setState({ view }, () => {
        this.fetchViewDescriptor();
      });
    } else {
      if (viewId) {
        this.fetchView(projectId, aoiId, viewId, action);
      }

      this.fetchFloorPlans();
      this.fetchInterior360Views();
    }

    // scrolls current component page to top of screen
    window.scrollTo(0, 0);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: Interior360EditorPropTypes
  ) {
    const { viewId } = this.props;
    const { viewId: nextViewId, projectId, aoiId, action } = nextProps;

    if (viewId !== nextViewId && nextViewId) {
      this.fetchView(projectId, aoiId, nextViewId, action);
    }
  }

  private fetchView = (
    projectId: string,
    aoiId: string,
    viewId: string,
    action: string
  ) => {
    if (action === 'edit' && viewId) {
      viewV2Api
        .getView(projectId, aoiId, viewId)
        .then((res) => {
          const { error, data } = res;

          if (error) {
            throw error;
          }

          this.setState({ view: data }, () => {
            this.fetchViewDescriptor();
            this.getDefaultDate();
          });
        })
        .catch((err) => {
          log.error(err);
        });
    }
  };

  private fetchViewDescriptor() {
    const { view } = this.state;

    if (!undefinedOrNull(view)) {
      viewV2Api
        .listViewConfig(view.projectId, view.aoiId, view.id)
        .then((res) => {
          const { error, data } = res;

          if (error) {
            throw error;
          }

          const images = data?.images || [];
          const currentPanoImage = images.find((i: any) => !i.hidden);

          this.setState({ viewDescriptor: data, currentPanoImage }, () => {
            this.setDefaultLevel();
          });
        })
        .catch((err) => {
          log.error(err);
        });
    }
  }

  private fetchFloorPlans = () => {
    const { projectId, aoiId } = this.props;

    floorPlansApi
      .listFloorPlans(projectId, aoiId)
      .then((res) => {
        const { error, data } = res;

        if (error) {
          throw error;
        }

        this.setState({ floorPlans: data });
      })
      .catch((err) => {
        log.error(err);
      });
  };

  private fetchInterior360Views = () => {
    const { projectId, aoiId } = this.props;

    viewV2Api
      .getViewsByTypeAndPublishStatus(projectId, aoiId, 'interior_360', true)
      .then((res) => {
        const { error, data } = res;

        if (error) {
          throw error;
        }

        this.setState({ interior360Views: data });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  private getViewWithSameDateAndLevel = () => {
    const { interior360Views, currentLevel, currentDate } = this.state;

    if (!undefinedOrNull(currentDate) && !undefinedOrNull(currentLevel)) {
      const currentSelectedDate = convertToUtc(currentDate.format());

      const viewInfo = interior360Views.find((view) => {
        if (!undefinedOrNull(view.sourceUrl)) {
          const floorLevelId = view.sourceUrl.substring(
            view.sourceUrl.lastIndexOf('/') + 1
          );

          return (
            currentSelectedDate === view.date && floorLevelId === currentLevel
          );
        }

        return false;
      });

      return viewInfo;
    }

    return null;
  };

  private updatePanoImagesOnUpload = (state: {
    images: any[];
    selected: any;
  }) => {
    const { viewDescriptor } = this.state;
    const { images, selected } = state;

    const updatedViewDescriptor = {
      ...viewDescriptor,
      images,
    };

    this.setState({
      viewDescriptor: updatedViewDescriptor,
      currentPanoImage: selected,
    });
  };

  private getDefaultDate = () => {
    const { view } = this.state;
    const { action } = this.props;

    if (action === 'edit' && !undefinedOrNull(view)) {
      this.setState({ currentDate: formatDateForDatePicker(view.date) });
    }
  };

  private handleSetDate = (date: any, dateString: string) => {
    const { action } = this.props;

    if (action === 'edit') {
      this.setState({
        currentDate: formatDateForDatePicker(dateString),
        showUserConfirmationModal: true,
      });
    }

    this.setState({ currentDate: formatDateForDatePicker(dateString) });
  };

  private getDefaultLevels = (): IOption[] => {
    // use view in state and use view Descriptor property to set these values
    const { floorPlans } = this.state;

    if (!undefinedOrNull(floorPlans) && floorPlans.length > 0) {
      return floorPlans.map((floorPlan) => {
        return { label: floorPlan.floor, value: floorPlan.id };
      });
    }

    return [];
  };

  private setDefaultLevel = () => {
    const { viewDescriptor } = this.state;

    if (!undefinedOrNull(viewDescriptor)) {
      this.setState({ currentLevel: viewDescriptor.floorLevelId });
    }
  };

  private handleSetLevel = (value: string) => {
    const { action } = this.props;

    if (action === 'edit') {
      this.setState({ currentLevel: value, showUserConfirmationModal: true });
    }

    this.setState({ currentLevel: value });
  };

  private handleManageLevelIcon = () => {
    this.setState({ showManageLevelModal: true });
  };

  public updateCurrentPanoImage = (panoImage: any | null) => {
    this.setState({ currentPanoImage: panoImage });
  };

  public updateCurrentPanoFromId = (id: string) => {
    const { viewDescriptor } = this.state;
    const images = viewDescriptor?.images || [];

    // eslint-disable-next-line no-restricted-syntax
    for (const img of images) {
      if (img.id === id) {
        this.setState({ currentPanoImage: img });
        break;
      }
    }
  };

  public updatePanoUploadStatus = (uploaded: boolean) => {
    this.setState({ uploadingPano: uploaded });
  };

  private renderFloorLevelManager = () => {
    const { showManageLevelModal, floorPlans } = this.state;

    return (
      <FloorLevelManager
        showManageLevelModal={showManageLevelModal}
        floorPlans={floorPlans}
        updateModalVisibility={this.manageLevelModalVisibility}
        updateFloorPlans={this.updateFloorPlansbyLevelManagerModal}
      />
    );
  };

  private manageLevelModalVisibility = (value: boolean) => {
    this.setState({ showManageLevelModal: value });
  };

  private updateFloorPlansbyLevelManagerModal = (levels: any[]) => {
    this.setState({ floorPlans: levels });
  };

  private getErrorMessage = (res: GenericApisReturnTypes): string | null => {
    if (!undefinedOrNull(res.networkError)) {
      return res.networkError;
    }

    if (res.status === 400) {
      // generic error
      return 'Something brokedown, please try this action again..';
    }

    return res.error;
  };

  private saveLevelsInfo = () => {
    const { view, floorPlans, currentLevel, currentDate } = this.state;
    const { projectId, aoiId, history } = this.props;

    this.setState({ updatingViewData: true });

    const floorInfo = floorPlans.find(
      (floorPlan) => floorPlan.id === currentLevel
    );

    // new view getting created
    if (undefinedOrNull(view)) {
      viewV2Api
        .postView(projectId, aoiId, {
          name: !undefinedOrNull(floorInfo) ? floorInfo.floor : '',
          type: 'interior_360',
          date: convertToUtc(currentDate.format()),
          floorLevelId: currentLevel,
        })
        .then((res) => {
          if (history)
            history.push(editInterior360viewUrl(projectId, aoiId, res.data.id));
        })
        .catch((err) => {
          log.error(err);
        });
    } else {
      // if view has already been created by right clicking pano upload and now we update this view
      viewV2Api
        .updateView(projectId, aoiId, view.id, {
          name: !undefinedOrNull(floorInfo) ? floorInfo.floor : '',
          date: convertToUtc(currentDate.format()),
          floorLevelId: currentLevel,
        })
        .then((res) => {
          this.setState({
            viewUpdateSuccess: 'View Updated sucessfully!!',
            viewUpdateError: this.getErrorMessage(res),
          });
        })
        .catch((err) => {
          log.error(err);
        })
        .finally(() => {
          this.setState({
            updatingViewData: false,
            viewPublishError: '',
            viewPublishSuccess: '',
          });
        });
    }
  };

  private getPanoImages = () => {
    const { viewDescriptor } = this.state;

    if (!undefinedOrNull(viewDescriptor)) {
      return viewDescriptor.images || [];
    }

    return [];
  };

  private saveLevelsInfoAndPublish = (published: boolean) => {
    const { projectId, aoiId } = this.props;
    const { view, floorPlans, currentLevel, currentDate } = this.state;

    this.setState({ updateAndPublishingViewData: true });

    const floorInfo = floorPlans.find(
      (floorPlan) => floorPlan.id === currentLevel
    );

    if (!undefinedOrNull(view)) {
      viewV2Api
        .updateView(projectId, aoiId, view.id, {
          name: !undefinedOrNull(floorInfo) ? floorInfo.floor : '',
          date: convertToUtc(currentDate.format()),
          floorLevelId: currentLevel,
          certifiedForDisplay: published,
        })
        .then((res) => {
          this.setState({
            view: res.data,
            viewPublishSuccess: 'View Published Successfully',
            viewPublishError: this.getErrorMessage(res),
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.setState({
            updateAndPublishingViewData: false,
            viewUpdateSuccess: '',
            viewUpdateError: '',
          });
        });
    }
  };

  private handleImageVisibility = (visible: boolean) => {
    const { view, currentPanoImage } = this.state;

    if (!undefinedOrNull(view)) {
      imagesApis
        .patchImage(view.id, currentPanoImage.id, { hidden: visible })
        .then((res) => {
          const updatedImage = res.data.images.filter(
            (updatedImages: any) => updatedImages.id === currentPanoImage.id
          );

          this.setState({
            currentPanoImage: updatedImage[0],
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  private addHotspot = () => {
    const {
      view,
      currentPanoImage,
      hotspotPosition,
      hotspotTargetPanorama,
      hotspotTitle,
    } = this.state;

    if (!view || !hotspotPosition || !hotspotTargetPanorama) return;

    const hotspots = currentPanoImage?.hotspots || [];
    let found = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const hs of hotspots) {
      if (hs.sceneId === hotspotTargetPanorama) {
        hs.yaw = hotspotPosition.yaw;
        hs.pitch = hotspotPosition.pitch;
        hs.text = hotspotTitle || '';

        found = true;
        break;
      }
    }

    if (!found) {
      hotspots.push({
        type: 'scene',
        ...hotspotPosition, // yaw, pitch
        text: hotspotTitle || '',
        sceneId: hotspotTargetPanorama,
      });
    }

    this.setState(
      { updatingHotspots: true, hotspotsUpdateError: undefined },
      () => {
        imagesApis
          .patchImage(view.id, currentPanoImage.id, {
            hotspots,
          })
          .then((res) => {
            if (res.error) {
              throw res.error;
            }

            const updatedImage = res.data.images.find(
              (updatedImages: any) => updatedImages.id === currentPanoImage.id
            );

            this.setState({
              currentPanoImage: updatedImage,
              viewDescriptor: res.data,
              showHotspotsModal: false,
              hotspotsUpdateError: undefined,
              updatingHotspots: false,
            });
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              hotspotsUpdateError: 'Could not add link',
              updatingHotspots: false,
            });
          });
      }
    );
  };

  private handleStartAddingHotspot = () => {
    const viewer = this.viewerRef.current?.getViewer();

    if (!viewer) return;

    // this is pretty ugly, but antd compnents give no way to get the contextmenu (right-click)
    // event which triggered the dropdown. we want to add hotspot where right-click happened, not
    // where user clicked on the menu item
    const { top, left } = $('.vimana-pano-ctxmenu').position() || {};

    if (undefinedOrNull(top) || undefinedOrNull(left)) return;

    const [pitch, yaw] = viewer.mouseEventToCoords({
      clientX: left,
      clientY: top,
      pageX: left,
      pageY: top,
    });

    this.setState({
      showHotspotsModal: true,
      hotspotPosition: { yaw, pitch },
      hotspotsUpdateError: undefined,
      hotspotTargetPanorama: undefined,
      hotspotTitle: undefined,
    });
  };

  private handleRemoveHotspots = () => {
    const { currentPanoImage, view } = this.state;

    if (!view || !currentPanoImage) return;

    this.setState(
      { updatingHotspots: true, hotspotsUpdateError: undefined },
      () => {
        imagesApis
          .patchImage(view.id, currentPanoImage.id, { hotspots: [] })
          .then((res) => {
            if (res.error) {
              throw res.error;
            }

            const updatedImage = res.data.images.find(
              (updatedImages: any) => updatedImages.id === currentPanoImage.id
            );

            this.setState({
              currentPanoImage: updatedImage,
              viewDescriptor: res.data,
              showHotspotsModal: false,
              hotspotsUpdateError: undefined,
              updatingHotspots: false,
            });
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              hotspotsUpdateError: 'Could not remove links',
              updatingHotspots: false,
            });
          });
      }
    );
  };

  private handleSetNorthOffset = () => {
    const { currentPanoImage, view } = this.state;
    const viewer = this.viewerRef?.current?.getViewer();

    if (!view || !currentPanoImage) return;
    if (!viewer) return;

    this.setState(
      { updatingHotspots: true, hotspotsUpdateError: undefined },
      () => {
        imagesApis
          .patchImage(view.id, currentPanoImage.id, {
            panoYaw: 0,
            imageYaw: -viewer.getYaw(),
          })
          .then((res) => {
            if (res.error) {
              throw res.error;
            }

            const updatedImage = res.data.images.find(
              (updatedImages: any) => updatedImages.id === currentPanoImage.id
            );

            this.setState({
              currentPanoImage: updatedImage,
              viewDescriptor: res.data,
              showHotspotsModal: false,
              hotspotsUpdateError: undefined,
              updatingHotspots: false,
            });
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              hotspotsUpdateError: 'Could not update image',
              updatingHotspots: false,
            });
          });
      }
    );
  };

  private renderContextMenu = () => {
    const { currentPanoImage, view } = this.state;
    const isSystemImage = currentPanoImage?.source === 'SYSTEM';

    const menu = (
      <Menu>
        <Menu.Item
          key="hide_image"
          onClick={() => {
            this.handleImageVisibility(!currentPanoImage.hidden);
          }}
          disabled={view?.viewConfig?.selection === currentPanoImage.id}
        >
          {currentPanoImage.hidden ? 'Unhide Image' : 'Hide Image'}
        </Menu.Item>
        <Menu.Item key="add_hotspot" onClick={this.handleStartAddingHotspot}>
          Add link to another panorama
        </Menu.Item>
        <Menu.Item key="remove_hotspots" onClick={this.handleRemoveHotspots}>
          Remove links to other panoramas
        </Menu.Item>
        <Menu.Item
          key="set_north"
          disabled={isSystemImage}
          onClick={this.handleSetNorthOffset}
        >
          Set current center as panorama&apos;s north
        </Menu.Item>
      </Menu>
    );

    return menu;
  };

  private renderMapInset = () => {
    return this.renderFloorMapInset();
  };

  private renderFloorMapInset = () => {
    const { projectId, aoiId, history } = this.props;
    const {
      view,
      currentPanoImage,
      uploadingPano,
      floorPlans,
      currentLevel,
      currentDate,
      viewerYaw,
    } = this.state;
    const { imageYaw, panoYaw } = currentPanoImage || {
      imageYaw: 0,
      panoYaw: 0,
    };
    const compensatedViewerYaw = viewerYaw + (imageYaw - panoYaw);

    if (!undefinedOrNull(view) && view.certifiedForDisplay) {
      return (
        <FloorMapInset
          parent="editor"
          published={view.certifiedForDisplay}
          projectId={projectId}
          aoiId={aoiId}
          planId={!undefinedOrNull(currentLevel) ? currentLevel : ''}
          view={view}
          date={currentDate}
          panoImages={this.getPanoImages()}
          currentPano={currentPanoImage || this.getPanoImages()[0]}
          updateCurrentPano={this.updateCurrentPanoImage}
          updatePanoUploadStatus={this.updatePanoUploadStatus}
          uploadingPano={uploadingPano}
          updatePanos={this.updatePanoImagesOnUpload}
          className={styles.inset}
          history={history}
          viewerYaw={compensatedViewerYaw}
        />
      );
    }

    return (
      <FloorMapInset
        parent="editor"
        published={view?.certifiedForDisplay}
        projectId={projectId}
        aoiId={aoiId}
        planId={!undefinedOrNull(currentLevel) ? currentLevel : ''}
        view={view}
        floorPlans={floorPlans}
        date={currentDate}
        panoImages={this.getPanoImages()}
        currentPano={currentPanoImage || this.getPanoImages()[0]}
        updateCurrentPano={this.updateCurrentPanoImage}
        updatePanoUploadStatus={this.updatePanoUploadStatus}
        uploadingPano={uploadingPano}
        updatePanos={this.updatePanoImagesOnUpload}
        className={styles.inset}
        history={history}
        viewerYaw={compensatedViewerYaw}
      >
        <DrawControl
          key="draw-control"
          drawMode="Point"
          allowEdit
          allowMultipleFeatures
          parent="floorMapInset"
        />
      </FloorMapInset>
    );
  };

  private renderPanoViewer = () => {
    const { view, currentPanoImage, viewDescriptor } = this.state;
    const images = viewDescriptor?.images || [];

    if (
      !undefinedOrNull(currentPanoImage) &&
      !undefinedOrNull(view) &&
      view.certifiedForDisplay
    ) {
      return (
        <PannellumRender
          ref={this.viewerRef}
          className={styles.panoRenderer}
          viewId={view.id}
          selectedImage={currentPanoImage.id}
          images={images}
          onViewStateChange={(e: any) => {
            const { yaw } = e;

            this.setState({ viewerYaw: yaw });
          }}
          onSceneChange={(sceneId: string) => {
            this.updateCurrentPanoFromId(sceneId);
          }}
        />
      );
    }

    return !undefinedOrNull(currentPanoImage) && !undefinedOrNull(view) ? (
      // NOTE: the overlayClassName here is important! See comment in addHotspot
      <Dropdown
        overlay={this.renderContextMenu()}
        trigger={['contextMenu']}
        overlayClassName="vimana-pano-ctxmenu"
      >
        <div>
          <PannellumRender
            ref={this.viewerRef}
            className={styles.panoRenderer}
            viewId={view.id}
            selectedImage={currentPanoImage.id}
            images={images}
            onViewStateChange={(e: any) => {
              const { yaw } = e;

              this.setState({ viewerYaw: yaw });
            }}
            onSceneChange={(sceneId: string) => {
              this.updateCurrentPanoFromId(sceneId);
            }}
          />
        </div>
      </Dropdown>
    ) : null;
  };

  private heading = () => {
    const { action } = this.props;

    if (action === 'new') {
      return 'NEW INTERIOR 360 VIEW';
    }

    return 'EDIT INTERIOR 360 VIEW';
  };

  private disableFormFields = () => {
    const { view } = this.state;

    return !undefinedOrNull(view) && view.certifiedForDisplay;
  };

  private showHints = () => {
    // check level selected or not and action is edit
    const { action } = this.props;
    const { currentLevel } = this.state;

    if (
      action === 'new' &&
      undefinedOrNull(this.getViewWithSameDateAndLevel())
    ) {
      if (!undefinedOrNull(currentLevel)) {
        return (
          <p className={styles.hint}>Please upload equirectangular image.</p>
        );
      }
    }

    return null;
  };

  private getToastMessage = () => {
    const { view, currentPanoImage } = this.state;

    const IMAGE_HIDDEN_MESSAGE =
      'This image is hidden, please right click to unhide it.';
    const IMAGE_UNHIDDEN_MESSAGE =
      'This image is un-hidden, please right click to hide it.';

    const SELECTED_UNHIDDEN_IMAGE_MESSAGE =
      'This image is un-hidden, you cannot hide it because it is selected as intial view';
    //  current image is selected as initial view image

    if (
      view?.viewConfig?.selection === currentPanoImage.id &&
      !currentPanoImage.hidden
    ) {
      return SELECTED_UNHIDDEN_IMAGE_MESSAGE;
    }

    return currentPanoImage.hidden
      ? IMAGE_HIDDEN_MESSAGE
      : IMAGE_UNHIDDEN_MESSAGE;
  };

  private showToast = () => {
    const { currentPanoImage } = this.state;

    if (!undefinedOrNull(currentPanoImage)) {
      return <Toast message={this.getToastMessage()} />;
    }

    return null;
  };

  private renderLevelSelection = () => {
    const { currentLevel } = this.state;
    const { action } = this.props;

    if (!undefinedOrNull(currentLevel) && action === 'edit') {
      return (
        <Select
          placeholder="Select a Level"
          value={currentLevel}
          onChange={this.handleSetLevel}
          disabled={this.disableFormFields()}
        >
          {this.getDefaultLevels().map((level) => {
            return (
              <Option value={level.value} key={level.value}>
                {level.label}
              </Option>
            );
          })}
        </Select>
      );
    }

    if (action === 'new') {
      return (
        <Select
          placeholder="Select a Level"
          onChange={this.handleSetLevel}
          disabled={this.disableFormFields()}
        >
          {this.getDefaultLevels().map((level) => {
            return (
              <Option value={level.value} key={level.value}>
                {level.label}
              </Option>
            );
          })}
        </Select>
      );
    }

    return null;
  };

  private renderDatePicker = () => {
    const { currentDate } = this.state;

    return (
      <DatePicker
        allowClear={false}
        value={currentDate}
        dropdownClassName="antCalendar"
        placeholder="Select Date"
        onChange={this.handleSetDate}
        disabled={this.disableFormFields()}
      />
    );
  };

  private isRedirectionLinkVisible = () => {
    const { action } = this.props;
    const { view } = this.state;
    const viewWithSelectedDateAndLevel = this.getViewWithSameDateAndLevel();

    return (
      (action === 'new' && !undefinedOrNull(viewWithSelectedDateAndLevel)) ||
      (action === 'edit' &&
        !undefinedOrNull(view) &&
        !undefinedOrNull(viewWithSelectedDateAndLevel) &&
        viewWithSelectedDateAndLevel.id !== view.id) ||
      (action === 'edit' && undefinedOrNull(viewWithSelectedDateAndLevel))
    );
  };

  private handleUserConfirmation = () => {
    this.setState({ showUserConfirmationModal: false });
  };

  private handleUserRejection = () => {
    const { view } = this.state;

    // revert to old state (rendered from view data)
    if (!undefinedOrNull(view)) {
      let floorLevelId = '';

      if (!undefinedOrNull(view.sourceUrl)) {
        floorLevelId = view.sourceUrl.substring(
          view.sourceUrl.lastIndexOf('/') + 1
        );
      }

      this.setState({
        showUserConfirmationModal: false,
        currentDate: formatDateForDatePicker(view.date),
        currentLevel: floorLevelId,
      });
    }
  };

  private renderUserConfirmationModal = () => {
    const { showUserConfirmationModal } = this.state;

    return (
      <Modal
        title="Confirmation"
        visible={showUserConfirmationModal}
        destroyOnClose
        cancelText="NO"
        okText="YES"
        onCancel={this.handleUserRejection}
        onOk={this.handleUserConfirmation}
        zIndex={10001}
      >
        You will be working on a different view by changing the level/date. Do
        you want to proceed?
      </Modal>
    );
  };

  private renderActionButtons = () => {
    const {
      updatingViewData,
      currentLevel,
      currentDate,
      updateAndPublishingViewData,
      view,
    } = this.state;
    const { action, projectId, aoiId } = this.props;
    const viewWithSelectedDateAndLevel = this.getViewWithSameDateAndLevel();

    if (
      action === 'edit' &&
      !undefinedOrNull(view) &&
      !undefinedOrNull(viewWithSelectedDateAndLevel) &&
      viewWithSelectedDateAndLevel.id !== view.id
    ) {
      return (
        <Link
          className={styles.backLink}
          to={editInterior360viewUrl(
            projectId,
            aoiId,
            viewWithSelectedDateAndLevel.id
          )}
        >
          Another view with the same date and level already exists. Click here
          to go to that view.
        </Link>
      );
    }

    if (action === 'edit' && undefinedOrNull(viewWithSelectedDateAndLevel)) {
      return (
        <Link
          className={styles.backLink}
          to={newInterior360ViewUrl(projectId, aoiId)}
        >
          A view with the selected date and level does not exist. Click here to
          create new view.
        </Link>
      );
    }

    if (action === 'new' && !undefinedOrNull(viewWithSelectedDateAndLevel)) {
      return (
        <Link
          className={styles.backLink}
          to={editInterior360viewUrl(
            projectId,
            aoiId,
            viewWithSelectedDateAndLevel.id
          )}
        >
          Another view with the same date and level already exists. Click here
          to go to that view.
        </Link>
      );
    }

    return (
      <Form.Item>
        <Button
          type="primary"
          text="SAVE"
          loading={updatingViewData}
          className={styles.padRight}
          onClick={this.saveLevelsInfo}
          disabled={
            this.disableFormFields() ||
            undefinedOrNull(currentLevel) ||
            undefinedOrNull(currentDate)
          }
        />
        <Popconfirm
          placement="right"
          title={
            this.disableFormFields()
              ? this.UNPUBLISH_CONFIRMATION_MESSAGE
              : this.PUBLISH_CONFIRMATION_MESSAGE
          }
          onConfirm={() => {
            if (this.disableFormFields()) {
              this.saveLevelsInfoAndPublish(false);
            } else {
              this.saveLevelsInfoAndPublish(true);
            }
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            text={this.disableFormFields() ? 'UNPUBLISH' : 'PUBLISH'}
            loading={updateAndPublishingViewData}
            disabled={undefinedOrNull(view)}
          />
        </Popconfirm>
      </Form.Item>
    );
  };

  private renderHotspotModal = () => {
    const {
      showHotspotsModal,
      viewDescriptor,
      currentPanoImage,
      hotspotPosition,
      hotspotTargetPanorama,
      hotspotTitle,
      updatingHotspots,
      hotspotsUpdateError,
      view,
    } = this.state;
    const { yaw, pitch } = hotspotPosition || {};
    const images = viewDescriptor?.images || [];

    return (
      <Modal
        title="Select target panorama"
        visible={
          showHotspotsModal && !undefinedOrNull(yaw) && !undefinedOrNull(pitch)
        }
        destroyOnClose
        cancelText={updatingHotspots ? undefined : 'Cancel'}
        okText={updatingHotspots ? 'Adding...' : 'Add link'}
        onCancel={() => {
          this.setState({
            showHotspotsModal: false,
            hotspotPosition: undefined,
            updatingHotspots: false,
          });
        }}
        onOk={this.addHotspot}
        zIndex={10001}
      >
        <Select
          placeholder="Select a panorama"
          value={hotspotTargetPanorama || ''}
          onChange={(id: string) => {
            this.setState({ hotspotTargetPanorama: id });
          }}
          dropdownStyle={{
            zIndex: 10002,
          }}
        >
          <Option value="" key="pano_select_default">
            Select a panorama
          </Option>
          {images.map((i: any, _idx: any) => {
            return (
              <Option
                value={i.id}
                key={i.id}
                disabled={i.hidden || i.id === currentPanoImage?.id}
              >
                {`${_idx + 1}`}
              </Option>
            );
          })}
        </Select>
        <div style={{ position: 'relative', height: '200px', width: '100%' }}>
          {hotspotTargetPanorama && view ? (
            <PannellumRender
              className={styles.panoRenderer}
              viewId={view.id}
              selectedImage={hotspotTargetPanorama}
              images={images}
              onViewStateChange={() => {}}
              disableControls
            />
          ) : (
            'Select a target panorama to preview it here'
          )}
        </div>
        <div>
          Link title
          <Input
            value={hotspotTitle}
            onChange={(e: any) => {
              this.setState({ hotspotTitle: e.target.value });
            }}
          />
        </div>
        {hotspotsUpdateError ? <div>{hotspotsUpdateError}</div> : undefined}
      </Modal>
    );
  };

  render(): React.ReactNode {
    const {
      view,
      uploadingPano,
      viewPublishError,
      viewPublishSuccess,
      viewUpdateError,
      viewUpdateSuccess,
      currentLevel,
    } = this.state;
    // const isInteriorView = view?.type === 'interior_360';
    const isExteriorView = view?.type === 'exterior_360';

    return (
      <div
        className={styles.container}
        style={isExteriorView ? { height: '100%', maxWidth: 'unset' } : {}}
      >
        {!isExteriorView ? (
          <div className={styles.headingWrapper}>
            <Title level={3}>{this.heading()}</Title>
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.interior360}
              toolTipPosition="right"
            />
          </div>
        ) : null}
        <div className={styles.innerContainer}>
          {!isExteriorView && (
            <div className={styles.viewCreationForm}>
              <Form layout="vertical">
                <Form.Item label="View Date" required>
                  {this.renderDatePicker()}
                </Form.Item>
                <Form.Item label="Level" required>
                  <div className={styles.inputFieldWrapper}>
                    {this.renderLevelSelection()}
                    <Tooltip placement="top" title="Manage Levels">
                      <Icon
                        name="stack"
                        onClick={() => {
                          if (!this.disableFormFields()) {
                            this.handleManageLevelIcon();
                          }
                        }}
                      />
                    </Tooltip>
                  </div>
                </Form.Item>
              </Form>
              {this.renderActionButtons()}
              <Form.Item>
                {!undefinedOrNull(viewUpdateSuccess) ? (
                  <p className={styles.successMessage}>{viewUpdateSuccess}</p>
                ) : null}
                {!undefinedOrNull(viewPublishSuccess) ? (
                  <p className={styles.successMessage}>{viewPublishSuccess}</p>
                ) : null}
                {!undefinedOrNull(viewUpdateError) ? (
                  <p className={styles.errorMessage}>{viewUpdateError}</p>
                ) : null}
                {!undefinedOrNull(viewPublishError) ? (
                  <p className={styles.errorMessage}>{viewPublishError}</p>
                ) : null}
              </Form.Item>
            </div>
          )}
          {!isExteriorView ? this.renderFloorLevelManager() : undefined}
          <div
            className={styles.viewPreview}
            style={isExteriorView ? { width: '100%' } : {}}
          >
            {this.showToast()}
            {this.showHints()}
            {!undefinedOrNull(currentLevel) &&
            !this.isRedirectionLinkVisible() &&
            !isExteriorView
              ? this.renderFloorMapInset()
              : null}
            {isExteriorView ? this.renderMapInset() : null}
            {uploadingPano ? (
              <Loading type="ellipsis" className={styles.uploadingPano} />
            ) : null}
            {this.renderPanoViewer()}
            {this.renderUserConfirmationModal()}
            {this.renderHotspotModal()}
          </div>
        </div>
      </div>
    );
  }
}

export default Interior360Editor;
