import {
  TerrainMapsReducersActionTypes,
  TerrainMapsReducersStateTypes,
  TerrainMapsActionsSetViewTypes,
  TerrainMapsActionsSetDsmsTypes,
} from '../../shapes/terrainMaps';
import { SET_DSMS, SET_DTM, SET_VIEW } from '../actions/terrainMaps';
import { DSMInfo, DTM } from '../../api/terrainMaps.types';
import { View } from '../../api/views.types';

const initialState: TerrainMapsReducersStateTypes = {};

export default function terrainMaps(
  state: TerrainMapsReducersStateTypes = initialState,
  action:
    | TerrainMapsReducersActionTypes
    | TerrainMapsActionsSetViewTypes
    | TerrainMapsActionsSetDsmsTypes
): TerrainMapsReducersStateTypes {
  const { type, payload } = action;

  switch (type) {
    case SET_DTM:
      return {
        ...state,
        dtmData: payload as DTM | undefined,
      };

    case SET_VIEW:
      return {
        ...state,
        viewData: payload as View | undefined,
      };

    case SET_DSMS:
      return {
        ...state,
        dsmsData: payload as DSMInfo[] | undefined,
      };

    default:
      return state;
  }
}
