import classnames from 'classnames';
import React, { useState } from 'react';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import { Button } from 'src/components/Button';
import { ListStyleLayers } from 'src/components/Mission/MapMaker/ListStyleLayers';
import { AddLayerModal } from 'src/components/Mission/MapMaker/StyleEditor/AddLayerModal';
import { DeleteLayerModal } from 'src/components/Mission/MapMaker/StyleEditor/DeleteLayerModal';
import { EditLayerModal } from 'src/components/Mission/MapMaker/StyleEditor/EditLayerModal';
import style from './index.module.scss';

export interface ManageLayersProps {
  layers: LayerDescriptor[];
  onLayerAdd: (layer: LayerDescriptor) => void;
  onLayerDelete: (layer: LayerDescriptor) => void;
  onLayerEdit: (layer: LayerDescriptor, originalName: string) => void;
}

export const ManageLayers: React.FC<ManageLayersProps> = ({
  layers,
  onLayerAdd,
  onLayerDelete,
  onLayerEdit,
}) => {
  const [showAddLayer, setShowAddLayer] = useState<boolean>(false);
  const [deleteLayer, setDeleteLayer] = useState<boolean>(false);
  const [editLayer, setEditLayer] = useState<boolean>(false);
  const [manageLayer, setManageLayer] = useState<LayerDescriptor>();

  return (
    <div className={style.fullHeight}>
      <div className={classnames(style.columnFlex, style.fullHeight)}>
        <div className={classnames(style.overflowScroll, style.fullHeight)}>
          <div className={style.labelGrid}>
            <span className={style.large}>Layers</span>
            <span>
              <Button
                onClick={() => setShowAddLayer(true)}
                className={style.fullWidth}
              >
                Add new layer
              </Button>
            </span>
          </div>
          <ListStyleLayers
            layers={layers}
            deleteLayer={(l) => {
              setDeleteLayer(true);
              setManageLayer(l);
            }}
            editLayer={(l) => {
              setEditLayer(true);
              setManageLayer(l);
            }}
          />
        </div>
      </div>
      <AddLayerModal
        visible={showAddLayer}
        onCreateLayer={(layer) => {
          setShowAddLayer(false);

          if (layer) onLayerAdd(layer);
        }}
      />
      {manageLayer ? (
        <>
          <EditLayerModal
            // `key` field is needed to ensure state reset on close
            key={`${editLayer}`}
            visible={editLayer}
            layer={manageLayer}
            onLayerEdit={(l) => {
              setEditLayer(false);

              if (l && manageLayer) {
                onLayerEdit(l, manageLayer.name);
                setManageLayer(l);
              }
            }}
          />
          <DeleteLayerModal
            layer={manageLayer}
            visible={deleteLayer}
            onDeleteLayer={(l) => {
              setDeleteLayer(false);

              if (l) {
                onLayerDelete(l);
              }
            }}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
