import * as React from 'react';
import { withRouter, matchPath } from 'react-router';
import Joyride, { Step, TooltipRenderProps } from 'react-joyride';
import { VIEW_PATH } from '../../routes/paths';
import { View } from '../../api/views.types';
import { undefinedOrNull } from '../../utils/functs';
import ownStyle from './Coachmark.module.scss';
import { Button } from '../Button';
import { setCoachMarkPreference } from '../../utils/localStorage';
import { RouterParamsTypes } from '../../shapes/routerParams';
import UserMenuStyle from '../UserMenu/UserMenu.module.scss';

// these props come from redux
interface IProps extends RouterParamsTypes {
  steps: Step[];
  run: boolean;
  views: View[];
  resetCoachMarks: () => void;
  showCoachMarks: (steps: Step[]) => void;
}

interface ViewPathParams {
  viewId: string;
  projectId: string;
  aoiId: string;
}

class Coachmark extends React.PureComponent<IProps> {
  private USER_HINT = 'Access feature tour using the option in menu here.';

  private CoachMarkTooltip = ({
    index,
    isLastStep,
    step,
    backProps,
    primaryProps,
    skipProps,
    tooltipProps,
  }: TooltipRenderProps) => {
    return (
      <div className={ownStyle.tooltipBody} {...tooltipProps}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            {...skipProps}
            onClick={skipProps.onClick as () => void}
            transparent
          >
            <i className="fa fa-times" aria-hidden="true" />
          </Button>
        </div>
        {step.title && <div>{step.title}</div>}
        {step.content && (
          <div style={{ textAlign: 'center' }}>{step.content}</div>
        )}
        {step.content === this.USER_HINT ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              text="OK"
              type="secondary"
              {...primaryProps}
              onClick={primaryProps.onClick as () => void}
            />
          </div>
        ) : (
          <div className={ownStyle.tooltipFooter}>
            <Button
              text="Do not show again"
              style={{ fontSize: 'smaller' }}
              transparent
              onClick={this.handleDisableCoachmarks}
            />
            <div>
              {index > 0 && (
                <Button
                  text="Back"
                  {...backProps}
                  type="secondary"
                  onClick={backProps.onClick as () => void}
                />
              )}
              <Button
                text={isLastStep ? 'Done' : 'Next'}
                {...primaryProps}
                // The tertiary operator is required to disable Coachmarks on the last step,
                // as primaryProps.onClick implicity calls `e.preventDefault` even though it
                // doesn't expect to take an event as an argument as per it's type. However, this
                // works when Button.onClick = primaryProps.onClick, but breaks if
                // Button.onClick = () => { primaryProps.onClick() }
                onClick={
                  isLastStep
                    ? this.handleDisableCoachmarks() &&
                      (primaryProps.onClick as () => void)
                    : (primaryProps.onClick as () => void)
                }
                type="secondary"
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  // returning true to ensure coachmarks are disabled on last step
  private handleDisableCoachmarks = (): true => {
    // set preferences in localstorage for disabling coachmarks
    const { resetCoachMarks, showCoachMarks, location } = this.props;
    const viewURLPath = matchPath(location.pathname, {
      path: VIEW_PATH,
      exact: true,
      strict: false,
    });

    resetCoachMarks();

    if (!undefinedOrNull(viewURLPath)) {
      const params = viewURLPath.params as ViewPathParams;
      const currentView = this.getCurrentView(params.viewId);

      if (!undefinedOrNull(currentView)) {
        setCoachMarkPreference(location.pathname, currentView.type);
      }
    }

    setCoachMarkPreference(location.pathname);

    // timeout required to ensure user hint shows up
    setTimeout(() => {
      showCoachMarks([
        {
          content: this.USER_HINT,
          target: `.${UserMenuStyle.userIcon}`,
          disableBeacon: true,
        },
      ]);
    }, 1000);

    // required to disable coachMarks, on the last step. Do not change this value.
    return true;
  };

  private getCurrentView = (viewId: string) => {
    const { views } = this.props;

    return views.find((view) => view.id === viewId);
  };

  private getArrowColor = () => {
    const { steps, run } = this.props;

    if (run && steps.length > 0) {
      return steps[0].content === this.USER_HINT ? '#95f671' : '#ce0008';
    }

    return '#ce0008';
  };

  render() {
    const { steps, run } = this.props;

    return (
      <Joyride
        steps={steps}
        run={run}
        continuous
        showProgress
        showSkipButton
        tooltipComponent={this.CoachMarkTooltip}
        styles={{
          options: {
            arrowColor: this.getArrowColor(),
          },
        }}
        locale={{
          last: 'End tour',
          skip: 'Close tour',
        }}
      />
    );
  }
}

export default withRouter(Coachmark);
