import ApiBase from './ApiBase';
import { APP_BASE_URL, BASE_CAPI_URL } from '../constants/urls';

export default class Measurement extends ApiBase {
  public constructor() {
    super();
    this.baseUrl = `${APP_BASE_URL}/measurements/api`;
  }

  public getViewProj4String(viewId: string) {
    return fetch(`${BASE_CAPI_URL}/v1/epsg/views/${viewId}/proj4`, {
      credentials: 'include',
    }).then((res) => {
      return res.text().then((t) => {
        return t;
      });
    });
  }

  public fetchVolume(
    viewId: string,
    points: number[][],
    elevation: number | null,
    volumeType: string
  ): Promise<any> {
    return fetch(
      `${BASE_CAPI_URL}/v1/measurements/volume?vol_type=${volumeType}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ viewId, points, elevation }),
        credentials: 'include',
      }
    ).then((res) => {
      return res.json().then((data) => {
        return { data };
      });
    });
  }

  public fetchCutFillVolume(
    viewId: string,
    points: number[][],
    elevation: number | null,
    volumeType: string
  ): Promise<any> {
    return fetch(
      `${BASE_CAPI_URL}/v1/measurements/cutfill?vol_type=${volumeType}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ viewId, points, elevation }),
        credentials: 'include',
      }
    ).then((res) => {
      return res.json().then((data) => {
        return { data };
      });
    });
  }

  public fetchSurfaceArea(viewId: string, points: number[][]): Promise<any> {
    return this.post('/v1/surface_area', {
      viewId,
      points,
    });
  }

  public fetchElevation(viewId: string, points: any): Promise<any> {
    return fetch(`${BASE_CAPI_URL}/v1/measurements/${viewId}/elevations`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ points }),
      credentials: 'include',
    }).then((res) => {
      return res.json().then((data) => {
        return { data };
      });
    });
  }

  public saveMeasurement(aoiId: string, req: any): Promise<any> {
    return fetch(`${BASE_CAPI_URL}/v2/measurements/saved/aoi/${aoiId}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
      credentials: 'include',
    }).then((res) => {
      return res.json().then((data) => {
        return { data };
      });
    });
  }

  public fetchMeasurements(aoiId: string): Promise<any> {
    // return this.get(`/v2/saved/aoi/${aoiId}`);
    return fetch(`${BASE_CAPI_URL}/v2/measurements/saved/aoi/${aoiId}`, {
      credentials: 'include',
    }).then((res) => {
      return res.json().then((data) => {
        return { data };
      });
    });
  }
}
