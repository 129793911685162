import React from 'react';
import { GetHandleProps, SliderItem } from 'react-compound-slider';
import { Tooltip } from 'antd';
import styles from './styles.module.scss';

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
interface HandleProps {
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  showValue: boolean;
}

interface IState {
  mouseOver: boolean;
}

export class Handle extends React.PureComponent<HandleProps, IState> {
  state = {
    mouseOver: false,
  };

  onMouseEnter = (): void => {
    this.setState({ mouseOver: true });
  };

  onMouseLeave = (): void => {
    this.setState({ mouseOver: false });
  };

  render(): JSX.Element {
    const {
      domain: [min, max],
      handle: { id, value, percent },
      getHandleProps,
      showValue,
    } = this.props;
    const { mouseOver } = this.state;

    const handlerProps = {
      ...getHandleProps(id, {
        onMouseEnter: this.onMouseEnter,
        onMouseLeave: this.onMouseLeave,
      }),
    };

    return (
      <>
        <div
          style={{
            top: `${percent}%`,
          }}
          className={styles.handle}
          {...handlerProps}
        />
        <Tooltip
          title={value}
          placement="right"
          visible={showValue || mouseOver}
        >
          <div
            role="slider"
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            className={styles.handleVisible}
            {...handlerProps}
            style={{
              top: `${percent}%`,
            }}
          />
        </Tooltip>
      </>
    );
  }
}

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************
export const KeyboardHandle: React.FC<HandleProps> = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
}) => {
  return (
    <button
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        top: `${percent}%`,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        width: 24,
        height: 24,
        zIndex: 5,
        cursor: 'pointer',
        border: 0,
        borderRadius: '50%',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
        backgroundColor: '#D7897E',
      }}
      {...getHandleProps(id)}
    />
  );
};
