import ImageWrapper from 'ol/Image';
import { defaultImageLoadFunction } from 'ol/source/Image';
import Static from 'ol/source/ImageStatic';
import Tiff from 'tiff.js';
import { MAGMA } from '../../../../constants/colors';
import TemperatureRange from './TemperatureRange';

export default class ThermalImageSource extends Static {
  private range: TemperatureRange;

  constructor(options: any, range: TemperatureRange) {
    super({
      imageLoadFunction: (img: ImageWrapper, src: string) => {
        this.thermalImageLoaderFn(img, src);
      },
      ...options,
    });
    // this.range = range;
    const { min, max } = range;

    this.range = { min, max, rangeMin: min, rangeMax: max };
  }

  // Convert pixel value [0, 255] to actual temp value
  private toTemp = (val: number) => {
    return (
      this.range.min + ((1.0 * val) / 255) * (this.range.max - this.range.min)
    );
  };

  // convert actual temp value to fraction representing value within
  // current selected range
  private toScaledFraction = (temp: number) => {
    if (temp <= this.range.rangeMin) return 0;
    if (temp >= this.range.rangeMax) return 1;
    if (this.range.rangeMin >= this.range.rangeMax) return 1;

    return (
      (temp - this.range.rangeMin) / (this.range.rangeMax - this.range.rangeMin)
    );
  };

  private thermalImageLoaderFn = (img: ImageWrapper, src: string) => {
    const scale = MAGMA;

    fetch(src, {
      credentials: 'include',
    })
      .then((res) => res.arrayBuffer())
      .then((buf) => {
        const tiff = new Tiff({ buffer: buf });
        const canvas = tiff.toCanvas() as HTMLCanvasElement;
        const ctx = canvas.getContext('2d');
        const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData?.data || [];

        const getIndexForCoord = (x: number, y: number) => {
          return y * (canvas.width * 4) + x * 4;
        };

        for (let x = 0; x < canvas.width; x += 1) {
          for (let y = 0; y < canvas.height; y += 1) {
            const dataIdx = getIndexForCoord(x, y);
            const val = data[dataIdx] || 0;
            const f = this.toScaledFraction(this.toTemp(val));
            let colorIdx = Math.floor(f * scale.length);

            if (colorIdx < 0) {
              colorIdx = 0;
            }

            if (colorIdx >= scale.length) {
              colorIdx = scale.length - 1;
            }

            const [r, g, b] = scale[colorIdx];

            data[dataIdx] = r;
            data[dataIdx + 1] = g;
            data[dataIdx + 2] = b;
          }
        }

        if (imageData && ctx) {
          ctx.putImageData(imageData, 0, 0);
        }

        defaultImageLoadFunction(img, canvas.toDataURL());
      })
      .catch((e) => console.error(e));
  };
}
