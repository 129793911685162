import turfArea from '@turf/area';
import turfLength from '@turf/length';
import * as React from 'react';
import Shapeinfo from './ShapeInfo';
import Unit from './Unit';

import { convertArea, convertLength } from '../../utils/helpers';

export interface IAreaShapeProps {
  shape: any;
}

const AreaInfo = ({ shape }: IAreaShapeProps): JSX.Element => {
  const area = +turfArea(shape).toFixed(2);
  const length = +turfLength(shape, { units: 'meters' }).toFixed(2);

  return (
    <Shapeinfo>
      <Unit label="Area">{convertArea(area, 'meter')}</Unit>
      <Unit label="">{convertArea(area, 'feet')}</Unit>
      <Unit label="Perimeter">{convertLength(length, 'meter')}</Unit>
      <Unit label="">{convertLength(length, 'feet')}</Unit>
    </Shapeinfo>
  );
};

export default AreaInfo;
