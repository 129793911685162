import * as React from 'react';
import { Redirect } from 'react-router';
import classnames from 'classnames';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Input } from 'antd';
import csv2geojson from 'csv2geojson';
import proj4 from 'proj4';
import { Button } from '../../Button';
import styles from './index.module.scss';
import { TerrainMapsApisReturnPromiseTypes } from '../../../shapes/terrainMaps';
import View from '../../View';
import ViewDsm from '../../ViewDsm/container';
import TerrainMapsApis from '../../../api/terrainMaps';
import TerrainMasksApis from '../../../api/terrainMasks';
import {
  splitIntoLines,
  filterEmptySplitLines,
  jsonToCsv,
  undefinedOrNullChained,
  undefinedOrNull,
  getExtension,
  arrayToCsv,
  toMetersEquivalent,
} from '../../../utils/functs';
import HelpTooltip from '../../HelpTooltip';
import { resetBodyOverFlow, setBodyOverflow } from '../../../utils/window';
import { formatDateTime, appFormatDate } from '../../../utils/date';
import ModalNotification from '../../ModalNotification/ModalNotification';
import {
  GenericApisReturnTypes,
  GenericObjectType,
  WithForm,
} from '../../../shapes/app';
import csvExample from '../image/csvExample.png';
import ProjectApis from '../../../api/projects';
import TransformApis from '../../../api/transform';
import { MapGeneratedStatus } from '../../MapGeneratedStatus';
import LoadingOverlay from '../../LoadingOverlay';
import { TerrainMapsProps } from '../index.types';
import { Project } from '../../../api/projects.types';
import { DTMSource } from '../../../api/terrainMaps.types';

const projectApis = new ProjectApis();
const terrainMapsApis = new TerrainMapsApis();
const terrainMasksApis = new TerrainMasksApis();
const transformApis = new TransformApis();

const SelectOption = Select.Option;

interface IProps extends WithForm, TerrainMapsProps {
  importBtnText: string;
  sourceType: DTMSource;
  acceptedImportFiles: string;
  projectData?: Project;
  epsgProjection: string | '';
  disableFinalizeBtn: () => any;
  disableGenerateBtn: () => any;
  finalizeBtnLoading: boolean;
  onFinalizeBtn: (dtmName: string) => any;
  unit: string;
  epsgCode: string;
}

export type TerrainMapsTabsLayout2StateSelectedDsmViewIdTypeTypes =
  | 'elevationViewId'
  | 'aerialViewId';

interface IState {
  selectedDsm: GenericObjectType | null;
  selectedDsmViewIdType: TerrainMapsTabsLayout2StateSelectedDsmViewIdTypeTypes;
  vcpGeoJsonValues: GenericObjectType | null;
  selectedTerrainMask: GenericObjectType | null;
  savedGroundAreas: GenericObjectType | null;
  automatedGroundAreas: GenericObjectType | null;
  aoiBoundary: GenericObjectType | null;
  generateBtnLoading: boolean;
  uploadVcpFile: any | null;
  newDtmCreated: boolean;
  isMapFullScreen: boolean;
  isDsmMapToolFullScreen: boolean | null;
  showEpsgConfirmModal: boolean;
  uploadVcpFileKey: number;
  showFinalizeConfirmModal: boolean;
  showCsvExampleModal: boolean;
  showGenerateConfirmModal: boolean;
  terrainMaskLoading: boolean;
}

class TerrainMapsTabsLayout2 extends React.Component<IProps, IState> {
  private uploadVcpFileRef = React.createRef<HTMLInputElement>();

  private viewDsmRef: any = React.createRef();

  private defaultSelectedDsmViewIdType: TerrainMapsTabsLayout2StateSelectedDsmViewIdTypeTypes =
    'elevationViewId';

  private acceptedVcpFile: string = '.csv';

  public constructor(props: IProps) {
    super(props);

    this.state = {
      selectedDsm: {},
      selectedDsmViewIdType: this.defaultSelectedDsmViewIdType,
      vcpGeoJsonValues: null,
      selectedTerrainMask: null,
      savedGroundAreas: null,
      automatedGroundAreas: null,
      aoiBoundary: null,
      generateBtnLoading: false,
      uploadVcpFile: null,
      newDtmCreated: false,
      isMapFullScreen: false,
      isDsmMapToolFullScreen: null,
      showEpsgConfirmModal: false,
      showFinalizeConfirmModal: false,
      showCsvExampleModal: false,
      uploadVcpFileKey: Date.now(),
      showGenerateConfirmModal: false,
      terrainMaskLoading: false,
    };
  }

  public componentDidMount(): void {
    this.fetchDsms();
    this.checkFeatureActionUrl();
  }

  public UNSAFE_componentWillReceiveProps({
    location: nextLocation,
    match: nextMatch,
  }: Readonly<IProps>): void {
    const { location, match } = this.props;
    const { featureAction: nextFeatureAction } = nextMatch.params;
    const { featureAction } = match.params;

    if (
      location &&
      nextLocation &&
      location.pathname !== nextLocation.pathname &&
      featureAction !== nextFeatureAction
    ) {
      if (featureAction === 'MarkGroundArea' && !nextFeatureAction) {
        this.setState(({ isDsmMapToolFullScreen }) => {
          return {
            isDsmMapToolFullScreen:
              isDsmMapToolFullScreen === null ? null : false,
          };
        });
      }
    }
  }

  public componentWillUnmount(): void {
    resetBodyOverFlow('y');
  }

  private checkFeatureActionUrl = (): void => {
    const { match } = this.props;
    const { featureAction } = match.params;

    if (featureAction === 'MarkGroundArea') {
      this.redirectDsmMapUrl(true);
    }
  };

  private redirectDsmMapUrl = (reverse?: boolean): void => {
    const { history, match, actionType } = this.props;
    const { projectId, aoiId, dtmId } = match.params;

    let newUrl = `/project/${projectId}/aoi/${aoiId}/TerrainMaps/new/MarkGroundArea`;
    let editUrl = `/project/${projectId}/aoi/${aoiId}/TerrainMaps/edit/${dtmId}/MarkGroundArea`;

    if (reverse) {
      newUrl = `/project/${projectId}/aoi/${aoiId}/TerrainMaps/new`;
      editUrl = `/project/${projectId}/aoi/${aoiId}/TerrainMaps/edit/${dtmId}`;
    }

    if (!history) {
      return;
    }

    history.push(actionType === 'new' ? newUrl : editUrl);
  };

  private initRegenerateDsm = () => {
    const { dtmData, dsmsData } = this.props;

    if (!dtmData || !dsmsData) {
      return;
    }

    const selectedSourceDEM = dtmData.sourceDEM;
    const selectedDtmDate = dtmData.date;

    let filteredDsm = dsmsData.filter((a: any) => {
      return a.demId === selectedSourceDEM;
    });

    if (!filteredDsm || !filteredDsm[0]) {
      filteredDsm = dsmsData.filter((a: any) => {
        return a.date === selectedDtmDate;
      });
    }

    const selectedDsm = filteredDsm && filteredDsm[0] ? filteredDsm[0] : {};

    this.setState({
      selectedDsm,
      selectedDsmViewIdType: this.defaultSelectedDsmViewIdType,
    });
  };

  private fetchDsms = () => {
    const { match, setDsms, actionType, showSnackbar } = this.props;
    const { projectId, aoiId } = match.params;

    terrainMapsApis.getDsms(projectId, aoiId).then((res: any) => {
      if (res.error) {
        showSnackbar({
          body: res.error,
          type: 'error',
        });
      }

      setDsms(res.data);

      if (actionType === 'edit') {
        this.initRegenerateDsm();

        return;
      }

      this.setState({
        selectedDsm: {},
        selectedDsmViewIdType: this.defaultSelectedDsmViewIdType,
      });
    });
  };

  private handleDsmDateSelect = (selectedDsmDate: GenericObjectType): void => {
    const { dsmsData } = this.props;

    if (!dsmsData) {
      return;
    }

    const filteredDsm = dsmsData.filter((a: any) => {
      return a.date === selectedDsmDate;
    });

    const selectedDsm = filteredDsm && filteredDsm[0] ? filteredDsm[0] : {};

    this.setState({
      selectedDsm,
      selectedDsmViewIdType: this.defaultSelectedDsmViewIdType,
    });
  };

  private handleImportVcpClick = (): void => {
    this.setState(
      {
        showCsvExampleModal: false,
      },
      () => {
        const node = this.uploadVcpFileRef.current;

        if (node) {
          node.click();
        }
      }
    );
  };

  private checkTerrainMapsSurfaceView = (): any => {
    const { selectedDsm } = this.state;

    const terrainMapsSurfaceViewTypeList = [];

    if (
      !undefinedOrNullChained(selectedDsm, 'aerialViewId') &&
      !undefinedOrNull(selectedDsm ? selectedDsm.aerialViewId : null)
    ) {
      terrainMapsSurfaceViewTypeList.push('aerialViewId');
    }

    if (
      !undefinedOrNullChained(selectedDsm, 'elevationViewId') &&
      !undefinedOrNull(selectedDsm ? selectedDsm.elevationViewId : null)
    ) {
      terrainMapsSurfaceViewTypeList.push('elevationViewId');
    }

    return terrainMapsSurfaceViewTypeList;
  };

  private handleEpsgModalClose = (confirm: boolean): void => {
    if (confirm) {
      this.setState({
        showEpsgConfirmModal: false,
        uploadVcpFileKey: Date.now(),
      });

      this.handleUploadedVcpFile();

      return;
    }

    this.setState(() => {
      return {
        vcpGeoJsonValues: null,
        uploadVcpFile: null,
        showEpsgConfirmModal: false,
        uploadVcpFileKey: Date.now(),
      };
    });
  };

  private handleFinalizeModalShow = (): void => {
    this.setState({
      showFinalizeConfirmModal: true,
    });
  };

  private handleFinalizeModalClose = (confirm: boolean): void => {
    if (confirm) {
      this.setState({
        showFinalizeConfirmModal: false,
      });
      this.finalizeDSM();

      return;
    }

    this.setState({
      showFinalizeConfirmModal: false,
    });
  };

  private handleUploadFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { showSnackbar } = this.props;

    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      const isCsv = file.name.toLowerCase().endsWith('csv');

      if (!isCsv) {
        showSnackbar({
          body: 'Unsupported file type! Only CSV files can be used for Terrain Map creation.',
          type: 'error',
        });

        this.setState({
          uploadVcpFile: null,
        });

        return;
      }

      this.setState({
        uploadVcpFile: file,
        showEpsgConfirmModal: isCsv,
      });
    }
  };

  private handleUploadedVcpFile = (): void => {
    const { showSnackbar } = this.props;
    const { uploadVcpFile, selectedDsm } = this.state;

    if (!selectedDsm || !uploadVcpFile) {
      showSnackbar({
        body: 'Some error occured. Try again.',
        type: 'error',
      });

      return;
    }

    if (undefinedOrNull(selectedDsm.processingSessionId)) {
      this.processUploadFile();

      return;
    }

    const fr = new FileReader();
    const bodyFormData = new FormData();

    fr.onloadend = (ev: any) => {
      const fileContents = ev.target.result;

      const selectedVcpBlob = new Blob([fileContents], {
        type: 'text/plain',
      });

      bodyFormData.append('file', selectedVcpBlob);

      transformApis
        .postTransform(selectedDsm.processingSessionId, bodyFormData)
        .then((res: GenericApisReturnTypes) => {
          if (res.error || !res.data) {
            showSnackbar({
              body:
                res.error ||
                'There was an unexpected error when trying to transform the VCP file.',
              type: 'error',
            });

            this.processUploadFile();

            return;
          }

          this.processCsvCoordinateConversion(res.data);
        });
    };

    fr.readAsText(uploadVcpFile);
  };

  private processUploadFile = (): void => {
    const { showSnackbar } = this.props;
    const { uploadVcpFile } = this.state;

    if (!uploadVcpFile) {
      return;
    }

    const fileType = getExtension(uploadVcpFile.name);
    const fr = new FileReader();

    fr.onloadend = (ev: any) => {
      const fileContents = ev.target.result;

      try {
        if (fileType === 'csv') {
          this.processCsvCoordinateConversion(fileContents);
        }
      } catch {
        showSnackbar({
          body: 'Some error occured while importing the file',
          type: 'error',
        });
      }
    };

    fr.readAsText(uploadVcpFile);
  };

  private processCsvCoordinateConversion = (fileContents: any) => {
    const { showSnackbar, projectData, epsgProjection } = this.props;

    const csvJson: string[] | null = splitIntoLines(fileContents);

    if (!csvJson || csvJson.length < 1) {
      showSnackbar({
        body: 'Some error occured while parsing the csv file',
        type: 'error',
      });

      return;
    }

    const epsgCode =
      projectData && projectData.epsgCode ? `EPSG:${projectData.epsgCode}` : '';

    if (csvJson[0] !== 'x,y,z') {
      showSnackbar({
        body: ` '${csvJson[0]}' is an invalid header. The header should be 'x,y,z', in lower-case. Click on 'CSV Example' to see the format`,
        type: 'error',
      });

      this.setState(() => {
        return {
          vcpGeoJsonValues: null,
          uploadVcpFile: null,
          uploadVcpFileKey: Date.now(),
        };
      });

      return;
    }

    const convertedCsv = csvJson
      .map((a, index) => {
        if (index < 1) {
          return null;
        }

        if (filterEmptySplitLines(a)) {
          return null;
        }

        const { x, y, z } = this.parseCsvCoordinates(a);

        return {
          ...this.convertCoordinates(x, y, epsgCode, epsgProjection),
          z,
        };
      })
      .filter((a) => a !== null);

    const convertedJsonToCsv = jsonToCsv(convertedCsv);

    csv2geojson.csv2geojson(
      convertedJsonToCsv,
      {
        lonfield: 'x',
        latfield: 'y',
        delimiter: ',',
      },
      (err: any, data: any) => {
        if (err) {
          showSnackbar({
            body: err,
            type: 'error',
          });

          return;
        }

        this.setState({
          vcpGeoJsonValues: data,
        });
      }
    );
  };

  private parseCsvCoordinates = (
    coordLine: string
  ): {
    x: number;
    y: number;
    z: number;
  } => {
    const splitedCoordLine = coordLine.split(',');

    return {
      x: parseFloat(splitedCoordLine[0] ? splitedCoordLine[0].trim() : '0'),
      y: parseFloat(splitedCoordLine[1] ? splitedCoordLine[1].trim() : '0'),
      z: parseFloat(splitedCoordLine[2] ? splitedCoordLine[2].trim() : '0'),
    };
  };

  private convertCoordinates = (
    x: number,
    y: number,
    source: string,
    sourceProjection: string | null,
    dest: string = 'EPSG:4326',
    destProjection: string | null = null
  ): { x: number; y: number } => {
    if (sourceProjection !== null) {
      proj4.defs(source, sourceProjection);
    }

    if (destProjection !== null) {
      proj4.defs(dest, destProjection);
    }

    const sourceProj = new proj4.Proj(source);
    const destProj = new proj4.Proj(dest);
    const p = { x, y };

    return proj4(sourceProj, destProj, p);
  };

  private flipViewType = (
    viewType: TerrainMapsTabsLayout2StateSelectedDsmViewIdTypeTypes
  ): void => {
    const { vcpGeoJsonValues, selectedTerrainMask } = this.state;

    this.setState(
      {
        selectedDsmViewIdType: viewType,
        vcpGeoJsonValues: null,
        selectedTerrainMask: null,
      },
      () => {
        // waiting some time before setting state, to allow drawcontrol to be removed, and then re-added
        setTimeout(() => {
          this.setState({ vcpGeoJsonValues, selectedTerrainMask });
        }, 1200);
      }
    );
  };

  private flipTerrainMasks = (checked: boolean): void => {
    if (!checked) {
      this.setState({
        selectedTerrainMask: null,
      });

      return;
    }

    this.fetchTerrainMasks();
  };

  private fetchTerrainMasks = async (): Promise<void> => {
    const { match, actionType, dtmData } = this.props;
    const { projectId, aoiId } = match.params;
    const { selectedDsm } = this.state;

    if (!selectedDsm || !selectedDsm.missionId) {
      return;
    }

    this.setState(
      {
        terrainMaskLoading: true,
      },
      async () => {
        let automatedMaskGeoJson = null;
        let aoiBoundaryGeoJson = null;

        const { data: automatedGroundAreas, error: automatedDataError } =
          await terrainMasksApis.getTerrainMasks(selectedDsm.missionId);

        if (!automatedDataError && automatedGroundAreas) {
          automatedMaskGeoJson = {
            type: 'FeatureCollection',
            features: [...(automatedGroundAreas.features || [])],
          };
        }

        const aoiBoundary = await projectApis
          .fetchAoiBoundary(projectId, aoiId)
          .catch(() => null);

        if (aoiBoundary) {
          aoiBoundaryGeoJson = {
            type: 'FeatureCollection',
            features: [...(aoiBoundary.features || [])],
          };
        }

        if (actionType === 'edit') {
          if (!dtmData) {
            return;
          }

          const { error: dtmArtifactError, data: dtmArtifactData } =
            await terrainMapsApis.getDtmArtifact(projectId, aoiId, dtmData.id);

          const { error: savedArtifactError, data: savedArtifactData } =
            await terrainMapsApis.getSavedDtmArtifact(
              projectId,
              aoiId,
              dtmData.id
            );

          let terrainMaskGeoJson = null;
          let savedGroundAreasGeoJson = null;

          if (!dtmArtifactError && dtmArtifactData) {
            terrainMaskGeoJson = {
              type: 'FeatureCollection',
              features: [...(dtmArtifactData.features || [])],
            };
          }

          if (!savedArtifactError && savedArtifactData) {
            savedGroundAreasGeoJson = {
              type: 'FeatureCollection',
              features: [...(savedArtifactData.features || [])],
            };
          }

          this.setState({
            selectedTerrainMask: terrainMaskGeoJson,
            savedGroundAreas: savedGroundAreasGeoJson,
            automatedGroundAreas: automatedMaskGeoJson,
            aoiBoundary: aoiBoundaryGeoJson,
            terrainMaskLoading: false,
          });

          return;
        }

        this.setState({
          automatedGroundAreas: automatedMaskGeoJson,
          aoiBoundary: aoiBoundaryGeoJson,
          terrainMaskLoading: false,
        });
      }
    );
  };

  private generateBtnName = (): string => {
    const { dtmData } = this.props;

    if (!dtmData || dtmData.status === 'pending') {
      return 'GENERATE TERRAIN MAP';
    }

    return 'REGENERATE TERRAIN MAP';
  };

  private processGenerateDtmBtn = (): void => {
    const {
      match,
      projectData,
      user,
      showSnackbar,
      setDtm,
      sourceType,
      dtmData,
      actionType,
      epsgCode,
      epsgProjection,
      form,
    } = this.props;
    const { selectedDsm } = this.state;
    const { projectId, aoiId } = match.params;

    if (!selectedDsm) {
      showSnackbar({
        body: `Mission Date is required`,
        type: 'error',
      });

      return;
    }

    form.validateFields(['dsmName'], (err: any, values: any) => {
      if (!err) {
        const bodyFormRequestData = {
          sourceType,
          sourceDEM: selectedDsm.demId,
          date: formatDateTime(selectedDsm.date),
          missionId: selectedDsm.missionId,
          name: values.dsmName,
          parameters: {
            elevationAttribute: 'ELEV',
          },
          artifactType: 'geojson',
        };
        const bodyFormData = new FormData();
        const bodyFormRequestDataBlob = new Blob(
          [JSON.stringify(bodyFormRequestData)],
          { type: 'application/json' }
        );

        bodyFormData.append('request', bodyFormRequestDataBlob);

        if (this.viewDsmRef.current) {
          const geoJsonValues = this.viewDsmRef.current
            .getWrappedInstance()
            .getGeoJsonValues();

          // Polygon GeoJson
          if (
            geoJsonValues.additionalPolygons.length > 0 ||
            geoJsonValues.terrainMasks.length > 0
          ) {
            const polygonGeoJson = {
              type: 'FeatureCollection',
              features: [
                ...geoJsonValues.additionalPolygons,
                ...geoJsonValues.terrainMasks,
              ],
            };

            const selectedTerrainMaskBlob = new Blob(
              [JSON.stringify(polygonGeoJson)],
              { type: 'application/json' }
            );

            bodyFormData.append('sourceData', selectedTerrainMaskBlob);
          }

          // VCP GeoJson
          const vcpCoords = geoJsonValues.vcp.map(
            (a: GenericObjectType | null) => {
              const coords = [];

              if (!a) {
                return [];
              }

              const transformCoords = this.convertCoordinates(
                a.geometry.coordinates[0],
                a.geometry.coordinates[1],
                'EPSG:4326',
                null,
                `EPSG:${epsgCode}`,
                epsgProjection
              );

              coords.push(transformCoords.x);
              coords.push(transformCoords.y);
              coords.push(parseFloat(a.properties.z));

              return coords;
            }
          );

          if (vcpCoords.length > 0) {
            const vcpCsv = arrayToCsv('x,y,z', vcpCoords);

            const selectedVcpBlob = new Blob([vcpCsv], {
              type: 'text/plain',
            });

            bodyFormData.append('vcpFile', selectedVcpBlob);
          }
        }

        this.setState({
          generateBtnLoading: true,
        });

        terrainMapsApis
          .postDtm(projectId, aoiId, bodyFormData, dtmData?.id || null)
          .then((res: TerrainMapsApisReturnPromiseTypes) => {
            if (undefinedOrNull(res)) {
              showSnackbar({
                body: `Some error occured. Try again!`,
                type: 'error',
              });

              this.setState({
                generateBtnLoading: false,
              });

              return;
            }

            if (res.error) {
              showSnackbar({
                body: res.error,
                type: 'error',
              });

              this.setState({
                generateBtnLoading: false,
              });

              return;
            }

            setDtm(res.data);

            if (actionType === 'edit') {
              this.setState({
                generateBtnLoading: false,
                newDtmCreated: false,
              });
            } else {
              this.setState({
                generateBtnLoading: false,
                newDtmCreated: true,
              });
            }

            let executionInfo = '';

            if (
              projectData &&
              projectData.projectType &&
              projectData.projectType === 'POST_PROCESSING_DEMO_PROJECT' &&
              user &&
              !user.staff
            ) {
              if (
                res.data.dtmGenerationCount != null &&
                res.data.dtmGenerationLimit != null
              ) {
                const { dtmGenerationCount, dtmGenerationLimit } = res.data;

                executionInfo = ` You have used up one Terrain Map, and you have ${
                  dtmGenerationLimit - dtmGenerationCount
                } generations left for this demo project.`;
              }
            }

            showSnackbar({
              body: `Terrain Map is being generated.${executionInfo} Please wait, you will receive an email when Terrain Map is generated.`,
              type: 'info',
            });
          });
      }
    });
  };

  private getDefaultMissionDate = (): { initialValue?: string } => {
    const { actionType } = this.props;
    const { selectedDsm } = this.state;

    if (selectedDsm && actionType === 'edit') {
      return { initialValue: appFormatDate(selectedDsm.date) };
    }

    return {};
  };

  private getDefaultDSMName = (): string | null => {
    const { dtmData, sourceType } = this.props;

    if (dtmData && dtmData.sourceType === sourceType && dtmData.name) {
      return dtmData.name;
    }

    return null;
  };

  private disableMissionDate = (): boolean => {
    const { actionType } = this.props;
    const { selectedDsm } = this.state;

    return !!(selectedDsm && actionType === 'edit');
  };

  private handleMapFullScreenToggle = (): void => {
    this.setState(
      ({ isMapFullScreen }) => {
        return {
          isMapFullScreen: !isMapFullScreen,
        };
      },
      () => {
        const { isMapFullScreen } = this.state;

        if (isMapFullScreen) {
          setBodyOverflow('y');

          return;
        }

        resetBodyOverFlow('y');
      }
    );
  };

  private handleDsmMapToolsFullScreenToggle = (): void => {
    const { form, showSnackbar } = this.props;

    form.validateFields(['dsmName'], (err: GenericObjectType) => {
      if (!err) {
        this.setState(
          ({ isDsmMapToolFullScreen }) => {
            return {
              isDsmMapToolFullScreen: !isDsmMapToolFullScreen,
            };
          },
          () => {
            const { isDsmMapToolFullScreen } = this.state;
            const { actionType } = this.props;

            if (isDsmMapToolFullScreen) {
              setBodyOverflow('y');
              this.redirectDsmMapUrl();

              return;
            }

            resetBodyOverFlow('y');
            if (actionType === 'new') {
              // saving DTM even onlyis not in edit state
              this.saveDtmDataOnUpdate(false);
            }

            this.redirectDsmMapUrl(true);
          }
        );
      } else {
        showSnackbar({
          body: 'Please specify a valid name for the Terrain Map, before marking terrain areas.',
          type: 'error',
        });
      }
    });
  };

  private finalizeDSM = () => {
    const { form, onFinalizeBtn } = this.props;

    form.validateFields(['dsmName'], (err: any, values: any) => {
      if (!err) {
        onFinalizeBtn(values.dsmName);
      }
    });
  };

  private handleCsvExampleModalShow = () => {
    this.setState({
      showCsvExampleModal: true,
    });
  };

  private handleCsvExampleModalClose = () => {
    this.setState({
      showCsvExampleModal: false,
    });
  };

  private finalizeConfirmBodyText = (): string => {
    const { dtmData } = this.props;

    if (dtmData && dtmData.finalized) {
      return `Un-publishing this Terrain Map will remove the Terrain Map from public display. Any associated contours & Cut-Fill Reports will be un-published as well. Are you sure you want to proceed?`;
    }

    return `Are you sure you want to publish the Terrain Map?`;
  };

  private showMarkTerrainAreasPopup = (): boolean => {
    const { selectedDsm } = this.state;
    const { dtmData } = this.props;

    if (!(selectedDsm && Object.keys(selectedDsm).length > 0)) {
      return false;
    }

    if (dtmData) {
      return (
        dtmData.status !== 'started' &&
        dtmData.status !== 'starting' &&
        !dtmData.finalized
      );
    }

    return true;
  };

  private validateFieldValue = (rule: any, value: any, callback: any) => {
    const { unit } = this.props;

    if (!undefinedOrNull(value)) {
      if (value !== '' && value < toMetersEquivalent(0.1, unit)) {
        callback(
          `Field value must be greater than ${toMetersEquivalent(0.1, unit)}`
        );
      }
    }

    callback();
  };

  private disableFieldsOnEmptyDsms() {
    const { dsmsData } = this.props;

    return dsmsData && dsmsData.length < 1;
  }

  private handleShowGenerateConfirmModal = (): void => {
    this.setState(({ showGenerateConfirmModal }) => {
      return {
        showGenerateConfirmModal: !showGenerateConfirmModal,
      };
    });
  };

  private handleGenerateConfirmModal = (value: boolean): void => {
    this.setState(() => {
      return {
        showGenerateConfirmModal: false,
      };
    });

    if (!value) {
      return;
    }

    this.processGenerateDtmBtn();
  };

  private generateConfirmBodyText = (): string => {
    const { dtmData } = this.props;

    if (!dtmData || dtmData.status === 'pending') {
      return 'Are you sure you want to start Terrain Map generation with these parameters?';
    }

    return `Are you sure you want to re-generate this Terrain Map with these parameters? The existing Terrain Map will be cleaned up.`;
  };

  private saveDtmDataOnUpdate = async (
    checkActionType: boolean = true
  ): Promise<boolean> => {
    if (this.viewDsmRef.current) {
      const { match, setDtm, sourceType, dtmData, actionType, form } =
        this.props;
      const { selectedDsm } = this.state;
      const { projectId, aoiId } = match.params;

      if (checkActionType && actionType !== 'edit') {
        // exit without saving, with no error
        return true;
      }

      const geoJsonValues = this.viewDsmRef.current
        .getWrappedInstance()
        .getGeoJsonValues();

      return new Promise<boolean>((resolve) => {
        form.validateFields(['dsmName'], (err: any, values: any) => {
          const bodyFormRequestData = {
            sourceType,
            name: values.dsmName,
            parameters: {
              elevationAttribute: 'ELEV',
            },
            artifactType: 'geojson',
          };

          let dsmData = {};

          if (selectedDsm) {
            dsmData = {
              sourceDEM: selectedDsm.demId,
              date: formatDateTime(selectedDsm.date),
              missionId: selectedDsm.missionId,
            };
          }

          const bodyFormData = new FormData();
          const bodyFormRequestDataBlob = new Blob(
            [JSON.stringify({ ...bodyFormRequestData, ...dsmData })],
            { type: 'application/json' }
          );

          bodyFormData.append('request', bodyFormRequestDataBlob);

          if (
            geoJsonValues.additionalPolygons.length > 0 ||
            geoJsonValues.terrainMasks.length > 0
          ) {
            const polygonGeoJson = {
              type: 'FeatureCollection',
              features: [
                ...geoJsonValues.additionalPolygons,
                ...geoJsonValues.terrainMasks,
              ],
            };

            const selectedTerrainMaskBlob = new Blob(
              [JSON.stringify(polygonGeoJson)],
              { type: 'application/json' }
            );

            bodyFormData.append('saveData', selectedTerrainMaskBlob);
          }

          terrainMapsApis
            .saveDtmWithArtifact(
              projectId,
              aoiId,
              bodyFormData,
              dtmData?.id || null
            )
            .then((res: TerrainMapsApisReturnPromiseTypes) => {
              if (undefinedOrNull(res) || res.error) {
                return resolve(false);
              }

              setDtm(res.data);
              if (actionType === 'edit') {
                this.setState({
                  newDtmCreated: false,
                });
              } else {
                this.setState({
                  newDtmCreated: true,
                });
              }

              resolve(true);
            });
        });
      });
    }

    return false;
  };

  public render(): React.ReactNode {
    const {
      history,
      match,
      form,
      dsmsData,
      disableGenerateBtn,
      dtmData,
      importBtnText,
      disableFinalizeBtn,
      finalizeBtnLoading,
      epsgCode,
    } = this.props;
    const {
      selectedDsm,
      selectedDsmViewIdType,
      vcpGeoJsonValues,
      selectedTerrainMask,
      savedGroundAreas,
      automatedGroundAreas,
      aoiBoundary,
      generateBtnLoading,
      newDtmCreated,
      isMapFullScreen,
      isDsmMapToolFullScreen,
      showEpsgConfirmModal,
      uploadVcpFileKey,
      showFinalizeConfirmModal,
      showCsvExampleModal,
      showGenerateConfirmModal,
      terrainMaskLoading,
    } = this.state;

    const { projectId, aoiId } = match.params;
    const { getFieldDecorator } = form;

    if (newDtmCreated && dtmData) {
      return (
        <Redirect
          to={`/project/${projectId}/aoi/${aoiId}/TerrainMaps/edit/${dtmData.id}`}
        />
      );
    }

    return (
      <div>
        <div className={styles.container}>
          <div className={styles.inputFieldContainer}>
            <Form layout="vertical">
              <div className={styles.inputFieldWrapper}>
                <Form.Item label="Name">
                  {getFieldDecorator('dsmName', {
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                    initialValue: this.getDefaultDSMName(),
                  })(
                    <Input
                      placeholder="Name"
                      style={{ margin: 0 }}
                      className={styles.inputField}
                      disabled={
                        dtmData?.finalized || this.disableFieldsOnEmptyDsms()
                      }
                    />
                  )}
                </Form.Item>
                <HelpTooltip
                  position="right"
                  helpText="Name to be used for public display."
                />
              </div>
              <div className={styles.inputFieldWrapper}>
                <Form.Item label="Mission Date">
                  {getFieldDecorator('dsmDate', {
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                    ...this.getDefaultMissionDate(),
                  })(
                    <Select
                      placeholder="Pick a date"
                      optionFilterProp="value"
                      className={styles.inputField}
                      onChange={this.handleDsmDateSelect}
                      disabled={
                        this.disableMissionDate() ||
                        this.disableFieldsOnEmptyDsms()
                      }
                    >
                      {(dsmsData || []).map((item: GenericObjectType) => {
                        return (
                          <SelectOption key={item.demId} value={item.date}>
                            {appFormatDate(item.date)}
                          </SelectOption>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
                <HelpTooltip
                  position="right"
                  helpText="Data gathering mission date, for which the data was processed and a surface map generated in Vimana."
                />
              </div>

              <input
                type="file"
                id="uploadVcpFileRef"
                key={uploadVcpFileKey}
                ref={this.uploadVcpFileRef}
                hidden
                multiple={false}
                accept={this.acceptedVcpFile}
                onChange={(events: React.ChangeEvent<HTMLInputElement>) =>
                  this.handleUploadFileChange(events)
                }
              />

              {selectedDsm && Object.keys(selectedDsm).length > 0 ? (
                <div className={styles.dsmDataInputWrapper}>
                  <div className={styles.inputFieldWrapper}>
                    <input
                      type="file"
                      id="uploadVcpFileRef"
                      key={uploadVcpFileKey}
                      ref={this.uploadVcpFileRef}
                      hidden
                      multiple={false}
                      accept={this.acceptedVcpFile}
                      onChange={(events: React.ChangeEvent<HTMLInputElement>) =>
                        this.handleUploadFileChange(events)
                      }
                    />

                    <Button
                      type="primary"
                      disabled={
                        (dtmData && dtmData.finalized) || disableGenerateBtn()
                      }
                      text={
                        isDsmMapToolFullScreen === null
                          ? 'Mark Terrain Areas'
                          : 'Update Terrain Areas'
                      }
                      className={styles.editDtmBtn}
                      onClick={() => this.handleDsmMapToolsFullScreenToggle()}
                    />
                    <HelpTooltip
                      position="right"
                      helpText="Mark the terrain areas in the displayed map. Initially, you will be shown an AI generated estimate of the terrain areas. You can edit it to make it more accurate."
                    />
                  </div>
                </div>
              ) : null}

              {selectedDsm && Object.keys(selectedDsm).length > 0 && (
                <Button
                  className={styles.generateBtn}
                  disabled={disableGenerateBtn()}
                  text={this.generateBtnName()}
                  loadingText="Generating..."
                  loading={generateBtnLoading}
                  onClick={() => {
                    this.handleShowGenerateConfirmModal();
                  }}
                />
              )}

              {dtmData ? (
                <div className={styles.inputFieldWrapper}>
                  <Button
                    className={styles.finalizeBtn}
                    text={dtmData.finalized ? 'UNPUBLISH' : 'PUBLISH'}
                    disabled={disableFinalizeBtn()}
                    loading={finalizeBtnLoading}
                    loadingText={
                      dtmData.finalized ? 'Unpublishing...' : 'Publishing...'
                    }
                    onClick={() => {
                      this.handleFinalizeModalShow();
                    }}
                  />
                  {!dtmData.finalized && (
                    <HelpTooltip
                      position="right"
                      helpText="Publishing the Terrain Map will make it available for public display."
                    />
                  )}
                </div>
              ) : null}
            </Form>
          </div>

          <div
            className={classnames(styles.viewContainer, {
              [styles.fullScreen]: isMapFullScreen,
            })}
          >
            {dsmsData && dsmsData.length < 1 ? (
              'Create a Mission for Aerial & Elevation Views, and finish processing, to enable Terrain Map creation from a Surface Map'
            ) : dtmData && dtmData.status !== 'pending' ? (
              <div className={styles.postGeneratedContainer}>
                {dtmData.status === 'error' ? (
                  MapGeneratedStatus({
                    data: dtmData,
                    type: 'dtm',
                    error: true,
                  })
                ) : dtmData.status === 'completed' && dtmData.viewId ? (
                  <div
                    className={classnames(styles.viewWrapper, {
                      [styles.fullScreen]: isMapFullScreen,
                    })}
                  >
                    <View
                      viewId={dtmData.viewId}
                      projectId={dtmData.projectId}
                      aoiId={dtmData.aoiId}
                      onEvent={() => {}}
                      view={dtmData.generatedView}
                      history={{} as any}
                    />
                  </div>
                ) : (
                  MapGeneratedStatus({
                    data: dtmData,
                    type: 'dtm',
                    generating: true,
                  })
                )}
              </div>
            ) : (
              MapGeneratedStatus({
                data: dtmData || null,
                type: 'dtm',
              })
            )}
          </div>
        </div>

        {this.showMarkTerrainAreasPopup() ? (
          <div
            className={classnames(styles.dsmMapToolsViewContainer, {
              [styles.fullScreen]: isDsmMapToolFullScreen,
            })}
          >
            <ViewDsm
              key={dtmData?.id}
              viewId={selectedDsm?.[selectedDsmViewIdType]}
              {...match.params}
              history={history}
              isDsm
              vcpGeoJsonValues={vcpGeoJsonValues}
              terrainMaskGeoJsonValues={selectedTerrainMask}
              savedGroundAreasJsonValues={savedGroundAreas}
              automatedGroundAreasJsonValues={automatedGroundAreas}
              aoiBoundaryJsonValues={aoiBoundary}
              onToggleDsmViewType={this.flipViewType}
              selectedDsmViewType={selectedDsmViewIdType}
              onFullScreen={this.handleDsmMapToolsFullScreenToggle}
              isFullScreen={isDsmMapToolFullScreen ?? undefined}
              ref={this.viewDsmRef}
              importBtnText={importBtnText}
              disableImportBtn={dtmData?.finalized || disableGenerateBtn()}
              onFetchTerrainMasks={this.fetchTerrainMasks}
              showCsvExampleModal={showCsvExampleModal}
              saveDtmData={this.saveDtmDataOnUpdate}
              onCsvExampleModalShow={this.handleCsvExampleModalShow}
              terrainMapsSurfaceViewTypeList={this.checkTerrainMapsSurfaceView()}
            />
          </div>
        ) : null}

        {showEpsgConfirmModal ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody={`Please confirm that the provided input file has coordinates in the Coordinate system defined as per the Project EPSG Code,"EPSG: ${epsgCode}".`}
            shownotificationModal
            handleModalClose={this.handleEpsgModalClose}
            cancelButtonTitle="CANCEL"
            okButtonTitle="OK"
            isConfirm
          />
        ) : null}

        {showFinalizeConfirmModal ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody={this.finalizeConfirmBodyText()}
            shownotificationModal
            handleModalClose={this.handleFinalizeModalClose}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}

        {showCsvExampleModal ? (
          <Modal
            title="Example CSV"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onCancel={this.handleCsvExampleModalClose}
            className={styles.csvExampleContainer}
          >
            <p className={styles.headertext}>
              {`Example of an acceptable CSV file. The header should be 'x,y,z', in lower-case, and in the specified order.`}
            </p>

            <img
              className={styles.ErrorImage}
              src={csvExample}
              alt="errorImg"
            />
            <p className={styles.vcpCoordinationText}>
              {`VCP readings should be in the same coordinate system as the Ground Control Points (GCPs) used to create the surface map.`}
            </p>
            <div className={styles.csvExampleModalDiv}>
              <Button
                className={styles.cancelModalButton}
                onClick={this.handleCsvExampleModalClose}
                text="Cancel"
              />
              <Button
                className={styles.importModalButton}
                onClick={this.handleImportVcpClick}
                text="Import"
              />
            </div>
          </Modal>
        ) : null}

        {showGenerateConfirmModal ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody={this.generateConfirmBodyText()}
            shownotificationModal
            handleModalClose={this.handleGenerateConfirmModal}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}
        {generateBtnLoading || finalizeBtnLoading || terrainMaskLoading ? (
          <LoadingOverlay />
        ) : null}
      </div>
    );
  }
}

export default Form.create<IProps>()(TerrainMapsTabsLayout2);
