'use strict';

import moment from 'moment';
import { isString } from './functs';

export const momentDateNow = (): any => {
  return moment();
};

export const dateNow = (monthInletters: boolean = false): string => {
  let monthFormat = `MM`;

  if (monthInletters) {
    monthFormat = `MMM`;
  }

  return moment().format(`YYYY-${monthFormat}-DD`);
};

export const yearMonthNow = (monthInletters: boolean = false): string => {
  let monthFormat = `MM`;

  if (monthInletters) {
    monthFormat = `MMM`;
  }

  return moment().format(`YYYY-${monthFormat}`);
};

export const dateTimeNow = (monthInletters: boolean = false): string => {
  let monthFormat = `MM`;

  if (monthInletters) {
    monthFormat = `MMM`;
  }

  return moment().format(`YYYY-${monthFormat}-DD HH:mm:ss`);
};

export const formatDateTime = (date: string): string => {
  return `${moment.utc(date).format(`YYYY-MM-DDT00:00:00`)}Z`;
};

export const convertToUtc = (date: Date | string): string => {
  if (isString(date)) {
    return `${date.split('T')[0]}T00:00:00Z`;
  }

  return `${date.toISOString().split('T')[0]}T00:00:00Z`;
};

export const dateConvertUkStyle = (
  date: string,
  monthInletters: boolean = false
): string => {
  let monthFormat = `MM`;

  if (monthInletters) {
    monthFormat = `MMM`;
  }

  return moment(date).format(`DD-${monthFormat}-YYYY`);
};

export const formatDateForDatePicker = (date: string): any => {
  return moment(date, 'YYYY-MM-DD');
};

export const dateTimeUnixTimestampNow = (
  monthInletters: boolean = false
): string => {
  let monthFormat = `MM`;

  if (monthInletters) {
    monthFormat = `MMM`;
  }

  return moment().format(`YYYY-${monthFormat}-DD HH:mm:ss (x)`);
};

export const unixTimestampNow = (): string => {
  return moment().format(`x`);
};

export const msToTime = (milliseconds: number): string => {
  const hours = milliseconds / (1000 * 60 * 60);
  const absoluteHours = Math.floor(hours);
  const h = absoluteHours > 9 ? absoluteHours : `0${absoluteHours}`;

  const minutes = (hours - absoluteHours) * 60;
  const absoluteMinutes = Math.floor(minutes);
  const m = absoluteMinutes > 9 ? absoluteMinutes : `0${absoluteMinutes}`;

  const seconds = (minutes - absoluteMinutes) * 60;
  const absoluteSeconds = Math.floor(seconds);
  const s = absoluteSeconds > 9 ? absoluteSeconds : `0${absoluteSeconds}`;

  return `${h}:${m}:${s}`;
};

export const daysDiff = (startDate: string, endDate: string): number => {
  const start = moment(startDate, 'YYYY-MM');
  const end = moment(endDate, 'YYYY-MM');

  return moment.duration(start.diff(end)).asDays();
};

export const appFormatDate = (date: string): string => {
  return formatDateForDatePicker(date).format('ll');
};

export const appFormatDateTime = (date: string): string => {
  return moment(date, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY hh:mm:ss A');
};

export const isDateAfter = (date1: string, date2: string): boolean => {
  return moment(date1).isAfter(date2);
};

export const getTimestamp = (
  date: string,
  tz: string | null = null,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  if (tz) {
    return moment(date, format).tz(tz).format('X');
  }

  return moment(date, format).format('X');
};

export const getDateTimeWithoutTz = (date: string) => {
  return moment.utc(date).format('MMM DD, YYYY hh:mm:ss A');
};

export const getDateTimeFromTimestamp = (timestamp: number) => {
  return moment.unix(timestamp).format('MMM DD, YYYY hh:mm:ss A');
};

export const convertISODateToDayMonthYearFormat = (date: string) => {
  return moment(date.split('T')[0]).format('ll');
};
