import MarkerClusterer from '@google/markerclusterer';
import * as deepEqual from 'deep-equal';
import * as React from 'react';
import {
  GOOGLE_MAP_MARKER_SELECTED_COLOR,
  ML_OBJECT_COLOR,
} from '../../constants/colors';
import { GOOGLE_GLOBAL_KEY } from '../../constants/tokens';
import { GenericObjectType } from '../../shapes/app';
import { undefinedOrNull } from '../../utils/functs';
import { log } from '../../utils/log';
import {
  coordsToGoogleMapPositionList,
  defaultHaloMarker,
  drawHaloMarker,
  drawHaloPointMarker,
  findClosestLatLong,
  getMarkerPointsPos,
  googleMapMarkerClustererStyles,
  googleMapMarkerIcon,
  handleHaloMarkerOnMapZoomChange,
  handleMarkerLabelOnMapZoomChange,
  loadGoogleMapApi,
  processGoogleMapClick,
  registerExpandCustomActionButton,
} from './helpers';
import styles from './index.module.scss';
import {
  CoordinatesListTypes,
  GoogleHaloMarkerTypes,
  GoogleMapsPositionTypes,
  PlotGoogleMapDrawingManagerStore,
  PlotGoogleMapDrawManagerModesTypes,
  PlotGoogleMapPropsTypes,
} from './index.types';

export default class PlotGoogleMap extends React.PureComponent<PlotGoogleMapPropsTypes> {
  public static defaultProps: Partial<PlotGoogleMapPropsTypes> = {
    // do not set initialZoom here.
    doubleClickToZoom: false,
    clickToZoom: false,
    markerClustererMaxZoom: 18,
    showHaloMarker: true,
    showMarkerClusterer: false,
    labelOriginPoints: [10, 35],
    markerClustererIcon: 'RED_CAMERA',
    gestureHandling: 'auto',
    maintainZoomLevel: false,
  };

  private googleMapNode: HTMLDivElement | null;

  private readonly defaultMapProperties: GenericObjectType;

  private googleMaps: null | GenericObjectType = null;

  private map: null | GenericObjectType = null;

  private polygon: GenericObjectType[] = [];

  private markers: GenericObjectType[] = [];

  private drawingManager: null | GenericObjectType = null;

  private mapZoomLevel: number | null = null;

  private deleteControlBtnSelector = 'deleteControl';

  private helpInfoPopupSelector = 'helpInfo';

  private helpInfoPopupTextSelector = 'helpInfoText';

  private helpInfoPopupContainerSelector = 'helpInfoContainer';

  private deleteControlBtnContainerNode: HTMLDivElement | null = null;

  private haloMarker: GoogleHaloMarkerTypes = {
    halo: null,
    point: null,
  };

  private combinedMarkersList: GenericObjectType = {};

  private drawingManagerStore: PlotGoogleMapDrawingManagerStore = {
    polygon: {
      coordinates: [],
      overlay: [],
    },
  };

  public constructor(props: PlotGoogleMapPropsTypes) {
    super(props);

    const { doubleClickToZoom, gestureHandling, maxZoom, minZoom } = this.props;

    const mapProps: GenericObjectType = {};

    if (!undefinedOrNull(maxZoom)) {
      mapProps.maxZoom = maxZoom;
    }

    if (!undefinedOrNull(minZoom)) {
      mapProps.minZoom = minZoom;
    }

    this.defaultMapProperties = {
      ...mapProps,
      zoom: 4,
      center: {
        // Bangalore
        lat: 12.966692,
        lng: 77.532199,
      },
      disableDoubleClickZoom: !doubleClickToZoom,
      gestureHandling,
    };
  }

  public componentDidMount() {
    this.fetchGoogleMapApi();
  }

  public componentDidUpdate({
    markerPointsList: prevMarkerPointsList,
    boundaryPointsList: prevBoundaryPointsList,
    selectedMarkerIndex: prevSelectedMarkerIndex,
    mapGeneratedTime: prevMapGeneratedTime,
    showMLObjectsLegend: prevInfoContent,
  }: Readonly<PlotGoogleMapPropsTypes>): void {
    const {
      markerPointsList,
      boundaryPointsList,
      selectedMarkerIndex,
      mapGeneratedTime,
      showMLObjectsLegend: infoContent,
    } = this.props;

    if (mapGeneratedTime !== prevMapGeneratedTime) {
      this.handleResetMap();

      return;
    }

    if (
      prevSelectedMarkerIndex !== selectedMarkerIndex &&
      this.markers.length > 0
    ) {
      this.updateActiveMarkers();
    } else if (prevMarkerPointsList !== markerPointsList) {
      this.updateActiveMarkers();
    }

    if (
      JSON.stringify(prevBoundaryPointsList) !==
      JSON.stringify(boundaryPointsList)
    ) {
      this.drawPolygons();
    }

    if (!prevInfoContent && infoContent) {
      this.handleMLObjectsLegend();
    }

    if (!deepEqual(markerPointsList, prevMarkerPointsList)) {
      this.handleResetMap();
    }
  }

  public componentWillUnmount(): void {
    this.deregisterMapEvents();
  }

  private getShowMarkerClusterer = () => {
    const { showMarkerClusterer } = this.props;

    return showMarkerClusterer;
  };

  private updateActiveMarkers = () => {
    const { showHaloMarker, selectedMarkerIndex, markerPointsList } =
      this.props;

    if (undefinedOrNull(this.googleMaps)) {
      return;
    }

    if (this.combinedMarkersList?.default) {
      this.combinedMarkersList.default.setMap(null);

      this.combinedMarkersList.default = null;
    }

    if (undefinedOrNull(markerPointsList)) {
      return;
    }

    const labelOrigin = this.getLabelOriginPoints();

    markerPointsList.map((a, index) => {
      if (index === selectedMarkerIndex) {
        if (showHaloMarker && this.markers[index]) {
          this.setHaloMarker(index);
        } else if (this.markers[index]) {
          this.markers[index].setIcon(
            googleMapMarkerIcon(
              this.googleMaps,
              this.map,
              markerPointsList,
              index,
              'selected',
              this.combinedMarkersList,
              labelOrigin
            )
          );

          this.markers[index].setZIndex(10008);
        }
      } else if (this.markers[index]) {
        this.markers[index].setIcon(
          googleMapMarkerIcon(
            this.googleMaps,
            this.map,
            markerPointsList,
            index,
            'unselected',
            this.combinedMarkersList,
            labelOrigin
          )
        );
      }

      return a;
    });
  };

  private setHaloMarker = (index: number) => {
    const { markerPointsList, customHaloMarker } = this.props;

    if (undefinedOrNull(this.googleMaps)) {
      return;
    }

    const marker = this.markers[index];

    const markerPos = {
      lat: marker.getPosition().lat(),
      lng: marker.getPosition().lng(),
    };

    this.haloMarker.halo = drawHaloMarker(
      this.googleMaps,
      this.map,
      this.haloMarker,
      markerPos,
      customHaloMarker ? customHaloMarker.icon.halo : null
    );

    this.haloMarker.point = drawHaloPointMarker(
      this.googleMaps,
      this.map,
      this.haloMarker,
      markerPos,
      customHaloMarker ? customHaloMarker.icon.point : null
    );

    this.markers[index].setIcon(
      googleMapMarkerIcon(
        this.googleMaps,
        this.map,
        markerPointsList,
        index,
        'selected',
        this.combinedMarkersList,
        this.getLabelOriginPoints()
      )
    );

    this.markers[index].setZIndex(10008);
  };

  private handleResetMap = (): void => {
    this.deregisterMapEvents();

    if (this.polygon.length > 0) {
      this.polygon.map((a) => {
        a.setMap(null);

        return a;
      });
    }

    if (this.markers) {
      this.markers.map((item: GenericObjectType) => {
        item.setMap(null);

        return item;
      });
    }

    if (this.drawingManager) {
      this.drawingManager.setMap(this.map);
    }

    this.map = null;
    this.polygon = [];
    this.markers = [];
    this.drawingManager = null;
    this.haloMarker = defaultHaloMarker();
    this.combinedMarkersList = {};

    this.fetchGoogleMapApi();
  };

  private handleMLObjectsLegend = () => {
    const { showMLObjectsLegend } = this.props;

    if (!this.map || !showMLObjectsLegend || !this.googleMaps) {
      return;
    }

    let infoDiv = document.getElementById(this.helpInfoPopupSelector);

    if (!infoDiv) {
      infoDiv = document.createElement('div');
      infoDiv.id = this.helpInfoPopupSelector;

      this.map.controls[this.googleMaps.ControlPosition.BOTTOM_CENTER].push(
        infoDiv
      );
    }

    infoDiv.style.display = 'block';

    // eslint-disable-next-line no-new
    new (this.getMLObjectsLegend as any)(infoDiv);
  };

  private recenterMarker = (
    marker: GenericObjectType,
    zoom?: null | number
  ): void => {
    const { maintainZoomLevel } = this.props;

    if (!this.map || !this.googleMaps) {
      return;
    }

    this.map.setCenter(marker.getPosition());
    this.map.panTo(marker.getPosition());

    // if the maintainZoomLevel & this.mapZoomLevel are present then recenter the map to the selected marker
    if (maintainZoomLevel && !undefinedOrNull(this.mapZoomLevel)) {
      this.zoomIntoMap(this.mapZoomLevel);
    } else if (!undefinedOrNull(zoom)) {
      this.zoomIntoMap(zoom);
    }
  };

  private zoomIntoMap = (zoom: number): void => {
    if (!this.googleMaps || !this.map) {
      return;
    }

    this.map.setOptions({ minZoom: zoom });

    const listener = this.googleMaps.event.addListener(this.map, 'idle', () => {
      if (!this.googleMaps || !this.map) {
        return;
      }

      this.map.setOptions({ minZoom: null });

      this.googleMaps.event.removeListener(listener);
    });
  };

  private fetchGoogleMapApi = (): void => {
    loadGoogleMapApi(this.initList);
  };

  private initList = async () => {
    try {
      this.initMap();
      const {
        boundaryPointsList,
        customActionButtonList,
        drawManagerOptions,
        shapeList,
      } = this.props;

      if (boundaryPointsList) {
        this.drawPolygons();
      }

      if (drawManagerOptions) {
        this.setDrawManager();
      } else {
        this.setFalseDrawManager();
        // display ML objects legend only if draw control is disabled
        this.handleMLObjectsLegend();
      }

      if (shapeList) {
        this.drawShapes();
      }

      this.drawMarkers();
      this.registerMapEvents();

      if (customActionButtonList && customActionButtonList.length > 0) {
        this.registerCustomActionButton();
      }
    } catch (e) {
      log.error(e, 'PlotGoogleMap.initList');
    }
  };

  private initMap = () => {
    this.googleMaps = window[GOOGLE_GLOBAL_KEY]
      ? window[GOOGLE_GLOBAL_KEY].maps
      : undefined;

    if (!this.googleMaps) {
      return;
    }

    this.map = new this.googleMaps.Map(
      this.googleMapNode,
      this.defaultMapProperties
    );
  };

  // this is set to prevent "TypeError: Cannot read property '__e3_' of null" error when no draw manager is mounted
  private setFalseDrawManager = () => {
    if (!this.googleMaps) {
      return;
    }

    this.drawingManager = new this.googleMaps.drawing.DrawingManager({
      drawingControl: true,
    });
  };

  private setDrawManager = () => {
    const { drawManagerOptions } = this.props;

    if (!this.googleMaps || !this.map || !drawManagerOptions) {
      return;
    }

    const deleteControlDiv: null | HTMLElement = document.createElement('div');
    const helpInfoPopupDiv: null | HTMLElement = document.createElement('div');

    if (!deleteControlDiv) {
      return;
    }

    if (!helpInfoPopupDiv) {
      return;
    }

    deleteControlDiv.id = this.deleteControlBtnSelector;
    helpInfoPopupDiv.id = this.helpInfoPopupSelector;

    this.getDeleteControlBtn(deleteControlDiv);

    this.getHelpInfoPopup(helpInfoPopupDiv);

    deleteControlDiv.style.display = 'block';
    helpInfoPopupDiv.style.display = 'none';

    this.map.controls[this.googleMaps.ControlPosition.TOP_CENTER].push(
      deleteControlDiv
    );

    this.map.controls[this.googleMaps.ControlPosition.BOTTOM_CENTER].push(
      helpInfoPopupDiv
    );

    this.drawingManager = new this.googleMaps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        ...this.getDrawingControlOptions(
          drawManagerOptions.modesOptions
            ? (Object.keys(
                drawManagerOptions.modesOptions
              ) as PlotGoogleMapDrawManagerModesTypes[])
            : null
        ),
      },
      polygonOptions: {
        ...(drawManagerOptions.modesOptions?.polygon ?? {}),
      },
    });

    if (!this.drawingManager) {
      return;
    }

    this.drawingManager.setMap(this.map);
  };

  // only polygon shapes are implemented for now
  private drawShapes = () => {
    const { shapeList, uniqueShape } = this.props;

    if (!this.googleMaps || !this.map || !shapeList) {
      return;
    }

    Object.keys(shapeList).map((type: PlotGoogleMapDrawManagerModesTypes) => {
      const polygonList = shapeList[type];
      // render polygon shape

      polygonList.map(({ coordinates, editable, draggable }) => {
        if (!this.googleMaps || !this.map) {
          return coordinates;
        }

        const polygon = new this.googleMaps.Polygon({
          paths: coordsToGoogleMapPositionList(coordinates),
        });

        polygon.setDraggable(draggable);
        polygon.setEditable(editable);
        polygon.setMap(this.map);

        const nextIndex = uniqueShape
          ? 0
          : this.drawingManagerStore[type].overlay.length;

        polygon.getPath().addListener('insert_at', () => {
          this.processDrawManagerOverlay(polygon, type, nextIndex, false);
        });
        polygon.getPath().addListener('set_at', () => {
          this.processDrawManagerOverlay(polygon, type, nextIndex, false);
        });

        this.processDrawManagerOverlay(polygon, type, nextIndex, false);

        return coordinates;
      });

      return type;
    });
  };

  private drawPolygons = (): void => {
    const { boundaryPointsList, centerMapTo, initialZoom } = this.props;

    if (
      !this.map ||
      !this.googleMaps ||
      undefinedOrNull(boundaryPointsList) ||
      boundaryPointsList.length < 1
    ) {
      return;
    }

    boundaryPointsList.map((a, index) => {
      if (!this.googleMaps) {
        return;
      }

      this.polygon.push(
        new this.googleMaps.Polygon({
          paths: a,
          strokeColor: GOOGLE_MAP_MARKER_SELECTED_COLOR,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: GOOGLE_MAP_MARKER_SELECTED_COLOR,
          fillOpacity: 0.15,
        })
      );

      if (!this.polygon[index]) {
        return;
      }

      this.polygon[index].setMap(this.map);

      return a;
    });

    if (centerMapTo === 'boundary') {
      this.recenterBoundary(initialZoom);
    }
  };

  private recenterBoundary = (zoom?: number | null): void => {
    if (!this.map || !this.googleMaps || this.polygon.length < 1) {
      return;
    }

    const bounds = new this.googleMaps.LatLngBounds();

    this.polygon.map((a) => {
      a.getPath().forEach((path: any) => {
        bounds.extend(path);
      });

      return a;
    });

    if (!undefinedOrNull(zoom)) {
      this.zoomIntoMap(zoom);
    }

    this.map.fitBounds(bounds);
  };

  private drawMarkers = (): void => {
    const {
      markerPointsList,
      selectedMarkerIndex,
      initialMarkerPosition,
      markerClustererMaxZoom,
      clickToZoom,
      initialZoom,
      centerMapTo,
      markerClustererIcon,
    } = this.props;

    if (!this.googleMaps || !this.map || undefinedOrNull(markerPointsList)) {
      return;
    }

    let defaultMarkerIndex: null | number = null;

    if (
      (!this.markers || this.markers.length < 1) &&
      !undefinedOrNull(selectedMarkerIndex)
    ) {
      defaultMarkerIndex = selectedMarkerIndex;
    }

    const bounds = new this.googleMaps.LatLngBounds();

    const ignoredClusterMarkers: number[] = [];

    markerPointsList.map((item, index: number) => {
      if (!this.googleMaps) {
        return;
      }

      const position = item.pos;

      const marker = new this.googleMaps.Marker({
        position,
      });

      const latLongPos = new this.googleMaps.LatLng(position.lat, position.lng);

      bounds.extend(latLongPos);

      marker.setIcon(
        googleMapMarkerIcon(
          this.googleMaps,
          this.map,
          markerPointsList,
          index,
          'unselected',
          this.combinedMarkersList,
          this.getLabelOriginPoints()
        )
      );

      marker.setZIndex(10008);
      // do not add the markers to the clusterer if defined in the markerPointsList
      if (!undefinedOrNull(item.icon)) {
        if (index === defaultMarkerIndex) {
          if (item.icon.selected.ignoreMarkerClusterer) {
            ignoredClusterMarkers.push(index);
          }
        } else if (item.icon.unselected.ignoreMarkerClusterer) {
          ignoredClusterMarkers.push(index);
        }
      }

      if (!undefinedOrNull(item.label)) {
        marker.setLabel(item.label.text);
      }

      // when the activeMarkerIndex is available
      if (!undefinedOrNull(defaultMarkerIndex)) {
        this.setDefaultMarker(marker, index, defaultMarkerIndex, position);
      }

      this.markers.push(marker);

      marker.addListener('click', () => {
        this.handleMarkerClick(index);
      });

      if (
        !this.getShowMarkerClusterer() ||
        ignoredClusterMarkers.indexOf(index) > -1
      ) {
        marker.setMap(this.map);
      }

      return position;
    });

    const clusterStyles = googleMapMarkerClustererStyles(markerClustererIcon);

    if (this.getShowMarkerClusterer()) {
      // eslint-disable-next-line no-new
      new MarkerClusterer(
        this.map,
        this.markers.filter(
          (a, index) => ignoredClusterMarkers.indexOf(index) < 0
        ),
        {
          maxZoom: markerClustererMaxZoom,
          styles: clusterStyles,
          zoomOnClick: clickToZoom,
        }
      );
    }

    let closestPos = null;

    if (centerMapTo === 'marker') {
      this.map.fitBounds(bounds);
    }

    if (
      undefinedOrNull(defaultMarkerIndex) &&
      initialMarkerPosition === 'center'
    ) {
      this.googleMaps.event.addListenerOnce(this.map, 'idle', () => {
        if (!this.googleMaps || !this.map) {
          return;
        }

        const centerPos: null | GenericObjectType = this.map.getCenter();

        if (centerPos) {
          closestPos = findClosestLatLong(
            getMarkerPointsPos(markerPointsList),
            {
              lat: centerPos.lat(),
              lng: centerPos.lng(),
            }
          );

          if (closestPos) {
            const initialMarkerPos = closestPos.index;

            if (centerMapTo === 'marker') {
              this.recenterMarker(this.markers[initialMarkerPos], initialZoom);
            }

            this.handleMarkerClick(initialMarkerPos, false);
          }
        }

        if (centerMapTo === 'marker') {
          this.map.fitBounds(bounds);
        }
      });
    }
  };

  private setDefaultMarker = (
    marker: GenericObjectType,
    index: number,
    defaultMarkerIndex: number | null,
    position: GoogleMapsPositionTypes
  ) => {
    const {
      centerMapTo,
      initialZoom,
      markerPointsList,
      showHaloMarker,
      maintainZoomLevel,
      customHaloMarker,
    } = this.props;

    if (undefinedOrNull(this.googleMaps)) {
      return;
    }

    if (defaultMarkerIndex !== null && index === defaultMarkerIndex) {
      marker.setIcon(
        googleMapMarkerIcon(
          this.googleMaps,
          this.map,
          markerPointsList,
          index,
          'selected',
          this.combinedMarkersList,
          this.getLabelOriginPoints()
        )
      );

      marker.setZIndex(10008);

      // if the maintainZoomLevel & this.mapZoomLevel are present then recenter the map to the selected marker
      if (
        (maintainZoomLevel && !undefinedOrNull(this.mapZoomLevel)) ||
        centerMapTo === 'marker'
      ) {
        this.recenterMarker(marker, initialZoom);
      }

      if (showHaloMarker) {
        this.haloMarker.halo = drawHaloMarker(
          this.googleMaps,
          this.map,
          this.haloMarker,
          position,
          customHaloMarker ? customHaloMarker.icon.halo : null
        );
        this.haloMarker.point = drawHaloPointMarker(
          this.googleMaps,
          this.map,
          this.haloMarker,
          position,
          customHaloMarker ? customHaloMarker.icon.point : null
        );
      }
    }
  };

  private handleMarkerClick = (
    index: number,
    simulated: boolean = false
  ): void => {
    const { onMarkerClick, markerPointsList, selectedMarkerIndex } = this.props;

    if (undefinedOrNull(onMarkerClick)) {
      return;
    }

    if (
      undefinedOrNull(markerPointsList) ||
      undefinedOrNull(markerPointsList[index])
    ) {
      return;
    }

    const markerIcon = markerPointsList[index].icon;

    if (!undefinedOrNull(markerIcon)) {
      if (index === selectedMarkerIndex && markerIcon.selected.disableClick) {
        return;
      }

      if (markerIcon.unselected.disableClick) {
        return;
      }
    }

    onMarkerClick(index, simulated);
  };

  private getLabelOriginPoints = () => {
    const { labelOriginPoints } = this.props;

    if (undefinedOrNull(this.googleMaps)) {
      return;
    }

    return new this.googleMaps.Point(
      labelOriginPoints[0],
      labelOriginPoints[1]
    );
  };

  private getDeleteControlBtn = (deleteControlDiv: GenericObjectType) => {
    this.deleteControlBtnContainerNode = document.createElement('div');

    this.deleteControlBtnContainerNode.style.backgroundColor = '#fff';
    this.deleteControlBtnContainerNode.style.padding = '2px';
    this.deleteControlBtnContainerNode.style.width = '24px';
    this.deleteControlBtnContainerNode.style.height = '24px';
    this.deleteControlBtnContainerNode.style.boxShadow =
      'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
    this.deleteControlBtnContainerNode.style.cursor = 'pointer';
    this.deleteControlBtnContainerNode.style.marginTop = '5px';
    this.deleteControlBtnContainerNode.style.marginLeft = '-5px';
    this.deleteControlBtnContainerNode.style.textAlign = 'center';
    this.deleteControlBtnContainerNode.title =
      'Click to delete the drawn boundary';

    this.deleteControlBtnContainerNode.addEventListener('click', (_) => {
      this.handleDeleteControlBtnClickEvent();
    });
    this.deleteControlBtnContainerNode.addEventListener('mouseover', (_) => {
      this.handleDeleteControlBtnMouseEvent('mouseover');
    });
    this.deleteControlBtnContainerNode.addEventListener('mouseleave', (_) => {
      this.handleDeleteControlBtnMouseEvent('mouseleave');
    });

    deleteControlDiv.appendChild(this.deleteControlBtnContainerNode);

    const controlText = document.createElement('div');

    controlText.style.color = 'rgb(25,25,25)';
    controlText.style.fontSize = '14px';
    controlText.innerHTML =
      '<div class="fa fa-trash-o" style="color: #000;" aria-hidden="true" />';
    this.deleteControlBtnContainerNode.appendChild(controlText);

    return this.deleteControlBtnContainerNode;
  };

  private getMLObjectsLegend = (legend: HTMLElement) => {
    const controlUI: HTMLDivElement | null = document.createElement('div');

    if (!controlUI) {
      return null;
    }

    controlUI.id = this.helpInfoPopupContainerSelector;
    controlUI.style.backgroundColor = 'rgba(102, 102, 102, 0.8)';
    controlUI.style.border = '2px solid rgba(102, 102, 102, 0.8)';
    controlUI.style.width = 'max-content';
    controlUI.style.height = '24px';
    controlUI.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginTop = '5px';
    controlUI.style.marginLeft = '-5px';
    controlUI.style.textAlign = 'center';
    controlUI.style.position = 'absolute';
    controlUI.style.bottom = '15px';
    controlUI.style.left = '-120px';
    controlUI.style.borderRadius = '20px';
    controlUI.style.padding = '0px 10px';

    controlUI.title = 'Need help?';
    legend.appendChild(controlUI);

    const controlText = document.createElement('div');

    controlText.style.color = 'rgba(255, 255, 255, 0.77)';
    controlText.style.fontSize = '16px';
    controlText.id = this.helpInfoPopupTextSelector;
    // controlText.innerHTML =
    //   '<div aria-hidden="true">Click to add vertex. Double-click to close.</div>';

    const greenDot = document.createElement('div');

    greenDot.style.height = '14px';
    greenDot.style.width = '14px';
    greenDot.style.backgroundColor = ML_OBJECT_COLOR.MANUAL;
    greenDot.style.border = `3px solid ${ML_OBJECT_COLOR.MANUAL}`;
    greenDot.style.borderRadius = '50%';
    greenDot.style.float = 'left';
    greenDot.style.marginRight = '8px';
    greenDot.style.position = 'relative';
    greenDot.style.top = '3px';

    const legendText = document.createElement('span');

    legendText.innerText = 'Image Containing Object';

    controlText.appendChild(greenDot);
    controlText.appendChild(legendText);

    controlUI.appendChild(controlText);

    return controlUI;
  };

  private getHelpInfoPopup = (helpInfoDiv: GenericObjectType) => {
    const controlUI: HTMLDivElement | null = document.createElement('div');

    if (!controlUI) {
      return null;
    }

    controlUI.id = this.helpInfoPopupContainerSelector;
    controlUI.style.backgroundColor = 'rgba(102, 102, 102, 0.8)';
    controlUI.style.border = '2px solid rgba(102, 102, 102, 0.8)';
    controlUI.style.width = 'max-content';
    controlUI.style.height = '24px';
    controlUI.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginTop = '5px';
    controlUI.style.marginLeft = '-5px';
    controlUI.style.textAlign = 'center';
    controlUI.style.position = 'absolute';
    controlUI.style.bottom = '15px';
    controlUI.style.left = '-120px';
    controlUI.style.borderRadius = '20px';
    controlUI.style.padding = '0px 10px';

    controlUI.title = 'Need help?';
    helpInfoDiv.appendChild(controlUI);

    const controlText = document.createElement('div');

    controlText.style.color = 'rgba(255, 255, 255, 0.77)';
    controlText.style.fontSize = '16px';
    controlText.id = this.helpInfoPopupTextSelector;
    controlText.innerHTML =
      '<div aria-hidden="true">Click to add vertex. Double-click to close.</div>';
    controlUI.appendChild(controlText);

    return controlUI;
  };

  private showHelpInfo = (show: boolean) => {
    const helpInfo: null | HTMLElement = document.getElementById(
      this.helpInfoPopupSelector
    );

    if (!helpInfo) {
      return;
    }

    helpInfo.style.display = show ? 'block' : 'none';
  };

  private handleHelpInfoTextChange = (polygonExists: boolean) => {
    const helpInfoText: HTMLElement | null = document.getElementById(
      this.helpInfoPopupTextSelector
    );
    const helpInfoContainer: null | HTMLElement = document.getElementById(
      this.helpInfoPopupContainerSelector
    );

    if (!helpInfoText) {
      return;
    }

    if (!helpInfoContainer) {
      return;
    }

    helpInfoContainer.style.left = polygonExists ? '-110px' : '-120px';
    helpInfoText.innerHTML = polygonExists
      ? 'Click and drag vertex to edit Polygon.'
      : 'Click to add vertex. Double-click to close.';
  };

  private handleDeleteControlBtnClickEvent = () => {
    const { drawManagerOptions } = this.props;

    // reset help info text on delete
    this.showHelpInfo(false);
    this.handleHelpInfoTextChange(false);

    if (!this.drawingManager || !drawManagerOptions?.modesOptions) {
      return;
    }

    Object.keys(this.drawingManagerStore).map((a) => {
      const overlayItem = this.drawingManagerStore[a].overlay;

      for (let i = 0; i < overlayItem.length; i += 1) {
        overlayItem[i].setMap(null);
      }

      this.drawingManagerStore[a].overlay = [];
      this.drawingManagerStore[a].coordinates = [];

      return a;
    });

    this.drawingManager.setOptions({
      drawingControlOptions: {
        ...this.getDrawingControlOptions(
          drawManagerOptions.modesOptions
            ? (Object.keys(
                drawManagerOptions.modesOptions
              ) as PlotGoogleMapDrawManagerModesTypes[])
            : null
        ),
      },
    });

    if (drawManagerOptions.events) {
      drawManagerOptions.events(null, null, this.drawingManagerStore);
    }
  };

  private handleDeleteControlBtnMouseEvent = (type: string) => {
    if (!this.deleteControlBtnContainerNode) {
      return;
    }

    switch (type) {
      case 'mouseover':
        this.deleteControlBtnContainerNode.style.backgroundColor =
          'rgb(235, 235, 235)';
        break;
      case 'mouseleave':
        this.deleteControlBtnContainerNode.style.backgroundColor = '#fff';
        break;

      default:
        break;
    }
  };

  private handleDrawManagerOverlayComplete = (e: GenericObjectType) => {
    if (!this.googleMaps) {
      return;
    }

    const { overlay, type } = e;
    const path = overlay.getPath();
    const nextIndex = this.drawingManagerStore[type].overlay.length;

    this.setDrawManagerOverlay(e, nextIndex);

    this.googleMaps.event.addListener(path, 'set_at', () => {
      this.setDrawManagerOverlay(e, nextIndex);
    });

    this.googleMaps.event.addListener(path, 'insert_at', () => {
      this.setDrawManagerOverlay(e, nextIndex);
    });
  };

  private setDrawManagerOverlay = (
    e: GenericObjectType,
    nextIndex: number = 0
  ) => {
    if (!e || !this.googleMaps || !this.drawingManager) {
      return;
    }

    if (e.type === this.googleMaps.drawing.OverlayType.MARKER) {
      return;
    }

    // Switch back to non-drawing mode after drawing a shape.
    this.drawingManager.setDrawingMode(null);

    const { overlay, type: overlayType } = e;

    this.processDrawManagerOverlay(overlay, overlayType, nextIndex);
  };

  private processDrawManagerOverlay = (
    overlay: GenericObjectType,
    overlayType: PlotGoogleMapDrawManagerModesTypes,
    nextIndex: number = 0,
    checkLimit: boolean = true,
    emitEvents: boolean = true
  ) => {
    const { drawManagerOptions } = this.props;

    if (!this.drawingManager || !drawManagerOptions?.modesOptions) {
      return;
    }

    const _overlay = overlay;
    const path = _overlay.getPath();
    const coordinates: GenericObjectType[] = path.getArray();

    _overlay.type = overlayType;

    const _coordinates: CoordinatesListTypes = coordinates.map((a) => {
      const lat = a.lat();
      const lng = a.lng();

      return [lat, lng];
    });

    if (this.drawingManagerStore[overlayType].overlay[nextIndex]) {
      this.drawingManagerStore[overlayType].overlay[nextIndex] = _overlay;
      this.drawingManagerStore[overlayType].coordinates[nextIndex] =
        _coordinates;
    } else {
      this.drawingManagerStore[overlayType].overlay.push(_overlay);
      this.drawingManagerStore[overlayType].coordinates.push(_coordinates);
    }

    if (
      checkLimit &&
      drawManagerOptions?.modesOptions[overlayType] &&
      this.drawingManagerStore[overlayType].overlay.length >=
        (drawManagerOptions.modesOptions[overlayType].maxAllowed as number)
    ) {
      const nextDrawingMode = Object.keys(
        drawManagerOptions.modesOptions
      ).filter((a) => a !== overlayType);

      this.drawingManager.setOptions({
        drawingControlOptions: {
          ...this.getDrawingControlOptions(
            nextDrawingMode as PlotGoogleMapDrawManagerModesTypes[]
          ),
        },
      });
    }

    if (emitEvents && drawManagerOptions.events) {
      drawManagerOptions.events(
        { overlay: _overlay, coordinates: _coordinates },
        overlayType,
        this.drawingManagerStore
      );
    }
  };

  private getDrawingControlOptions = (
    drawingModes: PlotGoogleMapDrawManagerModesTypes[] | null
  ) => {
    return {
      position: this.googleMaps
        ? this.googleMaps.ControlPosition.TOP_CENTER
        : null,
      drawingModes,
    };
  };

  private registerMapEvents = (): void => {
    if (undefinedOrNull(this.googleMaps) || undefinedOrNull(this.map)) {
      return;
    }

    const { markerPointsList, showHaloMarker, onRightClick } = this.props;

    this.map.addListener('rightclick', async (event: any) => {
      if (onRightClick !== undefined) {
        await onRightClick(event);
      }
    });

    if (undefinedOrNull(markerPointsList)) {
      return;
    }

    if (!undefinedOrNull(this.polygon) && this.polygon.length > 0) {
      // adding support for events when there is a single polygon or more
      this.polygon.map((p) => {
        if (!this.googleMaps) {
          return p;
        }

        this.googleMaps.event.addListener(
          p,
          'rightclick',
          async (event: any) => {
            if (onRightClick !== undefined) {
              await onRightClick(event);
            }
          }
        );

        return p;
      });
    }

    if (!undefinedOrNull(this.polygon) && this.polygon.length > 1) {
      this.polygon.map((a) => {
        if (!this.googleMaps) {
          return a;
        }

        this.googleMaps.event.addListener(a, 'click', (event: any) => {
          processGoogleMapClick(
            this.googleMaps,
            this.map,
            getMarkerPointsPos(markerPointsList),
            { lat: event.latLng.lat(), lng: event.latLng.lng() },
            this.handleMarkerClick
          );
        });

        return a;
      });
    }

    this.map.addListener('click', (event: any) => {
      processGoogleMapClick(
        this.googleMaps,
        this.map,
        getMarkerPointsPos(markerPointsList),
        { lat: event.latLng.lat(), lng: event.latLng.lng() },
        this.handleMarkerClick
      );
    });

    this.map.addListener('zoom_changed', () => {
      if (!undefinedOrNull(this.map)) {
        this.mapZoomLevel = this.map.getZoom();
      }

      if (
        !undefinedOrNull(this.map) &&
        !undefinedOrNull(markerPointsList) &&
        !undefinedOrNull(this.markers)
      ) {
        handleMarkerLabelOnMapZoomChange(
          this.map,
          markerPointsList,
          this.markers
        );
      }

      if (showHaloMarker) {
        handleHaloMarkerOnMapZoomChange(this.map, this.haloMarker);
      }
    });

    this.googleMaps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (e: GenericObjectType) => {
        this.handleHelpInfoTextChange(true);
        this.handleDrawManagerOverlayComplete(e);
      }
    );

    this.googleMaps.event.addListener(
      this.drawingManager,
      'drawingmode_changed',
      () => {
        if (this.drawingManager?.getDrawingMode() === 'polygon') {
          this.showHelpInfo(true);
          this.handleHelpInfoTextChange(false);
        }
      }
    );
  };

  private deregisterMapEvents = (): void => {
    if (undefinedOrNull(this.googleMaps) || undefinedOrNull(this.map)) {
      return;
    }

    this.googleMaps.event.clearInstanceListeners(this.map);

    if (!undefinedOrNull(this.polygon) && this.polygon.length > 1) {
      this.polygon.map((a) => {
        if (!this.googleMaps) {
          return a;
        }

        this.googleMaps.event.clearInstanceListeners(a);

        return a;
      });
    }

    if (this.deleteControlBtnContainerNode) {
      this.deleteControlBtnContainerNode.removeEventListener(
        'click',
        (_) => this.handleDeleteControlBtnClickEvent
      );
      this.deleteControlBtnContainerNode.removeEventListener(
        'mouseover',
        (_) => this.handleDeleteControlBtnClickEvent
      );
      this.deleteControlBtnContainerNode.removeEventListener(
        'mouseleave',
        (_) => this.handleDeleteControlBtnClickEvent
      );
    }
  };

  private registerCustomActionButton = (): void => {
    const { customActionButtonList } = this.props;

    if (undefinedOrNull(customActionButtonList)) {
      return;
    }

    customActionButtonList.map((a) => {
      if (a.type === 'expand') {
        registerExpandCustomActionButton(this.googleMaps, this.map, a);
      }

      return a;
    });
  };

  public render(): React.ReactNode {
    const { height, width } = this.props;

    return (
      <div
        className={styles.container}
        style={{ height, width }}
        ref={(n) => {
          this.googleMapNode = n;
        }}
      />
    );
  }
}
