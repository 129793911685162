import classnames from 'classnames';
import { MapBrowserEvent, Feature } from 'ol';
import { asArray as colorAsArray } from 'ol/color';
import GeoJSON from 'ol/format/GeoJSON';
import GeometryType from 'ol/geom/GeometryType';
import { Draw, Select } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import { SelectEvent } from 'ol/interaction/Select';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import OverlayPositioning from 'ol/OverlayPositioning';
import Projection from 'ol/proj/Projection';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import * as React from 'react';
import { ML_OBJECT_COLOR } from '../../../../../constants/colors';
import style from './index.module.scss';
import {
  SiteObjectsControlPropsType,
  SiteObjectsControlStateType,
} from './index.types';

const DRAWCONTROL_Z_INDEX = 100;

const GEOJSON_WRITER = new GeoJSON({
  dataProjection: new Projection({ code: 'EPSG:4326' }),
  featureProjection: new Projection({ code: 'EPSG:3857' }),
});

/**
 * SiteObjectsControl for Openlayers to allow users to:
 *  - Draw:
 *      - Polygons
 *  - Display static features
 *  - Select / Delete features
 *  - Show appropriate help-text for drawing
 */

export default class SiteObjectsControl extends React.PureComponent<
  SiteObjectsControlPropsType,
  SiteObjectsControlStateType
> {
  private helpToolTipElement: HTMLElement | null = null;

  private helpToolTipOverlay: Overlay | null = null;

  public componentDidMount() {
    this.initializeControls(this.props);
  }

  public UNSAFE_componentWillReceiveProps(
    newProps: SiteObjectsControlPropsType
  ) {
    const { drawMode: newDrawMode, shapeGeoJson: newShapeGeoJson } = newProps;
    const { drawMode: oldDrawMode, shapeGeoJson: oldShapeGeoJson } = this.props;

    if (newDrawMode !== oldDrawMode) {
      // re-init draw control on drawMode change
      this.resetControls();
      this.initializeControls(newProps);
    } else if (newShapeGeoJson !== oldShapeGeoJson) {
      this.initializeShapes(newProps);
    }
  }

  public componentWillUnmount() {
    this.resetControls();
  }

  private createHelpToolTip = (olMap?: Map) => {
    if (!olMap) {
      return;
    }

    if (this.helpToolTipElement) {
      // eslint-disable-next-line no-unused-expressions
      this.helpToolTipElement.parentNode?.removeChild(this.helpToolTipElement);
    }

    this.helpToolTipElement = document.createElement('div');
    this.helpToolTipElement.className = classnames(style.olTooltip, 'hidden');

    this.helpToolTipOverlay = new Overlay({
      element: this.helpToolTipElement,
      offset: [20, 0],
      positioning: OverlayPositioning.CENTER_LEFT,
      className: style.olTooltipContainer,
    });

    olMap.addOverlay(this.helpToolTipOverlay);
  };

  private getColorWithOpacity(color: string, opacity: number) {
    let opaqueColor = colorAsArray(color);

    opaqueColor = opaqueColor.slice();
    opaqueColor[3] = opacity;

    return opaqueColor;
  }

  private mlStyleFunction = (feature: Feature) => {
    const objectColor =
      feature.getProperties()?.source === 'ml'
        ? ML_OBJECT_COLOR.AI
        : ML_OBJECT_COLOR.MANUAL;

    return new Style({
      stroke: new Stroke({
        color: objectColor,
        width: 4,
      }),
      fill: new Fill({
        color: this.getColorWithOpacity(objectColor, 0.2),
      }),
    });
  };

  private initializeControls(props: SiteObjectsControlPropsType) {
    const { olMap, drawMode } = props;

    if (olMap) {
      const vectorSource = new VectorSource({
        wrapX: false,
        features: [],
      });

      const layer = new VectorLayer({
        source: vectorSource,
        style: this.mlStyleFunction,
        zIndex: DRAWCONTROL_Z_INDEX,
      });

      olMap.addLayer(layer);

      // adding event handlers
      this.createHelpToolTip(olMap);
      olMap.on('pointermove', this.onPointerMove);
      olMap.getViewport().addEventListener('mouseout', this.onMouseOut);

      this.setState(
        {
          source: vectorSource,
          layer,
        },
        () => {
          this.initializeShapes(props);
          this.resetSelect();
          this.resetDraw();

          if (drawMode === 'Polygon') {
            this.initializeDraw(olMap);
          } else if (drawMode === 'Select') {
            this.initializeSelect(olMap);
          }
        }
      );

      return;
    }

    console.error('Map must be initialized before draw control.');
  }

  private resetControls() {
    const { olMap } = this.props;
    const { draw, select, layer } = this.state;

    if (olMap) {
      if (draw) olMap.removeInteraction(draw);
      if (select) olMap.removeInteraction(select);
      if (layer) olMap.removeLayer(layer);

      // to prevent event listener from persisting, when component is unmounted
      olMap.un('pointermove', this.onPointerMove);
      olMap.getViewport().removeEventListener('mouseout', this.onMouseOut);

      // IMPORTANT: Ensure this listener is removed when component is unmounted
      document.removeEventListener('keyup', this.onEscape);
    }

    this.setState({
      draw: undefined,
      select: undefined,
      layer: undefined,
      source: undefined,
    });
  }

  private initializeShapes = (props: SiteObjectsControlPropsType) => {
    const { shapeGeoJson } = props;
    const { source } = this.state;
    const geoJsonWriter = this.getGeoJsonWriter();

    if (source) {
      source.clear();

      if (shapeGeoJson?.features) {
        source.addFeatures(geoJsonWriter.readFeatures(shapeGeoJson));
      }

      return;
    }

    console.error('Source must be initialized before features are added.');
  };

  private initializeDraw = (olMap: Map) => {
    const { source } = this.state;

    if (source) {
      const draw = new Draw({
        source,
        type: GeometryType.POLYGON,
      });

      draw.on('drawend', this.onFeatureCreate);
      draw.on('drawstart', this.onDrawStart);

      // IMPORTANT: ensure this event listener is unmounted on component unmount
      document.addEventListener('keyup', this.onEscape);

      olMap.addInteraction(draw);

      this.setState({
        draw,
      });

      return;
    }

    console.error('Could not find source to initialize draw control with');
  };

  private resetDraw = () => {
    const { olMap } = this.props;
    const { draw } = this.state;

    if (olMap && draw) {
      draw.un('drawend', this.onFeatureCreate);
      olMap.removeInteraction(draw);
    }

    document.removeEventListener('keyup', this.onEscape);
  };

  private initializeSelect = (olMap: Map) => {
    const select = new Select();

    select.on('select', this.onFeatureSelect);
    olMap.addInteraction(select);

    this.setState({
      select,
    });
  };

  private resetSelect = () => {
    const { olMap } = this.props;
    const { select } = this.state;

    if (olMap && select) {
      select.un('select', this.onFeatureSelect);
      olMap.removeInteraction(select);
    }
  };

  private getHelpMessageForDrawing = (): string | null => {
    const { drawMode } = this.props;
    const { sketchFeature } = this.state;

    switch (drawMode) {
      case 'Polygon': {
        return sketchFeature
          ? 'Double-click to close polygon.'
          : 'Click to start drawing.';
      }

      case 'Select': {
        return 'Click to select feature to delete.';
      }

      default: {
        return null;
      }
    }
  };

  // Event handling functions ------------------------

  private onDrawStart = (e: DrawEvent) => {
    this.setState({
      // storing state of intermediate feature
      sketchFeature: e.feature,
    });
  };

  private onEscape = (e: KeyboardEvent) => {
    const { olMap } = this.props;
    const { draw } = this.state;

    // 27 = ESC button
    if (e.keyCode === 27) {
      if (draw && olMap) {
        this.resetDraw();
        this.initializeDraw(olMap);
      }

      this.setState({
        sketchFeature: undefined,
      });
    }
  };

  private onPointerMove = (e: MapBrowserEvent) => {
    const { draw, select } = this.state;

    if (!this.helpToolTipElement || !this.helpToolTipOverlay) {
      return;
    }

    this.helpToolTipOverlay.setPosition(e.coordinate);

    if (draw || select) {
      const helpMessage = this.getHelpMessageForDrawing();

      if (helpMessage) {
        this.helpToolTipElement.innerHTML = helpMessage;
        this.helpToolTipElement.classList.remove('hidden');
      } else {
        this.helpToolTipElement.classList.add('hidden');
      }
    }
    // else if (allowEdit) {
    //   const helpMessage = 'Click & drag to edit.';

    //   this.helpToolTipElement.innerHTML = helpMessage;
    //   this.helpToolTipElement.classList.remove('hidden');
    // }
    else {
      this.helpToolTipElement.classList.add('hidden');
    }
  };

  private onMouseOut = () => {
    if (this.helpToolTipElement) {
      this.helpToolTipElement.classList.add('hidden');
    }
  };

  private getGeoJsonWriter(): GeoJSON {
    const { geoJsonWriter } = this.props;

    return geoJsonWriter || GEOJSON_WRITER;
  }

  private onFeatureCreate = (e: DrawEvent) => {
    const { onFeatureCreate, olMap } = this.props;
    const { draw } = this.state;

    // setting a unique id to every feature created
    e.feature.setId(this.uuid());

    if (onFeatureCreate) {
      const featureObject = this.getGeoJsonWriter().writeFeatureObject(
        e.feature
      );

      featureObject.properties = {
        id: e.feature.getId(),
      };

      onFeatureCreate({
        features: [featureObject],
      });
    }

    if (olMap && draw) {
      // remove ability to draw if only a single feature is allowed
      olMap.removeInteraction(draw);
      this.setState({
        draw: undefined,
      });
    }

    this.setState({
      sketchFeature: undefined,
    });
  };

  // private onFeatureEdit = (e: ModifyEvent) => {
  //   const { onFeatureEdit } = this.props;

  //   if (onFeatureEdit) {
  //     const features = e.features.getArray();
  //     const featureObjects = features.map(f => {
  //       const o = this.getGeoJsonWriter().writeFeatureObject(f);

  //       o.properties = {
  //         id: f.getId()
  //       };

  //       return o;
  //     });

  //     onFeatureEdit({
  //       features: featureObjects
  //     });
  //   }
  // };

  private onFeatureSelect = (e: SelectEvent) => {
    const { onFeatureSelect } = this.props;

    if (onFeatureSelect) {
      const features = e.selected;
      const featureObjects = features.map((f) => {
        const o = this.getGeoJsonWriter().writeFeatureObject(f);

        return o;
      });

      onFeatureSelect({
        features: featureObjects,
      });
    }
  };

  private uuid() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c) => {
        // eslint-disable-next-line no-bitwise
        const r = (dt + Math.random() * 16) % 16 | 0;

        dt = Math.floor(dt / 16);

        // eslint-disable-next-line no-bitwise
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }

  public render() {
    // placeholder as component will be rendered within the OpenLayers Map
    return <React.Fragment />;
  }
}
