import {
  BorderInnerOutlined,
  CameraOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { toLonLat } from 'ol/proj';
import * as React from 'react';
import { withRouter } from 'react-router';
import DynamicScale from 'src/components/DynamicScale';
import { ValueRange } from 'src/components/OpenLayersMap/index.types';
import {
  ColorList,
  DYNAMIC_MAPS,
  NDVI_COLORS,
  THERMAL_COLORS,
} from 'src/constants/colors';
import { View, ViewSubType } from '../../../api/views.types';
import ViewToolsApis from '../../../api/viewTools';
import { viewUrl } from '../../../routes/urls';
import { queryStringToObject, undefinedOrNull } from '../../../utils/functs';
import { log } from '../../../utils/log';
import ElevationScale from '../../ElevationScale';
import SkeletonLoader from '../../SkeletonLoader';
import ContextMenu from '../ViewControls/ContextMenu';
import style from './index.module.scss';
import { MapViewPropsType, MapViewStateType } from './index.types';
import OpenLayersRenderer from './OpenLayersRenderer';
import { ClampConfig } from './OpenLayersRenderer/index.types';
import { MapStateProvider } from './OpenLayersRenderer/MapStateProvider';
import SatelliteView from './OpenLayersRenderer/SatelliteView';

const viewToolsApis = new ViewToolsApis();

class MapView extends React.Component<MapViewPropsType, MapViewStateType> {
  private stateProviderRef = React.createRef<MapStateProvider>();

  constructor(props: MapViewPropsType) {
    super(props);

    this.state = {};
  }

  public componentDidMount(): void {
    const { location, onFooterControlsFragChange, showSatellite, view } =
      this.props;

    const center = queryStringToObject(location.search);

    if (
      Object.keys(center).length > 0 &&
      center.centerLatitude &&
      center.centerLongitude
    ) {
      this.setState({
        centerLatitude: parseFloat(center.centerLatitude),
        centerLongitude: parseFloat(center.centerLongitude),
      });
    }

    if (onFooterControlsFragChange) {
      onFooterControlsFragChange(
        <div style={{ width: 'auto', zIndex: 10 }}>
          <BorderInnerOutlined
            className={style.icon}
            title="Crosshair"
            onClick={() => {
              this.setState(({ showGuidelines }) => {
                return {
                  showGuidelines: !showGuidelines,
                };
              });
            }}
          />
          <EnvironmentOutlined
            className={style.icon}
            title="Satellite Overlay"
            onClick={() => {
              const { view } = this.props;

              this.setState(({ satelliteView }) => {
                return {
                  satelliteView: satelliteView ? undefined : (
                    <SatelliteView accessToken={view.sourceToken} />
                  ),
                };
              });
            }}
          />
          <CameraOutlined
            className={style.icon}
            title="Screenshot"
            onClick={() => {
              if (this.stateProviderRef.current) {
                this.stateProviderRef.current.saveScreenshot();
              }
            }}
          />
        </div>
      );
    }

    this.handleShowSatelliteView(showSatellite);
    this.handleSliderValueRanges(view);
  }

  public componentDidUpdate(prevProps: MapViewPropsType) {
    const { view, showSatellite } = this.props;
    const { view: prevView, showSatellite: prevShowSatellite } = prevProps;

    if (view !== prevView) {
      /*
           RM: FIXME: eslint complains about setState in didUpdate

           - We can memoize `sourceValueRange` but we also need to
             reset `valueRange` if source has changed.

           - We can't use getDerivedStateFromProps as it can be
             called even if view did not change (or even if no props
             changed).  We can't access prevProps to check it.

           - Only solution seems to be to consider `valueRange` as
             uncontrolled and ensure all usage of `MapView` uses
             `key={view}` to ensure a new component is created when
             view changes.
         */

      // eslint-disable-next-line react/no-did-update-set-state
      this.handleSliderValueRanges(view);
    }

    if (showSatellite !== prevShowSatellite) {
      this.handleShowSatelliteView(showSatellite);
    }
  }

  private handleSliderValueRanges = (view: View) => {
    const sourceValueRange = this.getDCMValueRange(view);

    if (sourceValueRange) {
      let valueRange = { ...sourceValueRange };

      if (view.subType === 'ndvi') {
        // override initial value range for display
        valueRange = { min: 0, max: 1 };
      }

      this.setState({ sourceValueRange, valueRange });
    }
  };

  private handleShowSatelliteView = (showSatellite?: boolean) => {
    const { view } = this.props;

    if (showSatellite) {
      this.setState({
        satelliteView: <SatelliteView accessToken={view.sourceToken} />,
      });
    } else {
      this.setState({
        satelliteView: undefined,
      });
    }
  };

  private handleInspectClick = (event: any, props: any): void => {
    const { view, linkedSiteWalkthroughView } = this.props;

    const { olMap } = props;
    const coordinates = olMap.getEventCoordinate(event);
    const [lng, lat] = toLonLat(coordinates);

    const formData = {
      latitude: lat,
      longitude: lng,
      elevation: 0,
      viewId: view.id,
      targetViewId: linkedSiteWalkthroughView?.id || null,
    };

    this.switchToDifferentView(formData);
  };

  private switchToDifferentView = (formData: {
    latitude: number;
    longitude: number;
    elevation: number;
    viewId: string;
    targetViewId: string | null;
  }) => {
    const { view, history, linkedSiteWalkthroughView } = this.props;

    let inspsectionTargetView: any = null;

    if (!undefinedOrNull(view)) {
      if (view.type === 'map') {
        inspsectionTargetView = linkedSiteWalkthroughView || null;

        if (inspsectionTargetView !== null) {
          formData.targetViewId = inspsectionTargetView.id; // eslint-disable-line
        }
      }
    }

    let useApiToGetTargetImageGuid = false;

    // what is this?
    if (view.type === 'map') {
      if (view.missionId === inspsectionTargetView.missionId) {
        // only use the api if both views in same mission, else guids will not match
        useApiToGetTargetImageGuid = true;
      }
    }

    if (useApiToGetTargetImageGuid) {
      viewToolsApis
        .getTargetImageForInspection(formData)
        .then((res) => {
          const { error: apiError, data: apiData } = res;

          if (apiError || undefinedOrNull(apiData.guid)) {
            log.error(apiError);
            throw new Error('Could not get image guid from API call');
          }

          history.push(
            viewUrl(view.projectId, view.aoiId, inspsectionTargetView.id, {
              gotoGuid: apiData.guid,
            })
          );
        })
        .catch((err) => {
          log.error(err);

          history.push(
            viewUrl(view.projectId, view.aoiId, inspsectionTargetView.id, {
              latitude: formData.latitude,
              longitude: formData.longitude,
            })
          );
        });
    } else {
      history.push(
        viewUrl(view.projectId, view.aoiId, inspsectionTargetView.id, {
          latitude: formData.latitude,
          longitude: formData.longitude,
        })
      );
    }
  };

  private getDCMValueRange(viewFromArg?: View): ValueRange | undefined {
    const { view: viewFromProps } = this.props;
    const view = viewFromArg || viewFromProps;

    if (!view || !view.demColorMapping) return;
    const max = view.demColorMapping.find((c) => c.color === 'max')?.elevation;

    if (!max) return;
    const min = view.demColorMapping.find((c) => c.color === 'min')?.elevation;

    if (!min) return;

    return { min, max };
  }

  private onValueRangeUpdate = (valueRange: ValueRange) => {
    this.setState({ valueRange });
  };

  public renderColorMapping() {
    const { view } = this.props;

    if (!view.subType || !view.demColorMapping?.length) {
      return <></>;
    }

    switch (view.subType) {
      case 'elevation':
      case 'dtm':
      case 'survey':
      case 'contour': {
        return (
          <ElevationScale
            className={style.colorScale}
            units={view.coordinateUnits || 'METRES'}
            dems={view.demColorMapping.slice().reverse()}
          />
        );
      }

      case 'thermal_mosaic': {
        const { sourceValueRange } = this.state;

        if (!sourceValueRange) return null;

        const { temperatureUnit } = view;

        return (
          <DynamicScale
            sourceRange={sourceValueRange}
            colors={this.getColorMap(view.subType)}
            onUpdate={this.onValueRangeUpdate}
            units={temperatureUnit === 'FAHRENHEIT' ? '°F' : '°C'}
            clampMax
            clampMin
          />
        );
      }

      case 'ndvi': {
        return (
          <DynamicScale
            defaultValue={{ min: 0, max: 1 }}
            sourceRange={{ min: -1, max: 1 }}
            colors={this.getColorMap(view.subType)}
            onUpdate={this.onValueRangeUpdate}
            units=""
            clampMax={false}
            clampMin={false}
          />
        );
      }

      default: {
        return <></>;
      }
    }
  }

  private getColorMap(viewSubType?: ViewSubType): ColorList {
    const { location } = this.props;
    const searchObj = queryStringToObject(location.search);

    if (searchObj && searchObj.cmap && DYNAMIC_MAPS[searchObj.cmap])
      return DYNAMIC_MAPS[searchObj.cmap];

    if (viewSubType === 'ndvi') return NDVI_COLORS;

    return THERMAL_COLORS;
  }

  private getClampConfig(viewSubType?: ViewSubType): ClampConfig | undefined {
    switch (viewSubType) {
      case 'thermal_mosaic':
        return { min: true, max: true };
      case 'ndvi':
        return { min: false, max: false };
      default:
        return undefined;
    }
  }

  public renderGuideLines() {
    const { showGuidelines } = this.state;

    return showGuidelines ? (
      <React.Fragment key="guideline">
        <div className={style.gridX} key="gridx" />
        <div className={style.gridY} key="gridy" />
      </React.Fragment>
    ) : (
      <></>
    );
  }

  public renderContextMenu() {
    const { view, linkedSiteWalkthroughView, disableInspect } = this.props;

    if (
      (view.subType === 'aerial' || view.subType === 'thermal_mosaic') &&
      linkedSiteWalkthroughView &&
      !disableInspect
    ) {
      return (
        <ContextMenu
          nodeSelector=".vimana-maps-contextMenuTarget"
          menuOptionsList={[
            {
              label: 'Inspect',
              name: 'inspect',
              onClick: (name, confirmActionValue, event, props) => {
                this.handleInspectClick(event, props);
              },
            },
          ]}
        />
      );
    }

    return <></>;
  }

  public render() {
    const {
      view,
      olView,
      onConfigCallbackChange,
      viewConfig,
      overlayGeojson,
      onEvent,
      children,
      linkedSiteWalkthroughView,
      overrideControls,
    } = this.props;

    const { centerLatitude, centerLongitude, satelliteView, valueRange } =
      this.state;

    if (!view || (!view.sourceUrl && view.subType !== 'satellite')) {
      return <SkeletonLoader />;
    }

    // if (
    //   view.subType === 'elevation' &&
    //   view.sourceUrl.startsWith('vimana://')
    // ) {
    //   // TODO: need to discuss better design for this
    //   const _olView = olView || new OLView();

    //   onEvent(new OLViewInitialized(_olView));

    //   if (!olView) {
    //     if (view?.zoomDefault) {
    //       _olView.setZoom(view?.zoomDefault);
    //     }

    //     if (view?.zoomMin) {
    //       _olView.setMinZoom(view.zoomMin);
    //     }

    //     if (view?.zoomMax) {
    //       _olView.setMaxZoom(view.zoomMax);
    //     }

    //     _olView.setCenter(
    //       fromLonLat([view.centerLongitude || 0, view.centerLatitude || 0])
    //     );
    //   }

    //   const { onFooterControlsFragChange } = this.props;

    //   return (
    //     <ElevationDifferenceView
    //       view={view}
    //       olView={_olView}
    //       onFooterControlsFragChange={onFooterControlsFragChange}
    //     >
    //       {children}
    //     </ElevationDifferenceView>
    //   );
    // }

    let defaultZoom = view.zoomDefault;

    if (!undefinedOrNull(centerLatitude) && !undefinedOrNull(centerLongitude)) {
      defaultZoom = (view.zoomMax || 21) - 2;
    }

    return (
      <div className={style.container} data-testid="map-view-container">
        <div className={style.mapContainer}>
          {this.renderColorMapping()}
          <OpenLayersRenderer
            view={view}
            olView={olView}
            viewId={view.id}
            sourceUrl={view.sourceUrl}
            sourceToken={view.sourceToken}
            overrideControls={overrideControls}
            defaultViewState={{
              center: {
                latitude: undefinedOrNull(centerLatitude)
                  ? view.centerLatitude
                  : centerLatitude,
                longitude: undefinedOrNull(centerLongitude)
                  ? view.centerLongitude
                  : centerLongitude,
              },
              zoom: {
                default: defaultZoom,
                max: view.zoomMax,
                min: view.zoomMin,
              },
            }}
            viewConfig={viewConfig}
            valueRange={valueRange}
            colorMap={this.getColorMap(view.subType)}
            clampConfig={this.getClampConfig(view.subType)}
            overlayGeojson={overlayGeojson}
            onConfigCallbackChange={onConfigCallbackChange}
            onEvent={onEvent}
            linkedSiteWalkthroughView={linkedSiteWalkthroughView}
            className="vimana-maps-contextMenuTarget"
          >
            {this.renderGuideLines()}
            {children}
            {satelliteView || <></>}
            {this.renderContextMenu()}
            <MapStateProvider ref={this.stateProviderRef} convertToLatLon />
          </OpenLayersRenderer>
        </div>
      </div>
    );
  }
}

export default withRouter(MapView);
