import * as React from 'react';
import { Modal, Typography } from 'antd';
import { Button } from '../Button';
import styles from './ModalNotification.module.scss';
import SkeletonLoader from '../SkeletonLoader';
import { undefinedOrNull } from '../../utils/functs';

interface IProps {
  notificationTitle: string;
  notificationBody?: JSX.Element | string;
  shownotificationModal: boolean;
  handleModalClose?: (confirm?: boolean) => any;
  handleModalCancel?: (confirm?: boolean) => any;
  error?: any;
  isConfirm?: boolean;
  cancelButtonTitle?: string;
  okButtonTitle?: string;
  zIndex: number;
  centered?: boolean;
  className?: string;
}

interface IState {
  showModal: boolean;
  loaded: boolean;
}

class ModalNotification extends React.Component<IProps, IState> {
  public static defaultProps = {
    zIndex: 10001,
  };

  public constructor(props: IProps) {
    super(props);

    this.state = {
      showModal: false,
      loaded: false,
    };
  }

  public componentDidMount(): void {
    const { shownotificationModal } = this.props;

    this.setState({
      showModal: shownotificationModal,
      loaded: true,
    });
  }

  private handleCancelAction = () => {
    const { handleModalCancel } = this.props;

    this.setState(
      {
        showModal: false,
      },
      () => {
        if (handleModalCancel) {
          handleModalCancel(false);
        }
      }
    );
  };

  private handleOkAction = () => {
    const { handleModalClose } = this.props;

    this.setState(
      {
        showModal: false,
      },
      () => {
        if (handleModalClose) {
          handleModalClose(true);
        }
      }
    );
  };

  private cancelButtonText = () => {
    const { cancelButtonTitle } = this.props;

    if (undefinedOrNull(cancelButtonTitle)) {
      return 'CANCEL';
    }

    return cancelButtonTitle;
  };

  private oKButtonText = () => {
    const { okButtonTitle } = this.props;

    if (undefinedOrNull(okButtonTitle)) {
      return 'OK';
    }

    return okButtonTitle;
  };

  public render(): React.ReactNode {
    const { loaded, showModal } = this.state;
    const {
      error,
      notificationTitle,
      notificationBody,
      isConfirm,
      zIndex,
      centered,
      className,
    } = this.props;
    const { Text } = Typography;

    if (!this.state || !loaded) {
      return <SkeletonLoader size={1} position="fixed" />;
    }

    return (
      <div>
        <Modal
          title={notificationTitle}
          centered={centered === undefined ? true : centered}
          footer={null}
          visible={showModal}
          onOk={this.handleOkAction}
          onCancel={this.handleCancelAction}
          destroyOnClose
          maskClosable={false}
          zIndex={zIndex}
          className={className}
        >
          <Text>{notificationBody}</Text>
          <Text>{error}</Text>
          <div className={styles.modalButtonDiv}>
            {isConfirm ? (
              <React.Fragment>
                <Button
                  transparent
                  onClick={this.handleCancelAction}
                  text={this.cancelButtonText()}
                />
                <Button
                  onClick={this.handleOkAction}
                  text={this.oKButtonText()}
                  className={styles.okBtn}
                />
              </React.Fragment>
            ) : (
              <Button
                onClick={this.handleCancelAction}
                text={this.oKButtonText()}
              />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModalNotification;
