import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Input,
  Checkbox,
  Button,
  Spin,
  Radio,
  Tabs,
  Typography,
} from 'antd';
import { showProjectAdminLinks } from '../../Header';
import styles from './index.module.scss';
import ProjectUsersModal from '../IssueDetail/ProjectUsersModal/container';
import DocumentationLink from '../../DocumentationLink';
import { DOCUMENTATION_URL_LIST } from '../../../constants/urls';
import { ManageTagsProps, ManageTagsState } from './index.types';
import {
  ClearTagsStatusEvent,
  CreateTagEvent,
  DeleteTagsEvent,
} from '../index.types';

const { Title } = Typography;

class ManageTagsModal extends React.Component<
  ManageTagsProps,
  ManageTagsState
> {
  constructor(props: ManageTagsProps) {
    super(props);
    this.state = {
      tagToAdd: '',
      tagsToDelete: [],
      issueListWithTag: '',
      tagNotSelectedWarning: '',
      hasInputTagError: false,
      showShareTagsModal: false,
    };
  }

  private handleShareIssueTagsModal = () => {
    this.setState((prevState) => ({
      showShareTagsModal: !prevState.showShareTagsModal,
    }));
  };

  private handleCancelModal = () => {
    const { cancelModal } = this.props;

    cancelModal();
  };

  private handleShareTags = (e: any): void => {
    const { value } = e.target;

    this.setState({ issueListWithTag: value, tagNotSelectedWarning: '' });
  };

  private openShareIssueListModal = () => {
    const { issueListWithTag } = this.state;

    if (issueListWithTag === '') {
      this.setState({
        tagNotSelectedWarning: 'Please select a tag to share issue list',
      });
    } else {
      this.handleShareIssueTagsModal();
    }
  };

  private handleCreateTag = (e: any): void => {
    e.preventDefault();

    const { onEvent } = this.props;
    const { tagToAdd: _tagToAdd } = this.state;
    const tagToAdd = _tagToAdd.trim();

    if (tagToAdd.length > 0) onEvent(new CreateTagEvent(tagToAdd));
  };

  private handleDeleteTags = () => {
    const { onEvent } = this.props;
    const { tagsToDelete } = this.state;

    onEvent(new DeleteTagsEvent(tagsToDelete));
  };

  private handleTabChange = (): void => {
    const { onEvent } = this.props;

    onEvent(new ClearTagsStatusEvent());
  };

  private handleInputChange = (e: any): void => {
    const { value } = e.target;

    if (value.length > 100) {
      this.setState({ hasInputTagError: true });
    } else {
      this.setState({ tagToAdd: value, hasInputTagError: false });
    }
  };

  private markCheckBox = (checkedValues: any): void => {
    this.setState({ tagsToDelete: checkedValues });
  };

  private renderTagCreationMessage() {
    const { loading, processState } = this.props;

    if (loading?.createTag || !processState?.createTag) {
      return <></>;
    }

    switch (processState.createTag) {
      case 'success':
        return <p className={styles.successMessage}>New Tag Created!</p>;

      case 'failed':
        return (
          <p className={styles.errorMessage}>
            Sorry, tag could not be created!
          </p>
        );

      default:
        return <></>;
    }
  }

  private renderTagDeletionMessage() {
    const { loading, processState } = this.props;

    if (loading?.deleteTags || !processState?.deleteTags) {
      return <></>;
    }

    switch (processState.createTag) {
      case 'success':
        return (
          <p className={styles.successMessage}>
            Selected tags removed from project.
          </p>
        );

      case 'failed':
        return (
          <p className={styles.errorMessage}>
            One or more tags are already in use.
          </p>
        );

      default:
        return <></>;
    }
  }

  public render(): React.ReactNode {
    const { isModalVisible, projectId, user, tags, projectUsers, loading } =
      this.props;
    const {
      tagToAdd,
      hasInputTagError,
      tagsToDelete,
      tagNotSelectedWarning,
      showShareTagsModal,
      issueListWithTag,
    } = this.state;

    return (
      <Modal
        title={
          <div className={styles.titleWrapper}>
            <Title level={3}>Manage Tags</Title>
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.issueTagging}
              toolTipPosition="right"
            />
          </div>
        }
        style={{ display: 'block', top: 100, width: '600px' }}
        visible={isModalVisible}
        footer={null}
        destroyOnClose
        onCancel={this.handleCancelModal}
        zIndex={10001}
      >
        <Tabs defaultActiveKey="2" onChange={this.handleTabChange} centered>
          <Tabs.TabPane tab="Add" key="1">
            {showProjectAdminLinks(projectId, user) ? (
              <Form onSubmit={this.handleCreateTag}>
                <Form.Item>
                  <Input
                    placeholder="Tag name..."
                    value={tagToAdd}
                    onChange={this.handleInputChange}
                    className={styles.addTagInput}
                    allowClear
                    required
                  />
                  {hasInputTagError ? (
                    <p className={styles.errorMessage}>
                      Tags should not be more than 100 characters long
                    </p>
                  ) : null}
                  {this.renderTagCreationMessage()}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading?.createTag}
                  >
                    Add Tag
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <p>This feature is available only to project admins</p>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Share" key="2">
            <div className={styles.shareTagsControl}>
              {loading?.tags ? (
                <Spin tip="Loading all tags..." />
              ) : (
                <Radio.Group
                  className={
                    tags.length ? styles.shareTagsGrid : styles.noProjectTags
                  }
                  onChange={this.handleShareTags}
                >
                  {tags.length ? (
                    tags.map((tag) => (
                      <Radio key={tag.id} value={tag.id}>
                        {tag.tagName}
                      </Radio>
                    ))
                  ) : (
                    <p>Project does not have any issue tags defined!</p>
                  )}
                </Radio.Group>
              )}
              <div className={styles.controls}>
                <Button
                  type="primary"
                  onClick={this.openShareIssueListModal}
                  disabled={issueListWithTag === ''}
                >
                  Share Issues
                </Button>
                {tagNotSelectedWarning && (
                  <p className={styles.warningMessage}>
                    {tagNotSelectedWarning}
                  </p>
                )}
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Delete" key="3">
            {showProjectAdminLinks(projectId, user) ? (
              <div>
                <div>
                  {' '}
                  {loading?.tags ? (
                    <Spin tip="Loading all tags" />
                  ) : (
                    <Checkbox.Group
                      className={
                        tags.length ? styles.tagsGrid : styles.noProjectTags
                      }
                      onChange={this.markCheckBox}
                      value={tagsToDelete}
                    >
                      {' '}
                      {tags.length
                        ? tags.map((tag) => (
                            <Checkbox key={tag.id} value={tag.id}>
                              {tag.tagName}
                            </Checkbox>
                          ))
                        : 'Project does not have any issue tags defined!'}
                    </Checkbox.Group>
                  )}
                </div>
                <div className={styles.controls}>
                  {this.renderTagDeletionMessage()}
                  <Button
                    type="primary"
                    onClick={this.handleDeleteTags}
                    disabled={tagsToDelete.length === 0}
                    loading={loading?.deleteTags}
                  >
                    Delete Tags
                  </Button>
                </div>
              </div>
            ) : (
              <p>This feature is available only to project admins</p>
            )}
          </Tabs.TabPane>
        </Tabs>
        {showShareTagsModal && (
          <ProjectUsersModal
            showModal={showShareTagsModal}
            tagId={issueListWithTag}
            projectUsersList={projectUsers}
            handleModalStatus={this.handleShareIssueTagsModal}
          />
        )}
      </Modal>
    );
  }
}

export default ManageTagsModal;
