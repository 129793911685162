import { connect } from 'react-redux';
import { fetchProject } from '../../redux/actions/projects';
import { resetCurrentView } from '../../redux/actions/views';
import AoiDropDown from './AoiDropDown';
import { NullOrGenericObjectType } from '../../shapes/app';

const getProject = (state: any, projectId: any): any => {
  if (state && state.projects && state.projects.data) {
    const filtered = state.projects.data.filter((p: any) => p.id === projectId);

    if (filtered.length) {
      return filtered[0];
    }
  }

  return null;
};

const getMission = (state: NullOrGenericObjectType, missionId: string): any => {
  if (missionId && state && state.mission && state.mission.mission) {
    return state.mission.mission;
  }

  return null;
};

const getAOI = (project: any, aoiId: string): any => {
  const aois = project ? project.aois || [] : [];

  if (aoiId) {
    // eslint-disable-next-line no-restricted-syntax
    for (const aoi of aois) {
      if (aoi.id === aoiId) {
        return aoi;
      }
    }
  }

  return null;
};

const getViewId = (state: any): any[] => {
  if (state && state.views) {
    const lastViewId = state.views.lastViewId || '';
    const lastSplitViewId = state.views.lastSplitViewId || '';
    const arr = [lastViewId, lastSplitViewId];

    return arr;
  }

  return [];
};
const mapStateToProps = (
  state: any,
  { projectId, aoiId, missionId }: any
): any => {
  return {
    project: getProject(state, projectId),
    aoi: getAOI(getProject(state, projectId), aoiId),
    mission: getMission(state, missionId),
    lastViewIdArray: getViewId(state),
  };
};

const mapDispatchToProps = (dispatch: any, { projectId }: any): any => {
  return {
    fetchProject: () =>
      dispatch((dispatch2: any, getState: any) => {
        const state = getState();

        if (state && state.projects && state.projects.data) {
          if (!getProject(state, projectId)) {
            dispatch2(fetchProject(projectId));
          }
        } else {
          dispatch2(fetchProject(projectId));
        }
      }),
    resetCurrentView: () => dispatch(resetCurrentView()),
  };
};

const AoiDropDownContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AoiDropDown);

export default AoiDropDownContainer;
