// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IssuesHeaderIcons_issuesHeaderIcon__5ANiy{display:inline-flex;align-items:center;cursor:pointer;padding:0 10px}.IssuesHeaderIcons_issuesReportIcon__12M\\+9{display:inline-flex;align-items:center;cursor:pointer;padding:15px 10px}", "",{"version":3,"sources":["webpack://./src/components/Header/IssuesHeaderIcons/index.module.scss"],"names":[],"mappings":"AAAA,2CACE,mBAAA,CACA,kBAAA,CACA,cAAA,CACA,cAAA,CAGF,4CACE,mBAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA","sourcesContent":[".issuesHeaderIcon {\n  display: inline-flex;\n  align-items: center;\n  cursor: pointer;\n  padding: 0 10px;\n}\n\n.issuesReportIcon {\n  display: inline-flex;\n  align-items: center;\n  cursor: pointer;\n  padding: 15px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"issuesHeaderIcon": "IssuesHeaderIcons_issuesHeaderIcon__5ANiy",
	"issuesReportIcon": "IssuesHeaderIcons_issuesReportIcon__12M+9"
};
export default ___CSS_LOADER_EXPORT___;
