export const VIEW_PATH = `/project/:projectId/aoi/:aoiId/view/:viewId`;
export const AOI_LISTING_PAGE_PATH = `/project/:projectId/aoi/:aoiId/list`;
export const PROJECT_VIDEOS_NEW_PAGE_PATH = `/project/:projectId/aoi/:aoiId/videos/new`;
export const PROJECT_VIDEOS_EDIT_PAGE_PATH = `/project/:projectId/aoi/:aoiId/view/:viewId/videos/edit`;
export const DOWNLOADS_LIST_WITH_AOI_PATH = `/project/:projectId/aoi/:aoiId/downloads/list`;
export const DOWNLOADS_LIST_WITHOUT_AOI_PATH = `/project/:projectId/downloads/list`;
export const SHARE_PATH = `/share/:id`;
export const INTERIOR360_VIEWS_NEW_PATH = `/project/:projectId/aoi/:aoiId/interior360/new`;
export const INTERIOR360_VIEWS_EDIT_PATH = `/project/:projectId/aoi/:aoiId/view/:viewId/interior360/edit`;
export const DRAWING_EDIT_PATH = `/project/:projectId/aoi/:aoiId/sandbox/drawing/edit/:drawingId`;
