import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import CutAndFillReport from './index';
import { actionShowSnackbar } from '../../redux/actions/snackbar';
import { CutAndFillReportTypes } from './index.types';

const mapStateToProps = (state: any): CutAndFillReportTypes.ReduxState => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): CutAndFillReportTypes.DispatchProps => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
    },
    dispatch
  );
};

const CutAndFillReportContainer = connect<
  CutAndFillReportTypes.ReduxState,
  CutAndFillReportTypes.DispatchProps,
  CutAndFillReportTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(CutAndFillReport);

export default CutAndFillReportContainer;
