import { Modal, Select } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LayerDescriptor, LayerType } from 'src/api/mapStyle.types';
import { Button } from 'src/components/Button';
import {
  useMapStyleApi,
  useMapStyleContext,
} from 'src/components/Mission/MapMaker/hooks';
import classnames from 'classnames';
import ViewApi from 'src/api/views';
import { View } from 'src/api/views.types';
import { toGeojsonFile } from 'src/components/Mission/MapMaker/utils';
import style from './index.module.scss';
import { formatDateTime } from '../../../../utils/date';

const viewsApi = new ViewApi();

interface ZoomDetails {
  min: number;
  max: number;
}

export interface AddLayerModalProps {
  visible: boolean;
  onCreateLayer: (layer?: LayerDescriptor) => void;
}

export const AddLayerModal: React.FC<AddLayerModalProps> = ({
  onCreateLayer,
  visible,
}) => {
  const mapStyle = useMapStyleContext();
  const api = useMapStyleApi();
  const [name, setName] = useState<string | undefined>();
  const [file, setFile] = useState<File>();
  const [zoom, setZoom] = useState<ZoomDetails>({ min: 0, max: 24 });
  const [sourceUrl, setSourceUrl] = useState<string>();
  const [layerType, setLayerType] = useState<LayerType>();

  if (!mapStyle) return <></>;

  return (
    <Modal
      title="Add a New Layer for Style"
      centered
      visible={visible}
      maskClosable
      onCancel={() => onCreateLayer()}
      onOk={async () => {
        if (name && layerType) {
          const { projectId, aoiId, id } = mapStyle.style;

          // use empty geojson if file not uploaded
          const f = file || toGeojsonFile();

          if (layerType === 'raster' && !sourceUrl) return;

          const layer = await api
            .createStyleLayer(
              projectId,
              aoiId,
              id,
              {
                name,
                type: layerType,
                sourceUrl: layerType === 'raster' ? sourceUrl : undefined,
                maxZoom: zoom.max,
                minZoom: zoom.min,
              },
              f
            )
            .catch((e) => {
              console.error('There was an error while creating the layer.', e);

              return undefined;
            });

          if (layer) onCreateLayer(layer);
        }
      }}
      destroyOnClose
      okText="Create"
    >
      <label>Layer Type:</label>
      <Select
        className={classnames(
          style.fullWidth,
          style.borderBottom,
          style.paddingBottom
        )}
        placeholder="Select layer type to proceed"
        onChange={(value) => {
          setFile(undefined);
          setName(undefined);
          setSourceUrl(undefined);
          setZoom({ min: 0, max: 24 });
          setLayerType(value);
        }}
      >
        <Select.Option value="vector">Vector</Select.Option>
        <Select.Option value="raster">Raster</Select.Option>
      </Select>
      {layerType === 'vector' ? (
        <CreateVectorLayer file={file} setFile={setFile} setName={setName} />
      ) : (
        <></>
      )}
      {layerType === 'raster' ? (
        <CreateRasterLayer
          setName={setName}
          setSourceUrl={setSourceUrl}
          setZoom={setZoom}
        />
      ) : (
        <></>
      )}
    </Modal>
  );
};

export interface CreateVectorLayerProps {
  file?: File;
  setFile: (f: File | undefined) => void;
  setName: (n: string) => void;
}

export const CreateVectorLayer: React.FC<CreateVectorLayerProps> = ({
  file,
  setFile,
  setName,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={style.paddingTop}>
      <div className={style.paddingTop}>
        <label>Name</label>
        <input
          placeholder="Enter Name of Layer"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className={style.paddingTopLarge}>
        <label style={{ display: 'block' }}>Vector File (Optional)</label>
        <div className={classnames(style.editLayerGrid, style.center)}>
          <Button type="secondary" onClick={() => inputRef.current?.click()}>
            Select Vector File
          </Button>
          {file?.name ? (
            <div>{file.name}</div>
          ) : (
            <div>
              No file selected. <br /> Layer will be an empty vector.
            </div>
          )}
        </div>
        <input
          ref={inputRef}
          type="file"
          onChange={(e) => setFile(e.target.files?.[0] || undefined)}
          accept=".json, .geojson"
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
};

export interface CreateRasterLayerProps {
  setName: (n: string) => void;
  setSourceUrl: (u: string) => void;
  setZoom: (z: ZoomDetails) => void;
}

export const CreateRasterLayer: React.FC<CreateRasterLayerProps> = ({
  setName,
  setSourceUrl,
  setZoom,
}) => {
  const mapStyle = useMapStyleContext();
  const [views, setViews] = useState<View[]>([]);

  useEffect(() => {
    if (!mapStyle) return;

    const { projectId, aoiId } = mapStyle.style;

    viewsApi
      .getViews(projectId, aoiId)
      .then((res) => setViews(res.data))
      .catch((e) =>
        console.error('There was an error while fetching the views.', e)
      );
  }, [mapStyle]);

  const options = useMemo(() => {
    const viewIds = new Set();

    return views
      .filter(
        (v) =>
          v.type === 'map' &&
          (v.subType === 'aerial' || v.subType === 'elevation') &&
          v.sourceUrl.length > 0 &&
          v.zoomMax !== undefined &&
          v.zoomMin !== undefined
      )
      .filter((v) => {
        if (viewIds.has(v.id)) return false;

        viewIds.add(v.id);

        return true;
      })
      .sort((a, b) => a.date.localeCompare(b.date))
      .map((v) => {
        return {
          url: v.sourceUrl,
          name: getCustomViewName(v),
          zoom: { min: v.zoomMin, max: v.zoomMax } as ZoomDetails,
        };
      });
  }, [views]);

  return (
    <div className={style.paddingTop}>
      <div className={style.paddingTop}>
        <label>Name</label>
        <input
          placeholder="Enter Name of Layer"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className={style.paddingTopLarge}>
        <label>Data source</label>
        <Select
          placeholder="Select existing view to use as source"
          className={style.fullWidth}
          onChange={(url) => {
            const v = options.find((o) => o.url === url);

            if (v) {
              setSourceUrl(v.url);
              setZoom(v.zoom);
            }
          }}
        >
          {options.map((o) => (
            <Select.Option key={o.url} value={o.url}>
              {o.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export const getCustomViewName = (v: View): string => {
  if (v.subType === 'aerial')
    return `Aerial - ${formatDateTime(v.date).split('T')[0]}${
      v.name ? ` - ${v.name}` : ''
    }`;
  if (v.subType === 'elevation')
    return `Elevation - ${formatDateTime(v.date).split('T')[0]}${
      v.name ? ` - ${v.name}` : ''
    }`;

  return `${v.subType} - ${formatDateTime(v.date).split('T')[0]}${
    v.name ? ` - ${v.name}` : ''
  }`;
};
