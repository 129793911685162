// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Coachmark_tooltipBody__NXkbc{display:flex;flex-direction:column;justify-content:space-between;background-color:#434344;color:#fff;min-width:400px;min-height:150px;padding:10px;opacity:.8;border-radius:5px}.Coachmark_tooltipFooter__jXF0J{display:flex;justify-content:space-between;align-items:center;margin-top:2em}.Coachmark_tooltipFooter__jXF0J div button{margin:.2em}.Coachmark_skipTour__f8QMy{margin-right:9em !important;font-size:smaller !important}.Coachmark_disableCoachMarks__CXe8K{margin:2em;text-align:left}", "",{"version":3,"sources":["webpack://./src/components/Coachmark/Coachmark.module.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,wBAAA,CACA,UAAA,CACA,eAAA,CACA,gBAAA,CACA,YAAA,CACA,UAAA,CACA,iBAAA,CAGF,gCACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,cAAA,CAGE,2CACE,WAAA,CAKN,2BACE,2BAAA,CACA,4BAAA,CAGF,oCACE,UAAA,CACA,eAAA","sourcesContent":[".tooltipBody {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  background-color: #434344;\n  color: #fff;\n  min-width: 400px;\n  min-height: 150px;\n  padding: 10px;\n  opacity: 0.8;\n  border-radius: 5px;\n}\n\n.tooltipFooter {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 2em;\n\n  div {\n    button {\n      margin: 0.2em;\n    }\n  }\n}\n\n.skipTour {\n  margin-right: 9em !important;\n  font-size: smaller !important;\n}\n\n.disableCoachMarks {\n  margin: 2em;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipBody": "Coachmark_tooltipBody__NXkbc",
	"tooltipFooter": "Coachmark_tooltipFooter__jXF0J",
	"skipTour": "Coachmark_skipTour__f8QMy",
	"disableCoachMarks": "Coachmark_disableCoachMarks__CXe8K"
};
export default ___CSS_LOADER_EXPORT___;
