/* eslint-disable func-names */

import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import POPUP from './Popup';

const DrawPoint = {} as any;

DrawPoint.onSetup = function () {
  const point = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: Constants.geojsonTypes.POINT,
      coordinates: [],
    },
  });

  this.addFeature(point);

  this.clearSelectedFeatures();
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.activateUIButton(Constants.types.POINT);

  this.setActionableState({
    trash: true,
  });

  POPUP.setHTML('Click on map to select point');

  return { point };
};

DrawPoint.stopDrawingAndRemove = function (state: any) {
  this.deleteFeature([state.point.id], { silent: true });
  this.changeMode('static');
};

DrawPoint.onTap = DrawPoint.onClick = function (state: any, e: any) {
  this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  state.point.updateCoordinate('', e.lngLat.lng, e.lngLat.lat);
  state.point.setProperty('label', '1');
  this.map.fire(Constants.events.CREATE, {
    features: [state.point.toGeoJSON()],
  });
  this.changeMode('static', { featureIds: [state.point.id] });
};

DrawPoint.onStop = function (state: any) {
  this.activateUIButton();
  if (!state.point.getCoordinate().length) {
    this.deleteFeature([state.point.id], { silent: true });
  }

  POPUP.remove();
};

DrawPoint.toDisplayFeatures = (state: any, geojson: any, display: any) => {
  // Never render the point we're drawing
  const isActivePoint = geojson.properties.id === state.point.id;

  // eslint-disable-next-line no-param-reassign
  geojson.properties.active = isActivePoint
    ? Constants.activeStates.ACTIVE
    : Constants.activeStates.INACTIVE;
  if (!isActivePoint) {
    return display(geojson);
  }
};

DrawPoint.onTrash = DrawPoint.stopDrawingAndRemove;

DrawPoint.onKeyUp = function (state: any, e: any) {
  if (CommonSelectors.isEscapeKey(e) || CommonSelectors.isEnterKey(e)) {
    return this.stopDrawingAndRemove(state, e);
  }
};

DrawPoint.onMouseMove = function (...args: any[]) {
  if (!POPUP.isOpen()) {
    POPUP.addTo(this.map);
  }

  POPUP.setLngLat(args[1].lngLat);
};

DrawPoint.onMouseOut = () => {
  POPUP.remove();
};

export default DrawPoint;
