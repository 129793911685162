/* eslint-disable func-names,no-param-reassign */

import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import mouseEventPoint from '@mapbox/mapbox-gl-draw/src/lib/mouse_event_point';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import StringSet from '@mapbox/mapbox-gl-draw/src/lib/string_set';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import booleanContains from '@turf/boolean-contains';
import POPUP from './Popup';

const DeleteSelect: any = {};

DeleteSelect.onSetup = function (opts: any) {
  POPUP.setHTML('Click on any Polygon');

  // turn the opts into state.
  const state = {
    dragMoveLocation: null,
    boxSelectStartLocation: null,
    boxSelectElement: undefined,
    boxSelecting: false,
    canBoxSelect: false,
    dragMoving: false,
    canDragMove: false,
    initiallySelectedFeatureIds: opts.featureIds || [],
  };

  this.setSelected(
    state.initiallySelectedFeatureIds.filter((id: any) => {
      return this.getFeature(id) !== undefined;
    })
  );
  this.fireActionable();

  this.setActionableState({
    combineFeatures: true,
    uncombineFeatures: true,
    trash: true,
  });

  return state;
};

DeleteSelect.fireUpdate = function () {
  this.map.fire(Constants.events.UPDATE, {
    action: Constants.updateActions.MOVE,
    features: this.getSelected().map((f: any) => f.toGeoJSON()),
  });
};

DeleteSelect.fireActionable = function () {
  const selectedFeatures = this.getSelected();

  const multiFeatures = selectedFeatures.filter((feature: any) =>
    this.isInstanceOf('MultiFeature', feature)
  );

  let combineFeatures = false;

  if (selectedFeatures.length > 1) {
    combineFeatures = true;
    const featureType = selectedFeatures[0].type.replace('Multi', '');

    selectedFeatures.forEach((feature: any) => {
      if (feature.type.replace('Multi', '') !== featureType) {
        combineFeatures = false;
      }
    });
  }

  const uncombineFeatures = multiFeatures.length > 0;
  const trash = selectedFeatures.length > 0;

  this.setActionableState({
    combineFeatures,
    uncombineFeatures,
    trash,
  });
};

DeleteSelect.getUniqueIds = function (allFeatures: any) {
  if (!allFeatures.length) return [];
  const ids = allFeatures
    .map((s: any) => s.properties.id)
    .filter((id: any) => id !== undefined)
    .reduce((memo: any, id: any) => {
      memo.add(id);

      return memo;
    }, new StringSet());

  return ids.values();
};

DeleteSelect.stopExtendedInteractions = function (state: any) {
  if (state.boxSelectElement) {
    if (state.boxSelectElement.parentNode)
      state.boxSelectElement.parentNode.removeChild(state.boxSelectElement);
    state.boxSelectElement = null;
  }

  this.map.dragPan.enable();

  state.boxSelecting = false;
  state.canBoxSelect = false;
  state.dragMoving = false;
  state.canDragMove = false;
};

DeleteSelect.onStop = function () {
  doubleClickZoom.enable(this);

  POPUP.remove();
};

DeleteSelect.onMouseMove = function (state: any, e: any) {
  // On mousemove that is not a drag, stop extended interactions.
  // This is useful if you drag off the canvas, release the button,
  // then move the mouse back over the canvas --- we don't allow the
  // interaction to continue then, but we do let it continue if you held
  // the mouse button that whole time

  if (!POPUP.isOpen()) {
    POPUP.addTo(this.map);
  }

  POPUP.setLngLat(e.lngLat);

  return this.stopExtendedInteractions(state);
};

DeleteSelect.onMouseOut = function (state: any) {
  POPUP.remove();

  // As soon as you mouse leaves the canvas, update the feature
  if (state.dragMoving) return this.fireUpdate();
};

DeleteSelect.onTap = DeleteSelect.onClick = function (state: any, e: any) {
  // Click (with or without shift) on no feature
  if (CommonSelectors.noTarget(e)) return this.clickAnywhere(state, e); // also tap
  if (CommonSelectors.isOfMetaType(Constants.meta.VERTEX)(e))
    return this.clickOnVertex(state, e); // tap
  if (CommonSelectors.isFeature(e)) return this.clickOnFeature(state, e);
};

DeleteSelect.toPolygonFeature = function (
  ringCoordinates: number[][],
  properties: any = {}
) {
  const polygon = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: { ...properties },
    geometry: {
      type: Constants.geojsonTypes.POLYGON,
      coordinates: [ringCoordinates],
    },
  });

  return polygon;
};

DeleteSelect.handleClickInHole = function (e: any) {
  // check if click happened in a hole
  const { lng: x, lat: y } = e.lngLat;
  const point = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: Constants.geojsonTypes.POINT,
      coordinates: [x, y],
    },
  });
  const allContent = this._ctx.api.getAll();
  const innerRingPolygons = allContent.features
    .filter(
      (feature: any) =>
        feature.geometry.coordinates.length > 1 &&
        feature.geometry.type === Constants.geojsonTypes.POLYGON
    )
    .map((feature: any) => {
      const rings: any[] = [];

      feature.geometry.coordinates.slice(1).forEach((ring: any) => {
        const poly = this.toPolygonFeature(ring);

        rings.push(poly);
      });

      return { rings, id: feature.id };
    });

  innerRingPolygons.forEach((feature: any) => {
    feature.rings.forEach(async (ring: any, index: number) => {
      const ringIndex = index + 1;

      if (ring && ring.coordinates && booleanContains(ring, point)) {
        const coordPath = { featureId: feature.id, ringIndex };
        const newFeature = this.toPolygonFeature(ring.coordinates[0], {
          coordPath,
        });

        this.addFeature(newFeature);
        this.select(newFeature.id);
        const ringGeoJSON = newFeature.toGeoJSON();

        ringGeoJSON.properties.coordPath = coordPath;
        this.map.fire(Constants.events.UPDATE, {
          action: Constants.updateActions.MOVE,
          features: [ringGeoJSON],
        });
      }
    });
  });
};

DeleteSelect.clickAnywhere = function (state: any, e: any) {
  // Clear the re-render selection
  const wasSelected = this.getSelectedIds();

  if (wasSelected.length) {
    this.clearSelectedFeatures();
    wasSelected.forEach((id: any) => this.doRender(id));
  }

  this.handleClickInHole(e);

  doubleClickZoom.enable(this);
  this.stopExtendedInteractions(state);
};

DeleteSelect.clickOnVertex = function () {
  // do nothing
};

DeleteSelect.startOnActiveFeature = function (state: any, e: any) {
  // Stop any already-underway extended interactions
  this.stopExtendedInteractions(state);

  // Disable map.dragPan immediately so it can't start
  this.map.dragPan.disable();

  // Re-render it and enable drag move
  this.doRender(e.featureTarget.properties.id);

  // Set up the state for drag moving
  state.canDragMove = true;
  state.dragMoveLocation = e.lngLat;
};

DeleteSelect.clickOnFeature = function (state: any, e: any) {
  POPUP.remove();

  // Stop everything
  doubleClickZoom.disable(this);
  this.stopExtendedInteractions(state);

  const isShiftClick = CommonSelectors.isShiftDown(e);
  const selectedFeatureIds = this.getSelectedIds();
  const featureId = e.featureTarget.properties.id;
  const isFeatureSelected = this.isSelected(featureId);

  if (!isFeatureSelected && !isShiftClick) {
    // Make it the only selected feature
    selectedFeatureIds.forEach((id: any) => this.doRender(id));
    this.setSelected(featureId);
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  }

  // No matter what, re-render the clicked feature
  this.doRender(featureId);
};

DeleteSelect.onMouseDown = function (state: any, e: any) {
  if (CommonSelectors.isActiveFeature(e))
    return this.startOnActiveFeature(state, e);
  if (this.drawConfig.boxSelect && CommonSelectors.isShiftMousedown(e))
    return this.startBoxSelect(state, e);
};

DeleteSelect.startBoxSelect = function (state: any, e: any) {
  this.stopExtendedInteractions(state);
  this.map.dragPan.disable();
  // Enable box select
  state.boxSelectStartLocation = mouseEventPoint(
    e.originalEvent,
    this.map.getContainer()
  );
  state.canBoxSelect = true;
};

DeleteSelect.onTouchStart = function (state: any, e: any) {
  if (CommonSelectors.isActiveFeature(e))
    return this.startOnActiveFeature(state, e);
};

// @todo: I am currently disabling the drag of the features. Enable it in the next release
// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
DeleteSelect.onDrag = function (state: any, e: any) {
  // if (state.canDragMove) return this.dragMove(state, e);
  //  if (this.drawConfig.boxSelect && state.canBoxSelect)
  //   return this.whileBoxSelect(state, e);
};

DeleteSelect.whileBoxSelect = function (state: any, e: any) {
  state.boxSelecting = true;
  this.updateUIClasses({ mouse: Constants.cursors.ADD });

  // Create the box node if it doesn't exist
  if (!state.boxSelectElement) {
    state.boxSelectElement = document.createElement('div');
    state.boxSelectElement.classList.add(Constants.classes.BOX_SELECT);
    this.map.getContainer().appendChild(state.boxSelectElement);
  }

  // Adjust the box node's width and xy position
  const current = mouseEventPoint(e.originalEvent, this.map.getContainer());
  const minX = Math.min(state.boxSelectStartLocation.x, current.x);
  const maxX = Math.max(state.boxSelectStartLocation.x, current.x);
  const minY = Math.min(state.boxSelectStartLocation.y, current.y);
  const maxY = Math.max(state.boxSelectStartLocation.y, current.y);
  const translateValue = `translate(${minX}px, ${minY}px)`;

  state.boxSelectElement.style.transform = translateValue;
  state.boxSelectElement.style.WebkitTransform = translateValue;
  state.boxSelectElement.style.width = `${maxX - minX}px`;
  state.boxSelectElement.style.height = `${maxY - minY}px`;
};

DeleteSelect.dragMove = function (state: any, e: any) {
  // Dragging when drag move is enabled
  state.dragMoving = true;
  e.originalEvent.stopPropagation();

  const delta = {
    lng: e.lngLat.lng - state.dragMoveLocation.lng,
    lat: e.lngLat.lat - state.dragMoveLocation.lat,
  };

  moveFeatures(this.getSelected(), delta);

  state.dragMoveLocation = e.lngLat;
};

DeleteSelect.onMouseUp = function (state: any, e: any) {
  // End any extended interactions
  if (state.dragMoving) {
    this.fireUpdate();
  } else if (state.boxSelecting) {
    const bbox = [
      state.boxSelectStartLocation,
      mouseEventPoint(e.originalEvent, this.map.getContainer()),
    ];
    const featuresInBox = this.featuresAt(null, bbox, 'click');
    const idsToSelect = this.getUniqueIds(featuresInBox).filter(
      (id: any) => !this.isSelected(id)
    );

    if (idsToSelect.length) {
      this.select(idsToSelect);
      idsToSelect.forEach((id: any) => this.doRender(id));
      this.updateUIClasses({ mouse: Constants.cursors.MOVE });
    }
  }

  this.stopExtendedInteractions(state);
};

DeleteSelect.toDisplayFeatures = function (
  state: any,
  geojson: any,
  display: any
) {
  geojson.properties.active = this.isSelected(geojson.properties.id)
    ? Constants.activeStates.ACTIVE
    : Constants.activeStates.INACTIVE;
  display(geojson);
  this.fireActionable();
  if (
    geojson.properties.active !== Constants.activeStates.ACTIVE ||
    geojson.geometry.type === Constants.geojsonTypes.POINT
  )
    return;
  createSupplementaryPoints(geojson).forEach(display);
};

DeleteSelect.onTrash = function () {
  this.deleteFeature(this.getSelectedIds());
  this.fireActionable();
};

DeleteSelect.onCombineFeatures = function () {
  const selectedFeatures = this.getSelected();

  if (selectedFeatures.length === 0 || selectedFeatures.length < 2) return;

  const coordinates = [];
  const featuresCombined = [];
  const featureType = selectedFeatures[0].type.replace('Multi', '');

  for (let i = 0; i < selectedFeatures.length; i += 1) {
    const feature = selectedFeatures[i];

    if (feature.type.replace('Multi', '') !== featureType) {
      return;
    }

    if (feature.type.includes('Multi')) {
      feature.getCoordinates().forEach((subcoords: any) => {
        coordinates.push(subcoords);
      });
    } else {
      coordinates.push(feature.getCoordinates());
    }

    featuresCombined.push(feature.toGeoJSON());
  }

  if (featuresCombined.length > 1) {
    const multiFeature = this.newFeature({
      type: Constants.geojsonTypes.FEATURE,
      properties: featuresCombined[0].properties,
      geometry: {
        type: `Multi${featureType}`,
        coordinates,
      },
    });

    this.addFeature(multiFeature);
    this.deleteFeature(this.getSelectedIds(), { silent: true });
    this.setSelected([multiFeature.id]);

    this.map.fire(Constants.events.COMBINE_FEATURES, {
      createdFeatures: [multiFeature.toGeoJSON()],
      deletedFeatures: featuresCombined,
    });
  }

  this.fireActionable();
};

DeleteSelect.onUncombineFeatures = function () {
  const selectedFeatures = this.getSelected();

  if (selectedFeatures.length === 0) return;

  const createdFeatures: any = [];
  const featuresUncombined = [];

  for (let i = 0; i < selectedFeatures.length; i += 1) {
    const feature = selectedFeatures[i];

    if (this.isInstanceOf('MultiFeature', feature)) {
      feature.getFeatures().forEach((subFeature: any) => {
        this.addFeature(subFeature);
        subFeature.properties = feature.properties;
        createdFeatures.push(subFeature.toGeoJSON());
        this.select([subFeature.id]);
      });
      this.deleteFeature(feature.id, { silent: true });
      featuresUncombined.push(feature.toGeoJSON());
    }
  }

  if (createdFeatures.length > 1) {
    this.map.fire(Constants.events.UNCOMBINE_FEATURES, {
      createdFeatures,
      deletedFeatures: featuresUncombined,
    });
  }

  this.fireActionable();
};

export default DeleteSelect;
