import classnames from 'classnames';
import * as React from 'react';
import BaseIcon from '../Icon';
import style from './ControlBox.module.scss';

interface IProps {
  renderHeader?: () => any;
  className?: string;
}

export class Wrapper extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { renderHeader, children, className } = this.props;

    return (
      <div className={style.container}>
        <div className={style.header}>{renderHeader && renderHeader()}</div>
        <div className={classnames(style.content, className)}>{children}</div>
      </div>
    );
  }
}

interface IIconProps {
  onClick?: () => void;
  name: string;
}

export const Icon = ({ onClick, name }: IIconProps): JSX.Element => (
  <div onClick={onClick} className={style.icon}>
    <BaseIcon name={name} size="small" />
  </div>
);

interface ITitleProps {
  title?: string;
  className?: string;
}

export const Title = ({ title, className }: ITitleProps): JSX.Element => (
  <div className={classnames(style.title, className)}>{title}</div>
);
