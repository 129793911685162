import * as React from 'react';
import { Link } from 'react-router-dom';
import style from './index.module.scss';
import { issueUrl, noAoiIssueUrl } from '../../../routes/urls';

interface IProps {
  projectId: string;
  aoiId?: string;
}

class IssueIconButton extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { projectId, aoiId } = this.props;

    return (
      <Link
        to={
          aoiId
            ? issueUrl(projectId, aoiId, null, { reload: '1' })
            : noAoiIssueUrl(projectId, null, { reload: '1' })
        }
        className={style.container}
        title="Issues"
      >
        <i className="fa fa-exclamation-circle" />
      </Link>
    );
  }
}

export default IssueIconButton;
