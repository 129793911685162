// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ViewToggle_container__zzDYn{width:100px;position:absolute;left:80px;top:10px;display:grid;grid-template-columns:1fr 1fr;grid-gap:10px;z-index:100}.ViewToggle_container__zzDYn .Dropdown-control{background:#49494d;min-width:140px;border-color:rgba(204,204,204,.2);color:#fff}.ViewToggle_container__zzDYn .Dropdown-option,.ViewToggle_container__zzDYn .is-selected{color:#fff}", "",{"version":3,"sources":["webpack://./src/components/TerrainMaps/TerrainMapsTabsLayout2/ViewToggle.module.scss"],"names":[],"mappings":"AAEA,6BACE,WAAA,CACA,iBAAA,CACA,SAAA,CACA,QAAA,CACA,YAAA,CACA,6BAAA,CACA,aAAA,CACA,WAAA,CAGE,+CACE,kBAAA,CACA,eAAA,CACA,iCAAA,CACA,UAAA,CAGF,wFAEE,UAAA","sourcesContent":["@import 'src/styles/scss/base/base';\n\n.container {\n  width: 100px;\n  position: absolute;\n  left: 80px;\n  top: 10px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-gap: 10px;\n  z-index: 100;\n\n  & :global {\n    & .Dropdown-control {\n      background: #49494d;\n      min-width: 140px;\n      border-color: rgba(204, 204, 204, 0.2);\n      color: #fff;\n    }\n\n    & .Dropdown-option,\n    & .is-selected {\n      color: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ViewToggle_container__zzDYn"
};
export default ___CSS_LOADER_EXPORT___;
