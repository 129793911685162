import * as React from 'react';
import styles from './index.module.scss';
import MissionsListing from '../Mission/MissionsListing/container';
import TerrainMaps from '../TerrainMaps/container';
import ElevationDifferenceControl from '../ElevationDifferenceControl/container';
import ContourMaps from '../ContourMaps/container';
import CutAndFillReport from '../CutAndFillReport/container';
import Diagnostics from '../Diagnostics/container';
import { AccessDenied } from '../DroneAnimation/DroneAnimationStatic';
import ProjectsAPI from '../../api/projects';
import ProjectVideos from '../ProjectVideos/container';
import { AoiDataProps, AoiDataStateTypes } from './index.types';
import { log } from '../../utils/log';
import {
  hasAdminAccess,
  hasManageAccess,
  isVimanaLite,
  undefinedOrNull,
} from '../../utils/functs';
import Interior360Manager from '../Interior360Manager';
import { AOI, Project } from '../../api/projects.types';

const projectsApis = new ProjectsAPI();

class AoiData extends React.Component<AoiDataProps, AoiDataStateTypes> {
  public constructor(props: AoiDataProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount(): void {
    this.fetchProject();
  }

  private fetchProject = () => {
    const { match } = this.props;
    const { projectId } = match.params;

    projectsApis
      .getProject(projectId)
      .then((res: any) => {
        if (res && res) {
          this.setState({
            projectData: res,
          });

          return;
        }

        this.setState({
          projectData: undefined,
        });
      })
      .catch((e) => {
        this.setState({
          projectData: undefined,
        });

        log.error(e, 'AoiData -> fetchProject');
      });
  };

  private hasManageAccess = (): boolean => {
    const { user, match } = this.props;
    const { projectId } = match.params;

    if (!user) {
      return false;
    }

    if (user.staff) {
      return true;
    }

    if (projectId) {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of user.project_roles || []) {
        if ((role as any).project_id === projectId) {
          return (
            ['drone_operator', 'field_staff'].indexOf((role as any).role) > -1
          );
        }
      }
    }

    return false;
  };

  public render(): React.ReactNode {
    const { user, location, match, history } = this.props;
    const { projectData } = this.state;

    const { projectId } = match.params;
    const _hasAdminAccess = hasAdminAccess(user, projectId);

    if (!(_hasAdminAccess || hasManageAccess(user, projectId))) {
      return <AccessDenied />;
    }

    return (
      <div className={styles.container}>
        <MissionsListing {...this.props} />
        {_hasAdminAccess ? (
          <React.Fragment>
            {isVimanaLite() ? (
              <>
                <ElevationDifferenceControl
                  match={match}
                  location={location}
                  history={history}
                  user={user}
                  projectData={projectData}
                />
                <TerrainMaps
                  user={user}
                  match={match}
                  location={location}
                  history={history}
                  projectData={projectData}
                />
                <ContourMaps
                  user={user}
                  match={match}
                  location={location}
                  history={history}
                  projectData={projectData}
                />
                <CutAndFillReport
                  match={match}
                  location={location}
                  history={history}
                  projectData={projectData}
                />
                <ProjectVideos
                  projectData={projectData}
                  history={history}
                  match={match}
                  location={location}
                />
                <Interior360Manager
                  user={user}
                  history={history}
                  location={location}
                  match={match}
                />
              </>
            ) : (
              <>
                <ElevationDifferenceControl
                  match={match}
                  location={location}
                  history={history}
                  user={user}
                  projectData={projectData}
                />
                <TerrainMaps
                  user={user}
                  match={match}
                  location={location}
                  history={history}
                  projectData={projectData}
                />
                <ContourMaps
                  user={user}
                  match={match}
                  location={location}
                  history={history}
                  projectData={projectData}
                />
                <CutAndFillReport
                  match={match}
                  location={location}
                  history={history}
                  projectData={projectData}
                />
                <ProjectVideos
                  projectData={projectData}
                  history={history}
                  match={match}
                  location={location}
                />
                <Interior360Manager
                  user={user}
                  history={history}
                  location={location}
                  match={match}
                />
              </>
            )}
          </React.Fragment>
        ) : null}
        {!undefinedOrNull(user) && user.staff ? (
          <Diagnostics
            history={history}
            location={location}
            match={match}
            user={user}
          />
        ) : null}
      </div>
    );
  }
}

export default AoiData;

export const getAoiFromProjectData = (
  project: Project | undefined,
  aoiId: string
): AOI | undefined => {
  return project?.aois.find((aoi) => aoi.id === aoiId);
};
