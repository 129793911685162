import { undefinedOrNull } from './functs';
import { fetchLogo } from '../api/branding';

export const resetBodyOverFlow = (direction: 'y' | 'x'): void => {
  if (typeof document !== 'undefined' && document) {
    if (direction === 'y') {
      document.body.style.overflowY = 'auto';
    } else {
      document.body.style.overflowX = 'auto';
    }
  }
};

export const setBodyOverflow = (
  direction: 'y' | 'x',
  property: string = 'hidden'
): void => {
  if (typeof document !== 'undefined' && document) {
    if (direction === 'y') {
      document.body.style.overflowY = property;
    } else {
      document.body.style.overflowX = property;
    }
  }
};

export const setBodyStyle = (property: string, value: string): void => {
  if (typeof document !== 'undefined' && document) {
    document.body.style[property] = value;
  }
};

export const setTitle = (title: string): void => {
  if (typeof document !== 'undefined' && document) {
    document.title = title;

    return;
  }

  console.error('There was an error while setting the title.');
};

export const setFavicon = (partnerId: any) => {
  const favicon: HTMLLinkElement | null =
    document.querySelector('link[rel="icon"]');

  if (!undefinedOrNull(favicon)) {
    // what if given user has not uploaded any favicon
    if (!undefinedOrNull(partnerId) && partnerId !== 'default') {
      favicon.href = fetchLogo(undefined, 'favicon', partnerId, undefined);
    } else {
      favicon.href = `/capi/api/v1/branding/logo?size=favicon`;
    }
  }
};
