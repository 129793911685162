import * as React from 'react';
import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import TerrainMapsCrud from './TerrainMapsCrud';
import TerrainMapsApis from '../../api/terrainMaps';
import { formatDateForDatePicker } from '../../utils/date';
import { Button } from '../Button';
import { AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE } from '../../constants';
import { GenericObjectType } from '../../shapes/app';
import { sortByDate, sortByRelativeDate } from '../../utils/helpers';
import { TerrainMapsProps, TerrainMapsState } from './index.types';
import DocumentationLink from '../DocumentationLink';
import { DOCUMENTATION_URL_LIST } from '../../constants/urls';
import { getAoiFromProjectData } from '../AoiData';

const { Title } = Typography;
const terrainMapsApis = new TerrainMapsApis();

class TerrainMaps extends React.Component<TerrainMapsProps, TerrainMapsState> {
  public constructor(props: TerrainMapsProps) {
    super(props);

    this.state = {
      dtmsListData: [],
      tableLoading: true,
    };
  }

  public componentDidMount(): void {
    const { match, showSnackbar } = this.props;
    const { projectId, aoiId } = match.params;

    terrainMapsApis.listDtms(projectId, aoiId).then((res) => {
      if (res.error) {
        showSnackbar({
          body: res.error,
          type: 'error',
        });
      }

      const sortedByCreatedAtDate = sortByRelativeDate(
        sortByDate(res.data, 'date', true),
        'date',
        'createdAt',
        true
      );

      const filtered = sortedByCreatedAtDate.filter(
        (x) => x.sourceType !== 'manual_upload'
      );

      this.setState({
        dtmsListData: filtered,
        tableLoading: false,
      });
    });
  }

  private showProjectAdminLinks = (): boolean => {
    const { user, match } = this.props;
    const { projectId } = match.params;

    if (!user) {
      return false;
    }

    if (user.staff) {
      return true;
    }

    if (projectId) {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of user.project_roles || []) {
        if ((role as any).project_id === projectId) {
          return (role as any).role === 'project_admin';
        }
      }
    }

    return false;
  };

  private handleRowClick = (record: GenericObjectType) => {
    const { history, match } = this.props;
    const { projectId, aoiId } = match.params;

    if (!history) {
      return;
    }

    history.push(
      `/project/${projectId}/aoi/${aoiId}/TerrainMaps/edit/${record.id}`
    );
  };

  public allowCreation = (): { allowed: boolean; message?: string } => {
    const { projectData, match } = this.props;
    const { aoiId } = match.params;
    const aoi = getAoiFromProjectData(projectData, aoiId);

    if (!projectData?.epsgCode && !aoi?.boundaryId) {
      return {
        allowed: false,
        message:
          'Add EPSG code and a boundary to your project to enable this feature.',
      };
    }

    if (!projectData?.epsgCode) {
      return {
        allowed: false,
        message: 'Add EPSG code to your project to enable this feature.',
      };
    }

    if (!aoi?.boundaryId) {
      return {
        allowed: false,
        message: 'Add a boundary to enable this feature.',
      };
    }

    return { allowed: true };
  };

  public render(): React.ReactNode {
    const { actionType, match } = this.props;
    const { dtmsListData, tableLoading } = this.state;
    const { projectId, aoiId } = match.params;
    const { allowed, message } = this.allowCreation();

    const tableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (value: string) => value,
      },
      {
        title: 'Survey Date',
        dataIndex: 'date',
        key: 'date',
        render: (value: string) => formatDateForDatePicker(value).format('ll'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value: string, list: any) => {
          if (list.finalized) {
            return 'PUBLISHED';
          }

          return value ? value.toUpperCase() : null;
        },
      },
      {
        title: 'Source Type',
        dataIndex: 'sourceType',
        key: 'sourceType',
        render: (value: string) => {
          if (value === 'dsm') {
            return 'SURFACE MAPS';
          }

          if (value === 'manual_upload') {
            return 'CUSTOM';
          }

          return value ? value.toUpperCase() : null;
        },
      },
    ];

    if (actionType === 'new' || actionType === 'edit') {
      return <TerrainMapsCrud {...this.props} />;
    }

    return (
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <div className={styles.titleInnerWrapper}>
            <Title level={2}>Terrain Maps</Title>
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.terrainMaps}
              toolTipPosition="right"
            />
          </div>

          {this.showProjectAdminLinks() ? (
            <Link
              className={styles.createItemLink}
              to={`/project/${projectId}/aoi/${aoiId}/TerrainMaps/new`}
              title={allowed ? '' : message}
            >
              <Button
                transparent
                text="+ NEW TERRAIN MAP"
                disabled={!allowed}
              />
            </Link>
          ) : null}
        </div>
        <Table
          locale={{ emptyText: 'No Terrain Map generated yet.' }}
          onRow={(record: GenericObjectType) => {
            return {
              onClick: () => {
                this.handleRowClick(record);
              },
            };
          }}
          rowKey={(dtmsListData: any) => dtmsListData.id}
          columns={tableColumns}
          loading={tableLoading}
          dataSource={dtmsListData}
          pagination={{
            defaultPageSize: AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE,
          }}
        />
      </div>
    );
  }
}

export default TerrainMaps;
