import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actionShowSnackbar } from '../../redux/actions/snackbar';
import SplitView from './SplitView';

const mapStateToProps = (): any => {
  return {};
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      showSnackbar: actionShowSnackbar,
    },
    dispatch
  );
};

const SplitViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SplitView);

export default SplitViewContainer;
