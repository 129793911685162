import * as React from 'react';
import style from './Spinner.module.scss';

interface IProps {
  text?: string;
}

export const Spinner: React.FunctionComponent<IProps> = ({
  text,
}: IProps): JSX.Element => (
  <span>
    <i className="fa fa-spinner fa-spin" />
    <span className={style.textSpace}>{text}</span>
  </span>
);
