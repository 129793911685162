import * as queryString from 'query-string';
import * as React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Redirect } from 'react-router-dom';
import { Tabs } from 'antd';
import { MapMakerContainer } from 'src/components/Mission/MapMaker';
import CreateMission from './CreateMission/CreateMission';
import FlightPlanning from './FlightPlanning/FlightPlanning';
import styles from './NewMission.module.scss';
import SurveyPlanning from './SurveyPlanning/SurveyPlanning';
import SkeletonLoader from '../SkeletonLoader';
import {
  isString,
  isVimanaLite,
  shouldShowPage,
  undefinedOrNull,
} from '../../utils/functs';
import AoiV2Apis from '../../api/aoiV2';
import ProjectAPIs from '../../api/projects';
import MissionAPIs from '../../api/mission';
import { GenericObjectType } from '../../shapes/app';
import MissionUploadData from './UploadData';
import DocumentationLink from '../DocumentationLink';
import { DOCUMENTATION_URL_LIST } from '../../constants/urls';
import Publish from './Publish';
import Process from './Process/Process';
import { NewMissionProps, IState } from './index.types';
import { getAoiFromProjectData } from '../AoiData';
import { BoundaryMissing } from './BoundaryMissing';

const { TabPane } = Tabs;

const missionApis = new MissionAPIs();
const projectApis = new ProjectAPIs();
const aoiV2Apis = new AoiV2Apis();

class NewMission extends React.Component<NewMissionProps, IState> {
  public constructor(props: NewMissionProps) {
    super(props);

    const { resetReducerState } = this.props;

    this.state = {
      loaded: false,
      error: null,
      tabIndex: '0',
      tabNumber: 1,
      createdMission: false,
      canEdit: false,
      canDelete: false,
      showManageModal: false,
    };

    resetReducerState();
  }

  public componentDidMount(): void {
    const { missionId } = this.props;

    const values = queryString.parse(window.location.search);

    if (
      !undefinedOrNull(values) &&
      isString(values.index) &&
      parseInt(values.index, 10) > 0
    ) {
      this.incrementTabIndex(parseInt(values.index, 10));
    }

    if (missionId) {
      this.loadMission();
    } else {
      this.setState({ loaded: true });
    }

    this.fetchProjectData();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: NewMissionProps): void {
    const { missionState } = this.props;

    if (
      !this.isEmpty(nextProps.missionState.mission) &&
      this.isEmpty(missionState.mission) &&
      !nextProps.missionState.loading
    ) {
      this.setState({
        mission: nextProps.missionState.mission,
        loaded: !nextProps.missionState.loading,
      });
    }
  }

  public isEmpty = (obj: any) => {
    if (obj == null) {
      return true;
    }

    if (obj.length > 0) {
      return false;
    }

    if (obj.length === 0) {
      return true;
    }

    return Object.getOwnPropertyNames(obj).length <= 0;
  };

  private handleChange = () => {
    this.setState({
      createdMission: true,
    });
  };

  private handleRedirectId = (id: any) => {
    this.setState({
      redirectId: id,
      tabNumber: isVimanaLite() ? 3 : 1,
      createdMission: true,
    });
  };

  private save = (req: any) => {
    const { aoiId, projectId } = this.props;
    const { mission } = this.state;

    if (mission) {
      // update mission
      return missionApis
        .updateMission(projectId, aoiId, mission.id, req)
        .then((res) => {
          if (res.data) {
            this.setState({
              mission: res.data,
            });
          }
        })
        .catch((err) => {
          try {
            this.setState({
              error: err,
            });
          } catch (e) {
            this.setState({
              error: { message: '' },
            });
          }
        });
    }

    if (!mission) {
      // create new mission
      return missionApis
        .createMission(projectId, aoiId, req)
        .then((res) => {
          this.setState({
            redirectId: res.data.id,
          });
        })
        .catch((err) => {
          try {
            this.setState({
              error: err,
            });
          } catch (e) {
            this.setState({
              error: { message: e },
            });
          }
        });
    }

    return Promise.resolve(null);
  };

  private submit = (req: any) => {
    const { mission } = this.state;

    if (mission) {
      const { aoiId, projectId } = this.props;

      return missionApis
        .updateMission(projectId, aoiId, mission.id, {
          ...req,
          status: 'PENDING',
        })
        .then((res) => {
          this.setState(
            {
              mission: res.data,
            },
            () => {
              this.incrementTabIndex();
            }
          );
        })
        .catch((err) => {
          try {
            this.setState({
              error: err,
            });
          } catch (e) {
            this.setState({
              error: { message: '' },
            });
          }
        });
    }

    return Promise.resolve(null);
  };

  private fetchEpsgCoordData = (projectData: GenericObjectType): void => {
    const { showSnackbar } = this.props;

    if (projectData.epsgCode) {
      aoiV2Apis
        .getEpsgData(projectData.epsgCode)
        .then((res) => {
          this.setState({
            epsgCoordData: res.data,
          });
        })
        .catch((err) => {
          showSnackbar({
            type: 'error',
            body: 'There was an error while fetching the coordinate data associated with the project.',
          });

          console.error(err, 'Mission.UploadData.fetchEpsgCoordData');
        });
    }
  };

  private fetchProjectData = () => {
    const { projectId, showSnackbar } = this.props;

    projectApis
      .getProject(projectId)
      .then((res) => {
        this.setState({
          projectData: res,
        });

        this.fetchEpsgCoordData(res);
      })
      .catch((err) => {
        showSnackbar({
          type: 'error',
          body: 'There was an error while fetching the project associated with the mission.',
        });

        console.error(err, 'Mission.UploadData.fetchProjectData');
      });
  };

  private loadMission = () => {
    // fetch mission details
    const { aoiId, projectId, missionId, getMission } = this.props;

    if (!missionId) return;

    getMission(projectId, aoiId, missionId);
    missionApis
      .checkMissionStatus(projectId, aoiId, missionId)
      .then((res) => {
        this.setState({
          canEdit: res.data.editable,
          canDelete: res.data.deletable,
        });
      })
      .catch(() => {
        this.setState({
          canEdit: false,
          canDelete: false,
        });
      });
  };

  private handleTabChange = (tabIndex: string) => {
    this.setState(
      {
        tabIndex,
        canEdit: false,
        canDelete: false,
      },
      () => {
        // reset state & reload mission parameters on tab change
        this.loadMission();
      }
    );
  };

  private incrementTabIndex = (step: number = 1) => {
    const { tabIndex } = this.state;

    this.setState({ tabIndex: (Number(tabIndex) + step).toString() }, () => {
      this.loadMission();
    });
  };

  private shouldDisplayBoundaryMissing = () => {
    const { projectData } = this.state;
    const { aoiId } = this.props;

    const aoi = getAoiFromProjectData(projectData, aoiId);

    return !aoi?.boundaryId?.length;
  };

  public render(): React.ReactNode {
    const {
      aoiId,
      projectId,
      getDrones,
      getGcpPlans,
      getGcpPlanData,
      getFlightPlans,
      getEPSGcodeById,
      missionId,
      missionState,
      searchEPSGcodeByText,
      user,
      updateGcpPlan,
      updateGcpPointsData,
      updateFlightPlan,
      history,
      showSnackbar,
    } = this.props;
    const {
      createdMission,
      error,
      loaded,
      mission,
      redirectId,
      tabIndex,
      tabNumber,
      canEdit,
      canDelete,
      projectData,
      epsgCoordData,
      showManageModal,
    } = this.state;

    if (!loaded) {
      return (
        <div className={styles.container}>
          <div className={styles.centerBodyDiv}>
            <Tabs
              centered
              tabBarStyle={{ textAlign: 'center' }}
              className={styles.reactTabs}
              size="large"
              tabBarGutter={30}
              activeKey={tabIndex}
              onChange={this.handleTabChange}
            >
              <TabPane className={styles.tabContainer} key="0" tab={null}>
                <SkeletonLoader size={2} />
              </TabPane>
              <TabPane className={styles.tabContainer} key="1" tab={null}>
                <SkeletonLoader size={2} />
              </TabPane>
              <TabPane className={styles.tabContainer} key="2" tab={null}>
                <SkeletonLoader size={2} />
              </TabPane>
              <TabPane className={styles.tabContainer} key="3" tab={null}>
                <SkeletonLoader size={2} />
              </TabPane>
              <TabPane className={styles.tabContainer} key="4" tab={null}>
                <SkeletonLoader size={2} />
              </TabPane>
            </Tabs>
          </div>
        </div>
      );
    }

    if (!undefinedOrNull(redirectId) && createdMission) {
      return (
        <Redirect
          to={`/project/${projectId}/aoi/${aoiId}/missions/${redirectId}?index=${tabNumber}`}
        />
      );
    }

    if (error) {
      return (
        <div className={styles.errorContainer}>
          An error occured while creating the mission
          <p>{error && error.message ? error.message : null}</p>
          <button onClick={() => this.setState({ error: null })}>
            Try again
          </button>
        </div>
      );
    }

    const canViewPlans = mission && mission.status !== 'CREATED';

    return (
      <div
        className={styles.container}
        style={{ maxWidth: tabIndex === '4' ? '' : '1500px', margin: 'auto' }}
      >
        <div className={styles.centerBodyDiv}>
          <Tabs
            centered
            tabBarStyle={{ textAlign: 'center' }}
            className={styles.reactTabs}
            size="large"
            tabBarGutter={30}
            activeKey={tabIndex}
            onChange={this.handleTabChange}
          >
            <TabPane
              className={styles.tabContainer}
              tab={
                <span className={styles.purposeSize}>
                  Purpose
                  <DocumentationLink
                    href={DOCUMENTATION_URL_LIST.missionPurpose}
                    toolTipPosition="right"
                  />{' '}
                </span>
              }
              key="0"
            >
              {tabIndex === '0' ? (
                <CreateMission
                  showSnackbar={showSnackbar}
                  aoiId={aoiId}
                  projectId={projectId}
                  mission={mission}
                  onSaveMission={this.save}
                  onSubmitMission={this.submit}
                  handleChange={this.handleChange}
                  handleRedirectId={this.handleRedirectId}
                  incrementTabIndex={this.incrementTabIndex}
                  canEdit={canEdit}
                  canDelete={canDelete}
                  history={history}
                />
              ) : (
                <SkeletonLoader size={2} />
              )}
            </TabPane>
            {isVimanaLite() ? (
              <></>
            ) : (
              <>
                <TabPane
                  className={styles.tabContainer}
                  disabled={!canViewPlans}
                  tab={
                    <span
                      className={canViewPlans ? styles.lightGrey : styles.grey}
                    >
                      Flight Planning
                      <DocumentationLink
                        href={DOCUMENTATION_URL_LIST.missionFlightPlanning}
                        toolTipPosition="right"
                      />
                    </span>
                  }
                  key="1"
                >
                  {missionId && tabIndex === '1' ? (
                    this.shouldDisplayBoundaryMissing() ? (
                      <BoundaryMissing projectId={projectId} aoiId={aoiId} />
                    ) : (
                      <FlightPlanning
                        aoiId={aoiId}
                        projectId={projectId}
                        missionId={missionId}
                        mission={mission}
                        epsg={epsgCoordData}
                        onSaveMission={this.save}
                        reloadMission={this.loadMission}
                        user={user}
                        getDrones={getDrones}
                        drones={missionState.drones}
                        getFlightPlans={getFlightPlans}
                        plans={missionState.flightPlans}
                        updateFlightPlan={updateFlightPlan}
                        showSnackbar={showSnackbar}
                      />
                    )
                  ) : (
                    <SkeletonLoader size={2} />
                  )}
                </TabPane>
                <TabPane
                  className={styles.tabContainer}
                  disabled={!canViewPlans}
                  tab={
                    <span
                      className={canViewPlans ? styles.lightGrey : styles.grey}
                    >
                      Survey Planning
                      <DocumentationLink
                        href={DOCUMENTATION_URL_LIST.missionSurveyPlanning}
                        toolTipPosition="right"
                      />
                    </span>
                  }
                  key="2"
                >
                  {missionId && tabIndex === '2' ? (
                    this.shouldDisplayBoundaryMissing() ? (
                      <BoundaryMissing projectId={projectId} aoiId={aoiId} />
                    ) : (
                      <SurveyPlanning
                        isGroundControlPoints
                        aoiId={aoiId}
                        projectId={projectId}
                        missionId={missionId}
                        missionStatus={mission ? mission.status : null}
                        unit={epsgCoordData?.unit || null}
                        mission={mission}
                        epsgCode={projectData?.epsgCode}
                        epsg={epsgCoordData}
                        onSaveMission={this.save}
                        reloadMission={this.loadMission}
                        getEPSGcodeById={getEPSGcodeById}
                        searchEPSGcodeByText={searchEPSGcodeByText}
                        epsgCodeList={missionState.epsgCodeList}
                        getGcpPlans={getGcpPlans}
                        plans={missionState.gcpPlans}
                        updateGcpPlan={updateGcpPlan}
                        getGcpPlanData={getGcpPlanData}
                        gcpPlanData={missionState.gcpPlanData}
                        updateGcpPointsData={updateGcpPointsData}
                        history={history}
                      />
                    )
                  ) : (
                    <SkeletonLoader size={2} />
                  )}
                </TabPane>
              </>
            )}
            <TabPane
              className={styles.tabContainer}
              disabled={!canViewPlans}
              tab={
                <span className={canViewPlans ? styles.lightGrey : styles.grey}>
                  Upload Data
                  <DocumentationLink
                    href={DOCUMENTATION_URL_LIST.missionUpload}
                    toolTipPosition="right"
                  />
                </span>
              }
              key="3"
            >
              {missionId && (tabIndex === '3' || showManageModal) ? (
                <MissionUploadData
                  showSnackbar={showSnackbar}
                  aoiId={aoiId}
                  projectId={projectId}
                  missionId={missionId}
                  showManageModal={showManageModal}
                  onManageModalClose={() => {
                    this.setState({ showManageModal: false });
                  }}
                />
              ) : (
                <SkeletonLoader size={2} />
              )}
            </TabPane>
            {(user as any)?.staff && mission && (
              <TabPane
                className={styles.tabContainer}
                disabled={!canViewPlans}
                tab={
                  <span
                    className={canViewPlans ? styles.lightGrey : styles.grey}
                  >
                    Process
                  </span>
                }
                key="4"
              >
                {tabIndex === '4' ? (
                  <Process
                    project={projectData}
                    mission={mission}
                    getFlightPlans={getFlightPlans}
                    plans={missionState.flightPlans}
                  />
                ) : (
                  <SkeletonLoader size={2} />
                )}
              </TabPane>
            )}
            {shouldShowPage(projectId, ['project_admin'], user ?? null) && (
              <TabPane
                className={styles.tabContainer}
                disabled={!canViewPlans}
                tab={
                  <span
                    className={canViewPlans ? styles.lightGrey : styles.grey}
                  >
                    Design
                  </span>
                }
                key="5"
              >
                {missionId && tabIndex === '5' ? (
                  <MapMakerContainer
                    projectId={projectId}
                    aoiId={aoiId}
                    missionId={missionId}
                    showSnackbar={showSnackbar}
                  />
                ) : (
                  <SkeletonLoader size={2} />
                )}
              </TabPane>
            )}
            {shouldShowPage(projectId, ['project_admin'], user ?? null) && (
              <TabPane
                className={styles.tabContainer}
                disabled={!canViewPlans}
                tab={
                  <span
                    className={canViewPlans ? styles.lightGrey : styles.grey}
                  >
                    Publish
                    <DocumentationLink
                      href={DOCUMENTATION_URL_LIST.missionPublish}
                      toolTipPosition="right"
                    />
                  </span>
                }
                key="6"
              >
                {missionId && tabIndex === '6' ? (
                  <Publish
                    aoiId={aoiId}
                    projectId={projectId}
                    missionId={missionId}
                    user={user}
                    controlManageModalVisibility={(show: boolean) => {
                      if (show) {
                        this.setState({ tabIndex: '6' });
                      }

                      this.setState({ showManageModal: show });
                    }}
                  />
                ) : (
                  <SkeletonLoader size={2} />
                )}
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default NewMission;
