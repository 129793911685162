import React from 'react';
import CostApis from 'src/api/cost';

interface SessionCostProps {
  sessionId: string;
}

interface SessionCostState {
  cost?: any;
  error?: string;
}

class SessionCost extends React.Component<SessionCostProps, SessionCostState> {
  private costApis = new CostApis();

  constructor(props: SessionCostProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    const { sessionId } = this.props;

    this.costApis.getSessionCost(sessionId).then(({ data, error }) => {
      if (error) {
        console.error(error);
        this.setState({ error: 'Could not load session cost' });

        return;
      }

      this.setState({ cost: data });
    });
  }

  render(): React.ReactNode {
    const { error, cost } = this.state;

    if (error) {
      return <div>{error}</div>;
    }

    if (!cost) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <p>Total: {cost?.totalCost || 'N/A'} </p>
        {(cost?.steps || []).map((s: any) => {
          return (
            <p>
              {s.id}: {s.totalCost}
            </p>
          );
        })}
      </div>
    );
  }
}

export default SessionCost;
