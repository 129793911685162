import * as React from 'react';
import classnames from 'classnames';
import { Switch, Tooltip, Typography } from 'antd';
import GeometryType from 'ol/geom/GeometryType';
import styles from './index.module.scss';
import {
  SiteWalkthroughViewPropsTypes,
  SiteWalkthroughViewStateTypes,
  SiteWalkthroughViewToggleNavigationTypes,
} from './index.types';
import ViewsV2Apis from '../../api/viewsV2';
import {
  GoogleMapsPositionTypes,
  PlotGoogleMapMarkerPointsTypes,
} from '../PlotGoogleMap/index.types';
import { GenericObjectType, NullOrGenericObjectType } from '../../shapes/app';
import {
  APP_PRIMARY_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_COLOR,
  ML_OBJECT_COLOR,
} from '../../constants/colors';
import SkeletonLoader from '../SkeletonLoader';
import Resizer from '../Resizer';
import PlotGoogleMap from '../PlotGoogleMap';
import NavigationButtons8WayController from '../NavigationButtons8WayController';
import {
  inArray,
  removeArrayByValue,
  undefinedOrNull,
  undefinedOrNullChained,
  yawToDeg,
  checkArrayContentEquality,
} from '../../utils/functs';
import { ResizerOnDragPropsTypes } from '../Resizer/index.types';
import { log } from '../../utils/log';
import ProjectsAPI from '../../api/projects';
import LoadingOverlay from '../LoadingOverlay';
import { findClosestLatLong } from '../PlotGoogleMap/helpers';
import ContextMenu from '../ContextMenu';
import OpenLayers from '../OpenLayers';
import ImagesApis from '../../api/images';
import NavigationBox from '../NavigationBox';
import { ContextMenuMenuOptionsTypes } from '../ContextMenu/index.types';
import { SiteObject } from '../../shapes/ml';
import { AnnotationData } from '../OpenLayers/index.types';

const viewsV2Apis = new ViewsV2Apis();
const imagesApis = new ImagesApis();
const projectsApi = new ProjectsAPI();
const { Text } = Typography;

class SiteWalkthroughView extends React.PureComponent<
  SiteWalkthroughViewPropsTypes,
  SiteWalkthroughViewStateTypes
> {
  public constructor(props: SiteWalkthroughViewPropsTypes) {
    super(props);

    this.state = {
      boundaryListData: null,
      imagesListData: null,
      selectedMarkerIndex: null,
      navigationNeighboursData: null,
      mapGeneratedTime: Date.now(),
      markerPointsList: [],
      isPlotGoogleMapExpanded: false,
      mapContainerStyles: {
        width: 400,
        height: '100%',
      },
      toggleNavigation: 'map',
      loadingViewData: true,
      isPegNorthEnabled: true,
      showHiddenImagesEnabled: false,
      showReverseMapInspectOnSiteWalkthrough: true,
      openLayersInitData: {
        centerTo: undefined,
        zoomLevel: undefined,
      },
      hiddenImagesGuidList: [],
      siteObjectImageList: null,
    };
  }

  public componentDidMount() {
    const { shareId, showReverseInspect } = this.props;

    this.fetchViewConfig();
    this.fetchAoiBoundary();

    if (shareId) {
      // disable inspect when displaying share
      this.setState({ showReverseMapInspectOnSiteWalkthrough: false });
    } else if (!undefinedOrNull(showReverseInspect)) {
      this.setState({
        showReverseMapInspectOnSiteWalkthrough: showReverseInspect,
      });
    }
  }

  public UNSAFE_componentWillReceiveProps({
    viewData: nextViewData,
  }: Readonly<SiteWalkthroughViewPropsTypes>): void {
    const { viewData } = this.props;

    if (viewData.id !== nextViewData.id) {
      this.setState({
        loadingViewData: true,
      });
    }
  }

  public componentDidUpdate(
    {
      issue: prevIssue,
      viewData: prevViewData,
      siteObjects: prevSiteObjects,
      siteObjectType: prevSiteObjectType,
      siteObjectSubType: prevSiteObjectSubType,
    }: Readonly<SiteWalkthroughViewPropsTypes>,
    {
      selectedMarkerIndex: prevSelectedMarkerIndex,
    }: Readonly<SiteWalkthroughViewStateTypes>
  ): void {
    const { viewData, issue, siteObjects, siteObjectSubType, siteObjectType } =
      this.props;
    const { selectedMarkerIndex } = this.state;

    if (viewData.id !== prevViewData.id) {
      this.fetchViewConfig();
    } else if (issue !== prevIssue) {
      this.fetchViewConfig();
    }

    if (selectedMarkerIndex !== prevSelectedMarkerIndex) {
      this.configChangeHandler();
    }

    if (!prevSiteObjects && siteObjects) {
      this.handleSiteObjectsChanged();
    } else if (prevSiteObjects && siteObjects) {
      if (
        siteObjectType !== prevSiteObjectType ||
        siteObjectSubType !== prevSiteObjectSubType
      ) {
        this.handleSiteObjectsChanged();
      } else if (
        !checkArrayContentEquality(
          prevSiteObjects
            .filter((o) => o !== null && !o.deleted)
            .map((o) => o.id),
          siteObjects.filter((o) => o !== null && !o.deleted).map((o) => o.id)
        )
      ) {
        this.handleSiteObjectsChanged();
      }
    }
  }

  private fetchViewConfig = () => {
    const { shareData, gotoGuid, latitude, longitude } = this.props;
    const { selectedMarkerIndex } = this.state;

    let _selectedMarkerIndex: null | number = selectedMarkerIndex;

    if (
      !undefinedOrNull(shareData) &&
      shareData.viewDescriptorData &&
      shareData.viewConfigData
    ) {
      const { viewDescriptorData, viewConfigData } = shareData;
      const imagesListData = viewDescriptorData
        ? viewDescriptorData.images
        : [];

      if (gotoGuid) {
        _selectedMarkerIndex = this.getImageFromGuid(gotoGuid, imagesListData);
      } else if (latitude && longitude) {
        const positionList: GoogleMapsPositionTypes[] = imagesListData.map(
          (a: any) => {
            return {
              lat: a.latitude,
              lng: a.longitude,
            };
          }
        );

        const closestLatLong = findClosestLatLong(positionList, {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        });

        if (!undefinedOrNull(closestLatLong)) {
          _selectedMarkerIndex = closestLatLong.index;
        }
      }

      if (undefinedOrNull(_selectedMarkerIndex)) {
        _selectedMarkerIndex = this.findNearestSelectedMarkerIndex(
          _selectedMarkerIndex,
          imagesListData
        );
      }

      if (!undefinedOrNull(imagesListData)) {
        imagesListData.map((a: GenericObjectType, index: number) => {
          if (parseInt(a.id, 10) === parseInt(viewConfigData.selection, 10)) {
            _selectedMarkerIndex = index;
          }

          return a;
        });
      }

      let selectedMarkerIndex = {};

      if (!undefinedOrNull(_selectedMarkerIndex)) {
        selectedMarkerIndex = { selectedMarkerIndex: _selectedMarkerIndex };
      }

      this.setState(
        () => {
          return {
            imagesListData,
            navigationNeighboursData: viewDescriptorData.neighbors,
            ...selectedMarkerIndex,
          };
        },
        () => {
          const { navigationNeighboursData } = this.state;

          this.mapImagesListToMarkerPoints();

          if (undefinedOrNull(navigationNeighboursData)) {
            this.handleNavigationToggleChange('map');
          }
        }
      );

      return;
    }

    this.fetchImages();
  };

  private fetchImages = () => {
    const {
      showSnackbar,
      viewData,
      issue,
      issueId,
      gotoGuid,
      latitude,
      longitude,
    } = this.props;

    const { selectedMarkerIndex, showHiddenImagesEnabled } = this.state;

    return new Promise<void | null>((resolve) => {
      if (!viewData) {
        return resolve(null);
      }

      let _selectedMarkerIndex: null | number = selectedMarkerIndex;
      let hiddenImagesGuidList: string[] = [];

      viewsV2Apis
        .listViewConfig(viewData.projectId, viewData.aoiId, viewData.id)
        .then((res) => {
          const { error: apiError, data: apiData } = res;

          if (apiError) {
            showSnackbar({
              type: 'error',
              body: apiError,
            });

            this.setState({
              loadingViewData: false,
            });

            return;
          }

          let imagesListData = apiData.images || [];

          hiddenImagesGuidList = apiData.hiddenGuids || [];

          if (!showHiddenImagesEnabled) {
            imagesListData = imagesListData.filter(
              (a: GenericObjectType) => !inArray(hiddenImagesGuidList, a.guid)
            );
          }

          if (gotoGuid) {
            _selectedMarkerIndex = this.getImageFromGuid(
              gotoGuid,
              imagesListData
            );
          } else if (latitude && longitude) {
            const positionList: GoogleMapsPositionTypes[] = imagesListData.map(
              (a: any) => {
                return {
                  lat: a.latitude,
                  lng: a.longitude,
                };
              }
            );

            const closestLatLong = findClosestLatLong(positionList, {
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
            });

            if (!undefinedOrNull(closestLatLong)) {
              _selectedMarkerIndex = closestLatLong.index;
            }
          }

          _selectedMarkerIndex = this.findNearestSelectedMarkerIndex(
            _selectedMarkerIndex,
            imagesListData
          );

          if (
            !undefinedOrNull(imagesListData) &&
            !undefinedOrNull(issueId) &&
            issue
          ) {
            imagesListData.map((a: GenericObjectType, index: number) => {
              if (
                parseInt(a.id, 10) === parseInt(issue.viewConfig.selection, 10)
              ) {
                _selectedMarkerIndex = index;
              }

              return a;
            });
          }

          let selectedMarkerIndex = {};

          if (!undefinedOrNull(_selectedMarkerIndex)) {
            selectedMarkerIndex = { selectedMarkerIndex: _selectedMarkerIndex };
          }

          this.setState(
            () => {
              return {
                imagesListData,
                navigationNeighboursData: apiData.neighbors || null,
                hiddenImagesGuidList,
                ...selectedMarkerIndex,
              };
            },
            () => {
              const { navigationNeighboursData } = this.state;

              this.mapImagesListToMarkerPoints();
              this.configChangeHandler();

              if (undefinedOrNull(navigationNeighboursData)) {
                this.handleNavigationToggleChange('map');
              }

              return resolve();
            }
          );
        });
    });
  };

  private getImageFromGuid = (
    guid: string,
    imagesListData: GenericObjectType[] | null
  ): null | number => {
    if (!imagesListData || imagesListData.length < 1) {
      return null;
    }

    let _index: null | number = null;

    imagesListData.map((a: GenericObjectType, index) => {
      if (a.guid === guid) {
        _index = index;
      }

      return a;
    });

    return _index;
  };

  private findNearestSelectedMarkerIndex = (
    selectedMarkerIndex: null | number,
    nextImagesListData: GenericObjectType[] | null,
    pos?: GoogleMapsPositionTypes
  ) => {
    const { imagesListData } = this.state;

    let currentPosition: GoogleMapsPositionTypes;

    if (!nextImagesListData) {
      return selectedMarkerIndex;
    }

    if (pos) {
      currentPosition = pos;
    } else if (!undefinedOrNull(selectedMarkerIndex) && imagesListData) {
      const currentImage: NullOrGenericObjectType =
        imagesListData[selectedMarkerIndex];

      if (undefinedOrNull(currentImage)) {
        return selectedMarkerIndex;
      }

      currentPosition = {
        lat: currentImage.latitude,
        lng: currentImage.longitude,
      };
    } else {
      return selectedMarkerIndex;
    }

    const positionList: GoogleMapsPositionTypes[] = nextImagesListData.map(
      (a) => {
        return {
          lat: a.latitude,
          lng: a.longitude,
        };
      }
    );

    const closestLatLong = findClosestLatLong(positionList, currentPosition);

    if (undefinedOrNull(closestLatLong)) {
      return selectedMarkerIndex;
    }

    return closestLatLong.index;
  };

  private fetchAoiBoundary = (): void => {
    const { viewData, shareData } = this.props;

    if (!undefinedOrNull(shareData) && shareData.boundaryData) {
      const { boundaryData } = shareData;

      this.setAoiBoundary(boundaryData);

      return;
    }

    projectsApi
      .listAoiBoundary(viewData.projectId, viewData.aoiId)
      .then((res) => {
        const { error: apiError, data: apiData } = res;

        this.setAoiBoundary(apiData);

        if (!undefinedOrNull(apiError)) {
          log.error(apiError, 'SiteWalkthrough -> fetchAoiBoundary');
        }
      });
  };

  private setAoiBoundary = (boundaryData: NullOrGenericObjectType) => {
    let boundaryList: GoogleMapsPositionTypes[] | null = null;

    if (
      !undefinedOrNull(boundaryData) &&
      !undefinedOrNullChained(
        boundaryData,
        `features[0].geometry.coordinates[0]`
      )
    ) {
      const coord = boundaryData.features[0].geometry.coordinates[0];

      boundaryList = coord.map((a: [number, number]) => {
        return { lat: a[1], lng: a[0] };
      });
    }

    this.setState(() => {
      return {
        boundaryListData: boundaryList,
      };
    });
  };

  private mapImagesListToMarkerPoints = () => {
    const { imagesListData, hiddenImagesGuidList, siteObjectImageList } =
      this.state;

    if (!imagesListData || imagesListData.length < 1) {
      this.setState(() => {
        return {
          mapGeneratedTime: Date.now(),
          markerPointsList: [],
          loadingViewData: false,
        };
      });

      return;
    }

    const siteObjectImageIdList = siteObjectImageList
      ? siteObjectImageList.map((img) => img.guid)
      : null;

    const mappedMarkerPointsList: PlotGoogleMapMarkerPointsTypes[] =
      imagesListData.map((a: GenericObjectType, index: number) => {
        let unselectedIconColor = GOOGLE_MAP_MARKER_DEFAULT_COLOR;

        if (inArray(hiddenImagesGuidList, a.guid)) {
          unselectedIconColor = GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR;
        } else if (
          siteObjectImageIdList &&
          inArray(siteObjectImageIdList, a.guid)
        ) {
          unselectedIconColor = ML_OBJECT_COLOR.MANUAL;
        }

        return {
          pos: {
            lat: parseFloat(a.latitude),
            lng: parseFloat(a.longitude),
          },
          index,
          icon: {
            selected: {
              types: ['DEFAULT'],
              color: APP_PRIMARY_COLOR,
              ignoreMarkerClusterer: false,
            },
            unselected: {
              types: ['DEFAULT'],
              color: unselectedIconColor,
              ignoreMarkerClusterer: false,
            },
          },
        };
      });

    this.setState({
      mapGeneratedTime: Date.now(),
      markerPointsList: mappedMarkerPointsList,
      loadingViewData: false,
    });
  };

  private handleSiteObjectCreate = (shape: any | null) => {
    const { handleShapeCreate } = this.props;
    const currentImage = this.getCurrentImage();

    if (!shape || !currentImage) {
      return;
    }

    const { features } = shape;
    const { imageWidth, imageHeight } = currentImage;

    if (features && features.length > 0) {
      const newFeatureList = features.map((f: any) => {
        const { geometry } = f;
        let coordinates;

        if (geometry.type === GeometryType.MULTI_POLYGON) {
          coordinates = geometry.coordinates.map((polygon: number[][][]) =>
            this.scalePolygonCoordinates(polygon, imageWidth, imageHeight, true)
          );
        } else if (geometry.type === GeometryType.POLYGON) {
          coordinates = this.scalePolygonCoordinates(
            geometry.coordinates,
            imageWidth,
            imageHeight,
            true
          );
        } else {
          console.error('Unsupported Geometry Type: ', geometry.type, f);

          return f;
        }

        geometry.coordinates = coordinates;
        // eslint-disable-next-line no-param-reassign
        f.geometry = geometry;

        return f;
      });

      // eslint-disable-next-line no-param-reassign
      shape.features = newFeatureList;
    }

    if (handleShapeCreate) {
      handleShapeCreate(shape);
    }
  };

  private handleSiteObjectDelete = (shape: any | null) => {
    const { handleShapeSelect } = this.props;

    if (!shape) {
      return;
    }

    if (handleShapeSelect) {
      handleShapeSelect(shape);
    }
  };

  private handleSiteObjectsChanged = () => {
    const { siteObjects, siteObjectType, siteObjectSubType } = this.props;
    const { imagesListData } = this.state;

    const currentImage = this.getCurrentImage();

    if (
      !currentImage ||
      !siteObjectType ||
      !siteObjectSubType ||
      !siteObjects ||
      !imagesListData
    ) {
      this.setState(
        {
          objectAnnotations: undefined,
          siteObjectImageList: null,
        },
        () => this.mapImagesListToMarkerPoints()
      );

      return;
    }

    const filteredObjects = siteObjects.filter(
      (so) => so.type === siteObjectType && so.subType === siteObjectSubType
    );
    const currentImageObjects = filteredObjects.filter(
      (so) => so.imageId === currentImage.guid
    );
    const annotations = currentImageObjects
      .map((so) => {
        return this.convertObjectToAnnotation(so, currentImage);
      })
      .filter((o) => o !== null);

    const objectImageIds = new Set(
      filteredObjects
        .map((so) => so.imageId)
        .filter((id) => id !== null) as string[]
    );
    const filterImageList = imagesListData.filter(
      (img) => objectImageIds.has(img.guid) || img.guid === currentImage.guid
    );

    this.setState(
      {
        objectAnnotations: annotations as AnnotationData[],
        siteObjectImageList: filterImageList,
      },
      () => this.mapImagesListToMarkerPoints()
    );
  };

  private convertObjectToAnnotation = (
    siteObject: SiteObject,
    currentImage: any
  ) => {
    const feature = JSON.parse(siteObject.finalFeatureGeoJSON);

    if (
      !currentImage ||
      !currentImage.imageHeight ||
      !currentImage.imageWidth ||
      !feature ||
      !feature.geometry ||
      siteObject.deleted
    ) {
      return null;
    }

    const { geometry } = feature;
    const { imageHeight, imageWidth } = currentImage;
    let coordinates;

    if (geometry.type === GeometryType.MULTI_POLYGON) {
      coordinates = geometry.coordinates.map((polygon: number[][][]) =>
        this.scalePolygonCoordinates(polygon, imageWidth, imageHeight)
      );
    } else if (geometry.type === GeometryType.POLYGON) {
      coordinates = this.scalePolygonCoordinates(
        geometry.coordinates,
        imageWidth,
        imageHeight
      );
    } else {
      console.error('Unsupported Geometry Type: ', geometry.type, siteObject);
    }

    const annotation = {
      id: siteObject.id,
      type: geometry.type,
      freehand: false,
      annotationData: coordinates,
      color:
        siteObject.source === 'manual'
          ? ML_OBJECT_COLOR.MANUAL
          : ML_OBJECT_COLOR.AI,
      properties: {
        objectId: siteObject.id,
        source: siteObject.source,
        deleted: siteObject.deleted,
      },
    };

    return annotation;
  };

  private scalePolygonCoordinates = (
    polygon: number[][][],
    xScale: number,
    yScale: number,
    inverse: boolean = false
  ): number[][][] => {
    return polygon.map((ring: number[][]) =>
      ring.map((point: number[]) => {
        // scale coordinates, and account for the fact that top-left is considered origin
        if (inverse) {
          return [point[0] / xScale, (point[1] * -1.0) / yScale];
        }

        return [point[0] * xScale, point[1] * -1.0 * yScale];
      })
    );
  };

  private handleResizerHandler = (payload: ResizerOnDragPropsTypes) => {
    const { mapContainerStyles } = this.state;
    const { right } = payload;

    const { height } = mapContainerStyles;
    let { width } = mapContainerStyles;

    if (!undefinedOrNull(right)) {
      width = this.getAllowedGoogleMapResizeWidth(right);
    }

    this.setState(() => {
      return {
        mapContainerStyles: {
          width,
          height,
        },
      };
    });
  };

  private getAllowedGoogleMapResizeWidth = (value: number): number => {
    const minAllowedWidth = 100;
    const maxAllowedWidth: number = window.innerWidth / 2;

    if (value <= minAllowedWidth) {
      return minAllowedWidth;
    }

    if (value >= maxAllowedWidth) {
      return maxAllowedWidth;
    }

    return value;
  };

  private mapContainerResizeStyles = () => {
    const { mapContainerStyles, isPlotGoogleMapExpanded } = this.state;

    if (!isPlotGoogleMapExpanded) {
      return {};
    }

    return {
      width: mapContainerStyles.width,
      height: mapContainerStyles.height,
    };
  };

  private handleGoogleMapExpandButton = () => {
    const { isPlotGoogleMapExpanded } = this.state;

    this.setState(() => {
      return {
        isPlotGoogleMapExpanded: !isPlotGoogleMapExpanded,
      };
    });
  };

  private handleMarkerClick = (index: number, simulated: boolean = false) => {
    const { onImagesSelectedMarkerIndexChange, forwardRef } = this.props;

    let zoomLevel: number | undefined;
    let centerTo: number[] | undefined;

    if (!simulated && forwardRef.current) {
      zoomLevel = forwardRef.current.getMapZoom();
      centerTo = forwardRef.current.getMapCenter();
    }

    this.setState(
      {
        selectedMarkerIndex: index,
        openLayersInitData: {
          zoomLevel,
          centerTo,
        },
      },
      () => {
        this.handleSiteObjectsChanged();
        if (!simulated && onImagesSelectedMarkerIndexChange) {
          onImagesSelectedMarkerIndexChange();
        }
      }
    );
  };

  private configChangeHandler = () => {
    const { onConfigChange } = this.props;

    if (onConfigChange) {
      const config = this.getConfig();

      onConfigChange(config);
    }
  };

  private getConfig = () => {
    const { hiddenImagesGuidList } = this.state;
    const currentImage = this.getCurrentImage();

    if (currentImage) {
      return {
        selection: currentImage.id,
        guid: currentImage.guid,
        hiddenImagesGuidList,
      };
    }

    return {
      selection: 0,
      guid: null,
    };
  };

  private handleNavigationBtnClick = (nextGuid: string | null) => {
    const { imagesListData } = this.state;

    if (undefinedOrNull(nextGuid) || undefinedOrNull(imagesListData)) {
      return;
    }

    let nextIndex: number | null = null;

    imagesListData.map((item, index) => {
      if (item.guid === nextGuid) {
        nextIndex = index;
      }

      return item;
    });

    if (undefinedOrNull(nextIndex)) {
      return;
    }

    this.handleMarkerClick(nextIndex);
  };

  private handleNavigationToggleChange = (
    value: SiteWalkthroughViewToggleNavigationTypes
  ) => {
    this.setState({ toggleNavigation: value });
  };

  private getCurrentImage = (): null | GenericObjectType => {
    const { imagesListData, selectedMarkerIndex } = this.state;

    if (!imagesListData) {
      return null;
    }

    if (selectedMarkerIndex === null) {
      return null;
    }

    return imagesListData[selectedMarkerIndex]
      ? imagesListData[selectedMarkerIndex]
      : null;
  };

  private findIndexById(id: string, imageArray: { id: number }[]): number {
    for (let i = 0; i < imageArray.length; i += 1) {
      if (imageArray[i].id === parseInt(id, 10)) {
        return i;
      }
    }

    return 0;
  }

  private handlePegNorthToggleSwitch = (value: boolean) => {
    this.setState({
      isPegNorthEnabled: value,
    });
  };

  private handleHiddenImagesToggleSwitch = (value: boolean) => {
    const { selectedMarkerIndex, imagesListData } = this.state;

    if (
      !imagesListData ||
      undefinedOrNull(selectedMarkerIndex) ||
      !imagesListData[selectedMarkerIndex]
    ) {
      return;
    }

    const { latitude: lat, longitude: lng } =
      imagesListData[selectedMarkerIndex];

    this.setState(
      {
        showHiddenImagesEnabled: value,
        loadingViewData: true,
      },
      async () => {
        await this.fetchImages();

        const { imagesListData: nextImagesListData } = this.state;

        const nextSelectedMarkerIndex = this.findNearestSelectedMarkerIndex(
          selectedMarkerIndex,
          nextImagesListData,
          { lat, lng }
        );

        this.setSelectedMarkerIndex(nextSelectedMarkerIndex);
      }
    );
  };

  private setSelectedMarkerIndex = (selectedMarkerIndex: number | null) => {
    this.setState({
      selectedMarkerIndex,
    });
  };

  private handleReverseMapInspect = () => {
    const { onReverseMapLookUp, viewData } = this.props;
    const { imagesListData, selectedMarkerIndex } = this.state;

    const images = imagesListData || [];

    if (!undefinedOrNull(selectedMarkerIndex)) {
      const selectedImage = images[selectedMarkerIndex];

      const preservedMapMeta = {
        centerLatitude: parseFloat(selectedImage.latitude),
        centerLongitude: parseFloat(selectedImage.longitude),
      };

      onReverseMapLookUp(preservedMapMeta, viewData);
    }
  };

  private handleHideImage = (hide: boolean) => {
    const { viewData, showSnackbar } = this.props;
    const { projectId, aoiId, id: viewId } = viewData;
    const currentImage = this.getCurrentImage();

    if (!currentImage) {
      return;
    }

    const { guid } = currentImage;

    const formData = {
      hidden: hide,
    };

    this.setState({
      loadingViewData: true,
    });

    viewsV2Apis
      .patchViewImageConfig(projectId, aoiId, viewId, guid, formData)
      .then((res) => {
        const { error: apiError } = res;

        if (apiError) {
          showSnackbar({
            type: 'error',
            body: apiError,
          });

          this.setState({
            loadingViewData: false,
          });

          return;
        }

        const { hiddenImagesGuidList } = this.state;

        let _hiddenImagesGuidList = [guid, ...hiddenImagesGuidList];

        if (!hide) {
          _hiddenImagesGuidList = removeArrayByValue(
            _hiddenImagesGuidList,
            guid
          );
        }

        this.setState(
          () => ({
            hiddenImagesGuidList: _hiddenImagesGuidList,
          }),
          () => {
            this.mapImagesListToMarkerPoints();
            this.configChangeHandler();
          }
        );
      });
  };

  private getMarkAnnotations = () => {
    const { isMarkIssueActive, issue, issueId } = this.props;

    if (!isMarkIssueActive) {
      return null;
    }

    let annotationData: null | number[][] = null;

    if (issue && issueId) {
      const { shapeGeoJson } = issue;

      let geoJson: NullOrGenericObjectType = null;

      try {
        geoJson = JSON.parse(shapeGeoJson);
      } catch (err) {
        log.error(err, 'SiteWalkthroughView.getMarkAnnotations');
      }

      if (geoJson) {
        annotationData = geoJson.features;
      }
    }

    return {
      id: issueId || 'mark_issue',
      type: GeometryType.LINE_STRING,
      freehand: true,
      maxSize: 1,
      annotationData,
    };
  };

  private getInitData = () => {
    const { issue, issueId } = this.props;
    const { openLayersInitData } = this.state;

    let { zoomLevel, centerTo } = openLayersInitData;

    if (issue && issueId) {
      const { viewConfig } = issue;

      if (
        !undefinedOrNull(viewConfig.latitude) &&
        !undefinedOrNull(viewConfig.longitude)
      ) {
        centerTo = [viewConfig.latitude, viewConfig.longitude];
      }

      zoomLevel = viewConfig.zoom;
    }

    return {
      zoomLevel,
      centerTo,
    };
  };

  private isMLEnabled = () => {
    const { siteObjects, siteObjectType, siteObjectSubType } = this.props;

    if (
      siteObjects &&
      siteObjects.length > 1 &&
      siteObjectType &&
      siteObjectSubType
    ) {
      return true;
    }

    return false;
  };

  private isAdmin = () => {
    const { userData, userRole } = this.props;

    if (!userData) {
      return;
    }

    return userRole === 'project_admin' || userData.staff;
  };

  private getContextMenuOptionsList = () => {
    const { showReverseMapInspectOnSiteWalkthrough, hiddenImagesGuidList } =
      this.state;

    const isAdmin = this.isAdmin();
    const currentImage = this.getCurrentImage();

    const menuOptionsList: ContextMenuMenuOptionsTypes[] = [];

    if (showReverseMapInspectOnSiteWalkthrough) {
      menuOptionsList.push({
        label: 'Back to Maps',
        name: 'back_to_maps',
        onClick: (_) => {
          this.handleReverseMapInspect();
        },
      });
    }

    if (isAdmin && currentImage) {
      if (currentImage && inArray(hiddenImagesGuidList, currentImage.guid)) {
        menuOptionsList.push({
          label: 'Unhide Image',
          name: 'unhide_image',
          onClick: (_) => {
            this.handleHideImage(false);
          },
        });
      } else {
        menuOptionsList.push({
          label: 'Hide Image',
          name: 'hide_image',
          onClick: (name, confirmActionValue) => {
            if (!confirmActionValue) {
              return;
            }

            this.handleHideImage(true);
          },
          confirmAction: {
            title: 'Confirmation',
            body: 'This image will be removed from the view.',
          },
        });
      }
    }

    return menuOptionsList;
  };

  public render(): React.ReactNode {
    const {
      shareId,
      forwardRef,
      issue,
      issueId,
      drawControlIntent,
      siteObjects,
      siteObjectType,
      siteObjectSubType,
    } = this.props;
    const {
      imagesListData,
      markerPointsList,
      isPlotGoogleMapExpanded,
      selectedMarkerIndex,
      mapGeneratedTime,
      toggleNavigation,
      boundaryListData,
      loadingViewData,
      isPegNorthEnabled,
      showHiddenImagesEnabled,
      objectAnnotations,
    } = this.state;

    if (
      !markerPointsList ||
      markerPointsList.length < 1 ||
      (issue && issueId && undefinedOrNull(selectedMarkerIndex))
    ) {
      return <SkeletonLoader position="fixed" />;
    }

    const currentImage = this.getCurrentImage();
    const isNadirImage = currentImage
      ? currentImage.gimbalPitch < -85 && currentImage.gimbalPitch > -95
      : false;

    let navigationRotationAngle = yawToDeg(0);
    let navigationCompassRotationAngle = yawToDeg(
      currentImage ? currentImage.gimbalYaw : 0
    );

    if (isPegNorthEnabled && isNadirImage) {
      navigationRotationAngle = yawToDeg(
        currentImage ? currentImage.gimbalYaw : 0
      );
      navigationCompassRotationAngle = yawToDeg(0);
    }

    const navPoints = (imagesListData || []).map((p) => {
      return {
        latitude: parseFloat(p.latitude),
        longitude: parseFloat(p.longitude),
        guid: p.guid,
      };
    });

    const contextMenuOptionsList = this.getContextMenuOptionsList();

    return (
      <div className={styles.container}>
        <div
          className={classnames(styles.containerInnerWrapper, {
            [styles.splitPanes]: isPlotGoogleMapExpanded,
          })}
        >
          <Resizer
            allowedDirections={['right']}
            onDrag={this.handleResizerHandler}
            showHotspot={{
              right: isPlotGoogleMapExpanded,
            }}
          >
            <div
              style={this.mapContainerResizeStyles()}
              className={classnames(styles.mapContainer, {
                [styles.float]: !isPlotGoogleMapExpanded,
                [styles.sidePane]: isPlotGoogleMapExpanded,
              })}
            >
              <div className={styles.informationContainer}>
                <div className={styles.item}>
                  <Text>Peg North&nbsp;</Text>
                  <Switch
                    defaultChecked={isPegNorthEnabled}
                    onChange={this.handlePegNorthToggleSwitch}
                  />
                </div>

                {this.isAdmin() && (
                  <div className={styles.item}>
                    <Text>Show Hidden Images&nbsp;</Text>
                    <Switch
                      defaultChecked={showHiddenImagesEnabled}
                      onChange={this.handleHiddenImagesToggleSwitch}
                    />
                  </div>
                )}
              </div>

              <div
                className={classnames(styles.plotGoogleMapWrapper, {
                  [styles.sidePane]: isPlotGoogleMapExpanded,
                  hide: toggleNavigation !== 'map',
                })}
              >
                <PlotGoogleMap
                  mapGeneratedTime={mapGeneratedTime}
                  width="100%"
                  height="100%"
                  boundaryPointsList={
                    boundaryListData ? [boundaryListData] : null
                  }
                  markerPointsList={markerPointsList}
                  selectedMarkerIndex={selectedMarkerIndex}
                  onMarkerClick={this.handleMarkerClick}
                  initialMarkerPosition="center"
                  centerMapTo="boundary"
                  markerClustererMaxZoom={20}
                  customActionButtonList={[
                    {
                      type: 'expand',
                      data: {
                        expandedTitle: 'Collapse the map',
                        collapsedTitle: 'Expand the map',
                      },

                      callback: this.handleGoogleMapExpandButton,
                    },
                  ]}
                  gestureHandling="greedy"
                  maintainZoomLevel
                  showMLObjectsLegend={this.isMLEnabled()}
                />
              </div>
              <div
                className={classnames(styles.eightWayNavWrapper, {
                  hide: toggleNavigation !== '8-way-navigation',
                })}
              >
                <NavigationButtons8WayController
                  points={navPoints}
                  rotationCorrection={
                    isPegNorthEnabled && isNadirImage
                      ? 0
                      : currentImage
                      ? currentImage.gimbalYaw
                      : 0
                  }
                  onNavigationBtnClick={this.handleNavigationBtnClick}
                  guid={currentImage ? currentImage.guid : null}
                />
              </div>

              <div
                className={classnames(styles.navigationToggleButtonWrapper, {
                  hide: isPlotGoogleMapExpanded,
                })}
              >
                <Tooltip placement="right" title="Navigate using map">
                  <button
                    className={classnames({
                      [styles.active]: toggleNavigation === 'map',
                    })}
                    onClick={() => {
                      this.handleNavigationToggleChange('map');
                    }}
                  >
                    <i className="fa fa-map" aria-hidden="true" />
                  </button>
                </Tooltip>
                <Tooltip placement="right" title="Navigate using joystick">
                  <button
                    className={classnames({
                      [styles.active]: toggleNavigation === '8-way-navigation',
                    })}
                    onClick={() => {
                      this.handleNavigationToggleChange('8-way-navigation');
                    }}
                  >
                    <i className="fa fa-compass fa-lg" aria-hidden="true" />
                  </button>
                </Tooltip>
              </div>
            </div>
          </Resizer>

          <div
            id="sitewalkthrough-container"
            className={classnames(styles.tilingWrapper, {
              [styles.sidePane]: isPlotGoogleMapExpanded,
            })}
          >
            {currentImage ? (
              <OpenLayers
                initialZoomLevel={2}
                type="TILES"
                guid={currentImage.guid}
                tileUrl={imagesApis.getImageTilesUrl(
                  currentImage.guid,
                  shareId
                )}
                imageUrl={imagesApis.getImageTilesUrl(
                  currentImage.guid,
                  shareId,
                  true
                )}
                rotationAngle={navigationRotationAngle}
                annotation={this.getMarkAnnotations()}
                initData={this.getInitData()}
                ref={forwardRef}
                isTwoPaneView={isPlotGoogleMapExpanded}
                objectAnnotations={objectAnnotations}
                drawModeType={
                  siteObjects &&
                  siteObjectType &&
                  siteObjectSubType &&
                  drawControlIntent === 'create_object'
                    ? 'Polygon'
                    : undefined
                }
                selectType={
                  siteObjects &&
                  siteObjectType &&
                  siteObjectSubType &&
                  drawControlIntent === 'delete_object'
                    ? 'click'
                    : undefined
                }
                handleShapeCreate={this.handleSiteObjectCreate}
                handleShapeSelect={this.handleSiteObjectDelete}
              >
                <div className={styles.navigationBoxWrapper}>
                  <NavigationBox
                    altitude={{
                      value: currentImage.elevation || 0,
                      round: true,
                      unit: 'meters',
                    }}
                    rotation={360 - navigationCompassRotationAngle}
                  />
                </div>
              </OpenLayers>
            ) : (
              <div className={styles.noMarkerWrapper}>
                <Text>Select any marker in map to load the image.</Text>
              </div>
            )}
          </div>
        </div>

        {loadingViewData ? <LoadingOverlay /> : null}

        {contextMenuOptionsList && contextMenuOptionsList.length > 0 && (
          <ContextMenu
            nodeSelector="#sitewalkthrough-container"
            menuOptionsList={contextMenuOptionsList}
          />
        )}
      </div>
    );
  }
}

export default SiteWalkthroughView;
