import { objectListToQueryString, undefinedOrNull } from '../utils/functs';

export const gcpMarkingListingPageUrl = (
  projectId: string,
  aoiId: string,
  sessionId: string
): string => {
  return `/project/${projectId}/aoi/${aoiId}/gcpmarking/session/${sessionId}`;
};

export const gcpMarkingGcpIdUrl = (
  projectId: string,
  aoiId: string,
  sessionId: string,
  gcpId: string
): string => {
  return `/project/${projectId}/aoi/${aoiId}/gcpmarking/session/${sessionId}/gcp/${gcpId}`;
};

export const gcpMarkingProjectionsActionUrl = (
  projectId: string,
  aoiId: string,
  sessionId: string,
  gcpId: string,
  actionType: string
): string => {
  return `/project/${projectId}/aoi/${aoiId}/gcpmarking/session/${sessionId}/gcp/${gcpId}/action/${actionType}`;
};

export const editMissionUrl = (
  projectId: string,
  aoiId: string,
  missionId: string,
  tabIndex: number = 0
): string => {
  return `/project/${projectId}/aoi/${aoiId}/missions/${missionId}?index=${tabIndex}`;
};

export const paymentsUrl = (): string => {
  return `/payments`;
};

export const paymentsHistoryUrl = (): string => {
  return `/payments/history`;
};

export const viewUrl = (
  projectId: string,
  aoiId: string,
  viewId: string,
  queryStringParams?: { [key: string]: string | number | undefined }
): string => {
  return `/project/${projectId}/aoi/${aoiId}/view/${viewId}${objectListToQueryString(
    queryStringParams
  )}`;
};

export const issueUrl = (
  projectId: string,
  aoiId: string,
  issueId?: string | null,
  queryStringParams?: { [key: string]: string | number }
): string => {
  let url = `/project/${projectId}/aoi/${aoiId}/issues`;

  if (!undefinedOrNull(issueId)) {
    url += `/${issueId}`;
  }

  return `${url}${objectListToQueryString(queryStringParams)}`;
};

export const noAoiIssueUrl = (
  projectId: string,
  issueId?: string | null,
  queryStringParams?: { [key: string]: string | number }
): string => {
  let url = `/project/${projectId}/issues`;

  if (!undefinedOrNull(issueId)) {
    url += `/${issueId}`;
  }

  return `${url}${objectListToQueryString(queryStringParams)}`;
};

export const aoiPageUrl = (projectId: string, aoiId: string): string => {
  return `/project/${projectId}/aoi/${aoiId}`;
};

export const aoiListingPageUrl = (projectId: string, aoiId: string): string => {
  return `/project/${projectId}/aoi/${aoiId}/list`;
};

export const sandboxUrl = (projectId: string, aoiId: string): string => {
  return `/project/${projectId}/aoi/${aoiId}/sandbox`;
};

export const projectVideosNewPageUrl = (
  projectId: string,
  aoiId: string
): string => {
  return `/project/${projectId}/aoi/${aoiId}/videos/new`;
};

export const projectVideosEditPageUrl = (
  projectId: string,
  aoiId: string,
  viewId: string
): string => {
  return `/project/${projectId}/aoi/${aoiId}/view/${viewId}/videos/edit`;
};

export const newInspectionImageUploadUrl = (
  projectId: string,
  aoiId: string
): string => {
  return `/project/${projectId}/aoi/${aoiId}/InspectionUpload/new`;
};

export const editInspectionImageUploadUrl = (
  projectId: string,
  aoiId: string,
  uploadId: string
): string => {
  return `/project/${projectId}/aoi/${aoiId}/InspectionUpload/edit/${uploadId}`;
};

export const listDownloadsUrl = (
  projectId: string,
  aoiId?: string | null
): string => {
  if (!undefinedOrNull(aoiId)) {
    return `/project/${projectId}/aoi/${aoiId}/downloads/list`;
  }

  return `/project/${projectId}/downloads/list`;
};

export const editElevationDifferenceUrl = (
  projectId: string,
  aoiId: string,
  elevationDifferenceId: string
): string => {
  return `/project/${projectId}/aoi/${aoiId}/elevationDifference/edit/${elevationDifferenceId}`;
};

export const newInterior360ViewUrl = (
  projectId: string,
  aoiId: string
): string => {
  return `/project/${projectId}/aoi/${aoiId}/interior360/new`;
};

export const editInterior360viewUrl = (
  projectId: string,
  aoiId: string,
  viewId: string
): string => {
  return `/project/${projectId}/aoi/${aoiId}/view/${viewId}/interior360/edit`;
};
