/* eslint-disable max-classes-per-file */
import { Project, ProjectRole, ProjectUser } from '../../api/projects.types';
import {
  HasMoreState,
  Issue,
  IssueComment,
  IssueProcessState,
  IssuesModalVisibilityState,
  IssuesPageConfig,
  IssuesStatus,
  LoadingState,
  Tag,
} from '../../api/issue.types';
import { RouterParamsTypes } from '../../shapes/routerParams';
import { SnackbarActionsActionShowSnackbarTypes } from '../../shapes/snackbar';
import { User } from '../../api/auth.types';

export type IssueDataManagerProps = RouterParamsTypes &
  IssueDataManagerTypes.OwnProps &
  IssueDataManagerTypes.DispatchProps &
  IssueDataManagerTypes.ReduxState;

export namespace IssueDataManagerTypes {
  export interface OwnProps {
    aoiId?: string;
    issueId?: string;
    projectId: string;
    queryParams?: {
      [key in IssueQueryParamKeys]?: string;
    };
  }
  export interface DispatchProps {
    fetchIssues: (pageConfig: IssuesPageConfig) => void;
    fetchSingleIssue: (issueId: string) => void;
    fetchIssuesStatus: (pageConfig: IssuesPageConfig) => void;
    fetchComments: (issueId: string, commentPage: number) => void;
    fetchProject: (projectId: string) => void;
    clearProjects: () => void;
    fetchTagsForProject: (projectId: string) => void;
    fetchProjectUsers: (projectId: string) => void;
    selectIssue: (issue: Issue) => void;
    clearSelectedIssue: () => void;
    createComment: (issueId: string, commentMessage: string) => void;
    resolveIssue: (issueId: string) => void;
    updateIssueTags: (issueId: string, tagIds: string[]) => void;
    createTag: (projectId: string, tagName: string) => void;
    deleteTags: (projectId: string, tagsToDelete: string[]) => void;
    clearTagStatus: () => void;
    setPageConfig: (pageConfig: IssuesPageConfig) => void;
    resetProcessState: () => void;
    closeManageTagsModal: () => void;
    closeReportPreviewModal: () => void;
    showSnackbar: SnackbarActionsActionShowSnackbarTypes;
  }
  export interface ReduxState {
    issues: Issue[];
    singleIssue?: Issue;
    comments: IssueComment[];
    commentPage?: number;
    tags: Tag[];
    issuesStatus?: IssuesStatus;
    pageConfig?: IssuesPageConfig;
    loading: LoadingState;
    processState: IssueProcessState;
    hasMore?: HasMoreState;
    project?: Project;
    projectUsers: ProjectUser[];
    user: User;
    userRole: ProjectRole;
    showModals?: IssuesModalVisibilityState;
  }
}

export type IssueQueryParamKeys =
  | 'showResolved'
  | 'page'
  | 'commentPage'
  | 'tagId'
  | 'reload';

export interface IssueQueryParams {
  showResolved?: boolean;
  page?: number;
  commentPage?: number;
  reload?: boolean;
  tagId?: string;
}

export interface IssueFilter {
  showResolved?: boolean;
  tagId?: string;
}

export interface IssuePages {
  // 2-d array of issues, where each row is a page of issues
  pages: Issue[][];
}

export class IssuesEvent<T> {
  type: IssuesEventType;

  data: T;
}

export type IssuesEventType =
  | 'create_tag' // Handle creation of a tag
  | 'delete_tags' // Handle deletion of a tag
  | 'create_issue_comment' // Handle creation of a comment in an issue
  | 'change_issue' // Handle change of selected issue
  | 'clear_issue' // Clear selected issue
  | 'clear_tags_status' // Clear manage tags status
  | 'clear_process_state' // Clear the process state
  | 'change_page' // Handle changing page in pagination
  | 'change_filter' // Handle any change in filtering criteria used to display issues.
  | 'resolve_issue' // Handle issue resolution
  | 'update_issue_tags' // Handle updating the tags associated with an issue.
  | 'change_comment_page'; // Handle change of comment page

export class ChangeIssueEvent extends IssuesEvent<Issue> {
  constructor(issue: Issue) {
    super();

    this.type = 'change_issue';
    this.data = issue;
  }
}

export class ClearIssueEvent extends IssuesEvent<undefined> {
  constructor() {
    super();

    this.type = 'clear_issue';
  }
}

export class ChangeFilterEvent extends IssuesEvent<IssuesPageConfig> {
  constructor(pageConfig: IssuesPageConfig) {
    super();

    this.type = 'change_filter';
    this.data = pageConfig;
  }
}

export class ChangePageEvent extends IssuesEvent<number> {
  constructor(page: number) {
    super();

    this.type = 'change_page';
    this.data = page;
  }
}

export class CreateCommentEvent extends IssuesEvent<{
  issue: Issue;
  comment: string;
}> {
  constructor(issue: Issue, comment: string) {
    super();

    this.type = 'create_issue_comment';
    this.data = {
      issue,
      comment,
    };
  }
}

export class ChangeCommentPageEvent extends IssuesEvent<{
  issue: Issue;
  page: number;
}> {
  constructor(issue: Issue, page: number) {
    super();

    this.type = 'change_comment_page';
    this.data = {
      issue,
      page,
    };
  }
}

export class ResolveIssueEvent extends IssuesEvent<Issue> {
  constructor(issue: Issue) {
    super();

    this.type = 'resolve_issue';
    this.data = issue;
  }
}

export class UpdateIssueTagsEvent extends IssuesEvent<{
  issue: Issue;
  tagIds: string[];
}> {
  constructor(issue: Issue, tagIds: string[]) {
    super();

    this.type = 'update_issue_tags';
    this.data = {
      issue,
      tagIds,
    };
  }
}

export class ClearProcessStateEvent extends IssuesEvent<undefined> {
  constructor() {
    super();

    this.type = 'clear_process_state';
  }
}

export class CreateTagEvent extends IssuesEvent<string> {
  constructor(tag: string) {
    super();

    this.type = 'create_tag';
    this.data = tag;
  }
}

export class DeleteTagsEvent extends IssuesEvent<string[]> {
  constructor(tagIds: string[]) {
    super();

    this.type = 'delete_tags';
    this.data = tagIds;
  }
}

export class ClearTagsStatusEvent extends IssuesEvent<undefined> {
  constructor() {
    super();

    this.type = 'clear_tags_status';
  }
}
