import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logoutWithConfirm } from '../../redux/actions/auth';
import {
  showCoachMarks,
  resetCoachMarks,
} from '../../redux/actions/coachmarks';
import {
  openSettings,
  openOrganisationSettings,
} from '../../redux/actions/settings';
import UserMenu from './UserMenu';
import { EmptyObject } from '../../shapes/app';

const mapStateToProps = (state: any): any => {
  return {
    name: state.auth.user.name,
    views: state.views.data,
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      logout: logoutWithConfirm,
      openSettings,
      openOrganisationSettings,
      showCoachMarks,
      resetCoachMarks,
    },
    dispatch
  );
};

const UserMenuContainer = connect<any, any, EmptyObject>(
  mapStateToProps,
  mapDispatchToProps
)(UserMenu) as any;

export default UserMenuContainer;
