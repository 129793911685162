import * as React from 'react';
import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import ContourMapsCrud from './ContourMapsCrud';
import { Button } from '../Button';
import ContourMapsApis from '../../api/contourMaps';
import ProjectsAPI from '../../api/projects';
import { appFormatDate } from '../../utils/date';
import { AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE } from '../../constants';
import { GenericObjectType } from '../../shapes/app';
import { LOG_IT } from '../../constants/env';
import TerrainMapsApis from '../../api/terrainMaps';
import ViewAPIs from '../../api/views';
import { Contour, ElevationInfo } from '../../api/contourMaps.types';
import { sortByDate, sortByRelativeDate } from '../../utils/helpers';
import DocumentationLink from '../DocumentationLink';
import { DOCUMENTATION_URL_LIST } from '../../constants/urls';
import { ContourMapsProps, ContourMapsState } from './index.types';
import { getAoiFromProjectData } from '../AoiData';

const { Title } = Typography;
const contourMapsApis = new ContourMapsApis();
const projectsApis = new ProjectsAPI();
const terrainMapsApis = new TerrainMapsApis();
const viewApis = new ViewAPIs();

class ContourMaps extends React.Component<ContourMapsProps, ContourMapsState> {
  public constructor(props: ContourMapsProps) {
    super(props);

    this.state = {
      contoursListData: [],
      tableLoading: true,
      unit: '',
      dtmsListData: [],
    };
  }

  public componentDidMount(): void {
    this.fetchDtms();
    this.fetchContours();
    this.fetchProject();
    this.fetchViews();
  }

  private fetchDtms = () => {
    const { match, showSnackbar } = this.props;
    const { projectId, aoiId } = match.params;

    terrainMapsApis.listDtms(projectId, aoiId).then((res) => {
      if (res.error) {
        showSnackbar({
          body: res.error,
          type: 'error',
        });
      }

      this.setState({
        dtmsListData: res.data,
      });
    });
  };

  private fetchContours = () => {
    const { match, showSnackbar } = this.props;
    const { projectId, aoiId } = match.params;

    contourMapsApis.listContours(projectId, aoiId).then((res) => {
      if (res.error) {
        showSnackbar({
          body: res.error,
          type: 'error',
        });
      }

      const sortedByCreatedAtDate = sortByRelativeDate(
        sortByDate(res.data, 'scheduledAt', true),
        'scheduledAt',
        'createdAt',
        true
      );

      this.setState({
        contoursListData: sortedByCreatedAtDate,
        tableLoading: false,
      });
    });
  };

  private fetchProject = () => {
    const { match } = this.props;
    const { projectId } = match.params;

    projectsApis
      .getProject(projectId)
      .then((res: any) => {
        if (res && res.epsgCode) {
          this.fetchUnit(res.epsgCode);
        }

        if (res && res.projectType) {
          this.setState({ projectType: res.projectType });
        }
      })
      .catch((e) => {
        if (LOG_IT) {
          console.error(e);
        }
      });
  };

  private fetchViews = () => {
    const { match, showSnackbar } = this.props;
    const { projectId, aoiId } = match.params;

    viewApis
      .getViews(projectId, aoiId)
      .then((res) => {
        this.setState({ views: res.data });
      })
      .catch((err) => {
        if (LOG_IT) {
          console.error(err);
        }

        this.setState({ views: undefined });

        showSnackbar({
          body: 'There was an error while fetching the views associated with the project.',
          type: 'error',
        });
      });
  };

  private getElevationInfo = (): ElevationInfo[] | undefined => {
    const { dtmsListData, views } = this.state;

    if (!dtmsListData || !views) {
      return undefined;
    }

    const finalizedDtms = dtmsListData.filter(
      (dtm) => dtm.finalized && dtm.viewId
    );
    const elevationViews = views.filter(
      (view) =>
        view.certifiedForMeasurement &&
        view.certifiedForDisplay &&
        view.demId &&
        view.subType === 'elevation'
    );

    const dtmElevationInfo = finalizedDtms.map((dtm) => {
      return {
        id: dtm.id,
        date: dtm.date,
        elevationSource: 'dtm',
        viewId: dtm.viewId,
        name: dtm.name,
      } as ElevationInfo;
    });

    const viewElevationInfo = elevationViews.map((view) => {
      return {
        id: view.demId,
        date: view.date,
        elevationSource: 'dem',
        viewId: view.id,
      } as ElevationInfo;
    });

    return [...dtmElevationInfo, ...viewElevationInfo];
  };

  private getUnitLabel = (unit: string): string => {
    switch (unit) {
      case 'meter':
        return 'meters';
      case 'foot':
        return 'feet';
      case 'usFT':
        return 'feet';
      default:
        return unit;
    }
  };

  private fetchUnit = (epsgCode: string): void => {
    projectsApis
      .getEPSGcodeById(epsgCode)
      .then((res) => {
        if (res && res.data) {
          const { data } = res;

          this.setState({
            unit: this.getUnitLabel(data.unit),
          });
        }
      })
      .catch((e) => {
        if (LOG_IT) {
          console.error(e);
        }
      });
  };

  private showProjectAdminLinks = (): boolean => {
    const { user, match } = this.props;
    const { projectId } = match.params;

    if (!user) {
      return false;
    }

    if (user.staff === true) {
      return true;
    }

    if (projectId) {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of user.project_roles || []) {
        if ((role as any).project_id === projectId) {
          return (role as any).role === 'project_admin';
        }
      }
    }

    return false;
  };

  private handleRowClick = (record: GenericObjectType) => {
    const { history, match } = this.props;
    const { projectId, aoiId } = match.params;

    if (!history) {
      return;
    }

    history.push(
      `/project/${projectId}/aoi/${aoiId}/ContourMaps/edit/${record.id}`
    );
  };

  public allowCreation = (): { allowed: boolean; message?: string } => {
    const { projectData, match } = this.props;
    const { aoiId } = match.params;
    const aoi = getAoiFromProjectData(projectData, aoiId);

    if (!projectData?.epsgCode && !aoi?.boundaryId) {
      return {
        allowed: false,
        message:
          'Add EPSG code and a boundary to your project to enable this feature.',
      };
    }

    if (!projectData?.epsgCode && !aoi?.boundaryId) {
      return {
        allowed: false,
        message:
          'Add EPSG code and a boundary to your project to enable this feature.',
      };
    }

    if (!projectData?.epsgCode) {
      return {
        allowed: false,
        message: 'Add EPSG code to your project to enable this feature.',
      };
    }

    if (!aoi?.boundaryId) {
      return {
        allowed: false,
        message: 'Add a boundary to enable this feature.',
      };
    }

    return { allowed: true };
  };

  public render(): React.ReactNode {
    const { actionType, match } = this.props;
    const { contoursListData, tableLoading, unit, dtmsListData, projectType } =
      this.state;
    const { projectId, aoiId } = match.params;
    const { allowed, message } = this.allowCreation();

    const tableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (value: string) => value,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'dtmId',
        render: (value: string, contourData: Contour | null) => {
          if (dtmsListData && contourData) {
            const { elevationInfo } = contourData;

            return appFormatDate(elevationInfo.date);
          }

          return 'Loading...';
        },
      },
      {
        title: 'Source',
        key: 'source',
        render: (value: string, contourData: Contour) => {
          const { elevationInfo } = contourData;

          return elevationInfo.elevationSource === 'dem'
            ? 'Elevation'
            : 'Terrain';
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value: string, list: any) => {
          if (list.finalized) {
            return 'PUBLISHED';
          }

          return value ? value.toUpperCase() : null;
        },
      },
      {
        title: `Interval (${unit})`,
        dataIndex: 'parameters',
        key: 'parameters',
        render: (value: any) => {
          return value ? value.contourInterval : null;
        },
      },
    ];

    if (actionType === 'new' || actionType === 'edit') {
      return (
        <ContourMapsCrud
          unit={unit}
          {...this.props}
          elevationInfo={this.getElevationInfo()}
          projectType={projectType}
        />
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <div className={styles.titleInnerWrapper}>
            <Title level={2}>Contour Maps</Title>
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.contourMaps}
              toolTipPosition="right"
            />
          </div>

          {this.showProjectAdminLinks() ? (
            <Link
              className={styles.createItemLink}
              to={`/project/${projectId}/aoi/${aoiId}/ContourMaps/new`}
              title={allowed ? '' : message}
            >
              <Button
                transparent
                text="+ NEW CONTOUR MAP"
                disabled={!allowed}
              />
            </Link>
          ) : null}
        </div>

        <Table
          locale={{ emptyText: 'No Contour generated yet.' }}
          onRow={(record: GenericObjectType) => {
            return {
              onClick: () => {
                this.handleRowClick(record);
              },
            };
          }}
          rowKey={(contoursListItem: any) => contoursListItem.id}
          loading={tableLoading}
          columns={tableColumns}
          dataSource={contoursListData}
          pagination={{
            defaultPageSize: AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE,
          }}
        />
      </div>
    );
  }
}

export default ContourMaps;
