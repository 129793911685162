import { UpOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';
import style from './ViewFooter.module.scss';
import { viewUrl } from '../../routes/urls';
import { isCertifiedView, isEmpty } from '../../utils/functs';
import { IMenu, ViewFooterProps, IState } from './index.types';

const MAX_MENU_ITEM = 5;

class ViewFooter extends React.Component<ViewFooterProps, IState> {
  public constructor(props: ViewFooterProps) {
    super(props);

    this.state = {
      isPopupOpen: false,
    };
  }

  private renderPopup(): JSX.Element | null {
    const { isPopupOpen } = this.state;

    if (!isPopupOpen) {
      return null;
    }

    return (
      <Drawer
        className={style.popup}
        visible
        placement="bottom"
        height="auto"
        onClose={() => this.handleClick(false)}
      >
        {this.renderMenuItem()}
      </Drawer>
    );
  }

  private groupMenuItemsByDate = (menus: IMenu[]) => {
    return menus.reduce((acc, curr) => {
      if (acc[curr.date]) {
        acc[curr.date] = [...acc[curr.date], curr];
      } else {
        acc[curr.date] = [];
        acc[curr.date].push(curr);
      }

      return acc;
    }, {});
  };

  private renderMenuItem(): JSX.Element[] {
    const { menus, compareViewId, viewId } = this.props;

    const certifiedViews = menus.filter((menu) => {
      return isCertifiedView(menu);
    });

    const viewsGroupedByDate = this.groupMenuItemsByDate(certifiedViews);

    return Object.keys(viewsGroupedByDate)
      .map((key) => viewsGroupedByDate[key][0])
      .map((menu) => (
        <Link
          key={`${menu.projectId}-${menu.aoiId}-${menu.viewId}`}
          to={viewUrl(menu.projectId, menu.aoiId, menu.viewId, {
            compareViewId,
          })}
        >
          <span
            className={classnames(
              style.menuItem,
              menu.viewId === viewId ? style.active : style.inactive
            )}
          >
            {menu.date}
          </span>
        </Link>
      ));
  }

  private getTrimmedMenuList = () => {
    const { menus } = this.props;

    const menusList = menus.filter((menu) => {
      return isCertifiedView(menu);
    });

    const viewsGroupedByDate = this.groupMenuItemsByDate(menusList);

    const menusListByDate = Object.keys(viewsGroupedByDate).map(
      (key) => viewsGroupedByDate[key][0]
    );

    // needs cleanup here
    const activeMenuIndex = menusList.findIndex((menu) => menu.isActive);

    if (menusListByDate.length > 5) {
      let trimmedMenu = menusListByDate.slice(0, MAX_MENU_ITEM);
      const activeIndex = trimmedMenu.findIndex((menu) => menu.isActive);

      if (this.isInTrimmedMenuList(activeIndex)) {
        return trimmedMenu;
      }

      // removes object from trimmedMenu whose date is same as menusList[menuActiveIndex] object
      // and pushed menusList[menuActiveIndex] object in final trimmedMenu List
      if (activeMenuIndex >= MAX_MENU_ITEM) {
        trimmedMenu = [
          ...trimmedMenu
            .slice(0, MAX_MENU_ITEM - 1)
            .filter((menu) => menu.date !== menusList[activeMenuIndex].date),
          menusList[activeMenuIndex],
        ];
      }

      return trimmedMenu;
    }

    // array of menus grouped by dates
    // map this object to array with first item selected for each date
    // if active menu not in this list, then replace item in this list whose date is same as activeMenu with the activeMenu

    const activeMenu = menusList[activeMenuIndex];
    const activeMenuNotInList = menusListByDate.find(
      (menu) => menu.viewId !== activeMenu.viewId
    );

    const activeMenuNotInListIndex = menusListByDate.findIndex(
      (menu) => menu.date === activeMenu.date
    );

    if (!isEmpty(activeMenuNotInList)) {
      return [
        ...menusListByDate.slice(0, activeMenuNotInListIndex),
        activeMenu,
        ...menusListByDate.slice(activeMenuNotInListIndex + 1),
      ];
    }

    return menusListByDate;
  };

  private renderTrimmedMenuItem(): JSX.Element[] {
    const { viewId, shareId, compareViewId } = this.props;

    if (shareId) {
      // removing link for a share view
      return this.getTrimmedMenuList().map((menu: any) => (
        <span
          key={`${menu.projectId}-${menu.aoiId}-${menu.viewId}`}
          className={classnames(
            style.menuItem,
            menu.viewId === viewId ? style.active : style.inactive
          )}
        >
          {menu.date}
        </span>
      ));
    }

    return this.getTrimmedMenuList().map((menu: any) => (
      <Link
        key={`${menu.projectId}-${menu.aoiId}-${menu.viewId}`}
        to={viewUrl(menu.projectId, menu.aoiId, menu.viewId, {
          compareViewId,
        })}
      >
        <span
          className={classnames(
            style.menuItem,
            menu.viewId === viewId ? style.active : style.inactive
          )}
        >
          {menu.date}
        </span>
      </Link>
    ));
  }

  @autobind
  private handleClick(status: boolean): void {
    this.setState({
      isPopupOpen: status,
    });
  }

  private isInTrimmedMenuList = (activeIndex: number) => {
    return activeIndex !== -1 && activeIndex < MAX_MENU_ITEM;
  };

  public render(): React.ReactNode {
    const { className, shareId, children } = this.props;

    if (shareId) {
      return (
        <div className={classnames(style.container, className)}>
          <div className={style.left}>{this.renderTrimmedMenuItem()}</div>
          <div>{children}</div>
        </div>
      );
    }

    return (
      <div className={classnames(style.container, className)}>
        <div className={style.left}>
          {this.renderTrimmedMenuItem()}
          <UpOutlined
            className={style.icon}
            onClick={() => this.handleClick(true)}
          />
          {this.renderPopup()}
        </div>
        <div className={style.controls}>
          <a
            href="https://vimana.vimanalabs.com/docs/"
            title="Documentation"
            target="_blank"
            rel="noopener noreferrer"
            className={style.docIcon}
          >
            <i className="fa fa-book" />
          </a>
          <div>{children}</div>
        </div>
      </div>
    );
  }
}

export default ViewFooter;
