import ApiBase from './ApiBase';

export default class AoiAPIs extends ApiBase {
  public fetchMissions(projectId: string, aoiId: string): Promise<any> {
    return this.get(`/v1/projects/${projectId}/aois/${aoiId}/missions`);
  }

  public fetchFloorplans(projectId: string, aoiId: string): Promise<any> {
    return this.get(`/v1/projects/${projectId}/aois/${aoiId}/floorplans`);
  }

  public fetchCADs(projectId: string, aoiId: string): Promise<any> {
    return this.get(`/v1/projects/${projectId}/aois/${aoiId}/cadDiagrams`);
  }
}
