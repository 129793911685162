/* eslint-disable react/no-unused-state */
import * as React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Feature, Map, MapBrowserEvent, Overlay, View } from 'ol';
import OverlayPositioning from 'ol/OverlayPositioning';
import { Draw, Modify } from 'ol/interaction';
import { fromLonLat } from 'ol/proj';
import GeoImageLayer from 'ol-ext/layer/GeoImage';
import GeoImage from 'ol-ext/source/GeoImage';
import { Image as ImageLayer, Vector as VectorLayer } from 'ol/layer';
import { ImageStatic, Vector as VectorSource } from 'ol/source';
import { getCenter } from 'ol/extent';
import Projection from 'ol/proj/Projection';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import { GeoJSON } from 'ol/format';
import { asArray as colorAsArray } from 'ol/color';
import {
  Fill,
  Stroke,
  Style,
  Text as TextStyle,
  Circle as CircleStyle,
} from 'ol/style';

import SkeletonLoader from '../../../SkeletonLoader';
import { getRendererLayersFromMapboxStyle } from '../../../View/MapView/OpenLayersRenderer/utils';
import { DEFAULT_LAYER_Z_INDEX } from '../../../../constants';
import { APP_PRIMARY_COLOR } from '../../../../constants/colors';

import {
  LocalDrawingMapPropsType,
  LocalDrawingMapStateType,
} from './index.types';
import style from './index.module.scss';

export default class LocalDrawingMap extends React.PureComponent<
  LocalDrawingMapPropsType,
  LocalDrawingMapStateType
> {
  private olMapReference = React.createRef<HTMLDivElement>();

  private helpToolTipElement: HTMLElement | null = null;

  private helpToolTipOverlay: Overlay | null = null;

  constructor(props: LocalDrawingMapPropsType) {
    super(props);

    this.state = {
      loading: false,
      interactions: {},
    };
  }

  public componentDidMount() {
    const mapEl = this.olMapReference.current;

    if (mapEl == null) {
      return;
    }

    const olMap = new Map({
      target: mapEl,
    });

    this.setState({ olMap, loading: true }, async () => {
      await this.initView(this.props);
      this.setState({ loading: false });
    });
  }

  public async UNSAFE_componentWillReceiveProps(
    newProps: LocalDrawingMapPropsType
  ) {
    const {
      designDataUrl: newDesignDataUrl,
      drawingSettings: newDrawingSettings,
      viewDisplayType: newViewDisplayType,
      overlayView: newOverlayView,
      alignParameters: newAlignParameters,
    } = newProps;
    const {
      designDataUrl: oldDesignDataUrl,
      drawingSettings: oldDrawingSettings,
      viewDisplayType: oldViewDisplayType,
      overlayView: oldOverlayView,
      alignParameters: oldAlignParameters,
    } = this.props;

    if (
      newDesignDataUrl !== oldDesignDataUrl ||
      !_.isEqual(newOverlayView, oldOverlayView) ||
      newViewDisplayType !== oldViewDisplayType
    ) {
      this.setState({ loading: true }, async () => {
        await this.initView(newProps);
        await this.initDrawing(newProps);
        this.setState({ loading: false });
      });
    } else {
      // handle propery change, when view display has not changed

      if (!_.isEqual(newDrawingSettings, oldDrawingSettings)) {
        this.initDrawing(newProps);
      }

      if (!_.isEqual(newAlignParameters, oldAlignParameters)) {
        this.handleAlignParametersUpdate(newProps);
      }
    }
  }

  // Setup Functions ------------------------------------------------------------------

  private createHelpToolTip = (olMap?: Map) => {
    if (!olMap) {
      return;
    }

    if (this.helpToolTipElement) {
      // eslint-disable-next-line no-unused-expressions
      this.helpToolTipElement.parentNode?.removeChild(this.helpToolTipElement);
    }

    this.helpToolTipElement = document.createElement('div');
    this.helpToolTipElement.className = classnames(style.olTooltip, 'hidden');

    this.helpToolTipOverlay = new Overlay({
      element: this.helpToolTipElement,
      offset: [20, 0],
      positioning: OverlayPositioning.CENTER_LEFT,
      className: style.olTooltipContainer,
    });

    olMap.addOverlay(this.helpToolTipOverlay);
  };

  private initView = async (props: LocalDrawingMapPropsType) => {
    const { viewDisplayType } = props;

    switch (viewDisplayType) {
      case 'aerial':
        return await this.initAerialView(props);
      case 'image':
        return await this.initDesignFileView(props);
      case 'combined':
        return await this.initDesignFileAndAerialView(props);
      default:
        break;
    }
  };

  private initAerialView = async (props: LocalDrawingMapPropsType) => {
    const { overlayView } = props;
    const { olMap, displayLayers, displayViews } = this.state;

    if (!olMap) {
      console.error('Map has not been initialzed');

      return;
    }

    if (!overlayView.centerLatitude || !overlayView.centerLongitude) {
      console.error('View is missing center coordinates.');

      return;
    }

    if (!overlayView.sourceToken) {
      console.error('View is missing access token.');

      return;
    }

    if (displayLayers) {
      const { aerial, image, combined } = displayLayers;
      const layers = [aerial, image, combined];

      layers.forEach((l) => l && olMap.removeLayer(l));
    }

    const center = fromLonLat([
      overlayView.centerLongitude,
      overlayView.centerLatitude,
    ]);

    const view =
      displayViews?.aerial ||
      new View({
        center,
        zoom: overlayView.zoomDefault,
      });

    olMap.setView(view);
    this.setState({
      olView: view,
      displayViews: { ...displayViews, aerial: view },
    });

    const layers = await getRendererLayersFromMapboxStyle(
      overlayView.sourceUrl,
      overlayView.sourceToken
    );
    const layer = layers[0]._layer;

    if (layers.length === 0 || !layer) {
      console.error('Could not get layers from Mapbox style');

      return;
    }

    olMap.addLayer(layer);
    this.setState({ displayLayers: { ...displayLayers, aerial: layer } });
  };

  private initDesignFileView = async (props: LocalDrawingMapPropsType) => {
    const { designDataUrl, imageParameters } = props;
    const { olMap, displayLayers, displayViews } = this.state;

    if (!olMap) {
      console.error('Map has not been initialized');

      return;
    }

    if (displayLayers) {
      const { aerial, image, combined } = displayLayers;
      const layers = [aerial, image, combined];

      layers.forEach((l) => l && olMap.removeLayer(l));
    }

    return new Promise<void>((resolve) => {
      const { height, width } = imageParameters;

      const extent = [0, -height, width, 0];
      const center = getCenter(extent);

      const projection = new Projection({
        code: 'ZOOMIFY',
        units: 'pixels',
        extent,
      });

      const oldView = displayViews?.image;

      const view = new View({
        projection,
        center: oldView?.getCenter() || center,
        zoom: oldView?.getZoom() || 2,
      });

      const source = new ImageStatic({
        projection,
        url: designDataUrl,
        imageExtent: extent,
      });
      const imageLayer = new ImageLayer({ source });

      olMap.setView(view);
      olMap.addLayer(imageLayer);
      this.setState({
        displayLayers: { ...displayLayers, image: imageLayer },
        displayViews: { ...displayViews, image: view },
        olView: view,
      });

      return resolve();
    });
  };

  private initDesignFileAndAerialView = async (
    props: LocalDrawingMapPropsType
  ) => {
    const { overlayView, designDataUrl, alignParameters } = props;
    const { olMap, displayLayers, displayViews } = this.state;

    if (!alignParameters) {
      console.error(
        'Align parameters must be specified to create combined view'
      );

      return;
    }

    if (!overlayView.centerLatitude || !overlayView.centerLongitude) {
      console.error('View is missing center coordinates.');

      return;
    }

    if (!overlayView.sourceToken) {
      console.error('View is missing access token.');

      return;
    }

    if (!olMap) {
      console.error('Map has not been initialized');

      return;
    }

    if (displayLayers) {
      const { aerial, image, combined } = displayLayers;
      const layers = [aerial, image, combined];

      layers.forEach((l) => l && olMap.removeLayer(l));
    }

    const center = fromLonLat([
      overlayView.centerLongitude,
      overlayView.centerLatitude,
    ]);

    const view =
      displayViews?.combined ||
      new View({
        center,
        zoom: overlayView.zoomDefault,
      });

    olMap.setView(view);
    this.setState({
      olView: view,
      displayViews: { ...displayViews, combined: view },
    });

    const layers = await getRendererLayersFromMapboxStyle(
      overlayView.sourceUrl,
      overlayView.sourceToken
    );
    const layer = layers[0]._layer;

    if (layers.length === 0 || !layer) {
      console.error('Could not get layers from Mapbox style');

      return;
    }

    olMap.addLayer(layer);

    return new Promise<void>((resolve) => {
      const { center, scale, rotation } = alignParameters;

      const source = new GeoImage({
        url: designDataUrl,
        imageCenter: [center.x, center.y],
        imageScale: [scale + 0.00000001, scale + 0.00000001],
        imageRotate: rotation,
        projection: 'EPSG:3857',
      });

      const imageLayer = new GeoImageLayer({
        source,
        opacity: 0.6, // TODO: implement opacity control
        zIndex: DEFAULT_LAYER_Z_INDEX + 50,
      });

      olMap.addLayer(imageLayer);
      this.setState({
        displayLayers: { aerial: layer, combined: imageLayer },
      });
      resolve();
    });
  };

  private initDrawing = (props: LocalDrawingMapPropsType) => {
    const { drawingSettings, viewDisplayType } = props;
    const { olMap, interactions, drawingLayers } = this.state;

    if (!olMap) {
      console.error('Map has not been initialized');

      return;
    }

    this.createHelpToolTip(olMap);

    // removing all existing interactions
    if (interactions.draw || interactions.modify) {
      if (interactions.draw) olMap.removeInteraction(interactions.draw);

      if (interactions.modify) olMap.removeInteraction(interactions.modify);
    }

    if (drawingLayers) {
      const { aerial, image, combined } = drawingLayers;
      const layers = [aerial, image, combined];

      layers.forEach((l) => l && olMap.removeLayer(l));
    }

    if (!drawingSettings) {
      // exit initialization after clean up
      return;
    }

    const vectorSource =
      (drawingLayers?.[viewDisplayType] as VectorLayer)?.getSource() ||
      new VectorSource();
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      zIndex: DEFAULT_LAYER_Z_INDEX + 100,
      style: this.annotationStyleFunction,
    });
    const { geometryType } = drawingSettings;

    const draw = new Draw({
      source: vectorSource,
      type: geometryType,
    });
    const modify = new Modify({
      source: vectorSource,
    });

    switch (drawingSettings.type) {
      case 'create_and_edit': {
        olMap.addInteraction(draw);
        olMap.addInteraction(modify);
        break;
      }

      case 'edit': {
        olMap.addInteraction(modify);
        break;
      }

      default:
        break;
    }

    olMap.addLayer(vectorLayer);
    olMap.on('pointermove', this.onPointerMove);
    draw.on('drawend', this.handleFeatureCreation);
    modify.on('modifyend', this.handleFeatureEdit);

    const layers = { ...drawingLayers };

    layers[viewDisplayType] = vectorLayer;

    this.setState({
      drawingLayers: { ...layers },
      interactions: { draw, modify },
    });
  };

  private getColorWithOpacity(color: string, opacity: number) {
    let opaqueColor = colorAsArray(color);

    opaqueColor = opaqueColor.slice();
    opaqueColor[3] = opacity;

    return opaqueColor;
  }

  private annotationStyleFunction = (feature: Feature) => {
    return new Style({
      text: this.annotationTextStyleFunction(feature),
      stroke: new Stroke({
        color: APP_PRIMARY_COLOR,
        width: 4,
      }),
      fill: new Fill({
        color: this.getColorWithOpacity(APP_PRIMARY_COLOR, 0.2),
      }),
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({
          color: APP_PRIMARY_COLOR,
        }),
      }),
    });
  };

  private annotationTextStyleFunction = (feature: Feature): TextStyle => {
    return new TextStyle({
      text: feature.getProperties()?.id || '',
      textBaseline: 'top',
      offsetY: 8,
      padding: [0, 0, 0, 10],
      font: 'bold 12px sans-serif',
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
    });
  };

  // Event Functions -------------------------------------------------------------------

  private onPointerMove = (e: MapBrowserEvent) => {
    const { olMap, interactions } = this.state;

    if (!this.helpToolTipElement || !this.helpToolTipOverlay || !olMap) {
      return;
    }

    if (!interactions.draw && !interactions.modify) {
      return;
    }

    const { draw } = interactions;

    this.helpToolTipOverlay.setPosition(e.coordinate);

    if (
      olMap
        .getInteractions()
        .getArray()
        .indexOf(draw as Draw) > -1
    ) {
      const helpMessage = 'Click to mark a point.';

      if (helpMessage) {
        this.helpToolTipElement.innerHTML = helpMessage;
        this.helpToolTipElement.classList.remove('hidden');
      } else {
        this.helpToolTipElement.classList.add('hidden');
      }

      return;
    }

    const helpMessage = 'Click & drag to edit a point.';

    this.helpToolTipElement.innerHTML = helpMessage;
    this.helpToolTipElement.classList.remove('hidden');
  };

  private handleAlignParametersUpdate = (props: LocalDrawingMapPropsType) => {
    const { viewDisplayType, alignParameters } = props;
    const { displayLayers } = this.state;

    if (viewDisplayType !== 'combined') {
      console.warn(
        'Align parameters will only be updated for the combined view'
      );

      return;
    }

    if (!alignParameters) {
      console.error('Align parameters must be specifed for the combined view');

      return;
    }

    if (!displayLayers?.combined?.getSource()) {
      console.error('Could not find the combined layer, to update!');

      return;
    }

    const { combined } = displayLayers;
    const { center, scale, rotation, opacity } = alignParameters;

    const source = combined.getSource() as any;

    source.setCenter([center.x, center.y]);
    source.setScale([scale + 0.00000001, scale + 0.00000001]);
    source.setRotation(rotation);
    combined.setOpacity(opacity);
  };

  private handleFeatureCreation = (e: DrawEvent) => {
    const { interactions, olMap } = this.state;
    const { onFeatureCreate, drawingSettings } = this.props;
    const { feature } = e;

    if (!olMap) {
      console.error('Could not get access to Map object.');

      return;
    }

    if (interactions.draw) {
      const { draw } = interactions;

      draw.finishDrawing();
      olMap.removeInteraction(draw);
    }

    if (drawingSettings?.name) {
      feature.setProperties({ id: drawingSettings.name });
    }

    const view = olMap.getView();
    const projection = view.getProjection();
    const geoJson = new GeoJSON({
      dataProjection: projection,
      featureProjection: projection,
    });

    onFeatureCreate(geoJson.writeFeatureObject(feature));
  };

  private handleFeatureEdit = (e: ModifyEvent) => {
    const { olMap } = this.state;
    const { onFeatureEdit } = this.props;

    if (e.features.getLength() === 0) {
      console.error('No features have been edited');

      return;
    }

    const features = e.features.getArray();

    if (!olMap) {
      console.error('Could not get access to Map object.');

      return;
    }

    const view = olMap.getView();
    const projection = view.getProjection();
    const geoJson = new GeoJSON({
      dataProjection: projection,
      featureProjection: projection,
    });

    onFeatureEdit(geoJson.writeFeaturesObject(features));
  };

  // Render Functions ------------------------------------------------------------------

  public renderCanvas() {
    return <canvas id="img-canvas" />;
  }

  public render() {
    const { loading } = this.state;

    return (
      <>
        {loading ? (
          <div className={style.container}>
            <SkeletonLoader />
          </div>
        ) : (
          <></>
        )}
        <div
          className={style.container}
          ref={this.olMapReference}
          style={{ visibility: loading ? 'hidden' : 'visible' }}
        />
        {this.renderCanvas()}
      </>
    );
  }
}
