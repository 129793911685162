import { hexToRgb, setStyle } from '../../utils/functs';
import { setBodyStyle } from '../../utils/window';
import { BrandingModel } from '../../shapes/branding';
import { APP_BG_COLOR } from '../../constants/colors';

export const setBrandingStyles = (brandingData: BrandingModel | null) => {
  const primaryColorRgb = hexToRgb(brandingData?.primaryColor);
  const textColorRgb = hexToRgb(brandingData?.textColor);

  setStyle(document.documentElement, {
    '--background': brandingData?.backgroundColor,
    '--forground': brandingData?.foregroundColor,
    '--input-password-color': 'unset',
    '--text-color': brandingData?.textColor,
    '--text-hover-color': textColorRgb
      ? `${textColorRgb.r}, ${textColorRgb.g}, ${textColorRgb.b}`
      : undefined,
    '--primary-color': brandingData?.primaryColor,
    '--primary-hover-color': primaryColorRgb
      ? `${primaryColorRgb.r}, ${primaryColorRgb.g}, ${primaryColorRgb.b}`
      : undefined,
  });

  setBodyStyle('background', brandingData?.backgroundColor ?? '');
  setBodyStyle('color', brandingData?.textColor ?? '');
};

export const resetBrandingStyles = () => {
  setBodyStyle('background-color', APP_BG_COLOR);
};
