import * as mapboxgl from 'mapbox-gl';

let popupList: any[] = [];

const POPUP = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
  anchor: 'left',
  className: 'noevent',
});

export const newElevationPopup = (
  elevation: number,
  viewUnits: 'm' | 'ft',
  location: any,
  map: any
) => {
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    anchor: 'left',
    className: 'noevent',
  });

  popup.setLngLat(location);
  popup.addTo(map);
  popup.setHTML(`${elevation} ${viewUnits}`);

  popupList.push(popup);

  return popup;
};

export const clearAllPopups = (): void => {
  popupList.map((p) => p.remove());
  popupList = [];
};

export default POPUP;
