import ProjectsAPI from '../../api/projects';

import {
  FETCH_PROJECTS,
  SET_PROJECTS_FILTER,
  CLEAR_PROJECTS_FILTER,
} from '../actions';
import { Project } from '../../api/projects.types';

const api = new ProjectsAPI();

export function setProjectFilter(filter: any): {} {
  return {
    type: SET_PROJECTS_FILTER,
    payload: filter,
  };
}

export function clearProjectFilter(): {} {
  return {
    type: CLEAR_PROJECTS_FILTER,
  };
}

export function fetchProjects(): {} {
  return {
    type: FETCH_PROJECTS,
    payload: api.getProjects(),
  };
}

export function clearLastCreated(): {} {
  return {
    type: 'CREATE_PROJECT_INIT',
  };
}

export function createProject(projectCreateReq: any): {} {
  return {
    type: 'CREATE_PROJECT',
    payload: api.createProject(projectCreateReq),
  };
}

export function createAoi(
  projectId: string,
  name: string,
  boundaryFileContents: any
): {} {
  return {
    type: 'CREATE_AOI',
    payload: api.createAoi(projectId, name, boundaryFileContents),
  };
}

export const fetchProject =
  (projectId: string): any =>
  (dispatch: any) => {
    api
      .getProject(projectId)
      .then((res) => {
        dispatch({
          type: 'FETCH_PROJECT_FULFILLED',
          payload: res,
        });
      })
      .catch(() => {
        return dispatch({
          type: 'FETCH_PROJECT_REJECTED',
        });
      });
  };

export function clearProjects(): {} {
  return {
    type: 'CLEAR_PROJECTS',
  };
}

export function updateProject(projectId: string, projectUpdateReq: any): {} {
  return {
    type: 'UPDATE_PROJECT',
    payload: api.updateProject(projectId, projectUpdateReq),
  };
}

export function updateAoi(
  projectId: string,
  aoiId: string,
  aoiUpdateReq: any
): {} {
  return {
    type: 'UPDATE_AOI',
    payload: api.updateAoi(projectId, aoiId, aoiUpdateReq),
  };
}

export function fetchProjectBoundary(projectId: string): {} {
  return {
    type: 'FETCH_PROJECT_BOUNDARY',
    payload: api.fetchProjectBoundary(projectId),
  };
}

export function fetchAoiBoundary(projectId: string, aoiId: string): {} {
  return {
    type: 'FETCH_AOI_BOUNDARY',
    payload: api.fetchAoiBoundary(projectId, aoiId),
  };
}

export function setScrollPosition(position: string): {} {
  return {
    type: 'UPDATE_SCROLL_POSITION',
    payload: position,
  };
}

export const updateProjectManually = (project: Project) => {
  return {
    type: 'UPDATE_PROJECT_MANUALLY',
    payload: project,
  };
};
