import * as React from 'react';
import { Modal, Typography } from 'antd';
import { Redirect } from 'react-router-dom';
import BoundaryEditor from './BoundaryEditor';
import style from './CreateAOI.module.scss';
import { Button } from '../Button';
import { convertArea } from '../../utils/helpers';
import { isArray, undefinedOrNull } from '../../utils/functs';
import { BASE_CAPI_URL, DOCUMENTATION_URL_LIST } from '../../constants/urls';
import { aoiListingPageUrl } from '../../routes/urls';
import AoiV2Apis from '../../api/aoiV2';
import { CreateAOIPropsTypes, CreateAOIStateTypes } from './index.types';
import { log } from '../../utils/log';
import { GenericObjectType } from '../../shapes/app';
import SkeletonLoader from '../SkeletonLoader';
import DocumentationLink from '../DocumentationLink';

const { Title } = Typography;
const aoiV2Apis = new AoiV2Apis();

class CreateAOI extends React.Component<
  CreateAOIPropsTypes,
  CreateAOIStateTypes
> {
  public constructor(props: CreateAOIPropsTypes) {
    super(props);
    this.state = {
      name: '',
      boundaryFileContents: null,
      aoiId: null,
      confirmation: false,
      aoiArea: 0,
      unit: '',
      showArea: false,
      aoisDataList: null,
      aoisBoundaryDataList: null,
    };
  }

  public componentDidMount(): void {
    this.fetchAois();
  }

  public UNSAFE_componentWillReceiveProps({
    aoi: nextAoi,
  }: CreateAOIPropsTypes): void {
    const { aoi } = this.props;

    if (nextAoi !== aoi) {
      this.setState({ aoiId: nextAoi.id });
    }
  }

  private fetchAois = () => {
    const { projectId } = this.props;

    aoiV2Apis.listAois(projectId).then((res) => {
      const { error: apiError, data: apiData } = res;

      if (apiError) {
        log.error(apiError, 'CreateAOI.fetchAois');

        return;
      }

      this.setState(
        {
          aoisDataList: apiData,
        },
        this.fetchAoiBoundary
      );
    });
  };

  private fetchAoiBoundary = async () => {
    const { projectId } = this.props;
    const { aoisDataList } = this.state;

    const promisifiedResult: Promise<GenericObjectType | null>[] = [];
    const results: GenericObjectType[] = [];

    if (!aoisDataList) {
      return;
    }

    aoisDataList
      .filter((a) => a.defaultAOI === false)
      .map((a) => {
        promisifiedResult.push(
          new Promise((resolve) => {
            aoiV2Apis.getAoiBoundary(projectId, a.id).then((res) => {
              const { error: apiError, data: apiData } = res;

              if (apiError) {
                log.error(apiError, 'CreateAOI.fetchAoiBoundary');

                return resolve(null);
              }

              results.push(apiData);

              return resolve(apiData);
            });
          })
        );

        return promisifiedResult;
      });

    await Promise.all(promisifiedResult);

    this.setState({
      aoisBoundaryDataList: results || [],
    });
  };

  private boundaryChanged = (boundary: string | null) => {
    this.setState({ boundaryFileContents: boundary });
  };

  private nameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: event.target.value });
  };

  private canSubmit = () => {
    const { loading } = this.props;
    const { name, boundaryFileContents } = this.state;

    return name && boundaryFileContents && !loading;
  };

  private handleModal = () => {
    const { confirmation } = this.state;

    this.setState({ confirmation: !confirmation }, () => {
      const { confirmation } = this.state;
      const { project } = this.props;
      const { epsgCode } = project || '';

      if (confirmation && !undefinedOrNull(epsgCode) && epsgCode !== '') {
        this.fetchEpsgUnit(epsgCode);
      } else {
        this.setState({
          showArea: true,
        });
      }
    });
  };

  private submit = () => {
    const { loading, createAoi, projectId } = this.props;
    const { name, boundaryFileContents } = this.state;

    if (loading || !boundaryFileContents) {
      return;
    }

    createAoi(projectId, name, boundaryFileContents);
  };

  private handleAoiArea = (aoiArea: number) => {
    this.setState({
      aoiArea,
    });
  };

  private fetchEpsgUnit(id: any): void | undefined {
    fetch(`${BASE_CAPI_URL}/v1/coords/${id}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((epsgCode) => {
        this.setState({
          unit: epsgCode.unit,
          showArea: true,
        });
      })
      .catch((err) => console.error(err));
  }

  public render(): React.ReactNode {
    const { projectId, project, loading } = this.props;
    const { epsgCode } = project || '';
    const {
      aoiId,
      confirmation,
      name,
      aoiArea,
      unit,
      showArea,
      aoisBoundaryDataList,
    } = this.state;

    if (aoiId) {
      return <Redirect to={aoiListingPageUrl(projectId, aoiId)} />;
    }

    return (
      <div className={style.container}>
        <Modal
          title="Confirm AOI Creation"
          centered
          footer={null}
          visible={confirmation}
          onCancel={this.handleModal}
          destroyOnClose
        >
          <p className={style.headertext}>
            Are you sure that you want to create an AOI?
          </p>
          <div className={style.btndeleteDiv}>
            <table>
              <tr>
                <td>Name </td>
                <td className={style.colonPadLeft}>:</td>
                <td className={style.colonPadLeft}>{name}</td>
              </tr>
              {aoiArea > 0 && showArea ? (
                <tr>
                  <td>Area </td>
                  <td className={style.colonPadLeft}>:</td>
                  <td className={style.colonPadLeft}>
                    {epsgCode !== '' && (unit === 'usFT' || unit === 'foot')
                      ? convertArea(aoiArea, 'feet')
                      : convertArea(aoiArea, 'meter')}
                    &nbsp;
                    <span>(</span>
                    {convertArea(aoiArea, 'acre')}
                    <span>)</span>
                  </td>
                </tr>
              ) : null}
            </table>
            <Button
              className={style.btndelete}
              onClick={this.submit}
              loading={loading}
              loadingText="Creating Area of Interest..."
              text="Create"
            />
            <Button
              className={style.btndelete}
              onClick={this.handleModal}
              text="Cancel"
            />
          </div>
        </Modal>
        <div className={style.bodycont}>
          <div className={style.formcont}>
            <div className={style.titleWrapper}>
              <Title level={3} className={style.title}>
                Create Area Of Interest
              </Title>
              <DocumentationLink
                href={DOCUMENTATION_URL_LIST.createAOI}
                toolTipPosition="right"
                className={style.titleDocumentation}
              />
            </div>
            <label className={style.name}>Name</label>
            <input
              type="text"
              placeholder="Enter area of interest name"
              value={name}
              onChange={this.nameChanged}
            />
            {aoisBoundaryDataList && isArray(aoisBoundaryDataList) ? (
              <React.Fragment>
                <BoundaryEditor
                  onBoundaryChange={this.boundaryChanged}
                  computePolygonArea={this.handleAoiArea}
                  hideUpload={false}
                  projectId={projectId}
                  project={project}
                  createProject={false}
                  polygonsDataList={aoisBoundaryDataList}
                  currentParent="CreateAOI"
                />
                <div className={style.bottombtn}>
                  <Button
                    disabled={!this.canSubmit()}
                    onClick={this.handleModal}
                    text="Done"
                    loading={loading}
                    loadingText="Creating Area of Interest..."
                  />
                </div>
              </React.Fragment>
            ) : (
              <div className={style.boundaryLoadingWrapper}>
                <SkeletonLoader size={1} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CreateAOI;
