import * as React from 'react';
import { Tabs, Typography, Tooltip } from 'antd';
import TerrainMapsTabsLayout1 from '../TerrainMapsTabsLayout1';
import TerrainMapsTabsLayout2 from '../TerrainMapsTabsLayout2';
import TerrainMapsTabsLayout3 from '../TerrainMapsTabsLayout3';
import { TerrainMapsApisReturnPromiseTypes } from '../../../shapes/terrainMaps';
import TerrainMapsApis from '../../../api/terrainMaps';
import SkeletonLoader from '../../SkeletonLoader';
import ProjectsAPI from '../../../api/projects';
import EpsgAPI from '../../../api/epsg';
import { undefinedOrNull } from '../../../utils/functs';
import { DATA_AUTO_POLLING_TIMEOUT } from '../../../constants';
import { LOG_IT } from '../../../constants/env';
import { TerrainMapsProps } from '../index.types';
import styles from './index.module.scss';
import { Project } from '../../../api/projects.types';
import { DTMSource } from '../../../api/terrainMaps.types';

const projectsApis = new ProjectsAPI();
const epsgApis = new EpsgAPI();

export type TerrainMapsCrudProps = TerrainMapsProps;

interface IState {
  isContentLoaded: boolean;
  isContentAvailable: boolean;
  activeTabKey: string;
  projectData?: Project;
  finalizeBtnLoading: boolean;
  epsgProjection: string;
  epsgCode: string;
  unit: string;
}

const terrainMapsApis = new TerrainMapsApis();
const { TabPane } = Tabs;
const { Text } = Typography;

class TerrainMapsCrud extends React.Component<TerrainMapsCrudProps, IState> {
  public constructor(props: TerrainMapsCrudProps) {
    super(props);

    this.state = {
      isContentLoaded: false,
      isContentAvailable: true,
      activeTabKey: '1',
      finalizeBtnLoading: false,
      epsgProjection: '',
      epsgCode: '',
      unit: '',
    };
  }

  public componentDidMount(): void {
    this.initCrud();
    this.fetchProject();
  }

  public UNSAFE_componentWillReceiveProps({
    dtmData: nextDtmsData,
  }: Readonly<TerrainMapsCrudProps>): void {
    const { actionType } = this.props;

    if (
      actionType === 'edit' &&
      nextDtmsData &&
      Object.keys(nextDtmsData).length > 0 &&
      !nextDtmsData.finalized &&
      ['started', 'starting'].indexOf(nextDtmsData.status) > -1
    ) {
      // Auto polling
      setTimeout(() => {
        this.initCrud();
      }, DATA_AUTO_POLLING_TIMEOUT);
    }
  }

  private initCrud = (): void => {
    const { match, actionType, setDtm, showSnackbar } = this.props;
    const { projectId, aoiId, dtmId } = match.params;

    if (actionType === 'new') {
      setDtm(undefined);
      this.setState({
        isContentLoaded: true,
      });

      return;
    }

    terrainMapsApis
      .getDtm(projectId, aoiId, dtmId)
      .then((res) => {
        if (!res.data || Object.keys(res.data).length < 1) {
          showSnackbar({
            body: 'Invalid Terrain Map',
            type: 'error',
          });
        }

        setDtm(res.data);

        this.setState({
          isContentLoaded: true,
          isContentAvailable: this.checkContentAvailable(),
          activeTabKey: this.setActiveTabKey(),
        });
      })
      .catch((e) => {
        showSnackbar({
          body: e,
          type: 'error',
        });
      });
  };

  private fetchProject = () => {
    const { match } = this.props;
    const { projectId } = match.params;

    projectsApis
      .getProject(projectId)
      .then((res: any) => {
        this.setState({
          projectData: res,
        });

        if (res && res.epsgCode) {
          this.fetchEpsgProjection(res.epsgCode);
          this.fetchUnit(res.epsgCode);
          this.setState({
            epsgCode: res.epsgCode,
          });
        }
      })
      .catch((e) => {
        if (LOG_IT) {
          console.error(e);
        }
      });
  };

  private getUnitLabel = (unit: string): string => {
    switch (unit) {
      case 'meter':
        return 'meters';
      case 'foot':
        return 'feet';
      case 'usFT':
        return 'feet';
      default:
        return unit;
    }
  };

  private fetchUnit = (epsgCode: string): void => {
    projectsApis
      .getEPSGcodeById(epsgCode)
      .then((res) => {
        if (res && res.data) {
          const { data } = res;

          this.setState({
            unit: this.getUnitLabel(data.unit),
          });
        }
      })
      .catch((e) => {
        if (LOG_IT) {
          console.error(e);
        }
      });
  };

  private fetchEpsgProjection = (epsgCode: string): void => {
    epsgApis
      .getEpsgProjection(epsgCode)
      .then((res: any) => {
        this.setState({
          epsgProjection: res.data,
        });
      })
      .catch((e) => {
        if (LOG_IT) {
          console.error(e);
        }
      });
  };

  private isTabDisabled = (sourceType: DTMSource): boolean => {
    const { dtmData } = this.props;

    if (!dtmData) {
      return false;
    }

    return dtmData.sourceType !== sourceType;
  };

  private isTabEnabled = (sourceType: string): boolean => {
    const { dtmData } = this.props;

    if (!dtmData) {
      return false;
    }

    return dtmData.sourceType === sourceType;
  };

  private setActiveTabKey = (): string => {
    const { dtmData } = this.props;

    if (!dtmData) {
      return '1';
    }

    switch (dtmData.sourceType) {
      case 'contour':
      default:
        return '1';

      case 'points':
        return '2';

      case 'dsm':
        return '3';

      case 'manual_upload':
        return '4';
    }
  };

  private checkContentAvailable = (): boolean => {
    const { dtmData, actionType } = this.props;

    return !(!dtmData && actionType === 'edit');
  };

  private changeActiveTabKey = (key: string): void => {
    const { dtmData } = this.props;

    if (dtmData) {
      return;
    }

    this.setState({
      activeTabKey: key,
    });
  };

  private RenderNoContents = (): JSX.Element => {
    return <Text>Invalid Terrain Map</Text>;
  };

  private disableFinalizeBtn = (): boolean => {
    const { dtmData } = this.props;

    if (!dtmData) {
      return false;
    }

    switch (dtmData.status) {
      case 'pending':
      case 'error':
      case 'started':
      case 'starting':
        return true;

      case 'completed':
      default:
        return false;
    }
  };

  public handleFinalizeBtn = (dtmName: string) => {
    const { match, showSnackbar, setDtm, dtmData } = this.props;
    const { projectId, aoiId, dtmId } = match.params;

    if (!dtmData) {
      return;
    }

    const bodyFormData = {
      finalized: !dtmData.finalized,
      name: dtmName,
    };

    this.setState({
      finalizeBtnLoading: true,
    });

    terrainMapsApis
      .updateDtm(projectId, aoiId, bodyFormData, dtmId)
      .then((res: TerrainMapsApisReturnPromiseTypes) => {
        if (undefinedOrNull(res)) {
          showSnackbar({
            body: `Some error occured. Try again!`,
            type: 'error',
          });

          this.setState({
            finalizeBtnLoading: false,
          });

          return;
        }

        if (res.error) {
          showSnackbar({
            body: res.error,
            type: 'error',
          });

          this.setState({
            finalizeBtnLoading: false,
          });

          return;
        }

        setDtm(res.data);

        this.setState({
          finalizeBtnLoading: false,
        });

        showSnackbar({
          body: `The Terrain Map was ${
            !dtmData.finalized ? 'published' : 'unpublished'
          } successfully`,
          type: 'success',
          isModal: false,
        });
      });
  };

  private disableGenerateBtn = (): boolean => {
    const { dtmData } = this.props;

    if (!dtmData) {
      return false;
    }

    if (dtmData.finalized) {
      return true;
    }

    switch (dtmData.status) {
      case 'started':
      case 'starting':
        return true;

      case 'pending':
      case 'completed':
      case 'error':
      default:
        return false;
    }
  };

  public render(): React.ReactNode {
    const { actionType } = this.props;
    const {
      isContentLoaded,
      isContentAvailable,
      activeTabKey,
      projectData,
      epsgProjection,
      finalizeBtnLoading,
      unit,
      epsgCode,
    } = this.state;

    return (
      <div className={styles.container}>
        <Tabs
          centered
          tabBarStyle={{ textAlign: 'center' }}
          activeKey={activeTabKey}
          onChange={this.changeActiveTabKey}
        >
          <TabPane
            disabled={this.isTabDisabled('contour')}
            tab={
              this.isTabDisabled('contour') ? (
                <Tooltip
                  placement="right"
                  title="To generate a Terrain Map from Contour Map, go back and create a new one."
                >
                  <span className={styles.toolTipSpan}>CONTOUR</span>
                </Tooltip>
              ) : (
                'CONTOUR'
              )
            }
            key="1"
          >
            {!isContentLoaded ? (
              <SkeletonLoader size={2} />
            ) : !isContentAvailable ? (
              <this.RenderNoContents />
            ) : (
              <TerrainMapsTabsLayout1
                {...this.props}
                sourceType="contour"
                importBtnText="Import DXF File"
                acceptedImportFiles=".dxf"
                projectData={projectData}
                disableFinalizeBtn={this.disableFinalizeBtn}
                disableGenerateBtn={this.disableGenerateBtn}
                finalizeBtnLoading={finalizeBtnLoading}
                onFinalizeBtn={this.handleFinalizeBtn}
                actionType={actionType}
                epsgCode={epsgCode}
                unit={unit}
              />
            )}
          </TabPane>
          <TabPane
            disabled={this.isTabDisabled('points')}
            key="2"
            tab={
              this.isTabDisabled('points') ? (
                <Tooltip
                  placement="right"
                  title="To generate a Terrain Map from Points Map, go back and create a new one."
                >
                  <span className={styles.toolTipSpan}>POINTS</span>
                </Tooltip>
              ) : (
                'POINTS'
              )
            }
          >
            {!isContentLoaded ? (
              <SkeletonLoader size={2} />
            ) : !isContentAvailable ? (
              <this.RenderNoContents />
            ) : (
              <TerrainMapsTabsLayout1
                {...this.props}
                sourceType="points"
                importBtnText="Import CSV File"
                acceptedImportFiles=".csv"
                projectData={projectData}
                disableFinalizeBtn={this.disableFinalizeBtn}
                disableGenerateBtn={this.disableGenerateBtn}
                finalizeBtnLoading={finalizeBtnLoading}
                onFinalizeBtn={this.handleFinalizeBtn}
                actionType={actionType}
                epsgCode={epsgCode}
                unit={unit}
              />
            )}
          </TabPane>

          <TabPane
            disabled={this.isTabDisabled('dsm')}
            key="3"
            tab={
              this.isTabDisabled('dsm') ? (
                <Tooltip
                  placement="right"
                  title="To generate a Terrain Map from Surface Map, go back and create a new one."
                >
                  <span className={styles.toolTipSpan}>SURFACE MAPS</span>
                </Tooltip>
              ) : (
                'SURFACE MAPS'
              )
            }
          >
            {!isContentLoaded ? (
              <SkeletonLoader size={2} />
            ) : !isContentAvailable ? (
              <this.RenderNoContents />
            ) : (
              <TerrainMapsTabsLayout2
                {...this.props}
                sourceType="dsm"
                importBtnText="Import VCP File"
                acceptedImportFiles=".csv"
                projectData={projectData}
                epsgProjection={epsgProjection}
                disableFinalizeBtn={this.disableFinalizeBtn}
                disableGenerateBtn={this.disableGenerateBtn}
                finalizeBtnLoading={finalizeBtnLoading}
                onFinalizeBtn={this.handleFinalizeBtn}
                actionType={actionType}
                epsgCode={epsgCode}
                unit={unit}
              />
            )}
          </TabPane>
          {this.isTabEnabled('manual_upload') ? (
            <TabPane
              disabled={this.isTabDisabled('manual_upload')}
              key="4"
              tab={
                this.isTabDisabled('manual_upload') ? (
                  <Tooltip
                    placement="right"
                    title="To generate a Custom Terrain Map, contact support@aspecscire.com."
                  >
                    <span className={styles.toolTipSpan}>CUSTOM</span>
                  </Tooltip>
                ) : (
                  'CUSTOM'
                )
              }
            >
              {!isContentLoaded ? (
                <SkeletonLoader size={2} />
              ) : !isContentAvailable ? (
                <this.RenderNoContents />
              ) : (
                <TerrainMapsTabsLayout3
                  {...this.props}
                  sourceType="manual_upload"
                  projectData={projectData}
                  disableFinalizeBtn={this.disableFinalizeBtn}
                  finalizeBtnLoading={finalizeBtnLoading}
                  onFinalizeBtn={this.handleFinalizeBtn}
                  actionType={actionType}
                  epsgCode={epsgCode}
                  unit={unit}
                />
              )}
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    );
  }
}

export default TerrainMapsCrud;
