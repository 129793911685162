import ApiBase from './ApiBase';
import { TerrainMapsApisReturnTypes } from '../shapes/terrainMaps';
import { processErrorHandling } from '../utils/helpers';
import { BASE_WORKBENCH_API_URL } from '../constants/urls';

export default class TerrainMapsApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_WORKBENCH_API_URL);

    this._baseUrl = `/v1/postprocess/projects`;
  }

  public listDtms(
    projectId: string,
    aoiId: string
  ): TerrainMapsApisReturnTypes {
    return this.get(`${this._baseUrl}/${projectId}/aois/${aoiId}/dtms`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the Terrain Maps.`,
          isArray: true,
        });
      });
  }

  public getDtm(
    projectId: string,
    aoiId: string,
    dtmId: string
  ): TerrainMapsApisReturnTypes {
    return this.get(`${this._baseUrl}/${projectId}/aois/${aoiId}/dtms/${dtmId}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the Terrain Map.`,
        });
      });
  }

  public postDtm(
    projectId: string,
    aoiId: string,
    formData: {},
    dtmId?: string | null
  ): TerrainMapsApisReturnTypes {
    let url = `${this._baseUrl}/${projectId}/aois/${aoiId}/dtms`;

    if (dtmId) {
      url += `/${dtmId}`;
    }

    return this.postForm(`${url}`, formData)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to generate the Terrain Map.`,
        });
      });
  }

  public updateDtm(
    projectId: string,
    aoiId: string,
    formData: {},
    dtmId: string | null
  ): TerrainMapsApisReturnTypes {
    const url = `${this._baseUrl}/${projectId}/aois/${aoiId}/dtms/${dtmId}`;

    return this.patch(`${url}`, formData)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to publish the Terrain Map.`,
        });
      });
  }

  public getDsms(projectId: string, aoiId: string): TerrainMapsApisReturnTypes {
    return this.get(`${this._baseUrl}/${projectId}/aois/${aoiId}/dsms`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch Surface Map information.`,
          isArray: true,
        });
      });
  }

  public getDtmArtifact(
    projectId: string,
    aoiId: string,
    dtmId: string
  ): TerrainMapsApisReturnTypes {
    return this.get(
      `${this._baseUrl}/${projectId}/aois/${aoiId}/dtms/${dtmId}/artifact`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the Ground Areas.`,
        });
      });
  }

  public saveDtmWithArtifact(
    projectId: string,
    aoiId: string,
    formData: {},
    dtmId?: string | null
  ): TerrainMapsApisReturnTypes {
    let url = `${this._baseUrl}/${projectId}/aois/${aoiId}/dtms`;

    if (dtmId) {
      url += `/${dtmId}/save`;
    } else {
      url += '/save';
    }

    return this.post(url, formData)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error when trying to save the Ground Areas.',
        });
      });
  }

  public getSavedDtmArtifact(
    projectId: string,
    aoiId: string,
    dtmId: string
  ): TerrainMapsApisReturnTypes {
    return this.get(
      `${this._baseUrl}/${projectId}/aois/${aoiId}/dtms/${dtmId}/artifact/saved`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the last saved Ground Areas.`,
        });
      });
  }
}
