import * as React from 'react';
import { Link } from 'react-router-dom';
import ownStyle from './AoiItem.module.scss';
import { aoiListingPageUrl, aoiPageUrl } from '../../routes/urls';
import { BASE_CAPI_URL } from '../../constants/urls';

interface IProps {
  id: any;
  name: string;
  projectId: string;
  className: string;
  style: any;
  tags: any[];
  center: any;
  showViewLinks?: boolean;
  showManageLinks?: boolean;
}

export default class AoiItem extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { id, name, projectId, className, style, tags, showManageLinks } =
      this.props;

    const imageUrl = `${BASE_CAPI_URL}/v1/thumbnail/aoi/${id}`;

    return (
      <div
        className={`${className || ownStyle.container}  ${ownStyle.shadow} `}
        style={style || {}}
      >
        <div className={`${ownStyle.imageContainer}`}>
          {showManageLinks ? (
            <Link to={aoiListingPageUrl(projectId, id)} title="Manage">
              <div className={ownStyle.manageBtn}>
                <i
                  className={`fa fa-wrench ${ownStyle.faIcon}`}
                  aria-hidden="true"
                />
              </div>
            </Link>
          ) : null}
          <Link to={aoiPageUrl(projectId, id)} title={name}>
            <div className={`${ownStyle.image}`}>
              <img src={imageUrl} alt={name} />
            </div>
          </Link>
        </div>

        <div className={ownStyle.titleWrapper}>
          <i
            className={`fa fa-building ${ownStyle.faIcon}`}
            aria-hidden="true"
          />
          <div className={ownStyle.title}>{name}</div>
        </div>

        <div className={ownStyle.tags}>
          {(tags || []).map((tag) => (
            <div key={tag} className={ownStyle.tag}>
              {tag}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
