// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MapGeneratedStatus_container__qWlnV{display:flex;justify-content:center;flex-direction:column;align-items:center}.MapGeneratedStatus_container__qWlnV .MapGeneratedStatus_mapGenerating__lmh9p{align-items:center;display:inline-flex}.MapGeneratedStatus_container__qWlnV .MapGeneratedStatus_ellipsisLoading__UfiPH{display:inline-flex}", "",{"version":3,"sources":["webpack://./src/components/MapGeneratedStatus/index.module.scss","webpack://./src/styles/scss/base/mixins/_horz-vert-center.scss"],"names":[],"mappings":"AAEA,qCCCE,YAAA,CACA,sBAAA,CACA,qBAAA,CAGE,kBAAA,CDHF,8EACE,kBAAA,CACA,mBAAA,CAGF,gFACE,mBAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  @include horz-vert-center();\n\n  .mapGenerating {\n    align-items: center;\n    display: inline-flex;\n  }\n\n  .ellipsisLoading {\n    display: inline-flex;\n  }\n}\n","// example: @include horz-vert-center();\n\n@mixin horz-vert-center($alignItem: true) {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n\n  @if $alignItem {\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MapGeneratedStatus_container__qWlnV",
	"mapGenerating": "MapGeneratedStatus_mapGenerating__lmh9p",
	"ellipsisLoading": "MapGeneratedStatus_ellipsisLoading__UfiPH"
};
export default ___CSS_LOADER_EXPORT___;
