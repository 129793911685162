import { Select, Typography } from 'antd';
import classnames from 'classnames';
import * as React from 'react';
import { DownloadsPropsTypes, DownloadsStateTypes } from './index.types';
import { log } from '../../utils/log';
import styles from './index.module.scss';
import DownloadV2Apis from '../../api/downloadsV2';
import AoiV2Apis from '../../api/aoiV2';
import ViewsV2Apis from '../../api/viewsV2';
import SkeletonLoader from '../SkeletonLoader';
import {
  arrayRemoveDuplicate,
  filteredArrayValue,
  inArray,
} from '../../utils/functs';
import { GenericObjectType } from '../../shapes/app';
import { appFormatDate, convertToUtc } from '../../utils/date';

const { Option: SelectOption } = Select;
const { Text } = Typography;

class Downloads extends React.Component<
  DownloadsPropsTypes,
  DownloadsStateTypes
> {
  private aoiApis = new AoiV2Apis();

  private viewsApis = new ViewsV2Apis();

  private downloadApis = new DownloadV2Apis();

  public constructor(props: DownloadsPropsTypes) {
    super(props);

    this.state = {
      aoiList: null,
      downloadTypeList: null,
      viewsList: null,
      downloadablesList: null,
      prevDownloadUrlsList: [],
      isViewsListLoading: false,
      isDownloadableListLoading: false,
    };
  }

  public componentDidMount(): void {
    this.fetchAoiList(false);
    this.fetchDownloadTypeList();
    this.fetchViewList();
    //  this.fetchDownloadablesList();
  }

  private fetchAoiList = (shouldFetchDownloadablesList = true) => {
    const { match } = this.props;
    const { projectId, aoiId } = match.params;

    this.aoiApis.listAois(projectId).then((res) => {
      const { error: apiError, data: apiData } = res;

      if (apiError) {
        log.error(apiError, 'Downloads.fetchAois');

        this.setState({
          aoiList: null,
        });

        return;
      }

      const filteredAoiList = filteredArrayValue(
        (apiData ?? []).filter((a: GenericObjectType) => {
          return a.id === aoiId;
        })
      );

      const selectedAoiId = filteredAoiList?.id ?? undefined;

      this.setState(
        {
          aoiList: apiData,
          selectedAoiId,
        },
        () => {
          if (!shouldFetchDownloadablesList) {
            return;
          }

          this.fetchDownloadablesList();
        }
      );
    });
  };

  private fetchDownloadTypeList = () => {
    this.downloadApis.listDownloadTypes().then((res) => {
      const { error: apiError, data: apiData } = res;

      if (apiError) {
        this.setState({
          downloadTypeList: null,
        });

        log.error(apiError, 'Downloads.fetchDownloadTypesList');

        return;
      }

      this.setState({
        downloadTypeList: apiData,
      });
    });
  };

  private fetchViewList = (shouldFetchDownloadablesList = true) => {
    const { match } = this.props;
    const { selectedAoiId } = this.state;

    const { projectId } = match.params;

    this.viewsApis.getViewList(projectId, selectedAoiId).then((res) => {
      const { error: apiError, data: apiData } = res;

      if (apiError) {
        this.setState({
          isViewsListLoading: false,
          viewsList: null,
        });

        log.error(apiError, 'Downloads.fetchViewList');

        return;
      }

      this.setState(
        {
          isViewsListLoading: false,
          viewsList: apiData,
        },
        () => {
          if (!shouldFetchDownloadablesList) {
            return;
          }

          this.fetchDownloadablesList();
        }
      );
    });
  };

  private fetchDownloadFilterList = () => {
    const { match } = this.props;
    const { selectedAoiId, selectedViewDate, selectedDownloadTypeId } =
      this.state;

    const { projectId } = match.params;

    const formData = {
      aoiId: selectedAoiId || null,
      downloadTypes: selectedDownloadTypeId ? [selectedDownloadTypeId] : null,
      dates: selectedViewDate ? [selectedViewDate] : null,
    };

    this.downloadApis
      .postDownloadFilterList(projectId, formData)
      .then((res) => {
        const { error: apiError, data: apiData } = res;

        if (apiError) {
          this.setState({
            isDownloadableListLoading: false,
            downloadablesList: null,
          });

          log.error(apiError, 'Downloads.fetchDownloadFilterList');

          return;
        }

        this.setState({
          isDownloadableListLoading: false,
          downloadablesList: apiData,
        });
      });
  };

  private fetchDownloadUrl = (
    selectedDownloadablesList: {
      id: string;
      source: string;
    }[]
  ) => {
    const { match } = this.props;
    const { projectId } = match.params;

    this.downloadApis
      .postDownloadUrl(projectId, selectedDownloadablesList)
      .then((res) => {
        const { error: apiError, data: apiData } = res;

        if (apiError) {
          log.error(apiError, 'Downloads.fetchDownloadUrl');

          return;
        }

        const currentSelectedDownloadablesList = selectedDownloadablesList.map(
          (a) => a.id
        );

        this.setState(
          ({ prevDownloadUrlsList }) => {
            return {
              prevDownloadUrlsList: [
                ...prevDownloadUrlsList,
                ...currentSelectedDownloadablesList,
              ],
            };
          },
          () => {
            const downloadUrlsList = apiData.map(
              (a: GenericObjectType) => a.signedUrl
            );

            this.downloadItems(downloadUrlsList);
          }
        );
      });
  };

  private fetchDownloadablesList = () => {
    this.setState(
      {
        isDownloadableListLoading: true,
      },
      () => {
        this.fetchDownloadFilterList();
      }
    );
  };

  private handleChangeAoi = (value: string) => {
    this.setState(
      {
        selectedAoiId: value,
        isViewsListLoading: true,
      },
      () => {
        this.fetchViewList();
      }
    );
  };

  private handleChangeDownloadTypeId = (value: string) => {
    this.setState(
      {
        selectedDownloadTypeId: value,
      },
      () => {
        this.fetchDownloadablesList();
      }
    );
  };

  private handleChangeAoiMissionsDate = (value: string) => {
    this.setState(
      {
        selectedViewDate: value,
      },
      () => {
        this.fetchDownloadablesList();
      }
    );
  };

  private handleDownloadItemClick = (value: string[]) => {
    const { downloadablesList } = this.state;

    const selectedDownloadablesList = (downloadablesList ?? [])
      .filter((a) => inArray(value ?? [], a.id))
      .map((a) => {
        const { id, source } = a;

        return {
          id,
          source,
        };
      });

    this.fetchDownloadUrl(selectedDownloadablesList);
  };

  private downloadItems = (downloadUrlsList: string[]) => {
    downloadUrlsList.map((a) => {
      window.open(a);

      return a;
    });
  };

  private getDates = () => {
    const { viewsList } = this.state;

    return arrayRemoveDuplicate(
      (viewsList ?? []).map((a) => {
        return convertToUtc(a.date);
      })
    );
  };

  public render(): React.ReactNode {
    const {
      aoiList,
      selectedAoiId,
      viewsList,
      selectedDownloadTypeId,
      downloadTypeList,
      selectedViewDate,
      isViewsListLoading,
      isDownloadableListLoading,
      downloadablesList,
      prevDownloadUrlsList,
    } = this.state;

    if (!aoiList || !downloadTypeList || !viewsList) {
      return <SkeletonLoader />;
    }

    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div
            className={`${styles.dualColumn} ${styles.aoiInputItemWrapperContainer}`}
          >
            <div className={styles.inputItemWrapper}>
              <Text>Area of Interest</Text>
              <div>
                <Select
                  placeholder="Pick an AOI"
                  defaultValue={selectedAoiId}
                  onChange={(val: string) => {
                    this.handleChangeAoi(val);
                  }}
                  className={styles.selectStyle}
                >
                  <SelectOption value={undefined}>All AOIs</SelectOption>

                  {aoiList.map((a) => {
                    return (
                      <SelectOption key={a.id} value={a.id}>
                        {a.name}
                      </SelectOption>
                    );
                  })}
                </Select>
              </div>
            </div>

            <div className={styles.inputItemWrapper}>
              <Text>Download Type</Text>
              <div>
                <Select
                  placeholder="Pick a Download Type"
                  defaultValue={selectedDownloadTypeId}
                  onChange={(val: string) => {
                    this.handleChangeDownloadTypeId(val);
                  }}
                  className={styles.selectStyle}
                >
                  <SelectOption value={undefined}>All Types</SelectOption>

                  {downloadTypeList.map((a) => {
                    return (
                      <SelectOption key={a.documentType} value={a.documentType}>
                        {a.name}
                      </SelectOption>
                    );
                  })}
                </Select>
              </div>
            </div>

            <div className={styles.inputItemWrapper}>
              <Text>Mission Dates</Text>
              <div>
                <Select
                  placeholder="Pick a Mission Date"
                  defaultValue={selectedViewDate}
                  onChange={(val: string) => {
                    this.handleChangeAoiMissionsDate(val);
                  }}
                  className={styles.selectStyle}
                  disabled={isViewsListLoading || !viewsList}
                >
                  <SelectOption value={undefined}>All Dates</SelectOption>

                  {this.getDates().map((a) => {
                    return (
                      <SelectOption key={a} value={a}>
                        {appFormatDate(a as string)}
                      </SelectOption>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>

          <div
            className={`${styles.dualColumn} ${styles.downloadableWrapperContainer}`}
          >
            <div className={styles.downloadItemsWrapper}>
              {isDownloadableListLoading ? (
                <Text>Fetching downloadable items...</Text>
              ) : !downloadablesList || (downloadablesList ?? []).length < 1 ? (
                <Text>No downloadable items were found</Text>
              ) : (
                <div>
                  <span className={styles.title}>Files for download</span>
                  <div className={styles.downloadItemsInnerWrapper}>
                    {(downloadablesList ?? []).map((a) => {
                      return (
                        <div>
                          <span
                            onClick={() => {
                              this.handleDownloadItemClick([a.id]);
                            }}
                            className={classnames(styles.downloadItem, {
                              [styles.visited]: inArray(
                                prevDownloadUrlsList,
                                a.id
                              ),
                            })}
                          >
                            {a.fileName}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Downloads;
