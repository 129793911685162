import React from 'react';
import { Draw, Modify, Select } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeometryType from 'ol/geom/GeometryType';
import { click } from 'ol/events/condition';
import DrawHole from 'ol-ext/interaction/DrawHole';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import { GeoJSON } from 'ol/format';
import { Feature } from 'ol';
import { isValidGeometry, uuid } from 'src/utils/functs';
import { SelectEvent } from 'ol/interaction/Select';
import { OverlayHelper } from './OverlayHelper';
import {
  FeatureObject,
  TerrainDrawControlProps,
  TerrainDrawControlState,
  TerrainDrawMode,
} from './index.types';
import { WEB_MERCATOR, WGS84 } from '../../../../OSMMap/utils';

/**
 * Different draw modes to support:
 * -- edit_terrain_areas
 * --
 * Interface functions:
 * --
 */
export class TerrainDrawControl extends React.PureComponent<
  TerrainDrawControlProps,
  TerrainDrawControlState
> {
  private sources: { polygon: VectorSource; points: VectorSource };

  private layers: { polygon: VectorLayer; points: VectorLayer };

  private geoJsonWriter: GeoJSON;

  private constructor(props: TerrainDrawControlProps) {
    super(props);

    this.sources = {
      polygon: new VectorSource({ wrapX: false }),
      points: new VectorSource({ wrapX: false }),
    };
    this.layers = {
      polygon: new VectorLayer({ source: this.sources.polygon, zIndex: 10000 }),
      points: new VectorLayer({ source: this.sources.points, zIndex: 10000 }),
    };
    this.geoJsonWriter = new GeoJSON({
      dataProjection: WGS84,
      featureProjection: WEB_MERCATOR,
    });

    this.state = {};
  }

  public componentDidMount = () => {
    const { olMap } = this.props;

    if (olMap) {
      olMap.addLayer(this.layers.points);
      olMap.addLayer(this.layers.polygon);
    }
  };

  public componentDidUpdate = ({
    drawMode: oldDrawMode,
  }: Readonly<TerrainDrawControlProps>) => {
    const { drawMode } = this.props;

    if (drawMode !== oldDrawMode) {
      this.drawModeChanged(drawMode);
    }
  };

  public resetDrawMode = () => {
    const { olMap } = this.props;
    const { interactions } = this.state;

    if (!olMap) return;

    interactions?.forEach((i) => olMap.removeInteraction(i));

    this.setState({ interactions: undefined, overlayMessage: undefined });
  };

  public drawModeChanged = (drawMode?: TerrainDrawMode) => {
    const { olMap } = this.props;

    this.resetDrawMode();
    this.updateOverlayMessage(drawMode);

    if (olMap === undefined) return;
    if (drawMode === undefined) return;

    switch (drawMode) {
      case 'mark_terrain_areas': {
        const draw = new Draw({
          source: this.sources.polygon,
          type: GeometryType.POLYGON,
        });

        olMap.addInteraction(draw);

        draw.on('drawstart', this.onDrawStart);
        draw.on('drawend', this.onShapeDraw);

        this.setState({
          interactions: [draw],
        });
        break;
      }

      case 'mark_holes': {
        const draw = new DrawHole({ layers: [this.layers.polygon] });

        draw.on('drawend', this.onHoleDraw);

        olMap.addInteraction(draw);
        this.setState({
          interactions: [draw],
        });
        break;
      }

      case 'edit_areas': {
        const modify = new Modify({
          source: this.sources.polygon,
        });

        olMap.addInteraction(modify);
        modify.on('modifyend', this.onShapeEdit);

        this.setState({ interactions: [modify] });
        break;
      }

      case 'delete_areas': {
        const select = new Select({
          layers: [this.layers.polygon],
          condition: click,
        });

        olMap.addInteraction(select);
        select.on('select', this.onShapeSelect);
        this.setState({
          interactions: [select],
        });
        break;
      }

      case 'delete_points': {
        const select = new Select({
          layers: [this.layers.points],
          condition: click,
        });

        olMap.addInteraction(select);
        select.on('select', this.onShapeSelect);
        this.setState({
          interactions: [select],
        });
        break;
      }

      default:
        break;
    }
  };

  public updateOverlayMessage = (drawMode?: TerrainDrawMode) => {
    if (drawMode === undefined) return;
    let overlayMessage: string | undefined;

    switch (drawMode) {
      case 'mark_terrain_areas': {
        overlayMessage = 'Click to draw a new terrain area.';
        break;
      }

      case 'mark_holes': {
        overlayMessage =
          'Click inside an existing area to mark holes. Double click to close.';
        break;
      }

      case 'edit_areas': {
        overlayMessage = 'Click and drag to edit.';
        break;
      }

      case 'delete_areas': {
        overlayMessage = 'Click on area to delete';
        break;
      }

      case 'delete_points': {
        overlayMessage = 'Click on point to delete';
        break;
      }

      default:
        break;
    }

    this.setState({ overlayMessage });
  };

  public addPolygonFeatures = (features: FeatureObject[]) => {
    const olFeatures = features.map(this.readFeatureFromObject);

    this.sources.polygon.addFeatures(olFeatures);
  };

  public removePolygonFeature = (featureId: string) => {
    const feature = this.sources.polygon.getFeatureById(featureId);

    if (feature) this.sources.polygon.removeFeature(feature);
  };

  public addPointFeatures = (features: FeatureObject[]) => {
    const olFeatures = features.map(this.readFeatureFromObject);

    this.sources.points.addFeatures(olFeatures);
  };

  public removePointFeature = (featureId: string) => {
    const feature = this.sources.points.getFeatureById(featureId);

    if (feature) this.sources.points.removeFeature(feature);
  };

  public clearPolygonFeatures = () => {
    this.sources.polygon.clear();
  };

  public clearPointFeatures = () => {
    this.sources.points.clear();
  };

  public clearSelection = () => {
    const { interactions } = this.state;

    interactions?.forEach((i) => {
      if (i instanceof Select) {
        i.getFeatures().clear();
      }
    });
  };

  public getAllPolygonFeatures = (): FeatureObject[] => {
    const features = this.sources.polygon.getFeatures();

    return features.map((f) => this.writeFeatureAsObject(f));
  };

  public getAllPointFeatures = (): FeatureObject[] => {
    const features = this.sources.points.getFeatures();

    return features.map((f) => this.writeFeatureAsObject(f));
  };

  public readFeatureFromObject = (featureObject: FeatureObject): Feature => {
    const feature = this.geoJsonWriter.readFeature(featureObject as object);

    feature.setId((featureObject as { id: string }).id || uuid());

    return feature;
  };

  public writeFeatureAsObject = (feature: Feature): FeatureObject => {
    const output = this.geoJsonWriter.writeFeatureObject(feature);

    if (!feature.getId()) feature.setId(uuid());

    output.id = feature.getId();

    return output;
  };

  private onDrawStart = (_: DrawEvent) => {
    this.setState({ overlayMessage: 'Double-click to close polygon.' });
  };

  private onShapeDraw = (e: DrawEvent) => {
    const { drawMode, events } = this.props;

    this.updateOverlayMessage(drawMode);

    if (!e.feature.getId()) e.feature.setId(uuid());

    if (events?.onShapeCreate === undefined) return;

    const feature = this.writeFeatureAsObject(e.feature);
    const isValid = isValidGeometry(JSON.stringify(feature.geometry));

    events.onShapeCreate({
      features: [feature],
      isValid,
    });
  };

  private onHoleDraw = (e: DrawEvent) => {
    const { drawMode, events } = this.props;

    this.updateOverlayMessage(drawMode);

    if (!e.feature.getId()) e.feature.setId(uuid());

    if (events?.onShapeUpdate === undefined) return;

    const feature = this.writeFeatureAsObject(e.feature);
    const isValid = isValidGeometry(JSON.stringify(feature.geometry));

    events.onShapeUpdate({ features: [feature], isValid });
  };

  private onShapeEdit = (e: ModifyEvent) => {
    const { events } = this.props;

    const featureList = e.features.getArray();
    const feature = featureList.length > 0 ? featureList[0] : undefined;

    if (feature === undefined || events?.onShapeUpdate === undefined) return;

    const featureObject = this.writeFeatureAsObject(feature);
    const isValid = isValidGeometry(JSON.stringify(featureObject.geometry));

    events.onShapeUpdate({ features: [featureObject], isValid });
  };

  private onShapeSelect = (e: SelectEvent) => {
    const { events } = this.props;

    const featureList = e.selected;
    const feature = featureList.length > 0 ? featureList[0] : undefined;

    if (feature === undefined || events?.onShapeSelect === undefined) return;

    events.onShapeSelect({
      features: [this.writeFeatureAsObject(feature)],
      isValid: true, // selection is always valid, as it doesn't involve edits
    });
  };

  render = () => {
    const { olMap } = this.props;
    const { overlayMessage } = this.state;

    return (
      <div data-testid="terrain-draw-control">
        <OverlayHelper olMap={olMap} overlayContent={overlayMessage} />
      </div>
    );
  };
}
