import ApiBase from './ApiBase';
import {
  CreateLayerStyleRequest,
  CreateMapStyleRequest,
  EditLayerStyleRequest,
  EditMapStyleRequest,
  MapStyleDetails,
  LayerDescriptor,
} from './mapStyle.types';
import { BASE_CAPI_URL } from '../constants/urls';

export default class MapStyleAPIs extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(`${BASE_CAPI_URL}/v1`);
  }

  public listMapStylesForAoi = (
    projectId: string,
    aoiId: string,
    missionId?: string
  ): Promise<MapStyleDetails[]> => {
    return this.get(
      `/projects/${projectId}/aois/${aoiId}/styles${
        missionId ? `?mission=${missionId}` : ''
      }`
    ).then((res) => {
      return res.data;
    });
  };

  public createMapStyle = (
    projectId: string,
    aoiId: string,
    request: CreateMapStyleRequest
  ): Promise<MapStyleDetails> => {
    return this.post(
      `/projects/${projectId}/aois/${aoiId}/styles`,
      request
    ).then((res) => {
      return res.data;
    });
  };

  public editMapStyle = (
    projectId: string,
    aoiId: string,
    styleId: string,
    request: EditMapStyleRequest
  ): Promise<MapStyleDetails> => {
    return this.patch(
      `/projects/${projectId}/aois/${aoiId}/styles/${styleId}`,
      request
    ).then((res) => {
      return res.data;
    });
  };

  public getMapStyle = (
    projectId: string,
    aoiId: string,
    styleId: string
  ): Promise<MapStyleDetails> => {
    return this.get(
      `/projects/${projectId}/aois/${aoiId}/styles/${styleId}`
    ).then((res) => {
      return res.data;
    });
  };

  public listStyleLayers = (
    projectId: string,
    aoiId: string,
    styleId: string
  ): Promise<LayerDescriptor[]> => {
    return this.get(
      `/projects/${projectId}/aois/${aoiId}/styles/${styleId}/layers`
    ).then((res) => {
      return res.data;
    });
  };

  public getStyleLayer = (
    projectId: string,
    aoiId: string,
    styleId: string,
    layerId: string
  ): Promise<LayerDescriptor> => {
    return this.get(
      `/projects/${projectId}/aois/${aoiId}/styles/${styleId}/layers/${layerId}`
    ).then((res) => {
      return res.data;
    });
  };

  public createStyleLayer = (
    projectId: string,
    aoiId: string,
    styleId: string,
    request: CreateLayerStyleRequest,
    layerSource?: File
  ): Promise<LayerDescriptor> => {
    const form = new FormData();

    form.set('request', JSON.stringify(request));
    if (layerSource) form.set('file', layerSource);

    return this.post(
      `/projects/${projectId}/aois/${aoiId}/styles/${styleId}/layers`,
      form
    ).then((res) => {
      return res.data;
    });
  };

  public editStyleLayer = (
    projectId: string,
    aoiId: string,
    styleId: string,
    layerId: string,
    request: EditLayerStyleRequest,
    layerSource?: File
  ): Promise<LayerDescriptor> => {
    const form = new FormData();

    form.set('request', JSON.stringify(request));
    if (layerSource) form.set('file', layerSource);

    return this.patch(
      `/projects/${projectId}/aois/${aoiId}/styles/${styleId}/layers/${layerId}`,
      form
    ).then((res) => {
      return res.data;
    });
  };

  public deleteStyleLayer = async (
    projectId: string,
    aoiId: string,
    styleId: string,
    layerId: string
  ): Promise<void> => {
    await this.delete(
      `/projects/${projectId}/aois/${aoiId}/styles/${styleId}/layers/${layerId}`
    );
  };
}
