// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VideoView_container__EQO3p{height:100%;width:100%}.VideoView_container__EQO3p .VideoView_dropDownWrapper__F6gIZ{min-width:200px;position:absolute;left:10px;top:75px;z-index:100}.VideoView_container__EQO3p .VideoView_videoPlayerWrapper__aq\\+uA .VideoView_videoPlayer__PNMvS{width:100vw;height:calc(100vh - 112px);margin-top:5px}.VideoView_container__EQO3p .VideoView_videoPlayerWrapper__aq\\+uA .VideoView_videoPlayer__PNMvS.VideoView_loading__0bEtC{height:calc(100vh - 106px)}", "",{"version":3,"sources":["webpack://./src/components/View/VideoView/index.module.scss"],"names":[],"mappings":"AAAA,4BACE,WAAA,CACA,UAAA,CAEA,8DACE,eAAA,CACA,iBAAA,CACA,SAAA,CACA,QAAA,CACA,WAAA,CAIA,gGACE,WAAA,CACA,0BAAA,CACA,cAAA,CAEA,yHACE,0BAAA","sourcesContent":[".container {\n  height: 100%;\n  width: 100%;\n\n  .dropDownWrapper {\n    min-width: 200px;\n    position: absolute;\n    left: 10px;\n    top: 75px;\n    z-index: 100;\n  }\n\n  .videoPlayerWrapper {\n    .videoPlayer {\n      width: 100vw;\n      height: calc(100vh - 112px);\n      margin-top: 5px;\n\n      &.loading {\n        height: calc(100vh - 106px);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "VideoView_container__EQO3p",
	"dropDownWrapper": "VideoView_dropDownWrapper__F6gIZ",
	"videoPlayerWrapper": "VideoView_videoPlayerWrapper__aq+uA",
	"videoPlayer": "VideoView_videoPlayer__PNMvS",
	"loading": "VideoView_loading__0bEtC"
};
export default ___CSS_LOADER_EXPORT___;
