import * as types from '../actions';
import { GenericObjectType } from '../../shapes/app';
import { GcpPlanData } from '../../api/mission.types';

interface IMissionState {
  loading: boolean;
  error: string;
  mission: any;
  redirectId: string;
  creating: boolean;
  submitting: boolean;
  drones: any[];
  epsgCodeList: any[];
  epsgCode: any;
  flightPlans: any[];
  gcpPlans: any[];
  gcpPlanData?: GcpPlanData;
  totalUploadedImages: number;
  uploadedImages: GenericObjectType[];
}

const initialMissionState: IMissionState = {
  loading: false,
  error: '',
  mission: {},
  redirectId: '',
  creating: false,
  submitting: false,
  drones: [],
  epsgCodeList: [],
  epsgCode: {},
  flightPlans: [],
  gcpPlans: [],
  gcpPlanData: undefined,
  totalUploadedImages: 0,
  uploadedImages: [],
};

export default function missionReducer(
  state: IMissionState = initialMissionState,
  action: any
): IMissionState {
  const { type, payload } = action;

  switch (type) {
    case types.CREATE_MISSION_PENDING:
      return {
        ...state,
        loading: true,
        creating: true,
      };

    case types.CREATE_MISSION_FULFILLED:
      // eslint-disable-next-line no-case-declarations
      const mission = payload.data;

      return {
        ...state,
        loading: false,
        creating: false,
        mission,
        redirectId: mission.id,
      };

    case types.CHECK_LOGIN_REJECTED:
      return {
        ...state,
        loading: false,
        creating: false,
        error: 'Error occured',
      };

    case types.SUBMIT_MISSION_PENDING:
      return {
        ...state,
        loading: true,
        submitting: true,
      };
    case types.SUBMIT_MISSION_FULFILLED:
      return {
        ...state,
        loading: false,
        submitting: false,
        mission: payload.data,
      };

    case types.SUBMIT_MISSION_REJECTED:
      return {
        ...state,
        loading: false,
        submitting: false,
        error: 'error occured',
      };

    case types.GET_MISSION_PENDING:
      return {
        ...state,
        loading: true,
        mission: {},
      };

    case types.GET_MISSION_FULFILLED:
      return {
        ...state,
        loading: false,
        mission: payload.data,
      };

    case types.GET_MISSION_REJECTED:
      return {
        ...state,
        loading: false,
        error: 'Error occured',
      };

    case types.GET_DRONES_PENDING:
      return {
        ...state,
        loading: true,
      };

    case types.GET_DRONES_FULFILLED:
      return {
        ...state,
        loading: false,
        drones: payload.data,
      };

    case types.GET_DRONES_REJECTED:
      return {
        ...state,
        loading: false,
      };

    case types.GET_EPSG_BY_ID_PENDING:
      return {
        ...state,
        loading: true,
      };

    case types.GET_EPSG_BY_ID_FULFILLED:
      return {
        ...state,
        loading: false,
        epsgCode: payload.data,
      };

    case types.GET_EPSG_BY_ID_REJECTED:
      return {
        ...state,
        loading: false,
      };

    case types.SEARCH_EPSG_BY_TEXT_PENDING:
      return {
        ...state,
        loading: true,
      };

    case types.SEARCH_EPSG_BY_TEXT_FULFILLED:
      return {
        ...state,
        loading: false,
        epsgCodeList: payload.data,
      };

    case types.SEARCH_EPSG_BY_TEXT_REJECTED:
      return {
        ...state,
        loading: false,
      };

    case types.GET_INITIAL_FLIGHT_PLAN_PENDING:
      return {
        ...state,
        loading: true,
      };

    case types.GET_INITIAL_FLIGHT_PLAN_FULFILLED:
      // eslint-disable-next-line no-case-declarations
      const sortedPlans: any[] = (payload.data || []).sort((a: any, b: any) => {
        // eslint-disable-next-line no-nested-ternary
        return a.type < b.type ? -1 : a.type === b.type ? 0 : 1;
      });

      return {
        ...state,
        loading: false,
        flightPlans: sortedPlans,
      };

    case types.GET_INITIAL_FLIGHT_PLAN_REJECTED:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_FLIGHT_PLAN:
      /* eslint-disable no-case-declarations */
      const plan = payload;
      const plans = state.flightPlans || [];
      const newPlans: any = [];
      /* eslint-enable no-case-declarations */

      plans.map((p: any) => {
        if (p.id === plan.id) {
          newPlans.push(plan);
        } else {
          newPlans.push(p);
        }

        return plan;
      });

      return {
        ...state,
        loading: false,
        flightPlans: newPlans,
        totalUploadedImages: 0,
        uploadedImages: [],
      };

    case types.GET_GCP_PLANS_PENDING:
      return {
        ...state,
        loading: true,
      };

    case types.GET_GCP_PLANS_FULFILLED:
      return {
        ...state,
        loading: false,
        gcpPlans: payload.data,
      };

    case types.GET_GCP_PLANS_REJECTED:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_GCP_PLAN: {
      const gcpPlan = payload;
      const { gcpPlanData } = state;
      const tempPlans = [...state.gcpPlans];
      const newGcpPlans: any = [];

      tempPlans.forEach((p: any) => {
        if (p.id === gcpPlan.id) {
          newGcpPlans.push(gcpPlan);
        } else {
          newGcpPlans.push(p);
        }
      });

      return {
        ...state,
        loading: false,
        gcpPlans: newGcpPlans,
        gcpPlanData: gcpPlan.id === gcpPlanData?.id ? gcpPlan : gcpPlanData,
      };
    }

    case types.GET_GCP_PLAN_DATA_PENDING:
      return {
        ...state,
        loading: true,
      };

    case types.GET_GCP_PLAN_DATA_FULFILLED:
      return {
        ...state,
        loading: false,
        gcpPlanData: payload.data,
      };

    case types.GET_GCP_PLAN_DATA_REJECTED:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_GCP_POINTS_DATA:
      if (payload.length > 0) {
        return {
          ...state,
          loading: false,
          gcpPlanData: {
            ...(state.gcpPlanData as GcpPlanData),
            gcpPoints: payload,
          },
        };
      }

      break;

    case types.GET_FLIGHT_PLAN_IMAGES_PENDING:
      return {
        ...state,
        loading: false,
      };

    case types.GET_FLIGHT_PLAN_IMAGES_FULFILLED:
      // eslint-disable-next-line no-case-declarations
      const imagesCount = payload.data.length;

      if (imagesCount > 0) {
        return {
          ...state,
          loading: true,
          totalUploadedImages: imagesCount,
          uploadedImages: payload.data,
        };
      }

      return {
        ...state,
        loading: true,
        totalUploadedImages: 0,
      };

    case types.GET_FLIGHT_PLAN_IMAGES_REJECTED:
      return {
        ...state,
        loading: true,
        totalUploadedImages: 0,
        uploadedImages: [],
      };

    case types.RESET_MISSION_STATE:
      return {
        mission: {},
        loading: false,
        submitting: false,
        creating: false,
        error: '',
        redirectId: '',
        drones: [],
        epsgCodeList: [],
        epsgCode: {},
        flightPlans: [],
        gcpPlans: [],
        gcpPlanData: undefined,
        totalUploadedImages: 0,
        uploadedImages: [],
      };

    default:
      return state;
  }

  return state;
}
