import React from 'react';
import OLView from 'ol/View';
import ElevationDifferenceAPIs from 'src/api/elevationDifference';
import { ElevationDifference } from 'src/api/elevationDifference.types';
import { View } from 'src/api/views.types';
import { sortByDate } from 'src/utils/helpers';
import { appFormatDate, formatDateTime } from 'src/utils/date';
import { Button } from 'src/components/Button';
import { Drawer, Modal } from 'antd';
import DocumentationLink from 'src/components/DocumentationLink';
import { DOCUMENTATION_URL_LIST } from 'src/constants/urls';
import { isEqual } from 'lodash';
import * as ControlBox from '../../../ControlBox/ControlBox';
import Dropdown from '../../../DropDown/DropDown';
import style from './index.module.scss';
import { ElevationDifferenceMetadata } from '../../SplitView/index.types';
import ElevationDifferenceView from '../../SplitView/ElevationDifferenceView';

const elevationDifferenceAPIs = new ElevationDifferenceAPIs();

interface IProps {
  view?: View;
  splitView?: View;
  viewList?: View[];
  elevationDifferences?: ElevationDifference[];
  olView?: OLView;
  onClose?: () => void;
}

interface IState {
  processedElevationDifferenceList?: ElevationDifference[];
  elevationDifferenceMetadata?: ElevationDifferenceMetadata;
  popup: {
    requestElevationDifference?: boolean;
    notification?: {
      message: string;
      title: string;
    };
  };
  loading?: boolean;
}

class ElevationDifferenceControlBox extends React.Component<IProps, IState> {
  //   Istate
  constructor(props: IProps) {
    super(props);

    this.state = {
      processedElevationDifferenceList: [],
      popup: {},
      loading: false,
    };
  }

  public componentDidMount() {
    const { view, elevationDifferences } = this.props;

    if (!view) {
      return;
    }

    const sortedByDate = sortByDate(
      elevationDifferences as any[],
      'targetDate',
      true
    ) as ElevationDifference[];

    this.setState({
      processedElevationDifferenceList: sortedByDate,
    });
  }

  public componentDidUpdate({
    elevationDifferences: prevElevationDifferences,
  }: IProps) {
    const { elevationDifferences } = this.props;

    if (!isEqual(elevationDifferences, prevElevationDifferences)) {
      const sortedByDate = sortByDate(
        elevationDifferences as any[],
        'targetDate',
        true
      ) as ElevationDifference[];

      this.setState({
        processedElevationDifferenceList: sortedByDate,
      });
    }
  }

  private renderHeader = () => {
    const { onClose } = this.props;

    return (
      <React.Fragment>
        <ControlBox.Title title="Elevation Difference" />
        <ControlBox.Icon
          onClick={() => {
            return onClose !== undefined ? onClose() : null;
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  private showElevationDifferenceControls(): boolean {
    const { view, splitView } = this.props;

    if (
      (view && view.subType !== 'aerial' && view.subType !== 'elevation') ||
      (view && !view.demId)
    ) {
      return false;
    }

    if (
      (splitView &&
        splitView.subType !== 'aerial' &&
        splitView.subType !== 'elevation') ||
      (splitView && !splitView.demId)
    ) {
      return false;
    }

    if (view && splitView && view.demId === splitView.demId) {
      return false;
    }

    if (
      view &&
      splitView &&
      appFormatDate(view.date) === appFormatDate(splitView.date)
    ) {
      return false;
    }

    return true;
  }

  private getElevationDifferenceMetadataByViewId(
    viewId: string
  ): ElevationDifferenceMetadata | undefined {
    const { elevationDifferences, viewList } = this.props;

    if (!elevationDifferences || !viewList) {
      console.error(
        'Missing data required to fetch the Elevation Difference View'
      );

      return undefined;
    }

    const filterElevationDiffs = elevationDifferences.filter(
      (ed) => ed.viewId === viewId
    );

    if (filterElevationDiffs.length === 1) {
      const elevationDiff = filterElevationDiffs[0];

      const elevationDiffView = viewList.find((view) => {
        return (
          view.subType === 'elevation_difference' &&
          view.id === elevationDiff.viewId &&
          view.certifiedForDisplay
        );
      });

      if (elevationDiffView) {
        return {
          elevationDifferenceView: elevationDiffView,
          referenceDate: elevationDiff.sourceDate,
          targetDate: elevationDiff.targetDate,
          elevationDifference: elevationDiff,
        };
      }
    }

    return undefined;
  }

  private getElevationDifferenceMetadata(
    view1: View,
    view2: View
  ): ElevationDifferenceMetadata | undefined {
    const { elevationDifferences, viewList } = this.props;

    if (!elevationDifferences || !viewList) {
      console.error(
        'Missing data required to fetch the Elevation Difference View'
      );

      return undefined;
    }

    const filterElevationDiffs = elevationDifferences.filter((ed) => {
      if (
        view1.demId === ed.targetDemId &&
        view2.demId === ed.sourceDemId &&
        ed.requestStatus === 'published' &&
        ed.viewId
      ) {
        return true;
      }

      return false;
    });

    if (filterElevationDiffs.length === 1) {
      const elevationDiff = filterElevationDiffs[0];

      const elevationDiffView = viewList.find((view) => {
        return (
          view.subType === 'elevation_difference' &&
          view.id === elevationDiff.viewId &&
          view.certifiedForDisplay
        );
      });

      if (elevationDiffView) {
        return {
          elevationDifferenceView: elevationDiffView,
          referenceDate: view2.date,
          targetDate: view1.date,
          elevationDifference: elevationDiff,
        };
      }
    }

    return undefined;
  }

  private elevationDifferenceExists(): boolean {
    const { view, splitView } = this.props;

    if (!view || !splitView) {
      return false;
    }

    const elevationDiff = this.getElevationDifferenceMetadata(view, splitView);

    return !!elevationDiff;
  }

  private renderElevationDifferenceDrawer() {
    const { elevationDifferenceMetadata } = this.state;
    const { olView } = this.props;

    return (
      <Drawer
        className={style.drawer}
        placement="right"
        title={
          <React.Fragment>
            <span className={style.title}>Elevation Difference</span>
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.viewElevationDifference}
              toolTipPosition="right"
            />
          </React.Fragment>
        }
        maskClosable={false}
        mask={false}
        closable
        onClose={() => {
          this.controlDrawerVisibility(null);
        }}
        visible={!!elevationDifferenceMetadata}
        width="50vw"
      >
        {elevationDifferenceMetadata?.referenceDate &&
        elevationDifferenceMetadata?.targetDate ? (
          <div className={style.date}>
            <span className={style.item}>
              Target: {appFormatDate(elevationDifferenceMetadata.targetDate)}
            </span>
            <span className={style.item}>
              Reference:{' '}
              {appFormatDate(elevationDifferenceMetadata?.referenceDate)}
            </span>
          </div>
        ) : (
          <></>
        )}
        {olView && elevationDifferenceMetadata?.elevationDifferenceView ? (
          <ElevationDifferenceView
            view={elevationDifferenceMetadata.elevationDifferenceView}
            olView={olView}
          />
        ) : (
          <></>
        )}
      </Drawer>
    );
  }

  private controlDrawerVisibility = (viewId: string | null) => {
    const { view, splitView } = this.props;

    if (view && splitView && viewId) {
      this.setState({
        elevationDifferenceMetadata:
          this.getElevationDifferenceMetadataByViewId(viewId),
      });
    } else {
      this.setState({
        elevationDifferenceMetadata: undefined,
      });
    }
  };

  private requestElevationDifference(): void {
    const { view, splitView } = this.props;

    if (view && view.demId && splitView && splitView.demId) {
      const elevDiffRequest = {
        sourceDemId: splitView.demId,
        sourceDate: formatDateTime(splitView.date),
        targetDemId: view.demId,
        targetDate: formatDateTime(view.date),
      };

      // TODO: create event to send to View to handle elevation difference request instead of making an API call
      elevationDifferenceAPIs
        .requestElevationDifference(view.projectId, view.aoiId, elevDiffRequest)
        .then((res) => {
          this.setState({
            popup: {},
            loading: false,
          });

          if (res.error) {
            // TODO: replace this with snackBar
            console.error(
              'Encountered error while requesting Elevation Difference Creation',
              elevDiffRequest
            );

            this.setState((prevState) => {
              return {
                popup: {
                  ...prevState.popup,
                  notification: {
                    title: 'Error!',
                    message:
                      'There was an error while requesting an Elevation Difference. Please try again later, or contact support@aspecscire.com for assistance.',
                  },
                },
              };
            });

            return;
          }

          this.setState((prevState) => {
            return {
              popup: {
                ...prevState.popup,
                notification: {
                  title: 'Success!',
                  message:
                    'A request for the required Elevation Difference has been submitted successfully.',
                },
              },
            };
          });
        });
    }
  }

  private handleRequestPopupClose = (ok?: boolean) => {
    if (ok) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.requestElevationDifference();
        }
      );
    } else {
      this.setState((prevState) => {
        return {
          popup: {
            ...prevState.popup,
            requestElevationDifference: false,
          },
        };
      });
    }
  };

  private handleNotificationClose = () => {
    this.setState((prevState) => {
      return {
        popup: {
          ...prevState.popup,
          notification: undefined,
        },
      };
    });
  };

  private renderModals() {
    const { view, splitView } = this.props;
    const { popup, loading } = this.state;

    const { requestElevationDifference, notification } = popup;

    if (!view || !splitView) {
      return;
    }

    return (
      <React.Fragment>
        {requestElevationDifference ? (
          <Modal
            title="Confirm Request"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onCancel={() => this.handleRequestPopupClose(false)}
            className={style.popupContainer}
          >
            <p className={style.headertext}>
              Are you sure you want to request an Elevation Difference? It will
              be calculated as{' '}
              <b>
                Elevation Difference = Target Date Elevation - Reference Date
                Elevation
              </b>
              .
            </p>
            <p>
              <b>Target Date: {appFormatDate(view.date)}</b>
              <br />
              <b>Reference Date: {appFormatDate(splitView.date)}</b>
            </p>

            <div className={style.popupModalDiv}>
              <Button
                onClick={() => this.handleRequestPopupClose(false)}
                text="Cancel"
                type="secondary"
                className={style.cancelButton}
              />
              <Button
                onClick={() => this.handleRequestPopupClose(true)}
                text="Confirm"
                loading={loading}
              />
            </div>
          </Modal>
        ) : (
          <></>
        )}
        {notification?.message && notification?.title ? (
          <Modal
            title={notification.title}
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onCancel={this.handleNotificationClose}
            className={style.popupContainer}
          >
            <p className={style.headertext}>{notification.message}</p>
            <div className={style.notificationDiv}>
              <Button onClick={this.handleNotificationClose} text="Ok" />
            </div>
          </Modal>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  }

  public render(): React.ReactNode {
    const { processedElevationDifferenceList } = this.state;

    const options =
      processedElevationDifferenceList?.length !== 0 &&
      processedElevationDifferenceList !== undefined
        ? processedElevationDifferenceList
            .filter((val) => val.requestStatus === 'published')
            .map((view) => ({
              value: view.viewId,
              label: `${appFormatDate(view.targetDate)} - ${appFormatDate(
                view.sourceDate
              )}`,
            }))
        : [];

    const allOptions = [{ value: '', label: 'Select Date' }, ...options];

    return (
      <div>
        <ControlBox.Wrapper
          className={style.container}
          renderHeader={this.renderHeader}
        >
          <div className={style.item}>
            <label>Choose Date </label>
            <Dropdown
              onChange={(arg) => {
                this.controlDrawerVisibility(arg.value);
              }}
              options={allOptions}
              placeholder="Select an option"
            />
          </div>

          <div>
            {this.showElevationDifferenceControls() &&
            !this.elevationDifferenceExists() ? (
              <Button
                type="secondary"
                onClick={() => {
                  if (!this.elevationDifferenceExists()) {
                    this.setState((prevState) => {
                      return {
                        popup: {
                          ...prevState.popup,
                          requestElevationDifference: true,
                        },
                      };
                    });
                  }
                }}
                text="Request Elevation Difference"
              />
            ) : (
              <Button
                type="secondary"
                disabled
                onClick={() => {
                  if (!this.elevationDifferenceExists()) {
                    this.setState((prevState) => {
                      return {
                        popup: {
                          ...prevState.popup,
                          requestElevationDifference: true,
                        },
                      };
                    });
                  }
                }}
                text="Request Elevation Difference"
              />
            )}
          </div>
          {this.renderElevationDifferenceDrawer()}
          {this.renderModals()}
        </ControlBox.Wrapper>
      </div>
    );
  }
}
export default ElevationDifferenceControlBox;
