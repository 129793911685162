import { useMemo, useContext, createContext } from 'react';
import { MapStyleDetails } from 'src/api/mapStyle.types';
import MapStyleAPIs from '../../../api/mapStyle';

export const useMapStyleApi = (): MapStyleAPIs => {
  return useMemo(() => {
    return new MapStyleAPIs();
  }, []);
};

export const MapStyleContext = createContext<MapStyleDetails | undefined>(
  undefined
);

export const useMapStyleContext = (): MapStyleDetails | undefined => {
  return useContext(MapStyleContext);
};
