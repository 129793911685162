import { Tag } from '../../api/issue.types';
import Issue from '../../api/issue';
import { NullOrGenericObjectType } from '../../shapes/app';

const issueApi = new Issue();

const issueCreated = (id: string): {} => {
  return {
    type: 'ISSUE_CREATE_SUCCESS',
    payload: {
      id,
    },
  };
};

const issueCreateFailed = (): {} => {
  return {
    type: 'ISSUE_CREATE_FAILED',
  };
};

const issueCreateStarted = (): {} => {
  return {
    type: 'ISSUE_CREATE_STARTED',
  };
};

const issueFetched = (data: object[]): {} => {
  return {
    type: 'ISSUE_FETCH_SUCCESS',
    payload: data,
  };
};

const issueFetchFailed = (): {} => {
  return {
    type: 'ISSUE_FETCH_FAILED',
  };
};

const issueFetchStarted = (): {} => {
  return {
    type: 'ISSUE_FETCH_STARTED',
  };
};

const issueStatusFetchStarted = (): {} => {
  return {
    type: 'ISSUE_STATUS_FETCH_STARTED',
  };
};

const issueStatusFetchFailed = (): {} => {
  return {
    type: 'ISSUE_STATUS_FETCH_FAILED',
  };
};

const issueStatusFetchSuccess = (data: any): {} => {
  return {
    type: 'ISSUE_STATUS_FETCH_SUCCESS',
    payload: data,
  };
};

const commentFetchStarted = (): {} => {
  return {
    type: 'COMMENT_FETCH_STARTED',
  };
};

const commentFetched = (data: object[]): {} => {
  return {
    type: 'COMMENT_FETCH_SUCCESS',
    payload: data,
  };
};

const commentFetchFailed = (): {} => {
  return {
    type: 'COMMENT_FETCH_FAILED',
  };
};

const commentCreateStarted = (): {} => {
  return {
    type: 'COMMENT_CREATE_STARTED',
  };
};

const commentCreated = (data: object): {} => {
  return {
    type: 'COMMENT_CREATE_SUCCESS',
    payload: data,
  };
};

const commentCreateFailed = (): {} => {
  return {
    type: 'COMMENT_CREATE_FAILED',
  };
};

const issueResolveStarted = (): {} => {
  return {
    type: 'ISSUE_RESOLVE_STARTED',
  };
};

const issueResolved = (data: object): {} => {
  return {
    type: 'ISSUE_RESOLVE_SUCCESS',
    payload: data,
  };
};

const issueResolveFailed = (): {} => {
  return {
    type: 'ISSUE_RESOLVE_FAILED',
  };
};

const singleIssueFetchStarted = (): {} => {
  return {
    type: 'SINGLE_ISSUE_FETCH_STARTED',
  };
};

const singleIssueFetched = (data: object): {} => {
  return {
    type: 'SINGLE_ISSUE_FETCH_SUCCESS',
    payload: data,
  };
};

const singleIssueFetchFailed = (): {} => {
  return {
    type: 'SINGLE_ISSUE_FETCH_FAILED',
  };
};

const issueStateCleared = (): {} => {
  return {
    type: 'ISSUE_STATE_CLEARED',
  };
};

const tagsFetchStarted = (): {} => {
  return {
    type: 'TAG_FETCH_STARTED',
  };
};

const tagsFetched = (data: object[]): {} => {
  return {
    type: 'TAGS_FETCHED_SUCCESS',
    payload: data,
  };
};

const tagsFetchFailed = (): {} => {
  return {
    type: 'TAGS_FETCH_FAILED',
  };
};

const tagCreationStarted = (): {} => {
  return {
    type: 'TAG_CREATION_STARTED',
  };
};

const tagCreatedSuccess = (tag: Tag): {} => {
  return {
    type: 'TAG_CREATED_SUCCESS',
    payload: tag,
  };
};

const tagCreatedFailed = (): {} => {
  return {
    type: 'TAG_CREATED_FAILED',
  };
};

const tagDeleteStarted = (): {} => {
  return {
    type: 'TAG_DELETE_STARTED',
  };
};

const tagDeleteSuccess = (tags: string[]): {} => {
  return {
    type: 'TAG_DELETE_SUCCESS',
    payload: tags,
  };
};

const tagDeleteFailed = (): {} => {
  return {
    type: 'TAG_DELETE_FAILED',
  };
};

const resetTagsStatusMessages = (): {} => {
  return {
    type: 'TAGS_STATUS_MESSAGES_RESET',
  };
};

export const clearTagStatusMessageState =
  (): {} =>
  (dispatch: any): void => {
    dispatch(resetTagsStatusMessages());
  };

export const createTag =
  (projectId: string, tagName: string): {} =>
  (dispatch: any): void => {
    dispatch(tagCreationStarted());

    issueApi
      .createTagForProject(projectId, tagName)
      .then((response) => {
        dispatch(tagCreatedSuccess(response.data));
      })
      .catch(() => {
        dispatch(tagCreatedFailed());
      });
  };

export const deleteTags =
  (projectId: string, tagsToDelete: string[]): {} =>
  (dispatch: any): void => {
    dispatch(tagDeleteStarted());

    issueApi
      .deleteTags(projectId, tagsToDelete)
      .then(() => {
        dispatch(tagDeleteSuccess(tagsToDelete));
      })
      .catch(() => {
        dispatch(tagDeleteFailed());
      });
  };

export const fetchTagsForProject =
  (projectId: string): any =>
  (dispatch: any) => {
    dispatch(tagsFetchStarted());
    issueApi
      .fetchTagsByProject(projectId)
      .then((response) => dispatch(tagsFetched(response.data)))
      .catch(() => dispatch(tagsFetchFailed()));
  };

export const createIssue =
  (
    viewId: string,
    title: string,
    message: string,
    config: object,
    shapeGeoJson: string | null,
    screenshot?: string,
    tagIds?: string[]
  ): any =>
  (dispatch: any): Promise<NullOrGenericObjectType> => {
    dispatch(issueCreateStarted());

    return new Promise((resolve) => {
      issueApi
        .createIssue(
          viewId,
          title,
          message,
          config,
          shapeGeoJson,
          screenshot,
          tagIds
        )
        .then((data) => {
          dispatch(issueCreated(data.id));

          return resolve(data ? data.data : null);
        })
        .catch(() => {
          dispatch(issueCreateFailed());

          return resolve(null);
        });
    });
  };

export const fetchIssues =
  (
    projectId: string,
    aoiId: string,
    showResolved: boolean = false,
    offset: number = 0,
    limit: number = 10
  ): any =>
  (dispatch: any) => {
    dispatch(issueFetchStarted());
    if (aoiId !== null) {
      issueApi
        .fetchIssuesByAOI(projectId, aoiId, showResolved, offset, limit)
        .then((response) => dispatch(issueFetched(response.data)))
        .catch(() => dispatch(issueFetchFailed()));
    } else {
      issueApi
        .fetchIssuesByProject(projectId, showResolved, offset, limit)
        .then((response) => dispatch(issueFetched(response.data)))
        .catch(() => dispatch(issueFetchFailed()));
    }
  };

export const fetchIssuesStatus =
  (projectId: string, aoiId: string): any =>
  (dispatch: any) => {
    dispatch(issueStatusFetchStarted());
    if (aoiId !== null) {
      issueApi
        .fetchIssuesStatusByAOI(projectId, aoiId)
        .then((response) => dispatch(issueStatusFetchSuccess(response.data)))
        .catch(() => dispatch(issueStatusFetchFailed()));
    } else {
      issueApi
        .fetchIssuesStatusByProject(projectId)
        .then((response) => dispatch(issueStatusFetchSuccess(response.data)))
        .catch(() => dispatch(issueStatusFetchFailed()));
    }
  };

export const fetchComments =
  (issueId: string, offset: number = 0, limit: number = 10): any =>
  (dispatch: any) => {
    dispatch(commentFetchStarted());
    issueApi
      .fetchIssueComments(issueId, offset, limit)
      .then((response) => dispatch(commentFetched(response.data)))
      .catch(() => dispatch(commentFetchFailed()));
  };

export const createComment =
  (issueId: string, commentMessage: string): any =>
  (dispatch: any) => {
    dispatch(commentCreateStarted());
    issueApi
      .createComment(issueId, commentMessage)
      .then((response) => dispatch(commentCreated(response.data)))
      .catch(() => dispatch(commentCreateFailed()));
  };

export const resolveIssue =
  (issueId: string): any =>
  (dispatch: any) => {
    dispatch(issueResolveStarted());
    issueApi
      .resolveIssue(issueId)
      .then((response) => dispatch(issueResolved(response.data)))
      .catch(() => dispatch(issueResolveFailed()));
  };

export const fetchSingleIssue =
  (issueId: string): any =>
  (dispatch: any) => {
    dispatch(singleIssueFetchStarted());
    issueApi
      .fetchIssue(issueId)
      .then((response) => dispatch(singleIssueFetched(response.data)))
      .catch(() => dispatch(singleIssueFetchFailed()));
  };

export const clearIssueState = (): any => (dispatch: any) => {
  dispatch(issueStateCleared());
};
