// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VerifyEmail_container__5Skrf{width:400px;background:var(--forground);border-radius:var(--radius);border:1px solid #1b1b1b;padding:30px 20px;margin:100px auto auto}.VerifyEmail_container__5Skrf .ant-input,.VerifyEmail_container__5Skrf .ant-btn{height:50px;color:#fff}.VerifyEmail_logo__hFV4z{display:inline-block;width:100%;padding:0 15px 15px 15px;margin-left:auto;text-align:center}.VerifyEmail_logo__hFV4z img{height:50px}.VerifyEmail_VerifyEmailText__Tjj8w{padding-bottom:10px;padding-top:10px}", "",{"version":3,"sources":["webpack://./src/components/VerifyEmail/VerifyEmail.module.scss","webpack://./src/styles/scss/base/app-mixins/_login-signup-logo.scss"],"names":[],"mappings":"AAEA,8BACE,WAAA,CACA,2BAAA,CACA,2BAAA,CACA,wBAAA,CACA,iBAAA,CACA,sBAAA,CAEA,gFAEE,WAAA,CACA,UAAA,CAIJ,yBChBE,oBAAA,CACA,UAAA,CACA,wBAAA,CACA,gBAAA,CACA,iBAAA,CAEA,6BACE,WAR8B,CDqBlC,oCACE,mBAAA,CACA,gBAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  width: 400px;\n  background: var(--forground);\n  border-radius: var(--radius);\n  border: 1px solid #1b1b1b;\n  padding: 30px 20px;\n  margin: 100px auto auto;\n\n  & :global(.ant-input),\n  & :global(.ant-btn) {\n    height: 50px;\n    color: #fff;\n  }\n}\n\n.logo {\n  @include login-signup-logo();\n}\n\n.VerifyEmailText {\n  padding-bottom: 10px;\n  padding-top: 10px;\n}\n","@mixin login-signup-logo($height: 50px) {\n  display: inline-block;\n  width: 100%;\n  padding: 0 15px 15px 15px;\n  margin-left: auto;\n  text-align: center;\n\n  img {\n    height: $height;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "VerifyEmail_container__5Skrf",
	"logo": "VerifyEmail_logo__hFV4z",
	"VerifyEmailText": "VerifyEmail_VerifyEmailText__Tjj8w"
};
export default ___CSS_LOADER_EXPORT___;
