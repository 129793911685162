import classnames from 'classnames';
import _ from 'lodash';
import { Collection, Feature } from 'ol';
import { click } from 'ol/events/condition';
import GeoJSON from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import { Modify } from 'ol/interaction';
import { ModifyEvent } from 'ol/interaction/Modify';
import Select, { SelectEvent } from 'ol/interaction/Select';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { fromLonLat, toLonLat } from 'ol/proj';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style, RegularShape, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import MapView from 'ol/View';
import * as React from 'react';
import flattenChildren from 'react-flatten-children';
import FloorPlan from '../../../api/floorPlans';
import ImagesApis from '../../../api/images';
import { View } from '../../../api/views.types';
import ViewsV2Apis from '../../../api/viewsV2';
import { BASE_CAPI_URL } from '../../../constants/urls';
import { editInterior360viewUrl } from '../../../routes/urls';
import { convertToUtc } from '../../../utils/date';
import { undefinedOrNull } from '../../../utils/functs';
import { log } from '../../../utils/log';
import ContextMenu from '../../View/ViewControls/ContextMenu';
import { ContextMenuMenuOptionsTypes } from '../../View/ViewControls/ContextMenu/index.types';
import styles from './index.module.scss';
import ModalNotification from '../../ModalNotification/ModalNotification';

type Parent = 'editor' | 'viewer';
type ViewCreation = 'new' | 'old';

interface FeatureRevision {
  id: string | number | undefined;
  revision: number;
  geometry: Geometry | undefined;
}

interface IProps {
  parent: Parent;
  projectId: string;
  floorPlans?: any[];
  aoiId: string;
  planId: string;
  view: View | null;
  panoImages: any[];
  published?: boolean;
  currentPano?: any;
  className?: string;
  date?: any;
  uploadingPano?: boolean;
  updateCurrentPano: (panoImage: any) => void;
  updatePanos?: (state: { images: any[]; selected: any }) => void;
  updatePanoUploadStatus?: (uploaded: boolean) => void;
  history?: any;
  viewerYaw?: number;
}

interface IState {
  map?: Map;
  baseLayers: any[];
  pointsLayer?: VectorLayer;
  fovLayer?: VectorLayer;
  selectInteraction?: Select;
  modifyInteraction?: Modify;
  childrenWithProps?: any[] | null;
  event?: MouseEvent;
  showAlert?: boolean;
  featureWithRevisions: FeatureRevision[];
  isPanoUploaded: boolean;
  intPanoError?: any;
  nonEquirectangularModalVisibility?: any;
}

const MAP_X_EXTENT = 400;
const MAP_Y_EXTENT = 300;

const floorPlanApi = new FloorPlan();
const imagesApi = new ImagesApis();
const viewV2Api = new ViewsV2Apis();

export default class FloorMapInset extends React.Component<IProps, IState> {
  private olMapReference = React.createRef<HTMLDivElement>();

  private uploadReference = React.createRef<HTMLInputElement>();

  public constructor(props: IProps) {
    super(props);
    this.state = {
      featureWithRevisions: [],
      isPanoUploaded: false,
      baseLayers: [],
      intPanoError: '',
      nonEquirectangularModalVisibility: false,
    };
  }

  public componentDidMount(): void {
    const mapEl = this.olMapReference.current;

    if (undefinedOrNull(mapEl)) {
      return;
    }

    const olMap = new Map({
      target: mapEl,
      controls: [],
    });

    this.setState(
      {
        map: olMap,
      },
      () => {
        this.initializeMap();
      }
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const {
      planId: nextPlanId,
      panoImages: nextPanoImages,
      currentPano: nextPano,
      viewerYaw: nextYaw,
    } = nextProps;
    const { planId, panoImages, currentPano, viewerYaw: yaw } = this.props;

    if (planId !== nextPlanId) {
      this.initializeMap();
    }

    if (panoImages.length !== nextPanoImages.length) {
      log.info('new pano added');
      this.setState({ isPanoUploaded: true }, () => {
        this.initializeMap();
      });
    }

    if (currentPano !== nextPano) {
      this.drawPoints(nextPanoImages, nextPano);
    }

    if (yaw !== nextYaw) {
      const { fovLayer } = this.state;

      if (fovLayer) {
        const feature = fovLayer.getSource().getFeatures()[0];
        const styles = feature.getStyle() as Style[];

        styles.forEach((style) => {
          const img = style.getImage();
          const rotation = (((nextYaw || 0) + 180) * Math.PI) / 180;

          img.setRotation(rotation);
        });
        feature.setStyle(styles);
        fovLayer.getSource().changed();
      }
    }
  }

  // public componentDidUpdate(prevProps: any, preState: any) {
  //   const { nonEquirectangularModalVisibility } = this.state;

  //   if (nonEquirectangularModalVisibility !== preState) {
  //     return true;
  //   }
  // }

  private initializeModify = (olMap: Map, source: VectorSource) => {
    const { featureWithRevisions } = this.state;
    const { panoImages, currentPano } = this.props;

    const movableFeatures = source.getFeatures().filter((f) => {
      if (
        (panoImages || []).find((i) => i.id === f.getId())?.source === 'SYSTEM'
      ) {
        return false;
      }

      return true;
    });

    const modify = new Modify({
      features: new Collection(movableFeatures),
    });

    modify.on('modifyend', (e: ModifyEvent) => {
      const features = e.features.getArray().map((feature) => {
        return {
          id: feature.getId(),
          revision: feature.getRevision(),
          geometry: feature.getGeometry(),
        };
      });

      const [changedFeature] = _.differenceBy(
        features,
        featureWithRevisions,
        'revision'
      );

      if (!changedFeature) return;

      const finalChangedFeature = e.features
        .getArray()
        .find((feature) => feature.getId() === changedFeature.id);

      if (finalChangedFeature?.getId() === currentPano.id) {
        // update fov indicator
        const { fovLayer } = this.state;

        if (fovLayer) {
          const fovPoint: Point = fovLayer
            .getSource()
            ?.getFeatures()[0]
            ?.getGeometry() as Point;

          if (fovPoint) {
            fovPoint.setCoordinates(
              (finalChangedFeature?.getGeometry() as Point).getCoordinates()
            );
          }
        }
      }

      this.setState({ featureWithRevisions: features }, () => {
        const { view, updatePanos } = this.props;

        if (
          !undefinedOrNull(view) &&
          !undefinedOrNull(finalChangedFeature) &&
          !undefinedOrNull(updatePanos)
        ) {
          const point = finalChangedFeature.getGeometry() as Point;
          let [lng, lat] = point.getCoordinates();

          if (view?.type === 'exterior_360') {
            [lng, lat] = toLonLat(point.getCoordinates());
          } else {
            lat /= MAP_Y_EXTENT;
            lng /= MAP_X_EXTENT;
          }

          imagesApi
            .patchImage(view.id, finalChangedFeature.getId() as string, {
              latitude: lat,
              longitude: lng,
            })
            .then((res) => {
              const { error } = res;

              if (error) {
                throw error;
              }

              // updatePanos({ images: data.images, selected: currentPano });
            })
            .catch((err) => {
              log.error(err);
            });
        }
      });
    });

    olMap.addInteraction(modify);
    this.setState({ modifyInteraction: modify });
  };

  private drawPoints = (panoImages: any[], currentPano: any) => {
    // fetch all images for this floorPlan and show them using points
    const { parent, viewerYaw, view } = this.props;
    const { map, pointsLayer, fovLayer, selectInteraction, modifyInteraction } =
      this.state;

    if (panoImages.length < 1 || undefinedOrNull(map)) {
      return;
    }

    if (pointsLayer) {
      map.removeLayer(pointsLayer);
    }

    if (fovLayer) {
      map.removeLayer(fovLayer);
    }

    if (selectInteraction) {
      map.removeInteraction(selectInteraction);
    }

    if (modifyInteraction) {
      map.removeInteraction(modifyInteraction);
    }

    const features = [];

    // eslint-disable-next-line
    for (let idx = 0; idx < panoImages.length; idx += 1) {
      const image = panoImages[idx];
      let coords = [image.longitude, image.latitude];

      if (view?.type === 'exterior_360') {
        coords = fromLonLat([image.longitude, image.latitude]);
      } else {
        coords = [
          image.longitude * MAP_X_EXTENT,
          image.latitude * MAP_Y_EXTENT,
        ];
      }

      const point = new Point(coords);
      const feature = new Feature({
        geometry: point,
      });
      const style = new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: image.hidden ? '#696969' : '#0000ff',
          }),
        }),
      });

      if (parent === 'editor') {
        style.setText(
          new Text({
            text: `${idx + 1}`,
            stroke: new Stroke({
              color: 'white',
              width: 4,
            }),
          })
        );
      }

      feature.setId(image.id);
      feature.setStyle(style);

      features.push(feature);
    }

    const vectorSource = new VectorSource({
      features,
    });

    const layer = new VectorLayer({
      source: vectorSource,
      zIndex: 10,
    });

    map.addLayer(layer);

    // add fov layer
    let coords = [currentPano.longitude, currentPano.latitude];

    if (view?.type === 'exterior_360') {
      coords = fromLonLat([currentPano.longitude, currentPano.latitude]);
    } else {
      coords = [
        currentPano.longitude * MAP_X_EXTENT,
        currentPano.latitude * MAP_Y_EXTENT,
      ];
    }

    const currentPoint = new Point(coords);
    const feature = new Feature({
      geometry: currentPoint,
    });
    const fovSource = new VectorSource({ features: [feature] });
    const fovTailLayer = new VectorLayer({
      source: fovSource,
      zIndex: 5,
    });

    feature.setStyle([
      // new Style({
      //   image: new Icon({
      //     src: icon,
      //     scale: 2,
      //     rotation: (viewerYaw || 0 * Math.PI) / 180,
      //     rotateWithView: true,
      //     opacity: 0.7
      //   } as any)
      // }),
      new Style({
        image: new RegularShape({
          fill: new Fill({ color: 'rgba(255, 0, 0, 0.4)' }),
          points: 3,
          radius: 30,
          displacement: [0, -15],
          rotation: (((viewerYaw || 0) + 180) * Math.PI) / 180,
          rotateWithView: true,
          opacity: 0.7,
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
        } as any),
      }),
    ]);

    map.addLayer(fovTailLayer);

    const selectClick = new Select({
      condition: click,
      layers: [layer],
      multi: false,
      style: () => {
        return new Style({
          image: new CircleStyle({
            radius: 6,
            fill: new Fill({
              color: '#ff0000',
            }),
          }),
        });
      },
    });

    map.addInteraction(selectClick);

    // eslint-disable-next-line
    for (const feature of features) {
      if (currentPano.id === feature.getId()) {
        selectClick.getFeatures().push(feature);
        selectClick.dispatchEvent({
          type: 'select',
          selected: [feature],
          deselected: [],
        });
        break;
      }
    }

    selectClick.on('select', (e: SelectEvent) => {
      this.getPanoImageByCoordinates(e.selected[0]);
    });

    if (!this.isViewPublished()) {
      this.setState(
        {
          featureWithRevisions: features.map((feature) => ({
            id: feature.getId(),
            revision: feature.getRevision(),
            geometry: feature.getGeometry(),
          })),
          pointsLayer: layer,
          fovLayer: fovTailLayer,
          selectInteraction: selectClick,
        },
        () => {
          if (parent === 'editor') {
            this.initializeModify(map, vectorSource);
          }
        }
      );
    } else {
      this.setState({
        pointsLayer: layer,
        fovLayer: fovTailLayer,
        selectInteraction: selectClick,
      });
    }
  };

  private getPanoImageByCoordinates = (feature: Feature) => {
    const { panoImages, updateCurrentPano, parent } = this.props;

    // eslint-disable-next-line
    for (const image of panoImages) {
      if (!undefinedOrNull(feature) && feature.getId() === image.id) {
        if (parent === 'editor') {
          updateCurrentPano(image);
        } else {
          updateCurrentPano(image.id);
        }

        break;
      }
    }
  };

  private initializeMap = () => {
    // init olMap and set the source here
    const { map } = this.state;
    let { baseLayers } = this.state;
    const { projectId, aoiId, planId, view: vimanaView } = this.props;

    if (undefinedOrNull(map)) {
      console.error('Could not find reference to map.');

      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const l of baseLayers) {
      map.removeLayer(l);
    }

    baseLayers = [];

    if (vimanaView?.type === 'exterior_360') {
      const osmLayer = new TileLayer({
        source: new OSM({ opaque: true }),
      });
      const view = new MapView({
        center: [0, 0],
        zoom: 12,
      });

      map.addLayer(osmLayer);
      baseLayers.push(osmLayer);
      map.setView(view);

      // load boundary
      const { projectId, aoiId } = vimanaView;

      fetch(
        `${BASE_CAPI_URL}/v2/projects/${projectId}/aois/${aoiId}/boundary`,
        {
          method: 'GET',
          credentials: 'include',
        }
      )
        .then((x) => x.json())
        .then((geojson) => {
          const vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(geojson).map((f) => {
              f.setGeometry(
                f
                  .getGeometry()
                  ?.transform('EPSG:4326', map.getView().getProjection())
              );

              return f;
            }),
          });

          const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: (_feature: any) => {
              return new Style({
                stroke: new Stroke({
                  color: 'red',
                  width: 2,
                }),
              });
            },
          });

          // this happened in an async context, so get current base layers and update it
          const { baseLayers: currBaseLayers } = this.state;

          currBaseLayers.push(vectorLayer);
          this.setState({ baseLayers: currBaseLayers }, () => {
            map.addLayer(vectorLayer);
          });

          const feature = vectorSource.getFeatures()[0];
          const polygon = feature.getGeometry();

          view.fit(polygon?.getExtent() as any, { padding: [20, 20, 20, 20] });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const inter of map.getInteractions().getArray()) {
        inter.setActive(false);
      }

      // image
      //  an array having bottom left longitude(height), latitude(width) and top right longitude and latitude
      const extent = [0, 0, MAP_X_EXTENT, MAP_Y_EXTENT];

      // determines coordinate system for given map
      const projection = new Projection({
        code: 'floorMap-image',
        units: 'pixels',
        extent,
      });

      const source = new Static({
        url: floorPlanApi.getFloorPlanImageUrl(projectId, aoiId, planId),
        imageExtent: extent,
        projection,
      });

      const layer = new ImageLayer({
        source,
        zIndex: 0,
      });

      const view = new MapView({
        zoom: 2,
        // @ts-ignore
        constrainOnlyCenter: true,
        center: [Math.floor(extent[2] / 2), Math.floor(extent[3] / 2)],
      });

      source.on('imageloadend', () => {
        view.fit(source.getImageExtent());
      });

      map.addLayer(layer);
      baseLayers.push(layer);
      map.setView(view);
    }

    const { children } = this.props;
    const { isPanoUploaded } = this.state;

    const childrenWithProps = React.Children.map(
      flattenChildren(children),
      (child) => {
        if (React.isValidElement(child as any)) {
          return React.cloneElement(child as any, {
            olMap: map,
            isPanoUploaded,
          });
        }

        return child;
      }
    );

    this.setState({ baseLayers, childrenWithProps }, () => {
      const { panoImages, currentPano } = this.props;

      this.drawPoints(panoImages, currentPano);
    });
  };

  private createViewAndRedirectToEdit = () => {
    const { projectId, aoiId, date, planId, floorPlans } = this.props;

    if (!undefinedOrNull(date) && !undefinedOrNull(floorPlans)) {
      const floorInfo = floorPlans.find((floorPlan) => floorPlan.id === planId);

      return viewV2Api
        .postView(projectId, aoiId, {
          name: !undefinedOrNull(floorInfo) ? floorInfo.floor : '',
          type: 'interior_360',
          date: convertToUtc(date.format()),
          floorLevelId: planId,
        })
        .then((res) => {
          return res;
        });
    }

    return null;
  };

  private uploadImageApiCall = (
    file: File,
    view: any,
    viewCreated: ViewCreation
  ) => {
    const {
      panoImages,
      updatePanoUploadStatus,
      updatePanos,
      history,
      projectId,
      aoiId,
    } = this.props;
    const { event, map: olMap } = this.state;
    const form = new FormData();

    form.append('image', file);

    if (
      !undefinedOrNull(event) &&
      !undefinedOrNull(olMap) &&
      !undefinedOrNull(updatePanos)
    ) {
      const coordinates = olMap.getEventCoordinate(event);
      let [lng, lat] = coordinates;

      if (view?.type === 'exterior_360') {
        [lng, lat] = toLonLat(coordinates);
      } else {
        lng /= MAP_X_EXTENT;
        lat /= MAP_Y_EXTENT;
      }

      if (!undefinedOrNull(updatePanoUploadStatus)) {
        updatePanoUploadStatus(true);
      }

      imagesApi
        .postImageByViewId(view.id, lat, lng, form)
        .then((res) => {
          const { data, error } = res;

          if (error) {
            throw error;
          }

          updatePanos({
            images: data.images,
            selected: _.differenceBy(data.images, panoImages, 'id')[0],
          });
        })
        .catch((err) => {
          this.setState({ intPanoError: err });
        })
        .finally(() => {
          if (!undefinedOrNull(updatePanoUploadStatus)) {
            updatePanoUploadStatus(false);
          }

          if (viewCreated === 'new' && history) {
            history.push(editInterior360viewUrl(projectId, aoiId, view.id));
          }
        });
    }
  };

  private handlePanoUpload = (file: File) => {
    const { view } = this.props;

    const fr = new FileReader();

    fr.onload = () => {
      // file is loaded
      const img = new Image() as any;

      img.onload = () => {
        if (img.width === 2 * img.height) {
          if (undefinedOrNull(view)) {
            // eslint-disable-next-line
            this.createViewAndRedirectToEdit()
              ?.then((response) => {
                this.uploadImageApiCall(file, response.data, 'new');
              })
              .catch((err) => {
                log.error(err);
              });
          } else {
            this.uploadImageApiCall(file, view, 'old');
          }
        } else {
          this.setState({ nonEquirectangularModalVisibility: true }, () => {});
        }
      };

      img.src = fr.result; // is the data URL because called with readAsDataURL
    };

    fr.readAsDataURL(file); // I'm using a <input type="file"> for demonstrating
  };

  private renderContextMenu = (): React.ReactNode => {
    const menuOptionsList: ContextMenuMenuOptionsTypes[] = [];

    menuOptionsList.push({
      label: 'Add Panorama',
      name: 'add_pano',
      // eslint-disable-next-line
      onClick: (name, confirmActionValue, evt, props) => {
        this.setState(
          {
            event: evt,
          },
          () => {
            if (!undefinedOrNull(this.uploadReference.current)) {
              this.uploadReference.current.click();
            }
          }
        );
      },
    });

    return (
      <ContextMenu
        nodeSelector={`.${styles.container}`}
        key="context-menu"
        menuOptionsList={menuOptionsList}
      />
    );
  };

  private isViewPublished = () => {
    const { published } = this.props;

    return !undefinedOrNull(published) && published;
  };

  render(): React.ReactNode {
    const {
      childrenWithProps,
      intPanoError,
      nonEquirectangularModalVisibility,
    } = this.state;
    const { className, parent, uploadingPano } = this.props;

    const computedClassName = classnames(styles.container, className);

    return (
      <div id="olMap" ref={this.olMapReference} className={computedClassName}>
        {intPanoError ? (
          <ModalNotification
            notificationTitle="Error in uploading interior panorama"
            shownotificationModal={!!intPanoError}
            error={intPanoError}
            handleModalCancel={() => {
              this.setState({ intPanoError: null });
            }}
          />
        ) : null}

        {nonEquirectangularModalVisibility ? (
          <ModalNotification
            notificationTitle="Non Equirectangular Image"
            shownotificationModal={nonEquirectangularModalVisibility}
            error="You have seleced non equirectangular image. Please select equirectangular panorama image"
            handleModalCancel={() => {
              this.setState({ nonEquirectangularModalVisibility: null });
            }}
          />
        ) : null}

        {childrenWithProps}
        {parent === 'editor' && !uploadingPano && !this.isViewPublished()
          ? this.renderContextMenu()
          : null}
        {parent === 'editor' && !uploadingPano && !this.isViewPublished() ? (
          <input
            type="file"
            ref={this.uploadReference}
            accept=".jpeg,.jpg"
            hidden
            onChange={(e) => {
              const { files } = e?.target || {};

              if (files && files.length > 0) {
                const file = files[0];

                if (e.target) {
                  e.target.value = '';
                }

                this.handlePanoUpload(file);
              }
            }}
          />
        ) : null}
      </div>
    );
  }
}
