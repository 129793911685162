import * as React from 'react';
import classnames from 'classnames';
import { Redirect } from 'react-router';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Input } from 'antd';
import { Button } from '../../Button';
import styles from './index.module.scss';
import { undefinedOrNull } from '../../../utils/functs';
import { momentDateNow, formatDateForDatePicker } from '../../../utils/date';
import { TerrainMapsProps } from '../index.types';
import HelpTooltip from '../../HelpTooltip/HelpTooltip';
import View from '../../View';
import { resetBodyOverFlow, setBodyOverflow } from '../../../utils/window';
import ModalNotification from '../../ModalNotification/ModalNotification';
import { WithForm } from '../../../shapes/app';
import { MapGeneratedStatus } from '../../MapGeneratedStatus';
import LoadingOverlay from '../../LoadingOverlay';
import { DTMSource } from '../../../api/terrainMaps.types';
import { Project } from '../../../api/projects.types';
import { User } from '../../../api/auth.types';

interface IProps extends WithForm, TerrainMapsProps {
  sourceType: DTMSource;
  projectData?: Project;
  disableFinalizeBtn: () => any;
  finalizeBtnLoading: boolean;
  onFinalizeBtn: (dtmName: string) => any;
  unit: string;
  epsgCode: string;
  user?: User;
}

interface IState {
  surveyDate: string;
  generateBtnLoading: boolean;
  newDtmCreated: boolean;
  isMapFullScreen: boolean;
  showFinalizeConfirmModal: boolean;
}

class TerrainMapsTabsLayout1 extends React.PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      surveyDate: momentDateNow(),
      generateBtnLoading: false,
      newDtmCreated: false,
      isMapFullScreen: false,
      showFinalizeConfirmModal: false,
    };
  }

  public componentDidMount(): void {
    this.handleInitSurveyDate();
  }

  private handleInitSurveyDate = (): void => {
    const { dtmData, sourceType, actionType } = this.props;

    if (
      actionType === 'edit' &&
      dtmData &&
      dtmData.sourceType === sourceType &&
      !undefinedOrNull(dtmData.date)
    ) {
      this.setState({
        surveyDate: formatDateForDatePicker(dtmData.date),
      });
    }
  };

  private handleFinalizeModalShow = (): void => {
    this.setState({
      showFinalizeConfirmModal: true,
    });
  };

  private handleFinalizeModalClose = (confirm: boolean): void => {
    if (confirm) {
      this.setState({
        showFinalizeConfirmModal: false,
      });
      this.finalizeDTM();

      return;
    }

    this.setState({
      showFinalizeConfirmModal: false,
    });
  };

  private handleDateChanged = (date: any, dateString: string) => {
    this.setState({ surveyDate: dateString });
  };

  private getDefaultSurveyDate = (): any => {
    const { dtmData, sourceType } = this.props;
    const { surveyDate } = this.state;

    if (
      dtmData &&
      Object.keys(dtmData).length > 0 &&
      dtmData.sourceType === sourceType &&
      !undefinedOrNull(dtmData.date)
    ) {
      return formatDateForDatePicker(dtmData.date);
    }

    return formatDateForDatePicker(surveyDate);
  };

  private getDefaultDTMName = (): any => {
    const { dtmData, sourceType } = this.props;

    if (
      dtmData &&
      Object.keys(dtmData).length > 0 &&
      dtmData.sourceType === sourceType &&
      !undefinedOrNull(dtmData.name)
    ) {
      return dtmData.name;
    }

    return null;
  };

  private finalizeForm = (): boolean => {
    const { dtmData } = this.props;

    if (!dtmData || Object.keys(dtmData).length < 1) {
      return false;
    }

    return dtmData.finalized;
  };

  private handleMapFullScreenToggle = (): void => {
    this.setState(
      ({ isMapFullScreen }) => {
        return {
          isMapFullScreen: !isMapFullScreen,
        };
      },
      () => {
        const { isMapFullScreen } = this.state;

        if (isMapFullScreen) {
          setBodyOverflow('y');

          return;
        }

        resetBodyOverFlow('y');
      }
    );
  };

  private finalizeDTM = () => {
    const { form, onFinalizeBtn } = this.props;

    form.validateFields(['dtmName'], (err: any, values: any) => {
      if (!err) {
        onFinalizeBtn(values.dtmName);
      }
    });
  };

  private finalizeConfirmBodyText = (): string => {
    const { dtmData } = this.props;

    if (dtmData && dtmData.finalized) {
      return 'Un-publishing this Terrain Map will remove the Terrain Map from public display. Any associated Contours & Cut and Fill Reports will be un-published as well. Are you sure you want to proceed?';
    }

    return `Are you sure you want to publish the Terrain Map?`;
  };

  public render(): React.ReactNode {
    const { form, dtmData, match, disableFinalizeBtn, finalizeBtnLoading } =
      this.props;
    const { projectId, aoiId } = match.params;
    const {
      generateBtnLoading,
      newDtmCreated,
      isMapFullScreen,
      showFinalizeConfirmModal,
    } = this.state;

    const { getFieldDecorator } = form;

    if (newDtmCreated && dtmData?.id) {
      return (
        <Redirect
          to={`/project/${projectId}/aoi/${aoiId}/TerrainMaps/edit/${dtmData.id}`}
        />
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.inputFieldContainer}>
          <div className={styles.inputFieldWrapper}>
            <Form.Item label="Name">
              {getFieldDecorator('dtmName', {
                rules: [
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ],
                initialValue: this.getDefaultDTMName(),
              })(
                <Input
                  placeholder="Name"
                  style={{ margin: 0 }}
                  className={styles.inputField}
                  disabled={this.finalizeForm()}
                />
              )}
            </Form.Item>
            <HelpTooltip
              position="right"
              helpText="Name to be used for public display."
            />
          </div>
          <div className={styles.inputFieldWrapper}>
            <Form.Item label="View Date">
              {getFieldDecorator('surveyDate', {
                rules: [
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ],
                initialValue: this.getDefaultSurveyDate(),
              })(
                <DatePicker
                  allowClear={false}
                  placeholder="Select Date"
                  size="small"
                  onChange={this.handleDateChanged}
                  className={styles.inputField}
                  dropdownClassName="antCalendar"
                  disabled
                />
              )}
            </Form.Item>
            <HelpTooltip
              position="right"
              helpText="View Date to be used for public display."
            />
          </div>

          {dtmData ? (
            <div className={styles.inputFieldWrapper}>
              <Button
                className={styles.finalizeBtn}
                text={dtmData.finalized ? 'UNPUBLISH' : 'PUBLISH'}
                disabled={disableFinalizeBtn()}
                loading={finalizeBtnLoading}
                loadingText={
                  dtmData.finalized ? 'Unpublishing...' : 'Publishing...'
                }
                onClick={() => {
                  this.handleFinalizeModalShow();
                }}
              />
              {!dtmData.finalized && (
                <HelpTooltip
                  position="right"
                  helpText="Publishing the Terrain Map will make it available for public display."
                />
              )}
            </div>
          ) : null}
        </div>

        <div
          className={classnames(styles.viewContainer, {
            [styles.fullScreen]: isMapFullScreen,
          })}
        >
          {dtmData ? (
            <div className={styles.postGeneratedContainer}>
              {dtmData.status === 'error' ? (
                MapGeneratedStatus({ data: dtmData, type: 'dtm', error: true })
              ) : dtmData.status === 'completed' && dtmData.viewId ? (
                <div
                  className={classnames(styles.viewWrapper, {
                    [styles.fullScreen]: isMapFullScreen,
                  })}
                >
                  <View
                    viewId={dtmData.viewId}
                    projectId={dtmData.projectId}
                    aoiId={dtmData.aoiId}
                    onEvent={() => {}}
                    view={dtmData.generatedView}
                    history={{} as any}
                  />
                </div>
              ) : (
                MapGeneratedStatus({
                  data: dtmData,
                  type: 'dtm',
                  generating: true,
                })
              )}
            </div>
          ) : (
            MapGeneratedStatus({
              data: dtmData || null,
              type: 'dtm',
            })
          )}
        </div>
        {showFinalizeConfirmModal ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody={this.finalizeConfirmBodyText()}
            shownotificationModal
            handleModalClose={this.handleFinalizeModalClose}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}
        {generateBtnLoading || finalizeBtnLoading ? <LoadingOverlay /> : null}
      </div>
    );
  }
}

export default Form.create<IProps>()(TerrainMapsTabsLayout1);
