import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDesignViews } from '../../redux/selectors/views';
import CompareControlBox from './CompareControlBox';

const mapStateToProps = (state: any): any => {
  return {
    views: getDesignViews(state),
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return {};
};

const CompareControlBoxContainer = withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(CompareControlBox) as any
);

export default CompareControlBoxContainer;
