import * as React from 'react';
import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import CutAndFillReportCrud from './CutAndFillReportCrud';
import { Button } from '../Button';
import CutAndFillReportApis from '../../api/cutAndFillReport';
import { CutFillReport, ElevationInfo } from '../../api/cutAndFillReport.types';
import ProjectsAPI from '../../api/projects';
import { formatDateForDatePicker } from '../../utils/date';
import { GenericObjectType } from '../../shapes/app';
import TerrainMapsApis from '../../api/terrainMaps';
import ViewApis from '../../api/views';
import MeasurementApis from '../../api/Measurement';
import { AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE } from '../../constants';
import { LOG_IT } from '../../constants/env';
import { sortByDate } from '../../utils/helpers';
import DocumentationLink from '../DocumentationLink';
import { DOCUMENTATION_URL_LIST } from '../../constants/urls';
import { CutAndFillReportProps, CutAndFillReportState } from './index.types';
import { getAoiFromProjectData } from '../AoiData';

const { Title } = Typography;
const projectsApis = new ProjectsAPI();
const terrainMapsApis = new TerrainMapsApis();
const cutAndFillReportApis = new CutAndFillReportApis();
const measurementApis = new MeasurementApis();
const viewApis = new ViewApis();

class CutAndFillReport extends React.Component<
  CutAndFillReportProps,
  CutAndFillReportState
> {
  public constructor(props: CutAndFillReportProps) {
    super(props);

    this.state = {
      cutAndFillReportsListData: [],
      tableLoading: true,
    };
  }

  public UNSAFE_componentWillMount(): void {
    this.fetchCutAndFillReports();
    this.fetchDtms();
    this.fetchMeasurements();
    this.fetchViews();
    this.fetchProject();
  }

  private fetchDtms = () => {
    const { match, showSnackbar } = this.props;
    const { projectId, aoiId } = match.params;

    terrainMapsApis
      .listDtms(projectId, aoiId)
      .then((res) => {
        this.setState({
          dtmsData: res.data,
        });
      })
      .catch((e) => {
        showSnackbar({
          body: 'There was an error while trying to fetch Terrain Elevation Sources.',
          type: 'error',
        });

        this.setState({
          dtmsData: [],
        });

        if (LOG_IT) {
          console.error(e);
        }
      });
  };

  private fetchMeasurements = () => {
    const { match, showSnackbar } = this.props;
    const { aoiId } = match.params;

    measurementApis
      .fetchMeasurements(aoiId)
      .then((res) => {
        this.setState({
          measurementsData: res.data,
        });
      })
      .catch((e) => {
        showSnackbar({
          body: 'There was an error while trying to fetch Plane Elevation Sources.',
          type: 'error',
        });

        this.setState({
          measurementsData: [],
        });

        if (LOG_IT) {
          console.error('Error while fetching measurements', e);
        }
      });
  };

  private fetchProject = () => {
    const { match } = this.props;
    const { projectId } = match.params;

    projectsApis
      .getProject(projectId)
      .then((res: any) => {
        if (res && res.projectType) {
          this.setState({ projectType: res.projectType, project: res });
        }
      })
      .catch((e) => {
        if (LOG_IT) {
          console.error(e);
        }
      });
  };

  private fetchCutAndFillReports = () => {
    const { match, showSnackbar } = this.props;
    const { projectId, aoiId } = match.params;

    cutAndFillReportApis.listCutAndFillReports(projectId, aoiId).then((res) => {
      if (res.error) {
        showSnackbar({
          body: res.error,
          type: 'error',
        });
      }

      this.setState({
        cutAndFillReportsListData: sortByDate(
          res.data,
          'createdAt',
          true
        ) as CutFillReport[],
        tableLoading: false,
      });
    });
  };

  private fetchViews = () => {
    const { match, showSnackbar } = this.props;
    const { projectId, aoiId } = match.params;

    viewApis
      .getViews(projectId, aoiId)
      .then((res) => {
        this.setState({ views: res.data });
      })
      .catch((err) => {
        if (LOG_IT) {
          console.error(err);
        }

        this.setState({ views: undefined });

        showSnackbar({
          body: 'There was an error while fetching the views associated with the project.',
          type: 'error',
        });
      });
  };

  private showProjectAdminLinks = (): boolean => {
    const { user, match } = this.props;
    const { projectId } = match.params;

    if (!user) {
      return false;
    }

    if (user.staff === true) {
      return true;
    }

    if (projectId) {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of user.project_roles || []) {
        if ((role as any).project_id === projectId) {
          return (role as any).role === 'project_admin';
        }
      }
    }

    return false;
  };

  private filterDtmList = (
    list: GenericObjectType | null,
    key: string
  ): null | GenericObjectType => {
    const { dtmsData } = this.state;

    if (!dtmsData || !list) {
      return null;
    }

    const filteredDtm = dtmsData.filter(
      (a: GenericObjectType) => a.id === list[key]
    );

    return filteredDtm && filteredDtm[0] ? filteredDtm[0] : null;
  };

  private handleRowClick = (record: GenericObjectType) => {
    const { history, match } = this.props;
    const { projectId, aoiId } = match.params;

    if (!history) {
      return;
    }

    history.push(
      `/project/${projectId}/aoi/${aoiId}/CutAndFillReport/edit/${record.id}`
    );
  };

  private getElevationInfo = (): ElevationInfo[] | undefined => {
    const { dtmsData, views, measurementsData } = this.state;

    if (!dtmsData || !views || !measurementsData) {
      return undefined;
    }

    const finalizedDtms = dtmsData.filter((dtm) => dtm.finalized && dtm.viewId);
    const elevationViews = views.filter(
      (view) =>
        view.certifiedForMeasurement &&
        view.certifiedForDisplay &&
        view.demId &&
        view.subType === 'elevation'
    );

    const dtmElevationInfo = finalizedDtms.map((dtm) => {
      return {
        id: dtm.id,
        date: dtm.date,
        elevationSource: 'dtm',
        viewId: dtm.viewId,
        name: dtm.name,
      } as ElevationInfo;
    });

    const viewElevationInfo = elevationViews.map((view) => {
      return {
        id: view.demId,
        date: view.date,
        elevationSource: 'dem',
        viewId: view.id,
      } as ElevationInfo;
    });

    const measurementElevationInfo = measurementsData.map((data) => {
      return {
        id: data.id,
        name: data.name,
        elevationSource: 'measurement',
      } as ElevationInfo;
    });

    return [
      ...dtmElevationInfo,
      ...viewElevationInfo,
      ...measurementElevationInfo,
    ];
  };

  public allowCreation = (): { allowed: boolean; message?: string } => {
    const { projectData, match } = this.props;
    const { aoiId } = match.params;
    const aoi = getAoiFromProjectData(projectData, aoiId);

    if (!projectData?.epsgCode && !aoi?.boundaryId) {
      return {
        allowed: false,
        message:
          'Add EPSG code and a boundary to your project to enable this feature.',
      };
    }

    if (!projectData?.epsgCode) {
      return {
        allowed: false,
        message: 'Add EPSG code to your project to enable this feature.',
      };
    }

    if (!aoi?.boundaryId) {
      return {
        allowed: false,
        message: 'Add a boundary to enable this feature.',
      };
    }

    return { allowed: true };
  };

  public render(): React.ReactNode {
    const { actionType, match } = this.props;
    const { cutAndFillReportsListData, tableLoading, projectType, project } =
      this.state;
    const { projectId, aoiId } = match.params;
    const { allowed, message } = this.allowCreation();

    const tableColumns = [
      {
        title: 'Reference Date',
        key: 'referenceDtmDate',
        render: (value: string, list: CutFillReport) => {
          const { referenceElevationInfo } = list;

          if (referenceElevationInfo.date) {
            return formatDateForDatePicker(referenceElevationInfo.date).format(
              'll'
            );
          }

          return 'N / A';
        },
      },
      {
        title: 'Reference Source',
        key: 'referenceDtmSource',
        render: (value: string, list: CutFillReport) => {
          const { referenceElevationInfo } = list;

          switch (referenceElevationInfo.elevationSource) {
            default:
            case 'dem':
              return 'Elevation';
            case 'dtm':
              return 'Terrain';
            case 'measurement':
              return 'Plane';
          }
        },
      },
      {
        title: 'Target Date',
        key: 'targetDtmDate',
        render: (value: string, list: CutFillReport) => {
          const { targetElevationInfo } = list;

          if (targetElevationInfo.date) {
            return formatDateForDatePicker(targetElevationInfo.date).format(
              'll'
            );
          }

          return 'N / A';
        },
      },
      {
        title: 'Target Source',
        key: 'targetDtmSource',
        render: (value: string, list: CutFillReport) => {
          const { targetElevationInfo } = list;

          switch (targetElevationInfo.elevationSource) {
            default:
            case 'dem':
              return 'Elevation';
            case 'dtm':
              return 'Terrain';
            case 'measurement':
              return 'Plane';
          }
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value: string, list: any) => {
          if (list.finalized) {
            return 'PUBLISHED';
          }

          return value ? value.toUpperCase() : null;
        },
      },
    ];

    if (actionType === 'new' || actionType === 'edit') {
      return (
        <CutAndFillReportCrud
          {...this.props}
          elevationInfo={this.getElevationInfo()}
          project={project}
          projectType={projectType}
        />
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          {' '}
          <div className={styles.titleInnerWrapper}>
            <Title level={2}>Cut And Fill Reports</Title>
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.cutAndFillReports}
              toolTipPosition="right"
            />
          </div>
          {this.showProjectAdminLinks() ? (
            <Link
              className={styles.createItemLink}
              to={`/project/${projectId}/aoi/${aoiId}/CutAndFillReport/new`}
              title={allowed ? '' : message}
            >
              <Button
                transparent
                text="+ NEW CUT AND FILL REPORT"
                disabled={!allowed}
              />
            </Link>
          ) : null}
        </div>

        <Table
          locale={{ emptyText: 'No Cut & Fill Report generated yet.' }}
          onRow={(record: GenericObjectType) => {
            return {
              onClick: () => {
                this.handleRowClick(record);
              },
            };
          }}
          rowKey={(cutAndFillReportsListItem: any) =>
            cutAndFillReportsListItem.id
          }
          loading={tableLoading}
          columns={tableColumns}
          dataSource={cutAndFillReportsListData}
          pagination={{
            defaultPageSize: AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE,
          }}
        />
      </div>
    );
  }
}

export default CutAndFillReport;
