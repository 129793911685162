import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAoiBoundary, fetchProject } from '../../redux/actions/projects';
import EditAoi from '.';
import { actionShowSnackbar } from '../../redux/actions/snackbar';

const getProjectById = (state: any, projectId: string): any => {
  return state && state.projects && state.projects.data
    ? state.projects.data.find((x: any) => x.id === projectId)
    : null;
};

const getAoiById = (project: any, aoiId: string): any => {
  return project && project.aois
    ? project.aois.find((x: any) => x.id === aoiId)
    : null;
};

const mapStateToProps = (state: any, ownProps: any): any => {
  return {
    project: getProjectById(state, ownProps.projectId),
    aoi: getAoiById(getProjectById(state, ownProps.projectId), ownProps.aoiId),
    boundary: state.projects.boundary,
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      fetchProject,
      fetchAoiBoundary,
      showSnackbar: actionShowSnackbar,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAoi);
