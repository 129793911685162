import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Feature, Map, Overlay, View } from 'ol';

import { Coordinate } from 'ol/coordinate';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { StyleLike } from 'ol/style/Style';
import BaseLayer from 'ol/layer/Base';
import { Draw, Modify } from 'ol/interaction';
import GeometryType from 'ol/geom/GeometryType';
import OverlayPositioning from 'ol/OverlayPositioning';
import classnames from 'classnames';
import { fromExtent } from 'ol/geom/Polygon';
import style from './index.module.scss';

export interface MapContextInterface {
  map: Map;
  view: View;
}

export const MapContext = React.createContext<MapContextInterface>({
  map: new Map({}),
  view: new View(),
});

export const useMapContext = (): MapContextInterface => {
  return useContext(MapContext);
};

export interface VectorContextInterface {
  source: VectorSource;
  layer: VectorLayer;
}

/**
 * Context to ensure that all components have access to the same vector source
 * and layer to modify, as necessary.
 */
export const VectorContext = React.createContext<VectorContextInterface>({
  source: new VectorSource({}),
  layer: new VectorLayer({}),
});

export const useVectorContext = (): VectorContextInterface => {
  return useContext(VectorContext);
};

export interface LayerManagerInterface {
  addLayer: (layer: BaseLayer) => void;
  removeLayer: (layer: BaseLayer) => void;
}

export const useMapTargetRevCount = () => {
  const { map } = useMapContext();
  const targetRevision = useRef(0);

  useEffect(() => {
    const updateRevCount = () => {
      targetRevision.current += 1;
    };

    map.on('change:target', updateRevCount);

    return () => {
      map.un('change:target', updateRevCount);
    };
  }, []);

  return targetRevision.current;
};

export const useLayerManager = (): LayerManagerInterface => {
  const { map } = useMapContext();
  const addLayer = useMemo(() => {
    return (layer: BaseLayer) => {
      // check if map already contains the layer, before adding
      if (
        !map
          .getLayers()
          .getArray()
          .find((l) => l === layer)
      )
        map.addLayer(layer);
    };
  }, [map]);

  const removeLayer = useMemo(() => {
    return (layer: BaseLayer) => {
      // check if map contains the layer, before removing
      if (
        map
          .getLayers()
          .getArray()
          .find((l) => l === layer)
      )
        map.removeLayer(layer);
    };
  }, [map]);

  return {
    addLayer,
    removeLayer,
  };
};

export interface ViewManagerInterface {
  centerMap: (center: Coordinate) => void;
  zoomMap: (zoom: number) => void;
  fitGeometry: (geometry: SimpleGeometry, buffer?: number) => void;
}

export const useViewManager = (): ViewManagerInterface => {
  const { map, view } = useMapContext();
  const centerMap = useMemo(() => {
    return (center: Coordinate) => {
      view.setCenter(center);
    };
  }, [map, view]);

  const zoomMap = useMemo(() => {
    return (zoom: number) => {
      view.setZoom(zoom);
    };
  }, [map, view]);

  const fitGeometry = useMemo(() => {
    return (geometry: SimpleGeometry, buffer?: number) => {
      const polygon = fromExtent(geometry.getExtent());

      polygon.scale(buffer ?? 1, buffer ?? 1);
      view.fit(polygon.getExtent());
    };
  }, [map, view]);

  return {
    centerMap,
    zoomMap,
    fitGeometry,
  };
};

export interface VectorManagerInterface {
  addFeature: (feature: Feature) => void;
  addFeatures: (features: Feature[]) => void;
  removeFeature: (feature: Feature) => void;
  removeFeatures: (features: Feature[]) => void;
  clearAllFeatures: () => void;
  setStyle: (style?: StyleLike) => void;
  setZIndex: (zIndex: number) => void;
}

export interface InternalVectorManagerInterface extends VectorManagerInterface {
  source: VectorSource;
  layer: VectorLayer;
}

export const useInternalVectorManager = (): InternalVectorManagerInterface => {
  const { source, layer } = useVectorContext();

  return useMemo(() => {
    return {
      addFeature: (feature) => source.addFeature(feature),
      addFeatures: (features) => source.addFeatures(features),
      removeFeature: (feature) => {
        if (source.getFeatures().find((f) => f === feature))
          source.removeFeature(feature);
      },
      removeFeatures: (features: Feature[]) => {
        features.forEach((f) => source.removeFeature(f));
      },
      clearAllFeatures: () => source.clear(),
      setStyle: (style) => layer.setStyle(style),
      setZIndex: (zIndex) => layer.setZIndex(zIndex),
      source,
      layer,
    };
  }, [source, layer]);
};

/**
 * Hook to help manage vectors. Requires ability to:
 * - add / remove features
 * - changes styling
 * - change zIndex
 */
export const useVectorManager = (): VectorManagerInterface => {
  return useInternalVectorManager();
};

export interface InteractionState {
  draw: boolean;
  modify: boolean;
  feature: boolean;
}

export interface DrawManagerInterface
  extends Pick<VectorManagerInterface, 'setStyle'> {
  draw: Draw;
  modify: Modify;
  interactionState: { current: InteractionState };
  addDraw: () => void;
  removeDraw: () => void;
  addModify: () => void;
  removeModify: () => void;
}

export const useDrawManager = (
  geometryType: GeometryType
): DrawManagerInterface => {
  const { map } = useMapContext();
  const { source, setStyle } = useInternalVectorManager();
  const draw = useMemo(
    () => new Draw({ type: geometryType, source }),
    [geometryType, source]
  );
  const modify = useMemo(() => new Modify({ source }), [source]);
  const interactionState = useRef<InteractionState>({
    draw: false,
    modify: false,
    feature: false,
  });

  const removeDraw = useMemo(
    () => () => {
      map.removeInteraction(draw);
      interactionState.current.draw = false;
    },
    [map, draw]
  );
  const addDraw = useMemo(
    () => () => {
      map.addInteraction(draw);
      interactionState.current.draw = true;
    },
    [map, draw]
  );
  const addModify = useMemo(
    () => () => {
      map.addInteraction(modify);
      interactionState.current.modify = true;
    },
    [map, modify]
  );
  const removeModify = useMemo(
    () => () => {
      map.removeInteraction(modify);
      interactionState.current.modify = false;
    },
    [map, modify]
  );

  useEffect(() => {
    addDraw();
    addModify();

    return () => {
      removeDraw();
      removeModify();
    };
  }, [map, draw, modify]);

  const onDrawStart = useMemo(
    () => () => {
      interactionState.current.feature = true;
    },
    []
  );
  const onDrawEnd = useMemo(
    () => () => {
      interactionState.current.feature = false;
    },
    []
  );

  useEffect(() => {
    draw.on('drawstart', onDrawStart);
    draw.on('drawend', onDrawEnd);

    return () => {
      draw.un('drawend', onDrawEnd);
      draw.un('drawstart', onDrawStart);
    };
  }, [draw]);

  return {
    setStyle,
    draw,
    modify,
    interactionState,
    addDraw,
    addModify,
    removeDraw,
    removeModify,
  };
};

export const useHelpToolTip = () => {
  const element = useMemo(() => document.createElement('div'), []);
  const overlay = useMemo(
    () =>
      new Overlay({
        element,
        offset: [20, 0],
        positioning: OverlayPositioning.CENTER_LEFT,
        className: style.olTooltipContainer,
      }),
    []
  );

  useEffect(() => {
    element.className = classnames(style.olTooltip, style.hidden);

    return () => {
      element.parentNode?.removeChild(element);
    };
  }, []);

  const setElementDisplay = useMemo(
    () => (show: boolean) => {
      if (show) element.classList.remove(style.hidden);
      else element.classList.add(style.hidden);
    },
    [element]
  );

  const setHelpText = useMemo(
    () => (text: string) => {
      element.innerText = text;
    },
    [element]
  );

  const setOverlayPosition = useMemo(
    () => (position: Coordinate) => {
      overlay.setPosition(position);
    },
    [overlay]
  );

  return {
    setElementDisplay,
    setHelpText,
    setOverlayPosition,
    overlay,
  };
};
