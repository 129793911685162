import * as React from 'react';
import { Tooltip, Typography } from 'antd';
import classnames from 'classnames';
import styles from './index.module.scss';
import { GenericObjectType } from '../../../shapes/app';
import PlotGoogleMap from '../../PlotGoogleMap';
import {
  GcpMarkingEditPropsTypes,
  GcpMarkingEditStateTypes,
  GcpMarkingEditToggleNavigationTypes,
} from './index.types';
import GcpMarkingApis from '../../../api/gcpMarking';
import SkeletonLoader from '../../SkeletonLoader';
import OpenSeaDragon from '../../OpenSeaDragon';
import { PlotGoogleMapMarkerPointsTypes } from '../../PlotGoogleMap/index.types';
import { OpenSeaDragonProjectionPointsTypes } from '../../OpenSeaDragon/index.types';
import { undefinedOrNull } from '../../../utils/functs';
import { Button } from '../../Button';
import { log } from '../../../utils/log';
import GcpMarkingEditReview from '../GcpMarkingEditReview';
import LoadingOverlay from '../../LoadingOverlay';
import ModalNotification from '../../ModalNotification/ModalNotification';
import {
  APP_PRIMARY_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_COLOR,
  GOOGLE_MAP_MARKER_YELLOW_COLOR,
} from '../../../constants/colors';
import {
  findClosestLatLong,
  getMarkerPointsPos,
} from '../../PlotGoogleMap/helpers';
import Resizer from '../../Resizer';
import { ResizerOnDragPropsTypes } from '../../Resizer/index.types';
import NavigationButtons8WayController from '../../NavigationButtons8WayController';
import ImagesApis from '../../../api/images';

const imagesApis = new ImagesApis();
const gcpMarkingApis = new GcpMarkingApis();
const { Title, Text } = Typography;

class GcpMarkingEdit extends React.PureComponent<
  GcpMarkingEditPropsTypes,
  GcpMarkingEditStateTypes
> {
  private selectedActiveMarkerIndex: null | number = null;

  public constructor(props: GcpMarkingEditPropsTypes) {
    super(props);

    this.state = {
      gcpData: null,
      projectionListData: [],
      gcpImagesListData: [],
      markerPointsList: [],
      selectedMarkerIndex: null,
      estimatedProjectionListData: [],
      loadingMoreProjectionsBtn: false,
      loadingSaveProjectionBtn: false,
      loadingDeleteProjectionBtn: false,
      deleteProjectionConfirmModalData: null,
      finishMarkingGcpConfirmModal: false,
      mapGeneratedTime: Date.now(),
      isPlotGoogleMapExpanded: false,
      mapContainerStyles: {
        width: 400,
        height: '100%',
      },
      gcpNavigationNeighboursData: null,
      toggleNavigation: 'map',
    };
  }

  public async componentDidMount(): Promise<any> {
    await this.fetchImages();
    this.findGcpData();
  }

  private fetchImages = (): Promise<boolean> => {
    return new Promise((resolve) => {
      const { sessionId, showSnackbar } = this.props;

      gcpMarkingApis.listImages(sessionId).then((res) => {
        const { error: apiError, data: apiData } = res;

        if (apiError) {
          showSnackbar({
            type: 'error',
            body: apiError,
          });

          return resolve(true);
        }

        this.setState(
          () => {
            return {
              mapGeneratedTime: Date.now(),
              gcpImagesListData: apiData.images || [],
              gcpNavigationNeighboursData: apiData.neighbors || null,
            };
          },
          () => {
            this.selectedActiveMarkerIndex = null;

            return resolve(true);
          }
        );
      });
    });
  };

  private findGcpData = (): void => {
    const { gcpListData, gcpId } = this.props;

    const filteredGcpListData = gcpListData.filter(
      (item: GenericObjectType) => {
        return item.id === gcpId;
      }
    );

    this.setState(
      {
        gcpData: filteredGcpListData[0] || null,
      },
      () => {
        this.fetchProjections();
      }
    );
  };

  private fetchProjections = (): void => {
    const { gcpData } = this.state;

    if (!gcpData) {
      return;
    }

    gcpMarkingApis.listProjections(gcpData.id).then((res) => {
      const { data: apiData } = res;

      this.setState(
        () => {
          return {
            projectionListData: apiData || [],
          };
        },
        () => {
          this.setProjectionMarkers();
        }
      );
    });
  };

  private setProjectionMarkers = (): void => {
    const {
      projectionListData,
      gcpImagesListData,
      estimatedProjectionListData,
      gcpData,
      selectedMarkerIndex,
    } = this.state;

    if (
      !gcpData ||
      !projectionListData ||
      projectionListData.length < 1 ||
      !gcpImagesListData ||
      gcpImagesListData.length < 1
    ) {
      this.mapImagesListToMarkerPoints();

      this.setState(({ loadingMoreProjectionsBtn }) => {
        return {
          loadingMoreProjectionsBtn: loadingMoreProjectionsBtn
            ? false
            : loadingMoreProjectionsBtn,
        };
      });

      return;
    }

    const activeMarkersList: number[] = [];

    let mappedMarkerPointsList: PlotGoogleMapMarkerPointsTypes[] =
      gcpImagesListData.map((gItem: GenericObjectType, gIndex: number) => {
        let unselectedMarkerColor = GOOGLE_MAP_MARKER_DEFAULT_COLOR;
        let isEstimatedProjection = false;
        let imageGuid: null | string = null;

        const filteredProjectionListData = projectionListData.filter(
          (a: GenericObjectType) =>
            a.imageGuid === gItem.guid && a.gcpId === gcpData.id
        );

        const isProjection = !!(
          filteredProjectionListData && filteredProjectionListData[0]
        );

        if (isProjection) {
          // set active color for the non estimated projection
          unselectedMarkerColor = GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR;
          // eslint-disable-next-line prefer-destructuring
          imageGuid = filteredProjectionListData[0].imageGuid;
        } else if (
          estimatedProjectionListData &&
          estimatedProjectionListData.length > 0
        ) {
          const filteredEstimatedProjectionListData =
            estimatedProjectionListData.filter(
              (a: GenericObjectType) => a.imageGuid === gItem.guid
            );

          isEstimatedProjection = !!(
            filteredEstimatedProjectionListData &&
            filteredEstimatedProjectionListData[0]
          );

          if (isEstimatedProjection) {
            // set active color for the non estimated projection
            unselectedMarkerColor = GOOGLE_MAP_MARKER_YELLOW_COLOR;
            // eslint-disable-next-line prefer-destructuring
            imageGuid = filteredEstimatedProjectionListData[0].imageGuid;
          }
        }

        const isActive = isProjection || isEstimatedProjection;

        if (isActive) {
          activeMarkersList.push(gIndex);
        }

        return {
          pos: {
            lat: parseFloat(gItem.latitude),
            lng: parseFloat(gItem.longitude),
          },
          index: gIndex,
          icon: {
            selected: {
              types: ['DEFAULT'],
              color: APP_PRIMARY_COLOR,
              ignoreMarkerClusterer: false,
            },
            unselected: {
              types: ['DEFAULT'],
              color: unselectedMarkerColor,
              ignoreMarkerClusterer: false,
            },
          },
          piggyback: {
            isActive,
            isEstimatedProjection,
            imageGuid,
          },
        };
      });

    this.selectedActiveMarkerIndex = selectedMarkerIndex;

    if (undefinedOrNull(this.selectedActiveMarkerIndex)) {
      this.selectedActiveMarkerIndex = !undefinedOrNull(activeMarkersList[0])
        ? activeMarkersList[0]
        : selectedMarkerIndex;
    }

    const generatedFlagMarker = this.generateFlagMarker(
      mappedMarkerPointsList,
      activeMarkersList
    );

    if (!undefinedOrNull(generatedFlagMarker)) {
      mappedMarkerPointsList = [
        ...mappedMarkerPointsList,
        ...generatedFlagMarker,
      ];
    }

    this.setState(({ loadingMoreProjectionsBtn }) => {
      return {
        selectedMarkerIndex: this.selectedActiveMarkerIndex,
        markerPointsList: mappedMarkerPointsList,
        loadingMoreProjectionsBtn: loadingMoreProjectionsBtn
          ? false
          : loadingMoreProjectionsBtn,
      };
    });
  };

  private mapImagesListToMarkerPoints = () => {
    const { gcpImagesListData } = this.state;

    if (!gcpImagesListData || gcpImagesListData.length < 1) {
      this.setState(() => {
        return {
          markerPointsList: [],
        };
      });

      return;
    }

    let mappedMarkerPointsList: PlotGoogleMapMarkerPointsTypes[] =
      gcpImagesListData.map((a: GenericObjectType, index: number) => {
        return {
          pos: {
            lat: parseFloat(a.latitude),
            lng: parseFloat(a.longitude),
          },
          index,
          icon: {
            selected: {
              types: ['DEFAULT'],
              color: APP_PRIMARY_COLOR,
              ignoreMarkerClusterer: false,
            },
            unselected: {
              types: ['DEFAULT'],
              color: GOOGLE_MAP_MARKER_DEFAULT_COLOR,
              ignoreMarkerClusterer: false,
            },
          },
        };
      });

    const generatedFlagMarker = this.generateFlagMarker(
      mappedMarkerPointsList,
      null
    );

    if (!undefinedOrNull(generatedFlagMarker)) {
      mappedMarkerPointsList = [
        ...mappedMarkerPointsList,
        ...generatedFlagMarker,
      ];
    }

    this.setState({
      selectedMarkerIndex: this.selectedActiveMarkerIndex,
      markerPointsList: mappedMarkerPointsList,
    });
  };

  private generateFlagMarker = (
    mappedMarkerPointsList: PlotGoogleMapMarkerPointsTypes[],
    activeMarkersList: number[] | null
  ): null | PlotGoogleMapMarkerPointsTypes[] => {
    const { gcpListData } = this.props;
    const { gcpData } = this.state;

    if (undefinedOrNull(gcpListData) || undefinedOrNull(gcpData)) {
      return null;
    }

    const flagMarkers: PlotGoogleMapMarkerPointsTypes[] = gcpListData
      .filter(
        (item) =>
          !undefinedOrNull(item.latitude) && !undefinedOrNull(item.longitude)
      )
      .map((item) => {
        const pos = {
          lat: parseFloat(item.latitude),
          lng: parseFloat(item.longitude),
        };

        return {
          pos,
          index: mappedMarkerPointsList.length,
          icon: {
            selected: {
              types: ['FLAG_TRIANGLE_FILLED'],
              color: !item.markingComplete ? 'yellow' : 'blue',
              ignoreMarkerClusterer: true,
              disableClick: true,
            },
            unselected: {
              types: ['FLAG_TRIANGLE_FILLED'],
              color: !item.markingComplete ? 'yellow' : 'blue',
              ignoreMarkerClusterer: true,
              disableClick: true,
            },
          },
          piggyback: {
            gcpId: item.id,
          },
          label: {
            text: item.name,
            showMinZoom: 19,
          },
        };
      });

    if (
      !undefinedOrNull(gcpData.latitude) &&
      !undefinedOrNull(gcpData.longitude)
    ) {
      const pos = {
        lat: parseFloat(gcpData.latitude),
        lng: parseFloat(gcpData.longitude),
      };

      if (undefinedOrNull(activeMarkersList) || activeMarkersList.length < 1) {
        const closestMarkerToFlag = findClosestLatLong(
          getMarkerPointsPos(mappedMarkerPointsList),
          pos
        );

        if (!undefinedOrNull(closestMarkerToFlag)) {
          this.selectedActiveMarkerIndex = closestMarkerToFlag.index;
        }
      }
    }

    return flagMarkers;
  };

  private handleMarkerClick = (index: number) => {
    this.setState({
      selectedMarkerIndex: index,
    });
  };

  private handleSaveProjection = (
    points: null | OpenSeaDragonProjectionPointsTypes,
    projection: null | GenericObjectType,
    guid: string
  ) => {
    const { gcpId, showSnackbar, sessionId } = this.props;

    if (undefinedOrNull(points)) {
      showSnackbar({
        type: 'error',
        body: 'Mark the projection to save.',
      });

      log.error(points, 'GcpMarkingEdit -> handleSaveProjection');

      return;
    }

    if (undefinedOrNull(projection) || undefinedOrNull(projection.gcpId)) {
      this.setState({
        loadingSaveProjectionBtn: true,
      });

      const formData = {
        gcpId,
        id: '',
        imageGuid: guid,
        pixelX: points.x,
        pixelY: points.y,
      };

      gcpMarkingApis.postProjection(sessionId, gcpId, formData).then((res) => {
        const { error: apiError } = res;

        if (apiError) {
          showSnackbar({
            type: 'error',
            body: apiError,
          });

          this.setState({
            loadingSaveProjectionBtn: false,
          });

          log.error(
            apiError,
            'GcpMarkingEdit -> handleSaveProjection -> gcpMarkingApis.postProjection'
          );

          return;
        }

        this.fetchProjections();

        this.setState({
          loadingSaveProjectionBtn: false,
        });
      });

      return;
    }

    this.setState({
      loadingSaveProjectionBtn: true,
    });

    const formData = {
      ...projection,
      pixelX: points.x,
      pixelY: points.y,
    };

    gcpMarkingApis
      .updateProjection(sessionId, projection.gcpId, projection.id, formData)
      .then((res) => {
        const { error: apiError } = res;

        if (apiError) {
          this.setState({
            loadingSaveProjectionBtn: false,
          });

          showSnackbar({
            type: 'error',
            body: apiError,
          });

          log.error(
            apiError,
            'GcpMarkingEdit -> handleSaveProjection -> gcpMarkingApis.updateProjection'
          );

          return;
        }

        this.fetchProjections();

        this.setState({
          loadingSaveProjectionBtn: false,
        });
      });
  };

  private handleDeleteProjection = (projection: null | GenericObjectType) => {
    this.setState({
      deleteProjectionConfirmModalData: projection,
    });
  };

  private processDeleteProjection = (value: boolean) => {
    const {
      deleteProjectionConfirmModalData: projection,
      selectedMarkerIndex,
      estimatedProjectionListData,
      markerPointsList,
    } = this.state;

    this.setState({
      deleteProjectionConfirmModalData: null,
    });

    if (!value) {
      return;
    }

    // delete the estimated projection
    if (undefinedOrNull(projection) || undefinedOrNull(projection.gcpId)) {
      let _estimatedProjectionListData = estimatedProjectionListData;

      if (
        undefinedOrNull(markerPointsList) ||
        undefinedOrNull(selectedMarkerIndex)
      ) {
        return;
      }

      const filteredMarkerPoints = markerPointsList[selectedMarkerIndex];

      if (undefinedOrNull(filteredMarkerPoints)) {
        return;
      }

      if (!undefinedOrNull(estimatedProjectionListData)) {
        _estimatedProjectionListData = estimatedProjectionListData.filter(
          (a) => {
            if (undefinedOrNull(filteredMarkerPoints.piggyback)) {
              return false;
            }

            return a.imageGuid !== filteredMarkerPoints.piggyback.imageGuid;
          }
        );
      }

      this.setState(
        {
          estimatedProjectionListData: _estimatedProjectionListData,
          loadingDeleteProjectionBtn: false,
        },
        () => {
          this.setProjectionMarkers();
        }
      );

      return;
    }

    // else move to delete the marked projection
    this.doDeleteProjection(projection.gcpId, projection.id);
  };

  private doDeleteProjection = (gcpId: string, projectionId: string) => {
    const { showSnackbar, sessionId } = this.props;

    this.setState({
      loadingDeleteProjectionBtn: true,
    });

    gcpMarkingApis
      .deleteProjection(sessionId, gcpId, projectionId)
      .then((res) => {
        const { error: apiError } = res;

        if (apiError) {
          this.setState({
            loadingDeleteProjectionBtn: false,
          });

          showSnackbar({
            type: 'error',
            body: apiError,
          });

          log.error(
            apiError,
            'GcpMarkingEdit -> doDeleteProjection -> gcpMarkingApis.deleteProjection'
          );

          return;
        }

        showSnackbar({
          timeout: 1500,
          type: 'success',
          isModal: false,
          body: 'The projection was deleted successfully.',
        });

        this.setState(
          {
            loadingDeleteProjectionBtn: false,
          },
          () => {
            this.fetchProjections();
          }
        );
      });
  };

  private disableMoreProjectionsBtn = () => {
    const { projectionListData } = this.state;

    if (undefinedOrNull(projectionListData)) {
      return true;
    }

    return projectionListData.length < 2;
  };

  private disableAllProjectionCompletedBtn = () => {
    const { projectionListData } = this.state;

    if (undefinedOrNull(projectionListData)) {
      return true;
    }

    return projectionListData.length < 1;
  };

  private handleMoreProjectionsBtn = () => {
    const { sessionId, showSnackbar } = this.props;
    const { projectionListData } = this.state;

    if (undefinedOrNull(projectionListData)) {
      return;
    }

    const formData = {
      projections: projectionListData,
    };

    this.setState(() => {
      return {
        loadingMoreProjectionsBtn: true,
      };
    });

    gcpMarkingApis.listEstimatedProjections(sessionId, formData).then((res) => {
      const { error: apiError, data: apiData } = res;

      if (apiError) {
        this.setState(() => {
          return {
            loadingMoreProjectionsBtn: false,
          };
        });

        showSnackbar({
          type: 'error',
          body: apiError,
        });

        log.error(
          apiError,
          'GcpMarkingEdit -> handleMoreProjectionsBtn -> gcpMarkingApis.listEstimatedProjections'
        );

        return;
      }

      this.setState(
        () => {
          return {
            estimatedProjectionListData: apiData.projections || [],
          };
        },
        () => {
          this.setProjectionMarkers();
        }
      );
    });
  };

  private handleCompleteProjectionsBtnClick = () => {
    this.setState({
      finishMarkingGcpConfirmModal: true,
    });
  };

  private processFinishMarkingGcp = (value: boolean) => {
    const { onCompleteProjectionsBtn } = this.props;

    this.setState({
      finishMarkingGcpConfirmModal: false,
    });

    if (!value) {
      return;
    }

    onCompleteProjectionsBtn(true);
  };

  private getGcpCurrentImage = (): null | GenericObjectType => {
    const { gcpImagesListData, selectedMarkerIndex } = this.state;

    if (!gcpImagesListData) {
      return null;
    }

    if (selectedMarkerIndex === null) {
      return null;
    }

    return gcpImagesListData[selectedMarkerIndex]
      ? gcpImagesListData[selectedMarkerIndex]
      : null;
  };

  private getProjectionForCurrentImage = () => {
    const { projectionListData, estimatedProjectionListData } = this.state;

    const currentImage = this.getGcpCurrentImage();

    if (undefinedOrNull(projectionListData)) {
      return null;
    }

    let listArray: GenericObjectType[] = projectionListData;

    if (!undefinedOrNull(estimatedProjectionListData)) {
      listArray = [...listArray, ...estimatedProjectionListData];
    }

    if (listArray && currentImage) {
      for (let i = 0; i < listArray.length; i += 1) {
        const item = listArray[i];

        if (item.imageGuid === currentImage.guid) {
          return item;
        }
      }
    }

    return null;
  };

  private handleGoogleMapExpandButton = () => {
    const { isPlotGoogleMapExpanded } = this.state;

    this.setState(() => {
      return {
        isPlotGoogleMapExpanded: !isPlotGoogleMapExpanded,
      };
    });
  };

  private handleResizerHandler = (payload: ResizerOnDragPropsTypes) => {
    const { mapContainerStyles } = this.state;
    const { right } = payload;

    const { height } = mapContainerStyles;
    let { width } = mapContainerStyles;

    if (!undefinedOrNull(right)) {
      width = this.getAllowedGoogleMapResizeWidth(right);
    }

    this.setState(() => {
      return {
        mapContainerStyles: {
          width,
          height,
        },
      };
    });
  };

  private mapContainerResizeStyles = () => {
    const { mapContainerStyles, isPlotGoogleMapExpanded } = this.state;

    if (!isPlotGoogleMapExpanded) {
      return {};
    }

    return {
      width: mapContainerStyles.width,
      height: mapContainerStyles.height,
    };
  };

  private getAllowedGoogleMapResizeWidth = (value: number): number => {
    const minAllowedWidth = 100;
    const maxAllowedWidth: number = window.innerWidth / 2;

    if (value <= minAllowedWidth) {
      return minAllowedWidth;
    }

    if (value >= maxAllowedWidth) {
      return maxAllowedWidth;
    }

    return value;
  };

  private handleNavigationBtnClick = (nextGuid: string | null) => {
    const { gcpImagesListData } = this.state;

    if (undefinedOrNull(nextGuid) || undefinedOrNull(gcpImagesListData)) {
      return;
    }

    let nextIndex: number | null = null;

    gcpImagesListData.map((item, index) => {
      if (item.guid === nextGuid) {
        nextIndex = index;
      }

      return item;
    });

    if (undefinedOrNull(nextIndex)) {
      return;
    }

    this.handleMarkerClick(nextIndex);
  };

  private handleNavigationToggleChange = (
    value: GcpMarkingEditToggleNavigationTypes
  ) => {
    this.setState({ toggleNavigation: value });
  };

  public render(): React.ReactNode {
    const {
      sessionId,
      gcpListData,
      gcpActionType,
      onCompleteProjectionsBtn,
      boundaryListData,
    } = this.props;

    const {
      loadingDeleteProjectionBtn,
      loadingSaveProjectionBtn,
      markerPointsList,
      loadingMoreProjectionsBtn,
      gcpImagesListData,
      selectedMarkerIndex,
      projectionListData,
      deleteProjectionConfirmModalData,
      finishMarkingGcpConfirmModal,
      gcpData,
      estimatedProjectionListData,
      mapGeneratedTime,
      isPlotGoogleMapExpanded,
      gcpNavigationNeighboursData,
      toggleNavigation,
    } = this.state;

    if (!gcpData || !markerPointsList || markerPointsList.length < 1) {
      return <SkeletonLoader position="fixed" />;
    }

    const currentGcpImage = this.getGcpCurrentImage();
    const projectionForCurrentImage = this.getProjectionForCurrentImage();
    const navPoints = (gcpImagesListData || []).map((p: any) => {
      return {
        latitude: parseFloat(p.latitude),
        longitude: parseFloat(p.longitude),
        guid: p.guid || p.id,
      };
    });

    if (gcpActionType === 'review')
      return (
        <div className={styles.container}>
          <div className={styles.markingEditReviewWrapper}>
            <div className={styles.contentWrapper}>
              <Title level={2}>{`Review Projections of ${gcpData.name}`}</Title>
              <GcpMarkingEditReview
                gcpActionType={gcpActionType}
                sessionId={sessionId}
                gcpListData={gcpListData}
                gcpImagesListData={gcpImagesListData}
                projectionListData={projectionListData}
                onDeleteProjection={this.doDeleteProjection}
              />

              <div className={styles.ctaWrapper}>
                <Button
                  disabled={this.disableAllProjectionCompletedBtn()}
                  text="Finish Marking This GCP"
                  type="primary"
                  onClick={() => {
                    this.handleCompleteProjectionsBtnClick();
                  }}
                />
              </div>
            </div>
          </div>

          {finishMarkingGcpConfirmModal ? (
            <ModalNotification
              notificationTitle="Confirm action"
              notificationBody="Are you sure want to finish marking this GCP?"
              shownotificationModal
              handleModalClose={this.processFinishMarkingGcp}
              cancelButtonTitle="NO"
              okButtonTitle="YES"
              isConfirm
            />
          ) : null}
        </div>
      );

    return (
      <div className={styles.container}>
        <div
          className={classnames(styles.containerInnerWrapper, {
            [styles.splitPanes]: isPlotGoogleMapExpanded,
          })}
        >
          <Resizer
            allowedDirections={['right']}
            onDrag={this.handleResizerHandler}
            showHotspot={{
              right: isPlotGoogleMapExpanded,
            }}
          >
            <div
              style={this.mapContainerResizeStyles()}
              className={classnames(styles.mapContainer, {
                [styles.float]: !isPlotGoogleMapExpanded,
                [styles.sidePane]: isPlotGoogleMapExpanded,
              })}
            >
              <div className={styles.informationContainer}>
                <Text>
                  Total:&nbsp;
                  {gcpImagesListData ? gcpImagesListData.length : 0}
                  &nbsp;|&nbsp; Marked:&nbsp;
                  {projectionListData ? projectionListData.length : 0}
                  &nbsp;|&nbsp; Estimations:&nbsp;
                  {estimatedProjectionListData
                    ? estimatedProjectionListData.length
                    : 0}
                </Text>
              </div>

              <div
                className={classnames(styles.plotGoogleMapWrapper, {
                  [styles.sidePane]: isPlotGoogleMapExpanded,
                  hide: toggleNavigation !== 'map',
                })}
              >
                <PlotGoogleMap
                  mapGeneratedTime={mapGeneratedTime}
                  width="100%"
                  height="100%"
                  boundaryPointsList={
                    boundaryListData ? [boundaryListData] : null
                  }
                  markerPointsList={markerPointsList}
                  selectedMarkerIndex={selectedMarkerIndex}
                  onMarkerClick={this.handleMarkerClick}
                  initialMarkerPosition="center"
                  centerMapTo="boundary"
                  markerClustererMaxZoom={20}
                  initialZoom={18}
                  customActionButtonList={[
                    {
                      type: 'expand',
                      data: {
                        expandedTitle: 'Collapse the map',
                        collapsedTitle: 'Expand the map',
                      },

                      callback: this.handleGoogleMapExpandButton,
                    },
                  ]}
                  gestureHandling="greedy"
                />
              </div>
              <div
                className={classnames(styles.eightWayNavWrapper, {
                  hide: toggleNavigation !== '8-way-navigation',
                })}
              >
                <NavigationButtons8WayController
                  points={navPoints}
                  onNavigationBtnClick={this.handleNavigationBtnClick}
                  guid={currentGcpImage ? currentGcpImage.guid : null}
                />
              </div>

              <div
                className={classnames(styles.navigationToggleButtonWrapper, {
                  hide: isPlotGoogleMapExpanded,
                })}
              >
                <Tooltip placement="right" title="Navigate using map">
                  <button
                    className={classnames({
                      [styles.active]: toggleNavigation === 'map',
                    })}
                    onClick={() => {
                      this.handleNavigationToggleChange('map');
                    }}
                  >
                    <i className="fa fa-map" aria-hidden="true" />
                  </button>
                </Tooltip>
                <Tooltip placement="right" title="Navigate using joystick">
                  <button
                    className={classnames({
                      [styles.active]: toggleNavigation === '8-way-navigation',
                    })}
                    onClick={() => {
                      this.handleNavigationToggleChange('8-way-navigation');
                    }}
                    disabled={undefinedOrNull(gcpNavigationNeighboursData)}
                  >
                    <i className="fa fa-compass fa-lg" aria-hidden="true" />
                  </button>
                </Tooltip>
              </div>
            </div>
          </Resizer>

          <div
            className={classnames(styles.tilingWrapper, {
              [styles.sidePane]: isPlotGoogleMapExpanded,
            })}
          >
            {currentGcpImage ? (
              <OpenSeaDragon
                tileSrc={`${imagesApis.getImageTilesUrl(currentGcpImage.guid)}`}
                imageTileSrc={`${imagesApis.getImageTilesUrl(
                  currentGcpImage.guid,
                  null,
                  true
                )}`}
                guid={currentGcpImage.guid}
                projection={projectionForCurrentImage}
                loadingDeleteProjectionBtn={loadingDeleteProjectionBtn}
                loadingSaveProjectionBtn={loadingSaveProjectionBtn}
                onSaveProjection={this.handleSaveProjection}
                onDeleteProjection={this.handleDeleteProjection}
                allowMarking
                isMarkerSaved={
                  !undefinedOrNull(projectionForCurrentImage) &&
                  !undefinedOrNull(projectionForCurrentImage.gcpId)
                }
                showNavigationBox
                navigationCompassAltitude={currentGcpImage.elevation}
                navigationCompassRotation={currentGcpImage.gimbalYaw}
              />
            ) : (
              <div className={styles.noMarkerWrapper}>
                <Text>
                  Select any marker on the map to load the tile image.
                </Text>
              </div>
            )}
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <Button
            text="Show Remaining Projections"
            disabled={this.disableMoreProjectionsBtn()}
            onClick={() => {
              this.handleMoreProjectionsBtn();
            }}
            loading={loadingMoreProjectionsBtn}
            loadingText="Finding the Remaining Projections..."
          />

          <Button
            text="All Projections Completed"
            disabled={this.disableAllProjectionCompletedBtn()}
            className={styles.allProjectionCompletedBtn}
            onClick={() => {
              onCompleteProjectionsBtn();
            }}
          />
        </div>

        {loadingMoreProjectionsBtn ? <LoadingOverlay /> : null}

        {!undefinedOrNull(deleteProjectionConfirmModalData) ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody="Are you sure want to delete the projection?"
            shownotificationModal
            handleModalClose={this.processDeleteProjection}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}
      </div>
    );
  }
}

export default GcpMarkingEdit;
