/* eslint-disable react/no-array-index-key, max-classes-per-file */

import * as React from 'react';
import { Input, Select } from 'antd';
import style from './AddUsers.module.scss';
import { Button } from '../Button';

import { BASE_CAPI_URL } from '../../constants/urls';

interface IProps {
  projectId: string;
}

interface IState {
  users: IUser[];
  status: 'pending' | 'success' | 'error';
  error?: string;
  inviting: boolean;
  role: any[];
}

const { Option } = Select;

export default class AddUsers extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      users: [{ email: '', role: 'project_viewer' }],
      status: 'pending',
      error: '',
      inviting: false,
      role: [
        { name: 'Project Admin', id: 'project_admin' },
        { name: 'Project Viewer', id: 'project_viewer' },
        { name: 'Project Read Only', id: 'read_only' },
        { name: 'Field Staff', id: 'field_staff' },
      ],
    };
  }

  public handleUser = (users: IUser[], user: any) => {
    if (users.length > 1) {
      this.setState({
        users: users.filter((usr) => usr !== user),
      });
    }
  };

  private handleUserOnChange = () => {
    const { users } = this.state;

    this.setState({
      users,
    });
  };

  // eslint-disable-next-line react/sort-comp
  public render(): React.ReactNode {
    const { error, inviting, status, role, users } = this.state;

    if (this.state && status !== 'pending') {
      return (
        <div className={style.container}>
          {status === 'success' ? 'Users added' : `An error occurred ${error}`}
        </div>
      );
    }

    return (
      <div className={style.container}>
        <div className={style.tableDiv}>
          <table className={style.table}>
            <tbody>
              {users.map((u, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <User
                  key={index}
                  user={u}
                  btnIcon="trash"
                  role={role}
                  onBtnClick={(user: any) => {
                    this.handleUser(users, user);
                  }}
                  onChange={() => {
                    this.handleUserOnChange();
                  }}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className={style.controls}>
          <Button
            className={style.addAnotherStyle}
            onClick={this.addAnotherUser}
            text="Add Another user"
          />
          <Button
            onClick={this.addUsers}
            text="Invite"
            loading={inviting}
            loadingText="INVITING..."
          />
        </div>
      </div>
    );
  }

  private addUsers = () => {
    const { users } = this.state;
    const { projectId } = this.props;
    const usersReq = users
      .filter((u) => u.email && u.role)
      .map((u) => ({
        email: u.email && u.email.trim().toLowerCase(),
        role: u.role,
      }));

    if (!usersReq || !usersReq.length) {
      return;
    }

    this.setState({ inviting: true });
    fetch(`${BASE_CAPI_URL}/v2/projects/${projectId}/users`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        users: usersReq,
      }),
    })
      .then(() => {
        this.setState({ status: 'success', inviting: false });
      })
      .catch(() => {
        this.setState({ status: 'error', inviting: false });
      });
  };

  private addAnotherUser = () => {
    const { users } = this.state;

    users.push({ email: '', role: 'project_viewer' });
    this.setState({ users });
  };
}

interface IUserProps {
  user: IUser;
  btnIcon: string;
  role: any[];
  onBtnClick: (user: IUser, event: any) => void;
  onChange: (user: IUser) => void;
}

// eslint-disable-next-line react/no-multi-comp
class User extends React.Component<IUserProps, {}> {
  public render(): React.ReactNode {
    const { btnIcon, onChange, onBtnClick, role, user } = this.props;

    return (
      <div className={style.userContainer}>
        <table>
          <tbody>
            <tr>
              <td>
                <form
                  onSubmit={(e: any) => {
                    e.preventDefault();
                  }}
                >
                  <div
                    className={style.emailContainer}
                    style={{ height: '4em' }}
                  >
                    <label>Email-Id</label>
                    <Input
                      className={style.email}
                      value={user.email}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        user.email = event.target.value;
                        onChange(user);
                        if (!this.validateEmail(user.email)) {
                          user.error = 'email not valid';
                        } else {
                          user.error = '';
                        }
                      }}
                    />
                  </div>
                </form>
              </td>
              <td>
                <form>
                  <div className={style.roleContainer}>
                    <label>Role</label>
                    <Select
                      className={style.select}
                      value={user.role}
                      onChange={(value: string) => {
                        user.role = value;
                        onChange(user);
                      }}
                    >
                      {role.map((item: any) => {
                        return (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </form>
              </td>
              <td align-items="center">
                <div className={style.btnContainer}>
                  <button
                    className={style.button}
                    onClick={(event) => onBtnClick(user, event)}
                  >
                    <i className={`fa fa-${btnIcon}`} />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {user.error ? (
          <tr>
            <p className={style.error}>{user.error}</p>
          </tr>
        ) : (
          ''
        )}
      </div>
    );
  }

  private validateEmail(email: string): boolean {
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
  }
}

interface IUser {
  email: string;
  role: string;
  error?: string;
}
