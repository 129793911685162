import { Slider } from 'antd';
import _ from 'lodash';
import * as React from 'react';
import flattenChildren from 'react-flatten-children';
import { isNullOrUndefined } from 'util';
import { APP_BASE_URL } from '../../../../constants/urls';
import { log } from '../../../../utils/log';
import { GlobalCoordinates } from '../../index.types';
import style from './index.module.scss';

declare let $: any;
declare let Potree: any;
declare let THREE: any;
declare let proj4: any;

const CAM_COLOR_NORMAL = 0xda2929;
const CAM_COLOR_HIDDEN = 0xff00ff;
const CAM_COLOR_SELECTED = 0x0000ff;
const CAM_COLOR_HOVER = 0xffff00;
const CAM_COLOR_HIGHLIGHT = 0x00ffff;
const CAM_COLOR_USERMARKED = 0xffffff;
const CAM_COLOR_TRIANGULATED = 0x00ff00;

const LAT_LNG_TO_METER_CONV_FACTOR = 110000;
const PROJ_LAT_LNG =
  '+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees +no_defs';

interface IProps {
  viewId: string | null;
  viewConfig?: any;
  images: any[];
  selectedImageIndex: number;
  highlightImageGuids?: string[];
  triangulatedPoint?: GlobalCoordinates;
  userMarkedImageGuids?: string[];
  onImageCenterClick: (index: number) => void;
}

interface IState {
  cameraSize: number;
}

export default class PotreeInset extends React.Component<IProps, IState> {
  private potreeSelectorId = 'potree_render_area';

  private viewer: any = null;

  private imageCenterMeshes: any[] = [];

  private projection: string | null = null;

  private activeCameraUiObj: any = null;

  private selectedCameraUiObj: any = null;

  private isDragging = false;

  private raycaster = new THREE.Raycaster();

  private mouseVector = new THREE.Vector2();

  constructor(props: IProps) {
    super(props);

    this.state = {
      cameraSize: 50,
    };
  }

  public componentDidMount() {
    this.renderCloud();
    this.registerEvents();
  }

  public componentDidUpdate(prevProps: IProps, _prevState: IState) {
    const {
      viewId,
      images,
      selectedImageIndex,
      highlightImageGuids,
      viewConfig,
    } = this.props;
    const {
      viewId: prevViewId,
      images: prevImages,
      selectedImageIndex: prevIndex,
      highlightImageGuids: prevHighlightImages,
      viewConfig: prevViewConfig,
    } = prevProps;

    if (
      viewId !== prevViewId ||
      (!_.isEqual(viewConfig, prevViewConfig) && viewConfig)
    ) {
      this.clearPointClouds();
      this.renderCloud();
    } else if (
      !_.isEqual(images, prevImages) ||
      selectedImageIndex !== prevIndex ||
      !_.isEqual(highlightImageGuids, prevHighlightImages)
    ) {
      this.renderImageCenters();
    }
  }

  private registerEvents = () => {
    const potreeSelector = document.getElementById(this.potreeSelectorId);

    if (isNullOrUndefined(potreeSelector)) {
      return;
    }

    let contextmenuInterval: any = null;

    $(`#${this.potreeSelectorId}`).on('mousemove', (e: any) => {
      if (contextmenuInterval != null) {
        clearTimeout(contextmenuInterval);
        contextmenuInterval = null;
      }

      this.mouseMovedOnCanvas(e);
    });
    $(`#${this.potreeSelectorId}`).on('click', (_e: any) => {
      this.clickOnCanvas();
    });
    $(`#${this.potreeSelectorId}`).on('mousedown', () => {
      this.isDragging = true;
    });
    $(`#${this.potreeSelectorId}`).on('mouseup', () => {
      this.isDragging = false;
    });
  };

  private clearPointClouds() {
    if (this.viewer) {
      this.viewer.scene.pointclouds.forEach((layer: any) => {
        this.viewer.scene.scenePointCloud.remove(layer);
      });
      this.viewer.scene.pointclouds = [];
    }

    this.viewer = null;
  }

  private renderCloud() {
    const { viewId } = this.props;
    const viewer = new Potree.Viewer(
      document.getElementById(this.potreeSelectorId)
    );
    const light = new THREE.AmbientLight(0x404040, 10);

    viewer.scene.scene.add(light);
    viewer.setPointBudget(1 * 1000 * 1000);

    (window as any).viewer = viewer;
    this.viewer = viewer;

    if (viewId) {
      Potree.loadPointCloud(
        `${APP_BASE_URL}/capi/api/v1/images/views/${viewId}/pointcloud/cloud.js`,
        'Vimana Point Cloud Viewer',
        (pc: any) => {
          viewer.scene.addPointCloud(pc.pointcloud);
          this.projection = pc?.pointcloud?.pcoGeometry?.projection;
          this.renderImageCenters();
          if (this.hasViewConfig()) {
            this.setViewConfig();
          } else {
            viewer.setTopView();
          }
        }
      );
    } else {
      this.projection = PROJ_LAT_LNG;
      this.renderImageCenters();
      if (this.hasViewConfig()) {
        this.setViewConfig();
      } else {
        // we can't just do setTopView, because it requires a PC
        // need to compute initial view from camera locations
        this.setViewPositionFromCentroid();
      }
    }
  }

  private hasViewConfig() {
    const { viewConfig } = this.props;

    return (
      !isNullOrUndefined(viewConfig?.x) &&
      !isNullOrUndefined(viewConfig?.y) &&
      !isNullOrUndefined(viewConfig?.z) &&
      !isNullOrUndefined(viewConfig?.x2) &&
      !isNullOrUndefined(viewConfig?.y2) &&
      !isNullOrUndefined(viewConfig?.z2)
    );
  }

  private setViewConfig() {
    const { viewConfig } = this.props;
    const { viewer } = this;

    if (this.hasViewConfig()) {
      viewer.scene.view.position.set(viewConfig.x, viewConfig.y, viewConfig.z);
      viewer.scene.view.lookAt(viewConfig.x2, viewConfig.y2, viewConfig.z2);
    }
  }

  private setViewPositionFromCentroid() {
    const centers = this.imageCenterMeshes || [];

    if (centers.length) {
      // calculate centroid
      let cx = 0;
      let cy = 0;
      let cz = 0;
      let count = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const p of centers) {
        if (!p.position.x || !p.position.y || !p.position.z) {
          // eslint-disable-next-line no-continue
          continue;
        }

        cx += p.position.x;
        cy += p.position.y;
        cz += p.position.z;
        count += 1;
      }

      cx /= count;
      cy /= count;
      cz /= count;

      let maxDiffX = 0;
      let maxDiffY = 0;
      let maxDiffZ = 0;

      // finding max distance from centroid along x and y
      centers.forEach((p) => {
        if (!p.position.x || !p.position.y || !p.position.z) {
          return;
        }

        const { x, y, z } = p.position;

        const diffX = x - cx;
        const diffY = y - cy;
        const diffZ = z - cz;

        maxDiffX = Math.abs(diffX) > Math.abs(maxDiffX) ? diffX : maxDiffX;
        maxDiffY = Math.abs(diffY) > Math.abs(maxDiffY) ? diffY : maxDiffY;
        maxDiffZ = Math.abs(diffZ) > Math.abs(maxDiffZ) ? diffZ : maxDiffZ;
      });

      // setting position off by 2 * max length from centroid along X, Y
      this.viewer.scene.view.position.set(
        cx + maxDiffX * 2,
        cy + maxDiffY * 2,
        cz + Math.abs(maxDiffZ * 2)
      );
      this.viewer.scene.view.lookAt(cx, cy, cz);
    }
  }

  private getZScale(proj: string) {
    let units: string = 'm';
    const match = proj.match(/\+units\s*=\s*([^\s]+)/);

    if (match) {
      // eslint-disable-next-line prefer-destructuring
      units = match[1];
    }

    let conv = 1.0;

    if (units === 'us-ft') {
      conv = 3.2808333333;
    }

    return conv;
  }

  private renderTriangulatedPoint(
    triangulatedPoint: GlobalCoordinates,
    transformer: any,
    zscale: number
  ) {
    const point = [
      triangulatedPoint.longitude,
      triangulatedPoint.latitude,
      triangulatedPoint.elevation,
    ];
    const convertedPoint = transformer.forward(point);
    const scale =
      this.projection === PROJ_LAT_LNG ? LAT_LNG_TO_METER_CONV_FACTOR : 1.0;

    convertedPoint[0] *= scale;
    convertedPoint[1] *= scale;
    convertedPoint[2] *= zscale;

    const scenePos = new THREE.Vector3(
      convertedPoint[0],
      convertedPoint[1],
      convertedPoint[2]
    );

    let cameraUiObj = null;

    const { cameraSize } = this.state;

    const geometry = new THREE.SphereGeometry(0.5 + cameraSize / 50, 32, 32);

    const color = CAM_COLOR_TRIANGULATED;

    geometry.center();
    // we want the cone's apex at origin, by default the base is at origin
    // so flip it about x-axis
    geometry.rotateX(Math.PI);

    const material = new THREE.MeshLambertMaterial({
      color: new THREE.Color(color),
      depthTest: true,
      depthWrite: true,
    });

    cameraUiObj = new THREE.Mesh(geometry, material);
    cameraUiObj.material.color.setHex(color);

    cameraUiObj.position.copy(scenePos);
    cameraUiObj.userData = { id: 0, objModel: false };

    cameraUiObj.position.copy(scenePos);
    this.viewer.scene.scene.add(cameraUiObj);
    this.imageCenterMeshes.push(cameraUiObj);
  }

  private renderImageCenters() {
    if (this.viewer == null) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const imgMesh of this.imageCenterMeshes) {
      this.viewer.scene.scene.remove(imgMesh);
    }

    const { images, selectedImageIndex, triangulatedPoint } = this.props;

    this.imageCenterMeshes = [];
    if (!this.projection && images && images.length) {
      log.error(
        'No projection data in point cloud, cannot render image centers',
        'PotreeWrapper.renderImageCenters'
      );
      // this.setState({ showProjectionWarning: true });

      return;
    }

    // this.setState({ showProjectionWarning: false });

    const imgs = images || [];
    const centers: any[] = [];

    const transformer = proj4(PROJ_LAT_LNG, this.projection);
    let zScale = 1.0;

    if (this.projection) {
      zScale = this.getZScale(this.projection);
    }

    for (let idx = 0; idx < imgs.length; idx += 1) {
      const c = imgs[idx];
      const point = [
        parseFloat(c.longitude),
        parseFloat(c.latitude),
        c.elevation,
      ];
      const convertedPoint = transformer.forward(point);
      const scale =
        this.projection === PROJ_LAT_LNG ? LAT_LNG_TO_METER_CONV_FACTOR : 1.0;

      convertedPoint[0] *= scale;
      convertedPoint[1] *= scale;
      convertedPoint[2] *= zScale;

      const scenePos = new THREE.Vector3(
        convertedPoint[0],
        convertedPoint[1],
        convertedPoint[2]
      );

      centers.push(convertedPoint);
      let pitch = (c.gimbalPitch * Math.PI) / 180;
      let roll = (c.gimbalRoll * Math.PI) / 180;
      let yaw = (c.gimbalYaw * Math.PI) / 180;

      if (c.gimbalPitch < -85 && c.gimbalPitch > -95) {
        pitch = -Math.PI / 2;
        // if nadir image, cone won't be able to show the other rotations
        // anyway, so set them to 0 to simplify rotation
        roll = 0;
        yaw = 0;
      }

      let cameraUiObj = null;

      const { cameraSize } = this.state;

      let geometry = new THREE.ConeGeometry(
        0.5 + cameraSize / 50,
        1 + cameraSize / 25,
        32,
        1,
        false
      );

      let color = CAM_COLOR_NORMAL;

      if (this.isImageHighlighted(c.guid)) {
        color = CAM_COLOR_HIGHLIGHT;
      }

      if (this.isImageUserMarked(c.guid)) {
        color = CAM_COLOR_USERMARKED;
        geometry = new THREE.ConeGeometry(
          1 + cameraSize / 50,
          2 + cameraSize / 25,
          32,
          1,
          false
        );
      }

      if (idx === selectedImageIndex) {
        color = CAM_COLOR_SELECTED;
      } else if (c.hidden) {
        color = CAM_COLOR_HIDDEN;
      }

      geometry.center();
      // we want the cone's apex at origin, by default the base is at origin
      // so flip it about x-axis
      geometry.rotateX(Math.PI);

      const material = new THREE.MeshLambertMaterial({
        color: new THREE.Color(color),
        depthTest: true,
        depthWrite: true,
      });

      cameraUiObj = new THREE.Mesh(geometry, material);
      cameraUiObj.material.color.setHex(color);

      cameraUiObj.rotation.setFromVector3(
        new THREE.Vector3(pitch, roll, -yaw),
        'ZXY'
      );
      cameraUiObj.position.copy(scenePos);
      cameraUiObj.userData = { id: c.guid, objModel: false };

      cameraUiObj.position.copy(scenePos);
      this.viewer.scene.scene.add(cameraUiObj);
      this.imageCenterMeshes.push(cameraUiObj);

      if (idx === selectedImageIndex) {
        this.selectedCameraUiObj = cameraUiObj;
        cameraUiObj.scale.x = 2;
        cameraUiObj.scale.y = 2;
        cameraUiObj.scale.z = 2;
      }
    }

    if (triangulatedPoint !== undefined) {
      this.renderTriangulatedPoint(triangulatedPoint, transformer, zScale);
    }
  }

  private isImageHighlighted = (guid: string): boolean => {
    const { highlightImageGuids } = this.props;

    return (
      highlightImageGuids !== undefined &&
      highlightImageGuids.indexOf(guid) > -1
    );
  };

  private isImageUserMarked = (guid: string): boolean => {
    const { userMarkedImageGuids } = this.props;

    return (
      userMarkedImageGuids !== undefined &&
      userMarkedImageGuids.indexOf(guid) > -1
    );
  };

  private clickOnCanvas() {
    const guid = this.activeCameraUiObj?.userData?.id;

    const { onImageCenterClick, images } = this.props;

    if (guid && onImageCenterClick) {
      let idx = -1;
      const _images = images || [];
      const len = _images.length;

      for (let i = 0; i < len; i += 1) {
        if (_images[i].guid === guid) {
          idx = i;
          break;
        }
      }

      if (idx !== -1) {
        if (this.selectedCameraUiObj) {
          let nextColor = CAM_COLOR_NORMAL;

          if (
            this.getImageByGuid(this.selectedCameraUiObj?.userData?.id)?.hidden
          ) {
            nextColor = CAM_COLOR_HIDDEN;
          }

          this.setCameraMeterialColor(this.selectedCameraUiObj, nextColor);
          this.selectedCameraUiObj.scale.x = 1;
          this.selectedCameraUiObj.scale.y = 1;
          this.selectedCameraUiObj.scale.z = 1;
        }

        this.selectedCameraUiObj = this.imageCenterMeshes[idx];
        this.setCameraMeterialColor(
          this.selectedCameraUiObj,
          CAM_COLOR_SELECTED
        );
        this.selectedCameraUiObj.scale.x = 2;
        this.selectedCameraUiObj.scale.y = 2;
        this.selectedCameraUiObj.scale.z = 2;
        onImageCenterClick(idx);
      }
    }
  }

  public mouseMovedOnCanvas(event: any) {
    if (!this.viewer) {
      return;
    }

    if (this.isDragging) {
      if (this.activeCameraUiObj) {
        this.setActiveCamera(null);
      }

      return;
    }

    const relX = event.pageX - $('#potree_render_area').offset().left;
    const relY = event.pageY - $('#potree_render_area').offset().top;
    const { mouseVector } = this;
    const { raycaster } = this;

    mouseVector.x = 2 * (relX / $('#potree_render_area').width()) - 1;
    mouseVector.y = 1 - 2 * (relY / $('#potree_render_area').height());
    const camera = this.viewer.scene.getActiveCamera();

    raycaster.setFromCamera(mouseVector, camera);

    const intersects = raycaster.intersectObjects(
      this.viewer.scene.scene.children,
      true
    );

    if (intersects && intersects.length) {
      let { object } = intersects[0];
      let userData: any = null;

      while (userData == null && object !== null) {
        if (object.userData?.id) {
          userData = object.userData;
          break;
        }

        object = object.parent;
      }

      if (userData && userData.id) {
        this.setActiveCamera(object);
      } else {
        this.setActiveCamera(null);
      }
    } else {
      this.setActiveCamera(null);
    }
  }

  public setActiveCamera(cameraObj: any) {
    if (this.activeCameraUiObj) {
      let nextColor = this.isImageHighlighted(
        this.activeCameraUiObj?.userData?.id
      )
        ? CAM_COLOR_HIGHLIGHT
        : CAM_COLOR_NORMAL;

      nextColor = this.isImageUserMarked(this.activeCameraUiObj?.userData?.id)
        ? CAM_COLOR_USERMARKED
        : CAM_COLOR_NORMAL;

      const selectedImageGuid = this.getSelectedImageGuid();

      if (this.activeCameraUiObj?.userData?.id === selectedImageGuid) {
        nextColor = CAM_COLOR_SELECTED;
        this.activeCameraUiObj.scale.x = 2;
        this.activeCameraUiObj.scale.y = 2;
        this.activeCameraUiObj.scale.z = 2;
      } else {
        this.activeCameraUiObj.scale.x = 1;
        this.activeCameraUiObj.scale.y = 1;
        this.activeCameraUiObj.scale.z = 1;

        if (this.getImageByGuid(this.activeCameraUiObj?.userData?.id)?.hidden) {
          // is it hidden
          nextColor = CAM_COLOR_HIDDEN;
        }
      }

      this.setCameraMeterialColor(this.activeCameraUiObj, nextColor);
      this.activeCameraUiObj = null;
    }

    if (cameraObj) {
      this.setCameraMeterialColor(cameraObj, CAM_COLOR_HOVER);
    }

    this.activeCameraUiObj = cameraObj;
  }

  public setCameraMeterialColor(cameraObj: any, color: any) {
    if (!cameraObj) {
      return;
    }

    cameraObj.traverse((child: any) => {
      if (child instanceof THREE.Mesh) {
        // eslint-disable-next-line no-unused-expressions
        child?.material?.color && child.material.color.setHex(color);
      }
    });
  }

  private getSelectedImageGuid(): string | null {
    const { images, selectedImageIndex } = this.props;

    const _images = images || [];
    const selIdx = selectedImageIndex || 0;

    for (let i = 0; i < _images.length; i += 1) {
      if (i === selIdx) {
        return _images[i].guid;
      }
    }

    return null;
  }

  private getImageByGuid(guid: string): any {
    const { images } = this.props;

    const _images = images || [];

    for (let i = 0; i < _images.length; i += 1) {
      if (_images[i].guid === guid) {
        return _images[i];
      }
    }

    return null;
  }

  private handleCameraSizeChanged = (value: number) => {
    this.setState({ cameraSize: value }, () => {
      this.renderImageCenters();
    });
  };

  public render() {
    const { children } = this.props;
    const { cameraSize } = this.state;
    const elements = React.Children.map(flattenChildren(children), (child) => {
      if (React.isValidElement(child as any)) {
        return React.cloneElement(child as any, { viewer: this.viewer });
      }

      return child;
    });

    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div id={this.potreeSelectorId} />
        <div id="potree_sidebar_container" style={{ display: 'none' }} />
        {elements}
        <div className={style.slider_container}>
          <div className={style.caption}>Size</div>
          <Slider
            defaultValue={cameraSize}
            onChange={(val: number) => this.handleCameraSizeChanged(val)}
          />
        </div>
      </div>
    );
  }
}
