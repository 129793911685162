/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-use-before-define */
import XYZ from 'ol/source/XYZ';
import ImageTile from 'ol/ImageTile';
import TileState from 'ol/TileState';
import { LoadFunction, Options } from 'ol/Tile';
import { TileCoord } from 'ol/tilecoord';

import { ColorList } from 'src/constants/colors';
import { RasterTileMetadata, ValueRange } from './index.types';

// TODO: Fix this component to display elevation views, with dynamic coloring

interface DynamicColorSourceParameters {
  url: string;
  getColorRange: () => ValueRange;
  colors: ColorList;
  index: RasterTileMetadata;
  clampMin?: boolean;
  clampMax?: boolean;
}

export class DynamicColorSource extends XYZ {
  constructor({
    url,
    index,
    getColorRange,
    colors,
    clampMin,
    clampMax,
    ...opts
  }: DynamicColorSourceParameters) {
    const tileUrl = `${url}/{z}/{-y}/{x}.bin`;

    super({ url: tileUrl, ...opts });

    class DynamincDemColorTile extends DemColorTile {
      constructor(
        tileCoord: TileCoord,
        state: TileState,
        src: string,
        crossOrigin: string,
        tileLoadFunction: LoadFunction,
        opt_options?: Options
      ) {
        super(
          tileCoord,
          state,
          src,
          crossOrigin,
          tileLoadFunction,
          opt_options
        );
        this.colors = colors;
        this.index = index;
        this.colorRange = getColorRange;
        this.clampMin = !!clampMin;
        this.clampMax = !!clampMax;
      }
    }

    this.tileClass = DynamincDemColorTile as any;
  }
}
class DemColorTile extends ImageTile {
  private context: CanvasRenderingContext2D;

  private canvas: HTMLCanvasElement;

  private tileIndex: { min: any; max: any; bins: any };

  protected index: RasterTileMetadata;

  private src_: RequestInfo;

  private buff: ArrayBuffer;

  protected colorRange: () => ValueRange;

  private prevColorMin: any;

  private prevColorMax: any;

  protected clampMin: boolean = false;

  protected clampMax: boolean = false;

  protected colors: ColorList;

  constructor(
    tileCoord: TileCoord,
    state: TileState,
    src: string,
    crossOrigin: string,
    tileLoadFunction: LoadFunction,
    opt_options?: Options
  ) {
    super(tileCoord, state, src, crossOrigin, tileLoadFunction, opt_options);

    // needed to ensure that this.src_ works as expected for rendering (as per ImageTile.js)
    // this is due to declaring our own private src_ variable, to address type requirements
    this.src_ = src;
    this.initContext();
  }

  protected initContext() {
    const ctx = createCanvasContext2D(256, 256);

    if (ctx == null) {
      console.error('Could not get canvas context!');

      return;
    }

    ctx.fillStyle = 'rgba(0,0,0,0)';
    ctx.fillRect(0, 0, 256, 256);
    this.context = ctx;
    this.canvas = this.context.canvas;
  }

  getImage() {
    this.renderColors();

    return this.canvas;
  }

  async load() {
    try {
      if (this.state === TileState.ERROR) {
        this.state = TileState.IDLE;
        this.changed();
      }

      if (this.state === TileState.IDLE) {
        this.state = TileState.LOADING;
        this.changed();

        // eslint-disable-next-line prefer-const
        let [z, x, y] = this.getTileCoord();

        // eslint-disable-next-line no-bitwise
        y = (1 << z) - y - 1;

        if (
          !this.index[`${z}`] ||
          !this.index[`${z}`][`${y}`] ||
          !this.index[`${z}`][`${y}`].index[`${x}`]
        ) {
          this.signalTileError();

          return;
        }

        const { min, max, bins } = this.index[`${z}`][`${y}`].index[`${x}`];

        if (!min || !max) {
          this.signalTileError();

          return;
        }

        const res = await fetch(this.src_);
        const buff = await res.arrayBuffer();

        this.buff = buff;

        this.tileIndex = { min, max, bins };

        this.state = TileState.LOADED;
        this.changed();
      }
    } catch (e) {
      console.error('error rendering tile: ', e);
      this.signalTileError();
    }
  }

  protected renderColors() {
    const { min: colorMin, max: colorMax } = this.colorRange();

    if (this.prevColorMin === colorMin && this.prevColorMax === colorMax)
      return;
    if (colorMin >= colorMax) return;
    this.prevColorMin = colorMin;
    this.prevColorMax = colorMax;

    const { buff } = this;
    const num_bytes = buff.byteLength;
    const arr = new DataView(buff);

    const { min, max, bins } = this.tileIndex;

    // the first color maps to colorMin
    // the last color maps to colorMax
    const numColors = Object.keys(this.colors).length - 1;
    const colorRange = numColors / (colorMax - colorMin);

    const output = this.context.createImageData(256, 256);
    const outputData = output.data;

    const valRange = (max - min) / bins;

    const { clampMin } = this;
    const { clampMax } = this;

    for (let i = 0; i < num_bytes; i += 2) {
      let r;
      let g;
      let b;
      let a;
      let val;

      r = g = b = a = 0;

      val = arr.getUint16(i);

      // val == 0 is transparent
      if (val > 0) {
        val -= 1;

        let fval = min + val * valRange;

        if (fval <= colorMin) {
          if (clampMin) {
            fval = colorMin;
          } else {
            // eslint-disable-next-line no-continue
            continue;
          }
        }

        if (fval >= colorMax) {
          if (clampMax) {
            fval = colorMax;
          } else {
            // eslint-disable-next-line no-continue
            continue;
          }
        }

        const colorVal = (fval - colorMin) * colorRange;
        let colorIdx = Math.floor(colorVal);

        if (colorIdx > numColors - 1) {
          colorIdx = numColors - 1;
        }

        [r, g, b] = this.colors[colorIdx];
        a = 255;
        const [r1, g1, b1] = this.colors[colorIdx + 1];
        const frac = Math.min(1, colorVal - colorIdx);

        if (frac > 0) {
          r = Math.round(r * (1 - frac) + r1 * frac);
          g = Math.round(g * (1 - frac) + g1 * frac);
          b = Math.round(b * (1 - frac) + b1 * frac);
        }
      }

      const outputIndex = 2 * i;

      outputData[outputIndex] = r;
      outputData[outputIndex + 1] = g;
      outputData[outputIndex + 2] = b;
      outputData[outputIndex + 3] = a;
    }

    this.context.putImageData(output, 0, 0);
  }

  protected signalTileError() {
    this.state = TileState.ERROR;
    this.changed();
  }
}
function createCanvasContext2D(
  width: number,
  height: number
): CanvasRenderingContext2D | null {
  const canvas = document.createElement('canvas');

  if (width) {
    canvas.width = width;
  }

  if (height) {
    canvas.height = height;
  }

  return canvas.getContext('2d');
}
