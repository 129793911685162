import { Typography, Tabs, Switch, Modal } from 'antd';
import { GoogleApiWrapper, Map, Polygon } from 'google-maps-react';
import { Polygon as OLPolygon } from 'ol/geom';
import * as React from 'react';
import turfArea from '@turf/area';
import booleanContains from '@turf/boolean-contains';
import booleanOverlap from '@turf/boolean-overlap';
import { polygon as toPolygon } from '@turf/helpers';
import { Coordinate } from 'ol/coordinate';
import { GenericObjectType, NullOrGenericObjectType } from '../../shapes/app';
import { ReadOnlyPolygon } from '../OSMMap/ReadOnlyPolygon';
import * as toGeoJSON from './to_geojson';
import style from './BoundaryEditor.module.scss';
import ProjectAPIs from '../../api/projects';
import {
  getGoogleMapKey,
  isVimanaLite,
  undefinedOrNull,
} from '../../utils/functs';
import { APP_PRIMARY_COLOR } from '../../constants/colors';
import { log } from '../../utils/log';
import {
  BoundaryEditorPropsTypes,
  BoundaryEditorStateTypes,
} from './BoundaryEditor.types';
import { Button } from '../Button';
import { GoogleMapsPositionTypes } from '../PlotGoogleMap/index.types';
import { OSMMapContainer, VectorLayerContainer } from '../OSMMap';
import { SatelliteTiles } from '../OSMMap/SatelliteTiles';
import { DrawAndEditPolygon } from '../OSMMap/DrawAndEditPolygon';
import {
  getDefaultDrawStyle,
  getGeometryCenter,
  WEB_MERCATOR,
  WGS84,
} from '../OSMMap/utils';
import { ResetControl } from '../OSMMap/ResetControl';
import { CenterMap } from '../OSMMap/CenterMap';

const { TabPane } = Tabs;
const { Text } = Typography;
const projectApis = new ProjectAPIs();

class BoundaryEditor extends React.Component<
  BoundaryEditorPropsTypes,
  BoundaryEditorStateTypes
> {
  private uploadFile = React.createRef<HTMLInputElement>();

  private aoiBoundariesPolygonsList: GenericObjectType[] = [];

  private polgyonDataListColorDictionary = [
    '#4363d8',
    '#42d4f4',
    '#469990',
    '#f58231',
    '#800000',
    '#e6beff',
    '#aaffc3',
    '#ffe119',
    '#e6194B',
  ];

  public constructor(props: BoundaryEditorPropsTypes) {
    super(props);

    this.state = {
      tabIndex: '0',
      map: null,
      mapData: null,
      drawingManager: null,
      polygon: null,
      boundaryPoints: [],
      showPolygonsListData: true,
      showUploadBoundaryFileModal: false,
    };
    this.handleUploadClick = this.handleUploadClick.bind(this);
  }

  public componentDidMount(): void {
    const { projectId, useAoiBoundary, aoiId, previousBoundaryContents } =
      this.props;

    this.handleBoundary(projectId, useAoiBoundary, aoiId);

    if (previousBoundaryContents) {
      this.setState({
        tabIndex: '1',
      });
    }
  }

  public UNSAFE_componentWillReceiveProps({
    projectId: nextProjectId,
    useAoiBoundary: nextUseAoiBoundary,
    aoiId: nextAoiId,
    project: nextProject,
  }: Readonly<BoundaryEditorPropsTypes>): void {
    const { projectId, useAoiBoundary, aoiId, project } = this.props;

    if (
      projectId !== nextProjectId ||
      useAoiBoundary !== nextUseAoiBoundary ||
      aoiId !== nextAoiId ||
      project !== nextProject
    ) {
      this.handleBoundary(nextProjectId, nextUseAoiBoundary, nextAoiId);
    }
  }

  private handleAoiBoundaries = (
    polygonsDataList: null | GenericObjectType[]
  ) => {
    const { google } = this.props;
    const { map } = this.state;

    if (!polygonsDataList || polygonsDataList.length < 1) {
      return;
    }

    polygonsDataList.map((a, index) => {
      if (!google.maps) {
        return null;
      }

      const coord = a.features[0].geometry.coordinates[0];

      const boundaryPoints = coord.map((a: GenericObjectType) => {
        return { lat: a[1], lng: a[0] };
      });

      let strokeColor =
        this.polgyonDataListColorDictionary[
          this.polgyonDataListColorDictionary.length - 1
        ];

      if (index < this.polgyonDataListColorDictionary.length - 1) {
        strokeColor = this.polgyonDataListColorDictionary[index];
      }

      this.aoiBoundariesPolygonsList[index] = new google.maps.Polygon({
        paths: boundaryPoints,
        strokeColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: strokeColor,
        fillOpacity: 0.15,
      });

      if (!this.aoiBoundariesPolygonsList[index]) {
        return;
      }

      this.aoiBoundariesPolygonsList[index].setMap(map);

      return a;
    });
  };

  private getBounds = (polygon: GenericObjectType) => {
    const { google } = this.props;
    const { map } = this.state;

    if (!map) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();

    return polygon.getPaths().forEach((path: GenericObjectType) => {
      path.forEach((latlng: GoogleMapsPositionTypes) => {
        bounds.extend(latlng);
        map.fitBounds(bounds);
      });
    });
  };

  private handleBoundary = (
    projectId: string | undefined,
    useAoiBoundary: boolean | undefined,
    aoiId: string | undefined
  ) => {
    if (projectId && !useAoiBoundary) {
      this.setState({ boundaryFetchComplete: false });
      projectApis
        .fetchProjectBoundary(projectId)
        .then((boundaryRes) => {
          this.setState(
            {
              boundaryPoints: this.getBoundaryPoints(boundaryRes),
              boundaryGeoJson: boundaryRes,
            },
            () => {
              const { map } = this.state;

              this.syncBoundary(map);
            }
          );
        })
        .catch((err) => {
          log.error(err, 'BoundaryEditor.handleBoundary');
        })
        .finally(() => {
          this.setState({ boundaryFetchComplete: true });
        });
    } else if (projectId && aoiId && useAoiBoundary) {
      this.setState({ boundaryFetchComplete: false });
      projectApis
        .fetchAoiBoundary(projectId, aoiId)
        .then((boundaryRes) => {
          this.setState(
            {
              boundaryPoints: this.getBoundaryPoints(boundaryRes),
              boundaryGeoJson: boundaryRes,
            },
            () => {
              const { map } = this.state;

              this.syncBoundary(map);
            }
          );
        })
        .catch((err) => {
          log.error(err, 'BoundaryEditor.handleBoundary');
        })
        .finally(() => {
          this.setState({ boundaryFetchComplete: true });
        });
    }
  };

  private handleUploadClick(): void {
    this.setState({
      showUploadBoundaryFileModal: true,
    });
  }

  private closeUploadBoundaryModal = (): void => {
    this.setState({
      showUploadBoundaryFileModal: false,
    });
  };

  private selectFileForUpload = (): void => {
    const node = this.uploadFile.current;

    if (node) {
      node.click();
    }

    this.setState({
      showUploadBoundaryFileModal: false,
    });
  };

  private addPreviousBoundary(): void {
    const { map, drawingManager } = this.state;
    const { previousBoundaryContents, google } = this.props;

    if (previousBoundaryContents && map && drawingManager && google) {
      const previousBoundary: GenericObjectType = JSON.parse(
        atob(previousBoundaryContents)
      );

      if (previousBoundary.features.length > 0) {
        const coords = previousBoundary.features[0].geometry.coordinates[0];
        const latLngCoords = coords.map((crds: number[]) => {
          return { lat: crds[1], lng: crds[0] };
        });
        const polygon = new google.maps.Polygon({
          map,
          paths: latLngCoords,
          ...drawingManager.polygonOptions,
        });

        this.addPolygonToMap(polygon, drawingManager, google);
      }
    }
  }

  private setBoundaryTypeTab = (tabIndex: string) => {
    const { mapData, drawingManager, polygon, map } = this.state;

    this.setState({ tabIndex });
    this.showHelpInfo(false);

    if (!drawingManager) {
      return;
    }

    if (tabIndex === '1') {
      if (mapData) {
        mapData.setMap(null);
      }

      this.setState({ mapData: null }, () => {
        drawingManager.setMap(map);
        drawingManager.setDrawingMode('polygon');
        this.showHelpInfo(true);
        this.handleDeleteControlButton(true);
      });
    } else {
      drawingManager.setMap(null);
      if (polygon) {
        polygon.setMap(null);
      }

      this.setState({ polygon: null }, () => {
        this.handleDeleteControlButton(true);
      });
    }
  };

  private handleDeleteControlButton = (show: boolean) => {
    const deleteControl: null | HTMLElement =
      document.getElementById('deleteControl');

    if (!deleteControl) {
      return;
    }

    deleteControl.style.display = show ? 'block' : 'none';
  };

  private showHelpInfo = (show: boolean) => {
    const helpInfo: null | HTMLElement = document.getElementById('helpInfo');

    if (!helpInfo) {
      return;
    }

    helpInfo.style.display = show ? 'block' : 'none';
  };

  private handleHelpInfoTextChange = (polygonExists: boolean) => {
    const helpInfoText: HTMLElement | null =
      document.getElementById('helpInfoText');
    const helpInfoContainer: null | HTMLElement =
      document.getElementById('helpInfoContainer');

    if (!helpInfoText) {
      return;
    }

    if (!helpInfoContainer) {
      return;
    }

    helpInfoContainer.style.left = polygonExists ? '-110px' : '-120px';
    helpInfoText.innerHTML = polygonExists
      ? 'Click and drag vertex to edit Polygon.'
      : 'Click to add vertex. Double-click to close.';
  };

  private validateGeojsonFile = (geoJSON: GenericObjectType): boolean => {
    const { features } = geoJSON;
    const { boundaryGeoJson, boundaryFetchComplete } = this.state;
    const { projectId } = this.props;

    if (!boundaryFetchComplete && projectId) {
      console.error('Boundary has not been loaded yet.');

      return false;
    }

    if (!features) {
      console.error('Could not extract features.');

      return false;
    }

    if (features.length !== 1) {
      console.error('Expected only one feature.');

      return false;
    }

    const aoiFeature = features[0];
    const { geometry } = aoiFeature;

    if (!(geometry && geometry.type === 'Polygon')) {
      console.error("Expected feature to have a 'Polygon' geometry.");

      return false;
    }

    const { coordinates } = geometry;

    if (!(coordinates && coordinates.length === 1)) {
      console.error('Expected only one ring in polygon.');

      return false;
    }

    const outerRing = coordinates[0];

    if (outerRing.length <= 1) {
      console.error('Expected ring in polygon with multiple points.');

      return false;
    }

    if (
      projectId &&
      boundaryGeoJson &&
      !this.checkPolygonOverlapsBoundary(boundaryGeoJson, outerRing)
    ) {
      console.error('Expected polygon to overlap with existing boundary');

      return false;
    }

    return true;
  };

  private fileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { map } = this.state;
    const { computePolygonArea, useAoiBoundary } = this.props;
    const boundaryName = useAoiBoundary ? 'displayed' : 'project';

    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const isKML = file.name.endsWith('kml');
      const fr = new FileReader();

      fr.onloadend = (ev: any) => {
        const contents = ev.target.result;
        const { google, currentParent } = this.props;
        const { mapData: existingMapData, uploadedGeojson: existingGeojson } =
          this.state;
        const mapData = new google.maps.Data();
        let geoJSON = null;
        let fileErrorMessage = '';

        if (currentParent === 'CreateProject') {
          fileErrorMessage =
            'Invalid file. Please check that you have selected a valid KML or GEOJSON file with only one Polygon feature.';
        } else if (currentParent === 'CreateAOI') {
          fileErrorMessage = `Invalid file. Please check that you have selected a valid KML or GEOJSON file, with only one Polygon feature, that overlaps with the ${boundaryName} boundary.`;
        }

        // resetting the selected file, once parsed
        if (this.uploadFile.current) {
          this.uploadFile.current.value = '';
        }

        try {
          if (!isKML) {
            geoJSON = JSON.parse(contents);
          } else {
            const kml = new DOMParser().parseFromString(contents, 'text/xml');

            geoJSON = toGeoJSON.kml(kml);
          }

          if (isVimanaLite() ? existingGeojson : existingMapData) {
            return this.setState({
              fileError:
                'A boundary file has already been uploaded. Please delete the existing file to proceed.',
            });
          }

          if (!this.validateGeojsonFile(geoJSON)) {
            return this.setState({
              fileError: fileErrorMessage,
            });
          }

          this.setState({
            fileError: '',
            error: '',
            uploadedGeojson: geoJSON,
          });

          mapData.addGeoJson(geoJSON);
          mapData.setMap(map);
          mapData.setStyle({
            strokeColor: APP_PRIMARY_COLOR,
            strokeWeight: 4,
          });

          mapData.forEach((f: any) => {
            const bounds = new google.maps.LatLngBounds();

            f.getGeometry().forEachLatLng((pos: any) => {
              bounds.extend(pos);
            });

            if (map) {
              map.fitBounds(bounds);
            }
          });

          this.setState({
            mapData,
          });

          this.boundaryChanged(geoJSON);

          if (
            !undefinedOrNull(computePolygonArea) &&
            !undefinedOrNull(geoJSON)
          ) {
            this.calculatePolygonArea(geoJSON);
          }
        } catch (e) {
          this.setState({
            fileError:
              'Invalid file. Please check that you have selected a correct KML or GEOJSON file.',
          });
        }
      };

      fr.readAsText(file);
    }
  };

  private handlePolygonChanged = () => {
    const { geoJson, error } = isVimanaLite()
      ? this.checkOpenlayersBoundary()
      : this.checkPointsBoundary();

    this.boundaryChanged(geoJson);
    this.setState({ error });
  };

  private addPolygonToMap = (
    polygon: GenericObjectType,
    drawingManager: GenericObjectType,
    google: GenericObjectType
  ) => {
    if (!drawingManager) {
      return;
    }

    drawingManager.setDrawingMode(null);

    this.setState({ polygon }, () => {
      this.handlePolygonChanged();
    });

    google.maps.event.addListener(
      polygon.getPath(),
      'insert_at',
      this.handlePolygonChanged
    );
    google.maps.event.addListener(
      polygon.getPath(),
      'remove_at',
      this.handlePolygonChanged
    );
    google.maps.event.addListener(
      polygon.getPath(),
      'set_at',
      this.handlePolygonChanged
    );
  };

  private onMapLoaded = (mapProps: any, map: any) => {
    const {
      onLocationChange,
      hideUpload,
      previousBoundaryContents,
      polygonsDataList,
    } = this.props;

    const { google } = mapProps;

    if (undefinedOrNull(google.maps.drawing)) {
      return;
    }

    const deleteControlDiv: null | HTMLElement = document.createElement('div');
    const helpInfoDiv: null | HTMLElement = document.createElement('div');

    if (!deleteControlDiv) {
      return;
    }

    deleteControlDiv.id = 'deleteControl';
    helpInfoDiv.id = 'helpInfo';

    this.deleteControl(deleteControlDiv);
    this.helpInfo(helpInfoDiv);

    deleteControlDiv.style.display = 'block';
    helpInfoDiv.style.display = 'none';

    map.controls[google.maps.ControlPosition.TOP_CENTER].push(deleteControlDiv);
    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(helpInfoDiv);

    const polyOptions = {
      strokeWeight: 4,
      fillOpacity: 0.45,
      editable: true,
    };
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['polygon'],
      },
      polylineOptions: {
        editable: true,
      },
      rectangleOptions: polyOptions,
      circleOptions: polyOptions,
      polygonOptions: polyOptions,
    });

    drawingManager.setMap(map);

    google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (ev: any) => {
        this.addPolygonToMap(ev.overlay, drawingManager, google);
        this.handleHelpInfoTextChange(true);
      }
    );

    deleteControlDiv.addEventListener('click', () => {
      const { polygon, mapData } = this.state;

      if (mapData) {
        mapData.setMap(null);
      }

      if (polygon) {
        polygon.setMap(null);
        this.showHelpInfo(false);
      }

      this.setState({
        error: '',
        polygon: null,
        mapData: null,
        fileError: '',
      });
      this.boundaryChanged(null);
    });

    google.maps.event.addListener(drawingManager, 'drawingmode_changed', () => {
      const { polygon } = this.state;

      if (drawingManager.getDrawingMode() === 'polygon') {
        this.showHelpInfo(true);
        this.handleHelpInfoTextChange(false);
        if (polygon) {
          this.handleHelpInfoTextChange(true);
          // prevent change to polygon drawingmode as polygon already exists
          drawingManager.setDrawingMode(null);
        }
      }
    });

    google.maps.event.addListener(map, 'center_changed', () => {
      const c = map.getCenter();
      const pos = { latitude: c.lat(), longitude: c.lng() };

      if (onLocationChange) {
        onLocationChange(pos);
      }
    });

    this.setState({ map, drawingManager }, () => {
      this.addPreviousBoundary();
    });
    if (hideUpload || !previousBoundaryContents) {
      this.setBoundaryTypeTab(hideUpload ? '1' : '0');
    }

    this.syncBoundary(map);

    this.handleAoiBoundaries(polygonsDataList || null);
  };

  private deleteControl = (
    deleteControlDiv: GenericObjectType
  ): NullOrGenericObjectType => {
    const controlUI: NullOrGenericObjectType = document.createElement('div');

    if (!controlUI) {
      return null;
    }

    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '2px solid #fff';
    controlUI.style.width = '24px';
    controlUI.style.height = '24px';
    controlUI.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginTop = '5px';
    controlUI.style.marginLeft = '-5px';
    controlUI.style.textAlign = 'center';
    controlUI.title = 'Click to delete the drawn boundary';
    deleteControlDiv.appendChild(controlUI);

    const controlText = document.createElement('div');

    controlText.style.color = 'rgb(25,25,25)';
    controlText.style.fontSize = '14px';
    controlText.innerHTML =
      '<div class="fa fa-trash-o" style="color: #000;" aria-hidden="true" />';
    controlUI.appendChild(controlText);

    return controlUI;
  };

  private helpInfo = (
    helpInfoDiv: GenericObjectType
  ): NullOrGenericObjectType => {
    const controlUI: HTMLDivElement | null = document.createElement('div');

    if (!controlUI) {
      return null;
    }

    controlUI.id = 'helpInfoContainer';
    controlUI.style.backgroundColor = 'rgba(102, 102, 102, 0.8)';
    controlUI.style.border = '2px solid rgba(102, 102, 102, 0.8)';
    controlUI.style.width = 'max-content';
    controlUI.style.height = '24px';
    controlUI.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginTop = '5px';
    controlUI.style.marginLeft = '-5px';
    controlUI.style.textAlign = 'center';
    controlUI.style.position = 'absolute';
    controlUI.style.bottom = '15px';
    controlUI.style.left = '-120px';
    controlUI.style.borderRadius = '20px';
    controlUI.style.padding = '0px 10px';

    controlUI.title = 'Need help?';
    helpInfoDiv.appendChild(controlUI);

    const controlText = document.createElement('div');

    controlText.style.color = 'rgba(255, 255, 255, 0.77)';
    controlText.style.fontSize = '16px';
    controlText.id = 'helpInfoText';
    controlText.innerHTML =
      '<div aria-hidden="true">Click to add vertex. Double-click to close.</div>';
    controlUI.appendChild(controlText);

    return controlUI;
  };

  private syncBoundary(map: any): void {
    const { google } = this.props;
    const { boundaryPoints } = this.state;

    if (map) {
      const bounds = new google.maps.LatLngBounds();

      // eslint-disable-next-line no-restricted-syntax
      for (const point of boundaryPoints || []) {
        bounds.extend({ lat: point.lat, lng: point.lng });
      }

      if (boundaryPoints && boundaryPoints.length) {
        // only fit bounds if boundary points exist
        map.fitBounds(bounds);
      }
    }

    if (boundaryPoints && boundaryPoints.length) {
      this.setState({
        boundaryGeometryAndBuffer: {
          geometry: new OLPolygon([
            boundaryPoints.map((p) => [p.lng, p.lat]),
          ]).transform(WGS84, WEB_MERCATOR) as OLPolygon,
          scaleBuffer: 1.2,
        },
      });
    }
  }

  private checkPointsBoundary = (): { geoJson: any; error?: any } => {
    const { useAoiBoundary } = this.props;
    const { boundaryGeoJson, polygon } = this.state;
    const boundaryName = useAoiBoundary ? 'displayed' : 'project';

    if (this.state && polygon) {
      const posArr = polygon.getPath().getArray();
      const points = posArr.map((point: any) => [point.lng(), point.lat()]);

      if (boundaryGeoJson) {
        if (!this.checkPolygonOverlapsBoundary(boundaryGeoJson, points)) {
          return {
            geoJson: null,
            error: `Polygon must overlap with the ${boundaryName} boundary.`,
          };
        }
      }

      if (
        points[0][0] !== points[points.length - 1][0] ||
        points[0][1] !== points[points.length - 1][1]
      ) {
        // append first point as last point, to close polygon
        points.push([posArr[0].lng(), posArr[0].lat()]);
      }

      const geoJSON = this.createGeoJson(points);

      return { geoJson: geoJSON, error: null };
    }

    return { geoJson: null, error: null };
  };

  private checkOpenlayersBoundary = () => {
    const { useAoiBoundary } = this.props;
    const { olGeojson, boundaryGeoJson } = this.state;
    const boundaryName = useAoiBoundary ? 'displayed' : 'project';

    if (!olGeojson) return { geoJson: null, error: null };

    const feature = JSON.parse(olGeojson);
    const points = feature.geometry.coordinates[0];

    if (boundaryGeoJson) {
      if (!this.checkPolygonOverlapsBoundary(boundaryGeoJson, points)) {
        return {
          geoJson: null,
          error: `Polygon must overlap with the ${boundaryName} boundary.`,
        };
      }
    }

    const geoJSON = this.createGeoJson(points);

    this.setState({
      olGeojsonCoordinates: geoJSON.features[0].geometry.coordinates,
    });

    return { geoJson: geoJSON, error: null };
  };

  private checkPolygonOverlapsBoundary = (
    boundaryGeoJSON: any,
    points: any
  ): boolean => {
    if (!(boundaryGeoJSON && boundaryGeoJSON.features && points)) {
      // exit if points or boundaryGeoJSON is invalid or empty
      return false;
    }

    const polygon = toPolygon([[...points, points[0]]]);
    const boundary = toPolygon(
      boundaryGeoJSON.features[0].geometry.coordinates
    );

    //  ensure polygon overlaps or contained in the boundary
    if (!booleanOverlap(boundary, polygon)) {
      if (!booleanContains(boundary, polygon)) {
        return false;
      }
    }

    return true;
  };

  private setupSearchBox = (input: any) => {
    const { google } = this.props;
    const { map } = this.state;

    if (!input || !google || !map) {
      return;
    }

    const searchBox = new google.maps.places.SearchBox(input);

    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();
      const bounds = new google.maps.LatLngBounds();

      places.forEach((place: any) => {
        if (!place.geometry) {
          return;
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

      map.fitBounds(bounds);
    });
  };

  private createGeoJson = (points: any[]) => {
    return {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
        },
      },
      features: [
        {
          type: 'Feature',
          properties: {
            id: null,
          },
          geometry: {
            type: 'Polygon',
            coordinates: [points],
          },
        },
      ],
    };
  };

  private boundaryChanged = (geojson: any) => {
    const { onBoundaryChange, computePolygonArea, onLocationChange } =
      this.props;

    if (onBoundaryChange) {
      if (geojson) {
        onBoundaryChange(btoa(JSON.stringify(geojson)));
      } else {
        onBoundaryChange(null);
      }
    }

    if (!undefinedOrNull(geojson)) {
      const polygon = new OLPolygon(geojson.features[0].geometry.coordinates);
      const center = getGeometryCenter(polygon);

      if (onLocationChange)
        onLocationChange({ latitude: center[1], longitude: center[0] });
      if (!undefinedOrNull(computePolygonArea))
        this.calculatePolygonArea(geojson);
    }
  };

  private calculatePolygonArea = (geojson: any) => {
    const { computePolygonArea } = this.props;
    const { features } = geojson;
    const polygonArea = +turfArea(features[0]).toFixed(2);

    if (computePolygonArea) {
      computePolygonArea(polygonArea);
    }
  };

  private getBoundaryPoints = (boundary: any) => {
    if (boundary && boundary.features) {
      const features = boundary.features.filter((f: any) => {
        return f && f.geometry && f.geometry.type === 'Polygon';
      });

      if (features.length > 0) {
        const pointsArr = features[0].geometry.coordinates;

        return (pointsArr || [[]])[0].map((p: any) => {
          return {
            lat: p[1],
            lng: p[0],
          };
        });
      }
    }

    return [];
  };

  private handleShowPolygonsListDataSwitch = (value: boolean) => {
    this.setState(
      {
        showPolygonsListData: value,
      },
      () => {
        const { map } = this.state;

        if (!value) {
          this.aoiBoundariesPolygonsList.map((a) => a.setMap(null));

          return;
        }

        this.aoiBoundariesPolygonsList.map((a) => a.setMap(map));
      }
    );
  };

  private RenderShowPolygonsListDataSwitch = () => {
    const { projectId } = this.props;
    const { showPolygonsListData } = this.state;

    if (!projectId) {
      return null;
    }

    return (
      <div className={style.showPolygonsListDataSwitchWrapper}>
        <span>Show Other AOIs&nbsp;</span>
        <Switch
          checked={showPolygonsListData}
          onChange={this.handleShowPolygonsListDataSwitch}
        />
      </div>
    );
  };

  private onOlGeojsonFeatureUpdate = async (feature: string) => {
    this.setState({ olGeojson: feature }, () => this.handlePolygonChanged());
  };

  public render(): React.ReactNode {
    const {
      project,
      projectId,
      hideUpload,
      google,
      createProject,
      useAoiBoundary,
    } = this.props;
    const {
      tabIndex,
      fileError,
      boundaryPoints,
      boundaryGeometryAndBuffer,
      error,
      showUploadBoundaryFileModal,
      uploadedGeojson,
      olGeojsonCoordinates,
    } = this.state;

    const { RenderShowPolygonsListDataSwitch } = this;

    let lat = 0;
    let lng = 0;

    const aoiMessage = useAoiBoundary
      ? 'Area of interest (AOI) should be within the displayed boundary.'
      : 'Area of interest (AOI) should be within the project boundary.';

    if (project || createProject) {
      if (project) {
        lat = project.latitude;
        lng = project.longitude;
      }

      return (
        <div className={style.container}>
          <div className={style.boundaryControls}>
            {hideUpload ? null : <Text>Boundary</Text>}
            {hideUpload ? null : (
              <Tabs
                centered
                tabBarStyle={{ textAlign: 'center' }}
                className={style.reactTabs}
                activeKey={tabIndex}
                onChange={this.setBoundaryTypeTab}
              >
                <TabPane
                  tab={<span className={style.tabTitle}>UPLOAD FILE</span>}
                  key="0"
                >
                  <div>
                    <label className={style.textStyle}>
                      Select KML or GeoJson file
                    </label>
                  </div>
                  <div className={style.uploadfile}>
                    <input
                      type="file"
                      id="file"
                      ref={this.uploadFile}
                      hidden
                      multiple={false}
                      accept=".kml,.geojson,.json"
                      onChange={this.fileChanged}
                      className={style.inputaddress}
                    />
                    <button
                      className={style.uploadInput}
                      onClick={this.handleUploadClick}
                    >
                      <i className={`${style.uploadBtn} fa fa-cloud-upload`} />
                      Upload
                    </button>
                  </div>
                  <RenderShowPolygonsListDataSwitch />
                  {fileError ? (
                    <div className={style.fileError}>{fileError}</div>
                  ) : null}
                </TabPane>
                <TabPane
                  className={style.manual}
                  tab={<span className={style.tabTitle}>MANUAL</span>}
                  key="1"
                >
                  {isVimanaLite() ? (
                    <></>
                  ) : (
                    <>
                      <div>
                        <label className={style.textStyle}>
                          Search for a location
                        </label>
                      </div>
                      <div className={style.search}>
                        <input ref={(ref) => this.setupSearchBox(ref)} />
                      </div>
                    </>
                  )}

                  <RenderShowPolygonsListDataSwitch />
                </TabPane>
              </Tabs>
            )}
          </div>
          {hideUpload ? (
            <div>
              <p className={style.aoiMessage}>{aoiMessage}</p>
            </div>
          ) : null}
          <div className={style.map}>
            {isVimanaLite() ? (
              <OSMMapContainer>
                <SatelliteTiles />
                {tabIndex === '1' ? (
                  <>
                    <VectorLayerContainer key="manual-draw">
                      <DrawAndEditPolygon
                        coordinates={olGeojsonCoordinates}
                        onGeoJsonFeatureCreate={this.onOlGeojsonFeatureUpdate}
                        onGeoJsonFeatureEdit={this.onOlGeojsonFeatureUpdate}
                      />
                      <ResetControl
                        onReset={() => {
                          this.setState({ uploadedGeojson: undefined });
                        }}
                      />
                    </VectorLayerContainer>
                  </>
                ) : (
                  <>
                    <VectorLayerContainer>
                      <ResetControl
                        onReset={() => {
                          this.setState({ uploadedGeojson: undefined });
                        }}
                      />
                      {uploadedGeojson ? (
                        <>
                          <ReadOnlyPolygon
                            coordinates={
                              (uploadedGeojson as any).features[0].geometry
                                .coordinates as Coordinate[][]
                            }
                            zIndex={101}
                            style={getDefaultDrawStyle('Polygon')}
                          />
                          <CenterMap
                            geometryWithScaleBuffer={{
                              geometry: new OLPolygon(
                                (uploadedGeojson as any).features[0].geometry
                                  .coordinates as Coordinate[][]
                              ).transform(WGS84, WEB_MERCATOR) as OLPolygon,
                              scaleBuffer: 1.25,
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </VectorLayerContainer>
                  </>
                )}
                <VectorLayerContainer>
                  <ReadOnlyPolygon
                    coordinates={[boundaryPoints.map((p) => [p.lng, p.lat])]}
                    zIndex={100}
                  />
                </VectorLayerContainer>
                {boundaryGeometryAndBuffer ? (
                  <CenterMap
                    geometryWithScaleBuffer={boundaryGeometryAndBuffer}
                  />
                ) : (
                  <></>
                )}
              </OSMMapContainer>
            ) : (
              <Map
                google={google}
                initialCenter={{ lat, lng }}
                onReady={this.onMapLoaded}
              >
                <Polygon
                  paths={boundaryPoints}
                  strokeColor="#FF0000"
                  strokeOpacity={0.8}
                  strokeWeight={2}
                  fillColor="#FF0000"
                  fillOpacity={0.15}
                  style={
                    style
                      ? { position: 'relative', ...style }
                      : { position: 'relative' }
                  }
                />
              </Map>
            )}
          </div>
          {showUploadBoundaryFileModal ? (
            <Modal
              title="Select Boundary File for Upload"
              centered
              footer={null}
              visible
              destroyOnClose
              maskClosable={false}
              onCancel={this.closeUploadBoundaryModal}
              className={style.popupContainer}
              zIndex={10002}
            >
              <p className={style.headertext}>
                Please select a GeoJSON or KML boundary file that meets the
                following criteria:
                <ul className={style.list}>
                  <li>
                    It should contain only a single polygon feature, with valid
                    geometry.
                  </li>
                  <li>
                    The polygon must be closed, ie., the first point must be the
                    same as the last.
                  </li>
                  <li>
                    The polygon must not have line segments that intersect with
                    each other.
                  </li>
                  <li>
                    The polygon feature should only have a single ring. There
                    should not be any holes or inner rings in the polygon.
                  </li>
                  <li>
                    The coordinate system of the file should be EPSG:4326.
                  </li>
                  {projectId ? (
                    <li>
                      The polygon should lie within the displayed boundary.
                    </li>
                  ) : null}
                </ul>
              </p>

              <div className={style.popupModalDiv}>
                <Button
                  onClick={this.selectFileForUpload}
                  text="Select Boundary File"
                />
              </div>
            </Modal>
          ) : null}
          {this.state && error && (
            <div className={style.fileMapError}>{error}</div>
          )}
        </div>
      );
    }

    return <div>Loading</div>;
  }
}

export default GoogleApiWrapper({
  apiKey: getGoogleMapKey(),
  libraries: ['places', 'drawing'],
})(BoundaryEditor);
