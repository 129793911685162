import { CameraOutlined } from '@ant-design/icons';
import { Slider, Switch, Tooltip, Typography } from 'antd';
import classnames from 'classnames';
import * as React from 'react';
import OSMMap from 'src/components/OSMMap/OSMMap';
import ImageMetadata from '../../../api/images.types';
import ProjectsAPI from '../../../api/projects';
import ViewsV2Apis from '../../../api/viewsV2';
import {
  APP_PRIMARY_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR,
  GOOGLE_MAP_MARKER_DEFAULT_COLOR,
  GOOGLE_MAP_MARKER_HIGHLIGHT_COLOR,
  MAGMA,
} from '../../../constants/colors';
import { viewUrl } from '../../../routes/urls';
import {
  inArray,
  isVimanaLite,
  removeArrayByValue,
  undefinedOrNull,
  yawToDeg,
} from '../../../utils/functs';
import { log } from '../../../utils/log';
import ContextMenu from '../../ContextMenu';
import { ContextMenuMenuOptionsTypes } from '../../ContextMenu/index.types';
import DynamicScale from '../../DynamicScale';
import LoadingOverlay from '../../LoadingOverlay';
import NavigationBox from '../../NavigationBox';
import PlotGoogleMap from '../../PlotGoogleMap';
import { GoogleMapsPositionTypes } from '../../PlotGoogleMap/index.types';
import Resizer from '../../Resizer';
import {
  CreateViewEvent,
  HighlightImageListChanged,
  HighlightImageDetails,
  ImageSelectionChanged,
  PixelCoordinates,
  RendererStateChange,
  ShowSnackbarEvent,
  ViewConfig,
} from '../index.types';
import { MapStateProvider } from '../MapView/OpenLayersRenderer/MapStateProvider';
import PotreeStateProvider from '../ThreeDView/PotreeStateProvider';
import ImageRenderer from './ImageRenderer';
import styles from './index.module.scss';
import {
  ImageViewPropsType,
  ImageViewStateType,
  InsetType,
  NavigationType,
} from './index.types';
import Navigation3DController from './Navigation3DController';
import NavigationButtons2WayController from './NavigationButtons2WayController';
import NavigationButtons8WayController from './NavigationButtons8WayController';
import PotreeInset from './PotreeInset';
import { CamProjections } from '../../../vendor/inspection-utils';
import CreateInspectionViewModal from './CreateInspectionViewModal';
import { CreateViewRequest } from '../../../api/views.types';
import {
  ImageAnnotationControl,
  PIXEL_SOURCE,
} from './ImageRenderer/ImageAnnotations';
import { NoImagePlaceholder } from './NoImagePlaceholder';

const { Text } = Typography;

interface SelectionQueryParams {
  gotoGuid?: string;
  latitude?: number;
  longitude?: number;
}

export interface TriangulationAlgoInput {
  guid: string;
  pixel: PixelCoordinates;
}

export default class ImageView extends React.Component<
  ImageViewPropsType,
  ImageViewStateType
> {
  private viewsV2Apis = new ViewsV2Apis();

  private projectsApi = new ProjectsAPI();

  private stateProviderRef = React.createRef<MapStateProvider>();

  private insetStateProviderRef = React.createRef<PotreeStateProvider>();

  constructor(props: ImageViewPropsType) {
    super(props);

    this.state = {
      viewDescriptor: null,
      viewableImages: [],
      insetState: 'window',
      insetSize: { height: '100%', width: 400 },
      pegImageRotationToNorth: true,
      boundaryListData: null,
      enabledInsetTypes: [],
      selectedInsetType: null,
      navType: null,
      showHiddenImages: false,
      filteredCount: 0,
    };
  }

  public componentDidMount() {
    const { view, share, onFooterControlsFragChange } = this.props;

    if (share) {
      this.setAoiBoundary(share?.boundary || {});
      this.setDescriptorData(share?.viewDescriptor, share?.viewConfig);
      this.setState({ insetState: 'minimized' });
    } else {
      this.fetchViewDescriptor(view, this.extractSlectionParams());
      this.fetchAoiBoundary(view);
    }

    const { onConfigCallbackChange } = this.props;

    onConfigCallbackChange((): ViewConfig => {
      const image = this.getCurrentImage();
      const insetState = this.insetStateProviderRef?.current
        ? this.insetStateProviderRef?.current.getPotreeState()
        : {};

      return {
        selection: `${image?.id}` || '0',
        ...insetState,
      };
    });

    if (onFooterControlsFragChange) {
      onFooterControlsFragChange(
        <div style={{ width: 'auto', zIndex: 10 }}>
          <CameraOutlined
            className={styles.icon}
            title="Screenshot"
            onClick={() => {
              if (this.stateProviderRef.current) {
                this.stateProviderRef.current.saveScreenshot();
              }
            }}
          />
        </div>
      );
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ImageViewPropsType) {
    const {
      view: nextView,
      viewConfig: nextViewConfig,
      potreeInsetViewIdOverride: nextInsetOverride,
    } = nextProps;
    const {
      view,
      viewConfig,
      potreeInsetViewIdOverride: insetOverride,
    } = this.props;

    if (insetOverride !== nextInsetOverride) {
      this.setState({ potreeInsetViewIdOverride: nextInsetOverride });
    }

    if (view !== nextView) {
      this.fetchViewDescriptor(nextView);
      this.setState(
        {
          triangulationPoints: undefined,
        },
        () => this.handleGetImagesWithPOI()
      );

      if (view?.aoiId !== nextView?.aoiId) {
        this.fetchAoiBoundary(nextView);
      }
    }

    if (viewConfig !== nextViewConfig || insetOverride !== nextInsetOverride) {
      // reset descriptor data with this viewConfig
      const { viewDescriptor } = this.state;

      this.setDescriptorData(viewDescriptor, nextViewConfig);
    }
  }

  private extractSlectionParams(): SelectionQueryParams | undefined {
    const urlParams = new URLSearchParams(window.location.search);
    let gotoGuid: string | undefined;
    let latitude: number | undefined;
    let longitude: number | undefined;

    if (urlParams.has('gotoGuid')) {
      gotoGuid = urlParams.get('gotoGuid') || undefined;
    }

    if (urlParams.has('latitude')) {
      try {
        latitude = parseFloat(urlParams.get('latitude') as any);
      } catch (e) {
        /**/
      }
    }

    if (urlParams.has('longitude')) {
      try {
        longitude = parseFloat(urlParams.get('longitude') as any);
      } catch (e) {
        /**/
      }
    }

    if (!gotoGuid && undefinedOrNull(latitude) && undefinedOrNull(longitude)) {
      return undefined;
    }

    return {
      gotoGuid,
      latitude,
      longitude,
    };
  }

  private fetchAoiBoundary = (view: any): void => {
    this.projectsApi.listAoiBoundary(view.projectId, view.aoiId).then((res) => {
      const { error: apiError, data: apiData } = res;

      this.setAoiBoundary(apiData);

      if (apiError) {
        log.error(apiError, 'SiteWalkthrough -> fetchAoiBoundary');
      }
    });
  };

  private setAoiBoundary = (boundaryData: any) => {
    let boundaryList: GoogleMapsPositionTypes[] | null = null;

    if (
      boundaryData &&
      boundaryData?.features &&
      boundaryData?.features[0]?.geometry?.coordinates
    ) {
      const coord = boundaryData.features[0].geometry.coordinates[0];

      boundaryList = coord.map((a: [number, number]) => {
        return { lat: a[1], lng: a[0] };
      });
    }

    this.setState({
      boundaryListData: boundaryList,
    });
  };

  private fetchViewDescriptor(
    view: any,
    selectionParams?: SelectionQueryParams
  ) {
    this.viewsV2Apis
      .listViewConfig(view.projectId, view.aoiId, view.id)
      .then((res) => {
        const { error, data } = res;

        if (error) {
          console.error(error);

          return;
        }

        if (data) {
          const { viewConfig } = this.props;

          this.setDescriptorData(data, viewConfig, selectionParams);
        }
      });
  }

  private setDescriptorData(
    data: any,
    viewConfig: any,
    selectionParams?: SelectionQueryParams
  ) {
    const { potreeInsetViewIdOverride, showHiddenImages } = this.state;
    let { showPotreeInsetToast } = this.state;

    const allImages = data?.images || [];
    const hiddenGuids = data?.hiddenGuids || [];
    const images = allImages.filter((i: any) => {
      return hiddenGuids.indexOf(i.guid) === -1;
    });

    // set viewable images
    const viewableImages = showHiddenImages ? allImages : images;

    // if we have display order for all images, use that
    if (!viewableImages.find((i: any) => undefinedOrNull(i.displayOrder))) {
      viewableImages.sort((a: any, b: any) => {
        return a.displayOrder < b.displayOrder
          ? -1
          : a.displayOrder === b.displayOrder
          ? 0
          : 1;
      });
    }

    // when new descriptor loads, use this image as reference
    // to select closest one from this
    let currentImage = this.getCurrentImage();

    let viewConfigSelectionGuid;

    if (viewConfig) {
      currentImage = null;

      const selImg = viewableImages.find(
        (i: any) => i.id === parseInt(viewConfig.selection, 10)
      );

      if (selImg) {
        viewConfigSelectionGuid = selImg.guid;
      }
    }

    if (selectionParams) {
      if (selectionParams.gotoGuid) {
        viewConfigSelectionGuid = selectionParams.gotoGuid;
        // don't try to retain current location
        currentImage = null;
      } else if (
        !undefinedOrNull(selectionParams.latitude) &&
        !undefinedOrNull(selectionParams.longitude)
      ) {
        const closestImage = this.findClosest(
          viewableImages || [],
          selectionParams.latitude,
          selectionParams.longitude
        );

        if (closestImage) {
          viewConfigSelectionGuid = closestImage.guid;
          // don't try to retain current location
          currentImage = null;
        }
      }
    }

    let selectedImageGuid: string | undefined;

    if (viewableImages.length) {
      selectedImageGuid = viewConfigSelectionGuid || viewableImages[0]?.guid;

      if (currentImage) {
        const closestImage = this.findClosest(
          viewableImages,
          parseFloat(currentImage.latitude),
          parseFloat(currentImage.longitude)
        );

        if (closestImage) {
          selectedImageGuid = closestImage.guid;
        }
      }
    }

    let enabledInsetTypes: InsetType[] = [];
    let selectedInsetType: InsetType | null = null;
    let navType: NavigationType | null = null;
    const { view } = this.props;

    switch (view.type) {
      case 'inspection':
        enabledInsetTypes = ['potree', 'map'];
        selectedInsetType = 'potree';
        navType = '6-way';
        break;
      case 'perspective':
        enabledInsetTypes = ['map'];
        selectedInsetType = 'map';
        navType = '2-way';
        break;
      case 'site_navigation':
        enabledInsetTypes = ['map'];
        selectedInsetType = 'map';
        navType = '8-way';
        break;
      default:
        break;
    }

    if (
      view.type === 'inspection' &&
      (data?.potreeInsetViewId || potreeInsetViewIdOverride)
    ) {
      showPotreeInsetToast = true;
    } else {
      showPotreeInsetToast = false;
    }

    // thermal range filter state
    let rangeFilter: any;

    if (data?.thermalImages) {
      const { min, max } = this.getTemperatureRange(viewableImages);

      rangeFilter = {
        min,
        max,
        rangeMin: min,
        rangeMax: max,
      };
    }

    this.setState(
      {
        viewDescriptor: data,
        viewableImages,
        selectedImageGuid,
        enabledInsetTypes,
        selectedInsetType,
        showPotreeInsetToast,
        navType,
        showHiddenImages: false,
        rangeFilter,
      },
      () => {
        const { onEvent } = this.props;

        onEvent(
          new RendererStateChange({
            currentImage: this.getCurrentImage() || undefined,
          })
        );
      }
    );
  }

  private getTemperatureRange = (
    images: ImageMetadata[]
  ): { min: number; max: number } => {
    const ranges = (images || []).map((i: ImageMetadata) => {
      return {
        min: i.minTemperature || 0,
        max: i.maxTemperature || 0,
      };
    });
    const max = Math.max(...ranges.map((r: any) => r.max));
    const min = Math.min(...ranges.map((r: any) => r.min));

    return { min, max };
  };

  private findClosest(images: any[], lat: number, lng: number) {
    let distance = Number.MAX_VALUE;
    let closest: any;

    // eslint-disable-next-line no-restricted-syntax
    for (const i of images) {
      const imgLat = parseFloat(i.latitude);
      const imgLng = parseFloat(i.longitude);

      const d = Math.sqrt(
        (lat - imgLat) * (lat - imgLat) + (lng - imgLng) * (lng - imgLng)
      );

      if (d < distance) {
        distance = d;
        closest = i;
      }
    }

    return closest;
  }

  private getCurrentImage(): ImageMetadata | null {
    const { selectedImageGuid } = this.state;
    const viewableImages = this.getViewableImages();

    if (selectedImageGuid) {
      // eslint-disable-next-line no-restricted-syntax
      for (const img of viewableImages) {
        if (img?.guid === selectedImageGuid) {
          return img;
        }
      }
    }

    return null;
  }

  private getCurrentImageIndex() {
    const { selectedImageGuid } = this.state;
    const viewableImages = this.getViewableImages();

    if (selectedImageGuid) {
      for (let idx = 0; idx < viewableImages.length; idx += 1) {
        if (viewableImages[idx]?.guid === selectedImageGuid) {
          return idx;
        }
      }
    }

    return null;
  }

  private getViewableImages() {
    const { viewableImages } = this.state;

    return viewableImages;
  }

  private setCurrentImageFromIndex = (idx: number) => {
    const { selectedImageGuid: previousImage } = this.state;
    const viewableImages = this.getViewableImages();

    if (viewableImages) {
      if (idx < viewableImages.length) {
        const { guid } = viewableImages[idx];

        this.setState({ selectedImageGuid: guid }, () => {
          const { onEvent } = this.props;

          onEvent(
            new RendererStateChange({
              currentImage: this.getCurrentImage() || undefined,
            })
          );
          onEvent(
            new ImageSelectionChanged({ previousImage, currentImage: guid })
          );
        });
      }
    }
  };

  private setCurrentImageFromGuid = (guid: string) => {
    const viewableImages = this.getViewableImages();
    const image = viewableImages.find((i: any) => i.guid === guid);

    if (!image) {
      console.error(`image ${guid} not found in this view`);

      return;
    }

    const { selectedImageGuid: previousImage } = this.state;

    this.setState({ selectedImageGuid: guid }, () => {
      const { onEvent } = this.props;

      onEvent(
        new RendererStateChange({
          currentImage: this.getCurrentImage() || undefined,
        })
      );
      onEvent(new ImageSelectionChanged({ previousImage, currentImage: guid }));
    });
  };

  private handleGetImagesWithPOI = () => {
    const { onEvent } = this.props;
    const { viewDescriptor, triangulationPoints } = this.state;
    const cameras = new CamProjections(viewDescriptor?.images || []);

    const inputMap: TriangulationAlgoInput[] =
      triangulationPoints?.map((point) => {
        return {
          guid: point.image.guid,
          pixel: { x: point.pixel.x, y: -1 * point.pixel.y },
        };
      }) || [];

    if (inputMap.length > 0) {
      onEvent(
        new HighlightImageListChanged(cameras.getImagesWithPOI(inputMap))
      );
    } else {
      onEvent(new HighlightImageListChanged([]));
    }
  };

  private handleCreateViewFromHighlightImages = (viewName: string) => {
    const { highlistImageList, view, onEvent } = this.props;
    const { viewDescriptor } = this.state;

    if (
      highlistImageList &&
      highlistImageList.length > 0 &&
      viewDescriptor?.images
    ) {
      const filteredGuids =
        highlistImageList?.map((point) => {
          return point.guid;
        }) || [];

      const imageIds = viewDescriptor.images
        .filter((img: ImageMetadata) => filteredGuids.indexOf(img.guid) > -1)
        .map((img: ImageMetadata) => img.id);

      const request: CreateViewRequest = {
        missionId: view.missionId as string,
        imageIds,
        type: 'inspection',
        subType: 'inspection_default',
        name: viewName,
        certifiedForAdmin: true,
        certifiedForDisplay: true,
      };

      onEvent(new CreateViewEvent(request));
    }
  };

  private getTriangulatedPoint(
    highlightImageList: HighlightImageDetails[] | undefined
  ) {
    return highlightImageList !== undefined
      ? highlightImageList.length !== 0
        ? highlightImageList[0].triangulatedPoint
        : undefined
      : undefined;
  }

  private renderInset() {
    const { highlistImageList } = this.props;
    const { triangulationPoints } = this.state;
    const {
      boundaryListData,
      selectedInsetType,
      viewDescriptor,
      viewableImages,
    } = this.state;

    const userMarkedList =
      triangulationPoints?.map((point) => {
        return point.image.guid;
      }) || [];
    const filteredGuids =
      highlistImageList?.map((point) => {
        return point.guid;
      }) || [];

    if (selectedInsetType === 'map') {
      const hiddenImagesGuidList = viewDescriptor?.hiddenGuids || [];

      const markerPointsList: any[] = viewableImages.map(
        (a: any, index: number) => {
          let unselectedIconColor = GOOGLE_MAP_MARKER_DEFAULT_COLOR;

          if (inArray(hiddenImagesGuidList, a.guid)) {
            unselectedIconColor = GOOGLE_MAP_MARKER_DEFAULT_ACTIVE_COLOR;
          }

          if (highlistImageList && inArray(filteredGuids, a.guid)) {
            unselectedIconColor = GOOGLE_MAP_MARKER_HIGHLIGHT_COLOR; // ML_OBJECT_COLOR.MANUAL;
          }

          /* if (userMarkedList && inArray(userMarkedList, a.guid)) {
            unselectedIconColor = ML_OBJECT_COLOR.MANUAL;
          } */

          return {
            pos: {
              lat: parseFloat(a.latitude),
              lng: parseFloat(a.longitude),
            },
            index,
            icon: {
              selected: {
                types: ['DEFAULT'],
                color: APP_PRIMARY_COLOR,
                ignoreMarkerClusterer: false,
              },
              unselected: {
                types: ['DEFAULT'],
                color: unselectedIconColor,
                ignoreMarkerClusterer: false,
              },
            },
          };
        }
      );

      const currentImage = this.getCurrentImage();
      const pitch = currentImage ? currentImage.gimbalPitch : 0;
      const yaw = currentImage ? currentImage.gimbalYaw : 0;
      let customHaloMarker: any;

      if (!(pitch > -91 && pitch <= -89)) {
        customHaloMarker = {
          icon: {
            halo: {
              type: 'FORWARD_OPEN_ARROW',
              scale: 10,
              fillColor: APP_PRIMARY_COLOR,
              strokeColor: APP_PRIMARY_COLOR,
              rotation: 180 + yaw,
            },
            point: {
              color: APP_PRIMARY_COLOR,
              type: 'DEFAULT',
            },
          },
        };
      }

      return isVimanaLite() ? (
        <OSMMap
          boundaryPointsList={boundaryListData ? [boundaryListData] : undefined}
          markerPointsList={markerPointsList}
          onMarkerClick={(idx: number) => this.setCurrentImageFromIndex(idx)}
        />
      ) : (
        <PlotGoogleMap
          width="100%"
          height="100%"
          boundaryPointsList={boundaryListData ? [boundaryListData] : null}
          markerPointsList={markerPointsList}
          selectedMarkerIndex={this.getCurrentImageIndex()}
          onMarkerClick={(idx: number) => this.setCurrentImageFromIndex(idx)}
          initialMarkerPosition="center"
          centerMapTo={boundaryListData ? 'boundary' : 'marker'}
          markerClustererMaxZoom={20}
          gestureHandling="greedy"
          maintainZoomLevel={false}
          customHaloMarker={customHaloMarker}
        />
      );
    }

    if (selectedInsetType === 'potree') {
      const { showPotreeInsetToast } = this.state;
      const { potreeInsetViewIdOverride, viewConfig, highlistImageList } =
        this.props;

      // undefined means not set, use id from descriptor, null means set but set to empty
      const viewId =
        potreeInsetViewIdOverride === undefined
          ? viewDescriptor?.potreeInsetViewId
          : potreeInsetViewIdOverride === null
          ? null
          : potreeInsetViewIdOverride || viewDescriptor?.potreeInsetViewId;

      const filteredGuids =
        highlistImageList?.map((point) => {
          return point.guid;
        }) || [];
      const triangulatedPoint = this.getTriangulatedPoint(highlistImageList);

      return (
        <PotreeInset
          viewId={viewId}
          viewConfig={viewConfig}
          images={viewableImages}
          highlightImageGuids={filteredGuids}
          triangulatedPoint={triangulatedPoint}
          userMarkedImageGuids={userMarkedList}
          selectedImageIndex={this.getCurrentImageIndex() || 0}
          onImageCenterClick={(index: number) => {
            this.setCurrentImageFromIndex(index);
          }}
        >
          <PotreeStateProvider ref={this.insetStateProviderRef} />
          {showPotreeInsetToast && (
            <div className={styles.onboardingWrapper}>
              <span>
                Left click and drag to rotate. Right click and drag to pan.
              </span>
              <div>
                <i
                  className="fa fa-times-circle"
                  aria-hidden="true"
                  onClick={() => {
                    this.setState({
                      showPotreeInsetToast: false,
                    });
                  }}
                />
              </div>
            </div>
          )}
        </PotreeInset>
      );
    }

    return null;
  }

  private renderNavigationControls() {
    const { navType, selectedImageGuid, viewableImages } = this.state;

    if (navType === '2-way') {
      return (
        <NavigationButtons2WayController
          onMarkerClick={this.setCurrentImageFromGuid}
          selectedMarkerGuid={selectedImageGuid}
          images={viewableImages}
        />
      );
    }

    if (navType === '6-way') {
      const { pegImageRotationToNorth } = this.state;
      const currentImage = this.getCurrentImage();
      const isNadirImage = currentImage
        ? currentImage.gimbalPitch < -85 && currentImage.gimbalPitch > -95
        : false;

      return (
        <Navigation3DController
          images={viewableImages}
          selectedImage={selectedImageGuid}
          onNavigationBtnClick={this.setCurrentImageFromGuid}
          forceYawToNorth={pegImageRotationToNorth && isNadirImage}
        />
      );
    }

    if (navType === '8-way') {
      const { pegImageRotationToNorth } = this.state;
      const currentImage = this.getCurrentImage();
      const isNadirImage = currentImage
        ? currentImage.gimbalPitch < -85 && currentImage.gimbalPitch > -95
        : false;
      const navPoints = viewableImages.map((p: any) => {
        return {
          latitude: parseFloat(p.latitude),
          longitude: parseFloat(p.longitude),
          guid: p.guid,
        };
      });

      return (
        <NavigationButtons8WayController
          points={navPoints}
          rotationCorrection={
            pegImageRotationToNorth && isNadirImage
              ? 0
              : currentImage
              ? currentImage.gimbalYaw
              : 0
          }
          onNavigationBtnClick={this.setCurrentImageFromGuid}
          guid={currentImage ? currentImage.guid : ''}
        />
      );
    }

    return null;
  }

  private showHideImageControls = (): boolean => {
    const { view, isStaff, userRole } = this.props;

    return !!(
      view.subType !== 'mission_default' &&
      (userRole === 'project_admin' || isStaff)
    );
  };

  private renderContextMenu() {
    const { linkedMapView, view } = this.props;

    const menuOptionsList: ContextMenuMenuOptionsTypes[] = [];

    // Ensure "Show in Map" is only available in Site Navigation Views
    if (linkedMapView && view.type === 'site_navigation') {
      menuOptionsList.push({
        label: 'Show in Map',
        name: 'show_in_map',
        onClick: (_) => {
          this.handleReverseMapInspect();
        },
      });
    }

    const currentImage = this.getCurrentImage();

    if (currentImage) {
      if (this.showHideImageControls()) {
        const { viewDescriptor } = this.state;
        const hiddenList: string[] = viewDescriptor?.hiddenGuids || [];
        const isHidden = hiddenList.indexOf(currentImage.guid) !== -1;

        if (isHidden) {
          menuOptionsList.push({
            label: 'Unhide image',
            name: 'unhide_image',
            onClick: (_) => {
              this.setImageHiddenStatus(currentImage.guid, false);
            },
          });
        } else {
          menuOptionsList.push({
            label: 'Hide image',
            name: 'hide_image',
            onClick: (_) => {
              this.setImageHiddenStatus(currentImage.guid, true);
            },
          });
        }
      }

      const triangulationOptions =
        this.getTriangulationContextMenuOptions(currentImage);

      menuOptionsList.push(...triangulationOptions);
    }

    if (menuOptionsList.length > 0) {
      return (
        <ContextMenu
          key="context-menu"
          nodeSelector="#sitewalkthrough-container"
          menuOptionsList={menuOptionsList}
          instanceId={currentImage?.guid}
        />
      );
    }

    return null;
  }

  private getTriangulationContextMenuOptions = (
    currentImage: ImageMetadata | null
  ): ContextMenuMenuOptionsTypes[] => {
    const menuOptions: ContextMenuMenuOptionsTypes[] = [];

    if (!currentImage) {
      return [];
    }

    menuOptions.push({
      label: 'Mark point of interest',
      name: 'mark_poi',
      onClick: (_item, _confirm, event) => {
        const { olMap } = this.state;
        const { onEvent } = this.props;
        const [x, y] =
          olMap && event ? olMap.getEventCoordinate(event) : [0, 0];

        this.setState(
          ({ triangulationPoints }) => {
            if (!triangulationPoints?.length) {
              onEvent(
                new ShowSnackbarEvent({
                  title: 'Need help?',
                  type: 'info',
                  body: 'Mark the same point of interest in multiple images, for the system to automatically select all images containing that point.',
                  isModal: false,
                })
              );
            }

            return {
              triangulationPoints: [
                ...(triangulationPoints?.filter(
                  (p) => p.image.guid !== currentImage.guid
                ) || []),
                { image: currentImage, pixel: { x, y } },
              ],
              showAnnotationRenderFrag: true,
              showProjAnnotationRenderFrag: true,
            };
          },
          () => {
            this.handleGetImagesWithPOI();
          }
        );
      },
    });

    const { triangulationPoints } = this.state;

    const pointsInImage = triangulationPoints?.filter(
      (p) => p.image.guid === currentImage.guid
    );

    if (pointsInImage?.length) {
      menuOptions.push({
        label: 'Clear marked point of interest',
        name: 'clear_poi_in_image',
        onClick: () => {
          const { triangulationPoints } = this.state;

          this.setState(
            {
              triangulationPoints: triangulationPoints?.filter(
                (p) => p.image.guid !== currentImage.guid
              ),
            },
            () => {
              this.handleGetImagesWithPOI();
            }
          );
        },
      });
    }

    if (triangulationPoints?.length) {
      menuOptions.push({
        label: `Clear all marked points - (${triangulationPoints.length})`,
        name: 'clear_pois',
        onClick: (_item, _confirm) => {
          this.setState(
            {
              triangulationPoints: undefined,
              showAnnotationRenderFrag: false,
              showProjAnnotationRenderFrag: false,
            },
            () => this.handleGetImagesWithPOI()
          );
        },
      });

      menuOptions.push({
        label: 'Create view from selected images',
        name: 'create_view_from_images',
        onClick: () => {
          this.setState({ showCreateViewModal: true });
        },
      });
    }

    return menuOptions;
  };

  private setImageHiddenStatus = (guid: string, hidden: boolean) => {
    const { view } = this.props;
    const { projectId, aoiId, id: viewId } = view;

    const formData = { hidden };

    this.viewsV2Apis
      .patchViewImageConfig(projectId, aoiId, viewId, guid, formData)
      .then((res) => {
        const { error: apiError } = res;

        if (apiError) {
          return;
        }

        const { viewDescriptor } = this.state;

        if (viewDescriptor) {
          const hiddenGuids = viewDescriptor?.hiddenGuids || [];
          let updatedHiddenGuids;

          if (hidden) {
            updatedHiddenGuids = [guid, ...hiddenGuids];
          } else {
            updatedHiddenGuids = removeArrayByValue(hiddenGuids, guid);
          }

          this.setState({
            viewDescriptor: {
              ...viewDescriptor,
              hiddenGuids: updatedHiddenGuids,
            },
          });
        }
      });
  };

  private handleReverseMapInspect = () => {
    const { linkedMapView, history } = this.props;

    const selectedImage = this.getCurrentImage();

    const preservedMapMetaData = {
      centerLatitude: parseFloat(selectedImage?.latitude || '0'),
      centerLongitude: parseFloat(selectedImage?.longitude || '0'),
    };

    if (!undefinedOrNull(linkedMapView)) {
      const { projectId, aoiId, id } = linkedMapView;

      history.push(viewUrl(projectId, aoiId, id, preservedMapMetaData));
    }
  };

  private getInsetDescription(type: InsetType) {
    const desc: any = { tooltip: '', icon: '' };

    switch (type) {
      case 'potree':
        desc.tooltip = 'Navigate in 3D space';
        desc.icon = 'cloud';
        break;
      case 'map':
        desc.tooltip = 'Navigate using map';
        desc.icon = 'map';
        break;
      default:
        break;
    }

    return desc;
  }

  private setShowHiddenImages = (flag: boolean) => {
    this.setState({ showHiddenImages: flag }, () => {
      this.filterImages();
    });
  };

  /**
   * Convert celsius temp value to temp in view units (C/F)
   * @param t
   * @returns
   */
  private toViewTemperatureUnits = (t: number): number => {
    const { view } = this.props;
    const { temperatureUnit } = view;

    if (temperatureUnit === 'FAHRENHEIT') {
      return (t * 9.0) / 5.0 + 32.0;
    }

    return t;
  };

  /**
   * convert a temp value in view units to celsius
   * @param t
   * @returns
   */
  private toCelsius = (t: number): number => {
    const { view } = this.props;
    const { temperatureUnit } = view;

    if (temperatureUnit === 'FAHRENHEIT') {
      return ((t - 32.0) / 9.0) * 5.0;
    }

    return t;
  };

  private setTemperatureFilter = (range: { min: number; max: number }) => {
    const { rangeFilter } = this.state;

    if (rangeFilter) {
      const { min: rangeMin, max: rangeMax } = range;

      this.setState(
        {
          rangeFilter: {
            ...rangeFilter,
            rangeMin: this.toCelsius(rangeMin),
            rangeMax: this.toCelsius(rangeMax),
          },
        },
        () => {
          this.filterImages();
        }
      );
    }
  };

  private filterImages = () => {
    const { viewDescriptor, selectedImageGuid, showHiddenImages, rangeFilter } =
      this.state;
    const images = viewDescriptor?.images || [];
    const viewableImages: ImageMetadata[] = [];
    const hiddenGuids: string[] = [];
    let nextImage: string | any;
    const hiddenByDescriptorGuids = viewDescriptor?.hiddenGuids || [];
    const filters: ((img: ImageMetadata) => boolean)[] = [
      (img: ImageMetadata): boolean => {
        return showHiddenImages
          ? true
          : hiddenByDescriptorGuids.indexOf(img.guid) === -1;
      },
    ];

    if (rangeFilter) {
      const { rangeMin, rangeMax } = rangeFilter;

      filters.push((img: ImageMetadata): boolean => {
        const imgMin = img.minTemperature || -999;
        const imgMax = img.maxTemperature || 999;

        return imgMax >= rangeMin && imgMin <= rangeMax;
      });
    }

    let filteredByTemp = 0;

    for (let i = 0; i < images.length; i += 1) {
      const img = images[i];
      let include = true;

      for (let j = 0; j < filters.length; j += 1) {
        const filter = filters[j];

        if (!filter(img)) {
          include = false;
          if (j > 0) {
            filteredByTemp += 1;
          }

          break;
        }
      }

      if (include) {
        viewableImages.push(img);
      } else {
        hiddenGuids.push(img.guid);
      }
    }

    if (!hiddenGuids.length) {
      // hiding images, if current image is hidden, we will select another image
      if (hiddenGuids.indexOf(selectedImageGuid || '') > -1) {
        // find next index which is not hidden
        const idx = this.getCurrentImageIndex() || 0;

        for (let i = idx; i < images.length; i += 1) {
          if (images[i]?.guid && hiddenGuids.indexOf(images[i]?.guid) === -1) {
            nextImage = images[i]?.guid;
            break;
          }
        }

        if (!nextImage) {
          for (let i = 0; i < idx; i += 1) {
            if (
              images[i]?.guid &&
              hiddenGuids.indexOf(images[i]?.guid) === -1
            ) {
              nextImage = images[i]?.guid;
              break;
            }
          }
        }
      }
    }

    if (!nextImage) {
      nextImage = selectedImageGuid;
    }

    this.setState({
      viewableImages,
      selectedImageGuid: nextImage,
      filteredCount: filteredByTemp,
    });
  };

  public renderModals = () => {
    const { showCreateViewModal } = this.state;
    const { highlistImageList } = this.props;

    const filteredGuids =
      highlistImageList?.map((point) => {
        return point.guid;
      }) || [];

    return (
      <>
        {showCreateViewModal ? (
          <CreateInspectionViewModal
            visible
            highlightImageList={filteredGuids}
            createView={(viewName?: string) => {
              this.setState({ showCreateViewModal: false });

              if (viewName?.length)
                this.handleCreateViewFromHighlightImages(viewName);
            }}
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  public checkIfViewSelectorIsPresent = (): boolean => {
    return !!window.document.getElementById('view-selector-dropdown');
  };

  public render() {
    const {
      viewDescriptor,
      insetState,
      insetSize,
      pegImageRotationToNorth,
      enabledInsetTypes,
      selectedInsetType,
      showHiddenImages,
      rangeFilter,
      filteredCount,
      showAnnotationRenderFrag,
      showProjAnnotationRenderFrag,
      triangulationPoints,
    } = this.state;

    const { viewConfig, highlistImageList } = this.props;
    const userMarkedList =
      triangulationPoints?.map((point) => {
        return point.image.guid;
      }) || [];

    const currentImage = this.getCurrentImage();

    if (!viewDescriptor) {
      return (
        <div className={styles.container}>
          <LoadingOverlay />
        </div>
      );
    }

    const isNadirImage = currentImage
      ? currentImage.gimbalPitch < -85 && currentImage.gimbalPitch > -95
      : false;
    let navigationRotationAngle = yawToDeg(0);
    let navigationCompassRotationAngle = yawToDeg(
      currentImage ? currentImage.gimbalYaw : 0
    );

    if (pegImageRotationToNorth && isNadirImage) {
      navigationRotationAngle = yawToDeg(
        currentImage ? currentImage.gimbalYaw : 0
      );
      navigationCompassRotationAngle = yawToDeg(0);
    }

    const insetMaximized = insetState === 'maximized';
    const insetMinimized = insetState === 'minimized';

    const { view, children, share } = this.props;
    const { temperatureUnit } = view;

    const temperatureUnitLabel = temperatureUnit === 'FAHRENHEIT' ? '°F' : '°C';

    const imageCount = this.getViewableImages().length;

    return (
      <div className={styles.container}>
        <div
          className={classnames(styles.containerInnerWrapper, {
            [styles.splitPanes]: insetMaximized,
          })}
        >
          <Resizer
            allowedDirections={['right']}
            onDrag={(e: any) =>
              this.setState({ insetSize: { ...insetSize, width: e.right } })
            }
            showHotspot={{
              right: insetMaximized,
            }}
            style={{ maxWidth: '50%' }}
          >
            <div
              style={insetMaximized ? insetSize : {}}
              className={classnames(styles.mapContainer, {
                [styles.float]: !insetMaximized,
                [styles.sidePane]:
                  insetMaximized && !this.checkIfViewSelectorIsPresent(),
                [styles.sidePaneWithSelector]:
                  insetMaximized && this.checkIfViewSelectorIsPresent(),
                [styles.minimized]: insetState === 'minimized',
                [styles.thermal]: !!rangeFilter,
              })}
            >
              {document.body.clientHeight >= 500 && (
                <>
                  <div
                    className={styles.informationContainer}
                    style={insetMaximized ? { opacity: 1 } : {}}
                  >
                    <div className={styles.item} style={{ width: '100%' }}>
                      <div>
                        <Text>Peg North&nbsp;</Text>
                        <Switch
                          defaultChecked={pegImageRotationToNorth}
                          onChange={(flag: boolean) =>
                            this.setState({ pegImageRotationToNorth: flag })
                          }
                        />
                      </div>
                      {this.showHideImageControls() && (
                        <div className={styles.item}>
                          <Text>Show Hidden Images&nbsp;</Text>
                          <Switch
                            defaultChecked={showHiddenImages}
                            onChange={this.setShowHiddenImages}
                          />
                        </div>
                      )}
                      <div
                        className={styles.windowButtons}
                        style={{ height: '21px' }}
                      >
                        <i
                          className={`fa fa-window-${
                            insetMinimized ? 'restore' : 'minimize'
                          } ${styles.windowButton}`}
                          aria-hidden="true"
                          onClick={() => {
                            this.setState({
                              insetState: insetMinimized
                                ? 'window'
                                : 'minimized',
                            });
                          }}
                        />
                        {undefinedOrNull(share) && (
                          <i
                            className={`fa fa-${
                              insetMaximized ? 'compress' : 'expand'
                            } ${styles.windowButton}`}
                            aria-hidden="true"
                            onClick={() => {
                              this.setState({
                                insetState: insetMaximized
                                  ? 'window'
                                  : 'maximized',
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {!!rangeFilter && (
                    <div
                      className={styles.informationContainer}
                      style={insetMaximized ? { opacity: 1 } : {}}
                    >
                      <p
                        className={styles.item}
                        style={{
                          fontSize: '9px',
                          color: 'black',
                          whiteSpace: 'normal',
                        }}
                      >
                        {`Show images within temperature range`}
                      </p>
                      <Slider
                        min={Math.floor(
                          this.toViewTemperatureUnits(rangeFilter.min)
                        )}
                        max={Math.ceil(
                          this.toViewTemperatureUnits(rangeFilter.max)
                        )}
                        range
                        defaultValue={[
                          this.toViewTemperatureUnits(rangeFilter.rangeMin),
                          this.toViewTemperatureUnits(rangeFilter.rangeMax),
                        ]}
                        className={styles.item}
                        style={{ width: '100%', paddingBottom: '10px' }}
                        onChange={(e: any) => {
                          const [min, max] = e;

                          this.setTemperatureFilter({ min, max });
                        }}
                      />
                      <p
                        className={styles.item}
                        style={{
                          color: 'black',
                          paddingTop: '9px',
                        }}
                      >
                        ({`${imageCount}/${imageCount + filteredCount}`})
                      </p>
                    </div>
                  )}
                </>
              )}
              <div
                className={classnames(styles.navigationToggleButtonWrapper, {
                  hide: insetState === 'minimized',
                })}
                style={{
                  left: insetMaximized ? insetSize.width + 15 : 400,
                  top: insetMaximized ? 65 : 51,
                }}
              >
                {enabledInsetTypes.length > 1 &&
                  enabledInsetTypes.map((t) => {
                    const desc = this.getInsetDescription(t);

                    return (
                      <Tooltip key={t} placement="right" title={desc.tooltip}>
                        <button
                          className={classnames({
                            [styles.active]: selectedInsetType === t,
                          })}
                          onClick={() => {
                            this.setState({ selectedInsetType: t });
                          }}
                        >
                          <i
                            className={`fa fa-${desc.icon}`}
                            aria-hidden="true"
                          />
                        </button>
                      </Tooltip>
                    );
                  })}
              </div>
              <div
                id="imageInsetMap"
                className={classnames(styles.plotGoogleMapWrapper, {
                  [styles.sidePane]: insetMaximized,
                  hide: insetState === 'minimized',
                })}
              >
                {insetState === 'minimized' ? null : this.renderInset()}
              </div>
            </div>
          </Resizer>
          <div
            id="sitewalkthrough-container"
            className={classnames(styles.tilingWrapper, {
              [styles.sidePane]: insetMaximized,
            })}
          >
            {currentImage ? (
              <ImageRenderer
                image={currentImage}
                rotation={navigationRotationAngle}
                viewConfig={viewConfig}
                temperatureRange={rangeFilter}
                setOlMap={(m) => this.setState({ olMap: m })}
              >
                {!!rangeFilter && (
                  <div className={styles.colorScale}>
                    <DynamicScale
                      sourceRange={{
                        min: this.toViewTemperatureUnits(rangeFilter.min),
                        max: this.toViewTemperatureUnits(rangeFilter.max),
                      }}
                      colors={MAGMA}
                      units={temperatureUnitLabel}
                      readonly
                      clampMax
                      clampMin
                    />
                  </div>
                )}
                <div
                  key="mav_controls"
                  id="imageNavButtons"
                  className={`${styles.navigationButtonsWrapper} no-include-screenshot`}
                  style={{
                    opacity: view?.type === 'perspective' ? 1 : undefined,
                  }}
                >
                  {this.renderNavigationControls()}
                </div>
                <div
                  key="mav_box_wrapper"
                  className={`${styles.navigationBoxWrapper} no-include-screenshot`}
                >
                  <NavigationBox
                    altitude={{
                      value: currentImage.elevation || 0,
                      round: true,
                      unit: 'meters',
                    }}
                    rotation={360 - navigationCompassRotationAngle}
                    temperatureRange={
                      rangeFilter
                        ? {
                            min: this.toViewTemperatureUnits(
                              currentImage.minTemperature
                            ),
                            max: this.toViewTemperatureUnits(
                              currentImage.maxTemperature
                            ),
                            unit: temperatureUnit === 'CELSIUS' ? 'C' : 'F',
                          }
                        : undefined
                    }
                  />
                </div>
                {children}
                <MapStateProvider
                  key="map_state_provider_screenshot"
                  ref={this.stateProviderRef}
                  convertToLatLon={false}
                />
                {showAnnotationRenderFrag ? (
                  <ImageAnnotationControl
                    annotationsData={{
                      annotations:
                        triangulationPoints
                          ?.filter((tp) => tp.image.guid === currentImage?.guid)
                          .map((tp) => {
                            return {
                              center: tp.pixel,
                              source: PIXEL_SOURCE.user,
                            };
                          }) || [],
                    }}
                  />
                ) : (
                  <></>
                )}
                {showProjAnnotationRenderFrag ? (
                  <ImageAnnotationControl
                    annotationsData={{
                      annotations:
                        highlistImageList
                          ?.filter(
                            (tp) =>
                              tp.guid === currentImage?.guid &&
                              !userMarkedList?.includes(currentImage?.guid) &&
                              tp.pixel
                          )
                          .map((tp) => {
                            const pixel = tp.pixel as PixelCoordinates;

                            return {
                              center: { x: pixel.x, y: -1 * pixel.y },
                              source: PIXEL_SOURCE.system,
                            };
                          }) || [],
                    }}
                  />
                ) : (
                  <></>
                )}
              </ImageRenderer>
            ) : (
              <NoImagePlaceholder />
            )}
          </div>
          {this.renderContextMenu()}
        </div>
        {this.renderModals()}
      </div>
    );
  }
}
