import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import ProcessedDataUploader from './index';
import { actionShowSnackbar } from '../../../redux/actions/snackbar';
import { fetchViewById } from '../../../redux/actions/views';
import { ProcessedDataUploaderTypes } from './index.types';

const mapStateToProps = (): ProcessedDataUploaderTypes.ReduxState => {
  return {};
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ProcessedDataUploaderTypes.DispatchProps => {
  return bindActionCreators(
    {
      showSnackBar: actionShowSnackbar,
      fetchViewById,
    },
    dispatch
  );
};

const Container = connect<
  ProcessedDataUploaderTypes.ReduxState,
  ProcessedDataUploaderTypes.DispatchProps,
  ProcessedDataUploaderTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ProcessedDataUploader);

export default Container;
