import * as React from 'react';
import { Route } from 'react-router';
import Issue from '../../api/issue';
import ViewAPIs from '../../api/views';
import MLObjectAPIs from '../../api/ml';
import MLClassAPIs from '../../api/mlClasses';
import ElevationDifferenceAPIs from '../../api/elevationDifference';
import ViewDisplay from '../View';
import ViewFooter from '../ViewFooter/ViewFooterContainer';
import style from './index.module.scss';
import {
  ViewDataManagerPropsType,
  ViewDataManagerStateType,
} from './index.types';
import { ViewEvent, ControlBoxStatus } from '../View/index.types';
import {
  SPLIT_VIEW_VALID_CONTROL_TYPES,
  VALID_CONTROL_TYPES,
} from '../View/ViewControls/index.types';
import { CreateViewRequest, View } from '../../api/views.types';

export default class ViewDataManager extends React.Component<
  ViewDataManagerPropsType,
  ViewDataManagerStateType
> {
  private issuesAPI: Issue = new Issue();

  private viewsAPI: ViewAPIs = new ViewAPIs();

  private mlObjectAPI = new MLObjectAPIs();

  private mlClassAPI = new MLClassAPIs();

  private elevationDifferenceAPI = new ElevationDifferenceAPIs();

  constructor(props: ViewDataManagerPropsType) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const {
      fetchViews,
      issueId,
      viewId,
      splitViewId,
      projectId,
      aoiId,
      viewList,
    } = this.props;

    if (this.isViewListStale()) {
      if (fetchViews) {
        fetchViews();
      }
    } else if (viewList) {
      this.handleViewUpdate(viewList, viewId, 'view');

      if (splitViewId) {
        this.handleViewUpdate(viewList, splitViewId, 'splitView');
      }
    }

    if (issueId) {
      this.fetchIssue(issueId);
    }

    if (viewId) {
      this.fetchDownloads(viewId);
      this.fetchReports(viewId);
      this.fetchMLObjects(viewId);
    }

    if (projectId) {
      this.fetchMLClasses(projectId);

      if (aoiId) {
        this.fetchElevationDifferences(projectId, aoiId);
      }
    }
  }

  public UNSAFE_componentWillReceiveProps({
    viewList: nextViewList,
    viewId: nextViewId,
    splitViewId: nextSplitViewId,
    issueId: nextIssueId,
    projectId: nextProjectId,
    aoiId: nextAoiId,
  }: ViewDataManagerPropsType) {
    const { viewList, viewId, splitViewId, issueId, projectId, aoiId } =
      this.props;

    if (nextViewList) {
      // viewId change
      if (nextViewId && (nextViewList !== viewList || nextViewId !== viewId)) {
        this.handleViewUpdate(nextViewList, nextViewId, 'view');
      }

      // splitViewId change
      if (nextViewList !== viewList || nextSplitViewId !== splitViewId) {
        this.handleViewUpdate(nextViewList, nextSplitViewId, 'splitView');
      }
    }

    if (issueId !== nextIssueId) {
      this.fetchIssue(nextIssueId);
    }

    if (viewId !== nextViewId) {
      this.fetchDownloads(nextViewId);
      this.fetchReports(nextViewId);
      this.fetchMLObjects(nextViewId);
    }

    if (projectId !== nextProjectId) {
      this.fetchMLClasses(nextProjectId);
      this.fetchElevationDifferences(nextProjectId, nextAoiId);
    } else if (aoiId !== nextAoiId) {
      // fetching elevation differences on AOI change
      this.fetchElevationDifferences(nextProjectId, nextAoiId);
    }
  }

  public componentWillUnmount() {
    const { resetCurrentView } = this.props;

    resetCurrentView();
  }

  private isViewListStale = (props?: ViewDataManagerPropsType): boolean => {
    const { aoiId, viewList } = props || this.props;

    if (!viewList || viewList.length === 0) {
      return true;
    }

    if (viewList[0].aoiId !== aoiId) {
      // viewList does not belong to current AOI list
      return true;
    }

    return false;
  };

  private fetchIssue(issueId: string | undefined) {
    this.setState({ issue: undefined }, () => {
      if (issueId) {
        this.issuesAPI.fetchIssue(issueId).then((res) => {
          const { data, error } = res;

          if (error) {
            console.error(error);
          } else if (data) {
            this.setState({ issue: data });
          }
        });
      }
    });
  }

  private fetchReports(viewId: string) {
    this.setState({ reports: undefined }, () => {
      if (!viewId) return;
      this.viewsAPI.getReports(viewId).then((res) => {
        const { data, error } = res;

        if (error) {
          console.error(error);

          return;
        }

        if (data) {
          this.setState({ reports: data });
        }
      });
    });
  }

  private fetchDownloads(viewId: string) {
    this.setState({ downloads: undefined }, () => {
      if (!viewId) return;
      this.viewsAPI.getDownloads(viewId).then((res) => {
        const { data, error } = res;

        if (error) {
          console.error(error);

          return;
        }

        if (data) {
          this.setState({ downloads: data });
        }
      });
    });
  }

  private fetchMLObjects(viewId: string) {
    this.setState(
      {
        siteObjects: undefined,
      },
      () => {
        this.mlObjectAPI.getViewObjects(viewId).then((res) => {
          const { data, error } = res;

          if (error) {
            console.error('Error fetching ML objects:', error);

            return;
          }

          if (data) {
            this.setState({
              siteObjects: data,
            });
          }
        });
      }
    );
  }

  private updateMLObjects(viewId: string) {
    this.mlObjectAPI.getViewObjects(viewId).then((res) => {
      const { data, error } = res;

      if (error) {
        console.error('Error fetching ML objects:', error);

        return;
      }

      if (data) {
        this.setState({
          siteObjects: data,
        });
      }
    });
  }

  private fetchMLClasses(projectId: string) {
    this.setState(
      {
        projectMLClasses: undefined,
      },
      () => {
        this.mlClassAPI.getProjectSiteObjectClasses(projectId).then((res) => {
          const { data, error } = res;

          if (error) {
            console.error('Error fetching ML classes:', error);

            return;
          }

          if (data) {
            this.setState({
              projectMLClasses: data,
            });
          }
        });
      }
    );
  }

  private fetchElevationDifferences(projectId: string, aoiId: string) {
    this.setState(
      {
        elevationDifferences: undefined,
      },
      () => {
        this.elevationDifferenceAPI
          .getElevationDifferencesForAOI(projectId, aoiId)
          .then((res) => {
            const { data, error } = res;

            if (error) {
              console.error('Error fetching elevation differences:', error);

              return;
            }

            if (data) {
              this.setState({
                elevationDifferences: data,
              });
            }
          });
      }
    );
  }

  private handleViewUpdate = (
    viewList: any[],
    viewId: string | undefined,
    viewType: 'view' | 'splitView' = 'view'
  ) => {
    const view = viewList.find((view) => view.id === viewId);

    if (viewType === 'view') {
      this.setState({ view });
    } else if (viewType === 'splitView') {
      this.setState({ splitView: view });
    } else {
      console.error('Unsupported view type: ', viewType);
    }
  };

  private handleViewEvent = async (event: ViewEvent<any>) => {
    // const { history } = this.props;
    const { view } = this.state;

    switch (event?.type || '') {
      case 'control_box_close': {
        const { itemType } = (event.data || {}) as ControlBoxStatus;

        if (itemType === 'mark_issue') {
          this.removeIssue();
        }

        break;
      }

      case 'site_objects_changed': {
        if (view) {
          this.updateMLObjects(view.id);
        }

        break;
      }

      case 'image_selection_changed': {
        this.removeIssue();

        break;
      }

      case 'issue_created': {
        this.setState({ issue: event.data });

        break;
      }

      case 'create_view': {
        await this.createNewView(event);

        break;
      }

      case 'show_snackbar': {
        const { showSnackbar } = this.props;

        showSnackbar(event.data);
        break;
      }

      default:
        break;
    }
  };

  private removeIssue() {
    this.setState({ issue: undefined });
    // check if we have issue id in url, if so, remove it
    const location = window.location.href;
    const newLocation = this.removeParam('issueId', location);

    if (newLocation !== location) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(null, '', newLocation);
    }
  }

  private removeParam = (key: string, sourceURL: string) => {
    let rtn = sourceURL.split('?')[0];
    let param;
    let params_arr = [];
    const queryString =
      sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';

    if (queryString !== '') {
      params_arr = queryString.split('&');
      for (let i = params_arr.length - 1; i >= 0; i -= 1) {
        // eslint-disable-next-line prefer-destructuring
        param = params_arr[i].split('=')[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }

      if (params_arr?.length) {
        rtn = `${rtn}?${params_arr.join('&')}`;
      }
    }

    return rtn;
  };

  private async createNewView(event: ViewEvent<CreateViewRequest>) {
    const { projectId, aoiId, showSnackbar } = this.props;
    const req = event.data as CreateViewRequest;
    const viewResponse = await this.viewsAPI
      .createView(projectId, aoiId, req)
      .catch((e) => {
        return {
          status: e.response.status,
          data: e.response.data,
        };
      });

    if (viewResponse.status === 200) {
      const { addViewManually, history } = this.props;

      // TODO: add check to ensure response.data is a valid view
      addViewManually(viewResponse.data as View);
      history.push(viewResponse.data.id);
      showSnackbar({
        isModal: false,
        body: 'The new view has been created.',
        type: 'success',
      });

      this.setState({ view: viewResponse.data });
    } else {
      showSnackbar({
        type: 'error',
        body: 'We ran into an error while trying to create the view. Please try again later, or contact support@aspecscire.com for assistance',
        isModal: false,
      });
      console.error(
        'There was an error while trying to create the view.',
        viewResponse.data
      );
    }
  }

  private setFooterControlsFrag = (frag: any) => {
    this.setState({ footerControlsFragment: frag });
  };

  public render() {
    const {
      projectId,
      aoiId,
      viewId,
      splitViewId,
      userRole,
      headerMenus,
      isStaff,
      viewList,
      history,
      showCoachMarks,
      resetCoachMarks,
      overlayGeojson,
      showSatellite,
    } = this.props;
    const {
      view,
      splitView,
      compareView,
      issue,
      downloads,
      reports,
      siteObjects,
      projectMLClasses,
      elevationDifferences,
      footerControlsFragment,
    } = this.state;

    return (
      <>
        <div
          className={style.container}
          style={{
            height: splitViewId ? '100%' : 'calc(100% - 40px)',
            width: '100%',
          }}
        >
          <ViewDisplay
            projectId={projectId}
            aoiId={aoiId}
            viewId={viewId}
            history={history}
            splitViewId={splitViewId}
            controlsWhitelist={
              splitViewId ? SPLIT_VIEW_VALID_CONTROL_TYPES : VALID_CONTROL_TYPES
            }
            userRole={userRole || undefined}
            isStaff={isStaff}
            onEvent={this.handleViewEvent}
            onFooterControlsFragChange={this.setFooterControlsFrag}
            view={view}
            splitView={splitView}
            compareView={compareView}
            viewList={viewList}
            issue={issue}
            downloads={downloads}
            reports={reports}
            siteObjects={siteObjects}
            projectMLClasses={projectMLClasses}
            elevationDifferences={elevationDifferences}
            headerMenus={headerMenus}
            showCoachMarks={showCoachMarks}
            resetCoachMarks={resetCoachMarks}
            overlayGeojson={overlayGeojson}
            showSatellite={showSatellite?.toLowerCase() === 'true'}
          />
        </div>
        {view && !splitViewId && (
          <>
            <Route
              exact
              path="/project/:projectId/aoi/:aoiId/view/:viewId"
              render={({ match }) => {
                return (
                  <ViewFooter {...match.params}>
                    {footerControlsFragment}
                  </ViewFooter>
                );
              }}
            />
            <Route
              exact
              path="/share/:shareId"
              render={({ match }) => {
                return (
                  <ViewFooter {...match.params} viewId={view.id}>
                    {footerControlsFragment}
                  </ViewFooter>
                );
              }}
            />
          </>
        )}
      </>
    );
  }
}
