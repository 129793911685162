import * as React from 'react';

interface IProps {
  message: string;
}

interface IState {
  open: boolean;
}

class Toast extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      open: true,
    };
  }

  private handleToastClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { message } = this.props;
    const { open } = this.state;

    return open ? (
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          top: '5px',
          zIndex: 1,
          background: 'rgba(102, 102, 102, 0.8)',
          borderRadius: '10px',
          transform: 'translate(-50%, 0%)',
          marginLeft: '50%',
          padding: '5px',
        }}
      >
        <span>{message}</span>
        <div style={{ marginLeft: '5px' }}>
          <i
            className="fa fa-times-circle"
            style={{ marginTop: '3px' }}
            aria-hidden="true"
            onClick={this.handleToastClose}
          />
        </div>
      </div>
    ) : null;
  }
}

export default Toast;
