// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectMapList_container__MdVYt{width:auto}", "",{"version":3,"sources":["webpack://./src/components/ProjectMapList/ProjectMapList.module.scss"],"names":[],"mappings":"AAEA,iCACE,UAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ProjectMapList_container__MdVYt"
};
export default ___CSS_LOADER_EXPORT___;
