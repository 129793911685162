import { LeftOutlined } from '@ant-design/icons';
import { Modal, Radio } from 'antd';
import * as React from 'react';
import MissionAPIs from '../../../api/mission';
import View from '../../View';
import style from './index.module.scss';
import ViewEditor from './ViewEditor';
import Interior360Editor from '../../Interior360Manager/Interior360Editor';
import ViewsV2Apis from '../../../api/viewsV2';
import CreateView from './CreateView';

interface IProps {
  aoiId: string;
  projectId: string;
  missionId: string;
  user: any;
  controlManageModalVisibility: (show: boolean) => void;
}

interface IState {
  missionApi: MissionAPIs;
  viewsApi: ViewsV2Apis;
  aoiViews: any;
  viewWrappers: any[] | null;
  loadingError: string | null;
  viewPropsOverrides: any;
  updating: boolean;
  viewChildrenFrags?: any;
  // this is an optional speciic editor fragment which takes over the left panel
  editorFrag?: any;
  getViewConfigFn?: () => any;
  previewMode: 'preview' | 'edit';
  showCreateViewModal?: boolean;
}

export default class Publish extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      missionApi: new MissionAPIs(),
      viewsApi: new ViewsV2Apis(),
      aoiViews: [],
      viewWrappers: null,
      loadingError: null,
      viewPropsOverrides: {},
      updating: false,
      previewMode: 'preview',
    };
  }

  public componentDidMount() {
    const { missionApi, viewsApi } = this.state;
    const { projectId, aoiId, missionId } = this.props;

    missionApi
      .getMissionViews(projectId, aoiId, missionId)
      .then((res) => {
        const viewWrappers = this.sortViews(res.data || []).map((v: any) => {
          return {
            view: v,
            selected: false,
          };
        });

        this.setState(
          {
            viewWrappers,
          },
          () => {
            if (viewWrappers?.length) {
              this.selectView(viewWrappers[0].view.id);
            }
          }
        );
      })
      .catch((_err) => {
        this.setState({
          viewWrappers: null,
          loadingError:
            'Could not load views, please try again later or contact support@aspecscire.com',
        });
      });

    viewsApi
      .getViewsByTypeAndPublishStatus(projectId, aoiId, 'three_d', true)
      .then((res) => {
        this.setState({ aoiViews: res.data });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ aoiViews: [] });
      });
  }

  private sortViews(views: any[]): any[] {
    const sortedByCreatedTime = views.sort((a, b) => {
      return a.createdAt < b.createdAt
        ? -1
        : a.createdAt === b.createdAt
        ? 0
        : 1;
    });

    const segregate = (
      source: any[],
      target: any[],
      type: string,
      subType: string | null = null
    ): any[] => {
      const remaining: any[] = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const v of source) {
        if (
          v.type === type &&
          (subType != null ? v.subType === subType : true)
        ) {
          target.push(v);
        } else {
          remaining.push(v);
        }
      }

      return remaining;
    };

    let segregated: any[] = [];
    /* eslint-disable */
    let remaining = segregate(sortedByCreatedTime, segregated, 'map');

    remaining = segregate(remaining, segregated, 'site_navigation');
    remaining = segregate(remaining, segregated, 'three_d');
    remaining = segregate(remaining, segregated, 'exterior_360');
    remaining = segregate(remaining, segregated, 'perspective');

    segregated = [...segregated, ...remaining];
    /* eslint-enable */

    return segregated;
  }

  private getViewDisplayName(v: any) {
    if (v.name) {
      return v.name;
    }

    switch (v.type) {
      case 'map':
        switch (v.subType) {
          case 'aerial':
            return 'Aerial View';
          case 'elevation':
            return 'Elevation View';
          case 'survey':
            return 'Survey View';
          default:
            return `Map View (${v.subType})`;
        }

      case 'site_navigation':
        return 'Site Navigation View';

      case 'three_d':
        return '3D View';

      case 'exterior_360':
        return 'Exterior 360 View';

      case 'perspective':
        return 'Perspective View';

      case 'inspection':
        return 'Inspection View';

      default:
    }

    return `${v.type} View (${v.subType})`;
  }

  private selectView(id: string) {
    const { viewWrappers } = this.state;

    // eslint-disable-next-line no-restricted-syntax
    for (const vw of viewWrappers || []) {
      if (vw.view.id === id) {
        vw.selected = true;
      } else {
        vw.selected = false;
      }
    }

    this.setState({
      viewWrappers: [...(viewWrappers || [])],
      viewPropsOverrides: {},
      viewChildrenFrags: null,
      editorFrag: null,
      previewMode: 'preview',
    });
  }

  private insertUpdatedView(view: any) {
    const { viewWrappers } = this.state;

    // eslint-disable-next-line no-restricted-syntax
    for (const v of viewWrappers || []) {
      if (v.view.id === view.id) {
        v.view = view;
        break;
      }
    }

    this.setState({ viewWrappers: [...(viewWrappers || [])] });
  }

  private renderPreview(view: any) {
    const { user } = this.props;
    const { viewPropsOverrides, updating, viewChildrenFrags, previewMode } =
      this.state;

    if (updating) {
      return 'Updating...';
    }

    if (view.type === 'exterior_360' && previewMode === 'edit') {
      const { projectId, aoiId } = view;

      return (
        <Interior360Editor
          action="edit"
          projectId={projectId}
          aoiId={aoiId}
          view={view}
        />
      );
    }

    return (
      <View
        view={view}
        userData={user}
        onConfigCallbackChange={() => {}}
        onEvent={() => {}}
        controlsWhitelist={['measure', 'download']}
        registerViewConfigProvider={(cb) => {
          this.setState({ getViewConfigFn: cb });
        }}
        {...viewPropsOverrides}
      >
        {viewChildrenFrags}
      </View>
    );
  }

  private getSelectedView() {
    const { viewWrappers } = this.state;
    const selectedWrapper =
      (viewWrappers || []).find((v) => v.selected) || null;

    return selectedWrapper?.view;
  }

  private renderViewsContainer() {
    const { editorFrag } = this.state;

    if (editorFrag) {
      return (
        <div className={style.viewsContainer} style={{ overflow: 'auto' }}>
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <span
              role="link"
              className={style.backNav}
              tabIndex={0}
              onClick={() => this.setState({ editorFrag: null })}
            >
              <LeftOutlined />
            </span>
            <h2 style={{ marginLeft: '8em' }}>MANAGE 3-D VECTORS</h2>
          </div>
          {editorFrag}
        </div>
      );
    }

    const { aoiViews, viewWrappers, getViewConfigFn, previewMode } = this.state;
    const { user, projectId, aoiId, missionId, controlManageModalVisibility } =
      this.props;
    const selectedView = this.getSelectedView();
    const allViews = (viewWrappers || []).map((vw) => vw.view);

    return (
      <div className={style.viewsContainer}>
        <div className={style.viewListContainer}>
          <table className={style.viewListTable}>
            <thead>
              <tr>
                <th />
                <th>
                  View
                  {user.staff && (
                    <a
                      style={{ float: 'right' }}
                      onClick={() =>
                        this.setState({ showCreateViewModal: true })
                      }
                    >
                      +
                    </a>
                  )}
                </th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {(viewWrappers || []).map((vw: any) => {
                const v = vw.view;

                return (
                  <tr key={v.id} onClick={(_e: any) => this.selectView(v.id)}>
                    <td>
                      <Radio
                        type="radio"
                        checked={vw.selected}
                        onChange={(_e: any) => this.selectView(v.id)}
                      />
                    </td>
                    <td style={{ width: '99%' }}>
                      <div key={v.id} className={style.viewItem}>
                        <label>
                          <span
                            style={{
                              borderBottom: vw.selected ? '1px solid red' : '',
                              cursor: 'pointer',
                            }}
                          >
                            {this.getViewDisplayName(v)}
                          </span>
                        </label>
                      </div>
                    </td>
                    <td>
                      {v.certifiedForDisplay ? 'PUBLISHED' : 'UNPUBLISHED'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={style.controlsContainer}>
          {selectedView && (
            <ViewEditor
              projectId={projectId}
              aoiId={aoiId}
              missionId={missionId}
              view={selectedView}
              showAdminFields={user.staff}
              allViews={allViews}
              aoiViews={aoiViews}
              previewMode={previewMode}
              getViewConfigFn={getViewConfigFn}
              onViewUpdated={(v: any) => {
                this.insertUpdatedView(v);
              }}
              onUpdatingStatusChanged={(updating: boolean) => {
                this.setState({ updating });
              }}
              onViewPropsUpdate={(p: any) =>
                this.setState({ viewPropsOverrides: p || {} })
              }
              onViewFragsUpdate={(frags: any) => {
                this.setState({ viewChildrenFrags: frags });
              }}
              onEditorFragUpdate={(editorFrag: any) => {
                this.setState({ editorFrag });
              }}
              onPreviewModeChange={(previewMode: 'preview' | 'edit') => {
                this.setState({ previewMode });
              }}
              controlManageModalVisibility={controlManageModalVisibility}
            />
          )}
        </div>
      </div>
    );
  }

  public render() {
    const { projectId, aoiId, missionId } = this.props;
    const { viewWrappers, loadingError, showCreateViewModal } = this.state;

    if (viewWrappers === null) {
      return (
        <div className={style.container}>
          {loadingError !== null ? loadingError : 'Loading...'}
        </div>
      );
    }

    const selectedView = this.getSelectedView();

    return (
      <div className={style.container}>
        {this.renderViewsContainer()}
        <div className={style.previewContainer}>
          {selectedView ? (
            this.renderPreview(selectedView)
          ) : (
            <p style={{ margin: '0.5em', textAlign: 'center' }}>
              Select a view to preview it
            </p>
          )}
        </div>
        <Modal
          visible={!!showCreateViewModal}
          onCancel={() => this.setState({ showCreateViewModal: undefined })}
          afterClose={() => this.setState({ showCreateViewModal: undefined })}
          destroyOnClose
          footer={null}
        >
          <CreateView
            projectId={projectId}
            aoiId={aoiId}
            missionId={missionId}
            onViewCreated={(view) => {
              this.setState({
                viewWrappers: [{ view }, ...viewWrappers],
                showCreateViewModal: undefined,
              });
            }}
          />
        </Modal>
      </div>
    );
  }
}
