import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchMissions } from '../../../redux/actions/aois';
import DiagnosticOperations from './index';
import { DiagnosticsOperationsTypes } from './index.types';

const mapStateToProps = (state: any): DiagnosticsOperationsTypes.ReduxState => {
  return {
    loading: state.aois.loading,
    missions: state.aois.missions,
    error: state.aois.error,
  };
};
const mapDispatchToProps = (
  dispatch: Dispatch
): DiagnosticsOperationsTypes.DispatchProps => {
  return bindActionCreators(
    {
      fetchMissions,
    },
    dispatch
  );
};

export default connect<
  DiagnosticsOperationsTypes.ReduxState,
  DiagnosticsOperationsTypes.DispatchProps,
  DiagnosticsOperationsTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(DiagnosticOperations);
