// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IssueIconButton_container__5VAPc{display:block;padding:19px 12px}.IssueIconButton_container__5VAPc i{font-size:25px;margin-top:3px;position:relative;bottom:6px}", "",{"version":3,"sources":["webpack://./src/components/Header/IssueIconButton/index.module.scss"],"names":[],"mappings":"AAEA,kCACE,aAAA,CACA,iBAAA,CAGF,oCACE,cAAA,CACA,cAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":["@import '../../../styles/scss/base/base';\n\n.container {\n  display: block;\n  padding: 19px 12px;\n}\n\n.container i {\n  font-size: 25px;\n  margin-top: 3px;\n  position: relative;\n  bottom: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "IssueIconButton_container__5VAPc"
};
export default ___CSS_LOADER_EXPORT___;
