import { connect } from 'react-redux';
import { getSelectedView } from '../../redux/selectors/views';
import { clearIssueState, fetchSingleIssue } from '../../redux/actions/issues';
import {
  fetchDownloads,
  fetchReports,
  fetchViews,
  resetCurrentView,
  setLastView,
} from '../../redux/actions/views';
import ViewDsm from './index';

const mapStateToProps = (
  state: any,
  { viewId, compareViewId, splitViewId }: any
): any => {
  return {
    view: getSelectedView(state, { viewId }),
    compareView: getSelectedView(state, { viewId: compareViewId }),
    splitView: getSelectedView(state, { viewId: splitViewId }),
    issue: state.issue.singleIssue,
    downloads: state.views.downloads,
    reports: state.views.reports,
  };
};

const mapDispatchToProps = (dispatch: any, { projectId, aoiId }: any) => {
  return {
    fetchViews: () => dispatch(fetchViews(projectId, aoiId)),
    fetchDownloads: (viewId: string) => dispatch(fetchDownloads(viewId)),
    fetchReports: (viewId: string) => dispatch(fetchReports(viewId)),
    resetCurrentView: () => dispatch(resetCurrentView()),
    fetchSingleIssue: (id: string) => dispatch(fetchSingleIssue(id)),
    clearIssueState: () => dispatch(clearIssueState()),
    setLastView: (viewId: string, splitViewId: string) =>
      dispatch(setLastView(viewId, splitViewId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(ViewDsm);
