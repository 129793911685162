import { useRouteMatch } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';
import ProjectsAPI from '../../api/projects';
import { Project, ProjectArchivalInfo } from '../../api/projects.types';
import {
  FetchProjectBrandingContext,
  FetchProjectContext,
  ManageBrandingDataContext,
  ProjectArchivalContext,
  ProjectUpdateContext,
} from './index.types';
import {
  ProjectEditProps,
  ProjectUpdateValidation,
} from '../CreateProjectV2/index.types';
import { Owner } from '../../api/organisations.types';
import { User } from '../../api/auth.types';
import { BrandingModel } from '../../shapes/branding';
import ProjectV2Apis from '../../api/projectsV2';
import { SnackbarActionsActionShowSnackbarTypes } from '../../shapes/snackbar';

export const useProjectFromParams = (): FetchProjectContext => {
  const { params } = useRouteMatch<{ projectId: string | undefined }>();
  const { projectId } = params;

  return useProjectFromId(projectId);
};

export const useFetchProjectBrandingFromId = (
  projectId?: string
): FetchProjectBrandingContext => {
  const projectsV2Api = useMemo(() => {
    return new ProjectV2Apis();
  }, []);

  const [brandingData, setBrandingData] = useState<BrandingModel>();

  const fetchBrandingData = useMemo(() => {
    return async () => {
      if (projectId?.length)
        await projectsV2Api
          .getProjectBranding(projectId)
          .then((res) => {
            const { data, error } = res;

            if (error) {
              setBrandingData(undefined);

              return;
            }

            if (data) {
              setBrandingData(data);
            }
          })
          .catch((e) => {
            console.error('Error while fetching branding data', e);
            setBrandingData(undefined);
          });
    };
  }, [projectId]);

  useEffect(() => {
    fetchBrandingData();

    return () => {
      setBrandingData(undefined);
    };
  }, [fetchBrandingData]);

  return {
    brandingData,
    fetchBrandingData,
  };
};

export const useProjectFromId = (projectId?: string): FetchProjectContext => {
  const projectsApi = useMemo(() => {
    return new ProjectsAPI();
  }, []);
  const [project, setProject] = useState<Project>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    setLoading(true);
    setError(undefined);

    if (projectId?.length)
      projectsApi
        .getProject(projectId)
        .then((p) => {
          setProject(p);
          setLoading(false);
          setError(undefined);
        })
        .catch((e) => {
          setProject(undefined);
          setLoading(false);
          setError(e);
        });
    else {
      setLoading(false);
      setError('Invalid Project Id');
    }

    return () => {
      setProject(undefined);
    };
  }, [projectId]);

  return {
    project,
    loading,
    error,
    setProject,
  };
};

export const useProjectUpdateContext = (): ProjectUpdateContext => {
  const projectsApi = useMemo(() => {
    return new ProjectsAPI();
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const updateProject = useMemo(() => {
    return async (
      projectId: string,
      project: ProjectEditProps
    ): Promise<Project | undefined> => {
      setLoading(true);

      return await projectsApi
        .updateProject(projectId, project)
        .catch((err) => {
          console.error(err);

          return undefined;
        })
        .finally(() => setLoading(false));
    };
  }, []);

  return {
    loading,
    updateProject,
  };
};

export const useProjectArchivalContext = (): ProjectArchivalContext => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const projectsApi = useMemo(() => {
    return new ProjectsAPI();
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const archiveProject = useMemo(() => {
    return async (
      projectId: string,
      archived: boolean,
      restore: boolean
    ): Promise<ProjectArchivalInfo | undefined> => {
      setLoading(true);

      return await projectsApi
        .updateProjectArchivalStatus(projectId, archived, restore)
        .catch((err) => {
          console.error(err);

          return undefined;
        })
        .finally(() => setLoading(false));
    };
  }, []);

  return {
    loading,
    archiveProject,
  };
};

export const useManageBrandingDataContext = (
  projectId: string,
  showSnackbar: SnackbarActionsActionShowSnackbarTypes
): ManageBrandingDataContext => {
  const projectV2Apis = useMemo(() => {
    return new ProjectV2Apis();
  }, []);
  const { brandingData, fetchBrandingData } =
    useFetchProjectBrandingFromId(projectId);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const updateBrandingData = useMemo(() => {
    return async () => {
      await form
        .validateFields(['brandingName', 'brandingWebsite'])
        .then(async (values) => {
          const formData = {
            name: values.brandingName,
            url: form.getFieldValue('brandingWebsite'),
          };

          setLoading(true);
          await projectV2Apis
            .updateProjectBranding(projectId, formData)
            .then(async (res: any) => {
              if (!res.error) {
                showSnackbar({
                  body: 'Whitelabelling information was updated successfully',
                  type: 'success',
                  isModal: false,
                });

                await fetchBrandingData();
              } else {
                console.error(
                  'Error while updating whitelabelling information.',
                  res.error
                );
                showSnackbar({
                  body: 'There was an error while updating whitelabelling information.',
                  type: 'error',
                  isModal: false,
                });
              }
            })
            .catch((err) => {
              showSnackbar({
                body: `${err}`,
                type: 'error',
              });
            })
            .finally(() => {
              setLoading(false);
            });
        });
    };
  }, [form, fetchBrandingData]);

  return {
    form,
    brandingData,
    updateBrandingData,
    loading,
  };
};

export const isValidProjectUpdateRequest = (
  project: ProjectEditProps
): ProjectUpdateValidation => {
  if (project.name !== undefined && project.name.length === 0) {
    return { isValid: false, message: 'Project name cannot be empty' };
  }

  if (
    project.boundaryFileContents !== undefined &&
    project.boundaryFileContents.length === 0
  ) {
    return {
      isValid: false,
      message: 'Project boundary must be specified.',
    };
  }

  if (
    Object.entries(project).length === 0 ||
    Object.entries(project).findIndex((entry) => entry[1] !== undefined) < 0
  ) {
    return {
      isValid: false,
      message: 'There has been no change made to the project.',
    };
  }

  return { isValid: true };
};

export const isWhiteLabelingEnabled = (
  project: Project,
  ownerOptions: Owner[]
): boolean => {
  const owner = ownerOptions.find(
    (o) => o.ownerId === project.ownerId && o.ownerType === project.ownerType
  );

  if (!owner) {
    return false;
  }

  return !!owner.whiteLabelingEnabled;
};

export const isProjectOwner = (
  project: Project,
  ownerOptions: Owner[],
  user: User
): boolean => {
  if (user && user.role === 'aspecscire_staff') {
    return true;
  }

  if (!project || ownerOptions.length === 0) {
    return false;
  }

  return !!ownerOptions.find((o) => o.ownerId === project.ownerId);
};
