import ApiBase from './ApiBase';
import { GenericObjectType, GenericApisReturnTypes } from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';

export default class MissionsAPIs extends ApiBase {
  public createMission(
    projectId: string,
    aoiId: string,
    body: any
  ): Promise<any> {
    return this.post(`/v1/projects/${projectId}/aois/${aoiId}/missions`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public submitMission(
    projectId: string,
    aoiId: string,
    missionId: string,
    body: any
  ): Promise<any> {
    return this.patch(
      `/v1/projects/${projectId}/aois/${aoiId}/missions/${missionId}`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  public getMission(
    projectId: string,
    aoiId: string,
    missionId: string
  ): Promise<any> {
    return this.get(
      `/v1/projects/${projectId}/aois/${aoiId}/missions/${missionId}`
    );
  }

  public updateMission(
    projectId: string,
    aoiId: string,
    missionId: string,
    body: GenericObjectType
  ) {
    return this.patch(
      `/v1/projects/${projectId}/aois/${aoiId}/missions/${missionId}`,
      body
    );
  }

  public deleteMission(projectId: string, aoiId: string, missionId: string) {
    return this.delete(
      `/v1/projects/${projectId}/aois/${aoiId}/missions/${missionId}`
    );
  }

  public async checkMissionStatus(
    projectId: string,
    aoiId: string,
    missionId: string
  ): Promise<GenericApisReturnTypes> {
    return this.get(
      `/v1/projects/${projectId}/aois/${aoiId}/missions/${missionId}/status`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText:
            'There was an unexpected error while checking if the mission can be edited.',
        });
      });
  }

  public getDrones(): Promise<any> {
    return this.get(`/v1/info/drones`);
  }

  public getEPSGcodeById(id: any): Promise<any> {
    return this.get(`/v1/coords/${id}`);
  }

  public searchEPSGcodeByText(searchText: string): Promise<any> {
    return this.get(`/v1/coords?search=${searchText}`);
  }

  public getFlightPlans(
    projectId: string,
    aoiId: string,
    missionId: string
  ): Promise<any> {
    return this.get(
      `/v1/projects/${projectId}/aois/${aoiId}/missions/${missionId}/flightPlans`
    );
  }

  public getGcpPlans(
    projectId: string,
    aoiId: string,
    missionId: string
  ): Promise<any> {
    return this.get(
      `/v1/projects/${projectId}/aois/${aoiId}/missions/${missionId}/gcpPlans`
    );
  }

  public getFlightPlanImages(
    projectId: string,
    aoiId: string,
    missionId: string,
    planId: string
  ): Promise<any> {
    return this.get(
      `/v1/projects/${projectId}/aois/${aoiId}/missions/${missionId}/flightPlans/${planId}/images`
    );
  }

  public getMissionViews(
    projectId: string,
    aoiId: string,
    missionId: string
  ): Promise<any> {
    return this.get(
      `/v1/projects/${projectId}/aois/${aoiId}/missions/${missionId}/views`
    );
  }
}
