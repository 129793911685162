import * as React from 'react';
import { Route } from 'react-router';

import DrawingAPIs from '../../api/drawing';
import ViewAPIs from '../../api/views';
import { DesignDrawing } from '../../api/drawing.types';
import DrawingsListing from './Drawings/DrawingsListing';

import {
  UserSandboxPropsType,
  UserSandboxStateType,
  SandboxEvent,
} from './index.types';
import style from './index.module.scss';
import DrawingManager from './Drawings/DrawingManager';

export default class UserSandbox extends React.PureComponent<
  UserSandboxPropsType,
  UserSandboxStateType
> {
  private drawingAPIs = new DrawingAPIs();

  private viewAPIs = new ViewAPIs();

  constructor(props: UserSandboxPropsType) {
    super(props);

    this.state = {
      drawings: [],
      views: [],
    };
  }

  public componentDidMount() {
    // add logic to fetch cad overlays
    this.fetchDrawings();
    this.fetchViews();
  }

  private fetchDrawings = (): Promise<void> => {
    const { projectId, aoiId, showSnackbar } = this.props;

    return this.drawingAPIs.getDrawingsForAOI(projectId, aoiId).then((res) => {
      const { error, data } = res;

      if (error) {
        console.error('Error fetching drawings!', error);

        if (showSnackbar) {
          showSnackbar({
            type: 'error',
            body: error,
          });
        }

        return;
      }

      return new Promise((resolve) => {
        this.setState(
          {
            drawings: data,
          },
          () => {
            resolve();
          }
        );
      });
    });
  };

  private fetchViews = (): Promise<void> => {
    const { projectId, aoiId, showSnackbar } = this.props;

    return this.viewAPIs
      .getViews(projectId, aoiId)
      .then((res) => {
        const { data } = res;

        return new Promise<void>((resolve) => {
          this.setState(
            {
              views: data,
            },
            () => {
              resolve();
            }
          );
        });
      })
      .catch((error) => {
        console.error('Error fetching views!', error);

        if (showSnackbar) {
          showSnackbar({
            type: 'error',
            body: 'There was an error while fetching the views.',
          });
        }
      });
  };

  private handleEvent = (e: SandboxEvent<any>) => {
    const { drawings } = this.state;

    switch (e.type) {
      case 'drawing_edited': {
        const drawing = e.data as DesignDrawing;
        const filteredDrawings = drawings.filter((d) => d.id !== drawing.id);

        this.setState({ drawings: [...filteredDrawings, drawing] });
        break;
      }

      case 'drawing_created': {
        const drawing = e.data as DesignDrawing;

        this.setState({ drawings: [...drawings, drawing] });
        break;
      }

      default:
        break;
    }
  };

  public renderDrawingRoutes() {
    const { projectId, aoiId, showSnackbar, showCoachMarks, resetCoachMarks } =
      this.props;
    const { drawings, views } = this.state;

    return (
      <>
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/sandbox/drawing/new"
          render={({ history }) => {
            return (
              <>
                <DrawingManager
                  projectId={projectId}
                  aoiId={aoiId}
                  views={views}
                  actionType="new"
                  onEvent={this.handleEvent}
                  showSnackbar={showSnackbar}
                  showCoachMarks={showCoachMarks}
                  resetCoachMarks={resetCoachMarks}
                  history={history}
                />
              </>
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/sandbox/drawing/edit/:drawingId"
          render={({ match, history }) => {
            const { drawingId } = match.params;

            return (
              <>
                <DrawingManager
                  projectId={projectId}
                  aoiId={aoiId}
                  drawingId={drawingId}
                  drawing={drawings.find((d) => d.id === drawingId)}
                  views={views}
                  actionType="edit"
                  onEvent={this.handleEvent}
                  showSnackbar={showSnackbar}
                  showCoachMarks={showCoachMarks}
                  resetCoachMarks={resetCoachMarks}
                  history={history}
                />
              </>
            );
          }}
        />
      </>
    );
  }

  public render() {
    const { projectId, aoiId, history } = this.props;
    const { drawings } = this.state;

    return (
      <>
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/sandbox"
          render={() => {
            return (
              <>
                <div className={style.contentContainer}>
                  <DrawingsListing
                    projectId={projectId}
                    aoiId={aoiId}
                    drawings={drawings}
                    onEvent={this.handleEvent}
                    history={history}
                  />
                </div>
              </>
            );
          }}
        />
        {this.renderDrawingRoutes()}
      </>
    );
  }
}
