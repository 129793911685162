import autobind from 'autobind-decorator';
import classnames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { LockOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Button, Input } from 'antd';
import {
  TERMS_AND_CONDITIONS_URL,
  PRIVACY_POLICY_URL,
} from '../../constants/urls';
import { undefinedOrNull } from '../../utils/functs';
import Logo from '../Logo';
import style from './index.module.scss';
import { LoginPreviewTypes } from './LoginPreview/index.types';
import { BrandingModel, ICheckBranding } from '../../shapes/branding';
import BrandingApi from '../../api/branding';
import { resetBrandingStyles, setBrandingStyles } from './helpers';
import LoadingOverlay from '../LoadingOverlay';
import { WithForm } from '../../shapes/app';

export interface IProps
  extends WithForm,
    Partial<Pick<LoginPreviewTypes, 'themePreview'>> {
  login: (username: string, password: string) => any;
  loading: boolean;
  error?: string;
  authenticated: boolean;
  partnerId?: string;
}

export interface IState {
  brandingData: BrandingModel | null;
  isPageLoading: boolean;
}

class Login extends React.Component<IProps, IState> {
  private brandingApi = new BrandingApi();

  constructor(props: IProps) {
    super(props);

    this.state = {
      brandingData: null,
      isPageLoading: true,
    };
  }

  public componentDidMount(): void {
    this.fetchProjectBranding();
  }

  public componentDidUpdate(): void {
    const { authenticated } = this.props;

    if (authenticated) {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  }

  public componentWillUnmount(): void {
    resetBrandingStyles();
  }

  private fetchProjectBranding = () => {
    const { partnerId } = this.props;

    if (undefinedOrNull(partnerId)) {
      this.brandingApi.checkDefaultBranding().then((res: ICheckBranding) => {
        if (!undefinedOrNull(res)) {
          this.setState(
            {
              brandingData: {
                ...res.data,
              },
            },
            () => {
              const { brandingData } = this.state;

              setBrandingStyles(brandingData);
            }
          );
        } else {
          this.setState({
            brandingData: null,
          });
        }

        this.setState({
          isPageLoading: false,
        });
      });

      return;
    }

    this.brandingApi.checkBranding(partnerId).then((res: ICheckBranding) => {
      if (!undefinedOrNull(res)) {
        this.setState(
          {
            brandingData: {
              ...res.data,
            },
          },
          () => {
            const { brandingData } = this.state;

            setBrandingStyles(brandingData);
          }
        );
      } else {
        this.setState({
          brandingData: null,
        });
      }

      this.setState({
        isPageLoading: false,
      });
    });
  };

  @autobind
  private handleSubmit(e: any): void {
    const { form, login, themePreview } = this.props;

    e.preventDefault();
    form.validateFields((err: any, values: any) => {
      if (!err) {
        if (themePreview) {
          return;
        }

        login(
          !undefinedOrNull(values.username)
            ? values.username.trim().toLowerCase()
            : null,
          values.password
        ).catch((error: any) => {
          console.error(error);
        });
      }
    });
  }

  public render(): React.ReactNode {
    const { loading, form, error, partnerId, authenticated, themePreview } =
      this.props;
    const { isPageLoading } = this.state;

    const { getFieldDecorator } = form;

    return (
      <div data-testid="login-container">
        <Form
          onSubmit={this.handleSubmit}
          className={classnames(style.container, {
            [style.isPreview]: themePreview,
          })}
        >
          <Logo
            size="large"
            className={style.logo}
            partnerId={partnerId}
            authenticated={authenticated}
          />
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Email is required' }],
            })(
              <Input
                prefix={<UserOutlined className={style.emailIcon} />}
                placeholder="Email"
                className={style.loginInputField}
                data-testid="login-username"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Password is required' }],
            })(
              <Input
                prefix={
                  <LockOutlined
                    className={classnames(style.emailIcon, style.iconPadding)}
                  />
                }
                type="password"
                placeholder="Password"
                className={style.loginInputField}
                data-testid="login-password"
              />
            )}
          </Form.Item>
          {error && <label className={style.error}>{error}</label>}
          <Button
            icon={<LoginOutlined />}
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            className={style.loginBtn}
            data-testid="login-submit"
          >
            LOGIN
          </Button>
          <Link to="/reset">
            <label className={style.forgot_password}>Forgot password?</label>
          </Link>
          <div>
            <span className={style.textColor}>
              New user?&nbsp;
              <label className={style.registrationLink}>
                {!undefinedOrNull(partnerId) ? (
                  <Link to={`/register/?partnerId=${partnerId}`}>Register</Link>
                ) : (
                  <Link to="/register/">Register</Link>
                )}
              </label>
            </span>
          </div>
          <div>
            <span className={style.forgot_password}>
              <a
                href={TERMS_AND_CONDITIONS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
            </span>
            <span className={style.textColor}>&nbsp;&nbsp;and&nbsp;&nbsp;</span>
            <span className={style.forgot_password}>
              <a
                target="_blank"
                href={PRIVACY_POLICY_URL}
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
          </div>
        </Form>

        {isPageLoading && (
          <LoadingOverlay overlayClassName={style.pageLoading} />
        )}
      </div>
    );
  }
}

export default Form.create<IProps>()(Login);
