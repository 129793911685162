import {
  ICheckBranding,
  IProjectBranding,
  BrandingModel,
} from '../shapes/branding';
import ApiBase from './ApiBase';
import { undefinedOrNull } from '../utils/functs';
import logoLargeImg from '../components/Logo/images/aspecscire-logo@2x.png';
import logoSmallImg from '../components/Logo/images/logo-small@2x.png';
import { BASE_CAPI_URL } from '../constants/urls';

export const fetchLogo = (
  projectId: string | undefined,
  size: 'small' | 'large' | 'favicon' | undefined,
  orgId?: string | undefined,
  authenticated?: boolean | undefined
): string => {
  let url = '';
  let _projectId = projectId;

  if (undefinedOrNull(projectId)) {
    _projectId = 'default';
  }

  if (!undefinedOrNull(projectId) && projectId !== 'default') {
    return `${BASE_CAPI_URL}/v2/projects/${_projectId}/logo?size=${size}`;
  }

  if (!undefinedOrNull(orgId)) {
    url = `${BASE_CAPI_URL}/v1/branding/organisation/${orgId}/logo?size=${size}`;
  } else if (!undefinedOrNull(authenticated) && authenticated === false) {
    if (size === 'small') {
      url = logoSmallImg;
    } else {
      url = logoLargeImg;
    }
  } else if (_projectId === 'default') {
    if (size === 'small') {
      url = logoSmallImg;
    } else {
      url = logoLargeImg;
    }
  }

  return url;
};

export const fetchHostLogo = (
  size: 'small' | 'large' | 'favicon' | undefined
): string => {
  return `${BASE_CAPI_URL}/v1/branding/logo?size=${size}`;
};

export default class BrandingApi extends ApiBase {
  public constructor() {
    super();

    this.baseUrl = `${BASE_CAPI_URL}`;
  }

  public checkDefaultBranding(): Promise<ICheckBranding> {
    const url = `${this.baseUrl}/v1/branding/`;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res: any) => {
        if (res.status === 204) {
          return {
            isEnabled: true,
            error: null,
            data: {},
          };
        }

        const result = res.json();

        return result.then((_res: any) => {
          switch (res.status) {
            case 200:
              return {
                isEnabled: true,
                error: null,
                data: _res,
              };

            case 204:
              return {
                isEnabled: true,
                error: null,
                data: {},
              };

            case 403:
            default:
              return {
                isEnabled: false,
                error: null,
                data: {},
              };
          }
        });
      })
      .catch((_) => {
        return {
          isEnabled: false,
          error: `Some error occured while fetching the branding details. Try again`,
          data: null,
        };
      });
  }

  public uploadLogo(orgId: string, size: string, formData: any): Promise<any> {
    return fetch(
      `${this.baseUrl}/v1/branding/organisation/${orgId}/logo/${size}`,
      {
        method: 'POST',
        credentials: 'include',
        body: formData,
      }
    )
      .then((res: any) => {
        const result = res.json();

        return result.then((_res: any) => {
          if (res.status === 200) {
            return {
              error: null,
              data: _res,
            };
          }

          return {
            error: _res.message,
            data: null,
          };
        });
      })
      .catch((_) => {
        return {
          error: `Some error occured while uploading the logo. Try again`,
          data: null,
        };
      });
  }

  public checkBranding(orgId: string): Promise<ICheckBranding> {
    const url = `${this.baseUrl}/v1/branding/organisation/${orgId}`;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res: any) => {
        if (res.status === 204) {
          return {
            isEnabled: true,
            error: null,
            data: {},
          };
        }

        const result = res.json();

        return result.then((_res: any) => {
          switch (res.status) {
            case 200:
              return {
                isEnabled: true,
                error: null,
                data: _res,
              };

            case 204:
              return {
                isEnabled: true,
                error: null,
                data: {},
              };

            case 403:
            default:
              return {
                isEnabled: false,
                error: null,
                data: {},
              };
          }
        });
      })
      .catch((_) => {
        return {
          isEnabled: false,
          error: `Some error occured while fetching the branding details. Try again`,
          data: null,
        };
      });
  }

  public updateBranding(
    orgId: string,
    formData: BrandingModel
  ): Promise<BrandingModel> {
    return fetch(`${this.baseUrl}/v1/branding/organisation/${orgId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formData,
        name: !undefinedOrNull(formData.name) ? formData.name.trim() : null,
        url: !undefinedOrNull(formData.url) ? formData.url.trim() : null,
      }),
    })
      .then((res: any) => {
        const result = res.json();

        return result.then((_res: any) => {
          switch (res.status) {
            case 200:
              return {
                error: false,
                data: _res,
              };

            case 403:
            default:
              return {
                error: _res.message,
                data: null,
              };
          }
        });
      })
      .catch((_) => {
        return {
          error: `Some error occured while fetching the branding details. Try again`,
        };
      });
  }

  public getProjectBranding(projectId?: string): Promise<IProjectBranding> {
    return fetch(`${this.baseUrl}/v2/projects/${projectId}/branding`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res: any) => {
        const result = res.json();

        return result.then((_res: any) => {
          switch (res.status) {
            case 200:
              return {
                data: _res,
              };
            case 403:
            case 400:
            default:
              return {
                error: _res.message,
              };
          }
        });
      })
      .catch((_) => {
        return {
          error: `Some error occured while fetching the branding details. Try again`,
        };
      });
  }
}
