// eslint-disable-next-line max-classes-per-file
import Layer from 'ol/layer/Layer';
import OLView from 'ol/View';

import { Step } from 'react-joyride';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import { CreateViewRequest, View } from '../../api/views.types';
import { ElevationDifference } from '../../api/elevationDifference.types';
import { DownloadItem } from '../ViewDataManager/index.types';
import { ControlTypes } from './ViewControls/index.types';
import { SiteObject, SiteObjectClass } from '../../shapes/ml';
import { RouterParamsTypes } from '../../shapes/routerParams';
import { GenericObjectType } from '../../shapes/app';
import { SnackbarReducersStateSnackbarsTypes } from '../../shapes/snackbar';

export interface ViewDisplayPropsType
  extends Pick<RouterParamsTypes, 'history'> {
  viewId: string;
  projectId: string;
  aoiId: string;
  splitViewId?: string;
  controlsWhitelist?: ControlTypes[];
  userRole?: string;
  isStaff?: boolean;
  share?: any;
  onEvent: (e: ViewEvent<any>) => void;
  view?: View;
  splitView?: View;
  compareView?: View;
  viewList?: View[];
  issue?: any;
  downloads?: DownloadItem[];
  reports?: any[];
  siteObjects?: SiteObject[];
  headerMenus?: any[];
  projectMLClasses?: SiteObjectClass[];
  elevationDifferences?: ElevationDifference[];
  // this potreeInsetViewIdOverride prop is used by the view editor in
  // Mission/Publish component, this should be passed along to image view
  potreeInsetViewIdOverride?: string;
  onFooterControlsFragChange?: (frag: any) => void;
  registerViewConfigProvider?: (callback: () => ViewConfig) => void;
  showCoachMarks?: (steps: Step[]) => void;
  resetCoachMarks?: () => void;
  coachMarkOverride?: CoachMarkOverrideMetadata;
  overlayGeojson?: string;
  showSatellite?: boolean;
}

export type MeasurementType =
  | 'distance'
  | 'area'
  | 'volume'
  | 'elevation'
  | 'profile';

export type MeasurementSubType = 'FlatPlane' | 'BestFitPlane';

export interface GlobalCoordinates {
  latitude: number;
  longitude: number;
  elevation: number;
}

export interface PixelCoordinates {
  x: number;
  y: number;
}

export interface HighlightImageDetails {
  guid: string;
  pixel?: PixelCoordinates;
  triangulatedPoint?: GlobalCoordinates;
}

export interface ViewDisplayStateType {
  controlSelection: ControlSelection;
  issue?: any;
  share?: any;
  measurement?: any;
  compareView?: any;
  splitView?: any;
  renderConfigCallback?: () => ViewConfig;
  rendererState?: RendererState;
  highlightImageList?: HighlightImageDetails[];
  olView?: OLView; // used to keep track of context b/w MapView and SplitView
}

export type ViewEventType =
  | 'control_box_open' // used to track control box open
  | 'control_box_close' // used to track control box close
  | 'measurement_changed' // used to track changed in measurement type selected
  | 'renderer_state_changed' // used to track and control the different layers rendered
  | 'map_config_changed' // used to keep split view orientations in check
  | 'comapre_status_changed' // used to track compare control status
  | 'site_objects_changed' // used when site_objects are added / deleted
  | 'highlist_image_list_changed' // used when object type / subType has changed
  | 'create_view' // used when a new view is created
  | 'image_selection_changed' // used when current image changes in image list or pano view
  | 'issue_created' // used to signal new issue created
  | 'ol_view_init' // used when openlayers renderer view is init'd
  | 'show_snackbar' // used to display a snackbar
  | 'layer_select' // (MapMaker) select a layer to edit
  | 'layer_clear' // (MapMaker) clear layer selection
  | 'layer_edit'; // (MapMaker) save edits made to a layer

export class ViewEvent<T> {
  type: ViewEventType;

  data: T;
}

export interface ControlBoxStatus {
  itemType: ControlTypes;
}

export class ControlBoxOpenEvent extends ViewEvent<ControlBoxStatus> {
  constructor(state: ControlBoxStatus) {
    super();

    this.type = 'control_box_open';
    this.data = state;
  }
}

export class ControlBoxCloseEvent extends ViewEvent<ControlBoxStatus> {
  constructor(state: ControlBoxStatus) {
    super();

    this.type = 'control_box_close';
    this.data = state;
  }
}

export interface MeasurementStatus {
  type?: MeasurementType;
  subType?: MeasurementSubType;
  volumePlaneElevation?: number;
  geoJson?: any;
}

export class MeasurementChanged extends ViewEvent<MeasurementStatus> {
  constructor(data: MeasurementStatus) {
    super();

    this.type = 'measurement_changed';
    this.data = data;
  }
}

export interface CompareStatus {
  comapreViewId?: string;
  opacity?: number;
}

export class CompareStatusChanged extends ViewEvent<CompareStatus> {
  constructor(data: CompareStatus) {
    super();

    this.type = 'comapre_status_changed';
    this.data = data;
  }
}

export class RendererStateChange extends ViewEvent<RendererState> {
  constructor(data: RendererState) {
    super();

    this.type = 'renderer_state_changed';
    this.data = data;
  }
}

export class OLViewInitialized extends ViewEvent<OLView> {
  constructor(data: OLView) {
    super();

    this.type = 'ol_view_init';
    this.data = data;
  }
}

export class MapConfigChanged extends ViewEvent<ViewConfig> {
  constructor(data: ViewConfig) {
    super();

    this.type = 'map_config_changed';
    this.data = data;
  }
}

export class SiteObjectsChanged extends ViewEvent<SiteObject> {
  constructor(data: SiteObject) {
    super();

    this.type = 'site_objects_changed';
    this.data = data;
  }
}

export interface HighlightImageDetails {
  guid: string;
  triangulatedPoint?: GlobalCoordinates;
  pixel?: PixelCoordinates;
}

export class HighlightImageListChanged extends ViewEvent<
  HighlightImageDetails[] | undefined
> {
  constructor(data: HighlightImageDetails[] | undefined) {
    super();

    this.type = 'highlist_image_list_changed';
    this.data = data;
  }
}

export interface ImageSelectionStatus {
  previousImage?: string;
  currentImage?: string;
}

export class ImageSelectionChanged extends ViewEvent<ImageSelectionStatus> {
  constructor(data: ImageSelectionStatus) {
    super();

    this.type = 'image_selection_changed';
    this.data = data;
  }
}

export class IssueCreated extends ViewEvent<any> {
  constructor(data: any) {
    super();

    this.type = 'issue_created';
    this.data = data;
  }
}

export class CreateViewEvent extends ViewEvent<CreateViewRequest> {
  constructor(data: CreateViewRequest) {
    super();

    this.type = 'create_view';
    this.data = data;
  }
}

export class ShowSnackbarEvent extends ViewEvent<SnackbarReducersStateSnackbarsTypes> {
  constructor(data: SnackbarReducersStateSnackbarsTypes) {
    super();

    this.type = 'show_snackbar';
    this.data = data;
  }
}

export class SelectLayerEvent extends ViewEvent<LayerDescriptor> {
  constructor(data: LayerDescriptor) {
    super();

    this.type = 'layer_select';
    this.data = data;
  }
}

export class ClearLayerEvent extends ViewEvent<undefined> {
  constructor() {
    super();

    this.type = 'layer_clear';
    this.data = undefined;
  }
}

export interface UpdateLayerData {
  layer: LayerDescriptor;
  geojsonContents: string;
}

export class UpdateLayerEvent extends ViewEvent<UpdateLayerData> {
  constructor(data: UpdateLayerData) {
    super();

    this.type = 'layer_edit';
    this.data = data;
  }
}

export interface ControlSelection {
  activeControlIds: string[];
  measurement?: {
    type?: MeasurementType;
    subType?: MeasurementSubType;
    geoJson?: any;
    renderChildrenFrag?: any;
  };
  info?: {
    renderChildrenFrag?: any;
  };

  site_objects?: {
    renderChildrenFrag?: any;
  };
}

export interface BaseRendererProps {
  view: View;
  // config from issue or share
  viewConfig?: ViewConfig;
  // share has a lot of info like descriptors, boundary, project etc.
  // the whole thing is passed to renderers so that they can connfigure
  // things like innsets etc.
  share?: any;
  // renderer registers with view after render complete
  onConfigCallbackChange: (cb: () => ViewConfig) => void;
  // renderer updates renderer-specific footer controls
  onFooterControlsFragChange?: (frag: any) => void;
  onEvent: (e: ViewEvent<any>) => void;
  // children <- implicit prop
}

export interface ViewConfig {
  selection?: string;
  zoom?: number;
  latitude?: number;
  longitude?: number;
  rotation?: number;
  measurementType?: string;
  volumePlaneElevation?: number;
  compareViewId?: string;
  hiddenImagesGuidList?: string[];
  id?: string;
}

export interface RendererState {
  layers?: RendererLayer[]; // layers that comprise the view being displayed. Used for layer toggle / opacity control
  currentImage?: {
    guid?: string;
    imageWidth?: number;
    imageHeight?: number;
  }; // guid of image being displayed currently, in image views
  descriptor?: VimanaViewDescriptor;
}

export interface RendererLayer {
  id: string;
  visible: () => boolean;
  label: string;
  icon?: string;
  _layer?: Layer;
  _data?: GenericObjectType;
  setVisible: (visible: boolean) => void;
}

export interface VimanaViewDescriptor {
  loader: string;
  widgets: VimanaWidget[];
  layers: VimanaLayer[];
}

export interface VimanaWidget {
  id: string;
  type: VimanaWidgetType;
  config?: any;
}

export interface VimanaLayer {
  id: string;
  type: VimanaLayerType;
  descriptor?: any;
}

export type VimanaWidgetType =
  | 'elevation_range_slider'
  | 'elevation_color_scale';

export type VimanaLayerType = 'tiles';

export interface CoachMarkOverrideMetadata {
  url?: string;
  coachmarkSteps: Step[];
  timeoutDuration?: number;
}
