const initialState = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
  elevation: null,
  elevationList: [],
  volumeType: 'FlatPlane',
};

export default function VIEWS(state = initialState, action: any): any {
  const { type, payload } = action;

  switch (type) {
    case 'PLANE_ELEVATION_UPDATE':
      return {
        ...state,
        elevation: payload,
      };

    case 'PLANE_ELEVATION_RESET':
      return {
        ...state,
        elevation: null,
      };

    case 'VOLUME_VALUE_RESET':
      return {
        ...initialState,
        volumeType: state.volumeType,
        elevation: state.elevation,
        elevationList: state.elevationList,
      };

    case 'VOLUME_FETCH_STARTED':
    case 'ELEVATION_FETCH_STARTED':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'VOLUME_FETCH_FAILED':
    case 'ELEVATION_FETCH_FAILED':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: payload,
      };

    case 'ELEVATION_FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          elevation: payload.elevation.metres,
        },
      };

    case 'VOLUME_FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          volume: payload.volume.cubic_metres,
        },
      };

    case 'SET_VOLUME_VIEW_ID':
      return {
        ...state,
        viewId: payload,
      };

    case 'SET_VOLUME_ELEVATION_LIST':
      return {
        ...state,
        elevationList: payload.elevation.metres,
      };

    case 'SET_VOLUME_VIEW_UNITS':
      return {
        ...state,
        viewUnits: payload,
      };

    case 'SET_VOLUME_TYPE':
      return {
        ...state,
        volumeType: payload,
      };

    case 'VOLUME_TYPE_RESET':
      return {
        ...state,
        volumeType: 'FlatPlane',
      };

    case `CLEAR_VOLUME_VIEW_DATA`:
      return {
        ...state,
        viewId: null,
        viewUnits: null,
        elevationList: [],
      };

    default:
      return state;
  }
}
