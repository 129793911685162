import * as React from 'react';
import { Tooltip, Popover } from 'antd';
import styles from './HelpTooltips.module.scss';
import { isString, undefinedOrNull } from '../../utils/functs';

interface IProps {
  tooltipText?: string | null;
  helpText: string | React.ReactNode;
  title: string;
  position: string;
}

class HelpTooltip extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    title: 'Information',
    position: 'right',
  };

  public render(): React.ReactNode {
    const { helpText, position, tooltipText, title } = this.props;

    if (isString(helpText)) {
      return (
        <span className={styles.helptoolTip}>
          {/* TODO: Fix types and remove 'any' */}
          <Tooltip placement={position as any} title={helpText}>
            {!undefinedOrNull(tooltipText) ? (
              <span className={styles.tooltipTextWrapper}>{tooltipText}</span>
            ) : (
              <span className={styles.tooltipWrapper}>
                <i className="fa fa-question-circle" aria-hidden="true" />
              </span>
            )}
          </Tooltip>
        </span>
      );
    }

    return (
      <span className={styles.helptoolTip}>
        <Popover
          // TODO: Fix types and remove 'any'
          placement={position as any}
          content={<div className={styles.popoverWrapper}>{helpText}</div>}
          title={title}
        >
          {!undefinedOrNull(tooltipText) ? (
            <span className={styles.tooltipTextWrapper}>{tooltipText}</span>
          ) : (
            <span className={styles.tooltipWrapper}>
              <i className="fa fa-question-circle" aria-hidden="true" />
            </span>
          )}
        </Popover>
      </span>
    );
  }
}

export default HelpTooltip;
