/* eslint-disable func-names */

import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import * as mapboxgl from 'mapbox-gl';
import { clearAllPopups } from './Popup';

const ViewSiteObjects: any = {};

const POPUP = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
  className: 'noevent',
});

ViewSiteObjects.onSetup = function (opts: any) {
  const features = (opts && opts.featureIds) || [];

  clearAllPopups();

  this.setSelected(
    features.filter((id: any) => {
      return this.getFeature(id) !== undefined;
    })
  );
  const showMetric = (opts && opts.showMetric) || false;

  (window as any).StaticMode = this;

  return {
    showMetric,
  };
};

ViewSiteObjects.onStop = () => {
  POPUP.remove();
};

ViewSiteObjects.onMouseMove = function (state: any, event: any) {
  if (!state.showMetric) {
    return;
  }

  const { featureTarget } = event;

  if (featureTarget) {
    const { properties } = featureTarget;

    this.updateUIClasses({
      mouse: Constants.cursors.POINTER,
    });
    POPUP.setHTML(
      `${properties.lengthInMeter}m (${properties.lengthInFeet}ft)`
    ).setLngLat(event.lngLat);
    if (!POPUP.isOpen()) {
      POPUP.addTo(this.map);
    }
  } else {
    this.updateUIClasses({
      mouse: Constants.cursors.NONE,
    });
    if (POPUP.isOpen()) {
      POPUP.remove();
    }
  }
};

ViewSiteObjects.toDisplayFeatures = function (
  state: any,
  geojson: any,
  display: any
) {
  display(geojson);
};

export default ViewSiteObjects;
