import * as React from 'react';
import {
  conertGeoJsonToThreeJs,
  convertDxfToThreeJs,
} from '../../../utils/VectorShapesToThreeJs';

declare let THREE: any;

interface IProps {
  viewer?: any;
  vectorFile?: File;
  style?: any;
}

interface IState {
  scene: any;
  shapeContainer?: any;
}

export class PotreeVectorPreview extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const scene = new THREE.Scene();
    const light = new THREE.PointLight(0xffffff, 1.0);

    scene.name = 'scene_overlay_vectors';
    scene.add(light);

    this.state = {
      scene,
      // shapeContainer: new THREE.Object3D()
    };
  }

  public componentDidMount() {
    const { viewer, vectorFile, style } = this.props;

    if (viewer && vectorFile) {
      this.insertShapes(viewer, vectorFile, style || {});
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { viewer } = this.props;

    if (viewer) {
      viewer.removeEventListener('render.pass.end', this.renderListener);
    }

    this.clearShapes();

    const { viewer: nextViewer, vectorFile, style } = nextProps;

    if (nextViewer && vectorFile) {
      this.insertShapes(nextViewer, vectorFile, style || {});
    }
  }

  public componentWillUnmount() {
    this.clearShapes();
  }

  private clearShapes() {
    const { shapeContainer, scene } = this.state;

    if (shapeContainer) {
      // eslint-disable-next-line no-restricted-syntax
      for (const s of shapeContainer.children || []) {
        shapeContainer.remove(s);
      }

      scene.remove(shapeContainer);
    }

    const { viewer } = this.props;

    if (viewer) {
      viewer.removeEventListener('render.pass.end', this.renderListener);
    }
  }

  private renderListener = () => {
    const { viewer } = this.props;
    const { scene } = this.state;

    if (viewer && scene) {
      viewer.renderer.render(scene, viewer.scene.getActiveCamera());
    }
  };

  private insertShapes(viewer: any, file: File, style: any) {
    if (!viewer || !file) return;
    this.clearShapes();

    const { scene } = this.state;

    const name = (file.name || '').toLowerCase();

    if (name.endsWith('.json') || name.endsWith('.geojson')) {
      this.readFile(file, 'text', (text: any) => {
        const json = JSON.parse(text);
        const shapeContainer = conertGeoJsonToThreeJs(json, style || {});

        scene.add(shapeContainer);
        viewer.addEventListener('render.pass.end', this.renderListener);
        this.setState({ shapeContainer });
      });
    } else if (name.endsWith('.dxf')) {
      this.readFile(file, 'text', (text: any) => {
        const shapeContainer = convertDxfToThreeJs(text, style || {});

        scene.add(shapeContainer);
        viewer.addEventListener('render.pass.end', this.renderListener);
        this.setState({ shapeContainer });
      });
    }
  }

  private readFile(
    file: File,
    type: 'arraybuffer' | 'text',
    cb: (contents: any) => void
  ) {
    const reader = new FileReader();

    if (type === 'arraybuffer') {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsText(file);
    }

    reader.onload = () => {
      if (reader.result) {
        cb(reader.result);
      }
    };
  }

  public render() {
    return <></>;
  }
}
