import moment from 'moment';
import * as React from 'react';
import { sortByName } from '../../../../utils/helpers';
import * as ControlBox from '../../../ControlBox/ControlBox';
import Dropdown from '../../../DropDown/DropDown';
import Slider from '../../../Slider';
import OverlayControl from '../../MapView/OpenLayersRenderer/OverlayControl';
import { CompareControlProps } from '../index.types';
import style from './index.module.scss';
import { CompareStatusChanged } from '../../index.types';

interface IState {
  opacity: number;
  overlayViewId: string;
}

export default class CompareControlBox extends React.Component<
  CompareControlProps,
  IState
> {
  constructor(props: CompareControlProps) {
    super(props);

    this.state = {
      opacity: 50,
      overlayViewId: '',
    };
  }

  public componentDidMount() {
    const { compareStatus } = this.props;

    this.setState(
      {
        opacity: compareStatus?.opacity || 0,
        overlayViewId: compareStatus?.comapreViewId || '',
      },
      () => {
        this.updateRenderFrag();
      }
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: CompareControlProps) {
    const { compareStatus: nextStatus } = nextProps;
    const { compareStatus: status } = this.props;

    if (
      status?.comapreViewId !== nextStatus?.comapreViewId ||
      status?.opacity !== nextStatus?.opacity
    ) {
      this.setState(
        {
          overlayViewId: nextStatus?.comapreViewId || '',
          opacity: nextStatus?.opacity || 0,
        },
        () => {
          this.updateRenderFrag();
        }
      );
    }
  }

  public componentWillUnmount() {
    const { onRenderFragChange } = this.props;

    onRenderFragChange(undefined);
  }

  private setOpacity = (opacity: number) => {
    this.setState({ opacity }, () => {
      this.updateRenderFrag();
      this.sendStatusEvent();
    });
  };

  private setOverlayViewId = (overlayViewId: string) => {
    const { overlayViewId: prevOverlayId, opacity: prevOpacity } = this.state;
    let nextOpacity = prevOpacity;

    if (!prevOverlayId) {
      nextOpacity = 50;
    }

    this.setState({ overlayViewId, opacity: nextOpacity }, () => {
      this.updateRenderFrag();
      this.sendStatusEvent();
    });
  };

  private updateRenderFrag = () => {
    const { onRenderFragChange, designViews } = this.props;
    const { opacity, overlayViewId } = this.state;
    const view = (designViews || []).find((v) => v.id === overlayViewId);

    if (view) {
      onRenderFragChange(() => {
        return <OverlayControl view={view} opacity={opacity} />;
      });
    } else {
      onRenderFragChange(undefined);
    }
  };

  private sendStatusEvent() {
    const { onEvent } = this.props;
    const { opacity, overlayViewId } = this.state;

    onEvent(
      new CompareStatusChanged({
        comapreViewId: overlayViewId || undefined,
        opacity,
      })
    );
  }

  private renderHeader = () => {
    const { onClose } = this.props;

    return (
      <React.Fragment>
        <ControlBox.Title title="Compare" />
        <ControlBox.Icon
          onClick={() => {
            onClose();
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  public render() {
    const { designViews: views } = this.props;
    const options = views.map((view) => ({
      value: view.id,
      label: `${
        view.name ? `${view.name}` : `${moment(view.date).format('ll')}`
      }`,
    }));
    const allOptions = [
      { value: '', label: 'Select' },
      ...sortByName(options, 'label'),
    ];
    const { opacity, overlayViewId } = this.state;

    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={this.renderHeader}
      >
        <div className={style.item}>
          <label>CAD Version </label>
          <Dropdown
            value={overlayViewId}
            onChange={(item: { value: string; label: string }) => {
              this.setOverlayViewId(item.value);
            }}
            options={allOptions}
            placeholder="Select an option"
          />
        </div>
        <div className={style.item}>
          <Slider
            disabled={!overlayViewId}
            defaultValue={opacity}
            onChange={this.setOpacity}
            min={0}
            max={100}
          />
        </div>
      </ControlBox.Wrapper>
    );
  }
}
