import { toPng } from 'html-to-image';
import { View } from 'ol';
import { toLonLat } from 'ol/proj';
import * as React from 'react';
import { ViewConfig } from '../../../index.types';

interface IProps {
  olMap?: any;
  convertToLatLon: boolean;
}

export class MapStateProvider extends React.Component<IProps> {
  public getMapState = (): ViewConfig => {
    const { olMap, convertToLatLon } = this.props;

    if (olMap) {
      const view: View = olMap.getView();

      if (view) {
        const center = view.getCenter();

        if (center) {
          const projectedCenter = convertToLatLon ? toLonLat(center) : center;

          return {
            zoom: view.getZoom(),
            longitude: projectedCenter ? projectedCenter[0] : undefined,
            latitude: projectedCenter ? projectedCenter[1] : undefined,
            rotation: view.getRotation(),
          };
        }
      }
    }

    return {};
  };

  public getScreenshot = (): Promise<string | null> => {
    const { olMap } = this.props;

    if (olMap) {
      const exportOptions = {
        filter: (element: any) => {
          const className = element?.className || '';

          if (className.indexOf('ol-control') !== -1) {
            return false;
          }

          if (className.indexOf('no-include-screenshot') !== -1) {
            return false;
          }

          return true;
        },
      };

      return new Promise((resolve) => {
        olMap.once('rendercomplete', () => {
          toPng(olMap.getTargetElement(), exportOptions).then((dataURL) => {
            resolve(dataURL);
          });
        });
        olMap.renderSync();
      });
    }

    return Promise.resolve(null);
  };

  public saveScreenshot = () => {
    this.getScreenshot().then((ss: any) => {
      if (ss) {
        const link = document.createElement('a');

        link.download = 'screenshot.png';
        link.href = ss;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  // this is the way mapbox example shows how to export, keeping it here for reference temporarily
  // public getScreenshot = (): Promise<string | null> => {
  //   const { ol } = this.props;

  //   if (ol) {
  //     return new Promise(resolve => {
  //       /* eslint-disable */
  //       ol.once('rendercomplete', () => {
  //         const map = ol;
  //         const mapCanvas = document.createElement('canvas');
  //         const size = map.getSize();

  //         mapCanvas.width = size[0];
  //         mapCanvas.height = size[1];

  //         const mapContext: any = mapCanvas.getContext('2d');

  //         Array.prototype.forEach.call(
  //           document.querySelectorAll('.ol-layer canvas'),

  //           (canvas: any) => {
  //             if (canvas.width > 0) {
  //               const opacity = canvas.parentNode.style.opacity;

  //               mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
  //               const transform = canvas.style.transform;
  //               // Get the transform parameters from the style's transform matrix
  //               const matrix = transform
  //                 .match(/^matrix\(([^\(]*)\)$/)[1]
  //                 .split(',')
  //                 .map(Number);

  //               // Apply the transform to the export map context
  //               CanvasRenderingContext2D.prototype.setTransform.apply(
  //                 mapContext,
  //                 matrix
  //               );
  //               mapContext.drawImage(canvas, 0, 0);
  //             }
  //           }
  //         );

  //         try {
  //           const dataUrl = mapCanvas.toDataURL();

  //           resolve(dataUrl);
  //         } catch (e) {
  //           console.error(e);
  //           resolve(null);
  //         }
  //       });
  //       ol.renderSync();
  //       /* eslint-enable */
  //     });
  //   }

  //   return Promise.resolve(null);
  // };

  public render() {
    return <></>;
  }
}
