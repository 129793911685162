import React, { ChangeEvent, useMemo, useState } from 'react';
import { Select, Collapse, Modal, Typography } from 'antd';
import { useHistory } from 'react-router';
import DocumentationLink from '../DocumentationLink';
import { BASE_CAPI_URL, DOCUMENTATION_URL_LIST } from '../../constants/urls';

import { Button } from '../Button';
import {
  AdvancedDetailsProps,
  BoundaryDetailsProps,
  ConfirmProjectCreateModalProps,
  CreateProjectBodyProps,
  CreateProjectV2Props,
  EPSGSelectorProps,
  PreviewDetailRowProps,
  ProjectDetailsProps,
  ProjectUpdatePreviewProps,
} from './index.types';
import BoundaryEditor from '../CreateAOI/BoundaryEditor';

import styles from './index.module.scss';
import {
  getOwnerName,
  isValidProjectCreateRequest,
  toProjectBoundaryHeaderWithArea,
  useEPSGSelection,
  useProjectCreationContext,
  useProjectStateManagement,
} from './utils';
import { convertArea } from '../../utils/helpers';
import { Owner } from '../../api/organisations.types';

const { Title } = Typography;

export const CreateProjectV2: React.FC<CreateProjectV2Props> = ({
  user,
  showSnackbar,
  insertProjectUserRole,
}) => {
  return (
    <div className={styles.container} data-testid="create-project-container">
      <div className={styles.content}>
        <CreateProjectTitle />
        <CreateProjectBody
          user={user}
          showSnackbar={showSnackbar}
          insertProjectUserRole={insertProjectUserRole}
        />
      </div>
    </div>
  );
};

export const CreateProjectTitle: React.FC = () => {
  return (
    <div
      className={styles.titleContainer}
      data-testid="create-project-title-container"
    >
      <Title level={3} className={styles.titleHeading}>
        Create New Project
      </Title>
      <DocumentationLink
        href={DOCUMENTATION_URL_LIST.createProject}
        toolTipPosition="right"
        className={styles.titleDocumentation}
      />
    </div>
  );
};

export const CreateProjectBody: React.FC<CreateProjectBodyProps> = ({
  user,
  project,
  showSnackbar,
  insertProjectUserRole,
}) => {
  const { defaultOwner, ownerOptions, projectState, actions } =
    useProjectStateManagement(user, project);
  const [confirmProjectCreate, setConfirmProjectCreate] =
    useState<boolean>(false);
  const history = useHistory();

  return (
    <div
      className={styles.bodyContainer}
      data-testid="create-project-body-container"
    >
      <ProjectDetails
        name={project?.name}
        ownerOptions={ownerOptions}
        owner={defaultOwner}
        events={{
          onOwnerChange: actions.ownerChanged,
          onNameChange: actions.nameChanged,
        }}
      />
      <BoundaryEditorCollapsible
        events={{
          onBoundaryChange: actions.boundaryChanged,
          onLocationChange: actions.locationChanged,
          onAreaChange: actions.areaChanged,
        }}
      />
      <AdvancedDetailsCollapsible
        address={project?.address}
        temperatureUnits={project?.temperatureUnit}
        epsgCode={project?.epsgCode}
        description={project?.description}
        events={{
          onAddressChange: actions.addressChanged,
          onDescriptionChange: actions.descriptionChanged,
          onEpsgCodeChange: actions.epsgCodeChanged,
          onTemperatureUnitChange: actions.temperatureUnitChanged,
        }}
      />
      <div className={styles.buttonContainer}>
        <Button
          type="secondary"
          onClick={() => {
            history.push('/');
          }}
        >
          Cancel
        </Button>
        <Button
          helpText="create project"
          toolTipPosition="top"
          onClick={() => {
            const validation = isValidProjectCreateRequest(projectState);

            if (validation.isValid) setConfirmProjectCreate(true);
            else if (validation.message) {
              showSnackbar({
                type: 'error',
                body: validation.message,
                isModal: false,
              });
            }
          }}
        >
          Create Project
        </Button>
      </div>
      <ConfirmProjectCreationModal
        projectDetails={projectState}
        visible={confirmProjectCreate}
        projectCreated={(projectId) => {
          insertProjectUserRole(projectId, 'project_admin');
          setConfirmProjectCreate(false);
          showSnackbar({
            type: 'success',
            body: 'Your project has been created!',
            isModal: false,
          });
          history.push(`/project/${projectId}`);
        }}
        closeModal={() => setConfirmProjectCreate(false)}
      />
    </div>
  );
};

export const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  name,
  ownerOptions,
  owner,
  events,
  hideOwnerEdit,
}: ProjectDetailsProps) => {
  const handleOwnerChange = useMemo(() => {
    return (value: string, ownerOptions?: Owner[]) => {
      const ownerType = value.split('_')[0];
      const ownerId = value.split('_')[1];

      const newOwner = ownerOptions?.find(
        (o) => o.ownerId === ownerId && o.ownerType === ownerType
      );

      if (newOwner && events?.onOwnerChange) {
        events.onOwnerChange(newOwner);
      }
    };
  }, [events?.onOwnerChange]);

  const handleNameChange = useMemo(() => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      if (events?.onNameChange) {
        events.onNameChange(e.target.value);
      }
    };
  }, [events?.onNameChange]);

  return (
    <div data-testid="project-details-container">
      {hideOwnerEdit ? (
        <></>
      ) : (
        <div className={styles.inputWrapper}>
          <label>Owner</label>
          <Select
            data-testid="project-details-owner-select"
            placeholder="Select Project Owner"
            defaultValue={
              owner ? `${owner.ownerType}_${owner.ownerId}` : undefined
            }
            key={owner ? `${owner.ownerType}_${owner.ownerId}` : undefined} // needed to ensure component re-renders on defaultOwner change
            onChange={(value: string) => handleOwnerChange(value, ownerOptions)}
          >
            {ownerOptions?.map((o) => {
              const ownerKey = `${o.ownerType}_${o.ownerId}`;

              return (
                <Select.Option value={ownerKey} key={ownerKey}>
                  {getOwnerName(o)}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      )}
      <div className={styles.inputWrapper}>
        <label>Name</label>
        <input
          data-testid="project-details-name-input"
          placeholder="Enter the name of the Project"
          defaultValue={name}
          type="text"
          onChange={handleNameChange}
        />
      </div>
    </div>
  );
};

export const BoundaryEditorCollapsible: React.FC<BoundaryDetailsProps> = ({
  defaultOpen,
  events,
  projectId,
  mode,
  project,
  projectEditState,
}) => {
  const areaSquareMetres =
    projectEditState?.areaSquareMetres || project?.areaSquareMetres;
  const defaultAoi = project?.aois.find((a) => a.defaultAOI);

  return (
    <div
      className={styles.collapseBody}
      data-testid="boundary-editor-collapsible-container"
    >
      <Collapse
        defaultActiveKey={defaultOpen ? 0 : undefined}
        className={styles.collapseContainer}
        style={{ marginTop: '15px' }}
      >
        <Collapse.Panel
          key={0}
          header={toProjectBoundaryHeaderWithArea(areaSquareMetres)}
        >
          {projectId !== undefined && defaultAoi?.boundaryId ? (
            <div className={styles.downloadBoundaryContainer}>
              <Button
                type="secondary"
                text="Download Boundary"
                href={`${BASE_CAPI_URL}/v2/projects/${projectId}/boundary?download=true`}
                onClick={() => {}}
              />
            </div>
          ) : (
            <></>
          )}
          <BoundaryEditor
            projectId={projectId}
            onBoundaryChange={events?.onBoundaryChange}
            onLocationChange={events?.onLocationChange}
            computePolygonArea={events?.onAreaChange}
            createProject={mode !== 'edit_project'}
            project={project}
            currentParent="CreateProject"
          />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export const AdvancedDetailsCollapsible: React.FC<AdvancedDetailsProps> = ({
  events,
  epsgCode,
  address,
  description,
  temperatureUnits,
}) => {
  return (
    <div
      className={styles.collapseBody}
      data-testid="advanced-details-collapsible-container"
    >
      <Collapse className={styles.collapseContainer}>
        <Collapse.Panel key={0} header="Advanced Details">
          <div className={styles.inputWrapper}>
            <label>Address</label>
            <input
              onChange={(e) => {
                if (events?.onAddressChange)
                  events.onAddressChange(e.target.value);
              }}
              data-testid="advanced-details-address-input"
              placeholder="Enter the Address of the Project"
              type="text"
              defaultValue={address}
            />
          </div>
          <div className={styles.inputWrapper}>
            <label>Description</label>
            <input
              onChange={(e) => {
                if (events?.onDescriptionChange)
                  events.onDescriptionChange(e.target.value);
              }}
              data-testid="advanced-details-description-input"
              placeholder="Describe the Project briefly"
              type="text"
              defaultValue={description}
            />
          </div>
          <EPSGSelector
            events={{ onEpsgCodeChange: events?.onEpsgCodeChange }}
            epsgCode={epsgCode}
          />
          <div className={styles.inputWrapper}>
            <label>Temperature Units</label>
            <Select
              placeholder="Select units to use for temperature measurement"
              defaultValue={temperatureUnits}
              onChange={events?.onTemperatureUnitChange}
            >
              <Select.Option value="CELSIUS" key="CELSIUS">
                CELSIUS
              </Select.Option>
              <Select.Option value="FAHRENHEIT" key="FAHRENHEIT">
                FAHRENHEIT
              </Select.Option>
            </Select>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export const EPSGSelector: React.FC<EPSGSelectorProps> = ({
  epsgCode,
  events,
}: EPSGSelectorProps) => {
  const { setSearchEPSG, searchResults } = useEPSGSelection(epsgCode);

  return (
    <div className={styles.inputWrapper}>
      <label>EPSG Code:</label>
      <Select
        showSearch
        placeholder="Search by datum or coordinate system (Enter at least 3 characters)"
        defaultValue={epsgCode}
        onSearch={setSearchEPSG}
        filterOption={false}
        onChange={(code) => {
          if (events?.onEpsgCodeChange) events.onEpsgCodeChange(code);
        }}
      >
        {searchResults.length > 0 ? (
          searchResults.map((res) => {
            return (
              <Select.Option value={res.code} key={res.code}>
                <b>EPSG:{res.code}</b> {res.name}
              </Select.Option>
            );
          })
        ) : (
          <></>
        )}
      </Select>
    </div>
  );
};

export const ConfirmProjectCreationModal: React.FC<
  ConfirmProjectCreateModalProps
> = ({ visible, projectDetails, projectCreated, closeModal }) => {
  const { loading, createProject } = useProjectCreationContext();

  if (!visible) {
    return <div data-testid="confirm-project-creation-modal-container" />;
  }

  return (
    <div data-testid="confirm-project-creation-modal-container">
      <Modal
        title="Confirm Project Creation"
        visible={visible}
        footer={null}
        onCancel={closeModal}
      >
        <div className={styles.previewContainer}>
          Here are the details of the Project you have asked to create:{' '}
          <ProjectUpdatePreviewTable project={projectDetails} />
        </div>
        <div className={styles.buttonContainer}>
          <Button type="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={async () => {
              const projectId = await createProject(projectDetails);

              if (projectId && projectCreated) projectCreated(projectId);
            }}
          >
            Create Project
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export const ProjectUpdatePreviewTable: React.FC<ProjectUpdatePreviewProps> = ({
  project,
}) => {
  return (
    <table>
      <PreviewDetailRow
        label="Owner"
        content={
          project?.ownerName && project?.ownerType && project?.ownerId
            ? getOwnerName({
                ownerType: project.ownerType,
                name: project.ownerName,
                ownerId: project.ownerId,
              })
            : undefined
        }
      />
      <PreviewDetailRow label="Name" content={project?.name} />
      <PreviewDetailRow label="Address" content={project?.address} />
      <PreviewDetailRow label="Description" content={project?.description} />
      <PreviewDetailRow
        label="Project Area"
        content={
          project?.areaSquareMetres !== undefined ? (
            <>
              {convertArea(project.areaSquareMetres)} (
              {convertArea(project.areaSquareMetres, 'acre')})
            </>
          ) : undefined
        }
      />
      <PreviewDetailRow
        label="EPSG Code"
        content={project?.epsgCode && `EPSG:${project.epsgCode}`}
      />
      <PreviewDetailRow
        label="Temperature Units"
        content={project?.temperatureUnit}
      />
    </table>
  );
};

export const PreviewDetailRow: React.FC<PreviewDetailRowProps> = ({
  label,
  content,
  separator,
}) => {
  if (!content) return <></>;

  return (
    <tr className={styles.rowContainer}>
      <td className={styles.label}>{label}</td>
      <td className={styles.colon}>{separator || ':'}</td>
      <td className={styles.content}>{content}</td>
    </tr>
  );
};
