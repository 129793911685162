import * as React from 'react';
import { Link } from 'react-router-dom';
import ModalNotification from '../../ModalNotification/ModalNotification';
import RenderGCP from '../GCPPlanGenerator/RenderGCP';
import styles from './SurveyPlanning.module.scss';
import SkeletonLoader from '../../SkeletonLoader';
import { GcpPlanData } from '../../../api/mission.types';

interface IProps {
  isGroundControlPoints: boolean;
  aoiId: string;
  projectId: string;
  missionId: string;
  missionStatus: string;
  unit?: string;
  epsgCode?: string;
  epsg: any;
  mission?: any;
  epsgCodeList: any[];
  plans: any[];
  gcpPlanData?: GcpPlanData;
  onSaveMission?: (req: any) => Promise<any>;
  reloadMission?: () => void;
  getEPSGcodeById: (id: any) => void;
  searchEPSGcodeByText: (searchText: string) => void;
  getGcpPlans: (projectId: string, aoiId: string, missionId: string) => void;
  updateGcpPlan: (plan: any) => void;
  getGcpPlanData: (planId: string) => void;
  updateGcpPointsData: (newGcpPointsData: any) => void;
  history: any;
}

interface IState {
  error: any;
  searchEPSGvalue: string;
  epsgCode: string;
  loaded: boolean;
  epsgSubmit: boolean;
  planForUpload?: any;
  gcpPlans?: any[];
}

class SurveyPlanning extends React.PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      error: null,
      searchEPSGvalue: '',
      epsgCode: '',
      loaded: true,
      epsgSubmit: false,
    };
  }

  public componentDidMount(): void {
    const {
      aoiId,
      projectId,
      missionId,
      getGcpPlans,
      epsgCode,
      epsg,
      getEPSGcodeById,
    } = this.props;

    getGcpPlans(projectId, aoiId, missionId);
    if (epsgCode) {
      const id = epsgCode;

      getEPSGcodeById(id);
    }

    if (epsg) {
      this.setState({
        searchEPSGvalue: epsg.name,
        epsgCode: epsg.code,
      });
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    const { epsg, epsgCode, getEPSGcodeById } = this.props;

    if (epsg !== nextProps.epsg) {
      this.setState({
        searchEPSGvalue: nextProps.epsg.name,
        epsgCode: nextProps.epsg.code,
      });
    }

    if (epsgCode && epsgCode !== nextProps.epsgCode) {
      getEPSGcodeById(epsgCode);
    }
  }

  private handleEpsgSubmit = () => {
    this.setState({
      epsgSubmit: false,
    });
  };

  private getUnitLabel = (unit: string) => {
    switch (unit) {
      case 'meter':
        return 'meters';
      case 'foot':
        return 'feet';
      case 'usFT':
        return 'US Survey feet';
      default:
        return unit;
    }
  };

  private handleEPSGChange = (event: any) => {
    const searchstr = event.target.value;
    const { searchEPSGcodeByText } = this.props;

    this.setState(
      {
        searchEPSGvalue: event.target.value,
        epsgCode: '',
      },
      () => {
        const { searchEPSGvalue } = this.state;

        if (searchEPSGvalue.length > 2) {
          searchEPSGcodeByText(searchEPSGvalue);
          this.setState({
            searchEPSGvalue: searchstr,
          });
        }
      }
    );
  };

  private openUploadModal = () => {
    /**/
  };

  private handleGcpPlanChanged = (plan: any) => {
    const { reloadMission, updateGcpPlan } = this.props;

    updateGcpPlan(plan);
    if (reloadMission) {
      reloadMission();
    }
  };

  public render(): React.ReactNode {
    const { epsgCode, error, loaded, searchEPSGvalue, epsgSubmit } = this.state;

    const {
      aoiId,
      projectId,
      missionId,
      plans,
      isGroundControlPoints,
      unit,
      getGcpPlanData,
      updateGcpPlan,
      gcpPlanData,
      updateGcpPointsData,
      epsgCode: epsgCodeProps,
      history,
      getGcpPlans,
    } = this.props;

    if (!loaded) {
      return <SkeletonLoader size={2} position="fixed" />;
    }

    return (
      <React.Fragment>
        <div className={styles.container}>
          <div className={styles.title}>
            <h3>EPSG CODE</h3>
          </div>
          <div className={styles.epsgCodeText}>
            {epsgCode ? (
              <>
                <p className={styles.paraType}>
                  The Project EPSG code is:{' '}
                  <b>
                    EPSG:
                    {epsgCode}
                  </b>
                  &nbsp;
                </p>
                <input
                  list="browser"
                  type="text"
                  name="epsgValue"
                  readOnly
                  autoComplete="off"
                  value={searchEPSGvalue}
                  onChange={(event: any) => this.handleEPSGChange(event)}
                  disabled
                />
              </>
            ) : (
              <div className={styles.unitText}>
                <p className={styles.paraType}>
                  No EPSG Code has yet been selected for this project. Please
                  select an EPSG code{' '}
                  <Link to={`/project/${projectId}/edit`}>
                    <u>here</u>
                  </Link>
                  .
                </p>
              </div>
            )}
          </div>
          {isGroundControlPoints ? (
            <div className={styles.measurementDiv}>
              <div className={styles.title}>
                <h3>MEASUREMENT UNITS </h3>
              </div>
              <div className={styles.unitText}>
                {unit
                  ? `Based on the selected EPSG code, ground control point measurements
                                must be taken in ${this.getUnitLabel(unit)}.`
                  : 'Please select an EPSG code for this project, to set the associated units.'}
              </div>
            </div>
          ) : null}
          {epsgCodeProps ? (
            <div className={styles.gcpContainer}>
              {
                <RenderGCP
                  aoiId={aoiId}
                  projectId={projectId}
                  missionId={missionId}
                  epsgCode={epsgCode}
                  plans={plans}
                  getGcpPlans={getGcpPlans}
                  getGcpPlanData={getGcpPlanData}
                  gcpPlanData={gcpPlanData}
                  updateGcpPointsData={updateGcpPointsData}
                  updateGcpPlanData={updateGcpPlan}
                  handleGcpPlanChanged={this.handleGcpPlanChanged}
                  openUploadModal={this.openUploadModal}
                  history={history}
                />
              }
            </div>
          ) : null}
        </div>
        {epsgSubmit ? (
          <ModalNotification
            notificationTitle="EPSG Code Saved"
            notificationBody="EPSG code has been successfully saved"
            shownotificationModal
            handleModalClose={this.handleEpsgSubmit}
            error={error}
            okButtonTitle="OK"
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default SurveyPlanning;
