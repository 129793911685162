export interface LatLon {
  latitude: number;
  longitude: number;
}

export function latToMetricScale(latitude: number): LatLon {
  const LAT_CONV = 111132.92;
  const LON_CONV = 111412.84;

  const latRadians = (latitude * Math.PI) / 180;
  const latc =
    LAT_CONV -
    559.82 * Math.cos(2 * latRadians) +
    1.175 * Math.cos(4 * latitude);
  const lonc = LON_CONV * Math.cos(latRadians) - 93.5 * Math.cos(3 * latitude);

  return { latitude: latc, longitude: lonc };
}

export function splitLineString(
  points: LatLon[],
  numSamples: number
): { samples: LatLon[]; lengths: number[] } {
  if (numSamples < 2) throw Error('At least two samples needed for splitting.');

  const numPoints = points.length;

  if (numPoints < 2) throw Error('At least two points must be given.');

  // We use one of the points to determine the metric scale.
  const scale = latToMetricScale(points[0].latitude);

  const totalLength = points.reduce((dist, pt, idx) => {
    if (idx === 0) return dist;

    const prev = points[idx - 1];
    const segLength = distance(pt, prev, scale);

    return dist + segLength;
  }, 0.0);

  const partLength = totalLength / (numSamples - 1);

  const samples = [points[0]];
  const sampleLengths = [0.0];

  let remaining = numSamples - 1;
  let remainingDist = partLength;

  let currPt = points[0];
  let currLen = 0;
  let nextIdx = 0;

  while (remaining > 1) {
    if (nextIdx >= numPoints) break;
    const nextPt = points[nextIdx];
    const nextDist = distance(currPt, nextPt, scale);

    if (nextDist < remainingDist) {
      remainingDist -= nextDist;
      currLen += nextDist;
      currPt = points[nextIdx];
      nextIdx += 1;
    } else {
      const del = delta(nextPt, currPt);
      const fac = remainingDist / nextDist;
      const step: LatLon = {
        latitude: del.latitude * fac,
        longitude: del.longitude * fac,
      };

      currPt = {
        latitude: currPt.latitude + step.latitude,
        longitude: currPt.longitude + step.longitude,
      };
      currLen += remainingDist;
      samples.push(currPt);
      sampleLengths.push(currLen);
      remaining -= 1;
      remainingDist = partLength;
    }
  }

  samples.push(points[numPoints - 1]);
  sampleLengths.push(totalLength);

  return { samples, lengths: sampleLengths };
}

function delta(pt1: LatLon, pt2: LatLon): LatLon {
  return {
    latitude: pt1.latitude - pt2.latitude,
    longitude: pt1.longitude - pt2.longitude,
  };
}

function length2(vec: LatLon, scale: LatLon): number {
  const delta: LatLon = {
    latitude: vec.latitude * scale.latitude,
    longitude: vec.longitude * scale.longitude,
  };

  return delta.latitude * delta.latitude + delta.longitude * delta.longitude;
}

function distance2(pt1: LatLon, pt2: LatLon, scale: LatLon): number {
  return length2(delta(pt1, pt2), scale);
}

export function distance(pt1: LatLon, pt2: LatLon, scale: LatLon): number {
  return Math.sqrt(distance2(pt1, pt2, scale));
}
