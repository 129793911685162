// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthorizeNetInputFields_container__KXt0H .AuthorizeNetInputFields_amountInputField__qzP06{margin-bottom:5px !important}.AuthorizeNetInputFields_container__KXt0H .AuthorizeNetInputFields_currencySelectField__hvKni{margin-bottom:10px !important}", "",{"version":3,"sources":["webpack://./src/components/Payments/AuthorizeNet/AuthorizeNetInputFields.module.scss"],"names":[],"mappings":"AAGE,2FACE,4BAAA,CAGF,8FACE,6BAAA","sourcesContent":["@import '../../../styles/scss/base/base';\n\n.container {\n  .amountInputField {\n    margin-bottom: 5px !important;\n  }\n\n  .currencySelectField {\n    margin-bottom: 10px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AuthorizeNetInputFields_container__KXt0H",
	"amountInputField": "AuthorizeNetInputFields_amountInputField__qzP06",
	"currencySelectField": "AuthorizeNetInputFields_currencySelectField__hvKni"
};
export default ___CSS_LOADER_EXPORT___;
