import { LayerDescriptor } from 'src/api/mapStyle.types';

export const zIndexSorter = (a: LayerDescriptor, b: LayerDescriptor) => {
  const az = a.style?.z_index || 0;
  const bz = b.style?.z_index || 0;

  return bz - az;
};

export const toGeojsonFile = (content?: string): File => {
  const contents =
    content || JSON.stringify({ type: 'FeatureCollection', features: [] });

  return new File([JSON.stringify(contents)], 'data.geojson', {
    type: 'application/geo+json',
  });
};
