import React, { useEffect, useMemo } from 'react';
import { Coordinate } from 'ol/coordinate';
import { Feature } from 'ol';
import { Polygon } from 'ol/geom';
import { StyleLike } from 'ol/style/Style';
import { useVectorManager } from './hooks';
import { getDefaultStyle, WEB_MERCATOR, WGS84 } from './utils';

export interface ReadOnlyPolygonProps {
  coordinates: Coordinate[][];
  style?: StyleLike;
  zIndex?: number;
}

export const ReadOnlyPolygon: React.FC<ReadOnlyPolygonProps> = ({
  coordinates,
  style,
  zIndex,
}) => {
  const { addFeature, removeFeature, setZIndex, setStyle } = useVectorManager();

  const { geometry, feature } = useMemo(() => {
    const geometry = new Polygon(coordinates);

    // maps are in web mercator by default.
    geometry.transform(WGS84, WEB_MERCATOR);
    const feature = new Feature(geometry);

    return { geometry, feature };
  }, [coordinates]);

  useEffect(() => {
    if (coordinates.length === 0) return;

    if (coordinates[0].length === 0) return;

    addFeature(feature);

    return () => {
      removeFeature(feature);
    };
  }, [feature, geometry, coordinates]);

  useEffect(() => {
    setStyle(style ?? getDefaultStyle('Polygon'));
    setZIndex(zIndex ?? 10);

    return () => {
      setStyle(undefined);
      setZIndex(0);
    };
  }, [style, zIndex]);

  return <div data-testid="osm-read-only-polygon" />;
};
