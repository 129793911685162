import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ResetPassword from './index';

import {
  clearAuthState,
  getResetMail,
  resetPasswordWithCode,
} from '../../redux/actions/auth';
import { ResetPasswordTypes } from './index.types';

const mapStateToProps = (state: any): ResetPasswordTypes.ReduxState => {
  return {
    error: state.auth.error,
    success: state.auth.success,
  };
};

const mapDispatchToProps = (
  dispatch: any
): ResetPasswordTypes.DispatchProps => {
  return bindActionCreators(
    {
      clearAuthState,
      getResetMail,
      resetPasswordWithCode,
    },
    dispatch
  );
};

const ResetPasswordContainer = connect<
  ResetPasswordTypes.ReduxState,
  ResetPasswordTypes.DispatchProps,
  ResetPasswordTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);

export default ResetPasswordContainer;
