import ApiBase from './ApiBase';
import { AUTH_URL } from '../constants/urls';

export default class Auth extends ApiBase {
  protected baseUrl: string = AUTH_URL;

  public getLogin(): Promise<any> {
    return this.get('/whoami', {});
  }

  public login(username: string, password: string): Promise<any> {
    return this.post(
      '/login',
      {
        username,
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then((data) => {
      return data;
    });
  }

  public logout(): Promise<any> {
    return this.post('/logout', null, {
      withCredentials: true,
    }).then((data) => {
      return data;
    });
  }

  public updatePassword(password: string): Promise<any> {
    return this.post('/v1/user/password', { password });
  }
}
