import { Map, View } from 'ol';
import { FullScreen } from 'ol/control';
import ImageLayer from 'ol/layer/Image';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import Projection from 'ol/proj/Projection';
import ImageStaticSource from 'ol/source/ImageStatic';
import Zoomify from 'ol/source/Zoomify';
import * as React from 'react';
import flattenChildren from 'react-flatten-children';
import ImagesApis from '../../../../api/images';
import ImageMetadata from '../../../../api/images.types';
import { degToRad, undefinedOrNull } from '../../../../utils/functs';
import { ViewConfig } from '../../index.types';
import style from './index.module.scss';
import TemperatureRange from './TemperatureRange';
import ThermalImageSource from './ThermalImageSource';

interface IProps {
  image: ImageMetadata;
  rotation: number;
  viewConfig?: ViewConfig;
  temperatureRange?: TemperatureRange;
  setOlMap?: (m: Map | undefined) => void;
}

interface IState {
  olMap?: Map;
  imageLayer?: Layer;
}

export default class ImageRenderer extends React.Component<IProps, IState> {
  private olMapReference = React.createRef<HTMLDivElement>();

  private imageAPI: ImagesApis = new ImagesApis();

  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const mapEl = this.olMapReference.current;

    if (mapEl == null) {
      return;
    }

    const olMap = new Map({
      target: mapEl,
      controls: [
        new FullScreen({
          className: style.fullScreenControl,
        }),
      ],
    });

    const { setOlMap } = this.props;

    if (setOlMap) {
      setOlMap(olMap);
    }

    this.setState(
      {
        olMap,
      },
      () => {
        const { image, rotation } = this.props;

        this.renderImageLayer(image, rotation || 0);
      }
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const {
      image: nextImage,
      rotation: nextRotation,
      temperatureRange: nextRange,
    } = nextProps;
    const { image, rotation, temperatureRange } = this.props;

    if (
      image?.guid !== nextImage?.guid ||
      !this.areRangesEqual(temperatureRange, nextRange)
    ) {
      this.renderImageLayer(nextImage, nextRotation || 0);
    } else if (rotation !== nextRotation) {
      const { olMap } = this.state;

      // eslint-disable-next-line no-unused-expressions
      olMap?.getView()?.setRotation(degToRad(nextRotation));
    }
  }

  public componentWillUnmount() {
    const { setOlMap } = this.props;

    if (setOlMap) {
      setOlMap(undefined);
    }
  }

  private areRangesEqual(
    a: TemperatureRange | undefined,
    b: TemperatureRange | undefined
  ) {
    if (!a && !b) return true;
    if (!!a && !b) return false;
    if (!a && !!b) return false;
    if (a && b) {
      return (
        a.min === b.min &&
        a.max === b.max &&
        a.rangeMin === b.rangeMin &&
        a.rangeMax === b.rangeMax
      );
    }

    return false;
  }

  private renderImageLayer(image: ImageMetadata, rotation: number) {
    const { temperatureRange } = this.props;

    if (image.thermalImage && image.linkedImageGuid) {
      const url = this.imageAPI.getThermalImageUrl(image.guid);
      const source = new ThermalImageSource(
        {
          url,
          imageExtent: [0, 0, image.imageWidth, image.imageHeight],
        },
        temperatureRange || {
          min: image.minTemperature,
          max: image.maxTemperature,
          rangeMin: image.minTemperature,
          rangeMax: image.maxTemperature,
        }
      );

      this.renderStaticImageLayer(source, rotation);

      return;
    }

    this.imageAPI.getTileImageData(image.guid).then((res) => {
      const { data, error } = res;

      if (error) {
        console.error(error);
        const url = this.imageAPI.getImageUrl(image.guid);
        const source = new ImageStaticSource({
          url,
          imageExtent: [0, 0, image.imageWidth, image.imageHeight],
        });

        this.renderStaticImageLayer(source, rotation);

        return;
      }

      if (data) {
        const dziInfo = this.parseTilesDzi(
          this.imageAPI.getImageTilesUrl(image.guid, null, false),
          data
        );
        const { imgHeight, imgWidth, tileSize, offset, url } = dziInfo;
        const imgCenter = [imgWidth / 2, -imgHeight / 2];

        const proj = new Projection({
          code: 'ZOOMIFY',
          units: 'pixels',
          extent: [0, 0, imgWidth, imgHeight],
        });

        const urlGenFn = (tileCoord: number[]) => {
          const u = url
            .replace('{z}', `${tileCoord[0] + offset}`)
            .replace('{x}', `${tileCoord[1]}`)
            .replace('{y}', `${tileCoord[2]}`);

          return u;
        };

        const source = new Zoomify({
          url,
          size: [imgWidth, imgHeight],
          tileSize,
          crossOrigin: 'anonymous',
        });

        source.setTileUrlFunction(urlGenFn.bind(source));

        const layer = new TileLayer({
          source,
          zIndex: 0,
        });
        // @ts-ignore

        const { olMap, imageLayer: oldLayer } = this.state;
        const oldZoom = olMap?.getView()?.getZoom();
        const oldCenter = olMap?.getView()?.getCenter();
        const { viewConfig } = this.props;

        let centerFromAPI = imgCenter;

        if (viewConfig) {
          centerFromAPI = [
            undefinedOrNull(viewConfig.longitude)
              ? imgCenter[1]
              : viewConfig.longitude,
            undefinedOrNull(viewConfig.latitude)
              ? imgCenter[0]
              : viewConfig.latitude,
          ];
        }

        const layerTypeChanged =
          !!oldLayer && !!(oldLayer instanceof ImageLayer);
        const center =
          (layerTypeChanged ? undefined : oldCenter) || centerFromAPI;
        const zoom =
          (layerTypeChanged ? undefined : oldZoom) || viewConfig?.zoom || 2;

        const view = new View({
          projection: proj,
          center,
          zoom,
          // @ts-ignore
          constrainOnlyCenter: true,
          constrainRotation: false,
          extent: [0, -imgHeight, imgWidth, 0],
          rotation: degToRad(rotation),
        });

        if (oldLayer) {
          oldLayer.setZIndex(5);

          const timeout = 200;
          const decrease = 0.1;
          let opacity = 1;

          // fade out old layer
          const fader = () => {
            if (opacity >= decrease) {
              opacity -= decrease;
              oldLayer.setOpacity(opacity);

              window.setTimeout(fader, timeout);
            } else if (olMap) {
              olMap.removeLayer(oldLayer);
            }
          };

          fader();
        }

        this.setState({ imageLayer: layer }, () => {
          if (olMap) {
            olMap.addLayer(layer);
            olMap.setView(view);
          }
        });
      }
    });
  }

  private renderStaticImageLayer(source: any, rotation: number) {
    const { viewConfig } = this.props;
    const imgCenter = [4000, 3000];

    const layer = new ImageLayer({
      source,
      zIndex: 10,
    });

    let centerFromAPI = imgCenter;

    if (viewConfig) {
      centerFromAPI = [
        undefinedOrNull(viewConfig.longitude)
          ? imgCenter[1]
          : viewConfig.longitude,
        undefinedOrNull(viewConfig.latitude)
          ? imgCenter[0]
          : viewConfig.latitude,
      ];
    }

    const view = new View({
      zoom: !undefinedOrNull(viewConfig) ? viewConfig.zoom : 2,
      // @ts-ignore
      constrainOnlyCenter: true,
      rotation: degToRad(rotation),
      center: centerFromAPI,
    });

    source.on('imageloadend', () => {
      view.fit(source.getImageExtent());
    });

    const { olMap, imageLayer: oldLayer } = this.state;

    this.setState({ imageLayer: layer }, () => {
      if (olMap) {
        olMap.addLayer(layer);
        olMap.setView(view);
        if (oldLayer) {
          olMap.removeLayer(oldLayer);
        }
      }
    });
  }

  private parseTilesDzi = (url: string, dziXmlData: string): any => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(dziXmlData, 'text/xml');
    const last = url.lastIndexOf('.');
    const path = url.slice(0, last);
    const elements = xmlDoc.getElementsByTagName('Image');
    const tileSize = Number(elements[0].getAttribute('TileSize'));
    const format = elements[0].getAttribute('Format');

    const width = Number(
      elements[0].getElementsByTagName('Size')[0].getAttribute('Width')
    );
    const height = Number(
      elements[0].getElementsByTagName('Size')[0].getAttribute('Height')
    );

    const formattedUrl = `${path}_files/{z}/{x}_{y}.${format}`;
    const offset = Math.ceil(Math.log(tileSize) / Math.LN2);

    return {
      url: formattedUrl,
      offset,
      imgWidth: width,
      imgHeight: height,
      tileSize,
    };
  };

  public render() {
    const { children } = this.props;
    const { olMap } = this.state;

    const childrenWithProps = React.Children.map(
      flattenChildren(children),
      (child) => {
        if (React.isValidElement(child as any)) {
          return React.cloneElement(child as any, { olMap });
        }

        return child;
      }
    );

    return (
      <div className={style.container} ref={this.olMapReference}>
        {childrenWithProps}
      </div>
    );
  }
}
