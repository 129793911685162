/* eslint-disable no-proto */
import classnames from 'classnames';
import _ from 'lodash';
import { FullScreen, Zoom } from 'ol/control';
import Rotate from 'ol/control/Rotate';
import GeoJSON from 'ol/format/GeoJSON';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { ObjectEvent } from 'ol/Object';
import { fromLonLat, toLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Stroke, Style } from 'ol/style';
import View from 'ol/View';
import * as React from 'react';
import flattenChildren from 'react-flatten-children';
import ProjectsAPI from 'src/api/projects';
import { ValueRange } from 'src/components/OpenLayersMap/index.types';
import { MAPBOX_STYLE_SATELLITE_V9_URL } from 'src/constants/urls';
import {
  DEFAULT_LAYER_Z_INDEX,
  SATELLITE_LAYER_Z_INDEX,
} from '../../../../constants';
import {
  MapConfigChanged,
  OLViewInitialized,
  RendererLayer,
  RendererStateChange,
  ViewConfig,
} from '../../index.types';
import style from './index.module.scss';
import {
  OpenLayersRendererPropsType,
  OpenLayersRendererStateType,
} from './index.types';
import {
  getCustomStylesForGeometries,
  getGeojsonFromB64String,
  getMapboxStyleTileUrl,
  getViewRendererLayers,
} from './utils';

export default class OpenLayersRenderer extends React.PureComponent<
  OpenLayersRendererPropsType,
  OpenLayersRendererStateType
> {
  private olMapReference = React.createRef<HTMLDivElement>();

  public constructor(props: OpenLayersRendererPropsType) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    const { viewConfig, overrideControls } = this.props;
    const mapEl = this.olMapReference.current;

    if (mapEl == null) {
      return;
    }

    const olMap = new Map({
      target: mapEl,
      controls: overrideControls || [
        new Zoom({
          className: style.zoomControl,
        }),
        new FullScreen({
          className: style.fullScreenControl,
        }),
        new Rotate({
          label: this.arrowLabel(),
          tipLabel: 'Alt+shift+drag to rotate \nClick to reset',
          autoHide: false,
          className: style.rotateControl,
        }),
      ],
    });

    this.setState(
      {
        olMap,
      },
      async () => {
        await this.initializeMap(this.props);
        const olView = olMap.getView();

        this.setMapViewConfig(olView, viewConfig);
      }
    );
  }

  public UNSAFE_componentWillReceiveProps(
    newProps: OpenLayersRendererPropsType
  ) {
    const { sourceUrl: newSourceUrl, viewConfig: newViewConfig } = newProps;
    const { sourceUrl, viewConfig } = this.props;
    const { olMap } = this.state;

    if (sourceUrl !== newSourceUrl) {
      this.initializeMap(newProps);
    }

    // when viewConfig object arrives before map creation
    if (!_.isEqual(newViewConfig, viewConfig)) {
      this.setMapViewConfig(olMap?.getView(), newViewConfig);
    }
  }

  public componentDidUpdate(prevProps: OpenLayersRendererPropsType) {
    // This can't be in cWRP as the source won't be able to read the new values.
    // When USNAFE_cWRP is moved here, this should be preserved.
    const { valueRange: prevValueRange } = prevProps;
    const { valueRange } = this.props;

    if (prevValueRange !== valueRange) {
      const { layers } = this.state;

      // eslint-disable-next-line no-unused-expressions
      layers?.forEach((l) => l._layer?.changed());
    }
  }

  private arrowLabel = () => {
    const divContainer = document.createElement('div');

    const divWrapper = document.createElement('div');

    divWrapper.className = style.compassWrapper;

    const divNeedleTop = document.createElement('div');

    divNeedleTop.className = style.needleTop;

    const divBtCenter = document.createElement('div');

    divBtCenter.className = style.btCenter;

    const divNeedleBottom = document.createElement('div');

    divNeedleBottom.className = style.needleBottom;

    divContainer.appendChild(divWrapper);
    divWrapper.appendChild(divNeedleTop);
    divWrapper.appendChild(divBtCenter);
    divWrapper.appendChild(divNeedleBottom);

    // div.innerHTML = 'Hello';
    const docContainer = document.getElementById('app');

    return docContainer?.appendChild(divWrapper);
  };

  private setMapViewConfig(
    olView: View | undefined,
    viewConfig: ViewConfig | undefined
  ) {
    const { overlayGeojson } = this.props;

    // disable view config restore if there is an overlay
    if (olView && !overlayGeojson) {
      if (viewConfig?.latitude && viewConfig?.longitude) {
        olView.setCenter(
          fromLonLat([viewConfig.longitude, viewConfig.latitude])
        );
      }

      if (viewConfig?.zoom) {
        // NOTE: Do not use setResolution. Causes unexpected motion when both zoom & resolution and set
        // to different values.
        olView.setZoom(viewConfig.zoom);
      }
    }
  }

  private getValueRange = (): ValueRange => {
    const { valueRange } = this.props;

    if (!valueRange) return { min: 0, max: 0 };

    return valueRange;
  };

  private initializeMap = async (props: OpenLayersRendererPropsType) => {
    const {
      sourceToken,
      defaultViewState,
      olView: externalOLView,
      view,
      overlayGeojson,
      onEvent,
    } = props;
    const { olMap, layers: oldLayers } = this.state;

    if (!olMap) {
      console.error('Could not find reference to map.');

      return;
    }

    const olViewInited = !!olMap.getView().getCenter();
    let olView: View;

    if (externalOLView !== undefined) {
      olView = externalOLView;
    } else {
      if (!defaultViewState) {
        console.error(
          `View is missing parameters essential to initialization.`
        );

        return;
      }

      const { center, zoom } = defaultViewState;

      if (
        center?.latitude === undefined ||
        center?.longitude === undefined ||
        zoom?.default === undefined ||
        zoom?.max === undefined ||
        zoom?.min === undefined
      ) {
        console.error(
          `View is missing parameters essential to initialization.`
        );

        return;
      }

      olView = olMap.getView();

      if (!olViewInited) {
        olView = new View({
          center: fromLonLat([center.longitude, center.latitude]),
          zoom: zoom.default,
        });
      }

      if (view.subType !== 'satellite') {
        olView.setMaxZoom(zoom.max);
        olView.setMinZoom(zoom.min);
      }

      onEvent(new OLViewInitialized(olView));
    }

    const layers: Layer[] = [];

    // setting up event handlers for map motion
    olView.on('change:center', this.onMapMove);
    olView.on('change:resolution', this.onMapZoom);

    olMap.setView(olView);

    let rendererLayers: RendererLayer[] | null = await getViewRendererLayers(
      view,
      this.getValueRange
    );
    let descriptor: any;

    if (view.subType === 'satellite') {
      const layer = new TileLayer({
        source: new XYZ({
          url: getMapboxStyleTileUrl(
            MAPBOX_STYLE_SATELLITE_V9_URL,
            sourceToken || ''
          ),
          crossOrigin: 'anonymous',
        }),
        zIndex: SATELLITE_LAYER_Z_INDEX,
      });

      rendererLayers = [
        {
          id: '',
          visible: () => true,
          label: '',
          setVisible: () => {},
          _layer: layer,
        },
      ];

      const projectApis = new ProjectsAPI();
      const { projectId, aoiId } = view;

      await projectApis
        .fetchAoiBoundary(projectId, aoiId)
        .then((boundary) => {
          const vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(boundary, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857',
            }),
          });
          const extent = vectorSource.getExtent();

          if (!olViewInited) {
            olView.fit(extent);
          }

          const vectorLayer = new VectorLayer({
            source: vectorSource,
            zIndex: DEFAULT_LAYER_Z_INDEX,
            style: (_feature: any, _resolution: any) => {
              return new Style({
                stroke: new Stroke({
                  color: 'red',
                  width: 2,
                }),
              });
            },
          });

          // eslint-disable-next-line
          rendererLayers?.push({
            id: 'boundary',
            visible: () => true,
            label: 'Boundary',
            setVisible: () => {},
            _layer: vectorLayer,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }

    if (!rendererLayers) {
      console.error('Error while parsing layers for vimana tiles');

      return;
    }

    if (overlayGeojson && getGeojsonFromB64String(overlayGeojson)) {
      const geojson = getGeojsonFromB64String(overlayGeojson);

      const vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(geojson, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }),
      });
      const extent = vectorSource.getExtent();

      if (!olViewInited) {
        olView.fit(extent, { padding: [25, 25, 25, 25] });
      }

      const vectorLayer = new VectorLayer({
        source: vectorSource,
        zIndex: DEFAULT_LAYER_Z_INDEX + 1,
        style: getCustomStylesForGeometries,
      });

      // eslint-disable-next-line
      rendererLayers?.push({
        id: 'overlay',
        visible: () => true,
        label: 'Overlay',
        setVisible: () => {},
        _layer: vectorLayer,
      });
    }

    // TODO: add appropriate logic to ensure zIndex of layers is set appropriately.
    const l = rendererLayers.map((r) => r._layer).filter((l) => !!l) as Layer[];

    layers.push(...l);

    this.setState(
      {
        layers: rendererLayers,
      },
      () => {
        const { onEvent } = this.props;

        onEvent(
          new RendererStateChange({
            layers: rendererLayers || undefined,
            descriptor,
          })
        );
      }
    );

    // cleaning up old layers
    if (oldLayers) {
      oldLayers.forEach((l) => {
        if (l._layer) {
          olMap.removeLayer(l._layer);
        }
      });
    }

    layers.forEach((l) => {
      olMap.addLayer(l);
    });
  };

  // Event handling functions --------------

  private onMapMove = (e: ObjectEvent) => {
    const { onEvent } = this.props;
    const coordinates = toLonLat(e.target.getCenter());

    onEvent(
      new MapConfigChanged({
        longitude: coordinates[0],
        latitude: coordinates[1],
      })
    );
  };

  private onMapZoom = (e: ObjectEvent) => {
    const { onEvent } = this.props;
    const zoom = e.target.getZoom();

    onEvent(
      new MapConfigChanged({
        zoom,
      })
    );
  };

  public render() {
    const { className, children } = this.props;
    const { olMap } = this.state;

    const childrenWithProps = React.Children.map(
      flattenChildren(children),
      (child) => {
        if (React.isValidElement(child as any)) {
          return React.cloneElement(child as any, { olMap });
        }

        return child;
      }
    );

    return (
      <div
        className={classnames(style.container, className)}
        ref={this.olMapReference}
      >
        {childrenWithProps}
      </div>
    );
  }
}
