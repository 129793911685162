import * as React from 'react';
import { Link } from 'react-router-dom';
import style from './ProjectItem.module.scss';
import { BASE_CAPI_URL } from '../../constants/urls';

interface IProps {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  address: string;
  description: string | null;
}
export default class ProjectItem extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { id, name, address, description } = this.props;

    const imageUrl = `${BASE_CAPI_URL}/v1/thumbnail/project/${id}`;

    return (
      <Link to={`/project/${id}`} title={name}>
        <div className={`${style.container} ${style.shadow}`}>
          <div className={`${style.image}`}>
            <img src={imageUrl} alt="projectImage" />
          </div>
          <div className={style.nameWrapper}>
            <i
              className={`fa fa-building ${style.faIcon}`}
              aria-hidden="true"
            />
            <div className={style.name}>{name || <div>&nbsp;</div>}</div>
          </div>
          {description && description.length > 0 ? (
            <div className={style.locationWrapper}>
              <i className={`fa fa-info ${style.faIcon}`} aria-hidden="true" />
              <div className={style.location}>{description}</div>
            </div>
          ) : (
            <div className={style.locationWrapper}>
              <i
                className={`fa fa-map-marker ${style.faIcon}`}
                aria-hidden="true"
              />
              <div className={style.location}>
                {address || <span>N/A</span>}
              </div>
            </div>
          )}
        </div>
      </Link>
    );
  }
}
