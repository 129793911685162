import ApiBase from './ApiBase';
import {
  GenericApisReturnPromiseTypes,
  GenericObjectType,
} from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_CAPI_URL } from '../constants/urls';

export default class GcpMarkingApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = `/v1`;
  }

  public listGcpBySession(sessionId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/markers/sessions/${sessionId}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the GCP by sessions.`,
          isArray: true,
        });
      });
  }

  public listProjections(markerId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/markers/${markerId}/projections`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the GCP projections.`,
          isArray: true,
        });
      });
  }

  public postProjection(
    sessionId: string,
    gcpId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/markers/sessions/${sessionId}/gcps/${gcpId}/projections`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to create the projection.`,
        });
      });
  }

  public updateProjection(
    sessionId: string,
    gcpId: string,
    projectionId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.patch(
      `${this._baseUrl}/markers/sessions/${sessionId}/gcps/${gcpId}/projections/${projectionId}`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to update the projection.`,
        });
      });
  }

  public deleteProjection(
    sessionId: string,
    gcpId: string,
    projectionId: string
  ): GenericApisReturnPromiseTypes {
    return this.delete(
      `${this._baseUrl}/markers/sessions/${sessionId}/gcps/${gcpId}/projections/${projectionId}`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to delete the projection.`,
        });
      });
  }

  public listImages(sessionId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/sessions/${sessionId}/images`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the images by sessions.`,
        });
      });
  }

  public listEstimatedProjections(
    sessionId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/sessions/${sessionId}/projections/estimates`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the estimated projections.`,
        });
      });
  }

  public updateSession(
    sessionId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.post(`${this._baseUrl}/marking_complete/${sessionId}`, formData)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to update the session.`,
        });
      });
  }

  public updateMarkingGcp(
    markerId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.patch(`${this._baseUrl}/markers/${markerId}`, formData)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to update the GCP.`,
        });
      });
  }

  public listRmsError(sessionId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/sessions/${sessionId}/rms_error`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the RMS Error.`,
          isArray: true,
        });
      });
  }
}
