/* eslint-disable func-names,no-param-reassign */

import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import constrainFeatureMovement from '@mapbox/mapbox-gl-draw/src/lib/constrain_feature_movement';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import booleanContains from '@turf/boolean-contains';
import booleanOverlap from '@turf/boolean-overlap';
import kinks from '@turf/kinks';
import POPUP from './Popup';

const { noTarget, isOfMetaType, isInactiveFeature, isShiftDown } =
  CommonSelectors;

const isVertex = isOfMetaType(Constants.meta.VERTEX);
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT);

const EditAreas: any = {};

// INTERNAL FUCNTIONS

EditAreas.fireUpdate = function () {
  this.map.fire(Constants.events.UPDATE, {
    action: Constants.updateActions.CHANGE_COORDINATES,
    features: this.getSelected().map((f: any) => f.toGeoJSON()),
  });
};

EditAreas.fireActionable = function (state: any) {
  this.setActionableState({
    combineFeatures: false,
    uncombineFeatures: false,
    trash: state.selectedCoordPaths.length > 0,
  });
};

EditAreas.startDragging = function (state: any, e: any) {
  this.map.dragPan.disable();
  state.canDragMove = true;
  state.dragMoveLocation = e.lngLat;
  state.saveFeatureGeoJson = state.featureId
    ? this.getFeature(state.featureId).toGeoJSON()
    : null;
};

EditAreas.stopDragging = function (state: any) {
  this.map.dragPan.enable();
  state.dragMoving = false;
  state.canDragMove = false;
  state.dragMoveLocation = null;
  state.featureSaveGeoJson = null;
};

EditAreas.onVertex = function (state: any, e: any) {
  POPUP.setHTML('Drag the vertex to edit the area');

  this.startDragging(state, e);
  const about = e.featureTarget.properties;
  const selectedIndex = state.selectedCoordPaths.indexOf(about.coord_path);

  if (!isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths = [about.coord_path];
  } else if (isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths.push(about.coord_path);
  }

  const selectedCoordinates = this.pathsToCoordinates(
    state.featureId,
    state.selectedCoordPaths
  );

  this.setSelectedCoordinates(selectedCoordinates);
};

EditAreas.onMidpoint = function (state: any, e: any) {
  this.startDragging(state, e);
  const about = e.featureTarget.properties;

  state.feature.addCoordinate(about.coord_path, about.lng, about.lat);
  this.fireUpdate();
  state.selectedCoordPaths = [about.coord_path];
};

EditAreas.pathsToCoordinates = function (featureId: any, paths: any) {
  return paths.map((coord_path: any) => {
    return { feature_id: featureId, coord_path };
  });
};

EditAreas.onFeature = function (state: any, e: any) {
  if (state.selectedCoordPaths.length === 0) this.startDragging(state, e);
  else this.stopDragging(state);
};

EditAreas.dragFeature = function (state: any, e: any, delta: any) {
  moveFeatures(this.getSelected(), delta);
  state.dragMoveLocation = e.lngLat;
};

EditAreas.dragVertex = function (state: any, e: any, delta: any) {
  const selectedCoords = state.selectedCoordPaths.map((coord_path: any) =>
    state.feature.getCoordinate(coord_path)
  );
  const selectedCoordPoints = selectedCoords.map((coords: any) => ({
    type: Constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: Constants.geojsonTypes.POINT,
      coordinates: coords,
    },
  }));

  const constrainedDelta = constrainFeatureMovement(selectedCoordPoints, delta);

  for (let i = 0; i < selectedCoords.length; i += 1) {
    const coord = selectedCoords[i];

    state.feature.updateCoordinate(
      state.selectedCoordPaths[i],
      coord[0] + constrainedDelta.lng,
      coord[1] + constrainedDelta.lat
    );
  }
};

EditAreas.clickNoTarget = function () {
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

EditAreas.clickInactive = function () {
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

EditAreas.clickActiveFeature = function (state: any) {
  state.selectedCoordPaths = [];
  this.clearSelectedCoordinates();
  state.feature.changed();
};

EditAreas.toPolygonFeature = function (
  coordinates: number[][][],
  properties: any = {}
) {
  const polygon = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: { ...properties },
    geometry: {
      type: Constants.geojsonTypes.POLYGON,
      coordinates,
    },
  });

  return polygon;
};

EditAreas.checkPolygonContainsHole = function (
  polygon: any,
  hole: any
): boolean {
  if (!booleanContains(polygon, hole)) {
    return false;
  }

  //  ensure hole does not overlap with existing holes
  if (booleanOverlap(polygon, hole)) {
    return false;
  }

  // check if the existing holes are contained within the new hole
  const innerRingsContainedInHole = polygon.geometry.coordinates
    .slice(1)
    .map((ring: any) => {
      return this.toPolygonFeature([ring]);
    })
    .map((ringPolygon: any) => {
      return booleanContains(hole, ringPolygon.toGeoJSON());
    });

  if (innerRingsContainedInHole.indexOf(true) > -1) {
    // there should be no inner ring contained in the hole
    return false;
  }

  return true;
};

EditAreas.validateModifiedFeatures = function (state: any) {
  const { saveFeatureGeoJson, selectedCoordPaths, featureId } = state;
  const modifiedFeature = this.getFeature(featureId);
  let validChange = false;

  if (
    selectedCoordPaths &&
    selectedCoordPaths.length === 1 &&
    modifiedFeature
  ) {
    const ringIndex = Number(selectedCoordPaths[0].split('.')[0]);
    const featureGeoJson = modifiedFeature.toGeoJSON();

    if (ringIndex === 0) {
      // handle case where outer ring has been modified
      const outerRing = this.toPolygonFeature([
        featureGeoJson.geometry.coordinates[0],
      ]);
      const checkHoleContainment = featureGeoJson.geometry.coordinates
        .slice(1)
        .map((ring: any) => {
          const ringPolygon = this.toPolygonFeature([ring]);

          // check if holes are still contained within the outer ring
          return booleanContains(outerRing, ringPolygon);
        });
      // logic to check for self-intersections.
      // refer: https://gis.stackexchange.com/questions/338163/turfjs-cannot-detect-all-self-intersections
      const _kinks = kinks(outerRing);

      if (
        checkHoleContainment.indexOf(false) <= -1 &&
        _kinks.features.length === 0 // ensure there are no self-intersections
      ) {
        // if all holes are still valid after change
        validChange = true;
      }
    } else {
      // handle case where inner ring (hole) has been modified
      // construct polygon from unmodified rings
      const otherRingsPolygon = this.toPolygonFeature([
        ...featureGeoJson.geometry.coordinates.slice(0, ringIndex),
        ...featureGeoJson.geometry.coordinates.slice(ringIndex + 1),
      ]).toGeoJSON();
      const modifiedRingPolygon = this.toPolygonFeature([
        featureGeoJson.geometry.coordinates[ringIndex],
      ]).toGeoJSON();
      const _kinks = kinks(modifiedRingPolygon);

      if (
        this.checkPolygonContainsHole(otherRingsPolygon, modifiedRingPolygon) &&
        _kinks.features.length === 0 // ensure there are no self-intersections
      ) {
        // if modified hole, is still contained within the rest of the modified feature
        validChange = true;
      }
    }
  }

  if (saveFeatureGeoJson && !validChange) {
    const newFeature = this.newFeature(saveFeatureGeoJson);

    this.deleteFeature(featureId);
    this.addFeature(newFeature);
    // fire update event with empty feature list to propogate error
    this.map.fire(Constants.events.UPDATE, {
      action: Constants.updateActions.CHANGE_COORDINATES,
      features: [],
    });
    // exit vertex editing mode
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  }
};

// EXTERNAL FUNCTIONS

EditAreas.onSetup = function (opts: any) {
  const { featureId } = opts;
  const feature = this.getFeature(featureId);

  if (!feature) {
    throw new Error('You must provide a featureId to enter direct_select mode');
  }

  if (feature.type === Constants.geojsonTypes.POINT) {
    throw new TypeError("direct_select mode doesn't handle point features");
  }

  const state = {
    featureId,
    feature,
    dragMoveLocation: opts.startPos || null,
    dragMoving: false,
    canDragMove: false,
    selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
    saveFeatureGeoJson: null,
  };

  this.setSelectedCoordinates(
    this.pathsToCoordinates(featureId, state.selectedCoordPaths)
  );
  this.setSelected(featureId);
  doubleClickZoom.disable(this);

  this.setActionableState({
    trash: true,
  });

  return state;
};

EditAreas.onStop = function () {
  doubleClickZoom.enable(this);
  this.clearSelectedCoordinates();
};

EditAreas.toDisplayFeatures = function (state: any, geojson: any, push: any) {
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = Constants.activeStates.ACTIVE;
    push(geojson);
    createSupplementaryPoints(geojson, {
      map: this.map,
      midpoints: true,
      selectedPaths: state.selectedCoordPaths,
    }).forEach(push);
  } else {
    geojson.properties.active = Constants.activeStates.INACTIVE;
    push(geojson);
  }

  this.fireActionable(state);
};

EditAreas.onTrash = function (state: any) {
  state.selectedCoordPaths
    .sort()
    .reverse()
    .forEach((id: any) => state.feature.removeCoordinate(id));
  this.fireUpdate();
  state.selectedCoordPaths = [];
  this.clearSelectedCoordinates();
  this.fireActionable(state);
  if (state.feature.isValid() === false) {
    this.deleteFeature([state.featureId]);
    this.changeMode(Constants.modes.SIMPLE_SELECT, {});
  }
};

EditAreas.onMouseMove = function (state: any, e: any) {
  if (!POPUP.isOpen()) {
    POPUP.addTo(this.map);
  }

  POPUP.setLngLat(e.lngLat);

  // On mousemove that is not a drag, stop vertex movement.
  const isFeature = CommonSelectors.isActiveFeature(e);
  const onVertex = isVertex(e);
  const noCoords = state.selectedCoordPaths.length === 0;

  if (isFeature && noCoords)
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  else if (onVertex && !noCoords)
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  else this.updateUIClasses({ mouse: Constants.cursors.NONE });
  this.stopDragging(state);
};

EditAreas.onMouseOut = function (state: any) {
  // As soon as you mouse leaves the canvas, update the feature
  if (state.dragMoving) this.fireUpdate();
};

EditAreas.onTouchStart = EditAreas.onMouseDown = function (state: any, e: any) {
  if (isVertex(e)) return this.onVertex(state, e);
  if (CommonSelectors.isActiveFeature(e)) return this.onFeature(state, e);
  if (isMidpoint(e)) return this.onMidpoint(state, e);
};

EditAreas.onDrag = function (state: any, e: any) {
  if (state.canDragMove !== true) return;
  state.dragMoving = true;
  e.originalEvent.stopPropagation();

  const delta = {
    lng: e.lngLat.lng - state.dragMoveLocation.lng,
    lat: e.lngLat.lat - state.dragMoveLocation.lat,
  };

  if (state.selectedCoordPaths.length > 0) this.dragVertex(state, e, delta);
  // @todo: I am currently disabling the drag of the features. Enable it in the next release
  // else this.dragFeature(state, e, delta);

  state.dragMoveLocation = e.lngLat;
};

EditAreas.onClick = function (state: any, e: any) {
  if (noTarget(e)) return this.clickNoTarget(state, e);
  if (CommonSelectors.isActiveFeature(e))
    return this.clickActiveFeature(state, e);
  if (isInactiveFeature(e)) return this.clickInactive(state, e);
  this.stopDragging(state);
};

EditAreas.onTap = function (state: any, e: any) {
  if (noTarget(e)) return this.clickNoTarget(state, e);
  if (CommonSelectors.isActiveFeature(e))
    return this.clickActiveFeature(state, e);
  if (isInactiveFeature(e)) return this.clickInactive(state, e);
};

EditAreas.onTouchEnd = EditAreas.onMouseUp = function (state: any) {
  if (state.dragMoving) {
    // TODO: add validation logic here, to ensure all rings in the feature are within it, and remove it, if not.
    this.validateModifiedFeatures(state);
    this.fireUpdate();
  }

  this.stopDragging(state);
};

export default EditAreas;
