import classnames from 'classnames';
import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Input, Typography, Popconfirm } from 'antd';
import { Button } from '../../Button';
import styles from './index.module.scss';
import SkeletonLoader from '../../SkeletonLoader';
import HelpTooltip from '../../HelpTooltip/HelpTooltip';
import LoadingOverlay from '../../LoadingOverlay';
import {
  ProjectVideosCrudStateTypes,
  ProjectVideosCrudPropsTypes,
  ProjectVideosCrudStateProjectVideoDataTypes,
  ProjectVideosFormVideoDataFieldsTypes,
} from './index.types';
import {
  formatDateForDatePicker,
  momentDateNow,
  convertToUtc,
} from '../../../utils/date';
import {
  GenericApisReturnTypes,
  GenericObjectType,
  NullOrGenericObjectType,
} from '../../../shapes/app';
import {
  parseVimeoId,
  parseYoutubeId,
  removeArrayByIndex,
  undefinedOrNull,
  validateUrl,
} from '../../../utils/functs';
import ViewsV2Apis from '../../../api/viewsV2';
import { log } from '../../../utils/log';
import { projectVideosEditPageUrl } from '../../../routes/urls';
import {
  INVALID_VIDEO_URL,
  INPUT_VALID_VIDEO_URL,
  INVALID_FORM_INPUT,
} from '../templates';
import {
  YOUTUBE_EMBED_URL,
  VIMEO_EMBED_URL,
  DOCUMENTATION_URL_LIST,
} from '../../../constants/urls';
import Loading from '../../Loading';
import DocumentationLink from '../../DocumentationLink';

const { Text, Title } = Typography;
const viewsV2Apis = new ViewsV2Apis();

class ProjectVideosCrud extends React.Component<
  ProjectVideosCrudPropsTypes,
  ProjectVideosCrudStateTypes
> {
  private defaultProjectVideoDataList: Readonly<ProjectVideosCrudStateProjectVideoDataTypes>[] =
    [
      {
        provider: null,
        videoId: null,
        name: null,
        description: null,
      },
    ];

  public constructor(props: ProjectVideosCrudPropsTypes) {
    super(props);

    this.state = {
      viewData: null,
      projectVideoData: this.defaultProjectVideoDataList,
      createBtnLoading: false,
      publishBtnLoading: false,
      viewDate: momentDateNow(),
      selectedVideoScreenIndex: 0,
      windowScrollPos: {
        x: 0,
        y: 0,
      },
      isVideoPlayerLoading: false,
      videoPlayerCreateTime: Date.now(),
    };
  }

  public componentDidMount(): void {
    this.fetchViewData();
    this.fetchVideoData();
    this.registerEvents();
  }

  public UNSAFE_componentWillUpdate(
    // eslint-disable-next-line no-empty-pattern
    {},
    {
      selectedVideoScreenIndex: prevSelectedVideoScreenIndex,
    }: Readonly<ProjectVideosCrudStateTypes>
  ): void {
    const { selectedVideoScreenIndex } = this.state;

    if (selectedVideoScreenIndex !== prevSelectedVideoScreenIndex) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({
        isVideoPlayerLoading: true,
        videoPlayerCreateTime: Date.now(),
      });
    }
  }

  public componentDidUpdate({
    match: prevMatch,
    actionType: prevActionType,
  }: Readonly<ProjectVideosCrudPropsTypes>): void {
    const { match, actionType } = this.props;

    const { viewId } = match.params;
    const { prevViewId } = prevMatch.params;

    if (prevViewId !== viewId && actionType !== prevActionType) {
      this.fetchViewData();
      this.fetchVideoData();
    }
  }

  private registerEvents = () => {
    window.addEventListener('scroll', this.handleScrollEvent, false);
  };

  private handleScrollEvent = () => {
    this.setState({
      windowScrollPos: {
        x: window.scrollX,
        y: window.scrollY,
      },
    });
  };

  private fetchViewData = () => {
    const { match, showSnackbar, actionType } = this.props;

    const { projectId, aoiId, viewId } = match.params;

    if (actionType !== 'edit' || undefinedOrNull(viewId)) {
      this.setState({
        viewData: null,
      });

      return;
    }

    viewsV2Apis
      .getView(projectId, aoiId, viewId)
      .then((res: GenericApisReturnTypes) => {
        if (undefinedOrNull(res)) {
          showSnackbar({
            body: `Some error occured. Try again!`,
            type: 'error',
          });

          log.error(
            `Invalid viewsV2Apis getView return data!`,
            `ProjectVideosCrud -> fetchVideoData -> viewsV2Apis -> getView`
          );

          this.setState({
            viewData: null,
          });

          return null;
        }

        if (res.error || !res.data) {
          showSnackbar({
            body: res.error || 'Video fetch error',
            type: 'error',
          });

          log.error(
            res.error,
            `ProjectVideosCrud -> fetchVideoData -> viewsV2Apis -> getView`
          );

          this.setState({
            viewData: null,
          });

          return null;
        }

        this.setState({
          viewData: res.data,
        });

        return res.data;
      });
  };

  private fetchVideoData = () => {
    const { match, showSnackbar, actionType } = this.props;

    const { projectId, aoiId, viewId } = match.params;

    if (actionType !== 'edit' || undefinedOrNull(viewId)) {
      this.setState({
        projectVideoData: this.defaultProjectVideoDataList,
      });

      return;
    }

    viewsV2Apis
      .getViewDescriptor(projectId, aoiId, viewId)
      .then((res: GenericApisReturnTypes) => {
        if (undefinedOrNull(res)) {
          showSnackbar({
            body: `Some error occured. Try again!`,
            type: 'error',
          });

          log.error(
            `Invalid viewsV2Apis getViewDescriptor return data!`,
            `ProjectVideosCrud -> fetchVideoData -> viewsV2Apis -> getViewDescriptor`
          );

          this.setState({
            projectVideoData: this.defaultProjectVideoDataList,
          });

          return null;
        }

        if (
          res.error ||
          !res.data ||
          !res.data.videos ||
          res.data.videos.length < 1
        ) {
          showSnackbar({
            body: res.error || 'Invalid Data',
            type: 'error',
          });

          log.error(
            res.error,
            `ProjectVideosCrud -> fetchVideoData -> viewsV2Apis -> getViewDescriptor`
          );

          this.setState({
            projectVideoData: this.defaultProjectVideoDataList,
          });

          return null;
        }

        this.setState({
          projectVideoData: res.data.videos,
        });

        return res.data;
      });
  };

  private heading = (): string => {
    const { actionType } = this.props;

    if (actionType === 'edit') {
      return 'Edit Video';
    }

    return 'New Video';
  };

  private getDefaultViewDate = (): any => {
    const { viewDate, viewData } = this.state;

    if (viewData) {
      return formatDateForDatePicker(viewData.date);
    }

    return formatDateForDatePicker(viewDate);
  };

  private getDefaultViewName = (): any => {
    const { viewData } = this.state;

    if (!viewData) {
      return null;
    }

    return viewData.name;
  };

  private getDefaultVideoName = (): string | null => {
    const { projectVideoData, selectedVideoScreenIndex } = this.state;

    if (!projectVideoData || !projectVideoData[selectedVideoScreenIndex]) {
      return null;
    }

    return projectVideoData[selectedVideoScreenIndex].name;
  };

  private getDefaultVideoDescription = (): string | null => {
    const { projectVideoData, selectedVideoScreenIndex } = this.state;

    if (!projectVideoData || !projectVideoData[selectedVideoScreenIndex]) {
      return null;
    }

    return projectVideoData[selectedVideoScreenIndex].description;
  };

  private getDefaultVideoUrl = (): string | null => {
    const { projectVideoData, selectedVideoScreenIndex } = this.state;

    if (!projectVideoData || !projectVideoData[selectedVideoScreenIndex]) {
      return null;
    }

    switch (projectVideoData[selectedVideoScreenIndex].provider) {
      default:
        return null;
      case 'youtube':
        return `https://youtu.be/${projectVideoData[selectedVideoScreenIndex].videoId}`;
      case 'vimeo':
        return `https://vimeo.com/${projectVideoData[selectedVideoScreenIndex].videoId}`;
    }
  };

  private handleDateChanged = (date: any, dateString: string) => {
    this.setState({ viewDate: dateString });
  };

  private updateProjectVideoData = (
    data: ProjectVideosCrudStateProjectVideoDataTypes
  ) => {
    const { projectVideoData, selectedVideoScreenIndex } = this.state;

    const _projectVideoData: ProjectVideosCrudStateProjectVideoDataTypes[] =
      projectVideoData || [];

    if (_projectVideoData[selectedVideoScreenIndex]) {
      _projectVideoData[selectedVideoScreenIndex] = data;
    } else {
      _projectVideoData.push(data);
    }

    return _projectVideoData;
  };

  private insertVideoUrlData = (
    data: ProjectVideosCrudStateProjectVideoDataTypes
  ) => {
    const { projectVideoData } = this.state;

    const _projectVideoData = [...(projectVideoData || [])];

    _projectVideoData.push(data);

    return _projectVideoData;
  };

  private setProjectVideoData = (
    data: ProjectVideosCrudStateProjectVideoDataTypes[]
  ) => {
    this.setState(() => {
      return {
        projectVideoData: data,
      };
    });
  };

  private handleUrlFieldChange = (value: string): void => {
    const { projectVideoData, selectedVideoScreenIndex } = this.state;

    if (!projectVideoData) {
      return;
    }

    const parsedItem = this.videoUrlParser(value);

    if (undefinedOrNull(parsedItem)) {
      const error: ProjectVideosCrudStateProjectVideoDataTypes = {
        ...projectVideoData[selectedVideoScreenIndex],
        provider: null,
        videoId: null,
      };

      this.setState(() => {
        return {
          isVideoPlayerLoading: true,
          projectVideoData: this.updateProjectVideoData(error),
        };
      });

      return;
    }

    this.setProjectVideoData(this.updateProjectVideoData(parsedItem));
  };

  public videoUrlParser = (
    url: string | null
  ): null | ProjectVideosCrudStateProjectVideoDataTypes => {
    const { projectVideoData, selectedVideoScreenIndex } = this.state;

    if (!projectVideoData || undefinedOrNull(url)) {
      return null;
    }

    if (!validateUrl(url)) {
      return null;
    }

    const youtubeId = parseYoutubeId(url);

    if (youtubeId) {
      return {
        ...projectVideoData[selectedVideoScreenIndex],
        provider: 'youtube',
        videoId: youtubeId,
      };
    }

    const vimeoId = parseVimeoId(url);

    if (vimeoId) {
      return {
        ...projectVideoData[selectedVideoScreenIndex],
        provider: 'vimeo',
        videoId: vimeoId,
      };
    }

    return null;
  };

  private createVideo = async (): Promise<void> => {
    const { match, showSnackbar, history, actionType } = this.props;
    const { projectVideoData, selectedVideoScreenIndex } = this.state;
    const { projectId, aoiId, viewId } = match.params;

    this.setState({
      createBtnLoading: true,
    });

    const validateForm = await this.validateAllFormFields();

    if (
      !validateForm ||
      !projectVideoData ||
      !projectVideoData[selectedVideoScreenIndex]
    ) {
      this.setState({
        createBtnLoading: false,
      });

      return;
    }

    if (!this.videoUrlParser(validateForm.videoUrl)) {
      showSnackbar({
        body: INVALID_VIDEO_URL,
        type: 'error',
      });

      log.error(
        INVALID_VIDEO_URL,
        `ProjectVideosCrud -> createVideo -> validateFields`
      );

      this.setState({
        createBtnLoading: false,
      });

      return;
    }

    // insert the data into the current selectedVideoScreenIndex of the projectVideoData before firing the create or update buttons
    // this has to be done if the user hasnt clicked on "Add", "Next", "Prev" buttons yet.
    const _projectVideoData = this.updateProjectVideoData({
      ...projectVideoData[selectedVideoScreenIndex],
      name: validateForm.videoName,
      description: validateForm.videoDescription,
    });

    await new Promise((resolve) => {
      this.setState(
        {
          projectVideoData: _projectVideoData,
        },
        () => {
          return resolve(true);
        }
      );
    });

    const createdViewData: NullOrGenericObjectType = await new Promise(
      (resolve) => {
        const formData = {
          certifiedForDisplay: false,
          certifiedForMeasurement: false,
          date: convertToUtc(validateForm.viewDate.format()),
          name: validateForm.viewName,
          ...(undefinedOrNull(viewId)
            ? { type: 'video', subType: 'video_default' }
            : {}),
        };

        viewsV2Apis
          .postView(projectId, aoiId, formData, viewId)
          .then((res: GenericApisReturnTypes) => {
            if (undefinedOrNull(res)) {
              showSnackbar({
                body: `Some error occured. Try again!`,
                type: 'error',
              });

              log.error(
                `Invalid viewsV2Apis return data!`,
                `ProjectVideosCrud -> createVideo -> viewsV2Apis -> postView`
              );

              this.setState({
                createBtnLoading: false,
              });

              return resolve(null);
            }

            if (res.error) {
              showSnackbar({
                body: res.error,
                type: 'error',
              });

              log.error(
                res.error,
                `ProjectVideosCrud -> createVideo -> viewsV2Apis -> postView`
              );

              this.setState({
                createBtnLoading: false,
              });

              return resolve(null);
            }

            return resolve(res.data);
          });
      }
    );

    if (createdViewData) {
      const formData = {
        videos: projectVideoData,
      };

      viewsV2Apis
        .postViewDescriptor(projectId, aoiId, createdViewData.id, formData)
        .then((res: GenericApisReturnTypes) => {
          if (undefinedOrNull(res)) {
            showSnackbar({
              body: `Some error occured. Try again!`,
              type: 'error',
            });

            log.error(
              `Invalid viewsV2Apis postViewDescriptor return data!`,
              `ProjectVideosCrud -> createVideo -> viewsV2Apis -> postViewDescriptor`
            );

            this.setState({
              createBtnLoading: false,
            });

            return null;
          }

          if (res.error) {
            showSnackbar({
              body: res.error,
              type: 'error',
            });

            log.error(
              res.error,
              `ProjectVideosCrud -> createVideo -> viewsV2Apis -> postViewDescriptor`
            );

            this.setState({
              createBtnLoading: false,
            });

            return null;
          }

          this.setState({
            createBtnLoading: false,
          });

          if (actionType === 'new') {
            history.push(
              projectVideosEditPageUrl(projectId, aoiId, createdViewData.id)
            );
          }

          return res.data;
        });
    }
  };

  private publishVideo = (): void => {
    const { match, showSnackbar } = this.props;
    const { viewData } = this.state;
    const { projectId, aoiId, viewId } = match.params;

    const formData = {
      certifiedForDisplay: !viewData || !viewData.certifiedForDisplay,
    };

    this.setState({
      publishBtnLoading: true,
    });

    viewsV2Apis
      .updateView(projectId, aoiId, viewId, formData)
      .then((res: GenericApisReturnTypes) => {
        if (undefinedOrNull(res)) {
          showSnackbar({
            body: `Some error occured. Try again!`,
            type: 'error',
          });

          log.error(
            `Invalid viewsV2Apis return data!`,
            `ProjectVideosCrud -> publishVideo -> viewsV2Apis -> updateView`
          );

          this.setState({
            publishBtnLoading: false,
          });
        }

        if (res.error) {
          showSnackbar({
            body: res.error,
            type: 'error',
          });

          log.error(
            res.error,
            `ProjectVideosCrud -> createVideo -> viewsV2Apis -> updateView`
          );

          this.setState({
            publishBtnLoading: false,
          });
        }

        this.setState(
          () => {
            return {
              publishBtnLoading: false,
            };
          },
          () => {
            this.fetchViewData();
          }
        );
      });
  };

  private getFieldId = (
    fieldName: ProjectVideosFormVideoDataFieldsTypes,
    index?: number
  ): string => {
    const { selectedVideoScreenIndex } = this.state;

    if (!undefinedOrNull(index)) {
      return `${fieldName}-${index}`;
    }

    return `${fieldName}-${selectedVideoScreenIndex}`;
  };

  private validateAllFormFields = async (): Promise<null | {
    viewDate: GenericObjectType;
    viewName: string | null;
    videoName: string | null;
    videoUrl: string | null;
    videoDescription: string | null;
  }> => {
    const { form } = this.props;

    return await new Promise((resolve) => {
      form.validateFields(
        [
          'viewDate',
          'viewName',
          this.getFieldId('videoDescription'),
          this.getFieldId('videoName'),
          this.getFieldId('videoUrl'),
        ],
        (err: any, value: GenericObjectType) => {
          if (err) {
            return resolve(null);
          }

          const videoUrl = value[this.getFieldId('videoUrl')];

          return resolve({
            viewDate: value.viewDate,
            viewName: value.viewName,
            videoName: value[this.getFieldId('videoName')],
            videoDescription: value[this.getFieldId('videoDescription')],
            videoUrl,
          });
        }
      );
    });
  };

  private validateAllVideoDataFormFields = async (): Promise<null | {
    videoDescription: string | null;
    videoName: string | null;
    videoUrl: string | null;
  }> => {
    const { form } = this.props;

    return await new Promise((resolve) => {
      form.validateFields(
        [
          this.getFieldId('videoDescription'),
          this.getFieldId('videoName'),
          this.getFieldId('videoUrl'),
        ],
        (err: any, value: GenericObjectType) => {
          if (err) {
            return resolve(null);
          }

          const videoUrl = value[this.getFieldId('videoUrl')];

          return resolve({
            videoName: value[this.getFieldId('videoName')],
            videoDescription: value[this.getFieldId('videoDescription')],
            videoUrl,
          });
        }
      );
    });
  };

  private addNewVideoScreen = async (): Promise<void> => {
    const { showSnackbar } = this.props;
    const { projectVideoData, selectedVideoScreenIndex } = this.state;

    if (!projectVideoData) {
      return;
    }

    const validateForm = await this.validateAllVideoDataFormFields();

    if (!validateForm) {
      showSnackbar({
        body: INVALID_FORM_INPUT,
        type: 'error',
      });

      return;
    }

    if (!this.videoUrlParser(validateForm.videoUrl)) {
      showSnackbar({
        body: INVALID_VIDEO_URL,
        type: 'error',
      });

      return;
    }

    let _projectVideoData = this.updateProjectVideoData({
      ...projectVideoData[selectedVideoScreenIndex],
      name: validateForm.videoName,
      description: validateForm.videoDescription,
    });

    _projectVideoData = this.insertVideoUrlData({
      provider: null,
      videoId: null,
      name: null,
      description: null,
    });

    this.setState(() => {
      return {
        projectVideoData: _projectVideoData,
        selectedVideoScreenIndex: _projectVideoData
          ? _projectVideoData.length - 1
          : 0,
      };
    });
  };

  private deleteVideoScreen = () => {
    const { selectedVideoScreenIndex, projectVideoData } = this.state;

    if (!projectVideoData || projectVideoData.length < 2) {
      return;
    }

    let nextSelectedVideoScreenIndex = selectedVideoScreenIndex + 1;

    if (projectVideoData.length >= selectedVideoScreenIndex) {
      nextSelectedVideoScreenIndex =
        selectedVideoScreenIndex - 1 < 0 ? 0 : selectedVideoScreenIndex - 1;
    }

    this.setState(() => {
      return {
        projectVideoData: removeArrayByIndex(
          projectVideoData,
          selectedVideoScreenIndex
        ),
        selectedVideoScreenIndex: nextSelectedVideoScreenIndex,
      };
    });
  };

  private prevVideoScreen = async () => {
    const { showSnackbar } = this.props;
    const { selectedVideoScreenIndex, projectVideoData } = this.state;

    const validateForm = await this.validateAllVideoDataFormFields();

    if (!validateForm) {
      showSnackbar({
        body: INVALID_FORM_INPUT,
        type: 'error',
      });

      return;
    }

    if (!this.videoUrlParser(validateForm.videoUrl)) {
      showSnackbar({
        body: INVALID_VIDEO_URL,
        type: 'error',
      });

      return;
    }

    if (this.disablePrevNavBtn()) {
      return;
    }

    const _projectVideoData = this.updateProjectVideoData({
      ...projectVideoData[selectedVideoScreenIndex],
      name: validateForm.videoName,
      description: validateForm.videoDescription,
    });

    this.setState({
      projectVideoData: _projectVideoData,
      selectedVideoScreenIndex: selectedVideoScreenIndex - 1,
    });
  };

  private nextVideoScreen = async () => {
    const { showSnackbar } = this.props;
    const { selectedVideoScreenIndex, projectVideoData } = this.state;

    const validateForm = await this.validateAllVideoDataFormFields();

    if (!validateForm) {
      showSnackbar({
        body: INVALID_FORM_INPUT,
        type: 'error',
      });

      return;
    }

    if (!this.videoUrlParser(validateForm.videoUrl)) {
      showSnackbar({
        body: INVALID_VIDEO_URL,
        type: 'error',
      });

      return;
    }

    if (this.disableNextNavBtn()) {
      return;
    }

    const _projectVideoData = this.updateProjectVideoData({
      ...projectVideoData[selectedVideoScreenIndex],
      name: validateForm.videoName,
      description: validateForm.videoDescription,
    });

    this.setState({
      projectVideoData: _projectVideoData,
      selectedVideoScreenIndex: selectedVideoScreenIndex + 1,
    });
  };

  private surveyDateHelpText = (): string => {
    return 'Select a video date';
  };

  private submitActionBtnName = (): string => {
    const { actionType } = this.props;

    if (actionType === 'edit') {
      return 'UPDATE VIDEO(S)';
    }

    return 'ADD NEW VIDEO(S)';
  };

  private publishActionBtnName = (): string => {
    const { viewData } = this.state;

    if (!viewData || !viewData.certifiedForDisplay) {
      return 'PUBLISH VIDEO(S)';
    }

    return 'UNPUBLISH VIDEO(S)';
  };

  private publishActionBtnPopConfirmText = (): string => {
    const { viewData } = this.state;

    if (!viewData || !viewData.certifiedForDisplay) {
      return 'Are you sure want to publish this video?';
    }

    return 'Are you sure want to unpublish this video?';
  };

  private publishBtnLoadingText = (): string => {
    const { viewData } = this.state;

    if (!viewData || !viewData.certifiedForDisplay) {
      return 'PUBLISHING...';
    }

    return 'UNPUBLISHING...';
  };

  private disableFormFields = (): boolean => {
    const { viewData } = this.state;

    return !!(viewData && viewData.certifiedForDisplay);
  };

  private disableNextNavBtn = (): boolean => {
    const { viewData, projectVideoData, selectedVideoScreenIndex } = this.state;

    return (
      !viewData ||
      !projectVideoData ||
      projectVideoData.length <= selectedVideoScreenIndex + 1
    );
  };

  private disablePrevNavBtn = (): boolean => {
    const { viewData, projectVideoData, selectedVideoScreenIndex } = this.state;

    return !viewData || !projectVideoData || selectedVideoScreenIndex < 1;
  };

  private disablePublishBtn = (): boolean => {
    const { viewData } = this.state;

    return !viewData;
  };

  private viewContainerPos = () => {
    const { windowScrollPos } = this.state;

    const minScroll = 20;
    const maxScroll = 232;
    let paddingTop = minScroll;

    if (windowScrollPos.y > maxScroll) {
      paddingTop = maxScroll;
    } else if (windowScrollPos.y > minScroll) {
      paddingTop = windowScrollPos.y;
    }

    return paddingTop;
  };

  private handleVideoPlayerLoaded = () => {
    this.setState({
      isVideoPlayerLoading: false,
    });
  };

  public render(): React.ReactNode {
    const { form, actionType } = this.props;
    const {
      projectVideoData,
      viewData,
      createBtnLoading,
      publishBtnLoading,
      selectedVideoScreenIndex,
      isVideoPlayerLoading,
      videoPlayerCreateTime,
    } = this.state;

    const { getFieldDecorator } = form;

    if (!viewData && actionType === 'edit') {
      return (
        <div className={styles.container}>
          <SkeletonLoader className={styles.skeletonLoader} size={2} />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.headingWrapper}>
          <Title level={3}>{this.heading()}</Title>
          <DocumentationLink
            href={DOCUMENTATION_URL_LIST.videos}
            toolTipPosition="right"
          />
        </div>

        <div className={styles.innerContainer}>
          <div className={styles.inputFieldContainer}>
            <Form layout="vertical">
              <div className={styles.inputFieldWrapper}>
                <Form.Item label="View Date">
                  {getFieldDecorator('viewDate', {
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                    initialValue: this.getDefaultViewDate(),
                  })(
                    <DatePicker
                      allowClear={false}
                      placeholder="Select Date"
                      size="small"
                      onChange={this.handleDateChanged}
                      className={styles.inputField}
                      dropdownClassName="antCalendar"
                      disabled={this.disableFormFields()}
                    />
                  )}
                </Form.Item>
                <HelpTooltip
                  position="right"
                  helpText={this.surveyDateHelpText()}
                />
              </div>

              <div className={styles.inputFieldWrapper}>
                <Form.Item label="View Name">
                  {getFieldDecorator('viewName', {
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                    initialValue: this.getDefaultViewName(),
                  })(
                    <Input
                      placeholder="View Name"
                      style={{ margin: 0 }}
                      className={styles.inputField}
                      disabled={this.disableFormFields()}
                    />
                  )}
                </Form.Item>
                <HelpTooltip
                  position="right"
                  helpText="Name given to the group of these videos and will be used only in the manage session"
                />
              </div>

              <div className={styles.swipeableContainer}>
                <div className={styles.actionBtnWrapper}>
                  <button
                    onClick={() => {
                      this.addNewVideoScreen();
                    }}
                    disabled={this.disableFormFields()}
                  >
                    <i className="fa fa-plus" />
                  </button>
                  {!this.disableFormFields() && projectVideoData.length > 1 ? (
                    <Popconfirm
                      title="Are you sure delete this video?"
                      onConfirm={this.deleteVideoScreen}
                      okText="Yes"
                      cancelText="Cancel"
                    >
                      <button
                        disabled={
                          this.disableFormFields() ||
                          projectVideoData.length < 2
                        }
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </Popconfirm>
                  ) : (
                    <button disabled>
                      <i className="fa fa-trash" />
                    </button>
                  )}
                </div>

                <div className={styles.swipeableOuterWrapper}>
                  <div className={classnames(styles.navBtn, styles.leftBtn)}>
                    <button
                      onClick={() => {
                        this.prevVideoScreen();
                      }}
                      disabled={this.disablePrevNavBtn()}
                    >
                      <i className="fa fa-chevron-left" />
                    </button>
                  </div>
                  <div className={classnames(styles.navBtn, styles.rightBtn)}>
                    <button
                      onClick={() => {
                        this.nextVideoScreen();
                      }}
                      disabled={this.disableNextNavBtn()}
                    >
                      <i className="fa fa-chevron-right" />
                    </button>
                  </div>

                  {(
                    (projectVideoData ||
                      []) as ProjectVideosCrudStateProjectVideoDataTypes[]
                  ).map((a, index: number) => {
                    return (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={classnames(styles.swipeableInnerWrapper, {
                          [styles.visible]: selectedVideoScreenIndex === index,
                        })}
                      >
                        <div className={styles.screenNameWrapper}>
                          <Text>{`Video #${index + 1}`}</Text>
                        </div>

                        <div className={styles.inputFieldWrapper}>
                          <Form.Item label="Name">
                            {getFieldDecorator(
                              this.getFieldId('videoName', index),
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ],
                                initialValue: this.getDefaultVideoName(),
                              }
                            )(
                              <Input
                                placeholder="Name"
                                style={{ margin: 0 }}
                                className={styles.inputField}
                                disabled={this.disableFormFields()}
                              />
                            )}
                          </Form.Item>
                          <HelpTooltip
                            position="right"
                            helpText="Name to be used for public display."
                          />
                        </div>

                        <div className={styles.inputFieldWrapper}>
                          <Form.Item label="Description">
                            {getFieldDecorator(
                              this.getFieldId('videoDescription', index),
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ],
                                initialValue: this.getDefaultVideoDescription(),
                              }
                            )(
                              <Input
                                placeholder="Description"
                                style={{ margin: 0 }}
                                className={styles.inputField}
                                disabled={this.disableFormFields()}
                              />
                            )}
                          </Form.Item>
                          <HelpTooltip
                            position="right"
                            helpText="Description about the video."
                          />
                        </div>

                        <div className={styles.inputFieldWrapper}>
                          <Form.Item label="Video URL">
                            {getFieldDecorator(
                              this.getFieldId('videoUrl', index),
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ],
                                initialValue: this.getDefaultVideoUrl(),
                                onChange: (
                                  e: React.ChangeEvent<HTMLSelectElement>
                                ) => this.handleUrlFieldChange(e.target.value),
                              }
                            )(
                              <Input
                                placeholder="Youtube or Vimeo URL"
                                style={{ margin: 0 }}
                                className={styles.inputField}
                                disabled={this.disableFormFields()}
                              />
                            )}
                          </Form.Item>
                          <HelpTooltip
                            position="right"
                            helpText="Youtube and Vimeo URLs are allowed."
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className={styles.btnWrapper}>
                <Button
                  text={this.submitActionBtnName()}
                  onClick={() => {
                    this.createVideo();
                  }}
                  loadingText="Submitting..."
                  loading={createBtnLoading}
                  className={styles.generateBtn}
                  disabled={this.disableFormFields()}
                />

                {!this.disablePublishBtn() ? (
                  <Popconfirm
                    title={this.publishActionBtnPopConfirmText()}
                    onConfirm={this.publishVideo}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <Button
                      text={this.publishActionBtnName()}
                      loadingText={this.publishBtnLoadingText()}
                      loading={publishBtnLoading}
                      className={styles.finalizeBtn}
                      disabled={this.disablePublishBtn()}
                    />
                  </Popconfirm>
                ) : (
                  <Button
                    text={this.publishActionBtnName()}
                    loadingText={this.publishBtnLoadingText()}
                    loading={publishBtnLoading}
                    className={styles.finalizeBtn}
                    disabled
                  />
                )}
              </div>
            </Form>
          </div>

          <div
            className={classnames(styles.viewContainer)}
            style={{
              paddingTop: this.viewContainerPos(),
            }}
          >
            {!projectVideoData ? (
              <div className={styles.alertWrapper}>
                <Text>{INPUT_VALID_VIDEO_URL}</Text>
              </div>
            ) : !projectVideoData[selectedVideoScreenIndex].provider ||
              !projectVideoData[selectedVideoScreenIndex].videoId ? (
              <div className={styles.alertWrapper}>
                <Text>{INPUT_VALID_VIDEO_URL}</Text>
              </div>
            ) : projectVideoData[selectedVideoScreenIndex].provider ===
              'youtube' ? (
              <div className={styles.videoContainer}>
                <div
                  className={classnames({
                    hide: !isVideoPlayerLoading,
                    [styles.loading]: true,
                  })}
                >
                  <Loading type="ellipsis" />
                </div>
                <iframe
                  key={videoPlayerCreateTime}
                  onLoad={() => {
                    this.handleVideoPlayerLoaded();
                  }}
                  title="youtube"
                  width="853"
                  height="480"
                  src={`${YOUTUBE_EMBED_URL}/${projectVideoData[selectedVideoScreenIndex].videoId}`}
                  className={classnames({
                    hide: isVideoPlayerLoading,
                  })}
                  allowFullScreen
                  frameBorder="0"
                />
              </div>
            ) : projectVideoData[selectedVideoScreenIndex].provider ===
              'vimeo' ? (
              <div className={styles.videoContainer}>
                <div
                  className={classnames({
                    hide: !isVideoPlayerLoading,
                    [styles.loading]: true,
                  })}
                >
                  <Loading type="ellipsis" />
                </div>
                <iframe
                  key={videoPlayerCreateTime}
                  title="vimeo"
                  onLoad={() => {
                    this.handleVideoPlayerLoaded();
                  }}
                  src={`${VIMEO_EMBED_URL}/${projectVideoData[selectedVideoScreenIndex].videoId}`}
                  className={classnames({
                    hide: isVideoPlayerLoading,
                  })}
                  width="853"
                  height="480"
                  allowFullScreen
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                />
              </div>
            ) : null}
          </div>
        </div>

        {createBtnLoading || publishBtnLoading ? <LoadingOverlay /> : null}
      </div>
    );
  }
}

export default Form.create()(ProjectVideosCrud);
