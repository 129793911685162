import { GoogleApiWrapper, Map, Marker, Polygon } from 'google-maps-react';
import * as React from 'react';
import { getGoogleMapKey } from 'src/utils/functs';
import { GOOGLE_MAP_MARKER_DEFAULT_COLOR } from 'src/constants/colors';
import { BASE_CAPI_URL } from 'src/constants/urls';
import styles from './BoundaryMap.module.scss';
import SkeletonLoader from '../SkeletonLoader';

interface IProps {
  projectId: string;
  aoiId: string;
  style?: any;
  className?: string;
  points?: any[];
  google: any;
  boundary?: any;
  addPoint?: any;
  getBoundary?: any;
  synBoundaryOnce?: boolean;
  imagePoints?: any[];
  markerCurrentIndex?: number;
  onMarkerClick?: (index: number) => void;
  onLoadBoundaryUpdate?: (boundary: any) => void;
}
interface IState {
  boundaryPoints: any;
  map: any;
  boundary: any;
  boundsSynced: boolean;
}

class BoundaryMap extends React.PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      boundaryPoints: null,
      map: null,
      boundary: null,
      boundsSynced: false,
    };
  }

  public componentDidMount(): void {
    const { projectId, aoiId, boundary, onLoadBoundaryUpdate } = this.props;

    if (boundary) {
      this.setState({ boundaryPoints: this.getBoundaryPoints(boundary) });
    } else {
      fetch(
        `${BASE_CAPI_URL}/v2/projects/${projectId}/aois/${aoiId}/boundary`,
        {
          method: 'GET',
          credentials: 'include',
        }
      )
        .then((x) => x.json())
        .then((boundaryRes) => {
          if (onLoadBoundaryUpdate) {
            onLoadBoundaryUpdate(boundaryRes);
          }

          this.setState({
            boundaryPoints: this.getBoundaryPoints(boundaryRes),
            boundary: boundaryRes,
          });
        })
        .catch(() => {
          /**/
        });
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: any): void | boolean {
    const { points } = this.props;
    const { map } = this.state;

    if (nextProps) {
      if (points) {
        points.splice(0, points.length);
        // eslint-disable-next-line no-restricted-syntax
        for (const p of nextProps.points || []) {
          points.push(p);
        }
      }
    }

    if (this.state && map) {
      this.syncBoundary(map);
    }

    return true;
  }

  private describeCircle(radius: number): string {
    return (
      `M ${-radius},0a${radius},${radius} 0 1,0 ${radius * 2},0` +
      `a${radius},${radius} 0 1,0 ${-radius * 2},0 Z`
    );
  }

  private onMapLoaded = (_: any, map: any) => {
    const { getBoundary } = this.props;
    const { boundary } = this.state;

    this.syncBoundary(map);
    if (!map) {
      this.setState({ map });
    }

    if (getBoundary) {
      getBoundary(boundary);
    }
  };

  private onPolygonClick = (coord: any) => {
    const { addPoint } = this.props;

    if (addPoint) {
      const { latLng } = coord;
      const lat = latLng.lat();
      const lng = latLng.lng();

      addPoint(lat, lng);
    }
  };

  private syncBoundary(map: any): void | null {
    const { synBoundaryOnce, google, points } = this.props;
    const { boundsSynced, boundaryPoints } = this.state;

    if (synBoundaryOnce && boundsSynced) {
      return null;
    }

    if (map) {
      const bounds = new google.maps.LatLngBounds();

      // eslint-disable-next-line no-restricted-syntax
      for (const point of points || []) {
        bounds.extend({ lat: point.latitude, lng: point.longitude });
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const point of boundaryPoints || []) {
        bounds.extend({ lat: point.lat, lng: point.lng });
      }

      map.fitBounds(bounds);
      this.setState({ boundsSynced: true });
    }
  }

  private getBoundaryPoints = (boundary: any) => {
    if (boundary && boundary.features) {
      const features = boundary.features.filter((f: any) => {
        return f && f.geometry && f.geometry.type === 'Polygon';
      });

      if (features.length > 0) {
        const pointsArr = features[0].geometry.coordinates;

        return (pointsArr || [[]])[0].map((p: any) => {
          return {
            lat: p[1],
            lng: p[0],
          };
        });
      }
    }

    return [];
  };

  public render(): React.ReactNode {
    const { boundaryPoints } = this.state;
    const {
      className,
      google,
      style,
      imagePoints,
      markerCurrentIndex,
      onMarkerClick,
      children,
    } = this.props;

    if (!boundaryPoints) {
      return (
        <SkeletonLoader
          size={1}
          position="absolute"
          className={styles.skeletonLoading}
        />
      );
    }

    return (
      <Map
        google={google}
        initialCenter={{
          lat: 0,
          lng: 0,
        }}
        onReady={this.onMapLoaded}
      >
        <Polygon
          paths={boundaryPoints}
          onClick={(t: any, map: any, coord: any) => {
            this.onPolygonClick(coord);
          }}
          strokeColor="#FF0000"
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor="#FF0000"
          fillOpacity={0.15}
          style={
            style
              ? { position: 'relative', ...style }
              : { position: 'relative' }
          }
          className={className || ''}
        />
        {imagePoints
          ? imagePoints.map(
              (position: { lng: number; lat: number }, index: number) => {
                return (
                  <Marker
                    key={`${position.lng}-${position.lat}`}
                    position={position}
                    zIndex={markerCurrentIndex === index ? 110 : 100}
                    onClick={() => {
                      if (onMarkerClick) {
                        onMarkerClick(index);
                      }
                    }}
                    icon={{
                      path: this.describeCircle(2),
                      scale: 3,
                      fillColor:
                        markerCurrentIndex === index
                          ? '#F00'
                          : GOOGLE_MAP_MARKER_DEFAULT_COLOR,
                      fillOpacity: 0.8,
                      strokeWeight: 0.2,
                    }}
                  />
                );
              }
            )
          : children}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: getGoogleMapKey(),
  libraries: ['places', 'drawing'],
})(BoundaryMap);
