// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditShapeControlBox_container__B4Yh3{padding:8px}.EditShapeControlBox_padTop__M2eq3{padding-top:16px}", "",{"version":3,"sources":["webpack://./src/components/View/ViewControls/MapMakerControls/EditShapeControlBox/index.module.scss"],"names":[],"mappings":"AAAA,sCACE,WAAA,CAGF,mCACE,gBAAA","sourcesContent":[".container {\n  padding: 8px;\n}\n\n.padTop {\n  padding-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EditShapeControlBox_container__B4Yh3",
	"padTop": "EditShapeControlBox_padTop__M2eq3"
};
export default ___CSS_LOADER_EXPORT___;
