import VectorLayer from 'ol/layer/Vector';
import React, { useEffect, useMemo, useState } from 'react';
import MapStyleAPIs from 'src/api/mapStyle';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import { useMapStyleContext } from 'src/components/Mission/MapMaker/hooks';
import { toGeojsonFile } from 'src/components/Mission/MapMaker/utils';
import SkeletonLoader from 'src/components/SkeletonLoader';
import { View } from 'ol';
import classnames from 'classnames';
import MapView from 'src/components/View/MapView';
import ViewControls from 'src/components/View/ViewControls';
import {
  RendererState,
  SelectLayerEvent,
  UpdateLayerData,
  ViewConfig,
  ViewEvent,
} from 'src/components/View/index.types';
import { SnackbarActionsActionShowSnackbarTypes } from 'src/shapes/snackbar';
import style from './index.module.scss';

export interface StylePreviewProps {
  updateKey?: string;
  layers: LayerDescriptor[];
  showSnackbar: SnackbarActionsActionShowSnackbarTypes;
}

const mapStyleApi = new MapStyleAPIs();

/**
 * Preview the style with all its layers
 * Responsibilities:
 *   - Display a preview of the style with all its layers
 *   - Should update dynamically as the layers & styling change
 *   - preview should be consistent with what the end-users finally see
 * @constructor
 */
export const StylePreview: React.FC<StylePreviewProps> = ({
  updateKey,
  layers,
  showSnackbar,
}) => {
  const mapStyle = useMapStyleContext();
  const [visible, setVisible] = useState<boolean>(true);
  const [olView, setOlView] = useState<View>();
  const [rendererState, setRendererState] = useState<RendererState>();
  const [renderFrag, setRenderFrags] = useState<any>();
  const onEvent = useMemo(
    () => async (e: ViewEvent<View>) => {
      if (e.type === 'ol_view_init') {
        const view = e.data;

        setOlView((v) => {
          if (!v) return view;

          return v;
        });
      }

      if (e.type === 'renderer_state_changed') {
        setRendererState(e.data as RendererState);
      }

      if (e.type === 'layer_select') {
        const layer = (e as unknown as SelectLayerEvent).data;
        const { layers } = rendererState || {};

        if (layers) {
          layers.forEach((l) => {
            if (l._layer instanceof VectorLayer) {
              if (l.id !== layer.name) l.setVisible(false);
              else l.setVisible(true);
            }
          });
        }
      }

      if (e.type === 'layer_clear') {
        const { layers } = rendererState || {};

        if (layers) {
          layers.forEach((l) => l.setVisible(true));
        }
      }

      if (e.type === 'layer_edit' && mapStyle) {
        const { projectId, aoiId, id: styleId } = mapStyle.style;
        const { layer, geojsonContents } = e.data as unknown as UpdateLayerData;

        await mapStyleApi
          .editStyleLayer(
            projectId,
            aoiId,
            styleId,
            layer.name,
            {},
            toGeojsonFile(geojsonContents)
          )
          .then(() =>
            showSnackbar({
              body: 'The layer has been saved successfully!',
              type: 'success',
              isModal: false,
            })
          )
          .catch(() => {
            showSnackbar({
              body: 'There was an error while saving the layer.',
              type: 'error',
              isModal: false,
            });
          });
      }
    },
    [mapStyle, rendererState, showSnackbar]
  );

  useEffect(() => {
    setVisible(false);

    const timer = setTimeout(() => {
      setVisible(true);
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, [updateKey]);

  if (!mapStyle?.view || !visible) {
    return (
      <div className={classnames(style.grow, style.center)}>
        <SkeletonLoader />
      </div>
    );
  }

  return (
    <div className={style.grow}>
      <MapView
        view={mapStyle.view}
        olView={olView}
        onEvent={onEvent}
        onConfigCallbackChange={() => {}}
        key={updateKey}
      >
        {renderFrag || <></>}
      </MapView>
      <ViewControls
        className={style.flex}
        projectId={mapStyle.view.projectId}
        aoiId={mapStyle.view.aoiId}
        viewId={mapStyle.view.id}
        view={mapStyle.view}
        items={['add_shape', 'edit_shape', 'remove_shape']}
        rendererState={rendererState}
        onRenderFragChange={(frag, _) => {
          setRenderFrags(frag);
        }}
        getViewConfig={() => {
          return {} as ViewConfig;
        }}
        onEvent={onEvent}
        layers={layers}
      />
    </div>
  );
};
