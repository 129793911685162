// MVT = Mapbox Vector Tiles. Refer: https://openlayers.org/en/v6.0.1/examples/mapbox-vector-tiles.html
import MVT from 'ol/format/MVT';
import TileLayer from 'ol/layer/Tile';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import ImageStyle from 'ol/style/Image';
import XYZ from 'ol/source/XYZ';
import GeoJSON from 'ol/format/GeoJSON';
import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { StyleLike } from 'ol/style/Style';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import { ValueRange } from 'src/components/OpenLayersMap/index.types';
import { asArray as colorAsArray } from 'ol/color';
import { View } from 'src/api/views.types';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import MapboxAPIs from '../../../../api/mapbox';
import {
  MapboxLayer,
  MapboxStyle,
  MapboxTileJSON,
} from '../../../../api/mapbox.types';
import {
  ColorList,
  ELEVATION_DIFF_COLORS,
  THERMAL_COLORS,
} from '../../../../constants/colors';
import { MAPBOX_API_BASE_URL } from '../../../../constants/urls';
import { DEFAULT_LAYER_Z_INDEX } from '../../../../constants';
import { GenericObjectType } from '../../../../shapes/app';
import { RendererLayer } from '../../index.types';
import { DynamicColorSource } from '../../../OpenLayersMap/DynamicColorSource';
import { ClampConfig } from './index.types';
import ViewsV2Apis from '../../../../api/viewsV2';

const mapboxApis = new MapboxAPIs();
const viewV2Apis = new ViewsV2Apis();

export const getMapboxStyle = async (
  styleUrl: string,
  accessToken: string
): Promise<MapboxStyle | null> => {
  return await mapboxApis.getStyle(styleUrl, accessToken);
};

export const getMapboxSource = async (
  tilesetId: string,
  accessToken: string
): Promise<MapboxTileJSON | null> => {
  return await mapboxApis.getTileJSON(tilesetId, accessToken);
};

const getMapboxRasterTileUrl = (
  tilesetId: string,
  accessToken: string
): string => {
  return `${MAPBOX_API_BASE_URL}/v4/${tilesetId}/{z}/{x}/{y}@2x.png?access_token=${accessToken}`;
};

const getMapboxVectorTileUrl = (
  tilesetId: string,
  accessToken: string
): string => {
  return `https://{a-d}.tiles.mapbox.com/v4/${tilesetId}/{z}/{x}/{y}.vector.pbf?access_token=${accessToken}`;
};

export const getMapboxStyleTileUrl = (
  styleUrl: string,
  accessToken: string
) => {
  const styleId = styleUrl.replace('mapbox://styles/', '');

  return `https://api.mapbox.com/styles/v1/${styleId}/tiles/256/{z}/{x}/{y}?access_token=${accessToken}`;
};

export const getRenderLayer = (
  layer: MapboxLayer,
  mapboxStyle: GenericObjectType,
  accessToken: string,
  index: number
): RendererLayer => {
  const sources = mapboxStyle?.sources || {};

  switch (layer.type) {
    case 'raster': {
      // layer.source is only undefined when layer.type is 'background'
      const source = sources[layer.source as string];

      if (!source) {
        console.error('Layer has unknown source', layer.source);
        break;
      }

      let tileUrl: string;

      if (source?.tiles?.length) {
        // eslint-disable-next-line prefer-destructuring
        tileUrl = source.tiles[0];
      } else {
        const sourceId = source?.url?.replace('mapbox://', '');

        if (!sourceId) {
          console.error('Error getting source url.', source);

          break;
        }

        tileUrl = getMapboxRasterTileUrl(sourceId, accessToken);
      }

      const olLayer = new TileLayer({
        source: new XYZ({
          url: tileUrl,
          crossOrigin: 'anonymous',
        }),
        zIndex: DEFAULT_LAYER_Z_INDEX + index,
      });

      let label = layer.id;
      let icon;

      if (mapboxStyle?.metadata) {
        const { metadata } = mapboxStyle;

        if (metadata[layer.id]?.name) {
          label = metadata[layer.id]?.name;
        }

        if (metadata[layer.id]?.image_url) {
          icon = metadata[layer.id]?.image_url;
        }
      }

      return {
        id: layer.id,
        label,
        icon,
        visible: () => {
          return olLayer.getVisible();
        },
        _layer: olLayer,
        _data: layer,
        setVisible: (visible: boolean) => {
          olLayer.setVisible(visible);
        },
      };
    }

    case 'circle':
    case 'fill':
    case 'line': {
      /**
       * For contours, mapbox styles have a separate layer of type 'symbol' which
       * shares a source with the vector contour lines layer, but uses its attributes to render
       */
      const source = sources[layer.source as string];
      const sourceLayer = layer['source-layer'];
      const symbolLayer = mapboxStyle.layers.find(
        (l: any) =>
          l['source-layer'] === sourceLayer &&
          l.id !== layer.id &&
          l.type === 'symbol'
      );

      if (!source.data || !source.url) {
        console.error('Error getting required info from source.', source);

        break;
      }

      const vectorLayers: any[] | undefined = source.data.vector_layers;
      const requiredLayer = vectorLayers?.find((l) => l.id === sourceLayer);

      if (!requiredLayer || !requiredLayer.source) {
        console.error(
          'Error getting required layer from source.',
          requiredLayer
        );

        break;
      }

      /*
       * NOte: all properties of style which can be calculated without the underlying feature are computed outside the styleGen fn
       */
      const color = (layer?.paint || {})[`${layer.type}-color`] || '#ff0000';
      const strokeWidth = layer?.paint?.['line-width'] || 0.5;
      const style = new Style();
      let image: ImageStyle | undefined;
      let fill: Fill | undefined;

      if (layer.type === 'circle') {
        try {
          image = new CircleStyle({
            radius: 4,
            fill: color
              ? new Fill({
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  color: normalizeColor(color),
                })
              : undefined,
          });
        } catch (ex) {
          console.error('incorrect circle style', color, ex);
        }
      }

      if (layer.type === 'fill' && color) {
        fill = new Fill({ color });
      }

      if (layer.type === 'fill') {
        fill = new Fill({ color });
      }

      if (image) {
        style.setImage(image);
      }

      if (fill) {
        style.setFill(fill);
      }

      style.setStroke(
        new Stroke({
          color,
          width: strokeWidth,
        })
      );

      const styleGen = (feature: any, _resolution: any) => {
        // label/text needs actual feature to compute, so do it everytime this fn is called
        let text: Text | undefined;

        if (symbolLayer?.layout && symbolLayer?.layout['text-field']) {
          const layout = symbolLayer?.layout['text-field'];
          let label: string | undefined;

          if (typeof layout === 'string' || layout instanceof String) {
            const fieldName = (layout as string)
              .replace('{', '')
              .replace('}', '');

            label = (feature?.properties_ || {})[fieldName];
          } else if (layout instanceof Array && layout.length > 1) {
            // this is an array like ["<operation (e.g. to-string)>", ["<data op, e.g. get>", "<field name, e.g. ELEV>"]]
            if (
              layout[0] === 'to-string' &&
              layout[1] instanceof Array &&
              layout[1].length > 1 &&
              layout[1][0] === 'get'
            ) {
              const fieldName = layout[1][1];

              label = (feature?.properties_ || {})[fieldName];
            }
          }

          if (label) {
            text = new Text({
              text: `${label}`,
              scale: 1.5,
              placement: symbolLayer.layout['symbol-placement'],
              stroke: new Stroke({
                color: symbolLayer.paint['text-color'],
              }),
            });
          }
        }

        if (text) {
          style.setText(text);
        }

        return style;
      };

      const olLayer = new VectorTileLayer({
        declutter: true,
        source: new VectorTileSource({
          format: new MVT(),
          url: getMapboxVectorTileUrl(requiredLayer.source, accessToken),
        }),
        style: styleGen,
        zIndex: DEFAULT_LAYER_Z_INDEX + index,
      });

      let label = layer.id;
      let icon;

      if (mapboxStyle?.metadata) {
        const { metadata } = mapboxStyle;

        if (metadata[layer.id]?.name) {
          label = metadata[layer.id]?.name;
        }

        if (metadata[layer.id]?.image_url) {
          icon = metadata[layer.id]?.image_url;
        }
      }

      return {
        id: layer.id,
        label,
        icon,
        visible: () => {
          return olLayer.getVisible();
        },
        _layer: olLayer,
        _data: layer,
        setVisible: (visible: boolean) => {
          olLayer.setVisible(visible);
        },
      };
    }

    // handling unsupported layers
    default: {
      console.error('Unsupported mapbox layer type!', layer.type);

      break;
    }
  }

  return {} as RendererLayer;
};

const normalizeColor = (colorStr: string) => {
  if (colorStr.startsWith('hsl(')) {
    const sep = colorStr.indexOf(',') > -1 ? ',' : ' ';

    const hsl = colorStr.substr(4).split(')')[0].split(sep);

    const h = parseFloat(hsl[0]);
    const s = parseFloat(hsl[1].substr(0, hsl[1].length - 1));
    const l = parseFloat(hsl[2].substr(0, hsl[2].length - 1));

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return hslToRgb(h, s, l).toUpperCase();
  }

  if (colorStr.startsWith('hsla(')) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return HSLAToRGBA(colorStr);
  }

  return colorStr;
};

const toHex = (x: any) => {
  const hex = Math.round(x * 255).toString(16);

  return hex.length === 1 ? `0${hex}` : hex;
};

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {string}          The hex representation
 */
const hslToRgbValues = (h: any, s: any, l: any) => {
  // eslint-disable-next-line no-param-reassign
  h /= 360;
  // eslint-disable-next-line no-param-reassign
  s /= 100;
  // eslint-disable-next-line no-param-reassign
  l /= 100;

  let r;
  let g;
  let b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: any, q: any, t: any) => {
      // eslint-disable-next-line no-param-reassign
      if (t < 0) t += 1;
      // eslint-disable-next-line no-param-reassign
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;

      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [r, g, b];
};

const hslToRgb = (h: any, s: any, l: any) => {
  const [r, g, b] = hslToRgbValues(h, s, l);

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

const HSLAToRGBA = (hslaStr: string) => {
  const sep = hslaStr.indexOf(',') > -1 ? ',' : ' ';

  const hsla = hslaStr.substr(5).split(')')[0].split(sep);

  if (hsla.indexOf('/') > -1) hsla.splice(3, 1);

  let h: any = hsla[0];
  const s = parseFloat(hsla[1].substr(0, hsla[1].length - 1));
  const l = parseFloat(hsla[2].substr(0, hsla[2].length - 1));
  const a = parseFloat(hsla[3]);

  if (h.indexOf('deg') > -1) h = h.substr(0, h.length - 3);
  else if (h.indexOf('rad') > -1)
    h = Math.round(h.substr(0, h.length - 3) * (180 / Math.PI));
  else if (h.indexOf('turn') > -1)
    h = Math.round(h.substr(0, h.length - 4) * 360);
  if (h >= 360) h %= 360;

  const [r, g, b] = hslToRgbValues(h, s, l);

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const getViewRendererLayers = async (
  view: View,
  getValueRange: () => ValueRange
): Promise<RendererLayer[]> => {
  if (!view.sourceUrl) {
    return [];
  }

  if (view.sourceUrl.startsWith('vimana://')) {
    return getVimanaViewLayers(view, getValueRange);
  }

  if (view.sourceToken) {
    return getRendererLayersFromMapboxStyle(
      view.sourceUrl,
      view.sourceToken || ''
    );
  }

  return [];
};

export const getVimanaViewLayers = async (
  view: View,
  getValueRange: () => ValueRange
): Promise<RendererLayer[]> => {
  const rendererLayers: RendererLayer[] = [];
  const { error, data: vimanaMapDescriptor } =
    await new ViewsV2Apis().getViewFiles(view.id, 'descriptor.json');

  if (!error && vimanaMapDescriptor) {
    const desc = vimanaMapDescriptor;
    let idx = 0;

    // eslint-disable-next-line
    for (const l of desc?.layers || []) {
      idx += 1;
      const parts = l.url.replace('vimana://', '').split('/');
      const vid = parts[1];
      const url = new ViewsV2Apis().getViewFileUrl(vid);

      switch (l.type) {
        case 'raster': {
          const { style } = l as LayerDescriptor;

          if (l.raster_type === 'single_channel') {
            // eslint-disable-next-line
            const descriptor = await getVimanaViewDescriptor(vid);
            const layers = getElevationDifferenceLayers(
              view.id,
              descriptor,
              getValueRange
            );

            if (layers) {
              rendererLayers?.push(layers[0]);
            }
          } else {
            const layer = new TileLayer({
              source: new XYZ({
                url: `${url}/{z}/{x}/{-y}.png`,
                crossOrigin: 'anonymous',
              }),
              zIndex: DEFAULT_LAYER_Z_INDEX + (style?.z_index || idx),
            });

            rendererLayers?.push({
              id: l.id || l.name || `{idx}`,
              visible: () => {
                return layer.getVisible();
              },
              label: l.name || '',
              _layer: layer,
              setVisible: (visible: boolean) => {
                layer.setVisible(visible);
              },
            });
          }

          break;
        }

        case 'vector': {
          const file = parts[2];
          // eslint-disable-next-line
          const contents = await new ViewsV2Apis().getViewFiles(vid, file);
          const vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(contents.data, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857',
            }),
          });

          const { style } = l as LayerDescriptor;

          const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: getVectorLayerStyle(l),
            zIndex: DEFAULT_LAYER_Z_INDEX + (style?.z_index || idx),
          });

          vectorLayer.setOpacity(0.5);

          // eslint-disable-next-line
          rendererLayers?.push({
            id: l.id || l.name || 'Contours',
            visible: () => {
              return vectorLayer.getVisible();
            },
            _data: style?.fill_color
              ? { paint: { color: style.fill_color } }
              : {},
            label: l.name || '',
            setVisible: (visible: boolean) => {
              vectorLayer.setVisible(visible);
            },
            _layer: vectorLayer,
          });
          break;
        }

        case 'vector_tiles': {
          const { style } = l as LayerDescriptor;

          const vectorLayer = new VectorTileLayer({
            declutter: false,
            source: new VectorTileSource({
              format: new MVT({
                defaultDataProjection: 'EPSG:4326',
              } as any),
              url: `${url}/vector_tiles/{z}/{x}/{y}.pbf`,
            }),
            style: getVectorLayerStyle(l),
            zIndex: DEFAULT_LAYER_Z_INDEX + (style?.z_index || idx),
          });

          vectorLayer.setOpacity(0.5);

          // eslint-disable-next-line
          rendererLayers?.push({
            id: l.id || l.name || 'Contours',
            visible: () => {
              return vectorLayer.getVisible();
            },
            label: l.name || '',
            setVisible: (visible: boolean) => {
              vectorLayer.setVisible(visible);
            },
            _layer: vectorLayer,
            _data: style?.fill_color
              ? { paint: { color: style.fill_color } }
              : {},
          });
          break;
        }

        default: {
          break;
        }
      }
    }
  } else if (
    view.subType === 'elevation_difference' ||
    view.subType === 'elevation'
  ) {
    // vimana:// elevation and elevation_difference maps
    // are raw dems which need a dexcriptor to render.
    // so get the descriptor first
    const descriptor = await getVimanaViewDescriptor(view.id);

    if (!descriptor) {
      console.error('Could not fetch view descriptor for view:', view.id);
    } else {
      rendererLayers?.push(
        ...(getElevationDifferenceLayers(view.id, descriptor, getValueRange) ||
          [])
      );
    }
  } else if (view.subType === 'thermal_mosaic' || view.subType === 'ndvi') {
    // vimana:// tiles require a descriptor so get it
    const descriptor = await getVimanaViewDescriptor(view.id);

    if (!descriptor) {
      console.error('Could not fetch view descriptor for view:', view.id);
    } else {
      rendererLayers.push(
        ...(getThermalMosaicLayers(
          view.id,
          descriptor,
          THERMAL_COLORS,
          { min: true, max: true },
          getValueRange
        ) || [])
      );
    }
  } else {
    const url = new ViewsV2Apis().getViewFileUrl(view.id);
    // TODO: move layer creation to ./utils
    const layer = new TileLayer({
      source: new XYZ({
        url: `${url}/{z}/{x}/{-y}.png`,
        crossOrigin: 'anonymous',
      }),
      zIndex: DEFAULT_LAYER_Z_INDEX,
    });

    rendererLayers.push({
      id: '',
      visible: () => {
        return layer.getVisible();
      },
      label: '',
      _layer: layer,
      setVisible: (visible: boolean) => {
        layer.setVisible(visible);
      },
    });
  }

  return rendererLayers;
};

export const getVectorLayerStyle = (l: LayerDescriptor): StyleLike => {
  const { style } = l;

  return (feature, _resolution) => {
    return new Style({
      stroke: new Stroke({
        color: style?.stroke_color || 'red',
        width: style?.stroke_width || 1,
      }),
      fill: style?.fill_color
        ? new Fill({
            color: style.fill_color,
          })
        : undefined,
      image: new CircleStyle({
        fill: style?.fill_color
          ? new Fill({ color: style.fill_color })
          : undefined,
        stroke: new Stroke({
          color: style?.stroke_color || 'red',
          width: style?.stroke_width || 1,
        }),
        radius: 5,
      }),
      text: new Text({
        text: l.label ? `${feature.get(l.label) || ''}` : undefined,
        font: `${style?.font_size || '12px'} ${
          style?.font_family || 'Calibri,sans-serif'
        }`,
        placement: 'line',
        fill: new Fill({
          color: '#000',
        }),
        stroke: new Stroke({
          color: style?.font_color || '#fff',
          width: 3,
        }),
      }),
    });
  };
};

export const getRendererLayersFromMapboxStyle = async (
  styleUrl: string,
  accessToken: string
): Promise<RendererLayer[]> => {
  let mapboxStyle: any;

  if (styleUrl.startsWith('mapbox://')) {
    mapboxStyle = await getMapboxStyle(styleUrl, accessToken);
  } else {
    mapboxStyle = await fetch(styleUrl, {
      credentials: 'include',
    }).then((response) => response.json());
  }

  if (!mapboxStyle || !mapboxStyle.sources || !mapboxStyle.layers) {
    console.error(
      'Mapbox style is missing required field, or was not retrieved.',
      mapboxStyle
    );

    return [];
  }

  const promises = Object.keys(mapboxStyle.sources).map((key: string) => {
    const s = mapboxStyle.sources[key];
    const sourceUrl = s.url ? s.url.replace('mapbox://', '') : s.tiles[0];
    const sourceData = getMapboxSource(sourceUrl, accessToken).then((d) => {
      // fetch source data, and edit original style object
      s.data = d;
    });

    return sourceData;
  });

  // wait for all source layer data to be fetched before proceeding.
  await Promise.all(promises);

  return mapboxStyle.layers.map((l: MapboxLayer, index: number) =>
    getRenderLayer(l, mapboxStyle, accessToken, index)
  );
};

export const getVimanaViewDescriptor = async (viewId: string) => {
  const index = await viewV2Apis
    .getViewFiles(viewId, 'index.json')
    .then((res: any) => {
      if (res.error) {
        console.error('Error fetching view index: ', res.error);

        return null;
      }

      return res.data;
    });

  return index;
};

export const getElevationDifferenceLayers = (
  viewId: string,
  viewDescriptor: any,
  getColorRange: () => ValueRange
): RendererLayer[] | null => {
  const url = viewV2Apis.getViewFileUrl(viewId);
  const source = new DynamicColorSource({
    url,
    getColorRange,
    colors: ELEVATION_DIFF_COLORS,
    index: viewDescriptor,
  });

  const layer = new TileLayer({
    source,
    zIndex: DEFAULT_LAYER_Z_INDEX,
  });

  return [
    {
      id: 'elevation_difference',
      visible: () => {
        return layer.getVisible();
      },
      label: 'Elevation Difference',
      _layer: layer,
      setVisible: (visible: boolean) => {
        layer.setVisible(visible);
      },
    },
  ];
};

export const getThermalMosaicLayers = (
  viewId: string,
  viewDescriptor: any,
  colormap: ColorList,
  clampConfig: ClampConfig,
  getColorRange: () => ValueRange
): RendererLayer[] | null => {
  const url = viewV2Apis.getViewFileUrl(viewId);
  const source = new DynamicColorSource({
    url,
    getColorRange,
    colors: colormap,
    index: viewDescriptor,
    clampMax: clampConfig.max,
    clampMin: clampConfig.min,
  });

  const layer = new TileLayer({
    source,
    zIndex: DEFAULT_LAYER_Z_INDEX,
  });

  return [
    {
      id: 'thermal_mosaic',
      visible: () => {
        return layer.getVisible();
      },
      label: 'Thermal Mosaic',
      _layer: layer,
      setVisible: (visible: boolean) => {
        layer.setVisible(visible);
      },
    },
  ];
};

export const getColorWithOpacity = (color: string, opacity: number) => {
  let opaqueColor = colorAsArray(color);

  opaqueColor = opaqueColor.slice();
  opaqueColor[3] = opacity;

  return opaqueColor;
};

export const getGeojsonFromB64String = (jsonStr: string): any | undefined => {
  try {
    const json = atob(jsonStr);

    return JSON.parse(json);
  } catch (e) {
    console.error(
      'There was an error while extracting the GeoJSON from a base64 string.',
      e
    );

    return undefined;
  }
};

export const getCustomStylesForGeometries = (
  feature: any,
  _resolution: any
) => {
  const image = new CircleStyle({
    radius: 3,
    stroke: new Stroke({ color: 'red', width: 1.25 }),
  });

  const styles = {
    Point: new Style({
      image,
    }),
    MultiPoint: new Style({
      image,
    }),
    MultiPolygon: new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
    }),
    Polygon: new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
    }),
  };

  return styles[feature.getGeometry().getType()];
};
