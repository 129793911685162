import * as React from 'react';
import { BarsOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Tabs, Typography } from 'antd';
import ProjectItem from '../ProjectItem/ProjectItem';
import ProjectMapList from '../ProjectMapList/ProjectMapListContainer';
import style from './ProjectList.module.scss';
import { SnackbarActionsActionShowSnackbarTypes } from '../../shapes/snackbar';
import SkeletonLoader from '../SkeletonLoader';
import { undefinedOrNull } from '../../utils/functs';
import noResultsFoundImg from '../../images/no-results-found.png';

interface IProps {
  fetchProjects: () => void;
  setScrollPosition: (offSet: any) => void;
  projects: any;
  scrollPosition: any;
  filter: any;
  loading: boolean;
  loaded: boolean;
  resetIssuesReducer: () => void;
  showSnackbar: SnackbarActionsActionShowSnackbarTypes;
}

const { Title } = Typography;

export class ProjectList extends React.Component<IProps> {
  public componentDidMount(): void {
    const { projects, fetchProjects, scrollPosition, resetIssuesReducer } =
      this.props;

    // Reset the issues state whenever we return to the project listing page
    resetIssuesReducer();

    window.scrollBy(0, scrollPosition);
    if (!projects || !projects.length) {
      fetchProjects();
    }
  }

  public componentWillUnmount(): void {
    const { setScrollPosition } = this.props;

    setScrollPosition(window.pageYOffset);
  }

  public renderProjectList(): React.ReactNode {
    const { projects } = this.props;

    return projects.map((project: any) => (
      <ProjectItem key={project.id} {...project} />
    ));
  }

  public render(): React.ReactNode {
    const { filter, projects, loaded, loading } = this.props;

    if (loading) {
      return (
        <div className={style.container}>
          <SkeletonLoader size={3} position="fixed" />
        </div>
      );
    }

    if (loaded && (projects.length === 0 || undefinedOrNull(projects))) {
      return (
        <div className={style.container}>
          <div className={style.noResultsFound}>
            <img
              src={noResultsFoundImg}
              title="No Project Found"
              alt="No Project Found"
            />
            <Title level={2}>No Project Found</Title>
          </div>
        </div>
      );
    }

    return (
      <div className={style.container}>
        {filter ? (
          <div className={style.filterResult}>
            <span>
              Showing projects with
              {` "${filter}" `}
              in their name.
            </span>
          </div>
        ) : null}
        <Tabs
          type="card"
          className={style.tabBar}
          tabBarStyle={{ border: 'none' }}
        >
          <Tabs.TabPane
            key="list"
            tab={
              <a>
                <BarsOutlined />
                List
              </a>
            }
          >
            <div className={style.list}>{this.renderProjectList()}</div>
          </Tabs.TabPane>
          <Tabs.TabPane
            className={style.map}
            tab={
              <a>
                <EnvironmentOutlined />
                Map
              </a>
            }
            key="map"
          >
            <ProjectMapList projects={projects} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

export default ProjectList;
