import { ConfirmActions } from 'react-redux-confirm';
import Auth from '../../api/Auth';
import User from '../../api/User';
import { CHECK_LOGIN, LOGIN, LOGOUT } from '../actions';
import { ProjectRole } from '../../api/projects.types';

const api = new Auth();
const userApi = new User();

export const login = (username: string, password: string): {} => {
  return {
    type: LOGIN,
    payload: api.login(username, password),
  };
};

export const insertProjectUserRole = (
  projectId: string,
  role: ProjectRole
): {} => {
  return {
    type: 'INSERT_PROJECT_USER_ROLE',
    payload: { project_id: projectId, role },
  };
};

export const unAuthorized = () => {
  return {
    type: 'UNAUTHORIZED',
  };
};

export const checkLogin = (): {} => {
  return {
    type: CHECK_LOGIN,
    payload: api.getLogin(),
  };
};

export const logout = (): {} => {
  return {
    type: LOGOUT,
    payload: api.logout(),
  };
};

export const logoutWithConfirm =
  (confirmAction: any): any =>
  (dispatch: any) => {
    dispatch(
      ConfirmActions.confirm({
        onConfirm: () => {
          confirmAction();
          dispatch(logout());
        },
        message: 'Do you want to logout?',
        options: {
          modalProps: {
            okText: 'Yes',
            cancelText: 'No',
          },
        },
      })
    );
  };

export const getResetMail = (email: string): {} => {
  return {
    type: 'RESET_MAIL',
    payload: userApi.getResetMail(email),
  };
};

export const resetPasswordWithCode = (
  resetCode: string,
  password: string
): {} => {
  return {
    type: 'RESET_PASSWORD',
    payload: userApi.resetPasswordWithCode(resetCode, password),
  };
};

export const clearAuthState = (): {} => {
  return {
    type: 'CLEAR_AUTH_STATE',
  };
};
