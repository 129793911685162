import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { toPng } from 'html-to-image';
import { asArray as colorAsArray } from 'ol/color';
import ZoomControl from 'ol/control/Zoom';
import { click } from 'ol/events/condition';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import GeometryType from 'ol/geom/GeometryType';
import LineString from 'ol/geom/LineString';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Polygon from 'ol/geom/Polygon';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import Select, { SelectEvent } from 'ol/interaction/Select';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import 'ol/ol.css';
import Overlay from 'ol/Overlay';
import OverlayPositioning from 'ol/OverlayPositioning';
import Projection from 'ol/proj/Projection';
import ImageStaticSource from 'ol/source/ImageStatic';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import Zoomify from 'ol/source/Zoomify';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import View from 'ol/View';
import * as React from 'react';
import flattenChildren from 'react-flatten-children';
import ImagesApis from '../../api/images';
import { APP_PRIMARY_COLOR } from '../../constants/colors';
import {
  checkArrayContentEquality,
  degToRad,
  undefinedOrNull,
} from '../../utils/functs';
import { log } from '../../utils/log';
import Loading from '../Loading';
import styles from './index.module.scss';
import {
  OpenLayersOlInteractionsTypes,
  OpenLayersOlLayersTypes,
  OpenLayersOlSourcesTypes,
  OpenLayersPropsTypes,
  OpenLayersStateTypes,
  OpenLayersTilesDataTypes,
} from './index.types';

const imagesApis = new ImagesApis();
const VECTOR_Z_INDEX = 10;

export default class OpenLayers extends React.PureComponent<
  OpenLayersPropsTypes,
  OpenLayersStateTypes
> {
  private olMapSelector = 'olMap';

  private screenShotBtnWrapper = 'screenShotBtnWrapper';

  private olMap: Map | null = null;

  private olView: View | null = null;

  private sketchFeature: Feature | null;

  private helpToolTipElement: HTMLElement | null = null;

  private helpToolTipOverlay: Overlay | null = null;

  private olLayers: OpenLayersOlLayersTypes = {
    Tile: null,
    Vector: null,
    Image: null,
    ML: [],
  };

  private olSources: OpenLayersOlSourcesTypes = {
    Vector: null,
    Zoomify: null,
    OSM: null,
    ImageStatic: null,
  };

  private olInteractions: OpenLayersOlInteractionsTypes = {
    Draw: null,
    Select: null,
  };

  public static defaultProps: Partial<OpenLayersPropsTypes> = {
    initialZoomLevel: 0,
    guid: null,
    tileUrl: null,
  };

  public constructor(props: OpenLayersPropsTypes) {
    super(props);

    this.state = {
      isImageLoading: false,
    };
  }

  public componentDidMount(): void {
    this.bootOlMap();
  }

  public componentDidUpdate({
    guid: prevGuid,
    annotation: prevAnnotation,
    rotationAngle: prevRotationAngle,
    isTwoPaneView: prevIsTwoPaneView,
    objectAnnotations: prevObjectAnnotations,
    drawModeType: prevDrawModeType,
    selectType: prevSelectType,
  }: Readonly<OpenLayersPropsTypes>): void {
    const {
      guid,
      annotation,
      rotationAngle,
      isTwoPaneView,
      objectAnnotations,
      drawModeType,
      selectType,
    } = this.props;

    if (guid !== prevGuid) {
      // reset ol instance
      this.initOl();

      // boot up a new map instance
      this.bootOlMap();
    } else {
      // toggle annotation

      if (annotation !== prevAnnotation) {
        this.handleAnnotations();
      }

      if (objectAnnotations !== prevObjectAnnotations) {
        if (objectAnnotations && prevObjectAnnotations) {
          // check if arrays are equal only if both are defined
          if (
            !checkArrayContentEquality(
              objectAnnotations
                .filter((o) => o !== null && !o.properties?.deleted)
                .map((o) => o.id),
              prevObjectAnnotations
                .filter((o) => o !== null && !o.properties?.deleted)
                .map((o) => o.id)
            )
          ) {
            this.handleObjectAnnotations();
          }
        }

        // when one is defined, while the other is not
        this.handleObjectAnnotations();
      }

      // toggle rotationAngle
      if (rotationAngle !== prevRotationAngle) {
        this.handleRotation();
      }

      // change draw mode
      if (drawModeType !== prevDrawModeType) {
        this.handleDrawModeChange();
      }

      // change select mode
      if (selectType !== prevSelectType) {
        this.handleSelectModeChange();
      }
    }

    if (isTwoPaneView !== prevIsTwoPaneView) {
      if (this.olMap) {
        this.olMap.updateSize();
      }
    }
  }

  @autobind
  public async getScreenshot() {
    const exportOptions = {
      filter: (element: any) => {
        return element.className
          ? element.className.indexOf('ol-control') === -1
          : true;
      },
    };

    const dataUrl: null | string = await new Promise((resolve) => {
      if (!this.olMap) {
        return resolve(null);
      }

      const screenShotBtnWrapperNode = document.getElementById(
        this.screenShotBtnWrapper
      );

      if (!screenShotBtnWrapperNode) {
        return resolve(null);
      }

      screenShotBtnWrapperNode.addEventListener('click', () => {
        if (!this.olMap) {
          return resolve(null);
        }

        this.olMap.once('rendercomplete', () => {
          if (!this.olMap) {
            return resolve(null);
          }

          toPng(this.olMap.getTargetElement(), exportOptions).then(
            (dataURL) => {
              return resolve(dataURL);
            }
          );
        });

        this.olMap.renderSync();
      });

      const oldScreenShotBtnWrapperNode = screenShotBtnWrapperNode;
      const newScreenShotBtnWrapperNode =
        oldScreenShotBtnWrapperNode.cloneNode(true);

      if (oldScreenShotBtnWrapperNode.parentNode) {
        oldScreenShotBtnWrapperNode.parentNode.replaceChild(
          newScreenShotBtnWrapperNode,
          oldScreenShotBtnWrapperNode
        );
      }

      screenShotBtnWrapperNode.click();
    });

    return dataUrl;
  }

  @autobind
  public getAnnotations() {
    if (!this.olLayers.Vector) {
      return null;
    }

    const features = this.olLayers.Vector.getSource().getFeatures();

    return features
      .map((a) => {
        if (!a.getGeometry()) {
          return null;
        }

        // @ts-ignore
        return a.getGeometry().getCoordinates();
      })
      .filter((a) => a);
  }

  @autobind
  public getMapZoom() {
    if (!this.olMap) {
      return null;
    }

    return this.olMap.getView().getZoom();
  }

  @autobind
  public getMapCenter() {
    if (!this.olMap) {
      return null;
    }

    return this.olMap.getView().getCenter();
  }

  @autobind
  public getMapRotation() {
    if (!this.olMap) {
      return null;
    }

    return this.olMap.getView().getRotation();
  }

  private initOl = () => {
    if (!this.olMap) {
      return;
    }

    this.olMap.setTarget(undefined);
    this.olMap = null;

    if (this.olSources.ImageStatic) {
      this.olSources.ImageStatic.un('imageloadstart', this.setImageLoading);
    }

    // reset all olLayers values
    Object.keys(this.olLayers).map((a) => {
      this.olLayers[a] = null;

      return a;
    });

    Object.keys(this.olInteractions).map((a) => {
      this.olInteractions[a] = null;

      return a;
    });

    Object.keys(this.olSources).map((a) => {
      this.olSources[a] = null;

      return a;
    });
  };

  private bootOlMap = async () => {
    const { rotationAngle, annotation, type, tileUrl, initData, imageUrl } =
      this.props;

    let mapView: View;
    let mapData;

    this.olMap = new Map({
      target: this.olMapSelector,
      controls: [new ZoomControl()],
      layers: [],
    });

    if (!this.olMap) {
      return;
    }

    switch (type) {
      case 'TILES':
        if (undefinedOrNull(tileUrl)) {
          return;
        }

        // eslint-disable-next-line no-case-declarations
        const tileData = await this.fetchTiles();
        // eslint-disable-next-line no-case-declarations
        let parsedTilesDzi;

        if (tileData) {
          parsedTilesDzi = this.parseTilesDzi(tileUrl, tileData);
          mapData = this.getTilesData(parsedTilesDzi);
        } else if (!undefinedOrNull(imageUrl)) {
          mapData = this.getStaticImageData({ url: imageUrl });
        } else {
          return;
        }

        mapView = mapData.view;
        this.olMap.addLayer(mapData.layer);

        break;

      case 'MAP':
        mapData = this.getMapData();

        mapView = mapData.view;
        this.olMap.addLayer(mapData.layer);

        break;

      default:
        return;
    }

    if (!this.olView) {
      return;
    }

    this.olMap.setView(mapView);
    this.createHelpToolTip();

    if (mapData.extent) {
      this.olMap.getView().fit(mapData.extent);
    }

    if (!undefinedOrNull(annotation)) {
      this.handleAnnotations();
    }

    if (!undefinedOrNull(rotationAngle)) {
      this.handleRotation();
    }

    if (!undefinedOrNull(initData)) {
      const { zoomLevel, centerTo } = initData;

      if (!undefinedOrNull(zoomLevel)) {
        this.olView.setZoom(zoomLevel);
      }

      if (!undefinedOrNull(centerTo)) {
        this.olView.setCenter(centerTo);
      }
    }
  };

  private fetchTiles = (): Promise<null | string> => {
    const { guid, tileUrl } = this.props;

    return new Promise((resolve) => {
      if (!guid || !tileUrl) {
        return resolve(null);
      }

      imagesApis.getTileImageData(guid).then((res) => {
        const { error: apiError, data: apiData } = res;

        if (apiError || !apiData) {
          log.error(apiError, 'OpenLayers.fetchTiles');

          return resolve(null);
        }

        return resolve(apiData);
      });
    });
  };

  // parse tiles values from the url and inputted dzi xml file
  private parseTilesDzi = (
    url: string,
    dziXmlData: string
  ): OpenLayersTilesDataTypes => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(dziXmlData, 'text/xml');
    const last = url.lastIndexOf('.');
    const path = url.slice(0, last);
    const elements = xmlDoc.getElementsByTagName('Image');
    const tileSize = Number(elements[0].getAttribute('TileSize'));
    const format = elements[0].getAttribute('Format');

    const width = Number(
      elements[0].getElementsByTagName('Size')[0].getAttribute('Width')
    );
    const height = Number(
      elements[0].getElementsByTagName('Size')[0].getAttribute('Height')
    );

    const formattedUrl = `${path}_files/{z}/{x}_{y}.${format}`;
    const offset = Math.ceil(Math.log(tileSize) / Math.LN2);

    return {
      url: formattedUrl,
      offset,
      imgWidth: width,
      imgHeight: height,
      tileSize,
    };
  };

  private getTilesData = (tilesData: OpenLayersTilesDataTypes) => {
    const { initialZoomLevel } = this.props;

    const { imgHeight, imgWidth, tileSize, offset, url } = tilesData;

    const imgCenter = [imgWidth / 2, -imgHeight / 2];

    const proj = new Projection({
      code: 'ZOOMIFY',
      units: 'pixels',
      extent: [0, 0, imgWidth, imgHeight],
    });

    this.olSources.Zoomify = new Zoomify({
      url,
      size: [imgWidth, imgHeight],
      tileSize,
      crossOrigin: 'anonymous',
    });

    this.olLayers.Tile = new TileLayer({
      source: this.olSources.Zoomify,
    });

    // @ts-ignore
    this.olLayers.Tile.getSource().setTileUrlFunction((tileCoord: number[]) => {
      return url
        .replace('{z}', `${tileCoord[0] + offset}`)
        .replace('{x}', `${tileCoord[1]}`)
        .replace('{y}', `${tileCoord[2]}`);
    });

    this.olView = new View({
      projection: proj,
      center: imgCenter,
      zoom: initialZoomLevel,
      // @ts-ignore
      constrainOnlyCenter: true,
      constrainRotation: false,
      extent: [0, -imgHeight, imgWidth, 0],
    });

    return {
      layer: this.olLayers.Tile as TileLayer,
      view: this.olView as View,
      extent: null,
    };
  };

  private getStaticImageData = (tilesData: { url: string }) => {
    const { initialZoomLevel } = this.props;

    const { url } = tilesData;

    const extent = [0, 0, 10000, 7328];
    const resolutions = [64, 32, 16, 8, 4, 2, 1];

    this.olSources.ImageStatic = new ImageStaticSource({
      url,
      imageExtent: extent,
      crossOrigin: 'anonymous',
    });

    this.olLayers.Image = new ImageLayer({
      source: this.olSources.ImageStatic,
    });

    this.olView = new View({
      resolutions,
      extent,
      zoom: initialZoomLevel,
      // @ts-ignore
      constrainOnlyCenter: true,
    });

    this.olSources.ImageStatic.on('imageloadstart', this.setImageLoading);

    this.olSources.ImageStatic.on('imageloadend', () => {
      this.setImageLoading(false);
    });

    return {
      layer: this.olLayers.Image as ImageLayer,
      view: this.olView as View,
      extent,
    };
  };

  private setImageLoading = (value: boolean = true) => {
    this.setState({
      isImageLoading: value,
    });
  };

  private getMapData = () => {
    const { initialZoomLevel } = this.props;

    this.olSources.OSM = new OSM();

    this.olLayers.Tile = new TileLayer({
      source: this.olSources.OSM,
    });

    this.olView = new View({
      center: [0, 0],
      zoom: initialZoomLevel,
    });

    return {
      layer: this.olLayers.Tile as TileLayer,
      view: this.olView as View,
      extent: null,
    };
  };

  private handleDrawStart = (e: DrawEvent) => {
    if (!this.olMap || !this.olLayers.Vector || !this.olInteractions.Draw) {
      return;
    }

    this.sketchFeature = e.feature;
  };

  private handleDrawEnd = (e: DrawEvent) => {
    const { handleShapeCreate } = this.props;

    if (!this.olMap || !this.olLayers.Vector || !this.olInteractions.Draw) {
      return;
    }

    const currentFeatures = e.feature;

    this.olInteractions.Draw.finishDrawing();

    const geojson = new GeoJSON();

    if (handleShapeCreate) {
      handleShapeCreate({
        features: [geojson.writeFeatureObject(currentFeatures)],
      });
    }

    this.resetDrawMode();
  };

  private handleFeatureSelect = (e: SelectEvent) => {
    const { handleShapeSelect } = this.props;

    if (!this.olMap || !this.olInteractions.Select) {
      return;
    }

    const selectedFeature = e.selected;
    const geojson = new GeoJSON();

    if (handleShapeSelect) {
      handleShapeSelect(geojson.writeFeaturesObject(selectedFeature));
    }

    this.resetSelectMode();
  };

  private resetSelectMode = () => {
    if (!this.olMap) {
      return;
    }

    if (this.olInteractions.Select) {
      this.olMap.removeInteraction(this.olInteractions.Select);
      this.olInteractions.Select.un('select', this.handleFeatureSelect);
      this.olMap.un('pointermove', this.pointerMoveHandler);
      this.olMap
        .getViewport()
        .removeEventListener('mouseout', this.mouseOutHandler);
      if (this.helpToolTipElement) {
        this.helpToolTipElement.classList.add('hidden');
      }
    }

    this.olInteractions.Select = null;
  };

  private handleSelectModeChange = () => {
    const { selectType } = this.props;

    if (!this.olMap) {
      return;
    }

    if (!selectType) {
      this.resetSelectMode();
    }

    if (selectType === 'click') {
      this.olInteractions.Select = new Select({
        condition: click,
      });

      this.olMap.addInteraction(this.olInteractions.Select);
      this.olInteractions.Select.on('select', this.handleFeatureSelect);
      this.olMap.on('pointermove', this.pointerMoveHandler);
      this.olMap
        .getViewport()
        .addEventListener('mouseout', this.mouseOutHandler);
    }
  };

  private resetDrawMode = () => {
    if (!this.olMap) {
      return;
    }

    if (this.olInteractions.Draw) {
      this.olInteractions.Draw.un('drawend', this.checkMaxDrawSize);
      this.olInteractions.Draw.un('drawend', this.handleDrawEnd);
      this.olInteractions.Draw.un('drawstart', this.handleDrawStart);
      this.olMap.un('pointermove', this.pointerMoveHandler);
      this.olMap
        .getViewport()
        .removeEventListener('mouseout', this.mouseOutHandler);

      if (this.helpToolTipElement) {
        this.helpToolTipElement.classList.add('hidden');
      }

      this.olMap.removeInteraction(this.olInteractions.Draw);
    }

    if (this.olLayers.Vector) {
      this.olMap.removeLayer(this.olLayers.Vector);
    }

    this.olLayers.Vector = null;
    this.olSources.Vector = null;
    this.olInteractions.Draw = null;
  };

  private createHelpToolTip = () => {
    if (!this.olMap) {
      return;
    }

    if (this.helpToolTipElement) {
      // eslint-disable-next-line no-unused-expressions
      this.helpToolTipElement.parentNode?.removeChild(this.helpToolTipElement);
    }

    this.helpToolTipElement = document.createElement('div');
    this.helpToolTipElement.className = classnames(styles.olTooltip, 'hidden');

    this.helpToolTipOverlay = new Overlay({
      element: this.helpToolTipElement,
      offset: [15, 0],
      positioning: OverlayPositioning.CENTER_LEFT,
    });

    this.olMap.addOverlay(this.helpToolTipOverlay);
  };

  private pointerMoveHandler = (e: MapBrowserEvent) => {
    const { drawModeType, selectType } = this.props;

    if (e.dragging || !this.helpToolTipElement || !this.helpToolTipOverlay) {
      return;
    }

    if (drawModeType && drawModeType === 'Polygon') {
      let helpMessage = 'Click to start drawing';

      if (this.sketchFeature) {
        helpMessage = 'Double click to close polygon.';
      }

      this.helpToolTipElement.innerHTML = helpMessage;
      this.helpToolTipOverlay.setPosition(e.coordinate);

      this.helpToolTipElement.classList.remove('hidden');
    } else if (selectType) {
      const helpMessage = 'Click to select feature to delete';

      this.helpToolTipElement.innerHTML = helpMessage;
      this.helpToolTipOverlay.setPosition(e.coordinate);

      this.helpToolTipElement.classList.remove('hidden');
    }
  };

  private mouseOutHandler = () => {
    if (this.helpToolTipElement) {
      this.helpToolTipElement.classList.remove('hidden');
    }
  };

  private handleDrawModeChange = () => {
    const { drawModeType } = this.props;

    if (!this.olMap) {
      return;
    }

    this.resetDrawMode();

    if (!drawModeType) {
      return;
    }

    if (drawModeType === 'Polygon') {
      this.olSources.Vector = new VectorSource({ wrapX: false });
      this.olLayers.Vector = new VectorLayer({
        source: this.olSources.Vector,
      });

      this.olInteractions.Draw = new Draw({
        source: this.olSources.Vector,
        type: GeometryType.POLYGON,
      });

      this.olMap.addLayer(this.olLayers.Vector);
      this.olMap.addInteraction(this.olInteractions.Draw);

      this.olInteractions.Draw.on('drawstart', this.handleDrawStart);
      this.olInteractions.Draw.on('drawend', this.handleDrawEnd);
      this.olMap.on('pointermove', this.pointerMoveHandler);
      this.olMap
        .getViewport()
        .addEventListener('mouseout', this.mouseOutHandler);
    } else {
      console.error('Unsupported OL Draw Mode Type!');
    }
  };

  private resetObjectAnnotations = () => {
    if (!this.olMap) {
      return;
    }

    if (this.olLayers.ML && this.olLayers.ML.length > 0) {
      this.olLayers.ML.forEach((mlLayer) => {
        if (this.olMap) {
          this.olMap.removeLayer(mlLayer);
        }
      });
    }

    this.olLayers.ML = [];
  };

  private handleObjectAnnotations = () => {
    const { objectAnnotations } = this.props;

    this.resetObjectAnnotations();

    if (!objectAnnotations) {
      return;
    }

    this.olLayers.ML = [];

    objectAnnotations.forEach((o) => {
      if (!this.olMap) {
        return;
      }

      if (
        !undefinedOrNull(
          o.annotationData &&
            !undefinedOrNull(o.properties) &&
            !o.properties.deleted
        )
      ) {
        const featuresList: Feature[] = [];

        if (o.type === GeometryType.MULTI_POLYGON) {
          const geometry = new MultiPolygon(o.annotationData as number[][][][]);
          const feature = new Feature(geometry);

          if (o.properties) {
            feature.setProperties(o.properties, true);
          }

          featuresList.push(feature);
        } else if (o.type === GeometryType.POLYGON) {
          const geometry = new Polygon(o.annotationData as number[][][]);
          const feature = new Feature(geometry);

          if (o.properties) {
            feature.setProperties(o.properties, true);
          }

          featuresList.push(feature);
        }

        const vectorSource = new VectorSource({
          wrapX: true,
          features: featuresList,
        });

        // setting opacity as 0.2 in fill color
        let fillColor = colorAsArray(o.color || APP_PRIMARY_COLOR);

        fillColor = fillColor.slice();
        fillColor[3] = 0.2;

        const layer = new VectorLayer({
          source: vectorSource,
          style: new Style({
            stroke: new Stroke({
              color: o.color || APP_PRIMARY_COLOR,
              width: 4,
            }),
            fill: new Fill({
              color: fillColor,
            }),
          }),
          // ensuring vector layers are always on top
          zIndex: VECTOR_Z_INDEX,
        });

        this.olMap.addLayer(layer);
        this.olLayers.ML.push(layer);
      }
    });
  };

  private handleAnnotations = () => {
    const { annotation } = this.props;

    if (!this.olMap) {
      return;
    }

    this.resetAnnotations();

    if (!annotation) {
      return;
    }

    let featuresList: Feature[] = [];
    let isDrawEnabled = true;

    if (!undefinedOrNull(annotation.annotationData)) {
      featuresList = (annotation.annotationData as any[]).map((a: any) => {
        const linePoints = new LineString(a);

        return new Feature(linePoints);
      });

      isDrawEnabled = false;
    }

    this.olSources.Vector = new VectorSource({
      wrapX: false,
      features: featuresList,
    });

    this.olLayers.Vector = new VectorLayer({
      source: this.olSources.Vector,
      style: this.getVectorStyles,
      zIndex: VECTOR_Z_INDEX,
    });

    this.olMap.addLayer(this.olLayers.Vector);

    if (isDrawEnabled) {
      this.olInteractions.Draw = new Draw({
        source: this.olSources.Vector,
        type: annotation.type,
        freehand: annotation.freehand,
      });

      this.olMap.addInteraction(this.olInteractions.Draw);

      if (!undefinedOrNull(annotation.maxSize)) {
        this.olInteractions.Draw.on('drawend', this.checkMaxDrawSize);
      }
    }
  };

  private resetAnnotations = () => {
    if (!this.olMap) {
      return;
    }

    if (this.olInteractions.Draw) {
      this.olInteractions.Draw.un('drawend', this.checkMaxDrawSize);

      this.olMap.removeInteraction(this.olInteractions.Draw);
    }

    if (this.olLayers.Vector) {
      this.olMap.removeLayer(this.olLayers.Vector);
    }

    this.olLayers.Vector = null;
    this.olInteractions.Draw = null;
  };

  private checkMaxDrawSize = (e: DrawEvent) => {
    const { annotation } = this.props;

    if (!annotation || undefinedOrNull(annotation.maxSize)) {
      return;
    }

    if (!this.olMap || !this.olLayers.Vector || !this.olInteractions.Draw) {
      return;
    }

    const currentFeatures = e.feature;
    const prevFeatures = this.olLayers.Vector.getSource().getFeatures();
    const features = prevFeatures.concat(currentFeatures);

    if (features.length >= annotation.maxSize) {
      this.olInteractions.Draw.finishDrawing();
      this.olMap.removeInteraction(this.olInteractions.Draw);
    }
  };

  private handleRotation = () => {
    const { rotationAngle } = this.props;

    if (!this.olMap || !this.olView || undefinedOrNull(rotationAngle)) {
      return;
    }

    this.olView.setRotation(degToRad(rotationAngle));
  };

  private getVectorStyles = (): Style => {
    return new Style({
      stroke: new Stroke({
        color: APP_PRIMARY_COLOR,
        width: 4,
      }),
    });
  };

  public render(): React.ReactNode {
    const { children, width, height, className } = this.props;
    const { isImageLoading } = this.state;

    const childrenWithProps = React.Children.map(
      flattenChildren(children),
      (child) => {
        // Checking isValidElement is the safe way and avoids a TS error too.
        if (React.isValidElement(child as any)) {
          return React.cloneElement(child as any, { olMap: this.olMap });
        }

        return child;
      }
    );

    return (
      <div className={styles.container}>
        <div
          id={this.olMapSelector}
          className={`${styles.mapWrapper} ${className}`}
          style={{ width, height }}
        />

        {isImageLoading && (
          <div className={styles.imageLoadingWrapper}>
            <Loading type="ellipsis" />
          </div>
        )}

        <button
          id={this.screenShotBtnWrapper}
          className={styles.screenShotBtnWrapper}
        />

        {childrenWithProps}
      </div>
    );
  }
}
