import * as math from 'mathjs';
import { getRotationMatrix, normalize } from 'src/vendor/inspection-utils/math';

export default class PerspectiveCamera {
  private T: number[][];

  private projectionMatrix: number[][];

  private invProjectionMatrix: number[][];

  private H: number[][];

  private invH: number[][];

  constructor(
    public id: string,
    private width: number,
    private height: number,
    private position: number[],
    private K: number[][],
    private R: number[][]
  ) {
    this.T = [
      [1, 0, 0, -this.position[0]],
      [0, 1, 0, -this.position[1]],
      [0, 0, 1, -this.position[2]],
    ];
    // world to pixel projection
    this.projectionMatrix = math.multiply(
      this.K,
      math.multiply(this.R, this.T)
    );
    // pixel to world projection
    const ART = getRotationMatrix(R, this.position);
    const invProjectionMatrix = math.multiply(K, ART);

    invProjectionMatrix[3] = [0, 0, 0, 1];
    this.invProjectionMatrix = math.inv(invProjectionMatrix);
    // pixel <-> OpenSFM transforms, see https://opensfm.readthedocs.io/en/latest/geometry.html
    const maxWH = math.max(this.width, this.height);

    this.H = [
      [maxWH, 0, (this.width - 1) / 2],
      [0, maxWH, (this.height - 1) / 2],
      [0, 0, 1],
    ];
    this.invH = [
      [1, 0, -(this.width - 1) / 2],
      [0, 1, -(this.height - 1) / 2],
      [0, 0, maxWH],
    ];
  }

  public getBearing(
    pixelX: number,
    pixelY: number
  ): { guid: string; bearing: number[]; position: number[] } {
    // convert to OpenSFM pixel coordinates (-0.5, -0.5) to (0.5, 0.5)
    const normalizedCoords = math.multiply(this.invH, [pixelX, pixelY, 1]);
    const [x, y] = [
      normalizedCoords[0] / normalizedCoords[2],
      normalizedCoords[1] / normalizedCoords[2],
    ];
    let pt = math.multiply(this.invProjectionMatrix, [x, y, 1, 1]);

    pt = [pt[0] / pt[3], pt[1] / pt[3], pt[2] / pt[3]];
    pt = math.subtract(pt, this.position);
    const bearing = normalize(pt);

    return {
      guid: this.id,
      bearing,
      position: this.position,
    };
  }

  public project(pointWorldCoords: { x: number; y: number; z: number }): {
    pixelX: number;
    pixelY: number;
  } | null {
    const { x, y, z } = pointWorldCoords;
    const projectedUnNormalized = math.multiply(this.projectionMatrix, [
      x,
      y,
      z,
      1,
    ]);
    const projected = [
      projectedUnNormalized[0] / projectedUnNormalized[2],
      projectedUnNormalized[1] / projectedUnNormalized[2],
    ];

    const imagePixelCoords = math.multiply(this.H, [
      projected[0],
      projected[1],
      1,
    ]);

    if (
      imagePixelCoords[0] >= 0 &&
      imagePixelCoords[0] < this.width &&
      imagePixelCoords[1] >= 0 &&
      imagePixelCoords[1] < this.height
    ) {
      return { pixelX: imagePixelCoords[0], pixelY: imagePixelCoords[1] };
    }

    return null;
  }
}
