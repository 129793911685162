import * as React from 'react';
import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import ElevationDifferenceAPIs from '../../api/elevationDifference';
import { ElevationDifference } from '../../api/elevationDifference.types';
import { formatDateForDatePicker } from '../../utils/date';
import { Button } from '../Button';
import { AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE } from '../../constants';
import { GenericObjectType } from '../../shapes/app';
import { sortByDate } from '../../utils/helpers';
import {
  ElevationDifferenceControlProps,
  ElevationDifferenceControlStateType,
} from './index.types';
import DocumentationLink from '../DocumentationLink';
import { DOCUMENTATION_URL_LIST } from '../../constants/urls';
import { getAoiFromProjectData } from '../AoiData';

const { Title } = Typography;
const elevationDifferenceAPIs = new ElevationDifferenceAPIs();

class ElevationDifferenceControl extends React.Component<
  ElevationDifferenceControlProps,
  ElevationDifferenceControlStateType
> {
  public constructor(props: ElevationDifferenceControlProps) {
    super(props);

    this.state = {
      elevationDifferenceList: [],
      tableLoading: true,
    };
  }

  public componentDidMount(): void {
    const { match, showSnackbar } = this.props;
    const { projectId, aoiId } = match.params;

    elevationDifferenceAPIs
      .getElevationDifferencesForAOI(projectId, aoiId)
      .then((res) => {
        if (res.error) {
          showSnackbar({
            body: res.error,
            type: 'error',
          });
        }

        const sortedByDate = sortByDate(
          res.data,
          'targetDate',
          true
        ) as ElevationDifference[];

        this.setState({
          elevationDifferenceList: sortedByDate,
          tableLoading: false,
        });
      });
  }

  private showProjectAdminLinks = (): boolean => {
    const { user, match } = this.props;
    const { projectId } = match.params;

    if (!user) {
      return false;
    }

    if (user.staff === true) {
      return true;
    }

    if (projectId) {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of user.project_roles || []) {
        if ((role as any).project_id === projectId) {
          return (role as any).role === 'project_admin';
        }
      }
    }

    return false;
  };

  private handleRowClick = (record: GenericObjectType) => {
    const { history, match, projectData, showSnackbar } = this.props;
    const { projectId, aoiId } = match.params;

    if (!history) {
      return;
    }

    if (projectData && projectData.epsgCode) {
      history.push(
        `/project/${projectId}/aoi/${aoiId}/elevationDifference/edit/${record.id}`
      );
    } else {
      showSnackbar({
        body: 'Add EPSG code to your project to enable this feature.',
        type: 'error',
      });
    }
  };

  public allowCreation = (): { allowed: boolean; message?: string } => {
    const { projectData, match } = this.props;
    const { aoiId } = match.params;
    const aoi = getAoiFromProjectData(projectData, aoiId);

    if (!projectData?.epsgCode && !aoi?.boundaryId) {
      return {
        allowed: false,
        message:
          'Add EPSG code and a boundary to your project to enable this feature.',
      };
    }

    if (!projectData?.epsgCode) {
      return {
        allowed: false,
        message: 'Add EPSG code to your project to enable this feature.',
      };
    }

    if (!aoi?.boundaryId) {
      return {
        allowed: false,
        message: 'Add a boundary to enable this feature.',
      };
    }

    return { allowed: true };
  };

  public render(): React.ReactNode {
    const { match } = this.props;
    const { elevationDifferenceList, tableLoading } = this.state;
    const { projectId, aoiId } = match.params;
    const { allowed, message } = this.allowCreation();

    const tableColumns = [
      {
        title: 'Reference Date',
        dataIndex: 'sourceDate',
        key: 'sourceDate',
        render: (value: string) => formatDateForDatePicker(value).format('ll'),
      },
      {
        title: 'Target Date',
        dataIndex: 'targetDate',
        key: 'targetDate',
        render: (value: string) => formatDateForDatePicker(value).format('ll'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value: string, list: any) => {
          if (list.requestStatus === 'created') {
            return value ? value.toUpperCase() : null;
          }

          if (list.requestStatus === 'pending') {
            return 'REQUEST PENDING';
          }

          return list.requestStatus.toUpperCase();
        },
      },
    ];

    return (
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <div className={styles.titleInnerWrapper}>
            <Title level={2}>Elevation Difference</Title>
            {/* TODO: Update documentation and enable it */}
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.manageElevationDifference}
              toolTipPosition="right"
            />
          </div>

          {this.showProjectAdminLinks() ? (
            <Link
              className={styles.createItemLink}
              to={`/project/${projectId}/aoi/${aoiId}/elevationDifference/new`}
              title={allowed ? '' : message}
            >
              <Button
                transparent
                text="+ NEW ELEVATION DIFFERENCE"
                disabled={!allowed}
              />
            </Link>
          ) : null}
        </div>
        <Table
          locale={{ emptyText: 'No data uploaded yet.' }}
          onRow={(record: GenericObjectType) => {
            return {
              onClick: () => {
                this.handleRowClick(record);
              },
            };
          }}
          rowKey={(uploadsList: any) => uploadsList.id}
          columns={tableColumns}
          loading={tableLoading}
          dataSource={elevationDifferenceList}
          pagination={{
            defaultPageSize: AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE,
          }}
        />
      </div>
    );
  }
}

export default ElevationDifferenceControl;
