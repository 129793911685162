import { AppConfig } from '../utils/config';
import ApiBase from './ApiBase';
import { BASE_CAPI_URL } from '../constants/urls';

export default class ConfigApi extends ApiBase {
  protected baseUrl: string = BASE_CAPI_URL;

  public getAppConfig(): Promise<Partial<AppConfig>> {
    return this.get('/v1/config').then((res) => {
      return { ...res.data, useLegacyMeasurements: true };
    });
  }
}
