import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createIssue } from '../../redux/actions/issues';
import MarkIssueControlBox from './MarkIssueControlBox';

const mapStateToProps = (state: any): {} => {
  return {
    creating: state.issue.creating,
    planeElevation: state.volume.elevation,
    volumeType: state.volume.volumeType,
  };
};

const mapDispatchToProps = (dispatch: any): {} => {
  return bindActionCreators(
    {
      createIssue,
    },
    dispatch
  );
};

const MarkIssueControlBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MarkIssueControlBox);

export default MarkIssueControlBoxContainer;
