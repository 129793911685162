import { LeftOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import * as queryString from 'query-string';
import * as React from 'react';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import AoiDropDown from '../AoiDropDown/AoiDropDownContainer';
import { Button } from '../Button';
import HamburgerMenu from '../HamburgerMenu';
import IssueIconButton from './IssueIconButton';
import UserSandboxButton from './UserSandboxButton';
import MapViewHeader from '../MapViewHeader/MapViewHeaderContainer';
import ProjectFilter from '../ProjectList/ProjectFilter';
import TypeMenu from '../TypeMenu';
import MenuItem from '../MenuItem';
import UserMenu from '../UserMenu';
import style from './index.module.scss';
import Logo from '../Logo/Logo';
import {
  AOI_LISTING_PAGE_PATH,
  DOWNLOADS_LIST_WITH_AOI_PATH,
  DOWNLOADS_LIST_WITHOUT_AOI_PATH,
  INTERIOR360_VIEWS_EDIT_PATH,
  INTERIOR360_VIEWS_NEW_PATH,
  PROJECT_VIDEOS_EDIT_PAGE_PATH,
  PROJECT_VIDEOS_NEW_PAGE_PATH,
  SHARE_PATH,
  VIEW_PATH,
} from '../../routes/paths';
import DownloadButton from './DownloadButton';
import BrandingApi from '../../api/branding';
import {
  isVimanaLite,
  undefinedOrNull,
  undefinedOrNullChained,
} from '../../utils/functs';
import { IProjectBranding } from '../../shapes/branding';
import { HeaderProps, HeaderState } from './index.types';
import IssuesHeaderIcons from './IssuesHeaderIcons';

export const showProjectAdminLinks = (
  projectId: string | null,
  user: any
): boolean => {
  if (!user) {
    return false;
  }

  if (user.staff === true) {
    return true;
  }

  if (projectId) {
    // eslint-disable-next-line no-restricted-syntax
    for (const role of user.project_roles || []) {
      if ((role as any).project_id === projectId) {
        return (role as any).role === 'project_admin';
      }
    }
  }

  return false;
};

const brandingApi = new BrandingApi();

class Header extends React.Component<HeaderProps, HeaderState> {
  public constructor(props: HeaderProps) {
    super(props);
    this.state = {
      data: {
        url: 'https://aspecscire.com/',
        name: 'Aspecscire Inc.',
      },
    };
  }

  public componentDidMount(): void {
    const { location } = this.props;
    const { pathname } = location;

    const currentProjectId = this.extractProjectIdFromUrl(pathname);

    if (!undefinedOrNull(currentProjectId)) {
      brandingApi
        .getProjectBranding(currentProjectId)
        .then((res: IProjectBranding) => {
          if (!undefinedOrNull(res)) {
            this.setState({
              data: {
                name: undefinedOrNullChained(res, 'data.name')
                  ? 'AspecScire'
                  : res.data.name,
                url: undefinedOrNullChained(res, 'data.url')
                  ? null
                  : res.data.url,
              },
            });
          }
        });
    }
  }

  public componentDidUpdate(prevProps: HeaderProps): void {
    const { location } = this.props;
    const { pathname } = location;

    const currentProjectId = this.extractProjectIdFromUrl(pathname);
    const previousProjectId = this.extractProjectIdFromUrl(
      prevProps.location.pathname
    );

    if (currentProjectId !== previousProjectId) {
      if (!undefinedOrNull(currentProjectId)) {
        brandingApi
          .getProjectBranding(currentProjectId)
          .then((res: IProjectBranding) => {
            if (!undefinedOrNull(res)) {
              this.setState({
                data: {
                  name: undefinedOrNullChained(res, 'data.name')
                    ? 'AspecScire'
                    : res.data.name,
                  url: undefinedOrNullChained(res, 'data.url')
                    ? null
                    : res.data.url,
                },
              });
            }
          });
      }
    }
  }

  public extractProjectIdFromUrl = (pathname: string): string | null => {
    if (pathname.startsWith('/project')) {
      const pathWithProjectId = pathname.substring(
        pathname.indexOf('/project/') + '/project/'.length
      );

      if (pathWithProjectId.indexOf('/') === -1) {
        return pathWithProjectId;
      }

      const projectId = pathWithProjectId.slice(
        0,
        pathWithProjectId.indexOf('/')
      );

      return projectId;
    }

    return null;
  };

  public canSee(projectId: string, roles: string[]): boolean {
    const { user } = this.props;

    if (!user) {
      return false;
    }

    if (user.staff) {
      return true;
    }

    if (projectId) {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of user.project_roles || []) {
        if ((role as any).project_id === projectId) {
          const roleId = (role as any).role;

          return roles.indexOf(roleId) !== -1;
        }
      }
    }

    return false;
  }

  private handleTagManagerClick = (): void => {
    const { openManageTagsModal } = this.props;

    openManageTagsModal();
  };

  public RenderQuickStartButton = (
    pathType: string,
    buttonText: string
  ): JSX.Element => {
    return (
      <a
        className={style.createItemLink}
        href={`/quickstart/${pathType}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <button className={`ant-btn ant-btn-primary ${style.headBtn}`}>
          <i
            className="fa fa-play-circle"
            aria-hidden="true"
            style={{
              margin: '3px',
              verticalAlign: 'middle',
            }}
          />
          <span>{buttonText.toUpperCase()}</span>
        </button>
      </a>
    );
  };

  public render(): React.ReactNode {
    const { user, share, partnerId } = this.props;
    const {
      data: { url, name },
    } = this.state;
    const productFrag = isVimanaLite() ? (
      <></>
    ) : (
      // TODO: re-instate post license clarity
      // <div style={{ marginTop: '0.5em', width: '10em' }}>
      //   (Vimana-Lite 1.0: NOT FOR COMMERCIAL USE)
      // </div>
      <></>
    );

    const HomeAndBrandSite = (
      <div>
        {productFrag}
        <Link
          style={{ paddingRight: '0.5em' }}
          to={share && share.partnerId ? `/?partnerId=${share.partnerId}` : '/'}
        >
          Dashboard
        </Link>{' '}
        |
        <a
          className={
            undefinedOrNull(url) || url.length === 0
              ? style.disabledLink
              : style.brandSite
          }
          href={undefinedOrNull(url) || url.length === 0 ? undefined : url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </a>
      </div>
    );

    return (
      <div className={style.container}>
        {productFrag}
        <Switch>
          <Route
            path="/archivedProjects"
            render={() => {
              return (
                <Link to="/">
                  <Logo projectId="default" size="small" />
                </Link>
              );
            }}
          />
          <Route
            path="/project/:projectId/"
            render={({ match }) => {
              const { projectId } = match.params;

              return (
                <Popover
                  content={HomeAndBrandSite}
                  trigger="hover"
                  placement="bottomRight"
                >
                  <div>
                    <Logo projectId={projectId} size="small" />
                  </div>
                </Popover>
              );
            }}
          />
          <Route
            exact
            path={SHARE_PATH}
            render={() => {
              if (share) {
                return (
                  <div>
                    <Logo
                      projectId={share.view.projectId}
                      partnerId={share.partnerId || undefined}
                      size="small"
                    />
                  </div>
                );
              }

              return null;
            }}
          />
          <Route
            path="/payments"
            render={() => {
              return (
                <Link to="/">
                  <Logo projectId="default" size="small" />
                </Link>
              );
            }}
          />
          <Route
            path="/payments/history"
            render={() => {
              return (
                <Link to="/">
                  <Logo projectId="default" size="small" />
                </Link>
              );
            }}
          />
        </Switch>
        <Route
          exact
          path="/"
          render={() => {
            return (
              <div className={style.homePageHeader}>
                <div className={style.hamburgerMenu}>
                  <HamburgerMenu />
                </div>
                <div className={style.projectFilter}>
                  <ProjectFilter />
                </div>
                {this.RenderQuickStartButton('home', 'quick start')}
              </div>
            );
          }}
        />
        <Route
          path="/payments"
          exact
          render={() => {
            return (
              <div className={style.floatLeftWrapper}>
                <div className={style.linkWrapper}>
                  <Link to="/">
                    <LeftOutlined />
                  </Link>
                </div>
                <div className={style.backCtaWrapper}>
                  <span>Back to Home</span>
                  <h2 className={style.heading}>Pay</h2>
                </div>
              </div>
            );
          }}
        />
        <Route
          path="/payments/history"
          exact
          render={() => {
            return (
              <div className={style.floatLeftWrapper}>
                <div className={style.linkWrapper}>
                  <Link to="/">
                    <LeftOutlined />
                  </Link>
                </div>
                <div className={style.backCtaWrapper}>
                  <span>Back to Home</span>
                  <h2 className={style.heading}>Payment History</h2>
                </div>
              </div>
            );
          }}
        />
        <Route
          exact
          path={SHARE_PATH}
          render={() => {
            if (share) {
              return (
                <div className={style.shareHeaderContainer}>
                  <AoiDropDown share={share} user={user} />
                  <TypeMenu viewId={share.view.id} readOnly />
                  {share.view && share.view.type === 'map' ? (
                    <MenuItem
                      name={
                        share.view.subType === 'survey'
                          ? 'Analytics'
                          : share.view.subType
                      }
                      isActive
                    />
                  ) : null}
                </div>
              );
            }

            return null;
          }}
        />
        <Route
          exact
          path="/project/:projectId/manageUsers"
          render={({ match }) => {
            const { projectId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                title="Manage Users"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/edit"
          render={({ match }) => {
            const { projectId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                title="Edit Project"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/manageML"
          render={({ match }) => {
            const { projectId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                title="Manage Detect"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/createAOI"
          render={({ match }) => {
            const { projectId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                title="Create Area of Interest"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/createProject"
          render={() => {
            return (
              <div>
                <div className={style.createProject}>
                  <Link to="/">
                    <LeftOutlined />
                  </Link>
                </div>
                <div className={style.backProject}>
                  <span>Back to projects</span>
                  <h2 className={style.projectHeading}>New project</h2>
                </div>
              </div>
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId"
          render={({ match }) => {
            const { projectId } = match.params;

            return (
              <AoiDropDown projectId={projectId} title={null} disableDropdown />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                aoiId={aoiId}
                title={null}
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path={AOI_LISTING_PAGE_PATH}
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                aoiId={aoiId}
                title="Manage Data"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/edit"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                aoiId={aoiId}
                title="Edit AOI"
                backToMissionList
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/missions/:missionId"
          render={({ match }) => {
            const { projectId, aoiId, missionId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Edit Mission"
                missionId={missionId}
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/missions/:missionId/diagnostics"
          render={({ match }) => {
            const { projectId, aoiId, missionId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Mission Diagnostics"
                missionId={missionId}
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/CreateMission"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Create Mission"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/DataUpload/new"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Upload Processed Data"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/DataUpload/edit/:uploadId"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Upload Processed Data"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/InspectionUpload/new"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Upload Inspection Images"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/InspectionUpload/edit/:uploadId"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Upload Inspection Images"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/elevationDifference/new"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Create an Elevation Difference"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/elevationDifference/edit/:elevationDifferenceId"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Manage an Elevation Difference"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/TerrainMaps/new"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Generate Terrain Map"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/TerrainMaps/edit/:id"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Edit Terrain Map"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/ContourMaps/new"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Generate Contour Map"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/ContourMaps/edit/:id"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Edit Contour Map"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/CutAndFillReport/new"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Generate Cut And Fill Report"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/CutAndFillReport/edit/:id"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Edit Cut And Fill Report"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/sandbox"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToView
                aoiId={aoiId}
                title="Sandbox"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/sandbox/drawing/new"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                customBackUrl={`/project/${projectId}/aoi/${aoiId}/sandbox`}
                aoiId={aoiId}
                title="Create Drawing"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/sandbox/drawing/edit/:drawingId"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                customBackUrl={`/project/${projectId}/aoi/${aoiId}/sandbox`}
                aoiId={aoiId}
                title="Manage Drawing"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/sandbox/drawing/upload/:drawingId"
          render={({ match }) => {
            const { projectId, aoiId, drawingId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                customBackUrl={`/project/${projectId}/aoi/${aoiId}/sandbox/drawing/edit/${drawingId}`}
                aoiId={aoiId}
                title="Align Drawing"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path={PROJECT_VIDEOS_NEW_PAGE_PATH}
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="New Video"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path={PROJECT_VIDEOS_EDIT_PAGE_PATH}
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Edit Video"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path={INTERIOR360_VIEWS_NEW_PATH}
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="New Interior 360 View"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path={INTERIOR360_VIEWS_EDIT_PATH}
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="Edit Interior 360 View"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path={DOWNLOADS_LIST_WITH_AOI_PATH}
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                aoiId={aoiId}
                title="Downloads"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path={DOWNLOADS_LIST_WITHOUT_AOI_PATH}
          render={({ match }) => {
            const { projectId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                title="Downloads"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/gcpmarking/session/:sessionId?"
          render={({ match }) => {
            const { projectId, aoiId, sessionId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                backToMissionList
                aoiId={aoiId}
                title="GCPs"
                disableDropdown
                isGcpMarking
                sessionId={sessionId}
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/gcpmarking/session/:sessionId/gcp/:gcpId"
          render={({ match }) => {
            const { projectId, aoiId, sessionId, gcpId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                aoiId={aoiId}
                title="Mark Projection"
                disableDropdown
                isGcpMarking
                sessionId={sessionId}
                gcpId={gcpId}
              />
            );
          }}
        />
        <Route
          exact
          path="/project/:projectId/aoi/:aoiId/gcpmarking/session/:sessionId/gcp/:gcpId/action/:gcpActionType"
          render={({ match }) => {
            const { projectId, aoiId, sessionId, gcpId, gcpActionType } =
              match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                aoiId={aoiId}
                title="Review Projections"
                disableDropdown
                isGcpMarking
                sessionId={sessionId}
                gcpId={gcpId}
                gcpActionType={gcpActionType}
              />
            );
          }}
        />
        <Route
          exact={false}
          path="/project/:projectId/issues"
          render={({ match }) => {
            const { projectId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                title="Issues"
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact={false}
          path="/project/:projectId/aoi/:aoiId/issues"
          render={({ match }) => {
            const { projectId, aoiId } = match.params;

            return (
              <AoiDropDown
                projectId={projectId}
                aoiId={aoiId}
                title="Issues"
                backToView
                disableDropdown
              />
            );
          }}
        />
        <Route
          exact
          path={VIEW_PATH}
          render={({ match, location }) => {
            const { projectId, aoiId, viewId } = match.params;
            const qs = queryString.parse(location.search);

            return (
              <MapViewHeader
                projectId={projectId}
                aoiId={aoiId}
                viewId={viewId}
                {...qs}
              />
            );
          }}
        />
        <div className={style.spacer} />
        <Route
          exact
          path={AOI_LISTING_PAGE_PATH}
          render={({ match }) => {
            const { projectId, aoiId } = match.params;
            const { user } = this.props;

            return showProjectAdminLinks(projectId, user) ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {this.RenderQuickStartButton('manageProject', 'TUTORIALS')}
                <Link
                  className={style.createItemLink}
                  to={`/project/${projectId}/aoi/${aoiId}/edit`}
                >
                  <Button
                    icon="edit"
                    text="Edit AOI"
                    className={style.headBtn}
                  />
                </Link>
              </div>
            ) : null;
          }}
        />
        <Route
          exact
          path="/project/:projectId"
          render={({ match }) => {
            const { projectId } = match.params;
            const { user } = this.props;

            return showProjectAdminLinks(projectId, user) ? (
              <React.Fragment>
                <Link
                  className={style.createItemLink}
                  to={`/project/${projectId}/createAOI`}
                >
                  <Button
                    text="+ CREATE AREA OF INTEREST"
                    className={style.headBtn}
                  />
                </Link>
              </React.Fragment>
            ) : null;
          }}
        />
        <Route
          exact
          path="/"
          render={() => {
            return (
              <Link className={style.createItemLink} to="/createProject">
                <Button text="+ CREATE PROJECT" className={style.headBtn} />
              </Link>
            );
          }}
        />
        <Route
          exact
          path={VIEW_PATH}
          render={({ match }) => {
            const { projectId } = match.params;
            const canSeeUserSandbox = this.canSee(projectId, [
              'project_admin',
              'project_viewer',
            ]);

            return canSeeUserSandbox ? (
              <UserSandboxButton {...match.params} />
            ) : (
              <></>
            );
          }}
        />
        <Route
          exact
          path={VIEW_PATH}
          render={({ match }) => {
            const { projectId } = match.params;
            const canSeeIssuesPage = this.canSee(projectId, [
              'project_admin',
              'project_viewer',
              'read_only',
            ]);

            return canSeeIssuesPage ? (
              <IssueIconButton {...match.params} />
            ) : null;
          }}
        />
        <Route
          exact
          path="/project/:projectId"
          render={({ match }) => {
            const { projectId } = match.params;
            const canSeeIssuesPage = this.canSee(projectId, [
              'project_admin',
              'project_viewer',
              'read_only',
            ]);

            return canSeeIssuesPage ? (
              <IssueIconButton {...match.params} />
            ) : null;
          }}
        />
        <Route
          exact
          path="/project/:projectId"
          render={({ match }) => {
            const { projectId } = match.params;
            const canSeeDownloads = this.canSee(projectId, [
              'project_admin',
              'project_viewer',
            ]);

            return canSeeDownloads ? (
              <DownloadButton {...match.params} disabled={!canSeeDownloads} />
            ) : null;
          }}
        />
        <Route
          exact
          path={VIEW_PATH}
          render={({ match }) => {
            const { projectId } = match.params;
            const canSeeDownloads = this.canSee(projectId, [
              'project_admin',
              'project_viewer',
            ]);

            return canSeeDownloads ? (
              <DownloadButton {...match.params} disabled={!canSeeDownloads} />
            ) : null;
          }}
        />
        <Route
          exact={false}
          path={[
            '/project/:projectId/aoi/:aoiId/issues',
            '/project/:projectId/issues',
          ]}
          render={({ match }) => {
            const { projectId } = match.params;
            const { openManageTagsModal, openReportPreviewModal } = this.props;
            const canSeeReportIcon = this.canSee(projectId, [
              'project_admin',
              'project_viewer',
            ]);

            return (
              <IssuesHeaderIcons
                canSeeReportIcon={canSeeReportIcon}
                openManageTagsModal={openManageTagsModal}
                openReportPreviewModal={openReportPreviewModal}
              />
            );
          }}
        />
        {/* added route here because the route props were not updating in UserMenu */}
        {user ? (
          <Route
            render={(routerProps) => (
              <UserMenu partnerId={partnerId} {...routerProps} />
            )}
          />
        ) : null}
      </div>
    );
  }
}

// TODO: fix types for withRouter
export default withRouter<any, any>(Header);
