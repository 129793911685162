import ApiBase from './ApiBase';
import { processErrorHandling } from '../utils/helpers';
import { GenericApisReturnPromiseTypes } from '../shapes/app';
import { BASE_NAVIGATOR_API_URL } from '../constants/urls';

export default class TransformApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_NAVIGATOR_API_URL);

    this._baseUrl = `/v1/transform`;
  }

  public postTransform(
    processingSessionId: string,
    formData: any
  ): GenericApisReturnPromiseTypes {
    const url = `${this._baseUrl}/${processingSessionId}`;

    return this.postForm(`${url}`, formData)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to transform the VCP file.`,
        });
      });
  }
}
