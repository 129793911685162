// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Overlay_container__OFUql{position:fixed;background:rgba(0,0,0,.5);top:0;right:0;bottom:0;left:0;z-index:10000;overflow:scroll}", "",{"version":3,"sources":["webpack://./src/components/Overlay/index.module.scss"],"names":[],"mappings":"AAEA,0BACE,cAAA,CACA,yBAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CACA,aAAA,CACA,eAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  position: fixed;\n  background: rgba(0, 0, 0, 0.5);\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 10000;\n  overflow: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Overlay_container__OFUql"
};
export default ___CSS_LOADER_EXPORT___;
