import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SurveyTypeSelector from './SurveyTypeSelector';
import { getSurveyTypes } from '../../redux/selectors/views';

const mapStateToProps = (state: any, { viewId }: any): any => {
  return {
    options: getSurveyTypes(state, { viewId }),
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return {};
};

const SurveyTypeSelectorContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SurveyTypeSelector)
);

export default SurveyTypeSelectorContainer;
