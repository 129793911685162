// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OpenLayersMap_olMapContainer__H4jsx{flex-grow:1}", "",{"version":3,"sources":["webpack://./src/components/OpenLayersMap/index.module.scss"],"names":[],"mappings":"AAAA,qCACE,WAAA","sourcesContent":[".olMapContainer {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"olMapContainer": "OpenLayersMap_olMapContainer__H4jsx"
};
export default ___CSS_LOADER_EXPORT___;
