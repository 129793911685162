import { Feature } from 'ol';
import { asArray as colorAsArray } from 'ol/color';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import * as React from 'react';
import { DRAW_LAYER_Z_INDEX } from '../../../../../constants';
import { APP_PRIMARY_COLOR } from '../../../../../constants/colors';

interface IProps {
  issue: any;
  sourceProjection?: string;
  olMap?: any;
}

export class VectorLayerControl extends React.PureComponent<IProps> {
  private layer: any = null;

  public componentDidMount(): void {
    const { issue, olMap } = this.props;

    this.layer = this.drawGeoJsonLayer(issue, olMap);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: any): void {
    this.clear();

    const { issue, olMap } = nextProps;

    this.layer = this.drawGeoJsonLayer(issue, olMap);
  }

  public componentWillUnmount(): void {
    this.clear();
  }

  private clear() {
    const { layer } = this;

    if (layer) {
      const { olMap } = this.props;

      if (olMap) {
        olMap.removeLayer(layer);
        this.layer = null;
      }
    }
  }

  private drawGeoJsonLayer(issue: any, olMap: any) {
    const { sourceProjection } = this.props;

    if (issue && olMap) {
      const { shapeGeoJson } = issue;
      const geoJson = JSON.parse(shapeGeoJson);
      const features = (geoJson?.features || [])
        .map((a: any) => {
          let geometry;

          if (a?.geometry?.type === 'Polygon') {
            geometry = new Polygon(a.geometry.coordinates);
          }

          if (a?.geometry?.type === 'LineString') {
            geometry = new LineString(a.geometry.coordinates);
          }

          if (a?.geometry?.type === 'Point') {
            geometry = new Point(a.geometry.coordinates);
          }

          if (!geometry) {
            try {
              geometry = new LineString(a);
            } catch (e) {
              console.error('could not create geometry from issue feature', e);
            }
          }

          if (!geometry) {
            return null;
          }

          const projectedGeometry = sourceProjection
            ? geometry.transform(
                sourceProjection,
                olMap.getView().getProjection()
              )
            : geometry;

          return new Feature(projectedGeometry);
        })
        .filter((f: any) => !!f);
      const vectorSource = new VectorSource({
        wrapX: false,
        features,
      });
      const layer = new VectorLayer({
        source: vectorSource,
        style: () => {
          return new Style({
            stroke: new Stroke({
              color: APP_PRIMARY_COLOR,
              width: 4,
            }),
            fill: new Fill({
              color: this.getColorWithOpacity(APP_PRIMARY_COLOR, 0.2),
            }),
            image: new CircleStyle({
              radius: 6,
              fill: new Fill({
                color: APP_PRIMARY_COLOR,
              }),
            }),
          });
        },
        zIndex: DRAW_LAYER_Z_INDEX,
      });

      olMap.addLayer(layer);

      return layer;
    }

    return null;
  }

  private getColorWithOpacity = (color: string, opacity: number) => {
    let opaqueColor = colorAsArray(color);

    opaqueColor = opaqueColor.slice();
    opaqueColor[3] = opacity;

    return opaqueColor;
  };

  public render = () => {
    return <></>;
  };
}
