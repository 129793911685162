// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Unit_container__g11LF{font-size:16px}.Unit_container__g11LF label{color:gray;font-size:16px;font-weight:bold;height:-webkit-fill-available;height:-moz-available;height:stretch}.Unit_container__g11LF sup{font-size:12px}.Unit_container__g11LF table{height:100%}.Unit_container__g11LF table tr{height:50%}", "",{"version":3,"sources":["webpack://./src/components/MeasureControlBox/Unit.module.scss"],"names":[],"mappings":"AAEA,uBACE,cAAA,CAEA,6BACE,UAAA,CACA,cAAA,CACA,gBAAA,CACA,6BAAA,CAAA,qBAAA,CAAA,cAAA,CAGF,2BACE,cAAA,CAGF,6BACE,WAAA,CAEA,gCACE,UAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  font-size: 16px;\n\n  & label {\n    color: #808080;\n    font-size: 16px;\n    font-weight: bold;\n    height: stretch;\n  }\n\n  & sup {\n    font-size: 12px;\n  }\n\n  & table {\n    height: 100%;\n\n    & tr {\n      height: 50%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Unit_container__g11LF"
};
export default ___CSS_LOADER_EXPORT___;
