import * as React from 'react';
import GCPs from './GCPs';
import styles from './RenderGCP.module.scss';
import { GcpPlanData } from '../../../api/mission.types';

interface IProps {
  aoiId: string;
  projectId: string;
  missionId: string;
  epsgCode: string;
  plans: any;
  gcpPlanData?: GcpPlanData;
  getGcpPlans: (projectId: string, aoiId: string, missionId: string) => void;
  getGcpPlanData: (planId: string) => void;
  updateGcpPlanData: (plan: any) => void;
  updateGcpPointsData: (newGcpPointsData: any) => void;
  handleGcpPlanChanged: (plan: any) => void;
  openUploadModal: (plan: any) => void;
  history: any;
}

export class RenderGCP extends React.PureComponent<IProps> {
  public render(): React.ReactNode {
    const {
      aoiId,
      handleGcpPlanChanged,
      getGcpPlanData,
      gcpPlanData,
      getGcpPlans,
      projectId,
      missionId,
      openUploadModal,
      plans,
      updateGcpPointsData,
      updateGcpPlanData,
      epsgCode,
      history,
    } = this.props;

    return (
      <React.Fragment>
        {plans.map((plan: any) => {
          return (
            <div key={plan.aoiId} className={styles.flightPlan}>
              <div className={styles.title}>
                <h3>GROUND CONTROL POINTS</h3>
              </div>
              <div>
                <div className={styles.gcpDiv}>
                  <GCPs
                    aoiId={aoiId}
                    projectId={projectId}
                    missionId={missionId}
                    epsgCode={epsgCode}
                    plan={plan}
                    onPlanChange={handleGcpPlanChanged}
                    getGcpPlanData={getGcpPlanData}
                    getGcpPlans={getGcpPlans}
                    gcpPlanData={gcpPlanData}
                    updateGcpPointsData={updateGcpPointsData}
                    updateGcpPlanData={updateGcpPlanData}
                    history={history}
                    openUploadModal={openUploadModal}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default RenderGCP;
