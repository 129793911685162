import * as React from 'react';
import AoiList from '../AoiList/AoiListContainer';
import { User } from '../../api/auth.types';

interface IProps {
  projectId: string;
  user: User;
}

export default class Project extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { projectId, user } = this.props;

    return (
      <div>
        <AoiList projectId={projectId} user={user} />
      </div>
    );
  }
}
