import { LeftOutlined } from '@ant-design/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import AoiItem from '../AoiItem/AoiItem';
import Overlay from '../Overlay';
import style from './AoiDropDown.module.scss';
import { undefinedOrNull } from '../../utils/functs';
import GcpMarkingApis from '../../api/gcpMarking';
import { GenericObjectType, NullOrGenericObjectType } from '../../shapes/app';
import {
  aoiListingPageUrl,
  gcpMarkingGcpIdUrl,
  gcpMarkingListingPageUrl,
} from '../../routes/urls';
import Loading from '../Loading';

interface IProps {
  share?: any;
  user?: any;
  projectId?: string;
  aoiId?: string;
  aoi: any;
  project: any;
  backToMissionList?: boolean;
  backToView?: boolean;
  customBackUrl?: string;
  fetchProject: any;
  resetCurrentView: () => void;
  disableDropdown?: boolean;
  title?: string;
  lastViewIdArray: any[];
  isGcpMarking?: boolean;
  sessionId?: string;
  gcpId?: string;
  gcpActionType?: string;
  missionId?: string;
  mission: NullOrGenericObjectType;
}

interface IState {
  isOpen: boolean;
  selected?: string;
  gcpListData: GenericObjectType[];
}

const gcpMarkingApis = new GcpMarkingApis();

class AoiDropDown extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      isOpen: false,
      gcpListData: [],
    };
  }

  public componentDidMount(): void {
    const { share, project, fetchProject, isGcpMarking, sessionId } =
      this.props;

    if (!project && !share) {
      fetchProject();
    }

    if (isGcpMarking && !undefinedOrNull(sessionId)) {
      this.fetchGcpsBySessions();
    }
  }

  private fetchGcpsBySessions = (): void => {
    const { sessionId } = this.props;

    if (!undefinedOrNull(sessionId)) {
      gcpMarkingApis.listGcpBySession(sessionId).then((res) => {
        const { error: apiError, data: apiData } = res;

        if (apiError) {
          return;
        }

        this.setState(() => {
          return {
            gcpListData: apiData,
          };
        });
      });
    }
  };

  public renderPopUp(): JSX.Element {
    const { project } = this.props;

    return (
      <Overlay onClick={this.handleClose}>
        <div className={style.overlay}>
          <div className={style.title}>{project.name}</div>
          <div className={style.list}>
            {this.getAOIs()
              .filter((aoi: any) => !aoi.deleted)
              .map((aoi: any) => (
                <AoiItem
                  key={aoi.id}
                  {...aoi}
                  center={{
                    latitude: project.latitude,
                    longitude: project.longitude,
                  }}
                />
              ))}
          </div>
        </div>
      </Overlay>
    );
  }

  private renderProjectListLink(): JSX.Element {
    return (
      <Link to="/">
        <LeftOutlined />
      </Link>
    );
  }

  private renderViewLink(
    projectId: string,
    projectName: string,
    aoiId: string,
    viewId: string,
    splitViewId: string
  ): JSX.Element {
    if (splitViewId.length !== 0) {
      return (
        <Link
          to={
            `/project/${projectId}/aoi/${aoiId}/view/${viewId}` +
            `?&splitViewId=` +
            `${splitViewId}`
          }
        >
          <LeftOutlined />
        </Link>
      );
    }

    if (viewId.length !== 0) {
      return (
        <Link to={`/project/${projectId}/aoi/${aoiId}/view/${viewId}`}>
          <LeftOutlined />
        </Link>
      );
    }

    return (
      <Link to={`/project/${projectId}/aoi/${aoiId}`}>
        <LeftOutlined />
      </Link>
    );
  }

  private renderMissionLink(
    projectId: string,
    projectName: string,
    aoiId: string
  ): JSX.Element {
    return (
      <Link to={aoiListingPageUrl(projectId, aoiId)}>
        <LeftOutlined />
      </Link>
    );
  }

  private renderCustomBackUrl(customUrl: string): JSX.Element {
    return (
      <Link to={customUrl}>
        <LeftOutlined />
      </Link>
    );
  }

  private renderGcpMarkingLink(): JSX.Element | null {
    const { projectId, aoiId, sessionId, gcpActionType, gcpId } = this.props;

    if (!undefinedOrNull(projectId) && !undefinedOrNull(aoiId)) {
      if (!undefinedOrNull(sessionId)) {
        if (!undefinedOrNull(gcpActionType) && !undefinedOrNull(gcpId)) {
          // Review projections screen
          return (
            <Link to={gcpMarkingGcpIdUrl(projectId, aoiId, sessionId, gcpId)}>
              <LeftOutlined />
            </Link>
          );
        }

        // Mark projection screen
        return (
          <Link to={gcpMarkingListingPageUrl(projectId, aoiId, sessionId)}>
            <LeftOutlined />
          </Link>
        );
      }
    }

    return null;
  }

  private renderProjectLink(projectId: string): JSX.Element {
    return (
      <Link to={`/project/${projectId}`}>
        <LeftOutlined />
      </Link>
    );
  }

  private renderProjectName(projectName: string): JSX.Element {
    const { share, user } = this.props;

    return (
      <div className={style.header}>
        {
          // removing chevron if not authenticated, in a share view
          share && !user ? null : (
            <i
              className="fa fa-chevron-left"
              style={{ marginRight: '0.5em' }}
            />
          )
        }
        {projectName}
      </div>
    );
  }

  @autobind
  private handleClose(): void {
    this.setState({ isOpen: false });
  }

  @autobind
  private handleOpen(): void {
    const { disableDropdown } = this.props;

    if (!disableDropdown) {
      this.setState({ isOpen: true });
    }
  }

  private getAOIs = () => {
    const { project } = this.props;

    return project ? project.aois || [] : [];
  };

  private getGcpTitle = (): string | null => {
    const { gcpId } = this.props;
    const { gcpListData } = this.state;

    const filteredGcpListData = gcpListData.filter((a) => a.id === gcpId);

    if (!undefinedOrNull(filteredGcpListData) && filteredGcpListData[0]) {
      return filteredGcpListData[0].name;
    }

    return null;
  };

  private renderShareHeader = (): JSX.Element => {
    const { share, user } = this.props;
    const { view, projectName, aoiName } = share;

    return (
      <div className={style.container}>
        <div>
          {user ? (
            <div className={style.linkWrapper}>
              {this.renderProjectLink(view.projectId)}
            </div>
          ) : null}
          <div className={style.aoiNameAlign}>
            <label className={style.projectNameAlign}>{projectName}</label>
            {aoiName ? <div className={style.content}>{aoiName}</div> : null}
          </div>
        </div>
      </div>
    );
  };

  public render(): React.ReactNode {
    const {
      project,
      disableDropdown,
      aoi,
      share,
      backToMissionList,
      backToView,
      customBackUrl,
      lastViewIdArray,
      isGcpMarking,
      mission,
    } = this.props;
    let { title } = this.props;
    const { isOpen, selected } = this.state;

    if (project && selected) {
      return <Redirect to={`/project/${project.id}/aoi/${selected}`} />;
    }

    if (mission && mission.name) {
      title = `${title} (${mission.name})`;
    }

    if (share) {
      return this.renderShareHeader();
    }

    if (project) {
      if (isGcpMarking) {
        const getGcpTitle = this.getGcpTitle();

        return (
          <div className={style.container}>
            <div onClick={this.handleOpen}>
              <div className={style.linkWrapper}>
                {this.renderGcpMarkingLink()}
              </div>
              <div className={style.aoiNameAlign}>
                <label className={style.projectNameAlign}>{project.name}</label>
                {aoi ? (
                  <div
                    className={style.content}
                    style={{ cursor: disableDropdown ? '' : 'pointer' }}
                  >
                    {aoi.name}
                    {title ? ` - ${title}` : ''}
                    {getGcpTitle ? ` | ${getGcpTitle}` : ''}
                  </div>
                ) : (
                  <div className={style.content}>
                    {title ? ` - ${title}` : ''}
                    {getGcpTitle ? ` | ${getGcpTitle}` : ''}
                  </div>
                )}
              </div>
            </div>
            {isOpen && this.renderPopUp()}
          </div>
        );
      }

      if (!aoi) {
        return (
          <div className={style.container}>
            <div onClick={this.handleOpen}>
              <div className={style.linkWrapper}>
                {title
                  ? this.renderProjectLink(project.id)
                  : this.renderProjectListLink()}
              </div>
              <div className={style.aoiNameAlign}>
                {aoi ? (
                  <div
                    className={style.contentP}
                    style={{ cursor: disableDropdown ? '' : 'pointer' }}
                  >
                    {aoi.name}
                    {title ? ` - ${title}` : ''}
                  </div>
                ) : (
                  <div className={style.contentP}>
                    {project.name}
                    {title ? ` - ${title}` : ''}
                  </div>
                )}
              </div>
            </div>
            {isOpen && this.renderPopUp()}
          </div>
        );
      }

      if (backToMissionList) {
        return (
          <div className={style.container}>
            <div onClick={this.handleOpen}>
              <div className={style.linkWrapper}>
                {this.renderMissionLink(project.id, aoi.name, aoi.id)}
              </div>
              <div className={style.aoiNameAlign}>
                <label className={style.projectNameAlign}>{project.name}</label>
                {aoi ? (
                  <div
                    className={style.content}
                    style={{ cursor: disableDropdown ? '' : 'pointer' }}
                  >
                    {aoi.name}
                    {title ? ` - ${title}` : ''}
                  </div>
                ) : (
                  <div className={style.content}>{title || ''}</div>
                )}
              </div>
            </div>
            {isOpen && this.renderPopUp()}
          </div>
        );
      }

      if (backToView) {
        const lastViewId = lastViewIdArray[0] ? lastViewIdArray[0] : '';
        const lastSplitViewId = lastViewIdArray[1] ? lastViewIdArray[1] : '';

        return (
          <div className={style.container}>
            <div onClick={this.handleOpen}>
              <div className={style.linkWrapper}>
                {this.renderViewLink(
                  project.id,
                  aoi.name,
                  aoi.id,
                  lastViewId,
                  lastSplitViewId
                )}
              </div>
              <div className={style.aoiNameAlign}>
                <label className={style.projectNameAlign}>{project.name}</label>
                {aoi ? (
                  <div
                    className={style.content}
                    style={{ cursor: disableDropdown ? '' : 'pointer' }}
                  >
                    {aoi.name}
                    {title ? ` - ${title}` : ''}
                  </div>
                ) : (
                  <div className={style.content}>{title || ''}</div>
                )}
              </div>
            </div>
            {isOpen && this.renderPopUp()}
          </div>
        );
      }

      if (customBackUrl) {
        return (
          <div className={style.container}>
            <div onClick={this.handleOpen}>
              <div className={style.linkWrapper}>
                {this.renderCustomBackUrl(customBackUrl)}
              </div>
              <div className={style.aoiNameAlign}>
                <label className={style.projectNameAlign}>{project.name}</label>
                {aoi ? (
                  <div
                    className={style.content}
                    style={{ cursor: disableDropdown ? '' : 'pointer' }}
                  >
                    {aoi.name}
                    {title ? ` - ${title}` : ''}
                  </div>
                ) : (
                  <div className={style.content}>{title || ''}</div>
                )}
              </div>
            </div>
            {isOpen && this.renderPopUp()}
          </div>
        );
      }

      return (
        <div className={style.container}>
          <div onClick={this.handleOpen}>
            <div className={style.linkWrapper}>
              {this.renderProjectLink(project.id)}
            </div>
            <div className={style.aoiNameAlign}>
              <label className={style.projectNameAlign}>{project.name}</label>
              {aoi ? (
                <div
                  className={style.content}
                  style={{ cursor: disableDropdown ? '' : 'pointer' }}
                >
                  {aoi.name}
                  {title ? ` - ${title}` : ''}
                </div>
              ) : (
                <div className={style.content}>{title || ''}</div>
              )}
            </div>
          </div>
          {isOpen && this.renderPopUp()}
        </div>
      );
    }

    return (
      <div>
        <Loading type="ellipsis" />
      </div>
    );
  }
}

export default AoiDropDown;
