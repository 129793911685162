import * as React from 'react';
import { Link } from 'react-router-dom';

import style from './index.module.scss';

interface IProps {
  projectId?: string;
  aoiId?: string;
}

export default class UserSandboxButton extends React.PureComponent<IProps> {
  public render() {
    const { projectId, aoiId } = this.props;

    if (!projectId || !aoiId) {
      return <></>;
    }

    return (
      <>
        <Link
          className={style.container}
          to={`/project/${projectId}/aoi/${aoiId}/sandbox`}
          title="Sandbox"
        >
          <i className="fa fa-wrench" />
        </Link>
      </>
    );
  }
}
