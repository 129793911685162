// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RemoveShapeControlBox_container__bQQ2u{padding:8px}.RemoveShapeControlBox_padTop__uRaYx{padding-top:16px}", "",{"version":3,"sources":["webpack://./src/components/View/ViewControls/MapMakerControls/RemoveShapeControlBox/index.module.scss"],"names":[],"mappings":"AAAA,wCACE,WAAA,CAGF,qCACE,gBAAA","sourcesContent":[".container {\n  padding: 8px;\n}\n\n.padTop {\n  padding-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RemoveShapeControlBox_container__bQQ2u",
	"padTop": "RemoveShapeControlBox_padTop__uRaYx"
};
export default ___CSS_LOADER_EXPORT___;
