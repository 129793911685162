import { Input, Select } from 'antd';
import * as React from 'react';
import rewind from '@mapbox/geojson-rewind';
import { DOCUMENTATION_URL_LIST } from '../../constants/urls';
import { GenericObjectType } from '../../shapes/app';
import { toMetersEquivalent } from '../../utils/functs';
import { convertVolume, getValidGeoJson } from '../../utils/helpers';
import { Button } from '../Button';
import DocumentationLink from '../DocumentationLink';
import ShapeInfo from '../MeasureControlBox/ShapeInfo';
import Unit from '../MeasureControlBox/Unit';
import { Spinner } from '../Spinner/Spinner';
import style from './VolumeCalculator.module.scss';

const SelectOption = Select.Option;

export type VolumeType = 'FlatPlane' | 'BestFitPlane';

export interface IOwnProps {
  shape: any;
  viewId?: string;
}

interface IOwnState {
  blobUrl: string;
}

export interface IStateProps {
  view?: GenericObjectType;
  volume?: number;
  loading: boolean;
  error: any;
  elevation: number | null;
  elevationList: number[] | null;
  volumeType: VolumeType | null;
}

export interface IDispatchProps {
  fetchVolume: (elevation: number | null, volumeType: string) => void;
  resetVolumeValues: () => void;
  setElevationValue: (elevation: number | null) => void;
  resetElevationValue: () => void;
  updateVolumeViewId: (viewId: string) => void;
  updateVolumeViewUnits: (viewUnits: string) => void;
  resetVolumeViewData: () => void;
  setVolumeType: (volumeType: VolumeType) => void;
}

type IProps = IOwnProps & IDispatchProps & IStateProps;

class VolumeCalculator extends React.Component<IProps, IOwnState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      blobUrl: '#',
    };
    this.handleElevation = this.handleElevation.bind(this);
    this.calculateVolume = this.calculateVolume.bind(this);
  }

  public componentDidMount() {
    const {
      resetVolumeValues,
      resetVolumeViewData,
      elevation,
      updateVolumeViewId,
      updateVolumeViewUnits,
      shape,
      viewId,
      volumeType: _volumeType,
    } = this.props;

    const volumeType = _volumeType || 'FlatPlane';

    resetVolumeValues();
    resetVolumeViewData();

    if (viewId) {
      updateVolumeViewId(viewId);
    }

    updateVolumeViewUnits(this.getViewUnits());

    if (volumeType === 'FlatPlane') {
      if (elevation !== null && !Number.isNaN(elevation)) {
        this.calculateVolume(elevation, 'FlatPlane');
      }
    } else if (volumeType === 'BestFitPlane') {
      this.calculateVolume(null, 'BestFitPlane');
    }

    const url = this.getPolygonBlobURL(shape.geometry);

    this.setState({ blobUrl: url });
  }

  public UNSAFE_componentWillReceiveProps(newProps: IProps) {
    const {
      viewId: newViewId,
      view: newView,
      volumeType: newVolumeType,
    } = newProps;
    const {
      viewId: oldViewId,
      view: oldView,
      updateVolumeViewId,
      updateVolumeViewUnits,
      volumeType: oldVolumeType,
      resetVolumeValues,
    } = this.props;

    if (newViewId && newViewId !== oldViewId) {
      updateVolumeViewId(newViewId);
    }

    if (newView !== oldView) {
      updateVolumeViewUnits(this._getViewUnits(newView));
    }

    if (oldVolumeType !== newVolumeType) {
      resetVolumeValues();
    }
  }

  public componentWillUnmount() {
    const { resetVolumeViewData } = this.props;
    const { blobUrl } = this.state;

    URL.revokeObjectURL(blobUrl);
    resetVolumeViewData();
  }

  private calculateVolume(elevation: number | null, volumeType: VolumeType) {
    const { fetchVolume } = this.props;

    fetchVolume(elevation, volumeType);
  }

  private handleElevation(event: React.ChangeEvent<HTMLInputElement>) {
    const { setElevationValue } = this.props;
    const elev = Number(event.target.value);

    if (
      elev !== null &&
      !Number.isNaN(elev) &&
      event.target.value.trim().length > 0
    ) {
      setElevationValue(elev);
    } else {
      setElevationValue(null);
    }
  }

  private getViewUnits(): string {
    const { view } = this.props;

    return this._getViewUnits(view);
  }

  private _getViewUnits(view?: GenericObjectType): string {
    if (!view) {
      return 'm';
    }

    if (view.coordinateUnits) {
      if (view.coordinateUnits === 'METRES') return 'm';

      return 'ft';
    }

    return 'm';
  }

  private getAverageElevation(): number | null {
    const { elevationList } = this.props;

    if (elevationList && elevationList.length > 0) {
      const sum = elevationList.reduce((a, b) => a + b, 0);

      return toMetersEquivalent(
        sum / elevationList.length,
        this.getViewUnits() === 'm' ? 'meters' : 'feet'
      );
    }

    return null;
  }

  private isVolumeButtonEnabled() {
    const { elevation, volumeType: _volumeType } = this.props;
    const volumeType = _volumeType || 'FlatPlane';

    if (volumeType === 'FlatPlane') {
      return !(elevation == null || Number.isNaN(elevation));
    }

    return true;
  }

  private getPolygonBlobURL(polygon: GenericObjectType): string {
    const validGeoJsonPolygon = rewind(getValidGeoJson(polygon));
    const blob = new Blob([JSON.stringify(validGeoJsonPolygon)], {
      type: 'text/geojson',
    });
    const url = URL.createObjectURL(blob);

    return url;
  }

  public render(): React.ReactNode {
    const {
      volume,
      loading,
      error,
      elevation,
      volumeType: _volumeType,
      setVolumeType,
    } = this.props;
    const { blobUrl } = this.state;
    const volumeType = _volumeType || 'FlatPlane';

    if (loading) {
      return <Spinner />;
    }

    if (error) {
      return (
        <div className={style.container}>
          {error.message || 'Something went wrong'}
        </div>
      );
    }

    return (
      <div>
        <div className={style.volumeTypeContainer}>
          <Select
            value={volumeType}
            defaultValue={volumeType}
            onChange={(val: any) => {
              setVolumeType(val);
            }}
            className={style.volumeTypeSelect}
            dropdownStyle={{ zIndex: 20001 }}
          >
            <SelectOption value="FlatPlane">Horizontal Plane</SelectOption>
            <SelectOption value="BestFitPlane">Best-fit Plane</SelectOption>
          </Select>
          <DocumentationLink
            className={style.toolTipWrapper}
            href={DOCUMENTATION_URL_LIST.volume}
            toolTipPosition="right"
          />
        </div>
        {volumeType === 'FlatPlane' && (
          <div>
            <div className={style.titleWrapper}>
              <label>Plane Elevation ({this.getViewUnits()})</label>
            </div>
            {this.getAverageElevation() !== null ? (
              <p className={style.elevationHint}>
                Average Elevation: {this.getAverageElevation()}{' '}
                {this.getViewUnits()}
              </p>
            ) : null}
            <Input
              defaultValue={`${elevation}`}
              type="number"
              placeholder="Plane elevation to calculate volume against"
              onChange={this.handleElevation}
            />
          </div>
        )}
        <Button
          disabled={!this.isVolumeButtonEnabled()}
          onClick={() => {
            this.calculateVolume(elevation || null, volumeType);
          }}
          className={style.calcButton}
          type="secondary"
        >
          Calculate Volume
        </Button>
        <a
          className={style.downloadLinkContainer}
          href={blobUrl}
          download="polygon.geojson"
        >
          <i className="fa fa-download" aria-hidden="true" />
          Download Polygon
        </a>
        {volume !== null && volume !== undefined ? (
          <div>
            <hr className={style.line} />
            <ShapeInfo>
              <Unit label="Volume">
                {volume && convertVolume(volume, 'meter')}
              </Unit>
              <Unit label="">{volume && convertVolume(volume, 'yard')}</Unit>
            </ShapeInfo>
          </div>
        ) : null}
      </div>
    );
  }
}

export default VolumeCalculator;
