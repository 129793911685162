// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AoiData_container__0rzFS{padding:0 100px 20px 100px;position:relative !important}", "",{"version":3,"sources":["webpack://./src/components/AoiData/index.module.scss"],"names":[],"mappings":"AAEA,0BACE,0BAAA,CACA,4BAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  padding: 0 100px 20px 100px;\n  position: relative !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AoiData_container__0rzFS"
};
export default ___CSS_LOADER_EXPORT___;
