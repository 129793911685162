import ApiBase from './ApiBase';
import { GenericApisReturnPromiseTypes } from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_WORKBENCH_API_URL } from '../constants/urls';

export default class CutAndFillReportMapsApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_WORKBENCH_API_URL);

    this._baseUrl = `/v1/postprocess/projects`;
  }

  public listCutAndFillReports(
    projectId: string,
    aoiId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(
      `${this._baseUrl}/${projectId}/aois/${aoiId}/cutfillreports`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the Cut And Fill Report.`,
          isArray: true,
        });
      });
  }

  public postCutAndFillReport(
    projectId: string,
    aoiId: string,
    formData: {},
    reportId?: string | null
  ): GenericApisReturnPromiseTypes {
    let url = `${this._baseUrl}/${projectId}/aois/${aoiId}/cutfillreports`;

    if (reportId) {
      url += `/${reportId}`;
    }

    return this.post(`${url}`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to submit the Cut And Fill Report.`,
        });
      });
  }

  public getCutAndFillReport(
    projectId: string,
    aoiId: string,
    reportId?: string | null
  ): GenericApisReturnPromiseTypes {
    const url = `${this._baseUrl}/${projectId}/aois/${aoiId}/cutfillreports/${reportId}`;

    return this.get(`${url}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the Cut And Fill Report.`,
        });
      });
  }

  public updateCutAndFillReport(
    projectId: string,
    aoiId: string,
    formData: {},
    reportId: string | null
  ): GenericApisReturnPromiseTypes {
    const url = `${this._baseUrl}/${projectId}/aois/${aoiId}/cutfillreports/${reportId}`;

    return this.patch(`${url}`, formData)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to update the Cut And Fill Report.`,
        });
      });
  }

  public getCutAndFillReportPdf(
    projectId: string,
    aoiId: string,
    reportId: string | null
  ): string {
    return `${BASE_WORKBENCH_API_URL}${this._baseUrl}/${projectId}/aois/${aoiId}/cutfillreports/${reportId}/pdf`;
  }

  public getCutAndFillReportBoundary(
    projectId: string,
    aoiId: string,
    reportId: string
  ): GenericApisReturnPromiseTypes {
    const url = `${this._baseUrl}/${projectId}/aois/${aoiId}/cutfillreports/${reportId}/aoi`;

    return this.get(`${url}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the Cut And Fill Report.`,
        });
      });
  }
}
