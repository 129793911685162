import ViewsAPI from '../../api/views';
import { GenericObjectType } from '../../shapes/app';
import { ILayer } from '../../components/LegendControlBox/LegendControlBox';
import { View } from '../../api/views.types';

const viewApi = new ViewsAPI();

export function fetchViews(projectId: string, aoiId: string): {} {
  return {
    type: 'VIEWS_FETCH',
    payload: viewApi.getViews(projectId, aoiId),
  };
}

export function fetchReports(viewId: string): {} {
  return {
    type: 'REPORTS_FETCH',
    payload: viewApi.getReports(viewId),
  };
}

export function fetchDownloads(viewId: string): {} {
  return {
    type: 'DOWNLOADS_FETCH',
    payload: viewApi.getDownloads(viewId),
  };
}

export function fetchSingleView(projectId: string, aoiId: string): {} {
  return {
    type: 'SINGLE_VIEW_FETCH',
    payload: viewApi.getViews(projectId, aoiId),
  };
}

export function resetCurrentView(): {} {
  return {
    type: 'RESET_CURRENT_VIEW',
  };
}

export function setLastView(viewId: string, splitViewId: string): {} {
  return {
    type: 'SET_LAST_VIEW',
    payload: [viewId, splitViewId],
  };
}

export function updateViewsManually(views: GenericObjectType[]): {} {
  return {
    type: 'MANUAL_UPDATE_VIEWS',
    payload: { data: views },
  };
}

export const addViewManually = (view: View) => {
  return {
    type: 'MANUAL_ADD_VIEW',
    payload: { data: view },
  };
};

export function resetViewsData(): {} {
  return {
    type: 'RESET_VIEWS_DATA',
  };
}

export function updateViewLayers(layers: ILayer[]) {
  return {
    type: 'UPDATE_VIEW_LAYERS',
    payload: layers,
  };
}

export function resetViewLayers() {
  return {
    type: 'RESET_VIEW_LAYERS',
  };
}

export function fetchViewById(
  projectId: string,
  aoiId: string,
  viewId: string
) {
  return {
    type: 'VIEW_FETCH_BY_ID',
    payload: viewApi.getView(projectId, aoiId, viewId),
  };
}
