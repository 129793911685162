import { Feature } from 'ol';
import { Point, Polygon } from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { fromLonLat } from 'ol/proj';
import { OSM } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import View from 'ol/View';
import * as React from 'react';
import { isNumber } from 'src/utils/functs';

export interface IProps {
  boundaryPointsList?: { lat: number; lng: number }[][];
  markerPointsList?: { pos: { lat: number; lng: number } }[];
  onMarkerClick?: (idx: number) => void;
}

class OSMMap extends React.Component<any, any> {
  private olMapReference = React.createRef<HTMLDivElement>();

  private map: Map;

  private vectorSource: VectorSource;

  private view: View;

  private styles = {
    Point: new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: 'rgba(255, 0, 0, 1)',
        }),
        stroke: new Stroke({ color: 'red', width: 1 }),
      }),
    }),
    Polygon: new Style({
      stroke: new Stroke({
        color: 'blue',
        lineDash: [4],
        width: 3,
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.1)',
      }),
    }),
  };

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    const mapEl = this.olMapReference.current;
    const { onMarkerClick } = this.props;

    if (mapEl == null) {
      return;
    }

    const { markerPointsList } = this.props;
    let center = [0, 0];

    if (markerPointsList && markerPointsList.length > 0) {
      let lat = 0.0;
      let lon = 0.0;
      let count = 0;

      markerPointsList.forEach((p: any) => {
        count += 1;
        lat += p.pos.lat;
        lon += p.pos.lng;
      });
      center = [lon / count, lat / count];
    }

    const view = new View({
      center: fromLonLat(center),
      zoom: 17,
      maxZoom: 20,
      minZoom: 2,
    });

    this.view = view;

    const osmLayer = new TileLayer({
      source: new OSM(),
    });

    const vectorSource = new VectorSource({});

    this.vectorSource = vectorSource;
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: (feature: any) => {
        return this.styles[feature.getGeometry().getType()];
      },
    });

    this.map = new Map({
      target: mapEl,
      layers: [osmLayer, vectorLayer],
      view,
    });

    this.map.on('click', (e) => {
      this.map.forEachFeatureAtPixel(e.pixel, (f: any) => {
        if (f.id && isNumber(f.id) && onMarkerClick) {
          onMarkerClick(f.id - 1);
        }
      });
    });

    this.syncFeatures();
  }

  public componentDidUpdate(): void {
    this.syncFeatures();
  }

  syncFeatures = () => {
    if (!this.map || !this.vectorSource || !this.view) {
      return;
    }

    this.vectorSource.clear();

    const { boundaryPointsList, markerPointsList } = this.props;

    if (markerPointsList) {
      markerPointsList.forEach((p: any, idx: number) => {
        const point = fromLonLat([p.pos.lng, p.pos.lat]);
        const f = new Feature(new Point(point));

        (f as any).id = idx + 1;
        this.vectorSource.addFeature(f);
      });
    }

    if (boundaryPointsList && boundaryPointsList.length > 0) {
      const boundary = boundaryPointsList[0];

      if (boundary && boundary.length > 0) {
        const coords = boundary.map((bp: any) => {
          const point = fromLonLat([bp.lng, bp.lat]);

          return point;
        });
        const f = new Feature(new Polygon([coords]));

        this.vectorSource.addFeature(f);
        const extent = f.getGeometry()?.getExtent();

        if (extent) {
          this.view.fit(extent);
        }
      }
    }
  };

  render() {
    return (
      <div
        ref={this.olMapReference}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    );
  }
}

export default OSMMap;
