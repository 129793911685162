import autobind from 'autobind-decorator';
import * as React from 'react';
import Dropdown from '../../DropDown/DropDown';
import style from './ViewToggle.module.scss';
import { TerrainMapsTabsLayout2StateSelectedDsmViewIdTypeTypes } from './index';

interface IProps {
  selectedDsmViewType?: string;
  terrainMapsSurfaceViewTypeList: any[];
  onToggleDsmViewType?: (
    viewType: TerrainMapsTabsLayout2StateSelectedDsmViewIdTypeTypes
  ) => void;
}

class TerrainMapsTabsLayout2ViewToggle extends React.Component<IProps> {
  private fillDsmViewTypeToggleOptions = () => {
    const { terrainMapsSurfaceViewTypeList } = this.props;
    const dsmViewTypeToggleOptions = [
      {
        label: 'Elevation View',
        value: 'elevationViewId',
        viewType: 'elevationViewId',
      },
    ];

    if (terrainMapsSurfaceViewTypeList.length > 0) {
      if (terrainMapsSurfaceViewTypeList.indexOf('aerialViewId') > -1) {
        dsmViewTypeToggleOptions.push({
          label: 'Aerial View',
          value: 'aerialViewId',
          viewType: 'aerialViewId',
        });
      }
    }

    return dsmViewTypeToggleOptions;
  };

  @autobind
  private handleOnToggle(option: any): void {
    const { onToggleDsmViewType } = this.props;

    if (onToggleDsmViewType) {
      onToggleDsmViewType(option.value);
    }
  }

  public render(): React.ReactNode {
    const { selectedDsmViewType } = this.props;

    return (
      <div className={style.container}>
        <Dropdown
          options={this.fillDsmViewTypeToggleOptions()}
          value={selectedDsmViewType}
          onChange={this.handleOnToggle}
        />
      </div>
    );
  }
}

export default TerrainMapsTabsLayout2ViewToggle;
