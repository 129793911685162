import {
  AuthType,
  CHECK_LOGIN_FULFILLED,
  CHECK_LOGIN_PENDING,
  CHECK_LOGIN_REJECTED,
  LOGIN_FULFILLED,
  LOGIN_PENDING,
  LOGIN_REJECTED,
  LOGOUT_FULFILLED,
} from '../actions';

interface IAuthState {
  authenticated: boolean;
  authorized: boolean;
  loading: boolean;
  error?: string;
  user: any;
  success: boolean;
}

export interface IAuthAction {
  type: AuthType;
  payload?: any;
}

// eslint-disable-next-line
const initialState: IAuthState = {
  authenticated: false,
  authorized: true,
  loading: false,
  success: false,
} as IAuthState;

interface ILoginState {
  loading: boolean;
  authenticated: boolean;
  error?: string;
}

interface ILoginAction {
  type: AuthType;
  payload?: any;
}

// eslint-disable-next-line
const initialLoginState: ILoginState = {
  authenticated: false,
  loading: false,
  error: undefined,
} as ILoginState;

export function login(
  state = initialLoginState,
  action: ILoginAction
): ILoginState {
  const { type } = action;

  switch (type) {
    case LOGIN_FULFILLED:
      return {
        ...state,
        loading: false,
        authenticated: true,
      };

    case LOGIN_PENDING:
      return {
        ...state,
        loading: true,
        authenticated: false,
      };

    case LOGIN_REJECTED:
      return {
        ...state,
        loading: false,
        authenticated: false,
        error: 'Invalid username or password',
      };

    default:
      return state;
  }
}

export default function auth(
  state: IAuthState = initialState,
  action: IAuthAction
): IAuthState {
  const { type, payload } = action;

  switch (type) {
    case CHECK_LOGIN_FULFILLED:
      return {
        ...state,
        user: payload && payload.data,
        authenticated: true,
        loading: false,
      };

    case CHECK_LOGIN_PENDING:
      return {
        ...state,
        loading: true,
      };

    case CHECK_LOGIN_REJECTED:
      return {
        ...state,
        loading: false,
      };

    case LOGOUT_FULFILLED:
      window.location.reload();

      return {
        ...state,
        loading: false,
        authenticated: false,
      };

    case 'INSERT_PROJECT_USER_ROLE':
      return {
        ...state,
        user: {
          ...state.user,
          project_roles: [...state.user.project_roles, payload],
        },
      };

    case 'UNAUTHORIZED':
      return {
        ...state,
        authorized: false,
      };

    case 'RESET_PASSWORD_PENDING':
    case 'RESET_MAIL_PENDING':
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case 'RESET_PASSWORD_FULFILLED':
    case 'RESET_MAIL_FULFILLED':
      return {
        ...state,
        error: undefined,
        loading: false,
        success: true,
      };

    case 'RESET_MAIL_REJECTED':
      return {
        ...state,
        loading: false,
        error: 'Error sending reset mail!',
      };

    case 'RESET_PASSWORD_REJECTED':
      return {
        ...state,
        loading: false,
        error: 'Error updating password!',
      };

    case 'CLEAR_AUTH_STATE':
      return initialState;

    default:
      return state;
  }
}
