// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmModal_container__C5V0k .ant-modal-body{font-size:1.5em}", "",{"version":3,"sources":["webpack://./src/components/ConfirmModal/ConfirmModal.module.scss"],"names":[],"mappings":"AAIE,+CACE,eAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */\n  & :global .ant-modal-body {\n    font-size: 1.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ConfirmModal_container__C5V0k"
};
export default ___CSS_LOADER_EXPORT___;
