// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Resizer_container__KpLX0{display:flex}.Resizer_container__KpLX0 .Resizer_right__OZh06,.Resizer_container__KpLX0 .Resizer_left__T0Yin{cursor:ew-resize;height:100%;width:9px;border:1px solid #d6d9dc;background-color:#eff0f1}", "",{"version":3,"sources":["webpack://./src/components/Resizer/index.module.scss","webpack://./src/styles/scss/base/mixins/_display_flex.scss"],"names":[],"mappings":"AAEA,0BCGE,YAAA,CAAA,+FDEE,gBAAA,CACA,WAAA,CACA,SAAA,CACA,wBAAA,CACA,wBAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  @include display_flex();\n\n  .right,\n  .left {\n    cursor: ew-resize;\n    height: 100%;\n    width: 9px;\n    border: 1px solid #d6d9dc;\n    background-color: #eff0f1;\n  }\n}\n","@mixin display_flex() {\n  display: -webkit-box;\n  display: -moz-box;\n  display: -ms-flexbox;\n  display: -webkit-flex;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Resizer_container__KpLX0",
	"right": "Resizer_right__OZh06",
	"left": "Resizer_left__T0Yin"
};
export default ___CSS_LOADER_EXPORT___;
