import classnames from 'classnames';
import * as React from 'react';
import _BrandingApi, { fetchHostLogo, fetchLogo } from '../../api/branding';
import style from './Logo.module.scss';
import { undefinedOrNull, undefinedOrNullChained } from '../../utils/functs';
import { ICheckBranding, IProjectBranding } from '../../shapes/branding';

const BrandingApi = new _BrandingApi();

interface IProps {
  size?: 'small' | 'large';
  className?: string;
  projectId?: string;
  partnerId?: string;
  authenticated?: boolean;
}

interface IState {
  brandingData: {
    url: null | string;
    name: null | string;
  };
}

class Logo extends React.Component<IProps, IState> {
  public static defaultProps = {
    size: 'small',
  };

  public constructor(props: IProps) {
    super(props);

    this.state = {
      brandingData: {
        name: 'AspecScire',
        url: null,
      },
    };
  }

  public componentDidMount(): void {
    const { partnerId, projectId } = this.props;

    if (undefinedOrNull(partnerId) && undefinedOrNull(projectId)) {
      BrandingApi.checkDefaultBranding().then((res: ICheckBranding) => {
        if (!undefinedOrNull(res)) {
          this.setState((prevState) => {
            return {
              brandingData: {
                name: undefinedOrNullChained(res, 'data.name')
                  ? prevState.brandingData.name
                  : res.data.name,
                url: undefinedOrNullChained(res, 'data.url')
                  ? prevState.brandingData.url
                  : res.data.url,
              },
            };
          });
        }
      });
    }

    if (undefinedOrNull(partnerId)) {
      BrandingApi.getProjectBranding(projectId).then(
        (res: IProjectBranding) => {
          if (!undefinedOrNull(res)) {
            this.setState({
              brandingData: {
                name: undefinedOrNullChained(res, 'data.name')
                  ? 'AspecScire'
                  : res.data.name,
                url: undefinedOrNullChained(res, 'data.url')
                  ? null
                  : res.data.url,
              },
            });
          }
        }
      );
    } else {
      BrandingApi.checkBranding(partnerId).then((res: ICheckBranding) => {
        if (!undefinedOrNull(res)) {
          this.setState((prevState) => {
            return {
              brandingData: {
                name: undefinedOrNullChained(res, 'data.name')
                  ? prevState.brandingData.name
                  : res.data.name,
                url: undefinedOrNullChained(res, 'data.url')
                  ? prevState.brandingData.url
                  : res.data.url,
              },
            };
          });
        }
      });
    }
  }

  public render(): React.ReactNode {
    const { size, projectId, className, partnerId, authenticated } = this.props;
    const { brandingData } = this.state;

    return (
      <div className={classnames(style.container, className)}>
        <img
          src={
            undefinedOrNull(partnerId) && undefinedOrNull(projectId)
              ? fetchHostLogo(size)
              : fetchLogo(projectId, size, partnerId, authenticated)
          }
          // @ts-ignore
          alt={brandingData.name}
          // @ts-ignore
          title={brandingData.name}
          onError={(e) => {
            if (e.currentTarget.src === fetchHostLogo(size)) {
              e.currentTarget.src = fetchLogo(
                projectId,
                size,
                partnerId,
                authenticated
              );
            }
          }}
        />
      </div>
    );
  }
}

export default Logo;
