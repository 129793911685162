import * as React from 'react';
import { APP_BASE_URL } from '../../../../constants/urls';
import * as ControlBox from '../../../ControlBox/ControlBox';
import PDFViewer from '../../../PDFViewer/PDFViewer';
import { ReportsControlProps } from '../index.types';
import style from './index.module.scss';

interface IState {
  selectedReport?: any;
}

function ReportItem({ name, id, mimeType, onClick }: any): JSX.Element {
  const url = `${APP_BASE_URL}/capi/api/v1/reports/${id}/artifact`;
  const handleClick =
    mimeType === 'application/pdf'
      ? (e: any) => {
          e.preventDefault();
          onClick(url);
        }
      : () => {
          /**/
        };

  return (
    <a
      onClick={handleClick}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={style.report_item}>
        <i
          className={
            mimeType === 'application/pdf'
              ? 'fa fa-file-pdf-o'
              : 'fa fa-download'
          }
        />
        &nbsp;
        {name}
      </div>
    </a>
  );
}

export default class ReportsControlBox extends React.Component<
  ReportsControlProps,
  IState
> {
  constructor(props: ReportsControlProps) {
    super(props);

    this.state = {};
  }

  public renderHeader = (): JSX.Element => {
    const { onClose } = this.props;

    return (
      <React.Fragment>
        <ControlBox.Title title="Reports" />
        <ControlBox.Icon
          onClick={() => {
            onClose();
          }}
          name="close"
        />
      </React.Fragment>
    );
  };

  private handleClick = (report?: any, url?: string): void => {
    if (!report) {
      this.setState({
        selectedReport: undefined,
      });

      return;
    }

    this.setState({
      selectedReport: {
        ...report,
        url,
      },
    });
  };

  private renderReportViewer = (report: any) => {
    if (report.mimeType === 'application/pdf') {
      return (
        <PDFViewer
          onClose={() => this.handleClick()}
          file={report.url}
          name={report.name}
        />
      );
    }

    return null;
  };

  public render(): React.ReactNode {
    const { reports } = this.props;
    const { selectedReport } = this.state;

    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={this.renderHeader}
      >
        {reports.map((report) => (
          <ReportItem
            key={report.id}
            onClick={this.handleClick.bind(this, report)}
            {...report}
          />
        ))}
        {selectedReport && this.renderReportViewer(selectedReport)}
      </ControlBox.Wrapper>
    );
  }
}
