import ApiBase from './ApiBase';
import { ContourMapsApisReturnTypes } from '../shapes/contourMaps';
import { processErrorHandling } from '../utils/helpers';
import { BASE_WORKBENCH_API_URL } from '../constants/urls';

export default class ContourMapsApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_WORKBENCH_API_URL);

    this._baseUrl = `/v1/postprocess/projects`;
  }

  public listContours(
    projectId: string,
    aoiId: string
  ): ContourMapsApisReturnTypes {
    return this.get(`${this._baseUrl}/${projectId}/aois/${aoiId}/contours`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the Contours.`,
          isArray: true,
        });
      });
  }

  public postContour(
    projectId: string,
    aoiId: string,
    formData: {},
    contourId?: string | null
  ): ContourMapsApisReturnTypes {
    let url = `${this._baseUrl}/${projectId}/aois/${aoiId}/contours`;

    if (contourId) {
      url += `/${contourId}`;
    }

    return this.post(`${url}`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to generate the Contour.`,
        });
      });
  }

  public getContour(
    projectId: string,
    aoiId: string,
    contourId?: string | null
  ): ContourMapsApisReturnTypes {
    const url = `${this._baseUrl}/${projectId}/aois/${aoiId}/contours/${contourId}`;

    return this.get(`${url}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the Contour.`,
        });
      });
  }

  public updateContour(
    projectId: string,
    aoiId: string,
    formData: {},
    contourId: string | null
  ): ContourMapsApisReturnTypes {
    const url = `${this._baseUrl}/${projectId}/aois/${aoiId}/contours/${contourId}`;

    return this.patch(`${url}`, formData)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to update the Contour.`,
        });
      });
  }
}
