import DxfParser from 'dxf-parser';

declare const THREE: any;

export const convertDxfToThreeJs = (dxfText: string, style: any) => {
  const parser = new DxfParser();
  const dxf = parser.parseSync(dxfText);
  const loader = new THREE.DXFLoader();

  return loader.parse(dxf, style);
};

export const conertGeoJsonToThreeJs = (json: any, style: any) => {
  const shapeContainer = new THREE.Object3D();

  if (json?.type === 'FeatureCollection') {
    const features = json?.features || [];

    // eslint-disable-next-line no-restricted-syntax
    for (const f of features) {
      if (f?.type === 'Feature') {
        const g = f?.geometry;

        if (g?.type === 'Point') {
          // eslint-disable-next-line no-continue
          if (f?.properties?.name !== 'Point') continue;

          const sg = new THREE.SphereGeometry(style.pointSize || 1, 18, 18);
          const sm = new THREE.MeshLambertMaterial({
            color: new THREE.Color(style.color || 0xda2929),
            depthTest: true,
            depthWrite: true,
          });
          const s = new THREE.Mesh(sg, sm);

          s.position.set(...g.coordinates);
          shapeContainer.add(s);
        } else if (g?.type === 'LineString') {
          const coordinates = [];

          const min = new THREE.Vector3(Infinity, Infinity, Infinity);

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < g.coordinates.length; i++) {
            const pos = g.coordinates[i];

            min.x = Math.min(min.x, pos[0]);
            min.y = Math.min(min.y, pos[1]);
            min.z = Math.min(min.z, pos[2]);

            coordinates.push(...pos);
            if (i > 0 && i < g.coordinates.length - 1) {
              coordinates.push(...pos);
            }
          }

          for (let i = 0; i < coordinates.length; i += 3) {
            coordinates[i + 0] -= min.x;
            coordinates[i + 1] -= min.y;
            coordinates[i + 2] -= min.z;
          }

          const lineGeometry = new THREE.LineGeometry();

          lineGeometry.setPositions(coordinates);

          const matLine = new THREE.LineMaterial({
            color: style.color || 0xff0000,
            linewidth: style.lineWidth || 10, // in pixels
            resolution: new THREE.Vector2(1000, 1000),
            dashed: false,
            depthTest: true,
            depthWrite: true,
          });
          const line = new THREE.Line2(lineGeometry, matLine);

          line.computeLineDistances();
          line.position.copy(min);

          shapeContainer.add(line);
        } else if (g?.type === 'Polygon') {
          // eslint-disable-next-line no-restricted-syntax
          for (const pc of g.coordinates) {
            const coordinates = [];

            const min = new THREE.Vector3(Infinity, Infinity, Infinity);

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < pc.length; i++) {
              const pos = pc[i];

              min.x = Math.min(min.x, pos[0]);
              min.y = Math.min(min.y, pos[1]);
              min.z = Math.min(min.z, pos[2]);

              coordinates.push(...pos);
              if (i > 0 && i < pc.length - 1) {
                coordinates.push(...pos);
              }
            }

            for (let i = 0; i < coordinates.length; i += 3) {
              coordinates[i + 0] -= min.x;
              coordinates[i + 1] -= min.y;
              coordinates[i + 2] -= min.z;
            }

            const lineGeometry = new THREE.LineGeometry();

            lineGeometry.setPositions(coordinates);

            const matLine = new THREE.LineMaterial({
              color: style.color || 0xff0000,
              linewidth: style.lineWidth || 10, // in pixels
              resolution: new THREE.Vector2(1000, 1000),
              dashed: false,
              depthTest: true,
              depthWrite: true,
            });
            const line = new THREE.Line2(lineGeometry, matLine);

            line.computeLineDistances();
            line.scale.set(1, 1, 1);
            line.position.copy(min);

            shapeContainer.add(line);
          }
        } else {
          // console.log(g, f);
        }
      }
    }
  }

  return shapeContainer;
};
