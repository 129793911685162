import { Region } from '@aspecscire/navigator-wasm';
import turfArea from '@turf/area';
import { polygon } from '@turf/helpers';
import { getCenter } from 'ol/extent';
import { GeoJSON, WKT } from 'ol/format';
import { Polygon, Point } from 'ol/geom';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';

// TODO: add ability to support transformation to project epsg

export const GCP_ALGO_ITERATIONS = 128;

export interface GCPPlanRequest {
  boundary: string | Object;
  gcpCount?: number;
  coverageRadius?: number;
  icpCount?: number;
  mode: 'coverage' | 'count';
}

export interface ControlPointPlan {
  gcps: Point[];
  icps: Point[];
}

export const createGCPPlan = ({
  boundary,
  mode,
  gcpCount,
  icpCount,
  coverageRadius,
}: GCPPlanRequest): ControlPointPlan | undefined => {
  const geojson = new GeoJSON();
  const wkt = new WKT();
  let geojsonBoundary: Polygon | undefined;

  try {
    geojsonBoundary = geojson.readFeatures(boundary)[0].getGeometry() as
      | Polygon
      | undefined;
  } catch (e) {
    console.error('Error while reading boundary data', e);

    return undefined;
  }

  if (geojsonBoundary === undefined) {
    console.error('Error extracting boundary geometry.', boundary);

    return undefined;
  }

  const boundaryArea = turfArea(polygon(geojsonBoundary.getCoordinates()));
  const wktBoundary = wkt.writeGeometry(convertPolygonToUtm(geojsonBoundary));

  switch (mode) {
    case 'count': {
      if (gcpCount === undefined) {
        console.error('gcpCount must be specified to generate GCP plan');

        return undefined;
      }

      const coverageRadius = getCoverageForGcpCount(boundaryArea, gcpCount, 1);
      const gcps = generateControlPoints(wktBoundary, coverageRadius, gcpCount);
      const icps = icpCount
        ? generateIcps(wktBoundary, boundaryArea, icpCount)
        : [];

      return { gcps, icps };
    }

    case 'coverage': {
      if (coverageRadius === undefined) {
        console.error('coverageRadius must be specified to generate GCP plan');

        return;
      }

      const count = getGcpCountForCoverage(boundaryArea, coverageRadius);
      const gcps = generateControlPoints(wktBoundary, coverageRadius, count);
      const icps = icpCount
        ? generateIcps(wktBoundary, boundaryArea, icpCount)
        : [];

      return { gcps, icps };
    }

    default: {
      console.error('Invalid gcp generation mode.', mode);

      return undefined;
    }
  }
};

/**
 * Function to get the number of GCPs required to cover the boundary, with each GCP having a specified coverage radius.
 * @param boundaryArea Area of the boundary to be covered in square metres
 * @param coverageRadius Radius of coverage expected from each GCP, in metres
 * @param safetyFactor Safety factor to account for irregular shape of the boundary (Default: 4)
 */
const getGcpCountForCoverage = (
  boundaryArea: number,
  coverageRadius: number,
  safetyFactor?: number
) => {
  const idealGcpCount = boundaryArea / (Math.PI * coverageRadius ** 2);

  return idealGcpCount * (safetyFactor || 4);
};

/**
 * Function to get the GCP coverage radius in metres, that is to be used to ensure full coverage with the specified number of GCPs.
 * @param boundaryArea Area of the boundary to be covered in square metres
 * @param gcpCount Number of GCPs to be used
 * @param safetyFactor Safety factor to account for irregular shape of the boundary (Default: 4)
 */
const getCoverageForGcpCount = (
  boundaryArea: number,
  gcpCount: number,
  safetyFactor?: number
) => {
  const idealCoverage = (boundaryArea / (gcpCount * Math.PI)) ** 0.5;

  return idealCoverage * Math.abs(safetyFactor || 4) ** 0.5;
};

const generateControlPoints = (
  wktBoundary: string,
  coverage: number,
  gcpCount: number
): Point[] => {
  const region = new Region(wktBoundary, coverage);
  const points: Point[] = [];
  const wkt = new WKT();

  for (let i = 0; i < gcpCount; i += 1) {
    try {
      const point = region.sample_best_of(GCP_ALGO_ITERATIONS);

      points.push(
        wkt
          .readFeature(point)
          .getGeometry()
          ?.transform('EPSG:AUTO', 'EPSG:4326') as Point
      );
    } catch (e) {
      console.error('Error while generating control points', e);

      return points;
    }
  }

  return points;
};

const generateIcps = (
  wktBoundary: string,
  boundaryArea: number,
  icpCount: number
): Point[] => {
  const coverage = getCoverageForGcpCount(boundaryArea, icpCount);

  return generateControlPoints(wktBoundary, coverage, icpCount);
};

export const utmZoneFromLongitude = (longitude: number): number => {
  // get utm-zone from longitude degrees
  // refer: https://stackoverflow.com/a/9188972
  return Math.round(((longitude + 180) / 6) % 60) + 1;
};

export const getUtmZoneDefinition = (longitude: number): string => {
  const utmZone = utmZoneFromLongitude(longitude);

  return `+proj=utm +zone=${utmZone} +datum=WGS84 +units=m +no_defs`;
};

export const convertPolygonToUtm = (polygon: Polygon): Polygon => {
  const center = getCenter(polygon.getExtent());
  const longitude = center[0];

  proj4.defs('EPSG:AUTO', getUtmZoneDefinition(longitude));
  // register new coordinate system with openlayers
  register(proj4);

  return polygon.transform('EPSG:4326', 'EPSG:AUTO') as Polygon;
};
