import React from 'react';
import { Map } from 'ol';

export interface AccessMapProps {
  olMap?: Map;
}

/**
 * This class is used to expose the internal openlayers map object to higher
 * level components, through a reference.
 */
export class AccessMap extends React.PureComponent<AccessMapProps> {
  public getMap = (): Map | undefined => {
    const { olMap } = this.props;

    return olMap;
  };

  public render = () => {
    return <div data-testid="openlayers-access-map" />;
  };
}
