import ApiBase from './ApiBase';
import { BASE_CAPI_URL } from '../constants/urls';
import { ApiReturnType } from '../shapes/app';
import { EpsgDetails } from './epsgDetails.types';
import { processErrorHandling } from '../utils/helpers';

export default class EpsgDetailsAPI extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = `/v1`;
  }

  public searchEpsgDetailsByString(
    searchString: string
  ): Promise<ApiReturnType<EpsgDetails[]>> {
    return this.get(`${this._baseUrl}/coords?search=${searchString}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `An error occured while fetching the EPSG information. Try again`,
          isArray: true,
        });
      });
  }

  public getEpsgDetailsById(id: string): Promise<ApiReturnType<EpsgDetails>> {
    return this.get(`${this._baseUrl}/coords/${id}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `An error occured while fetching the EPSG information. Try again`,
          isArray: true,
        });
      });
  }
}
