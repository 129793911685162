// action SHOW_COACHMARK

import { Step } from 'react-joyride';

interface IState {
  steps: Step[];
  isRunning: boolean;
}

const initialState: IState = {
  steps: [],
  isRunning: false,
};

export default function coachmarks(
  state: IState = initialState,
  action: any
): IState {
  const { payload, type } = action;

  switch (type) {
    case 'SHOW_COACHMARKS':
      return {
        ...state,
        steps: payload,
      };

    case 'RUN_COACHMARKS':
      return {
        ...state,
        isRunning: true,
      };

    case 'RESET_COACHMARKS':
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
