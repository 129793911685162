interface IShareState {
  error?: any;
  data?: any;
  loading: boolean;
}

const initialState: IShareState = {
  loading: false,
};

export default function share(
  state: IShareState = initialState,
  action: any
): IShareState {
  const { type, payload } = action;

  switch (type) {
    case 'FETCH_SHARE_PENDING':
      return {
        ...state,
        data: undefined,
        error: undefined,
        loading: true,
      };

    case 'FETCH_SHARE_FULFILLED':
      return {
        ...state,
        data: payload.data,
        loading: false,
      };

    case 'FETCH_SHARE_REJECTED':
      return {
        ...state,
        error: payload.response,
        loading: false,
      };

    case 'CLEAR_SHARE':
      return {
        error: undefined,
        data: undefined,
        loading: false,
      };

    default:
      return state;
  }
}
