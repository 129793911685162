import * as React from 'react';
import Loading from '../../../Loading';
import styles from './index.module.scss';

interface IProps {
  src: string;
  width?: number;
  height?: number;
}

interface IState {
  loaded: boolean;
}

class ImageWithLoading extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  public componentDidMount(): void {
    const { src } = this.props;

    this.initSrc(src);
  }

  public UNSAFE_componentWillReceiveProps({ src: newSrc }: IProps) {
    const { src: oldSrc } = this.props;

    if (oldSrc !== newSrc) {
      this.setState({ loaded: false }, () => {
        this.initSrc(newSrc);
      });
    }
  }

  private initSrc = (src: string) => {
    const img = new Image();

    img.src = src;
    img.onload = () => {
      this.setState({ loaded: true });
    };
  };

  public render(): React.ReactNode {
    const { loaded } = this.state;
    const { src } = this.props;

    return (
      <div>
        {!loaded ? (
          <div className={styles.center}>
            <Loading size="large" />
          </div>
        ) : (
          <img alt="Loading..." src={src} className={styles.image} />
        )}
      </div>
    );
  }
}

export default ImageWithLoading;
