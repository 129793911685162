import React, { useEffect } from 'react';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import * as ControlBox from 'src/components/ControlBox';
import { RendererState } from 'src/components/View/index.types';
import { MapMakerDrawControl } from 'src/components/View/MapView/OpenLayersRenderer/MapMakerDrawControl';
import { BaseControlProps } from 'src/components/View/ViewControls/index.types';
import { useLayerManagement } from 'src/components/View/ViewControls/MapMakerControls/hooks';
import { LayerControl } from 'src/components/View/ViewControls/MapMakerControls/LayerControl';
import { LayerSelector } from 'src/components/View/ViewControls/MapMakerControls/LayerSelector';
import { NoLayerPlaceholder } from 'src/components/View/ViewControls/MapMakerControls/NoLayerPlaceholder';
import style from './index.module.scss';

export interface EditShapeControlBoxProps extends BaseControlProps {
  layers?: LayerDescriptor[];
  selectedLayer?: LayerDescriptor;
  rendererState?: RendererState;
}
export const EditShapeControlBox: React.FC<EditShapeControlBoxProps> = ({
  layers,
  selectedLayer,
  rendererState,
  onRenderFragChange,
  onClose,
  onEvent,
}) => {
  const {
    layer,
    resetLayerFeatures,
    updateInitialState,
    saveLayer,
    selectLayer,
    clearLayer,
  } = useLayerManagement(onEvent, selectedLayer, rendererState);

  useEffect(() => {
    if (layer) {
      updateInitialState(layer, layer.getSource().getFeatures());
      onRenderFragChange(() => (
        <MapMakerDrawControl layer={layer} drawMode="edit" />
      ));
    }

    return () => {
      resetLayerFeatures();
      updateInitialState(undefined);
      onRenderFragChange(undefined);
    };
    // The eslint-disable is so that we can ignore onRenderFragChange as a dependency.
    // There's no easy way to memoize it, and each render, a new version of the
    // function is created, causing the effect to be called more than necessary.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layer, resetLayerFeatures, updateInitialState]);

  return (
    <ControlBox.Wrapper
      renderHeader={() => <Header onClose={onClose} />}
      className={style.container}
    >
      <LayerSelector
        select={selectLayer}
        clear={clearLayer}
        layers={layers}
        selectedLayer={selectedLayer}
      />
      {selectedLayer ? (
        <div className={style.padTop}>
          <LayerControl reset={resetLayerFeatures} save={saveLayer} />
        </div>
      ) : (
        <NoLayerPlaceholder />
      )}
    </ControlBox.Wrapper>
  );
};

export const Header: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <>
      <ControlBox.Title title="Edit Shape" />
      <ControlBox.Icon
        onClick={() => {
          onClose();
        }}
        name="close"
      />
    </>
  );
};
