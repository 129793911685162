import * as math from 'mathjs';
import PerspectiveCamera from './PerspectiveCamera';

export default class ODMScene {
  private cameras: PerspectiveCamera[] = [];

  constructor(exportedOdmCameras: any) {
    // create a camera object for all cameras in reconstrction
    const filenames = Object.keys(exportedOdmCameras?.shots || {});

    filenames.forEach((filename: string) => {
      if (filename) {
        const shot = exportedOdmCameras?.shots[filename];
        const cameraParams = exportedOdmCameras.cameras[shot.camera];

        if (cameraParams) {
          this.cameras.push(
            new PerspectiveCamera(
              shot.guid,
              cameraParams.width,
              cameraParams.height,
              shot.position,
              cameraParams.K,
              shot.rotation_matrix
            )
          );
        }
      }
    });
  }

  public triangulate(
    pixels: { guid: string; pixelX: number; pixelY: number }[]
  ) {
    const dataList = pixels
      .map((p) => {
        const cam = this.cameras.find((c) => c.id === p.guid);

        if (cam) {
          return cam.getBearing(p.pixelX, p.pixelY);
        }

        return null;
      })
      .filter((x: any) => !!x);
    // Find the closest point by Least Square Solution using eq. 13 in http://cal.cs.illinois.edu/~johannes/research/LS_line_intersect.pdf.
    // Find R and q matrix
    let R = math.zeros(3, 3);
    let q = math.zeros(3, 1);

    dataList.forEach((data) => {
      const I = math.identity(3);
      const temp1 = math.multiply(math.transpose([data?.bearing]), [
        data?.bearing,
      ]);
      const temp2 = math.subtract(I, temp1);
      const temp3 = math.multiply(temp2, math.transpose([data?.position]));

      R = math.add(R, temp2);
      q = math.add(q, temp3);
    });

    const realPt = math.multiply(math.inv(R), q); // 3x1 matrix

    return [realPt.get([0, 0]), realPt.get([1, 0]), realPt.get([2, 0])]; // Array of length 3
  }

  public projectUTMPointToPixels(point: {
    x: number;
    y: number;
    z: number;
  }): any[] {
    const projs: any[] = [];

    this.cameras.forEach((c) => {
      const proj = c.project(point);

      if (proj) {
        projs.push({ ...proj, guid: c.id });
      }
    });

    return projs;
  }
}
