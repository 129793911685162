import React from 'react';
import WorkbenchApis from 'src/api/workbench';

interface StepLogsProps {
  sessionId: string;
  stepId: string;
}

interface StepLogsState {
  lines?: string[];
}

export default class StepLogsViewer extends React.Component<
  StepLogsProps,
  StepLogsState
> {
  private wbApi = new WorkbenchApis();

  constructor(props: StepLogsProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { sessionId, stepId } = this.props;

    this.wbApi.getStepLogs(sessionId, stepId).then(({ data, error }) => {
      if (error) {
        console.error(error);

        return;
      }

      if (data) {
        this.setState({
          lines: (data?.data || '').split('\n'),
        });
      }
    });
  }

  render() {
    const { lines } = this.state;

    if (!lines) {
      return <div>Loading...</div>;
    }

    let count = 0;

    return (
      <div style={{ maxHeight: '20em', overflowY: 'auto' }}>
        {lines.map((l) => {
          // eslint-disable-next-line
          return <p key={`${count++}`}>{l}</p>;
        })}
      </div>
    );
  }
}
