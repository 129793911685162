import ApiBase from './ApiBase';
import { MAPBOX_API_BASE_URL } from '../constants/urls';

import { MapboxStyle, MapboxTileJSON } from './mapbox.types';

export default class MapboxAPIs extends ApiBase {
  protected baseUrl: string = MAPBOX_API_BASE_URL;

  public getStyle(
    styleUrl: string,
    accessToken: string
  ): Promise<MapboxStyle | null> {
    const styleId = styleUrl.replace('mapbox://styles/', '').split('/');

    return this.get(`/styles/v1/${styleId[0]}/${styleId[1]}`, {
      params: {
        access_token: accessToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(
          'There was an error while fetching the Mapbox Style.',
          err
        );

        return null;
      });
  }

  public getTileJSON(
    tilesetId: string,
    accessToken: string
  ): Promise<MapboxTileJSON | null> {
    return this.get(`/v4/${tilesetId}.json`, {
      params: {
        access_token: accessToken,
      },
      withCredentials: false,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(
          'There was an error while fetching Mapbox Tileset Metadata.',
          err
        );

        return null;
      });
  }
}
