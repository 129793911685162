import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSelectedView } from '../../redux/selectors/views';
import {
  fetchVolume,
  resetVolumeValues,
  setElevationValue,
  resetElevationValue,
  updateVolumeViewId,
  updateVolumeViewUnits,
  setVolumeType,
  resetVolumeViewData,
} from '../../redux/actions/volume';
import VolumeCalculator, {
  IDispatchProps,
  IOwnProps,
  IStateProps,
} from './VolumeCalculator';

const mapStateToProps = (state: any, { viewId }: any): IStateProps => {
  return {
    view: getSelectedView(state, { viewId }),
    volume: state.volume.data.volume,
    loading: state.volume.loading,
    error: state.volume.error,
    elevation: state.volume.elevation,
    elevationList: state.volume.elevationList,
    volumeType: state.volume.volumeType,
  };
};

const mapDispatchToProps = (
  dispatch: any,
  { viewId, shape }: IOwnProps
): IDispatchProps => {
  return bindActionCreators(
    {
      fetchVolume: fetchVolume.bind(
        null,
        viewId,
        shape.geometry.coordinates[0]
      ),
      resetVolumeValues,
      setElevationValue,
      resetElevationValue,
      updateVolumeViewId,
      updateVolumeViewUnits,
      setVolumeType,
      resetVolumeViewData,
    },
    dispatch
  );
};

const VolumeCalculatorContainer = connect<
  IStateProps,
  IDispatchProps,
  IOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(VolumeCalculator);

export default VolumeCalculatorContainer;
