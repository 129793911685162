import {
  LockOutlined,
  MailOutlined,
  MobileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Input, Modal } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import autobind from 'autobind-decorator';
import { Redirect } from 'react-router';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactPixel from 'react-facebook-pixel';
import { Button } from '../../Button';
import Logo from '../../Logo/Logo';
import style from './index.module.scss';
import ModalNotification from '../../ModalNotification/ModalNotification';
import { setBodyStyle } from '../../../utils/window';
import {
  BASE_CAPI_URL,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
} from '../../../constants/urls';
import { BrandingModel, ICheckBranding } from '../../../shapes/branding';
import BrandingApi from '../../../api/branding';
import {
  objectListToQueryString,
  undefinedOrNull,
} from '../../../utils/functs';
import { setBrandingStyles, resetBrandingStyles } from '../helpers';
import LoadingOverlay from '../../LoadingOverlay';
import { WithForm } from '../../../shapes/app';

interface IProps extends WithForm {
  authenticated: boolean;
  partnerId?: string;
  utm_source?: string;
}

interface IState {
  firstName: string;
  lastName: string;
  password: string;
  password2: string;
  email: string;
  licencedDroneOperator: boolean;
  surveyor: boolean;
  architect: boolean;
  phoneNumber: string;
  company: string;
  jobTitle: string;
  recaptcha: string;
  cancelSignUP: boolean;
  checked: boolean;
  signingUp: boolean;
  registerError: string;
  showRegistrationErrorModal: boolean;
  registrationSuccesModalHeading: string;
  isUserRegisteredSuccessfully: boolean;
  isUserAleardyRegistered: boolean;
  isPageLoading: boolean;
  brandingData: BrandingModel | null;
}

class SelfSignUp extends React.Component<IProps, IState> {
  private brandingApi = new BrandingApi();

  public constructor(props: IProps) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      password: '',
      password2: '',
      email: '',
      licencedDroneOperator: false,
      surveyor: false,
      architect: false,
      phoneNumber: '',
      company: '',
      jobTitle: '',
      recaptcha: '',
      cancelSignUP: false,
      checked: false,
      signingUp: false,
      registerError: '',
      showRegistrationErrorModal: false,
      registrationSuccesModalHeading: '',
      isUserRegisteredSuccessfully: false,
      isUserAleardyRegistered: false,
      isPageLoading: true,
      brandingData: null,
    };
  }

  public componentDidMount(): void {
    const { utm_source } = this.props;

    const FB_PIXEL_ID = process.env.REACT_APP_FB_PIXEL_ID
      ? `${process.env.REACT_APP_FB_PIXEL_ID}`
      : null;

    if (
      !undefinedOrNull(utm_source) &&
      utm_source === 'facebook' &&
      !undefinedOrNull(FB_PIXEL_ID)
    ) {
      ReactPixel.init(FB_PIXEL_ID);
      ReactPixel.pageView();
    }

    this.fetchProjectBranding();

    setBodyStyle('background-color', '#434344');
  }

  public componentWillUnmount(): void {
    resetBrandingStyles();
  }

  private fetchProjectBranding = () => {
    const { partnerId } = this.props;

    if (undefinedOrNull(partnerId)) {
      this.brandingApi.checkDefaultBranding().then((res: ICheckBranding) => {
        if (!undefinedOrNull(res)) {
          this.setState(
            {
              brandingData: {
                ...res.data,
              },
            },
            () => {
              const { brandingData } = this.state;

              setBrandingStyles(brandingData);
            }
          );
        } else {
          this.setState({
            brandingData: null,
          });
        }

        this.setState({
          isPageLoading: false,
        });
      });

      return;
    }

    this.brandingApi.checkBranding(partnerId).then((res: ICheckBranding) => {
      if (!undefinedOrNull(res)) {
        this.setState(
          {
            brandingData: {
              ...res.data,
            },
          },
          () => {
            const { brandingData } = this.state;

            setBrandingStyles(brandingData);
          }
        );
      } else {
        this.setState({
          brandingData: null,
        });
      }

      this.setState({
        isPageLoading: false,
      });
    });
  };

  private handleCheck = () => {
    const { checked } = this.state;

    this.setState({
      checked: !checked,
    });
  };

  private handleLicencedDroneOperator = () => {
    const { licencedDroneOperator } = this.state;

    this.setState({
      licencedDroneOperator: !licencedDroneOperator,
    });
  };

  private handleSurveyor = () => {
    const { surveyor } = this.state;

    this.setState({
      surveyor: !surveyor,
    });
  };

  private handleArchitect = () => {
    const { architect } = this.state;

    this.setState({
      architect: !architect,
    });
  };

  private selfSignUP = () => {
    const {
      firstName,
      lastName,
      password,
      email,
      licencedDroneOperator,
      surveyor,
      architect,
      phoneNumber,
      company,
      jobTitle,
      recaptcha,
    } = this.state;

    const { partnerId, utm_source } = this.props;

    this.setState({
      signingUp: true,
    });

    fetch(`${BASE_CAPI_URL}/v1/signup/new`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        password,
        email,
        licencedDroneOperator,
        surveyor,
        architect,
        phoneNumber,
        company,
        jobTitle,
        recaptcha,
        partnerId: partnerId ? partnerId.trim() : null,
      }),
    })
      .then((res) => {
        if (res.status === 204 || res.status === 200) {
          this.setState({
            registerError:
              'We have sent a verification link to your email address',
            showRegistrationErrorModal: true,
            registrationSuccesModalHeading: 'Success',
            isUserRegisteredSuccessfully: true,
            signingUp: false,
          });

          if (
            !undefinedOrNull(utm_source) &&
            utm_source === 'facebook' &&
            !undefinedOrNull(process.env.REACT_APP_FB_PIXEL_ID)
          ) {
            ReactPixel.trackCustom('LeadWithUserData', {
              content_name: email,
              surveyor,
              architect,
              licencedDroneOperator,
            });
          }

          return true;
        }

        throw res;
      })
      .then((res) => {
        console.info(res);
      })
      .catch((err) => {
        err
          .json()
          .then((error: any) => {
            let isUserAleardyRegistered = false;

            if (err.status === 409) {
              isUserAleardyRegistered = true;
            }

            this.setState({
              signingUp: false,
              isUserAleardyRegistered,
              registerError: error.message,
              showRegistrationErrorModal: true,
              isUserRegisteredSuccessfully: false,
              registrationSuccesModalHeading: 'Error while sign up',
            });
          })
          .catch(() => {
            this.setState({
              signingUp: false,
              registerError:
                'Could not register, please contact support@aspecscire.com',
              showRegistrationErrorModal: true,
              isUserRegisteredSuccessfully: false,
              registrationSuccesModalHeading: 'Error while sign up',
            });
          });
      });
  };

  @autobind
  private handleSubmit(e: any): void {
    const { form } = this.props;

    e.preventDefault();

    form.validateFields((err: any, values: any) => {
      if (!err) {
        this.setState(
          {
            firstName: values.FirstName,
            lastName: values.LastName,
            email: values.Email && values.Email.trim().toLowerCase(),
            password: values.Password,
            password2: values.ConfirmPassword,
            phoneNumber: form.getFieldValue('phoneNumber'),
            company: form.getFieldValue('company'),
            jobTitle: form.getFieldValue('jobTitle'),
          },
          () => {
            const { password, password2, checked } = this.state;
            let registerError = '';
            let showRegistrationErrorModal = false;
            let registrationSuccesModalHeading = '';

            if (password === password2 && checked) {
              this.selfSignUP();
            } else if (password !== password2) {
              registerError = 'Password and confirm password should be same';
              showRegistrationErrorModal = true;
              registrationSuccesModalHeading = 'Password Error';
            } else if (!checked) {
              registerError =
                'You must agree to the terms of use before you can sign up';
              showRegistrationErrorModal = true;
              registrationSuccesModalHeading = 'Error';
            }

            this.setState({
              registerError,
              showRegistrationErrorModal,
              registrationSuccesModalHeading,
            });
          }
        );
      }
    });
  }

  private handleCapchaChange = (value: string) => {
    this.setState({
      recaptcha: value,
    });
  };

  private handleRegistrationErrorModal = () => {
    const { showRegistrationErrorModal } = this.state;

    this.setState(
      {
        showRegistrationErrorModal: !showRegistrationErrorModal,
      },
      () => {
        const { isUserRegisteredSuccessfully } = this.state;

        if (isUserRegisteredSuccessfully) {
          window.location.href = `${window.location.origin}/`;
        }
      }
    );
  };

  private redirectToLogin = () => {
    window.location.href = `${window.location.origin}/`;
  };

  public render(): JSX.Element {
    const { form, authenticated, partnerId } = this.props;
    const {
      cancelSignUP,
      checked,
      signingUp,
      licencedDroneOperator,
      surveyor,
      architect,
      registerError,
      showRegistrationErrorModal,
      isUserAleardyRegistered,
      registrationSuccesModalHeading,
      isPageLoading,
    } = this.state;
    const { getFieldDecorator } = form;

    if (cancelSignUP) {
      return <Redirect to="/" />;
    }

    return (
      <div className={style.container}>
        <Form onSubmit={this.handleSubmit} className={style.formContainer}>
          <Logo
            className={style.logo}
            authenticated={authenticated}
            partnerId={partnerId}
            size="large"
          />
          <Form.Item>
            {getFieldDecorator('FirstName', {
              rules: [
                {
                  required: true,
                  message: 'Please enter your first name',
                },
              ],
            })(
              <Input
                prefix={<UserOutlined className={style.textInput} />}
                placeholder="*First Name"
                autoFocus
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('LastName', {
              rules: [
                { required: true, message: 'Please enter your last name' },
              ],
            })(
              <Input
                prefix={<UserOutlined className={style.textInput} />}
                placeholder="*Last Name"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('Email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                { required: true, message: 'Please enter your email' },
              ],
            })(
              <Input
                prefix={<MailOutlined className={style.textInput} />}
                placeholder="*Email"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('Password', {
              rules: [
                { required: true, message: 'Please enter your password' },
              ],
            })(
              <Input
                prefix={<LockOutlined className={style.textInput} />}
                type="password"
                placeholder="*Password"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('ConfirmPassword', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your password',
                },
              ],
            })(
              <Input
                prefix={<LockOutlined className={style.textInput} />}
                type="password"
                placeholder="*Confirm Password"
              />
            )}
          </Form.Item>
          <Form.Item className={style.licencedDroneOperatorCheckbox}>
            {getFieldDecorator('licencedDroneOperator')(
              <Checkbox
                checked={licencedDroneOperator}
                onChange={this.handleLicencedDroneOperator}
              >
                Professional Drone Operator (with License)?
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item className={style.surveyorCheckbox}>
            {getFieldDecorator('surveyor')(
              <Checkbox checked={surveyor} onChange={this.handleSurveyor}>
                Surveying Professional
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item className={style.architectCheckbox}>
            {getFieldDecorator('architect')(
              <Checkbox checked={architect} onChange={this.handleArchitect}>
                Architecture / Construction Professional
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('phoneNumber')(
              <Input
                prefix={<MobileOutlined className={style.textInput} />}
                placeholder="Phone Number"
                type="tel"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('jobTitle')(
              <Input
                prefix={<UserOutlined className={style.textInput} />}
                placeholder="Title"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('company')(
              <Input
                prefix={<UserOutlined className={style.textInput} />}
                placeholder="Company Name"
              />
            )}
          </Form.Item>
          <div className={style.agreedTermsAndPolicyText}>
            <Checkbox checked={checked} onChange={() => this.handleCheck()} />
            <span className={style.spanColor}>
              I agree to the&nbsp;
              <a
                className={style.terms}
                href={TERMS_AND_CONDITIONS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
            </span>
            &nbsp;&nbsp;and&nbsp;&nbsp;
            <span className={style.privatePolicyText}>
              <a
                target="_blank"
                href={PRIVACY_POLICY_URL}
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
          </div>
          <div className={style.googlerRecaptcha}>
            <ReCAPTCHA
              size="normal"
              sitekey="6Le5zKgUAAAAAI0yFeZrW3zQJ9suZZnBzRXTkQCZ"
              onChange={this.handleCapchaChange}
            />
          </div>
          <Button
            icon="login"
            htmlType="submit"
            loading={signingUp}
            loadingText="SIGNING UP..."
            fullWidth
            text="SIGN UP"
            className={style.signupBtn}
          />
          <div className={style.textColor}>
            <span>Already registered? &nbsp;</span>
            <label className={style.loginRedirectText}>
              <Link
                to={`/${objectListToQueryString({
                  partnerId,
                })}`}
              >
                Login
              </Link>
            </label>
          </div>
        </Form>
        {showRegistrationErrorModal && !isUserAleardyRegistered ? (
          <ModalNotification
            className={style.errorMobileVersion}
            notificationTitle={registrationSuccesModalHeading}
            notificationBody={registerError}
            shownotificationModal
            handleModalCancel={this.handleRegistrationErrorModal}
            okButtonTitle="OK"
          />
        ) : null}
        {showRegistrationErrorModal && isUserAleardyRegistered ? (
          <Modal
            title={registrationSuccesModalHeading}
            centered
            className={style.mobileVersion}
            footer={null}
            visible={isUserAleardyRegistered}
            onCancel={this.handleRegistrationErrorModal}
            maskClosable={false}
          >
            <p>{registerError}</p>
            <div className={style.alreadyUserExits}>
              <Button
                className={style.errorModalButton}
                onClick={this.handleRegistrationErrorModal}
                text="Ok"
              />
              <Button
                className={style.errorModalButton}
                onClick={this.redirectToLogin}
                text="Login"
              />
            </div>
          </Modal>
        ) : null}

        {isPageLoading && (
          <LoadingOverlay overlayClassName={style.pageLoading} />
        )}
      </div>
    );
  }
}

export default Form.create<IProps>()(SelfSignUp);
