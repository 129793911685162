import { Input, Modal, Typography, Checkbox, Select, Tooltip } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../Loading';
import ImageWithLoading from './ImageWithLoading';
import IssueComment from './IssueComment';
import styles from './index.module.scss';
import { Button } from '../../Button';
import { BASE_CAPI_URL } from '../../../constants/urls';
import { mapboxImageUrl, undefinedOrNull } from '../../../utils/functs';
import { viewUrl } from '../../../routes/urls';
import IssuesV2Apis from '../../../api/issuesV2';
import { Issue } from '../../../api/issue.types';
import { showProjectAdminLinks } from '../../Header';
import { IssueDetailProps, IssueDetailState } from './index.types';
import {
  ChangeCommentPageEvent,
  CreateCommentEvent,
  ResolveIssueEvent,
  UpdateIssueTagsEvent,
} from '../index.types';

const issuesV2Apis = new IssuesV2Apis();
const { Text } = Typography;

class IssueDetail extends React.Component<IssueDetailProps, IssueDetailState> {
  public constructor(props: IssueDetailProps) {
    super(props);
    this.state = {
      commentMessage: '',
      shareIssueBtnLoading: false,
      showShareModal: false,
      showEditTags: false,
      selectedShareToUserIdsList: [],
      tagsAttachedtoIssue: [],
    };
  }

  public componentDidUpdate(prevProps: IssueDetailProps): void {
    const { issue } = this.props;

    if (issue.id !== prevProps.issue.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        tagsAttachedtoIssue: [],
        showEditTags: false,
        commentMessage: '',
      });
    }
  }

  private isUserProjectAdminOrCreator = () => {
    const { user, issue, projectId } = this.props;

    const isProjectAdmin = showProjectAdminLinks(projectId, user);

    return isProjectAdmin || issue.userId === user.user_id;
  };

  private handleCommentMessage = (e: any): void => {
    this.setState({ commentMessage: e.target.value });
  };

  private handleCommentSubmit = (): void => {
    const { issue, onEvent } = this.props;
    const { commentMessage } = this.state;

    // Ensuring empty comments aren't added to issues.
    if (commentMessage.trim().length > 0) {
      onEvent(new CreateCommentEvent(issue, commentMessage));
      this.setState({ commentMessage: '' });
    }
  };

  private handleEditTags = (): void => {
    this.setState((prevState) => ({
      showEditTags: !prevState.showEditTags,
    }));
  };

  private handleTagSelection = (values: any) => {
    this.setState({
      tagsAttachedtoIssue: values,
    });
  };

  private handleResolveIssue = (): void => {
    const { issue, onEvent } = this.props;

    onEvent(new ResolveIssueEvent(issue));
  };

  private handleShareIssueBtn = (value = false): void => {
    this.setState({
      showShareModal: value,
    });
  };

  private handleShareCheckboxChange = (value: string[]): void => {
    this.setState({
      selectedShareToUserIdsList: value,
    });
  };

  private handleUpdateIssueTags = (): void => {
    const { issue, onEvent } = this.props;
    const { tagsAttachedtoIssue } = this.state;

    onEvent(new UpdateIssueTagsEvent(issue, tagsAttachedtoIssue));
  };

  private mapTagIdsToTagNames = (tagIds: string[] | undefined): string[] => {
    const { tags } = this.props;
    const tagNames: string[] = [];

    if (!undefinedOrNull(tagIds)) {
      for (let i = 0; i < tagIds.length; i += 1) {
        const tagData = tags.find((tag) => tag.id === tagIds[i]);

        if (!undefinedOrNull(tagData)) {
          tagNames.push(tagData.tagName);
        }
      }
    }

    return tagNames;
  };

  private processShareIssueBtn = (): void => {
    const { issue, showSnackbar } = this.props;
    const { selectedShareToUserIdsList } = this.state;
    const { id } = issue;

    const formData = { userIds: selectedShareToUserIdsList };

    this.setState({
      shareIssueBtnLoading: true,
    });

    issuesV2Apis.postShareIssues(id, formData).then((res) => {
      const { error: apiError } = res;

      this.setState({
        shareIssueBtnLoading: false,
      });

      if (apiError) {
        showSnackbar({
          type: 'error',
          body: apiError,
        });

        return;
      }

      showSnackbar({
        type: 'success',
        body: 'The issue was shared with the users via email',
      });

      this.handleShareIssueBtn();
    });
  };

  private RenderResolveButton = (): JSX.Element => {
    const { issue, userRole, loading } = this.props;

    if (issue.resolved) {
      return (
        <div className={styles.resolveButton}>
          {userRole === 'read_only' ? (
            <Typography.Text
              disabled
              strong
              className={styles.disabledResolvedBtn}
            >
              Resolved
            </Typography.Text>
          ) : (
            <Typography.Text
              disabled
              strong
              className={styles.disabledResolvedBtn}
            >
              Resolved
            </Typography.Text>
          )}
        </div>
      );
    }

    return (
      <div className={styles.resolveButton}>
        {userRole === 'read_only' ? (
          <Button
            toolTip
            disabled
            text="Resolve Issue"
            toolTipPosition="left"
            helpText="The use of this feature requires change in the user role . Please contact project admin if you need that."
          />
        ) : (
          <Button
            onClick={this.handleResolveIssue}
            text="Resolve Issue"
            loading={loading?.resolve}
            loadingText="Resolving..."
          />
        )}
      </div>
    );
  };

  private RenderShareButton = (): JSX.Element => {
    const { projectUsers } = this.props;
    const { shareIssueBtnLoading } = this.state;

    return (
      <Button
        onClick={() => {
          this.handleShareIssueBtn(true);
        }}
        disabled={!projectUsers || projectUsers.length < 1}
        loading={shareIssueBtnLoading}
        loadingText="Sharing..."
      >
        Share Issue
      </Button>
    );
  };

  private getViewIssueUrl = () => {
    const { issue } = this.props;
    const { compareViewId } = issue.viewConfig;
    const { projectId, aoiId, viewId } = issue;

    return viewUrl(projectId, aoiId, viewId, {
      issueId: issue.id,
      compareViewId,
    });
  };

  private renderIssueImage(): JSX.Element {
    const { issue } = this.props;
    const { latitude, longitude, zoom } = issue.viewConfig;

    let imageUrl = this.constructStaticViewUrl(issue);

    imageUrl = imageUrl || mapboxImageUrl(longitude, latitude, '600x300', zoom);

    return (
      <Link to={this.getViewIssueUrl()}>
        <div>
          {imageUrl ? (
            <ImageWithLoading src={imageUrl} />
          ) : (
            <div className="center" style={{ height: '300px' }}>
              <Loading />
            </div>
          )}
        </div>
      </Link>
    );
  }

  private renderAddComment(): JSX.Element | null {
    const { issue, userRole } = this.props;
    const { commentMessage } = this.state;

    if (issue.resolved) {
      return null;
    }

    return (
      <div>
        <div className={styles.commentDiv}>
          <section style={{ marginTop: '15px' }}>Add a comment</section>
          <Input.TextArea
            rows={4}
            autoSize={{ minRows: 2, maxRows: 6 }}
            className={styles.addComment}
            onChange={this.handleCommentMessage}
            value={commentMessage}
          />
        </div>
        <div className={styles.replyButtonDiv}>
          {userRole === 'read_only' ? (
            <Button
              text="Reply"
              disabled
              toolTip
              height="100%"
              width="100%"
              className={styles.disableReplyButton}
              onClick={this.handleCommentSubmit}
              toolTipPosition="left"
              helpText="The use of this feature requires change in the user role . Please contact project admin if you need that."
            />
          ) : (
            <Button
              text="Reply"
              className={styles.addCommentButton}
              onClick={this.handleCommentSubmit}
            />
          )}
        </div>
      </div>
    );
  }

  private constructStaticViewUrl(issue: Issue): string | null {
    const { imageId, view } = issue;

    if (imageId) {
      return `${BASE_CAPI_URL}/v1/screenshot/${imageId}`;
    }

    if (!view) {
      return null;
    }

    const { sourceUrl, sourceToken } = view;

    if (view.type === 'map') {
      if (sourceUrl.startsWith('mapbox')) {
        const username = sourceUrl.split('/')[3];
        const styleId = sourceUrl.split('/')[4];
        const { longitude, latitude, zoom, rotation } = issue.viewConfig;

        return mapboxImageUrl(
          longitude,
          latitude,
          '600x300',
          zoom,
          rotation,
          username,
          styleId,
          sourceToken
        );
      }
    } else if (view.type === 'perspective') {
      if (issue.viewConfig.selection) {
        return `${BASE_CAPI_URL}/v1/images/${issue.viewConfig.selection}`;
      }
    } else if (view.type === 'exterior_360' || view.type === 'interior_360') {
      if (issue.viewConfig.selection) {
        const { viewId } = issue;
        const { selection } = issue.viewConfig;

        return `${BASE_CAPI_URL}/v1/images/views/${viewId}/images/${selection}`;
      }
    }

    return null;
  }

  private changeCommentPage = (direction: 'next' | 'prev') => {
    const { onEvent, commentPage, issue } = this.props;
    const page = commentPage || 0;

    switch (direction) {
      case 'next': {
        onEvent(new ChangeCommentPageEvent(issue, page + 1));
        break;
      }

      case 'prev': {
        if (page > 0) {
          onEvent(new ChangeCommentPageEvent(issue, page - 1));
        }

        break;
      }

      default:
        break;
    }
  };

  private renderComments(): JSX.Element {
    const { loading } = this.props;

    if (loading?.comments) {
      return (
        <div className={styles.commentLoading}>
          <Loading />
        </div>
      );
    }

    const { comments } = this.props;

    return (
      <div>
        {comments.map((comment) => {
          return (
            <IssueComment
              key={comment.id}
              author={comment.author}
              message={comment.message}
              capturedAt={new Date(comment.capturedAt)}
            />
          );
        })}
        {this.renderCommentPageControl()}
      </div>
    );
  }

  private renderCommentPageControl(): JSX.Element | null {
    const { hasMoreComments, commentPage: _commentPage } = this.props;
    const commentPage = _commentPage || 0;

    // Empty, when there are lesser than 10 comments
    if (commentPage <= 0 && !hasMoreComments) {
      return null;
    }

    const prevButton =
      commentPage > 0 ? (
        <button
          className={styles.pageControl}
          onClick={() => this.changeCommentPage('prev')}
        >
          <i className="fa fa-arrow-left" />
        </button>
      ) : null;

    const nextButton = hasMoreComments ? (
      <button
        className={styles.pageControl}
        onClick={() => this.changeCommentPage('next')}
      >
        <i className="fa fa-arrow-right" />
      </button>
    ) : null;

    return (
      <div>
        {prevButton}
        <div className={styles.pageInfo}>{`${commentPage + 1}`}</div>
        {nextButton}
      </div>
    );
  }

  public RenderTagMessage = (): JSX.Element => {
    const { processState, loading } = this.props;

    if (loading?.editIssueTags || !processState?.editIssueTags) {
      return <></>;
    }

    switch (processState.editIssueTags) {
      case 'success':
        return (
          <p className={styles.successMessage}>Tags updated for this issue!</p>
        );
      case 'failed':
        return (
          <p className={styles.errorMessage}>
            One or more tags have been deleted.
          </p>
        );

      default:
        return <></>;
    }
  };

  public render(): React.ReactNode {
    const { issue, tags, projectUsers, loading } = this.props;
    const {
      showShareModal,
      shareIssueBtnLoading,
      selectedShareToUserIdsList,
      showEditTags,
    } = this.state;

    const {
      RenderResolveButton,
      RenderShareButton,
      RenderTagMessage,
      mapTagIdsToTagNames,
    } = this;

    const optionsList: { label: string; value: string }[] = [];

    projectUsers
      .filter((a) => a.role !== 'field_staff')
      .map((a) => {
        const value = { label: `${a.email} - ${a.name}`, value: a.userId };

        optionsList.push(value);

        return a;
      });

    return (
      <div className={styles.container}>
        <div>{this.renderIssueImage()}</div>
        <div className={styles.details}>
          <div>
            <h1 className={styles.title}>{issue.title}</h1>
            <section className={styles.description}>{issue.message}</section>
            <div className={styles.auther}>
              {`By ${issue.author}, on ${moment(
                new Date(issue.createdAt)
              ).format('ll')}`}
            </div>
            <div>
              <div className={styles.editTagsInfo}>
                <p className={styles.tagsInfo}>
                  Tags:{' '}
                  {mapTagIdsToTagNames(issue.tagIds).length
                    ? mapTagIdsToTagNames(issue.tagIds).join(',  ')
                    : ' No tags attached to this issue yet!'}
                </p>
                {!issue.resolved && this.isUserProjectAdminOrCreator() ? (
                  <Tooltip placement="right" title="Update Tags">
                    <div
                      onClick={this.handleEditTags}
                      className={styles.editIcon}
                    >
                      <i className="fa fa-edit" />
                    </div>
                  </Tooltip>
                ) : null}
              </div>
              {showEditTags ? (
                tags.length ? (
                  <div className={styles.editTagsControl}>
                    <Select
                      mode="multiple"
                      placeholder="Please select tags"
                      className={styles.dropdown}
                      defaultValue={issue.tagIds} // show tags already atteched to this issue
                      onChange={this.handleTagSelection}
                    >
                      {tags.map((tag) => (
                        <Select.Option key={tag.id} value={tag.id}>
                          {tag.tagName}
                        </Select.Option>
                      ))}
                    </Select>
                    <Button
                      onClick={this.handleUpdateIssueTags}
                      className={styles.updateTags}
                      loading={loading?.editIssueTags}
                    >
                      Update Tags
                    </Button>
                  </div>
                ) : (
                  <p className={styles.warningMessage}>
                    Please contact project admin to add tags to given project.
                  </p>
                )
              ) : null}
              <RenderTagMessage />
            </div>
          </div>
          <div className={styles.detailsBtnWrapper}>
            <RenderShareButton />
            <RenderResolveButton />
          </div>
        </div>
        <div className={styles.comments}>{this.renderAddComment()}</div>
        <div className={styles.commentList}>{this.renderComments()}</div>

        {showShareModal && (
          <Modal
            title="Confirm action"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onCancel={() => {
              this.handleShareIssueBtn();
            }}
            className={styles.shareModalContainer}
          >
            <div className={styles.shareModalInnerWrapper}>
              <Text className={styles.shareModalTitle}>Select Users</Text>

              <Checkbox.Group
                options={optionsList}
                onChange={this.handleShareCheckboxChange}
              />
            </div>
            <div className={styles.shareModalBtnWrapper}>
              <Button
                className={styles.cancelButton}
                onClick={() => {
                  this.handleShareIssueBtn();
                }}
                transparent
                text="CANCEL"
              />
              <Button
                disabled={
                  !selectedShareToUserIdsList ||
                  selectedShareToUserIdsList.length < 1
                }
                loading={shareIssueBtnLoading}
                loadingText="Sharing..."
                onClick={() => {
                  this.processShareIssueBtn();
                }}
                text="Ok"
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default IssueDetail;
