import autobind from 'autobind-decorator';
import { Marker } from 'google-maps-react';
import 'pannellum';
import * as React from 'react';
import { getValidGeoJson } from '../../utils/helpers';
import BoundaryMap from '../Mission/BoundaryMap';
import style from './Exterior360.module.scss';
import SkeletonLoader from '../SkeletonLoader';
import { GenericObjectType, NullOrGenericObjectType } from '../../shapes/app';
import {
  googleMapDefaultMarkerIcon,
  googleMapTailIconMarker,
  loadGoogleMapApi,
} from '../PlotGoogleMap/helpers';
import { BASE_CAPI_URL } from '../../constants/urls';
import { APP_PRIMARY_COLOR } from '../../constants/colors';
import { GOOGLE_GLOBAL_KEY } from '../../constants/tokens';
import {
  filteredArrayValue,
  undefinedOrNull,
  getGeoJsonFeatures,
} from '../../utils/functs';

declare const pannellum: any;

interface Hotspot {
  id: string;
  pitch: number;
  yaw: number;
  text: string;
  type: string;
}

export interface IProps {
  view: any;
  onConfigChange?: (config: any) => void;
  fetchSingleIssue: (id: string) => void;
  onShapeCreate?: (geoJson: NullOrGenericObjectType) => void;
  viewDescriptor?: any;
  viewConfig?: any;
  shareId?: string;
  boundary?: any;
  issueId?: string;
  issue: any;
  isMarkIssueActive: boolean;
  loadingSingleIssue: boolean;
  isCreatingIssue: boolean;
}

interface IState {
  descriptor: any;
  imageUrl: string;
  selectedImage: string;
  hFov: number;
  yaw: number;
  pitch: number;
  hotspot: Hotspot | null;
  showToast: boolean;
}

class Exterior360 extends React.Component<IProps, IState> {
  private googleMaps: null | GenericObjectType = null;

  private viewer: any;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      descriptor: props.viewDescriptor || { images: [] },
      imageUrl: '',
      selectedImage: '',
      yaw: 0,
      hFov: 90,
      pitch: -45,
      hotspot: null,
      showToast: false,
    };
  }

  public componentDidMount(): void {
    const { issueId, fetchSingleIssue } = this.props;

    this.fetchGoogleMapApi();
    if (!undefinedOrNull(issueId)) {
      fetchSingleIssue(issueId);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: any): void {
    const {
      issueId,
      issue,
      fetchSingleIssue,
      view,
      loadingSingleIssue,
      isMarkIssueActive,
    } = this.props;

    if (issueId !== nextProps.issueId) {
      fetchSingleIssue(nextProps.issueId);
    } else if (issue !== nextProps.issue) {
      if (nextProps.issue) {
        this.setView(nextProps.view, nextProps.issue.viewConfig);
      }
    }

    if (loadingSingleIssue !== nextProps.loadingSingleIssue) {
      if (!undefinedOrNull(nextProps.issue)) {
        const geoJSON = JSON.parse(nextProps.issue.shapeGeoJson);
        const hotspotDataFromIssue = getGeoJsonFeatures(geoJSON);

        if (!undefinedOrNull(hotspotDataFromIssue)) {
          this.setState({
            hotspot: {
              id: 'issue-hotspot-restored',
              yaw: hotspotDataFromIssue.geometry.coordinates[0],
              pitch: hotspotDataFromIssue.geometry.coordinates[1],
              text: hotspotDataFromIssue.properties.text,
              type: hotspotDataFromIssue.properties.type,
            },
            pitch: hotspotDataFromIssue.geometry.coordinates[1],
            yaw: hotspotDataFromIssue.geometry.coordinates[0],
            hFov: hotspotDataFromIssue.properties.zoom,
          });
        }
      }
    }

    // if mark_issue control is opened
    if (
      nextProps.isMarkIssueActive !== isMarkIssueActive &&
      nextProps.isMarkIssueActive &&
      undefinedOrNull(issueId)
    ) {
      this.setState({ showToast: true }); // eslint-disable-line
    }

    if (nextProps.view) {
      // if current state has view and id is same then dont update
      if (view && view.id === nextProps.view.id) {
        return;
      }

      this.setView(nextProps.view);
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState): void {
    const { imageUrl, selectedImage, descriptor, yaw, hFov, pitch, hotspot } =
      this.state;
    const { imageUrl: prevImageUrl } = prevState;
    const { isMarkIssueActive, isCreatingIssue, issue } = this.props;

    // if mark_issue control is closed
    if (
      prevProps.isMarkIssueActive !== isMarkIssueActive &&
      !isMarkIssueActive
    ) {
      this.setState({ showToast: false }); // eslint-disable-line
      this.viewer.removeHotSpot('issue-hotspot');
      this.viewer.removeHotSpot('issue-hotspot-restored');
    }

    // if issue creation process started
    if (prevProps.isCreatingIssue !== isCreatingIssue && isCreatingIssue) {
      this.setState({ showToast: false }); // eslint-disable-line
    }

    const filtered = descriptor.images.filter(
      (i: any) => i.id === selectedImage
    );
    const image = filtered.length ? filtered[0] : null;
    const tiled = image ? image.tiled : false;

    if (!this.state || !imageUrl) {
      return;
    }

    if (prevImageUrl !== imageUrl) {
      if (this.viewer) {
        try {
          this.viewer.destroy();
        } catch (e) {
          console.error(e);
        }
      }

      const maxLevel = image ? image.maxLevels || 3 : 3;
      const tileResolution = image ? image.tileSize || 1024 : 1024;
      const cubeResolution = image ? image.cubeSize || 8432 : 8432;

      const { shareId } = this.props;
      const config = tiled
        ? {
            autoLoad: true,
            crossOrigin: 'use-credentials',
            compass: true,
            hfov: hFov,
            pitch,
            yaw,
            type: 'multires',
            multiRes: {
              crossOrigin: 'use-credentials',
              basePath: `${imageUrl}/tiles`,
              path: `/%l/%s%y_%x.jpg${
                shareId ? `?vimana_share_token=${shareId}&dummy=` : '?dummy='
              }`,
              fallbackPath: `/fallback/%s.jpg${
                shareId ? `?vimana_share_token=${shareId}&dummy=` : '?dummy='
              }`,
              extension: '',
              maxLevel,
              tileResolution,
              cubeResolution,
            },
          }
        : {
            type: 'equirectangular',
            panorama: `${imageUrl}${
              shareId ? `?vimana_share_token=${shareId}&dummy=` : '?dummy='
            }`,
            autoLoad: true,
            crossOrigin: 'use-credentials',
            pitch,
            yaw,
            compass: true,
            hfov: hFov,
          };

      let hotspotConfig: any;

      if (hotspot) {
        if (!undefinedOrNull(issue)) {
          if (issue.viewConfig.selection === selectedImage) {
            hotspotConfig = {
              ...hotspot,
              targetPitch: hotspot.pitch || -90,
              targetYaw: hotspot.yaw || 0,
              targetHfov: hFov,
            };
            (config as any).hotspots = [hotspotConfig];
          }
        }
      }

      const node = document.getElementById('pannellum_container');

      this.viewer = pannellum.viewer(node, config);

      if (hotspotConfig) {
        this.viewer.on('load', () => {
          try {
            this.viewer.addHotSpot(hotspotConfig);
          } catch (e) {
            console.error(e);
          }
        });
      }

      this.viewer.on('mouseup', () => {
        this.compareAndSetPanoState();
      });

      this.viewer.on('touchend', () => {
        this.compareAndSetPanoState();
      });

      this.viewer.on('zoomchange', (updatedHfov: any) => {
        this.setState({ hFov: updatedHfov });
      });

      if (node) {
        node.addEventListener('contextmenu', (e: any) => {
          this.markHotspot(e);
        });
      }
    }
  }

  private renderViewer() {}

  private fetchGoogleMapApi = (): void => {
    loadGoogleMapApi(this.initList);
  };

  private initList = () => {
    this.initMap();

    const { view, issueId, issue } = this.props;

    if (view) {
      if (issueId) {
        // If issue already present
        if (issue) {
          this.setView(view, issue.viewConfig);
        } else {
          this.setView(view);
        }
      } else {
        this.setView(view);
      }
    }
  };

  private initMap = () => {
    this.googleMaps = window[GOOGLE_GLOBAL_KEY]
      ? window[GOOGLE_GLOBAL_KEY].maps
      : undefined;
  };

  @autobind
  public getScreenshot(): string | null {
    const { viewer } = this;
    const { yaw, hFov } = this.state;

    if (!viewer) {
      return null;
    }

    return viewer
      .getRenderer()
      .render(
        (viewer.getPitch() / 180) * Math.PI,
        (yaw / 180) * Math.PI,
        (hFov / 180) * Math.PI,
        { returnImage: true }
      );
  }

  private markHotspot(e: any): void {
    const { isMarkIssueActive, issueId } = this.props;

    this.viewer.removeHotSpot('issue-hotspot');

    if (!isMarkIssueActive && undefinedOrNull(issueId)) {
      this.viewer.removeHotSpot('issue-hotspot-restored');

      return;
    }

    const [pitch, yaw] = this.viewer.mouseEventToCoords(e);

    this.setState(
      {
        hotspot: {
          id: 'issue-hotspot',
          yaw,
          pitch,
          text: '',
          type: 'info',
        },
      },
      () => {
        const { hotspot, hFov } = this.state;
        const { onShapeCreate } = this.props;

        this.viewer.addHotSpot(hotspot);
        if (onShapeCreate) {
          const geoJson = getValidGeoJson(
            {
              type: 'Point',
              coordinates: [hotspot?.yaw, hotspot?.pitch],
            },
            { text: '', type: 'info', zoom: hFov }
          );

          onShapeCreate(geoJson);
        }
      }
    );
  }

  public compareAndSetPanoState(): void {
    const { yaw, pitch, hFov } = this.state;

    const viewerYaw = this.viewer.getYaw();
    const viewerHFov = this.viewer.getHfov();
    const viewerPitch = this.viewer.getPitch();

    if (yaw !== viewerYaw || hFov !== viewerHFov || pitch !== viewerPitch) {
      this.setState({
        yaw: viewerYaw,
        pitch: viewerPitch,
        hFov: viewerHFov,
      });
    }
  }

  public getMarkers = () => {
    const { descriptor, selectedImage, yaw } = this.state;
    const markers: any[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const img of descriptor.images || []) {
      const selected = selectedImage === img.id;
      const imageYaw = img.imageYaw || 0;
      const panoYaw = img.panoYaw || 0;
      const icon = selected
        ? googleMapTailIconMarker(
            this.googleMaps,
            180 + yaw + (imageYaw - panoYaw)
          )
        : googleMapDefaultMarkerIcon();
      const marker = (
        <Marker
          key={img.id}
          position={{ lat: img.latitude, lng: img.longitude }}
          icon={icon}
          onClick={
            selected
              ? () => {
                  /**/
                }
              : this.getMarkerClickHandler(img)
          }
          zIndex={selected ? 99 : 999}
        />
      );

      markers.push(marker);
      if (selected) {
        markers.push(
          <Marker
            key={`${img.id}-dup`}
            position={{ lat: img.latitude, lng: img.longitude }}
            icon={googleMapDefaultMarkerIcon(APP_PRIMARY_COLOR)}
          />
        );
      }
    }

    return markers;
  };

  public polarToCartesian(
    centerX: any,
    centerY: any,
    radius: any,
    angleInDegrees: any
  ): { x: number; y: number } {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  public describeArc(
    x: any,
    y: any,
    radius: any,
    startAngle: any,
    endAngle: any
  ): string {
    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);

    return `M ${x},${y} ${start.x},${start.y} ${end.x},${end.y} Z`;
  }

  public getMarkerClickHandler = (img: any) => {
    return () => {
      this.selectImage(img.id);
    };
  };

  public setView(view: any, viewConfig: any | null = null): void {
    const { viewDescriptor, viewConfig: viewConfigProps } = this.props;

    this.setState({ imageUrl: '' });
    const { projectId, aoiId, id } = view;

    if (viewDescriptor) {
      const descriptor = viewDescriptor;

      descriptor.images = (descriptor.images || []).filter(
        (i: any) => !i.hidden
      );

      this.setState({ descriptor });
      let selection = descriptor.images[0].id;

      if (viewConfigProps && viewConfigProps.selection) {
        // eslint-disable-next-line prefer-destructuring
        selection = viewConfigProps.selection;
      }

      this.selectImage(selection);
    } else {
      fetch(
        `${BASE_CAPI_URL}/v1/projects/${projectId}/aois/${aoiId}/views/${id}/config`,
        {
          method: 'GET',
          credentials: 'include',
        }
      )
        .then((x) => x.json())
        .then((descriptor) => {
          const desc = descriptor;

          desc.images = (desc.images || []).filter((i: any) => !i.hidden);
          this.setState({ descriptor: desc });
          // even if viewConfig.selection may point to a
          // hidden image, selection may have come from a share/issue
          // so select it disregarding viewConfig
          if (viewConfig && viewConfig.viewId === view.id) {
            this.selectImage(viewConfig.selection);
          } else {
            const filteredImage = filteredArrayValue(
              (descriptor.images ?? []).filter(
                (a: GenericObjectType) => a.default
              )
            );
            let selectedImageId = descriptor.images[0].id;

            if (filteredImage) {
              selectedImageId = filteredImage.id;
            }

            this.selectImage(selectedImageId);
          }
        })
        .catch((err) => console.error(err));
    }
  }

  public selectImage(imageId: any): void {
    const { view } = this.props;
    const { descriptor } = this.state;

    const filtered = descriptor.images.filter((i: any) => i.id === imageId);
    const image = filtered.length ? filtered[0] : null;

    if (!image) {
      return;
    }

    // update yaw to make new pano point in sme direction
    const { selectedImage: prevImageId, yaw } = this.state;
    let deltaYaw = 0;

    if (prevImageId) {
      const prevImage = descriptor.images.find(
        (i: any) => i.id === prevImageId
      );

      if (!undefinedOrNull(prevImage) && !undefinedOrNull(image)) {
        // get delta for yaw
        const prevImageYaw = prevImage.imageYaw || 0;
        const prevImagePanoYaw = prevImage.panoYaw || 0;
        const currImageYaw = image.imageYaw || 0;
        const currImagePanoYaw = image.panoYaw || 0;

        deltaYaw =
          prevImageYaw - prevImagePanoYaw - (currImageYaw - currImagePanoYaw);
      }
    }

    this.setState(
      {
        imageUrl: `${BASE_CAPI_URL}/v1/images/views/${view.id}/images/${image.id}`,
        selectedImage: image.id,
        yaw: yaw + deltaYaw,
      },
      this.callConfigChangeHandler
    );
  }

  private callConfigChangeHandler = () => {
    const { onConfigChange } = this.props;

    if (onConfigChange) {
      onConfigChange(this.getConfig());
    }
  };

  private getConfig = () => {
    const { selectedImage } = this.state;

    return {
      selection: selectedImage,
    };
  };

  public render(): React.ReactNode {
    const { imageUrl, descriptor, showToast } = this.state;
    const { view, boundary } = this.props;

    if (!view || !this.state || !imageUrl) {
      return (
        <SkeletonLoader
          size={3}
          position="absolute"
          className={style.skeletonLoading}
        />
      );
    }

    const { projectId, aoiId } = view;

    return (
      <div className={style.container}>
        <div
          id="pannellum_container"
          ref={(node: any) => {
            if (node) {
              try {
                node.focus();
              } catch (e) {
                console.error(e);
              }
            }
          }}
          className={style.refNode}
        />
        {showToast && (
          <div className={style.onboardingWrapper}>
            <span>Right-click to add/move the issue marker</span>
            <div>
              <i
                className="fa fa-times-circle"
                aria-hidden="true"
                onClick={() => {
                  this.setState({
                    showToast: false,
                  });
                }}
              />
            </div>
          </div>
        )}
        <div className={style.controls}>
          <BoundaryMap
            projectId={projectId}
            aoiId={aoiId}
            points={[...descriptor.images]}
            boundary={boundary}
          >
            {this.getMarkers()}
          </BoundaryMap>
        </div>
      </div>
    );
  }
}

export default Exterior360;
