import * as React from 'react';
import { Tabs } from 'antd';
import style from './index.module.scss';
import ProjectApis from '../../../api/projects';
import ElevationDifferenceAPIs from '../../../api/elevationDifference';
import SkeletonLoader from '../../SkeletonLoader';
import ElevationDifferenceCrud from '../ElevationDifferenceCrud';
import { ElevationDifference } from '../../../api/elevationDifference.types';

import {
  ElevationDifferenceCrudContainerProps,
  ProcessedDataUploadCrudState,
} from './index.types';
import { Project } from '../../../api/projects.types';

const elevationDifferenceAPIs = new ElevationDifferenceAPIs();
const projectApis = new ProjectApis();
const { TabPane } = Tabs;

class ElevationDifferenceCrudContainer extends React.Component<
  ElevationDifferenceCrudContainerProps,
  ProcessedDataUploadCrudState
> {
  public constructor(props: ElevationDifferenceCrudContainerProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { match, actionType, showSnackBar } = this.props;
    const { projectId, aoiId, elevationDifferenceId } = match.params;

    if (projectId === null || aoiId === null) {
      // exit if projectId or aoiId not specified
      return;
    }

    projectApis.getProject(projectId).then((project) => {
      this.setState({
        projectData: project,
      });
    });

    if (actionType === 'edit' && elevationDifferenceId) {
      // add logic to fetch upload by id
      elevationDifferenceAPIs
        .getElevationDifferenceById(projectId, aoiId, elevationDifferenceId)
        .then((res) => {
          if (res.error) {
            return showSnackBar({
              body: res.error,
              type: 'error',
            });
          }

          if (res.data !== null) {
            this.setState({
              elevationDifference: res.data,
            });
          }
        });
    }
  }

  public updateElevationDifference = (
    newElevationDifference: ElevationDifference
  ): void => {
    if (newElevationDifference) {
      this.setState({ elevationDifference: newElevationDifference });
    }
  };

  public isLoading = (): boolean => {
    const { actionType } = this.props;
    const { elevationDifference: uploadData, projectData } = this.state;

    if (!projectData) {
      return true;
    }

    if (actionType === 'edit' && !uploadData) {
      return true;
    }

    return false;
  };

  public render() {
    const { elevationDifference, projectData } = this.state;

    return (
      <div className={style.container}>
        <Tabs>
          <TabPane tab="Elevation Difference" key="1">
            {this.isLoading() ? (
              <SkeletonLoader size={2} />
            ) : (
              <ElevationDifferenceCrud
                {...this.props}
                elevationDifference={elevationDifference}
                projectData={projectData as Project}
                updateElevationDifference={this.updateElevationDifference}
              />
            )}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default ElevationDifferenceCrudContainer;
