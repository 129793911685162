import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { fetchProject } from '../../redux/actions/projects';
import {
  showCoachMarks,
  resetCoachMarks,
} from '../../redux/actions/coachmarks';
import AoiList from './AoiList';
import './AoiList.module.scss';

const getProject = (state: any, props: any): any => {
  if (state && state.projects && state.projects.data) {
    const filtered = state.projects.data.filter(
      (p: any) => p.id === props.projectId
    );

    if (filtered.length) {
      return filtered[0];
    }
  }

  return null;
};

const mapStateToProps = (state: any, props: any): any => {
  return {
    project: getProject(state, props),
    loading: state.projects.loading,
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      fetchProject,
      showCoachMarks,
      resetCoachMarks,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AoiList)
);
