import * as React from 'react';
import styles from './index.module.scss';
import {
  ResizerAllowedDirectionsEnumTypes,
  ResizerPropsTypes,
} from './index.types';

// @todo Implement function to drag left, top, bottom of the resizer

class Resizer extends React.PureComponent<ResizerPropsTypes> {
  private node: {
    [key in Extract<
      keyof typeof ResizerAllowedDirectionsEnumTypes,
      string
    >]?: HTMLDivElement | null;
  } = {};

  private containerRef: HTMLDivElement | null;

  public componentDidMount(): void {
    this.registerEventListeners();
  }

  public UNSAFE_componentWillReceiveProps({
    showHotspot: nextShowHotspot,
  }: Readonly<ResizerPropsTypes>): void {
    const { showHotspot } = this.props;

    if (showHotspot !== nextShowHotspot) {
      this.deregisterEventListeners();
    }
  }

  public componentDidUpdate({
    showHotspot: prevShowHotspot,
  }: Readonly<ResizerPropsTypes>): void {
    const { showHotspot } = this.props;

    if (showHotspot !== prevShowHotspot) {
      this.registerEventListeners();
    }
  }

  public componentWillUnmount(): void {
    this.deregisterEventListeners();
  }

  private registerEventListeners = () => {
    // @todo Implement function to drag left, top, bottom of the resizer
    if (!this.node.right) {
      return;
    }

    this.node.right.addEventListener(
      'mousedown',
      () => {
        document.addEventListener('mousemove', this.handleResize, false);
      },
      false
    );

    document.addEventListener(
      'mouseup',
      () => {
        document.removeEventListener('mousemove', this.handleResize, false);
      },
      false
    );
  };

  private deregisterEventListeners = () => {
    // @todo Implement function to drag left, top, bottom of the resizer
    if (!this.node.right) {
      return;
    }

    this.node.right.removeEventListener('mousedown', () => {
      /**/
    });
    document.removeEventListener('mouseup', () => {
      /**/
    });
    document.removeEventListener('mousemove', () => {
      /**/
    });
  };

  private handleResize = (e: MouseEvent) => {
    const { onDrag } = this.props;

    let containerOffsetX = 0;

    if (this.containerRef) {
      const rect = this.containerRef.getBoundingClientRect();

      containerOffsetX = rect.x;
    }

    onDrag({ right: e.x - containerOffsetX });
  };

  public render(): React.ReactNode {
    const { children, allowedDirections, showHotspot, style } = this.props;

    return (
      <div
        className={styles.container}
        ref={(ref) => {
          this.containerRef = ref;
        }}
        style={style || {}}
      >
        {showHotspot.left && allowedDirections.indexOf('left') > -1 && (
          <div
            className={styles.left}
            ref={(n: any) => {
              this.node.left = n;
            }}
          />
        )}
        {children}
        {showHotspot.right && allowedDirections.indexOf('right') > -1 && (
          <div
            className={styles.right}
            ref={(n: any) => {
              this.node.right = n;
            }}
          />
        )}
      </div>
    );
  }
}

export default Resizer;
