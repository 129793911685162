import * as React from 'react';
import classnames from 'classnames';
import style from './index.module.scss';
import { LoadingPropsTypes } from './index.types';

export default class Loading extends React.PureComponent<LoadingPropsTypes> {
  public static defaultProps: Partial<LoadingPropsTypes> = {
    type: 'logo',
    position: 'static',
  };

  public componentDidMount(): void {
    const { position } = this.props;

    if (position === 'fixed') {
      // @todo: work on it issue: https://github.com/AspecScire/vimana_web_ng/issues/1214
      // setBodyOverflow('y');
    }
  }

  public componentWillUnmount(): void {
    const { position } = this.props;

    if (position === 'fixed') {
      // @todo: work on it issue: https://github.com/AspecScire/vimana_web_ng/issues/1214
      // resetBodyOverFlow('y');
    }
  }

  public render(): React.ReactNode {
    const { position, className, type, size } = this.props;

    return (
      <div
        className={classnames(
          style.containerWrapper,
          style[position],
          className
        )}
      >
        {type === 'logo' ? (
          <div className={classnames(style.logoContainer, style.loading)}>
            <div
              className={classnames(
                style.logo,
                size === 'large' ? style.large : ''
              )}
            />
          </div>
        ) : (
          <div className={classnames(style.ellipsisContainer)}>
            <div />
            <div />
            <div />
            <div />
          </div>
        )}
      </div>
    );
  }
}
