// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ElevationInfo_container__njazK{width:auto}.ElevationInfo_label__RMJWc label{color:rgba(255,255,255,.8)}.ElevationInfo_scrollable__l6dpx{max-height:50vh;overflow-y:scroll}.ElevationInfo_elevation__bnFIO{width:95%}", "",{"version":3,"sources":["webpack://./src/components/View/ViewControls/MeasureControlBox/ElevationInfo/index.module.scss"],"names":[],"mappings":"AAEA,gCACE,UAAA,CAGF,kCACE,0BAAA,CAGF,iCACE,eAAA,CACA,iBAAA,CAGF,gCACE,SAAA","sourcesContent":["@import '../../../../../styles/scss/base/base';\n\n.container {\n  width: auto;\n}\n\n.label label {\n  color: rgba(255, 255, 255, 0.8);\n}\n\n.scrollable {\n  max-height: 50vh;\n  overflow-y: scroll;\n}\n\n.elevation {\n  width: 95%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ElevationInfo_container__njazK",
	"label": "ElevationInfo_label__RMJWc",
	"scrollable": "ElevationInfo_scrollable__l6dpx",
	"elevation": "ElevationInfo_elevation__bnFIO"
};
export default ___CSS_LOADER_EXPORT___;
