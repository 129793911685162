// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LegendControlBox_container__zVOqp{width:auto;height:50vh;overflow-y:scroll}.LegendControlBox_legendName__6qAsc{padding:10px;position:relative;cursor:pointer;opacity:.5}.LegendControlBox_legendDot__ew3zL{width:20px;height:20px;background:red;display:inline-block;border-radius:50%;float:right;position:absolute;bottom:0;top:0;right:11px;margin:auto;border:1px solid #fff}.LegendControlBox_enabled__IUkvF{opacity:1}", "",{"version":3,"sources":["webpack://./src/components/LegendControlBox/LegendControlBox.module.scss"],"names":[],"mappings":"AAEA,mCACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,oCACE,YAAA,CACA,iBAAA,CACA,cAAA,CACA,UAAA,CAGF,mCACE,UAAA,CACA,WAAA,CACA,cAAA,CACA,oBAAA,CACA,iBAAA,CACA,WAAA,CACA,iBAAA,CACA,QAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CAGF,iCACE,SAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  width: auto;\n  height: 50vh;\n  overflow-y: scroll;\n}\n\n.legendName {\n  padding: 10px;\n  position: relative;\n  cursor: pointer;\n  opacity: 0.5;\n}\n\n.legendDot {\n  width: 20px;\n  height: 20px;\n  background: #f00;\n  display: inline-block;\n  border-radius: 50%;\n  float: right;\n  position: absolute;\n  bottom: 0;\n  top: 0;\n  right: 11px;\n  margin: auto;\n  border: 1px solid #fff;\n}\n\n.enabled {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LegendControlBox_container__zVOqp",
	"legendName": "LegendControlBox_legendName__6qAsc",
	"legendDot": "LegendControlBox_legendDot__ew3zL",
	"enabled": "LegendControlBox_enabled__IUkvF"
};
export default ___CSS_LOADER_EXPORT___;
