import * as React from 'react';

import style from './index.module.scss';

interface IProps {
  onClick?: () => void;
}

class Overlay extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { children, ...rest } = this.props;

    return (
      <div className={style.container} {...rest}>
        {children}
      </div>
    );
  }
}

export default Overlay;
