// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MapMakerControls_controlGrid__CIoDC{display:grid;grid-template-columns:1fr 1fr;grid-column-gap:8px;grid-row-gap:8px}.MapMakerControls_fullWidth__Aznes{width:100%}.MapMakerControls_selectorGrid__bVdqv{display:grid;grid-template-columns:3fr 1fr;grid-column-gap:8px;padding-bottom:8px;border-bottom:1px solid gray}.MapMakerControls_placeholder__2S8jx{height:90px;font-size:16px}", "",{"version":3,"sources":["webpack://./src/components/View/ViewControls/MapMakerControls/index.module.scss"],"names":[],"mappings":"AAAA,qCACE,YAAA,CACA,6BAAA,CACA,mBAAA,CACA,gBAAA,CAGF,mCACE,UAAA,CAGF,sCACE,YAAA,CACA,6BAAA,CACA,mBAAA,CACA,kBAAA,CACA,4BAAA,CAGF,qCACE,WAAA,CACA,cAAA","sourcesContent":[".controlGrid {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-column-gap: 8px;\n  grid-row-gap: 8px;\n}\n\n.fullWidth {\n  width: 100%;\n}\n\n.selectorGrid {\n  display: grid;\n  grid-template-columns: 3fr 1fr;\n  grid-column-gap: 8px;\n  padding-bottom: 8px;\n  border-bottom: 1px solid grey;\n}\n\n.placeholder {\n  height: 90px;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlGrid": "MapMakerControls_controlGrid__CIoDC",
	"fullWidth": "MapMakerControls_fullWidth__Aznes",
	"selectorGrid": "MapMakerControls_selectorGrid__bVdqv",
	"placeholder": "MapMakerControls_placeholder__2S8jx"
};
export default ___CSS_LOADER_EXPORT___;
