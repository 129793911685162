import * as React from 'react';
import { Checkbox, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classnames from 'classnames';

import MLClassAPIs from '../../api/mlClasses';
import { SiteObjectClass } from '../../shapes/ml';
import DocumentationLink from '../DocumentationLink';
import { DOCUMENTATION_URL_LIST } from '../../constants/urls';
import styles from './index.module.scss';
import droneImage from '../DroneAnimation/images/drone.svg';
import SkeletonLoader from '../SkeletonLoader';
import ModalNotification from '../ModalNotification/ModalNotification';
import { Button } from '../Button';
import { inArray } from '../../utils/functs';
import { ManageMLPropsType, ManageMLStateType } from './index.types';

const mlClassAPIs = new MLClassAPIs();
const { Title } = Typography;

export default class ManageProjectML extends React.Component<
  ManageMLPropsType,
  ManageMLStateType
> {
  constructor(props: ManageMLPropsType) {
    super(props);
    this.state = {
      globalClasses: null,
      projectClasses: null,
      selectedClasses: [],
      popups: { confirmUpdate: false },
      loading: false,
      projectIsMLEnabled: null,
    };
  }

  componentDidMount() {
    const { projectId } = this.props;

    mlClassAPIs.getSiteObjectClasses().then((res) => {
      if (res.error) {
        console.error(
          'Encountered an error while fetching the global class set',
          res.error
        );

        return;
      }

      this.setState({
        globalClasses: res.data,
      });
    });

    mlClassAPIs.getProjectSiteObjectClasses(projectId).then((res) => {
      if (res.error) {
        console.error(
          'Encountered an error while fetching the classes associated with the project',
          res.error
        );

        return;
      }

      this.setState({
        projectClasses: res.data,
        selectedClasses: res.data.map((siteClass) => siteClass.id),
      });
    });

    mlClassAPIs.checkProjectMLEnabled(projectId).then((res) => {
      if (res.error) {
        console.error(
          'Encountered an error while checking if the project is ML enabled.',
          res.error
        );

        // default to false, when API error
        this.setState({
          projectIsMLEnabled: false,
        });

        return;
      }

      this.setState({
        projectIsMLEnabled: res.data.enabled,
      });
    });
  }

  public handleCheckboxChanged = (classId: string) => {
    return (e: CheckboxChangeEvent) => {
      const { selectedClasses: oldClasses } = this.state;
      const filteredClasses = oldClasses.filter((cls) => cls !== classId);

      if (e.target.checked) {
        this.setState({
          selectedClasses: [...filteredClasses, classId],
        });
      } else {
        this.setState({
          selectedClasses: [...filteredClasses],
        });
      }
    };
  };

  public handleReset = () => {
    const { projectClasses } = this.state;

    if (!projectClasses) {
      return;
    }

    const selectedClasses = projectClasses.map((cls) => cls.id);

    this.setState({
      selectedClasses,
    });
  };

  public showUpdateConfirmModal = (show: boolean) => {
    const { popups } = this.state;

    popups.confirmUpdate = show;

    this.setState({
      popups,
    });
  };

  public handleUpdateProjectClasses = () => {
    const { projectId, showSnackbar } = this.props;
    const { selectedClasses, popups } = this.state;

    this.setState(
      {
        loading: true,
      },
      () => {
        mlClassAPIs
          .updateProjectSiteObjectClasses(projectId, selectedClasses)
          .then((res) => {
            if (res.error) {
              showSnackbar({
                type: 'error',
                body: "There was an unexpected error while trying to update the Project's ML Classes",
              });
            } else {
              this.setState({
                projectClasses: res.data,
                selectedClasses: res.data.map((cls) => cls.id),
              });
            }

            popups.confirmUpdate = false;

            this.setState({ loading: false, popups });

            showSnackbar({
              type: 'success',
              body: "The Project's ML Classes were updated successfully.",
            });
          });
      }
    );
  };

  public groupSiteClassesByType = (classList: SiteObjectClass[]) => {
    const typeGroup = {};

    classList.forEach((cls) => {
      if (typeGroup[cls.type]) {
        typeGroup[cls.type].push(cls);
      } else {
        typeGroup[cls.type] = [cls];
      }
    });

    return typeGroup;
  };

  public renderClassCheckBox = (
    siteClass: SiteObjectClass,
    selectedClasses: string[]
  ) => {
    return (
      <div className={styles.checkboxContainer} key={siteClass.id}>
        <div>
          <Checkbox
            checked={inArray(selectedClasses, siteClass.id)}
            onChange={this.handleCheckboxChanged(siteClass.id)}
          />
          <span className={styles.name}>{siteClass.name}</span>
        </div>
        <label className={styles.description}>{siteClass.description}</label>
      </div>
    );
  };

  public renderClassesByType = (
    classList: SiteObjectClass[],
    classType: string
  ) => {
    const { selectedClasses } = this.state;

    const classCheckboxes = classList.map((cls: SiteObjectClass) => {
      return this.renderClassCheckBox(cls, selectedClasses);
    });

    if (classList.length % 2 === 1) {
      classCheckboxes.push(<div className={styles.checkboxContainer} />);
    }

    return (
      <div className={styles.typeGroup} key={classType}>
        <h3>{classType}</h3>
        <hr />
        <div className={styles.siteClassContainer}>{classCheckboxes}</div>
      </div>
    );
  };

  public renderClassSelection = () => {
    const { globalClasses, projectClasses } = this.state;

    if (!globalClasses || !projectClasses) {
      return <SkeletonLoader />;
    }

    const typeGroup = this.groupSiteClassesByType(globalClasses);

    return Object.keys(typeGroup).map((key) => {
      return this.renderClassesByType(typeGroup[key], key);
    });
  };

  public renderPopups = () => {
    const { popups } = this.state;

    return (
      <React.Fragment>
        {popups.confirmUpdate ? (
          <ModalNotification
            notificationTitle="Confirm ML Class Update"
            notificationBody="Are you sure you want to update the ML Classes for this Project? This will affect the outputs displayed in the Detect Feature."
            shownotificationModal
            isConfirm
            handleModalClose={(isOk: boolean) => {
              if (isOk) {
                this.handleUpdateProjectClasses();
              } else {
                this.showUpdateConfirmModal(false);
              }
            }}
          />
        ) : null}
      </React.Fragment>
    );
  };

  renderProjectMLDisabled = () => {
    return (
      <div className={classnames(styles.droneContainer, 'center')}>
        <img src={droneImage} className={styles.drone} alt="" />
        <div className={styles.disabledText}>
          Contact support@aspecscire.com to enable ML for this Project.
        </div>
      </div>
    );
  };

  render() {
    const { loading, projectIsMLEnabled } = this.state;

    if (projectIsMLEnabled === null) {
      return <SkeletonLoader />;
    }

    if (!projectIsMLEnabled) {
      return this.renderProjectMLDisabled();
    }

    return (
      <div className={styles.container}>
        <div className={styles.bodyContainer}>
          <div className={styles.titleWrapper}>
            <Title level={3} className={styles.title}>
              Manage Detect
            </Title>
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.manageDetect}
              toolTipPosition="right"
            />
          </div>
          <div className={styles.classWrapper}>
            {this.renderClassSelection()}
          </div>
          <div className={styles.buttonWrapper}>
            <span className={styles.resetButton}>
              <Button
                text="Reset"
                type="secondary"
                onClick={this.handleReset}
              />
            </span>
            <Button
              text="Update Project ML Classes"
              onClick={() => this.showUpdateConfirmModal(true)}
              loading={loading}
            />
          </div>
        </div>
        {this.renderPopups()}
      </div>
    );
  }
}
