import * as React from 'react';
import { Typography } from 'antd';
import Login from '../Login/container';
import View from '../View';
import style from './Share.module.scss';
import SkeletonLoader from '../SkeletonLoader';
import { ShareProps, ShareState } from './index.types';

const { Text } = Typography;

class Share extends React.Component<ShareProps, ShareState> {
  public constructor(props: ShareProps) {
    super(props);
    this.state = {
      share: null,
      isInvalidShareData: false,
    };
  }

  public componentDidMount(): void {
    const { id, fetchShare } = this.props;

    fetchShare(id);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ShareProps): void {
    const { share, error, updateViewsManually } = this.props;

    if (nextProps.share !== share) {
      this.setState({ share: nextProps.share });

      if (nextProps.share && nextProps.share.view) {
        updateViewsManually([nextProps.share.view]);
      }
    }

    if (nextProps.error && nextProps.error !== error) {
      const { error } = nextProps;

      if (error.status && error.status === 401) {
        this.setState({ needsLogin: true });
      } else if (error.status && error.status === 404) {
        this.setState({ isInvalidShareData: true });
      } else if (error.status && error.status === 403) {
        this.setState({ needsAuth: true });
      }
    }
  }

  public componentWillUnmount(): void {
    const { clearShareState, resetViewsData } = this.props;

    clearShareState();
    resetViewsData();
  }

  public render(): React.ReactNode {
    const { location, unAuthorized, overlayGeojson, showSatellite } =
      this.props;
    const { share, needsLogin, isInvalidShareData, needsAuth } = this.state;

    if (needsLogin) {
      return <Login />;
      // eslint-disable-next-line
    } else {
      // eslint-disable-next-line
      if (needsAuth) {
        unAuthorized();
      }
    }

    if (isInvalidShareData) {
      return (
        <div className={style.noDatacontainer}>
          <Text>This link is not valid.</Text>
        </div>
      );
    }

    if (!share || !share.view) {
      return <SkeletonLoader position="fixed" />;
    }

    const { view } = share;

    return (
      <div className={style.container}>
        <View
          projectId={view.projectId}
          aoiId={view.aoiId}
          viewId={view.id}
          view={view}
          share={share}
          controlsWhitelist={[]}
          overlayGeojson={overlayGeojson}
          showSatellite={showSatellite?.toLowerCase() === 'true'}
          onEvent={() => {}}
          history={location.history}
        />
      </div>
    );
  }
}

export default Share;
