import autobind from 'autobind-decorator';
import classnames from 'classnames';
import * as React from 'react';

import * as ControlBox from '../ControlBox';
import style from './LegendControlBox.module.scss';
import { ViewControlsV2TypeTypes } from '../ViewControlsV2/index.types';
import { undefinedOrNull } from '../../utils/functs';

export interface ILayer {
  id: string;
  type: string;
  name: string;
  color: string;
  enabled?: boolean;
}

interface IProps {
  itemType?: ViewControlsV2TypeTypes;
  legends: ILayer[];
  onClose?: (itemType: ViewControlsV2TypeTypes) => void;
  onLegendToggle: (id: string, status: boolean) => void;
}

class LegendControlBox extends React.Component<IProps> {
  @autobind
  public renderHeader(): React.ReactElement<HTMLElement> {
    const { onClose, itemType } = this.props;

    return (
      <React.Fragment>
        <ControlBox.Title title="Legend" />
        <ControlBox.Icon
          onClick={() => {
            if (!onClose || undefinedOrNull(itemType)) {
              return;
            }

            onClose(itemType);
          }}
          name="close"
        />
      </React.Fragment>
    );
  }

  public render(): React.ReactNode {
    const { legends, onLegendToggle } = this.props;

    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={this.renderHeader}
      >
        {(legends ?? []).map((legend) => (
          <div
            onClick={() => onLegendToggle(legend.id, !legend.enabled)}
            className={classnames(
              style.legendName,
              legend.enabled && style.enabled
            )}
            key={legend.name}
          >
            {legend.name}
            {legend.type !== 'raster' ? (
              <span
                className={style.legendDot}
                style={{ backgroundColor: legend.color }}
              />
            ) : null}
          </div>
        ))}
      </ControlBox.Wrapper>
    );
  }
}

export default LegendControlBox;
