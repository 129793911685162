/* eslint-disable func-names */

import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import length from '@turf/length';
import lineSegment from '@turf/line-segment';
import immer from 'immer';
import * as mapboxgl from 'mapbox-gl';
import { clearAllPopups } from './Popup';

const StaticMode: any = {};

const POPUP = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
  className: 'noevent',
});

StaticMode.onSetup = function (opts: any) {
  const features = (opts && opts.featureIds) || [];

  clearAllPopups();

  this.setSelected(
    features.filter((id: any) => {
      return this.getFeature(id) !== undefined;
    })
  );
  const showMetric = (opts && opts.showMetric) || false;

  (window as any).StaticMode = this;

  return {
    showMetric,
  };
};

StaticMode.onStop = () => {
  POPUP.remove();
};

StaticMode.onMouseMove = function (state: any, event: any) {
  if (!state.showMetric) {
    return;
  }

  const { featureTarget } = event;

  if (featureTarget) {
    const { properties } = featureTarget;

    this.updateUIClasses({
      mouse: Constants.cursors.POINTER,
    });
    POPUP.setHTML(
      `${properties.lengthInMeter}m (${properties.lengthInFeet}ft)`
    ).setLngLat(event.lngLat);
    if (!POPUP.isOpen()) {
      POPUP.addTo(this.map);
    }
  } else {
    this.updateUIClasses({
      mouse: Constants.cursors.NONE,
    });
    if (POPUP.isOpen()) {
      POPUP.remove();
    }
  }
};

StaticMode.toDisplayFeatures = function (
  state: any,
  geojson: any,
  display: any
) {
  if (state.showMetric) {
    const segments = lineSegment(geojson);

    segments.features.forEach((segment) => {
      const newSegment = immer(segment, (draftSegment) => {
        if (draftSegment && draftSegment.properties) {
          const lengthInMeter = length(draftSegment, {
            units: 'meters',
          }).toFixed(2);
          const lengthInFeet = length(draftSegment, { units: 'feet' }).toFixed(
            2
          );

          // eslint-disable-next-line no-param-reassign
          draftSegment.properties = {
            ...draftSegment.properties,
            id: `${draftSegment.properties.id}-${draftSegment.id}`,
            lengthInMeter,
            lengthInFeet,
          };
        }
      });

      display(newSegment);
    });
  } else {
    const feature = this.getFeature(geojson.properties.id);

    if (feature && feature.properties.label) {
      const labelPoint = {
        type: 'Feature',
        properties: {
          label: `P${feature.properties.label}`,
          meta: 'label',
        },
        geometry: geojson.geometry,
      };

      display(labelPoint);
    }

    display(geojson);
  }
};

export default StaticMode;
