import ApiBase from './ApiBase';
import { GenericApisReturnPromiseTypes } from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_CAPI_URL } from '../constants/urls';

export default class CapiV2Apis extends ApiBase {
  private readonly _baseUrl: string;

  private readonly _baseUrlV2: string;

  public constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = `/v1`;
    this._baseUrlV2 = `/v2`;
  }

  public getProjectUsers(projectId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrlV2}/projects/${projectId}/users`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the project users data.`,
        });
      });
  }
}
