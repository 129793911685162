// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Snackbar_container__b\\+FEG{max-height:450px;overflow-x:auto;overflow-y:auto;position:fixed;top:65px;right:5px;z-index:10000}", "",{"version":3,"sources":["webpack://./src/components/Snackbar/Snackbar.module.scss"],"names":[],"mappings":"AAEA,4BACE,gBAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA,CACA,QAAA,CACA,SAAA,CACA,aAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  max-height: #{$maxSnackbarContainerHeight}px;\n  overflow-x: auto;\n  overflow-y: auto;\n  position: fixed;\n  top: #{$headerHeight + 5}px;\n  right: 5px;\n  z-index: 10000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Snackbar_container__b+FEG"
};
export default ___CSS_LOADER_EXPORT___;
