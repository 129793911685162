import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Typography } from 'antd';
import { Button } from '../../Button';
import styles from './Parameters.module.scss';
import { SnackbarActionsActionShowSnackbarTypes } from '../../../shapes/snackbar';
import {
  TerrainMapsReducersStateTypes,
  TerrainMapsSourceTypeTypes,
} from '../../../shapes/terrainMaps';
import HelpTooltip from '../../HelpTooltip';
import { toMetersEquivalent, undefinedOrNull } from '../../../utils/functs';
import ModalNotification from '../../ModalNotification/ModalNotification';

const { Title } = Typography;

interface IProps {
  showSnackbar: SnackbarActionsActionShowSnackbarTypes;
  onGenerateDtmBtn: () => void;
  generateBtnLoading: boolean;
  finalizeForm: boolean;
  form: any;
  sourceType: TerrainMapsSourceTypeTypes;
  uploadContourPointsFile: any;
  disableGenerateBtn: () => any;
  unit: string;
}

interface IState {
  showGenerateConfirmModal: boolean;
}

export type TerrainMapsTabsLayout1ParametersPropsTypes = IProps &
  TerrainMapsReducersStateTypes &
  SnackbarActionsActionShowSnackbarTypes;

class TerrainMapsTabsLayout1Parameters extends React.PureComponent<
  TerrainMapsTabsLayout1ParametersPropsTypes,
  IState
> {
  public constructor(props: TerrainMapsTabsLayout1ParametersPropsTypes) {
    super(props);

    this.state = {
      showGenerateConfirmModal: false,
    };
  }

  private getDefaultOutputDTMResolution = (): number | null => {
    const { dtmData, sourceType } = this.props;

    if (
      dtmData &&
      dtmData.sourceType === sourceType &&
      !undefinedOrNull(dtmData.parameters.outputDTMResolution)
    ) {
      return dtmData.parameters.outputDTMResolution;
    }

    return null;
  };

  private getDefaultElevationAttribute = (): { initialValue?: string } => {
    const { dtmData, sourceType } = this.props;

    if (
      dtmData &&
      dtmData.sourceType === sourceType &&
      !undefinedOrNull(dtmData.parameters.elevationAttribute)
    ) {
      return { initialValue: dtmData.parameters.elevationAttribute };
    }

    return {};
  };

  private getElevationAttributePlaceholder = (): string => {
    const { uploadContourPointsFile } = this.props;

    if (!uploadContourPointsFile) {
      return 'Select an import file to populate this field';
    }

    return 'Select Elevation Attribute';
  };

  private disableElevationAttributeField = (): boolean => {
    const { uploadContourPointsFile, finalizeForm } = this.props;

    if (finalizeForm) {
      return true;
    }

    return uploadContourPointsFile && uploadContourPointsFile.length < 1;
  };

  private generateBtnName = (): string => {
    const { dtmData } = this.props;

    if (!dtmData || dtmData.status === 'pending') {
      return 'GENERATE TERRAIN MAP';
    }

    return 'REGENERATE TERRAIN MAP';
  };

  private validateFieldValue = (rule: any, value: any, callback: any) => {
    const { unit } = this.props;

    if (!undefinedOrNull(value)) {
      if (value !== '' && value < toMetersEquivalent(0.1, unit)) {
        callback(
          `Field value must be greater than ${toMetersEquivalent(0.1, unit)}`
        );
      }
    }

    callback();
  };

  private handleShowGenerateConfirmModal = (): void => {
    this.setState(({ showGenerateConfirmModal }) => {
      return {
        showGenerateConfirmModal: !showGenerateConfirmModal,
      };
    });
  };

  private handleGenerateConfirmModal = (value: boolean): void => {
    const { onGenerateDtmBtn } = this.props;

    this.setState(() => {
      return {
        showGenerateConfirmModal: false,
      };
    });

    if (!value) {
      return;
    }

    onGenerateDtmBtn();
  };

  private generateConfirmBodyText = (): string => {
    const { dtmData } = this.props;

    if (!dtmData || dtmData.status === 'pending') {
      return 'Are you sure you want to start Terrain Map generation with these parameters?';
    }

    return `Are you sure you want to re-generate this Terrain Map with these parameters? The existing Terrain Map will be cleaned up.`;
  };

  public render(): React.ReactNode {
    const { generateBtnLoading, form, finalizeForm, disableGenerateBtn, unit } =
      this.props;
    const { showGenerateConfirmModal } = this.state;

    const { getFieldDecorator } = form;

    return (
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <Title level={4}>Interpolation Parameters</Title>
        </div>
        <Form layout="vertical">
          <div className={styles.interPolationParamsInputWrapper}>
            <Form.Item
              label={`Output Terrain Map Resolution in (${unit}/pixel) `}
            >
              {getFieldDecorator('outputDTMResolution', {
                rules: [
                  {
                    required: true,
                    message: 'This field is required',
                  },
                  {
                    validator: this.validateFieldValue,
                  },
                ],
                initialValue: this.getDefaultOutputDTMResolution(),
              })(
                <Input
                  placeholder="Output Terrain Map Resolution"
                  style={{ margin: 0 }}
                  disabled={finalizeForm}
                  className={styles.inputField}
                />
              )}
            </Form.Item>
            <HelpTooltip
              position="right"
              helpText="The required Ground Sampling Distance (GSD) of the output Terrain Map in units/pixel, where the unit of measurement is the unit associated with the project's coordinate system, specified as EPSG code."
            />
          </div>
        </Form>
        <Button
          className={styles.generateBtn}
          disabled={disableGenerateBtn()}
          text={this.generateBtnName()}
          loadingText="Generating..."
          loading={generateBtnLoading}
          onClick={() => {
            this.handleShowGenerateConfirmModal();
          }}
        />

        {showGenerateConfirmModal ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody={this.generateConfirmBodyText()}
            shownotificationModal
            handleModalClose={this.handleGenerateConfirmModal}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}
      </div>
    );
  }
}

export default TerrainMapsTabsLayout1Parameters;
