import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../../redux/actions/auth';
import SignUP from './Signup';
import { EmptyObject } from '../../shapes/app';

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};

const SignupContainer = connect<any, any, EmptyObject>(
  null,
  mapDispatchToProps
)(SignUP) as any;

export default SignupContainer;
