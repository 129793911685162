// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserSandbox_sandboxModalContainer__F9oun{width:100%;top:0 !important;margin:0;height:100%;max-height:unset !important;max-width:100% !important;border:rgba(255,255,255,.25) 1px solid}.UserSandbox_sandboxModalContainer__F9oun .ant-modal-header{border-radius:0;background:#1f2022 !important}.UserSandbox_sandboxModalContainer__F9oun .ant-modal-header .ant-modal-title{font-size:18px;font-weight:bold}.UserSandbox_sandboxModalContainer__F9oun .ant-modal-body{height:calc(100vh - 57px);overflow-y:auto;background:#1f2022 !important;padding:18px !important}.UserSandbox_sandboxModalWrapper__f3WlZ{top:0 !important;z-index:10001 !important}.UserSandbox_contentContainer__lKFbT{width:90% !important;margin:auto !important;height:100%}", "",{"version":3,"sources":["webpack://./src/components/UserSandbox/index.module.scss"],"names":[],"mappings":"AAEA,0CACE,UAAA,CACA,gBAAA,CACA,QAAA,CACA,WAAA,CACA,2BAAA,CACA,yBAAA,CACA,sCAAA,CAGE,4DACE,eAAA,CACA,6BAAA,CAEA,6EACE,cAAA,CACA,gBAAA,CAIJ,0DACE,yBAAA,CACA,eAAA,CACA,6BAAA,CACA,uBAAA,CAKN,wCACE,gBAAA,CACA,wBAAA,CAGF,qCACE,oBAAA,CACA,sBAAA,CACA,WAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.sandboxModalContainer {\n  width: 100%;\n  top: 0 !important;\n  margin: 0;\n  height: 100%;\n  max-height: unset !important;\n  max-width: 100% !important;\n  border: rgba(255, 255, 255, 0.25) 1px solid;\n\n  :global {\n    & .ant-modal-header {\n      border-radius: 0;\n      background: $appBgColor !important;\n\n      .ant-modal-title {\n        font-size: 18px;\n        font-weight: bold;\n      }\n    }\n\n    & .ant-modal-body {\n      height: calc(100vh - 57px);\n      overflow-y: auto;\n      background: $appBgColor !important;\n      padding: 18px !important;\n    }\n  }\n}\n\n.sandboxModalWrapper {\n  top: 0 !important;\n  z-index: 10001 !important;\n}\n\n.contentContainer {\n  width: 90% !important;\n  margin: auto !important;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sandboxModalContainer": "UserSandbox_sandboxModalContainer__F9oun",
	"sandboxModalWrapper": "UserSandbox_sandboxModalWrapper__f3WlZ",
	"contentContainer": "UserSandbox_contentContainer__lKFbT"
};
export default ___CSS_LOADER_EXPORT___;
