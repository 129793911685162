import * as React from 'react';
import { Tabs } from 'antd';
import { Button } from '../../Button';
import FlightPlanGenerator from '../FlightPlanGenerator';
import styles from './ViewFlightPlan.module.scss';
import SkeletonLoader from '../../SkeletonLoader';
import {
  BASE_NAVIGATOR_API_URL,
  DOCUMENTATION_URL_LIST,
} from '../../../constants/urls';
import {
  GenericObjectType,
  NullOrGenericObjectType,
} from '../../../shapes/app';
import { SnackbarActionsActionShowSnackbarTypes } from '../../../shapes/snackbar';
import DocumentationLink from '../../DocumentationLink';
import HelpTooltip from '../../HelpTooltip';

const { TabPane } = Tabs;

interface IProps {
  showSnackbar: SnackbarActionsActionShowSnackbarTypes;
  aoiId: string;
  projectId: string;
  missionId: string;
  drones: any;
  flightPlan: any;
  planTypeLabel: string;
  epsg?: any;
  onSaveMission?: (req: GenericObjectType) => Promise<NullOrGenericObjectType>;
  user: any;
  droneId?: string;
  cameraId?: string;
  handlePlanChanged?: (plan: any) => void;
}

interface IState {
  tabIndex: string;
}

class ViewFlightPlanning extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      tabIndex: props.flightPlan.flightApp,
    };
  }

  private handlePlanChanged = (plan: any) => {
    const { handlePlanChanged } = this.props;

    if (handlePlanChanged) {
      handlePlanChanged(plan);
    }
  };

  private isFlightAppTabDisabled = (plan: NullOrGenericObjectType) => {
    if (plan?.type === 'INSPECTION' && plan.flightApp === 'LITCHI') {
      return true;
    }

    return false;
  };

  private getFlightAppTab = (plan: NullOrGenericObjectType) => {
    if (plan?.flightApp === 'OTHER' || plan?.type === 'INSPECTION') {
      return 'OTHER';
    }

    return 'LITCHI';
  };

  private handleTabChange = (tabIndex: string): void => {
    this.setState({ tabIndex });
  };

  public render(): React.ReactNode {
    if (!this.state) {
      return <SkeletonLoader size={2} position="fixed" />;
    }

    const {
      aoiId,
      cameraId,
      drones,
      droneId,
      projectId,
      missionId,
      flightPlan,
      planTypeLabel,
      user,
      epsg,
      onSaveMission,
    } = this.props;
    const { tabIndex } = this.state;

    if (flightPlan?.type === 'INSPECTION') {
      return (
        <React.Fragment>
          <div>
            <div className={styles.title}>
              <h3>
                {`${planTypeLabel} `}
                FLIGHT PLAN
                <DocumentationLink href={DOCUMENTATION_URL_LIST.flightPlans} />
              </h3>
            </div>
            <div className={styles.checkLater}>
              Under Construction! Please check again later!
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div>
          <div className={styles.title}>
            <h3>
              {`${planTypeLabel} `}
              FLIGHT PLAN
              <DocumentationLink href={DOCUMENTATION_URL_LIST.flightPlans} />
            </h3>
          </div>
          <div className={styles.dronesContainer}>
            <div key={flightPlan.id} className={styles.flightPlan}>
              <div className={styles.flexColumn}>
                <div className={styles.flightPlanType}>
                  <Tabs
                    centered
                    tabBarStyle={{ textAlign: 'center' }}
                    className={styles.reactTabs}
                    defaultActiveKey={this.getFlightAppTab(flightPlan)}
                    onChange={(tabIndex: string) =>
                      this.handleTabChange(tabIndex)
                    }
                  >
                    <TabPane
                      tab={
                        <span className={styles.tabSpan}>
                          Litchi Flight Plan
                        </span>
                      }
                      key="LITCHI"
                      disabled={this.isFlightAppTabDisabled(flightPlan)}
                    >
                      <HelpTooltip
                        tooltipText="Instructions"
                        helpText={
                          <div className={styles.tooltipData}>
                            <li className={styles.list}>
                              Flight plan that is generated includes CSV
                              file(s), instructions and checklist documents.
                            </li>
                            <li className={styles.list}>
                              CSV file(s) make up the Litchi flight plan and
                              should be uploaded into your Litchi account.
                            </li>
                            <li className={styles.list}>
                              Before starting the flight, please read the
                              accompanying documents.
                            </li>
                          </div>
                        }
                      />

                      <div className={styles.flexRow}>
                        <FlightPlanGenerator
                          aoiId={aoiId}
                          projectId={projectId}
                          missionId={missionId}
                          plan={flightPlan}
                          drones={drones}
                          epsg={epsg}
                          onPlanChange={this.handlePlanChanged}
                          user={user}
                          flightApp={tabIndex}
                          onSaveMission={onSaveMission}
                          droneId={droneId}
                          cameraId={cameraId}
                        />
                      </div>
                    </TabPane>
                    <TabPane
                      tab={
                        <span className={styles.tabSpan}>
                          Third Party Flight Plan
                        </span>
                      }
                      key="OTHER"
                      disabled={this.isFlightAppTabDisabled(flightPlan)}
                    >
                      <HelpTooltip
                        tooltipText="Instructions"
                        helpText={
                          <div className={styles.tooltipData}>
                            <li className={styles.list}>
                              Download the site boundary along with instructions
                              and checklist documents.
                            </li>
                            <li className={styles.list}>
                              Before starting on the flight, please read the
                              accompanying documents.
                            </li>
                            {flightPlan && flightPlan.type === 'NADIR' ? (
                              <li className={styles.list}>
                                Adherence to the side overlap, front overlap and
                                ground sampling distance (GSD) guidelines
                                mentioned in the documents, is necessary for
                                ensuring quality outputs.
                              </li>
                            ) : null}
                          </div>
                        }
                      />

                      <div className={styles.actionBtnWrapper}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${BASE_NAVIGATOR_API_URL}/v1/flightplans/${flightPlan.id}/thirdparty`}
                        >
                          <Button
                            className={styles.actionBtn}
                            text="DOWNLOAD INSTRUCTIONS"
                          />
                        </a>
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ViewFlightPlanning;
