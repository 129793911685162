// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CompareControlBox_container__WOV4w{padding:20px;min-height:200px}.CompareControlBox_container__WOV4w .CompareControlBox_item__FmJeQ{margin-bottom:15px}.CompareControlBox_container__WOV4w .CompareControlBox_item__FmJeQ:last-child{margin:0}.CompareControlBox_container__WOV4w label{font-size:14px;color:rgba(255,255,255,.4)}.CompareControlBox_container__WOV4w .Dropdown-menu{max-height:100px}", "",{"version":3,"sources":["webpack://./src/components/View/ViewControls/CompareControlBox/index.module.scss"],"names":[],"mappings":"AAEA,oCACE,YAAA,CACA,gBAAA,CAEA,mEACE,kBAAA,CAEA,8EACE,QAAA,CAIJ,0CACE,cAAA,CACA,0BAAA,CAIA,mDACE,gBAAA","sourcesContent":["@import '../../../../styles/scss/base/base';\n\n.container {\n  padding: 20px;\n  min-height: 200px;\n\n  & .item {\n    margin-bottom: 15px;\n\n    &:last-child {\n      margin: 0;\n    }\n  }\n\n  & label {\n    font-size: 14px;\n    color: rgba(255, 255, 255, 0.4);\n  }\n\n  & :global {\n    & .Dropdown-menu {\n      max-height: 100px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CompareControlBox_container__WOV4w",
	"item": "CompareControlBox_item__FmJeQ"
};
export default ___CSS_LOADER_EXPORT___;
