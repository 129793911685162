// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OSMMap_olTooltipContainer__dcfvU{pointer-events:none}.OSMMap_olTooltipContainer__dcfvU .OSMMap_olTooltip__TNOsh{position:relative;background:rgba(0,0,0,.8);border-radius:4px;color:#fff;padding:4px 8px;opacity:.7;white-space:nowrap;font-size:12px}.OSMMap_hidden__BmBLd{display:none}.OSMMap_olResetControl__Bwe5z{top:.5em;right:.5em}", "",{"version":3,"sources":["webpack://./src/components/OSMMap/index.module.scss"],"names":[],"mappings":"AAAA,kCACE,mBAAA,CAEA,2DACE,iBAAA,CACA,yBAAA,CACA,iBAAA,CACA,UAAA,CACA,eAAA,CACA,UAAA,CACA,kBAAA,CACA,cAAA,CAIJ,sBACE,YAAA,CAGF,8BACE,QAAA,CACA,UAAA","sourcesContent":[".olTooltipContainer {\n  pointer-events: none;\n\n  .olTooltip {\n    position: relative;\n    background: rgba(0, 0, 0, 0.8);\n    border-radius: 4px;\n    color: white;\n    padding: 4px 8px;\n    opacity: 0.7;\n    white-space: nowrap;\n    font-size: 12px;\n  }\n}\n\n.hidden {\n  display: none;\n}\n\n.olResetControl {\n  top: 0.5em;\n  right: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"olTooltipContainer": "OSMMap_olTooltipContainer__dcfvU",
	"olTooltip": "OSMMap_olTooltip__TNOsh",
	"hidden": "OSMMap_hidden__BmBLd",
	"olResetControl": "OSMMap_olResetControl__Bwe5z"
};
export default ___CSS_LOADER_EXPORT___;
