import { Button } from 'antd';
import React from 'react';
import CoordinateSystemApis from 'src/api/coordinateSystemDetails';

interface CSDProps {
  projectId: string;
  onCSDSelect: (guid: string) => void;
}

interface CSDState {
  csds?: any[];
  editing?: boolean;
  editModel?: {
    name: string;
    ref_global_x: number;
    ref_global_y: number;
    ref_local_x: number;
    ref_local_y: number;
    rotate_angle_deg: number;
    utm_srs: string;
  };
}

export default class CoordinateSystemDetails extends React.Component<
  CSDProps,
  CSDState
> {
  private csdApi = new CoordinateSystemApis();

  constructor(props: CSDProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    const { projectId } = this.props;

    this.csdApi.getCoordinateDetails(projectId).then(({ data, error }) => {
      if (error) {
        console.error(error);

        return;
      }

      if (data) {
        const csds = data?.data?.coordinate_system_details || [];
        const csd = csds.length > 0 ? csds[0] : {};

        this.setState({
          csds,
          editModel: this.getEditModel(csd),
        });
      }
    });
  }

  createCSD() {
    const { projectId } = this.props;

    this.csdApi
      .createCoordinateDetails(projectId, this.getRequest())
      .then(({ error, data }) => {
        if (error) {
          console.error(error);

          return;
        }

        if (data) {
          const csds = data?.data?.coordinate_system_details || [];
          const csd = csds.length > 0 ? csds[0] : {};

          this.setState({
            csds: [csd],
            editModel: this.getEditModel(csd),
          });
        }
      });
  }

  updateCSD() {
    const { projectId } = this.props;

    this.csdApi
      .updateCoordinateDetails(projectId, this.getRequest())
      .then(({ error, data }) => {
        if (error) {
          console.error(error);

          return;
        }

        if (data) {
          const csds = data?.data?.coordinate_system_details || [];
          const csd = csds.length > 0 ? csds[0] : {};

          this.setState({
            csds: [csd],
            editing: false,
            editModel: this.getEditModel(csd),
          });
        }
      });
  }

  getRequest() {
    const { editModel } = this.state;
    const {
      name,
      ref_global_x,
      ref_global_y,
      ref_local_x,
      ref_local_y,
      rotate_angle_deg,
      utm_srs,
    } = editModel as any;
    const req = {
      name,
      ref_global_x: parseFloat(ref_global_x),
      ref_global_y: parseFloat(ref_global_y),
      ref_local_x: parseFloat(ref_local_x),
      ref_local_y: parseFloat(ref_local_y),
      rotate_angle_deg: parseFloat(rotate_angle_deg),
      utm_srs: `EPSG:${utm_srs}`,
    };

    return req;
  }

  getEditModel(csd: any) {
    const em = {
      name: 'Default',
      ref_global_x: 0,
      ref_global_y: 0,
      ref_local_x: 0,
      ref_local_y: 0,
      rotate_angle_deg: 0,
      utm_srs: '',
      ...csd,
    };

    em.utm_srs = csd?.utm_srs?.replace('EPSG:', '');

    return em;
  }

  select(guid: string) {
    const { onCSDSelect } = this.props;

    onCSDSelect(guid);
  }

  renderEditor(ro: boolean) {
    const { editModel } = this.state;
    const em = editModel || {
      name: 'Default',
      ref_global_x: 0,
      ref_global_y: 0,
      ref_local_x: 0,
      ref_local_y: 0,
      rotate_angle_deg: 0,
      utm_srs: 0,
    };
    const setProp = (n: string, v: any) => {
      const { editModel: e } = this.state;
      const ns: any = { ...(e || {}) };

      ns[n] = v;
      this.setState({ editModel: ns });
    };

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td>ref_global_x</td>
              <td>
                {ro ? (
                  em.ref_global_x
                ) : (
                  <input
                    value={em.ref_global_x}
                    onChange={(e) => setProp('ref_global_x', e.target.value)}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>ref_global_y</td>
              <td>
                {ro ? (
                  em.ref_global_y
                ) : (
                  <input
                    value={em.ref_global_y}
                    onChange={(e) => setProp('ref_global_y', e.target.value)}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>ref_local_x</td>
              <td>
                {ro ? (
                  em.ref_local_x
                ) : (
                  <input
                    value={em.ref_local_x}
                    onChange={(e) => setProp('ref_local_x', e.target.value)}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>ref_local_y</td>
              <td>
                {ro ? (
                  em.ref_local_y
                ) : (
                  <input
                    value={em.ref_local_y}
                    onChange={(e) => setProp('ref_local_y', e.target.value)}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>rotate_angle_deg</td>
              <td>
                {ro ? (
                  em.rotate_angle_deg
                ) : (
                  <input
                    value={em.rotate_angle_deg}
                    onChange={(e) =>
                      setProp('rotate_angle_deg', e.target.value)
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>utm_srs</td>
              <td>
                EPSG:
                {ro ? (
                  em.utm_srs
                ) : (
                  <input
                    value={em.utm_srs}
                    onChange={(e) => setProp('utm_srs', e.target.value)}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { csds, editing } = this.state;

    if (!csds) {
      return <div>Loading...</div>;
    }

    if (csds.length === 0) {
      return (
        <div>
          <Button type="primary" onClick={() => this.createCSD()}>
            Create
          </Button>{' '}
          coordinate system details entity
          {this.renderEditor(false)}
        </div>
      );
    }

    const csd = csds[0];

    return (
      <div>
        <p>
          {csd.name} ({csd.guid})
          {!editing && (
            <>
              <Button type="primary" onClick={() => this.select(csd.guid)}>
                Select
              </Button>
              <Button onClick={() => this.setState({ editing: true })}>
                Edit
              </Button>
            </>
          )}
          {editing && (
            <>
              <Button type="primary" onClick={() => this.updateCSD()}>
                Update
              </Button>
              <Button onClick={() => this.setState({ editing: false })}>
                Cancel
              </Button>
            </>
          )}
        </p>
        {this.renderEditor(!editing)}
      </div>
    );
  }
}
