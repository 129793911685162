import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Share from './Share';
import { clearShareState, fetchShare } from '../../redux/actions/share';
import { unAuthorized } from '../../redux/actions/auth';
import { updateViewsManually, resetViewsData } from '../../redux/actions/views';
import { ShareTypes } from './index.types';

const mapStateToProps = (state: any): any => {
  return {
    share: state.share.data,
    loading: state.share.loading,
    error: state.share.error,
    userData: state?.auth?.user ?? null,
  };
};

const mapDispatchToProps = (dispatch: any): any => {
  return bindActionCreators(
    {
      fetchShare,
      clearShareState,
      updateViewsManually,
      resetViewsData,
      unAuthorized,
    },
    dispatch
  );
};

const ShareContainer = connect<
  ShareTypes.ReduxState,
  ShareTypes.DispatchProps,
  ShareTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(Share);

export default ShareContainer;
