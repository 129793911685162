import classNames from 'classnames';
import * as React from 'react';
import { View } from '../../../api/views.types';
import ViewsV2Apis from '../../../api/viewsV2';
import { VIMEO_EMBED_URL, YOUTUBE_EMBED_URL } from '../../../constants/urls';
import { log } from '../../../utils/log';
import Dropdown from '../../DropDown/DropDown';
import { BaseRendererProps } from '../index.types';
import styles from './index.module.scss';

interface IProps extends BaseRendererProps {
  view: View;
}

interface IState {
  selectedIndex: number;
  viewDescriptor: any;
}

export default class VideoView extends React.Component<IProps, IState> {
  private viewsV2Apis = new ViewsV2Apis();

  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedIndex: 0,
      viewDescriptor: null,
    };
  }

  public componentDidMount() {
    const { share, onFooterControlsFragChange } = this.props;

    if (share) {
      this.setState({ viewDescriptor: share?.viewDescriptor });
    } else {
      this.fetchviewDescriptor();
    }

    if (onFooterControlsFragChange) {
      onFooterControlsFragChange(undefined);
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { view: prevView } = prevProps;
    const { view } = this.props;

    if (view.id !== prevView.id) {
      this.fetchviewDescriptor();
    }
  }

  private fetchviewDescriptor() {
    const { view } = this.props;

    if (view) {
      const { projectId, aoiId, id: viewId } = view;

      this.viewsV2Apis
        .getViewDescriptor(projectId, aoiId, viewId)
        .then((res) => {
          const { error, data } = res;

          if (data) {
            this.setState({ viewDescriptor: data });
          }

          if (error) {
            log.error(error);
          }
        });
    }
  }

  private getSelectedVideo() {
    const { selectedIndex, viewDescriptor } = this.state;

    if (selectedIndex !== null && viewDescriptor?.videos?.length) {
      const videos = viewDescriptor?.videos || [];

      if (selectedIndex > -1 && selectedIndex < videos.length) {
        return videos[selectedIndex];
      }
    }

    return null;
  }

  public render() {
    const { selectedIndex, viewDescriptor } = this.state;

    if (!viewDescriptor) {
      return <div className={styles.container}>Loading...</div>;
    }

    const videos = viewDescriptor?.videos || [];
    const video = this.getSelectedVideo();

    if (video) {
      return (
        <div className={styles.container}>
          <div className={styles.dropDownWrapper}>
            <Dropdown
              options={videos.map((v: any, idx: number) => {
                return {
                  label: v.name,
                  value: `${idx}`,
                };
              })}
              value={`${selectedIndex}`}
              onChange={(e: any) => {
                try {
                  this.setState({ selectedIndex: parseInt(e.value, 10) });
                } catch (e) {
                  log.error(e);
                }
              }}
            />
          </div>
          <div className={styles.videoPlayerWrapper}>
            {video.provider === 'youtube' && (
              <iframe
                onLoad={() => {}}
                title="youtube"
                src={`${YOUTUBE_EMBED_URL}/${video.videoId}`}
                className={classNames(styles.videoPlayer, {
                  hide: false,
                })}
                allowFullScreen
                frameBorder="0"
              />
            )}
            {video.provider === 'vimeo' && (
              <iframe
                onLoad={() => {}}
                title="vimeo"
                src={`${VIMEO_EMBED_URL}/${video.videoId}`}
                className={classNames(styles.videoPlayer, {
                  hide: false,
                })}
                allowFullScreen
                frameBorder="0"
                allow="autoplay; fullscreen"
              />
            )}
          </div>
        </div>
      );
    }

    return <div className={styles.container} />;
  }
}
