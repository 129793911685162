import * as React from 'react';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import UploadImages from '../UploadImages';
import { Button } from '../../Button';
import {
  MissionUploadDataPropsTypes,
  MissionUploadDataStateTypes,
  MissionUploadUploadImagesActionType,
} from './index.types';
import UploadGCP from '../GCPPlanGenerator/UploadGCP';
import { inArray, undefinedOrNull } from '../../../utils/functs';
import { log } from '../../../utils/log';
import MissionV2CapiApis from '../../../api/missionV2Capi';
import ProjectAPIs from '../../../api/projects';
import SkeletonLoader from '../../SkeletonLoader';
import AoiV2Apis from '../../../api/aoiV2';
import { GenericObjectType } from '../../../shapes/app';
import { APP_BASE_URL, DOCUMENTATION_URL_LIST } from '../../../constants/urls';
import DocumentationLink from '../../DocumentationLink';
import ProcessedDataUploader from '../ProcessedDataUploader/container';
import { MissionRequirementId } from '../../../api/mission.types';
import { getAoiFromProjectData } from '../../AoiData';

const missionV2CapiApis = new MissionV2CapiApis();
const projectApis = new ProjectAPIs();
const aoiV2Apis = new AoiV2Apis();

export default class MissionUploadData extends React.PureComponent<
  MissionUploadDataPropsTypes,
  MissionUploadDataStateTypes
> {
  public constructor(props: MissionUploadDataPropsTypes) {
    super(props);

    this.state = {
      epsgCoordData: null,
      capiGcpPlansDataList: null,
      navigatorGcpPlansData: null,
      showUploadImagesModal: false,
      showUploadGcpsModal: false,
      showManualUploadsModal: false,
      uploadImagesActionType: 'upload',
      isImageUploading: false,
    };
  }

  public componentDidMount(): void {
    this.fetchMissionData();
    this.fetchProjectData();
    this.fetchCapiGcpPlans();
  }

  private fetchMissionData = () => {
    const { missionId, showSnackbar, projectId, aoiId } = this.props;

    missionV2CapiApis.getMission(projectId, aoiId, missionId).then((res) => {
      const { error: apiError, data: apiData } = res;

      if (apiError) {
        showSnackbar({ type: 'error', body: apiError });

        log.error(apiError, `UploadImages.fetchMissionData`);

        return;
      }

      this.setState({
        missionData: apiData,
      });
    });
  };

  private fetchProjectData = () => {
    const { projectId, showSnackbar } = this.props;

    projectApis
      .getProject(projectId)
      .then((res) => {
        this.setState({
          projectData: res,
        });

        this.fetchEpsgCoordData(res);
      })
      .catch((err) => {
        showSnackbar({
          type: 'error',
          body: 'There was an error while fetching the project associated with the mission.',
        });

        log.error(err, 'Mission.UploadData.fetchProjectData');
      });
  };

  private fetchEpsgCoordData = (projectData: GenericObjectType): void => {
    const { showSnackbar } = this.props;

    if (projectData.epsgCode) {
      aoiV2Apis
        .getEpsgData(projectData.epsgCode)
        .then((res) => {
          this.setState({
            epsgCoordData: res.data,
          });
        })
        .catch((err) => {
          showSnackbar({
            type: 'error',
            body: 'There was an error while fetching the coordinate data associated with the project.',
          });

          log.error(err, 'Mission.UploadData.fetchEpsgCoordData');
        });
    }
  };

  private fetchCapiGcpPlans = (): void => {
    const { aoiId, projectId, missionId, showSnackbar } = this.props;

    missionV2CapiApis
      .listGcpPlansForAoi(projectId, aoiId, missionId)
      .then((res) => {
        const { error: apiError, data: apiData } = res;

        if (!undefinedOrNull(apiError) || undefinedOrNull(apiData)) {
          log.error(apiError, 'UploadImages.fetchCapiGcpPlans');

          showSnackbar({
            body: apiError || 'Unable to fetch GCP plan data',
            type: 'error',
          });

          return;
        }

        this.setState(
          () => {
            return {
              capiGcpPlansDataList: apiData,
            };
          },
          () => {
            if (!apiData[0]) {
              return;
            }

            this.fetchNavigatorGcpPlanData(apiData[0].id);
          }
        );
      });
  };

  private fetchNavigatorGcpPlanData = (gcpPlanId: string): void => {
    const { showSnackbar } = this.props;

    fetch(`${APP_BASE_URL}/capi/api/v1/gcp_plan_data/${gcpPlanId}`, {
      credentials: 'include',
    })
      .then((res) => {
        if (res.status > 299) {
          const apiError =
            'There was an error while fetching the GCP Plan details.';

          showSnackbar({
            body: apiError,
            type: 'error',
          });

          log.error(apiError, 'UploadImages.fetchNavigatorGcpPlanData');

          throw res;
        }

        return res.json();
      })
      .then((gcpPlan) => {
        this.setState(() => {
          return {
            navigatorGcpPlansData: gcpPlan,
          };
        });
      });
  };

  private handleUploadImagesBtnClick = (
    value = false,
    type: MissionUploadUploadImagesActionType
  ) => {
    this.setState({
      showUploadImagesModal: value,
      uploadImagesActionType: type,
    });
  };

  private handleUploadGcpsBtnClick = (value = false) => {
    this.setState({
      showUploadGcpsModal: value,
    });
  };

  private handleManualUploadsBtnClick = (value = false) => {
    this.setState({
      showManualUploadsModal: value,
    });
  };

  private handleUploadGcpPlanChange = (value: GenericObjectType) => {
    this.setState({
      navigatorGcpPlansData: value,
    });
  };

  private getUploadGcpsCtaText = () => {
    const { capiGcpPlansDataList } = this.state;

    if (
      capiGcpPlansDataList &&
      capiGcpPlansDataList[0] &&
      inArray(['APPROVED'], capiGcpPlansDataList[0].gcpDataStatus)
    ) {
      return 'View Uploaded GCP Data';
    }

    return 'Upload';
  };

  private handleMissionsImageUploading = (value: true) => {
    this.setState({
      isImageUploading: value,
    });
  };

  private getUploadGcpsModalTitleText = () => {
    const { capiGcpPlansDataList } = this.state;

    return capiGcpPlansDataList &&
      capiGcpPlansDataList[0] &&
      capiGcpPlansDataList[0].status === 'APPROVED'
      ? 'Uploaded GCP Data'
      : 'Upload';
  };

  private getUploadImagesCtaText = () => {
    const { uploadImagesActionType } = this.state;

    return uploadImagesActionType === 'manage'
      ? 'Manage Uploaded Images'
      : 'Upload Images';
  };

  private RenderImageUploadModal = () => {
    return (
      <div className={styles.modalTitleWrapper}>
        <span>{this.getUploadImagesCtaText()}</span>
        <DocumentationLink
          href={DOCUMENTATION_URL_LIST.uploadDate}
          toolTipPosition="right"
        />
      </div>
    );
  };

  private RenderGcpUploadModal = () => {
    return (
      <div className={styles.modalTitleWrapper}>
        <span>{this.getUploadGcpsModalTitleText()}</span>
        <DocumentationLink
          href={DOCUMENTATION_URL_LIST.uploadDate}
          toolTipPosition="right"
        />
      </div>
    );
  };

  private hasRequirement = (reqId: MissionRequirementId): boolean => {
    const { missionData } = this.state;

    if (!missionData || !missionData.requirements) {
      return false;
    }

    return !!(missionData?.requirements || []).find((r: any) => r.id === reqId);
  };

  private hasAoiBoundary = () => {
    const { aoiId } = this.props;
    const { projectData } = this.state;
    const aoi = getAoiFromProjectData(projectData, aoiId);

    return !!aoi?.boundaryId;
  };

  public render() {
    const {
      showSnackbar,
      aoiId,
      projectId,
      missionId,
      showManageModal,
      onManageModalClose,
    } = this.props;
    const {
      showUploadImagesModal,
      showUploadGcpsModal,
      showManualUploadsModal,
      capiGcpPlansDataList,
      navigatorGcpPlansData,
      missionData,
      projectData,
      epsgCoordData,
      uploadImagesActionType,
      isImageUploading,
    } = this.state;

    const { RenderImageUploadModal, RenderGcpUploadModal } = this;
    const hasMapsRequirement = this.hasRequirement('AERIAL_MAPS');
    const hasPointCloudRequirement = this.hasRequirement('THREE_D_VIEW');
    const hasThermalRequirement = this.hasRequirement('THERMAL_MAP');
    const hasDTMRequirement = hasMapsRequirement;

    if (!capiGcpPlansDataList || !missionData || !projectData) {
      return (
        <div className={styles.loadingWrapper}>
          <SkeletonLoader />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <div className={styles.contentInnerWrapper}>
            <span className={styles.title}>IMAGES</span>

            <div className={styles.btnWrapper}>
              <Button
                type="primary"
                onClick={() => {
                  this.handleUploadImagesBtnClick(true, 'upload');
                }}
              >
                Upload
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  this.handleUploadImagesBtnClick(true, 'manage');
                }}
              >
                Manage
              </Button>
            </div>
          </div>

          <div className={styles.contentInnerWrapper}>
            <span className={styles.title}>CONTROL POINTS</span>

            <div className={styles.btnWrapper}>
              <Button
                type="primary"
                onClick={() => {
                  this.handleUploadGcpsBtnClick(true);
                }}
                disabled={
                  !capiGcpPlansDataList || capiGcpPlansDataList.length < 1
                }
              >
                {this.getUploadGcpsCtaText()}
              </Button>

              {/* this is empty/fake place holder to keep the upload gcp data btn in alignment */}
              <button style={{ visibility: 'hidden' }} />
              <div />
            </div>
          </div>

          <div className={styles.contentInnerWrapper}>
            <span className={styles.title}>PROCESSED DATA</span>

            <div className={styles.btnWrapper}>
              <Button
                type="primary"
                onClick={() => {
                  if (projectData) {
                    if (
                      !projectData.epsgCode ||
                      projectData.epsgCode.length === 0
                    ) {
                      showSnackbar({
                        type: 'error',
                        body: (
                          <p>
                            Project EPSG must be specified before uploading
                            processed data. Project EPSG can be specified{' '}
                            <Link to={`/project/${projectId}/edit`}>
                              <u>here</u>
                            </Link>
                            .
                          </p>
                        ),
                      });

                      return;
                    }

                    if (!this.hasAoiBoundary()) {
                      showSnackbar({
                        type: 'error',
                        body: (
                          <p>
                            Boundary must be specified before uploading
                            processed data. Boundary can be specified &nbsp;
                            <Link
                              to={`/project/${projectId}/aoi/${aoiId}/edit`}
                            >
                              <u>here</u>
                            </Link>
                            .
                          </p>
                        ),
                      });

                      return;
                    }

                    this.handleManualUploadsBtnClick(true);
                  }
                }}
                disabled={
                  !hasMapsRequirement &&
                  !hasPointCloudRequirement &&
                  !hasDTMRequirement &&
                  !hasThermalRequirement
                }
              >
                Upload processed data
              </Button>

              {/* this is empty/fake place holder to keep the upload gcp data btn in alignment */}
              <button style={{ visibility: 'hidden' }} />
              <div />
            </div>
          </div>
        </div>

        {(showUploadImagesModal || showManageModal) && (
          <Modal
            title={<RenderImageUploadModal />}
            className={styles.uploadModalContainer}
            centered={false}
            footer={null}
            visible={showUploadImagesModal || showManageModal}
            onCancel={() => {
              if (isImageUploading) {
                return;
              }

              if (onManageModalClose) {
                onManageModalClose();
              }

              this.handleUploadImagesBtnClick(false, 'upload');
            }}
            destroyOnClose
            maskClosable={false}
            width="100%"
            wrapClassName={styles.uploadModalWrapper}
          >
            <UploadImages
              missionData={missionData}
              showSnackbar={showSnackbar}
              aoiId={aoiId}
              projectId={projectId}
              missionId={missionId}
              projectData={projectData}
              units={epsgCoordData && epsgCoordData.unit}
              onReloadMissionData={this.fetchMissionData}
              uploadImagesActionType={
                showManageModal ? 'manage' : uploadImagesActionType
              }
              onImageUploading={this.handleMissionsImageUploading}
              onUploadImagesActionTypeChange={this.handleUploadImagesBtnClick}
            />
          </Modal>
        )}

        {showUploadGcpsModal && (
          <Modal
            title={<RenderGcpUploadModal />}
            centered={false}
            footer={null}
            visible={showUploadGcpsModal}
            onCancel={() => {
              this.handleUploadGcpsBtnClick();
            }}
            destroyOnClose
            maskClosable={false}
            width={900}
          >
            <UploadGCP
              aoiId={aoiId}
              projectId={projectId}
              missionId={missionId}
              plan={capiGcpPlansDataList[0]}
              epsgCode={projectData.epsgCode}
              units={epsgCoordData && epsgCoordData.unit}
              gcpPlanData={navigatorGcpPlansData || { gcp_points: [] }}
              onPlanChange={this.fetchCapiGcpPlans}
              getGcpPlanData={this.fetchNavigatorGcpPlanData}
            />
          </Modal>
        )}

        {showManualUploadsModal && (
          <Modal
            title={<RenderGcpUploadModal />}
            className={styles.uploadModalContainer}
            centered={false}
            footer={null}
            visible={showManualUploadsModal}
            onCancel={() => {
              this.handleManualUploadsBtnClick(false);
            }}
            destroyOnClose
            maskClosable={false}
            width="100%"
            wrapClassName={styles.uploadModalWrapper}
          >
            <ProcessedDataUploader
              aoiId={aoiId}
              projectId={projectId}
              missionId={missionId}
              hideMapUpload={!hasMapsRequirement}
              hidePointcloudUpload={!hasPointCloudRequirement}
              hideDTMUpload={!hasDTMRequirement}
              hideThermalUpload={!hasThermalRequirement}
              projectData={projectData}
              units={epsgCoordData && epsgCoordData.units}
            />
          </Modal>
        )}
      </div>
    );
  }
}
