import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Tabs, Input, Typography } from 'antd';
import { withRouter } from 'react-router';
import { Button } from '../../Button';
import { undefinedOrNull } from '../../../utils/functs';
import styles from './index.module.scss';
import { RouterParamsTypes } from '../../../shapes/routerParams';
import FloorPlans from '../../../api/floorPlans';
import { log } from '../../../utils/log';
import { GenericApisReturnTypes } from '../../../shapes/app';

interface IState {
  floorPlanSuccess: string | null;
  postingFloorPlan: boolean;
  floorPlanError: string | null;
  selectedPlanFile: File | null;
  levelName: string;
  previewUrl: string;
  isImageValid: boolean;
}

interface IProps extends RouterParamsTypes {
  updateFloorPlans: (floorPlan: any[]) => void;
  updateModalVisibility: (show: boolean) => void;
  showManageLevelModal: boolean;
  floorPlans: any[];
}

const { Text } = Typography;

const floorPlansApi = new FloorPlans();

class FloorLevelManager extends React.Component<IProps, IState> {
  private imageInput = React.createRef<HTMLInputElement>();

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedPlanFile: null,
      previewUrl: '#',
      postingFloorPlan: false,
      floorPlanError: null,
      floorPlanSuccess: null,
      levelName: '',
      isImageValid: true,
    };
  }

  private handleCancelModal = () => {
    // hide modal
    const { updateModalVisibility } = this.props;

    updateModalVisibility(false);

    this.setState({
      selectedPlanFile: null,
      floorPlanError: null,
      floorPlanSuccess: null,
      previewUrl: '#',
      levelName: '',
      isImageValid: true,
    });
  };

  private handleLevelNameInput = (e: any) => {
    this.setState({ levelName: e.target.value });
  };

  private convertFileToDataUrl = (file: File | null) => {
    if (!undefinedOrNull(file)) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.addEventListener('load', (e) => {
        if (!undefinedOrNull(e.target)) {
          const { result } = e.target as any;

          this.setState({ previewUrl: result });
        }
      });
    }
  };

  private handlePlanUpload = (uploadedPlan: File) => {
    this.setState(
      {
        selectedPlanFile: uploadedPlan,
      },
      () => {
        const { selectedPlanFile } = this.state;

        this.convertFileToDataUrl(selectedPlanFile);
      }
    );
  };

  private getErrorMessage = (res: GenericApisReturnTypes): string | null => {
    if (!undefinedOrNull(res.networkError)) {
      return res.networkError;
    }

    if (res.status === 400) {
      // generic error
      return 'Something brokedown, please try this action again..';
    }

    return res.error;
  };

  private isLevelInfoButtonDisabled = (): boolean => {
    const { selectedPlanFile, levelName, isImageValid } = this.state;

    return (
      !undefinedOrNull(selectedPlanFile) && levelName !== '' && isImageValid
    );
  };

  private handleLevelInfoSubmit = () => {
    const { match, floorPlans, updateFloorPlans } = this.props;
    const { selectedPlanFile, levelName } = this.state;
    const { projectId, aoiId } = match.params;
    const formdata = new FormData();

    if (!undefinedOrNull(selectedPlanFile)) {
      formdata.append('image', selectedPlanFile);
      formdata.append('name', levelName);

      this.setState({ postingFloorPlan: true });

      floorPlansApi
        .postFloorPlan(projectId, aoiId, formdata)
        .then((res) => {
          log.info(res.data);
          if (!undefinedOrNull(res.data) && Object.keys(res.data).length > 0) {
            updateFloorPlans([res.data, ...floorPlans]);
            this.setState({
              floorPlanSuccess: 'Floor plan added successfully!',
              floorPlanError: null,
            });
          } else {
            const errMessage = this.getErrorMessage(res);

            if (!undefinedOrNull(errMessage)) {
              this.setState({
                floorPlanError: errMessage,
                floorPlanSuccess: null,
              });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.setState({
            postingFloorPlan: false,
            selectedPlanFile: null,
            previewUrl: '#',
            levelName: '',
          });
        });
    }
  };

  private renderUpload = (): JSX.Element => {
    return (
      <input
        type="file"
        accept=".jpeg,.jpg"
        hidden
        ref={this.imageInput}
        onChange={(e) => {
          const { files } = e?.target || {};

          if (files && files.length > 0) {
            const file = files[0];

            if (e.target) {
              e.target.value = '';
            }

            this.handlePlanUpload(file);
          }
        }}
      />
    );
  };

  private hasImageValidDimensions = (width: number, height: number) => {
    return width > 300 && height > 300;
  };

  private renderFilePreview = (): JSX.Element | null => {
    const { previewUrl, selectedPlanFile } = this.state;

    if (!undefinedOrNull(selectedPlanFile)) {
      return (
        <div className={styles.uploader}>
          <Text>
            File uploaded is{' '}
            <Text strong underline>
              {selectedPlanFile.name}
            </Text>
          </Text>
          <img
            src={previewUrl}
            alt="Plan Preview"
            onLoad={(e: any) => {
              this.setState({
                isImageValid: this.hasImageValidDimensions(
                  e.target.width,
                  e.target.height
                ),
              });
            }}
          />
        </div>
      );
    }

    return null;
  };

  render(): React.ReactNode {
    const {
      postingFloorPlan,
      floorPlanError,
      floorPlanSuccess,
      levelName,
      isImageValid,
    } = this.state;

    const { showManageLevelModal } = this.props;

    return (
      <Modal
        title="Manage Levels"
        visible={showManageLevelModal}
        footer={null}
        destroyOnClose
        onCancel={this.handleCancelModal}
        zIndex={10001}
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane key="1" tab="Add Level">
            <Form layout="vertical">
              <Form.Item label="Level Name" required>
                <Input
                  value={levelName}
                  autoComplete="off"
                  onChange={this.handleLevelNameInput}
                  placeholder="Level (ex-Floor No.3)"
                />
              </Form.Item>
              <Form.Item label="Level Plan" required>
                {this.renderUpload()}
                <Button
                  type="secondary"
                  text="UPLOAD FLOOR PLAN"
                  icon="upload"
                  onClick={() => {
                    if (this.imageInput.current) {
                      // eslint-disable-next-line
                      this.imageInput.current.click();
                    }
                  }}
                  className={styles.uploadButton}
                />
                <Text style={{ fontSize: '0.8em' }}>
                  Only JPEG/JPG Files supported
                </Text>
                {!undefinedOrNull(floorPlanError) ? (
                  <p className={styles.errorMessage}>{floorPlanError}</p>
                ) : null}
                {!undefinedOrNull(floorPlanSuccess) ? (
                  <p className={styles.successMessage}>{floorPlanSuccess}</p>
                ) : null}
                {this.renderFilePreview()}
              </Form.Item>
              {!isImageValid ? (
                <p className={styles.imageValidationError}>
                  Please upload image with height and width 300px or more.
                </p>
              ) : null}
              <Button
                type="primary"
                onClick={this.handleLevelInfoSubmit}
                text="Add Level"
                loading={postingFloorPlan}
                loadingText="Adding floor plans..."
                disabled={!this.isLevelInfoButtonDisabled()}
              />
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="Edit Level">
            Upcoming feature...
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    );
  }
}

export default withRouter(FloorLevelManager);
