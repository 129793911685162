import { CancelToken } from 'axios';
import ApiBase from './ApiBase';
import {
  GenericApisReturnPromiseTypes,
  GenericObjectType,
} from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_CAPI_URL } from '../constants/urls';
import { UploadServiceType } from '../components/Mission/UploadImages/index.types';

export default class MissionV2CapiApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_CAPI_URL);

    this._baseUrl = `/v1`;
  }

  public getMission(
    projectId: string,
    aoiId: string,
    missionId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(
      `${this._baseUrl}/projects/${projectId}/aois/${aoiId}/missions/${missionId}`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the mission.`,
        });
      });
  }

  public listUploadedImages(missionId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/upload/missions/${missionId}/images`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch the mission images.`,
          isArray: true,
        });
      });
  }

  public postUploadedImages(
    uploadServiceType: UploadServiceType,
    uploadServiceTypeId: string,
    formData: GenericObjectType,
    cancelToken?: CancelToken
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/upload/${uploadServiceType}/${uploadServiceTypeId}/images`,
      formData,
      null,
      cancelToken
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to upload the images.`,
        });
      });
  }

  public updateMissionImageStatus(
    missionId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.patch(
      `${this._baseUrl}/upload/missions/${missionId}/images/status`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to update the mission image status.`,
        });
      });
  }

  public listFlightPlansByAoi(
    projectId: string,
    aoiId: string,
    missionId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(
      `${this._baseUrl}/projects/${projectId}/aois/${aoiId}/missions/${missionId}/flightPlans`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the flight plans by AOI.`,
          isArray: true,
        });
      });
  }

  public updateMissionImageType(
    missionId: string,
    imageGuid: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.patch(
      `${this._baseUrl}/upload/missions/${missionId}/images/${imageGuid}/type`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to update the mission image type.`,
        });
      });
  }

  public updateBulkMissionImageType(
    missionId: string,
    formData: GenericObjectType
  ): GenericApisReturnPromiseTypes {
    return this.patch(
      `${this._baseUrl}/upload/missions/${missionId}/images/types`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to update the mission image type in bulk.`,
        });
      });
  }

  public getImagePreview(guid: string): string {
    return `${BASE_CAPI_URL}/${this._baseUrl}/images/${guid}/preview`;
  }

  public listGcpPlansForAoi(
    projectId: string,
    aoiId: string,
    missionId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(
      `${this._baseUrl}/projects/${projectId}/aois/${aoiId}/missions/${missionId}/gcpPlans`
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to list the GCP plans for AOI.`,
          isArray: true,
        });
      });
  }

  public postMissionRequirementProcessing(
    projectId: string,
    aoiId: string,
    missionId: string,
    reqId: string,
    formData: GenericObjectType,
    force = false
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/projects/${projectId}/aois/${aoiId}/missions/${missionId}/processingStatus/${reqId}?force=${force}`,
      formData
    )
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to trigger mission requirement processing.`,
        });
      });
  }
}
