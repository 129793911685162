import { connect } from 'react-redux';
import { getFooterMenu } from '../../redux/selectors/views';
import ViewFooter from './ViewFooter';
import { getCertifiedViewsOnly as getCerifiedFooterMenus } from '../ViewDataManager/container';
import { ViewFooterType } from './index.types';

interface IOwnProps {
  viewId: string;
}

const mapStateToProps = (
  state: any,
  props: IOwnProps
): ViewFooterType.ReduxState => {
  return {
    menus: getCerifiedFooterMenus(
      getFooterMenu(state, props),
      state.auth.user?.staff
    ),
  };
};

const mapDispatchToProps = (dispatch: any): {} => {
  return {};
};

const ViewFooterContainer = connect<
  ViewFooterType.ReduxState,
  ViewFooterType.DispatchProps,
  ViewFooterType.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ViewFooter);

export default ViewFooterContainer;
