import { connect } from 'react-redux';
import SplitViewSelector from './index';

import {
  getSelectedView,
  getSplitViewDates,
  getSplitViewTypes,
} from '../../redux/selectors/views';
import { SplitViewSelectorTypes } from './index.types';

const mapStateToProps = (
  state: any,
  { viewId }: any
): SplitViewSelectorTypes.ReduxState => {
  return {
    subTypes: getSplitViewTypes(state),
    selectedSubType: getSelectedView(state, { viewId }).subType,
    dates: getSplitViewDates(state, { viewId }),
    selectedDate: getSelectedView(state, { viewId }).id,
  };
};

const mapDispatchToProps = (
  dispatch: any
): SplitViewSelectorTypes.DispatchProps => {
  return {};
};

const SplitViewSelectorContainer = connect<
  SplitViewSelectorTypes.ReduxState,
  SplitViewSelectorTypes.DispatchProps,
  SplitViewSelectorTypes.OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(SplitViewSelector) as any;

export default SplitViewSelectorContainer;
