import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { LayerDescriptor } from 'src/api/mapStyle.types';
import {
  useMapStyleApi,
  useMapStyleContext,
} from 'src/components/Mission/MapMaker/hooks';
import { ManageLayers } from 'src/components/Mission/MapMaker/StyleEditor/ManageLayers';
import { NoLayersPlaceholder } from 'src/components/Mission/MapMaker/StyleEditor/NoLayersPlaceholder';
import { zIndexSorter } from 'src/components/Mission/MapMaker/utils';
import SkeletonLoader from 'src/components/SkeletonLoader';
import { SnackbarActionsActionShowSnackbarTypes } from 'src/shapes/snackbar';
import { StylePreview } from './StylePreview';
import style from './index.module.scss';

export interface StyleEditorProps {
  showSnackbar: SnackbarActionsActionShowSnackbarTypes;
  refreshMapStyle: () => Promise<void>;
}

/**
 * Component to preview style and make changes.
 * Responsibilities:
 *  - Edit style name
 *  - publish style
 *  - add / remove layers
 *  - edit layers
 *  - view preview
 * @constructor
 */
export const StyleEditor: React.FC<StyleEditorProps> = ({
  showSnackbar,
  refreshMapStyle,
}) => {
  const mapStyle = useMapStyleContext();
  const api = useMapStyleApi();
  const [layers, setLayers] = useState<LayerDescriptor[] | undefined>();
  const [layerKey, setLayerKey] = useState<number>(0);

  useEffect(() => {
    if (!mapStyle) return;

    const { projectId, aoiId, id } = mapStyle.style;

    api
      .listStyleLayers(projectId, aoiId, id)
      .then((layers) => setLayers(layers.sort(zIndexSorter)));
  }, [api, mapStyle]);

  if (!mapStyle) return <SkeletonLoader />;

  return (
    <div className={classnames(style.grow, style.columnFlex, style.container)}>
      {/* TODO: figure out how to preserve view (center, zoom) through layer updates */}
      {layers?.length ? (
        <div
          className={classnames(style.container, style.flex, style.fullHeight)}
        >
          <div className={classnames(style.sideContainer)}>
            <ManageLayers
              layers={layers}
              onLayerAdd={(l) => {
                setLayers((layers) => [...(layers || []), l]);

                refreshMapStyle().finally(() => {
                  setLayerKey((k) => (k + 1) % 10);
                });
              }}
              onLayerDelete={(l) => {
                setLayers(
                  (layers) => layers?.filter((_l) => _l.name !== l.name) || []
                );
                setLayerKey((k) => (k + 1) % 10);
              }}
              onLayerEdit={(l, name) => {
                setLayers((layers) => {
                  const _layers =
                    layers?.filter((_l) => _l.name !== name) || [];

                  return [..._layers, l].sort(zIndexSorter);
                });

                setLayerKey((k) => (k + 1) % 10);
              }}
            />
          </div>
          <div className={classnames(style.previewContainer, style.flex)}>
            <StylePreview
              showSnackbar={showSnackbar}
              updateKey={layerKey.toString()}
              layers={layers || []}
            />
          </div>
        </div>
      ) : (
        <NoLayersPlaceholder
          onLayerAdd={(l) => {
            refreshMapStyle().finally(() => {
              setLayers((layers) => [...(layers || []), l]);
            });
          }}
        />
      )}
    </div>
  );
};
