import * as React from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Typography } from 'antd';
import BoundaryEditor from '../../CreateAOI/BoundaryEditor';
import { Button } from '../../Button';
import styles from './index.module.scss';
import { appFormatDate, isDateAfter } from '../../../utils/date';
import SkeletonLoader from '../../SkeletonLoader';
import { undefinedOrNull } from '../../../utils/functs';
import CutAndFillReportsApis from '../../../api/cutAndFillReport';
import { GenericApisReturnTypes, GenericObjectType } from '../../../shapes/app';
import { resetBodyOverFlow, setBodyOverflow } from '../../../utils/window';
import PDFViewer from '../../PDFViewer';
import HelpTooltip from '../../HelpTooltip/HelpTooltip';
import ModalNotification from '../../ModalNotification/ModalNotification';
import { DATA_AUTO_POLLING_TIMEOUT } from '../../../constants';
import { MapGeneratedStatus } from '../../MapGeneratedStatus';
import LoadingOverlay from '../../LoadingOverlay';
import { ElevationInfo } from '../../../api/cutAndFillReport.types';
import {
  CutAndFillReportsCrudProps,
  CutAndFillReportCrudState,
} from './index.types';

const SelectOption = Select.Option;
const { Title } = Typography;
const cutAndFillReportsApis = new CutAndFillReportsApis();

class CutAndFillReportsCrud extends React.Component<
  CutAndFillReportsCrudProps,
  CutAndFillReportCrudState
> {
  private lastPollTime: number = 0;

  public constructor(props: CutAndFillReportsCrudProps) {
    super(props);

    this.state = {
      generateBtnLoading: false,
      finalizeBtnLoading: false,
      newCutAndFillReportCreated: false,
      isCutAndFillFullScreen: false,
      showFinalizeConfirmModal: false,
      showGenerateConfirmModal: false,
      showMarkAoiModal: false,
    };
  }

  public componentDidMount(): void {
    const { actionType } = this.props;

    if (actionType === 'edit') {
      this.fetchCutAndFillReport(true);
    }

    window.scrollTo(0, 0);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: CutAndFillReportsCrudProps
  ): void {
    const { elevationInfo } = this.props;
    const { selectedReferenceDtm } = this.state;

    if (!_.isEqual(elevationInfo, nextProps.elevationInfo)) {
      this.setState({
        selectedReferenceDtm: this.getElevationInfoByKey(
          `${selectedReferenceDtm?.elevationSource}_${selectedReferenceDtm?.id}`,
          nextProps
        ),
      });
    }
  }

  public UNSAFE_componentWillUpdate(
    nextProps: Readonly<CutAndFillReportsCrudProps>,
    {
      cutAndFillReportData: nextCutAndFillReportData,
    }: Readonly<CutAndFillReportCrudState>
  ): void {
    const { actionType } = this.props;

    if (
      actionType === 'edit' &&
      nextCutAndFillReportData &&
      Object.keys(nextCutAndFillReportData).length > 0 &&
      !nextCutAndFillReportData.finalized &&
      ['pending', 'started', 'starting'].indexOf(
        nextCutAndFillReportData.status
      ) > -1
    ) {
      const currentPollTime = new Date().getTime();

      if (
        currentPollTime - this.lastPollTime >
        DATA_AUTO_POLLING_TIMEOUT * 0.9
      ) {
        setTimeout(() => {
          this.fetchCutAndFillReport();
        }, DATA_AUTO_POLLING_TIMEOUT);
      }

      this.lastPollTime = currentPollTime;
    }
  }

  private fetchCutAndFillReport = (fetchAoi: boolean = false) => {
    const { match, showSnackbar } = this.props;
    const { projectId, aoiId, reportId } = match.params;

    cutAndFillReportsApis
      .getCutAndFillReport(projectId, aoiId, reportId)
      .then((res) => {
        if (res.error) {
          showSnackbar({
            body: res.error,
            type: 'error',
          });
        }

        this.setState(
          {
            cutAndFillReportData: res.data,
            selectedReferenceDtm: res.data
              .referenceElevationInfo as ElevationInfo,
            selectedTargetDtm: res.data.targetElevationInfo as ElevationInfo,
          },
          () => {
            if (res.data.cutFillAoiId && fetchAoi) {
              // f etching custom boundary, only if it exists
              this.fetchCutAndFillReportBoundary();
            }
          }
        );
      });
  };

  private fetchCutAndFillReportBoundary = () => {
    const { match, showSnackbar } = this.props;
    const { projectId, aoiId, reportId } = match.params;

    cutAndFillReportsApis
      .getCutAndFillReportBoundary(projectId, aoiId, reportId)
      .then((res) => {
        if (res.error) {
          showSnackbar({
            body: res.error,
            type: 'error',
          });
        }

        this.setState({
          boundaryFileContents: btoa(JSON.stringify(res.data)),
        });
      });
  };

  private fetchCutAndFillReportPdf = (): string => {
    const { match } = this.props;
    const { projectId, aoiId, reportId } = match.params;

    return cutAndFillReportsApis.getCutAndFillReportPdf(
      projectId,
      aoiId,
      reportId
    );
  };

  private getElevationInfoByKey = (
    key: string,
    nextProps?: CutAndFillReportsCrudProps
  ) => {
    const { elevationInfo } = nextProps || this.props;

    if (!key) {
      return undefined;
    }

    const keyParts = key.split('_');

    if (!elevationInfo) {
      return undefined;
    }

    return elevationInfo.find(
      (info) => info.elevationSource === keyParts[0] && info.id === keyParts[1]
    );
  };

  private handleReferenceDtmDateSelect = (
    selectedReferenceDtmId: string
  ): void => {
    const selectedInfo = this.getElevationInfoByKey(selectedReferenceDtmId);

    this.setState({
      selectedReferenceDtm: selectedInfo,
    });
  };

  private handleTargetDtmDateSelect = (selectedTargetDtmId: string): void => {
    const selectedInfo = this.getElevationInfoByKey(selectedTargetDtmId);

    this.setState({
      selectedTargetDtm: selectedInfo,
    });
  };

  private processGenerateCutAndFillReportBtn = (): void => {
    const { form, match, showSnackbar, actionType, projectType, user } =
      this.props;
    const { cutAndFillReportData, boundaryFileContents } = this.state;
    const { projectId, aoiId } = match.params;

    form.validateFields(
      ['referenceDtmId', 'targetDtmId'],
      (err: any, values: any) => {
        if (!err) {
          const filteredReferenceDtm = this.getElevationInfoByKey(
            values.referenceDtmId
          );
          const filteredTargetDtm = this.getElevationInfoByKey(
            values.targetDtmId
          );

          if (!filteredReferenceDtm) {
            showSnackbar({
              body: `Reference Elevation Source was not selected. Try again!`,
              type: 'error',
            });

            return;
          }

          if (!filteredTargetDtm) {
            showSnackbar({
              body: `Target Elevation Source was not selected. Try again!`,
              type: 'error',
            });

            return;
          }

          if (
            filteredReferenceDtm.elevationSource !== 'measurement' &&
            filteredTargetDtm.elevationSource !== 'measurement' &&
            filteredTargetDtm.date &&
            filteredReferenceDtm.date &&
            !isDateAfter(filteredTargetDtm.date, filteredReferenceDtm.date)
          ) {
            showSnackbar({
              body: `Target Date must be after the Reference Date. Try again!`,
              type: 'error',
            });

            return;
          }

          if (
            filteredReferenceDtm.elevationSource === 'measurement' &&
            filteredTargetDtm.elevationSource === 'measurement'
          ) {
            showSnackbar({
              body: 'Both Elevation Sources cannot be a plane. Try again!',
              type: 'error',
            });

            return;
          }

          let customBoundary = boundaryFileContents;

          if (
            filteredReferenceDtm.elevationSource === 'measurement' ||
            filteredTargetDtm.elevationSource === 'measurement'
          ) {
            customBoundary = undefined;
          }

          //  r emove optional name field from API request
          const formData = {
            referenceElevationInfo: {
              ...filteredReferenceDtm,
              name: undefined,
            },
            targetElevationInfo: { ...filteredTargetDtm, name: undefined },
            cutFillAoiContents: customBoundary || null,
          };

          cutAndFillReportsApis
            .postCutAndFillReport(
              projectId,
              aoiId,
              formData,
              cutAndFillReportData &&
                Object.keys(cutAndFillReportData).length > 0
                ? cutAndFillReportData.id
                : null
            )
            .then((res: GenericApisReturnTypes) => {
              if (undefinedOrNull(res)) {
                showSnackbar({
                  body: `Some error occured. Try again!`,
                  type: 'error',
                });

                this.setState({
                  generateBtnLoading: false,
                });

                return;
              }

              if (res.error) {
                showSnackbar({
                  body: res.error,
                  type: 'error',
                });

                this.setState({
                  generateBtnLoading: false,
                });

                return;
              }

              if (actionType === 'edit') {
                this.setState({
                  generateBtnLoading: false,
                  newCutAndFillReportCreated: false,
                  cutAndFillReportData: res.data,
                });
              } else {
                this.setState({
                  generateBtnLoading: false,
                  newCutAndFillReportCreated: true,
                  cutAndFillReportData: res.data,
                });
              }

              let executionInfo = '';

              if (
                projectType &&
                projectType === 'POST_PROCESSING_DEMO_PROJECT' &&
                user &&
                !user.staff
              ) {
                if (
                  res.data.reportGenerationCount != null &&
                  res.data.reportGenerationLimit != null
                ) {
                  const { reportGenerationCount, reportGenerationLimit } =
                    res.data;

                  executionInfo = ` You have used up one Cut & Fill Report, and you have ${
                    reportGenerationLimit - reportGenerationCount
                  } generations left for this demo project.`;
                }
              }

              showSnackbar({
                body: `Cut And Fill Report is being generated.${executionInfo} Please wait, you will receive an email when the pdf is generated.`,
                type: 'info',
              });
            })
            .catch((e) => {
              showSnackbar({
                body: e.error,
                type: 'error',
              });
            });
        }
      }
    );
  };

  private handleFinalizeModalShow = (): void => {
    this.setState({
      showFinalizeConfirmModal: true,
    });
  };

  private handleShowMarkAoiModal = (): void => {
    this.setState({
      showMarkAoiModal: true,
    });
  };

  private handleCloseMarkAoiModel = (): void => {
    this.setState({
      showMarkAoiModal: false,
    });
  };

  private handleFinalizeModalClose = (confirm: boolean): void => {
    if (confirm) {
      this.setState({
        showFinalizeConfirmModal: false,
      });
      this.handleFinalizeBtn();

      return;
    }

    this.setState({
      showFinalizeConfirmModal: false,
    });
  };

  public handleBoundaryUpdate = (contents: string) => {
    this.setState({
      boundaryFileContents: contents,
    });
  };

  public handleFinalizeBtn = () => {
    const { match, showSnackbar } = this.props;
    const { cutAndFillReportData } = this.state;
    const { projectId, aoiId, reportId } = match.params;

    if (!cutAndFillReportData) {
      showSnackbar({
        body: `Some error occured. Try again!`,
        type: 'error',
      });

      return;
    }

    const bodyFormData = {
      finalized: !cutAndFillReportData.finalized,
    };

    this.setState({
      finalizeBtnLoading: true,
    });

    cutAndFillReportsApis
      .updateCutAndFillReport(projectId, aoiId, bodyFormData, reportId)
      .then((res: GenericApisReturnTypes) => {
        if (undefinedOrNull(res)) {
          showSnackbar({
            body: `Some error occured. Try again!`,
            type: 'error',
          });

          this.setState({
            finalizeBtnLoading: false,
          });

          return;
        }

        if (res.error) {
          showSnackbar({
            body: res.error,
            type: 'error',
          });

          this.setState({
            finalizeBtnLoading: false,
          });

          return;
        }

        this.setState(
          {
            finalizeBtnLoading: false,
          },
          () => {
            this.fetchCutAndFillReport();
          }
        );

        showSnackbar({
          body: `The Cut & Fill Report was ${
            !cutAndFillReportData.finalized ? 'published' : 'unpublished'
          } successfully`,
          type: 'success',
          isModal: false,
        });
      });
  };

  private getDefaultReferenceDtmDate = (): any => {
    const { elevationInfo } = this.props;
    const { cutAndFillReportData } = this.state;

    if (
      elevationInfo &&
      cutAndFillReportData &&
      Object.keys(cutAndFillReportData).length > 0 &&
      !undefinedOrNull(cutAndFillReportData.referenceElevationInfo)
    ) {
      const { referenceElevationInfo } = cutAndFillReportData;
      const filteredReportDtm = elevationInfo.find(
        (a) =>
          a.id === referenceElevationInfo.id &&
          a.elevationSource === referenceElevationInfo.elevationSource
      );

      const reportDtm = filteredReportDtm || null;

      if (!reportDtm) {
        return {};
      }

      return {
        initialValue: `${reportDtm.elevationSource}_${reportDtm.id}`,
      };
    }

    return {};
  };

  private getDefaultTargetDtmDate = (): any => {
    const { elevationInfo } = this.props;
    const { cutAndFillReportData } = this.state;

    if (
      elevationInfo &&
      cutAndFillReportData &&
      Object.keys(cutAndFillReportData).length > 0 &&
      !undefinedOrNull(cutAndFillReportData.targetElevationInfo)
    ) {
      const { targetElevationInfo } = cutAndFillReportData;
      const filteredReportDtm = elevationInfo.find(
        (a) =>
          a.id === targetElevationInfo.id &&
          a.elevationSource === targetElevationInfo.elevationSource
      );

      const reportDtm = filteredReportDtm || null;

      if (!reportDtm) {
        return {};
      }

      return {
        initialValue: `${reportDtm.elevationSource}_${reportDtm.id}`,
      };
    }

    return {};
  };

  private getElevationInfoName = (info: ElevationInfo): string => {
    switch (info.elevationSource) {
      case 'dem': {
        //  DEM elevation source will always have date
        return `Elevation - ${appFormatDate(info.date as string)}${
          info.name ? `- ${info.name}` : ''
        }`;
      }

      case 'dtm': {
        //  DTM elevation source will always have date
        return `Terrain - ${appFormatDate(info.date as string)}${
          info.name ? `- ${info.name}` : ''
        }`;
      }

      case 'measurement': {
        return `Plane${info.name ? ` - ${info.name}` : ''}`;
      }

      default: {
        return 'Bad Data - Do not use';
      }
    }
  };

  private populateReferenceElevationOptions = () => {
    const { elevationInfo } = this.props;
    const { selectedTargetDtm } = this.state;

    if (!elevationInfo) {
      return [];
    }

    return elevationInfo.map((info) => {
      if (
        selectedTargetDtm &&
        selectedTargetDtm.elevationSource === 'measurement' &&
        info.elevationSource === 'measurement'
      ) {
        return null;
      }

      return (
        <SelectOption
          key={`${info.elevationSource}_${info.id}`}
          value={`${info.elevationSource}_${info.id}`}
        >
          {this.getElevationInfoName(info)}
        </SelectOption>
      );
    });
  };

  private populateTargetElevationOptions = ():
    | (JSX.Element | null)[]
    | null => {
    const { elevationInfo, actionType } = this.props;
    const { cutAndFillReportData, selectedReferenceDtm } = this.state;

    if (!elevationInfo) {
      return [];
    }

    return elevationInfo?.map((info: ElevationInfo) => {
      if (selectedReferenceDtm) {
        if (
          selectedReferenceDtm.elevationSource === 'measurement' &&
          info.elevationSource === 'measurement'
        ) {
          return null;
        }

        if (
          selectedReferenceDtm.elevationSource !== 'measurement' &&
          info.elevationSource !== 'measurement' &&
          selectedReferenceDtm.date &&
          info.date &&
          !isDateAfter(info.date, selectedReferenceDtm.date)
        ) {
          return null;
        }
      }

      if (actionType === 'edit' && cutAndFillReportData) {
        const { referenceElevationInfo } = cutAndFillReportData;
        const filteredDtm = elevationInfo?.find((a: GenericObjectType) => {
          return (
            a.id === referenceElevationInfo.id &&
            a.elevationSource === referenceElevationInfo.elevationSource
          );
        });

        const selectedTargetDtm = filteredDtm || null;

        if (
          selectedTargetDtm &&
          selectedTargetDtm.elevationSource !== 'measurement' &&
          info.elevationSource !== 'measurement' &&
          selectedTargetDtm.date &&
          info.date &&
          !isDateAfter(info.date, selectedTargetDtm.date)
        ) {
          return null;
        }
      }

      return (
        <SelectOption
          key={`${info.elevationSource}_${info.id}`}
          value={`${info.elevationSource}_${info.id}`}
        >
          {this.getElevationInfoName(info)}
        </SelectOption>
      );
    });
  };

  private targetDtmDatePlaceholder = (): string => {
    const { selectedReferenceDtm } = this.state;

    if (!selectedReferenceDtm || Object.keys(selectedReferenceDtm).length < 1) {
      return 'Pick a Surface';
    }

    return 'Pick a Surface';
  };

  private targetDtmDateDisable = (): boolean => {
    const { actionType } = this.props;
    const { selectedReferenceDtm, cutAndFillReportData } = this.state;

    if (
      actionType !== 'edit' &&
      (!selectedReferenceDtm || Object.keys(selectedReferenceDtm).length < 1)
    ) {
      return true;
    }

    return !!(cutAndFillReportData && cutAndFillReportData.finalized);
  };

  private generateBtnName = (): string => {
    const { cutAndFillReportData } = this.state;

    if (!cutAndFillReportData || Object.keys(cutAndFillReportData).length < 1) {
      return 'GENERATE CUT AND FILL REPORT';
    }

    return 'REGENERATE CUT AND FILL REPORT';
  };

  private disableCustomAoiBtn = (): boolean => {
    const { selectedReferenceDtm, selectedTargetDtm } = this.state;

    if (
      selectedReferenceDtm?.elevationSource === 'measurement' ||
      selectedTargetDtm?.elevationSource === 'measurement'
    ) {
      return true;
    }

    return this.disableGenerateBtn();
  };

  private disableGenerateBtn = (): boolean => {
    const { cutAndFillReportData } = this.state;

    if (!cutAndFillReportData || Object.keys(cutAndFillReportData).length < 1) {
      return false;
    }

    if (cutAndFillReportData.finalized) {
      return true;
    }

    switch (cutAndFillReportData.status) {
      case 'started':
      case 'starting':
        return true;

      case 'pending':
      case 'completed':
      case 'error':
      default:
        return false;
    }
  };

  private disableFinalizeBtn = (): boolean => {
    const { cutAndFillReportData } = this.state;

    if (!cutAndFillReportData || Object.keys(cutAndFillReportData).length < 1) {
      return false;
    }

    switch (cutAndFillReportData.status) {
      case 'pending':
      case 'error':
      case 'started':
      case 'starting':
        return true;

      case 'completed':
      default:
        return false;
    }
  };

  private heading = (): string => {
    const { actionType } = this.props;

    if (actionType === 'edit') {
      return 'Edit Cut And Fill Report';
    }

    return 'New Cut And Fill Report';
  };

  private finalizeConfirmBodyText = (): string => {
    const { cutAndFillReportData } = this.state;

    if (cutAndFillReportData && cutAndFillReportData.finalized) {
      return `Un-publishing this Cut & Fill Report will remove the Cut & Fill Report from public Display. Are you sure you want to proceed?`;
    }

    return `Are you sure you want to publish the Cut & Fill Report?`;
  };

  private handleCutAndFillFullScreenToggle = (): void => {
    this.setState(
      ({ isCutAndFillFullScreen }) => {
        return {
          isCutAndFillFullScreen: !isCutAndFillFullScreen,
        };
      },
      () => {
        const { isCutAndFillFullScreen } = this.state;

        if (isCutAndFillFullScreen) {
          setBodyOverflow('y');

          return;
        }

        resetBodyOverFlow('y');
      }
    );
  };

  private handleShowGenerateConfirmModal = (): void => {
    const { form } = this.props;

    form.validateFields(['referenceDtmId', 'targetDtmId'], (err: any) => {
      if (!err) {
        // s how confirmation modal, only if there is no error in form validation
        this.setState(({ showGenerateConfirmModal }) => {
          return {
            showGenerateConfirmModal: !showGenerateConfirmModal,
          };
        });
      }
    });
  };

  private handleGenerateConfirmModal = (value: boolean): void => {
    this.setState(() => {
      return {
        showGenerateConfirmModal: false,
      };
    });

    if (!value) {
      return;
    }

    this.setState(
      {
        generateBtnLoading: true,
      },
      () => {
        this.processGenerateCutAndFillReportBtn();
      }
    );
  };

  private generateConfirmBodyText = (): string => {
    const {
      cutAndFillReportData,
      boundaryFileContents,
      selectedReferenceDtm,
      selectedTargetDtm,
    } = this.state;

    let boundaryWarning = boundaryFileContents
      ? ''
      : 'As you have not marked a custom Cut and Fill area of interest, the AOI boundary will be used.';

    boundaryWarning =
      selectedReferenceDtm?.elevationSource !== 'measurement' &&
      selectedTargetDtm?.elevationSource !== 'measurement'
        ? boundaryWarning
        : 'As a plane is being used to generate the Cut and Fill report, the boundary will be picked up from the plane definition.';

    if (!cutAndFillReportData || Object.keys(cutAndFillReportData).length < 1) {
      return `Are you sure you want to start Cut and Fill Report generation with these parameters? ${boundaryWarning}`;
    }

    return `Are you sure you want to re-generate this Cut and Fill Report with these parameters? ${boundaryWarning} The existing Cut and Fill Report will be cleaned up.`;
  };

  public render(): React.ReactNode {
    const { form, match, elevationInfo, project } = this.props;
    const {
      cutAndFillReportData,
      generateBtnLoading,
      newCutAndFillReportCreated,
      finalizeBtnLoading,
      isCutAndFillFullScreen,
      showFinalizeConfirmModal,
      showGenerateConfirmModal,
      showMarkAoiModal,
      boundaryFileContents,
    } = this.state;
    const { getFieldDecorator } = form;
    const { projectId, aoiId } = match.params;

    if (
      newCutAndFillReportCreated &&
      cutAndFillReportData &&
      Object.keys(cutAndFillReportData).length > 0
    ) {
      return (
        <Redirect
          to={`/project/${projectId}/aoi/${aoiId}/CutAndFillReport/edit/${cutAndFillReportData.id}`}
        />
      );
    }

    if (!elevationInfo) {
      return (
        <div className={styles.container}>
          <SkeletonLoader className={styles.skeletonLoader} size={2} />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.headingWrapper}>
          <Title level={3}>{this.heading()}</Title>
        </div>

        <div className={styles.innerContainer}>
          <div className={styles.inputFieldContainer}>
            <Form layout="vertical">
              <div className={styles.inputFieldWrapper}>
                <Form.Item label="Reference Surface">
                  {getFieldDecorator('referenceDtmId', {
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                    ...this.getDefaultReferenceDtmDate(),
                  })(
                    <Select
                      placeholder="Pick a surface"
                      optionFilterProp="value"
                      className={styles.inputField}
                      onChange={this.handleReferenceDtmDateSelect}
                      disabled={
                        cutAndFillReportData && cutAndFillReportData.finalized
                      }
                    >
                      {this.populateReferenceElevationOptions()}
                    </Select>
                  )}
                </Form.Item>
                <HelpTooltip
                  position="right"
                  helpText="The older Elevation Source which will serve as reference for Cut and Fill calculation."
                />
              </div>
              <div className={styles.inputFieldWrapper}>
                <Form.Item label="Target Surface">
                  {getFieldDecorator('targetDtmId', {
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                    ...this.getDefaultTargetDtmDate(),
                  })(
                    <Select
                      placeholder={this.targetDtmDatePlaceholder()}
                      optionFilterProp="value"
                      className={styles.inputField}
                      onChange={this.handleTargetDtmDateSelect}
                      disabled={this.targetDtmDateDisable()}
                    >
                      {this.populateTargetElevationOptions()}
                    </Select>
                  )}
                </Form.Item>
                <HelpTooltip
                  position="right"
                  helpText="The newer Elevation Source for which Cut and Fill calculation will be performed."
                />
              </div>

              <div className={styles.markAoiWrapper}>
                <Button
                  text="Mark Cut & Fill AOI"
                  onClick={() => {
                    this.handleShowMarkAoiModal();
                  }}
                  className={styles.generateBtn}
                  disabled={this.disableCustomAoiBtn()}
                />
              </div>

              <div className={styles.btnWrapper}>
                <Button
                  text={this.generateBtnName()}
                  onClick={() => {
                    this.handleShowGenerateConfirmModal();
                  }}
                  loadingText="Generating..."
                  loading={generateBtnLoading}
                  className={styles.generateBtn}
                  disabled={this.disableGenerateBtn()}
                />

                {cutAndFillReportData &&
                Object.keys(cutAndFillReportData).length > 0 ? (
                  <div className={styles.inputFieldWrapper}>
                    <Button
                      className={styles.finalizeBtn}
                      text={
                        cutAndFillReportData.finalized ? 'UNPUBLISH' : 'PUBLISH'
                      }
                      disabled={this.disableFinalizeBtn()}
                      loading={finalizeBtnLoading}
                      loadingText={
                        cutAndFillReportData.finalized
                          ? 'Unpublishing...'
                          : 'Publishing...'
                      }
                      onClick={() => {
                        this.handleFinalizeModalShow();
                      }}
                    />
                    {!cutAndFillReportData.finalized && (
                      <HelpTooltip
                        position="right"
                        helpText="Publishing the Cut & Fill Report will make it available for public display in the view associated with the target Elevation Source. If a plane was used as the target Elevation Source, then it will be available in the view associated with the reference Elevation Source."
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </Form>
          </div>

          <div className={styles.pdfContainer}>
            {cutAndFillReportData ? (
              <div className={styles.postGeneratedContainer}>
                {cutAndFillReportData.status === 'error' ? (
                  MapGeneratedStatus({
                    data: cutAndFillReportData,
                    type: 'cutAndFillReport',
                    error: true,
                  })
                ) : cutAndFillReportData.status === 'completed' ? (
                  // e slint-disable-next-line react/jsx-indent
                  <div className={styles.pdfWrapper}>
                    <PDFViewer
                      file={this.fetchCutAndFillReportPdf()}
                      name="Cut and fill report"
                      position={isCutAndFillFullScreen ? 'fixed' : 'absolute'}
                      onFullScreen={this.handleCutAndFillFullScreenToggle}
                      isFullScreen={isCutAndFillFullScreen}
                    />
                  </div>
                ) : (
                  MapGeneratedStatus({
                    data: cutAndFillReportData,
                    type: 'cutAndFillReport',
                    generating: true,
                  })
                )}
              </div>
            ) : (
              MapGeneratedStatus({
                data: cutAndFillReportData || null,
                type: 'cutAndFillReport',
              })
            )}
          </div>
          {showFinalizeConfirmModal ? (
            <ModalNotification
              notificationTitle="Confirm action"
              notificationBody={this.finalizeConfirmBodyText()}
              shownotificationModal
              handleModalClose={this.handleFinalizeModalClose}
              cancelButtonTitle="NO"
              okButtonTitle="YES"
              isConfirm
            />
          ) : null}
        </div>

        {showMarkAoiModal ? (
          <Modal
            title="Mark Cut & Fill AOI"
            centered
            footer={null}
            visible={showMarkAoiModal}
            onOk={this.handleCloseMarkAoiModel}
            onCancel={this.handleCloseMarkAoiModel}
            destroyOnClose
            maskClosable={false}
            zIndex={10001}
          >
            <div>
              <BoundaryEditor
                onBoundaryChange={this.handleBoundaryUpdate}
                computePolygonArea={undefined}
                hideUpload={false}
                useAoiBoundary
                aoiId={aoiId}
                project={project}
                projectId={projectId}
                createProject={false}
                previousBoundaryContents={boundaryFileContents}
              />
            </div>
            If an AOI is not marked, the displayed boundary will be used as the
            Cut & Fill AOI.
            <div className={styles.finishMarkingBtn}>
              <Button
                text="FINISH MARKING"
                onClick={this.handleCloseMarkAoiModel}
                className={styles.generateBtn}
                disabled={false}
              />
            </div>
          </Modal>
        ) : null}

        {showGenerateConfirmModal ? (
          <ModalNotification
            notificationTitle="Confirm action"
            notificationBody={this.generateConfirmBodyText()}
            shownotificationModal
            handleModalClose={this.handleGenerateConfirmModal}
            cancelButtonTitle="NO"
            okButtonTitle="YES"
            isConfirm
          />
        ) : null}
        {generateBtnLoading || finalizeBtnLoading ? <LoadingOverlay /> : null}
      </div>
    );
  }
}

export default Form.create<CutAndFillReportsCrudProps>()(CutAndFillReportsCrud);
