import * as React from 'react';
import * as openseadragon from 'openseadragon';
import autobind from 'autobind-decorator';
import styles from './index.module.scss';
import {
  OpenSeaDragonPropsTypes,
  OpenSeaDragonProjectionPointsTypes,
} from './index.types';
import { GenericObjectType } from '../../shapes/app';
import markerSavedIcon from '../../images/crosshair-marker-saved.png';
import markerUnsavedIcon from '../../images/crosshair-marker-unsaved.png';
import { undefinedOrNull, setStyle } from '../../utils/functs';
import FloatingToolbar from '../FloatingToolbar';
import { FloatingToolbarButtonListProps } from '../FloatingToolbar/index.types';
import { log } from '../../utils/log';
import LoadingOverlay from '../LoadingOverlay';
import NavigationBox from '../NavigationBox';

export default class OpenSeaDragon extends React.PureComponent<OpenSeaDragonPropsTypes> {
  private osViewer: null | GenericObjectType = null;

  private osViewerWrapperNode: HTMLDivElement | null;

  private osViewerMarkerNode: HTMLDivElement | null;

  private osViewerMarkerProjectionPoints: null | OpenSeaDragonProjectionPointsTypes =
    null;

  private allowProjectionTilePainting = true;

  private zoomLevel = 0;

  public static defaultProps: Partial<OpenSeaDragonPropsTypes> = {
    zoomTo: 10,
    allowMarking: false,
    rotationAngle: 0,
  };

  public componentDidMount(): void {
    this.initOsViewer();
  }

  public UNSAFE_componentWillReceiveProps({
    guid: nextGuid,
    rotationAngle: nextRotationAngle,
  }: Readonly<OpenSeaDragonPropsTypes>): void {
    const { guid, rotationAngle } = this.props;

    if (guid !== nextGuid) {
      if (this.osViewer) {
        this.osViewer.removeOverlay(this.osViewerMarkerNode);
      }
    } else if (rotationAngle !== nextRotationAngle) {
      if (this.osViewer) {
        this.osViewer.viewport.setRotation(nextRotationAngle);
      }
    }
  }

  public componentDidUpdate({
    guid: prevGuid,
    projection: prevProjection,
  }: Readonly<OpenSeaDragonPropsTypes>): void {
    const { guid, projection } = this.props;

    if (guid !== prevGuid) {
      this.allowProjectionTilePainting = true;

      if (this.osViewer) {
        this.initOsViewer();
      }
    } else if (projection !== prevProjection) {
      this.allowProjectionTilePainting = false;

      this.addMarkerOnTiles();

      if (projection) {
        this.osViewerMarkerProjectionPoints = {
          x: projection.pixelX,
          y: projection.pixelY,
        };
      } else {
        this.osViewerMarkerProjectionPoints = null;
      }
    }
  }

  public componentWillUnmount(): void {
    if (this.osViewer) {
      this.osViewer.removeOverlay(this.osViewerMarkerNode);
      this.osViewer.destroy();
      this.osViewer = null;
      this.osViewerMarkerProjectionPoints = null;
    }
  }

  @autobind
  public getScreenshot(): string | null {
    return (this.osViewer && this.osViewer.drawer.canvas.toDataURL()) || null;
  }

  private initOsViewer(): void | undefined {
    const {
      allowMarking,
      guid,
      projection,
      tileSrc,
      imageTileSrc,
      rotationAngle,
    } = this.props;

    if (!this.osViewerWrapperNode) {
      return;
    }

    try {
      if (!guid) {
        return;
      }

      if (!undefinedOrNull(projection)) {
        this.osViewerMarkerProjectionPoints = {
          x: projection.pixelX,
          y: projection.pixelY,
        };
      } else {
        this.osViewerMarkerProjectionPoints = null;
      }

      if (this.osViewer) {
        this.osViewer.destroy();
        this.osViewer = null;
      }

      const config = {
        element: this.osViewerWrapperNode,
        constrainDuringPan: true,
        showZoomControl: false,
        showHomeControl: false,
        showFullPageControl: false,
        crossOriginPolicy: 'use-credentials',
        ajaxWithCredentials: true,
        gestureSettingsMouse: {
          clickToZoom: false,
        },
        maxZoomPixelRatio: 30,
        degrees: rotationAngle,
      };

      this.osViewer = openseadragon(config);

      if (!this.osViewer) {
        return;
      }

      // we try to add the tiled image here
      this.osViewer.addTiledImage({
        tileSource: tileSrc || '',

        error: () => {
          if (!this.osViewer) {
            return;
          }

          // if an error is thrown then try adding image url instead of the tile.
          this.osViewer.addSimpleImage({
            url: imageTileSrc || '',
          });
        },
      });

      this.addMarkerOnTiles();

      if (allowMarking) {
        this.osViewer.addHandler(
          'canvas-double-click',
          (event: GenericObjectType) => {
            if (!this.osViewer) {
              return;
            }

            this.resetOverlay(this.osViewerMarkerNode);

            const viewportPoint = this.osViewer.viewport.pointFromPixel(
              event.position
            );

            if (!this.osViewer.getOverlayById(this.osViewerMarkerNode)) {
              this.osViewer.addOverlay(
                this.osViewerMarkerNode,
                viewportPoint,
                openseadragon.OverlayPlacement.CENTER
              );
            } else {
              this.osViewer.updateOverlay(
                this.osViewerMarkerNode,
                viewportPoint,
                openseadragon.OverlayPlacement.CENTER
              );
            }

            const osViewerWorldItem: GenericObjectType | null =
              this.osViewer.world.getItemAt(0);

            if (undefinedOrNull(osViewerWorldItem)) {
              return;
            }

            const osViewerSizes: GenericObjectType | null =
              osViewerWorldItem.getContentSize();

            this.osViewerMarkerProjectionPoints = this.getProjectionPxFromImage(
              osViewerSizes,
              viewportPoint
            );

            this.handleSaveProjectionBtn();
          }
        );
      }
    } catch (e) {
      log.info(e);
    }
  }

  private addMarkerOnTiles = () => {
    const { zoomTo, projection } = this.props;

    if (!this.osViewer) {
      return;
    }

    // projection
    if (!undefinedOrNull(projection)) {
      this.osViewer.addHandler('tile-loaded', () => {
        if (
          !this.osViewer ||
          !this.allowProjectionTilePainting ||
          !projection
        ) {
          return;
        }

        const osViewerWorldItem: GenericObjectType | null =
          this.osViewer.world.getItemAt(0);

        if (undefinedOrNull(osViewerWorldItem)) {
          return;
        }

        const osViewerSizes: GenericObjectType | null =
          osViewerWorldItem.getContentSize();

        const projectionPx = this.getImagePxFromProjection(osViewerSizes, {
          x: projection.pixelX,
          y: projection.pixelY,
        });

        if (undefinedOrNull(projectionPx)) {
          return;
        }

        this.resetOverlay(this.osViewerMarkerNode);

        const projectionPoints = new openseadragon.Point(
          projectionPx.x,
          projectionPx.y
        );

        this.osViewer.addOverlay(
          this.osViewerMarkerNode,
          projectionPoints,
          openseadragon.OverlayPlacement.CENTER
        );

        this.zoomAndPan(projectionPoints, this.zoomLevel || zoomTo);

        this.allowProjectionTilePainting = false;

        this.osViewer.addHandler('zoom', (event: GenericObjectType) => {
          this.zoomLevel = event.zoom;
        });
      });
    } else {
      this.osViewer.removeOverlay(this.osViewerMarkerNode);
    }
  };

  private zoomAndPan = (
    projectionPoints: GenericObjectType,
    zoomTo: number = 0
  ) => {
    if (undefinedOrNull(this.osViewer)) {
      return;
    }

    this.osViewer.viewport.panTo(projectionPoints, true);
    this.osViewer.viewport.zoomTo(zoomTo, projectionPoints, true);
  };

  private resetOverlay = ($el: HTMLDivElement | null) => {
    if (!$el) {
      return;
    }

    const style = window.getComputedStyle($el);

    if (style.display === 'none') {
      setStyle($el, {
        display: 'block',
      });
    }
  };

  private getProjectionPxFromImage = (
    osViewerSizes: null | GenericObjectType,
    viewportPoint: null | OpenSeaDragonProjectionPointsTypes
  ): null | OpenSeaDragonProjectionPointsTypes => {
    if (undefinedOrNull(osViewerSizes) || undefinedOrNull(viewportPoint)) {
      return null;
    }

    return {
      x: viewportPoint.x * osViewerSizes.x,
      y: viewportPoint.y * osViewerSizes.x,
    };
  };

  private getImagePxFromProjection = (
    osViewerSizes: null | GenericObjectType,
    projectionPoints: null | OpenSeaDragonProjectionPointsTypes
  ): null | OpenSeaDragonProjectionPointsTypes => {
    if (undefinedOrNull(osViewerSizes) || undefinedOrNull(projectionPoints)) {
      return null;
    }

    return {
      x: projectionPoints.x / osViewerSizes.x,
      y: projectionPoints.y / osViewerSizes.x,
    };
  };

  private handleSaveProjectionBtn = () => {
    const { onSaveProjection, projection, guid } = this.props;

    if (!onSaveProjection) {
      return;
    }

    onSaveProjection(this.osViewerMarkerProjectionPoints, projection, guid);
  };

  private handleDeleteProjectionBtn = () => {
    const { onDeleteProjection, projection } = this.props;

    if (!onDeleteProjection) {
      return;
    }

    onDeleteProjection(projection);
  };

  private getButtonList = (): FloatingToolbarButtonListProps[] => {
    const { projection } = this.props;

    return [
      {
        label: 'Help',
        disabled: false,
        icon: 'question-circle',
        tooltipText: 'Double click to position and save the marker.',
        onClick: () => {
          /**/
        },
      },
      {
        label: 'Delete',
        disabled: undefinedOrNull(projection),
        icon: 'trash',
        onClick: this.handleDeleteProjectionBtn,
      },
    ];
  };

  public render(): React.ReactNode {
    const {
      loadingDeleteProjectionBtn,
      loadingSaveProjectionBtn,
      allowMarking,
      className,
      isMarkerSaved,
      navigationCompassRotation,
      navigationCompassAltitude,
      showNavigationBox,
      navigationBtnData,
    } = this.props;

    return (
      <div className={styles.container}>
        {allowMarking && (
          <FloatingToolbar
            buttonList={this.getButtonList()}
            top="10px"
            right="10px"
          />
        )}
        <div
          ref={(n) => {
            this.osViewerWrapperNode = n;
          }}
          className={`${styles.osViewerWrapper} ${className}`}
        />

        <img
          alt="Marker"
          ref={(n) => {
            this.osViewerMarkerNode = n;
          }}
          src={!isMarkerSaved ? markerUnsavedIcon : markerSavedIcon}
          className={styles.osViewerMarker}
        />

        {loadingDeleteProjectionBtn || loadingSaveProjectionBtn ? (
          <LoadingOverlay />
        ) : null}

        {navigationBtnData && (
          <div className={styles.navigationButtonsWrapper}>
            <div className={styles.twoWayNavigationWrapper}>
              <div className={styles.contentWrapper}>
                <button
                  className={styles.prevBtn}
                  onClick={() => {
                    navigationBtnData.onClick('prev');
                  }}
                >
                  <i className="fa fa-chevron-left" />
                </button>

                {navigationBtnData.data && (
                  <div className={styles.selectedIndexInfoWrapper}>
                    <div className={styles.center}>
                      {navigationBtnData.data.selectedMarkerIndex === 0
                        ? 1
                        : navigationBtnData.data.totalMarkerPoints -
                          (navigationBtnData.data.selectedMarkerIndex || 0) +
                          1}
                      &nbsp;/&nbsp;
                      {navigationBtnData.data.totalMarkerPoints}
                    </div>
                  </div>
                )}

                <button
                  className={styles.nextBtn}
                  onClick={() => {
                    navigationBtnData.onClick('next');
                  }}
                >
                  <i className="fa fa-chevron-right" />
                </button>
              </div>
            </div>
          </div>
        )}

        {showNavigationBox && (
          <div className={styles.navigationBoxWrapper}>
            <NavigationBox
              altitude={{
                value: navigationCompassAltitude || 0,
                round: true,
                unit: 'meters',
              }}
              rotation={360 - (navigationCompassRotation || 0)}
            />
          </div>
        )}
      </div>
    );
  }
}
