import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as React from 'react';
import BaseDrawControl from 'react-mapbox-gl-draw';
import LotsOfPointsMode from './LotsOfPointsMode';
import style from './DrawControl.module.scss';
import DrawLineString from './DrawLineString';
import DrawMultiPolygon from './DrawMultiPolygon';
import DrawPoint from './DrawPoint';
import DrawPolygon from './DrawPolygon';
import EditVolumePolygon from './EditVolumePolygon';
import VolumePolygon from './VolumePolygon';
import DeleteSelect from './DeleteSelect';
import SimpleSelect from './SimpleSelect';
import StaticMode from './StaticMode';
import { theme as customTheme } from './theme';
import DrawPolygonWithEdit from './DrawMultiPolygonWithEdit';
import DrawHolesInPolygon from './DrawHolesInPolygon';
import EditAreas from './EditAreas';
import ViewSiteObjects from './ViewSiteObjects';
import {
  DrawControlPropsTypes,
  DrawControlModeTypes,
  DrawControlIntentTypes,
} from './DrawControl.types';
import { inArray, undefinedOrNull } from '../../utils/functs';
import { log } from '../../utils/log';
import { NullOrGenericObjectType } from '../../shapes/app';
import { DrawControlThemeTypes } from './theme.types';
import { clearAllPopups } from './Popup';
import CreateObject from './CreateObject';

class DrawControl extends React.Component<DrawControlPropsTypes> {
  private drawControl: NullOrGenericObjectType = null;

  public UNSAFE_componentWillReceiveProps({
    drawMode: nextDrawMode,
  }: DrawControlPropsTypes): void {
    const { drawMode, isDsm } = this.props;

    if (nextDrawMode !== drawMode && this.drawControl) {
      try {
        const newMode = this.getDrawMode(nextDrawMode || null);

        if (!nextDrawMode && !isDsm) {
          this.drawControl.draw.deleteAll();
          clearAllPopups();
        }

        this.drawControl.draw.changeMode(newMode);
      } catch (e) {
        log.error(
          e,
          'DrawControl.componentWillReceiveProps nextDrawMode changeMode'
        );
      }
    }
  }

  public componentWillUnmount() {
    clearAllPopups();
  }

  private getDrawMode = (
    intent: DrawControlIntentTypes | null
  ): DrawControlModeTypes => {
    const { isDsm } = this.props;

    if (isDsm && undefinedOrNull(intent)) {
      return 'static';
    }

    if (undefinedOrNull(intent)) {
      return 'simple_select';
    }

    if (['measure'].indexOf(intent) > -1) {
      return 'static';
    }

    if (['area'].indexOf(intent) > -1) {
      return 'draw_polygon';
    }

    if (['volume'].indexOf(intent) > -1) {
      return 'volume_polygon';
    }

    if (['distance'].indexOf(intent) > -1) {
      return 'draw_line_string';
    }

    if (['elevation'].indexOf(intent) > -1) {
      return 'draw_lots_of_points';
    }

    if (['static'].indexOf(intent) > -1) {
      return 'static';
    }

    if (['issue', 'mark_issue'].indexOf(intent) > -1) {
      return 'draw_multi_polygon';
    }

    if (['mark_ground_areas'].indexOf(intent) > -1) {
      return 'draw_multi_polygon';
    }

    if (['mark_non_ground_areas'].indexOf(intent) > -1) {
      return 'draw_holes';
    }

    if (['remove_selected_area'].indexOf(intent) > -1) {
      return 'delete_select';
    }

    if (['site_objects'].indexOf(intent) > -1) {
      return 'view_site_objects';
    }

    if (['create_object'].indexOf(intent) > -1) {
      return 'create_object';
    }

    if (['delete_object'].indexOf(intent) > -1) {
      return 'delete_object';
    }

    if (['compare'].indexOf(intent) > -1) {
      return 'static';
    }

    return 'simple_select';
  };

  private getModes = () => {
    const { isDsm } = this.props;
    const mapboxDraw = MapboxDraw;

    if (!mapboxDraw) {
      return null;
    }

    const { modes } = mapboxDraw;

    if (!modes) {
      return null;
    }

    modes.static = StaticMode;
    modes.edit_volume_polygon = EditVolumePolygon;
    modes.volume_polygon = VolumePolygon;
    modes.draw_polygon = DrawPolygon;
    modes.draw_line_string = DrawLineString;
    modes.draw_lots_of_points = LotsOfPointsMode;
    modes.draw_point = DrawPoint;
    modes.edit_area = SimpleSelect;
    modes.view_site_objects = ViewSiteObjects;
    modes.create_object = CreateObject;
    modes.delete_object = SimpleSelect;

    if (isDsm) {
      modes.draw_multi_polygon = DrawPolygonWithEdit;
      modes.delete_select = DeleteSelect;
      modes.direct_select = EditAreas;
      modes.draw_holes = DrawHolesInPolygon;
    } else {
      modes.draw_multi_polygon = DrawMultiPolygon;
    }

    return modes;
  };

  public getDrawControl(): any {
    return this.drawControl;
  }

  private getThemes = (): DrawControlThemeTypes[] => {
    const { selectedViewControlsMenuTypeList } = this.props;

    const theme: DrawControlThemeTypes[] = [];

    if (!undefinedOrNull(selectedViewControlsMenuTypeList)) {
      if (inArray(selectedViewControlsMenuTypeList, ['mark_issue'])) {
        theme.push({
          id: 'gl-draw-polygon-stroke-inactive',
          paint: {
            'line-color': '#15f4ee',
          },
        });

        theme.push({
          id: 'gl-draw-polygon-stroke-static',
          paint: {
            'line-color': '#15f4ee',
          },
        });
      }
    }

    return customTheme(theme);
  };

  public render(): React.ReactNode {
    const { drawMode, ...rest } = this.props;

    return (
      <BaseDrawControl
        {...rest}
        ref={(drawControl: any) => {
          this.drawControl = drawControl;
        }}
        className={style.container}
        styles={this.getThemes()}
        position="bottom-left"
        modes={this.getModes()}
        defaultMode={this.getDrawMode(drawMode || null)}
        displayControlsDefault={false}
        userProperties
      />
    );
  }
}

export default DrawControl;
