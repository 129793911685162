// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".View_container__GVrLj{height:100%;width:100%}.View_controls__1pcca{position:absolute;top:10px;bottom:40px;right:10px;margin:auto;display:grid}", "",{"version":3,"sources":["webpack://./src/components/View/index.module.scss"],"names":[],"mappings":"AAAA,uBACE,WAAA,CACA,UAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".container {\n  height: 100%;\n  width: 100%;\n}\n\n.controls {\n  position: absolute;\n  top: 10px;\n  bottom: 40px;\n  right: 10px;\n  margin: auto;\n  display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "View_container__GVrLj",
	"controls": "View_controls__1pcca"
};
export default ___CSS_LOADER_EXPORT___;
