import { Collapse, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../Button';
import {
  AdvancedDetailsCollapsible,
  BoundaryEditorCollapsible,
  ProjectDetails,
  ProjectUpdatePreviewTable,
} from '../CreateProjectV2';
import { useOwnerOptions, useProjectReducer } from '../CreateProjectV2/utils';
import SkeletonLoader from '../SkeletonLoader';
import { WhiteLabellingFormV2 } from '../WhiteLabellingFormV2';
import styles from './index.module.scss';
import {
  ArchiveButtonProps,
  ConfirmProjectArchivalModalProps,
  ConfirmProjectUpdateModalProps,
  EditProjectBodyProps,
  EditProjectV2Props,
  WhitelabellingCollapsibleProps,
} from './index.types';
import {
  isProjectOwner,
  isValidProjectUpdateRequest,
  isWhiteLabelingEnabled,
  useManageBrandingDataContext,
  useProjectArchivalContext,
  useProjectFromParams,
  useProjectUpdateContext,
} from './utils';

export const EditProjectV2: React.FC<EditProjectV2Props> = ({
  user,
  showSnackbar,
  updateProjectManually,
}) => {
  const { project, loading, setProject } = useProjectFromParams();

  return (
    <>
      <div className={styles.container} data-testid="edit-project-container">
        <div className={styles.content}>
          <EditProjectTitle />
          {project && !loading ? (
            <EditProjectBody
              user={user}
              project={project}
              showSnackbar={showSnackbar}
              projectUpdated={(project) => {
                updateProjectManually(project);
                setProject(project);
              }}
            />
          ) : (
            <SkeletonLoader />
          )}
        </div>
      </div>
    </>
  );
};

export const EditProjectTitle: React.FC = () => {
  return (
    <div
      className={styles.titleContainer}
      data-testid="create-project-title-container"
    >
      <Typography.Title level={3} className={styles.titleHeading}>
        Edit Project
      </Typography.Title>
    </div>
  );
};

export const EditProjectBody: React.FC<EditProjectBodyProps> = ({
  user,
  project,
  showSnackbar,
  projectUpdated,
}) => {
  const { ownerOptions, defaultOwner } = useOwnerOptions(user, project);
  const [projectState, actions] = useProjectReducer();
  const [confirmProjectUpdate, setConfirmProjectUpdate] =
    useState<boolean>(false);
  const history = useHistory();

  return (
    <div
      className={styles.bodyContainer}
      data-testid="create-project-body-container"
    >
      <ProjectDetails
        name={project.name}
        ownerOptions={ownerOptions}
        owner={defaultOwner}
        hideOwnerEdit={!isProjectOwner(project, ownerOptions, user)}
        events={{
          onOwnerChange: actions.ownerChanged,
          onNameChange: actions.nameChanged,
        }}
      />
      <BoundaryEditorCollapsible
        projectId={project.id}
        project={project}
        mode="edit_project"
        events={{
          onBoundaryChange: actions.boundaryChanged,
          onLocationChange: actions.locationChanged,
          onAreaChange: actions.areaChanged,
        }}
      />
      <AdvancedDetailsCollapsible
        address={project.address}
        temperatureUnits={project.temperatureUnit}
        epsgCode={project.epsgCode}
        description={project.description}
        events={{
          onAddressChange: actions.addressChanged,
          onDescriptionChange: actions.descriptionChanged,
          onEpsgCodeChange: actions.epsgCodeChanged,
          onTemperatureUnitChange: actions.temperatureUnitChanged,
        }}
      />
      <WhiteLabellingCollapsible
        userId={user.user_id}
        projectId={project.id}
        showSnackbar={showSnackbar}
        allowWhitelabelling={isWhiteLabelingEnabled(project, ownerOptions)}
        visible
      />
      <div className={styles.buttonContainer}>
        <ArchiveButtonWithPopup
          className={styles.archive}
          project={project}
          user={user}
          projectUpdated={projectUpdated}
        />
        <Button
          type="secondary"
          onClick={() => {
            history.push(`/project/${project.id}`);
          }}
        >
          Cancel
        </Button>
        <Button
          helpText="update project"
          toolTipPosition="top"
          onClick={() => {
            const validation = isValidProjectUpdateRequest(projectState);

            if (validation.isValid) setConfirmProjectUpdate(true);
            else if (validation.message) {
              showSnackbar({
                type: 'error',
                body: validation.message,
                isModal: false,
              });
            }
          }}
        >
          Update Project
        </Button>
      </div>
      <ConfirmProjectUpdateModal
        closeModal={() => setConfirmProjectUpdate(false)}
        projectId={project.id}
        projectDetails={projectState}
        visible={confirmProjectUpdate}
        projectUpdated={(project) => {
          if (project !== undefined) {
            projectUpdated(project);
            actions.reset();
            showSnackbar({
              type: 'success',
              body: 'Project has been updated successfully',
              isModal: false,
            });
          } else {
            showSnackbar({
              type: 'error',
              body: 'There was an error while updating the Project',
              isModal: false,
            });
          }

          setConfirmProjectUpdate(false);
        }}
      />
    </div>
  );
};

export const WhiteLabellingCollapsible: React.FC<
  WhitelabellingCollapsibleProps
> = ({ userId, showSnackbar, projectId, visible, allowWhitelabelling }) => {
  const { form, brandingData, updateBrandingData } =
    useManageBrandingDataContext(projectId, showSnackbar);

  if (!visible)
    return <div data-testid="whitelabelling-collapsible-container" />;

  return (
    <div
      className={styles.collapseBody}
      data-testid="advanced-details-collapsible-container"
    >
      <Collapse className={styles.collapseContainer}>
        <Collapse.Panel key="whitelabelling" header="Whitelabelling">
          <WhiteLabellingFormV2
            form={form}
            userId={userId}
            projectId={projectId}
            brandingData={brandingData}
            showSnackbar={showSnackbar}
            allowWhiteLabelling={allowWhitelabelling || false}
            serviceType="project"
            onValuesChanged={() => {}}
          />
          {allowWhitelabelling ? (
            <div className={styles.buttonContainer}>
              <Button
                onClick={async () => {
                  await updateBrandingData();
                }}
              >
                Save Changes
              </Button>
            </div>
          ) : (
            <></>
          )}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export const ConfirmProjectUpdateModal: React.FC<
  ConfirmProjectUpdateModalProps
> = ({ visible, projectDetails, projectUpdated, closeModal, projectId }) => {
  const { loading, updateProject } = useProjectUpdateContext();

  if (!visible) {
    return <div data-testid="confirm-project-creation-modal-container" />;
  }

  return (
    <div data-testid="confirm-project-creation-modal-container">
      <Modal
        title="Confirm Project Update"
        visible={visible}
        footer={null}
        onCancel={closeModal}
      >
        <div className={styles.previewContainer}>
          Here are the details of the Project that are being updated:{' '}
          <ProjectUpdatePreviewTable project={projectDetails} />
        </div>
        <div className={styles.buttonContainer}>
          <Button type="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={async () => {
              const project = await updateProject(projectId, projectDetails);

              if (projectUpdated) projectUpdated(project);
            }}
          >
            Update Project
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export const ArchiveButtonWithPopup: React.FC<ArchiveButtonProps> = ({
  className,
  project,
  user,
  projectUpdated,
}) => {
  const [popupVisible, setPopupVisible] = useState<boolean>();
  const { archived, restore } = project;

  if (user?.role !== 'aspecscire_staff') {
    return <></>;
  }

  let actionElement = <></>;
  let action: 'Archive' | 'Restore' = 'Archive';

  if (archived) {
    if (restore) {
      actionElement = <p>Project is being restored</p>;
    } else if (project.archivalComplete) {
      action = 'Restore';
      actionElement = (
        <p>
          Project is archived
          <Button onClick={() => setPopupVisible(true)}>Restore Project</Button>
        </p>
      );
    } else {
      actionElement = <p>Project is being archived</p>;
    }
  } else {
    actionElement = (
      <Button onClick={() => setPopupVisible(true)}>Archive Project</Button>
    );
  }

  return (
    <div className={className}>
      {actionElement}
      <ConfirmProjectArchiveModal
        project={project}
        user={user}
        closeModal={() => setPopupVisible(false)}
        visible={popupVisible}
        action={action}
        projectUpdated={projectUpdated}
      />
    </div>
  );
};

export const ConfirmProjectArchiveModal: React.FC<
  ConfirmProjectArchivalModalProps
> = ({ visible, closeModal, project, action, projectUpdated }) => {
  const { loading, archiveProject } = useProjectArchivalContext();

  if (!visible) {
    return <div data-testid="confirm-project-archive-modal-container" />;
  }

  return (
    <div data-testid="confirm-project-archive-modal-container">
      <Modal
        title="Confirm Project Archive"
        visible={visible}
        footer={null}
        onCancel={closeModal}
      >
        <div className={styles.previewContainer}>
          Do you want to {action} the Project? It may take up to 2 days to
          complete this action.
        </div>
        <div className={styles.buttonContainer}>
          <Button type="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={async () => {
              const archiveInfo = await archiveProject(
                project.id,
                action === 'Archive',
                action === 'Restore'
              );

              if (archiveInfo) {
                const newProject = {
                  ...project,
                  archived: archiveInfo.archived,
                  restore: archiveInfo.restore,
                  archivalComplete: archiveInfo.archivalComplete,
                  archivedAt: archiveInfo.archivedAt,
                };

                projectUpdated(newProject);
              }

              closeModal();
            }}
          >
            {action} Project
          </Button>
        </div>
      </Modal>
    </div>
  );
};
