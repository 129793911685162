import * as React from 'react';
import classname from 'classnames';
import styles from './index.module.scss';
import {
  HamburgerMenuPropsTypes,
  HamburgerMenuStateTypes,
} from './index.types';
import { Button } from '../Button';
import { resetBodyOverFlow, setBodyOverflow } from '../../utils/window';
import {
  TERMS_AND_CONDITIONS_URL,
  PRIVACY_POLICY_URL,
} from '../../constants/urls';

class HamburgerMenu extends React.PureComponent<
  HamburgerMenuPropsTypes,
  HamburgerMenuStateTypes
> {
  public constructor(props: HamburgerMenuPropsTypes) {
    super(props);

    this.state = {
      collapsed: true,
    };
  }

  private open = () => {
    this.setState(() => {
      return {
        collapsed: false,
      };
    });
    setBodyOverflow('y');
  };

  private close = () => {
    resetBodyOverFlow('y');
    this.setState(() => {
      return {
        collapsed: true,
      };
    });
  };

  public render() {
    const { collapsed } = this.state;

    return (
      <div className={styles.container}>
        <div>
          <Button
            type="primary"
            onClick={collapsed ? this.open : this.close}
            transparent
            icon="menu"
            size="large"
          />
        </div>
        <div className={styles.sidebarOuterWrapper}>
          <div
            className={classname(styles.sidebarInnerWrapper, {
              [styles.hide]: collapsed,
            })}
          >
            <div className={styles.closeButtonWrapper}>
              <Button
                type="primary"
                transparent
                size="large"
                icon="close"
                onClick={this.close}
              />
            </div>
            <a
              href="https://vimana.vimanalabs.com/docs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.sidebarItem}>
                <div className={styles.iconWrapper}>
                  <i className="fa fa-book" aria-hidden="true" />
                </div>
                <div className={styles.sidebarItemDesc}>DOCUMENTATION</div>
              </div>
            </a>
            <a
              href={TERMS_AND_CONDITIONS_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.sidebarItem}>
                <div className={styles.iconWrapper}>
                  <i className="fa fa-question-circle-o" aria-hidden="true" />
                </div>
                <div className={styles.sidebarItemDesc}>TERMS OF USE</div>
              </div>
            </a>
            <a
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.sidebarItem}>
                <div className={styles.iconWrapper}>
                  <i className="fa fa-lock" aria-hidden="true" />
                </div>
                <div className={styles.sidebarItemDesc}>PRIVACY POLICY</div>
              </div>
            </a>
          </div>

          <div
            className={classname(styles.overlay, {
              [styles.hide]: collapsed,
            })}
            onClick={this.close}
          />
        </div>
      </div>
    );
  }
}

export default HamburgerMenu;
