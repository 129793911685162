/* eslint-disable func-names,no-param-reassign */
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import constrainFeatureMovement from '@mapbox/mapbox-gl-draw/src/lib/constrain_feature_movement';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import POPUP, { clearAllPopups, newElevationPopup } from './Popup';
import MeasureApis from '../../api/Measurement';
// adding global redux store to draw mode
import {
  updateVolumeElevationList,
  elevationFetchFailed,
  elevationFetchStarted,
  elevationFetched,
} from '../../redux/actions/volume';
import { toMetersEquivalent } from '../../utils/functs';
import { store } from '../../redux/store';

const { noTarget, isOfMetaType, isInactiveFeature, isShiftDown } =
  CommonSelectors;
const measureApis = new MeasureApis();

const isVertex = isOfMetaType(Constants.meta.VERTEX);
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT);

let loading = false;

const EditVolumePolygon: any = {};

const getViewId = (): string | null => {
  const state = store.getState();

  if (state && state.volume) {
    return state.volume.viewId;
  }

  return null;
};

const getViewUnits = (): 'm' | 'ft' => {
  const state = store.getState();

  if (state && state.volume) {
    return state.volume.viewUnits;
  }

  return 'm';
};

// INTERNAL FUCNTIONS

EditVolumePolygon.fireUpdate = function () {
  this.fetchPointElevation();
  this.map.fire(Constants.events.UPDATE, {
    action: Constants.updateActions.CHANGE_COORDINATES,
    features: this.getSelected().map((f: any) => f.toGeoJSON()),
  });
};

EditVolumePolygon.fetchPointElevation = function () {
  const features = this.getSelected().map((f: any) => f.toGeoJSON());

  clearAllPopups();
  if (features.length === 1) {
    const featurePolygon = features[0];
    const points = featurePolygon.geometry.coordinates[0].slice(0, -1);

    const viewId = getViewId();

    if (viewId) {
      loading = true;
      // TODO: fix types and remove any
      store.dispatch(elevationFetchStarted() as any);
      POPUP.setHTML(`<i class="fa fa-spinner fa-spin" />`);

      measureApis
        .fetchElevation(viewId, points)
        .then((res) => {
          // TODO: fix types and remove any
          store.dispatch(elevationFetched(res?.data || []) as any);
          const elevations = res.data.elevation.metres;

          if (elevations) {
            store.dispatch(updateVolumeElevationList(res.data));
          }

          clearAllPopups();

          const viewUnits = getViewUnits();

          elevations.forEach((val: number, idx: number) => {
            const loc = points[idx];
            const elevation = toMetersEquivalent(
              val,
              viewUnits === 'm' ? 'meters' : 'feet'
            );

            newElevationPopup(
              elevation,
              viewUnits,
              {
                lng: loc[0],
                lat: loc[1],
              },
              this.map
            );
          });
        })
        .catch((err: any) => {
          let result = { message: 'Unknown error' };

          if (!err.response) {
            result = { message: 'Network error' };
          }

          if (err?.response?.data) {
            result = err?.response?.data;
          }

          // TODO: fix types and remove any
          store.dispatch(elevationFetchFailed(result) as any);
        })
        .finally(() => {
          loading = false;
          POPUP.remove();
        });
    }
  }
};

EditVolumePolygon.fireActionable = function (state: any) {
  this.setActionableState({
    combineFeatures: false,
    uncombineFeatures: false,
    trash: state.selectedCoordPaths.length > 0,
  });
};

EditVolumePolygon.startDragging = function (state: any, e: any) {
  this.map.dragPan.disable();
  state.canDragMove = true;
  state.dragMoveLocation = e.lngLat;
  state.saveFeatureGeoJson = state.featureId
    ? this.getFeature(state.featureId).toGeoJSON()
    : null;
};

EditVolumePolygon.stopDragging = function (state: any) {
  this.map.dragPan.enable();
  state.dragMoving = false;
  state.canDragMove = false;
  state.dragMoveLocation = null;
  state.featureSaveGeoJson = null;
};

EditVolumePolygon.onVertex = function (state: any, e: any) {
  this.startDragging(state, e);
  const about = e.featureTarget.properties;
  const selectedIndex = state.selectedCoordPaths.indexOf(about.coord_path);

  if (!isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths = [about.coord_path];
  } else if (isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths.push(about.coord_path);
  }

  const selectedCoordinates = this.pathsToCoordinates(
    state.featureId,
    state.selectedCoordPaths
  );

  this.setSelectedCoordinates(selectedCoordinates);
};

EditVolumePolygon.onMidpoint = function (state: any, e: any) {
  this.startDragging(state, e);
  const about = e.featureTarget.properties;

  state.feature.addCoordinate(about.coord_path, about.lng, about.lat);
  this.fireUpdate();
  state.selectedCoordPaths = [about.coord_path];
};

EditVolumePolygon.pathsToCoordinates = function (featureId: any, paths: any) {
  return paths.map((coord_path: any) => {
    return { feature_id: featureId, coord_path };
  });
};

EditVolumePolygon.onFeature = function (state: any, e: any) {
  if (state.selectedCoordPaths.length === 0) this.startDragging(state, e);
  else this.stopDragging(state);
};

EditVolumePolygon.dragFeature = function (state: any, e: any, delta: any) {
  moveFeatures(this.getSelected(), delta);
  state.dragMoveLocation = e.lngLat;
};

EditVolumePolygon.dragVertex = function (state: any, e: any, delta: any) {
  const selectedCoords = state.selectedCoordPaths.map((coord_path: any) =>
    state.feature.getCoordinate(coord_path)
  );
  const selectedCoordPoints = selectedCoords.map((coords: any) => ({
    type: Constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: Constants.geojsonTypes.POINT,
      coordinates: coords,
    },
  }));

  const constrainedDelta = constrainFeatureMovement(selectedCoordPoints, delta);

  for (let i = 0; i < selectedCoords.length; i += 1) {
    const coord = selectedCoords[i];

    state.feature.updateCoordinate(
      state.selectedCoordPaths[i],
      coord[0] + constrainedDelta.lng,
      coord[1] + constrainedDelta.lat
    );
  }
};

EditVolumePolygon.clickNoTarget = function () {
  // this.changeMode(Constants.modes.SIMPLE_SELECT);
};

EditVolumePolygon.clickInactive = function () {
  // this.changeMode(Constants.modes.SIMPLE_SELECT);
};

EditVolumePolygon.clickActiveFeature = function (state: any) {
  state.selectedCoordPaths = [];
  this.clearSelectedCoordinates();
  state.feature.changed();
};

EditVolumePolygon.toPolygonFeature = function (
  coordinates: number[][][],
  properties: any = {}
) {
  const polygon = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: { ...properties },
    geometry: {
      type: Constants.geojsonTypes.POLYGON,
      coordinates,
    },
  });

  return polygon;
};

// EXTERNAL FUNCTIONS

EditVolumePolygon.onSetup = function (opts: any) {
  const { featureId } = opts;
  const feature = this.getFeature(featureId);

  if (!feature) {
    throw new Error('You must provide a featureId to enter direct_select mode');
  }

  if (feature.type === Constants.geojsonTypes.POINT) {
    throw new TypeError("direct_select mode doesn't handle point features");
  }

  POPUP.setHTML('Click on a vertex to edit it');
  POPUP.addTo(this.map);

  const state = {
    featureId,
    feature,
    dragMoveLocation: opts.startPos || null,
    dragMoving: false,
    canDragMove: false,
    selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
    saveFeatureGeoJson: null,
  };

  this.setSelectedCoordinates(
    this.pathsToCoordinates(featureId, state.selectedCoordPaths)
  );
  this.setSelected(featureId);
  doubleClickZoom.disable(this);

  this.setActionableState({
    trash: true,
  });

  this.fetchPointElevation();

  return state;
};

EditVolumePolygon.onStop = function () {
  clearAllPopups();
  doubleClickZoom.enable(this);
  this.clearSelectedCoordinates();
};

EditVolumePolygon.toDisplayFeatures = function (
  state: any,
  geojson: any,
  push: any
) {
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = Constants.activeStates.ACTIVE;
    push(geojson);
    createSupplementaryPoints(geojson, {
      map: this.map,
      midpoints: true,
      selectedPaths: state.selectedCoordPaths,
    }).forEach(push);
  } else {
    geojson.properties.active = Constants.activeStates.INACTIVE;
    push(geojson);
  }

  this.fireActionable(state);
};

EditVolumePolygon.onTrash = function (state: any) {
  state.selectedCoordPaths
    .sort()
    .reverse()
    .forEach((id: any) => state.feature.removeCoordinate(id));
  this.fireUpdate();
  state.selectedCoordPaths = [];
  this.clearSelectedCoordinates();
  this.fireActionable(state);
  if (state.feature.isValid() === false) {
    this.deleteFeature([state.featureId]);
    this.changeMode(Constants.modes.SIMPLE_SELECT, {});
  }
};

EditVolumePolygon.onMouseMove = function (state: any, e: any) {
  if (!POPUP.isOpen()) {
    POPUP.addTo(this.map);
  }

  if (loading) {
    POPUP.setHTML(`<i class="fa fa-spinner fa-spin" />`);
  } else if (state.selectedCoordPaths.length === 0) {
    POPUP.setHTML('Click on a vertex to edit it.');
  } else {
    POPUP.setHTML('Drag vertex to edit area');
  }

  POPUP.setLngLat(e.lngLat);

  // On mousemove that is not a drag, stop vertex movement.
  const isFeature = CommonSelectors.isActiveFeature(e);
  const onVertex = isVertex(e);
  const noCoords = state.selectedCoordPaths.length === 0;

  if (isFeature && noCoords)
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  else if (onVertex && !noCoords)
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  else this.updateUIClasses({ mouse: Constants.cursors.NONE });
  this.stopDragging(state);
};

EditVolumePolygon.onMouseOut = function (state: any) {
  // As soon as you mouse leaves the canvas, update the feature
  if (state.dragMoving) this.fireUpdate();
  // remove pop-up on mouse out
  POPUP.remove();
};

EditVolumePolygon.onTouchStart = EditVolumePolygon.onMouseDown = function (
  state: any,
  e: any
) {
  if (isVertex(e)) return this.onVertex(state, e);
  if (CommonSelectors.isActiveFeature(e)) return this.onFeature(state, e);
  if (isMidpoint(e)) return this.onMidpoint(state, e);
};

EditVolumePolygon.onDrag = function (state: any, e: any) {
  if (state.canDragMove !== true) return;
  state.dragMoving = true;
  e.originalEvent.stopPropagation();

  const delta = {
    lng: e.lngLat.lng - state.dragMoveLocation.lng,
    lat: e.lngLat.lat - state.dragMoveLocation.lat,
  };

  POPUP.setLngLat(e.lngLat);

  if (state.selectedCoordPaths.length > 0) this.dragVertex(state, e, delta);
  // @todo: I am currently disabling the drag of the features. Enable it in the next release
  // else this.dragFeature(state, e, delta);

  state.dragMoveLocation = e.lngLat;
};

EditVolumePolygon.onClick = function (state: any, e: any) {
  if (noTarget(e)) return this.clickNoTarget(state, e);
  if (CommonSelectors.isActiveFeature(e))
    return this.clickActiveFeature(state, e);
  if (isInactiveFeature(e)) return this.clickInactive(state, e);
  this.stopDragging(state);
};

EditVolumePolygon.onTap = function (state: any, e: any) {
  if (noTarget(e)) return this.clickNoTarget(state, e);
  if (CommonSelectors.isActiveFeature(e))
    return this.clickActiveFeature(state, e);
  if (isInactiveFeature(e)) return this.clickInactive(state, e);
};

EditVolumePolygon.onTouchEnd = EditVolumePolygon.onMouseUp = function (
  state: any
) {
  if (state.dragMoving) {
    // TODO: add validation logic here, to ensure all rings in the feature are within it, and remove it, if not.
    this.fireUpdate();
  }

  this.stopDragging(state);
};

export default EditVolumePolygon;
