import * as React from 'react';
import moment from 'moment';
import { Table, Typography, Button } from 'antd';

import DocumentationLink from '../../../DocumentationLink';
import { DesignDrawing } from '../../../../api/drawing.types';
import { getDrawingUrl } from '../utils';
import { DOCUMENTATION_URL_LIST } from '../../../../constants/urls';

import { DrawingsListingPropsType } from './index.types';
import style from './index.module.scss';

const { Title } = Typography;

export default class DrawingListing extends React.PureComponent<DrawingsListingPropsType> {
  public renderDrawingsTable = () => {
    const { projectId, aoiId, drawings, history } = this.props;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (list: DesignDrawing[], drawing: DesignDrawing) => {
          if (drawing.published) {
            return 'PUBLISHED';
          }

          if (!drawing.status) {
            return 'CREATED';
          }

          switch (drawing.status) {
            case 'error':
            case 'pending':
              return 'PENDING';
            case 'started':
            case 'starting':
              return 'ALIGNING';
            case 'completed':
              return 'ALIGNED';

            default:
              return '';
          }
        },
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (date: any) => moment(date).tz('Etc/GMT').format('ll'),
      },
    ];

    return (
      <Table
        dataSource={drawings}
        columns={columns}
        locale={{ emptyText: 'No Drawing created yet.' }}
        onRow={(record: DesignDrawing) => {
          return {
            onClick: () => {
              history.push(getDrawingUrl(projectId, aoiId, 'edit', record.id));
            },
          };
        }}
        rowKey={(record: DesignDrawing) => record.id}
      />
    );
  };

  public render() {
    const { projectId, aoiId, history } = this.props;

    return (
      <div>
        <div>
          <div className={style.titleWrapper}>
            <Title level={2} className={style.title}>
              Drawings
            </Title>
            <DocumentationLink
              href={DOCUMENTATION_URL_LIST.drawingUpload}
              toolTipPosition="right"
            />
          </div>

          <Button
            className={style.newDrawing}
            onClick={() => {
              history.push(getDrawingUrl(projectId, aoiId, 'new'));
            }}
          >
            + New Drawing
          </Button>
        </div>

        {this.renderDrawingsTable()}
      </div>
    );
  }
}
