import { polygon } from '@turf/helpers';
import centroid from '@turf/centroid';
import GeoJSONReader from 'jsts/org/locationtech/jts/io/GeoJSONReader.js';
import ValidationOperation from 'jsts/org/locationtech/jts/operation/valid/IsValidOp.js';
import { CoordinateUnits } from 'src/api/views.types';
import { Config } from 'src/utils/config';
import { PRODUCT_ID } from '../constants';
import { GenericObjectType, NullOrGenericObjectType } from '../shapes/app';
import { GoogleMapsPositionTypes } from '../components/PlotGoogleMap/index.types';
import { MAPBOX_API_URL } from '../constants/urls';
import { showCertifiedOnly } from './localStorage';
import { User } from '../api/auth.types';

export const isVimanaLite = (): boolean => {
  return PRODUCT_ID === 'vimana_lite';
};

export const shouldUseLegacyMeasurements = (): boolean => {
  return Config.getConfig().useLegacyMeasurements;
};

export const getMapboxToken = (): string => {
  return Config.getConfig().mapboxToken;
};

export const getGoogleMapKey = (): string => {
  return Config.getConfig().googleMapsToken;
};

export const getGoogleApiJsUrl = (): string => {
  return `https://maps.google.com/maps/api/js?key=${getGoogleMapKey()}&libraries=drawing`;
};

export const humanFileSize = (bytes: number, si?: boolean): string => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    : ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let u = -1;

  do {
    // eslint-disable-next-line no-param-reassign
    bytes /= thresh;
    ++u; // eslint-disable-line no-plusplus
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);

  return `${bytes.toFixed(1)} ${units[u]}`;
};

export const undefinedOrNull = <T>(
  variable: T | null | undefined
): variable is null | undefined => {
  return typeof variable === 'undefined' || variable === null;
};

/**
 * Chained object validator
 * Validates the chained object values
 * @returns boolean
 * @param mainObj: object or array
 * @param key: string
 */
export const undefinedOrNullChained = (
  mainObj: any,
  key: string | null = null
): boolean | undefined => {
  const _return = undefined;

  if (typeof mainObj === 'undefined' || !mainObj) {
    return typeof _return === 'undefined';
  }

  if (
    !key ||
    (Object.prototype.toString.call(key) !== '[object String]' &&
      key.trim() === '')
  ) {
    return typeof _return === 'undefined';
  }

  const keyArray = key.split('.');

  if (!keyArray || keyArray.length < 1) {
    return typeof _return === 'undefined';
  }

  let temp = mainObj;

  keyArray.map((a: string) => {
    if (typeof temp !== 'undefined') {
      const _matches = a.match(/[^[\]]+(?=])/g);

      if (_matches && _matches.length > 0) {
        const aSplits = a.split('[')[0];
        let lTemp = temp[aSplits];

        _matches.map((e: number | string) => {
          if (typeof lTemp !== 'undefined' && typeof lTemp[e] !== 'undefined') {
            lTemp = lTemp[e];

            return lTemp;
          }

          lTemp = undefined;

          return lTemp;
        });
        temp = lTemp;

        return temp;
      }

      if (typeof temp[a] !== 'undefined') {
        temp = temp[a];

        return temp;
      }
    }

    temp = _return;

    return temp;
  });

  return typeof temp === 'undefined';
};

export const isNumeric = (n: string, isNatural?: boolean): boolean => {
  let regex = /^-?\d+(\.\d{1,2})?$/;

  if (isNatural) {
    regex = /^\d+(\.\d{1,2})?$/;
  }

  return regex.test(n);
};

export const quickHash = (str: string): number => {
  let hash = 0;
  let i;
  let chr;

  if (str.length === 0) {
    return hash;
  }

  for (i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr; // eslint-disable-line no-bitwise
    hash |= 0; // eslint-disable-line no-bitwise
  }

  return hash;
};

export const reactKey = (str: string | number): number => {
  if (undefinedOrNull(str)) {
    return 0;
  }

  return quickHash(str.toString());
};

export const getExtension = (filename: string): string | undefined | null => {
  if (undefinedOrNull(filename)) {
    return null;
  }

  return filename.split('.').pop();
};

export const splitIntoLines = (str: string): null | string[] => {
  if (undefinedOrNull(str)) {
    return null;
  }

  return str.toString().split(/(\r?\n)/g);
};

export const filterEmptySplitLines = (string: string): boolean => {
  return string === '\n' || string === '\r\n' || string === '';
};

export const jsonToCsv = (jsonData: any[]): string => {
  const replacer = (key: string, value: string): string =>
    value === null ? '' : value;
  const header = Object.keys(jsonData[0]);
  const csv = jsonData.map((row: any) =>
    header
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(',')
  );

  csv.unshift(header.join(','));

  return csv.join('\r\n');
};

export const getTime = (date?: any): any => {
  return new Date(date) != null ? new Date(date).getTime() : 0;
};

export const arrayToCsv = (header: string, array: []): string => {
  return `${header}\n${array.map((e: any) => e.join(',')).join('\n')}`;
};

export const checkObjectsEquality = (firstObject: any, secondObject: any) => {
  const firstObjectProps = Object.getOwnPropertyNames(firstObject);
  const secondObjectProps = Object.getOwnPropertyNames(secondObject);

  if (firstObjectProps.length !== secondObjectProps.length) {
    return false;
  }

  for (let i = 0; i < firstObjectProps.length; i += 1) {
    const propName = firstObjectProps[i];

    if (firstObject[propName] !== secondObject[propName]) {
      return false;
    }
  }

  return true;
};

export const checkArrayContentEquality = (
  a: (string | number)[],
  b: (string | number)[]
): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  const bContainsA = a.map((i) => b.includes(i));
  const aContainsB = b.map((i) => a.includes(i));

  if (bContainsA.includes(false)) {
    return false;
  }

  if (aContainsB.includes(false)) {
    return false;
  }

  return true;
};

export const toMetersEquivalent = (
  value: number,
  type: string = 'meters'
): number => {
  if (!value) {
    return 0;
  }

  switch (type) {
    case 'meters':
    default:
      return parseFloat(value.toFixed(2));

    case 'feet':
    case 'usFT':
    case 'usft':
      return parseFloat((value * 3.28084).toFixed(2));
  }
};

export const arrayDiff = (
  array1: (string | number)[],
  array2: (string | number)[]
): (string | number)[] => {
  return array1.filter((x) => !array2.includes(x));
};

export const arrayIntersection = (
  array1: (string | number | null)[],
  array2: (string | number | null)[]
): (string | number | null)[] => {
  return array1.filter((x) => array2.includes(x));
};

export const isNumber = (x: any): x is number => {
  return typeof x === 'number';
};

export const isString = (x: any): x is string => {
  return typeof x === 'string';
};

export const isArray = (x: any): x is any[] => {
  return !!x && x.constructor === Array;
};

export const isObject = (x: any): x is GenericObjectType => {
  if (x === null) {
    return false;
  }

  return typeof x === 'function' || typeof x === 'object';
};

export const inArray = (
  array: (string | number | null)[],
  value: (string | number | null)[] | string | number | null
): boolean => {
  if (isArray(value)) {
    const diff = arrayIntersection(array, value);

    return diff && diff.length > 0;
  }

  return array.indexOf(value) > -1;
};

export function isEmpty(
  x: any,
  trim: boolean = false
): x is string | any[] | GenericObjectType | null | undefined {
  if (undefinedOrNull(x)) {
    return true;
  }

  if (isString(x)) {
    if (trim && x.trim() === '') {
      return true;
    }

    return !!(!trim && x === '');
  }

  if (isArray(x) && x.length < 1) {
    return true;
  }

  if (isObject(x) && Object.keys(x).length < 1) {
    return true;
  }

  return false;
}

export const roundDecimals = (value: number): number => {
  return Math.round(value * 100) / 100;
};

export const setStyle = (
  selector: string | HTMLElement | null,
  styles: React.CSSProperties | GenericObjectType
) => {
  let elem: HTMLElement | null;

  if (isString(selector)) {
    elem = document.querySelector(selector);
  } else {
    elem = selector;
  }

  if (!elem) {
    return;
  }

  let styleString = '';

  Object.keys(styles).map((a) => {
    const item = styles[a];

    styleString += `${a}:${item};`;

    return a;
  });

  elem.setAttribute('style', styleString);
};

export const addZeroes = (n: string) => {
  let value: number | string = Number(n);
  const res = n.split('.');

  if (res.length === 1 || res[1].length < 3) {
    value = value.toFixed(2);
  }

  return value.toString();
};

export const filteredArrayValue = (array: any[]): GenericObjectType | null => {
  if (undefinedOrNull(array) || array.length < 1 || !array[0]) {
    return null;
  }

  return array[0];
};

export const objectListToQueryString = (
  queryStringParams?:
    | {
        [key: string]: string | number | undefined;
      }
    | null
    | undefined,
  allowQuestionMarkSeparator = true
) => {
  let _qs = null;

  if (!undefinedOrNull(queryStringParams)) {
    _qs = Object.keys(queryStringParams)
      .filter(
        (key) =>
          !undefinedOrNull(key) && !undefinedOrNull(queryStringParams[key])
      )
      .map((key) => `${key}=${queryStringParams[key]}`)
      .join('&');
  }

  if (!allowQuestionMarkSeparator) {
    return !_qs ? '' : `${_qs}`;
  }

  return !_qs ? '' : `?${_qs}`;
};

export const validateUrl = (url: string): boolean => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url
  );
};

export const parseYoutubeId = (url: string): string | null => {
  const regExp =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]+).*/;

  const matched = url.match(regExp);

  return matched && matched[1] ? matched[1] : null;
};

export const parseVimeoId = (url: string): string | null => {
  const regExp =
    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  const matched = regExp.exec(url);

  return matched && matched[5] ? matched[5] : null;
};

export const removeArrayByIndex = (array: any[], index: number) => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
];

export const removeArrayByValue = (
  array: (string | number)[],
  value: string | number
) => array.filter((a) => a !== value);

export const swapArrayIndex = (
  array: any[],
  oldIndex: number,
  newIndex: number
) => {
  if (newIndex >= array.length) {
    let k = newIndex - array.length + 1;

    // eslint-disable-next-line no-plusplus
    while (k--) {
      array.push(undefined);
    }
  }

  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);

  return array;
};

export const shiftArrayIndexToFront = (array: any[], index: number) => {
  const overallSiteArray = array[index];

  // eslint-disable-next-line no-param-reassign
  delete array[index];

  return [overallSiteArray, ...array.filter((a: GenericObjectType) => a)];
};

export const mapboxImageUrl = (
  longitude: number | string,
  latitude: number | string,
  resolution: string = '400x280',
  zoom: number | string = 15,
  rotation: number | string = '0.00',
  username: string = 'mapbox',
  styleId: string = 'satellite-v9',
  accessToken: string = getMapboxToken()
) => {
  return `${MAPBOX_API_URL}${username}/${styleId}/static/${longitude || 0},${
    latitude || 0
  },${zoom || 0},${rotation},0.00/${resolution}@2x?access_token=${accessToken}`;
};

export const objectDiff = (
  obj1: GenericObjectType,
  obj2: GenericObjectType
) => {
  return Object.keys(obj2).reduce((diff, key) => {
    if (obj1[key] === obj2[key]) {
      return diff;
    }

    return {
      ...diff,
      [key]: obj2[key],
    };
  }, {});
};

export const getGeoJsonFeatures = (
  geoJson: NullOrGenericObjectType
): NullOrGenericObjectType => {
  if (undefinedOrNull(geoJson)) {
    return null;
  }

  const { features } = geoJson;
  let geoJsonShapeList = null;

  if (features.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    geoJsonShapeList = features[0];
  } else {
    geoJsonShapeList = features;
  }

  return geoJsonShapeList;
};

export const getBoundaryPoints = (
  boundary: NullOrGenericObjectType
): GoogleMapsPositionTypes[] | null => {
  if (!boundary || !boundary.features) {
    return null;
  }

  const features = boundary.features.filter((f: GenericObjectType) => {
    return f && f.geometry && f.geometry.type === 'Polygon';
  });

  if (features.length > 0) {
    const pointsArr = features[0].geometry.coordinates;

    return (pointsArr || [[]])[0].map((p: any) => {
      return {
        lat: p[1],
        lng: p[0],
      };
    });
  }

  return null;
};

export const hasAdminAccess = (
  user: NullOrGenericObjectType,
  projectId: string
): boolean => {
  if (!user) {
    return false;
  }

  if (user.staff === true) {
    return true;
  }

  if (!projectId) {
    return false;
  }

  const projectRoles = user.project_roles || [];

  for (let i = 0; i < projectRoles.length; i += 1) {
    const item = projectRoles[i];

    if (item.project_id === projectId) {
      return item.role === 'project_admin';
    }
  }

  return false;
};

export const hasManageAccess = (user?: User, projectId?: string): boolean => {
  if (!user) {
    return false;
  }

  if (user.staff) {
    return true;
  }

  if (!projectId) {
    return false;
  }

  const projectRoles = user.project_roles || [];

  for (let i = 0; i < projectRoles.length; i += 1) {
    const item = projectRoles[i];

    if (item.project_id === projectId) {
      return inArray(['drone_operator', 'field_staff'], item.role);
    }
  }

  return false;
};

export const shouldShowPage = (
  projectId: string | null,
  allowedRoles: string[],
  user: NullOrGenericObjectType
) => {
  if (!user) {
    return false;
  }

  if (user.staff === true) {
    return true;
  }

  if (projectId) {
    // eslint-disable-next-line no-restricted-syntax
    for (const role of user.project_roles || []) {
      if ((role as any).project_id === projectId) {
        const roleId = (role as any).role;

        return allowedRoles.indexOf(roleId) !== -1;
      }
    }
  }

  return false;
};

export const trimArray = (array: any[], start: number = 0, end: number = 1) => {
  return array.slice(start, end);
};

export const round = (number: number | string, size: number = 2) => {
  const _number = `${number}`;

  return +`${Math.round(parseFloat(`${_number}e+${size}`))}e-${size}`;
};

export const percentage = (
  partial: number,
  total: number,
  roundOff: number = 2
) => {
  return round((100 * partial) / total, roundOff);
};

export const isCertifiedView = (view: GenericObjectType) => {
  const viewCertifiedOnly = showCertifiedOnly(view.aoiId);

  return (view.certifiedForDisplay && viewCertifiedOnly) || !viewCertifiedOnly;
};

export const loadExternalScript = (
  url: string,
  variable: any,
  callbackBefore?: () => void
) => {
  return new Promise((resolve) => {
    if (!undefinedOrNull(variable)) {
      return resolve(true);
    }

    if (callbackBefore) {
      callbackBefore();
    }

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = url;

    const scriptTag = document.getElementsByTagName('script')[0];

    if (scriptTag.parentNode) {
      scriptTag.parentNode.insertBefore(script, scriptTag);

      script.addEventListener('load', () => {
        return resolve(true);
      });
    }
  });
};

// Converts from degrees to radians.
export const degToRad = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

// Converts from radians to degrees.
export const radToDeg = (radians: number) => {
  return (radians * 180) / Math.PI;
};

export const yawToDeg = (yaw: number) => {
  return (360 + yaw) % 360;
};

export const flattenArray = (array: any[]): any[] => {
  // eslint-disable-next-line prefer-spread
  return [].concat.apply([], array);
};

export const watch = (value: any, interval = 1) => {
  return new Promise((resolve) => {
    let timeOut: any = setInterval(() => {
      let isLoaded = false;

      if (!undefinedOrNull(value)) {
        isLoaded = true;

        if (isLoaded) {
          clearInterval(timeOut);
          timeOut = 0;
        }

        return resolve(value);
      }
    }, interval);
  });
};

export const arraySum = (array: number[]) => {
  return array.reduce((a, b) => a + b, 0);
};

export const firstLetterUpperCase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const arrayInsert = (array: any[], index: number, newItem: any) => [
  ...array.slice(0, index),
  newItem,
  ...array.slice(index),
];

export const arrayRemoveDuplicate = (array: (string | number)[]) => {
  return array.filter((item, pos) => {
    return array.indexOf(item) === pos;
  });
};

export const isInRange = (x: number, min: number, max: number) => {
  return x >= min && x <= max;
};

export interface DataFilterTypes {
  [key: string]:
    | {
        type: 'equals';
        value: (number | string)[];
      }
    | {
        type: 'range';
        value: [number, number];
      };
}

export const filterData = (
  data: GenericObjectType[],
  filter: DataFilterTypes,
  excludedFilters?: string[]
) => {
  const query = {};

  Object.keys(filter).map((key) => {
    const item = filter[key];

    if (excludedFilters && inArray(excludedFilters ?? [], key)) {
      return;
    }

    if (isArray(item.value) && item.value.length > 0) {
      query[key] = item;
    }

    return key;
  });

  return data.filter((item) => {
    let itemCount = 0;

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in query) {
      if (item[key] !== undefined) {
        let count = 0;

        if (
          query[key].type === 'equals' &&
          query[key].value.includes(item[key])
        ) {
          count += 1;
        }

        if (
          query[key].type === 'range' &&
          isInRange(
            item[key],
            parseFloat(`${query[key].value[0]}`),
            parseFloat(`${query[key].value[1]}`)
          )
        ) {
          count += 1;
        }

        itemCount += count;
      }
    }

    return itemCount === Object.keys(query).length;
  });
};

export const generateRange = (start: number, end: number) => {
  const result = [];

  for (let i = start; i <= end; i += 1) {
    result.push(i);
  }

  return result;
};

export const minValue = (value: number, min = 0) => {
  return value < 0 ? min : value;
};

export const hexToRgb = (hex: string | undefined) => {
  if (!hex) {
    return undefined;
  }

  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  const _hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(_hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : undefined;
};

export const extractProjectIdFromURL = (url: string) => {
  if (url.length === 0) {
    return null;
  }

  const pathWithProjectId = url.substring(
    url.indexOf('/project/') + '/project/'.length
  );

  if (pathWithProjectId.indexOf('/') === -1) {
    return pathWithProjectId;
  }

  const projectId = pathWithProjectId.slice(0, pathWithProjectId.indexOf('/'));

  return projectId;
};

export const queryStringToObject = (queryString: string): GenericObjectType => {
  const qsObject = {};
  let modifiedQueryString = queryString;

  if (queryString.length === 0) {
    return qsObject;
  }

  if (queryString[0] === '?') {
    modifiedQueryString = queryString.substr(1);
  }

  const pairs = modifiedQueryString.split('&');

  return pairs.reduce((acc, curr) => {
    const [key, value] = curr.split('=');

    acc[key] = value;

    return acc;
  }, qsObject);
};

// user defined type guard
// https://stackoverflow.com/questions/43010737/way-to-tell-typescript-compiler-array-prototype-filter-removes-certain-types-fro/54318054
export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}

export function uuid() {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;

    dt = Math.floor(dt / 16);

    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
}

/**
 * Function to check whether a Geometry is valid.
 *
 * @param geometryString Geometry as a GeoJSON string
 */
export const isValidGeometry = (geometryString: string) => {
  const reader = new GeoJSONReader();

  try {
    const geometry = reader.read(geometryString);
    const checkGeometry = new ValidationOperation(geometry);

    return checkGeometry.isValid();
  } catch (e) {
    console.error('Error during geometry validation.', e);

    return false;
  }
};

/**
 * Function to get the units of the view. If coordinate units is not specified, it should be treated as metres.
 * @param coordinateUnits
 */
export const getUnits = (coordinateUnits?: CoordinateUnits): 'm' | 'ft' => {
  if (coordinateUnits) {
    if (coordinateUnits === 'METRES') return 'm';

    return 'ft';
  }

  return 'm';
};

export const getGeoJsonVertexPoints = (
  geoJson: any
): Array<Array<[number, number]>> => {
  if (geoJson && geoJson.features) {
    const features = geoJson.features.filter((f: any) => {
      return f && f.geometry && f.geometry.type === 'Polygon';
    });

    if (features.length > 0) {
      return features[0].geometry.coordinates;
    }
  }

  return [];
};

export const getCentroid = (geoJson: any): { x: number; y: number } => {
  const p = polygon(getGeoJsonVertexPoints(geoJson));

  const center = centroid(p);

  const { coordinates } = center.geometry;

  return { x: coordinates[0], y: coordinates[1] };
};
