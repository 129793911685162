// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageRenderer_container__M98MN{height:100%;width:100%}.ImageRenderer_fullScreenControl__C7VGc{position:absolute;top:.5em;right:.5em}", "",{"version":3,"sources":["webpack://./src/components/View/ImageView/ImageRenderer/index.module.scss"],"names":[],"mappings":"AAAA,gCACE,WAAA,CACA,UAAA,CAGF,wCACE,iBAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".container {\n  height: 100%;\n  width: 100%;\n}\n\n.fullScreenControl {\n  position: absolute;\n  top: 0.5em;\n  right: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ImageRenderer_container__M98MN",
	"fullScreenControl": "ImageRenderer_fullScreenControl__C7VGc"
};
export default ___CSS_LOADER_EXPORT___;
