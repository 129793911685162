import * as React from 'react';
import flattenChildren from 'react-flatten-children';
import { isNullOrUndefined } from 'util';
import ViewsV2Apis from '../../api/viewsV2';
import { APP_BASE_URL } from '../../constants/urls';
import { undefinedOrNull } from '../../utils/functs';
import { log } from '../../utils/log';
import {
  conertGeoJsonToThreeJs,
  convertDxfToThreeJs,
} from '../../utils/VectorShapesToThreeJs';
import style from './index.module.scss';
import { PotreePropsTypes, PotreeStateTypes } from './index.types';

declare let $: any;
declare let Potree: any;
declare let THREE: any;
declare let proj4: any;

class PotreeWrapper extends React.Component<
  PotreePropsTypes,
  PotreeStateTypes
> {
  private VIMANA_POINT_CLASSES = {
    0: { visible: true, name: 'Never classified', color: [0.5, 0.5, 0.5, 1.0] },
    1: { visible: true, name: 'Unclassified', color: [0.5, 0.5, 0.5, 1.0] },
    2: { visible: true, name: 'Ground', color: [0.63, 0.32, 0.18, 1.0] },
    3: { visible: true, name: 'Low vegetation', color: [0.0, 1.0, 0.0, 1.0] },
    4: {
      visible: true,
      name: 'Medium vegetation',
      color: [0.0, 0.8, 0.0, 1.0],
    },
    5: { visible: true, name: 'High vegetation', color: [0.0, 0.6, 0.0, 1.0] },
    6: { visible: true, name: 'Building', color: [1.0, 0.66, 0.0, 1.0] },
    7: {
      visible: true,
      name: 'Low point (noise)',
      color: [1.0, 0.0, 1.0, 1.0],
    },
    9: { visible: true, name: 'Water', color: [0.0, 0.0, 1.0, 1.0] },
    10: { visible: true, name: 'Rail', color: [0.0, 0.0, 1.0, 1.0] },
    11: { visible: true, name: 'Road Surface', color: [0.0, 0.0, 1.0, 1.0] },
    12: { visible: true, name: 'overlap', color: [1.0, 1.0, 0.0, 1.0] },
    13: {
      visible: true,
      name: 'Wire - Guard (Shield)',
      color: [0.0, 0.0, 1.0, 1.0],
    },
    14: {
      visible: true,
      name: 'Wire - Conductor (Phase)',
      color: [0.0, 0.0, 1.0, 1.0],
    },
    15: {
      visible: true,
      name: 'Transmission Tower',
      color: [0.0, 0.0, 1.0, 1.0],
    },
    16: {
      visible: true,
      name: 'Wire-structure Connector',
      color: [0.0, 0.0, 1.0, 1.0],
    },
    17: { visible: true, name: 'Bridge Desk', color: [0.0, 0.0, 1.0, 1.0] },
    18: { visible: true, name: 'High Noise', color: [0.0, 0.0, 1.0, 1.0] },
    19: { visible: true, name: 'Culvert', color: [0.0, 0.0, 1.0, 1.0] },
    20: { visible: true, name: 'Vehicle', color: [0.0, 0.0, 1.0, 1.0] },
    21: { visible: true, name: 'Pipe Line', color: [0.0, 0.0, 1.0, 1.0] },
    22: { visible: true, name: 'Berth', color: [0.0, 0.0, 1.0, 1.0] },
    23: { visible: true, name: 'Water Stream', color: [0.0, 0.0, 1.0, 1.0] },
    24: { visible: true, name: 'Solar Panel', color: [0.0, 0.0, 1.0, 1.0] },
  };

  private potreeSelectorId = 'potree_render_area';

  private viewer: any = null;

  private projection: string | null = null;

  private prevConfig: any = null;

  private mouseVector = new THREE.Vector2();

  private isDragging = false;

  private _refPromiseResolver: any = null;

  // eslint-disable-next-line promise/param-names
  private renderElementRefPromise: Promise<HTMLDivElement> = new Promise(
    (resolve, _) => {
      this._refPromiseResolver = resolve;
    }
  );

  private unmounted = false;

  constructor(props: PotreePropsTypes) {
    super(props);
    this.state = {
      showProjectionWarning: false,
      pointcloudLoaded: false,
      menuPosition: null,
    };
  }

  public componentDidMount() {
    this.renderCloud();
    this.registerEvents();
  }

  public componentDidUpdate(prevProps: PotreePropsTypes) {
    const { view: prevView, previewVectorLayer: prevPreviewVectorLayer } =
      prevProps;
    const { view, previewVectorLayer } = this.props;

    if (prevView?.id !== view?.id) {
      // if viewer is present, save old camera
      if (this.viewer) {
        const { x, y, z } = this.viewer?.scene?.view?.position;
        const { x: x2, y: y2, z: z2 } = this.viewer?.scene?.view?.getPivot();

        this.prevConfig = {
          position: [x, y, z],
          pivot: [x2, y2, z2],
          pointBudget: Potree?.pointBudget,
        };
      } else {
        this.prevConfig = null;
      }

      this.renderCloud();
    }

    if (previewVectorLayer !== prevPreviewVectorLayer) {
      if (this.viewer) {
        const loader = new Potree.ShapefileLoader();
        // loader.transform = transform;

        // group all shapefile scene nodes into this node
        const shapeNode = new THREE.Object3D();

        this.viewer.scene.scene.add(shapeNode);

        // load points.shp
        loader.load(previewVectorLayer).then((shpPoints: any) => {
          shapeNode.add(shpPoints.node);
        });
      }
    }
  }

  public componentWillUnmount() {
    this.unmounted = true;
    try {
      if (this.viewer?.profileWindow) {
        this.viewer.profileWindow.hide();
      }

      this.clearPointClouds();
      this.viewer = null;
      (window as any).viewer = null;
    } catch (e) {
      log.error(e, 'PotreeWrapper.componentWillUnmount');
    }
  }

  private registerEvents = () => {
    const potreeSelector = document.getElementById(this.potreeSelectorId);

    if (undefinedOrNull(potreeSelector)) {
      return;
    }

    let contextmenuInterval: any = null;

    $(`#${this.potreeSelectorId}`).on('mousemove', (_e: any) => {
      if (contextmenuInterval != null) {
        clearTimeout(contextmenuInterval);
        contextmenuInterval = null;
      }

      if (this.isDragging) {
        // if  dragging, remove context menu if shown
        const { menuPosition } = this.state;

        if (menuPosition) {
          this.setState({ menuPosition: null });
        }
      }
    });
    $(`#${this.potreeSelectorId}`).on('click', (_e: any) => {
      if (!$(_e.target).hasClass('potree_menu_click_marker')) {
        this.setState({ menuPosition: null });
      }
    });
    $(`#${this.potreeSelectorId}`).on('mousedown', () => {
      this.isDragging = true;
    });
    $(`#${this.potreeSelectorId}`).on('mouseup', () => {
      this.isDragging = false;
    });
    $(`#${this.potreeSelectorId}`).contextmenu((e: any) => {
      const { showMinimalControls, inspectionTargetView } = this.props;

      if (!showMinimalControls && inspectionTargetView) {
        contextmenuInterval = setTimeout(() => {
          this.contextMenu(e);
        }, 100);
      }
    });
  };

  public clearPointClouds() {
    if (this.viewer) {
      this.viewer.scene.pointclouds.forEach((layer: any) => {
        this.viewer.scene.scenePointCloud.remove(layer);
      });
      this.viewer.scene.pointclouds = [];
    }

    this.viewer = null;
  }

  public renderCloud() {
    const { view, showMinimalControls } = this.props;

    if (!view) {
      return;
    }

    this.renderElementRefPromise.then((el) => {
      if (!el) {
        return;
      }

      this.clearPointClouds();
      const viewId = view.id;
      const viewer = new Potree.Viewer(el);

      (window as any).viewer = viewer;
      this.viewer = viewer;
      viewer.classifications = this.VIMANA_POINT_CLASSES;
      let unitsLabel = 'm';

      if (view.coordinateUnits === 'FEET') {
        unitsLabel = 'ft';
      } else if (view.coordinateUnits === 'US_SURVEY_FEET') {
        unitsLabel = 'usft';
      }

      viewer.LENGTH_UNITS = {
        METER: { code: unitsLabel, unitspermeter: 1 },
        FEET: { code: 'ft', unitspermeter: 1 },
        INCH: { code: '\u2033', unitspermeter: 1 },
        USFT: { code: 'usft', unitspermeter: 1 },
      };
      viewer.lengthUnit = viewer.LENGTH_UNITS.METER;
      viewer.lengthUnitDisplay = viewer.LENGTH_UNITS.METER;
      viewer.setEDLEnabled(false);
      viewer.setFOV(60);
      viewer.setMinNodeSize(0);
      viewer.loadGUI(() => {
        viewer.setLanguage('en');
        this.initColorSelector();
      });

      Potree.loadPointCloud(
        `${APP_BASE_URL}/capi/api/v1/images/views/${viewId}/pointcloud/cloud.js`,
        'Vimana Point Cloud Viewer',
        (pc: any) => {
          this.onPoinCloudLoaded(
            viewer,
            pc,
            view.id,
            showMinimalControls || false
          );
        }
      );
    });
  }

  private onPoinCloudLoaded(
    viewer: any,
    pc: any,
    viewId: string,
    showMinimalControls: boolean
  ) {
    try {
      const { projection: oldProjection, prevConfig } = this;

      viewer.scene.addPointCloud(pc.pointcloud);
      this.projection = pc?.pointcloud?.pcoGeometry?.projection;

      const light = new THREE.AmbientLight(0x404040, 10);

      viewer.scene.scene.add(light);

      const { viewConfig } = this.props;

      if (
        oldProjection &&
        prevConfig &&
        prevConfig.position &&
        prevConfig.pivot &&
        this.projection
      ) {
        const pos = proj4(oldProjection, this.projection, prevConfig.position);
        const pivot = proj4(oldProjection, this.projection, prevConfig.pivot);

        viewer.scene.view.position.set(pos[0], pos[1], pos[2]);
        viewer.scene.view.lookAt(pivot[0], pivot[1], pivot[2]);
      } else if (
        !isNullOrUndefined(viewConfig?.x) &&
        !isNullOrUndefined(viewConfig?.y) &&
        !isNullOrUndefined(viewConfig?.z) &&
        !isNullOrUndefined(viewConfig?.x2) &&
        !isNullOrUndefined(viewConfig?.y2) &&
        !isNullOrUndefined(viewConfig?.z2)
      ) {
        viewer.scene.view.position.set(
          viewConfig.x,
          viewConfig.y,
          viewConfig.z
        );
        viewer.scene.view.lookAt(viewConfig.x2, viewConfig.y2, viewConfig.z2);
      } else {
        viewer.setTopView();
      }

      if (!isNullOrUndefined(prevConfig?.pointBudget)) {
        viewer.setPointBudget(prevConfig?.pointBudget);
      } else if (!isNullOrUndefined(viewConfig?.pointBudget)) {
        viewer.setPointBudget(viewConfig.pointBudget);
      } else {
        viewer.setPointBudget(1 * 100 * 1000);
      }

      this.setState({ pointcloudLoaded: true });
    } catch (e) {
      log.error(e, 'PotreeWrapper.renderCloud');
    }

    if (!showMinimalControls) {
      try {
        viewer.toggleNavigationCube();
      } catch (e) {
        log.error(e, 'PotreeWrapper.renderCloud');
      }
    }

    try {
      $(`#${this.potreeSelectorId}`).css('left', '0px');
    } catch (e) {
      log.error(e, 'PotreeWrapper.renderCloud');
    }

    $('#sidebar_header').hide();
    $('#menu_about').hide();

    const { view } = this.props;

    if (view?.demId && view?.certifiedForMeasurement) {
      $('#menu_tools').hide();
    }
    // $('#scene_export').hide();

    // load any vector files if they exist
    const api = new ViewsV2Apis();

    api.getViewVectors(viewId).then((res) => {
      const { error, data } = res;

      if (error) {
        log.error(error, 'PotreeWrapper.loadVectorFiles');
        throw error;
      }

      if (data) {
        if (this.unmounted) return;
        // create a scene for rendering vectors and insert it
        const scene = new THREE.Scene();
        const light = new THREE.PointLight(0xffffff, 1.0);

        scene.name = 'scene_overlay_vectors';
        scene.add(light);

        this.viewer.inputHandler.registerInteractiveScene(scene);

        // eslint-disable-next-line no-restricted-syntax
        for (const f of data) {
          if (f?.id) {
            api.getViewVectorContent(f.id).then((res) => {
              const { data } = res;

              if (data) {
                let shapeContainer: any;

                if (f.type === 'dxf') {
                  shapeContainer = convertDxfToThreeJs(data, f);
                } else if (f.type === 'geojson') {
                  shapeContainer = conertGeoJsonToThreeJs(data, f);
                }

                if (!shapeContainer) {
                  return;
                }

                const groups: any = {};

                shapeContainer.traverse((child: any) => {
                  if (child?.userData?.layer) {
                    const layer = child?.userData?.layer;

                    if (!groups[layer]) {
                      groups[layer] = [];
                    }

                    groups[layer].push(child);
                  }
                });

                shapeContainer.visible = true;
                scene.add(shapeContainer);

                const tree: any = $(`#jstree_scene`);
                const parentNode: any = 'vectors';

                const shpPointsID = tree.jstree(
                  'create_node',
                  parentNode,
                  {
                    text: f.name || 'Vector',
                    icon: `${Potree.resourcePath}/icons/triangle.svg`,
                    object: shapeContainer,
                    data: shapeContainer,
                  },
                  'last',
                  false,
                  false
                );

                tree.jstree(
                  shapeContainer.visible ? 'check_node' : 'uncheck_node',
                  shpPointsID
                );

                // eslint-disable-next-line no-restricted-syntax
                for (const id in groups) {
                  if (id) {
                    const group = {
                      checked: true,
                      items: groups[id],
                      onCheck: (checked: boolean) => {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const item of group.items) {
                          item.visible = checked;
                        }
                      },
                    };

                    // eslint-disable-next-line no-restricted-syntax
                    for (const item of group.items) {
                      item.visible = true;
                    }

                    const groupNodeId = tree.jstree(
                      'create_node',
                      shpPointsID,
                      {
                        text: id || '',
                        icon: `${Potree.resourcePath}/icons/triangle.svg`,
                        object: group,
                        data: group,
                      },
                      'last',
                      false,
                      false
                    );

                    tree.jstree(
                      group.checked ? 'check_node' : 'uncheck_node',
                      groupNodeId
                    );
                  }
                }

                tree.on(
                  'check_node.jstree uncheck_node.jstree',
                  (e: any, data: any) => {
                    if (data?.node?.data?.onCheck) {
                      data.node.data.onCheck(data.node.state.checked);
                    }
                  }
                );
              }
            });
          }
        }

        const listener = (_e: any) => {
          if (!this.viewer) {
            return;
          }

          if (this.unmounted) {
            try {
              this.viewer.removeEventListener('render.pass.end', listener);
            } catch (e) {
              console.error(e);
            }

            return;
          }

          this.viewer.renderer.render(
            scene,
            this.viewer.scene.getActiveCamera()
          );
        };

        this.viewer.addEventListener('render.pass.end', listener);
      }
    });
  }

  public contextMenu(event: any) {
    if (!this.projection) return;

    const relX = event.pageX - $('#potree_render_area').offset().left;
    const relY = event.pageY - $('#potree_render_area').offset().top;
    const { mouseVector } = this;

    mouseVector.x = 2 * (relX / $('#potree_render_area').width()) - 1;
    mouseVector.y = 1 - 2 * (relY / $('#potree_render_area').height());

    const intersection =
      this.viewer.inputHandler.getMousePointCloudIntersection(mouseVector);
    const location = intersection?.location;

    if (location) {
      const latlon =
        '+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees +no_defs';
      const point = [location.x, location.y, location.z];
      const convertedPoint = proj4(this.projection, latlon, point);

      this.setState({
        menuPosition: {
          x: relX,
          y: relY,
          longitude: convertedPoint[0],
          latitude: convertedPoint[1],
          elevation: convertedPoint[2],
        },
      });
    }
  }

  private initColorSelector = () => {
    const appearanceUl = $('#menu_appearance').nextAll('div:first').find('ul');

    if (appearanceUl) {
      const colorization = $(`
      <ul class="pv-menu-list">
        <div class="divider">
          <span>Color Settings</span>
        </div>
        <li>
          <select>
            <option value="rgba">RGB Colors</option>
            <option value="elevation">Elevation</option>
            <option value="classification">Classification</option>
          </select>
          <span data="explanation">Show normal colors</span>
        </li>
      </ul>
      `);

      appearanceUl.append(colorization);

      $('select', colorization).selectmenu({
        change: () => {
          const val = $('select', colorization).selectmenu().val();

          switch (val) {
            case 'rgba':
              $('span[data="explanation"]', colorization).text(
                'Show normal colors'
              );
              break;

            case 'elevation':
              $('span[data="explanation"]', colorization).text(
                'Show colors based on elevation values'
              );
              break;

            case 'classification':
              $('span[data="explanation"]', colorization).text(
                'Show different classes of points in different colors. Note: you can select custom colors in "Filters" panel below'
              );
              break;

            default:
              $('span[data="explanation"]', colorization).text('');
          }

          if (this.viewer?.scene?.pointclouds?.length) {
            const pc = this.viewer?.scene?.pointclouds[0];

            pc.material.activeAttributeName = val;
          }
        },
      });
    }
  };

  private handleInspection() {
    const { menuPosition } = this.state;
    const { inspectionCallback } = this.props;

    if (inspectionCallback && menuPosition) {
      const { latitude, longitude, elevation } = menuPosition;

      inspectionCallback({
        latitude,
        longitude,
        elevation,
      });
    }
  }

  public render(): React.ReactNode {
    const { images, children } = this.props;
    const { showProjectionWarning, pointcloudLoaded, menuPosition } =
      this.state;

    const elements = React.Children.map(flattenChildren(children), (child) => {
      if (React.isValidElement(child as any)) {
        return React.cloneElement(child as any, { viewer: this.viewer });
      }

      return child;
    });

    return (
      <div className={style.container}>
        <div className={style.potree_container}>
          <div
            id={this.potreeSelectorId}
            ref={(el) => {
              this._refPromiseResolver(el);
            }}
          >
            {menuPosition && (
              <div
                className={style.menu_wrapper}
                style={{
                  top: menuPosition.y,
                  left: menuPosition.x,
                }}
              >
                {/* see registerEvents for explaination about class potree_menu_click_marker */}
                <div
                  className={`${style.menu_item} potree_menu_click_marker`}
                  onClick={() => {
                    this.handleInspection();
                  }}
                >
                  Inspect
                </div>
              </div>
            )}
          </div>
          <div id="potree_sidebar_container" />
        </div>
        {showProjectionWarning && pointcloudLoaded && images && (
          <div className={style.no_projection_warning}>
            <div className={style.warning_message}>
              This pointcloud has no projection data. Cannot display camera
              positions.
            </div>
          </div>
        )}
        {elements}
      </div>
    );
  }
}

export default PotreeWrapper;
