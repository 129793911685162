import React, { useEffect, useMemo } from 'react';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { useLayerManager } from './hooks';

export const SatelliteTiles: React.FC = () => {
  const { addLayer, removeLayer } = useLayerManager();
  const satelliteLayer = useMemo(() => {
    return new TileLayer({
      source: new OSM(),
    });
  }, []);

  useEffect(() => {
    addLayer(satelliteLayer);

    return () => {
      removeLayer(satelliteLayer);
    };
  }, [addLayer]);

  return <div data-testid="osm-satellite-tiles" />;
};
