import * as React from 'react';
import autobind from 'autobind-decorator';
import * as ControlBox from '../../../ControlBox';
import { InfoControlProps } from '../index.types';
import style from './index.module.scss';

class InfoControlBox extends React.Component<InfoControlProps> {
  @autobind
  private renderHeader(): React.ReactElement<React.ReactNode> {
    const { onClose } = this.props;

    return (
      <React.Fragment>
        <ControlBox.Title title="Information" />
        <ControlBox.Icon
          onClick={() => {
            if (!onClose) {
              return;
            }

            onClose();
          }}
          name="close"
        />
      </React.Fragment>
    );
  }

  public render(): React.ReactNode {
    const { metadata } = this.props;

    if (metadata && metadata.length) {
      return (
        <ControlBox.Wrapper
          className={style.container}
          renderHeader={this.renderHeader}
        >
          <table>
            <tbody>
              {metadata.map((meta, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index}>
                  <td className={style.infoName}>{meta.name}</td>
                  <td className={style.infoValue}>{meta.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ControlBox.Wrapper>
      );
    }

    return null;
  }
}

export default InfoControlBox;
