/* eslint-disable react/no-unused-state */
import * as React from 'react';
import classnames from 'classnames';
import { Modal, Select } from 'antd';

import { Button } from '../../../Button';
import {
  DemoProject,
  Organisation,
  OrganisationDemoProject,
} from '../../../../api/organisations.types';

import style from './index.module.scss';

const { Option } = Select;

interface IProps {
  organisation: Organisation;
  ownedProjects: DemoProject[];
  demoProjects: OrganisationDemoProject[];
  addDemoProject: (projectId: string) => void;
  removeDemoProject: (id: string) => void;
}

interface IState {
  ownedProjectId?: string;
  projectIdToDelete?: string;
  confirmAddPopup: boolean;
  confirmDeletePopup: boolean;
}

export default class OrganisationDemoProjects extends React.PureComponent<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      confirmAddPopup: false,
      confirmDeletePopup: false,
    };
  }

  private confirmDemoProjectAdd = (ok: boolean) => {
    const { addDemoProject } = this.props;
    const { ownedProjectId } = this.state;

    if (ok && ownedProjectId) {
      addDemoProject(ownedProjectId);
    }

    this.setState({ confirmAddPopup: false });
  };

  private confirmDemoProjectDelete = (ok: boolean) => {
    const { removeDemoProject } = this.props;
    const { projectIdToDelete } = this.state;

    if (ok && projectIdToDelete) {
      removeDemoProject(projectIdToDelete);
    }

    this.setState({ confirmDeletePopup: false });
  };

  public getOwnedProjectOptions = () => {
    const { ownedProjects } = this.props;

    return ownedProjects.map((o) => {
      return (
        <Option key={o.projectId} value={o.projectId}>
          {o.name}
        </Option>
      );
    });
  };

  public renderModals() {
    const { ownedProjects, demoProjects } = this.props;
    const {
      confirmAddPopup,
      confirmDeletePopup,
      projectIdToDelete,
      ownedProjectId,
    } = this.state;
    const demoProjectToDelete =
      projectIdToDelete && demoProjects.find((p) => p.id === projectIdToDelete);
    const projectToAdd =
      ownedProjectId &&
      ownedProjects.find((p) => p.projectId === ownedProjectId);
    const defaultDemoProjects =
      demoProjects.find((d) => d.id.startsWith('default')) !== undefined;

    return (
      <>
        {confirmAddPopup && projectToAdd ? (
          <Modal
            visible
            title="Add Demo Project to Organisation"
            onOk={() => this.confirmDemoProjectAdd(true)}
            onCancel={() => this.confirmDemoProjectAdd(false)}
            zIndex={10010}
            centered={false}
            destroyOnClose
            maskClosable={false}
            className={style.notificationModal}
          >
            <p>
              Are you sure you want to add the following project to the list of
              demo projects associated with the organisation?
            </p>
            <ul>
              <li>
                <b>{projectToAdd.name}</b>
              </li>
            </ul>
            {defaultDemoProjects ? (
              <>
                <p>
                  <b>Note:</b> Adding a project will override the current list
                  of default Demo Projects.
                </p>
              </>
            ) : (
              <></>
            )}
          </Modal>
        ) : (
          <></>
        )}
        {confirmDeletePopup && demoProjectToDelete ? (
          <Modal
            visible
            title="Remove Demo Project from Organisation"
            onOk={() => this.confirmDemoProjectDelete(true)}
            onCancel={() => this.confirmDemoProjectDelete(false)}
            zIndex={10010}
            centered={false}
            destroyOnClose
            maskClosable={false}
            className={style.notificationModal}
          >
            <>
              <p>
                Are you sure you want to remove the following demo project from
                the organisation?
              </p>
              <ul>
                <li>
                  <b>{demoProjectToDelete.name}</b>
                </li>
              </ul>
            </>
          </Modal>
        ) : (
          <></>
        )}
      </>
    );
  }

  public render() {
    const { ownedProjectId } = this.state;
    const { demoProjects } = this.props;

    return (
      <>
        <div className={style.addUserContainer}>
          <span className={style.addUserEmail}>
            <Select
              placeholder="Select Project to add to Demo Project List"
              onChange={(value: string) => {
                this.setState({ ownedProjectId: value || undefined });
              }}
            >
              {this.getOwnedProjectOptions()}
            </Select>
          </span>
          <Button
            text="+ Add Project"
            className={style.addUserBtn}
            onClick={() => this.setState({ confirmAddPopup: true })}
            disabled={!ownedProjectId}
          />
        </div>
        {demoProjects && demoProjects.length ? (
          <table className={style.usersTable}>
            <thead className={style.headingStyle}>
              <tr>
                <td>Project Name</td>
                <td />
              </tr>
            </thead>
            <tbody>
              {demoProjects.map((p, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr className={style.singleRowStyle} key={idx}>
                  <td className={classnames(style.capitalize, style.active)}>
                    {p.name}
                  </td>
                  <td
                    className={classnames(
                      style.controls,
                      style.capitalize,
                      style.rowEditStyle
                    )}
                  >
                    <Button
                      onClick={() =>
                        this.setState({
                          confirmDeletePopup: true,
                          projectIdToDelete: p.id,
                        })
                      }
                      text="Delete"
                      icon="delete"
                      className={style.deleteBtn}
                      disabled={p.id.startsWith('default')}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <></>
        )}
        {this.renderModals()}
      </>
    );
  }
}
