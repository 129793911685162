export const APP_BASE_URL =
  window.location.host === 'localhost:3000'
    ? process.env.REACT_APP_PUBLIC_URL || `https://vimanadev.vimanalabs.com`
    : `${window.location.protocol}//${window.location.host}`;

export const BASE_CAPI_URL = `${APP_BASE_URL}/capi/api`;

export const BASE_NAVIGATOR_API_URL = BASE_CAPI_URL;

export const BASE_NAVIGATOR_ML_API_URL = `${BASE_CAPI_URL}/ml/api`;

export const BASE_CAPI_ML_API_URL = `${APP_BASE_URL}/capi/ml/api`;

export const BASE_WORKBENCH_API_URL = `${APP_BASE_URL}/workbenchapi/api`;

export const AUTH_URL = `${APP_BASE_URL}/auth`;

export const WEBSITE_URL = `https://www.aspecscire.com`;

export const TERMS_AND_CONDITIONS_URL = `${WEBSITE_URL}/terms-and-conditions`;

export const PRIVACY_POLICY_URL = `${WEBSITE_URL}/privacy-policy-2`;

export const YOUTUBE_EMBED_URL = 'https://www.youtube.com/embed';

export const VIMEO_EMBED_URL = 'https://player.vimeo.com/video';

export const DOCUMENTATION_URL_LIST = {
  missions: `https://vimana.vimanalabs.com/docs/plan-manage-data-gathering-misions/create-update-a-mission-for-the-aoi`,
  terrainMaps: `https://vimana.vimanalabs.com/docs/generate-mapping-outputs/digital-terrain-maps-contour-maps-and-cut-and-fill-reports/digital-terrain-maps`,
  contourMaps: `https://vimana.vimanalabs.com/docs/generate-mapping-outputs/digital-terrain-maps-contour-maps-and-cut-and-fill-reports/contour-maps`,
  cutAndFillReports: `https://vimana.vimanalabs.com/docs/generate-mapping-outputs/digital-terrain-maps-contour-maps-and-cut-and-fill-reports/cut-and-fill-report`,
  videos: `https://vimana.vimanalabs.com/docs/documenting-site-aoi-videos`,
  volume: `https://vimana.vimanalabs.com/docs/view-and-download-results/measurements-tool/volume`,
  flightPlans: `https://vimana.vimanalabs.com/docs/plan-manage-data-gathering-misions/create-and-use-a-flight-plan-flight-planning`,
  processedDataUpload: `https://vimana.vimanalabs.com/docs/upload-processed-data`,
  inspectionImageUpload: `https://vimana.vimanalabs.com/docs/generate-non-mapping-outputs/inspection-view`,
  uploadDate: `https://vimana.vimanalabs.com/docs/plan-manage-data-gathering-misions/upload-data`,
  createProject: `https://vimana.vimanalabs.com/docs/creating-and-managing-a-project/create-project`,
  createAOI: `https://vimana.vimanalabs.com/docs/creating-and-managing-a-project/create-new-area-of-interest-aoi`,
  missionPurpose: `https://vimana.vimanalabs.com/docs/plan-manage-data-gathering-misions/specify-a-mission-purpose`,
  missionFlightPlanning: `https://vimana.vimanalabs.com/docs/plan-manage-data-gathering-misions/create-and-use-a-flight-plan-flight-planning`,
  missionSurveyPlanning: `https://vimana.vimanalabs.com/docs/plan-manage-data-gathering-misions/create-and-use-a-site-marking-surey-plan-survey-planning`,
  missionUpload: `https://vimana.vimanalabs.com/docs/plan-manage-data-gathering-misions/upload-data`,
  missionPublish: `https://vimana.vimanalabs.com/docs/plan-manage-data-gathering-misions/publish-views`,
  manageElevationDifference: `https://vimana.vimanalabs.com/docs/generate-mapping-outputs/elevation-difference`,
  issueTagging: `https://vimana.vimanalabs.com/docs/view-and-download-results/issues-annotations/issues-tagging`,
  manageDetect: `https://vimana.vimanalabs.com/docs/creating-and-managing-a-project/manage-detect`,
  viewElevationDifference: `https://vimana.vimanalabs.com/docs/view-and-download-results/split-view/elevation-difference`,
  interior360:
    'https://vimana.vimanalabs.com/docs/generate-non-mapping-outputs/interior-360',
  organisation: `https://vimana.vimanalabs.com/docs/manage-user-settings/manage-organisation`,
  drawingUpload: `https://vimana.vimanalabs.com/docs/generate-mapping-outputs/user-sandbox-cad-overlay-diy`,
};

export const MAPBOX_STYLE_PROTOCOL_URL = `mapbox://styles/`;

export const MAPBOX_API_BASE_URL = `https://api.mapbox.com`;

export const MAPBOX_API_URL = `https://api.mapbox.com/styles/v1/`;

export const MAPBOX_STYLE_SATELLITE_V9_URL = `${MAPBOX_STYLE_PROTOCOL_URL}mapbox/satellite-v9`;

export const MAPBOX_STYLE_JIBIN_1_URL = `${MAPBOX_STYLE_PROTOCOL_URL}jibingeo/cjpwjhcbd2a582rmze24ffmjj`;

export const MAPBOX_STYLE_JIBIN_2_URL = `${MAPBOX_STYLE_PROTOCOL_URL}jibingeo/cjmcft3486snd2rmqawswaa16`;
