// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DroneAnimation_container__qCBBZ{margin:auto}.DroneAnimation_drone__ZpEuZ{width:200px;-webkit-animation:DroneAnimation_bounce__BwNpA 2s infinite;animation:DroneAnimation_bounce__BwNpA 2s infinite}.DroneAnimation_text__GJebL{font-size:1.5em;font-weight:600}@-webkit-keyframes DroneAnimation_bounce__BwNpA{0%,100%{-webkit-transform:translateY(0);transform:translateY(0)}50%{-webkit-transform:translateY(-15px);transform:translateY(-15px)}}@keyframes DroneAnimation_bounce__BwNpA{0%,100%{-webkit-transform:translateY(0);transform:translateY(0)}50%{-webkit-transform:translateY(-15px);transform:translateY(-15px)}}", "",{"version":3,"sources":["webpack://./src/components/DroneAnimation/DroneAnimation.module.scss"],"names":[],"mappings":"AAEA,iCACE,WAAA,CAGF,6BACE,WAAA,CACA,0DAAA,CAAA,kDAAA,CAGF,4BACE,eAAA,CACA,eAAA,CAGF,gDACE,QAEE,+BAAA,CAAA,uBAAA,CAGF,IACE,mCAAA,CAAA,2BAAA,CAAA,CAPJ,wCACE,QAEE,+BAAA,CAAA,uBAAA,CAGF,IACE,mCAAA,CAAA,2BAAA,CAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.container {\n  margin: auto;\n}\n\n.drone {\n  width: 200px;\n  animation: bounce 2s infinite;\n}\n\n.text {\n  font-size: 1.5em;\n  font-weight: 600;\n}\n\n@keyframes bounce {\n  0%,\n  100% {\n    transform: translateY(0);\n  }\n\n  50% {\n    transform: translateY(-15px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DroneAnimation_container__qCBBZ",
	"drone": "DroneAnimation_drone__ZpEuZ",
	"bounce": "DroneAnimation_bounce__BwNpA",
	"text": "DroneAnimation_text__GJebL"
};
export default ___CSS_LOADER_EXPORT___;
