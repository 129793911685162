/* eslint-disable func-names, no-param-reassign */

import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import booleanContains from '@turf/boolean-contains';
import booleanOverlap from '@turf/boolean-overlap';
import POPUP from './Popup';
import { GenericObjectType } from '../../shapes/app';

const DrawHolesInPolygon: any = {} as any;

DrawHolesInPolygon.onSetup = function () {
  const polygon = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: Constants.geojsonTypes.POLYGON,
      coordinates: [[]],
    },
  });

  this.addFeature(polygon);

  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.activateUIButton(Constants.types.POLYGON);
  this.setActionableState({
    trash: true,
  });

  POPUP.setHTML('Click on map to select area');

  return {
    polygon,
    currentVertexPosition: 0,
  };
};

DrawHolesInPolygon.toPolygonFeature = function (
  ringCoordinates: number[][],
  properties: any = {}
) {
  const polygon = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: { ...properties },
    geometry: {
      type: Constants.geojsonTypes.POLYGON,
      coordinates: [ringCoordinates],
    },
  });

  return polygon;
};

DrawHolesInPolygon.checkPolygonContainsHole = function (
  polygon: any,
  hole: any
): boolean {
  if (!booleanContains(polygon, hole)) {
    return false;
  }

  //  ensure hole does not overlap with existing holes
  if (booleanOverlap(polygon, hole)) {
    return false;
  }

  // check if the existing holes are contained within the new hole
  const innerRingsContainedInHole = polygon.geometry.coordinates
    .slice(1)
    .map((ring: any) => {
      return this.toPolygonFeature(ring);
    })
    .map((ringPolygon: any) => {
      return booleanContains(hole, ringPolygon.toGeoJSON());
    });

  if (innerRingsContainedInHole.indexOf(true) > -1) {
    // there should be no inner ring contained in the hole
    return false;
  }

  return true;
};

DrawHolesInPolygon.addPolygonWithHole = function (state: any) {
  const allContent = this._ctx.api.getAll();
  const holeGeoJson = state.polygon.toGeoJSON();
  const innerRingCoordinates = holeGeoJson.geometry.coordinates[0];
  const featuresToUpdate: any[] = allContent.features
    .filter(
      (polygonFeature: any) =>
        polygonFeature.geometry.type === Constants.geojsonTypes.POLYGON &&
        this.checkPolygonContainsHole(polygonFeature, holeGeoJson) &&
        polygonFeature.id !== state.polygon.id
    )
    .map((feature: any) => {
      // adding polygon as inner ring & ensure it is a linear ring
      feature.geometry.coordinates.push([
        ...innerRingCoordinates,
        innerRingCoordinates[0],
      ]);

      return this.newFeature(feature);
    });

  if (state.polygon.isValid() && featuresToUpdate.length > 0) {
    this.deleteFeature(
      [
        state.polygon.id,
        ...featuresToUpdate.map((feature: GenericObjectType) => feature.id),
      ],
      { silent: true }
    );
    featuresToUpdate.forEach((feature: GenericObjectType) => {
      this.addFeature(feature, { silent: true });
    });

    // firing the update event, after polygon update
    this.map.fire(Constants.events.UPDATE, {
      action: Constants.updateActions.CHANGE_COORDINATES,
      features: featuresToUpdate.map((f: GenericObjectType) => f.toGeoJSON()),
    });

    return true;
  }

  // firing update with empty feature list, when polygon doesn't belong to a terrain area
  this.map.fire(Constants.events.UPDATE, {
    action: Constants.updateActions.CHANGE_COORDINATES,
    features: [],
  });

  return false;
};

DrawHolesInPolygon.clickAnywhere = function (state: any, e: any) {
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    e.lngLat.lng,
    e.lngLat.lat
  );
  state.currentVertexPosition += 1;
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    e.lngLat.lng,
    e.lngLat.lat
  );
  if (state.currentVertexPosition > 0) {
    POPUP.setHTML('Double click to finish the selection(min 3 point)');
  }
};

DrawHolesInPolygon.clickOnVertex = function (state: any) {
  return this.changeMode('draw_holes', {
    featureIds: [state.polygon.id],
  });
};

DrawHolesInPolygon.onMouseMove = function (state: any, e: any) {
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    e.lngLat.lng,
    e.lngLat.lat
  );
  if (CommonSelectors.isVertex(e)) {
    this.updateUIClasses({ mouse: Constants.cursors.POINTER });
  }

  if (!POPUP.isOpen()) {
    POPUP.addTo(this.map);
  }

  POPUP.setLngLat(e.lngLat);
};

DrawHolesInPolygon.onTap = DrawHolesInPolygon.onClick = function (
  state: any,
  e: any
) {
  if (CommonSelectors.isVertex(e)) return this.clickOnVertex(state, e);

  return this.clickAnywhere(state, e);
};

DrawHolesInPolygon.onKeyUp = function (state: any, e: any) {
  if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.polygon.id], { silent: true });
    this.changeMode('draw_holes');
  }
};

DrawHolesInPolygon.onStop = function (state: any) {
  this.updateUIClasses({ mouse: Constants.cursors.NONE });
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.polygon.id) === undefined) {
    return;
  }

  // remove last added coordinate
  state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`);

  if (!state.polygon.isValid() || !this.addPolygonWithHole(state)) {
    this.deleteFeature([state.polygon.id], { silent: true });
  }

  POPUP.remove();
};

DrawHolesInPolygon.toDisplayFeatures = function (
  state: any,
  geojson: any,
  display: any
) {
  const isActivePolygon = geojson.properties.id === state.polygon.id;

  geojson.properties.active = isActivePolygon
    ? Constants.activeStates.ACTIVE
    : Constants.activeStates.INACTIVE;
  if (!isActivePolygon) {
    return display(geojson);
  }

  // Don't render a polygon until it has two positions
  // (and a 3rd which is just the first repeated)
  if (geojson.geometry.coordinates.length === 0) {
    return;
  }

  const coordinateCount = geojson.geometry.coordinates[0].length;

  // 2 coordinates after selecting a draw type
  // 3 after creating the first point
  if (coordinateCount < 3) {
    return;
  }

  geojson.properties.meta = Constants.meta.FEATURE;
  display(
    createVertex(
      state.polygon.id,
      geojson.geometry.coordinates[0][0],
      '0.0',
      false
    )
  );
  if (coordinateCount > 3) {
    // Add a start position marker to the map, clicking on this will finish the feature
    // This should only be shown when we're in a valid spot
    const endPos = geojson.geometry.coordinates[0].length - 3;

    display(
      createVertex(
        state.polygon.id,
        geojson.geometry.coordinates[0][endPos],
        `0.${endPos}`,
        false
      )
    );
  }

  if (coordinateCount <= 4) {
    // If we've only drawn two positions (plus the closer),
    // make a LineString instead of a Polygon
    const lineCoordinates = [
      [
        geojson.geometry.coordinates[0][0][0],
        geojson.geometry.coordinates[0][0][1],
      ],
      [
        geojson.geometry.coordinates[0][1][0],
        geojson.geometry.coordinates[0][1][1],
      ],
    ];

    // create an initial vertex so that we can track the first point on mobile devices
    display({
      type: Constants.geojsonTypes.FEATURE,
      properties: geojson.properties,
      geometry: {
        coordinates: lineCoordinates,
        type: Constants.geojsonTypes.LINE_STRING,
      },
    });
    if (coordinateCount === 3) {
      return;
    }
  }

  // render the Polygon
  return display(geojson);
};

DrawHolesInPolygon.onTrash = function (state: any) {
  this.deleteFeature([state.polygon.id], { silent: true });
  this.changeMode('simple_select');
};

DrawHolesInPolygon.onMouseOut = () => {
  POPUP.remove();
};

export default DrawHolesInPolygon;
