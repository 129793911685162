import * as React from 'react';
import IssueList from './IssueList';
import {
  IssueDataManagerProps,
  IssueQueryParams,
  IssuesEvent,
} from './index.types';
import SkeletonLoader from '../SkeletonLoader';
import { IssuesPageConfig } from '../../api/issue.types';
import ManageTagsModal from './ManageTagsModal';
import ReportPreviewModal from './ReportPreviewModal';

export default class IssueDataManagerV2 extends React.PureComponent<IssueDataManagerProps> {
  public componentDidMount() {
    const {
      pageConfig: _pageConfig,
      projectId,
      aoiId,
      issueId,
      history,
      fetchTagsForProject,
      fetchProjectUsers,
    } = this.props;
    const params = this.getIssueQueryParams();
    const { showResolved, page, tagId } = params;
    // Ensure projectId, aoiId are present in Page Config
    const pageConfig = _pageConfig
      ? { ..._pageConfig, projectId, aoiId }
      : { projectId, aoiId, showResolved, page, tagId };
    const url = this.getUrlFromProps({ ...this.props, pageConfig }, issueId);

    // update URL based on config
    history.replace(url);

    this.initDataWithPageConfig(pageConfig);
    fetchTagsForProject(projectId);
    fetchProjectUsers(projectId);
  }

  public UNSAFE_componentWillReceiveProps({
    issues,
    issueId,
    loading,
    fetchSingleIssue,
  }: IssueDataManagerProps) {
    const { loading: oldLoading } = this.props;

    if (oldLoading.issues && !loading.issues && issueId) {
      // fetch an issue by id, if not present according to filter criteria
      const issue = issues.find((s) => s.id === issueId);

      if (!issue) {
        fetchSingleIssue(issueId);
      }
    }
  }

  public initDataWithPageConfig = (pageConfig: IssuesPageConfig) => {
    const { fetchIssues, fetchIssuesStatus, setPageConfig } = this.props;

    setPageConfig(pageConfig);
    fetchIssues(pageConfig);
    fetchIssuesStatus(pageConfig);
  };

  private getIssueQueryParams(): IssueQueryParams {
    const { queryParams } = this.props;

    if (!queryParams) return {};

    const { showResolved, page, commentPage, reload, tagId } = queryParams;

    return {
      showResolved: showResolved?.toLowerCase() === 'true',
      page: (page && Number.parseInt(page, 10)) || undefined,
      commentPage:
        (commentPage && Number.parseInt(commentPage, 10)) || undefined,
      reload: reload?.toLowerCase() === 'true',
      tagId,
    };
  }

  private getUrlFromProps = (
    props: IssueDataManagerProps,
    issueId?: string
  ) => {
    const { pageConfig, commentPage, projectId, aoiId } = props;
    let query = { commentPage };
    let url = `/project/${projectId}`;

    url += aoiId ? `/aoi/${aoiId}/issues` : '/issues';

    if (pageConfig) {
      // Remove projectId, aoiId from pageConfig, before adding to query
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { projectId, aoiId, ...rest } = pageConfig;

      query = { ...query, ...rest };
    }

    const params = Object.entries(query)
      .filter(([_key, value]) => value !== undefined)
      .map(([key, value]) => [key, `${value}`]);
    const queryParams = new URLSearchParams(params);

    url += issueId ? `/${issueId}?` : '?';
    url += queryParams.toString();

    return url;
  };

  public handleEvent = (e: IssuesEvent<any>) => {
    const {
      projectId,
      history,
      pageConfig,
      selectIssue,
      clearSelectedIssue,
      resetProcessState,
      createComment,
      fetchComments,
      resolveIssue,
      updateIssueTags,
      createTag,
      deleteTags,
      clearTagStatus,
    } = this.props;

    switch (e.type) {
      case 'change_issue': {
        selectIssue(e.data);
        const url = this.getUrlFromProps(this.props, e.data.id);

        history.replace(url);
        break;
      }

      case 'clear_issue': {
        clearSelectedIssue();
        const url = this.getUrlFromProps(this.props);

        history.replace(url);
        break;
      }

      case 'clear_process_state': {
        resetProcessState();

        break;
      }

      case 'change_filter': {
        // resetting page, when filter is updated
        const newPageConfig = { ...e.data, page: undefined };
        const url = this.getUrlFromProps({
          ...this.props,
          pageConfig: newPageConfig,
        });

        this.initDataWithPageConfig(newPageConfig);
        clearSelectedIssue();
        history.replace(url);
        break;
      }

      case 'change_page': {
        const newPageConfig = {
          ...pageConfig,
          page: e.data,
        } as IssuesPageConfig;

        this.initDataWithPageConfig(newPageConfig);
        const url = this.getUrlFromProps({
          ...this.props,
          pageConfig: newPageConfig,
        });

        clearSelectedIssue();
        history.replace(url);
        break;
      }

      case 'create_issue_comment': {
        const { issue, comment } = e.data;
        const url = this.getUrlFromProps(this.props, issue.id);

        selectIssue(issue);
        createComment(issue.id, comment);
        history.replace(url);
        break;
      }

      case 'change_comment_page': {
        const { issue, page } = e.data;
        const url = this.getUrlFromProps(
          { ...this.props, commentPage: page },
          issue.id
        );

        fetchComments(issue.id, page);
        history.replace(url);
        break;
      }

      case 'resolve_issue': {
        const issue = e.data;

        resolveIssue(issue.id);
        break;
      }

      case 'update_issue_tags': {
        const { issue, tagIds } = e.data;

        updateIssueTags(issue.id, tagIds);
        break;
      }

      case 'create_tag': {
        const tag = e.data;

        createTag(projectId, tag);
        break;
      }

      case 'delete_tags': {
        const tagIds = e.data;

        deleteTags(projectId, tagIds);
        break;
      }

      case 'clear_tags_status': {
        clearTagStatus();
        break;
      }

      default:
        break;
    }
  };

  public renderIssueList() {
    const { project, issues, singleIssue, pageConfig, ...rest } = this.props;
    const queryParams = this.getIssueQueryParams();

    if (project && pageConfig) {
      return (
        <IssueList
          {...rest}
          issues={singleIssue ? [singleIssue, ...issues] : issues}
          queryParams={queryParams}
          project={project}
          pageConfig={pageConfig}
          onEvent={this.handleEvent}
        />
      );
    }

    return <SkeletonLoader />;
  }

  public renderModals() {
    const {
      showModals,
      closeManageTagsModal,
      tags,
      pageConfig,
      closeReportPreviewModal,
      ...rest
    } = this.props;

    if (showModals) {
      return (
        <>
          {showModals?.manageTags ? (
            <ManageTagsModal
              isModalVisible={showModals?.manageTags}
              tags={tags}
              cancelModal={closeManageTagsModal}
              onEvent={this.handleEvent}
              {...rest}
            />
          ) : (
            <></>
          )}
          {pageConfig ? (
            <ReportPreviewModal
              pageConfig={pageConfig}
              closeReportPreviewModal={closeReportPreviewModal}
              showModal={!!showModals?.reportPreview}
            />
          ) : (
            <></>
          )}
        </>
      );
    }

    return <></>;
  }

  public render() {
    return (
      <>
        {this.renderIssueList()}
        {this.renderModals()}
      </>
    );
  }
}
