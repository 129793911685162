// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Password_textInput__Zq4Jz{background-color:unset !important}.Password_textInput__Zq4Jz input{background-color:unset !important}.Password_textInput__Zq4Jz input:focus{border:none !important}", "",{"version":3,"sources":["webpack://./src/components/Antd/Password.module.scss"],"names":[],"mappings":"AAAA,2BACE,iCAAA,CAEA,iCACE,iCAAA,CAGF,uCACE,sBAAA","sourcesContent":[".textInput {\n  background-color: unset !important;\n\n  input {\n    background-color: unset !important;\n  }\n\n  input:focus {\n    border: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textInput": "Password_textInput__Zq4Jz"
};
export default ___CSS_LOADER_EXPORT___;
