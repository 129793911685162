import axios from 'axios';
import MissionsAPIs from '../../api/mission';
import {
  CREATE_MISSION,
  GET_DRONES,
  GET_EPSG_BY_ID,
  GET_FLIGHT_PLAN_IMAGES,
  GET_GCP_PLAN_DATA,
  GET_GCP_PLANS,
  GET_INITIAL_FLIGHT_PLAN,
  GET_MISSION,
  RESET_MISSION_STATE,
  SEARCH_EPSG_BY_TEXT,
  SUBMIT_MISSION,
  UPDATE_FLIGHT_PLAN,
  UPDATE_GCP_PLAN,
  UPDATE_GCP_POINTS_DATA,
} from '../actions';
import { APP_BASE_URL } from '../../constants/urls';

const api = new MissionsAPIs();

export function createMission(projectId: string, aoiId: string, body: any): {} {
  return {
    type: CREATE_MISSION,
    payload: api.createMission(projectId, aoiId, body),
  };
}

export function submitMission(
  projectId: string,
  aoiId: string,
  missionId: string,
  body: any
): {} {
  return {
    type: SUBMIT_MISSION,
    payload: api.submitMission(projectId, aoiId, missionId, body),
  };
}

export function getMission(
  projectId: string,
  aoiId: string,
  missionId: string
): {} {
  return {
    type: GET_MISSION,
    payload: api.getMission(projectId, aoiId, missionId),
  };
}

export function getDrones(): {} {
  return {
    type: GET_DRONES,
    payload: api.getDrones(),
  };
}

export function getEPSGcodeById(id: any): {} {
  return {
    type: GET_EPSG_BY_ID,
    payload: api.getEPSGcodeById(id),
  };
}

export function searchEPSGcodeByText(searchText: string): {} {
  return {
    type: SEARCH_EPSG_BY_TEXT,
    payload: api.searchEPSGcodeByText(searchText),
  };
}

export function getFlightPlans(
  projectId: string,
  aoiId: string,
  missionId: string
): {} {
  return {
    type: GET_INITIAL_FLIGHT_PLAN,
    payload: api.getFlightPlans(projectId, aoiId, missionId),
  };
}

export function updateFlightPlan(plan: any): {} {
  return {
    type: UPDATE_FLIGHT_PLAN,
    payload: plan,
  };
}

export function getGcpPlans(
  projectId: string,
  aoiId: string,
  missionId: string
): {} {
  return {
    type: GET_GCP_PLANS,
    payload: api.getGcpPlans(projectId, aoiId, missionId),
  };
}

export function updateGcpPlan(plan: any): {} {
  return {
    type: UPDATE_GCP_PLAN,
    payload: plan,
  };
}

function getGcpPlanDataApi(planId: string): Promise<any> {
  return axios.get(`${APP_BASE_URL}/capi/api/v1/gcp_plan_data/${planId}`, {
    withCredentials: true,
  });
}

export function getGcpPlanData(planId: string): {} {
  return {
    type: GET_GCP_PLAN_DATA,
    payload: getGcpPlanDataApi(planId),
  };
}

export function updateGcpPointsData(newGcpPointsData: any): {} {
  return {
    type: UPDATE_GCP_POINTS_DATA,
    payload: newGcpPointsData,
  };
}

export function getFlightPlanImages(
  projectId: string,
  aoiId: string,
  missionId: string,
  planId: string
): {} {
  return {
    type: GET_FLIGHT_PLAN_IMAGES,
    payload: api.getFlightPlanImages(projectId, aoiId, missionId, planId),
  };
}

export function resetReducerState(): {} {
  return {
    type: RESET_MISSION_STATE,
  };
}
