// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectIssueTagsModal_selectedTags__S\\+8ES label:first-child{margin-left:.6em}.SelectIssueTagsModal_titleWrapper__EagH9{display:flex}", "",{"version":3,"sources":["webpack://./src/components/SelectIssueTagsModal/index.module.scss","webpack://./src/styles/scss/base/mixins/_display_flex.scss"],"names":[],"mappings":"AAGE,6DACE,gBAAA,CAIJ,0CCHE,YAAA","sourcesContent":["@import '../../styles/scss/base/base';\n\n.selectedTags {\n  label:first-child {\n    margin-left: 0.6em;\n  }\n}\n\n.titleWrapper {\n  @include display_flex();\n}\n","@mixin display_flex() {\n  display: -webkit-box;\n  display: -moz-box;\n  display: -ms-flexbox;\n  display: -webkit-flex;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedTags": "SelectIssueTagsModal_selectedTags__S+8ES",
	"titleWrapper": "SelectIssueTagsModal_titleWrapper__EagH9"
};
export default ___CSS_LOADER_EXPORT___;
