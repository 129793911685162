/* eslint-disable max-classes-per-file */
import { Step } from 'react-joyride';

import { DesignDrawing } from '../../api/drawing.types';
import { View } from '../../api/views.types';
import { SnackbarActionsActionShowSnackbarTypes } from '../../shapes/snackbar';
import { RouterParamsTypes } from '../../shapes/routerParams';

export type UserSandboxPropsType = UserSandboxTypes.OwnProps &
  UserSandboxTypes.DispatchProps &
  UserSandboxTypes.ReduxState;

export namespace UserSandboxTypes {
  export interface OwnProps extends Pick<RouterParamsTypes, 'history'> {
    projectId: string;
    aoiId: string;
    closeSandbox?: () => void;
  }

  export type ReduxState = {};

  export interface DispatchProps {
    showSnackbar?: SnackbarActionsActionShowSnackbarTypes;
    showCoachMarks?: (steps: Step[]) => void;
    resetCoachMarks?: () => void;
  }
}

export interface UserSandboxStateType {
  drawings: DesignDrawing[];
  views: View[];
}

export class SandboxEvent<T> {
  type: SandboxEventType;

  data: T;
}

export class DrawingCreatedEvent extends SandboxEvent<DesignDrawing> {
  constructor(drawing: DesignDrawing) {
    super();

    this.type = 'drawing_created';
    this.data = drawing;
  }
}

export class DrawingEditedEvent extends SandboxEvent<DesignDrawing> {
  constructor(drawing: DesignDrawing) {
    super();

    this.type = 'drawing_edited';
    this.data = drawing;
  }
}

export type SandboxEventType =
  | 'drawing_created' // on a drawing being created
  | 'drawing_edited'; // on a drawing being edited
