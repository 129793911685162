import * as React from 'react';
import { Redirect } from 'react-router';
import styles from './index.module.scss';
import DroneAnimation from '../DroneAnimation/DroneAnimation';
import SkeletonLoader from '../SkeletonLoader';
import { viewUrl } from '../../routes/urls';
import { AoiProps, AoiState } from './index.types';
import ViewAPIs from '../../api/views';
import { getDefaultView } from '../../redux/reducers/views';

const viewApis = new ViewAPIs();

class Aoi extends React.Component<AoiProps, AoiState> {
  constructor(props: AoiProps) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  public componentDidMount(): void {
    this.fetchDefaultView();
  }

  private fetchDefaultView = () => {
    const { match } = this.props;
    const { aoiId, projectId } = match.params;

    viewApis.getViews(projectId, aoiId).then((res) => {
      const defaultView = getDefaultView(res.data || []);

      if (defaultView && defaultView.length > 0) {
        this.setState({ defaultViewId: defaultView });
      } else {
        this.setState({ loaded: true });
      }
    });
  };

  public render(): React.ReactNode | null {
    const { match } = this.props;
    const { projectId, aoiId } = match.params;
    const { defaultViewId, loaded } = this.state;

    if (defaultViewId && !loaded) {
      return <Redirect to={viewUrl(projectId, aoiId, defaultViewId)} />;
    }

    if (loaded) {
      return (
        <DroneAnimation
          className="center"
          projectId={projectId}
          aoiId={aoiId}
        />
      );
    }

    return (
      <SkeletonLoader
        size={3}
        position="absolute"
        className={styles.skeletonLoading}
      />
    );
  }
}

export default Aoi;
