import ApiBase from './ApiBase';
import { GenericApisReturnPromiseTypes } from '../shapes/app';
import { processErrorHandling } from '../utils/helpers';
import { BASE_WORKBENCH_API_URL } from '../constants/urls';

export default class WorkbenchApis extends ApiBase {
  private readonly _baseUrl: string;

  public constructor() {
    super(BASE_WORKBENCH_API_URL);

    this._baseUrl = `/v1`;
  }

  public getSessionsForMission(
    missionId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/sessions/mission/${missionId}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to fetch sessions for a mission`,
        });
      });
  }

  public createSessionForFlightPlan(
    planId: string,
    type: string | undefined
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/flightplans/${planId}/processingSessions${
        type ? `?session_type_hint=${type}` : ''
      }`,
      null
    )
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to create processing session`,
        });
      });
  }

  public getSession(sessionId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/sessions/${sessionId}`)
      .then((res) => {
        return {
          error: null,
          data: res.data,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to get processing session`,
        });
      });
  }

  public runSession(sessionId: string): GenericApisReturnPromiseTypes {
    return this.post(`${this._baseUrl}/sessions/${sessionId}/run/auto`, null)
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to run processing session`,
        });
      });
  }

  public killSession(sessionId: string): GenericApisReturnPromiseTypes {
    return this.post(`${this._baseUrl}/sessions/${sessionId}/kill`, null)
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to kill processing session`,
        });
      });
  }

  public continueSession(
    sessionId: string,
    stepId: string
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/sessions/${sessionId}/continue?stepId=${stepId}`,
      null
    )
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to continue processing session`,
        });
      });
  }

  public forkSession(
    sessionId: string,
    stepId: string
  ): GenericApisReturnPromiseTypes {
    return this.post(
      `${this._baseUrl}/sessions/${sessionId}/fork?stepId=${stepId}`,
      null
    )
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to continue processing session`,
        });
      });
  }

  public getStepLogs(
    sessionId: string,
    stepId: string
  ): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/sessions/${sessionId}/logs/${stepId}`)
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to get step logs`,
        });
      });
  }

  public getSessionConfig(sessionId: string): GenericApisReturnPromiseTypes {
    return this.get(`${this._baseUrl}/sessions/${sessionId}/config`)
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to get session configuration`,
        });
      });
  }

  public patchSessionConfig(
    sessionId: string,
    req: any
  ): GenericApisReturnPromiseTypes {
    return this.patch(`${this._baseUrl}/sessions/${sessionId}/config`, req)
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to get session configuration`,
        });
      });
  }

  public getSessionArtifactContents(
    sessionId: string,
    docType: string
  ): GenericApisReturnPromiseTypes {
    return this.get(
      `${this._baseUrl}/sessions/${sessionId}/artifacts/${docType}/contents`
    )
      .then((res) => {
        return {
          error: null,
          data: res,
        };
      })
      .catch((error) => {
        return processErrorHandling({
          error,
          errorText: `There was an unexpected error when trying to get session configuration`,
        });
      });
  }
}
