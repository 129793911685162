import autobind from 'autobind-decorator';
import * as React from 'react';
import { Tooltip } from 'antd';
import DownloadAPI from '../../api/download';
import { humanFileSize, undefinedOrNull } from '../../utils/functs';
import * as ControlBox from '../ControlBox/ControlBox';

import { Spinner } from '../Spinner/Spinner';
import style from './DownloadControlBox.module.scss';
import { ViewControlsV2TypeTypes } from '../ViewControlsV2/index.types';

const downloadAPI = new DownloadAPI();

const DOWNLOAD_NAME_MAPPING = {
  DEM_COLOR_GLOBAL_TIF: 'Colorized Elevation Profile',
  ORTHOMOSAIC_GLOBAL_TIF: 'Orthomosaic',
  DEM_RAW_GLOBAL_TIF: 'Elevation Profile',
};

interface IProps {
  itemType?: ViewControlsV2TypeTypes;
  onClose?: (itemType: ViewControlsV2TypeTypes) => void;
  downloads: any[];
  viewId: string;
  userRole: string;
}

interface IState {
  itemLoading: boolean;
}

interface IDownloadItem {
  id: string;
  name: string;
  size: number;
  remainingDownloads: number;
  type: string;
  viewId: string;
}

class DownloadControlBox extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      itemLoading: false,
    };
  }

  @autobind
  public renderHeader(): JSX.Element {
    const { onClose, itemType } = this.props;

    return (
      <React.Fragment>
        <ControlBox.Title title="Download" />
        <ControlBox.Icon
          onClick={() => {
            if (!onClose || undefinedOrNull(itemType)) {
              return;
            }

            onClose(itemType);
          }}
          name="close"
        />
      </React.Fragment>
    );
  }

  protected ReportItem = ({ id, name, size, viewId, type }: IDownloadItem) => {
    const { userRole } = this.props;
    const finalData = (
      <div
        onClick={() => {
          // eslint-disable-next-line no-unused-expressions
          userRole !== 'read_only' ? this.downloadURL(viewId, id, type) : null;
        }}
        className={style.report_item}
      >
        <div>
          <i className="fa fa-download" />
        </div>
        <div className={style.meta_info}>
          <div>{DOWNLOAD_NAME_MAPPING[type] || name}</div>
          <div>
            <span>{`Size: ${humanFileSize(size)}`}</span>
          </div>
        </div>
      </div>
    );

    if (userRole === 'read_only') {
      return (
        <Tooltip
          placement="right"
          title="The use of this feature requires change in the user role . Please contact project admin if you need that."
        >
          {finalData}
        </Tooltip>
      );
    }

    return finalData;
  };

  protected downloadURL = (
    viewId: string,
    id: string,
    documentType: string
  ) => {
    this.setLoadingItem(true);

    downloadAPI.getURL(viewId, id, documentType).then((urlData) => {
      this.setLoadingItem(false);
      if (undefinedOrNull(urlData)) {
        return;
      }

      window.location.href = urlData;
    });
  };

  protected setLoadingItem = (value: boolean): void => {
    this.setState({
      itemLoading: value,
    });
  };

  public render(): React.ReactNode {
    const { downloads, viewId } = this.props;
    const { itemLoading } = this.state;

    return (
      <ControlBox.Wrapper
        className={style.container}
        renderHeader={this.renderHeader}
      >
        {itemLoading && (
          <div className={style.spinner}>
            <div className={style.spinnerInnerWrapper}>
              <Spinner text="Downloading file..." />
            </div>
          </div>
        )}

        {downloads.map((download) => (
          <this.ReportItem
            key={download.id}
            id={download.id}
            viewId={viewId}
            type={download.documentType}
            remainingDownloads={download.remainingDownloads}
            size={download.sizeBytes}
            name={download.name || download.fileName}
          />
        ))}
      </ControlBox.Wrapper>
    );
  }
}

export default DownloadControlBox;
