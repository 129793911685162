import * as React from 'react';
import { Table, Typography } from 'antd';
import { Redirect } from 'react-router-dom';
import ModalNotification from '../../ModalNotification/ModalNotification';
import styles from '../index.module.scss';
import { AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE } from '../../../constants';
import { APP_BASE_URL } from '../../../constants/urls';
import { GenericObjectType } from '../../../shapes/app';
import DiagnosticOpApis from '../../../api/diagnostics';
import WorkbenchApis from '../../../api/workbench';
import { hasManageAccess } from '../../../utils/functs';
import {
  DiagnosticOperationsState,
  DiagnosticsOperationsProps,
} from './index.types';

const { Title } = Typography;
const diagnosticOpApis = new DiagnosticOpApis();
const workbenchApis = new WorkbenchApis();

export default class DiagnosticOperations extends React.Component<
  DiagnosticsOperationsProps,
  DiagnosticOperationsState
> {
  public constructor(props: DiagnosticsOperationsProps) {
    super(props);

    this.state = {
      redirect: false,
      missionArtifactData: [],
      missionSessionData: [],
    };
  }

  public componentDidMount(): void {
    this.getMissionArtifacts().then((data) => {
      this.setState({ missionArtifactData: data });
    });
  }

  private handleModalClose = () => {
    this.setState({
      redirect: true,
    });
  };

  private handleMarkingRowClick = (sessionId: string) => {
    const { match } = this.props;
    const { aoiId, projectId } = match.params;
    const url = `${APP_BASE_URL}/project/${projectId}/aoi/${aoiId}/gcpmarking/session/${sessionId}`;
    const win = window.open(url, '_blank');

    if (win) {
      win.focus();
    }
  };

  private handleReportClick = async (docGuid: string) => {
    const win = window.open('', '_blank');
    const url = await this.getArtifactLink(docGuid);

    if (win && url) {
      win.location.href = url;
      win.focus();
    }
  };

  private getMissionArtifacts = async (): Promise<GenericObjectType[]> => {
    const { match } = this.props;
    const { missionId } = match.params;
    const missionSessions: GenericObjectType[] = [];

    const res = await workbenchApis.getSessionsForMission(missionId);

    const missionSessionData: GenericObjectType[] = [];

    for (let i = 0; i < res.data.length; i += 1) {
      const sessionWithAlignStep = res.data[i].steps.filter(
        (eachStep: GenericObjectType) => {
          return eachStep.type === 'align_photos';
        }
      );

      if (sessionWithAlignStep.length >= 1) {
        missionSessionData.push({
          id: res.data[i].sessionId,
          name: res.data[i].name,
          key: res.data[i].sessionId,
          forkedFrom: res.data[i].forkedFrom || '-',
        });
      }
    }

    this.setState({ missionSessionData });

    const results = [];

    for (let i = 0; i < res.data.length; i += 1) {
      results.push(
        this.getSessionArtifacts(res.data[i].sessionId, res.data[i].name)
      );
    }

    const sessionArtifacts = await Promise.all(results);

    sessionArtifacts.forEach((eachArtifactSet) => {
      missionSessions.push(...eachArtifactSet);
    });

    return missionSessions;
  };

  private getSessionArtifacts = (
    sessionId: string,
    sessionName: string
  ): Promise<GenericObjectType[]> => {
    const sessionArtifacts: GenericObjectType[] = [];

    return new Promise<GenericObjectType[]>((resolve) => {
      diagnosticOpApis
        .getOutputArtifactForSession(sessionId)
        .then(async (res) => {
          for (let i = 0; i < res.data.files.length; i += 1) {
            sessionArtifacts.push({
              docGuid: res.data.files[i].guid,
              docType: res.data.files[i].document_type,
              sessionName,
              sessionId,
              key: sessionId,
            });
          }

          resolve(sessionArtifacts);
        })
        .catch(() => {
          resolve([]);
        });
    });
  };

  private getArtifactLink = (guid: string): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      diagnosticOpApis
        .getArtifactLink(guid)
        .then((res) => {
          resolve(res.data.url);
        })
        .catch(() => {
          reject();
        });
    });
  };

  public render(): React.ReactNode {
    const { match, loading, error, user } = this.props;
    const { redirect, missionArtifactData, missionSessionData } = this.state;

    const { projectId } = match.params;

    if (!hasManageAccess(user, projectId)) {
      return <Redirect to={`/project/${projectId}`} />;
    }

    const reportTableColumns = [
      {
        title: 'Report Name',
        dataIndex: 'docType',
        key: 'docType',
        render: (name: string) => name,
      },
      {
        title: 'Session Name',
        dataIndex: 'sessionName',
        key: 'sessionName',
        render: (name: string) => name,
      },
      {
        title: 'Session Id',
        dataIndex: 'sessionId',
        key: 'sessionId',
        render: (name: string) => name,
      },
    ];

    const markingLinkTableColumns = [
      {
        title: 'Session Name',
        dataIndex: 'name',
        key: 'name',
        render: (name: string) => name,
      },
      {
        title: 'Session Id',
        dataIndex: 'id',
        key: 'id',
        render: (name: string) => name,
      },
      {
        title: 'Parent Session Id',
        dataIndex: 'forkedFrom',
        key: 'forkedFrom',
        render: (name: string) => name,
      },
    ];

    if (error) {
      return (
        <ModalNotification
          notificationTitle="Error"
          shownotificationModal
          handleModalClose={this.handleModalClose}
          error={error}
        />
      );
    }

    if (redirect) {
      return <Redirect to={`/project/${projectId}`} />;
    }

    return (
      <div>
        <div className={styles.reportLinkTableContainer}>
          <div className={styles.header}>
            <div>
              <Title level={2}>Photogrammetry: Processing Reports</Title>
            </div>
          </div>
          <Table
            locale={{ emptyText: 'No photoscan reports' }}
            onRow={(record: GenericObjectType) => {
              return {
                onClick: () => {
                  this.handleReportClick(record.docGuid);
                },
              };
            }}
            rowKey={(record: any) => record.doc_guid}
            columns={reportTableColumns}
            dataSource={missionArtifactData}
            loading={loading}
            pagination={{
              defaultPageSize: AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE,
            }}
          />
        </div>
        <div className={styles.reportLinkTableContainer}>
          <div className={styles.header}>
            <div className={styles.titleWrapper}>
              <div className={styles.titleInnerWrapper}>
                <Title level={2}>Photogrammetry: GCP Marking Page Links</Title>
              </div>
            </div>
          </div>
          <Table
            locale={{ emptyText: 'No marking links' }}
            onRow={(record: GenericObjectType) => {
              return {
                onClick: () => {
                  this.handleMarkingRowClick(record.id);
                },
              };
            }}
            rowKey={(record: any) => record.id}
            columns={markingLinkTableColumns}
            dataSource={missionSessionData}
            loading={loading}
            pagination={{
              defaultPageSize: AOI_LIST_PAGINATION_DEFAULT_PAGE_SIZE,
            }}
          />
        </div>
      </div>
    );
  }
}
