import * as React from 'react';
import { Prompt } from 'react-router';
import classnames from 'classnames';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Typography, Select } from 'antd';

import ManualUploadApis from '../../../../api/manualUploads';
import { resetBodyOverFlow, setBodyOverflow } from '../../../../utils/window';
import { Button } from '../../../Button';
import { MapGeneratedStatus } from '../../../MapGeneratedStatus';
import ViewV2 from '../../../ViewV2/container';
import styles from './index.module.scss';
import {
  ManualUpload,
  CoordinateUnits,
} from '../../../../shapes/manualUploads';
import {
  PointCloudUploadPropsType,
  PointCloudUploadStateType,
  PopupTypes,
  ContentType,
} from './index.types';

const manualUploadApis = new ManualUploadApis();

const { Text } = Typography;
const { Option } = Select;
const MAX_REFRESH_ERROR_RETRIES = 5;
const AUTO_REFRESH_INTERVAL_MS = 5000;

class PointCloudUploadCrud extends React.Component<
  PointCloudUploadPropsType,
  PointCloudUploadStateType
> {
  private pointCloudFileUploadRef = React.createRef<HTMLInputElement>();

  private intervalTimer: any = null;

  private errorCount: number = 0;

  public constructor(props: PointCloudUploadPropsType) {
    super(props);

    this.state = {
      popupOpen: {
        selectPointCloud: false,
        confirmProcess: false,
        uploadingPointCloud: false,
        publish: false,
        unpublish: false,
        uploadImages: false,
      },
      pointCloudFile: null,
      loading: false,
      isMapFullScreen: false,
    };
  }

  public componentDidMount() {
    const { uploadData } = this.props;

    // start auto-refresh on component load
    if (uploadData) {
      this.fetchViewsAssociatedWithUpload(uploadData);

      if (
        uploadData.status === 'started' ||
        uploadData.status === 'pending' ||
        uploadData.status === 'starting'
      ) {
        if (this.intervalTimer === null) {
          this.intervalTimer = setInterval(
            () => this.fetchLatestUploadData(uploadData.id),
            AUTO_REFRESH_INTERVAL_MS
          );
        }
      }
    }
  }

  public UNSAFE_componentWillReceiveProps(newProps: PointCloudUploadPropsType) {
    const { uploadData: newUploadData } = newProps;
    const { uploadData: oldUploadData } = this.props;

    // start auto-refresh when processing starts
    if (newUploadData) {
      // fetching flightplan data when uploadData obtained for the first time
      if (!oldUploadData) {
        this.fetchViewsAssociatedWithUpload(newUploadData);
      } else if (
        newUploadData.generatedViews &&
        newUploadData.generatedViews.length > 0 &&
        (!oldUploadData.generatedViews ||
          (oldUploadData.generatedViews &&
            oldUploadData.generatedViews.length === 0))
      ) {
        this.fetchViewsAssociatedWithUpload(newUploadData);
      }

      if (
        newUploadData.status === 'started' ||
        newUploadData.status === 'pending' ||
        newUploadData.status === 'starting'
      ) {
        if (this.intervalTimer === null) {
          this.intervalTimer = setInterval(
            () => this.fetchLatestUploadData(newUploadData.id),
            AUTO_REFRESH_INTERVAL_MS
          );
        }
      }

      if (newUploadData.status === 'completed') {
        if (this.intervalTimer !== null) {
          clearInterval(this.intervalTimer);
        }
      }
    }
  }

  public componentDidUpdate() {
    const { loading } = this.state;

    if (loading) {
      // preventing navigation away from the page while APIs are pending
      window.onbeforeunload = () => {
        return 'Are you sure you want to navigate away?';
      };
    } else {
      // allowing navigation away from the page
      window.onbeforeunload = () => {
        return null;
      };
    }
  }

  public componentWillUnmount() {
    if (this.intervalTimer) {
      // clearing auto-refresh on unmount
      clearInterval(this.intervalTimer);
    }
  }

  public fetchViewsAssociatedWithUpload = (uploadData: ManualUpload) => {
    const { projectId, aoiId, fetchViewById } = this.props;

    if (
      uploadData &&
      uploadData.generatedViews &&
      uploadData.generatedViews.length > 0
    ) {
      uploadData.generatedViews.forEach((view) => {
        fetchViewById(projectId, aoiId, view.id);
      });
    }
  };

  public fetchLatestUploadData = (uploadId: string | null) => {
    const { updateUploadData, projectId, aoiId, showSnackBar } = this.props;

    if (uploadId) {
      manualUploadApis.fetchUpload(projectId, aoiId, uploadId).then((res) => {
        if (res.error) {
          if (this.errorCount < MAX_REFRESH_ERROR_RETRIES) {
            this.errorCount += 1;

            console.error(
              `[${this.errorCount}/${MAX_REFRESH_ERROR_RETRIES}] Error while fetching upload data.`,
              res.error
            );
          } else {
            clearInterval(this.intervalTimer);
            showSnackBar({
              body: res.error,
              type: 'error',
            });
          }

          // preventing state update when API errors out
          return;
        }

        const uploadData: ManualUpload = res.data;

        if (
          !(
            uploadData.status === 'started' ||
            uploadData.status === 'pending' ||
            uploadData.status === 'starting'
          )
        ) {
          clearInterval(this.intervalTimer);
        }

        return updateUploadData(uploadData);
      });
    }
  };

  public getDefaultUnits = () => {
    const { uploadData } = this.props;

    if (
      uploadData &&
      uploadData.uploadInfo &&
      uploadData.uploadInfo.coordinateUnits
    ) {
      return uploadData.uploadInfo.coordinateUnits;
    }

    return 'METRES';
  };

  public isPublished = () => {
    // placeholder for published status
    const { uploadData } = this.props;

    if (uploadData && uploadData.finalized) {
      return true;
    }

    return false;
  };

  public isProcessing = () => {
    const { uploadData } = this.props;

    if (uploadData) {
      if (
        uploadData.status === 'started' ||
        uploadData.status === 'pending' ||
        uploadData.status === 'starting'
      ) {
        return true;
      }
    }

    return false;
  };

  // ----- Function to handle state change -------------------------------------------------

  public handleShowPopup = (popupType: PopupTypes) => {
    const { popupOpen } = this.state;

    popupOpen[popupType] = true;

    this.setState({
      popupOpen,
    });
  };

  public handlePopupCloseOnCancel = (popupType: PopupTypes) => {
    const { popupOpen, loading } = this.state;

    if (loading) {
      // prevent pop-up close when APIs are running
      return;
    }

    popupOpen[popupType] = false;

    this.setState({
      popupOpen,
    });
  };

  public handleSelectPointCloud = () => {
    const node = this.pointCloudFileUploadRef.current;

    if (node) {
      node.click();
    }

    this.handlePopupCloseOnCancel('selectPointCloud');
  };

  public handleProcessStart = () => {
    const { form, showSnackBar, uploadData } = this.props;
    const { pointCloudFile } = this.state;

    form.validateFields(['units'], (err: any, values: any) => {
      if (err) {
        return;
      }

      const { units } = values;

      if (pointCloudFile === null) {
        return showSnackBar({
          body: 'Select an appropriate LAS Pointcloud file, before starting upload.',
          type: 'error',
        });
      }

      this.setState(
        {
          loading: true,
        },
        () => {
          if (uploadData) {
            return this.reprocessExistingUpload(units);
          }

          return this.createNewUpload(units);
        }
      );
    });
  };

  public createNewUpload = (coordinateUnits: CoordinateUnits) => {
    const { showSnackBar, projectId, aoiId, missionId, updateUploadData } =
      this.props;
    const { pointCloudFile } = this.state;

    if (pointCloudFile == null) {
      // this should not happen, as it should be validated before calling this function
      return;
    }

    return manualUploadApis
      .createPointCloudUpload(projectId, aoiId, coordinateUnits, missionId)
      .then((res) => {
        if (res.error) {
          this.setState({ loading: false });

          return showSnackBar({
            body: res.error,
            type: 'error',
          });
        }

        if (updateUploadData) {
          updateUploadData(res.data.upload);
        }

        const { popupOpen } = this.state;

        popupOpen.confirmProcess = false;
        popupOpen.uploadingPointCloud = true;

        this.setState(
          {
            popupOpen,
          },
          () => {
            const uploadId = res.data.upload.id;
            const { pointCloudUrl } = res.data.urls;

            return manualUploadApis
              .uploadFile(pointCloudFile, pointCloudUrl)
              .then(() => {
                return manualUploadApis
                  .triggerUploadProcessing(projectId, aoiId, uploadId, {
                    coordinateUnits,
                  })
                  .then((res) => {
                    const { popupOpen } = this.state;

                    popupOpen.uploadingPointCloud = false;

                    this.setState({
                      popupOpen,
                      loading: false,
                    });

                    if (res.error) {
                      return showSnackBar({
                        body: res.error,
                        type: 'error',
                      });
                    }

                    if (updateUploadData) {
                      updateUploadData(res.data);
                    }
                  });
              });
          }
        );
      });
  };

  public reprocessExistingUpload = (coordinateUnits: CoordinateUnits) => {
    const { showSnackBar, projectId, aoiId, uploadData, updateUploadData } =
      this.props;
    const { pointCloudFile } = this.state;

    if (pointCloudFile == null) {
      // this should not happen, as it should be validated before calling this function
      return;
    }

    if (!uploadData?.id) {
      // this function should only be called when there is an existing upload
      return;
    }

    return manualUploadApis
      .getFileReUploadUrls(projectId, aoiId, uploadData.id)
      .then((res) => {
        if (res.error) {
          this.setState({ loading: false });

          return showSnackBar({
            body: res.error,
            type: 'error',
          });
        }

        const { popupOpen } = this.state;

        popupOpen.confirmProcess = false;
        popupOpen.uploadingPointCloud = true;

        this.setState(
          {
            popupOpen,
          },
          () => {
            const uploadId = res.data.upload.id;
            const { pointCloudUrl } = res.data.urls;

            return manualUploadApis
              .uploadFile(pointCloudFile, pointCloudUrl)
              .then(() => {
                return manualUploadApis
                  .triggerUploadProcessing(projectId, aoiId, uploadId, {
                    coordinateUnits,
                  })
                  .then((res) => {
                    const { popupOpen } = this.state;

                    popupOpen.uploadingPointCloud = false;

                    this.setState({
                      popupOpen,
                      loading: false,
                    });

                    if (res.error) {
                      return showSnackBar({
                        body: res.error,
                        type: 'error',
                      });
                    }

                    updateUploadData(res.data);
                  });
              });
          }
        );
      });
  };

  public handlePointCloudFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let file: File | null = null;

    if (event.target.files && event.target.files.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      file = event.target.files[0];
    }

    if (file === null) {
      // break if file has not been selected
      return;
    }

    this.setState({ pointCloudFile: file });
  };

  private handleMapFullScreenToggle = (): void => {
    this.setState(
      ({ isMapFullScreen }) => {
        return {
          isMapFullScreen: !isMapFullScreen,
        };
      },
      () => {
        const { isMapFullScreen } = this.state;

        if (isMapFullScreen) {
          setBodyOverflow('y');

          return;
        }

        resetBodyOverFlow('y');
      }
    );
  };

  // ------ Functions to handle if content should be displayed or enabled --------------------

  public shouldDisplayContent = (contentType: ContentType) => {
    // placeholder for logic to control if certain content should be displayed
    const { pointCloudFile } = this.state;
    const { uploadData, actionType } = this.props;

    if (contentType == null) {
      return false;
    }

    if (contentType === 'selectPointCloud') {
      if (actionType === 'new') {
        return true;
      }

      if (actionType === 'edit' && uploadData) {
        if (uploadData.status === 'error' || uploadData.sessionId === null) {
          return true;
        }
      }
    }

    if (contentType === 'process') {
      if (actionType === 'edit') {
        if (
          uploadData &&
          (uploadData.sessionId === null || uploadData.status === 'error')
        ) {
          if (pointCloudFile !== null) {
            return true;
          }
        }
      }

      if (actionType === 'new') {
        if (pointCloudFile !== null) {
          return true;
        }
      }
    }

    if (contentType === 'units') {
      if (actionType === 'edit') {
        if (
          uploadData &&
          (uploadData.sessionId === null || uploadData.status === 'error')
        ) {
          if (pointCloudFile !== null) {
            return true;
          }
        }
      }

      if (actionType === 'new') {
        if (pointCloudFile !== null) {
          return true;
        }
      }
    }

    return false;
  };

  public renderInputParameters() {
    const { form } = this.props;
    const { pointCloudFile } = this.state;

    const { getFieldDecorator } = form;

    return (
      <div className={styles.inputFieldContainer}>
        {this.shouldDisplayContent('selectPointCloud') ? (
          <React.Fragment>
            <div className={styles.inputFieldWrapper}>
              <Button
                icon="upload"
                text="Select Point Cloud"
                className={styles.uploadInput}
                onClick={() => this.handleShowPopup('selectPointCloud')}
              />
            </div>
            {pointCloudFile ? (
              <div className={styles.uploadInputFileWrapper}>
                <i className="fa fa-file-o" aria-hidden="true" />
                <Text>{pointCloudFile.name}</Text>
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
        {this.shouldDisplayContent('units') ? (
          <div className={styles.inputFieldWrapper}>
            <Form.Item label="Point Cloud Units">
              {getFieldDecorator('units', {
                rules: [
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ],
                initialValue: this.getDefaultUnits(),
              })(
                <Select
                  className={styles.inputField}
                  dropdownStyle={{ zIndex: 20001 }}
                >
                  <Option value="METRES">Metres</Option>
                  <Option value="FEET">Feet</Option>
                  <Option value="US_SURVEY_FEET">US Survey Feet</Option>
                </Select>
              )}
            </Form.Item>
          </div>
        ) : null}
        {this.shouldDisplayContent('process') ? (
          <div className={styles.inputFieldWrapper}>
            <Button
              text="Upload"
              className={styles.uploadInput}
              onClick={() => this.handleShowPopup('confirmProcess')}
            />
          </div>
        ) : null}
      </div>
    );
  }

  public renderViewPreview() {
    const { uploadData, projectId, aoiId } = this.props;
    const { isMapFullScreen } = this.state;

    if (
      uploadData &&
      uploadData.status === 'completed' &&
      uploadData.generatedViews &&
      uploadData.generatedViews.length > 0
    ) {
      const { generatedViews: views } = uploadData;
      const viewToDisplay = views.find((view) => view.subType === 'potree');

      if (viewToDisplay) {
        return (
          <div
            className={classnames(styles.viewContainer, {
              [styles.fullScreen]: isMapFullScreen,
            })}
          >
            <div className={styles.postGeneratedContainer}>
              <div
                className={classnames(styles.viewWrapper, {
                  [styles.fullScreen]: isMapFullScreen,
                })}
              >
                <ViewV2
                  viewId={viewToDisplay.id}
                  projectId={projectId}
                  aoiId={aoiId}
                  isTerrainMap
                  isAoiArtifact
                  onFullScreen={this.handleMapFullScreenToggle}
                  isFullScreen={isMapFullScreen}
                />
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className={styles.viewContainer}>
        <div className={styles.postGeneratedContainer}>
          {MapGeneratedStatus({
            data: uploadData || null,
            type: 'pointCloudUpload',
            generating: uploadData ? uploadData.status === 'started' : false,
            error: uploadData ? uploadData.status === 'error' : false,
          })}
        </div>
      </div>
    );
  }

  public renderPopups() {
    // placeholder to display pop-ups
    const { popupOpen } = this.state;

    return (
      <React.Fragment>
        {popupOpen.selectPointCloud ? (
          <Modal
            title="Select Point Cloud"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onCancel={() => this.handlePopupCloseOnCancel('selectPointCloud')}
            className={styles.popupContainer}
            wrapClassName={styles.uploadModalWrapper}
          >
            <p className={styles.headertext}>
              Please select a point cloud that meets the following criteria:
              <ul className={styles.list}>
                <li>It should be a LAS Point Cloud.</li>
                <li>It should share some common area with the AOI boundary.</li>
              </ul>
            </p>

            <div className={styles.popupModalDiv}>
              <Button
                onClick={() => this.handleSelectPointCloud()}
                text="Select LAS Point Cloud"
              />
            </div>
          </Modal>
        ) : null}
        {popupOpen.confirmProcess ? (
          <Modal
            title="Confirm"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable={false}
            onCancel={() => this.handlePopupCloseOnCancel('confirmProcess')}
            className={styles.popupContainer}
            wrapClassName={styles.uploadModalWrapper}
          >
            <p className={styles.headertext}>
              Are you sure you want to upload and process the selected point
              cloud? The point cloud cannot be changed after upload.
            </p>

            <div className={styles.popupModalDiv}>
              <Button
                onClick={() => this.handleProcessStart()}
                text="Confirm"
              />
            </div>
          </Modal>
        ) : null}
        {popupOpen.uploadingPointCloud ? (
          <Modal
            title="Upload In Process"
            centered
            footer={null}
            visible
            destroyOnClose
            maskClosable
            className={styles.popupContainer}
            wrapClassName={styles.uploadModalWrapper}
          >
            <p className={styles.headertext}>
              Please wait while the files you selected are being uploaded.
              <b>
                Do not close this window or tab while the upload is taking
                place.
              </b>
            </p>
            <p className={styles.headertext}>
              This pop-up will close automatically once file upload is
              completed, and processing to display the output data starts.
            </p>
            <div className={styles.popupModalDiv}>
              <Button text="Uploading" loading />
            </div>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }

  public renderFileSelectElements = () => {
    return (
      <div>
        <input
          type="file"
          id="pointCloudFileUploadRef"
          ref={this.pointCloudFileUploadRef}
          key="pointCloudFileUpload"
          hidden
          multiple={false}
          accept=".laz,.las"
          onChange={(events: React.ChangeEvent<HTMLInputElement>) =>
            this.handlePointCloudFileChange(events)
          }
        />
      </div>
    );
  };

  public render() {
    const { loading } = this.state;

    return (
      <React.Fragment>
        <Prompt message="Are you sure you want to leave?" when={loading} />
        <div className={styles.container}>
          {this.renderInputParameters()}
          {this.renderViewPreview()}
        </div>
        {this.renderPopups()}
        {this.renderFileSelectElements()}
      </React.Fragment>
    );
  }
}

export default Form.create<PointCloudUploadPropsType>()(PointCloudUploadCrud);
