interface IAoiState {
  missions: any[];
  floorplans: any[];
  cadDiagrams: any[];
  loading: boolean;
  error: string;
}

const initialAoiState: IAoiState = {
  missions: [],
  floorplans: [],
  cadDiagrams: [],
  loading: false,
  error: '',
};

export default function aois(
  state: IAoiState = initialAoiState,
  action: any
): IAoiState {
  const { type, payload } = action;

  switch (type) {
    case 'FETCH_MISSIONS_PENDING':
      return {
        ...state,
        loading: true,
      };

    case 'FETCH_FLOORPLANS_PENDING':
      return {
        ...state,
        loading: true,
      };

    case 'FETCH_CADS_PENDING':
      return {
        ...state,
        loading: true,
      };

    case 'FETCH_MISSIONS_FULFILLED':
      return {
        ...state,
        missions: payload.data,
        loading: false,
      };

    case 'FETCH_MISSIONS_REJECTED':
      return {
        ...state,
        loading: false,
        error: 'Could not load missions, click OK to go back',
      };

    case 'FETCH_FLOORPLANS_FULFILLED':
      return {
        ...state,
        floorplans: payload.data,
        loading: false,
      };

    case 'FETCH_CADS_FULFILLED':
      return {
        ...state,
        cadDiagrams: payload.data,
        loading: false,
      };

    case 'RESET_AOI_STATE':
      return {
        missions: [],
        floorplans: [],
        cadDiagrams: [],
        loading: false,
        error: '',
      };

    default:
      return state;
  }
}
